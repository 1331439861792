import React from 'react';
import { RowProduct, ScansBlock } from 'components';
import { Row, Col } from 'reactstrap';
import PropTypes from 'prop-types';
import { combineRows } from 'helpers';
import { block } from 'bem-cn';
import './styles.scss';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.number,
  ])).isRequired,
};

const b = block('info-table');

const InfoTable = (props) => {
  const { data } = props;
  const formatData = combineRows(data);

  return (
    <Row className={b()}>
      {formatData.map((item, index) => {
        if (item) {
          if (item.header) {
            return (
              // eslint-disable-next-line
              <Col {...item} key={`header${index}`}>
                <p className={b('header-block')}>{item.header}</p>
              </Col>
            );
          }
          if (Array.isArray(item)) {
            return (
              <Col
                md="6"
                className="info-table__custom-row"
                // eslint-disable-next-line
                key={`${index}subswithch`}
              >
                {
                  item.map((subItem, subIndex) => (
                    <RowProduct
                      {...subItem}
                      // eslint-disable-next-line
                      key={`${index}${subIndex}includeSwitch`}
                      title={`${subItem.title}:`}
                    />
                  ))
                }
              </Col>
            );
          }

          if (item.element) {
            return (
              <Col
                md={item.md}
                sm={item.sm}
                row={item.row}
                className={`info-table__custom-row ${item.stylePrefix}`}
                // eslint-disable-next-line react/no-array-index-key
                key={`column-with-children-${index}`}
              >
                <RowProduct title={item.title} stylePrefix={item.stylePrefix}>
                  {item.element}
                </RowProduct>
              </Col>
            );
          }

          return (
            // eslint-disable-next-line
            <React.Fragment key={`${index}${item.title}`}>
              <Col {...item} key={item.title} className="info-table__custom-row">
                {item.title && <RowProduct {...item} title={`${item.title}:`} />}
              </Col>
              {item.scans && (
                <Col md="12" sm="12">
                  <ScansBlock data={item.scans} />
                </Col>
              )}
            </React.Fragment>
          );
        }

        return null;
      })}
    </Row>
  );
};

InfoTable.propTypes = propTypes;
export default InfoTable;
