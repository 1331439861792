import _ from 'lodash';
import { removeIfNeed, updateItem } from 'helpers';

import { GET_DATA_CONTRACT_SUCCESS } from 'redux/reducers/types';
import {
  GET_SCAN_SUCCESS,
  GET_SCAN_START,
  GET_SCAN_FAIL,
  UPLOAD_SCAN_FAIL,
  UPLOAD_SCAN_START,
  UPLOAD_SCAN_SUCCESS,
  DELETE_SCAN_SUCCESS,
  SET_FILE_TYPE_SUCCESS,
  DOWNLOAD_SCAN_FOR_VIEW_FAIL,
  DOWNLOAD_SCAN_FOR_VIEW_START,
  DOWNLOAD_SCAN_FOR_VIEW_SUCCESS,
  SET_INIT_STATE,
} from '../types';

const INITIAL_STATE = {
  loading: false,
  isLoadingScan: false,
  contractFiles: [],
  selectedTypes: [],
  isDownloadScan: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_SCAN_START:
      return {
        ...state,
        [payload]: undefined,
        loading: true,
      };

    case GET_SCAN_FAIL:
      return {
        ...state,
        loading: false,
      };

    case DOWNLOAD_SCAN_FOR_VIEW_START:
      return {
        ...state,
        isDownloadScan: true,
      };

    case DOWNLOAD_SCAN_FOR_VIEW_SUCCESS: {
      const { data, scanId } = payload;
      const { contractFiles } = state;
      const index = contractFiles.findIndex((scan) => scan.id === scanId);

      return {
        ...state,
        isDownloadScan: false,
        contractFiles: updateItem(contractFiles, index, {
          ...contractFiles[index], fileInfo: data,
        }),
      };
    }

    case DOWNLOAD_SCAN_FOR_VIEW_FAIL:
      return {
        ...state,
        isDownloadScan: false,
      };

    case UPLOAD_SCAN_START: {
      return {
        ...state,
        isLoadingScan: true,
      };
    }

    case GET_DATA_CONTRACT_SUCCESS:
      return {
        ...state,
        contractFiles: payload.scans,
        selectedTypes: payload.scans.map((scan) => scan.typeId),
      };

    case UPLOAD_SCAN_SUCCESS: {
      const {
        data,
        file,
        ctsId,
        fileName,
      } = payload;
      const { contractFiles } = state;
      const fileItem = [{
        id: data,
        fileInfo: file,
        ctsId,
        fileName,
      }];

      return {
        ...state,
        contractFiles: [...contractFiles, ...fileItem],
        isLoadingScan: false,
      };
    }

    case UPLOAD_SCAN_FAIL: {
      return {
        ...state,
        error: payload,
        isLoadingScan: false,
      };
    }

    case GET_SCAN_SUCCESS: {
      const {
        id,
        link,
        type: fileType,
        name,
        downloadLink,
      } = payload;

      return {
        ...state,
        [id]: {
          link,
          type: fileType,
          name,
          id,
          downloadLink,
        },
        loading: false,
      };
    }

    case DELETE_SCAN_SUCCESS: {
      const { contractFiles, selectedTypes } = state;
      const updateContractFiles = [...contractFiles];
      const updatedSelectedTypes = [...selectedTypes];
      const { typeId } = updateContractFiles.find((file) => file.id === payload);

      _.remove(updateContractFiles, { id: payload });
      removeIfNeed(updatedSelectedTypes, typeId);

      return {
        ...state,
        contractFiles: updateContractFiles,
        ...(typeId && { selectedTypes: updatedSelectedTypes }),
      };
    }

    case SET_FILE_TYPE_SUCCESS: {
      const { typeId, fileId } = payload;
      const { contractFiles, selectedTypes } = state;
      const { typeId: currentTypeId } = contractFiles.find((file) => file.id === fileId);

      removeIfNeed(selectedTypes, currentTypeId);

      const updateContractFiles = contractFiles.map((file) => ({
        ...file,
        ...(fileId === file.id && { typeId }),
      }));

      const updatedSelectedTypes = [...selectedTypes, typeId];

      return {
        ...state,
        contractFiles: updateContractFiles,
        selectedTypes: updatedSelectedTypes,
      };
    }

    case SET_INIT_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
