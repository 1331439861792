const TRADING_VIEW_WIDGETS = {
  reporting: 'reporting',
  chart: 'chart',
  miniChart: 'miniChart',
  technicalAnalysis: 'technicalAnalysis',
};

const TRADING_VIEW_URLS = {
  name: 'TradingView',
  chartUrl: 'https://s3.tradingview.com/tv.js',
  reportingUrl:
    'https://s3.tradingview.com/external-embedding/embed-widget-financials.js',
  technicalAnalysisUrl:
    'https://s3.tradingview.com/external-embedding/embed-widget-technical-analysis.js',
};

export { TRADING_VIEW_WIDGETS, TRADING_VIEW_URLS };
