export default (array, index, item) => {
  const newArray = [...array];

  if (!newArray[index]) {
    newArray[index] = null;
  }

  newArray.splice(index, 1, item);

  return newArray;
};
