import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import { useSelector } from 'react-redux';
import {
  selectCurrencyCode,
  selectMinInsuranceSumFormCreating,
  selectIsMinInsuranceSumClickedFormCreating,
} from 'redux/basketConstructor/selectors';
import { KnowMinInsuranceAmountButton } from '../../../_components/KnowMinInsuranceAmountButton';

const {
  ADD_IN_PRODUCT_STRATEGY,
  BACK_WORD,
  REQUEST_COUPON,
} = LANG_DICTIONARY;

const b = block('basket-creating-form-strategy-control');

const propTypes = {
  onClickAddStrategy: PropTypes.func.isRequired,
  onClickCalculate: PropTypes.func.isRequired,
  onClickGetMinInsuranceSum: PropTypes.func.isRequired,
  onClickBack: PropTypes.func.isRequired,
  isShowAddStrategy: PropTypes.bool.isRequired,
  isDisabledCalculate: PropTypes.bool.isRequired,
  closeMinInsuranceSumTable: PropTypes.func.isRequired,
};

const StrategyControl = ({
  onClickAddStrategy,
  onClickCalculate,
  onClickGetMinInsuranceSum,
  onClickBack,
  isShowAddStrategy,
  isDisabledCalculate,
  closeMinInsuranceSumTable,
}) => {
  const minInsuranceSum = useSelector(selectMinInsuranceSumFormCreating);
  const isMinInsuranceSumClicked = useSelector(selectIsMinInsuranceSumClickedFormCreating);
  const currencyCode = useSelector(selectCurrencyCode);

  return (
    <section className={b()}>
      <div className={b('row')}>
        <div className={b('add-strategy')}>
          {isShowAddStrategy && (
            <Button className={b('button').toString()} onClick={onClickAddStrategy}>
              <Icon name="plus" className={b('add-strategy-icon').toString()} />
              <p className={b('text')}>{ADD_IN_PRODUCT_STRATEGY}</p>
            </Button>
          )}
        </div>
        <div className={b('calculate-min-amount')}>
          <KnowMinInsuranceAmountButton
            onClick={onClickGetMinInsuranceSum}
            minInsuranceAmountData={minInsuranceSum}
            currencyCode={currencyCode}
            isMinInsuranceSumClicked={isMinInsuranceSumClicked}
            handleCloseMinInsuranceTable={closeMinInsuranceSumTable}
          />
        </div>
      </div>
      <div className={b('row')}>
        <Button className={b('button').toString()} onClick={onClickBack}>
          <p className={b('text')}>{BACK_WORD}</p>
        </Button>
        <Button
          className={b('button').toString()}
          onClick={onClickCalculate}
          disabled={isDisabledCalculate}
        >
          <p className={b('text')}>{REQUEST_COUPON}</p>
        </Button>
      </div>
    </section>
  );
};

StrategyControl.propTypes = propTypes;
export default React.memo(StrategyControl);
