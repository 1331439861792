import { updateItem } from 'helpers';
import {
  CHANGE_STATUS_SCAN_SAVE,
  GET_CONTRACT_FAIL,
  GET_CONTRACT_START,
  GET_CONTRACT_SUCCESS,
  CHANGE_STATUS_SCAN_SELECT,
  CHANGE_DESC_SCAN,
  SET_INIT_STATE, SET_STATUS_FINISH_CHECK,
} from '../types';

const INITIAL_STATE = {
  loading: false,
  finishedCheck: false,
  contractData: {},
  beneficiaryData: [],
  checkingData: {},
  insuredData: [],
  insurerData: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_STATUS_SCAN_SAVE: {
      const {
        prop,
        indexPerson = null,
        indexScan,
        status,
      } = payload;

      if (indexPerson !== null) {
        return {
          ...state,
          [prop]: updateItem(state[prop], indexPerson, {
            ...state[prop][indexPerson],
            scans: updateItem(state[prop][indexPerson].scans, indexScan, {
              ...state[prop][indexPerson].scans[indexScan],
              checkStatusCode: status,
              onChanged: true,
            }),
          }),
        };
      }

      return {
        ...state,
        [prop]: {
          ...state[prop],
          scans: updateItem(state[prop].scans, indexScan, {
            ...state[prop].scans[indexScan],
            checkStatusCode: status,
            onChanged: true,
          }),
        },
      };
    }

    case CHANGE_STATUS_SCAN_SELECT: {
      const {
        prop,
        indexPerson = null,
        indexScan,
        status,
        saveOnChanged,
      } = payload;

      if (indexPerson !== null) {
        return {
          ...state,
          [prop]: updateItem(state[prop], indexPerson, {
            ...state[prop][indexPerson],
            scans: updateItem(state[prop][indexPerson].scans, indexScan, {
              ...state[prop][indexPerson].scans[indexScan],
              checkErrorCode: status,
              ...(saveOnChanged && {
                onChanged: true,
              }),
            }),
          }),
        };
      }

      return {
        ...state,
        [prop]: {
          ...state[prop],
          scans: updateItem(state[prop].scans, indexScan, {
            ...state[prop].scans[indexScan],
            checkErrorCode: status,
            ...(saveOnChanged && {
              onChanged: true,
            }),
          }),
        },
      };
    }

    case CHANGE_DESC_SCAN: {
      const {
        prop,
        indexPerson = null,
        indexScan,
        desc,
      } = payload;

      if (indexPerson !== null) {
        return {
          ...state,
          [prop]: updateItem(state[prop], indexPerson, {
            ...state[prop][indexPerson],
            scans: updateItem(state[prop][indexPerson].scans, indexScan, {
              ...state[prop][indexPerson].scans[indexScan],
              checkErrorDescription: desc,
              onChanged: true,
            }),
          }),
        };
      }

      return {
        ...state,
        [prop]: {
          ...state[prop],
          scans: updateItem(state[prop].scans, indexScan, {
            ...state[prop].scans[indexScan],
            checkErrorDescription: desc,
            onChanged: true,
          }),
        },
      };
    }

    case SET_INIT_STATE:
      return INITIAL_STATE;

    case GET_CONTRACT_START:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTRACT_FAIL:
      return {
        ...state,
        error: payload,
        loading: false,
      };
    case GET_CONTRACT_SUCCESS:
      return {
        ...state,
        ...payload,
        loading: false,
        contractLoaded: true,
      };

    case SET_STATUS_FINISH_CHECK:
      return {
        ...state,
        finishedCheck: payload,
      };
    default:
      return state;
  }
};
