import { ROUTES, PRODUCT_VERSION } from 'consts';

const factoryProducts = (scenario, history, historyLink, id) => {
  switch (scenario) {
    case PRODUCT_VERSION.normal:
    case PRODUCT_VERSION.coupon:
    case PRODUCT_VERSION.normalCurrency:
    case PRODUCT_VERSION.couponCurrency:
      history.push(historyLink);
      break;

    case PRODUCT_VERSION.constructorIsz:
    case PRODUCT_VERSION.constructorIszDollar:
      history.push(ROUTES.constructorIsz({ id }));
      break;

    case PRODUCT_VERSION.nsz_1:
    case PRODUCT_VERSION.nsz_2:
    case PRODUCT_VERSION.nsz_3:
      history.push(`${ROUTES.nszCalculation}/${id}`);
      break;

    case PRODUCT_VERSION.ksz_1:
      history.push(`${ROUTES.kszParameters}/${id}`);
      break;

    default: {
      history.push(ROUTES.notFound);
    }
  }
};

export { factoryProducts };
