import {
  GET_SCAN_REQUEST,
  GET_SCAN_DIRECTLY_REQUEST,
  UPLOAD_SCAN_REQUEST,
  DELETE_SCAN_REQUEST,
  SET_FILE_TYPE_REQUEST,
  DOWNLOAD_SCAN_FOR_VIEW_REQUEST,
  SET_INIT_STATE,
} from '../types';

const getScanUnderwriting = (id, fileName) => ({
  type: GET_SCAN_REQUEST,
  payload: { id, fileName },
});

const getScanDirectly = (ctsId, fileName) => ({
  type: GET_SCAN_DIRECTLY_REQUEST,
  payload: { ctsId, fileName },
});

const uploadScan = (data) => ({
  type: UPLOAD_SCAN_REQUEST,
  payload: { ...data },
});

const deleteScan = (id) => ({
  type: DELETE_SCAN_REQUEST,
  payload: id,
});

const setTypeScan = (typeId, fileId) => ({
  type: SET_FILE_TYPE_REQUEST,
  payload: { typeId, fileId },
});

const downloadScanForView = (scanId) => ({
  type: DOWNLOAD_SCAN_FOR_VIEW_REQUEST,
  payload: scanId,
});

const setInitStateFiles = () => ({
  type: SET_INIT_STATE,
});

export {
  setInitStateFiles,
  downloadScanForView,
  setTypeScan,
  deleteScan,
  uploadScan,
  getScanUnderwriting,
  getScanDirectly,
};
