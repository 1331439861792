import {
  SET_FIELD_VALUE,
  SET_STATUS_TAB,
  SET_ACTIVE_TAB,
  SET_SHARED_COUNT_ERROR,
  SET_ERRORS,
  SET_TOUCHED,
  SET_ADDRESS,
  SET_FMS_UNIT,
  SET_LIVING_ADDRESS_IS_SAME,
  SET_ALL_TOUCHED,
  SET_INIT_FORM,
  SET_COUNT_BENEFICIARIES,
  SET_IS_BENEFICIARIES,
} from '../types';

const setSharedCountErrorForm = ({ count, form, index }) => ({
  type: SET_SHARED_COUNT_ERROR,
  payload: { count, form, index },
});

const setFieldValueBeneficiary = ({
  name,
  value,
  index,
  form,
}) => ({
  type: SET_FIELD_VALUE,
  payload: {
    name,
    value,
    index,
    form,
  },
});

const setStatusTabBeneficiary = ({ status, tabName, index }) => ({
  type: SET_STATUS_TAB,
  payload: { status, tabName, index },
});

const setErrorsBeneficiary = ({
  errors,
  index,
  form,
}) => ({
  type: SET_ERRORS,
  payload: {
    errors,
    index,
    form,
  },
});

const setTouchedBeneficiary = ({
  name,
  index,
  form,
  value,
}) => ({
  type: SET_TOUCHED,
  payload: {
    name,
    index,
    form,
    value,
  },
});

const setActiveTabBeneficiary = ({ tabName, index }) => ({
  type: SET_ACTIVE_TAB,
  payload: { tabName, index },
});

const setAddressBeneficiary = ({ index, values }) => ({
  type: SET_ADDRESS,
  payload: { index, values },
});

const setFmsUnitBeneficiary = ({ index, values }) => ({
  type: SET_FMS_UNIT,
  payload: { index, values },
});

const setLivingAddressIsSameBeneficiary = ({ index, status }) => ({
  type: SET_LIVING_ADDRESS_IS_SAME,
  payload: { index, status },
});

const setAllTouchedBeneficiary = ({ index, touched, form }) => ({
  type: SET_ALL_TOUCHED,
  payload: { index, touched, form },
});

const setInitFormBeneficiary = () => ({
  type: SET_INIT_FORM,
});

const setCountBeneficiaries = (count) => ({
  type: SET_COUNT_BENEFICIARIES,
  payload: count,
});

const setIsBeneficiaries = (status) => ({
  type: SET_IS_BENEFICIARIES,
  payload: status,
});

export {
  setCountBeneficiaries,
  setIsBeneficiaries,
  setInitFormBeneficiary,
  setAllTouchedBeneficiary,
  setLivingAddressIsSameBeneficiary,
  setAddressBeneficiary,
  setFmsUnitBeneficiary,
  setTouchedBeneficiary,
  setErrorsBeneficiary,
  setSharedCountErrorForm,
  setActiveTabBeneficiary,
  setStatusTabBeneficiary,
  setFieldValueBeneficiary,
};
