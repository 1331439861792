import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { ScanWindow } from 'components';
import block from 'bem-cn';
import {
  Button,
  Modal,
  Icon,
} from 'semantic-ui-react';
import { rebuildToPdf, getFormat } from 'helpers';
import './styles.scss';

const b = block('fileModal');

const defaultProps = {
  file: null,
  downloadScan: () => null,
  scanId: 0,
};

const propTypes = {
  file: PropTypes.object,
  downloadScan: PropTypes.func,
  scanId: PropTypes.number,
};

class FileViewModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
      url: '',
    };
  }

  rebuildToPdf = () => {
    const { file } = this.props;
    rebuildToPdf(file).then((url) => this.setState({ url }));
  }

  toggleModal = (value) => {
    const { downloadScan, scanId } = this.props;
    this.setState({ isOpen: value });
    if (value) downloadScan(scanId);
  };

  componentDidMount() {
    const { file } = this.props;

    if (file) {
      this.rebuildToPdf();
    }
  }

  componentDidUpdate(prevProps) {
    const { file: prevFile } = prevProps;
    const { file: newFile } = this.props;

    if (!prevFile && newFile) {
      this.rebuildToPdf();
    }
  }

  render() {
    const { isOpen, url } = this.state;
    const { file } = this.props;
    const fileType = file instanceof Blob ? getFormat(file.type) : '';

    return (
      <Modal
        size="tiny"
        open={isOpen}
        onClose={() => this.toggleModal(false)}
        className={b().toString()}
        trigger={(
          <Button
            type="button"
            className="fileModal__viewButton"
            onClick={() => this.toggleModal(true)}
          >
            <Icon name="eye" />
          </Button>
        )}
      >
        {url && (
          <ScanWindow
            fileLink={url}
            isShowDownloadIcon={false}
            fileType={fileType}
          />
        )}
        <Modal.Actions>
          <Button onClick={() => this.toggleModal(false)}>
            <Icon name="remove" />
            Отмена
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

FileViewModal.defaultProps = defaultProps;
FileViewModal.propTypes = propTypes;

export default FileViewModal;
