import {
  UNLOCK_ALL_LOCKS_DOCUMENTS_SUCCESS,
  UNLOCK_ALL_LOCKS_DOCUMENTS_START,
  UNLOCK_ALL_LOCKS_DOCUMENTS_FAIL,
  NOTIFY_CLIENTS_CALC_BASKET_FAIL,
  NOTIFY_CLIENTS_CALC_BASKET_START,
  NOTIFY_CLIENTS_CALC_BASKET_SUCCESS,
} from '../types';

const INITIAL_STATE = {
  activePage: 1,
  loadingUnlock: false,
  loadingNotifyCalcBasket: false,
};

export default (state = INITIAL_STATE, { type }) => {
  switch (type) {
    case UNLOCK_ALL_LOCKS_DOCUMENTS_START:
      return {
        ...state,
        loadingUnlock: true,
      };

    case UNLOCK_ALL_LOCKS_DOCUMENTS_FAIL:
      return {
        ...state,
        loadingUnlock: false,
      };

    case UNLOCK_ALL_LOCKS_DOCUMENTS_SUCCESS:
      return {
        ...state,
        loadingUnlock: false,
      };

    case NOTIFY_CLIENTS_CALC_BASKET_START:
      return {
        ...state,
        loadingNotifyCalcBasket: true,
      };

    case NOTIFY_CLIENTS_CALC_BASKET_SUCCESS:
      return {
        ...state,
        loadingNotifyCalcBasket: false,
      };

    case NOTIFY_CLIENTS_CALC_BASKET_FAIL:
      return {
        ...state,
        loadingNotifyCalcBasket: false,
      };

    default:
      return state;
  }
};
