import { LANG_DICTIONARY } from 'consts';
import moment from 'moment';

const {
  TODAY,
  YESTERDAY,
  CURRENT_WEEK,
  LAST_WEEK,
  CURRENT_MONTH,
  CURRENT_YEAR,
  ALL_TIME,
} = LANG_DICTIONARY;

const date = new Date();

const day = 24 * 60 * 60 * 1000;

const wrapperDate = (time) => ({
  start: new Date(time.start),
  end: new Date(time.end),
});

const getToday = () => ({ start: date, end: date });
const getYesterday = () => (wrapperDate({ start: date - day, end: date - day }));

const getCurrentWeek = () => {
  const currentDay = date.getDay();

  const currentMonday = +date - (currentDay - 1) * day;
  const currentSunday = +date + day * 6;

  return wrapperDate({
    start: currentMonday,
    end: currentSunday,
  });
};

const getLastWeek = () => {
  const { start } = getCurrentWeek();

  return wrapperDate({
    start: start - 7 * day,
    end: start - day,
  });
};

const getCurrentMonth = () => {
  const currentDay = date.getDate();
  const start = date - (currentDay - 1) * day;

  return wrapperDate({
    start,
    end: date,
  });
};

const getCurrentYear = () => ({
  start: moment().startOf('year').toDate(),
  end: date,
});

const getAllTime = () => ({
  start: moment('2000-01-01').toDate(),
  end: date,
});

export default (time) => {
  switch (time) {
    case TODAY:
      return getToday();
    case YESTERDAY:
      return getYesterday();
    case CURRENT_WEEK: {
      return getCurrentWeek();
    }
    case LAST_WEEK:
      return getLastWeek();
    case CURRENT_MONTH:
      return getCurrentMonth();
    case ALL_TIME:
      return getAllTime();
    case CURRENT_YEAR:
      return getCurrentYear();
    default:
      return undefined;
  }
};
