import AsyncActionFactory from 'helpers/actionsFactory';

const actionFactory = new AsyncActionFactory('publicApi');

export const GET_PUBLIC_API_LIST = actionFactory.createAsync('GET_PUBLIC_API_LIST');
export const SET_INIT_STATE = actionFactory.create('SET_INIT_STATE');
export const REMOVE_IP_ADDRESS = actionFactory.create('REMOVE_IP_ADDRESS');
export const ADD_IP_ADDRESS = actionFactory.create('ADD_IP_ADDRESS');
export const CHANGE_IP_ADDRESS = actionFactory.create('CHANGE_IP_ADDRESS');
export const CHANGE_FIELD_BY_NAME = actionFactory.create('CHANGE_FIELD_BY_NAME');
export const GET_DATA_FOR_SELECT_API = actionFactory.createAsync('GET_DATA_FOR_SELECT_API');
export const GET_PARTNERS = actionFactory.createAsync('GET_PARTNERS');
export const GET_BRANCHES = actionFactory.createAsync('GET_BRANCHES');
export const GET_USERS = actionFactory.createAsync('GET_USERS');
export const CREATE_NEW_PUBLIC_API = actionFactory.createAsync('CREATE_NEW_PUBLIC_API');
export const SET_STATUS_POPUP = actionFactory.create('SET_STATUS_POPUP');
export const GET_PUBLIC_API_BY_ID = actionFactory.createAsync('GET_PUBLIC_API_BY_ID');
export const REGENERATE_SECRET_KEY = actionFactory.createAsync('REGENERATE_SECRET_KEY');
