import { configDashboard, configMySales, configSideMenu } from './config';

const rebuildRoles = (roles) => {
  const rolesObject = {};
  roles.forEach((role) => {
    rolesObject[role] = true;
  });

  return rolesObject;
};

const checkRights = (roles, currentRight) => roles && roles[currentRight];

const getConfig = (roles, config) => Object.keys(config).reduce((rebuild, key) => ({
  ...rebuild,
  [key]: checkRights(roles, config[key]) || false,
}), {});

const getConfigMySales = (roles) => getConfig(roles, configMySales);
const getConfigDashboard = (roles) => getConfig(roles, configDashboard);
const getConfigSideMenu = (roles) => getConfig(roles, configSideMenu);

export {
  getConfigSideMenu,
  getConfigDashboard,
  getConfigMySales,
  checkRights,
  rebuildRoles,
};
