import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('toastr-trace-id');

const getToastrOptions = (traceId) => ({
  component: traceId ? (
    <div className={b()}>
      {traceId}
    </div>
  ) : undefined,
});

export default getToastrOptions;
