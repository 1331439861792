import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setStageProduct,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { BeneficiariesCommon } from 'containers';
import { addressCheckingPayloadNszSelector, productIdNszSelector } from 'redux/rootSelectors';

const defaultProps = {};

const propTypes = {
  setStage: PropTypes.func.isRequired,
};

const NszBeneficiaries = ({ setStage, ...rest }) => (
  <BeneficiariesCommon
    setStage={setStage}
    executionUrl={ROUTES.executionNsz}
    preliminaryCalculationUrl={ROUTES.nszCalculation}
    {...rest}
  />
);

NszBeneficiaries.propTypes = propTypes;
NszBeneficiaries.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  addressCheckingPayload: addressCheckingPayloadNszSelector(state),
  productId: productIdNszSelector(state),
});

const mapDispatchToProps = {
  setStage: setStageProduct,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NszBeneficiaries));
