import React, { useEffect, useCallback, useRef } from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Search, Button } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('filter-constructor-isz-popup');

const {
  APPLY_WORD,
  SET_FILTER_CONSTRUCTOR,
} = LANG_DICTIONARY;

const propTypes = {
  label: PropTypes.string.isRequired,
  onConfirm: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
};

export const FilterView = ({
  onConfirm,
  value,
  label,
  name,
  ...rest
}) => {
  const inputRef = useRef(null);
  const valueRef = useRef(null);
  valueRef.current = value;

  const listener = useCallback((e) => {
    if (e.key === 'Enter') {
      onConfirm(name, valueRef.current);
    }
  }, []);

  useEffect(() => {
    inputRef.current.focus();
    document.addEventListener('keydown', listener);

    return () => {
      document.removeEventListener('keydown', listener);
    };
  }, []);

  return (
    <div className={b()}>
      <p className={b('title')}>
        {`${SET_FILTER_CONSTRUCTOR} `}
        <b>{`«${label}»`}</b>
      </p>
      <div className={b('search-wrapper')}>
        <Search
          value={value}
          name={name}
          input={{ ref: inputRef }}
          {...rest}
        />
        <Button
          type="button"
          className={b('button').toString()}
          onClick={() => onConfirm(name, valueRef.current)}
        >
          {APPLY_WORD}
        </Button>
      </div>
    </div>
  );
};

FilterView.propTypes = propTypes;
