import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import PopupContainer from '../PopupContainer';
import './styles.scss';

const b = block('dp-policy-constructor-execution-success-popup');

const {
  DP_POLICY_EXECUTION_MESSAGE,
  FIO,
  MASTER_ID,
  CLIENT,
} = LANG_DICTIONARY;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  user: PropTypes.object.isRequired,
};

const DpPolicyConstructorExecutionSuccess = ({
  onClose,
  isOpen,
  user,
}) => (
  <PopupContainer
    isOpen={isOpen}
    isCloseButton
    close={onClose}
    className={b()}
  >
    <div className={b('form')}>
      <p className={b('message')}>{DP_POLICY_EXECUTION_MESSAGE}</p>
      <div>
        <p className={b('user')}>{`${CLIENT}:`}</p>
        <table>
          <tbody>
            <tr>
              <td>
                {MASTER_ID}
              </td>
              <td>
                {user.masterId}
              </td>
            </tr>
            {user.fullName && (
              <tr>
                <td>
                  {FIO}
                </td>
                <td>
                  {user.fullName}
                </td>
              </tr>
            )}
          </tbody>
        </table>
      </div>
    </div>
  </PopupContainer>
);

DpPolicyConstructorExecutionSuccess.propTypes = propTypes;
export default DpPolicyConstructorExecutionSuccess;
