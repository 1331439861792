import React from 'react';
import { SuccessBtn, CancelCrossBtn, LastCheckInfo } from 'components';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { TYPE_CHECKING_CONTRACT, TYPE_CHECKING_CONTRACT_RU } from 'consts';
import './styles.scss';

const b = block('scan-list');

const propTypes = {
  list: PropTypes.arrayOf(PropTypes.object).isRequired,
};

const {
  checked,
  new: newStatus,
  error,
} = TYPE_CHECKING_CONTRACT;

const getIcon = (status) => {
  switch (status) {
    case checked:
      return <SuccessBtn active />;
    case newStatus:
      return <SuccessBtn />;
    case error:
      return <CancelCrossBtn active />;
    default:
      return null;
  }
};

const ScanList = ({ list }) => (
  <div className={b()}>
    {list && list.map(({
      checkStatusCode,
      fileType,
      checkUserFio,
      checkDateTime,
      checkErrorName,
      checkErrorDescription,
    }, index) => (
      <div
        className={b('scan')}
        // eslint-disable-next-line
        key={`${fileType}${checkDateTime}${index}`}
      >
        <p>{fileType}</p>
        <LastCheckInfo content={{
          checker: checkUserFio,
          status: TYPE_CHECKING_CONTRACT_RU[checkStatusCode],
          dateCheck: checkDateTime,
          checkErrorName,
          checkErrorDescription,
        }}
        >
          <div>
            {getIcon(checkStatusCode)}
          </div>
        </LastCheckInfo>
      </div>
    ))}
  </div>

);

ScanList.propTypes = propTypes;
export default ScanList;
