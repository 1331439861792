import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import {
  Form,
  Icon,
  Select,
  Loader,
} from 'semantic-ui-react';
import Scroll from 'react-scroll';

import { GetFormError, Tooltip } from 'components';
import { getFormInputClass } from 'helpers';

const defaultProps = {
  values: {},
  errors: {},
  touched: {},
  name: null,
  propertyName: null,
  label: null,
  fieldNumber: null,
  width: '',
  inputWidth: '',
  wrapClass: '',
  tooltipContent: null,
  placeholder: '',
  required: false,
  onBlur: () => null,
  isShowLoading: false,
  loading: false,
  setFieldTouched: () => null,
  documentSelector: false,
  isAlwaysTouched: false,
  prefixScroll: '',
  prefixWrapperClass: '',
  multiple: false,
  search: false,
};

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  propertyName: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  fieldNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  inputWidth: PropTypes.string,
  wrapClass: PropTypes.string,
  tooltipContent: PropTypes.shape({
    text: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  isShowLoading: PropTypes.bool,
  loading: PropTypes.bool,
  setFieldTouched: PropTypes.func,
  documentSelector: PropTypes.bool,
  isAlwaysTouched: PropTypes.bool,
  prefixScroll: PropTypes.string,
  prefixWrapperClass: PropTypes.string,
  multiple: PropTypes.bool,
  search: PropTypes.bool,
};

const changeTarget = (onChange, data) => {
  onChange({ target: data });
};

const SelectInput = ({
  values,
  name,
  propertyName,
  errors,
  touched,
  label,
  fieldNumber,
  onChange,
  onBlur,
  tooltipContent,
  width,
  inputWidth,
  wrapClass,
  placeholder,
  required,
  isShowLoading,
  loading,
  setFieldTouched,
  documentSelector,
  isAlwaysTouched,
  prefixScroll,
  prefixWrapperClass,
  multiple,
  search,
  ...props
}) => {
  const [value, setValue] = useState(values[propertyName || name]);
  // const value = values[propertyName || name];

  useEffect(() => {
    setValue(values[propertyName || name]);
  }, [values[propertyName || name], propertyName, name]);
  const touchedCorrect = isAlwaysTouched ? { [propertyName || name]: true } : touched;

  return (
    <Scroll.Element name={`scroll${name}${prefixScroll}`} className={prefixWrapperClass}>
      <Form.Field
        className={getFormInputClass({
          error: errors[name],
          touched: touchedCorrect[propertyName || name],
          width,
          inputWidth,
          wrapClass,
        })}
        data-tip="helperTip"
        data-for={name}
      >
        { fieldNumber && <div className="fieldNumber">{ fieldNumber }</div> }
        <div className="fieldWrap">
          { label && <div className="label">{ label }</div> }
          <Select
            placeholder={placeholder}
            value={value}
            name={name}
            onChange={(event, data) => changeTarget(onChange, data, documentSelector)}
            onBlur={(event, data) => changeTarget(onBlur, data)}
            className={classNames(inputWidth)}
            multiple={multiple}
            search={search}
            {...props}
          />
          {required && <Icon name="write" className="icon-write-select" />}
          {isShowLoading && (
          <div className="select-loader">
            <Loader inline active={loading} size="small" />
          </div>
          )}
          {required && <Icon name="write" className="icon-write-select" />}
        </div>
        <GetFormError
          errors={errors}
          touched={touchedCorrect}
          itemName={name}
          propertyName={propertyName}
          value={value}
        />
        { tooltipContent && <Tooltip data={tooltipContent} tooltipName={name} /> }
      </Form.Field>
    </Scroll.Element>
  );
};

SelectInput.defaultProps = defaultProps;
SelectInput.propTypes = propTypes;

export default SelectInput;
