import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { FlexTable } from 'components';
import { getFormattedDate, getLinkScan } from 'helpers';
import { block } from 'bem-cn';
import { checkAccessAction, actions } from 'roles';
import { withCustomRouter } from 'HOC';
import { Icon } from 'semantic-ui-react';
import template from './template';
import extendedTemplate from './extendedTemplate';
import './styles.scss';

const b = block('flex-table-scans-status');

const {
  NUMBER,
  DATE_CONTRACT,
  FULL_NAME_CLIENTS,
  STATUS_CONTRACT,
  DATE_CHECK,
  ERROR,
  SUBDIVISION,
  USER,
} = LANG_DICTIONARY;

const getHeaders = (isExtendedData) => (isExtendedData ? [
  NUMBER,
  DATE_CONTRACT,
  FULL_NAME_CLIENTS,
  STATUS_CONTRACT,
  DATE_CHECK,
  SUBDIVISION,
  USER,
  ERROR,
] : [
  NUMBER,
  DATE_CONTRACT,
  FULL_NAME_CLIENTS,
  STATUS_CONTRACT,
  DATE_CHECK,
  ERROR,
]);

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.object,
  history: PropTypes.object,
};

const defaultProps = {
  data: [],
  roles: {},
  history: {},
};

const propTypesScanStatus = {
  scanType: PropTypes.string,
  errorDescription: PropTypes.string,
  scanError: PropTypes.string,
  ctsId: PropTypes.number,
  code: PropTypes.string,
  history: PropTypes.object,
};

const defaultPropsScanStatus = {
  scanType: '',
  errorDescription: '',
  scanError: '',
  ctsId: 0,
  code: '',
  history: {},
};

const ScansStatus = ({
  scanType,
  errorDescription,
  scanError,
  ctsId,
  code,
  history,
}) => {
  const handleClickDocument = (id, type) => {
    const link = getLinkScan(id, type);
    history.push(link);
  };

  return (
    <div className={b()}>
      <div className={b('content')}>
        <p>{`${scanType}:`}</p>
        <p>{errorDescription || scanError}</p>
      </div>
      <button
        type="button"
        className={b('button')}
        onClick={() => handleClickDocument(ctsId, code)}
      >
        <Icon name="file alternate" />
      </button>
    </div>
  );
};

const FlextTableEventsScan = ({ data, roles, history }) => {
  const isExtendedData = checkAccessAction(actions.GET_EXTENDED_SCANS_ERRORS_EVENTS, roles);

  const rebuildData = data && data
    .map(({
      ctsNum,
      dateCreate,
      insurerFirstName,
      insurerLastName,
      insurerMiddleName,
      status,
      checkFinishDate,
      errorDescription,
      scanError,
      scanType,
      branch,
      id,
      userLastName,
      userFirstName,
      userMiddleName,
      ctsId,
      code,
    }) => (isExtendedData ? extendedTemplate(getHeaders(isExtendedData), [
      ctsNum,
      getFormattedDate(dateCreate),
      `${insurerLastName} ${insurerFirstName} ${insurerMiddleName || ''}`,
      status,
      getFormattedDate(checkFinishDate),
      branch,
      `${userLastName} ${userFirstName} ${userMiddleName || ''}`,
      ScansStatus({
        scanType,
        errorDescription,
        scanError,
        ctsId,
        code,
        history,
      }),
      `${id}${ctsNum}${scanType}`,
    ]) : template(getHeaders(), [
      ctsNum,
      getFormattedDate(dateCreate),
      `${insurerLastName} ${insurerFirstName} ${insurerMiddleName || ''}`,
      status,
      getFormattedDate(checkFinishDate),
      ScansStatus({
        scanType,
        errorDescription,
        scanError,
        ctsId,
        code,
        history,
      }),
      `${id}${ctsNum}${scanType}`,
    ])));

  return (
    <FlexTable data={rebuildData} />
  );
};

FlextTableEventsScan.propTypes = propTypes;
FlextTableEventsScan.defaultProps = defaultProps;
ScansStatus.defaultProps = defaultPropsScanStatus;
ScansStatus.propTypes = propTypesScanStatus;

export default withCustomRouter(FlextTableEventsScan);
