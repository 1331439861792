const GET_CONTRACT_INFO_REQUEST = 'GET_CONTRACT_INFO_REQUEST';
const GET_CONTRACT_INFO_START = 'GET_CONTRACT_INFO_START';
const GET_CONTRACT_INFO_SUCCESS = 'GET_CONTRACT_INFO_SUCCESS';
const GET_CONTRACT_INFO_FAIL = 'GET_CONTRACT_INFO_FAIL';

const DOWNLOAD_SCAN = 'DOWNLOAD_SCAN';
const CLEAR_CONTRACT_INFO = 'CLEAR_CONTRACT_INFO';

const UPDATE_SCAN_CONTRACT_REQUEST = 'UPDATE_SCAN_CONTRACT_REQUEST';
const UPDATE_SCAN_CONTRACT_SUCCESS = 'UPDATE_SCAN_CONTRACT_SUCCESS';
const UPDATE_SCAN_CONTRACT_START = 'UPDATE_SCAN_CONTRACT_START';
const UPDATE_SCAN_CONTRACT_FAIL = 'UPDATE_SCAN_CONTRACT_FAIL';

export {
  UPDATE_SCAN_CONTRACT_REQUEST,
  UPDATE_SCAN_CONTRACT_SUCCESS,
  UPDATE_SCAN_CONTRACT_START,
  UPDATE_SCAN_CONTRACT_FAIL,
  CLEAR_CONTRACT_INFO,
  DOWNLOAD_SCAN,
  GET_CONTRACT_INFO_FAIL,
  GET_CONTRACT_INFO_REQUEST,
  GET_CONTRACT_INFO_START,
  GET_CONTRACT_INFO_SUCCESS,
};
