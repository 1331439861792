import {
  GET_ERROR,
  SEARCH_ADDRESS_START,
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_FAIL,
  SEARCH_ADDRESS_RESET,
  TOGGLE_MENU, SEARCH_ADDRESS_VALUE,
  SEARCH_FMS_UNIT_START,
  SEARCH_FMS_UNIT_SUCCESS,
  SEARCH_FMS_UNIT_FAIL,
  SEARCH_FMS_UNIT_RESET,
  SEARCH_FMS_UNIT_VALUE,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAIL,
  PARSE_XML_FILE_FOR_SELECTS_SUCCESS,
  CLOSE_IMPORT_FILE_FROM1C_POPUP,
  GET_AUTO_IMPORT_JOBS_SUCCESS,
  CHANGE_AUTO_IMPORT_SETTINGS,
  GET_BUILD_HASH_VERSION_SUCCESS,
  SEARCH_REGION,
  SEARCH_REGION_RESET,
} from './types';

import {
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS,
} from '../import/types';

const INITIAL_STATE = {
  menuOpen: false,
  passportError: null,
  snilsError: null,
  innError: null,
  addressSearchResult: [],
  isLoading: false,
  isFmsUnitSearchLoading: false,
  fmsUnitValues: [],
  genders: [],
  beneficiaries: [],
  relationships: [],
  isBeneficiaries: true,
  countBeneficiaries: 1,
  importFrom1CPartners: [],
  importFrom1CStatuses: [],
  answer: null,
  errors: [],
  successImport: false,
  importFileFrom1CPopupIsOpen: false,
  errorFilesDir: '/var/import1C/error',
  isActive: false,
  newFilesDir: '/var/import1C/new',
  successFilesDir: '/var/import1C/loaded',
  timeNext: '',
  timeRepeat: null,
  timeStart: '',
  timeSuccess: null,
  statusSuccessJob: false,
  hash: null,
  needUpdate: false,
  loadingImport: false,
  activeTaskInfoImport: {},
  regionSearchResult: [],
  isRegionSearchLoading: false,
  isRegionSearchLoadingLiving: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS: {
      const task = payload.data[0] || {};
      const parseAdditionalData = (data) => {
        try {
          return JSON.parse(data);
        } catch (e) {
          return {};
        }
      };

      return {
        ...state,
        activeTaskInfoImport: {
          ...task,
          ...parseAdditionalData(task.additionalData),
        },
      };
    }
    case CHANGE_AUTO_IMPORT_SETTINGS: {
      return {
        ...state,
        [payload.name]: payload.value,
      };
    }
    case GET_AUTO_IMPORT_JOBS_SUCCESS: {
      const { errorFilesDir, newFilesDir, successFilesDir } = state;

      return {
        ...state,
        statusSuccessJob: payload.status,
        errorFilesDir: payload.errorFilesDir || errorFilesDir,
        isActive: payload.isActive,
        newFilesDir: payload.newFilesDir || newFilesDir,
        successFilesDir: payload.successFilesDir || successFilesDir,
        timeNext: payload.dateNext,
        timeRepeat: payload.repeatInterval,
        timeStart: payload.timeStart,
        timeSuccess: payload.dateEnd,
      };
    }
    case CLOSE_IMPORT_FILE_FROM1C_POPUP: {
      return {
        ...state,
        importFileFrom1CPopupIsOpen: false,
      };
    }
    case PARSE_XML_FILE_FOR_SELECTS_SUCCESS: {
      return {
        ...state,
        importFrom1CPartners: [
          ...payload.partners,
        ],
        importFrom1CStatuses: [
          {
            key: null,
            value: null,
            text: 'Не выбрано',
          },
          ...payload.statuses,
        ],
      };
    }
    case GET_BUILD_HASH_VERSION_SUCCESS: {
      const { hash, needUpdate } = state;
      if (needUpdate) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        hash: payload.git_hash,
        needUpdate: Boolean(hash && hash !== payload.git_hash),
      };
    }
    case TOGGLE_MENU: {
      const { menuOpen } = state;
      const { systemClose } = payload;
      const isOpen = systemClose ? false : !menuOpen;

      return { ...state, menuOpen: isOpen };
    }

    case GET_ERROR: {
      const { error, itemType } = payload;

      return { ...state, [itemType]: error };
    }

    case SEARCH_ADDRESS_START: {
      return { ...state, isLoading: true };
    }

    case SEARCH_ADDRESS_SUCCESS: {
      return { ...state, addressSearchResult: payload, isLoading: false };
    }

    case SEARCH_ADDRESS_FAIL: {
      return { ...state, isLoading: false, error: payload };
    }

    case SEARCH_ADDRESS_VALUE: {
      return { ...state, addressValues: payload.address };
    }

    case SEARCH_ADDRESS_RESET: {
      return { ...state, addressValues: [] };
    }

    case SEARCH_FMS_UNIT_START: {
      return { ...state, isFmsUnitSearchLoading: true };
    }

    case SEARCH_FMS_UNIT_SUCCESS: {
      return { ...state, fmsUnitSearchResult: payload, isFmsUnitSearchLoading: false };
    }

    case SEARCH_FMS_UNIT_FAIL: {
      return { ...state, isFmsUnitSearchLoading: false, error: payload };
    }

    case SEARCH_FMS_UNIT_VALUE: {
      return { ...state, fmsUnitValues: payload.fmsUnit };
    }

    case SEARCH_FMS_UNIT_RESET: {
      return { ...state, fmsUnitValues: [] };
    }

    case GET_OPTIONS_SUCCESS: {
      const { data, dataType } = payload;

      return { ...state, [dataType]: data };
    }

    case GET_OPTIONS_FAIL: {
      return { ...state, addressValues: payload.address };
    }

    case SEARCH_REGION.start: {
      return {
        ...state,
        isRegionSearchLoading: !payload.isLivingAddress,
        isRegionSearchLoadingLiving: payload.isLivingAddress,
      };
    }

    case SEARCH_REGION.success: {
      return {
        ...state,
        regionSearchResult: payload,
        isRegionSearchLoading: false,
        isRegionSearchLoadingLiving: false,
      };
    }

    case SEARCH_REGION.fail: {
      return {
        ...state,
        isRegionSearchLoading: false,
        isRegionSearchLoadingLiving: false,
      };
    }

    case SEARCH_REGION_RESET: {
      return { ...state, regionSearchResult: [] };
    }

    default:
      return state;
  }
};
