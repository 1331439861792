import React, { Component } from 'react';
import {
  SwitchForm,
  InfoTable,
} from 'components';
import { connect } from 'react-redux';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { getDataPerson, getContractInfo } from 'helpers';
import { withCustomRouter } from 'HOC';
import { LANG_DICTIONARY } from 'consts';
import {
  getContractInfo as getContractInfoAction,
  getScan,
  clearContractInfo,
} from 'redux/rootActions';
import { ransomType, garantsType } from 'consts/propTypes';
import { actions, checkAccessAction } from 'roles';
import Controls from './controls';
import './styles.scss';

const propTypes = {
  contractInfo: PropTypes.shape({
    beneficiary: PropTypes.array,
    insurer: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.array,
    ]),
    contract: PropTypes.object,
    insured: PropTypes.array,
    garants: PropTypes.arrayOf(garantsType),
    ransoms: PropTypes.arrayOf(ransomType),
  }).isRequired,
  match: PropTypes.object,
  location: PropTypes.object,
  history: PropTypes.object,
  getContract: PropTypes.func,
  clearInfo: PropTypes.func,
  roles: PropTypes.object,
};

const defaultProps = {
  location: {},
  match: {},
  history: {},
  getContract: () => null,
  clearInfo: () => null,
  roles: {},
};

const {
  CONTRACT,
  INSURER,
  INSURED,
  BENEFICIARY,
  INSURED_IS_INSURER,
  BENEFICIARY_LAW,
} = LANG_DICTIONARY;

const b = block('contract-info-form');

class InfoContract extends Component {
  componentDidMount() {
    const {
      match: {
        params: {
          id,
        },
      },
      getContract,
    } = this.props;
    getContract(id);
  }

  componentWillUnmount() {
    const { clearInfo } = this.props;
    clearInfo();
  }

  goPath = (stateRoute) => {
    const { history } = this.props;
    history.goBack(stateRoute);
  };

  actionBackBtn = () => {
    this.goPath({ cash: true, access: true });
  };

  render() {
    const {
      contractInfo: {
        beneficiary,
        insurer,
        insured,
        contract,
        garants,
        ransoms,
      },
      match: { params: { active, subActive } },
      location: { state: stateRoute },
    } = this.props;
    const { ctsNum, insurerIsInsured } = contract;
    const additionallyData = { garants, ransoms };

    const { roles } = this.props;

    const isShowContractInfo = checkAccessAction(actions.SHOW_INSURER_CONTRACT_INFO, roles);

    const dataInsurer = isShowContractInfo ? getDataPerson(insurer) : getContractInfo(contract);

    const dataInsured = isShowContractInfo ? getDataPerson(insured) : getContractInfo(contract);

    const dataBeneficiary = isShowContractInfo
      ? getDataPerson(beneficiary)
      : getContractInfo(contract);

    const forms = [
      {
        title: CONTRACT,
        data: getContractInfo(contract, additionallyData),
        Component: InfoTable,
      },
      {
        title: INSURER,
        data: dataInsurer,
        Component: InfoTable,
      },
      {
        title: INSURED,
        data: dataInsured,
        Component: InfoTable,
        message: insurerIsInsured ? INSURED_IS_INSURER : null,
      },
      {
        title: BENEFICIARY,
        data: dataBeneficiary,
        Component: InfoTable,
        message: beneficiary.length ? null : BENEFICIARY_LAW,
      },
    ];

    return (
      <div className={b()}>
        <Controls
          data={{ contract, insurer }}
          handleClickBackBtn={this.actionBackBtn}
        />
        <section className={b('content')}>
          {ctsNum
          && (
            <SwitchForm
              accessDenied={isShowContractInfo}
              stateRoute={stateRoute}
              panels={forms}
              subActive={subActive}
              active={active}
            />
          )}
        </section>
      </div>
    );
  }
}

const mapStateToProps = ({
  contractInfo,
  authReducer: {
    roles,
  },
}) => ({
  contractInfo,
  roles,
});
const mapDispatchToProps = ({
  getContract: getContractInfoAction,
  downloadScan: getScan,
  clearInfo: clearContractInfo,
});

InfoContract.propTypes = propTypes;
InfoContract.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(InfoContract));
