import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  checkAccountNumbersKsz,
  saveDataKszForm,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { AgentData } from 'containers';

const defaultProps = {};

const propTypes = {
  saveData: PropTypes.func.isRequired,
  checkNumbers: PropTypes.func.isRequired,
};

export default (formType) => {
  class KszAgentData extends Component {
    constructor(props) {
      super(props);
      this.state = {};
    }

    AgentDataComponent = AgentData(formType);

    render() {
      const { AgentDataComponent } = this;
      const {
        saveData,
        checkNumbers,
        ...rest
      } = this.props;

      return (
        <AgentDataComponent
          saveData={saveData}
          checkNumbers={checkNumbers}
          insurerDataUrl={ROUTES.kszInsurerData}
          preliminaryCalculationUrl={ROUTES.kszParameters}
          {...rest}
        />
      );
    }
  }

  KszAgentData.propTypes = propTypes;
  KszAgentData.defaultProps = defaultProps;

  const mapDispatchToProps = {
    saveData: saveDataKszForm,
    checkNumbers: checkAccountNumbersKsz,
  };

  return withRouter(connect(null, mapDispatchToProps)(KszAgentData));
};
