import { put, takeLatest } from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  apiCall,
  getError,
} from 'helpers';
import { API, LANG_DICTIONARY } from 'consts';
import { getCatalogInfo as getCatalogs } from 'redux/rootActions';
import {
  GET_CATALOG_INFO_TABLE_REQUEST,
  GET_CATALOG_INFO_TABLE_START,
  GET_CATALOG_INFO_TABLE_SUCCESS,
  GET_CATALOG_INFO_TABLE_FAIL,
  SAVE_CHANGES_FOR_CATALOG_REQUEST,
  SAVE_CHANGES_FOR_CATALOG_START,
  SAVE_CHANGES_FOR_CATALOG_SUCCESS,
  SAVE_CHANGES_FOR_CATALOG_FAIL,
  GET_SELECT_INFO_REQUEST,
  GET_SELECT_INFO_START,
  GET_SELECT_INFO_SUCCESS,
  GET_SELECT_INFO_FAIL,
} from '../types';

const { STATUS_SAVED } = LANG_DICTIONARY;

function* getCatalogInfo({ payload }) {
  try {
    yield put({ type: GET_CATALOG_INFO_TABLE_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: `${API.GET_CATALOG_INFO}${payload}`,
    });
    yield put({ type: GET_CATALOG_INFO_TABLE_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CATALOG_INFO_TABLE_FAIL, payload: getError(e) });
  }
}

function* getSelectInfo() {
  try {
    yield put({ type: GET_SELECT_INFO_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CATALOG_SELECTOR,
    });
    yield put({ type: GET_SELECT_INFO_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_SELECT_INFO_FAIL, payload: getError(e) });
  }
}

function* saveChangesCatalog({ payload }) {
  try {
    yield put({ type: SAVE_CHANGES_FOR_CATALOG_START });
    yield apiCall({
      url: API.SAVE_CHANGES_CATALOGS,
      type: 'POST',
      body: payload,
    });
    yield put({ type: SAVE_CHANGES_FOR_CATALOG_SUCCESS });
    toastr.success('', STATUS_SAVED);
    yield put(getCatalogs(payload.id));
  } catch (e) {
    yield put({ type: SAVE_CHANGES_FOR_CATALOG_FAIL, payload: getError(e, null, null, true) });
  }
}

function* catalogsAdministrationSaga() {
  yield takeLatest(GET_CATALOG_INFO_TABLE_REQUEST, getCatalogInfo);
  yield takeLatest(GET_SELECT_INFO_REQUEST, getSelectInfo);
  yield takeLatest(SAVE_CHANGES_FOR_CATALOG_REQUEST, saveChangesCatalog);
}

export default catalogsAdministrationSaga;
