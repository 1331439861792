import {
  GET_CATALOG_INFO_TABLE_REQUEST,
  SAVE_CHANGES_FOR_CATALOG_REQUEST,
  GET_SELECT_INFO_REQUEST,
  ADD_NEW_ROW_TO_CATALOG,
  EDIT_ROW_IN_CATALOG,
  DELETE_ROW_IN_CATALOG,
  RELOAD_CHANGES,
  ACCESS_EDIT,
  INITIAL_STORE,
} from '../types';

const clearStore = () => ({
  type: INITIAL_STORE,
});

const accessEdit = (index) => ({
  type: ACCESS_EDIT,
  payload: index,
});

const getSelectInfo = () => ({
  type: GET_SELECT_INFO_REQUEST,
});

const getCatalogInfo = (id) => ({
  type: GET_CATALOG_INFO_TABLE_REQUEST,
  payload: id,
});

const saveChanges = (payload) => ({
  type: SAVE_CHANGES_FOR_CATALOG_REQUEST,
  payload,
});

const addNewInfoToCatalog = (payload) => ({
  type: ADD_NEW_ROW_TO_CATALOG,
  payload,
});

const editInfoInCatalog = (indexRow, changedIndex, value) => ({
  type: EDIT_ROW_IN_CATALOG,
  payload: { indexRow, changedIndex, value },
});

const deleteRowInCatalog = (indexRow) => ({
  type: DELETE_ROW_IN_CATALOG,
  payload: indexRow,
});

const cancelChangesCatalog = () => ({
  type: RELOAD_CHANGES,
});

export {
  getCatalogInfo,
  saveChanges,
  addNewInfoToCatalog,
  editInfoInCatalog,
  deleteRowInCatalog,
  cancelChangesCatalog,
  getSelectInfo,
  accessEdit,
  clearStore,
};
