import { put, takeLatest } from 'redux-saga/effects';
import {
  apiCall,
  getError,
  simpleDate,
  directDownloadFile,
} from 'helpers';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import { DOWNLOAD_ZIP_FORMAT_NAME } from 'config';
import {
  EXPORT_1C_FAIL,
  EXPORT_1C_SUCCESS,
  EXPORT_1C_START,
  EXPORT_1C_REQUEST,
  GET_CONTRACT_BY_NUMBER_SUCCESS,
  GET_CONTRACT_BY_NUMBER_FAIL,
  GET_CONTRACT_BY_NUMBER_REQUEST,
  GET_CONTRACT_BY_NUMBER_START,
  SET_CONTRACT_STATUS_FAIL,
  SET_CONTRACT_STATUS_START,
  SET_CONTRACT_STATUS_REQUEST,
  GET_CONTRACT_CURRENT_STATE_REQUEST,
  GET_CONTRACT_CURRENT_STATE_START,
  GET_CONTRACT_CURRENT_STATE_SUCCESS,
  GET_CONTRACT_CURRENT_STATE_FAIL,
  GET_CONTRACT_STATUSES_REQUEST,
  GET_CONTRACT_STATUSES_START,
  GET_CONTRACT_STATUSES_SUCCESS,
  GET_CONTRACT_STATUSES_FAIL,
  IMPORT_MANUAL_WEBSOCKET_START,
  IMPORT_MANUAL_WEBSOCKET_EMIT,
  IMPORT_MANUAL_WEBSOCKET_ERROR,
} from '../types';

const { EXPORT_1C, IMPORT_1C } = API;
const { EXPORT_SUCCESS, STATUS_SAVED } = LANG_DICTIONARY;
const { home } = ROUTES;

function* getStatuses() {
  try {
    yield put({ type: GET_CONTRACT_STATUSES_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_STATUS_LIST,
    });
    const rebuildData = data.map(({ id, status }) => (
      {
        key: id,
        value: id,
        text: status,
      }
    ));
    yield put({ type: GET_CONTRACT_STATUSES_SUCCESS, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_CONTRACT_STATUSES_FAIL, payload: getError(e) });
  }
}

function* getContractStates() {
  try {
    yield put({ type: GET_CONTRACT_CURRENT_STATE_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTRACT_STATES,
    });
    yield put({ type: GET_CONTRACT_CURRENT_STATE_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CONTRACT_CURRENT_STATE_FAIL, payload: getError(e) });
  }
}
function* saveContractStatus({ payload }) {
  try {
    const {
      ctsId,
      status,
      currentState,
      payments,
      dateCancel,
    } = payload;
    yield put({ type: SET_CONTRACT_STATUS_START });
    yield apiCall({
      type: 'POST',
      url: API.SAVE_CONTRACT_STATUS,
      body: {
        ctsId,
        paymentConditionId: currentState,
        payments,
        dateCancel: dateCancel ? simpleDate(dateCancel) : null,
        statusId: status,
      },
    });
    toastr.success('', STATUS_SAVED);
  } catch (e) {
    yield put({ type: SET_CONTRACT_STATUS_FAIL, payload: getError(e) });
  }
}

function* getContract({ payload }) {
  try {
    yield put({ type: GET_CONTRACT_BY_NUMBER_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTRACT_PAYOUTS_BY_NUMBER,
      query: { ctsNum: payload },
    });
    yield put({ type: GET_CONTRACT_BY_NUMBER_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CONTRACT_BY_NUMBER_FAIL, payload: getError(e) });
  }
}

function* export1C({ payload, history, isDownloadFileFrom1C }) {
  try {
    yield put({ type: EXPORT_1C_START });
    if (isDownloadFileFrom1C) {
      const { data } = yield apiCall({
        type: 'POST',
        url: EXPORT_1C,
        body: payload,
        isBlob: true,
      });
      const blob = new Blob([data]);
      directDownloadFile(blob, `export_${moment().format(DOWNLOAD_ZIP_FORMAT_NAME)}.zip`);
      toastr.success('', EXPORT_SUCCESS);
      history.push(home);
      yield put({ type: EXPORT_1C_SUCCESS, payload: data });
    } else {
      const { data } = yield apiCall({
        type: 'POST',
        url: EXPORT_1C,
        body: payload,
      });
      toastr.success('', EXPORT_SUCCESS);
      history.push(home);
      yield put({ type: EXPORT_1C_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: EXPORT_1C_FAIL, payload: getError(error) });
  }
}

function* importManualWebsocket({ payload }) {
  try {
    yield put({ type: IMPORT_MANUAL_WEBSOCKET_START });
    yield apiCall({
      type: 'POST',
      url: IMPORT_1C,
      body: payload.file,
    });
  } catch (error) {
    yield put({ type: IMPORT_MANUAL_WEBSOCKET_ERROR, payload: getError(error) });
  }
}

export default function* administrationSaga() {
  yield takeLatest(EXPORT_1C_REQUEST, export1C);
  yield takeLatest(GET_CONTRACT_BY_NUMBER_REQUEST, getContract);
  yield takeLatest(SET_CONTRACT_STATUS_REQUEST, saveContractStatus);
  yield takeLatest(GET_CONTRACT_STATUSES_REQUEST, getStatuses);
  yield takeLatest(GET_CONTRACT_CURRENT_STATE_REQUEST, getContractStates);
  yield takeLatest(IMPORT_MANUAL_WEBSOCKET_EMIT, importManualWebsocket);
}
