import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  firstRow: PropTypes.string,
  secondRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  isShowHeader: PropTypes.bool,
};

const defaultProps = {
  firstRow: '',
  secondRow: '',
  isShowHeader: false,
};

const b = block('underwriting-product-col');

const ProductColumn = ({ firstRow, secondRow, isShowHeader }) => (
  <div className={b()}>
    {isShowHeader && <p className={b('header')}>{firstRow}</p>}
    <div className={b('text')}>{secondRow}</div>
  </div>
);

ProductColumn.propTypes = propTypes;
ProductColumn.defaultProps = defaultProps;
export default ProductColumn;
