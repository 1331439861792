const GET_CATALOG_INFO_TABLE_REQUEST = 'catalogsAdminInterface/GET_CATALOG_INFO_TABLE_REQUEST';
const GET_CATALOG_INFO_TABLE_START = 'catalogsAdminInterface/GET_CATALOG_INFO_TABLE_START';
const GET_CATALOG_INFO_TABLE_SUCCESS = 'catalogsAdminInterface/GET_CATALOG_INFO_TABLE_SUCCESS';
const GET_CATALOG_INFO_TABLE_FAIL = 'catalogsAdminInterface/GET_CATALOG_INFO_TABLE_FAIL';

const SAVE_CHANGES_FOR_CATALOG_REQUEST = 'catalogsAdminInterface/SAVE_CHANGES_FOR_CATALOG_REQUEST';
const SAVE_CHANGES_FOR_CATALOG_START = 'catalogsAdminInterface/SAVE_CHANGES_FOR_CATALOG_START';
const SAVE_CHANGES_FOR_CATALOG_SUCCESS = 'catalogsAdminInterface/SAVE_CHANGES_FOR_CATALOG_SUCCESS';
const SAVE_CHANGES_FOR_CATALOG_FAIL = 'catalogsAdminInterface/SAVE_CHANGES_FOR_CATALOG_FAIL';

const GET_SELECT_INFO_REQUEST = 'catalogsAdminInterface/GET_SELECT_INFO_REQUEST';
const GET_SELECT_INFO_START = 'catalogsAdminInterface/GET_SELECT_INFO_START';
const GET_SELECT_INFO_SUCCESS = 'catalogsAdminInterface/GET_SELECT_INFO_SUCCESS';
const GET_SELECT_INFO_FAIL = 'catalogsAdminInterface/GET_SELECT_INFO_FAIL';

const ADD_NEW_ROW_TO_CATALOG = 'catalogsAdminInterface/ADD_NEW_ROW_TO_CATALOG';

const EDIT_ROW_IN_CATALOG = 'catalogsAdminInterface/EDIT_ROW_IN_CATALOG';

const DELETE_ROW_IN_CATALOG = 'catalogsAdminInterface/DELETE_ROW_IN_CATALOG';

const RELOAD_CHANGES = 'catalogsAdminInterface/RELOAD_CHANGES';

const ACCESS_EDIT = 'catalogsAdminInterface/ACCESS_EDIT';

const INITIAL_STORE = 'catalogsAdminInterface/INITIAL_STORE';

export {
  INITIAL_STORE,
  ACCESS_EDIT,
  GET_SELECT_INFO_REQUEST,
  GET_SELECT_INFO_START,
  GET_SELECT_INFO_SUCCESS,
  GET_SELECT_INFO_FAIL,
  GET_CATALOG_INFO_TABLE_REQUEST,
  GET_CATALOG_INFO_TABLE_START,
  GET_CATALOG_INFO_TABLE_SUCCESS,
  GET_CATALOG_INFO_TABLE_FAIL,
  SAVE_CHANGES_FOR_CATALOG_REQUEST,
  SAVE_CHANGES_FOR_CATALOG_START,
  SAVE_CHANGES_FOR_CATALOG_SUCCESS,
  SAVE_CHANGES_FOR_CATALOG_FAIL,
  ADD_NEW_ROW_TO_CATALOG,
  EDIT_ROW_IN_CATALOG,
  DELETE_ROW_IN_CATALOG,
  RELOAD_CHANGES,
};
