import React from 'react';
import { withCustomRouter } from 'HOC';
import { BasketCreatingForm } from 'components';

function BasketCreating() {
  return (
    <BasketCreatingForm />
  );
}

export default withCustomRouter(BasketCreating);
