const APP_CAN_RENDER = 'APP_CAN_RENDER';
const SET_TIMEOUT_WORK = 'SET_TIMEOUT_WORK';
const SET_ROUTE = 'shared/SET_ROUTE';
const NOTIFY_REDIRECT = 'shared/NOTIFY_REDIRECT';
const SET_BLOCK_REDIRECT = 'shared/SET_BLOCK_REDIRECT';
const SET_ACTION_REDIRECT = 'shared/SET_ACTION_REDIRECT';
const SET_STATUS_REDIRECT_MODAL = 'shared/SET_STATUS_REDIRECT_MODAL';
const PUSH_HISTORY_ROUTE = 'auth/PUSH_HISTORY_ROUTE';

const CHECK_EXIST_MANUAL_REQUEST = 'shared/CHECK_EXIST_MANUAL_REQUEST';
const CHECK_EXIST_MANUAL_SUCCESS = 'shared/CHECK_EXIST_MANUAL_SUCCESS';

const DOWNLOAD_MANUAL_REQUEST = 'shared/DOWNLOAD_MANUAL_REQUEST';
const DOWNLOAD_MANUAL_SUCCESS = 'shared/DOWNLOAD_MANUAL_SUCCESS';

const SET_PROGRESS_BAR_DOWNLOAD = 'shared/SET_PROGRESS_BAR_DOWNLOAD';
const SET_PROGRESS_PERCENT = 'shared/SET_PROGRESS_PERCENT';
const SET_DOWNLOAD_FILENAME = 'shared/SET_DOWNLOAD_FILENAME';
const SAVE_AND_CLEAR_QUERY_PARAMS = 'shared/SAVE_AND_CLEAR_QUERY_PARAMS';
const SAVE_AND_CLEAR_QUERY_PARAMS_STORE = 'shared/SAVE_AND_CLEAR_QUERY_PARAMS_STORE';
const GENERATE_WINDOW_UUID = 'shared/GENERATE_WINDOW_UUID';
const CHANGE_QUERY_PARAMS = 'shared/CHANGE_QUERY_PARAMS';
const SET_WINDOW_TIMESTAMP = 'shared/SET_WINDOW_TIMESTAMP';

export {
  SET_WINDOW_TIMESTAMP,
  CHANGE_QUERY_PARAMS,
  GENERATE_WINDOW_UUID,
  SAVE_AND_CLEAR_QUERY_PARAMS_STORE,
  SAVE_AND_CLEAR_QUERY_PARAMS,
  SET_DOWNLOAD_FILENAME,
  SET_PROGRESS_PERCENT,
  SET_PROGRESS_BAR_DOWNLOAD,
  DOWNLOAD_MANUAL_REQUEST,
  DOWNLOAD_MANUAL_SUCCESS,
  CHECK_EXIST_MANUAL_REQUEST,
  CHECK_EXIST_MANUAL_SUCCESS,
  PUSH_HISTORY_ROUTE,
  SET_STATUS_REDIRECT_MODAL,
  SET_ACTION_REDIRECT,
  SET_BLOCK_REDIRECT,
  NOTIFY_REDIRECT,
  SET_ROUTE,
  APP_CAN_RENDER,
  SET_TIMEOUT_WORK,
};
