import {
  GET_CONTRACT_INFO_REQUEST,
  DOWNLOAD_SCAN,
  CLEAR_CONTRACT_INFO,
  UPDATE_SCAN_CONTRACT_REQUEST,
} from '../types';

const getContractInfo = (id) => ({
  type: GET_CONTRACT_INFO_REQUEST,
  payload: id,
});

const clearContractInfo = () => ({
  type: CLEAR_CONTRACT_INFO,
});

const getScan = (id, fileName) => ({
  type: DOWNLOAD_SCAN,
  payload: id,
  fileName,
});

const updateContractScan = (ctsId, formData) => ({
  type: UPDATE_SCAN_CONTRACT_REQUEST,
  payload: { ctsId, formData },
});

export {
  updateContractScan,
  clearContractInfo,
  getContractInfo,
  getScan,
};
