export default (data) => {
  const {
    index,
    republic,
    area,
    city,
    street,
    house,
    houseNumber,
    apartment,
    indexLiving,
    republicLiving,
    areaLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    houseNumberLiving,
    apartmentLiving,
  } = data;

  return `${index}${republic}${area}${city}${street}${house}${houseNumber}${apartment}` ===
    `${indexLiving}${republicLiving}${areaLiving}${cityLiving}${streetLiving}${houseLiving}${houseNumberLiving}${apartmentLiving}`;
};
