import { ERRORS, LANG_DICTIONARY } from 'consts';
import { rebuildRoles } from 'helpers';

import {
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  RESET_REQUEST_START,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  CHECK_PASS_REQUEST_SUCCESS,
  CHECK_PASS_REQUEST_FAIL,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_IP_ADDRESS_SUCCESS,
  GET_CAPTCHA_SUCCESS,
  GET_CAPTCHA_FAIL,
  GET_CAPTCHA_START,
  LOG_OUT_SUCCESS,
  RESET_TEMPORARY_PASSWORD_SUCCESS,
  SET_STORE_TOKEN,
  TOGGLE_MODAL_AFFILIATION,
  GET_BRANCHES_FOR_AUTH_FAIL,
  GET_BRANCHES_FOR_AUTH_START,
  GET_PARTNERS_FOR_AUTH_START,
  GET_PARTNERS_FOR_AUTH_FAIL,
  GET_BRANCHES_FOR_AUTH_SUCCESS,
  SELECT_BRANCH_FOR_AUTH,
  GET_PARTNERS_FOR_AUTH_SUCCESS,
  SELECT_PARTNER_FOR_AUTH,
  OPEN_WINDOW_BLOCK_UNITED_FRONT,
  SET_UNITED_FRONTEND_PARAMS,
  TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION,
  SET_IS_TEST_BRANCH,
  ACCEPT_AGREEMENT_RULES,
  SET_AFFILIATION_SUCCESS,
  CLEAR_FORM_PARTNER_CHANNEL,
  CHANGE_FORM_PARTNER_CHANNEL,
  CHANGE_RECOVERY_LOGIN_MODAL,
  LOGIN_RECOVERY,
} from './types';

const INITIAL_STATE = {
  token: null,
  error: null,
  success: null,
  isLoading: false,
  userEmail: null,
  confirmationToken: null,
  isAuthenticated: false,
  nameCP: '',
  treeName: '',
  userLastName: '',
  userFirstName: '',
  userMiddleName: '',
  userLogin: '',
  userIP: '',
  gurole: [],
  roles: {},
  sessionExpire: '',
  tempPass: false,
  captchaId: '',
  captcha: '',
  accessBranches: [],
  accessPartners: [],
  modalAffiliation: false,
  selectedBranchId: null,
  selectedPartnerId: null,
  dataUserExist: false,
  isLoadingBranches: false,
  isLoadingPartners: false,
  isTestBranch: false,
  isShowAcceptAgreement: false,
  partnerRules: {},
  partnerChannelForm: {},
  isShowLoginRecoveryModal: false,
  isLoadingSendingMessageRecoveryAccess: false,

  /* united frontend */
  masterId: null,
  riskProfile: null,
  isExistOtherUnitedFrontSession: false,
  isOpenBlockUnitedFront: false,
  isUnitedFront: false,
  qualification: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_FORM_PARTNER_CHANNEL: {
      const { name, value } = payload;
      const { partnerChannelForm } = state;

      return {
        ...state,
        partnerChannelForm: {
          ...partnerChannelForm,
          [name]: value,
        },
      };
    }

    case CLEAR_FORM_PARTNER_CHANNEL: {
      return {
        ...state,
        partnerChannelForm: {},
      };
    }

    case SET_AFFILIATION_SUCCESS: {
      const { partnerRules, isShowAcceptAgreement } = payload;

      const partnerRulesCheckboxes = Object.fromEntries(partnerRules.map(
        (item, index) => ([`rule${index}`, false]),
      ));

      return {
        ...state,
        isShowAcceptAgreement,
        partnerRules,
        partnerChannelForm: partnerRulesCheckboxes,
      };
    }

    case ACCEPT_AGREEMENT_RULES.success: {
      return {
        ...state,
        isShowAcceptAgreement: true,
        partnerChannelForm: {},
      };
    }

    case SET_IS_TEST_BRANCH: {
      return {
        ...state,
        isTestBranch: payload,
      };
    }

    case SET_STORE_TOKEN: {
      return {
        ...state,
        token: payload,
      };
    }
    case SIGN_IN_START: {
      return { ...state, isLoading: true, error: null };
    }
    case SIGN_IN_SUCCESS: {
      localStorage.setItem('token', payload);

      return {
        ...state,
        isLoading: false,
        token: payload,
        isAuthenticated: true,
        captcha: '',
        captchaId: '',
      };
    }
    case SIGN_IN_FAIL: {
      localStorage.removeItem('token');

      return { ...state, isLoading: false, error: payload };
    }

    case RESET_REQUEST_START: {
      return { ...state, isLoading: true, error: null };
    }
    case RESET_REQUEST_SUCCESS: {
      const { CHECK_EMAIL } = LANG_DICTIONARY;

      return {
        ...state,
        isLoading: false,
        success: CHECK_EMAIL,
        error: null,
      };
    }
    case RESET_REQUEST_FAIL: {
      return {
        ...state,
        isLoading: false,
        error: payload,
        success: null,
      };
    }

    case CHECK_PASS_REQUEST_SUCCESS: {
      const { userEmail, confirmationToken } = payload;

      return {
        ...state,
        isLoading: false,
        error: null,
        userEmail,
        confirmationToken,
      };
    }
    case CHECK_PASS_REQUEST_FAIL: {
      const { RESET_PASS_ERROR } = ERRORS;

      return {
        ...state,
        isLoading: false,
        error: RESET_PASS_ERROR,
        userEmail: null,
        confirmationToken: null,
      };
    }

    case CHANGE_PASSWORD_START: {
      return { ...state, isLoading: true, error: null };
    }
    case CHANGE_PASSWORD_SUCCESS: {
      return { ...state, isLoading: false };
    }
    case CHANGE_PASSWORD_FAIL: {
      return { ...state, isLoading: false, error: payload };
    }

    case GET_CURRENT_USER_START:
      return {
        ...state,
        isLoading: true,
      };

    case GET_CURRENT_USER_SUCCESS: {
      const { gurole, roles, ...rest } = payload;
      const data = {
        ...rest,
        gurole,
        roles: rebuildRoles(roles),
        isLoading: false,
        dataUserExist: true,
      };

      return {
        ...state,
        ...data,
        isAuthenticated: true,
      };
    }

    case GET_CAPTCHA_START: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case GET_CAPTCHA_FAIL: {
      return {
        ...state,
        error: payload,
        isLoading: false,
      };
    }

    case GET_CAPTCHA_SUCCESS: {
      const { captcha, captchaId } = payload;

      return {
        ...state,
        isLoading: false,
        captcha,
        captchaId,
      };
    }

    case LOG_OUT_SUCCESS: {
      localStorage.removeItem('token');

      return {
        ...INITIAL_STATE,
        userIP: state.userIP,
      };
    }

    case GET_IP_ADDRESS_SUCCESS: {
      return {
        ...state,
        userIP: payload,
      };
    }

    case RESET_TEMPORARY_PASSWORD_SUCCESS:
      return {
        ...state,
        tempPass: false,
      };

    case GET_BRANCHES_FOR_AUTH_SUCCESS:
      return {
        ...state,
        accessBranches: payload,
        isLoadingBranches: false,
      };

    case TOGGLE_MODAL_AFFILIATION:
      return {
        ...state,
        modalAffiliation: payload,
      };

    case SELECT_BRANCH_FOR_AUTH:
      return {
        ...state,
        selectedBranchId: payload,
      };

    case SELECT_PARTNER_FOR_AUTH:
      return {
        ...state,
        selectedPartnerId: payload,
      };

    case GET_PARTNERS_FOR_AUTH_SUCCESS:
      return {
        ...state,
        accessPartners: payload,
        dataPartnersExist: true,
      };

    case GET_BRANCHES_FOR_AUTH_START:
      return {
        ...state,
        isLoadingBranches: true,
      };
    case GET_BRANCHES_FOR_AUTH_FAIL:
      return {
        ...state,
        isLoadingBranches: false,
      };

    case GET_PARTNERS_FOR_AUTH_START:
      return {
        ...state,
        isLoadingPartners: true,
      };

    case GET_PARTNERS_FOR_AUTH_FAIL:
      return {
        ...state,
        isLoadingPartners: false,
      };

    case OPEN_WINDOW_BLOCK_UNITED_FRONT:
      return {
        ...state,
        isOpenBlockUnitedFront: true,
      };

    case SET_UNITED_FRONTEND_PARAMS:
      return {
        ...state,
        ...payload,
      };

    case TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION:
      return {
        ...state,
        isExistOtherUnitedFrontSession: payload,
      };

    case CHANGE_RECOVERY_LOGIN_MODAL:
      return {
        ...state,
        isShowLoginRecoveryModal: payload,
      };

    case LOGIN_RECOVERY.start:
      return {
        ...state,
        isLoadingSendingMessageRecoveryAccess: true,
      };

    case LOGIN_RECOVERY.end:
      return {
        ...state,
        isLoadingSendingMessageRecoveryAccess: false,
      };

    default:
      return state;
  }
};
