import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';
import { LANG_DICTIONARY } from 'consts';
import { SUPPORT_MAIL } from 'config';
import { setStatusAmountLimit } from 'redux/rootActions';
import { connect } from 'react-redux';
import './styles.scss';

const b = block('limit-amount-popup');

const {
  ATTENTION,
  MESSAGE_DAILY_LIMIT,
} = LANG_DICTIONARY;

const defaultProps = {
  setStatusModal: () => null,
  isOpen: false,
};
const propTypes = {
  setStatusModal: PropTypes.func,
  isOpen: PropTypes.bool,
};

const LimitAmount = ({ setStatusModal, isOpen }) => (
  <PopupContainer
    isOpen={isOpen}
    close={() => setStatusModal(false)}
    isCloseButton
  >
    <div className={b()}>
      <p className={b('title')}>{`${ATTENTION}!`}</p>
      <div className={b('message')}>
        <p className={b('message-text')}>{MESSAGE_DAILY_LIMIT}</p>
        <p className={b('email')}>{SUPPORT_MAIL}</p>
      </div>
    </div>
  </PopupContainer>
);

const mapStateToProps = ({
  popups: {
    amountLimitPopup,
  },
}) => ({ isOpen: amountLimitPopup });

const mapDispatchToProps = {
  setStatusModal: setStatusAmountLimit,
};

LimitAmount.propTypes = propTypes;
LimitAmount.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(LimitAmount);
