import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setStageProduct,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { InsuredDataV2 } from 'containers';
import { addressCheckingPayloadNszSelector, productIdNszSelector } from 'redux/rootSelectors';

const defaultProps = {};

const propTypes = {
  setStage: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
};

class NszInsured extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      setStage,
      ...rest
    } = this.props;

    return (
      <InsuredDataV2
        setStage={setStage}
        beneficiariesDataUrl={ROUTES.beneficiariesNsz}
        insurerDataUrl={ROUTES.nszInsurerData}
        {...rest}
      />
    );
  }
}

NszInsured.propTypes = propTypes;
NszInsured.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  addressCheckingPayload: addressCheckingPayloadNszSelector(state),
  productId: productIdNszSelector(state),
});

const mapDispatchToProps = {
  setStage: setStageProduct,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(NszInsured));
