import React from 'react';
import {
  ExecutionData,
  InsurerData,
  PreliminaryCalculation,
  ConstructorIsz,
  ConstructorIszCreating,
  CalculatedPersonalBaskets,
  NszCalculation,
  IszBeneficiaryControl,
  NszBeneficiaryControl,
  NszInsurerData,
  NszInsuredData,
  IszExecutionData,
  NszExecutionData,
  KszParameters,
  KszInsurerData,
  KszInsuredData,
  KszBeneficiaryControl,
  BasketCreating,
} from 'containers';
import {
  BREADCRUMBS,
  PAGE_TITLES,
  ROUTES,
  INS_LINE_TYPES,
} from 'consts';
import { CustomRoute } from 'components';
import KszExecutionData from '../containers/_contractExecution/KszExecutionData';

const {
  KSZ,
  NSZ,
  MAIN,
  SUB_BREADCRUMBS,
  NSZ_SUB_BREADCRUMBS,
  KSZ_SUB_BREADCRUMBS,
} = BREADCRUMBS;
const {
  preliminaryCalculation,
  insurer,
  insured,
  beneficiaries,
  executionData,
} = PAGE_TITLES;
const {
  preliminaryCalculation: preliminaryCalculationUrl,
  insurerData: insurerDataUrl,
  insuredData: insuredDataUrl,
  beneficiaries: beneficiaryDataUrl,
  execution: executionUrl,
  printForm: printFormUrl,
  constructorIsz: constructorIszUrl,
  constructorIszCreating: constructorIszCreatingUrl,
  constructorIszPreparedBaskets: constructorIszPreparedBasketsUrl,
  nszCalculation: nszCalculationUrl,
  nszInsuredData: nszInsuredDataUrl,
  nszInsurerData: nszInsurerDataUrl,
  beneficiariesNsz: beneficiariesNszUrl,
  executionNsz: executionNszUrl,
  constructorIszEditing: constructorIszEditingUrl,

  kszParameters: kszParametersUrl,
  kszInsurerData: kszInsurerDataUrl,
  kszInsuredData: kszInsuredDataUrl,
  kszBeneficiaries: kszBeneficiariesUrl,
  kszExecution: kszExecutionUrl,
} = ROUTES;

const pathes = [
  {
    path: `${kszParametersUrl}/:id`,
    component: KszParameters,
    title: preliminaryCalculation,
    activeBreadcrumb: '0',
    breadcrumbs: KSZ,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.KSZ,
    },
  },
  {
    path: `${kszInsurerDataUrl}/:id`,
    title: insurer,
    component: KszInsurerData,
    activeBreadcrumb: '1',
    breadcrumbs: KSZ,
    subBreadcrumbs: KSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '0',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.KSZ,
    },
  },
  {
    path: `${kszInsuredDataUrl}/:id`,
    title: insured,
    component: KszInsuredData,
    activeBreadcrumb: '1',
    breadcrumbs: KSZ,
    subBreadcrumbs: KSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '1',
    additionalParams: {
      isForm: true,
      isInsured: true,
      type: INS_LINE_TYPES.KSZ,
    },
  },
  {
    path: `${kszBeneficiariesUrl}/:id`,
    title: beneficiaries,
    component: KszBeneficiaryControl,
    activeBreadcrumb: '1',
    breadcrumbs: KSZ,
    subBreadcrumbs: KSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '2',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.KSZ,
    },
  },
  {
    path: `${kszExecutionUrl}/:id`,
    title: executionData,
    component: KszExecutionData,
    activeBreadcrumb: '2',
    breadcrumbs: KSZ,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.KSZ,
    },
  },
  {
    path: `${nszCalculationUrl}/:id`,
    component: NszCalculation,
    title: preliminaryCalculation,
    activeBreadcrumb: '0',
    breadcrumbs: NSZ,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.NSZ,
    },
  },
  {
    path: `${nszInsurerDataUrl}/:id`,
    title: insurer,
    component: NszInsurerData,
    activeBreadcrumb: '1',
    breadcrumbs: NSZ,
    subBreadcrumbs: NSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '0',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.NSZ,
    },
  },
  {
    path: `${nszInsuredDataUrl}/:id`,
    title: insured,
    component: NszInsuredData,
    activeBreadcrumb: '1',
    breadcrumbs: NSZ,
    subBreadcrumbs: NSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '1',
    additionalParams: {
      isForm: true,
      isInsured: true,
      type: INS_LINE_TYPES.NSZ,
    },
  },
  {
    path: `${preliminaryCalculationUrl}/:id`,
    title: preliminaryCalculation,
    component: PreliminaryCalculation,
    activeBreadcrumb: '0',
    breadcrumbs: MAIN,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.ISZ,
    },
  },
  {
    path: `${insurerDataUrl}/:id`,
    title: insurer,
    component: InsurerData,
    activeBreadcrumb: '1',
    breadcrumbs: MAIN,
    subBreadcrumbs: SUB_BREADCRUMBS,
    activeSubBreadcrumb: '0',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.ISZ,
    },
  },
  {
    path: `${insuredDataUrl}/:id`,
    title: insured,
    component: InsurerData,
    activeBreadcrumb: '1',
    breadcrumbs: MAIN,
    subBreadcrumbs: SUB_BREADCRUMBS,
    activeSubBreadcrumb: '1',
    additionalParams: {
      isForm: true,
      isInsured: true,
      type: INS_LINE_TYPES.ISZ,
    },
  },
  {
    path: `${beneficiaryDataUrl}/:id`,
    title: beneficiaries,
    component: IszBeneficiaryControl,
    activeBreadcrumb: '1',
    breadcrumbs: MAIN,
    subBreadcrumbs: SUB_BREADCRUMBS,
    activeSubBreadcrumb: '2',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.ISZ,
    },
  },
  {
    path: `${beneficiariesNszUrl}/:id`,
    title: beneficiaries,
    component: NszBeneficiaryControl,
    activeBreadcrumb: '1',
    breadcrumbs: NSZ,
    subBreadcrumbs: NSZ_SUB_BREADCRUMBS,
    activeSubBreadcrumb: '2',
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.NSZ,
    },
  },
  {
    path: `${executionUrl}/:id`,
    title: executionData,
    component: IszExecutionData,
    activeBreadcrumb: '2',
    breadcrumbs: MAIN,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.ISZ,
    },
  },
  {
    path: `${executionNszUrl}/:id`,
    title: executionData,
    component: NszExecutionData,
    activeBreadcrumb: '2',
    breadcrumbs: NSZ,
    additionalParams: {
      isForm: true,
      type: INS_LINE_TYPES.NSZ,
    },
  },
  {
    path: `${printFormUrl}/:id`,
    title: executionData,
    component: ExecutionData,
    additionalParams: {
      isForm: true,
      printForm: true,
    },
  },
  {
    path: constructorIszUrl(),
    title: preliminaryCalculation,
    component: ConstructorIsz,
  },
  {
    path: constructorIszCreatingUrl(),
    title: preliminaryCalculation,
    component: BasketCreating,
  },
  {
    path: constructorIszPreparedBasketsUrl(),
    title: preliminaryCalculation,
    component: CalculatedPersonalBaskets,
  },
  {
    path: `${constructorIszEditingUrl}/:id/:basketId`,
    title: preliminaryCalculation,
    component: ConstructorIszCreating,
  },
];

export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
