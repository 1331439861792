import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('underwriting-contract-col');

const propTypes = {
  firstRow: PropTypes.string,
  secondRow: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
  className: PropTypes.string,
};

const defaultProps = {
  firstRow: '',
  secondRow: '',
  className: '',
};

const ProductColumn = ({
  firstRow,
  secondRow,
  className,
}) => (
  <div className={`${b()} ${className}`}>
    <p className={b('header')}>{firstRow}</p>
    <div className={b('text')}>{secondRow}</div>
  </div>
);

ProductColumn.defaultProps = defaultProps;
ProductColumn.propTypes = propTypes;
export default ProductColumn;
