const CHECK_PODFT = 'check_podft';
const AUTH_SIGNOUT = 'auth_signout';
const AUTH_CHANGE_TEMP_PASS = 'change_temp_pass';
const AUTH_CHECK_USER = 'get_current_user';
const FIAS_ADDRESS = 'get_fias_address';
const FIAS_BANK = 'get_fias_bank';
const FORM_PRODUCT = 'get_product_list';
const FORM_PRODUCT_SELECT = 'product_selection';
const FORM_PRODUCT_FORM = 'get_product_parameters';
const FORM_CHECK_SUM_INSURED = 'check_sum_insured';
const FORM_GENDER_LIST = 'get_references';
const FORM_BENEFICIARY_LIST = 'get_beneficiary_list';
const FORM_RELATIONSHIP_LIST = 'get_references';
const FORM_SAVE_DATA_CONTRACT = 'save_contract';
const FORM_GET_DATA_CONTRACT = 'get_data_contract';
const FORM_DOWNLOAD_CONTRACT = 'download_contract';
const FORM_UPDATE_STATUS_CONTRACT = 'update_status_contract';
const FORM_UPLOAD_DOCUMENT_SCAN = 'upload_document_scan';
const FORM_SAVE_DOCUMENT_SCAN = 'save_document_scan';
const SEARCH_PRODUCT = 'search_by_product';
const SEARCH_STATUS = 'search_by_status';
const SEARCH_BRANCH = 'search_by_branch';
const SEARCH_PARTNER = 'search_by_partner';
const SEARCH_LIST = 'get_search_list';
const SEARCH_EXTENDED = 'get_extended_contract';
const SEARCH_DOWNLOAD = 'download_search_document';
const IMPORT_UPLOAD_REFERENCIES = 'import_upload_referencies';
const IMPORT_DOWNLOAD_TEMPLATE = 'import_download_template';
const IMPORT_SCHEDULT = 'import_schedule';
const IMPORT_CANCELED = 'import_canceled';
const IMPORT_JOBS = 'import_jobs';
const EXPORT_1C = 'export_1c';
const LOG_PAGE_LOGGING = 'page_logging';
const DOCUMENT_CHECKING_GET_DOCUMENT = 'get_document_for_checking';
const DOCUMENT_CHECKING_GET_BRANCH_LIST = 'get_document_check_by_branch';
const DOCUMENT_CHECKING_GET_USER_LIST = 'get_document_check_by_user';
const DOCUMENT_CHECKING_GET_CHECKING_USER_LIST = 'get_document_check_by_checking_user';
const DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING = 'search_document_list_for_checking';
const DOCUMENT_CHECKING_GET_DOCUMENT_COUNT = 'get_document_count_for_checking';
const DOCUMENT_CHECKING_GET_ERROR_LIST = 'get_document_check_error_list';
const DOCUMENT_CHECKING_SAVE_DOCUMENT = 'save_document_after_checking';
const DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST = 'search_document_list';
const DOCUMENT_CHECKING_GET_ALL_BRANCH_LIST = 'get_all_branch_list';
const DOCUMENT_CHECKING_CHECK_STATUS_LIST = 'get_check_status_list';
const DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT = 'unlock_all_document';
const DOCUMENT_CHECKING_UNLOCK_DOCUMENT = 'unlock_document';
const UPDATE_DOCUMENT_SCAN = 'update_document_scan';
const GETTING_BRANCHES_FOR_AUTH = 'getting_branches_for_auth';
const GETTING_PARTNERS_FOR_AUTH = 'getting_partners_for_auth';
const SET_AFFILIATION = 'set_affiliation';
const GET_EVENT_COUNT = 'get_count_events';
const GET_SCANS_ERRORS_EVENTS = 'get_events_scan_with_errors';
const GET_EXTENDED_SCANS_ERRORS_EVENTS = 'get_extended_events_scan_with_errors';
const GET_PARTNERS_DATA_FOR_ADMIN_INTERFACE = 'get_partners_data_for_admin_interface';
const CREATE_NEW_PARTNER = 'create_partner';
const UPDATE_PARTNER = 'update_partner';
const GET_PARTNER = 'get_partner';
const GET_SIGNED_CONTRACT_FILE = 'get_signed_contract_file';
const GET_BRANCHES_DATA_FOR_ADMIN_INTERFACE = 'get_branches_data_for_admin_interface';
const GET_BRANCH = 'get_branch';
const CREATE_NEW_BRANCH = 'create_branch';
const UPDATE_BRANCH = 'update_branch';
const GET_REPORT = 'get_report';
const CREATE_NEW_REPORT = 'create_report';
const UPDATE_REPORT = 'update_user';
const GET_REPORT_DATA_FOR_ADMIN_INTERFACE = 'get_report_data_for_admin_interface';
const GET_USER = 'get_user';
const CREATE_NEW_USER = 'create_user';
const UPDATE_USER = 'update_user';
const GET_USERS_DATA_FOR_ADMIN_INTERFACE = 'get_users_data_for_admin_interface';
const GET_REPORTS_DATA_FOR_ADMIN_INTERFACE = 'get_reports_list';
const GET_CONTRACT_STATUSES = 'get_cts_statuses_data';
const GET_PRODUCTS = 'get_distribution_data';
const GET_SCHEDULER = 'get_scheduler_stats';
const GET_REFERENCES = 'get_reference';
const GET_PUBLIC_API_LIST = 'get_public_api_list';
const CREATE_NEW_PUBLIC_API = 'add_item_to_public_api_list';
const UPDATE_PUBLIC_API = 'update_item_to_public_api_list';
const UPLOAD_TEMPLATE = 'upload_template';
const SHOW_SEARCH_SALES = 'show_search_sales';
const SHOW_CONTRACT_PRINT_DOC = 'show_contract_print_doc';
const SHOW_INSURER = 'show_insurer';
const SHOW_INSURER_CONTRACT_INFO = 'show_insurer_contract_info';
const SHOW_CONTRACT_CLIENTS_INFO = 'show_contract_clients_info';
const SHOW_PRELIMINARY_CALCULATION_NEXT = 'show_preliminary_calculation_next';

export default {
  CREATE_NEW_PUBLIC_API,
  UPDATE_PUBLIC_API,
  GET_PUBLIC_API_LIST,
  GET_REFERENCES,
  GET_SCHEDULER,
  GET_CONTRACT_STATUSES,
  GET_PRODUCTS,
  UPDATE_REPORT,
  CREATE_NEW_REPORT,
  GET_REPORT,
  GET_USER,
  GET_REPORTS_DATA_FOR_ADMIN_INTERFACE,
  GET_REPORT_DATA_FOR_ADMIN_INTERFACE,
  CREATE_NEW_USER,
  UPDATE_USER,
  GET_USERS_DATA_FOR_ADMIN_INTERFACE,
  GET_SIGNED_CONTRACT_FILE,
  CREATE_NEW_BRANCH,
  UPDATE_BRANCH,
  GET_BRANCHES_DATA_FOR_ADMIN_INTERFACE,
  GET_BRANCH,
  GET_PARTNERS_DATA_FOR_ADMIN_INTERFACE,
  CREATE_NEW_PARTNER,
  UPDATE_PARTNER,
  GET_PARTNER,
  GET_EVENT_COUNT,
  GET_EXTENDED_SCANS_ERRORS_EVENTS,
  GET_SCANS_ERRORS_EVENTS,
  AUTH_CHANGE_TEMP_PASS,
  AUTH_CHECK_USER,
  AUTH_SIGNOUT,
  CHECK_PODFT,
  DOCUMENT_CHECKING_CHECK_STATUS_LIST,
  DOCUMENT_CHECKING_GET_ALL_BRANCH_LIST,
  DOCUMENT_CHECKING_GET_BRANCH_LIST,
  DOCUMENT_CHECKING_GET_CHECKING_USER_LIST,
  DOCUMENT_CHECKING_GET_DOCUMENT,
  DOCUMENT_CHECKING_GET_DOCUMENT_COUNT,
  DOCUMENT_CHECKING_GET_ERROR_LIST,
  DOCUMENT_CHECKING_GET_USER_LIST,
  DOCUMENT_CHECKING_SAVE_DOCUMENT,
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST,
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING,
  DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT,
  DOCUMENT_CHECKING_UNLOCK_DOCUMENT,
  EXPORT_1C,
  FIAS_ADDRESS,
  FIAS_BANK,
  FORM_BENEFICIARY_LIST,
  FORM_CHECK_SUM_INSURED,
  FORM_DOWNLOAD_CONTRACT,
  FORM_GENDER_LIST,
  FORM_GET_DATA_CONTRACT,
  FORM_PRODUCT,
  FORM_PRODUCT_FORM,
  FORM_PRODUCT_SELECT,
  FORM_RELATIONSHIP_LIST,
  FORM_SAVE_DATA_CONTRACT,
  FORM_SAVE_DOCUMENT_SCAN,
  FORM_UPDATE_STATUS_CONTRACT,
  FORM_UPLOAD_DOCUMENT_SCAN,
  GETTING_BRANCHES_FOR_AUTH,
  GETTING_PARTNERS_FOR_AUTH,
  IMPORT_CANCELED,
  IMPORT_DOWNLOAD_TEMPLATE,
  IMPORT_JOBS,
  IMPORT_SCHEDULT,
  IMPORT_UPLOAD_REFERENCIES,
  LOG_PAGE_LOGGING,
  SEARCH_BRANCH,
  SEARCH_DOWNLOAD,
  SEARCH_EXTENDED,
  SEARCH_LIST,
  SEARCH_PARTNER,
  SEARCH_PRODUCT,
  SEARCH_STATUS,
  SET_AFFILIATION,
  UPDATE_DOCUMENT_SCAN,
  UPLOAD_TEMPLATE,
  SHOW_SEARCH_SALES,
  SHOW_CONTRACT_PRINT_DOC,
  SHOW_INSURER,
  SHOW_INSURER_CONTRACT_INFO,
  SHOW_CONTRACT_CLIENTS_INFO,
  SHOW_PRELIMINARY_CALCULATION_NEXT,
};
