import React from 'react';
import { Row, Col } from 'reactstrap';
import {
  RangeDatePicker,
} from 'components';
import {
  Input,
  Select,
} from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import PropTypes from 'prop-types';
import {
  getStatus,
  getPartners,
  getBranches,
  numberOrStringTypes,
} from 'helpers';

const propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object),
  handleChange: PropTypes.func,
  checkUserFio: PropTypes.string,
  dateCheckBeg: PropTypes.string,
  dateCheckEnd: PropTypes.string,
  userFio: PropTypes.string,
  ctsNumOrFio: PropTypes.string,
  filterPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  ...numberOrStringTypes(
    'productId',
    'statusId',
    'branchId',
    'partnerId',
    'contractNumber',
    'ammount',
    'statusChangeId',
  ),
};

const defaultProps = {
  statusList: [],
  handleChange: () => null,
  ctsNumOrFio: '',
  checkUserFio: '',
  dateCheckBeg: '',
  dateCheckEnd: '',
  userFio: '',
};

const b = block('document-check-status-form-search-block');
const {
  STATUS_CHECK,
  CONTRACT_NUMBER_OR_FIO,
  CHECKER,
  END_DATE,
  CHECK_DATE,
  STATUS_DOCUMENT,
  USER,
  PARTNER,
  SUBDIVISION,
} = LANG_DICTIONARY;

const SearchInputs = (props) => {
  const {
    handleChange,
    ctsNumOrFio,
    checkUserFio,
    dateCheckBeg,
    dateCheckEnd,
    statusId,
    statusList,
    userFio,
    partnerId,
    branchId,
    idPartner,
    filterPartners,
    branches,
    statusChangeId,
    handleChangeDate,
    checkingStatus,
  } = props;

  return (
    <div className={b('search-inputs')}>
      <Row>
        <Col md="3" sm="6">
          <div className={b('input-wrap')}>
            <Input
              placeholder={CONTRACT_NUMBER_OR_FIO}
              onChange={handleChange}
              value={ctsNumOrFio}
              name="ctsNumOrFio"
              type="text"
            />
          </div>
        </Col>
        <Col md="3" sm="6">
          <div className={b('input-wrap')}>
            <RangeDatePicker
              values={{
                dateCheckBeg,
                dateCheckEnd,
              }}
              labelStartDate={CHECK_DATE}
              labelEndDate={END_DATE}
              onChange={handleChangeDate}
              startDateName="dateCheckBeg"
              endDateName="dateCheckEnd"
            />
          </div>
        </Col>
        <Col md="2" sm="6">
          <div className={b('input-wrap')}>
            <Select
              placeholder={STATUS_CHECK}
              options={checkingStatus}
              name="statusChangeId"
              value={statusChangeId}
              onChange={handleChange}
            />
          </div>
        </Col>
        <Col md="4" sm="6">
          <Input
            placeholder={CHECKER}
            onChange={handleChange}
            value={checkUserFio}
            name="checkUserFio"
            type="text"
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="6">
          <div className={b('input-wrap')}>
            <Select
              placeholder={STATUS_DOCUMENT}
              options={getStatus(statusList)}
              name="statusId"
              value={statusId}
              onChange={handleChange}
            />
          </div>
        </Col>
        {/* {searchByUser && ( */}
        <Col md="3" sm="6">
          <div className={b('input-wrap')}>
            <Input
              placeholder={`${USER}:`}
              onChange={handleChange}
              value={userFio}
              name="userFio"
              type="text"
            />
          </div>
        </Col>
        {/* )} */}
        {/* {searchByPartner && ( */}
        <Col md="2" sm="4">
          <div className={b('input-wrap')}>
            <Select
              placeholder={`${PARTNER}:`}
              options={getPartners(filterPartners)}
              value={partnerId || partnerId === null ? partnerId : idPartner}
              name="partnerId"
              onChange={handleChange}
            />
          </div>
        </Col>
        {/* )} */}
        {/* {searchByBranch && ( */}
        <Col md="4" sm="8">
          <Select
            placeholder={`${SUBDIVISION}:`}
            options={getBranches(branches)}
            name="branchId"
            value={branchId}
            onChange={handleChange}
          />
        </Col>
        {/* )} */}
      </Row>
    </div>
  );
};

SearchInputs.defaultProps = defaultProps;
SearchInputs.propTypes = propTypes;
export default SearchInputs;
