import {
  SET_UNREAD_INBOX_COUNT,
  GET_UNREAD_INBOX_MESSAGES_COUNT,
  GET_INBOX_MESSAGES,
  DOWNLOAD_INBOX_MESSAGE_FILES,
  MARK_AS_READ_INBOX_MESSAGE,
  SET_SEARCH_VALUES,
  GET_SENT_MESSAGES,
  GET_RECIPIENT_PARTNERS,
  GET_RECIPIENTS,
  CREATE_NEW_MESSAGE,
} from '../types';

const setUnreadInboxCount = (count) => ({
  type: SET_UNREAD_INBOX_COUNT,
  payload: count,
});

const getInboxUnreadMessagesCount = () => ({
  type: GET_UNREAD_INBOX_MESSAGES_COUNT.request,
});

const getInboxMessages = (payload, query) => ({
  type: GET_INBOX_MESSAGES.request,
  payload: { currentPage: payload, query },
});

const getSentMessages = (payload, query) => ({
  type: GET_SENT_MESSAGES.request,
  payload: { currentPage: payload, query },
});

const downloadInboxMessageFiles = (id) => ({
  type: DOWNLOAD_INBOX_MESSAGE_FILES.request,
  payload: { id },
});

const markAsReadInboxMessage = (id) => ({
  type: MARK_AS_READ_INBOX_MESSAGE.request,
  payload: { id },
});

const setInboxMessagesSearchValues = (name, value) => ({
  type: SET_SEARCH_VALUES,
  payload: { name, value },
});

const getRecipientPartners = () => ({
  type: GET_RECIPIENT_PARTNERS.request,
});

const getRecipients = (recipientPartnerId) => ({
  type: GET_RECIPIENTS.request,
  payload: { recipientPartnerId },
});

const createNewMessage = (formData) => ({
  type: CREATE_NEW_MESSAGE.request,
  payload: { formData },
});

export {
  setUnreadInboxCount,
  getInboxUnreadMessagesCount,
  getInboxMessages,
  downloadInboxMessageFiles,
  markAsReadInboxMessage,
  setInboxMessagesSearchValues,
  getSentMessages,
  getRecipientPartners,
  getRecipients,
  createNewMessage,
};
