import {
  put,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import {
  apiCall,
  getError,
  getTerms,
  premiumFreqRebuild,
  productCalcTypesRebuild,
  currencySelector,
  getGenders,
  simpleDate,
  apiDownload,
} from 'helpers';
import { API } from 'consts';
import moment from 'moment';
import { DOWNLOAD_PRINT_PAYMENT_NAME } from 'config';
import _ from 'lodash';
import {
  GET_PRODUCT_INFO,
  GET_GENDER_LIST,
  GET_ADDITIONAL_RISKS,
  GET_PACKAGE_RISKS,
  COUNT_SUM_NSZ,
  CHECK_ACCOUNT_NUMBERS,
  PRINT_PAYMENT,
} from '../types';

const throttleCountSumMs = 2000;
const throttleDownloadPayment = 2000;

function* checkAccountNumbers({ payload }) {
  try {
    yield put({ type: CHECK_ACCOUNT_NUMBERS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CHECK_ACCOUNT_NUMBERS,
      query: {
        num: payload,
      },
    });
    yield put({ type: CHECK_ACCOUNT_NUMBERS.success, payload: data });

    return data;
  } catch (e) {
    yield put({ type: CHECK_ACCOUNT_NUMBERS.fail, payload: getError(e) });

    return false;
  }
}

function* countSumNsz({ payload }) {
  try {
    yield put({ type: COUNT_SUM_NSZ.start });
    const { data } = yield apiCall({
      type: 'POST',
      body: {
        ...payload,
        id: undefined,
        insuredBirthDate: simpleDate(payload.insuredBirthDate),
        childInsuredBirthDate: payload.childInsuredBirthDate
          ? simpleDate(payload.childInsuredBirthDate) : undefined,
      },
      url: API.COUNT_SUM_NSZ_CALCULATOR(payload.id),
    });
    yield put({ type: COUNT_SUM_NSZ.success, payload: data });
  } catch (e) {
    yield put({ type: COUNT_SUM_NSZ.fail, payload: getError(e) });
  }
}

function* getAdditionalRisks({ payload }) {
  try {
    yield put({ type: GET_ADDITIONAL_RISKS.start });
    const {
      id,
      insuredBirthDate,
      termId,
      scenario,
      childInsuredBirthDate,
    } = payload;
    const { data } = yield apiCall({
      type: 'GET',
      query: {
        insuredBirthDate,
        termId,
        scenario,
        childInsuredBirthDate,
      },
      url: API.GET_ADDITIONAL_RISKS(id),
    });
    const rebuilData = data.map((item, i) => ({
      ...item,
      checked: true,
      name: `additionalRisk${i}`,
      value: item.maxWeight && item.maxWeight,
    }));
    yield put({ type: GET_ADDITIONAL_RISKS.success, payload: rebuilData });
  } catch (e) {
    yield put({ type: GET_ADDITIONAL_RISKS.fail, payload: getError(e) });
  }
}

function* getPackageRisks({ payload }) {
  try {
    yield put({ type: GET_PACKAGE_RISKS.start });
    const {
      id,
      insuredBirthDate,
      termId,
      scenario,
      childInsuredBirthDate,
    } = payload;
    const { data } = yield apiCall({
      type: 'GET',
      query: {
        insuredBirthDate,
        termId,
        scenario,
        childInsuredBirthDate,
      },
      url: API.GET_PACKAGE_RISKS(id),
    });
    const rebuilData = data.map((item) => ({
      ...item,
      checked: item.isMandatory,
      isOpen: false,
    }));
    yield put({ type: GET_PACKAGE_RISKS.success, payload: rebuilData });
  } catch (e) {
    yield put({ type: GET_PACKAGE_RISKS.fail, payload: getError(e) });
  }
}

function* getGenderList() {
  try {
    yield put({ type: GET_GENDER_LIST.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_GENDERS,
    });
    const rebuildData = getGenders(data);
    yield put({ type: GET_GENDER_LIST.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_GENDER_LIST.fail, payload: getError(e) });
  }
}

function* downloadPrintPayment({
  payload: {
    insLineNszCalcId,
    insProductId,
  },
}) {
  try {
    yield put({ type: PRINT_PAYMENT.start });
    yield apiDownload({
      type: 'GET',
      url: API.DOWNLOAD_PRINT_PAYMENT(insProductId, insLineNszCalcId),
    }, `print_payment_${insProductId}_${moment().format(DOWNLOAD_PRINT_PAYMENT_NAME)}.pdf`);
  } catch (e) {
    yield put({ type: PRINT_PAYMENT.fail, payload: getError(e) });
  }
}

function* getProductInfo({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_INFO.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_PRODUCT_FORM,
      body: {
        id: payload,
      },
    });

    const TMin = _.get(_.minBy(data.productTerms, 'value'), 'value', null);
    const TMax = _.get(_.maxBy(data.productTerms, 'value'), 'value', null);

    const rebuildData = {
      ...data,
      premiumFreq: premiumFreqRebuild(data.premiumFreq),
      productTerms: getTerms(_.sortBy(data.productTerms, 'value')),
      productCalcTypes: productCalcTypesRebuild(data.productCalcTypes),
      refCurrencies: currencySelector(data.refCurrencies),
      refCurrenciesFull: data.refCurrencies,
      insuredAvailableAgesByRisks: {
        ...data.insuredAvailableAgesByRisks,
        TMin,
        TMax,
      },
      childInsuredAvailableAgesByRisks: {
        ...data.childInsuredAvailableAgesByRisks,
        TMin,
        TMax,
        B: data.childInsuredMaxAgeByRestrictions && data.childInsuredMaxAgeByRestrictions.B,
      },
    };

    yield put({ type: GET_PRODUCT_INFO.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_PRODUCT_INFO.fail, payload: getError(e) });
  }
}

function* nszProductSaga() {
  yield throttle(throttleDownloadPayment, PRINT_PAYMENT.request, downloadPrintPayment);
  yield throttle(throttleCountSumMs, COUNT_SUM_NSZ.request, countSumNsz);
  yield takeLatest(GET_ADDITIONAL_RISKS.request, getAdditionalRisks);
  yield takeLatest(GET_PACKAGE_RISKS.request, getPackageRisks);
  yield takeLatest(GET_PRODUCT_INFO.request, getProductInfo);
  yield takeLatest(GET_GENDER_LIST.request, getGenderList);
  yield takeLatest(CHECK_ACCOUNT_NUMBERS.request, checkAccountNumbers);
}

export default nszProductSaga;
