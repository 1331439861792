import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  getContractInfoUnderwriting,
  setCurrentScanIndexUnderwriting,
  saveCheckedContract,
  unlockDocumentsUnderwriting,
  setInitStateUnderwriting,
  setBlockRedirect,
  setStatusRedirectModal,
  getEventsCount,
} from 'redux/rootActions';
import { block } from 'bem-cn';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES, API } from 'consts';
import { checkFinished, formatDataScans, directRequest } from 'helpers';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import Editor from './editor';
import './styles.scss';

const defaultProps = {
  listForChecking: [],
  getContract: () => null,
  currentContractIndex: 0,
  location: {},
  contractLoaded: false,
  contractData: {},
  beneficiaryData: [],
  insurerData: {},
  insuredData: [],
  saveContract: () => null,
  setCurrentScanIndex: () => null,
  loading: false,
  history: {},
  setInitState: () => null,
  notificationBlockRedirect: false,
  setStatusModal: () => null,
  setActionBeforeRedirect: () => null,
  confirmRedirect: () => null,
  getEvents: () => null,
};

const propTypes = {
  confirmRedirect: PropTypes.func,
  listForChecking: PropTypes.arrayOf(PropTypes.number),
  getContract: PropTypes.func,
  currentContractIndex: PropTypes.number,
  location: PropTypes.object,
  contractLoaded: PropTypes.bool,
  contractData: PropTypes.object,
  beneficiaryData: PropTypes.arrayOf(PropTypes.object),
  insurerData: PropTypes.object,
  insuredData: PropTypes.arrayOf(PropTypes.object),
  saveContract: PropTypes.func,
  setCurrentScanIndex: PropTypes.func,
  loading: PropTypes.bool,
  history: PropTypes.object,
  setInitState: PropTypes.func,
  notificationBlockRedirect: PropTypes.bool,
  setStatusModal: PropTypes.func,
  setActionBeforeRedirect: PropTypes.func,
  getEvents: PropTypes.func,
};

const b = block('underwriting-loader');
const { QUESTION_NEXT_CONTRACT } = LANG_DICTIONARY;

class CheckDocumentEditor extends Component {
  constructor(props) {
    super(props);

    this.state = {
      messageClose: '',
      actionClickYes: () => null,
    };
    const {
      location: {
        state: stateRoute,
      },
      history,
    } = props;

    const access = stateRoute && stateRoute.access;
    if (!access) history.push(ROUTES.checkMaster);
  }

  componentDidMount() {
    const {
      listForChecking,
      getContract,
      currentContractIndex,
      location: { state: stateRoute },
      contractLoaded,
      setInitState,
      history,
      setActionBeforeRedirect,
    } = this.props;
    const cash = stateRoute && stateRoute.cash;
    const access = stateRoute && stateRoute.access;
    const cashing = !cash || !contractLoaded;

    setActionBeforeRedirect(this.directRequests);

    if (cashing && access) {
      setInitState();
      const currentId = listForChecking[currentContractIndex];
      getContract(currentId, history);
    }
  }

  directRequests = async () => {
    const {
      listForChecking,
      contractData,
      beneficiaryData,
      insurerData,
      getEvents,
      insuredData,
      setInitState,
      setCurrentScanIndex,
      contractData: { ctsId },
    } = this.props;

    const scans = formatDataScans({
      contractData,
      beneficiaryData,
      insurerData,
      insuredData,
    });

    await directRequest({
      url: API.SAVE_CHECKED_DOCUMENT(ctsId),
      type: 'POST',
      body: scans,
    });

    await directRequest({
      url: API.UNLOCK_DOCUMENT_UNDERWRITING,
      type: 'POST',
      body: listForChecking,
    });
    setCurrentScanIndex(0);
    setInitState();
    getEvents();
  };

  closeWarningPopup = () => this.setState({ messageClose: '' });

  handleSwitch = (type) => () => {
    const {
      contractData,
      beneficiaryData,
      insurerData,
      insuredData,
    } = this.props;
    const isFinishedCheck = checkFinished({
      contractData,
      beneficiaryData,
      insurerData,
      insuredData,
    });

    const actionSuccess = type === 'next'
      ? this.switchNextContract : this.switchPrevContract;
    if (!isFinishedCheck) {
      this.setState({
        messageClose: QUESTION_NEXT_CONTRACT,
        actionClickYes: actionSuccess,
      });
    } else {
      actionSuccess();
    }
  };

  saveContract = () => {
    const {
      contractData,
      beneficiaryData,
      insurerData,
      insuredData,
      saveContract,
      contractData: { ctsId },
    } = this.props;

    const formatData = formatDataScans({
      contractData,
      beneficiaryData,
      insurerData,
      insuredData,
    });

    saveContract(ctsId, formatData);
  };

  handleFinishCheck = () => {
    const { history } = this.props;
    history.push(ROUTES.checkMaster);
  };

  switchNextContract = () => {
    this.closeWarningPopup();
    const {
      getContract,
      listForChecking,
      setCurrentScanIndex,
      currentContractIndex,
    } = this.props;
    this.saveContract();
    const nextValue = currentContractIndex + 1;
    setCurrentScanIndex(nextValue);
    getContract(listForChecking[nextValue]);
  };

  switchPrevContract = () => {
    this.closeWarningPopup();
    const {
      getContract,
      listForChecking,
      setCurrentScanIndex,
      currentContractIndex,
    } = this.props;
    this.saveContract();
    const prevValue = currentContractIndex - 1;
    setCurrentScanIndex(prevValue);
    getContract(listForChecking[prevValue]);
  };

  cancelRedirect = () => {
    const { setStatusModal } = this.props;
    setStatusModal(false);
  };

  confirmRedirect = () => {
    const { confirmRedirect } = this.props;

    confirmRedirect();
  };

  loadingForm = (
    <Dimmer.Dimmable as={Segment} className={b().toString()}>
      <Dimmer active inverted>
        <Loader size="big">{LANG_DICTIONARY.DOWNLOADING}</Loader>
      </Dimmer>
    </Dimmer.Dimmable>);

  render() {
    const {
      loading,
      contractLoaded,
      listForChecking,
      currentContractIndex,
      history,
      notificationBlockRedirect,
    } = this.props;

    const { messageClose, actionClickYes } = this.state;

    return !loading && contractLoaded
      ? (
        <Editor
          listForChecking={listForChecking}
          messageClose={messageClose}
          actionClickYes={actionClickYes}
          actionClickNo={this.closeWarningPopup}
          currentContractIndex={currentContractIndex + 1}
          handleSwitch={this.handleSwitch}
          handleFinishCheck={this.handleFinishCheck}
          cancelRedirect={this.cancelRedirect}
          confirmRedirect={this.confirmRedirect}
          history={history}
          notificationBlockRedirect={notificationBlockRedirect}
        />
      ) : this.loadingForm;
  }
}

const mapStateToProps = ({
  underwriting: {
    loading,
    contractLoaded,
    contractData,
    beneficiaryData,
    insurerData,
    insuredData,
  },
  underwritingMaster: {
    listForChecking,
    currentContractIndex,
  },
  shared: {
    notificationBlockRedirect,
    lastPageOnBlockRedirect,
  },
}) => ({
  contractData,
  beneficiaryData,
  insurerData,
  insuredData,
  loading,
  listForChecking,
  currentContractIndex,
  contractLoaded,
  notificationBlockRedirect,
  lastPageOnBlockRedirect,
});
const mapDispatchToProps = {
  getContract: getContractInfoUnderwriting,
  setCurrentScanIndex: setCurrentScanIndexUnderwriting,
  saveContract: saveCheckedContract,
  unlockDocuments: unlockDocumentsUnderwriting,
  setInitState: setInitStateUnderwriting,
  setBlockRedirectApp: setBlockRedirect,
  setStatusModal: setStatusRedirectModal,
  getEvents: getEventsCount,
};

CheckDocumentEditor.propTypes = propTypes;
CheckDocumentEditor.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(CheckDocumentEditor));
