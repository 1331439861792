import {
  UNLOCK_ALL_LOCKS_DOCUMENTS_REQUEST,
  NOTIFY_CLIENTS_CALC_BASKET_REQUEST,
} from '../types';

export const unlockAllCheckingDocuments = () => ({
  type: UNLOCK_ALL_LOCKS_DOCUMENTS_REQUEST,
});

export const notifyClientsCalcBasket = () => ({
  type: NOTIFY_CLIENTS_CALC_BASKET_REQUEST,
});
