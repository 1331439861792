import React from 'react';
import { CheckDocumentMaster, CheckDocumentEditor, CheckDocumentList } from 'containers';
import { CustomRoute } from 'components';
import {
  PAGE_TITLES,
  ROUTES,
  BREADCRUMBS,
  LANG_DICTIONARY,
} from 'consts';

const { CHECKING_DOCUMENT, DESCRIPTION_INFO_BLOCK_UNDERWRITING } = LANG_DICTIONARY;
const { DOCUMENT_UNDERWRITING } = BREADCRUMBS;
const { administration } = PAGE_TITLES;
const {
  checkMaster,
  checkMasterEditor,
  checkDocumentList,
} = ROUTES;

const infoBlock = {
  title: CHECKING_DOCUMENT,
  description: DESCRIPTION_INFO_BLOCK_UNDERWRITING,
};

const pathes = [
  {
    path: checkMaster,
    title: administration,
    component: CheckDocumentMaster,
    additionalParams: {
      isForm: true,
    },
    breadcrumbs: DOCUMENT_UNDERWRITING,
    activeBreadcrumb: '0',
    infoBlock,
  },
  {
    path: checkMasterEditor,
    title: administration,
    component: CheckDocumentEditor,
  },
  {
    path: checkDocumentList,
    title: administration,
    breadcrumbs: DOCUMENT_UNDERWRITING,
    component: CheckDocumentList,
  },
];

export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    exact
    key={route.path}
  />
));
