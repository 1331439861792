const SET_DATE = 'mySales/SET_DATE';
const SET_SELECT_DATE = 'mySales/SET_SELECT_DATE';
const SET_EXTRA_SETTINGS = 'mySales/SET_EXTRA_SETTINGS';
const SET_INITIAL_VALUES = 'mySales/SET_INITIAL_VALUES';
const SET_PAGINATION_PAGE = 'mySales/SET_PAGINATION_PAGE';
const GET_EXTEND_SETTINGS = 'mySales/GET_EXTEND_SETTINGS';
const SET_INITIAL_VALUES_REPORT = 'mySales/SET_INITIAL_VALUES_REPORT';
export {
  GET_EXTEND_SETTINGS,
  SET_INITIAL_VALUES,
  SET_DATE,
  SET_SELECT_DATE,
  SET_EXTRA_SETTINGS,
  SET_PAGINATION_PAGE,
  SET_INITIAL_VALUES_REPORT,
};
