import { storeLink } from 'index';
import {
  IMPORT_MANUAL_WEBSOCKET_SUCCESS,
  IMPORT_MANUAL_WEBSOCKET_FAIL,
} from 'redux/transferData/types';
import { getSocket } from 'websocket';
import { IMPORT_1C } from '../types';

const subscribe = () => getSocket().on(IMPORT_1C, (data) => {
  if (data.errors.length) {
    storeLink.dispatch({ type: IMPORT_MANUAL_WEBSOCKET_FAIL, payload: data.errors });

    return null;
  }
  storeLink.dispatch({ type: IMPORT_MANUAL_WEBSOCKET_SUCCESS, payload: data });

  return null;
});

export default {
  subscribe,
  unsubscribe: () => getSocket().removeListener(IMPORT_1C),
};
