import { useCallback, useMemo } from 'react';
import {
  useDispatch,
  useSelector,
  useStore,
} from 'react-redux';
import {
  useParams,
} from 'react-router-dom';
import _ from 'lodash';
import { getReduxGlobalAction } from 'helpers/globalHandlerRedux';
import { createSelector } from 'reselect';
import { getErrorsFromValidationError } from 'helpers';

/*
  Хук для прямой работы формы со стором. Имеет похожий API c библиотекой formik.
*/

const selectExistFormStore = createSelector(
  (state, path) => _.get(state, path),
  (formStore) => Boolean(formStore),
);

export const getReduxFormChangeActionType = (reducer, name) => `${reducer}/CHANGE_FIELD_${name}`;
export const getReduxFormChangeAction = ({
  reducerPath,
  router,
  input,
  formContext,
}) => {
  const [reducer] = reducerPath.split('.');

  return {
    type: getReduxFormChangeActionType(reducer, input.name),
    payload: {
      reducerPath,
      router,
      input,
      formContext,
    },
  };
};

export const useReduxForm = ({
  /* reducerPath - Подчиняется правил задания полей объекта через функции lodash set (_.set) */
  reducerPath,
  /* initState - Для корректной работы должны быть заданы все поля формы начальным значением. */
  initState,
  /* initState - Схема Yup */
  schema,
  /*
     isInitializeIfExistState - Инициализировать начальное состояние формы,
     если значение в строке уже есть. Это перезатрет уже имеющиеся данные при рендере.
  */
  isOverwriteOnStateInit = true,
}) => {
  const dispatch = useDispatch();
  const valuesPath = `${reducerPath}.values`;
  const errorsPath = `${reducerPath}.errors`;
  const touchedPath = `${reducerPath}.touched`;
  const formUtilsPath = `${reducerPath}.formUtils`;
  const match = useParams();
  const store = useStore();

  const {
    valuesEmptyObj,
    errorsEmptyObj,
    touchedEmptyObj,
  } = useMemo(() => ({
    valuesEmptyObj: {},
    errorsEmptyObj: {},
    touchedEmptyObj: {},
  }), []);

  const getFormData = () => {
    const state = store.getState();

    return {
      values: _.get(state, valuesPath, {}),
      errors: _.get(state, errorsPath, {}),
      touched: _.get(state, touchedPath, {}),
    };
  };

  const getNullableInitState = () => Object
    .keys(initState)
    .reduce((acc, key) => ({
      ...acc,
      [key]: null,
    }), {});

  /*
   Селектор для получения демонстрационных ошибок.
   Маппинг ошибки и их видимости в случае флага true в соответствующем поле.
   Используется для показа ошибок в ипнутах, которые уже затронул пользователь.
  */
  const selectVisibleErrors = useCallback(createSelector(
    (state) => _.get(state, errorsPath),
    (state) => _.get(state, touchedPath),
    (errors, touched) => Object.entries(errors || {}).reduce((acc, [key, value]) => {
      const paramTouched = touched || {};
      if (paramTouched[key]) {
        return {
          ...acc,
          [key]: value,
        };
      }

      return acc;
    }, {}),
  ), []);

  const isExistFormStore = useSelector((state) => selectExistFormStore(state, reducerPath));

  /* Задание начального состояния перед монтированием формы */
  useMemo(() => {
    const valuesAction = getReduxGlobalAction(valuesPath, initState);
    const errorsAction = getReduxGlobalAction(errorsPath, {});
    const touchedAction = getReduxGlobalAction(touchedPath, {});

    if (isExistFormStore && !isOverwriteOnStateInit) {
      return null;
    }

    dispatch(valuesAction);
    dispatch(errorsAction);
    dispatch(touchedAction);

    return null;
  }, []);

  /* Задание ошибок формы */
  const setErrors = useCallback((errorsParam) => {
    const action = getReduxGlobalAction(errorsPath, errorsParam);
    dispatch(action);
  }, []);

  /* Валидация формы по схеме Yup */
  const validateValues = useCallback((valuesParam) => {
    try {
      schema({ values: valuesParam }).validateSync(valuesParam, { abortEarly: false });
      setErrors(getNullableInitState());

      return null;
    } catch (error) {
      const parsingError = getErrorsFromValidationError(error);
      setErrors({
        ...getNullableInitState(),
        ...parsingError,
      });

      return error;
    }
  }, [schema]);

  /* Задание флага, отвечающего за событие затрагивания пользователем определенного инпута */
  const setTouched = useCallback((name, value) => {
    const action = getReduxGlobalAction(touchedPath, { [name]: value });
    dispatch(action);
  }, []);

  /*
    Обработчик для onFocus.
    В случае если в инпуте присутствует значение, запускает фукнции setTouched
  */
  const handleFocus = useCallback(({ target: { name, value } }) => {
    if (value) {
      setTouched(name, value);
    }
  }, []);

  /* Обработчик для onBlur */
  const handleBlur = useCallback(({ target: { name } }) => {
    setTouched(name, true);
  }, []);

  /*
    Функция crateBatchActions используется для оптимизации рендера при
    множественном вызове dispatch в сагах.
    Она создаёт хранилище для данных экшенов изменения значений формы.
    Метод add добавляет новые значение инпута (имя, значение).
    Метод exec запускает все изменения одним обновлением стора,
    при этом в цикле запускаются экшены для side-эффектов.
  */
  const crateBatchActions = (payload) => {
    const actionPayload = {};

    return {
      add(name, value) {
        actionPayload[name] = value;

        return this;
      },
      exec: () => {
        const action = getReduxGlobalAction(valuesPath, actionPayload);
        dispatch(action);

        Object.entries(actionPayload).forEach(([name, value]) => {
          const changeAction = getReduxFormChangeAction({
            ...payload,
            input: {
              name,
              value,
            },
          });

          dispatch(changeAction);
        });
      },
    };
  };

  /* Задание флага true для всех ипнутов */
  const setAllTouched = useCallback(() => {
    const allTouched = Object.keys(initState).reduce((acc, key) => ({
      ...acc,
      [key]: true,
    }));

    const action = getReduxGlobalAction(touchedPath, allTouched);
    dispatch(action);
  }, []);

  /* Настройки по умолчанию для handleChange */
  const defaultOptionsHandleChange = {
    isSideEffect: true,
    isTouchedOnChange: true,
  };

  /*
     Обработчик для изменения инпута. Автоматически запускает валидацию всей формы.
     При изменении инпута происходит вызов экшена {reducer}/CHANGE_FIELD_{name}.
     На этот экшен, в сагах, можно повесить слушатель и вызывать side-эффекты.
     Все такие функции в сагах для ясности лучше называть с префикса side.
  */
  const handleChange = useCallback((
    { target: { value, name } },
    { isSideEffect, isTouchedOnChange } = defaultOptionsHandleChange,
  ) => {
    const {
      values,
      touched,
      errors,
    } = getFormData();

    const changeActionPayload = {
      reducerPath,
      router: {
        match,
      },
      input: {
        name,
        value,
      },
      formContext: {
        values,
        errors,
        touched,
        changeFormField: (nameInput, valueInput) => (
          handleChange({ target: { name: nameInput, value: valueInput } })),
        get batchChangeActions() {
          return crateBatchActions(changeActionPayload);
        },
      },
    };

    const changeActionSideEffect = getReduxFormChangeAction(changeActionPayload);
    if (isSideEffect) {
      dispatch(changeActionSideEffect);
    }

    const changeAction = getReduxGlobalAction(valuesPath, { [name]: value });
    dispatch(changeAction);

    if (isTouchedOnChange) {
      setTouched(name, true);
    }

    if (schema) {
      validateValues({
        ...values,
        [name]: value,
      });
    }
  }, [
    match,
    validateValues,
  ]);

  /* Сохранение утилит дря работы с формой в стор */
  useMemo(() => {
    const {
      values,
      errors,
      touched,
    } = getFormData();

    const formUtils = {
      setAllTouched,
      setTouched,
      validateValues,
      changeFormField: (nameInput, valueInput, options) => (
        handleChange({ target: { name: nameInput, value: valueInput } }, options)),
      get batchChangeActions() {
        return crateBatchActions({
          reducerPath,
          router: {
            match,
          },
          formContext: {
            values,
            errors,
            touched,
            get batchChangeActions() {
              return formUtils.batchChangeActions;
            },
          },
        });
      },
    };

    const action = getReduxGlobalAction(formUtilsPath, formUtils);
    dispatch(action);
  }, [
    match,
    validateValues,
  ]);

  const setInitState = useCallback(() => {
    const valuesAction = getReduxGlobalAction(valuesPath, initState);
    dispatch(valuesAction);
  }, []);

  return {
    handleChange,
    handleFocus,
    handleBlur,
    setTouched,
    setAllTouched,
    setErrors,
    validateValues,
    setInitState,
    values: useSelector((state) => _.get(state, valuesPath, valuesEmptyObj)),
    errors: useSelector((state) => _.get(state, errorsPath, errorsEmptyObj)),
    touched: useSelector((state) => _.get(state, touchedPath, touchedEmptyObj)),
    visibleErrors: useSelector(selectVisibleErrors),
  };
};
