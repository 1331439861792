import React, { Component } from 'react';
import { withCustomRouter } from 'HOC';
import { AdministrativeSectionInterface, CreatingOrUpdatingReportForm } from 'components';
import PropTypes from 'prop-types';
import {
  createReport,
  updateReport,
  getReportDataForUpdate,
  clearEditorAdminReportsForm,
  getRolesForReportsForm,
  setRolesForReport,
  getTagsForReport,
  getReportTypesForReportForm,
  getPartnersForReportForm,
  setReportSelectOption,
  setTagsForReport,
  setFilteredTags,
  setReportStatus,
} from 'redux/rootActions';
import { connect } from 'react-redux';

const propTypes = {
  match: PropTypes.object,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  history: PropTypes.object,
  location: PropTypes.object,
  reportsFormData: PropTypes.object,
  createNewReport: PropTypes.func,
  updateReportById: PropTypes.func,
  clearForm: PropTypes.func,
  getReportData: PropTypes.func,
  getRoles: PropTypes.func,
  reportsRoles: PropTypes.arrayOf(PropTypes.object),
  selectedRoles: PropTypes.arrayOf(PropTypes.object),
  setRoles: PropTypes.func,
  getTags: PropTypes.func,
  getReportTypes: PropTypes.func,
  getPartners: PropTypes.func,
  setOptionSelect: PropTypes.func,
  setTags: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.object),
  reportTypes: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  allRoles: PropTypes.arrayOf(PropTypes.object),
  switchCheckBox: PropTypes.func,
};

const defaultProps = {
  partners: [],
  reportTypes: [],
  tags: [],
  setTags: () => null,
  setOptionSelect: () => null,
  getPartners: () => null,
  getReportTypes: () => null,
  setRoles: () => null,
  selectedRoles: [],
  reportsRoles: [],
  getRoles: () => null,
  match: {},
  clearForm: () => null,
  active: '',
  history: {},
  location: {},
  reportsFormData: {},
  createNewReport: () => null,
  updateReportById: () => null,
  getReportData: () => null,
  getTags: () => null,
  allRoles: [],
  switchCheckBox: () => null,
};

class CreatingOrUpdatingReport extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { id } } } = props;
    this.selectPrefix = 'update';
    this.updatedReport = id || null;
    this.handleSubmitSuccess = id ? this.updateReport : this.createReport;
  }

  state = {
    partnerError: false,
    reportTypeError: false,
    reportTags: false,
    rolesError: false,
  }

  componentDidMount() {
    const {
      getReportData,
      getRoles,
      getReportTypes,
      getPartners,
    } = this.props;
    const { location: { state } } = this.props;
    const id = state && state.id;
    if (this.updatedReport) {
      getReportData(this.updatedReport || id);
    }
    getRoles();
    getReportTypes();
    getPartners();
  }

  componentWillUnmount() {
    const { clearForm } = this.props;
    clearForm();
  }

  handleChangeSelect(type) {
    if (type === null) {
      this.setState({
        type: false,
      });
    } else {
      this.setState({
        type: true,
      });
    }
  }

  getBodyRequest = (values) => {
    const {
      isActive,
      partnerId,
      reportType,
      roles: rolesValues,
      tags,
    } = values;
    const roles = rolesValues.map((item) => (item.value || item));

    return {
      isActive,
      partnerId,
      reportTypeId: reportType,
      roles,
      tags,
    };
  };

  createReport = (values) => {
    const { createNewReport, history } = this.props;
    const {
      partnerId,
      reportType,
      tags,
      roles,
    } = values;
    this.setState({ partnerError: Boolean(!partnerId) });
    this.setState({ reportTypeError: Boolean(!reportType) });
    this.setState({ reportTags: Boolean(!tags.length) });
    this.setState({ rolesError: Boolean(!roles.length) });
    let check = true;
    const { state } = this;
    Object.keys(this.state).forEach((item) => {
      if (state[item] === true) {
        check = false;

        return false;
      }

      return true;
    });
    if (check) {
      const body = this.getBodyRequest(values);
      createNewReport(body, history);
    }
  };

  updateReport = (values) => {
    const { updateReportById } = this.props;
    const {
      partnerId,
      reportType,
      tags,
      roles,
    } = values;
    this.setState({ partnerError: Boolean(!partnerId) });
    this.setState({ reportTypeError: Boolean(!reportType) });
    this.setState({ reportTags: Boolean(!tags.length) });
    this.setState({ rolesError: Boolean(!roles.length) });
    let check = true;
    const { state } = this;
    Object.keys(this.state).forEach((item) => {
      if (state[item] === true) {
        check = false;

        return false;
      }

      return true;
    });
    if (check) {
      const body = this.getBodyRequest(values);
      updateReportById(this.updatedReport, body);
    }
  }

  setSelectorOption = (values, option) => (e, { value }) => {
    const { setOptionSelect, getTags } = this.props;
    setOptionSelect(values, option, value);
    if (option === 'partnerId' && value === null) {
      this.setState({
        partnerError: true,
      });
    } else if (option === 'partnerId') {
      this.setState({
        partnerError: false,
      });
    }
    if (option === 'reportType' && value === null) {
      this.setState({
        reportTypeError: true,
      });
    } else if (option === 'reportType') {
      this.setState({
        reportTypeError: false,
      });
      getTags(value);
    }
  }

  setErrorMultiSelect = (type) => {
    this.setState({
      rolesError: type,
    });
  }

  handleSwitchCheckBox = (values) => {
    const { switchCheckBox } = this.props;
    switchCheckBox(values);
  };

  render() {
    const {
      active,
      history,
      reportsFormData,
      reportsRoles,
      selectedRoles,
      setRoles,
      partners,
      reportTypes,
      tags,
      setTags,
      match: { params: { id } },
      allRoles,
      getTags,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <CreatingOrUpdatingReportForm
          history={history}
          getTags={getTags}
          handleSubmitSuccess={this.handleSubmitSuccess}
          isUpdatedForm={Boolean(this.updatedReport)}
          initialValues={reportsFormData}
          reportsRoles={reportsRoles}
          selectedRoles={selectedRoles}
          setRoles={setRoles}
          partners={partners}
          reportTypes={reportTypes}
          setSelectorOption={this.setSelectorOption}
          tags={tags}
          setTags={setTags}
          id={id}
          allRoles={allRoles}
          switchCheckBox={this.handleSwitchCheckBox}
          errors={this.state}
          handleChangeError={this.handleChangeSelect}
          setErrorMultiSelect={this.setErrorMultiSelect}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  reportsAdminInterface,
}) => ({
  ...reportsAdminInterface,
});

const mapDispatchToProps = {
  createNewReport: createReport,
  updateReportById: updateReport,
  getReportData: getReportDataForUpdate,
  clearForm: clearEditorAdminReportsForm,
  getRoles: getRolesForReportsForm,
  setRoles: setRolesForReport,
  getTags: getTagsForReport,
  getReportTypes: getReportTypesForReportForm,
  getPartners: getPartnersForReportForm,
  setOptionSelect: setReportSelectOption,
  setTags: setTagsForReport,
  setNewTags: setFilteredTags,
  switchCheckBox: setReportStatus,
};

CreatingOrUpdatingReport.propTypes = propTypes;
CreatingOrUpdatingReport.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatingOrUpdatingReport),
);
