import { LANG_DICTIONARY } from 'consts';
import {
  SET_DATE,
  SET_SELECT_DATE,
  SET_EXTRA_SETTINGS,
  SET_INITIAL_VALUES,
  SET_PAGINATION_PAGE,
  GET_EXTEND_SETTINGS, SET_INITIAL_VALUES_REPORT,
} from '../types';

const { TODAY } = LANG_DICTIONARY;

const INITIAL_STATE = {
  startDate: new Date(),
  endDate: new Date(),
  dateSelectValue: TODAY,
  productId: '',
  statusId: '',
  branchId: '',
  partnerId: '',
  contractNumber: '',
  ammount: '',
  userFio: '',
  ctsNumOrFio: '',
  checkUserFio: '',
  insurerFio: '',
  paginationPage: 1,
  isOpen: false,
  isNewSearch: true,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_EXTEND_SETTINGS: {
      const { isOpen } = state;

      return {
        ...state,
        isOpen: !isOpen,
      };
    }
    case SET_PAGINATION_PAGE: {
      return {
        ...state,
        paginationPage: payload,
        isNewSearch: false,
      };
    }
    case SET_DATE: {
      const { name, date } = payload;

      return {
        ...state,
        [name]: date,
        isNewSearch: false,
      };
    }

    case SET_INITIAL_VALUES:
      return INITIAL_STATE;

    case SET_INITIAL_VALUES_REPORT:
      return {
        ...state,
        dateCheckBegReport: '',
        dateCheckEndReport: '',
        productIdsReport: [],
        statusIdsReport: [],
      };

    case SET_SELECT_DATE: {
      const { start, end, value } = payload;

      return {
        ...state,
        dateSelectValue: value,
        startDate: start,
        endDate: end,
        isNewSearch: false,
      };
    }
    case SET_EXTRA_SETTINGS: {
      const { name, value } = payload;

      return {
        ...state,
        [name]: value,
        isNewSearch: false,
      };
    }
    default:
      return state;
  }
};
