import { put, takeLatest, all } from 'redux-saga/effects';
import {
  API,
  ROUTES,
  LANG_DICTIONARY,
} from 'consts';
import {
  apiCall,
  getError,
  apiDownload,
} from 'helpers';
import { ITEM_COUNT_CONTEST_RESULT } from 'config';
import { getGlobalHistory } from 'components';
import {
  CHECK_ACTIVE_CONTEST,
  GET_BANNER, GET_CLUSTER,
  GET_CONTESTS_DATA, GET_DOWNLOAD_CONTEST,
  SAVE_CONTEST_IMAGE,
} from '../types';

const { CONTEST_RESULT } = LANG_DICTIONARY;

function* checkActiveContest() {
  try {
    yield put({ type: CHECK_ACTIVE_CONTEST.start });
    const { data: { status } } = yield apiCall({
      type: 'GET',
      url: API.CHECK_ACTIVE_CONTEST,
    });
    yield put({ type: CHECK_ACTIVE_CONTEST.success, payload: status });

    if (window.location.pathname === ROUTES.home && status) {
      yield getBanner();
    }
  } catch (e) {
    getGlobalHistory().push(ROUTES.home);
    yield put({ type: CHECK_ACTIVE_CONTEST.fail, payload: getError(e) });
  }
}

function* getBanner() {
  try {
    yield put({ type: GET_BANNER.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_INVITE_BANNER,
      isBlob: true,
    });

    yield put({
      type: GET_BANNER.success,
      payload: window.URL.createObjectURL(new Blob([data])),
    });
  } catch (e) {
    yield put({ type: GET_BANNER.fail, payload: getError(e) });
  }
}

function* getContestsData() {
  try {
    yield put({ type: GET_CONTESTS_DATA.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTESTS_DATA,
    });
    yield put({ type: GET_CONTESTS_DATA.success, payload: data });

    const gettingContestFunctions = data.map(getContest);
    yield all(gettingContestFunctions);
  } catch (e) {
    yield put({ type: GET_BANNER.fail, payload: getError(e) });
  }
}

function* getContest(contest) {
  const gettingClusterFunctions = contest.clasters.map(
    (cluster) => getCluster({
      payload: {
        compId: contest.compId,
        id: cluster.id,
        limit: ITEM_COUNT_CONTEST_RESULT,
      },
    }),
  );
  yield all([
    ...gettingClusterFunctions,
    getContestImage(contest.compId),
  ]);
}

function* getCluster({ payload: { compId, id, limit } }) {
  try {
    yield put({ type: GET_CLUSTER.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_CONTEST_CLUSTER(compId, id),
      query: {
        offset: 0,
        limit,
      },
    });
    yield put({
      type: GET_CLUSTER.success,
      payload: {
        id,
        data,
      },
    });
  } catch (e) {
    yield put({ type: GET_CLUSTER.fail, payload: getError(e) });
  }
}

function* getContestImage(compId) {
  const { data } = yield apiCall({
    type: 'GET',
    url: API.GET_CONTEST_IMAGE(compId),
    isBlob: true,
  });

  yield put({
    type: SAVE_CONTEST_IMAGE,
    payload: {
      compId,
      url: window.URL.createObjectURL(new Blob([data])),
    },
  });
}

function* getDownloadContest({ payload: { compId, compName } }) {
  const { DOWNLOAD_CONTEST } = API;
  try {
    yield put({ type: GET_DOWNLOAD_CONTEST.start });
    yield apiDownload({
      url: DOWNLOAD_CONTEST(compId),
    }, `${CONTEST_RESULT} '${compName}'.xlsx`);
    yield put({ type: GET_DOWNLOAD_CONTEST.success });
  } catch (e) {
    yield put({ type: GET_DOWNLOAD_CONTEST.fail, error: getError(e) });
  }
}

function* contestsSages() {
  yield takeLatest(GET_CLUSTER.request, getCluster);
  yield takeLatest(CHECK_ACTIVE_CONTEST.request, checkActiveContest);
  yield takeLatest(GET_CONTESTS_DATA.request, getContestsData);
  yield takeLatest(GET_DOWNLOAD_CONTEST.request, getDownloadContest);
}

export default contestsSages;
