import {
  GET_VERSION_DP_REQUEST,
  CLEAR_VERSION_DP,
  SET_STATUS_MODAL_MIGRATIONS,
} from '../types';

const getVersionDp = () => ({
  type: GET_VERSION_DP_REQUEST,
});

const clearVersionDp = () => ({
  type: CLEAR_VERSION_DP,
});

const setStatusModalMigrations = (status) => ({
  type: SET_STATUS_MODAL_MIGRATIONS,
  payload: status,
});

export {
  setStatusModalMigrations,
  getVersionDp,
  clearVersionDp,
};
