import { getOptionsForPartnerSelect, getOptionsForBranchSelect, getOptionsForReportTypeSelect } from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts';

const { REPORT_TYPE } = LANG_DICTIONARY;
const selects = {
  reportTypes: {
    placeholder: REPORT_TYPE,
    action: getOptionsForReportTypeSelect,
  },
  partners: {
    placeholder: 'Наименование партнера',
    action: getOptionsForPartnerSelect,
  },
  branches: {
    placeholder: 'Наименование подразделения',
    action: getOptionsForBranchSelect,
  },
  statusActive: {
    placeholder: 'Статус',
    staticOptions: [
      {
        key: null,
        value: null,
        text: 'Не выбрано',
      },
      {
        key: 'statusActive1',
        value: true,
        text: 'Активен',
      },
      {
        key: 'statusActive2',
        value: false,
        text: 'Не активен',
      },
    ],
  },
  statusSite: {
    placeholder: 'Статус сайт',
    staticOptions: [
      {
        key: null,
        value: null,
        text: 'Не выбрано',
      },
      {
        key: 'statusSite1',
        value: true,
        text: 'Отображается',
      },
      {
        key: 'statusSite2',
        value: false,
        text: 'Не отображается',
      },
    ],
  },
  isEmployee: {
    placeholder: 'Сотрудник СК',
    staticOptions: [
      {
        key: null,
        value: null,
        text: 'Не выбрано',
      },
      {
        key: 'isEmployee1',
        value: true,
        text: 'Сотрудник СК',
      },
      {
        key: 'isEmployee2',
        value: false,
        text: 'Не сотрудник СК',
      },
    ],
  },
};

export default (type) => selects[type];
