import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import './styles.scss';
import StrategyTitleButton from '../StrategyTitleButton';

const b = block('basket-strategy-title');

const {
  STRATEGY_NUMBER,
  DELETE,
  COPY,
} = LANG_DICTIONARY;

const propTypes = {
  onDeleteStrategy: PropTypes.func,
  onCopyStrategy: PropTypes.func,
  isDeleteStrategy: PropTypes.bool,
  isCopyStrategy: PropTypes.bool,
  index: PropTypes.number.isRequired,
};

const defaultProps = {
  onDeleteStrategy: () => null,
  onCopyStrategy: () => null,
  isDeleteStrategy: true,
  isCopyStrategy: true,
};

const StrategyTitle = ({
  onDeleteStrategy,
  onCopyStrategy,
  isDeleteStrategy,
  isCopyStrategy,
  index,
}) => (
  <div className={b()}>
    <p className={b('title')}>{`${STRATEGY_NUMBER}${index + 1}`}</p>
    <section className={b('buttons')}>
      {isCopyStrategy && (
      <StrategyTitleButton
        text={COPY}
        iconName="plus"
        classNameIcon={b('plus').toString()}
        onClick={onCopyStrategy}
      />
      )}
      {isDeleteStrategy && (
      <StrategyTitleButton
        text={DELETE}
        iconName="close"
        onClick={onDeleteStrategy}
      />
      )}
    </section>
  </div>
);

StrategyTitle.defaultProps = defaultProps;
StrategyTitle.propTypes = propTypes;

export default StrategyTitle;
