import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('docsTransfer');

const SET_UNREAD_INBOX_COUNT = factory.create('SET_UNREAD_INBOX_COUNT');
const SET_SEARCH_VALUES = factory.create('SET_SEARCH_VALUES');

const GET_UNREAD_INBOX_MESSAGES_COUNT = factory.createAsync('GET_UNREAD_INBOX_MESSAGES_COUNT');
const GET_INBOX_MESSAGES = factory.createAsync('GET_INBOX_MESSAGES');
const GET_SENT_MESSAGES = factory.createAsync('GET_SENT_MESSAGES');
const DOWNLOAD_INBOX_MESSAGE_FILES = factory.createAsync('DOWNLOAD_INBOX_MESSAGE_FILES');
const MARK_AS_READ_INBOX_MESSAGE = factory.createAsync('MARK_AS_READ_INBOX_MESSAGE');
const GET_RECIPIENT_PARTNERS = factory.createAsync('GET_RECIPIENT_PARTNERS');
const GET_RECIPIENTS = factory.createAsync('GET_RECIPIENTS');
const CREATE_NEW_MESSAGE = factory.createAsync('CREATE_NEW_MESSAGE');

export {
  SET_UNREAD_INBOX_COUNT,
  GET_UNREAD_INBOX_MESSAGES_COUNT,
  GET_INBOX_MESSAGES,
  DOWNLOAD_INBOX_MESSAGE_FILES,
  MARK_AS_READ_INBOX_MESSAGE,
  SET_SEARCH_VALUES,
  GET_SENT_MESSAGES,
  GET_RECIPIENT_PARTNERS,
  GET_RECIPIENTS,
  CREATE_NEW_MESSAGE,
};
