import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { CustomCheckBox, AddressBlockCommon } from 'components';
import { simpleDate } from 'helpers';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ENUMS } from 'consts';
import '../../styles.scss';

const {
  SAME_ADDRESS,
  LIVING_ADDRESS,
  REGISTRATION_ADDRESS,
} = LANG_DICTIONARY;

const { BENEFICIARY_FORM_ENUM } = ENUMS;

const b = block('additional-block');

const propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  index: PropTypes.number,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  setAddress: PropTypes.func,
  setLivingAddressIsSame: PropTypes.func,
  isListBlackAddress: PropTypes.bool,
  formData: PropTypes.object,
  userInfo: PropTypes.object,
  setValues: PropTypes.func,
};

const defaultProps = {
  values: {},
  errors: {},
  touched: {},
  index: 0,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  setAddress: () => null,
  setLivingAddressIsSame: () => null,
  isListBlackAddress: false,
  formData: {},
  userInfo: {},
  setValues: () => null,
};
const AddressBlockBeneficiary = ({
  values,
  touched,
  errors,
  index,
  handleChangeByForm,
  handleTouchedByForm,
  setAddress,
  setLivingAddressIsSame,
  isListBlackAddress,
  formData,
  userInfo,
  setValues,
}) => {
  const { livingAddressIsSame } = values;
  const form = BENEFICIARY_FORM_ENUM.address;

  const payloadRequestCheckCode = {
    firstName: formData.firstName,
    lastName: formData.lastName,
    patronymic: formData.middleName,
    birthDate: simpleDate(formData.birthDate),
    branchId: userInfo.branchId,
    partnerId: userInfo.partnerId,
    personType: 'beneficiary',
  };

  const inputProps = {
    values,
    errors,
    touched,
    onChange: (e) => handleChangeByForm(e, form, index, values),
    onFocus: (e) => handleTouchedByForm(e, form, index),
    prefixScroll: `${form}${index}`,
    setValues,
  };

  const saveAddressRegistration = (data) => {
    setAddress(data, index, values);
  };

  const saveAddressLiving = (data) => {
    setAddress(data, index, values, true);
  };

  const handleChangeCheckbox = (name, data) => {
    setLivingAddressIsSame(data, index, values);
  };

  return (
    <div className={b('address')}>
      <p className={b('address-title')}>{REGISTRATION_ADDRESS}</p>
      <AddressBlockCommon
        inputProps={inputProps}
        isLivingAddress={false}
        insuredIsSame={false}
        selectSearch={saveAddressRegistration}
        isListBlackAddress={isListBlackAddress}
        index={index}
        isManualEnter={values.isManualEnter}
        toggleTypeEnter={(value) => inputProps.onChange({
          target: {
            name: 'isManualEnter',
            value,
          },
        })}
        payloadRequestCheckCode={payloadRequestCheckCode}
      />
      <p className={b('address-title')}>{LIVING_ADDRESS}</p>
      <div className={b('living-address')}>
        <p className={b('checkbox-text')}>{SAME_ADDRESS}</p>
        <CustomCheckBox
          checked={livingAddressIsSame}
          onChange={handleChangeCheckbox}
        />
      </div>
      { !livingAddressIsSame
      && (
        <AddressBlockCommon
          inputProps={inputProps}
          isLivingAddress
          insuredIsSame={false}
          selectSearch={saveAddressLiving}
          isListBlackAddress={isListBlackAddress}
          index={index}
          isManualEnter={values.isManualEnterLiving}
          toggleTypeEnter={(value) => inputProps.onChange({
            target: {
              name: 'isManualEnterLiving',
              value,
            },
          })}
          payloadRequestCheckCode={payloadRequestCheckCode}
        />
      )}
    </div>
  );
};

AddressBlockBeneficiary.propTypes = propTypes;
AddressBlockBeneficiary.defaultProps = defaultProps;
export default memo(AddressBlockBeneficiary);
