import { LOG_PAGE_REQUEST } from '../types';

const loggingPage = ({
  history,
  path,
  title,
}) => ({
  type: LOG_PAGE_REQUEST,
  payload: {
    history,
    path,
    title,
  },
});

export default loggingPage;
