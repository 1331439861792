import React from 'react';
import { connect } from 'react-redux';
import { CheckDocumentMasterForm } from 'components';
import { toastr } from 'react-redux-toastr';
import { checkAccessRoute } from 'roles';
import PropTypes from 'prop-types';
import {
  getBranchesUnderwriting,
  getUsersUnderwriting,
  getPartnersUnderwriting,
  getStatusUnderwriting,
  getCountContractUnderwriting,
  getCheckerListUnderwriting,
  getContractArrayUnderwriting,
  setInitStateUnderwritingMastrer,
} from 'redux/rootActions';

import { withCustomRouter } from 'HOC';

const defaultProps = {
  partners: [],
  loadingPartners: false,
  loadingBranches: false,
  loadingCount: false,
  loadingChecker: false,
  checkerLoading: false,
  countContract: 0,
  getBranches: () => null,
  getUsers: () => null,
  users: [],
  branches: [],
  loadingUsers: false,
  loadingStatus: false,
  statusList: [],
  checkerList: [],
  getPartners: () => null,
  getStatusList: () => null,
  getCheckerList: () => null,
  getContractArray: () => null,
  getCountContract: () => null,
  setInitState: () => null,
  history: {},
  location: {},
  roles: {},
};

const propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object),
  loadingPartners: PropTypes.bool,
  loadingBranches: PropTypes.bool,
  loadingCount: PropTypes.bool,
  loadingChecker: PropTypes.bool,
  checkerLoading: PropTypes.bool,
  countContract: PropTypes.number,
  getBranches: PropTypes.func,
  getUsers: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  loadingUsers: PropTypes.bool,
  loadingStatus: PropTypes.bool,
  statusList: PropTypes.arrayOf(PropTypes.object),
  checkerList: PropTypes.arrayOf(PropTypes.object),
  getPartners: PropTypes.func,
  getStatusList: PropTypes.func,
  getCheckerList: PropTypes.func,
  getContractArray: PropTypes.func,
  getCountContract: PropTypes.func,
  setInitState: PropTypes.func,
  history: PropTypes.object,
  location: PropTypes.object,
  roles: PropTypes.object,
};

class CheckDocumentMaster extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      partnerId: 0,
      branchId: 0,
      userId: 0,
      statusId: 0,
      ctsNum: '',
      ctgtsFio: '',
      isInsurer: false,
      isInsured: false,
      isBeneficiary: false,
      isNew: true,
      isChecked: false,
      isHalfChecked: true,
      isError: false,
      documentCount: 0,
      checkUserId: 0,
      dateBeg: new Date('2019-01-01'),
      dateEnd: new Date(),
    };
  }

  componentDidMount() {
    const {
      getPartners,
      getStatusList,
      getCheckerList,
      location: { pathname },
      roles,
    } = this.props;
    const {
      ...others
    } = this.state;

    const body = {
      ...others,
      documentCount: undefined,
    };

    const access = checkAccessRoute(pathname, roles);
    if (access) {
      getPartners();
      getStatusList();
      getCheckerList();
      this.getCountContract(body);
    }
  }

  handleSubmit = async () => {
    const { getContractArray, history } = this.props;
    const { documentCount } = this.state;
    if (documentCount) {
      getContractArray({ payload: this.state, history });
    } else {
      toastr.error('', 'Вы не выбрали ни одного контракта');
    }
  };

  handleChange = ({ target: { name, value } }) => {
    const {
      ...others
    } = this.state;

    const body = {
      ...others,
      documentCount: undefined,
      [name]: value,
    };
    this.getCountContract(body);
    this.setState({ [name]: value });
  };

  handleChangeCheckbox = (name, checked) => {
    const {
      ...others
    } = this.state;

    const body = {
      ...others,
      documentCount: undefined,
      [name]: !checked,
    };
    this.getCountContract(body);
    this.setState({ [name]: !checked });
  };

  handleChangeCheckboxDependRange = (name, checked) => {
    const {
      ...others
    } = this.state;

    const body = {
      ...others,
      documentCount: undefined,
      [name]: !checked,
    };
    this.getCountContract(body);
    this.setState({ [name]: !checked });
  };

  getCountContract = (checkboxes) => {
    const { getCountContract } = this.props;
    getCountContract(checkboxes, this.handleChangeRangeInput);
  };

  handleChangePartner = (e) => {
    this.handleChange(e);
    const { getBranches, getUsers } = this.props;
    const { target: { value } } = e;
    if (value !== 0) {
      getBranches(value);
      getUsers({ partnerId: value });
    } else {
      this.setState({
        branchId: 0,
        userId: 0,
      });
    }
  };

  handleChangeBranches = (e) => {
    const { getUsers } = this.props;
    const { partnerId } = this.state;
    const { target: { value } } = e;
    this.handleChange(e);
    if (value !== 0) {
      getUsers({ branchId: value, partnerId });
    } else {
      this.setState({ userId: 0 });
    }
  };

  handleChangeRangeInput = (value) => {
    this.setState({ documentCount: value });
  };

  render() {
    const {
      partners,
      loadingPartners,
      loadingBranches,
      loadingCount,
      loadingChecker,
      checkerLoading,
      countContract,
      getBranches,
      getUsers,
      users,
      branches,
      loadingUsers,
      loadingStatus,
      statusList,
      checkerList,
    } = this.props;
    const { documentCount } = this.state;

    return (
      <CheckDocumentMasterForm
        partners={partners}
        users={users}
        branches={branches}
        getBranches={getBranches}
        getUsers={getUsers}
        loadingPartners={loadingPartners}
        loadingBranches={loadingBranches}
        loadingUsers={loadingUsers}
        loadingStatus={loadingStatus}
        statusList={statusList}
        loadingCount={loadingCount}
        countContract={countContract}
        loadingChecker={loadingChecker}
        checkerLoading={checkerLoading}
        checkerList={checkerList}
        rangeInputValue={documentCount}
        handleChangePartner={this.handleChangePartner}
        handleChangeBranches={this.handleChangeBranches}
        handleChange={this.handleChange}
        handleChangeCheckbox={this.handleChangeCheckbox}
        handleChangeCheckboxDependRange={this.handleChangeCheckboxDependRange}
        handleChangeRangeInput={this.handleChangeRangeInput}
        handleSubmit={this.handleSubmit}
        {...this.state}
      />
    );
  }
}

const mapStateToProps = ({
  underwritingMaster: {
    partners,
    users,
    branches,
    statusList,
    checkerList,
    countContract,
    loadingPartners,
    loadingBranches,
    loadingUsers,
    loadingStatus,
    loadingCount,
    loadingChecker,
  },
  authReducer: {
    roles,
  },
}) => ({
  roles,
  checkerList,
  partners,
  loadingPartners,
  loadingBranches,
  loadingChecker,
  users,
  branches,
  loadingUsers,
  statusList,
  loadingStatus,
  loadingCount,
  countContract,
});
const mapDispatchToProps = {
  getPartners: getPartnersUnderwriting,
  getBranches: getBranchesUnderwriting,
  getUsers: getUsersUnderwriting,
  getStatusList: getStatusUnderwriting,
  getCountContract: getCountContractUnderwriting,
  getCheckerList: getCheckerListUnderwriting,
  getContractArray: getContractArrayUnderwriting,
  setInitState: setInitStateUnderwritingMastrer,
};

CheckDocumentMaster.defaultProps = defaultProps;
CheckDocumentMaster.propTypes = propTypes;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(CheckDocumentMaster));
