export const PARTIC_TYPE = {
  BRANCHES: 'branches',
  USERS: 'users',
};

export const MEASURE_TYPE = {
  PREMIUM: 'premium',
  CONTRACTS: 'contracts',
  BIL: 'bill',
};
