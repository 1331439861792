import React from 'react';
import PropTypes from 'prop-types';
import { FileViewModal } from 'components';
import { Button, Icon, Select } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';

const { SELECT_DOCUMENT_TYPE } = LANG_DICTIONARY;

const defaultProps = {
  file: null,
  itemClass: null,
  value: null,
  downloadScan: () => null,
  fileName: '',
  disableControls: false,
};

const propTypes = {
  file: PropTypes.object,
  itemClass: PropTypes.object,
  value: PropTypes.number,
  onDelete: PropTypes.func.isRequired,
  getTypeList: PropTypes.func.isRequired,
  onChangeType: PropTypes.func.isRequired,
  id: PropTypes.number.isRequired,
  downloadScan: PropTypes.func,
  fileName: PropTypes.string,
  disableControls: PropTypes.bool,
};

const FileItem = ({
  file,
  itemClass,
  onDelete,
  getTypeList,
  onChangeType,
  value,
  id,
  downloadScan,
  fileName,
  disableControls,
}) => (
  <div className={itemClass}>
    <div className="fileLeftBlock">
      <FileViewModal file={file} scanId={id} downloadScan={downloadScan} />
      <p className="fileNameTextExecution">{fileName}</p>
    </div>
    <div className="fileRightBlock">
      <Select
        className="docTypes"
        placeholder={SELECT_DOCUMENT_TYPE}
        options={getTypeList(id)}
        onChange={(event, data) => onChangeType(data.value, id)}
        value={value}
        disabled={disableControls}
      />
      <Button
        type="button"
        className="removeButton"
        onClick={() => !disableControls && onDelete(id, fileName)}
        disabled={disableControls}
      >
        <Icon name="remove" />
      </Button>
    </div>
  </div>
);

FileItem.defaultProps = defaultProps;
FileItem.propTypes = propTypes;

export default FileItem;
