const GET_REPORTS_FOR_TABLE_REQUEST = 'reportsAdminInterface/GET_REPORTS_FOR_TABLE_REQUEST';
const GET_REPORTS_FOR_TABLE_START = 'reportsAdminInterface/GET_REPORTS_FOR_TABLE_START';
const GET_REPORTS_FOR_TABLE_SUCCESS = 'reportsAdminInterface/GET_REPORTS_FOR_TABLE_SUCCESS';
const GET_REPORTS_FOR_TABLE_FAIL = 'reportsAdminInterface/GET_REPORTS_FOR_TABLE_FAIL';

const CREATE_REPORT_REQUEST = 'reportsAdminInterface/CREATE_REPORT_REQUEST';
const CREATE_REPORT_SUCCESS = 'reportsAdminInterface/CREATE_REPORT_SUCCESS';
const CREATE_REPORT_FAIL = 'reportsAdminInterface/CREATE_REPORT_FAIL';
const CREATE_REPORT_START = 'reportsAdminInterface/CREATE_REPORT_START';

const GET_REPORT_DATA_REQUEST = 'reportsAdminInterface/GET_REPORT_DATA_REQUEST';
const GET_REPORT_DATA_START = 'reportsAdminInterface/GET_REPORT_DATA_START';
const GET_REPORT_DATA_SUCCESS = 'reportsAdminInterface/GET_REPORT_DATA_SUCCESS';
const GET_REPORT_DATA_FAIL = 'reportsAdminInterface/GET_REPORT_DATA_FAIL';

const UPDATE_REPORT_REQUEST = 'reportsAdminInterface/UPDATE_REPORT_REQUEST';
const UPDATE_REPORT_SUCCESS = 'reportsAdminInterface/UPDATE_REPORT_SUCCESS';
const UPDATE_REPORT_FAIL = 'reportsAdminInterface/UPDATE_REPORT_FAIL';
const UPDATE_REPORT_START = 'reportsAdminInterface/UPDATE_REPORT_START';

const CLEAR_FORM = 'reportsAdminInterface/CLEAR_FORM';

const SET_PAGE_PAGINATION = 'reportsAdminInterface/SET_PAGE_PAGINATION';

const SET_INITIAL_VALUES = 'reportsAdminInterface/SET_INITIAL_VALUES';

const SET_ROLES_FOR_REPORT = 'reportsAdminInterface/SET_ROLES_FOR_REPORT';

const GET_ROLES_FOR_SELECT_REQUEST = 'reportsAdminInterface/GET_ROLES_FOR_SELECT_REQUEST';
const GET_ROLES_FOR_SELECT_START = 'reportsAdminInterface/GET_ROLES_FOR_SELECT_START';
const GET_ROLES_FOR_SELECT_SUCCESS = 'reportsAdminInterface/GET_ROLES_FOR_SELECT_SUCCESS';
const GET_ROLES_FOR_SELECT_FAIL = 'reportsAdminInterface/GET_ROLES_FOR_SELECT_FAIL';

const GET_PARTNERS_FOR_SELECT_REQUEST = 'reportsAdminInterface/GET_PARTNERS_FOR_SELECT_REQUEST';
const GET_PARTNERS_FOR_SELECT_START = 'reportsAdminInterface/GET_PARTNERS_FOR_SELECT_START';
const GET_PARTNERS_FOR_SELECT_SUCCESS = 'reportsAdminInterface/GET_PARTNERS_FOR_SELECT_SUCCESS';
const GET_PARTNERS_FOR_SELECT_FAIL = 'reportsAdminInterface/GET_PARTNERS_FOR_SELECT_FAIL';

const GET_REPORT_TYPES_FOR_SELECT_REQUEST = 'reportsAdminInterface/GET_REPORT_TYPES_FOR_SELECT_REQUEST';
const GET_REPORT_TYPES_FOR_SELECT_START = 'reportsAdminInterface/GET_REPORT_TYPES_FOR_SELECT_START';
const GET_REPORT_TYPES_FOR_SELECT_SUCCESS = 'reportsAdminInterface/GET_REPORT_TYPES_FOR_SELECT_SUCCESS';
const GET_REPORT_TYPES_FOR_SELECT_FAIL = 'reportsAdminInterface/GET_REPORT_TYPES_FOR_SELECT_FAIL';

const GET_REPORT_TAGS_REQUEST = 'reportsAdminInterface/GET_REPORT_TAGS_REQUEST';
const GET_REPORT_TAGS_START = 'reportsAdminInterface/GET_REPORT_TAGS_START';
const GET_REPORT_TAGS_SUCCESS = 'reportsAdminInterface/GET_REPORT_TAGS_SUCCESS';
const GET_REPORT_TAGS_FAIL = 'reportsAdminInterface/GET_REPORT_TAGS_FAIL';

const SET_REPORT_SELECT = 'reportsAdminInterface/SET_REPORT_SELECT';

const SET_TAGS_FOR_REPORT = 'reportsAdminInterface/SET_TAGS_FOR_REPORT';

const SET_NEW_TAGS_SUCCESS = 'reportsAdminInterface/SET_NEW_TAGS_SUCCESS';

const SET_REPORT_STATUS = 'reportsAdminInterface/SET_REPORT_STATUS';

const GET_DOWNLOAD_CHECK_REQUEST = 'reportsAdminInterface/GET_DOWNLOAD_CHECK_REQUEST';
const GET_DOWNLOAD_CHECK_START = 'reportsAdminInterface/GET_DOWNLOAD_CHECK_START';
const GET_DOWNLOAD_CHECK_SUCCESS = 'reportsAdminInterface/GET_DOWNLOAD_CHECK_SUCCESS';
const GET_DOWNLOAD_CHECK_FAIL = 'reportsAdminInterface/GET_DOWNLOAD_CHECK_FAIL';

const GET_DOWNLOAD_LIST_REQUEST = 'reportsAdminInterface/GET_DOWNLOAD_LIST_REQUEST';
const GET_DOWNLOAD_LIST_START = 'reportsAdminInterface/GET_DOWNLOAD_LIST_START';
const GET_DOWNLOAD_LIST_SUCCESS = 'reportsAdminInterface/GET_DOWNLOAD_LIST_SUCCESS';
const GET_DOWNLOAD_LIST_FAIL = 'reportsAdminInterface/GET_DOWNLOAD_LIST_FAIL';

const GET_DOWNLOAD_REPORT_REQUEST = 'reportsAdminInterface/GET_DOWNLOAD_REPORT_REQUEST';
const GET_DOWNLOAD_REPORT_START = 'reportsAdminInterface/GET_DOWNLOAD_REPORT_START';
const GET_DOWNLOAD_REPORT_SUCCESS = 'reportsAdminInterface/GET_DOWNLOAD_REPORT_SUCCESS';
const GET_DOWNLOAD_REPORT_FAIL = 'reportsAdminInterface/GET_DOWNLOAD_REPORT_FAIL';

const CLOSE_DOWNLOAD_POPUP = 'reportsAdminInterface/CLOSE_DOWNLOAD_POPUP';

export {
  CLOSE_DOWNLOAD_POPUP,
  GET_DOWNLOAD_CHECK_FAIL,
  GET_DOWNLOAD_CHECK_REQUEST,
  GET_DOWNLOAD_CHECK_SUCCESS,
  GET_DOWNLOAD_CHECK_START,
  GET_DOWNLOAD_LIST_FAIL,
  GET_DOWNLOAD_LIST_REQUEST,
  GET_DOWNLOAD_LIST_SUCCESS,
  GET_DOWNLOAD_LIST_START,
  GET_DOWNLOAD_REPORT_FAIL,
  GET_DOWNLOAD_REPORT_REQUEST,
  GET_DOWNLOAD_REPORT_SUCCESS,
  GET_DOWNLOAD_REPORT_START,
  SET_REPORT_STATUS,
  SET_TAGS_FOR_REPORT,
  SET_REPORT_SELECT,
  GET_REPORT_TAGS_REQUEST,
  GET_REPORT_TAGS_START,
  GET_REPORT_TAGS_SUCCESS,
  GET_REPORT_TAGS_FAIL,
  GET_ROLES_FOR_SELECT_REQUEST,
  GET_ROLES_FOR_SELECT_START,
  GET_ROLES_FOR_SELECT_SUCCESS,
  GET_ROLES_FOR_SELECT_FAIL,
  SET_ROLES_FOR_REPORT,
  GET_REPORT_TYPES_FOR_SELECT_REQUEST,
  GET_REPORT_TYPES_FOR_SELECT_START,
  GET_REPORT_TYPES_FOR_SELECT_SUCCESS,
  GET_REPORT_TYPES_FOR_SELECT_FAIL,
  GET_PARTNERS_FOR_SELECT_REQUEST,
  GET_PARTNERS_FOR_SELECT_START,
  GET_PARTNERS_FOR_SELECT_SUCCESS,
  GET_PARTNERS_FOR_SELECT_FAIL,
  SET_INITIAL_VALUES,
  GET_REPORTS_FOR_TABLE_FAIL,
  GET_REPORTS_FOR_TABLE_REQUEST,
  GET_REPORTS_FOR_TABLE_SUCCESS,
  GET_REPORTS_FOR_TABLE_START,
  SET_PAGE_PAGINATION,
  CLEAR_FORM,
  UPDATE_REPORT_FAIL,
  UPDATE_REPORT_START,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DATA_START,
  GET_REPORT_DATA_SUCCESS,
  CREATE_REPORT_FAIL,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_START,
  CREATE_REPORT_SUCCESS,
  SET_NEW_TAGS_SUCCESS,
};
