import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { getFormattedDate } from 'helpers';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { CloudPopup } from 'components';
import './styles.scss';

const b = block('last-check-info');

const propTypes = {
  content: PropTypes.object,
  children: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]),
};

const defaultProps = {
  content: {
    checker: '',
    dateCheck: '',
    status: '',
    checkErrorDescription: '',
    checkErrorName: '',
  },
  children: '',
};

const {
  CHECKER,
  DATE_CHECK,
  STATUS_CHECK,
  MISSING,
} = LANG_DICTIONARY;

const LastCheckInfo = ({
  content: {
    checker,
    dateCheck,
    status,
    checkErrorDescription,
    checkErrorName,
  }, children,
}) => {
  const errorMessage = checkErrorDescription && checkErrorDescription !== ''
    ? `(${checkErrorName}: ${checkErrorDescription})` : `(${checkErrorName})`;

  const message = checkErrorName ? errorMessage : '';

  return (
    <CloudPopup
      trigger={children}
      className={b()}
      children={(
        <table className={b('table')}>
          <tbody>
            <tr>
              <td>{`${CHECKER}:`}</td>
              <td>{checker === null ? MISSING : checker}</td>
            </tr>
            <tr>
              <td>{`${DATE_CHECK}:`}</td>
              <td>{dateCheck ? getFormattedDate(dateCheck) : '-'}</td>
            </tr>
            <tr>
              <td>{`${STATUS_CHECK}:`}</td>
              <td>{`${status} ${message.toLowerCase()}`}</td>
            </tr>
          </tbody>
        </table>
      )}
    />
  );
};

LastCheckInfo.propTypes = propTypes;
LastCheckInfo.defaultProps = defaultProps;

export default LastCheckInfo;
