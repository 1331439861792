import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('basketConstructor');

export const GET_PRODUCT_TYPES_TABLE_CONTROL = factory.createAsync('GET_PRODUCT_TYPES_TABLE_CONTROL');
export const GET_AMOUNT_FAVORITES_BASKET = factory.createAsync('GET_AMOUNT_FAVORITES_BASKET');
export const GET_OPTION_TYPES = factory.createAsync('GET_OPTION_TYPES');
export const GET_OPTION_TYPES_RESTRICTIONS = factory.createAsync('GET_OPTION_TYPES_RESTRICTIONS');
export const GET_CURRENCIES = factory.createAsync('GET_CURRENCIES');
export const GET_TERMS = factory.createAsync('GET_TERMS');
export const GET_ACTIVES = factory.createAsync('GET_ACTIVES');
export const GET_PAYOUT_FREQUENCY = factory.createAsync('GET_PAYOUT_FREQUENCY');
export const CHANGE_LOOKBACK_FLAG = factory.create('CHANGE_LOOKBACK_FLAG');
export const GET_MIN_INSURANCE_SUM_FORM_CREATING = factory.createAsync('GET_MIN_INSURANCE_SUM_FORM_CREATING');
export const GET_MIN_INSURANCE_SUM_TABLES = factory.createAsync('GET_MIN_INSURANCE_SUM_TABLES');
export const CALCULATE_COUPON = factory.createAsync('CALCULATE_COUPON');
export const GET_PRODUCT_SETTINGS = factory.createAsync('GET_PRODUCT_SETTINGS');
export const GET_CALCULATED_PERSON_BASKETS = factory.createAsync('GET_CALCULATED_PERSON_BASKETS');
export const GET_ASSETS_DESCRIPTION = factory.createAsync('GET_ASSETS_DESCRIPTION');
export const SET_FAVORITES_DESCRIPTION = factory.createAsync('SET_FAVORITES_DESCRIPTION');
export const DELETE_FAVORITES_DESCRIPTION = factory.createAsync('DELETE_FAVORITES_DESCRIPTION');
export const GET_HINTS_FOR_BASKETS = factory.createAsync('GET_HINTS_FOR_BASKETS');
export const GET_HINTS_FOR_READY_BASKETS = factory.createAsync('GET_HINTS_FOR_READY_BASKETS');
export const GET_HINTS_FOR_REGULAR_BASKETS = factory.createAsync('GET_HINTS_FOR_REGULAR_BASKETS');
export const GET_REQUEST_DATA_FOR_COPY = factory.createAsync('GET_REQUEST_DATA_FOR_COPY');
export const GET_PRODUCT_TYPES_FORM_CREATING = factory.createAsync('GET_PRODUCT_TYPES_FORM_CREATING');
export const GET_REQUEST_COUNT = factory.createAsync('GET_REQUEST_COUNT');
export const GET_READY_BASKETS = factory.createAsync('GET_READY_BASKETS');
export const GET_REGULAR_BASKETS = factory.createAsync('GET_REGULAR_BASKETS');
export const EXECUTION_CALCULATING_BASKET = factory.createAsync('EXECUTION_CALCULATING_BASKET');
export const EXECUTION_CALCULATING_DP_POLICY_BASKET = factory.createAsync('EXECUTION_CALCULATING_DP_POLICY_BASKET');
export const PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET = factory.createAsync('PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET');
export const INS_LINE_ISZ_CALC = factory.createAsync('INS_LINE_ISZ_CALC');
export const GENERATE_REPORT = factory.createAsync('GENERATE_REPORT');
export const CHECK_JUST_NOW_EXECUTION_BASKET = factory.createAsync('CHECK_JUST_NOW_EXECUTION_BASKET');
export const ANNUL_OLD_CONSTRUCTOR = factory.createAsync('ANNUL_OLD_CONSTRUCTOR');
export const RECALCULATE_COUPON = factory.createAsync('RECALCULATE_COUPON');
export const CLOSE_EXECUTION_DP_POLICY_POPUP = factory.create('CLOSE_EXECUTION_DP_POLICY_POPUP');

export const DELETE_STRATEGY = factory.create('DELETE_STRATEGY');
export const COPY_STRATEGY = factory.create('COPY_STRATEGY');
export const ADD_NEW_STRATEGY = factory.create('ADD_NEW_STRATEGY');
export const ADD_NEW_GUARANTEED_PAYOUT = factory.create('ADD_NEW_GUARANTEED_PAYOUT');
export const CLEAR_MIN_INSURANCE_SUM = factory.create('CLEAR_MIN_INSURANCE_SUM');
export const SAVE_PARTNERS_INS_PRODUCT_ID = factory.create('SAVE_PARTNERS_INS_PRODUCT_ID');
export const SET_INIT_STATE = factory.create('SET_INIT_STATE');
export const SET_INIT_STATE_SELECT = factory.create('SET_INIT_STATE_SELECT');
export const SET_GENERAL_OPTION_ID = factory.create('SET_GENERAL_OPTION_ID');
export const CHANGE_GUARANTEE_LEVEL = factory.create('CHANGE_GUARANTEE_LEVEL');
export const CHANGE_CURRENCY_TABLE_CONTROL = factory.create('CHANGE_CURRENCY_TABLE_CONTROL');
export const CHANGE_GUARANTEE_LEVEL_ERROR = factory.create('CHANGE_GUARANTEE_LEVEL_ERROR');
export const TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKET = factory.create('TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKETS');
export const TOGGLE_OPEN_READY_CHILD_BASKET = factory.create('TOGGLE_OPEN_READY_CHILD_BASKET');
export const SELECT_CALCULATED_PERSON_BASKET = factory.create('SELECT_CALCULATED_PERSON_BASKET');
export const SET_PRODUCT_TYPE_TABLE_CONTROL = factory.create('SET_PRODUCT_TYPE_TABLE_CONTROL');
export const SET_PRODUCT_ONLY_FAVORITE = factory.create('SET_PRODUCT_ONLY_FAVORITE');
export const SET_BASKET_FILTER = factory.create('SET_BASKET_FILTER');
export const SET_REGULAR_BASKET_FILTER = factory.create('SET_REGULAR_BASKET_FILTER');
export const SET_READY_BASKET_FILTER = factory.create('SET_READY_BASKET_FILTER');
export const SET_BASKET_SORT = factory.create('SET_BASKET_SORT');
export const SET_REGULAR_BASKET_SORT = factory.create('SET_REGULAR_BASKET_SORT');
export const SET_READY_BASKET_SORT = factory.create('SET_READY_BASKET_SORT');
export const CLEAR_SORTING_AND_FILTERING = factory.create('CLEAR_SORTING_AND_FILTERING');
export const SET_MAIN_STRATEGY_DATA = factory.create('SET_MAIN_STRATEGY_DATA');
export const SET_CHILDREN_STRATEGY_DATA = factory.create('SET_CHILDREN_STRATEGY_DATA');
export const SET_INIT_STATE_CREATING_FORM = factory.create('SET_INIT_STATE_CREATING_FORM');
export const GET_READY_AND_REGULAR_BASKETS = factory.create('GET_READY_AND_REGULAR_BASKETS');
export const SELECT_READY_BASKET = factory.create('SELECT_READY_BASKET');
export const SELECT_REGULAR_BASKET = factory.create('SELECT_REGULAR_BASKET');
export const TOGGLE_OPEN_REGULAR_CHILD_BASKET = factory.create('TOGGLE_OPEN_REGULAR_CHILD_BASKET');
export const REMOVE_LAST_STRATEGIES = factory.create('REMOVE_LAST_STRATEGIES');
export const SELECT_CHILD_CALCULATED_PERSON_BASKET = factory.create('SELECT_CHILD_CALCULATED_PERSON_BASKET');
export const SELECT_CHILD_READY_BASKET = factory.create('SELECT_CHILD_READY_BASKET');
export const SELECT_CHILD_REGULAR_BASKET = factory.create('SELECT_CHILD_REGULAR_BASKET');
export const CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS = factory.create('CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS');
export const SET_WARNING_MESSAGE = factory.create('SET_WARNING_MESSAGE');
export const SET_JUST_NOW_EXECUTION_BASKET = factory.create('SET_JUST_NOW_EXECUTION_BASKET');
export const SET_PRODUCT_ID = factory.create('SET_PRODUCT_ID');
export const CLOSE_MIN_INSURANCE_SUM_TABLE = factory.create('CLOSE_MIN_INSURANCE_SUM_TABLE');
export const CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING = factory.create('CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING');
export const CHECK_MULTIPLE_ASSETS = factory.createAsync('CHECK_MULTIPLE_ASSETS');
export const GET_GENERAL_ASSET_CLASS = factory.createAsync('GET_GENERAL_ASSET_CLASS');
export const CHANGE_FIELDS_FOR_NAME = factory.create('CHANGE_FIELDS_FOR_NAME');
export const COPY_GUARANTEED_PAYOUTS = factory.create('COPY_GUARANTEED_PAYOUTS');
export const RESET_CALCULATED_STATUS_COUPON = factory.create('RESET_CALCULATED_STATUS_COUPON');

export const CHANGE_CURRENCY = factory.createManyStep('CHANGE_CURRENCY');
export const SET_PRODUCT_TYPE_FORM_CREATING = factory.createManyStep('SET_PRODUCT_TYPE_FORM_CREATING');
export const GET_MAX_GUARANTEE_LEVEL = factory.createAsync('GET_MAX_GUARANTEE_LEVEL');
export const DELETE_GUARANTEED_PAYOUT = factory.create('DELETE_GUARANTEED_PAYOUT');
export const CLEAR_CALCULATED_BASKET = factory.create('CLEAR_CALCULATED_BASKET');
export const CLEAR_REGULAR_BASKET = factory.create('CLEAR_REGULAR_BASKET');
export const CLEAR_READY_BASKET = factory.create('CLEAR_READY_BASKET');
