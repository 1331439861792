import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';

const defaultProps = {
  component: null,
};

const propTypes = {
  component: PropTypes.func,
};

const b = block('auth');

const AuthContainer = ({ component: Component }) => (
  <section className={b()}>
    <div className={b('container')}>
      <div className={b('form')}>
        <Component bem={b} />
      </div>
    </div>
  </section>
);

AuthContainer.propTypes = propTypes;
AuthContainer.defaultProps = defaultProps;

export default AuthContainer;
