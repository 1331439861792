import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  checkAccountNumbersNsz,
  saveDataNszForm,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { AgentData } from 'containers';

const defaultProps = {};

const propTypes = {
  saveData: PropTypes.func.isRequired,
  checkNumbers: PropTypes.func.isRequired,
};

export default (formType) => {
  class NszAgentData extends Component {
    AgentDataComponent = AgentData(formType);

    render() {
      const { AgentDataComponent } = this;
      const {
        saveData,
        checkNumbers,
        ...rest
      } = this.props;

      return (
        <AgentDataComponent
          saveData={saveData}
          checkNumbers={checkNumbers}
          insurerDataUrl={ROUTES.NszInsurerData}
          preliminaryCalculationUrl={ROUTES.nszCalculation}
          {...rest}
        />
      );
    }
  }

  NszAgentData.propTypes = propTypes;
  NszAgentData.defaultProps = defaultProps;

  const mapDispatchToProps = {
    saveData: saveDataNszForm,
    checkNumbers: checkAccountNumbersNsz,
  };

  return withRouter(connect(null, mapDispatchToProps)(NszAgentData));
};
