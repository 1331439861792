import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer, MainBtn } from 'components';
import './styles.scss';

const { REFRESH_PAGE_POPUP_TEXT, REFRESH_TEXT } = LANG_DICTIONARY;

const b = block('page-version-popup');
const propTypes = {
  check: PropTypes.bool,
};

const defaultProps = {
  check: false,
};

const RefreshPagePopUp = ({ check }) => {
  const popUp = (
    <div className={b()}>
      <p>{REFRESH_PAGE_POPUP_TEXT}</p>
      <MainBtn text={REFRESH_TEXT} onClick={() => window.location.reload(true)} className="update-version-popup-button-reload" />
    </div>
  );

  return (
    <PopupContainer
      isOpen={check}
      children={popUp}
    />
  );
};

RefreshPagePopUp.propTypes = propTypes;
RefreshPagePopUp.defaultProps = defaultProps;
export default RefreshPagePopUp;
