const dictionary = {
  ACTIVE: 'active',
  PROCCESS: 'proccess',
  COMPLETE: 'complete',
  FAIL: 'fail',
  PRIORITY: 'priority',
  CRON_TYPE_WORD: 'cronTypeId',
  TIME_WORD: 'time',
  INTERVAL_WORD: 'interval',
  EDIT_WORD: 'edit',
  CONFIRM_WORD: 'confirm',
  TIME_START: 'timeStart',
};

export default dictionary;
