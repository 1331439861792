import React from 'react';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';

const propTypes = {
  isOpen: PropTypes.bool,
  closeAction: PropTypes.func,
  children: PropTypes.node,
};
const defaultProps = {
  isOpen: false,
  closeAction: () => null,
  children: null,
};

const BranchesPopUp = ({
  isOpen,
  closeAction,
  children,
}) => (
  <PopupContainer
    isOpen={isOpen}
    children={children}
    close={closeAction}
    isCloseButton
  />
);

BranchesPopUp.propTypes = propTypes;
BranchesPopUp.defaultProps = defaultProps;
export default BranchesPopUp;
