import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('multi-select');
const { ROLES, NOT_CHOOSED } = LANG_DICTIONARY;

const propTypes = {
  reportsRoles: PropTypes.arrayOf(PropTypes.object),
  selectedRoles: PropTypes.arrayOf(PropTypes.object),
  setRoles: PropTypes.func,
  values: PropTypes.object,
  allRoles: PropTypes.arrayOf(PropTypes.object),
  setErrorMultiSelect: PropTypes.func,
  error: PropTypes.bool,
  required: PropTypes.string,
  toBottom: PropTypes.bool,
  title: PropTypes.string,
  maxItems: PropTypes.number,
  disabled: PropTypes.bool,
};

const defaultProps = {
  maxItems: 0,
  title: '',
  toBottom: false,
  required: '',
  error: false,
  disabled: false,
  setErrorMultiSelect: () => null,
  setRoles: () => null,
  reportsRoles: [],
  selectedRoles: [],
  values: {},
  allRoles: [],
};

class MultiSelect extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isOpen: false,
    };
  }

  componentDidMount = () => {
    document.addEventListener('click', this.handleClickOutside);
  }

  componentWillUnmount = () => {
    document.removeEventListener('click', this.handleClickOutside);
  }

  setWrapperRef = (node) => {
    this.wrapperRef = node;
  }

  handleClickOutside = (event) => {
    const { isOpen } = this.state;
    if (isOpen && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
      this.setState({ isOpen: false });
    }
  }

  addItem = (role, assetApprove) => {
    const {
      reportsRoles,
      selectedRoles,
      setRoles,
      values,
      setErrorMultiSelect,
      maxItems,
    } = this.props;
    const { isOpen } = this.state;
    const { value } = role;
    if (value === null) {
      this.setState({
        isOpen: !isOpen,
      });
      if (!selectedRoles.length) {
        setErrorMultiSelect(true);
      }

      return false;
    }
    if (maxItems && selectedRoles.length === 6) return false;
    const oldArr = reportsRoles.slice();
    const newArr = selectedRoles.slice();
    const deletedIndex = oldArr.findIndex((item, index) => {
      if (item.value === value && value !== null) {
        newArr.push({ value: item.value, assetApprove });

        return index;
      }

      return null;
    });
    oldArr.splice(deletedIndex === -1 ? 0 : deletedIndex, 1);
    setRoles(newArr, oldArr, values);
    this.setState({
      isOpen: !isOpen,
    });
    setErrorMultiSelect(false);

    return true;
  }

  deleteItem = (role, assetApprove) => {
    const {
      reportsRoles,
      selectedRoles,
      setRoles,
      values,
      allRoles,
      setErrorMultiSelect,
    } = this.props;
    let value = null;
    const info = Object.values(allRoles).find(
      (val) => role.value === val.value || val.value === role,
    );
    if (role.value) {
      const { value: val } = role;
      value = val;
    } else {
      value = role;
    }
    const oldArr = reportsRoles.slice();
    const newArr = selectedRoles.slice();
    const deletedIndex = newArr.findIndex((item, index) => {
      if (!item.value && item === value && value !== null) {
        oldArr.push({
          key: value,
          value,
          text: info.text,
          assetApprove,
        });

        return index;
      } if (item.value === value && value !== null) {
        oldArr.push({ ...item, text: info.text, key: value });

        return index;
      }

      return null;
    });
    newArr.splice(deletedIndex === -1 ? 0 : deletedIndex, 1);
    setRoles(newArr, oldArr, values);
    if (selectedRoles.length === 1) {
      setErrorMultiSelect(true);
    }
  }

  openItemsList = () => {
    const { isOpen } = this.state;
    this.setState({
      isOpen: !isOpen,
    });
  }

  getRole = (item) => {
    const { allRoles } = this.props;
    if (item.value) {
      const searchResult = Object.values(allRoles).find((role) => role.value === item.value);

      return searchResult && searchResult.text;
    }
    const searchResult = Object.values(allRoles).find((role) => role.value === item);

    return searchResult && searchResult.text;
  }

  render() {
    const {
      reportsRoles,
      selectedRoles,
      error,
      required,
      toBottom,
      title,
      disabled,
    } = this.props;
    const { isOpen } = this.state;
    const sortedRoles = reportsRoles.sort((a, d) => {
      if (a.text < d.text) {
        return -1;
      }
      if (a.text > d.text) {
        return 1;
      }

      return 0;
    });

    return (
      <div className={b()}>
        <div className={b('test')}>
          <div className={b('title')}><p>{ title || ROLES }</p></div>
          {isOpen
            ? (
              <div className={toBottom ? b('items-list-to-bottom') : b('items-list')}>
                {sortedRoles.map((item) => (
                  <div
                    className={b('item-in-list')}
                    onClick={() => this.addItem(item, item.assetApprove)}
                    role="button"
                    tabIndex={0}
                    key={item.value}
                    name={item.assetApprove ? `Approve-${item.assetApprove}` : null}
                  >
                    {item.text}
                  </div>
                )) }
              </div>
            )
            : null}
          <div className={b('select-wrap')}>
            <div className={b('select', { error, disabled })}>
              <div className={b('roles-container-wrap')}>
                {selectedRoles.length ? null : <div className={b('placeholder')}><p>{NOT_CHOOSED}</p></div>}
                {selectedRoles.map((item) => (
                  <div className={b('role')} key={`role${item.value}`}>
                    <div className={b('role-wrap')}>
                      {this.getRole(item)}
                      <Icon name="delete" className={b('delete').toString()} onClick={() => this.deleteItem(item, item.assetApprove)} />
                    </div>
                  </div>
                ))}
              </div>
              <div className={b('click-area')} onClick={this.openItemsList} role="button" tabIndex={0} ref={this.setWrapperRef}>
                <div className={b('click-area-dropdown-icon')}><Icon name="dropdown" /></div>
                <Icon name="write" className={b('icon-write-select-multi').toString()} />
              </div>
            </div>
            {error ? <p className={b('error')}>{required}</p> : null}
          </div>
        </div>
      </div>
    );
  }
}

MultiSelect.propTypes = propTypes;
MultiSelect.defaultProps = defaultProps;
export default MultiSelect;
