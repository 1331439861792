import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { FlexTable } from 'components';
import { getFormattedDate } from 'helpers';
import { withCustomRouter } from 'HOC';
import template from './template';
import './styles.scss';

const {
  STOCK_BASKET,
  PAYOUT,
  BARRIERS,
  COUPON,
  DOING_END,
  PERIOD,
  BASKETS_NOT_FOUND,
} = LANG_DICTIONARY;
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  selectedItem: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  setSelectedItem: PropTypes.func,
};
const defaultProps = {
  data: [],
  selectedItem: null,
  setSelectedItem: () => null,
};
const FlexTableConstructorIsz = ({ data, selectedItem, setSelectedItem }) => {
  const headers = [
    STOCK_BASKET,
    PAYOUT,
    BARRIERS,
    PERIOD,
    COUPON,
    DOING_END,
  ];
  const numberOfFields = data.length;
  const rebuildData = data.map(({
    basket,
    pay,
    barriers,
    term,
    coupon,
    dateEnd,
    id,
  }) => template(headers, [
    basket,
    pay,
    barriers,
    term,
    coupon && `${coupon}%`,
    dateEnd && getFormattedDate(dateEnd),
    `${id}basket`,
  ]));
  const checkForNoInfo = data.map(({ id }) => (id));
  const placeholderCheck = checkForNoInfo.filter((id) => id === undefined);
  const lastCheck = placeholderCheck.length === numberOfFields;

  return (
    <div>
      {!lastCheck && (
        <FlexTable
          data={rebuildData}
          minHeight={45}
          selectedItem={selectedItem}
          setSelectedItem={setSelectedItem}
        />
      )}
      {lastCheck && <div className="placeholder-not-found"><p>{BASKETS_NOT_FOUND}</p></div>}
    </div>
  );
};

FlexTableConstructorIsz.propTypes = propTypes;
FlexTableConstructorIsz.defaultProps = defaultProps;
export default withCustomRouter(FlexTableConstructorIsz);
