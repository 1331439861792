import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('nszProduct');

export const GET_PRODUCT_INFO = factory.createAsync('GET_PRODUCT_INFO');
export const CHANGE_FIELD = factory.create('CHANGE_FIELD');
export const GET_GENDER_LIST = factory.createAsync('GET_GENDER_LIST');
export const CLEAR_STORE_NSZ_PRODUCTS = factory.create('CLEAR_STORE_NSZ_PRODUCTS');
export const RISKS_LIST_CONTROL = factory.create('RISKS_LIST_CONTROL');
export const GET_ADDITIONAL_RISKS = factory.createAsync('GET_ADDITIONAL_RISKS');
export const GET_PACKAGE_RISKS = factory.createAsync('GET_PACKAGE_RISKS');
export const COUNT_SUM_NSZ = factory.createAsync('COUNT_SUM_NSZ');
export const SAVE_DATA = factory.create('SAVE_DATA');
export const CHECK_ACCOUNT_NUMBERS = factory.createAsync('CHECK_ACCOUNT_NUMBER');
export const SET_STAGE_PRODUCT = factory.create('SET_STAGE_PRODUCT');
export const PRINT_PAYMENT = factory.createAsync('PRINT_PAYMENT');
export const FILL_TEST_DATA_INSURER_NSZ = factory.create('FILL_TEST_DATA_INSURER_NSZ');
export const FILL_TEST_DATA_INSURED_NSZ = factory.create('FILL_TEST_DATA_INSURED_NSZ');
export const FILL_TEST_DATA_BENEFICIARY_NSZ = factory.create('FILL_TEST_DATA_BENEFICIARY_NSZ');
