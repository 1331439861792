import React from 'react';
import { block } from 'bem-cn';
import { Row, Col } from 'reactstrap';
import { Select } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { FlexTableAdminProducts, MainBtn, CheckCircleOrCloseIcon } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  currentPartner: PropTypes.number,
  currentProduct: PropTypes.number,
  handleSearchOnSelect: PropTypes.func,
  changeProduct: PropTypes.func,
  cancelButton: PropTypes.func,
  saveButton: PropTypes.func,
  setStatusForAllProducts: PropTypes.func,
};

const defaultProps = {
  partners: [],
  products: [],
  tableInfo: [],
  currentPartner: '',
  currentProduct: '',
  handleSearchOnSelect: () => null,
  changeProduct: () => null,
  cancelButton: () => null,
  saveButton: () => null,
  setStatusForAllProducts: () => null,
};

const {
  PARTNER,
  PRODUCT,
  CANCEL_FOR_ALL_PRODUCTS,
  ACTIVATING_FOR_ALL_PRODUCTS,
  SAVE_WORD,
  REVOKE,
} = LANG_DICTIONARY;
const b = block('admin-products');

const ProductsAdminForm = ({
  tableInfo,
  partners,
  products,
  currentPartner,
  currentProduct,
  handleSearchOnSelect,
  changeProduct,
  cancelButton,
  saveButton,
  setStatusForAllProducts,
}) => (
  <div className={b()}>
    <div className={b('controls')}>
      <Row>
        <Col md="6" sm="6">
          <Select
            options={partners}
            value={currentPartner}
            placeholder={PARTNER}
            onChange={handleSearchOnSelect('currentPartner')}
          />
        </Col>
        <Col md="6" sm="6">
          <Select
            options={products}
            value={currentProduct}
            placeholder={PRODUCT}
            onChange={handleSearchOnSelect('currentProduct')}
          />
        </Col>
      </Row>
      <FlexTableAdminProducts data={tableInfo} changeProduct={changeProduct} />
    </div>
    <div className={b('footer')}>
      <MainBtn text={REVOKE} onClick={cancelButton} />
      <div className={b('check-box-control')}>
        <MainBtn
          text={(
            <div className={b('control-buttons').toString()}>
              <CheckCircleOrCloseIcon />
              <div className={b('control-buttons-text')}>
                {CANCEL_FOR_ALL_PRODUCTS}
              </div>
            </div>
          )}
          onClick={() => setStatusForAllProducts(false)}
        />
        <MainBtn
          className={b('accept-btn').toString()}
          text={(
            <div className={b('control-buttons')}>
              <CheckCircleOrCloseIcon status />
              <div className={b('control-buttons-text')}>{ACTIVATING_FOR_ALL_PRODUCTS}</div>
            </div>
          )}
          onClick={() => setStatusForAllProducts(true)}
        />
      </div>
      <MainBtn text={SAVE_WORD} onClick={saveButton} disabled={!tableInfo.length} />
    </div>
  </div>
);

ProductsAdminForm.propTypes = propTypes;
ProductsAdminForm.defaultProps = defaultProps;

export default ProductsAdminForm;
