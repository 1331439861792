const NORMAL_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const NORMAL_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
};

const NORMAL_BENEFICIARY = {};

const KSZ_NORMAL_SETTINGS = {
  insurrerAgent: NORMAL_AGENT,
  insurredPerson: NORMAL_PERSON,
  beneficiary: NORMAL_BENEFICIARY,
};

export default KSZ_NORMAL_SETTINGS;
