const GET_VERSION_DP_REQUEST = 'versionDp/GET_VERSION_DP_REQUEST';
const GET_VERSION_DP_SUCCESS = 'versionDp/GET_VERSION_DP_SUCCESS';
const GET_VERSION_DP_FAIL = 'versionDp/GET_VERSION_DP_FAIL';
const GET_VERSION_DP_START = 'versionDp/GET_VERSION_DP_START';

const CLEAR_VERSION_DP = 'versionDp/CLEAR_VERSION_DP';
const SET_STATUS_MODAL_MIGRATIONS = 'versionDp/SET_STATUS_MODAL_MIGRATIONS';

export {
  SET_STATUS_MODAL_MIGRATIONS,
  CLEAR_VERSION_DP,
  GET_VERSION_DP_FAIL,
  GET_VERSION_DP_REQUEST,
  GET_VERSION_DP_START,
  GET_VERSION_DP_SUCCESS,
};
