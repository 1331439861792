import React, { useState } from 'react';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import { FlexTable, CustomCheckBox, DocsTransferMessage } from 'components';
import PropTypes from 'prop-types';
import template from './template';

const {
  DATE,
  ORGANISATION,
  RECIPIENT,
  MESSAGE,
  HAVE_RECEIVED,
} = LANG_DICTIONARY;

const headers = [
  DATE,
  ORGANISATION,
  RECIPIENT,
  MESSAGE,
  HAVE_RECEIVED,
];

const propTypes = {
  data: PropTypes.array,
  className: PropTypes.string,
  isLoading: PropTypes.bool,
};

const defaultProps = {
  data: [],
  className: '',
  isLoading: false,
};

const FlexTableSentMessage = ({ data, className, isLoading }) => {
  const [openedMessageId, setOpenedMessageId] = useState(null);

  const b = block(className);

  const rebuildData = data.map(({
    id,
    importDate,
    recipientFullName,
    recipientPartnerName,
    haveRead,
    messageText,
  }) => template(headers, [
    id,
    importDate,
    recipientPartnerName,
    recipientFullName,
    <DocsTransferMessage
      messageId={id}
      messageText={messageText}
      openedMessageId={openedMessageId}
      setOpenedMessageId={setOpenedMessageId}
    />,
    <CustomCheckBox
      name="check"
      checked={haveRead}
      disabled
    />,
  ]));

  return (
    <div className={b('messages-wrapper', { loading: isLoading })}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableSentMessage.propTypes = propTypes;
FlexTableSentMessage.defaultProps = defaultProps;
export default FlexTableSentMessage;
