import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY, DATE_FORMAT } from 'consts';
import moment from 'moment';
import {
  Dimmer,
  Icon,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { DOWNLOAD_CONTEST_DATA } from 'helpers/rolesConfig/roles';
import UserCardInfo from './components/UserCardInfo';
import ContestResult from './components/ContestResults';
import './styles.scss';

const {
  DOWNLOADING,
  RESULTS_FOR,
  CONTEST_DOWNLOAD,
} = LANG_DICTIONARY;

const b = block('contests-form');

const propTypes = {
  images: PropTypes.object.isRequired,
  clusters: PropTypes.object.isRequired,
  contests: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCluster: PropTypes.func.isRequired,
  getDownloadContest: PropTypes.func.isRequired,
  roles: PropTypes.object.isRequired,
};

const ContestsForm = ({
  images,
  clusters,
  contests,
  getCluster,
  getDownloadContest,
  roles,
}) => {
  const getDescriptionImg = (contest) => (
    <img
      src={images[contest.compId]}
      alt="desc"
      className={b('contest-image')}
    />
  );

  const loader = (
    <Segment className={b('segment').toString()}>
      <Dimmer
        active
        inverted
        className={b('dimmer').toString()}
      >
        <div className={b('loader-wrapper')}>
          <Loader inverted content={DOWNLOADING} />
        </div>
      </Dimmer>
    </Segment>
  );

  const downloadContest = (contest) => {
    getDownloadContest({
      compId: contest.compId,
      compName: contest.firstFrameDescription,
    });
  };

  const isDownloadContestBtn = roles[DOWNLOAD_CONTEST_DATA];

  return (
    <div className={b()}>
      {contests.map((contest) => (
        <div
          key={`${contest.compId}contest`}
          className={b('contest-wrapper')}
        >
          <section className={b('contest')}>
            <section className={b('contest-info')}>
              <p className={b('contest-title')}>
                {contest.firstFrameDescription}
              </p>
              <div className={b('contest-description')}>
                {images[contest.compId] ? getDescriptionImg(contest) : loader }
              </div>
            </section>
            <section className={b('contest-info')}>
              <p className={b('result-title')}>
                {`${RESULTS_FOR} ${moment().format(DATE_FORMAT)}`}
              </p>
              <div className={b('contest-result')}>
                {contest.clusters.map(({ id, number, isPlan }) => (
                  <ContestResult
                    cluster={clusters[id] || []}
                    key={`cluster${id}`}
                    getCluster={getCluster}
                    clusterId={id}
                    compId={contest.compId}
                    clusterNumber={number}
                    particType={contest.particType}
                    measureType={contest.measureType}
                    isPlan={isPlan}
                  />
                ))}
              </div>
              <div className={b('contest-controls')}>
                {isDownloadContestBtn && (
                  <button type="button" className="ui button" onClick={() => downloadContest(contest)}>
                    <Icon name="download" />
                    {CONTEST_DOWNLOAD}
                  </button>
                )}
              </div>
            </section>
          </section>
          <section className={b('user-info')}>
            {Boolean(contest.personalResult) && (
              <UserCardInfo
                user={contest.personalResult}
                compId={contest.compId}
              />
            )}
          </section>
        </div>
      ))}
    </div>
  );
};

ContestsForm.propTypes = propTypes;
export default ContestsForm;
