import React, { useCallback } from 'react';
import { Icon, Select } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import Scroll from 'react-scroll';
import './styles.scss';

const b = block('custom-select-input');

const propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  scrollId: PropTypes.string,
  error: PropTypes.string,
  loading: PropTypes.bool,
  disabled: PropTypes.bool,
  search: PropTypes.bool,
  // eslint-disable-next-line
  value: PropTypes.any,
};

const defaultProps = {
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  name: '',
  value: null,
  options: [],
  placeholder: '',
  scrollId: '',
  error: '',
  search: true,
  loading: false,
  disabled: false,
  required: false,
};

const CustomSelect = ({
  options,
  onChange,
  onBlur,
  onFocus,
  name,
  value,
  placeholder,
  required,
  scrollId,
  error,
  loading,
  disabled,
  search,
}) => {
  const handleChange = useCallback((e, target) => {
    onChange({ target });
  }, []);

  const handleBlur = useCallback((e, target) => {
    onBlur({ target });
  }, []);

  const handleFocus = useCallback((e, target) => {
    onFocus({ target });
  }, []);

  const isError = Boolean(error);

  return (
    <Scroll.Element name={`scroll${scrollId}`} className={b()}>
      <div className={b('select-wrapper')}>
        <div className={b('select')}>
          <Select
            options={options}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={handleBlur}
            onFocus={handleFocus}
            value={value}
            name={name}
            loading={loading}
            error={isError}
            disabled={disabled}
            search={search}
          />
          {required && <Icon name="write" className={b('required-icon', { loading: isError }).toString()} />}
        </div>
        {isError && <p className={b('error')}>{error}</p>}
      </div>
    </Scroll.Element>
  );
};

CustomSelect.propTypes = propTypes;
CustomSelect.defaultProps = defaultProps;

export default CustomSelect;
