import {
  GET_PRODUCT_INFO,
  CHANGE_FIELD,
  GET_GENDER_LIST,
  CLEAR_STORE_NSZ_PRODUCTS,
  RISKS_LIST_CONTROL,
  GET_ADDITIONAL_RISKS,
  GET_PACKAGE_RISKS,
  COUNT_SUM_NSZ,
  SAVE_DATA,
  CHECK_ACCOUNT_NUMBERS,
  SET_STAGE_PRODUCT,
  PRINT_PAYMENT,
  FILL_TEST_DATA_INSURER_NSZ,
  FILL_TEST_DATA_INSURED_NSZ,
  FILL_TEST_DATA_BENEFICIARY_NSZ,
} from '../types';

export const fillTestDataBeneficiaryNsz = (beneficiaries, beneficiaryInLaw) => ({
  type: FILL_TEST_DATA_BENEFICIARY_NSZ,
  payload: {
    beneficiaries,
    beneficiaryInLaw,
  },
});

export const fillTestDataInsuredNsz = (insured, insurerIsInsured, insuredChild) => ({
  type: FILL_TEST_DATA_INSURED_NSZ,
  payload: { data: insured, insurerIsInsured, insuredChild },
});

export const fillTestDataInsurerNsz = (data) => ({
  type: FILL_TEST_DATA_INSURER_NSZ,
  payload: data,
});

export const checkAccountNumbersNsz = (num) => ({
  type: CHECK_ACCOUNT_NUMBERS.request,
  payload: num,
});

export const saveDataNszForm = (data) => ({
  type: SAVE_DATA,
  payload: data,
});

export const countSumCalculator = (body) => ({
  type: COUNT_SUM_NSZ.request,
  payload: body,
});

export const getPackageRisks = (
  {
    id,
    insuredBirthDate,
    termId,
    scenario,
    childInsuredBirthDate,
  },
) => ({
  type: GET_PACKAGE_RISKS.request,
  payload: {
    id,
    insuredBirthDate,
    termId,
    scenario,
    childInsuredBirthDate,
  },
});

export const getAdditionalRisks = (
  {
    id,
    insuredBirthDate,
    termId,
    scenario,
    childInsuredBirthDate,
  },
) => ({
  type: GET_ADDITIONAL_RISKS.request,
  payload: {
    id,
    insuredBirthDate,
    termId,
    scenario,
    childInsuredBirthDate,
  },
});

export const risksListControl = (name, value) => ({
  type: RISKS_LIST_CONTROL,
  payload: { name, value },
});

export const clearStoreNszProducts = () => ({
  type: CLEAR_STORE_NSZ_PRODUCTS,
});

export const changeFieldNszProduct = (name, value) => ({
  type: CHANGE_FIELD,
  payload: { name, value },
});

export const getNszProductInfo = (id) => ({
  type: GET_PRODUCT_INFO.request,
  payload: id,
});

export const getGenderList = () => ({
  type: GET_GENDER_LIST.request,
});

export const setStageProduct = (stage) => ({
  type: SET_STAGE_PRODUCT,
  payload: stage,
});

export const printPayment = ({ insLineNszCalcId, insProductId }) => ({
  type: PRINT_PAYMENT.request,
  payload: { insLineNszCalcId, insProductId },
});
