import AsyncActionFactory from 'helpers/actionsFactory';

const GET_BRANCHES_FOR_TABLE_REQUEST = 'branchesAdminInterface/GET_BRANCHES_FOR_TABLE_REQUEST';
const GET_BRANCHES_FOR_TABLE_START = 'branchesAdminInterface/GET_BRANCHES_FOR_TABLE_START';
const GET_BRANCHES_FOR_TABLE_SUCCESS = 'branchesAdminInterface/GET_BRANCHES_FOR_TABLE_SUCCESS';
const GET_BRANCHES_FOR_TABLE_FAIL = 'branchesAdminInterface/GET_BRANCHES_FOR_TABLE_FAIL';

const CREATE_BRANCH_REQUEST = 'branchesAdminInterface/CREATE_BRANCH_REQUEST';
const CREATE_BRANCH_SUCCESS = 'branchesAdminInterface/CREATE_BRANCH_SUCCESS';
const CREATE_BRANCH_FAIL = 'branchesAdminInterface/CREATE_BRANCH_FAIL';
const CREATE_BRANCH_START = 'branchesAdminInterface/CREATE_BRANCH_START';

const SET_PAGE_PAGINATION = 'branchesAdminInterface/SET_PAGE_PAGINATION';

const SEARCH_BY_BRANCHES_NAME = 'branchesAdminInterface/SEARCH_BY_BRANCHES_NAME';

const GET_BRANCH_DATA_REQUEST = 'branchesAdminInterface/GET_BRANCH_DATA_REQUEST';
const GET_BRANCH_DATA_START = 'branchesAdminInterface/GET_BRANCH_DATA_START';
const GET_BRANCH_DATA_SUCCESS = 'branchesAdminInterface/GET_BRANCH_DATA_SUCCESS';
const GET_BRANCH_DATA_FAIL = 'branchesAdminInterface/GET_BRANCH_DATA_FAIL';

const UPDATE_BRANCH_REQUEST = 'branchesAdminInterface/UPDATE_BRANCH_REQUEST';
const UPDATE_BRANCH_SUCCESS = 'branchesAdminInterfacee/UPDATE_BRANCH_SUCCESS';
const UPDATE_BRANCH_FAIL = 'branchesAdminInterface/UPDATE_BRANCH_FAIL';
const UPDATE_BRANCH_START = 'branchesAdminInterface/UPDATE_BRANCH_START';

const CLEAR_FORM = 'branchesAdminInterface/CLEAR_FORM';

const SET_INITIAL_VALUES = 'branchesAdminInterface/SET_INITIAL_VALUES';

const CONTROL_BRANCHES_POPUPS = 'branchesAdminInterface/CONTROL_BRANCHES_POPUPS';

const factory = new AsyncActionFactory('contests');
const GET_USERS = factory.createAsync('GET_USERS');
const branchesFactory = new AsyncActionFactory('branches');
const GET_LIST_OF_CTS_SOURCE = branchesFactory.createAsync('GET_LIST_OF_CTS_SOURCE');

export {
  GET_USERS,
  CONTROL_BRANCHES_POPUPS,
  SET_INITIAL_VALUES,
  CLEAR_FORM,
  UPDATE_BRANCH_FAIL,
  UPDATE_BRANCH_START,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
  GET_BRANCH_DATA_REQUEST,
  GET_BRANCH_DATA_FAIL,
  GET_BRANCH_DATA_START,
  GET_BRANCH_DATA_SUCCESS,
  CREATE_BRANCH_FAIL,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_START,
  CREATE_BRANCH_SUCCESS,
  SET_PAGE_PAGINATION,
  SEARCH_BY_BRANCHES_NAME,
  GET_BRANCHES_FOR_TABLE_START,
  GET_BRANCHES_FOR_TABLE_SUCCESS,
  GET_BRANCHES_FOR_TABLE_FAIL,
  GET_BRANCHES_FOR_TABLE_REQUEST,
  GET_LIST_OF_CTS_SOURCE,
};
