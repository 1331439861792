import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { withCustomRouter } from 'HOC';
import { connect } from 'react-redux';
import {
  cancelCurrentJob,
  startAutoImport,
  parseXmlFile,
  closePopupImportFileFrom1C,
  changeAutoImportSetting,
  setInitStoreImportManual,
} from 'redux/rootActions';
import PropTypes from 'prop-types';
import Unloading1C from './unloading1C';
import ImportFrom1C from './importFrom1C';
import './styles.scss';

const {
  TRADE_DATAS,
  EXPORT_IN_1C,
  IMPORT_FROM_1C,
} = LANG_DICTIONARY;

const {
  transferData,
  transferDataImportManual,
} = ROUTES;

const b = block('administration-force-data');

const propTypes = {
  history: PropTypes.object,
  getJob: PropTypes.func,
  timeStart: PropTypes.string,
  isActive: PropTypes.bool,
  timeRepeat: PropTypes.number,
  changeSettingAutoImport: PropTypes.func,
  parseFile: PropTypes.func,
  startImportManual: PropTypes.func,
  errorFilesDir: PropTypes.string,
  newFilesDir: PropTypes.string,
  successFilesDir: PropTypes.string,
  startImportAuto: PropTypes.func,
  importFrom1CPartners: PropTypes.arrayOf(PropTypes.object),
  importFrom1CStatuses: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.string),
  timeNext: PropTypes.string,
  timeSuccess: PropTypes.string,
  cancelJob: PropTypes.func,
  statusSuccessJob: PropTypes.bool,
  match: PropTypes.object,
  isImportManualLoading: PropTypes.bool,
  isSuccessImport: PropTypes.bool,
  clearStoreImportManual: PropTypes.func,
};

const defaultProps = {
  match: {},
  statusSuccessJob: false,
  history: {},
  getJob: () => null,
  timeNext: '',
  timeSuccess: '',
  cancelJob: () => null,
  errorFilesDir: '',
  newFilesDir: '',
  successFilesDir: '',
  startImportAuto: () => null,
  startImportManual: () => null,
  parseFile: () => null,
  changeSettingAutoImport: () => null,
  timeRepeat: '',
  isActive: false,
  timeStart: '',
  importFrom1CPartners: [],
  importFrom1CStatuses: [],
  errors: [],
  isImportManualLoading: false,
  isSuccessImport: false,
  clearStoreImportManual: () => null,
};

const CONSTANTS = {
  Unloading1C: 'Unloading1C',
  ImportFrom1C: 'ImportFrom1C',
  exportForm: 'transfer-data-export',
  importForm: 'transfer-data-import-manual',
  importFormAuto: 'transfer-data-import-auto',
};

class Administration extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      active: Unloading1C,
      name: CONSTANTS.Unloading1C,
      import1c: ImportFrom1C,
      export1c: Unloading1C,
    };
  }

  componentDidMount = () => {
    const { match, clearStoreImportManual } = this.props;
    const { importForm, importFormAuto } = CONSTANTS;
    const pathToArray = match.path.split('/');
    const typeOf1CAction = pathToArray[pathToArray.length - 1];
    clearStoreImportManual();
    if (typeOf1CAction === importForm || typeOf1CAction === importFormAuto) {
      this.setState({
        active: ImportFrom1C,
        name: CONSTANTS.ImportFrom1C,
      });
    }
  }

  handleChangeBreadCrumb = (name) => {
    const { import1c, export1c } = this.state;
    const { history } = this.props;
    if (name === CONSTANTS.Unloading1C) {
      this.setState({
        active: export1c,
        name,
      });
      history.push(transferData);
    }
    if (name === CONSTANTS.ImportFrom1C) {
      this.setState({
        active: import1c,
        name,
      });
      history.push(transferDataImportManual);
    }
  }

  render() {
    const { active: Component, name } = this.state;
    const {
      cancelJob,
      getJob,
      startImportAuto,
      startImportManual,
      parseFile,
      importFrom1CPartners,
      importFrom1CStatuses,
      errors,
      errorFilesDir,
      isActive,
      newFilesDir,
      successFilesDir,
      timeNext,
      timeRepeat,
      timeStart,
      timeSuccess,
      changeSettingAutoImport,
      statusSuccessJob,
      isImportManualLoading,
      isSuccessImport,
      clearStoreImportManual,
    } = this.props;

    return (
      <div className={b()}>
        <section className={b('header-menu')}>
          <div className={b('header-title')}>
            {TRADE_DATAS}
          </div>
          <div
            tabIndex={-1}
            role="button"
            onClick={() => this.handleChangeBreadCrumb('Unloading1C')}
            className={name === 'Unloading1C' ? b('switch-button-active') : b('switch-button')}
          >
            {EXPORT_IN_1C}
          </div>
          <div
            tabIndex={-1}
            role="button"
            onClick={() => this.handleChangeBreadCrumb('ImportFrom1C')}
            className={name === 'ImportFrom1C' ? b('switch-button-import-active') : b('switch-button-import')}
          >
            {IMPORT_FROM_1C}
          </div>
        </section>
        <Component
          parentClass={b}
          cancelJob={cancelJob}
          getJob={getJob}
          startImportAuto={startImportAuto}
          startImportManual={startImportManual}
          importFrom1CPartners={importFrom1CPartners}
          importFrom1CStatuses={importFrom1CStatuses}
          parseFile={parseFile}
          errors={errors}
          successImport={isSuccessImport}
          popupIsOpen={Boolean(isSuccessImport || errors.length)}
          closePopup={clearStoreImportManual}
          errorFilesDir={errorFilesDir}
          isActive={isActive}
          newFilesDir={newFilesDir}
          successFilesDir={successFilesDir}
          timeNext={timeNext}
          timeRepeat={timeRepeat}
          timeStart={timeStart}
          timeSuccess={timeSuccess}
          loadingImport={isImportManualLoading}
          changeSettingAutoImport={changeSettingAutoImport}
          statusSuccessAutoImportJob={statusSuccessJob}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  helperReducer,
  transferData: {
    errors,
    isImportManualLoading,
    isSuccessImport,
  },
}) => ({
  ...helperReducer,
  errors,
  isImportManualLoading,
  isSuccessImport,
});

const mapDispatchToProps = {
  cancelJob: cancelCurrentJob,
  startImportAuto: startAutoImport,
  parseFile: parseXmlFile,
  closePopUp: closePopupImportFileFrom1C,
  changeSettingAutoImport: changeAutoImportSetting,
  clearStoreImportManual: setInitStoreImportManual,
};

Administration.propTypes = propTypes;
Administration.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(Administration));
