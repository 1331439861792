import React from 'react';
import { connect } from 'react-redux';
import { Button, Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { CHECK_IS_ACTIVE_CONTEST } from 'helpers/rolesConfig/roles';
import {
  UserInfo,
  EventsFormBtn,
  SiteVersion,
  BonusBtn,
} from 'components';
import { getUnitedFrontendParamsSelector, sharedSelectors, getUnreadMessagesCountSelector } from 'redux/rootSelectors';
import { toggleModalAffiliation, toggleMenu } from 'redux/rootActions';
import branchIcon from 'assets/images/change_branch.png';
import { toastr } from 'react-redux-toastr';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import './styles.scss';
import { checkAccessToDocumentCheckStatusButton, checkAccessToEventsButton } from './helpers';

const {
  myClients,
  home,
  signIn,
  events,
  documentCheckStatus,
  customerReportingService,
  createDocsTransferMessage,
  docsTransfer,
} = ROUTES;

const b = block('header-panel');

const propTypes = {
  ToggleMenu: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  dataUserExist: PropTypes.bool,
  toggleModal: PropTypes.func,
  accessBranches: PropTypes.arrayOf(PropTypes.object),
  accessPartners: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.object.isRequired,
  isUnitedFront: PropTypes.bool.isRequired,
  query: PropTypes.object.isRequired,
  masterId: PropTypes.string,
  riskProfile: PropTypes.string,
  isShowAcceptAgreement: PropTypes.bool,
  gurole: PropTypes.array,
  isReportsAvailable: PropTypes.bool,
  inboxUnreadMessagesCount: PropTypes.number,
};

const defaultProps = {
  dataUserExist: false,
  toggleModal: () => null,
  accessBranches: [],
  accessPartners: [],
  masterId: null,
  riskProfile: null,
  isShowAcceptAgreement: false,
  gurole: [],
  isReportsAvailable: false,
  inboxUnreadMessagesCount: 0,
};

const {
  DIGITAL_PLATFORM,
  GO_TO_HOME,
  MY_SALES,
  MY_DOCUMENTS,
  ENTRANCE_FROM_UNITED_FRONTEND,
  READ_MODE,
  DOCUMENT_CHECK_STATUS,
  CUSTOMER_REPORTING_SERVICE,
  UNITED_FRONTEND_WITHOUT_MASTER_ID,
  DOCS_TRANSFER,
} = LANG_DICTIONARY;

const HeadPanel = (props) => {
  const {
    history: { location: { pathname } },
    history,
    ToggleMenu,
    dataUserExist,
    toggleModal,
    accessBranches,
    accessPartners,
    roles,
    isUnitedFront,
    query,
    masterId,
    riskProfile,
    isShowAcceptAgreement,
    gurole,
    isReportsAvailable,
    inboxUnreadMessagesCount,
  } = props;

  const link = dataUserExist ? home : signIn;

  const getCurrentPage = (isCurrentPage) => (isCurrentPage ? 'sectionButton active' : 'sectionButton');
  const isProductPage = pathname === home || pathname.indexOf('/product') !== -1;
  const isSalesPage = pathname === myClients;
  const isDocumentCheckStatusPage = pathname === documentCheckStatus;
  const isCustomerReportingServicePage = pathname === customerReportingService;
  const isDocsTransfer = pathname.includes(docsTransfer);
  const isUnreadInboxMessages = inboxUnreadMessagesCount > 0;

  const switchTab = (tab) => () => {
    history.push(tab);
  };

  const handleClickEvents = () => {
    if (pathname.includes('/product')) {
      toastr.error('', GO_TO_HOME);
    } else {
      history.push(events);
    }
  };

  const openPopupAffiliation = () => {
    if (pathname.includes('/product')) {
      toastr.error('', GO_TO_HOME);
    } else {
      toggleModal(true);
    }
  };

  const isIconSelectBranches = accessBranches.length > 1 || accessPartners.length > 1;
  const isBonusBtn = roles[CHECK_IS_ACTIVE_CONTEST];

  const isShowUnitedFrontend = dataUserExist ?
    isUnitedFront :
    Boolean(query.masterId || query.riskProfile || query.qualification !== undefined);
  const isReadMode = dataUserExist && (!masterId || !riskProfile);
  const isWithoutMasterId = !query.masterId;

  return (
    <section className={b()}>
      {isShowUnitedFrontend && (
        <div className={b('united-frontend', { read: isReadMode || isWithoutMasterId })}>
          <Icon name="warning sign" />
          <p className={b('united-frontend-content')}>
            {isWithoutMasterId ? UNITED_FRONTEND_WITHOUT_MASTER_ID : ENTRANCE_FROM_UNITED_FRONTEND}
          </p>
          {isReadMode && (<p className={b('united-frontend-content', { read: true })}>{`(${READ_MODE})`}</p>)}
        </div>
      )}
      <div className={b('main')}>
        <div className="logoWrap">
          <div role="button" tabIndex="-1" className="logoImg" onClick={switchTab(link)} />
          <div className="headSections">
            {
            (dataUserExist && !isShowAcceptAgreement)
              ? (
                <>
                  <Button
                    type="button"
                    className={getCurrentPage(isProductPage)}
                    onClick={switchTab(home)}
                  >
                    {MY_DOCUMENTS}
                  </Button>
                  <Button
                    type="button"
                    className={getCurrentPage(isSalesPage)}
                    onClick={switchTab(myClients)}
                  >
                    {MY_SALES}
                  </Button>
                  {isReportsAvailable && (
                    <Button
                      type="button"
                      className={getCurrentPage(isCustomerReportingServicePage)}
                      onClick={switchTab(customerReportingService)}
                    >
                      {CUSTOMER_REPORTING_SERVICE}
                    </Button>
                  )}
                  <Button
                    type="button"
                    className={getCurrentPage(isDocsTransfer)}
                    onClick={switchTab(createDocsTransferMessage)}
                  >
                    {DOCS_TRANSFER}
                    {isUnreadInboxMessages && (
                      <span className={b('docs-transfer-unread-messages')}>
                        {inboxUnreadMessagesCount}
                      </span>
                    )}
                  </Button>
                  <div
                    style={{ width: '140px' }}
                  />
                  {
                    checkAccessToDocumentCheckStatusButton(gurole) ? (
                      <Button
                        type="button"
                        className={getCurrentPage(isDocumentCheckStatusPage)}
                        onClick={switchTab(documentCheckStatus)}
                      >
                        {DOCUMENT_CHECK_STATUS}
                      </Button>
                    ) : null
                  }
                  <SiteVersion />
                </>
              ) : (<div className="subLogo">{ DIGITAL_PLATFORM }</div>)
          }
          </div>
        </div>
        {
        (dataUserExist && !isShowAcceptAgreement)
        && (
          <div className="rightSideContent">
            {isBonusBtn && (
              <BonusBtn
                className={b('bonus-btn').toString()}
                onClick={() => history.push(ROUTES.contests)}
              />
            )}
            {
              checkAccessToEventsButton(gurole)
              &&
              <EventsFormBtn onClick={handleClickEvents} />
            }
            {isIconSelectBranches && (
              <Button
                type="button"
                className="menuButton selectBranchIcon"
                onClick={openPopupAffiliation}
              >
                <img src={branchIcon} alt="branchIcon" />
              </Button>
            )}
            <UserInfo />
            <Button
              type="button"
              className="menuButton"
              onClick={() => ToggleMenu()}
            >
              <Icon name="bars" />
            </Button>
          </div>
        )
      }
      </div>
    </section>
  );
};

function mapStateToProps(state) {
  const {
    helperReducer,
    authReducer,
  } = state;

  return {
    ...helperReducer,
    ...authReducer,
    ...getUnitedFrontendParamsSelector(state),
    query: sharedSelectors.getQueryParams(state),
    inboxUnreadMessagesCount: getUnreadMessagesCountSelector(state),
  };
}

HeadPanel.propTypes = propTypes;
HeadPanel.defaultProps = defaultProps;

export default withCustomRouter(connect(mapStateToProps, {
  ToggleMenu: toggleMenu,
  toggleModal: toggleModalAffiliation,
})(HeadPanel));
