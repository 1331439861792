import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon, Loader } from 'semantic-ui-react';
import { LANG_DICTIONARY, DATE_LAST_IMPORT_PODFT } from 'consts';
import { UploadIcon, ConfirmationPopup } from 'components';
import ExcelIcon from 'assets/images/excel.png';
import './styles.scss';
import moment from 'moment';

const propTypes = {
  title: PropTypes.string.isRequired,
  nameInputFile: PropTypes.string,
  inputType: PropTypes.string,
  type: PropTypes.string,
  resetCurrError: PropTypes.func,
  uploadFile: PropTypes.func,
  isUpload: PropTypes.bool,
  currentFileUpload: PropTypes.string,
  errors: PropTypes.object,
  getExcelFile: PropTypes.func,
  handleChangeLink: PropTypes.func,
  link: PropTypes.string,
  resData: PropTypes.bool,
  metadataRequest: PropTypes.object,
  isConfirm: PropTypes.bool,
  confirmText: PropTypes.string,
  acceptFiles: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  icon: PropTypes.any,
  podftType: PropTypes.string,
  version: PropTypes.object.isRequired,
};

const defaultProps = {
  nameInputFile: '',
  inputType: 'file',
  type: '',
  resetCurrError: () => null,
  uploadFile: () => null,
  isUpload: false,
  currentFileUpload: '',
  errors: {},
  getExcelFile: () => null,
  handleChangeLink: () => null,
  link: '',
  resData: false,
  metadataRequest: {},
  isConfirm: false,
  confirmText: '',
  acceptFiles: '.xlsx,.xls',
  icon: '',
  podftType: '',
};

const getExampleType = (type) => `${type}_example`;
const {
  PLACEHOLDER_FILE_UPLOAD,
  UPLOAD_PROCESS,
  UPLOAD_LAST_DATE,
  UPLOAD_LAST_VERSION,
} = LANG_DICTIONARY;
const b = block('download-block');

class FileUploadAdmin extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isRemove: false,
      placeholder: PLACEHOLDER_FILE_UPLOAD,
      isSelectFile: false,
      isOpenConfirm: false,
    };

    const { nameInputFile, type } = props;
    this.inputName = nameInputFile || type;
  }

  handleChange = (e) => {
    const { files } = e.target;
    if (files.length) {
      const { name } = files[0];
      this.setState({
        placeholder: name,
        isRemove: true,
        isSelectFile: true,
      });
    }
  };

  removeFile = () => {
    const { resetCurrError, type } = this.props;
    this.setState({
      isRemove: false,
      placeholder: PLACEHOLDER_FILE_UPLOAD,
      isSelectFile: false,
    });
    resetCurrError(type);
    this.files.value = null;
  };

  renderInputUpload = () => {
    const { isUpload, handleChangeLink, link } = this.props;

    return (
      <div className={b('link-wrapper')}>
        <div className={b('download-link')}>
          <input
            onChange={handleChangeLink}
            type="text"
            className={b('input-text')}
            value={link}
          />
          <UploadIcon
            disabled={!link || isUpload}
            onClick={this.updatePassport}
          />
        </div>
        {this.showProcessUpload()}
      </div>
    );
  };

  downloadExcel = () => {
    const { type, getExcelFile } = this.props;
    getExcelFile(getExampleType(type));
  };

  updatePassport = () => {
    const { uploadFile, type, link } = this.props;
    uploadFile({ type, path: link });
  };

  uploadFile = () => {
    const { files } = this.files;
    const {
      uploadFile,
      podftType,
      resData,
      type,
      metadataRequest,
    } = this.props;
    const currentType = podftType || type;

    const formData = new FormData();
    formData.append('file', files[0]);
    formData.append('type', currentType);

    Object.entries(metadataRequest).forEach(([key, value]) => {
      formData.append(key, value);
    });

    uploadFile({
      file: formData,
      type: currentType,
      clearAction: this.removeFile,
      resData,
    }, files[0]);

    this.setState({ isOpenConfirm: false });
  };

  showError = () => {
    const { errors, podftType } = this.props;
    if (errors[podftType]) {
      return (
        <div className={b('error-block')}>
          <Icon name="warning sign" />
          <p className={b('error')}>{errors[podftType]}</p>
        </div>
      );
    }

    return null;
  };

  showProcessUpload = () => {
    const {
      type,
      podftType,
      isUpload,
      currentFileUpload,
    } = this.props;
    const currentType = podftType || type;

    if (currentFileUpload === currentType && isUpload) {
      return (
        <div className={b('loading-upload')}>
          <p>{UPLOAD_PROCESS}</p>
          <Loader active={isUpload} inline size="tiny" />
        </div>
      );
    }

    return null;
  };

  openConfirmPopup = () => {
    this.setState({ isOpenConfirm: true });
  }

  render() {
    const {
      title,
      nameInputFile,
      inputType,
      isUpload,
      isConfirm,
      confirmText,
      acceptFiles,
      icon,
      version,
    } = this.props;
    const {
      placeholder,
      isRemove,
      isSelectFile,
      isOpenConfirm,
    } = this.state;

    return (
      <div className={b()}>
        {isConfirm && (
          <ConfirmationPopup
            open={isOpenConfirm}
            text={confirmText}
            handleCancel={() => this.setState({ isOpenConfirm: false })}
            handleConfirm={this.uploadFile}
          />
        )}
        <div className={b('title-podft')}>
          <p className={b('title')}>{title}</p>
          {version && (
          <div className={b('podft-version')}>
            <p>
              {UPLOAD_LAST_DATE + (version.dateUpdate
                ? moment(version.dateUpdate).format(DATE_LAST_IMPORT_PODFT)
                : '-')}
            </p>
            <p>
              {UPLOAD_LAST_VERSION + (version.dateUpdate ? version.version : '-')}
            </p>
          </div>
          )}
        </div>
        {inputType === 'text' ? this.renderInputUpload() : (
          <>
            <div className={b('control-wrapper')}>
              <button
                className={b('button-excel')}
                // TODO исправить скачивание на форме Импорта
                onClick={this.downloadExcel}
                type="button"
                tabIndex="-1"
              >
                <img
                  alt=""
                  src={icon || ExcelIcon}
                  className={b('icon-wrapper')}
                />
              </button>
              <div className={b('field-block')}>
                <div className={b('field-upload')}>
                  <p>{placeholder}</p>
                  <label htmlFor={this.inputName} className={b('label')}>
                    <input
                      type="file"
                      id={this.inputName}
                      name={nameInputFile}
                      onChange={this.handleChange}
                      ref={(ref) => { this.files = ref; }}
                      accept={acceptFiles}
                    />
                    <div className={b('controllers')}>
                      {!isRemove && (<Icon name="plus" className="plusButton" />)}
                    </div>
                  </label>
                  {isRemove && !isUpload && (
                  <div
                    tabIndex="-1"
                    role="button"
                    onClick={this.removeFile}
                    className={b('remove-wrapper')}
                  >
                    <Icon name="times" className="removeButton" />
                  </div>
                  )}
                </div>
                {this.showProcessUpload()}
                {this.showError()}
              </div>
              <UploadIcon
                disabled={!isSelectFile || isUpload}
                onClick={isConfirm ? this.openConfirmPopup : this.uploadFile}
              />
            </div>
          </>
        )}
      </div>
    );
  }
}

FileUploadAdmin.propTypes = propTypes;
FileUploadAdmin.defaultProps = defaultProps;

export default FileUploadAdmin;
