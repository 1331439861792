import React from 'react';
import { PaginationPanel, FlexTablePublicApi } from 'components';
import PropTypes from 'prop-types';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  handleDownPage: PropTypes.func.isRequired,
  handleUpPage: PropTypes.func.isRequired,
};

const PublicApiListForm = ({
  data,
  currentPage,
  totalPages,
  handleDownPage,
  handleUpPage,
}) => (
  <div>
    <FlexTablePublicApi data={data} />
    <PaginationPanel
      active={currentPage}
      total={totalPages}
      handleDownPage={handleDownPage}
      handleUpPage={handleUpPage}
    />
  </div>
);

PublicApiListForm.propTypes = propTypes;
export default PublicApiListForm;
