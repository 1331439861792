import {
  all, put, takeLatest,
} from 'redux-saga/effects';
import {
  apiCall,
  getError,
  apiDownload,
  downloadFile,
} from 'helpers';
import { API, LANG_DICTIONARY } from 'consts';
import { toastr } from 'react-redux-toastr';

import {
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_START,
  DOWNLOAD_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_REQUEST,
  UPLOAD_IMPORT_FILE_REQUEST,
  UPLOAD_IMPORT_FILE_SUCCESS,
  UPLOAD_IMPORT_FILE_START,
  UPLOAD_IMPORT_FILE_FAIL,
  CREATE_SCHEDULE_TASK_FAIL,
  CREATE_SCHEDULE_TASK_REQUEST,
  CREATE_SCHEDULE_TASK_START,
  CANCEL_SCHEDULE_TASK_FAIL,
  CANCEL_SCHEDULE_TASK_REQUEST,
  CANCEL_SCHEDULE_TASK_START,
  GET_INFO_SCHEDULE_TASK_SUCCESS,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_START,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_FAIL,
  GET_IMPORT_VERSION,
  UPLOAD_PODFT_IMPORT_FILE,
} from '../types';

const { EXPORT_SUCCESS } = LANG_DICTIONARY;

function* getActiveTaskInfo({ payload }) {
  try {
    yield put({ type: GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_START });
    const { data } = yield apiCall({
      url: API.GET_ACTIVE_TASK_INFO_IMPORT_SCHEDULER,
      type: 'GET',
      query: {
        type: payload,
      },
    });
    yield put({ type: GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_FAIL, payload: getError(e) });
  }
}
function* downloadTemplate({ payload }) {
  const { GET_FILE_TEMPLATE } = API;
  const fileName = payload === 'import_extremism_xlsx_example' ? 'import_extremism_xml_example.xml' : `${payload}.xlsx`;
  try {
    yield put({ type: DOWNLOAD_TEMPLATE_START });
    yield apiDownload({
      headers: { 'Content-Type': 'multipart/form-data' },
      url: `${GET_FILE_TEMPLATE}/${payload}`,
    }, fileName);
    yield put({ type: DOWNLOAD_TEMPLATE_SUCCESS });
  } catch (error) {
    yield put({ type: DOWNLOAD_TEMPLATE_FAIL, payload: getError(error) });
  }
}

function* uploadFile({
  payload: {
    file,
    type,
    clearAction,
    path,
    resData,
  },
}) {
  const { IMPORT_FILE_ADMIN } = API;

  try {
    yield put({ type: UPLOAD_IMPORT_FILE_START, fileType: type });
    if (path) {
      yield apiCall({
        type: 'POST',
        body: {
          path,
          type,
        },
        url: IMPORT_FILE_ADMIN,
      });
    } else {
      const res = yield apiCall({
        type: 'POST',
        headers: { 'Content-Type': 'multipart/form-data' },
        body: file,
        url: IMPORT_FILE_ADMIN,
        isBlob: resData,
      });
      if (res.data !== true) downloadFile(res);
    }

    yield put({ type: UPLOAD_IMPORT_FILE_SUCCESS, fileType: type });

    toastr.success('', EXPORT_SUCCESS);

    if (clearAction) clearAction();
  } catch (error) {
    yield put({
      type: UPLOAD_IMPORT_FILE_FAIL,
      payload: getError(error),
      fileType: type,
    });
  }
}

function* uploadPodftFile({
  payload: {
    file,
    type,
    clearAction,
  },
}) {
  const { IMPORT_PODFT_FILE } = API;

  try {
    yield put({ type: UPLOAD_PODFT_IMPORT_FILE.start, fileType: type });
    yield apiCall({
      type: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: file,
      url: IMPORT_PODFT_FILE,
    });

    yield put({ type: UPLOAD_PODFT_IMPORT_FILE.success, fileType: type });

    toastr.success('', EXPORT_SUCCESS);

    if (clearAction) clearAction();
    yield getVersion({ payload: { uploadBlocksPodft: Array(type) } });
  } catch (error) {
    yield put({
      type: UPLOAD_PODFT_IMPORT_FILE.fail,
      payload: getError(error),
      fileType: type,
    });
  }
}

function* getVersion({ payload: { uploadBlocksPodft } }) {
  try {
    yield put({ type: GET_IMPORT_VERSION.start });
    const data = yield all(uploadBlocksPodft.map((item) => apiCall({
      url: API.GET_VERSION_DATE,
      type: 'GET',
      query: {
        type: item,
      },
    })));
    const versions = Object.fromEntries(data.map((res) => [
      res.config.params.type,
      res.data,
    ]));

    yield put({ type: GET_IMPORT_VERSION.success, payload: { versions } });
  } catch (e) {
    yield put({ type: GET_IMPORT_VERSION.fail, error: getError(e) });
  }
}

function* createTask({
  payload: {
    path,
    interval,
    time,
    action,
    isNetwork,
    cronTypeId,
    priorityId,
    taskType,
  },
}) {
  const { START_SCHEDULER_JOB } = API;
  yield put({ type: CREATE_SCHEDULE_TASK_START });
  try {
    const { data } = yield apiCall({
      type: 'POST',
      url: START_SCHEDULER_JOB,
      body: {
        taskType,
        priorityId,
        interval,
        time,
        cronTypeId,
        specificTaskData: {
          path,
          isNetwork,
        },
      },
    });
    yield put({ type: GET_INFO_SCHEDULE_TASK_SUCCESS, payload: data });
    if (action) action();
  } catch (error) {
    yield put({
      type: CREATE_SCHEDULE_TASK_FAIL,
      payload: getError(error),
    });
  }
}

function* cancelTask({ payload }) {
  const { STOP_SCHEDULER_JOB } = API;
  const { taskType, action } = payload;
  yield put({ type: CANCEL_SCHEDULE_TASK_START });
  try {
    const { data } = yield apiCall({
      type: 'DELETE',
      url: STOP_SCHEDULER_JOB,
      body: {
        taskType,
      },
    });
    yield put({ type: GET_INFO_SCHEDULE_TASK_SUCCESS, payload: data });
    if (action) action();
  } catch (error) {
    yield put({
      type: CANCEL_SCHEDULE_TASK_FAIL,
      payload: getError(error),
    });
  }
}

export default function* administrationSaga() {
  yield takeLatest(GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST, getActiveTaskInfo);
  yield takeLatest(CANCEL_SCHEDULE_TASK_REQUEST, cancelTask);
  yield takeLatest(CREATE_SCHEDULE_TASK_REQUEST, createTask);
  yield takeLatest(DOWNLOAD_TEMPLATE_REQUEST, downloadTemplate);
  yield takeLatest(UPLOAD_IMPORT_FILE_REQUEST, uploadFile);
  yield takeLatest(GET_IMPORT_VERSION.request, getVersion);
  yield takeLatest(UPLOAD_PODFT_IMPORT_FILE.request, uploadPodftFile);
}
