import {
  onlyNumbers,
  yupEmpty,
  checkSnilsForYup,
  validateEmail,
} from 'helpers';
import { LANG_DICTIONARY } from 'consts';

import { object, string, number } from 'yup';

const {
  REQUIRED, EMAIL_VALIDATION, FILL_COMPLETELY, CHECK_SUM_CUR_ACCOUNT,
} = LANG_DICTIONARY;

export default (id) => object().shape({
  snils: yupEmpty(
    string()
      .test('len', CHECK_SUM_CUR_ACCOUNT, checkSnilsForYup),
    CHECK_SUM_CUR_ACCOUNT,
  ),
  mobilePhone: yupEmpty(
    string()
      .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 11),
    FILL_COMPLETELY,
  ),
  email: string().test('len', EMAIL_VALIDATION, (val) => (val ? validateEmail(val) : true)),
  login: string().required(REQUIRED),
  password: id ? string() : string().required(REQUIRED),
  firstName: string().required(REQUIRED),
  lastName: string().required(REQUIRED),
  partnerUid: string().nullable(),
  partnerEnum: number().typeError('Табельный номер должен быть числом').nullable(),
});
