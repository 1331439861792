const NORMAL_CURRENCY_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: true,
  isProductStart: true,
  isPremium: true,
};

const NORMAL_CURRENCY_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const NORMAL_CURRENCY_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const NORMAL_CURRENCY_BENEFICIARY = {

};

const NORMAL_CURRENCY_SETTINGS = {
  preliminary: NORMAL_CURRENCY_PRELIMINARY,
  insurrerAgent: NORMAL_CURRENCY_AGENT,
  insurredPerson: NORMAL_CURRENCY_PERSON,
  beneficiary: NORMAL_CURRENCY_BENEFICIARY,
};

export default NORMAL_CURRENCY_SETTINGS;
