import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ReportsAdminForm, AdministrativeSectionInterface, PaginationPanel } from 'components';
import {
  getReportsForAdminTable,
  selectOption,
  setInitialValuesReportsAdministration,
} from 'redux/rootActions';
import { withCustomRouter } from 'HOC';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const propTypes = {
  active: PropTypes.number,
  location: PropTypes.object,
  page: PropTypes.number,
  setOption: PropTypes.func,
  loadingTableInfo: PropTypes.bool,
  history: PropTypes.object,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  setInitialValues: PropTypes.func,
  getReports: PropTypes.func,
  totalPages: PropTypes.number,
};

const defaultProps = {
  totalPages: 1,
  active: 0,
  location: {},
  page: 0,
  setOption: () => null,
  getReports: () => null,
  loadingTableInfo: false,
  history: {},
  tableInfo: [],
  setInitialValues: () => null,
};

const {
  PARTNERS_PREFIX,
} = LANG_DICTIONARY;

class ReportsAdministration extends React.Component {
  selectPrefix = 'reports';

  componentDidMount() {
    const {
      location: { state },
      page,
      setOption,
      getReports,
    } = this.props;
    const isCache = state && state.cache;
    const currentPage = isCache ? page : 1;
    const params = this.getQueryParams();
    const { setInitialValues } = this.props;
    if (!isCache) {
      setOption(PARTNERS_PREFIX, null, this.selectPrefix);
      setInitialValues();
    }

    getReports(currentPage, params);
  }

  getQueryParams = () => {
    const {
      [`${this.selectPrefix}partnersSelect`]: partnerId,
      reportsreportTypesSelect,
    } = this.props;
    const checkEmptyProp = (prop) => (prop !== null && prop !== '' ? prop : undefined);

    return {
      partnerId: checkEmptyProp(partnerId),
      reportTypeId: checkEmptyProp(reportsreportTypesSelect),
    };
  }

  handleDownPage = () => {
    const {
      page,
      getReports,
      loadingTableInfo,
    } = this.props;
    if (page > 1 && !loadingTableInfo) {
      getReports(page - 1, this.getQueryParams());
    }
  }

  handleUpPage = () => {
    const {
      page,
      getReports,
    } = this.props;
    getReports(page + 1, this.getQueryParams());
  }

  handleSearchOnSelect = (e, { value, name }) => {
    const {
      getReports, page,
    } = this.props;
    const params = this.getQueryParams();
    if (!value) {
      getReports(1, { ...params, [name]: undefined }, page);
    } else {
      getReports(1, {
        ...params,
        [name]: value,
      });
    }
  }

  render() {
    const {
      active,
      tableInfo,
      loadingTableInfo,
      page,
      history,
      totalPages,
    } = this.props;

    return (
      <AdministrativeSectionInterface
        active={active}
      >
        <ReportsAdminForm
          tableInfo={tableInfo}
          loadingTableInfo={loadingTableInfo}
          history={history}
          handleSearchOnSelect={this.handleSearchOnSelect}
        />
        <PaginationPanel
          active={page}
          total={totalPages}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  reportsAdminInterface,
  selects,
}) => ({
  ...reportsAdminInterface,
  ...selects,
});

const mapDispatchToProps = {
  setOption: selectOption,
  getReports: getReportsForAdminTable,
  setInitialValues: setInitialValuesReportsAdministration,
};
ReportsAdministration.propTypes = propTypes;
ReportsAdministration.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(ReportsAdministration));
