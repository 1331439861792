import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer, MainBtn } from 'components';
import './styles.scss';

const {
  ATTENTION,
  STOP_ACTIVE_TASK,
  YES,
  NO,
} = LANG_DICTIONARY;

const b = block('scheduler-stop-job');
const propTypes = {
  isOpen: PropTypes.bool,
  jobConrol: PropTypes.func,
  taskType: PropTypes.string,
};
const defaultProps = {
  isOpen: false,
  jobConrol: () => null,
  taskType: '',
};

const WarningPopUp = ({
  isOpen,
  jobConrol,
  taskType,
}) => {
  const confrimPopUp = (
    <div className={b()}>
      <div className={b('header')}>{`${ATTENTION}!`}</div>
      <div className={b('content')}>{STOP_ACTIVE_TASK}</div>
      <div className={b('footer')}>
        <MainBtn
          text={YES}
          className={b('confirm-button')}
          onClick={() => jobConrol(true, false, true, taskType, isOpen)}
        />
        <MainBtn
          text={NO}
          className={b('cancel-button')}
          onClick={() => jobConrol(true, false, false, false, isOpen)}
        />
      </div>
    </div>
  );

  return (
    <PopupContainer
      isOpen={isOpen}
      children={confrimPopUp}
    />
  );
};

WarningPopUp.propTypes = propTypes;
WarningPopUp.defaultProps = defaultProps;
export default WarningPopUp;
