import ENUMS from './enums';
import API from './apiUrls';
import MASK from './mask';
import INPUT_NAMES from './inputsNames';
import { NSZ_CALCULATOR_ENUMS } from './nszCalculatorEnums';
import {
  PRODUCT_VERSION,
  NAMES_GROUP_SETTINGS,
  ENVIRONMENT_ENUM,
  VALID_PODFT_TYPE,
  IMPORT_FILE_TYPE,
  TYPE_PERSON,
  RU_TYPE_PERSON,
  UNDERWRITING_STORE_LINK,
  STATUS_SCANS,
  TYPE_CHECKING_CONTRACT_RU,
  TYPE_CHECKING_CONTRACT,
  TYPE_ERROR_CHECKING_SCAN,
  ASSET_COLOR,
  ERROR_STATUSES,
  CURRENCY_REF,
  PAYOUT_FREQ,
  CURRENCY_CODE_REF,
  KEY_NAMES,
  NSZ_RISK_TYPE,
  REQUEST_ERROR_CODE,
  CONSTRUCTOR_ISZ_OPTION_IDS,
  UNITED_FRONTEND_HEADER,
  UNITED_FRONTEND_ENUM,
  CONTRACT_STATUS_CODES,
  UNITED_FRONTEND_ERRORS,
  REF_INS_STATUSES_CODE_ENUM,
  REF_INS_STATUSES_NAME_ENUM,
  TEMPLATES_MODAL_CODES,
  CONSTRUCTOR_ISZ_OPTION_CODES,
  PAYOUT_CODE_REF,
  TEST_DATA_ENUMS,
  IMPORT_PODFT_TYPE,
  SERVICE_HEADERS,
} from './maps';
import REGEXP from './regexp';
import LANG_DICTIONARY from './dictionary';
import PAGE_TITLES from './pageTitles';
import ROLES_ACTION from './roleActions';
import USER_ROLES from './userRoles';
import ROUTES from './routes';
import BLOCK_NAMES from './blockNames';
import PROCESSING_STAGES from './processingStages';
import REF_CALC_DATE_REG_REGIMES_ENUM from './regRegims';
import {
  FAVORITES_BASKET, BASKET_CONST, REGULAR_BASKET_TYPE, READY_BASKET_TYPE, X_CONDITION,
} from './basketConstructors';
import { DATE_FORMATS, DATE_MASKS } from './dateFormats';
import { CONSTRUCTOR_TABLE } from './constructorTable';
import TIMES from './times';
import {
  AVAILABLE_DOCS_TRANSFER_FORMATS,
  DOCS_TRANSFER_MAX_FILES,
  DOCS_TRANSFER_MAX_MESSAGE_LENGTH,
} from './docsTransfer';

import {
  INSURED_FIELDS,
  INSURER_FIELDS,
  BENEFICIARY_FIELDS,
  INSURED_FIELDS_LIVING,
  DOCUMENT_FIELDS,
  ADDRESS_FIELDS,
  CONTACT_FIELDS,
  BANK_FIELDS,
  RELATIONSHIP_FIELD,
  CHILD_INSURED_FIELDS,
  CHILD_INSURED_FIELDS_LIVING,
} from './fieldList';

import INS_LINE_TYPES from './insLineTypes';
import { MILLION, ONE_AND_HALF_MILLION } from './numbers';

const DATE_FORMAT = 'DD.MM.YYYY';
const SYSTEM_DATE_FROM = 'DD-MM-YYYY';
const SYSTEM_DATE_TO = 'YYYY-MM-DD';
const TIME_FORMAT = 'HH:mm';
const DATE_PICKER_FORMAT = 'dd.MM.yyyy';
const DATE_CRETE_FORMAT_CONSTRUCTOR = 'DD.MM.YYYY HH:mm:SS';
const DATE_LAST_IMPORT_PODFT = 'DD.MM.YYYY HH:mm';
const DEFAULT_SERVER_DATE = 'YYYY-MM-DDTHH:mm:ss.SSSZ';
const DEFAULT_DATE = 'YYYY-MM-DDTHH:mm:ss';

const DATE_CHECK_PASSPORT_COVID_START = '2020-02-01';
const DATE_CHECK_PASSPORT_COVID_END = '2020-07-15';

const ERRORS = {
  AUTH_ERROR: 'Неверное имя пользователя или пароль',
  RESET_PASS_ERROR: 'Недействительный запрос',
  USER_NOT_FOUND: 'Пользователь не найден',
  ERROR_VALID_DATE: 'Введена некорректная дата',
};

const RUB_CURRENCY_ID = 1;

const BREADCRUMBS = {
  KSZ: [
    {
      name: 'Продукт',
      link: ROUTES.kszParameters,
      stage: 1,
    },
    {
      name: 'Клиент',
      link: ROUTES.kszInsurerData,
      stage: 2,
    },
    {
      name: 'Оформление',
      link: ROUTES.kszExecution,
      stage: 5,
    },
  ],
  NSZ: [
    {
      name: 'Продукт',
      link: ROUTES.nszCalculation,
      stage: 1,
    },
    {
      name: 'Клиент',
      link: ROUTES.nszInsurerData,
      stage: 2,
    },
    {
      name: 'Оформление',
      link: ROUTES.executionNsz,
      stage: 5,
    },
  ],
  MAIN: [
    {
      name: 'Продукт',
      link: ROUTES.preliminaryCalculation,
      stage: 1,
    },
    {
      name: 'Клиент',
      link: ROUTES.insurerData,
      stage: 2,
    },
    {
      name: 'Оформление',
      link: ROUTES.execution,
      stage: 5,
    },
  ],
  SUB_BREADCRUMBS: [
    {
      name: 'Страхователь',
      link: ROUTES.insurerData,
      stage: 2,
    },
    {
      name: 'Застрахованный',
      link: ROUTES.insuredData,
      stage: 3,
    },
    {
      name: 'Выгодоприобретатель',
      link: ROUTES.beneficiaries,
      stage: 4,
    },
  ],
  NSZ_SUB_BREADCRUMBS: [
    {
      name: 'Страхователь',
      link: ROUTES.nszInsurerData,
      stage: 2,
    },
    {
      name: 'Застрахованный',
      link: ROUTES.nszInsuredData,
      stage: 3,
    },
    {
      name: 'Выгодоприобретатель',
      link: ROUTES.beneficiariesNsz,
      stage: 4,
    },
  ],
  KSZ_SUB_BREADCRUMBS: [
    {
      name: 'Страхователь',
      link: ROUTES.kszInsurerData,
      stage: 2,
    },
    {
      name: 'Застрахованный',
      link: ROUTES.kszInsuredData,
      stage: 3,
    },
    {
      name: 'Выгодоприобретатель',
      link: ROUTES.kszBeneficiaries,
      stage: 4,
    },
  ],
  DOCUMENT_UNDERWRITING: [
    {
      name: 'Мастер проверки',
      link: ROUTES.checkMaster,
      stage: 1,
    },
    {
      name: 'Список документов',
      link: ROUTES.checkDocumentList,
      stage: 1,
    },
  ],
  ADMINISTRATIVE_SECTION: [
    {
      name: 'Партнеры',
      link: ROUTES.preliminaryCalculation,
      stage: 1,
    },
    {
      name: 'Подразделения',
      link: ROUTES.payment,
      stage: 2,
    },
    {
      name: 'Пользователи',
      link: ROUTES.insurerData,
      stage: 3,
    },
    {
      name: 'Продукты',
      link: ROUTES.execution,
      stage: 4,
    },
  ],
};

const COUNTRIES = [
  { key: 'Россия', text: 'Россия', value: 'Россия' },
];

const DOCUMENTS = [
  { key: '1', text: 'Паспорт гражданина РФ', value: 1 },
  { key: '2', text: 'Свидетельство о рождении', value: 2 },
  { key: '5', text: 'Временное удостоверение по Форме 2П', value: 5 },
];

const PASSPORT = [
  { key: '1', text: 'Паспорт гражданина РФ', value: 1 },
  { key: '5', text: 'Временное удостоверение по Форме 2П', value: 5 },
];

const LIST_DICTIONARY = {
  genders: 'GET_GENDERS',
  beneficiaries: 'GET_BENEFICIARIES',
  relationships: 'GET_RELATIONSHIPS',
};

const AVAILABLE_FORMATS = ['pdf', 'jpg', 'jpeg', 'tif', 'tiff', 'png', 'bmp'];

const AVAILABLE_FORMATS_STRING = '.pdf, .jpg ,.jpeg, .tif, .tiff, .png, .bmp';

const PAGINATION_DEFAULT_INDEX = 1;

const AGES = {
  FIRST_PASSPORT_GET_YEARS: 14,
  SECOND_PASSPORT_GET_YEARS: 20,
  LAST_PASSPORT_GET_YEARS: 45,
};

const CLIENT_REPORTS_TYPES = {
  CONTRACT: 'contract',
  INSR_PASSPORT: 'insr_passport',
  INSR_CONTRACT: 'insr_contract',
};

export {
  PAYOUT_FREQ,
  ASSET_COLOR,
  STATUS_SCANS,
  UNDERWRITING_STORE_LINK,
  RU_TYPE_PERSON,
  TYPE_PERSON,
  ROUTES,
  IMPORT_FILE_TYPE,
  ADDRESS_FIELDS,
  API,
  AVAILABLE_FORMATS_STRING,
  AVAILABLE_FORMATS,
  BANK_FIELDS,
  BENEFICIARY_FIELDS,
  BREADCRUMBS,
  CONTACT_FIELDS,
  COUNTRIES,
  DATE_FORMAT,
  DATE_PICKER_FORMAT,
  DOCUMENT_FIELDS,
  DOCUMENTS,
  ENVIRONMENT_ENUM,
  ERRORS,
  INPUT_NAMES,
  INSURED_FIELDS_LIVING,
  INSURED_FIELDS,
  INSURER_FIELDS,
  LANG_DICTIONARY,
  LIST_DICTIONARY,
  MASK,
  NAMES_GROUP_SETTINGS,
  PAGE_TITLES,
  PASSPORT,
  PRODUCT_VERSION,
  REGEXP,
  RELATIONSHIP_FIELD,
  ROLES_ACTION,
  USER_ROLES,
  SYSTEM_DATE_FROM,
  SYSTEM_DATE_TO,
  TIME_FORMAT,
  TYPE_CHECKING_CONTRACT_RU,
  TYPE_CHECKING_CONTRACT,
  TYPE_ERROR_CHECKING_SCAN,
  VALID_PODFT_TYPE,
  ENUMS,
  NSZ_CALCULATOR_ENUMS,
  CHILD_INSURED_FIELDS,
  CHILD_INSURED_FIELDS_LIVING,
  BLOCK_NAMES,
  DATE_CHECK_PASSPORT_COVID_START,
  DATE_CHECK_PASSPORT_COVID_END,
  ERROR_STATUSES,
  CURRENCY_REF,
  CURRENCY_CODE_REF,
  KEY_NAMES,
  NSZ_RISK_TYPE,
  REQUEST_ERROR_CODE,
  CONSTRUCTOR_ISZ_OPTION_IDS,
  DATE_CRETE_FORMAT_CONSTRUCTOR,
  INS_LINE_TYPES,
  UNITED_FRONTEND_HEADER,
  UNITED_FRONTEND_ENUM,
  CONTRACT_STATUS_CODES,
  UNITED_FRONTEND_ERRORS,
  REF_INS_STATUSES_CODE_ENUM,
  REF_INS_STATUSES_NAME_ENUM,
  TEMPLATES_MODAL_CODES,
  CONSTRUCTOR_ISZ_OPTION_CODES,
  PAYOUT_CODE_REF,
  TEST_DATA_ENUMS,
  AGES,
  RUB_CURRENCY_ID,
  DEFAULT_SERVER_DATE,
  REF_CALC_DATE_REG_REGIMES_ENUM,
  PROCESSING_STAGES,
  CLIENT_REPORTS_TYPES,
  DATE_FORMATS,
  DATE_MASKS,
  CONSTRUCTOR_TABLE,
  DEFAULT_DATE,
  TIMES,
  AVAILABLE_DOCS_TRANSFER_FORMATS,
  DOCS_TRANSFER_MAX_FILES,
  DOCS_TRANSFER_MAX_MESSAGE_LENGTH,
  PAGINATION_DEFAULT_INDEX,
  IMPORT_PODFT_TYPE,
  DATE_LAST_IMPORT_PODFT,
  SERVICE_HEADERS,
  BASKET_CONST,
  FAVORITES_BASKET,
  REGULAR_BASKET_TYPE,
  READY_BASKET_TYPE,
  X_CONDITION,
  MILLION,
  ONE_AND_HALF_MILLION,
};
