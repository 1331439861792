import React from 'react';
import { Button, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('main-btn');
const propTypes = {
  text: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.node,
  ]).isRequired,
  onClick: PropTypes.func.isRequired,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  disabled: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
};
const defaultProps = {
  className: '',
  disabled: false,
  type: 'button',
  isLoading: false,
};

const MainBtn = ({
  text,
  onClick,
  className,
  disabled,
  type,
  isLoading,
}) => {
  const handleClick = (...args) => {
    if (!isLoading) {
      onClick(...args);
    }
  };

  return (
    <Button
      className={`${b()} ${className}`}
      onClick={handleClick}
      disabled={disabled}
      type={type}
    >
      <Loader
        inline
        active={isLoading}
        size="medium"
        className={b('loader').toString()}
      />
      <div className={b('text', { loading: isLoading })}>{text}</div>
    </Button>
  );
};

MainBtn.propTypes = propTypes;
MainBtn.defaultProps = defaultProps;
export default MainBtn;
