import {
  CHANGE_SEARCH_VALUE_UNDERWRITING,
  TOGGLE_ADDITIONAL_SEARCH,
  TOGGLE_SHOW_ADDITIONAL_INFO,
  SEARCH_CONTRACTS_SUCCESS,
  SWITCH_PAGINATION_PAGE,
  SET_INIT_STATE,
} from '../types';

const INITIAL_STATE = {
  config: {
    showAdditionalSearch: false,
    activePaginationPage: 1,
    isDownloaded: false,
  },
  searchValues: {
    ctsNumOrFio: '',
    checkStatusId: null,
    ctsStatusId: null,
    searchBranchId: null,
    checkUserFio: '',
    dateCtsBeg: null,
    dateCtsEnd: null,
    dateCheckBeg: null,
    dateCheckEnd: null,
    userFio: '',
  },
  list: [],
  page: 1,
  totalPages: 1,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHANGE_SEARCH_VALUE_UNDERWRITING:
      return {
        ...state,
        list: state.list,
        config: state.config,
        searchValues: {
          ...state.searchValues,
          [payload.name]: payload.value,
        },
      };
    case TOGGLE_ADDITIONAL_SEARCH:
      return {
        ...state,
        list: state.list,
        searchValues: state.searchValues,
        config: {
          ...state.config,
          showAdditionalSearch: payload,
        },
      };

    case TOGGLE_SHOW_ADDITIONAL_INFO:
      return {
        ...state,
        list: state.list,
        searchValues: state.searchValues,
        config: {
          ...state.config,
          [payload.id]: payload.status,
        },
      };

    case SEARCH_CONTRACTS_SUCCESS: {
      const { paginationData, data } = payload;

      return {
        ...state,
        list: paginationData,
        page: data.currentPage,
        totalPages: data.totalPages,
        searchValues: state.searchValues,
        config: {
          ...state.config,
          isDownloaded: true,
        },
      };
    }

    case SWITCH_PAGINATION_PAGE: {
      return {
        ...state,
        list: state.list,
        searchValues: state.searchValues,
        page: payload,
        config: {
          ...state.config,
          activePaginationPage: payload,
        },
      };
    }

    case SET_INIT_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
