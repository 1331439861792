import {
  GET_USERS_FOR_TABLE_REQUEST,
  SET_PAGE_PAGINATION,
  CREATE_USER_REQUEST,
  GET_USER_DATA_REQUEST,
  CLEAR_FORM,
  SET_INITIAL_VALUES,
  UPDATE_USER_REQUEST,
  SEARCH_BY_USERS_NAME,
  USER_TOGGLE_CHECKBOX,
  CLEAR_CHECKBOXED_LIST,
  LOCK_USER_REQUEST,
  UNLOCK_USER_REQUEST,
  GET_PARTNERS_FOR_USER_REQUEST,
  SELECT_USER_OPTIONS,
  GET_BRANCHES_FOR_USER_REQUEST,
  GET_USERS_ROLES_FOR_SELECT_REQUEST,
  SET_AFFILIATIONS_FOR_USER,
  SET_BRANCHES_BY_PARTNERS,
  GET_LOGIN_FOR_USER_REQUEST,
  GET_PASSWORD_FOR_USER_REQUEST,
  SELECT_ALL_USERS,
  SET_WEBSITE_LISTED_USER_TRUE,
  SET_WEBSITE_LISTED_USER_FALSE,
  SET_WEBSITE_LOCKED_USER_TRUE,
  SET_WEBSITE_LOCKED_USER_FALSE,
  GET_EXTRA_SETTING,
  SET_VALUES_FOR_USER,
  SEND_USER_CREDENTIALS,
} from '../types';

const setValueForUser = (name, value) => ({
  type: SET_VALUES_FOR_USER,
  payload: { name, value },
});

const getExtraSettings = () => ({
  type: GET_EXTRA_SETTING,
});

const setWebsiteLockedStatusTrue = (value) => ({
  type: SET_WEBSITE_LOCKED_USER_TRUE,
  payload: value,
});

const setWebsiteLockedStatusFalse = (value) => ({
  type: SET_WEBSITE_LOCKED_USER_FALSE,
  payload: value,
});

const setWebsiteListedStatusTrue = (value) => ({
  type: SET_WEBSITE_LISTED_USER_TRUE,
  payload: value,
});

const setWebsiteListedStatusFalse = (value) => ({
  type: SET_WEBSITE_LISTED_USER_FALSE,
  payload: value,
});

const getPasswordForUser = (values) => ({
  type: GET_PASSWORD_FOR_USER_REQUEST,
  payload: values,
});

const checkAllUsers = (type) => ({
  type: SELECT_ALL_USERS,
  payload: type,
});

const getLoginForUser = (loginData, values) => ({
  type: GET_LOGIN_FOR_USER_REQUEST,
  payload: { loginData, values },
});

const setBranchesByPartner = (affil, values) => ({
  type: SET_BRANCHES_BY_PARTNERS,
  payload: { affil, values },
});

const setUserOption = (newAffiliation, values) => ({
  type: SET_AFFILIATIONS_FOR_USER,
  payload: { newAffiliation, values },
});

const getUsersRoles = (params, prefix) => ({
  type: GET_USERS_ROLES_FOR_SELECT_REQUEST,
  payload: { params, prefix },
});

const getOptionsForPartnerSelectUser = (params, prefix) => ({
  type: GET_PARTNERS_FOR_USER_REQUEST,
  payload: { params, prefix },
});

const getOptionsForBranchSelectUser = (params, prefix) => ({
  type: GET_BRANCHES_FOR_USER_REQUEST,
  payload: { prefix, params },
});

const selectOptionUser = (selectType, value, prefix) => ({
  type: SELECT_USER_OPTIONS,
  payload: {
    selectType,
    value,
    prefix,
  },
});

const lockUser = (body, page, params, id) => ({
  type: LOCK_USER_REQUEST,
  payload: {
    body, page, params, id,
  },
});

const unlockUser = (body, page, params, id) => ({
  type: UNLOCK_USER_REQUEST,
  payload: {
    body, page, params, id,
  },
});

const setInitialValuesUserAdministration = () => ({
  type: SET_INITIAL_VALUES,
});

const clearCheckedList = () => ({
  type: CLEAR_CHECKBOXED_LIST,
});

const getUsersForAdminTable = (offset, query) => ({
  type: GET_USERS_FOR_TABLE_REQUEST,
  payload: offset,
  query,
});

const setPageUsersAdminTable = (page) => ({
  type: SET_PAGE_PAGINATION,
  payload: page,
});

const createUser = (body, history) => ({
  type: CREATE_USER_REQUEST,
  payload: { body, history },
});

const userToggleCheckbox = (checkedUser) => ({
  type: USER_TOGGLE_CHECKBOX,
  payload: checkedUser,
});

const searchUsersAdminTable = (params, page) => ({
  type: SEARCH_BY_USERS_NAME,
  payload: params,
  page,
});

const updateUser = (id, body) => ({
  type: UPDATE_USER_REQUEST,
  payload: { body, id },
});

const getUserDataForUpdate = (id, isParent) => ({
  type: GET_USER_DATA_REQUEST,
  payload: id,
  isParent,
});

const clearEditorAdminUsersForm = () => ({
  type: CLEAR_FORM,
});

const sendUserCredentials = (body) => ({
  type: SEND_USER_CREDENTIALS.request,
  payload: body,
});

export {
  setValueForUser,
  setWebsiteLockedStatusTrue,
  setWebsiteLockedStatusFalse,
  setWebsiteListedStatusTrue,
  setWebsiteListedStatusFalse,
  checkAllUsers,
  getPasswordForUser,
  getLoginForUser,
  setBranchesByPartner,
  setUserOption,
  getUsersRoles,
  getOptionsForPartnerSelectUser,
  selectOptionUser,
  getOptionsForBranchSelectUser,
  unlockUser,
  lockUser,
  clearCheckedList,
  userToggleCheckbox,
  setInitialValuesUserAdministration,
  clearEditorAdminUsersForm,
  updateUser,
  getUserDataForUpdate,
  createUser,
  getUsersForAdminTable,
  setPageUsersAdminTable,
  searchUsersAdminTable,
  getExtraSettings,
  sendUserCredentials,
};
