import { API, ROUTES } from 'consts';
import { apiCall, getError, checkFinished } from 'helpers';
import {
  put,
  takeEvery,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { setBlockRedirect, unlockDocumentsUnderwriting } from 'redux/rootActions';
import { getListForChecking } from 'redux/rootSelectors';
import {
  GET_SCAN_START,
  GET_SCAN_FAIL,
  GET_SCAN_SUCCESS,
  GET_SCAN_REQUEST,
  GET_CONTRACT_START,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  GET_CONTRACT_REQUEST,
  SAVE_CHECKED_CONTRACT_SUCCESS,
  SAVE_CHECKED_CONTRACT_START,
  SAVE_CHECKED_CONTRACT_REQUEST,
  SAVE_CHECKED_CONTRACT_FAIL,
  CHANGE_STATUS_SCAN_SAVE,
  CHANGE_STATUS_SCAN,
} from '../types';
import underwritingSelector from '../selectors';

const { GET_SCAN, GET_CONTRACT_FOR_UNDERWRITING, SAVE_CHECKED_DOCUMENT } = API;

function* getScan({ payload }) {
  try {
    yield put({ type: GET_SCAN_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: `${GET_SCAN}/${payload}`,
      isBlob: true,
    });
    yield put({ type: GET_SCAN_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_SCAN_FAIL, payload: getError(error) });
  }
}

function* getContract({ payload, history }) {
  try {
    yield put({ type: GET_CONTRACT_START });
    if (!payload) {
      history.push(ROUTES.checkMaster);
    } else {
      const { data } = yield apiCall({
        type: 'POST',
        url: GET_CONTRACT_FOR_UNDERWRITING,
        body: {
          ctsId: payload,
        },
      });
      const isFinishChecking = checkFinished(data);
      yield put(setBlockRedirect(!isFinishChecking));
      yield put({ type: GET_CONTRACT_SUCCESS, payload: data });
    }
  } catch (error) {
    yield put({ type: GET_CONTRACT_FAIL, payload: getError(error) });
  }
}

function* saveCheckedContract({ payload: { ctsId, body, isUnlock } }) {
  try {
    yield put({ type: SAVE_CHECKED_CONTRACT_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: SAVE_CHECKED_DOCUMENT(ctsId),
      body,
    });

    if (isUnlock) {
      const listChecking = yield select(getListForChecking);
      yield put(unlockDocumentsUnderwriting(listChecking));
    }

    yield put({ type: SAVE_CHECKED_CONTRACT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: SAVE_CHECKED_CONTRACT_FAIL, payload: getError(error) });
  }
}

function* changeStatusScan({ payload }) {
  yield put({ type: CHANGE_STATUS_SCAN_SAVE, payload });
  const data = yield select(underwritingSelector);
  const isFinishedChecking = checkFinished(data);
  yield put(setBlockRedirect(!isFinishedChecking));
}

function* underwritingSaga() {
  yield takeLatest(CHANGE_STATUS_SCAN, changeStatusScan);
  yield takeLatest(SAVE_CHECKED_CONTRACT_REQUEST, saveCheckedContract);
  yield takeLatest(GET_CONTRACT_REQUEST, getContract);
  yield takeEvery(GET_SCAN_REQUEST, getScan);
}

export default underwritingSaga;
