import { LANG_DICTIONARY } from 'consts';
import { string, object, boolean } from 'yup';
import { onlyNumbers, yupEmpty } from 'helpers';

const {
  REQUIRED,
  FILL_COMPLETELY,
  MAX_HUNDRED,
  NO_MORE_TWO_HUNDRED_FIFTY_FIVE,
} = LANG_DICTIONARY;

export default object().shape({
  codeValue: string().required(REQUIRED).max(100, MAX_HUNDRED),
  contrAgent: string().required(REQUIRED).max(100, MAX_HUNDRED),
  contractNumber: string().max(100, MAX_HUNDRED),
  dateContract: string().nullable().max(100, MAX_HUNDRED),
  fullNaming: string().required(REQUIRED).max(255, NO_MORE_TWO_HUNDRED_FIFTY_FIVE),
  inn: yupEmpty(
    string()
      .test('len', FILL_COMPLETELY,
        (val) => onlyNumbers(val.toString()).length === 12
        || onlyNumbers(val.toString()).length === 10),
    FILL_COMPLETELY,
  ),
  legalType: string().required(REQUIRED).max(100, MAX_HUNDRED),
  linkLogo: string().max(100, MAX_HUNDRED),
  ogrn: string().max(100, MAX_HUNDRED),
  shortNaming: string().required(REQUIRED).max(100, MAX_HUNDRED),
  status: string().max(100, MAX_HUNDRED),
  typeContract: string().max(100, MAX_HUNDRED),
  isRulesAccepting: boolean().required(),
});
