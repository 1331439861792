import React, { Component, Fragment } from 'react';
import {
  TemporaryPassword,
  UserDependencies,
  Migrations,
  HistoryScansPopup,
  ScanUploadPopup,
  LimitAmount,
  RefreshPagePopUp,
  ProgressBarDownload,
  UnitedFrontendBlockPopup,
  UpdateUnitedFrontendSessionPopup,
} from 'components';

import { connect } from 'react-redux';
import {
  resetTemporaryPassword,
  getCurrentUser,
  logOut,
  showPopupFinishedCheck,
  getEventsCount,
} from 'redux/rootActions';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';

const propTypes = {
  resetPassword: PropTypes.func,
  tempPass: PropTypes.bool,
  history: PropTypes.object,
  modalAffiliation: PropTypes.bool,
  isModalMigrations: PropTypes.bool,
  needUpdate: PropTypes.bool,
};

const defaultProps = {
  needUpdate: false,
  history: {},
  resetPassword: () => null,
  tempPass: false,
  modalAffiliation: false,
  isModalMigrations: false,
};

/*
  Do not use this _components for new popups.
  The _components was written during react-portal shutdown
*/

class SharedPopups extends Component {
  constructor(props) {
    super(props);

    this.state = {
      isShowTimeout: false,
    };
    this.timeout = null;
  }

  /*
    TODO componentWillReceiveProps deprecated
    TODO implementation of timeout without error. Popup session is temporarily disabled
  */

  // componentDidMount() {
  //   const { getEvents } = this.props;
  //   this.interval = setInterval(() => getEvents(), 1000 * 60 * 5);
  // }
  //
  // componentWillReceiveProps(nextProps) {
  //   const { requests, clearTimeout: clearTimeoutFunc } = this.props;
  //   if (requests !== nextProps.requests) {
  //     clearTimeoutFunc(this.timeout);
  //     this.startTimeout();
  //   }
  // }
  // startTimeout = () => {
  //   const { setTimeout: setTimeoutFunc, sessionExpire } = this.props;
  //   this.timeout = setTimeoutFunc(this.showTimeoutWindow, sessionExpire * 1000 - 60 * 1000);
  // };

  showTimeoutWindow = () => {
    this.setState({ isShowTimeout: true });
  };

  closeTimeoutWindow = () => {
    this.setState({ isShowTimeout: false });
  };

  render() {
    const { isShowTimeout } = this.state;
    const {
      resetPassword,
      tempPass,
      history,
      modalAffiliation,
      isModalMigrations,
      needUpdate,
    } = this.props;

    return (
      <>
        {isModalMigrations && <Migrations />}
        <LimitAmount />
        <HistoryScansPopup />
        <ScanUploadPopup />
        <UnitedFrontendBlockPopup />
        <UpdateUnitedFrontendSessionPopup />
        <LimitAmount />
        <ProgressBarDownload />
        <RefreshPagePopUp check={needUpdate} />
        {modalAffiliation && <UserDependencies />}
        {tempPass && !isShowTimeout && (
          <TemporaryPassword
            resetPassword={resetPassword}
            history={history}
          />
        )}
        {/* {<TimeoutWindow */}
        {/*  startTimeout={this.startTimeout} */}
        {/*  getUser={getUserAction} */}
        {/*  isShow={isShowTimeout} */}
        {/*  logOut={logOutAction} */}
        {/*  history={history} */}
        {/*  clearIntervalFunc={clearIntervalFunc} */}
        {/*  setIntervalFunc={setIntervalFunc} */}
        {/*  close={this.closeTimeoutWindow} */}
        {/* />} */}
      </>
    );
  }
}

const mapStateToProps = ({
  authReducer: {
    tempPass,
    sessionExpire,
    modalAffiliation,
  },
  shared: {
    requests,
    historyRoutes,
  },
  versionDp: {
    isModalMigrations,
  },
  helperReducer: {
    needUpdate,
  },
}) => ({
  isModalMigrations,
  modalAffiliation,
  tempPass,
  sessionExpire,
  requests,
  historyRoutes,
  needUpdate,
});

const mapDispatchToProps = {
  resetPassword: resetTemporaryPassword,
  logOutAction: logOut,
  getUserAction: getCurrentUser,
  showPopupFinishedChecking: showPopupFinishedCheck,
  getEvents: getEventsCount,
};

SharedPopups.defaultProps = defaultProps;
SharedPopups.propTypes = propTypes;

export default withCustomRouter(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  )(SharedPopups),
);
