import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { get, sum, isNil } from 'lodash';
import moment from 'moment';
import {
  ROUTES,
  LANG_DICTIONARY,
  DATE_FORMAT,
  REF_CALC_DATE_REG_REGIMES_ENUM,
} from 'consts';
import {
  getProducts,
  getKszProductInfo,
  clearStoreKszProducts,
  changeFieldKszProduct,
  setStageKszProduct,
  setKszProductRestrictions,
} from 'redux/rootActions';
import { KszForm } from 'components';
import { comparisonValues, checkIsCurrencyScenario } from 'helpers';

export const REF_KSZ_CALC_TYPES = {
  FROM_CREDIT_SUM: 'from_credit_sum',
  FROM_INS_SUM: 'from_ins_sum',
};

const RESTRICTIONS_ROUNDING = 7;

const {
  VALUE_MUST_BE,
  WARNING_FOR_CORRECTION_COEF,
  VALUE_MUST_BE_NO_LEST,
  VALUE_MUST_BE_NO_MORE,
} = LANG_DICTIONARY;

const defaultProps = {
  history: null,
  getAllProducts: () => null,
  saveStore: () => null,
  getProductInfo: () => null,
  fieldValues: {},
  kszProductValues: {
    productsGrouping: [],
    productRiskTariffs: [],
  },
  setStage: () => null,
  setRestrictions: () => null,
  refExchangeRatesRegimesCoefs: null,
  productSelection: {
    status: false,
    message: null,
  },
};

const propTypes = {
  kszProductValues: PropTypes.object,
  saveStore: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  getAllProducts: PropTypes.func,
  getProductInfo: PropTypes.func,
  fieldValues: PropTypes.object,
  setStage: PropTypes.func,
  setRestrictions: PropTypes.func,
  refExchangeRatesRegimesCoefs: PropTypes.arrayOf(PropTypes.object),
  productSelection: PropTypes.object,
};

class KszParametersComponent extends Component {
  componentDidMount() {
    const {
      match: { params: { id } },
      getAllProducts,
      getProductInfo,
    } = this.props;

    getProductInfo(id);
    getAllProducts(id);
  }

  componentDidUpdate(prevProps) {
    const {
      fieldValues: {
        creditAmount: prevAmount,
        creditTerm: prevTerm,
        productId: prevId,
        productCalcType: prevProductCalcType,
      },
    } = prevProps;

    const {
      fieldValues: {
        creditAmount,
        creditTerm,
        productId,
        productCalcType,
      },
      saveStore,
    } = this.props;

    if (prevAmount !== creditAmount || prevTerm !== creditTerm || prevId !== productId) {
      this.calcInsuranceSum({ creditAmount, creditTerm, productId });
    }

    if (prevProductCalcType !== productCalcType) {
      saveStore('creditAmount', 0);
      saveStore('creditInsuranceSum', 0);
      saveStore('creditInsurancePremium', 0);
    }
  }

  handleChangeCreditTerm = (event) => {
    const {
      saveStore,
    } = this.props;
    const { target: { name, value } } = event;

    saveStore(name, value);
    this.calcCreditEndDate({ termMonths: value });
    this.calcInsuranceSum({ termMonths: value });
  };

  handleChangeCalcType = (select) => {
    const { saveStore } = this.props;
    const { name, value } = select.target;

    saveStore(name, value);
  }

  handleChangeCreditAmount = (value, amount, event) => {
    const {
      saveStore,
      refExchangeRatesRegimesCoefs,
      fieldValues: {
        errors,
        creditCurrency,
      },
    } = this.props;
    const { target: { name } } = event;

    saveStore(name, amount);
    this.calcInsuranceSum({ amount });

    const isCurrencyScenario = checkIsCurrencyScenario(creditCurrency);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    if (isCurrencyScenario) {
      const coefId =
        this.findCorrectCoefIdInListOfCoefsByContractAmount(refExchangeRatesRegimesCoefs, amount);

      if (coefId === null) {
        delete errors.creditInsurancePremium;
        saveStore('errors', {
          ...errors,
          correctionCoefSettingsError: WARNING_FOR_CORRECTION_COEF,
        });

        return;
      }

      delete errors.correctionCoefSettingsError;
      saveStore('errors', { ...errors });
    } else {
      delete errors.correctionCoefSettingsError;
      saveStore('errors', { ...errors });
    }
  };

  handleChangeInsuranceAmount = (value, amount, event) => {
    const {
      saveStore,
    } = this.props;
    const { target: { name } } = event;

    saveStore(name, amount);
    this.calcInsuranceSum({ insuranceAmount: amount });
  }

  findCorrectCoefIdInListOfCoefsByContractAmount = (coefs, amount) => {
    const correctCoefs = coefs
      .filter((coef) => this.checkIsAmountSatisfiesTheConstraints(coef, amount));

    if (correctCoefs.length) {
      if (correctCoefs.length > 1) {
        const nearestCoefId = this.findNearestPremiumAmountCoefIdInInterval(coefs);

        return nearestCoefId;
      }

      return correctCoefs[0].id;
    }

    return null;
  }

  checkIsAmountSatisfiesTheConstraints =
    (coef, amount) => (coef.premiumAmountMin <= amount && coef.premiumAmountMax >= amount);

  findNearestPremiumAmountCoefIdInInterval = (coefs) => {
    const correctCoef = coefs
      .map((coef) => ({
        value: coef.premiumAmountMax - coef.premiumAmountMin,
        id: coef.id,
      }))
      .sort((el1, el2) => el1.value - el2.value)[0];

    return correctCoef.id;
  }

  handleChangeCreditCurrency = (event) => {
    const {
      saveStore,
      kszProductValues,
      refExchangeRatesRegimesCoefs,
      fieldValues: {
        errors,
        creditAmount,
      },
    } = this.props;
    const { target: { name, value } } = event;
    const { productsGrouping } = kszProductValues;

    const products = [];
    productsGrouping.forEach(
      (grouping) => products.push(grouping.mainProduct, grouping.groupedProduct),
    );

    const product = products.find((data) => (data.refCurrencies.id === value));
    if (!product) {
      return;
    }
    const productId = product.insProduct.id;

    saveStore(name, value);
    saveStore('productId', productId);
    this.calcInsuranceSum({ product: productId });

    const isCurrencyScenario = checkIsCurrencyScenario(value);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    if (isCurrencyScenario) {
      const amount = creditAmount || 0;

      const coefId =
        this.findCorrectCoefIdInListOfCoefsByContractAmount(refExchangeRatesRegimesCoefs, amount);

      if (coefId === null) {
        delete errors.creditInsurancePremium;
        saveStore('errors', {
          ...errors,
          correctionCoefSettingsError: WARNING_FOR_CORRECTION_COEF,
        });

        return;
      }

      delete errors.correctionCoefSettingsError;
      saveStore('errors', { ...errors });
    } else {
      delete errors.correctionCoefSettingsError;
      saveStore('errors', { ...errors });
    }
  };

  calcCreditEndDate = ({ startDate, termMonths } = {}) => {
    const {
      saveStore,
      fieldValues,
    } = this.props;
    const creditStartDate = isNil(startDate) ? fieldValues.creditStartDate : startDate;
    const creditTerm = isNil(termMonths) ? fieldValues.creditTerm : termMonths;

    if (isNil(creditStartDate) || isNil(creditTerm)) {
      return;
    }

    const creditEndDate = moment(creditStartDate).add(creditTerm, 'months').subtract(1, 'day').toDate();
    saveStore('creditEndDate', creditEndDate);
  };

  calcInsuranceSum = ({
    amount,
    termMonths,
    product,
    insuranceAmount,
  } = {}) => {
    const {
      saveStore,
      fieldValues,
      kszProductValues,
    } = this.props;
    const creditAmount = isNil(amount) ? fieldValues.creditAmount : amount;
    const insuranceSum = isNil(insuranceAmount) ? fieldValues.creditInsuranceSum : insuranceAmount;
    const creditTerm = isNil(termMonths) ? fieldValues.creditTerm : termMonths;
    const productId = isNil(product) ? fieldValues.productId : product;
    const { productsRiskTariffs } = kszProductValues;
    const { productCalcType } = fieldValues;

    const isMissingParam =
      isNil(productsRiskTariffs) ||
      (isNil(creditAmount) && isNil(insuranceSum)) ||
      isNil(creditTerm);
    if (isMissingParam) {
      return;
    }

    const risks = this.getRisks(productId);

    const tariffsSum = risks.reduce((acc, risk) => {
      const tariff = get(risk, 'insLineKszTariff[0].tariff', 0);

      return acc + tariff;
    }, 0);

    if (productCalcType === REF_KSZ_CALC_TYPES.FROM_CREDIT_SUM) {
      const insurancePremiums = risks.map((risk) => {
        const tariff = get(risk, 'insLineKszTariff[0].tariff', 0);

        return ((creditAmount * tariff * creditTerm) / (1 - tariffsSum * creditTerm));
      });

      const creditInsurancePremium = sum(insurancePremiums);
      const creditInsuranceSum = creditAmount + creditInsurancePremium;
      saveStore('creditInsurancePremium', parseFloat(creditInsurancePremium.toFixed(RESTRICTIONS_ROUNDING)));
      saveStore('creditInsuranceSum', parseFloat(creditInsuranceSum.toFixed(RESTRICTIONS_ROUNDING)));
      this.checkAmountRestrictions({
        creditInsurancePremium,
        productId,
        creditInsuranceSum: insuranceSum,
      });
    } else if (productCalcType === REF_KSZ_CALC_TYPES.FROM_INS_SUM) {
      const creditInsurancePremium = insuranceSum * tariffsSum * creditTerm;

      saveStore('creditInsurancePremium', parseFloat(creditInsurancePremium.toFixed(RESTRICTIONS_ROUNDING)));
      this.checkAmountRestrictions({
        creditInsurancePremium,
        productId,
        creditInsuranceSum: insuranceSum,
      });
    }
  };

  getRisks = (productId) => {
    const {
      kszProductValues,
    } = this.props;
    const { productsRiskTariffs } = kszProductValues;

    const productData = productsRiskTariffs.find((data) => (data.insProduct.id === productId));

    return get(productData, 'insProduct.insObject[0].insObjectsRisk', []);
  }

  checkAmountRestrictions = ({ creditInsurancePremium, productId, creditInsuranceSum } = {}) => {
    const {
      setRestrictions,
      saveStore,
      kszProductValues: {
        productsRestrictions,
      },
      fieldValues: {
        errors,
      },
    } = this.props;

    const sumValue = parseFloat(creditInsuranceSum.toFixed(RESTRICTIONS_ROUNDING));
    const risks = this.getRisks(productId);

    let restrictionsErrors = {};

    if (risks.length) {
      const minInsSumCur = Math.max(...risks.map((risk) => risk.insSumCurMin));
      const maxInsSumCur = Math.min(...risks.map((risk) => risk.insSumCurMax));

      if (minInsSumCur && sumValue < minInsSumCur) {
        restrictionsErrors = {
          ...restrictionsErrors,
          creditInsuranceSum: `${VALUE_MUST_BE} >= ${minInsSumCur}`,
        };
      }

      if (maxInsSumCur && sumValue > maxInsSumCur) {
        restrictionsErrors = {
          ...restrictionsErrors,
          creditInsuranceSum: `${VALUE_MUST_BE} <= ${maxInsSumCur}`,
        };
      }
    }

    const productRestrictions = productsRestrictions.find((restrictions) => (
      restrictions.productId === productId
    ));

    if (isNil(creditInsurancePremium) || isNil(productId) || isNil(productRestrictions)) {
      return;
    }

    setRestrictions(productRestrictions);

    const premiumValue = parseFloat(creditInsurancePremium.toFixed(RESTRICTIONS_ROUNDING));
    const {
      insuranceAmount: {
        noMin, minType, minValue, noMax, maxType, maxValue,
      },
    } = productRestrictions;

    if (!noMin && !comparisonValues(premiumValue, minValue, minType)) {
      restrictionsErrors = {
        ...restrictionsErrors,
        creditInsurancePremium: `${VALUE_MUST_BE} ${minType} ${minValue}`,
      };
    }

    if (!noMax && !comparisonValues(premiumValue, maxValue, maxType)) {
      restrictionsErrors = {
        ...restrictionsErrors,
        creditInsurancePremium: `${VALUE_MUST_BE} ${maxType} ${maxValue}`,
      };
    }

    delete errors.creditInsurancePremium;
    delete errors.creditInsuranceSum;
    saveStore('errors', { ...errors, ...restrictionsErrors });
  };

  goNextForm = () => {
    const {
      history,
      match: { params: { id } },
      setStage,
      saveStore,
      fieldValues: { creditCurrency },
    } = this.props;

    const isCurrencyScenario = checkIsCurrencyScenario(creditCurrency);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    setStage(2);
    history.push(`${ROUTES.kszInsurerData}/${id}`);
  }

  handleChangeCreditStartDate = (event) => {
    const {
      saveStore,
      kszProductValues: {
        dateBegDelay,
      },
      fieldValues: {
        errors,
      },
    } = this.props;
    const { target: { name, value } } = event;

    saveStore(name, value);

    const minDate = moment().add(1, 'days').startOf('day');
    const maxDate = moment().add(dateBegDelay, 'days').endOf('day');

    if (minDate.valueOf() > value.valueOf()) {
      saveStore('errors', {
        ...errors,
        creditStartDate: `${VALUE_MUST_BE_NO_LEST} ${moment(minDate).format(DATE_FORMAT)}`,
      });

      return;
    }

    if (maxDate.valueOf() < value.valueOf()) {
      saveStore('errors', {
        ...errors,
        creditStartDate: `${VALUE_MUST_BE_NO_MORE} ${moment(maxDate).format(DATE_FORMAT)}`,
      });

      return;
    }

    delete errors.creditStartDate;
    saveStore('errors', { ...errors });

    this.calcCreditEndDate({ startDate: value });
  };

  render() {
    const {
      kszProductValues,
      kszProductValues: {
        productsGrouping,
        refCalcDateRegRegimesId,
      },
      fieldValues,
    } = this.props;

    const currencyOptions = productsGrouping.length ? [{
      key: productsGrouping[0].mainProduct.id,
      text: productsGrouping[0].mainProduct.refCurrencies.nameShort,
      value: productsGrouping[0].mainProduct.refCurrencies.id,
    }] : [];

    const isDefaultProduct = productsGrouping.length
      && productsGrouping[0].mainProduct.id === productsGrouping[0].groupedProduct.id;
    if (!isDefaultProduct) {
      productsGrouping.forEach((grouping) => {
        currencyOptions.push({
          key: grouping.id,
          text: grouping.groupedProduct.refCurrencies.nameShort,
          value: grouping.groupedProduct.refCurrencies.id,
        });
      });
    }

    const productCalcTypes =
    kszProductValues.productCalcTypes ? kszProductValues.productCalcTypes.map((t) => ({
      key: t.code,
      text: t.name,
      value: t.code,
    })) : [];

    const creditSumDisabled = !fieldValues.productCalcType ||
      fieldValues.productCalcType !== REF_KSZ_CALC_TYPES.FROM_CREDIT_SUM;
    const insSumDisabled = !fieldValues.productCalcType ||
      fieldValues.productCalcType !== REF_KSZ_CALC_TYPES.FROM_INS_SUM;

    return (
      <KszForm
        productSelection={kszProductValues.productSelection}
        values={fieldValues}
        isDisabledStartDate={refCalcDateRegRegimesId === REF_CALC_DATE_REG_REGIMES_ENUM.ksz_1}
        productTip={kszProductValues.productTip}
        durationMin={kszProductValues.durationMin}
        durationMax={kszProductValues.durationMax}
        handleChangeCreditTerm={this.handleChangeCreditTerm}
        handleChangeCreditAmount={this.handleChangeCreditAmount}
        handleChangeInsuranceAmount={this.handleChangeInsuranceAmount}
        handleChangeCreditStartDate={this.handleChangeCreditStartDate}
        currencyOptions={currencyOptions}
        handleChangeCreditCurrency={this.handleChangeCreditCurrency}
        goNextForm={this.goNextForm}
        handleChangeCalcTypes={this.handleChangeCalcType}
        productCalcTypes={productCalcTypes}
        creditSumDisabled={creditSumDisabled}
        insSumDisabled={insSumDisabled}
      />
    );
  }
}

const mapStateToProps = ({
  kszProduct: {
    kszProductValues,
    fieldValues,
    refExchangeRatesRegimesCoefs,
    productSelection,
  },
}) => ({
  productSelection,
  kszProductValues,
  fieldValues,
  refExchangeRatesRegimesCoefs,
});

const mapDispatchToProps = {
  getAllProducts: getProducts,
  getProductInfo: getKszProductInfo,
  saveStore: changeFieldKszProduct,
  setStage: setStageKszProduct,
  clearStore: clearStoreKszProducts,
  setRestrictions: setKszProductRestrictions,
};

KszParametersComponent.propTypes = propTypes;
KszParametersComponent.defaultProps = defaultProps;

export default withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(KszParametersComponent));
