import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import {
  Select,
  Button,
  Form,
  Radio,
  Icon,
  Dimmer,
  Segment,
  Loader,
} from 'semantic-ui-react';
import {
  onlyNumbers,
} from 'helpers';
import {
  FieldBlock,
  ImportFrom1CPopup,
  EditJobPopUp,
} from 'components';
import {
  getAdminOptions,
  export1C,
  getActiveTaskInfo,
  getSelectsCrons,
  importManual,
} from 'redux/rootActions';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { onImport1cManual } from 'websocket/listeners';

const propTypes = {
  changeSettingAutoImport: PropTypes.func,
  parseFile: PropTypes.func,
  errorFilesDir: PropTypes.string,
  newFilesDir: PropTypes.string,
  successFilesDir: PropTypes.string,
  startImportAuto: PropTypes.func,
  parentClass: PropTypes.func,
  isLoading: PropTypes.bool,
  importFrom1CPartners: PropTypes.arrayOf(PropTypes.object),
  importFrom1CStatuses: PropTypes.arrayOf(PropTypes.object),
  errors: PropTypes.arrayOf(PropTypes.string),
  successImport: PropTypes.bool,
  popupIsOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  cancelJob: PropTypes.func,
  getTaskInfo: PropTypes.func,
  getCrons: PropTypes.func,
  loadingImport: PropTypes.bool,
  match: PropTypes.object,
  history: PropTypes.object,
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  activeTaskInfoImport: PropTypes.object,
  importManualWs: PropTypes.func.isRequired,
  fileName: PropTypes.string,
};

const defaultProps = {
  cronTypeSelect: [],
  activeTaskInfoImport: {},
  history: {},
  match: {},
  cancelJob: () => null,
  isLoading: false,
  parentClass: () => null,
  errorFilesDir: '',
  newFilesDir: '',
  successFilesDir: '',
  startImportAuto: () => null,
  parseFile: () => null,
  changeSettingAutoImport: () => null,
  importFrom1CPartners: [],
  importFrom1CStatuses: [],
  errors: [],
  successImport: false,
  popupIsOpen: false,
  closePopup: () => null,
  getTaskInfo: () => null,
  getCrons: () => null,
  loadingImport: false,
  fileName: null,
};

const {
  IMPORT_TYPE,
  MANUAL_IMPORT,
  AUTOMATICALY_IMPORT,
  CHOOSE_FILE_FOR_IMPORT,
  CONTRACT_NUMBER_FOR_IMPORT,
  CHOOSE_CONTRACT_STATUS_FOR_IMPORT,
  CHOOSE_ALL_OR_ONE_PARTNER_FOR_IMPORT,
  EXECUTE_IMPORT,
  DESCRIPTION_IMPORT_FROM_1C,
  SETTINGS_IMPORT,
  EXECUTE,
  NOT_CHOOSED,
  DIRECTORY_FOR_NEW_FILES,
  DIRECTORY_FOR_ERROR,
  DIRECTORY_FOR_SUCCESS,
  UPLOAD_PROCESS,
} = LANG_DICTIONARY;

const {
  transferDataImportManual,
  transferDataImportAuto,
} = ROUTES;

const CONSTANTS = {
  manual: 'transfer-data-import-manual',
  auto: 'transfer-data-import-auto',
};

class ImportFrom1C extends Component {
  constructor(props) {
    super(props);
    this.state = {
      importType: 'manual',
      contractNumber: '',
      contractStatus: null,
      contractPartner: null,
      selectedFile: null,
    };
    this.file = React.createRef();
  }

  componentDidMount = () => {
    const {
      match,
      getTaskInfo,
      getCrons,
    } = this.props;

    const { manual, auto } = CONSTANTS;
    const pathToArray = match.path.split('/');
    const typeOf1CAction = pathToArray[pathToArray.length - 1];
    if (typeOf1CAction === auto) {
      this.setState({
        importType: 'auto',
      });
    }
    if (typeOf1CAction === manual) {
      this.setState({
        importType: 'manual',
      });
    }
    getTaskInfo('import_1c');
    getCrons();
    onImport1cManual.subscribe();
  };

  componentWillUnmount() {
    onImport1cManual.unsubscribe();
  }

  handleChangeRadio = () => {
    const { importType } = this.state;
    const { history } = this.props;
    if (importType === 'manual') {
      this.setState({
        importType: 'auto',
      });
      history.push(transferDataImportAuto);
    }
    if (importType === 'auto') {
      this.setState({
        importType: 'manual',
      });
      history.push(transferDataImportManual);
    }
  }

   handleChangeSelect = (name) => (e, { value }) => {
     this.setState({
       [name]: value,
     });
   }

   handleChangeInput = (e) => {
     const { value, name } = e.target;
     this.setState({
       [name]: value,
     });
   }

   handleChangeInputStore = (e) => {
     const { value, name } = e.target;
     const { changeSettingAutoImport } = this.props;
     if (name === 'timeRepeat') {
       const onlyNumbersValues = onlyNumbers(value);
       changeSettingAutoImport(name, onlyNumbersValues);
     } else {
       changeSettingAutoImport(name, value);
     }
   }

   onChangeHandler=({ target: { files } }) => {
     const { parseFile } = this.props;
     this.setState({
       selectedFile: files[0],
     });
     const data = new FormData();
     data.append('file', files[0]);
     parseFile(data);
   }

   execute = async () => {
     const {
       contractNumber,
       contractStatus,
       contractPartner,
       selectedFile,
     } = this.state;
     const { importManualWs } = this.props;
     const partners = contractPartner && contractPartner.length ? contractPartner : null;
     const file = new FormData();
     file.append('file', selectedFile);

     const data = {
       statusId: contractStatus || undefined,
       partners: partners || undefined,
       ctsNum: contractNumber || undefined,
     };

     importManualWs({ file, data });
     this.deleteSelectedFile();
   };

   createTask = (body) => {
     const {
       errorFilesDir,
       newFilesDir,
       successFilesDir,
       startImportAuto,
     } = this.props;
     const {
       time,
       interval,
       taskType,
       priorityId,
       cronTypeId,
       action,
     } = body;
     startImportAuto(
       {
         action,
         cronTypeId,
         errorFilesDir,
         newFilesDir,
         successFilesDir,
         time,
         interval,
         taskType,
         priorityId,
       },
     );
   }

   deleteSelectedFile = () => {
     this.setState({
       selectedFile: null,
     });
     this.file.current.value = null;
   }

   render() {
     const {
       parentClass,
       isLoading,
       importFrom1CPartners,
       importFrom1CStatuses,
       errors,
       successImport,
       popupIsOpen,
       closePopup,
       errorFilesDir,
       newFilesDir,
       successFilesDir,
       cancelJob,
       cronTypeSelect,
       activeTaskInfoImport,
       loadingImport,
       getTaskInfo,
       fileName,
     } = this.props;
     const {
       importType,
       contractNumber,
       selectedFile,
     } = this.state;
     const renderImportType = {
       manual:
  <Dimmer.Dimmable as={Segment} dimmed={loadingImport}>
    <Dimmer active={loadingImport} inverted>
      <Loader>{UPLOAD_PROCESS}</Loader>
    </Dimmer>
    <div>
      <FieldBlock title={CHOOSE_FILE_FOR_IMPORT}>
        <div className={parentClass('input-upload-area')}>
          <input
            ref={this.file}
            type="file"
            name="file"
            onChange={this.onChangeHandler}
            className={parentClass('input-file')}
            accept=".xml"
          />
          <Icon
            name="cancel"
            className={parentClass('cancel-button').toString()}
            size="big"
            onClick={this.deleteSelectedFile}
          />
        </div>
      </FieldBlock>
      <FieldBlock title={CONTRACT_NUMBER_FOR_IMPORT}>
        <input
          value={contractNumber}
          name="contractNumber"
          onChange={this.handleChangeInput}
          disabled={!selectedFile}
        />
      </FieldBlock>
      <FieldBlock title={CHOOSE_CONTRACT_STATUS_FOR_IMPORT}>
        <Select
          className={parentClass('select').toString()}
          options={importFrom1CStatuses}
          placeholder={NOT_CHOOSED}
          onChange={this.handleChangeSelect('contractStatus')}
          name="statuses"
          disabled={!selectedFile}
        />
      </FieldBlock>
      <FieldBlock title={CHOOSE_ALL_OR_ONE_PARTNER_FOR_IMPORT}>
        <Select
          className={parentClass('select').toString()}
          options={importFrom1CPartners}
          placeholder={NOT_CHOOSED}
          multiple
          onChange={this.handleChangeSelect('contractPartner')}
          name="partnerId"
          disabled={!selectedFile}
        />
      </FieldBlock>
      <FieldBlock title={EXECUTE_IMPORT}>
        <Button
          type="submit"
          className={parentClass('submit').toString()}
          onClick={this.execute}
          disabled={isLoading || !selectedFile}
        >
          {EXECUTE}
        </Button>
      </FieldBlock>
    </div>
  </Dimmer.Dimmable>,
       auto: <div>
         <FieldBlock title={DIRECTORY_FOR_NEW_FILES}>
           <input name="newFilesDir" value={newFilesDir} onChange={this.handleChangeInputStore} />
         </FieldBlock>
         <FieldBlock title={DIRECTORY_FOR_ERROR}>
           <input name="errorFilesDir" value={errorFilesDir} onChange={this.handleChangeInputStore} />
         </FieldBlock>
         <FieldBlock title={DIRECTORY_FOR_SUCCESS}>
           <input name="successFilesDir" value={successFilesDir} onChange={this.handleChangeInputStore} />
         </FieldBlock>
         <div className={parentClass('scheduler')}>
           {Object.keys(activeTaskInfoImport).length && (
           <EditJobPopUp
             cronTypeSelect={cronTypeSelect}
             activeTaskInfoImport={activeTaskInfoImport}
             createTask={this.createTask}
             cancelTask={cancelJob}
             getTaskInfo={getTaskInfo}
           />
           )}
         </div>
       </div>,
     };

     return (
       <div className={parentClass('import-from-1c')}>
         <ImportFrom1CPopup
           errors={errors}
           successImport={successImport}
           popupIsOpen={popupIsOpen}
           closePopup={closePopup}
           fileName={fileName || ''}
         />
         <section className={parentClass('info')}>
           <p>{DESCRIPTION_IMPORT_FROM_1C}</p>
         </section>
         <Form className={parentClass('import-1c').toString()} loading={isLoading}>
           <section className={parentClass('content')}>
             <p className={parentClass('content-header')}>{SETTINGS_IMPORT}</p>
             <FieldBlock title={IMPORT_TYPE}>
               <div className={parentClass('form-content-radio')}>
                 <Form.Field>
                   <Radio
                     label={MANUAL_IMPORT}
                     name="radioGroup"
                     value={MANUAL_IMPORT}
                     checked={importType === 'manual'}
                     onClick={() => this.handleChangeRadio(importType)}
                   />
                 </Form.Field>
                 <Form.Field>
                   <Radio
                     label={AUTOMATICALY_IMPORT}
                     name="radioGroup"
                     value={AUTOMATICALY_IMPORT}
                     checked={importType === 'auto'}
                     onClick={() => this.handleChangeRadio(importType)}
                   />
                 </Form.Field>
               </div>
             </FieldBlock>
             {renderImportType[importType]}
           </section>
         </Form>
       </div>
     );
   }
}

const mapDispatchToProps = {
  getOptions: getAdminOptions,
  export1c: export1C,
  getTaskInfo: getActiveTaskInfo,
  getCrons: getSelectsCrons,
  importManualWs: importManual,
};
const mapStateToProps = (
  {
    transferData: {
      isLoadingExport1C,
      fileName,
    },
    helperReducer: {
      activeTaskInfoImport,
    },
    schedulerJobs: {
      cronTypeSelect,
    },
  },
) => (
  {
    isLoading: isLoadingExport1C,
    cronTypeSelect,
    activeTaskInfoImport,
    fileName,
  });

ImportFrom1C.defaultProps = defaultProps;
ImportFrom1C.propTypes = propTypes;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(ImportFrom1C));
