const blockNames = {
  addressBlock: 'addressBlock',
  addressBlockLiving: 'addressBlockLiving',
  personBlock: 'personBlock',
  documentBlock: 'documentBlock',
  bankBlock: 'bankBlock',
  innSnilsBlock: 'innSnilsBlock',
  contactBlock: 'contactBlock',
  relationBlock: 'relationBlock',
};

export default blockNames;
