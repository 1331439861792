import { takeLatest, put } from 'redux-saga/effects';
import { apiCall, getError, getOptionsUnderwriting } from 'helpers';
import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import { toastr } from 'react-redux-toastr';
import {
  GET_USERS_UNDERWRITING_SUCCESS,
  GET_USERS_UNDERWRITING_START,
  GET_BRANCHES_UNDERWRITING_SUCCESS,
  GET_BRANCHES_UNDERWRITING_START,
  GET_PARTNERS_UNDERWRITING_SUCCESS,
  GET_PARTNERS_UNDERWRITING_START,
  GET_BRANCHES_UNDERWRITING_FAIL,
  GET_USERS_UNDERWRITING_FAIL,
  GET_PARTNERS_UNDERWRITING_FAIL,
  GET_USERS_UNDERWRITING_REQUEST,
  GET_BRANCHES_UNDERWRITING_REQUEST,
  GET_PARTNERS_UNDERWRITING_REQUEST,
  GET_STATUS_UNDERWRITING_FAIL,
  GET_STATUS_UNDERWRITING_REQUEST,
  GET_STATUS_UNDERWRITING_START,
  GET_STATUS_UNDERWRITING_SUCCESS,
  GET_COUNT_UNDERWRITING_REQUEST,
  GET_COUNT_UNDERWRITING_SUCCESS,
  GET_COUNT_UNDERWRITING_START,
  GET_COUNT_UNDERWRITING_FAIL,
  GET_CHECKER_UNDERWRITING_FAIL,
  GET_CHECKER_UNDERWRITING_REQUEST,
  GET_CHECKER_UNDERWRITING_START,
  GET_CHECKER_UNDERWRITING_SUCCESS,
  GET_CONTRACTS_UNDERWRITING_SUCCESS,
  GET_CONTRACTS_UNDERWRITING_START,
  GET_CONTRACTS_UNDERWRITING_REQUEST,
  GET_CONTRACTS_UNDERWRITING_FAIL,
  GET_STATUS_SCAN_UNDERWRITING_FAIL,
  GET_STATUS_SCAN_UNDERWRITING_REQUEST,
  GET_STATUS_SCAN_UNDERWRITING_START,
  GET_STATUS_SCAN_UNDERWRITING_SUCCESS,
  UNLOCK_DOCUMENT_UNDERWRITING_REQUEST,
  UNLOCK_DOCUMENT_UNDERWRITING_SUCCESS,
  UNLOCK_DOCUMENT_UNDERWRITING_START,
  UNLOCK_DOCUMENT_UNDERWRITING_FAIL,
  GET_ALL_BRANCHES_UNDERWRITING_REQUEST,
  GET_ALL_BRANCHES_UNDERWRITING_SUCCESS,
  GET_ALL_BRANCHES_UNDERWRITING_START,
  GET_ALL_BRANCHES_UNDERWRITING_FAIL,
  GET_STATUS_CHECKING_UNDERWRITING_START,
  GET_STATUS_CHECKING_UNDERWRITING_REQUEST,
  GET_STATUS_CHECKING_UNDERWRITING_FAIL,
  GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
} from '../types';

const { NO_SCANS_FOR_CHECK } = LANG_DICTIONARY;

function* getPartners() {
  const { GET_PARTNER_LIST } = API;
  try {
    yield put({ type: GET_PARTNERS_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_PARTNER_LIST,
      type: 'GET',
    });
    yield put({
      type: GET_PARTNERS_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'partner'),
    });
  } catch (e) {
    yield put({ type: GET_PARTNERS_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getUsers({ payload: { branchId, partnerId } }) {
  const { GET_USERS_DEPENDING_BRANCH_AND_PARTNER } = API;
  try {
    yield put({ type: GET_USERS_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_USERS_DEPENDING_BRANCH_AND_PARTNER,
      type: 'POST',
      body: {
        branchId,
        partnerId,
      },
    });
    yield put({
      type: GET_USERS_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'userFio', 'userId'),
    });
  } catch (e) {
    yield put({ type: GET_USERS_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getBranches({ payload }) {
  const { GET_BRANCHES_DEPENDING_PARTNER } = API;
  try {
    yield put({ type: GET_BRANCHES_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_BRANCHES_DEPENDING_PARTNER,
      type: 'POST',
      body: {
        partnerId: payload,
      },
    });
    yield put({
      type: GET_BRANCHES_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'branchName', 'branchId'),
    });
  } catch (e) {
    yield put({ type: GET_BRANCHES_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getAllBranches() {
  const { GET_ALL_BRANCHES_UNDERWRITING } = API;
  try {
    yield put({ type: GET_ALL_BRANCHES_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_ALL_BRANCHES_UNDERWRITING,
      type: 'GET',
    });
    yield put({
      type: GET_ALL_BRANCHES_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'treeName', 'id'),
    });
  } catch (e) {
    yield put({ type: GET_ALL_BRANCHES_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getCheckingStatus() {
  const { GET_STATUS_LIST_CHECKING_UNDERWRITING } = API;
  try {
    yield put({ type: GET_STATUS_CHECKING_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_STATUS_LIST_CHECKING_UNDERWRITING,
      type: 'GET',
    });
    yield put({
      type: GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'name', 'id'),
    });
  } catch (e) {
    yield put({ type: GET_STATUS_CHECKING_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getStatus() {
  const { GET_DOC_CHECKING_STATUS_LIST } = API;
  try {
    yield put({ type: GET_STATUS_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_DOC_CHECKING_STATUS_LIST,
      type: 'GET',
    });
    yield put({
      type: GET_STATUS_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'status'),
    });
  } catch (e) {
    yield put({ type: GET_STATUS_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getCountContract({ payload }) {
  const { GET_COUNT_CONTRACT_BY_FILTER } = API;
  try {
    yield put({ type: GET_COUNT_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_COUNT_CONTRACT_BY_FILTER,
      type: 'POST',
      body: payload.query,
    });
    payload.action(Math.ceil(data.count / 2));
    yield put({
      type: GET_COUNT_UNDERWRITING_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_COUNT_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getCheckerList() {
  const { GET_CHECKER_LIST } = API;
  try {
    yield put({ type: GET_CHECKER_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_CHECKER_LIST,
      type: 'GET',
    });
    yield put({
      type: GET_CHECKER_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'userFio', 'userId'),
    });
  } catch (e) {
    yield put({ type: GET_CHECKER_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getContractList({ payload, history }) {
  const { GET_CONTRACT_LIST } = API;
  try {
    yield put({ type: GET_CONTRACTS_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_CONTRACT_LIST,
      type: 'POST',
      body: payload,
    });

    yield put({
      type: GET_CONTRACTS_UNDERWRITING_SUCCESS,
      payload: data,
    });

    if (data.length) {
      history.push(ROUTES.checkMasterEditor, { access: true });
    } else {
      toastr.error('', NO_SCANS_FOR_CHECK);
    }
  } catch (e) {
    yield put({ type: GET_CONTRACTS_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getStatusScanList() {
  const { GET_STATUS_UNDERWRITING } = API;
  try {
    yield put({ type: GET_STATUS_SCAN_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_STATUS_UNDERWRITING,
      type: 'GET',
    });

    yield put({
      type: GET_STATUS_SCAN_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'name', 'code'),
    });
  } catch (e) {
    yield put({ type: GET_STATUS_SCAN_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* unlockDocuments({ payload }) {
  const { UNLOCK_DOCUMENT_UNDERWRITING } = API;
  try {
    yield put({ type: UNLOCK_DOCUMENT_UNDERWRITING_START });
    yield apiCall({
      url: UNLOCK_DOCUMENT_UNDERWRITING,
      type: 'POST',
      body: payload,
    });
    yield put({ type: UNLOCK_DOCUMENT_UNDERWRITING_SUCCESS });
  } catch (e) {
    yield put({ type: UNLOCK_DOCUMENT_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* underwritingMasterSaga() {
  yield takeLatest(GET_STATUS_CHECKING_UNDERWRITING_REQUEST, getCheckingStatus);
  yield takeLatest(GET_ALL_BRANCHES_UNDERWRITING_REQUEST, getAllBranches);
  yield takeLatest(UNLOCK_DOCUMENT_UNDERWRITING_REQUEST, unlockDocuments);
  yield takeLatest(GET_STATUS_SCAN_UNDERWRITING_REQUEST, getStatusScanList);
  yield takeLatest(GET_CONTRACTS_UNDERWRITING_REQUEST, getContractList);
  yield takeLatest(GET_CHECKER_UNDERWRITING_REQUEST, getCheckerList);
  yield takeLatest(GET_COUNT_UNDERWRITING_REQUEST, getCountContract);
  yield takeLatest(GET_STATUS_UNDERWRITING_REQUEST, getStatus);
  yield takeLatest(GET_PARTNERS_UNDERWRITING_REQUEST, getPartners);
  yield takeLatest(GET_USERS_UNDERWRITING_REQUEST, getUsers);
  yield takeLatest(GET_BRANCHES_UNDERWRITING_REQUEST, getBranches);
}

export default underwritingMasterSaga;
