import { put, takeEvery } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';
import {
  GET_VERSION_DP_FAIL,
  GET_VERSION_DP_SUCCESS,
  GET_VERSION_DP_REQUEST,
  GET_VERSION_DP_START,
} from '../types';

function* getVersionDp() {
  const { GET_VERSION_DP } = API;
  yield put({ type: GET_VERSION_DP_START });
  try {
    const { data } = yield apiCall({
      type: 'GET',
      url: GET_VERSION_DP,
    });
    yield put({ type: GET_VERSION_DP_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_VERSION_DP_FAIL, payload: getError(e) });
  }
}

function* versionDpSaga() {
  yield takeEvery(GET_VERSION_DP_REQUEST, getVersionDp);
}

export default versionDpSaga;
