import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import {
  LANG_DICTIONARY,
  ROUTES,
} from 'consts';
import block from 'bem-cn';
import './styles.scss';

const propTypes = {
  history: PropTypes.object,
  children: PropTypes.element,
  inboxUnreadMessagesCount: PropTypes.number,
};

const defaultProps = {
  history: {},
  children: null,
  inboxUnreadMessagesCount: 0,
};

const {
  BACK,
  CREATE,
  INCOMING,
  OUTGOING,
} = LANG_DICTIONARY;

const {
  home,
  inboxDocsTransferMessages,
  createDocsTransferMessage,
  sentDocsTransferMessages,
} = ROUTES;

const b = block('docs-transfer-menu');

const DocsTransferMenu = (props) => {
  const {
    history: { location: { pathname } },
    history,
    children,
    inboxUnreadMessagesCount,
  } = props;

  const backToDashboard = async () => {
    history.push(home);
  };

  const buttonClickHandler = (path) => () => {
    history.push(path);
  };

  const isUnreadInboxMessages = inboxUnreadMessagesCount > 0;
  const isCreateMessagePage = createDocsTransferMessage === pathname;
  const isInboxMessagesPage = inboxDocsTransferMessages === pathname;
  const isSentMessagesPage = sentDocsTransferMessages === pathname;

  return (
    <div className={b()}>
      <div className={b('navigation')}>
        <Button
          type="text"
          className="formContainer__content__wrap__backButton"
          onClick={() => backToDashboard(history)}
        >
          {BACK}
        </Button>
        <div className={b('menu')}>
          <div
            className={b('menu-item', { active: isCreateMessagePage })}
            role="button"
            onClick={buttonClickHandler(createDocsTransferMessage)}
            tabIndex={-1}
          >
            {CREATE}
          </div>
          <div
            className={b('menu-item', { active: isInboxMessagesPage })}
            role="button"
            onClick={buttonClickHandler(inboxDocsTransferMessages)}
            tabIndex={-1}
          >
            {INCOMING}
            {isUnreadInboxMessages && (
              <span className={b('unread-messages')}>
                {inboxUnreadMessagesCount}
              </span>
            )}
          </div>
          <div
            className={b('menu-item', { active: isSentMessagesPage })}
            role="button"
            onClick={buttonClickHandler(sentDocsTransferMessages)}
            tabIndex={-1}
          >
            {OUTGOING}
          </div>
        </div>
      </div>
      <div className={b('content')}>
        {children}
      </div>
    </div>
  );
};

DocsTransferMenu.propTypes = propTypes;
DocsTransferMenu.defaultProps = defaultProps;
export default DocsTransferMenu;
