const SEARCH_BANK_REQUEST = 'SEARCH_BANK_REQUEST';
const SEARCH_BANK_START = 'SEARCH_BANK_START';
const SEARCH_BANK_SUCCESS = 'SEARCH_BANK_SUCCESS';
const SEARCH_BANK_FAIL = 'SEARCH_BANK_FAIL';
const SEARCH_BANK_RESET = 'SEARCH_BANK_RESET';
const SEARCH_BANK_VALUE = 'SEARCH_BANK_VALUE';

export {
  SEARCH_BANK_REQUEST,
  SEARCH_BANK_START,
  SEARCH_BANK_SUCCESS,
  SEARCH_BANK_RESET,
  SEARCH_BANK_FAIL,
  SEARCH_BANK_VALUE,
};
