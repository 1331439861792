import React, { useEffect } from 'react';
import { useScript } from 'hooks/useScript';
import PropTypes from 'prop-types';
import { TRADING_VIEW_URLS } from '../../tradingViewConfig';
import { chartConfig } from './config';

const propTypes = {
  tvCode: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

const { chartUrl, name } = TRADING_VIEW_URLS;

const ChartWidget = ({ tvCode, code }) => {
  const { TradingView } = useScript(chartUrl, name);

  useEffect(() => {
    if (TradingView) {
      // eslint-disable-next-line
      new TradingView.widget({ ...chartConfig, symbol: `${tvCode}:${code}` });
    }
  }, [TradingView]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_chart_root_id" />
    </div>
  );
};

ChartWidget.propTypes = propTypes;

export default ChartWidget;
