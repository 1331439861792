export const getTotalPagesApiPublicSelector = ({ publicApi: { totalPages } }) => totalPages;
export const getPublicApiListSelector = ({ publicApi: { publicApiList } }) => publicApiList;
export const getCurrentPageApiPublicSelector = ({ publicApi: { currentPage } }) => currentPage;
export const getApiDataSelector = ({ publicApi: { apiData } }) => apiData;
export const getApiSelectDataSelector = ({ publicApi: { apiSelectData } }) => apiSelectData;
export const getOptionsPublicApiSelector =
    ({ publicApi: { partners, branches, users } }) => ({ partners, branches, users });
export const getLoadersPublicApiSelector =
    ({
      publicApi: {
        loadingBranches,
        loadingPartners,
        loadingUsers,
      },
    }) => ({ loadingBranches, loadingPartners, loadingUsers });
export const getStatusPopupPublicApiSelector = ({ publicApi: { isOpenPopup } }) => isOpenPopup;
