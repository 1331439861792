import {
  GET_SELECT_INFO_SUCCESS,
  GET_CATALOG_INFO_TABLE_SUCCESS,
  ADD_NEW_ROW_TO_CATALOG,
  EDIT_ROW_IN_CATALOG,
  DELETE_ROW_IN_CATALOG,
  RELOAD_CHANGES,
  ACCESS_EDIT,
  INITIAL_STORE,
  SAVE_CHANGES_FOR_CATALOG_FAIL,
} from '../types';

const INITIAL_STATE = {
  initialTable: [],
  tableInfo: [],
  selectsInfo: [],
  headers: [],
  referenceName: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SAVE_CHANGES_FOR_CATALOG_FAIL: {
      const { tableInfo } = state;
      const newTableInfo = tableInfo.map((item) => {
        if (item.id === payload.rowId) {
          return {
            ...item,
            cannotSave: true,
            editAccess: false,
          };
        }

        return item;
      });

      return {
        ...state,
        tableInfo: newTableInfo,
      };
    }

    case ACCESS_EDIT: {
      const { tableInfo } = state;
      const newTableInfo = tableInfo.map((item, index) => {
        if (index === payload) {
          return {
            ...item,
            editAccess: !item.editAccess,
          };
        }

        return item;
      });

      return {
        ...state,
        tableInfo: newTableInfo,
      };
    }
    case GET_CATALOG_INFO_TABLE_SUCCESS: {
      return {
        ...state,
        headers: payload.headers,
        tableInfo: payload.data.map((item) => ({
          editAccess: false,
          fromDataBase: true,
          createdRow: false,
          deleteStatus: false,
          id: item[0],
          data: item,
        })),
        initialTable: payload.data.map((item) => ({
          editAccess: false,
          createdRow: false,
          fromDataBase: true,
          deleteStatus: false,
          id: item[0],
          data: item,
        })),
        referenceName: payload.referenceName,
      };
    }
    case GET_SELECT_INFO_SUCCESS: {
      return {
        ...state,
        selectsInfo: payload,
      };
    }
    case ADD_NEW_ROW_TO_CATALOG: {
      const { tableInfo } = state;

      return {
        ...state,
        tableInfo: [...tableInfo, payload],
      };
    }
    case EDIT_ROW_IN_CATALOG: {
      const { tableInfo } = state;
      const { indexRow, changedIndex, value } = payload;
      const newTableInfo = tableInfo.map((row, index) => {
        if (index === indexRow) {
          const newData = row.data.map((item, i) => {
            if (i === changedIndex) {
              return value;
            }

            return item;
          });

          return {
            ...row,
            modify: row.fromDataBase,
            cannotSave: false,
            data: newData,
          };
        }

        return row;
      });

      return {
        ...state,
        tableInfo: newTableInfo,
      };
    }

    case DELETE_ROW_IN_CATALOG: {
      const { tableInfo } = state;
      const newTableInfo = tableInfo.map((item, index) => {
        if (index === payload) {
          return {
            ...item,
            deleteStatus: !item.deleteStatus,
            deletedFromDataBase: item.fromDataBase ? !item.deleteStatus : null,
            editAccess: false,
          };
        }

        return item;
      });

      return {
        ...state,
        tableInfo: newTableInfo,
      };
    }

    case RELOAD_CHANGES: {
      const { initialTable } = state;

      return {
        ...state,
        tableInfo: initialTable,
      };
    }

    case INITIAL_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }
    default:
      return state;
  }
};
