import {
  GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_CRON_TYPES_SUCCESS,
  GET_PRIORITIES_SUCCESS,
  SET_ACTIVE_TAB,
  SET_SETTINGS_FOR_JOB,
  OPEN_POPUP_FOR_EDIT,
  OPEN_POPUP_FOR_CONFIRM_STOP,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_JOBS_COUNT_SUCCESS,
  CLEAR_FORM,
} from '../types';

const INITIAL_STATE = {
  activeJobs: {},
  proccessJobs: {},
  completeJobs: {},
  failJobs: {},
  tableInfo: {},
  numberOfActive: 0,
  numberOfProccess: 0,
  numberOfComplete: 0,
  numberOfFail: 0,
  activeTab: 'active',
  prioritiSelect: [],
  cronTypeSelect: [],
  popUpOpenEdit: false,
  popUpOpenStop: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CLEAR_FORM: {
      return {
        ...INITIAL_STATE,
      };
    }
    case GET_JOBS_COUNT_SUCCESS: {
      return {
        ...state,
        numberOfActive: payload.active,
        numberOfProccess: payload.process,
        numberOfComplete: payload.complete,
        numberOfFail: payload.failed,
      };
    }
    case GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS: {
      return {
        ...state,
        tableInfo: payload,
        completeJobs: payload,
      };
    }
    case GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS: {
      return {
        ...state,
        tableInfo: payload,
        completeJobs: payload,
      };
    }
    case GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS: {
      return {
        ...state,
        tableInfo: payload,
        proccessJobs: payload,
      };
    }
    case OPEN_POPUP_FOR_CONFIRM_STOP: {
      return {
        ...state,
        popUpOpenStop: payload,
      };
    }

    case OPEN_POPUP_FOR_EDIT: {
      return {
        ...state,
        popUpOpenEdit: payload,
      };
    }

    case GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS: {
      return {
        ...state,
        tableInfo: payload,
        activeJobs: payload,
      };
    }

    case GET_CRON_TYPES_SUCCESS: {
      return {
        ...state,
        cronTypeSelect: payload,
      };
    }

    case GET_PRIORITIES_SUCCESS: {
      return {
        ...state,
        prioritiSelect: payload,
      };
    }

    case SET_SETTINGS_FOR_JOB: {
      const { id, name, value } = payload;
      const { tableInfo, tableInfo: { data } } = state;
      const newData = data.map((item) => {
        if (id === item.id) {
          return {
            ...item,
            [name]: value,
          };
        }

        return item;
      });

      return {
        ...state,
        tableInfo: {
          ...tableInfo,
          data: newData,
        },
      };
    }

    case SET_ACTIVE_TAB: {
      return {
        ...state,
        activeTab: payload.tabName,
        tableInfo: payload.tab,
      };
    }

    default:
      return state;
  }
};
