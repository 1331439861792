import React from 'react';
import { Row, Col } from 'reactstrap';
import { MainBtn } from 'components';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { getFormattedDate } from 'helpers';
import PropTypes from 'prop-types';
import './styles.scss';
import { connect } from 'react-redux';
import { actions, checkAccessAction } from 'roles';

const propTypes = {
  data: PropTypes.object.isRequired,
  handleClickBackBtn: PropTypes.func.isRequired,
  roles: PropTypes.object,
};

const defaultProps = {
  roles: {},
};

const {
  CONTRACT_NUMBER,
  DATE_CONTRACT,
  INSURER,
  BIRTH_DATE_SHORT,
  PRODUCT,
  INSURANCE_SUM,
  BACK_PAGE,
} = LANG_DICTIONARY;

const b = block('contract-info-form');

const Controls = (props) => {
  const { data, handleClickBackBtn, roles } = props;
  const isCheckAction = checkAccessAction(actions.SHOW_INSURER_CONTRACT_INFO, roles);

  const {
    contract: {
      ctsNum,
      dateCreate,
      productName,
      insuranceAmmount,
    },
    insurer: {
      birthDate,
      fio,
    },
  } = data;

  const renderHeaderBlock = (...fields) => (
    <Col md="3" sm="6">
      <div className={b('header-block')}>
        <table>
          <tbody>
            {fields.map((field) => {
              const { title, value } = field;

              return (
                <tr key={title}>
                  <td>
                    {title}
                    :
                  </td>
                  <td>
                    {value}
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </Col>
  );

  return (
    <div className={b('controls')}>
      <Row>
        <Col md="3" sm="6">
          <MainBtn text={BACK_PAGE} onClick={handleClickBackBtn} />
        </Col>
        {renderHeaderBlock(
          {
            title: CONTRACT_NUMBER,
            value: ctsNum,
          },
          {
            title: DATE_CONTRACT,
            value: getFormattedDate(dateCreate),
          },
        )}
        {renderHeaderBlock(
          {
            title: INSURER,
            value: isCheckAction && fio,
          },
          {
            title: BIRTH_DATE_SHORT,
            value: isCheckAction && getFormattedDate(birthDate),
          },
        )}
        {renderHeaderBlock(
          {
            title: PRODUCT,
            value: productName,
          },
          {
            title: INSURANCE_SUM,
            value: insuranceAmmount,
          },
        )}

      </Row>
    </div>
  );
};

const mapStateToProps = (
  {
    authReducer: {
      roles,
    },
  },
) => ({
  roles,
});

Controls.propTypes = propTypes;
Controls.defaultProps = defaultProps;
export default connect(mapStateToProps)(Controls);
