export default (e) => {
  const { left, top } = e.target.getBoundingClientRect();
  const bodyRect = document.body.getBoundingClientRect();
  const ducumentTop = top - bodyRect.top;

  return {
    top: ducumentTop,
    left,
  };
};
