import React, { Component } from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import { toastr } from 'react-redux-toastr';
import { connect } from 'react-redux';
import { withCustomRouter } from 'HOC';
import {
  LANG_DICTIONARY,
  ROUTES,
  PRODUCT_VERSION,
  REF_INS_STATUSES_CODE_ENUM,
  REF_INS_STATUSES_NAME_ENUM,
} from 'consts';
import { checkAccessAction, actions } from 'roles';
import {
  Button,
  Icon,
  Dimmer,
  Segment,
  Loader,
  Select,
} from 'semantic-ui-react';
import './styles.scss';
import {
  DocumentStatusBar,
  FileUpload,
  FileCount,
  ConfirmationPopup,
  Hint,
  SelectButton,
  AnnulContractWarning,
  CustomCheckBox,
  PopupContainer,
} from 'components';
import {
  getDataContract,
  uploadScan,
  setAnnulStatus,
  deleteScan,
  setTypeScan,
  saveScan,
  getContractFile,
  getProducts,
  getRegenerateContract,
  getContractCopy,
  getAdditionalDocumentList,
  downloadAdditionalDocument,
  getCopyNszContract,
  getCopyKszContract,
  setInitStateFiles,
  setCtsIdArr,
  getSigningType,
  setSigningType,
  setIsScanUploadByClient,
  checkIsActiveConfirmButton,
  setInitStateSigningType,
  setSmsSent,
  sentSms,
} from 'redux/rootActions';

const {
  PRINT_DOCUMENT,
  CANCEL,
  SAVE,
  COUNT_OVERSIZE,
  DOWNLOAD,
  PRINT_ERROR,
  QUESTION_RESET_TYPE,
  QUESTION_CHANGE_TYPE,
  QUESTION_DELETE_FILE,
  REBUILD_CONTRACTS_COMPLECT,
  COPY_CONTRACT,
  PRINT_OTHER_DOCUMENTS,
  SCANS_UPLOADED_BY_CLIENT,
  CONFIRM_PHONE,
  COMPLETE_REGISTRATION,
  REQUEST_CONFIRMATION_AGAIN,
  SEND_NEW_LINK_CONFIRM,
  CHANGE,
  SIGNING_METHOD,
} = LANG_DICTIONARY;

const b = block('execution');

const defaultProps = {
  GetDataContract: null,
  GetContractFile: null,
  UploadScan: null,
  contractFiles: null,
  DeleteFile: null,
  SetType: null,
  contractData: {},
  selectedTypes: [],
  ctsId: null,
  stage: 1,
  isPrinted: false,
  isLoadingScan: false,
  additionalParams: {},
  roles: {},
  signPolicy: () => null,
  setAnnulStatusContract: () => null,
  partnerId: 0,
  getNewContract: () => null,
  getCopy: () => null,
  getCopyKsz: () => null,
  additionalDocuments: [],
  downloadAdditionalFile: () => null,
  scenario: '',
  setInitState: () => null,
  ctsIdArr: [],
  setCtsIdsArr: () => null,
  insuredIsSame: false,
  getSigningTypes: () => null,
  signingTypes: [],
  setType: () => null,
  selectedSigningType: '',
  isScanUploadByClient: false,
  setIsScanUploadClient: () => null,
  checkIsActiveButton: () => null,
  setInitStateSignType: () => null,
  setIsSentSms: () => null,
  isSmsSent: false,
  sendingSms: () => null,
  smsTimer: 0,
};

const propTypes = {
  getCopy: PropTypes.func,
  getCopyKsz: PropTypes.func,
  getNewContract: PropTypes.func,
  partnerId: PropTypes.number,
  signPolicy: PropTypes.func,
  GetDataContract: PropTypes.func,
  GetContractFile: PropTypes.func,
  UploadScan: PropTypes.func,
  DeleteFile: PropTypes.func,
  SetType: PropTypes.func,
  contractFiles: PropTypes.arrayOf(PropTypes.object),
  contractData: PropTypes.object,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  selectedTypes: PropTypes.arrayOf(PropTypes.number),
  ctsId: PropTypes.number,
  stage: PropTypes.number,
  isPrinted: PropTypes.bool,
  isLoadingScan: PropTypes.bool,
  additionalParams: PropTypes.object,
  roles: PropTypes.object,
  additionalDocuments: PropTypes.arrayOf(PropTypes.object),
  downloadAdditionalFile: PropTypes.func,
  getCopyNsz: PropTypes.func.isRequired,
  scenario: PropTypes.string,
  setInitState: PropTypes.func,
  setAnnulStatusContract: PropTypes.func,
  ctsIdArr: PropTypes.array,
  setCtsIdsArr: PropTypes.func,
  insuredIsSame: PropTypes.bool,
  getSigningTypes: PropTypes.func,
  signingTypes: PropTypes.array,
  setType: PropTypes.func,
  selectedSigningType: PropTypes.string,
  isScanUploadByClient: PropTypes.bool,
  setIsScanUploadClient: PropTypes.func,
  checkIsActiveButton: PropTypes.func,
  setInitStateSignType: PropTypes.func,
  setIsSentSms: PropTypes.func,
  isSmsSent: PropTypes.bool,
  sendingSms: PropTypes.func,
  smsTimer: PropTypes.number,
};

const defaultScanType = {
  refCtsInsScanTypeId: null,
  refCtsInsScanTypeName: 'Не выбрано',
};

const bcsPartnerId = 17;

class ExecutionData extends Component {
  constructor(props) {
    super(props);
    const { GetDataContract, ctsId } = props;
    const {
      additionalParams: { printForm },
      match: { params },
      history,
    } = props;

    if (printForm) {
      this.ctsId = Number(params.id);
      GetDataContract(this.ctsId, history);
    } else {
      this.ctsId = ctsId;
      this.checkStageAvailable();
    }

    this.state = {
      isError: false,
      confirmationText: '',
      scanId: 0,
      confirmAction: () => null,
      isOpenPopup: false,
      isChangeLink: false,
    };
  }

  componentWillUnmount() {
    const { setInitState, setInitStateSignType } = this.props;

    setInitStateSignType();
    setInitState();
  }

  // eslint-disable-next-line camelcase
  UNSAFE_componentWillMount() {
    const {
      insuredIsSame,
      getSigningTypes,
      ctsId,
      checkIsActiveButton,
    } = this.props;

    checkIsActiveButton(ctsId || this.ctsId);

    if (insuredIsSame) {
      getSigningTypes(ctsId || this.ctsId);
    }
  }

  openPopup = () => {
    const {
      isOpenPopup,
    } = this.state;

    this.setState({ isOpenPopup: !isOpenPopup });
  }

  checkStageAvailable = () => {
    const {
      stage,
      history,
      GetDataContract,
    } = this.props;
    if (stage < 5) {
      history.push(ROUTES.home);
    } else {
      GetDataContract(this.ctsId, history);
    }
  };

  onUpload = async (files) => {
    const { UploadScan } = this.props;
    const filesArray = Array.from(files);

    if (!this.checkFileCountOversize(files)) {
      toastr.error('', COUNT_OVERSIZE);

      return;
    }

    filesArray.map((file) => {
      const formData = new FormData();
      formData.append('file', file);
      formData.append('ctsId', this.ctsId);

      return UploadScan({
        formData,
        file,
        ctsId: this.ctsId,
        fileName: file.name,
      });
    });
  };

  onPrintFile = (statusType) => {
    const {
      GetContractFile,
      additionalParams: {
        printForm,
      },
      contractData: {
        isPrinted,
      },
      roles,
    } = this.props;
    const isAccessPrint = checkAccessAction(actions.GET_SIGNED_CONTRACT_FILE, roles);
    if (printForm && !isPrinted && !isAccessPrint) {
      toastr.error('', PRINT_ERROR);
    } else {
      GetContractFile({ ctsId: this.ctsId, statusType });
    }
  };

  regenerateFie = () => {
    const { getNewContract } = this.props;
    getNewContract(this.ctsId);
  };

  filterTypes = (types, currentTypeId) => {
    const { selectedTypes } = this.props;
    const filteredTypes = types.filter(({ refCtsInsScanTypeId: ctsType }) => {
      const condition = selectedTypes
        .find((selectedType) => selectedType === ctsType);

      return !condition || ctsType === currentTypeId;
    });

    return selectedTypes.length ? filteredTypes : types;
  };

  getTypeList = (fileId) => {
    const { contractFiles } = this.props;
    const { typeId } = contractFiles.find((file) => file.id === fileId);
    const initialTypes = this.getInitialFileTypes();

    if (!initialTypes.length) return [];

    const types = this.filterTypes(initialTypes, typeId);

    return types.map(({
      refCtsInsScanTypeId: value,
      refCtsInsScanTypeName: name,
    }) => ({ key: value, text: name, value }));
  };

  confirmChangeType = (value, id) => {
    const { SetType } = this.props;
    SetType(value, id);
    this.setState({
      confirmationText: '',
    });
  };

  onChangeType = (value, id) => {
    const { contractFiles, contractData: { allScanTypes } } = this.props;
    const { typeId } = contractFiles.find((scan) => scan.id === id);
    const { refCtsInsScanTypeName: name } = allScanTypes
      .find((type) => type.refCtsInsScanTypeId === value);
    if (typeId && typeId !== value) {
      const confirmText = value ? `${QUESTION_CHANGE_TYPE} "${name}"?`
        : `${QUESTION_RESET_TYPE}?`;
      this.setState({
        confirmationText: confirmText,
        scanId: id,
        confirmAction: () => this.confirmChangeType(value, id),
      });
    } else {
      this.confirmChangeType(value, id);
    }
  };

  checkFileCountOversize = (newFiles) => {
    const { contractFiles, contractData: { allScanTypes } } = this.props;
    if (!allScanTypes) return false;

    const currentCount = newFiles.length + contractFiles.length;

    return currentCount <= allScanTypes.length;
  };

  checkEmptyTypes = (contractFiles) => contractFiles.find((file) => !file.typeId);

  checkValidation = () => {
    const {
      contractFiles,
      isPrinted,
      contractData: {
        isPrinted: isPrintedServer,
      },
      selectedSigningType,
    } = this.props;
    const initialTypes = this.getInitialFileTypes();
    const necessaryCount = initialTypes.length - 1;
    const filesCount = contractFiles.length;
    const accessPrint = isPrinted || isPrintedServer;

    if (selectedSigningType === 'sms_sign') {
      return filesCount >= necessaryCount && !this.checkEmptyTypes(contractFiles);
    }

    return filesCount >= necessaryCount && !this.checkEmptyTypes(contractFiles) && accessPrint;
  };

  checkOpportunityToUpload = () => {
    const { contractFiles } = this.props;
    const initialTypes = this.getInitialFileTypes();
    const necessaryCount = initialTypes.length;
    const filesCount = contractFiles.length;

    return filesCount >= necessaryCount - 1;
  };

  getInitialFileTypes = () => {
    const { contractData: { allScanTypes }, selectedSigningType } = this.props;

    const isSmsSigning = selectedSigningType === 'sms_sign';

    const scanTypesUploadedByEmployee = allScanTypes?.filter(
      (item) => item.refCtsInsScanTypeCode === 'insurer_passport_main_turn' || item.refCtsInsScanTypeCode === 'insurer_passport_registration',
    );

    if (!allScanTypes?.length) return [];

    if (isSmsSigning) {
      return [defaultScanType, ...scanTypesUploadedByEmployee];
    }

    return [defaultScanType, ...allScanTypes];
  };

  onAdditionalDocumentClick = (item) => {
    const {
      contractData: {
        contractNum,
        insLineIszObjectId,
      },
      downloadAdditionalFile,
      ctsId,
      history: {
        location: {
          state,
        },
      },
    } = this.props;

    downloadAdditionalFile({
      ...item,
      contractNum,
      insLineIszObjectId,
      ctsId: ctsId || state.ctsId,
    });
  };

  deleteFile = (id, fileName) => {
    this.setState({
      confirmationText: `${QUESTION_DELETE_FILE} ${fileName}`,
      scanId: id,
      confirmAction: this.deleteFileConfirm,
    });
  };

  deleteFileConfirm = () => {
    const { DeleteFile } = this.props;
    const { scanId } = this.state;
    DeleteFile(scanId);
    this.setState({
      confirmationText: '',
    });
  };

  getCopy = (...args) => {
    const {
      getCopy,
      getCopyKsz,
      getCopyNsz,
      scenario,
      contractData: {
        scenario: scenarioForPrintForm,
      },
      additionalParams: { printForm },
    } = this.props;

    const currentScenario = printForm ? scenarioForPrintForm : scenario;

    switch (currentScenario) {
      case PRODUCT_VERSION.normal:
      case PRODUCT_VERSION.coupon:
      case PRODUCT_VERSION.normalCurrency:
      case PRODUCT_VERSION.couponCurrency:
      case PRODUCT_VERSION.constructorIsz:
      case PRODUCT_VERSION.constructorIszDollar:
        return getCopy(...args);

      case PRODUCT_VERSION.nsz_1:
      case PRODUCT_VERSION.nsz_2:
      case PRODUCT_VERSION.nsz_3:
        return getCopyNsz(...args);

      case PRODUCT_VERSION.ksz_1:
        return getCopyKsz(...args);

      default:
        return {};
    }
  };

  onSave = () => {
    const {
      additionalParams: {
        printForm,
      },
      history,
      signPolicy,
      contractFiles,
      partnerId,
    } = this.props;

    const scanParams = contractFiles.map(({ id, typeId }) => ({
      ctsInsScanId: id,
      ctsInsScanType: typeId,
    }));

    signPolicy({
      ctsId: this.ctsId,
      scanParams,
      history,
      partnerId,
      isPrintForm: printForm,
    });
  };

  onSaveSmsSigning = () => {
    const {
      history,
    } = this.props;

    history.push(ROUTES.home);
  }

  render() {
    const {
      contractData,
      contractData: {
        isCopyButtonAvailable,
        isPrinted: contractPrinted,
        scenario,
        isWasDocumentsSigned,
        statusCanceled,
        statusSigned,
        productTip,
        statusCode,
        statusCurrent,
      },
      contractFiles,
      isLoadingScan,
      additionalParams: {
        printForm,
      },
      roles,
      history,
      isPrinted,
      additionalDocuments,
      setAnnulStatusContract,
      ctsIdArr,
      ctsId,
      setCtsIdsArr,
      insuredIsSame,
      signingTypes,
      setType,
      selectedSigningType,
      setIsScanUploadClient,
      isScanUploadByClient,
      checkIsActiveButton,
      partnerId,
      isSmsSent,
      setIsSentSms,
      sendingSms,
      smsTimer,
    } = this.props;
    const {
      isError,
      confirmationText,
      confirmAction,
      isOpenPopup,
      isTimeEnd,
      time,
      isChangeLink,
    } = this.state;

    const timerFunc = () => {
      this.setState({ isTimeEnd: true });
      let timer = smsTimer;
      const int = setInterval(() => {
        if (timer === 0) {
          this.setState({ isTimeEnd: false });
          clearInterval(int);

          return;
        }
        timer -= 1;
        this.setState({ time: timer });
      }, 1000);
    };

    const isCopyActive = () => {
      if (!isConstructorScenarios) {
        return true;
      }

      return isCopyButtonAvailable;
    };

    const isSmsSigning = selectedSigningType === 'sms_sign';

    const expectedFiles = this.getInitialFileTypes();

    const disableAnnulButton = printForm &&
    (statusCode === REF_INS_STATUSES_CODE_ENUM.CANCELED || isWasDocumentsSigned) ?
      !roles.delete_contract_unsigned : false;
    const showRegenerateButton = roles.regeneration_contract;
    const printedContract = isPrinted || contractPrinted;
    const isDisableActionForm = isWasDocumentsSigned ||
      statusCode === statusCanceled ||
      statusCode === statusSigned;

    const isConstructorScenarios = [
      PRODUCT_VERSION.constructorIsz,
      PRODUCT_VERSION.constructorIszDollar,
    ].includes(scenario);

    const copyContract = () => {
      this.getCopy(this.ctsId, history);

      setIsSentSms(false);

      const rebuiltArr = ctsIdArr.filter((item) => item !== ctsId);

      setCtsIdsArr(rebuiltArr);
    };

    const isDisabledAnnulButton = statusCurrent === REF_INS_STATUSES_NAME_ENUM.ANNUL;

    const isDisableRegistration = () => {
      if (isSmsSigning && isSmsSent) {
        if (partnerId === bcsPartnerId && isSmsSent) {
          return false;
        }
        if (partnerId !== bcsPartnerId && !isScanUploadByClient && this.checkValidation()) {
          return false;
        }

        if (partnerId !== bcsPartnerId && isScanUploadByClient) {
          return false;
        }
      }

      return true;
    };

    const changeType = (data) => {
      setType(data.value);
      if (data.value === 'sms_sign') {
        checkIsActiveButton(ctsId || this.ctsId);
      }
    };

    const handleChangeCheckBox = (name, checked) => {
      if (isSmsSent) {
        this.setState({ isChangeLink: true });

        return;
      }
      setIsScanUploadClient(!checked);
    };

    const sendSms = () => {
      sendingSms(ctsId || this.ctsId, isScanUploadByClient);
      setIsSentSms(true);
      this.setState({ time: smsTimer });
      timerFunc();
    };

    const changeLink = () => {
      this.setState({ isChangeLink: false });
      setIsSentSms(false);
      setIsScanUploadClient(!isScanUploadByClient);
    };

    const closePopup = () => {
      this.setState({ isChangeLink: false });
    };

    const checkIsDisabledSendSmsBtn = () => {
      if (isSmsSigning && !isScanUploadByClient) {
        return Boolean(!this.checkValidation() || isTimeEnd);
      }

      if (isSmsSigning && isScanUploadByClient) {
        return Boolean(false || isTimeEnd);
      }

      return true;
    };

    return (
      <div className={b('formContainer')}>
        <PopupContainer
          isOpen={isChangeLink}
          close={closePopup}
          isCloseButton
          className={b('popupContainer')}
        >
          <div>{SEND_NEW_LINK_CONFIRM}</div>
          <Button
            type="button"
            className={b('confirmPopup').toString()}
            onClick={changeLink}
          >
            {CHANGE}
          </Button>
        </PopupContainer>
        <ConfirmationPopup
          open={Boolean(confirmationText)}
          text={confirmationText}
          handleConfirm={confirmAction}
          handleCancel={() => this.setState({ confirmationText: '' })}
        />
        <div className={b('formWrap')}>
          <div className={b('leftBar')}>
            { DocumentStatusBar(contractData) }
            <Button
              type="button"
              className={b('printButton').toString()}
              onClick={() => this.onPrintFile(statusCode)}
            >
              <Icon name="print" />
              { PRINT_DOCUMENT }
            </Button>
            {printedContract && showRegenerateButton && (
            <Button
              type="button"
              className={b('printButton').toString()}
              onClick={this.regenerateFie}
            >
              <Icon name="refresh" />
              { REBUILD_CONTRACTS_COMPLECT }
            </Button>
            )}
            {Boolean(additionalDocuments.length) && (
              <SelectButton
                data={additionalDocuments}
                text={PRINT_OTHER_DOCUMENTS}
                icon="file alternate"
                onClickDropDown={this.onAdditionalDocumentClick}
              />
            )}
            {
              insuredIsSame && (
              <div className={b('select-type')}>
                <Select
                  options={signingTypes}
                  disabled={isWasDocumentsSigned}
                  placeholder={SIGNING_METHOD}
                  onChange={(event, data) => changeType(data)}
                  value={selectedSigningType}
                />
              </div>
              )
            }
            {
              selectedSigningType && isSmsSigning && (
              <>
                <div className={b('checkbox-block')}>
                  <CustomCheckBox
                    checked={isScanUploadByClient}
                    onChange={handleChangeCheckBox}
                  />
                  <p className={b('checkbox-text')}>{SCANS_UPLOADED_BY_CLIENT}</p>
                </div>
                <Button
                  disabled={checkIsDisabledSendSmsBtn()}
                  type="button"
                  className={b('send-sms-button')}
                  onClick={() => sendSms()}
                >
                  <p className={b('send-sms-text')}>{CONFIRM_PHONE}</p>
                </Button>
                {
                  isTimeEnd && (
                  <p className={b('send-sms-text-repeat')}>{REQUEST_CONFIRMATION_AGAIN(time)}</p>
                  )
                }
              </>
              )
            }
          </div>
          <div className={b('rightBar')}>
            <Hint tipText={productTip} />
            {Boolean(expectedFiles.length) && (
              <>
                <FileCount
                  contractFiles={contractFiles}
                  expectedFiles={expectedFiles}
                  className={b('fileCount')}
                />
                <div className={b('wrapper-file-input', { error: isError })}>
                  {isLoadingScan && (
                    <input
                      type="file"
                      onClick={(e) => e.preventDefault()}
                      className={b('fake-input')}
                    />
                  )}
                  <Dimmer.Dimmable as={Segment}>
                    <Dimmer active={isLoadingScan} inverted>
                      <Loader>{DOWNLOAD}</Loader>
                    </Dimmer>
                    <FileUpload
                      onUpload={this.onUpload}
                      onDelete={this.deleteFile}
                      files={contractFiles}
                      getTypeList={this.getTypeList}
                      onChangeType={this.onChangeType}
                      disabled={this.checkOpportunityToUpload() || isDisableActionForm}
                      disableControls={isDisableActionForm}
                    />
                  </Dimmer.Dimmable>
                </div>
              </>
            )}
          </div>
        </div>
        <div className={b('bottomContent')}>
          <Button
            type="button"
            className={b('cancelButton').toString()}
            onClick={() => this.openPopup()}
            disabled={disableAnnulButton || isDisabledAnnulButton}
          >
            {CANCEL}
            <div className={b('cancelIcon')}>
              <Icon name="times" />
            </div>
          </Button>
          {isCopyActive() && (
            <Button
              type="button"
              className={b('saveButton').toString()}
              onClick={() => copyContract()}
            >
              {COPY_CONTRACT}
            </Button>
          )}
          {
            isSmsSigning ? (
              <Button
                type="button"
                className={b('saveButton').toString()}
                onClick={this.onSaveSmsSigning}
                disabled={isDisableRegistration()}
              >
                {COMPLETE_REGISTRATION}
              </Button>
            ) : (
              <Button
                type="button"
                className={b('saveButton').toString()}
                onClick={this.onSave}
                disabled={!this.checkValidation() || isDisableActionForm}
              >
                {SAVE}
              </Button>
            )
          }
          <AnnulContractWarning
            setAnnulStatusContract={setAnnulStatusContract}
            ctsId={this.ctsId}
            openPopup={this.openPopup}
            isOpen={isOpenPopup}
          />
        </div>
      </div>
    );
  }
}

ExecutionData.defaultProps = defaultProps;
ExecutionData.propTypes = propTypes;

function mapStateToProps(state) {
  const {
    checkoutReducer: {
      contractData,
      isPrinted,
      error,
      ctsId,
      additionalDocuments,
      ctsIdArr,
      insuredIsSame,
      signingTypes,
      selectedSigningType,
      isScanUploadByClient,
      isActive,
      isSmsSent,
      smsTimer,
    },
    files: {
      isLoadingScan,
      contractFiles,
      selectedTypes,
    },
    authReducer: {
      roles,
      partnerId,
    },
  } = state;

  return {
    partnerId,
    contractData,
    contractFiles,
    selectedTypes,
    isPrinted,
    error,
    ctsId,
    isLoadingScan,
    roles,
    additionalDocuments,
    ctsIdArr,
    insuredIsSame,
    signingTypes,
    selectedSigningType,
    isScanUploadByClient,
    isActive,
    isSmsSent,
    smsTimer,
  };
}

export default withCustomRouter(connect(
  mapStateToProps,
  {
    GetDataContract: getDataContract,
    GetContractFile: getContractFile,
    UploadScan: uploadScan,
    setAnnulStatusContract: setAnnulStatus,
    DeleteFile: deleteScan,
    SetType: setTypeScan,
    signPolicy: saveScan,
    getProductsByUser: getProducts,
    getNewContract: getRegenerateContract,
    getCopy: getContractCopy,
    getCopyKsz: getCopyKszContract,
    getCopyNsz: getCopyNszContract,
    getAdditionalDocuments: getAdditionalDocumentList,
    downloadAdditionalFile: downloadAdditionalDocument,
    setInitState: setInitStateFiles,
    setCtsIdsArr: setCtsIdArr,
    getSigningTypes: getSigningType,
    setType: setSigningType,
    setIsScanUploadClient: setIsScanUploadByClient,
    checkIsActiveButton: checkIsActiveConfirmButton,
    setInitStateSignType: setInitStateSigningType,
    setIsSentSms: setSmsSent,
    sendingSms: sentSms,
  },
)(ExecutionData));
