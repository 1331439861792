import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { PopupContainer } from 'components';
import './styles.scss';

const {
  WHEN_FILE_IMPORT_FROM_1C,
  ERRORS_WHEN_FILE_IMPORT_FROM_1C,
  SUCCESS_WHEN_FILE_IMPORT_FROM_1C,
  ATTENTION,
} = LANG_DICTIONARY;

const propTypes = {
  popupIsOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string,
  successImport: PropTypes.bool,
};
const defaultProps = {
  popupIsOpen: false,
  closePopup: () => null,
  errors: [],
  fileName: '',
  successImport: false,
};

const b = block('import-from-1c-popup');

const ImportFrom1CPopup = ({
  closePopup,
  errors,
  popupIsOpen,
  fileName,
  successImport,
}) => {
  const result = successImport ? SUCCESS_WHEN_FILE_IMPORT_FROM_1C
    : ERRORS_WHEN_FILE_IMPORT_FROM_1C;
  const importPopup = (
    <div className={b()}>
      <div className={b('header')}>
        <div className={b('header-warning')}>{`${ATTENTION}!`}</div>
        <div className={b('header-text')}>
          {`${WHEN_FILE_IMPORT_FROM_1C} ${fileName} ${result}`}
        </div>
      </div>
      {successImport ? null
        : (
          <div className={b('import-errors')}>
            {errors.map((error) => (
              <div className={b('error-row')}>
                <Icon size="big" name="warning sign" className={b('warning-sign').toString()} />
                {error}
              </div>
            ))}
          </div>
        )}
    </div>
  );

  return (
    <PopupContainer
      close={closePopup}
      isOpen={popupIsOpen}
      children={importPopup}
      isCloseButton
    />
  );
};

ImportFrom1CPopup.propTypes = propTypes;
ImportFrom1CPopup.defaultProps = defaultProps;
export default ImportFrom1CPopup;
