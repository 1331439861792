const MASK = {
  serial: '9999',
  passportNumber: '999999',
  tempCertNumber: '999999999999',
  kp: '999-999',
  inn: '9999999999',
  kpp: '999999999',
  snils: '999-999-999 99',
  phoneMask: '+7(999)999-99-99',
  homePhoneMask: '8(999)999-99-99',
  bic: '999999999',
  corAcc: '99999999999999999999',
  checkAcc: '99999999999999999999',
  birthDateMask: '99.99.9999',
  timeMask: '99:99',
  timeDatePickerFormat: 'HH:mm',
  datePickerSchedulerFormat: 'HH:mm, d MMMM, yyyy ',
  schedulerFormatDate: 'HH:mm:ss, DD.MM.YYYY',
  distributingDateValidator: 'YYYY-MM-DD',
};

export default MASK;
