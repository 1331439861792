import React, { memo } from 'react';
import { LANG_DICTIONARY } from 'consts';
import { getDateForPicker } from 'helpers';
import PropTypes from 'prop-types';

const propTypes = {
  parentClass: PropTypes.func,
  number: PropTypes.string,
  serial: PropTypes.string,
  issuedBy: PropTypes.string,
  dateIssue: PropTypes.string,
  authCode: PropTypes.string,
  contrAgentAddress: PropTypes.string,
};

const defaultProps = {
  parentClass: () => null,
  number: '',
  serial: '',
  issuedBy: '',
  dateIssue: '',
  authCode: '',
  contrAgentAddress: '',
};

const {
  SERIAL,
  ISSUED_BY_SHORT,
  WHEN,
  SUBDIVISION_CODE_SHORT,
  PERSON_PASSPORT_RU,
  SIMBOL_NUMBER,
  CONTR_AGENT_ADDRESS,
} = LANG_DICTIONARY;

const PassportData = ({
  parentClass,
  number,
  serial,
  issuedBy,
  dateIssue,
  authCode,
  contrAgentAddress,
}) => {
  const field = parentClass('field', { big: true });
  const fixedTitle = parentClass('field-title', { 'fixed-width': true });

  return (
    <section className={parentClass('passport')}>
      <p className={parentClass('block-title')}>{PERSON_PASSPORT_RU}</p>
      <div className={parentClass('content')}>
        <div className={parentClass('client-block')}>
          <div className={parentClass('client-fields-row')}>
            <div className={field}>
              <p className={fixedTitle}>{SERIAL}</p>
              <p className={parentClass('field-value')}>{serial || '-'}</p>
            </div>
            <div className={field}>
              <p className={parentClass('field-title')}>{SIMBOL_NUMBER}</p>
              <p className={parentClass('field-value')}>{number || '-'}</p>
            </div>
          </div>
          <div className={parentClass('client-fields-row')}>
            <div className={field}>
              <p className={fixedTitle}>{ISSUED_BY_SHORT}</p>
              <p className={parentClass('field-value')}>{issuedBy || '-'}</p>
            </div>
          </div>
          <div className={parentClass('client-fields-row')}>
            <div className={field}>
              <p className={fixedTitle}>{WHEN}</p>
              <p className={parentClass('field-value')}>{dateIssue ? getDateForPicker(dateIssue) : '-'}</p>
            </div>
            <div className={field}>
              <p className={parentClass('field-title')}>{SUBDIVISION_CODE_SHORT}</p>
              <p className={parentClass('field-value')}>{authCode || '-'}</p>
            </div>
          </div>
          {contrAgentAddress && (
            <div className={parentClass('client-fields-row-registration')}>
              <div className={parentClass('client-fields-row-registration-wrapper')}>
                <p>{CONTR_AGENT_ADDRESS}</p>
                <p className={parentClass('field-value')}>{contrAgentAddress}</p>
              </div>
            </div>
          )}
        </div>
      </div>
    </section>
  );
};

PassportData.defaultProps = defaultProps;
PassportData.propTypes = propTypes;
export default memo(PassportData);
