import React from 'react';
import { block } from 'bem-cn';
import {
  DateCustomPicker,
  ErrorMessage,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { Row, Col } from 'reactstrap';
import '../../styles.scss';

const {
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  BIRTH_DATE_SHORT,
  GENDER_SHORT,
  RELATIONSHIPS,
  INSURED_SUM,
  BIRTH_PLACE,
  BENEFICIARY_DATA,
} = LANG_DICTIONARY;

const b = block('beneficiary');

const propTypes = {
  formData: PropTypes.object,
  formDataErrors: PropTypes.object,
  genders: PropTypes.arrayOf(PropTypes.object),
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  index: PropTypes.number,
  relationships: PropTypes.arrayOf(PropTypes.object),
  formDataTouched: PropTypes.object,
  handleChangeNameByForm: PropTypes.func,
  handleBlurNameByForm: PropTypes.func,
  validateExtremism: PropTypes.func,
  insuranceAmmountError: PropTypes.string,
  validateInsuredIsInBeneficiary: PropTypes.func,
  validateAddress: PropTypes.func,
};
const defaultProps = {
  formData: {},
  formDataErrors: {},
  genders: [],
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  index: 0,
  relationships: [],
  formDataTouched: {},
  handleChangeNameByForm: () => null,
  handleBlurNameByForm: () => null,
  validateExtremism: () => null,
  insuranceAmmountError: '',
  validateInsuredIsInBeneficiary: () => null,
  validateAddress: () => null,
};

const MainFormBeneficiary = ({
  formData,
  formDataErrors,
  genders,
  handleChangeByForm,
  handleTouchedByForm,
  index,
  relationships,
  formDataTouched,
  handleChangeNameByForm,
  handleBlurNameByForm,
  validateExtremism,
  insuranceAmmountError,
  validateInsuredIsInBeneficiary,
  validateAddress,
}) => {
  const beneficiaryIsInsured = validateInsuredIsInBeneficiary(formData);
  const today = new Date();

  const inputData = {
    values: formData,
    errors: formDataErrors,
    touched: formDataTouched,
    onChange: (e) => handleChangeByForm(e, '', index, formData),
    onFocus: (e) => handleTouchedByForm(e, '', index),
    prefixScroll: index,
  };

  const inputDataForName = {
    ...inputData,
    isVisableError: Boolean(beneficiaryIsInsured),
    onChange: (e) => handleChangeNameByForm(e, '', index, formData),
    onBlur: (e) => {
      handleBlurNameByForm('', index, formData, e);
      validateAddress(e, index);
    },
    prefixScroll: index,
  };

  return (
    <div className={b()}>
      <p className={b('title')}>{BENEFICIARY_DATA(index)}</p>
      <div className={b('content')}>
        <Row>
          <Col md="4" sm="12">
            <TextInput
              placeholder={LAST_NAME}
              name="lastName"
              required
              {...inputDataForName}
            />
          </Col>
          <Col md="4" sm="12">
            <TextInput
              placeholder={FIRST_NAME}
              name="firstName"
              required
              {...inputDataForName}
            />
          </Col>
          <Col md="4" sm="12">
            <TextInput
              placeholder={PATRONYMIC}
              name="middleName"
              {...inputDataForName}
            />
          </Col>
        </Row>
        <Row>
          <Col md="4" sm="12">
            <DateCustomPicker
              placeholder={BIRTH_DATE_SHORT}
              name="birthDate"
              maxDate={today}
              required
              onBlur={(value) => {
                validateExtremism(formData, index, {
                  target: {
                    value,
                    name: 'birthDate',
                  },
                });

                validateAddress({
                  target: {
                    value,
                    name: 'birthDate',
                  },
                }, index);
              }}
              isVisableError={Boolean(beneficiaryIsInsured)}
              {...inputData}
            />
          </Col>
          <Col md="3" sm="12">
            <SelectInput
              placeholder={GENDER_SHORT}
              name="gender"
              required
              options={genders}
              {...inputData}
            />
          </Col>
          <Col md="5" sm="12">
            <SelectInput
              placeholder={RELATIONSHIPS}
              name="relationships"
              required
              options={relationships}
              {...inputData}
            />
          </Col>
        </Row>
        <Row>
          <Col md="7" sm="12">
            <TextInput
              placeholder={INSURED_SUM}
              name="insuredSum"
              required
              {...inputData}
              errors={{
                ...formDataErrors,
                insuredSum: formDataErrors.insuredSum || insuranceAmmountError,
              }}
            />
          </Col>
          <Col md="5" sm="12">
            <TextInput
              placeholder={BIRTH_PLACE}
              name="birthPlace"
              {...inputData}
            />
          </Col>
        </Row>
      </div>
      <ErrorMessage message={beneficiaryIsInsured} />
    </div>
  );
};

MainFormBeneficiary.propTypes = propTypes;
MainFormBeneficiary.defaultProps = defaultProps;
export default MainFormBeneficiary;
