import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { toastr } from 'react-redux-toastr';
import { withCustomRouter } from 'HOC';
import { Button } from 'semantic-ui-react';
import { connect, useSelector } from 'react-redux';
import { checkProduct, setFundDescription } from 'redux/actions';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { sharedSelectors } from 'redux/shared/selectors';
import './styles.scss';

const defaultProps = {
  id: null,
  nameShort: null,
  description: null,
  color: null,
  buttonContent: null,
  CheckProduct: null,
  scenario: '',
  data: {
    title: '',
    description: '',
  },
  maxHeight: false,
  qualification: null,
};

const { preliminaryCalculation } = ROUTES;

const propTypes = {
  id: PropTypes.number,
  nameShort: PropTypes.string,
  description: PropTypes.string,
  color: PropTypes.string,
  buttonContent: PropTypes.bool,
  history: PropTypes.object.isRequired,
  CheckProduct: PropTypes.func,
  scenario: PropTypes.string,
  data: PropTypes.shape({
    title: PropTypes.string,
    description: PropTypes.string,
  }),
  maxHeight: PropTypes.bool,
  qualification: PropTypes.number,
};

const b = block('fund');

const FundItem = (props) => {
  const {
    id,
    nameShort,
    description,
    buttonContent,
    history,
    CheckProduct,
    color,
    scenario,
    data,
    maxHeight,
    qualification,
  } = props;

  const { CHECKOUT } = LANG_DICTIONARY;
  const historyLink = `${preliminaryCalculation}/${id}`;
  const title = nameShort || data.title;

  const query = useSelector(sharedSelectors.getQueryParams);

  const isWithoutMasterId = !query.masterId;

  const reqForCheckProduct = () => {
    CheckProduct({
      id,
      history,
      historyLink,
      toastr,
      scenario,
      qualification,
      isWithoutMasterId,
    });
  };

  return (
    <div className={b({ maxHeight })} key={id}>
      {title && <div className={b('type')} style={{ backgroundColor: color }}>{ title }</div>}
      {data.description && (<p className={b('description')}>{data.description}</p>)}
      <div dangerouslySetInnerHTML={{ __html: description }} />
      {
        !buttonContent
            && (
            <Button
              type="submit"
              className="fund__checkout"
              onClick={() => reqForCheckProduct()}
            >
              { CHECKOUT }
            </Button>
            )
      }
    </div>
  );
};

FundItem.defaultProps = defaultProps;
FundItem.propTypes = propTypes;

const mapStateToProps = ({
  authReducer: {
    qualification,
  },
  checkoutReducer: {
    fund,
  },
}) => ({ ...fund, qualification });

export default withCustomRouter(connect(
  mapStateToProps,
  {
    CheckProduct: checkProduct,
    setDescription: setFundDescription,
  },
)(FundItem));
