import React from 'react';
import { connect } from 'react-redux';
import { ExecutionData } from 'containers';
import PropTypes from 'prop-types';

const propTypes = {
  scenario: PropTypes.string.isRequired,
  stage: PropTypes.number.isRequired,
};

const NszExecutionData = ({
  scenario,
  stage,
}) => (
  <ExecutionData
    scenario={scenario}
    stage={stage}
  />
);

const mapStateToProps = ({
  nszProduct: {
    stage,
    nszProductValues: {
      scenario,
    },
  },
}) => ({
  scenario,
  stage,
});

NszExecutionData.propTypes = propTypes;

export default connect(mapStateToProps)(NszExecutionData);
