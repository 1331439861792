import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Beneficiaries } from 'containers';
import { withCustomRouter } from 'HOC';
import { connect } from 'react-redux';
import { setBeneficiaryValues } from 'redux/rootActions';
import { ROUTES } from 'consts';

const propTypes = {
  insuredIsSame: PropTypes.bool,
  productValues: PropTypes.object,
  insurerValues: PropTypes.object,
  insuredValues: PropTypes.object,
  stage: PropTypes.number,
  scenario: PropTypes.string,
  productForm: PropTypes.object.isRequired,
  saveProduct: PropTypes.func.isRequired,
  setNextStage: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  match: PropTypes.object.isRequired,
  addressCheckingPayload: PropTypes.object.isRequired,
};

const defaultProps = {
  insuredIsSame: false,
  productValues: {},
  insurerValues: {},
  insuredValues: {},
  stage: 0,
  scenario: '',
};

const IszBeneficiaries = ({
  productValues,
  insurerValues,
  insuredValues,
  insuredIsSame,
  stage,
  scenario,
  productForm,
  saveProduct,
  setNextStage,
  history,
  match: { params: { id } },
  addressCheckingPayload,
}) => {
  useEffect(() => {
    if (stage < 4) {
      history.push(`${ROUTES.insuredData}/${id}`);
    }
  });

  return (
    <Beneficiaries
      addressCheckingPayload={addressCheckingPayload}
      productValues={productValues}
      insurerValues={insurerValues}
      insuredValues={insuredValues}
      insuredIsSame={insuredIsSame}
      stage={stage}
      productId={Number(id)}
      scenario={scenario}
      productForm={productForm}
      saveProduct={saveProduct}
      executionPath={ROUTES.execution}
      setNextStage={setNextStage}
    />
  );
};

function mapStateToProps({
  checkoutReducer: {
    insurerValues,
    insuredValues,
    insuredIsSame,
    stage,
    scenario,
    productValues,
    productForm,
  },
}) {
  return {
    addressCheckingPayload: {
      amount: productValues.policySum,
    },
    productForm,
    productValues,
    insurerValues,
    insuredValues,
    insuredIsSame,
    stage,
    scenario,
  };
}

const mapDispatchToProps = {
  setNextStage: setBeneficiaryValues,
};

IszBeneficiaries.propTypes = propTypes;
IszBeneficiaries.defaultProps = defaultProps;
export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(IszBeneficiaries));
