import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import {
  RequiredLabel,
  MainBtn,
  ConstructorBasketPopup,
  DescriptionAssetsPopup,
  CustomMultiSelect,
  HelpMessage,
  BarrierFields,
} from 'components';
import {
  Icon,
  Select,
  Form,
  Radio,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import {
  LANG_DICTIONARY,
  ASSET_COLOR,
  REGEXP,
} from 'consts';
import CurrencyInput from 'react-currency-input';
import {
  CONSTRUCTOR_BARRIERS_MAX,
  CONSTRUCTOR_BARRIERS_MIN,
} from 'config';
import './styles.scss';

const b = block('creating-constructor-isz');

const propTypes = {
  setCurrency: PropTypes.func,
  getMinPay: PropTypes.func,
  assets: PropTypes.arrayOf(PropTypes.object),
  periods: PropTypes.arrayOf(PropTypes.object),
  payouts: PropTypes.arrayOf(PropTypes.object),
  handleSubmitSuccess: PropTypes.func,
  initialValues: PropTypes.object,
  handleChangeSelectPayout: PropTypes.func,
  ourErrors: PropTypes.object,
  status: PropTypes.string,
  history: PropTypes.object,
  productId: PropTypes.number,
  handleChangeSelectTerm: PropTypes.func,
  isLoadingCalculateCoupon: PropTypes.bool,
  setAssets: PropTypes.func,
  isDisableSelectCurrency: PropTypes.bool,
  setDescription: PropTypes.func,
  descriptions: PropTypes.arrayOf(PropTypes.object),
  setBarriers: PropTypes.func,
  getCountBarriers: PropTypes.func,
  saveBarrierError: PropTypes.func,
  settings: PropTypes.object,
  onChangeOptionType: PropTypes.func,
  checkBarrierOption: PropTypes.func,
};

const defaultProps = {
  productId: 0,
  handleSubmitSuccess: () => null,
  initialValues: {},
  handleChangeSelectPayout: () => null,
  ourErrors: {},
  status: '',
  history: {},
  setCurrency: () => null,
  getMinPay: () => null,
  handleChangeSelectTerm: () => null,
  assets: [],
  periods: [],
  payouts: [],
  isLoadingCalculateCoupon: false,
  setAssets: () => null,
  isDisableSelectCurrency: true,
  setDescription: () => null,
  descriptions: [],
  setBarriers: () => null,
  getCountBarriers: () => null,
  saveBarrierError: () => null,
  settings: {
    optionOptions: [],
  },
  onChangeOptionType: () => null,
  checkBarrierOption: () => null,
};

const {
  CHOOSE_PARAMETRES,
  MAXIMUM_ASSETS,
  CHOOSE_ASSETS,
  MINIMUM_PAY_REQUEST,
  KNOW_MINIMUM_PAY_REQUEST,
  CHOOSE_CURRENCY_CONTRACT,
  CHOOSE_PERIOD_CONTRACT,
  CHOOSE_BARRIERS_LEVELS,
  CHOOSE_PAYOUT_TIMES_CONTRACT,
  REQUEST_COUPON,
  BACK_WORD,
  BARRIERS_MINMAX,
  REQUIRED,
  ROUBLE,
  DOLLAR,
  DOLLAR_WORD,
  ROUBLE_WORD,
  WARNING_MESSAGE_DIFFERENT_COLORS_ASSETS,
  BASKET_TERM_HELP_MESSAGE,
  BASKET_ASSETS_HELP_MESSAGE,
  BASKET_MIN_AMOUNT_HELP_MESSAGE,
  BASKET_LEVEL_BARRIERS_HELP_MESSAGE,
  BASKET_PAYOUT_COUPON_HELP_MESSAGE,
  SELECT_OPTION_CONSTRUCTOR,
  SELECT_OPTION_TYPE_CONSTRUCTOR,
} = LANG_DICTIONARY;

const ConstructorIszCreatingForm = ({
  setCurrency,
  getMinPay,
  assets,
  periods,
  payouts,
  handleSubmitSuccess,
  initialValues,
  ourErrors,
  status,
  history,
  productId,
  handleChangeSelectTerm,
  handleChangeSelectPayout,
  isLoadingCalculateCoupon,
  setAssets,
  isDisableSelectCurrency,
  setDescription,
  descriptions,
  setBarriers,
  getCountBarriers,
  saveBarrierError,
  settings: {
    optionOptions,
  },
  onChangeOptionType,
  checkBarrierOption,
}) => (
  <>
    <DescriptionAssetsPopup
      isOpen={Boolean(descriptions.length)}
      onClose={() => setDescription([])}
      descriptions={descriptions}
    />
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmitSuccess}
      enableReinitialize
    >
      {({
        values,
        handleSubmit,
      }) => {
        let isDifferentAssetsColorsWarning = false;

        if (values.selectedAssets.length) {
          const firstSelectAssetApprove =
            assets.find((asset) => asset.value === values.selectedAssets[0]).asset;

          isDifferentAssetsColorsWarning = values.selectedAssets.some((item) => {
            const assetData = assets.find((asset) => asset.value === item) || {};

            return assetData.asset !== firstSelectAssetApprove;
          });
        }

        const getColor = (asset) => {
          switch (asset) {
            case ASSET_COLOR.green:
              return 'green';

            case ASSET_COLOR.yellow:
              return 'yellow';

            case ASSET_COLOR.red:
              return 'red';

            case ASSET_COLOR.orange:
              return 'orange';

            default:
              return 'black';
          }
        };

        const renderLabel = (label) => ({
          color: getColor(label.asset),
          content: label.text.replace(REGEXP.BRACKETS_CONTENT, ''),
        });

        const handleLabelClick = (e, { value, content }) => {
          const asset = assets.find((item) => value === item.value);

          if (asset.desc) {
            setDescription([{ desc: asset.desc, name: content }]);
          }
        };

        const option = optionOptions.find((item) => item.value === values.optionType);
        const description = option ? option.desc : '';
        const isDisabledSelectOption = !optionOptions.length || optionOptions.length === 1;
        const isErrorBarriers = Object.values(values.barrierErrors).some(Boolean);
        const errorBarriersMinMax = isErrorBarriers ?
          BARRIERS_MINMAX(CONSTRUCTOR_BARRIERS_MIN, CONSTRUCTOR_BARRIERS_MAX) : '';
        const countBarriers = getCountBarriers(values.period, values.payout);

        return (
          <Form className={b()} loading={isLoadingCalculateCoupon}>
            <ConstructorBasketPopup
              status={status}
              history={history}
              isOpen={status !== null}
              productId={productId}
            />
            <div className={b('content')}>
              <div className={b('header')}>
                <div>
                  <p>
                    {CHOOSE_PARAMETRES}
                  </p>
                </div>
                <div><p>{MAXIMUM_ASSETS}</p></div>
                <RequiredLabel />
              </div>
              <div className={b('form-field')}>
                <div className={b('form-title')}>{SELECT_OPTION_TYPE_CONSTRUCTOR}</div>
                <div className={b('form-content')}>
                  <div className={b('option')}>
                    <Select
                      placeholder={SELECT_OPTION_CONSTRUCTOR}
                      options={optionOptions}
                      value={values.optionType}
                      disabled={isDisabledSelectOption}
                      name="optionType"
                      onChange={onChangeOptionType}
                      className={b('select-option').toString()}
                    />
                  </div>
                </div>
                <HelpMessage message={description} />
              </div>
              {Boolean(values.optionType) && (
                <>
                  <Form>
                    <div className={b('form-title')}>
                      <Form.Field>
                        {CHOOSE_CURRENCY_CONTRACT}
                      </Form.Field>
                    </div>
                    <div className={b('form-content-radio')}>
                      <Form.Field>
                        <Radio
                          label={ROUBLE_WORD}
                          name="radioGroup"
                          value={ROUBLE_WORD}
                          checked={values.currency === ROUBLE}
                          onChange={() => setCurrency(values)}
                          disabled={isDisableSelectCurrency}
                        />
                      </Form.Field>
                      <Form.Field>
                        <Radio
                          label={DOLLAR_WORD}
                          name="radioGroup"
                          value={DOLLAR_WORD}
                          checked={values.currency === DOLLAR}
                          onChange={() => setCurrency(values)}
                          disabled={isDisableSelectCurrency}
                        />
                      </Form.Field>
                    </div>
                  </Form>
                  <div className={b('form-field')}>
                    <div className={b('form-title')}>{CHOOSE_PERIOD_CONTRACT}</div>
                    <div className={b('form-content')}>
                      <Select
                        value={values.period}
                        options={periods}
                        onChange={handleChangeSelectTerm(values)}
                        className={ourErrors.periodError ? b('error-select').toString() : null}
                      />
                      <Icon name="write" className="icon-write-select" />
                      {ourErrors.periodError ? <p className={b('error')}>{REQUIRED}</p> : null}
                    </div>
                    <HelpMessage message={BASKET_TERM_HELP_MESSAGE} />
                  </div>
                  <div className={b('form-field')}>
                    <div className={b('form-title')}>{CHOOSE_ASSETS}</div>
                    <div className={b('form-content')}>
                      <CustomMultiSelect
                        options={assets}
                        renderLabel={renderLabel}
                        isSearch
                        onChange={(e, { value }) => {
                          setAssets(value);
                        }}
                        onLabelClick={handleLabelClick}
                        maxItems={6}
                        value={values.selectedAssets}
                        disabled={!values.period}
                        error={ourErrors.assetsError ? REQUIRED : undefined}
                        required
                      />
                      {isDifferentAssetsColorsWarning && (
                        <div className={b('assets-different-colors-warning')}>
                          <Icon name="warning" />
                          <p className={b('assets-different-colors-warning-text')}>{WARNING_MESSAGE_DIFFERENT_COLORS_ASSETS}</p>
                        </div>
                      )}
                    </div>
                    <HelpMessage message={BASKET_ASSETS_HELP_MESSAGE} />
                  </div>
                  {checkBarrierOption(values.optionType) && (
                    <>
                      <div className={b('form-field')}>
                        <div className={b('form-title')}>{CHOOSE_PAYOUT_TIMES_CONTRACT}</div>
                        <div className={b('form-content')}>
                          <Select
                            options={payouts}
                            onChange={handleChangeSelectPayout(values)}
                            className={ourErrors.payoutError ? b('error-select').toString() : null}
                            value={values.payout}
                          />
                          <Icon name="write" className="icon-write-select" />
                          {ourErrors.payoutError ? <p className={b('error')}>{REQUIRED}</p> : null}
                        </div>
                        <HelpMessage message={BASKET_PAYOUT_COUPON_HELP_MESSAGE} />
                      </div>
                      <div className={b('form-field')}>
                        <div className={b('form-title')}>{CHOOSE_BARRIERS_LEVELS}</div>
                        <div className={b('form-content', { 'no-margin-right': true })}>
                          <BarrierFields
                            count={countBarriers}
                            values={values.barriers}
                            onChange={setBarriers}
                            minValue={CONSTRUCTOR_BARRIERS_MIN}
                            maxValue={CONSTRUCTOR_BARRIERS_MAX}
                            disabled={!values.period || !values.payout}
                            onError={saveBarrierError}
                            errors={values.barrierErrors}
                            generalError={errorBarriersMinMax}
                          />
                        </div>
                        <HelpMessage message={BASKET_LEVEL_BARRIERS_HELP_MESSAGE} />
                      </div>
                    </>
                  )}
                  <div className={b('form-field')}>
                    <div className={b('form-title')}>{MINIMUM_PAY_REQUEST}</div>
                    <MainBtn
                      text={KNOW_MINIMUM_PAY_REQUEST}
                      onClick={() => getMinPay(values)}
                      className={b('btn-get-sum').toString()}
                    />
                    <div className={b('form-content', { small: true })}>
                      <CurrencyInput
                        name="minPay"
                        value={values.minimumPay}
                        precision={0}
                        className={b('input').toString()}
                        disabled
                      />
                    </div>
                    <HelpMessage message={BASKET_MIN_AMOUNT_HELP_MESSAGE} />
                  </div>
                </>
              )}
            </div>
            <div className={b('footer')}>
              <MainBtn text={BACK_WORD} onClick={() => history.goBack()} />
              {Boolean(values.optionType) && (
                <MainBtn
                  text={REQUEST_COUPON}
                  onClick={handleSubmit}
                />
              )}
            </div>
          </Form>
        );
      }}
    </Formik>
  </>
);
ConstructorIszCreatingForm.propTypes = propTypes;
ConstructorIszCreatingForm.defaultProps = defaultProps;
export default ConstructorIszCreatingForm;
