import { replaceTemplateRoute } from 'helpers';
import mapActionsToRoutes from './mapActionsToRoutes';
import actions from './actions';

const checkAccessRoute = (route, roles) => {
  const rebuildRoute = replaceTemplateRoute(route);
  const accessAction = mapActionsToRoutes[rebuildRoute];
  if (!accessAction) return true;

  return roles[accessAction];
};

const checkAccessAction = (action, roles) => roles && Boolean(roles[action]);

export {
  actions,
  checkAccessAction,
  checkAccessRoute,
};
