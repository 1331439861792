import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';
import './styles.scss';

const b = block('confirmation-popup');

const {
  ATTENTION,
  BACK_WORD,
  YES,
} = LANG_DICTIONARY;

const propTypes = {
  open: PropTypes.bool,
  text: PropTypes.string,
  handleConfirm: PropTypes.func,
  handleCancel: PropTypes.func,
};

const defaultProps = {
  open: false,
  text: '',
  handleConfirm: () => null,
  handleCancel: () => null,
};
const Сonfirmation = ({
  text,
  handleConfirm,
  handleCancel,
  open,
}) => (
  <PopupContainer
    isOpen={open}
    close={handleCancel}
  >
    <div className={b()}>
      <p className={b('title')}>{`${ATTENTION}!`}</p>
      <p>{text}</p>
      <div className={b('controls')}>
        <div className={b('btn-wrapper')}>
          <button
            className={b('btn-cancel')}
            type="button"
            onClick={handleCancel}
          >
            {BACK_WORD}
          </button>
        </div>
        <div className={b('btn-wrapper')}>
          <button
            className={b('btn-confirm')}
            type="button"
            onClick={handleConfirm}
          >
            {YES}
          </button>
        </div>
      </div>
    </div>
  </PopupContainer>
);

Сonfirmation.propTypes = propTypes;
Сonfirmation.defaultProps = defaultProps;
export default Сonfirmation;
