import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { ContactBlock } from 'components';
import { ENUMS } from 'consts';
import '../../styles.scss';

const { BENEFICIARY_FORM_ENUM } = ENUMS;

const propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  index: PropTypes.number,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  isRequiredEmailAndMobile: PropTypes.bool,
};

const defaultProps = {
  values: {},
  touched: {},
  errors: {},
  index: 0,
  isRequiredEmailAndMobile: true,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
};
const ContactsBlockBeneficiary = ({
  values,
  touched,
  errors,
  index,
  handleChangeByForm,
  handleTouchedByForm,
  isRequiredEmailAndMobile,
}) => {
  const form = BENEFICIARY_FORM_ENUM.contacts;

  const inputProps = {
    values,
    touched,
    errors,
    onChange: (e) => handleChangeByForm(e, form, index, values),
    onFocus: (e) => handleTouchedByForm(e, form, index),
    prefixScroll: `${form}${index}`,
  };

  return (
    <div>
      <ContactBlock
        inputProps={inputProps}
        options={{
          isRequiredMobile: isRequiredEmailAndMobile,
          isRequiredEmail: isRequiredEmailAndMobile,
        }}
      />
    </div>
  );
};

ContactsBlockBeneficiary.propTypes = propTypes;
ContactsBlockBeneficiary.defaultProps = defaultProps;
export default memo(ContactsBlockBeneficiary);
