import testDataGenerator from 'helpers/getTestData';
import { TEST_DATA_ENUMS } from 'consts';
import rebuildBeneficiaries from './rebuildBeneficiaries';

const generateInsurer = (scenario, storeData) => {
  const {
    fillInsurerTestDataKsz,
    hideInnKsz,
    hideSnilsKsz,
  } = storeData;
  const { insurer } = testDataGenerator.kszTestDataGenerate(scenario);

  const rebuildInsurer = {
    ...insurer,
    ...(hideInnKsz && { inn: '' }),
    ...(hideSnilsKsz && { snils: '' }),
  };
  fillInsurerTestDataKsz(rebuildInsurer);
};

const generateInsured = (scenario, storeData) => {
  const {
    fillInsuredDataKsz,
    kszInsurerIsInsured,
    hideInnKsz,
    hideSnilsKsz,
  } = storeData;
  const { insured } = testDataGenerator.kszTestDataGenerate(scenario);

  const rebuildInsured = {
    ...insured,
    ...(hideInnKsz && { inn: '' }),
    ...(hideSnilsKsz && { snils: '' }),
  };
  fillInsuredDataKsz(rebuildInsured, kszInsurerIsInsured);
};

const generateBeneficiary = (scenario, storeData) => {
  const {
    fillBeneficiaryTestDataKsz,
    beneficiaryByLawKsz,
    numberOfBeneficiaries,
  } = storeData;
  const { beneficiaries } = testDataGenerator.kszTestDataGenerate(scenario);

  const finalBeneficiaries = rebuildBeneficiaries(beneficiaries, numberOfBeneficiaries);
  fillBeneficiaryTestDataKsz(finalBeneficiaries, beneficiaryByLawKsz);
};

export default {
  [TEST_DATA_ENUMS.insurer]: (scenario, storeData) => generateInsurer(scenario, storeData),
  [TEST_DATA_ENUMS.insured]: (scenario, storeData) => generateInsured(scenario, storeData),
  [TEST_DATA_ENUMS.beneficiary]: (scenario, storeData) => generateBeneficiary(scenario, storeData),
};
