import { USER_ROLES } from 'consts';

const {
  SUPERADMIN,
  SUPERVISOR,
  CURATOR,
  PARTNER_ADMIN,
  SK_ADMIN,
  DOCUMENT_CONTROLLER,
  USER,
  USER_CURRENT_BRANCH,
  USER_ALL_BRANCH,
} = USER_ROLES;

const accessToDocumentCheckStatusButtonRoles = {
  [SUPERADMIN]: SUPERADMIN,
  [SUPERVISOR]: SUPERVISOR,
  [CURATOR]: CURATOR,
  [PARTNER_ADMIN]: PARTNER_ADMIN,
  [SK_ADMIN]: SK_ADMIN,
  [DOCUMENT_CONTROLLER]: DOCUMENT_CONTROLLER,
};

const accessToFormsEventsButtonRoles = {
  [SUPERADMIN]: SUPERADMIN,
  [SUPERVISOR]: SUPERVISOR,
  [CURATOR]: CURATOR,
  [PARTNER_ADMIN]: PARTNER_ADMIN,
  [SK_ADMIN]: SK_ADMIN,
  [DOCUMENT_CONTROLLER]: DOCUMENT_CONTROLLER,
  [USER]: USER,
  [USER_CURRENT_BRANCH]: USER_CURRENT_BRANCH,
  [USER_ALL_BRANCH]: USER_ALL_BRANCH,
};

export const checkAccessToDocumentCheckStatusButton = (roles) => roles.length &&
  roles.some((role) => accessToDocumentCheckStatusButtonRoles[role]);

export const checkAccessToEventsButton = (roles) => roles.length &&
    roles.some((role) => accessToFormsEventsButtonRoles[role]);
