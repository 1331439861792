export default {
  beneficiary1: {
    formData: {
      firstName: 'Отец',
      lastName: 'Выгодоприобретатель',
      middleName: 'Выгодоприобретателя',
      birthDate: new Date('1988-05-01T20:00:00.000Z'),
      gender: 1,
      relationships: 3,
      insuredSum: 10,
      birthPlace: 'Таганрог',
    },
    formDataDocuments: {
      documentType: 1,
      serial: '0621',
      passportNumber: '321321',
      issuedBy: 'МВД ПО РЕСП. БАШКОРТОСТАН',
      when: new Date('2010-05-01T20:00:00.000Z'),
      validUntil: null,
      kp: '020-060',
      inn: '',
      snils: '',
      issuedByAccepted: true,
    },
    formDataAddress: {
      addressFias: {
        data: {
          postal_code: '347904',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '305576b5-7ad6-44b6-b6c9-a94c4822cecf',
          street_kladr_id: '61000011000043900',
          street_with_type: 'ул Ростовская',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Ростовская',
          house_fias_id: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
          house_kladr_id: '6100001100004390001',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
          fias_code: '61000011000000004390001',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100004390001',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.22496',
          geo_lon: '38.9218',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '0',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347904, Ростовская обл, г Таганрог, ул Ростовская, д 1',
        value: 'Ростовская обл, г Таганрог, ул Ростовская, д 1',
      },
      addressFiasLiving: {
        data: {
          postal_code: '347939',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '936d8bbd-3c05-4195-9589-f95a26404f3a',
          street_kladr_id: '61000011000025100',
          street_with_type: 'ул Лизы Волошиной',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Лизы Волошиной',
          house_fias_id: '71deb44b-654b-4416-98a1-334071624653',
          house_kladr_id: '6100001100002510006',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: '71deb44b-654b-4416-98a1-334071624653',
          fias_code: '61000011000000002510006',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100002510006',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.221044',
          geo_lon: '38.871782',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '2',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347939, Ростовская обл, г Таганрог, ул Лизы Волошиной, д 1',
        value: 'Ростовская обл, г Таганрог, ул Лизы Волошиной, д 1',
      },
      livingAddressIsSame: false,
      apartment: '2',
      apartmentLiving: '3',
      area: '',
      areaLiving: '',
      city: 'г Таганрог',
      cityLiving: 'г Таганрог',
      country: 'Россия',
      countryLiving: 'Россия',
      house: 'д 1',
      houseLiving: 'д 1',
      houseNumber: '1',
      houseNumberLiving: '1',
      index: '347904',
      indexLivin: '',
      republic: 'обл Ростовская',
      republicLiving: 'обл Ростовская',
      street: 'ул Ростовская',
      streetLiving: 'ул Лизы Волошиной',
      fiasId: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
      kladId: '6100001100004390001',
      indexLiving: '347939',
      fiasIdLiving: '71deb44b-654b-4416-98a1-334071624653',
      kladIdLiving: '6100001100002510006',
    },
    formDataContacts: {
      contactPhone: '891235422432',
      email: 'testBenificary@mail.ru',
      homePhone: '8(123)132-13-12',
      mobilePhone: '+7(312)312-31-32',
      workPhone: '+7(312)321-31-23',
    },
    formDataErrors: {},
    formDataDocumentsErrors: {},
    formDataContactsErrors: {},
    formDataAddressErrors: {},
    formDataTouched: {
      lastName: true,
      firstName: true,
      middleName: true,
      undefined: true,
      insuredSum: true,
      birthPlace: true,
    },
    formDataDocumentsTouched: {
      undefined: true,
      serial: true,
      passportNumber: true,
    },
    formDataContactsTouched: {
      mobilePhone: true,
      email: true,
      homePhone: true,
      workPhone: true,
      contactPhone: true,
    },
    formDataAddressTouched: {
      addressFias: true,
      addressFiasLiving: true,
      livingAddressIsSame: true,
      apartment: true,
      apartmentLiving: true,
      area: true,
      areaLiving: true,
      city: true,
      cityLiving: true,
      country: true,
      countryLiving: true,
      house: true,
      houseLiving: true,
      houseNumber: true,
      houseNumberLiving: true,
      index: true,
      indexLivin: true,
      republic: true,
      republicLiving: true,
      street: true,
      streetLiving: true,
      fiasId: true,
      kladId: true,
      indexLiving: true,
      fiasIdLiving: true,
      kladIdLiving: true,
    },
    sharedCountErrors: 0,
    sharedCountErrorsDocuments: 0,
    sharedCountErrorsContacts: 0,
    sharedCountErrorsAddress: 0,
    livingAddressIsSame: true,
    contacts: true,
    address: true,
    documents: true,
    active: 'Documents',
  },
  beneficiary2: {
    formData: {
      firstName: 'Андрей',
      lastName: 'Васильев',
      middleName: 'Игоревич',
      birthDate: new Date('1999-05-12T20:00:00.000Z'),
      gender: 1,
      relationships: 9,
      insuredSum: 10,
      birthPlace: 'Таганрог',
    },
    formDataDocuments: {
      documentType: 1,
      serial: '2132',
      passportNumber: '321312',
      issuedBy: 'ТП № 2 ОУФМС РОССИИ ПО РЕСП. БУРЯТИЯ В ОКТЯБРЬСКОМ РАЙОНЕ Г. УЛАН-УДЭ',
      when: new Date('2020-05-01T21:00:00.000Z'),
      validUntil: null,
      kp: '030-006',
      inn: '',
      snils: '',
      issuedByAccepted: true,
    },
    formDataAddress: {
      addressFias: {
        data: {
          postal_code: '347904',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '305576b5-7ad6-44b6-b6c9-a94c4822cecf',
          street_kladr_id: '61000011000043900',
          street_with_type: 'ул Ростовская',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Ростовская',
          house_fias_id: 'a2ed9e7a-fcb6-4598-8e49-3cd2e40382b0',
          house_kladr_id: '6100001100004390010',
          house_type: 'д',
          house_type_full: 'дом',
          house: '10',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: 'a2ed9e7a-fcb6-4598-8e49-3cd2e40382b0',
          fias_code: '61000011000000004390010',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100004390010',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.225309',
          geo_lon: '38.920929',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '0',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347904, Ростовская обл, г Таганрог, ул Ростовская, д 10',
        value: 'Ростовская обл, г Таганрог, ул Ростовская, д 10',
      },
      addressFiasLiving: {
        data: {
          postal_code: '347905',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: 'bb6f7463-8357-4a3b-be11-e0b4adf3c3b2',
          street_kladr_id: '61000011000004900',
          street_with_type: '1-й Артиллерийский пер',
          street_type: 'пер',
          street_type_full: 'переулок',
          street: '1-й Артиллерийский',
          house_fias_id: 'dd8c3394-004c-46fe-866d-17cec044f639',
          house_kladr_id: '6100001100000490020',
          house_type: 'д',
          house_type_full: 'дом',
          house: '20',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: 'dd8c3394-004c-46fe-866d-17cec044f639',
          fias_code: '61000011000000000490020',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100000490020',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.236562',
          geo_lon: '38.912611',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '0',
          qc_complete: null,
          qc_house: null,
          history_values: [
            'пер Артиллерийский 1-й',
          ],
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347905, Ростовская обл, г Таганрог, 1-й Артиллерийский пер, д 20',
        value: 'Ростовская обл, г Таганрог, 1-й Артиллерийский пер, д 20',
      },
      livingAddressIsSame: false,
      apartment: '5',
      apartmentLiving: '5',
      area: '',
      areaLiving: '',
      city: 'г Таганрог',
      cityLiving: 'г Таганрог',
      country: 'Россия',
      countryLiving: 'Россия',
      house: 'д 10',
      houseLiving: 'д 20',
      houseNumber: '1',
      houseNumberLiving: '1',
      index: '347904',
      indexLivin: '',
      republic: 'обл Ростовская',
      republicLiving: 'обл Ростовская',
      street: 'ул Ростовская',
      streetLiving: '1-й Артиллерийский пер',
      fiasId: 'a2ed9e7a-fcb6-4598-8e49-3cd2e40382b0',
      kladId: '6100001100004390010',
      indexLiving: '347905',
      fiasIdLiving: 'dd8c3394-004c-46fe-866d-17cec044f639',
      kladIdLiving: '6100001100000490020',
    },
    formDataContacts: {
      contactPhone: '321312',
      email: 'test@mail.ru',
      homePhone: '8(133)123-12-31',
      mobilePhone: '+7(321)313-21-31',
      workPhone: '+7(321)312-31-23',
    },
    formDataErrors: {},
    formDataDocumentsErrors: {},
    formDataContactsErrors: {},
    formDataAddressErrors: {},
    formDataTouched: {
      lastName: true,
      firstName: true,
      middleName: true,
      undefined: true,
      insuredSum: true,
      birthPlace: true,
    },
    formDataDocumentsTouched: {
      serial: true,
      passportNumber: true,
    },
    formDataContactsTouched: {
      mobilePhone: true,
      homePhone: true,
      workPhone: true,
      contactPhone: true,
      email: true,
    },
    formDataAddressTouched: {
      addressFias: true,
      addressFiasLiving: true,
      livingAddressIsSame: true,
      apartment: true,
      apartmentLiving: true,
      area: true,
      areaLiving: true,
      city: true,
      cityLiving: true,
      country: true,
      countryLiving: true,
      house: true,
      houseLiving: true,
      houseNumber: true,
      houseNumberLiving: true,
      index: true,
      indexLivin: true,
      republic: true,
      republicLiving: true,
      street: true,
      streetLiving: true,
      fiasId: true,
      kladId: true,
      indexLiving: true,
      fiasIdLiving: true,
      kladIdLiving: true,
    },
    sharedCountErrors: 0,
    sharedCountErrorsDocuments: 0,
    sharedCountErrorsContacts: 0,
    sharedCountErrorsAddress: 0,
    livingAddressIsSame: true,
    contacts: true,
    address: true,
    documents: true,
    active: 'Documents',
  },
  beneficiary3: {
    formData: {
      firstName: 'Лариса',
      lastName: 'Данильченко',
      middleName: 'Андреевна',
      birthDate: new Date('1980-05-01T21:00:00.000Z'),
      gender: 2,
      relationships: 10,
      insuredSum: 10,
      birthPlace: 'Таганрог',
    },
    formDataDocuments: {
      documentType: 1,
      serial: '3123',
      passportNumber: '321312',
      issuedBy: 'ОФМС РОССИИ ПО РЕСП. АДЫГЕЯ',
      when: new Date('2010-02-01T21:00:00.000Z'),
      validUntil: null,
      kp: '010-101',
      inn: '',
      snils: '',
      issuedByAccepted: true,
    },
    formDataAddress: {
      addressFias: {
        data: {
          postal_code: '347930',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: 'e02d89f8-c6c7-4d5d-83bc-fa091f448349',
          street_kladr_id: '61000011000008400',
          street_with_type: 'ул Больничная',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Больничная',
          house_fias_id: '07718e47-1ead-4507-a931-eeaa9713e1cd',
          house_kladr_id: '6100001100000840014',
          house_type: 'д',
          house_type_full: 'дом',
          house: '11',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: '07718e47-1ead-4507-a931-eeaa9713e1cd',
          fias_code: '61000011000000000840014',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100000840014',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.229073',
          geo_lon: '38.888033',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '1',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347930, Ростовская обл, г Таганрог, ул Больничная, д 11',
        value: 'Ростовская обл, г Таганрог, ул Больничная, д 11',
      },
      addressFiasLiving: {
        data: {
          postal_code: '347927',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '7bf95702-4e07-4e89-be84-16eb603e5793',
          street_kladr_id: '61000011000060600',
          street_with_type: 'ул Марии Питериной',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Марии Питериной',
          house_fias_id: '30a14b20-6dfc-4aa1-85bf-abd7f4a3155b',
          house_kladr_id: '6100001100006060005',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: '30a14b20-6dfc-4aa1-85bf-abd7f4a3155b',
          fias_code: '61000011000000006060005',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100006060005',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.221301',
          geo_lon: '38.872734',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '2',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347927, Ростовская обл, г Таганрог, ул Марии Питериной, д 1',
        value: 'Ростовская обл, г Таганрог, ул Марии Питериной, д 1',
      },
      livingAddressIsSame: false,
      apartment: '5',
      apartmentLiving: '2',
      area: '',
      areaLiving: '',
      city: 'г Таганрог',
      cityLiving: 'г Таганрог',
      country: 'Россия',
      countryLiving: 'Россия',
      house: 'д 11',
      houseLiving: 'д 1',
      houseNumber: '1',
      houseNumberLiving: '1',
      index: '347930',
      indexLivin: '',
      republic: 'обл Ростовская',
      republicLiving: 'обл Ростовская',
      street: 'ул Больничная',
      streetLiving: 'ул Марии Питериной',
      fiasId: '07718e47-1ead-4507-a931-eeaa9713e1cd',
      kladId: '6100001100000840014',
      indexLiving: '347927',
      fiasIdLiving: '30a14b20-6dfc-4aa1-85bf-abd7f4a3155b',
      kladIdLiving: '6100001100006060005',
    },
    formDataContacts: {
      contactPhone: '321312',
      email: 'testBeneficiaryLarisa@mail.ru',
      homePhone: '8(321)313-23-12',
      mobilePhone: '+7(321)312-31-32',
      workPhone: '+7(321)313-21-32',
    },
    formDataErrors: {},
    formDataDocumentsErrors: {},
    formDataContactsErrors: {},
    formDataAddressErrors: {},
    formDataTouched: {
      insuredSum: true,
      lastName: true,
      firstName: true,
      middleName: true,
      undefined: true,
      birthPlace: true,
    },
    formDataDocumentsTouched: {
      serial: true,
      passportNumber: true,
    },
    formDataContactsTouched: {
      mobilePhone: true,
      homePhone: true,
      workPhone: true,
      contactPhone: true,
      email: true,
    },
    formDataAddressTouched: {
      addressFias: true,
      addressFiasLiving: true,
      livingAddressIsSame: true,
      apartment: true,
      apartmentLiving: true,
      area: true,
      areaLiving: true,
      city: true,
      cityLiving: true,
      country: true,
      countryLiving: true,
      house: true,
      houseLiving: true,
      houseNumber: true,
      houseNumberLiving: true,
      index: true,
      indexLivin: true,
      republic: true,
      republicLiving: true,
      street: true,
      streetLiving: true,
      fiasId: true,
      kladId: true,
      indexLiving: true,
      fiasIdLiving: true,
      kladIdLiving: true,
    },
    sharedCountErrors: 0,
    sharedCountErrorsDocuments: 0,
    sharedCountErrorsContacts: 0,
    sharedCountErrorsAddress: 0,
    livingAddressIsSame: true,
    contacts: true,
    address: true,
    documents: true,
    active: 'Documents',
  },
  beneficiary4: {
    formData: {
      firstName: 'Тест',
      lastName: 'ВыгодныйТест',
      middleName: 'Тестеевич',
      birthDate: new Date('1988-05-01T20:00:00.000Z'),
      gender: 1,
      relationships: 9,
      insuredSum: 70,
      birthPlace: 'Таганрог',
    },
    formDataDocuments: {
      documentType: 1,
      serial: '0622',
      passportNumber: '321322',
      issuedBy: 'МВД ПО РЕСП. БАШКОРТОСТАН',
      when: new Date('2010-05-01T20:00:00.000Z'),
      validUntil: null,
      kp: '020-060',
      inn: '',
      snils: '',
      issuedByAccepted: true,
    },
    formDataAddress: {
      addressFias: {
        data: {
          postal_code: '347904',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '305576b5-7ad6-44b6-b6c9-a94c4822cecf',
          street_kladr_id: '61000011000043900',
          street_with_type: 'ул Ростовская',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Ростовская',
          house_fias_id: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
          house_kladr_id: '6100001100004390001',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
          fias_code: '61000011000000004390001',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100004390001',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.22496',
          geo_lon: '38.9218',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '0',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347904, Ростовская обл, г Таганрог, ул Ростовская, д 1',
        value: 'Ростовская обл, г Таганрог, ул Ростовская, д 1',
      },
      addressFiasLiving: {
        data: {
          postal_code: '347939',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '936d8bbd-3c05-4195-9589-f95a26404f3a',
          street_kladr_id: '61000011000025100',
          street_with_type: 'ул Лизы Волошиной',
          street_type: 'ул',
          street_type_full: 'улица',
          street: 'Лизы Волошиной',
          house_fias_id: '71deb44b-654b-4416-98a1-334071624653',
          house_kladr_id: '6100001100002510006',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: '71deb44b-654b-4416-98a1-334071624653',
          fias_code: '61000011000000002510006',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100002510006',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.221044',
          geo_lon: '38.871782',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '2',
          qc_complete: null,
          qc_house: null,
          history_values: null,
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347939, Ростовская обл, г Таганрог, ул Лизы Волошиной, д 1',
        value: 'Ростовская обл, г Таганрог, ул Лизы Волошиной, д 1',
      },
      livingAddressIsSame: false,
      apartment: '2',
      apartmentLiving: '3',
      area: '',
      areaLiving: '',
      city: 'г Таганрог',
      cityLiving: 'г Таганрог',
      country: 'Россия',
      countryLiving: 'Россия',
      house: 'д 1',
      houseLiving: 'д 1',
      houseNumber: '1',
      houseNumberLiving: '1',
      index: '347904',
      indexLivin: '',
      republic: 'обл Ростовская',
      republicLiving: 'обл Ростовская',
      street: 'ул Ростовская',
      streetLiving: 'ул Лизы Волошиной',
      fiasId: 'e02c2133-516f-49b7-8aac-857fc9a5810e',
      kladId: '6100001100004390001',
      indexLiving: '347939',
      fiasIdLiving: '71deb44b-654b-4416-98a1-334071624653',
      kladIdLiving: '6100001100002510006',
    },
    formDataContacts: {
      contactPhone: '891235422432',
      email: 'testBenificary@mail.ru',
      homePhone: '8(123)132-13-12',
      mobilePhone: '+7(312)312-31-32',
      workPhone: '+7(312)321-31-23',
    },
    formDataErrors: {},
    formDataDocumentsErrors: {},
    formDataContactsErrors: {},
    formDataAddressErrors: {},
    formDataTouched: {
      lastName: true,
      firstName: true,
      middleName: true,
      undefined: true,
      insuredSum: true,
      birthPlace: true,
    },
    formDataDocumentsTouched: {
      undefined: true,
      serial: true,
      passportNumber: true,
    },
    formDataContactsTouched: {
      mobilePhone: true,
      email: true,
      homePhone: true,
      workPhone: true,
      contactPhone: true,
    },
    formDataAddressTouched: {
      addressFias: true,
      addressFiasLiving: true,
      livingAddressIsSame: true,
      apartment: true,
      apartmentLiving: true,
      area: true,
      areaLiving: true,
      city: true,
      cityLiving: true,
      country: true,
      countryLiving: true,
      house: true,
      houseLiving: true,
      houseNumber: true,
      houseNumberLiving: true,
      index: true,
      indexLivin: true,
      republic: true,
      republicLiving: true,
      street: true,
      streetLiving: true,
      fiasId: true,
      kladId: true,
      indexLiving: true,
      fiasIdLiving: true,
      kladIdLiving: true,
    },
    sharedCountErrors: 0,
    sharedCountErrorsDocuments: 0,
    sharedCountErrorsContacts: 0,
    sharedCountErrorsAddress: 0,
    livingAddressIsSame: true,
    contacts: true,
    address: true,
    documents: true,
    active: 'Documents',
  },
  isBeneficiaries: false,
  countBeneficiaries: 4,
};
