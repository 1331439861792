import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const propTypes = {
  onClick: PropTypes.func,
  text: PropTypes.string.isRequired,
  className: PropTypes.string,
  disabled: PropTypes.bool,
};

const defaultProps = {
  onClick: () => null,
  className: '',
  disabled: false,
};

const b = block('create-entity-btn');
const db = block('create-entity-btn-disabled');

const CreateEntityBtn = ({
  onClick,
  text,
  className,
  disabled,
}) => (
  <button
    type="button"
    className={disabled ? `${db()} ${className}` : `${b()} ${className}`}
    onClick={onClick}
    disabled={disabled}
  >
    <div className={b('icon-add')}><Icon name="add" /></div>
    <p>{text}</p>
  </button>
);

CreateEntityBtn.defaultProps = defaultProps;
CreateEntityBtn.propTypes = propTypes;

export default CreateEntityBtn;
