export default (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 3,
      headers: [
        { text: [headers[0]] },
      ],
      data: [
        {
          text: [data[0]],
        },
      ],
    },
    {
      md: 3,
      headers: [
        { text: [headers[1]] },
      ],
      data: [
        {
          text: [data[1]],
        },
      ],
    },
  ],
});
