import {
  DOWNLOAD_TEMPLATE_REQUEST,
  UPLOAD_IMPORT_FILE_REQUEST,
  RESET_CURRENT_ERROR_IMPORT,
  RESET_ALL_ERROR_IMPORT,
  GET_INFO_SCHEDULE_TASK_REQUEST,
  CREATE_SCHEDULE_TASK_REQUEST,
  CANCEL_SCHEDULE_TASK_REQUEST,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST,
  SET_INIT_STATE_IMPORT,
  GET_IMPORT_VERSION,
  UPLOAD_PODFT_IMPORT_FILE,
} from '../types';

const getActiveTaskInfo = (type) => ({
  type: GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST,
  payload: type,
});

const downloadTemplate = (typeTemp) => ({
  type: DOWNLOAD_TEMPLATE_REQUEST,
  payload: typeTemp,
});

const uploadFileImport = ({
  file,
  type,
  clearAction,
  path,
  resData,
}) => ({
  type: UPLOAD_IMPORT_FILE_REQUEST,
  payload: {
    file,
    type,
    clearAction,
    path,
    resData,
  },
});

const resetCurrentError = (payload) => ({
  type: RESET_CURRENT_ERROR_IMPORT,
  payload,
});

const resetAllError = () => ({
  type: RESET_ALL_ERROR_IMPORT,
});

const getInfoScheduleTask = (setIsNetwork, setPath) => ({
  type: GET_INFO_SCHEDULE_TASK_REQUEST,
  setIsNetwork,
  setPath,
});

const createScheduleTask = ({
  path,
  time,
  interval,
  action,
  isNetwork,
  cronTypeId,
  priorityId,
  taskType,
}) => ({
  type: CREATE_SCHEDULE_TASK_REQUEST,
  payload: {
    path,
    time,
    interval,
    action,
    isNetwork,
    cronTypeId,
    taskType,
    priorityId,
  },
});

const cancelScheduleTask = (taskType, action) => ({
  type: CANCEL_SCHEDULE_TASK_REQUEST,
  payload: { taskType, action },
});

const setInitStateImport = () => ({
  type: SET_INIT_STATE_IMPORT,
});

export const getPodftVersion = (uploadBlocksPodft) => ({
  type: GET_IMPORT_VERSION.request,
  payload: { uploadBlocksPodft },
});

export const uploadPodftFileImport = ({
  file,
  type,
  clearAction,
}) => ({
  type: UPLOAD_PODFT_IMPORT_FILE.request,
  payload: {
    file,
    type,
    clearAction,
  },
});

export {
  setInitStateImport,
  getActiveTaskInfo,
  cancelScheduleTask,
  createScheduleTask,
  getInfoScheduleTask,
  resetAllError,
  resetCurrentError,
  downloadTemplate,
  uploadFileImport,
};
