import React, { useCallback, useEffect, useState } from 'react';
import { v4 as uuidV4 } from 'uuid';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { useActions } from 'hooks/useActions';
import { ConstructorBasketPopup, DescriptionAssetsPopup } from 'components';
import { withCustomRouter } from 'HOC';
import { useEffectRedirect } from 'hooks/useEffectRedirect';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import {
  selectProductTypeByCode,
  selectProductTypeCode,
  selectProductTypeOptions,
  selectStrategyUuids,
  selectCalculatingStatus,
  selectIsLoadingCreatingForm,
  getStrategies,
  selectGuaranteedPayouts,
} from 'redux/basketConstructor/selectors';
import { getQualificationFormSelector } from 'redux/rootSelectors';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { Collapse } from 'reactstrap';
import { LANG_DICTIONARY } from '../../../../consts';
import { Strategy } from './components/Strategy';
import StrategyControl from './components/StrategyControl';
import ProductType from './components/ProductType';
import { CONSTRUCTOR_URLS } from '../_constants';
import './styles.scss';

const b = block('basket-creating-form');

const { DOWNLOADING } = LANG_DICTIONARY;

const defaultProps = {
  calculatedSelected: {},
  regularSelected: {},
  readySelected: {},
};

const propTypes = {
  history: PropTypes.object.isRequired,
  calculatedSelected: PropTypes.object,
  regularSelected: PropTypes.object,
  readySelected: PropTypes.object,
};

const BasketCreatingForm = (props) => {
  const [isDisabledCalculate, setIsDisabledCalculate] = useState(false);
  const {
    history,
    calculatedSelected,
    readySelected,
    regularSelected,
  } = props;

  const {
    calculateCoupon,
    addNewStrategy,
    setProductTypeFormCreating,
    getMinInsuranceSumFormCreating,
    savePartnersInsProduct,
    setInitCreatingForm,
    getProductTypeFormCreating,
    setInitState,
    closeMinInsuranceSumTableFormCreating,
    clearSortAndFiltering,
    resetCalculatedStatusCoupon,
  } = useActions(basketConstructorActions);
  const { id: partnersInsProductId } = useParams();

  useEffectRedirect(() => {
    setInitState();
  }, CONSTRUCTOR_URLS);

  useEffect(() => {
    getProductTypeFormCreating(partnersInsProductId);
  }, [partnersInsProductId]);

  useEffect(() => {
    savePartnersInsProduct(partnersInsProductId);

    return setInitCreatingForm;
  }, [partnersInsProductId]);

  const productTypeCode = useSelector(selectProductTypeCode);
  const productTypeOptions = useSelector(selectProductTypeOptions);
  const productType = useSelector((state) => selectProductTypeByCode(state, productTypeCode));
  const strategyUuids = useSelector(selectStrategyUuids);
  const calculatingStatus = useSelector(selectCalculatingStatus);
  const isLoading = useSelector(selectIsLoadingCreatingForm);
  const strategies = useSelector(getStrategies);
  const qualification = useSelector(getQualificationFormSelector);
  const guaranteedPayouts = useSelector(selectGuaranteedPayouts);

  const checkGuaranteedPayouts = guaranteedPayouts.filter(
    (item) => item.value && item.paymentTerm,
  );

  const isDisableCalculateByPayouts = () => {
    if (checkGuaranteedPayouts?.length === guaranteedPayouts?.length) return true;

    return false;
  };

  const isDisabledCalculateByClass = strategyUuids.find(
    (uuid) => strategies[uuid]?.values.isMultipleAssetClass,
  );

  const handleChangeProductType = useCallback((e, { value }) => {
    setProductTypeFormCreating(partnersInsProductId, value);
  }, [partnersInsProductId]);

  const handleClickAddNewStrategy = useCallback(() => {
    const uuid = uuidV4();
    addNewStrategy(uuid);
  }, []);

  const getMinInsuranceSum = () => {
    getMinInsuranceSumFormCreating({
      productId: partnersInsProductId,
      qualification,
    });
  };

  const goBackWithoutSorting = () => {
    clearSortAndFiltering();
    history.goBack();
  };

  const isCopyStrategy = Boolean(
    productType &&
    productType.maxStrategiesAmount > strategyUuids.length,
  );

  const onClickCreateBasketAgain = () => {
    resetCalculatedStatusCoupon();
  };

  return (
    <section className={b().toString()}>
      <DescriptionAssetsPopup />
      <Segment>
        <Dimmer active={isLoading} inverted>
          <Loader>{DOWNLOADING}</Loader>
        </Dimmer>
        <ConstructorBasketPopup
          status={calculatingStatus}
          history={history}
          isOpen={Boolean(calculatingStatus)}
          productId={partnersInsProductId}
          onClickCreateBasketAgain={onClickCreateBasketAgain}
        />
        <ProductType
          productTypeOptions={productTypeOptions}
          productTypeCode={productTypeCode}
          onChange={handleChangeProductType}
          helpMessage={productType && productType.description}
        />
        <Collapse isOpen={Boolean(productTypeCode)}>
          <>
            {strategyUuids.map((uuid, index) => (
              <>
                <Strategy
                  isMain={index === 0}
                  isCopyStrategy={isCopyStrategy}
                  index={index}
                  uuid={uuid}
                  key={uuid}
                  calculatedSelected={calculatedSelected}
                  readySelected={readySelected}
                  regularSelected={regularSelected}
                  setIsDisabledCalculate={setIsDisabledCalculate}
                />
              </>
            ))}
          </>
          <StrategyControl
            onClickAddStrategy={handleClickAddNewStrategy}
            isShowAddStrategy={isCopyStrategy}
            onClickCalculate={calculateCoupon}
            onClickGetMinInsuranceSum={getMinInsuranceSum}
            onClickBack={() => goBackWithoutSorting()}
            closeMinInsuranceSumTable={closeMinInsuranceSumTableFormCreating}
            isDisabledCalculate={
              isDisabledCalculate ||
              isDisabledCalculateByClass ||
              !isDisableCalculateByPayouts()
            }
          />
        </Collapse>
      </Segment>
    </section>
  );
};

BasketCreatingForm.defaultProps = defaultProps;

BasketCreatingForm.propTypes = propTypes;

export default withCustomRouter(BasketCreatingForm);
