import React from 'react';
import block from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const defaultProps = {
  tipText: null,
};

const propTypes = {
  tipText: PropTypes.string,
};

const b = block('hint');

const Hint = ({ tipText }) => (
  <div className={b()}>
    <div className={b('helpersWrap')}>
      { tipText && tipText }
    </div>
  </div>
);

Hint.defaultProps = defaultProps;
Hint.propTypes = propTypes;

export default Hint;
