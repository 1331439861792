import {
  Button,
  Icon,
  Popup,
  Loader,
} from 'semantic-ui-react';
import React from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import { selectIsLoadingCreatingForm } from 'redux/basketConstructor/selectors';

const {
  REFRESH_TEXT,
  FIXED_INCOME_PERC_WARNING,
} = LANG_DICTIONARY;

const propTypes = {
  onRefresh: PropTypes.func,
  basket: PropTypes.object,
  child: PropTypes.array,
  selectedId: PropTypes.number,
};

const defaultProps = {
  onRefresh: () => null,
  basket: {},
  child: [],
  selectedId: 0,
};

const RefreshButton = (props) => {
  const {
    onRefresh,
    basket,
    child,
    selectedId,
  } = props;

  const isUpdateLoading = useSelector(selectIsLoadingCreatingForm);

  return (
    <>
      <div className="refresh" style={{ whiteSpace: 'nowrap' }}>
        {
          isUpdateLoading ?
            <Loader inline active size="tiny" />
            : (
              <Button
                type="button"
                className="update"
                onClick={() => onRefresh(basket, child)}
                disabled={basket.id !== selectedId}
              >
                <div style={{ fontSize: '11px' }}>{REFRESH_TEXT}</div>
              </Button>
            )
          }
      </div>
      <Popup
        content={<p>{FIXED_INCOME_PERC_WARNING(basket.fixedIncomePerc)}</p>}
        trigger={<Icon className="info" name="info circle" size="large" />}
        position="top left"
        on="click"
      />
    </>
  );
};

RefreshButton.defaultProps = defaultProps;
RefreshButton.propTypes = propTypes;
export default RefreshButton;
