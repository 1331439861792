const removeUnnecessary = (dataItem) => {
  if (!dataItem) {
    return false;
  }

  if (typeof dataItem === 'object') {
    if (Array.isArray(dataItem)) {
      if (!dataItem.length) {
        return false;
      }

      return true;
    }

    const hasData = Boolean(Object.keys(dataItem).length);

    if (!hasData) {
      return false;
    }

    return true;
  }

  return false;
};

const combineRows = (data) => {
  const newData = [].concat(data);
  for (let i = 0; i < newData.length; i += 1) {
    if (newData[i].row) {
      if (i % 2 === 0) {
        const combine = newData.splice(i + 1, newData[i].row);
        newData.splice(i + 1, 0, combine);
      } else {
        const combine = newData.splice(i + 1, newData[i].row - 1);
        const prevElem = newData[i - 1];
        combine.unshift(prevElem);
        newData[i - 1] = combine;
        i += 1;
      }
    }
  }

  const removedUnnecessary = newData.filter(removeUnnecessary);

  return removedUnnecessary;
};

export default (data) => {
  if (data && Array.isArray(data[0])) {
    return data.map((item) => (combineRows(item)));
  }

  return combineRows(data);
};
