import React from 'react';
import PropTypes from 'prop-types';
import { separateThousands } from 'helpers';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('calc-item');

const propTypes = {
  calculatedValues: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  calculatedValues: [],
};

const CalculatedValues = ({
  calculatedValues,
}) => calculatedValues.map((calculatedValue) => Boolean(calculatedValue.value) && (
  <div key={`${calculatedValue.title}-${calculatedValue.value}`} className={b()}>
    <div className={b('calc-sum-row')}>
      <div className={b('calc-sum-title')}>{calculatedValue.title}</div>
      <div className={b('calc-sum-weight')}>{separateThousands(calculatedValue.value)}</div>
    </div>
  </div>
));

CalculatedValues.propTypes = propTypes;
CalculatedValues.defaultProps = defaultProps;
export default CalculatedValues;
