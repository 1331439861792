import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { Receiver } from 'react-file-uploader';
import { toastr } from 'react-redux-toastr';
import { LANG_DICTIONARY, AVAILABLE_FORMATS_STRING, AVAILABLE_FORMATS } from 'consts';
import { connect } from 'react-redux';
import { downloadScanForView } from 'redux/rootActions';
import { getFormat } from 'helpers';
import { MAX_SIZE_SCAN } from 'config';
import FileList from './fileList';

const { SIZE_ERROR, TYPE_ERROR } = LANG_DICTIONARY;

const defaultProps = {
  files: [],
  downloadScan: () => null,
  disableControls: false,
};

const propTypes = {
  onUpload: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  disabled: PropTypes.bool.isRequired,
  files: PropTypes.arrayOf(PropTypes.object),
  downloadScan: PropTypes.func,
  disableControls: PropTypes.bool,
};

const b = block('fileArea');

class FileUpload extends Component {
  checkFileSize = (data) => Object.values(data).find((file) => file.size > MAX_SIZE_SCAN);

  checkFileType = (data) => {
    let isError = false;

    Object.values(data).map((file) => {
      if (!AVAILABLE_FORMATS.includes(getFormat(file.type))) {
        isError = true;
      }

      return null;
    });

    return isError;
  };

  onFileDrop = (e, data) => {
    const files = data.map((file) => (file.data));
    this.handleChange(files);
  };

  handleChange = (files) => {
    const { onUpload } = this.props;

    if (this.checkFileType(files)) {
      toastr.error('', TYPE_ERROR);

      return;
    }

    if (this.checkFileSize(files)) {
      toastr.error('', SIZE_ERROR);

      return;
    }

    onUpload(files);
    this.fileInput.value = null;
  };

  onSelectFile = () => {
    this.fileInput.value = null;
    this.fileInput.click();
  };

  render() {
    const {
      files,
      onDelete,
      disabled,
      downloadScan,
      disableControls,
      ...props
    } = this.props;

    return (
      <Receiver
        wrapperId="dndArea"
        onDragEnter={() => null}
        onDragOver={() => null}
        onDragLeave={() => null}
        onFileDrop={this.onFileDrop}
        isOpen
      >
        <div className={b()}>
          <div id="dndArea">
            <FileList
              files={files}
              listClass={b('fileList')}
              itemClass={b('fileItem')}
              downloadScan={downloadScan}
              onDelete={onDelete}
              disableControls={disableControls}
              {...props}
            />
            <div className={b('areaButton')} onClick={() => !disabled && this.onSelectFile()} role="button" tabIndex="0">
              <div className={b('areaButton', 'areaText')}>
                Нажмите или перетащите сюда
                <b> сканы для загрузки</b>
              </div>
            </div>
          </div>
        </div>
        <input
          type="file"
          style={{ display: 'none' }}
          ref={(ref) => { this.fileInput = ref; }}
          onChange={(e) => this.handleChange(e.target.files)}
          accept={AVAILABLE_FORMATS_STRING}
          multiple
        />
      </Receiver>
    );
  }
}

const mapDispatchToProps = {
  downloadScan: downloadScanForView,
};

FileUpload.defaultProps = defaultProps;
FileUpload.propTypes = propTypes;

export default connect(null, mapDispatchToProps)(FileUpload);
