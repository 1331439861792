import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { SelectInput } from 'components/_deprecated';
import { LANG_DICTIONARY } from 'consts';
import { getRelationships } from 'helpers';
import { getOptions } from 'redux/rootActions';

import { Container, Row, Col } from 'reactstrap';

const {
  RELATIONSHIP_PERSON_WITH_AGENT,
  RELATIONSHIPS,
  RELATIONSHIP_PERSON_WITH_AGENT_CHILD,
} = LANG_DICTIONARY;

const defaultProps = {
  getOptionsValues: null,
  inputProps: {},
  relationships: [],
  prefix: '',
  label: '',
  isChildForm: false,
};

const propTypes = {
  label: PropTypes.string,
  prefix: PropTypes.string,
  getOptionsValues: PropTypes.func,
  inputProps: PropTypes.object,
  relationships: PropTypes.arrayOf({}),
  isChildForm: PropTypes.bool,
};

class RelationShipBlock extends Component {
  componentDidMount() {
    const { getOptionsValues } = this.props;
    getOptionsValues('relationships');
  }

  render() {
    const {
      relationships,
      inputProps,
      prefix,
      label = RELATIONSHIP_PERSON_WITH_AGENT,
      isChildForm,
    } = this.props;

    const labelWithMarkChild = isChildForm
      ? RELATIONSHIP_PERSON_WITH_AGENT_CHILD
      : label;

    return (
      <Container>
        <Row>
          <Col md="12" sm="12">
            <SelectInput
              name={`${prefix}relationship`}
              label={labelWithMarkChild}
              placeholder={RELATIONSHIPS}
              options={getRelationships(relationships)}
              {...inputProps}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

RelationShipBlock.defaultProps = defaultProps;
RelationShipBlock.propTypes = propTypes;

export default connect(
  ({ helperReducer: { relationships } }) => ({
    relationships,
  }),
  {
    getOptionsValues: getOptions,
  },
)(RelationShipBlock);
