import {
  CHECK_TEMPLATE,
  UPLOAD_TEMPLATE,
  SET_TEMPLATE,
  CLEAR_ERRORS,
  CLOSE_TEMPLATE_IS_EXIST_MODAL,
  CLEAR_STORE,
} from '../types';

const checkTemplate = (formData) => ({
  type: CHECK_TEMPLATE.request,
  payload: formData,
});

const uploadTemplate = (formData) => ({
  type: UPLOAD_TEMPLATE.request,
  payload: formData,
});

const setTemplate = (file) => ({
  type: SET_TEMPLATE,
  payload: file,
});

const clearUploadTemplateErrors = () => ({
  type: CLEAR_ERRORS,
});

const closeIsExistFileModal = () => ({
  type: CLOSE_TEMPLATE_IS_EXIST_MODAL,
});

const clearStoreTemplates = () => ({
  type: CLEAR_STORE,
});

export {
  checkTemplate,
  uploadTemplate,
  setTemplate,
  clearUploadTemplateErrors,
  closeIsExistFileModal,
  clearStoreTemplates,
};
