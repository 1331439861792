export default (headers, data) => ({
  key: data[9],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0] },
      ],
      data: [
        { text: data[0] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[5] },
        { text: headers[6] },
      ],
      data: [
        { text: data[5] },
        { text: data[6] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[7] },
      ],
      data: [
        { text: data[7] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[8] },
      ],
      data: [
        { text: data[8] },
      ],
    },
  ],
});
