import React from 'react';
import { CustomRoute, NotFoundPage } from 'components';
import { ROUTES, LANG_DICTIONARY } from 'consts';

const { PAGE_NOT_FOUND } = LANG_DICTIONARY;

const pathes = [
  {
    path: '*',
    title: PAGE_NOT_FOUND,
    component: NotFoundPage,
    publicRoute: true,
  },
  {
    path: ROUTES.notFound,
    title: PAGE_NOT_FOUND,
    component: NotFoundPage,
    publicRoute: true,
  },
];
export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
