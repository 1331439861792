import { put, takeEvery } from 'redux-saga/effects';
import { API } from 'consts';
import { apiCall, getError } from 'helpers';
import {
  LOG_PAGE_SUCCESS,
  LOG_PAGE_START,
  LOG_PAGE_REQUEST,
  LOG_PAGE_FAIL,
} from '../types';

function* loggingPage({ payload: { path, title } }) {
  const { LOG_PAGE } = API;
  yield put({ type: LOG_PAGE_START });
  try {
    yield apiCall({
      type: 'post',
      body: {
        title,
        result: true,
        url: path,
      },
      url: LOG_PAGE,
    });
    yield put({ type: LOG_PAGE_SUCCESS });
  } catch (err) {
    yield put({ type: LOG_PAGE_FAIL, payload: getError(err) });
  }
}

function* loggingSaga() {
  yield takeEvery(LOG_PAGE_REQUEST, loggingPage);
}

export default loggingSaga;
