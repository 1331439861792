import { ROUTES } from 'consts';
import actions from './actions';

const {
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING,
  DOCUMENT_CHECKING_GET_DOCUMENT,
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST,
  EXPORT_1C,
  IMPORT_UPLOAD_REFERENCIES,
  DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT,
  SEARCH_LIST,
  GET_PARTNERS_DATA_FOR_ADMIN_INTERFACE,
  CREATE_NEW_PARTNER,
  UPDATE_PARTNER,
  GET_BRANCHES_DATA_FOR_ADMIN_INTERFACE,
  GET_USERS_DATA_FOR_ADMIN_INTERFACE,
  UPDATE_USER,
  UPDATE_REPORT,
  GET_REPORTS_DATA_FOR_ADMIN_INTERFACE,
  GET_CONTRACT_STATUSES,
  GET_PRODUCTS,
  GET_SCHEDULER,
  GET_REFERENCES,
  CREATE_NEW_PUBLIC_API,
  UPDATE_PUBLIC_API,
  GET_PUBLIC_API_LIST,
  UPLOAD_TEMPLATE,
} = actions;

const {
  myClients,
  checkMaster,
  checkMasterEdtior,
  checkDocumentList,
  importForm,
  transferData,
  locking,
  addPartners,
  createPartner,
  updatePartner,
  addSubdivisions,
  addUsers,
  updateUser,
  addReports,
  updateReport,
  statusControl,
  addProducts,
  schedulerJobs,
  catalogsSection,
  publicApi,
  createPublicApi,
  updatePublicApi,
  templatesUpload,
} = ROUTES;

export default {
  [catalogsSection]: GET_REFERENCES,
  [schedulerJobs]: GET_SCHEDULER,
  [statusControl]: GET_CONTRACT_STATUSES,
  [addProducts]: GET_PRODUCTS,
  [checkMaster]: DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING,
  [checkMasterEdtior]: DOCUMENT_CHECKING_GET_DOCUMENT,
  [checkDocumentList]: DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST,
  [transferData]: EXPORT_1C,
  [importForm]: IMPORT_UPLOAD_REFERENCIES,
  [myClients]: SEARCH_LIST,
  [locking]: DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT,
  [addPartners]: GET_PARTNERS_DATA_FOR_ADMIN_INTERFACE,
  [addSubdivisions]: GET_BRANCHES_DATA_FOR_ADMIN_INTERFACE,
  [createPartner]: CREATE_NEW_PARTNER,
  [updatePartner]: UPDATE_PARTNER,
  [addUsers]: GET_USERS_DATA_FOR_ADMIN_INTERFACE,
  [updateUser]: UPDATE_USER,
  [addReports]: GET_REPORTS_DATA_FOR_ADMIN_INTERFACE,
  [updateReport]: UPDATE_REPORT,
  [publicApi]: GET_PUBLIC_API_LIST,
  [createPublicApi]: CREATE_NEW_PUBLIC_API,
  [updatePublicApi]: UPDATE_PUBLIC_API,
  [templatesUpload]: UPLOAD_TEMPLATE,
};
