import { LANG_DICTIONARY } from 'consts';
import { string, object, boolean } from 'yup';
import { yupEmpty, checkOkatoCode } from 'helpers';

const {
  REQUIRED,
  MAX_HUNDRED,
  FILL_COMPLETELY,
} = LANG_DICTIONARY;

export default (isRootBranch) => object().shape({
  isRootBranch: boolean().notRequired(),
  branchAddress: string().max(100, MAX_HUNDRED),
  branchName: string().required(REQUIRED).max(100, MAX_HUNDRED),
  parentBranchId: isRootBranch
    ? string().max(100, MAX_HUNDRED).nullable()
    : string().required(REQUIRED).max(100, MAX_HUNDRED).nullable(),
  partnerId: string().required(REQUIRED).nullable(),
  treeBranch: string(),
  codeOKATO: yupEmpty(
    string()
      .test('len', FILL_COMPLETELY, (val) => checkOkatoCode(val)),
    FILL_COMPLETELY,
  ).nullable(),
});
