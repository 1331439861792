export default (status) => {
  switch (status) {
    case 'new':
      return {
        title: 'Новый',
        color: 'black',
      };
    case 'checked':
      return {
        title: 'Проверен',
        color: 'green',
      };
    case 'half_checked':
      return {
        title: 'Частично проверен',
        color: 'blue',
      };
    case 'error':
      return {
        title: 'Ошибка',
        color: 'red',
      };
    default:
      return {
        title: status,
        color: 'black',
      };
  }
};
