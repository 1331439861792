import {
  CHECK_ACTIVE_CONTEST,
  GET_BANNER,
  GET_CONTESTS_DATA,
  TOGGLE_IS_ACTIVE,
  SET_INIT_STATE,
  GET_CLUSTER,
  GET_DOWNLOAD_CONTEST,
} from '../types';

export const contestActions = {
  checkActive: () => ({
    type: CHECK_ACTIVE_CONTEST.request,
  }),

  getBanner: () => ({
    type: GET_BANNER.request,
  }),

  toggleIsActiveBanner: (status) => ({
    type: TOGGLE_IS_ACTIVE,
    payload: status,
  }),

  getContestsData: () => ({
    type: GET_CONTESTS_DATA.request,
  }),

  getDownloadContest: ({
    compId,
    compName,
  }) => ({
    type: GET_DOWNLOAD_CONTEST.request,
    payload: {
      compId,
      compName,
    },
  }),

  setInitState: () => ({
    type: SET_INIT_STATE,
  }),

  getCluster: ({
    compId,
    id,
    limit,
  }) => ({
    type: GET_CLUSTER.request,
    payload: {
      compId,
      id,
      limit,
    },
  }),
};
