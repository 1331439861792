import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('agentData');

export const CHANGE_FIELD_VALUE = factory.create('CHANGE_FIELD_VALUE');
export const SET_TOUCH_FIELD = factory.create('SET_TOUCH_FIELD');
export const VALIDATION_FIELD = factory.create('VALIDATION_FIELD');
export const SET_CURRENT_AGENT_TYPE = factory.create('SET_CURRENT_AGENT_TYPE');
export const CHANGE_INSURED_IS_SAME = factory.create('CHANGE_INSURED_IS_SAME');
export const SET_ALL_TOUCHED_FIELD = factory.create('SET_ALL_TOUCHED_FIELD');
export const CLEAR_AGENT_ERRORS = factory.create('CLEAR_AGENT_ERRORS');
export const CLEAR_AGENT_DATA = factory.create('CLEAR_AGENT_DATA');
export const CHANGE_DISABLE_INSURED_IS_SAME = factory.create('CHANGE_DISABLE_INSURED_IS_SAME');
export const COPY_CONTRACT = factory.createAsync('COPY_CONTRACT');
export const SET_ADDRESS_DATA_AGENT = factory.create('SET_ADDRESS_DATA_AGENT');
