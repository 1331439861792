import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('field-block');

const propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  big: PropTypes.bool,
  bold: PropTypes.bool,
};

const defaultProps = {
  title: '',
  big: false,
  bold: false,
};

const FieldBlock = (props) => {
  const {
    title,
    children,
    big,
    bold,
  } = props;

  const getClassText = () => b('text', { big, bold });

  return (
    <div className={b()}>
      <p className={getClassText()}>{title}</p>
      {children}
    </div>
  );
};

FieldBlock.propTypes = propTypes;
FieldBlock.defaultProps = defaultProps;

export default FieldBlock;
