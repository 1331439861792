import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';

import { LANG_DICTIONARY } from 'consts';

import { Input } from 'semantic-ui-react';
import { SearchBtn } from 'components';
import { Row, Col } from 'reactstrap';

const {
  FIO,
  CONTRACT_NUMBER,
} = LANG_DICTIONARY;

const b = block('events-form');

const propTypes = {
  choosedTabErrors: PropTypes.bool.isRequired,
  controls: PropTypes.object.isRequired,
  onControlsChange: PropTypes.func.isRequired,
  onControlsSubmit: PropTypes.func.isRequired,
};

const Controls = (props) => {
  const {
    choosedTabErrors,
    controls,
    onControlsChange,
    onControlsSubmit: onSearch,
  } = props;

  const inputHandler = (key) => (e) => {
    onControlsChange(key, e.target.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    onSearch();
  };

  if (choosedTabErrors) {
    return (
      <div className={b('table-controls')}>
        <form onSubmit={onSubmit}>
          <Row>
            <Col md="3" sm="12" className="nopaddings">
              <Input
                className="w-100"
                placeholder={FIO}
                name="fullName"
                value={controls.fullName}
                onChange={inputHandler('fullName')}
              />
            </Col>
            <Col md="3" sm="12" className="nopaddings">
              <Input
                className="w-100"
                placeholder={CONTRACT_NUMBER}
                name="contractName"
                value={controls.contractName}
                onChange={inputHandler('contractName')}
              />
            </Col>
            <Col className="offset-md-3" md="3" sm="12">
              <SearchBtn className="w-100" action={onSearch} />
            </Col>
          </Row>
        </form>
      </div>
    );
  }

  return null;
};

Controls.propTypes = propTypes;

export default Controls;
