import React, { useEffect, useMemo, useState } from 'react';
import { LANG_DICTIONARY } from 'consts';
import { MainBtn, Select } from 'components';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import PopupContainer from '../PopupContainer';
import './styles.scss';

const b = block('dp-policy-constructor-execution-popup');

const {
  SEND,
  SELECT_PRODUCT_VERSION,
} = LANG_DICTIONARY;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  isSubmitLoading: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  productForm: PropTypes.object,
  onSubmit: PropTypes.func.isRequired,
};

const defaultProps = {
  productForm: null,
};

const DpPolicyConstructorExecution = ({
  isOpen,
  isSubmitLoading,
  productForm,
  onClose,
  onSubmit,
}) => {
  const [productType, setProductType] = useState();

  useEffect(() => {
    setProductType(productForm?.insLineIszObjects[0].id);
  }, [productForm?.insLineIszObjects]);

  const options = useMemo(() => productForm?.insLineIszObjects?.map((item) => ({
    key: item.id,
    value: item.id,
    text: item.name,
  })), [productForm?.insLineIszObjects]);

  const handleChangeProductType = ({ target: { value } }) => {
    setProductType(value);
  };

  const handleSubmit = () => {
    onSubmit(productType);
  };

  return (
    <PopupContainer
      isOpen={isOpen}
      isCloseButton
      close={onClose}
      className={b()}
    >
      <div>
        <div className={b('form')}>
          <p className={b('label')}>{SELECT_PRODUCT_VERSION}</p>
          <Select
            options={options}
            value={productType}
            onChange={handleChangeProductType}
          />
        </div>
        <MainBtn
          text={SEND}
          onClick={handleSubmit}
          disabled={isSubmitLoading}
          isLoading={isSubmitLoading}
        />
      </div>
    </PopupContainer>
  );
};

DpPolicyConstructorExecution.propTypes = propTypes;
DpPolicyConstructorExecution.defaultProps = defaultProps;

export default DpPolicyConstructorExecution;
