import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import { withCustomRouter } from 'HOC';
import { getUnitedFrontendParamsSelector, sharedSelectors } from 'redux/rootSelectors';
import { Formik } from 'formik';
import {
  authorize,
  getRecaptcha,
  logOut,
  toggleModalAffiliation,
  forgotPassword,
  changeRecoveryLoginModal,
} from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts';
import { object, string } from 'yup';
import {
  CaptchaBlock,
  UserDependencies,
  RecoveryLogin,
} from 'components';
import { TextInput } from 'components/_deprecated';
import { getErrorsFromValidationError } from 'helpers';
import { block } from 'bem-cn';
import { socketClose } from 'websocket';
import './styles.scss';

const bem = block('auth');

const defaultProps = {
  isLoading: false,
  signIn: () => null,
  history: null,
  error: null,
  bem: null,
  token: '',
  getIpAddress: () => null,
  getCaptchaAction: () => null,
  recaptchaAction: () => null,
  logOutAction: () => null,
  captcha: '',
  captchaId: '',
  modalAffiliation: false,
  setModalAffiliation: () => null,
  sendRequestForForgottenPassword: () => null,
  masterId: '',
  riskProfile: '',
  qualification: null,
  queryParams: {},
  changeRecoveryLoginModalAction: () => null,
};

const propTypes = {
  recaptchaAction: PropTypes.func,
  isLoading: PropTypes.bool,
  signIn: PropTypes.func,
  history: PropTypes.object,
  error: PropTypes.string,
  bem: PropTypes.func,
  token: PropTypes.string,
  getIpAddress: PropTypes.func,
  captcha: PropTypes.string,
  captchaId: PropTypes.string,
  getCaptchaAction: PropTypes.func,
  logOutAction: PropTypes.func,
  modalAffiliation: PropTypes.bool,
  setModalAffiliation: PropTypes.func,
  sendRequestForForgottenPassword: PropTypes.func,
  masterId: PropTypes.string,
  riskProfile: PropTypes.string,
  qualification: PropTypes.number,
  windowUuid: PropTypes.string.isRequired,
  windowTimestamp: PropTypes.number.isRequired,
  queryParams: PropTypes.object,
  changeRecoveryLoginModalAction: PropTypes.func,
};

const {
  REQUIRED,
  NO_MORE_THEN_FIFTY,
  RESTOR_PASSWORD,
  WELCOME,
  USER_NAME,
  PASSWORD,
  SIGN_IN,
} = LANG_DICTIONARY;

const schema = (isCaptcha) => object().shape({
  userLogin: string()
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
  userPassword: string()
    .required(REQUIRED),
  key: (isCaptcha) ? string().required(REQUIRED) : string().notRequired(),
});

class AuthForm extends Component {
  constructor(props) {
    super(props);
    this.buttonRef = React.createRef(null);
    this.state = {
      isUnitedFront: false,
    };
  }

  componentDidMount() {
    const {
      logOutAction,
      history,
      setModalAffiliation,
      queryParams,
    } = this.props;
    const {
      isUnitedFront,
    } = this.state;

    this.setState({
      isUnitedFront: Boolean(queryParams?.masterId || queryParams?.riskProfile)
      || queryParams?.qualification !== undefined,
    });

    logOutAction({
      history,
      mute: true,
      redirect: false,
      isUnitedFront,
      masterId: queryParams.masterId,
      riskProfile: queryParams.riskProfile,
      qualification: Number(queryParams.qualification),
    });

    setModalAffiliation(false);
    socketClose();
  }

  componentDidUpdate() {
    if (this.buttonRef.current) {
      this.buttonRef.current.focus();
    }
  }

  handleReqNewPassword = (userLogin) => {
    const {
      sendRequestForForgottenPassword,
    } = this.props;
    sendRequestForForgottenPassword(userLogin);
  }

  handleAuth = ({ userLogin, userPassword, key }, { setSubmitting, setFieldValue }) => {
    const {
      signIn,
      history,
      captchaId,
      windowUuid,
      windowTimestamp,
      queryParams,
    } = this.props;

    signIn({
      userLogin,
      userPassword,
      key,
      captchaId,
      history,
      action: () => setFieldValue('key', ''),
      masterId: queryParams.masterId,
      riskProfile: queryParams.riskProfile,
      qualification: Number(queryParams.qualification),
      tabUuid: windowUuid,
      tabTimestamp: windowTimestamp,
    });
    setSubmitting(false);
  };

  validateForm = ({ userLogin, userPassword, key }) => {
    const { captcha } = this.props;
    try {
      schema(captcha)
        .validateSync({ userLogin, userPassword, key }, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };

  handleRecaptcha = () => {
    const { captchaId, recaptchaAction } = this.props;
    recaptchaAction(captchaId);
  };

  handleRestorePasswordClick = (values) => {
    if (!values.userLogin) {
      const { changeRecoveryLoginModalAction } = this.props;

      changeRecoveryLoginModalAction(true);

      return;
    }

    this.handleReqNewPassword(values.userLogin);
    this.buttonRef.current.focus();
  }

  render() {
    const {
      isLoading,
      error: serverError,
      captcha,
      captchaId,
      modalAffiliation,
    } = this.props;

    const {
      isUnitedFront,
    } = this.state;

    return (
      <>
        <Formik
          initialValues={{ userLogin: '', userPassword: '', key: '' }}
          validate={(values) => this.validateForm(values)}
          onSubmit={this.handleAuth}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const inputProps = {
              values,
              errors,
              touched,
              onChange: handleChange,
              onBlur: handleBlur,
            };

            if (modalAffiliation) return <UserDependencies isAuth />;

            return (
              <div className={bem()}>
                <div className={bem('container')}>
                  <div className={bem('form')}>
                    <Form loading={isLoading}>
                      <div className={bem('title')}>{ WELCOME }</div>
                      <TextInput name="userLogin" placeholder={USER_NAME} {...inputProps} />
                      <TextInput name="userPassword" type="password" placeholder={PASSWORD} {...inputProps} />
                      {isUnitedFront
                    && (
                      <div className={bem('restore-password-wrapper')}>
                        <button
                          onClick={() => this.handleRestorePasswordClick(values)}
                          className={bem('restore-password').toString()}
                          tabIndex="-1"
                          type="button"
                        >
                          {RESTOR_PASSWORD}
                        </button>
                      </div>
                    )}
                      { serverError && <div className={bem('error')}>{ serverError }</div>}
                      <div className={bem('form', 'interface')}>
                        {captchaId
                      && (
                      <CaptchaBlock
                        captcha={captcha}
                        handleRecaptcha={this.handleRecaptcha}
                        {...inputProps}
                      />
                      )}
                        <div className={bem('controls')}>
                          <Button
                            type="submit"
                            className={bem('signInButton').toString()}
                            disabled={isSubmitting}
                            ref={this.buttonRef}
                            onClick={handleSubmit}
                          >
                            { SIGN_IN }
                          </Button>
                        </div>
                      </div>
                    </Form>
                  </div>
                </div>
              </div>
            );
          }}
        </Formik>
        <RecoveryLogin isUnitedFront={isUnitedFront} />
      </>
    );
  }
}

AuthForm.propTypes = propTypes;
AuthForm.defaultProps = defaultProps;

function mapStateToProps(state) {
  const { windowTimestamp, windowUuid } = getUnitedFrontendParamsSelector(state);

  return {
    ...state.authReducer,
    queryParams: sharedSelectors.getQueryParams(state),
    windowTimestamp,
    windowUuid,
  };
}

export default withCustomRouter(connect(mapStateToProps, {
  signIn: authorize,
  recaptchaAction: getRecaptcha,
  logOutAction: logOut,
  setModalAffiliation: toggleModalAffiliation,
  sendRequestForForgottenPassword: forgotPassword,
  changeRecoveryLoginModalAction: changeRecoveryLoginModal,
})(AuthForm));
