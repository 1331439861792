import { put, takeLatest } from 'redux-saga/effects';
import { apiCall, apiDownload, getError } from 'helpers';
import { ITEM_COUNT_INBOX_MESSAGES_FORM } from 'config';
import { API, LANG_DICTIONARY } from 'consts';
import { toastr } from 'react-redux-toastr';
import {
  GET_UNREAD_INBOX_MESSAGES_COUNT,
  GET_INBOX_MESSAGES,
  DOWNLOAD_INBOX_MESSAGE_FILES,
  MARK_AS_READ_INBOX_MESSAGE,
  GET_SENT_MESSAGES,
  GET_RECIPIENT_PARTNERS,
  GET_RECIPIENTS,
  CREATE_NEW_MESSAGE,
} from '../types';

const {
  CREATE_MESSAGE_SUCCESS,
  DOCS_TRANSFER_MARK_AS_READ_SUCCESS,
} = LANG_DICTIONARY;

function* getRecipientPartners() {
  try {
    yield put({ type: GET_RECIPIENT_PARTNERS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_RECIPIENT_PARTNERS,
    });
    yield put({ type: GET_RECIPIENT_PARTNERS.success, payload: data });
  } catch (e) {
    yield put({ type: GET_RECIPIENT_PARTNERS.fail, payload: getError(e) });
  }
}

function* getRecipients({ payload: { recipientPartnerId } }) {
  try {
    yield put({ type: GET_RECIPIENTS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_RECIPIENTS,
      query: {
        recipientPartnerId,
      },
    });
    yield put({ type: GET_RECIPIENTS.success, payload: data });
  } catch (e) {
    yield put({ type: GET_RECIPIENTS.fail, payload: getError(e) });
  }
}

function* getInboxUnreadMessagesCount() {
  try {
    yield put({ type: GET_UNREAD_INBOX_MESSAGES_COUNT.start });
    const { data: { inboxUnreadMessagesCount } } = yield apiCall({
      type: 'GET',
      url: API.GET_INBOX_UNREAD_MESSAGES_COUNT,
    });
    yield put({ type: GET_UNREAD_INBOX_MESSAGES_COUNT.success, payload: inboxUnreadMessagesCount });
  } catch (e) {
    yield put({ type: GET_UNREAD_INBOX_MESSAGES_COUNT.fail, payload: getError(e) });
  }
}

function* getInboxMessages({ payload }) {
  try {
    const { currentPage, query } = payload;
    yield put({ type: GET_INBOX_MESSAGES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_INBOX_MESSAGES,
      query: {
        offset: ITEM_COUNT_INBOX_MESSAGES_FORM * (currentPage - 1),
        limit: ITEM_COUNT_INBOX_MESSAGES_FORM,
        ...query,
      },
    });
    yield put({ type: GET_INBOX_MESSAGES.success, payload: data });
  } catch (e) {
    yield put({ type: GET_INBOX_MESSAGES.fail, payload: getError(e) });
  }
}

function* getSentMessages({ payload }) {
  try {
    const { currentPage, query } = payload;
    yield put({ type: GET_SENT_MESSAGES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_SENT_MESSAGES,
      query: {
        offset: ITEM_COUNT_INBOX_MESSAGES_FORM * (currentPage - 1),
        limit: ITEM_COUNT_INBOX_MESSAGES_FORM,
        ...query,
      },
    });
    yield put({ type: GET_SENT_MESSAGES.success, payload: data });
  } catch (e) {
    yield put({ type: GET_SENT_MESSAGES.fail, payload: getError(e) });
  }
}

function* downloadInboxMessageFiles({ payload }) {
  try {
    const { id } = payload;
    yield put({ type: DOWNLOAD_INBOX_MESSAGE_FILES.start });
    yield apiDownload({
      type: 'GET',
      url: API.DOWNLOAD_INBOX_MESSAGE_FILE(id),
    }, '');
    yield put({ type: DOWNLOAD_INBOX_MESSAGE_FILES.success, payload: { messageId: id } });
  } catch (e) {
    yield put({ type: DOWNLOAD_INBOX_MESSAGE_FILES.fail, payload: getError(e) });
  }
}

function* markAsReadInboxMessage({ payload: { id } }) {
  try {
    yield put({ type: MARK_AS_READ_INBOX_MESSAGE.start });
    yield apiCall({
      type: 'PUT',
      url: API.DOCS_TRANSFER_MESSAGES(id),
    });
    yield put({ type: MARK_AS_READ_INBOX_MESSAGE.success, payload: { messageId: id } });
    toastr.success('', DOCS_TRANSFER_MARK_AS_READ_SUCCESS);
  } catch (e) {
    yield put({ type: MARK_AS_READ_INBOX_MESSAGE.fail, payload: getError(e) });
  }
}

function* createNewMessage({ payload: { formData } }) {
  try {
    yield put({ type: CREATE_NEW_MESSAGE.start });
    yield apiCall({
      type: 'POST',
      url: API.DOCS_TRANSFER_MESSAGES(''),
      body: formData,
    });
    yield put({ type: CREATE_NEW_MESSAGE.success });
    toastr.success('', CREATE_MESSAGE_SUCCESS);
  } catch (e) {
    yield put({ type: CREATE_NEW_MESSAGE.fail, payload: getError(e) });
  }
}

function* docsTransferSaga() {
  yield takeLatest(GET_RECIPIENT_PARTNERS.request, getRecipientPartners);
  yield takeLatest(GET_RECIPIENTS.request, getRecipients);
  yield takeLatest(GET_UNREAD_INBOX_MESSAGES_COUNT.request, getInboxUnreadMessagesCount);
  yield takeLatest(GET_INBOX_MESSAGES.request, getInboxMessages);
  yield takeLatest(GET_SENT_MESSAGES.request, getSentMessages);
  yield takeLatest(DOWNLOAD_INBOX_MESSAGE_FILES.request, downloadInboxMessageFiles);
  yield takeLatest(MARK_AS_READ_INBOX_MESSAGE.request, markAsReadInboxMessage);
  yield takeLatest(CREATE_NEW_MESSAGE.request, createNewMessage);
}

export default docsTransferSaga;
