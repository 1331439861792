import { dateFormatter } from 'helpers';

const wrapperOptionsList = (array) => {
  array.unshift({
    key: null,
    text: 'Не выбрано',
    value: null,
  });

  return array;
};

const getTerms = (values) => (values ? values.map((val) => ({
  key: val.value,
  text: dateFormatter(val.value, val.unit),
  value: val.termId,
})) : []);

const getGenders = (genders) => {
  if (genders) {
    return genders.map((gender) => (
      {
        text: gender.gender,
        value: gender.id,
        key: gender.id,
      }));
  }

  return [];
};

const getProducts = (values, isMulti) => {
  const options = values ? values.map(({ id, product }) => ({
    key: id,
    text: product,
    value: id,
  })) : [];

  return isMulti ? options : wrapperOptionsList(options);
};

const getStatus = (values, isMulti) => {
  const options = values ? values.map(({ id, status }) => ({
    key: id,
    text: status,
    value: id,
  })) : [];

  return isMulti ? options : wrapperOptionsList(options);
};

const getPartners = (values) => {
  const options = values ? values.map(({ id, partner }) => ({
    key: id,
    text: partner,
    value: id,
  })) : [];

  return wrapperOptionsList(options);
};

const getBranches = (values) => {
  const options = values ? values.map(({ id, branch }) => ({
    key: id,
    text: branch,
    value: id,
  })) : [];

  return wrapperOptionsList(options);
};

const getRelationships = (relationships) => {
  if (relationships) {
    return relationships.map((item) => (
      {
        text: item.relationship,
        value: item.id,
        key: item.id,
      }));
  }

  return [];
};

const currencySelector = (currency) => currency.map((item) => ({
  key: item.code,
  value: item.id,
  text: item.currencyName,
}));

const premiumFreqRebuild = (premiumFreq) => premiumFreq.map((item) => ({
  key: item.code,
  value: item.id,
  text: item.premiumFreq,
}));

const productCalcTypesRebuild = (productCalcTypes) => productCalcTypes.map((item) => ({
  key: item.id,
  value: item.code,
  text: item.name,
}));

const getInsLineIszObjectsProductForm = (insLineIszObjects) => insLineIszObjects.map((item) => ({
  key: item.id,
  value: item.id,
  text: item.name,
}));

export {
  getInsLineIszObjectsProductForm,
  getRelationships,
  getBranches,
  getPartners,
  getProducts,
  getTerms,
  getGenders,
  getStatus,
  premiumFreqRebuild,
  productCalcTypesRebuild,
  currencySelector,
};
