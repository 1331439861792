const CHANGE_SEARCH_VALUE_UNDERWRITING = 'underwriting-list/CHANGE_SEARCH_VALUE_UNDERWRITING';
const TOGGLE_ADDITIONAL_SEARCH = 'underwriting-list/TOGGLE_ADDITIONAL_SEARCH';
const TOGGLE_SHOW_ADDITIONAL_INFO = 'underwriting-list/TOGGLE_SHOW_ADDITIONAL_INFO';
const SEARCH_CONTRACTS_REQUEST = 'underwriting-list/SEARCH_CONTRACTS_REQUEST';
const SEARCH_CONTRACTS_START = 'underwriting-list/SEARCH_CONTRACTS_START';
const SEARCH_CONTRACTS_SUCCESS = 'underwriting-list/SEARCH_CONTRACTS_SUCCESS';
const SEARCH_CONTRACTS_FAIL = 'underwriting-list/SEARCH_CONTRACTS_FAIL';
const SWITCH_PAGINATION_PAGE = 'underwriting-list/SWITCH_PAGINATION_PAGE';
const SET_INIT_STATE = 'underwriting-list/SET_INIT_STATE';

export {
  SET_INIT_STATE,
  SWITCH_PAGINATION_PAGE,
  TOGGLE_SHOW_ADDITIONAL_INFO,
  SEARCH_CONTRACTS_FAIL,
  SEARCH_CONTRACTS_REQUEST,
  SEARCH_CONTRACTS_START,
  SEARCH_CONTRACTS_SUCCESS,
  TOGGLE_ADDITIONAL_SEARCH,
  CHANGE_SEARCH_VALUE_UNDERWRITING,
};
