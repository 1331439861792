import React from 'react';
import {
  AddressBlock,
  AddressBlockManual,
  ErrorMessage,
} from 'components';
import { setManualValidationAddress } from 'redux/rootActions';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('address-block-common');

const {
  SWITCH_AUTO_ENTER,
  SWITCH_MANUAL_ENTER,
} = LANG_DICTIONARY;

const propTypes = {
  index: PropTypes.number,
  isManualEnter: PropTypes.bool,
  toggleTypeEnter: PropTypes.func,
  isLivingAddress: PropTypes.bool,
  inputProps: PropTypes.object,
  listValidAddress: PropTypes.arrayOf(PropTypes.object),
  isListBlackAddress: PropTypes.bool,
  setValidationAddress: PropTypes.func,
};

const defaultProps = {
  index: 0,
  isManualEnter: false,
  toggleTypeEnter: () => null,
  isLivingAddress: false,
  inputProps: {},
  listValidAddress: [],
  isListBlackAddress: false,
  setValidationAddress: () => null,
};

const AddressBlockCommon = (props) => {
  const {
    isManualEnter,
    toggleTypeEnter,
    isLivingAddress,
    inputProps,
    listValidAddress,
    index,
  } = props;

  const living = isLivingAddress ? 'Living' : '';

  const inputs = [
    `index${living}`,
    `republic${living}`,
    `area${living}`,
    `city${living}`,
    `street${living}`,
    `house${living}`,
    `houseNumber${living}`,
    `apartment${living}`,
  ];

  const resetForm = () => {
    const { onChange } = inputProps;
    const getEmpty = (nameInput) => ({ target: { name: nameInput, value: '' } });
    inputs.forEach((item) => {
      onChange(getEmpty(item));
    });
    onChange({ target: { name: `addressFias${living}`, value: {} } });
  };

  const switchTypeEnter = () => {
    toggleTypeEnter(!isManualEnter);

    resetForm();
  };

  const errorAddress = listValidAddress[index] && listValidAddress[index].error;
  const errorAddressLiving = isLivingAddress ?
    listValidAddress[index] && listValidAddress[index].errorLiving :
    null;

  return (
    <div className={b()}>
      {isManualEnter ? (
        <AddressBlockManual
          {...props}
          inputs={inputs}
        />
      ) : (
        <AddressBlock
          {...props}
          inputs={inputs}
        />
      )}
      <div className={b('btn-interface')}>
        <button
          className={b('switch-button')}
          onClick={switchTypeEnter}
          type="button"
        >
          {isManualEnter ? SWITCH_AUTO_ENTER : SWITCH_MANUAL_ENTER}
        </button>
      </div>
      {errorAddress && !isLivingAddress && <ErrorMessage message={errorAddress} />}
      {errorAddressLiving && isLivingAddress && <ErrorMessage message={errorAddressLiving} />}
    </div>
  );
};

const mapStateToProps = ({
  validation: {
    listValidAddress,
  },
}) => ({ listValidAddress });

const mapDispatchToProps = {
  setValidationAddress: setManualValidationAddress,
};

AddressBlockCommon.propTypes = propTypes;
AddressBlockCommon.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressBlockCommon);
