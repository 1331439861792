import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Row, Col } from 'reactstrap';
import { assignIdCustomTableRow } from 'helpers';
import './styles.scss';

const b = block('custom-table-row');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  minHeight: PropTypes.number,
  index: PropTypes.number,
  windowWidth: PropTypes.number,
  selectedItem: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  setSelectedItem: PropTypes.func,
};

const defaultProps = {
  data: [],
  minHeight: 80,
  index: 0,
  windowWidth: 1000,
  selectedItem: null,
  setSelectedItem: () => null,
};

const getStyles = (height, countColumn) => (
  {
    minHeight: height / countColumn,
    height: `${(1 / countColumn) * 100}%`,
  });

const FlexTableRow = ({
  data,
  minHeight,
  index,
  windowWidth,
  setSelectedItem,
  selectedItem,
}) => (
  <div
    className={b()}
  >
    <Row>
      {assignIdCustomTableRow({ data, prefix: 1, startId: 1 }).map(({
        data: includeData,
        headers,
        md,
        id,
        xs,
        sm,
      }) => (
        <Col md={md} key={id} xs={xs} sm={sm}>
          <div className={b('headers')}>
            {headers && headers.map(({ text, id: includeId }) => (
              <div
                style={getStyles(minHeight, headers.length)}
                className={b('item', { header: true })}
                key={includeId}
              >
                {text}
              </div>
            ))}
          </div>
          {includeData && (
          <div
            className={b('data', { highlight: index === selectedItem })}
            onClick={() => setSelectedItem(index)}
            role="button"
            tabIndex="-1"
            style={{
              height: index === 0 || windowWidth < 767
                ? `calc(100% - ${minHeight}px)` : '100%',
            }}
            key={`${windowWidth}windowwkey${index}`}
          >
            {includeData.map(({ text, id: includeId, style }) => (
              <div
                style={{ ...getStyles(minHeight, includeData.length), ...style }}
                className={b('item', { second: index % 2 === 1 })}
                key={includeId}
              >
                {text}
              </div>
            ))}
          </div>
          )}
        </Col>
      ))}
    </Row>
  </div>
);

FlexTableRow.propTypes = propTypes;
FlexTableRow.defaultProps = defaultProps;
export default FlexTableRow;
