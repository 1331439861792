const COUPON_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: false,
  isProductStart: true,
  isPremium: true,
};

const COUPON_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const COUPON_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const COUPON_BENEFICIARY = {

};

const COUPON_SETTINGS = {
  preliminary: COUPON_PRELIMINARY,
  insurrerAgent: COUPON_AGENT,
  insurredPerson: COUPON_PERSON,
  beneficiary: COUPON_BENEFICIARY,
};

export default COUPON_SETTINGS;
