const GET_PARTNERS_OPTIONS_REQUEST = 'selects/GET_PARTNERS_OPTIONS_REQUEST';
const GET_PARTNERS_OPTIONS_START = 'selects/GET_PARTNERS_OPTIONS_START';
const GET_PARTNERS_OPTIONS_SUCCESS = 'selects/GET_PARTNERS_OPTIONS_SUCCESS';
const GET_PARTNERS_OPTIONS_FAIL = 'selects/GET_PARTNERS_OPTIONS_FAIL';

const GET_BRANCHES_OPTIONS_REQUEST = 'selects/GET_BRANCHES_OPTIONS_REQUEST';
const GET_BRANCHES_OPTIONS_START = 'selects/GET_BRANCHES_OPTIONS_START';
const GET_BRANCHES_OPTIONS_SUCCESS = 'selects/GET_BRANCHES_OPTIONS_SUCCESS';
const GET_BRANCHES_OPTIONS_FAIL = 'selects/GET_BRANCHES_OPTIONS_FAIL';

const GET_REPORT_TYPE_OPTIONS_REQUEST = 'selects/GET_REPORT_TYPE_REQUEST';
const GET_REPORT_TYPE_OPTIONS_START = 'selects/GET_REPORT_TYPE_START';
const GET_REPORT_TYPE_OPTIONS_SUCCESS = 'selects/GET_REPORT_TYPE_SUCCESS';
const GET_REPORT_TYPE_OPTIONS_FAIL = 'selects/GET_REPORT_TYPE_FAIL';

const SELECT_OPTIONS = 'selects/SELECT_OPTIONS';

export {
  GET_BRANCHES_OPTIONS_REQUEST,
  GET_BRANCHES_OPTIONS_SUCCESS,
  GET_BRANCHES_OPTIONS_START,
  GET_BRANCHES_OPTIONS_FAIL,
  SELECT_OPTIONS,
  GET_PARTNERS_OPTIONS_FAIL,
  GET_PARTNERS_OPTIONS_START,
  GET_PARTNERS_OPTIONS_REQUEST,
  GET_PARTNERS_OPTIONS_SUCCESS,
  GET_REPORT_TYPE_OPTIONS_REQUEST,
  GET_REPORT_TYPE_OPTIONS_START,
  GET_REPORT_TYPE_OPTIONS_SUCCESS,
  GET_REPORT_TYPE_OPTIONS_FAIL,
};
