import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { withWindowDimensions } from 'HOC';
import RowTable from './row';
import './styles.scss';

const b = block('flex-table');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  minHeight: PropTypes.number,
  windowWidth: PropTypes.number,
  selectedItem: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  setSelectedItem: PropTypes.func,
};

const defaultProps = {
  minHeight: 80,
  windowWidth: 1000,
  selectedItem: null,
  setSelectedItem: () => null,
};

const FlexTable = ({
  data,
  minHeight,
  windowWidth,
  setSelectedItem,
  selectedItem,
}) => (
  <div className={b()}>
    {data.map(({ data: includeData, key }, index) => (
      <RowTable
        minHeight={minHeight}
        index={index}
        data={includeData}
        prefix={key}
        key={key}
        windowWidth={windowWidth}
        setSelectedItem={setSelectedItem}
        selectedItem={selectedItem}
      />
    ))}
  </div>
);

FlexTable.propTypes = propTypes;
FlexTable.defaultProps = defaultProps;
export default withWindowDimensions(FlexTable);
