import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { CatalogsAdminForm, AdministrativeSectionInterface } from 'components';
import {
  getCatalogInfo,
  saveChanges,
  addNewInfoToCatalog,
  editInfoInCatalog,
  deleteRowInCatalog,
  cancelChangesCatalog,
  getSelectInfo,
  accessEdit,
  clearStore,
} from 'redux/rootActions';
import { withCustomRouter } from 'HOC';
import randomstring from 'randomstring';

const propTypes = {
  active: PropTypes.number,
  tableInfo: PropTypes.arrayOf(PropTypes.any),
  selectsInfo: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.any),
  getSelects: PropTypes.func,
  getCatalogs: PropTypes.func,
  addNewRow: PropTypes.func,
  saveTableChanges: PropTypes.func,
  editRow: PropTypes.func,
  deleteRow: PropTypes.func,
  cancelChanges: PropTypes.func,
  accessEditRow: PropTypes.func,
  clearTable: PropTypes.func,
  referenceName: PropTypes.string,
};

const defaultProps = {
  referenceName: '',
  active: 0,
  tableInfo: [],
  selectsInfo: [],
  headers: [],
  getSelects: () => null,
  accessEditRow: () => null,
  getCatalogs: () => null,
  addNewRow: () => null,
  saveTableChanges: () => null,
  editRow: () => null,
  deleteRow: () => null,
  cancelChanges: () => null,
  clearTable: () => null,
};

class CatalogsSection extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      id: null,
    };
  }

  componentDidMount = () => {
    const { getSelects } = this.props;
    getSelects();
  };

  componentWillUnmount = () => {
    const { clearTable } = this.props;
    clearTable();
  };

  handleChangeSelect = (value) => {
    const { getCatalogs } = this.props;
    getCatalogs(value);
    this.setState({
      id: value,
    });
  };

  addNewRowToTable = () => {
    const { addNewRow, headers } = this.props;
    const id = randomstring.generate(10);
    const newRow = headers.map((item, index) => {
      if (index === 0) {
        return id;
      }

      return null;
    });
    addNewRow({
      editAccess: true,
      fromDataBase: false,
      createdRow: true,
      data: newRow,
      id,
    });
  };

  saveChanges = () => {
    const {
      saveTableChanges,
      tableInfo,
    } = this.props;
    const {
      id,
    } = this.state;
    const created = tableInfo.map((item) => {
      if (item.createdRow && !item.deleteStatus) {
        return item.data;
      }

      return null;
    });
    const deleted = tableInfo.map((item) => {
      if (item.deletedFromDataBase) {
        return item.data;
      }

      return null;
    });
    const modify = tableInfo.map((item) => {
      if (item.modify) {
        return item.data;
      }

      return null;
    });
    const insertArray = created.filter((item) => item !== null);
    const updateArray = modify.filter((item) => item !== null);
    const deleteArray = deleted.filter((item) => item !== null);
    saveTableChanges({
      id,
      insertArray,
      updateArray,
      deleteArray,
    });
  };

  editTableRow = (indexRow, changedIndex, value) => {
    const { editRow } = this.props;
    editRow(indexRow, changedIndex, value);
  };

  deleteTableRow = (indexRow) => {
    const { deleteRow } = this.props;
    deleteRow(indexRow);
  };

  reloadChanges = () => {
    const { cancelChanges } = this.props;
    cancelChanges();
  };

  accessRowEdit = (index) => {
    const { accessEditRow } = this.props;
    accessEditRow(index);
  };

  render() {
    const {
      tableInfo,
      selectsInfo,
      active,
      headers,
      referenceName,
    } = this.props;
    const {
      id,
    } = this.state;

    return (
      <AdministrativeSectionInterface
        active={active}
      >
        <CatalogsAdminForm
          selectId={id}
          headers={headers}
          tableInfo={tableInfo}
          selectsInfo={selectsInfo}
          handleChangeSelect={this.handleChangeSelect}
          addRow={this.addNewRowToTable}
          saveChanges={this.saveChanges}
          editRow={this.editTableRow}
          deleteRow={this.deleteTableRow}
          reloadChanges={this.reloadChanges}
          accessRowEdit={this.accessRowEdit}
          referenceName={referenceName}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  catalogsAdminInterface,
}) => ({
  ...catalogsAdminInterface,
});

const mapDispatchToProps = {
  getCatalogs: getCatalogInfo,
  saveTableChanges: saveChanges,
  addNewRow: addNewInfoToCatalog,
  editRow: editInfoInCatalog,
  deleteRow: deleteRowInCatalog,
  cancelChanges: cancelChangesCatalog,
  getSelects: getSelectInfo,
  accessEditRow: accessEdit,
  clearTable: clearStore,
};

CatalogsSection.propTypes = propTypes;
CatalogsSection.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(CatalogsSection));
