import React, { Component } from 'react';
import { block } from 'bem-cn';
import { FileViewModal } from 'components';
import { Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import PropsTypes from 'prop-types';
import './styles.scss';

const b = block('scan-upload-input');

const { SELECT_FILE } = LANG_DICTIONARY;

const propTypes = {
  setFile: PropsTypes.func,
  // eslint-disable-next-line
  file: PropsTypes.any,
};

const defaultProps = {
  setFile: () => null,
};

class ScanUpload extends Component {
  constructor(props) {
    super(props);
    this.state = {
      text: SELECT_FILE,
    };
  }

  handleChange = (e) => {
    const { setFile } = this.props;
    const { files } = e.target;
    if (files.length) {
      const { name } = files[0];
      setFile(files[0]);
      this.setState({
        text: name,
      });
    }
  };

  handleRemove = (e) => {
    e.preventDefault();
    const { setFile } = this.props;
    setFile(null);
    this.setState({
      text: SELECT_FILE,
    });
    this.files.value = null;
  }

  render() {
    const { text } = this.state;
    const { file } = this.props;

    return (
      <div className={b()}>
        <label htmlFor="scan-upload-admin" className={b('scan-upload-label')}>
          <input
            type="file"
            id="scan-upload-admin"
            onChange={this.handleChange}
            ref={(ref) => { this.files = ref; }}
          />
          <div className={b('upload-input')}>
            <div className={b('view')}>
              {file && <FileViewModal file={file} />}
            </div>
            <p className={b('input-text')}>{text}</p>
            <div
              role="button"
              className={b('remove-button')}
              onClick={this.handleRemove}
              tabIndex="-1"
            >
              <Icon name="remove" />
            </div>
          </div>
        </label>
      </div>
    );
  }
}

ScanUpload.propTypes = propTypes;
ScanUpload.defaultProps = defaultProps;
export default ScanUpload;
