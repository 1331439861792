import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Container, Row, Col } from 'reactstrap';
import {
  FormSection,
  DateCustomPicker,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY } from 'consts';
import {
  getGenders,
  getGenderByName,
  replaceFirstChar,
} from 'helpers';
import { getOptions } from 'redux/rootActions';

const {
  INSURER_DATA,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  BIRTH_DATE_SHORT,
  BIRTH_PLACE,
  GENDER_SHORT,
  INSURED_DATA,
  INSURED_DATA_CHILD,
} = LANG_DICTIONARY;

const defaultProps = {
  inputProps: {},
  props: {},
  genders: [],
  setFieldValue: null,
  getOptionList: null,
  isInsured: false,
  validateFullName: () => {},
  validateByDate: () => {},
  checkPassportCovid: false,
  disableBirthdate: false,
  disableGender: false,
  prefix: '',
  isChildForm: false,
  disableAutodetectGender: false,
  validateAddress: () => null,
};

const propTypes = {
  prefix: PropTypes.string,
  disableGender: PropTypes.bool,
  disableBirthdate: PropTypes.bool,
  props: PropTypes.shape({
    modifiedProps: PropTypes.func,
  }),
  genders: PropTypes.arrayOf(PropTypes.object),
  setFieldValue: PropTypes.func,
  getOptionList: PropTypes.func,
  inputProps: PropTypes.object,
  isInsured: PropTypes.bool,
  validateFullName: PropTypes.func,
  validateByDate: PropTypes.func,
  isChildForm: PropTypes.bool,
  disableAutodetectGender: PropTypes.bool,
  checkPassportCovid: PropTypes.bool,
  validateAddress: PropTypes.func,
};

class PersonBlock extends PureComponent {
  constructor(props) {
    super(props);
    const { getOptionList } = this.props;
    getOptionList('genders');
  }

  onBlurInputName = async (e) => {
    const {
      inputProps: {
        onBlur,
        values,
        setFieldValue,
      },
      disableAutodetectGender,
    } = this.props;
    onBlur(e);
    const { firstName, lastName, patronymic: midName } = values;

    if (!disableAutodetectGender) {
      const gender = await getGenderByName({ firstName, lastName, midName });
      setFieldValue('gender', gender);
    }
  };

  render() {
    const {
      inputProps,
      genders,
      isInsured,
      validateFullName,
      validateByDate,
      disableBirthdate,
      disableGender,
      prefix,
      isChildForm,
      validateAddress,
    } = this.props;

    const { onChange } = inputProps;
    const handleChangeInputName = ({ target: { value, name } }) => {
      const rebuildValue = replaceFirstChar(value);
      onChange({ target: { value: rebuildValue, name } });
    };
    const label = isInsured ? INSURED_DATA : INSURER_DATA;
    const labelMarkChild = isChildForm ? INSURED_DATA_CHILD : label;

    return (
      <>
        <FormSection
          label={labelMarkChild}
          inputs={(
            <Container>
              <Row>
                <Col md="4" sm="12">
                  <TextInput
                    name={`${prefix}lastName`}
                    placeholder={LAST_NAME}
                    {...inputProps}
                    onChange={handleChangeInputName}
                    onBlur={(e) => {
                      this.onBlurInputName(e);
                      validateFullName(e);
                      validateAddress(e);
                    }}
                    required
                  />
                </Col>
                <Col md="4" sm="12">
                  <TextInput
                    name={`${prefix}firstName`}
                    placeholder={FIRST_NAME}
                    {...inputProps}
                    onChange={handleChangeInputName}
                    onBlur={(e) => {
                      this.onBlurInputName(e);
                      validateFullName(e);
                      validateAddress(e);
                    }}
                    required
                  />
                </Col>
                <Col md="4" sm="12">
                  <TextInput
                    name={`${prefix}patronymic`}
                    placeholder={PATRONYMIC}
                    {...inputProps}
                    onChange={handleChangeInputName}
                    onBlur={(e) => {
                      this.onBlurInputName(e);
                      validateFullName(e);
                      validateAddress(e);
                    }}
                  />
                </Col>
              </Row>
              <Row>
                <Col md="4" sm="12">
                  <SelectInput
                    name={`${prefix}gender`}
                    placeholder={GENDER_SHORT}
                    options={getGenders(genders)}
                    disabled={disableGender}
                    {...inputProps}
                    required
                  />
                </Col>
                <Col md="3" sm="12">
                  <DateCustomPicker
                    name={`${prefix}birthDate`}
                    placeholder={BIRTH_DATE_SHORT}
                    maxDate={new Date()}
                    {...inputProps}
                    onBlur={(value) => {
                      validateAddress({ target: { name: 'birthDate', value } });
                      validateByDate(value);
                    }}
                    disabled={disableBirthdate}
                    required
                  />
                </Col>
                <Col md="5" sm="12">
                  <TextInput
                    name={`${prefix}birthPlace`}
                    placeholder={BIRTH_PLACE}
                    {...inputProps}
                    required
                  />
                </Col>
              </Row>
            </Container>
          )}
        />
      </>
    );
  }
}

PersonBlock.defaultProps = defaultProps;
PersonBlock.propTypes = propTypes;

function mapStateToProps({
  helperReducer: {
    genders,
  },
}) {
  return {
    genders,
  };
}

export default connect(
  mapStateToProps,
  {
    getOptionList: getOptions,
  },
)(PersonBlock);
