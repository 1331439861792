const COUPON_CURRENCY_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: true,
  isProductStart: true,
  isPremium: true,
};

const COUPON_CURRENCY_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const COUPON_CURRENCY_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const COUPON_CURRENCY_BENEFICIARY = {

};

const COUPON_CURRENCY_SETTINGS = {
  preliminary: COUPON_CURRENCY_PRELIMINARY,
  insurrerAgent: COUPON_CURRENCY_AGENT,
  insurredPerson: COUPON_CURRENCY_PERSON,
  beneficiary: COUPON_CURRENCY_BENEFICIARY,
};

export default COUPON_CURRENCY_SETTINGS;
