import React from 'react';
import { Row, Col } from 'reactstrap';
import { LANG_DICTIONARY } from 'consts';
import { ITEM_COUNT_CONTEST_RESULT, ROUND_CONTEST_VALUES } from 'config';
import { block } from 'bem-cn';
import { Scrollbars } from 'react-custom-scrollbars';
import { roundAndSeparateThousands } from 'helpers';
import { PropTypes } from 'prop-types';

import './styles.scss';
import { MEASURE_TYPE, PARTIC_TYPE } from '../../consts';

const {
  PLACE,
  SUBDIVISION_ABBREVIATION,
  FIO,
  SALES,
  RUB_SHORT,
  NUMBER_OF_AGREEMENTS_SOLD,
  QUANTITY_ITEM_SHORT,
  AVERAGE_CHECK,
  COLLECTION_PLAN,
  IMPLEMENTATION_OF_A_PLAN,
  CLUSTER,
} = LANG_DICTIONARY;

const b = block('contest-result-item');

const schemaNormal = [1, 6, 5];
const schemaWithPlan = [1, 4, 2, 3, 2];

const height = 250;

const getThirdColumnTitle = (measureType) => {
  switch (measureType) {
    case MEASURE_TYPE.PREMIUM:
      return {
        title: `${SALES}, ${RUB_SHORT}`,
        value: 'salesAmount',
      };
    case MEASURE_TYPE.CONTRACTS:
      return {
        title: `${NUMBER_OF_AGREEMENTS_SOLD}, ${QUANTITY_ITEM_SHORT}`,
        value: 'salesCount',
      };
    case MEASURE_TYPE.BIL:
      return {
        title: `${AVERAGE_CHECK}, ${RUB_SHORT}`,
        value: 'salesAverage',
      };
    default:
      return {
        title: '',
        value: '',
      };
  }
};

const propTypes = {
  cluster: PropTypes.arrayOf(PropTypes.object).isRequired,
  getCluster: PropTypes.func.isRequired,
  clusterId: PropTypes.number.isRequired,
  compId: PropTypes.number.isRequired,
  clusterNumber: PropTypes.number.isRequired,
  particType: PropTypes.string.isRequired,
  measureType: PropTypes.string.isRequired,
  isPlan: PropTypes.bool.isRequired,
};

const ContestResult = ({
  cluster,
  getCluster,
  clusterId,
  compId,
  clusterNumber,
  particType,
  measureType,
  isPlan,
}) => {
  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const isEnd = scrollTop === scrollHeight - clientHeight;

    if (isEnd) {
      getCluster({ compId, id: clusterId, limit: cluster.length + ITEM_COUNT_CONTEST_RESULT });
    }
  };

  const schema = isPlan ? schemaWithPlan : schemaNormal;

  return (
    <div className={b()}>
      <Row>
        <Col md={12} className={b('title').toString()}>
          {`${clusterNumber} ${CLUSTER.toUpperCase()}`}
        </Col>
      </Row>
      <Row className={b('title-table').toString()}>
        <Col md={schema[0]} className={b('col-title-table').toString()}>{PLACE}</Col>
        <Col
          md={schema[1]}
          className={b('col-title-table').toString()}
        >
          {particType === PARTIC_TYPE.BRANCHES ? SUBDIVISION_ABBREVIATION : FIO}
        </Col>
        <Col md={schema[2]} className={b('col-title-table').toString()}>{getThirdColumnTitle(measureType).title}</Col>
        {isPlan && (
          <>
            <Col
              md={schema[3]}
              className={b('col-title-table').toString()}
            >
              {`${COLLECTION_PLAN}, ${RUB_SHORT}`}
            </Col>
            <Col
              md={schema[4]}
              className={b('col-title-table').toString()}
            >
              {`${IMPLEMENTATION_OF_A_PLAN}, %`}
            </Col>
          </>
        )}
      </Row>
      <Scrollbars style={{ height: `${height}px` }} onScroll={handleScroll}>
        {cluster.map((item) => (
          <Row key={`${compId}${clusterId}cluster_item${item.id}`}>
            <Col className={b('row-item').toString()} md={schema[0]}>{item.order}</Col>
            <Col className={b('row-item').toString()} md={schema[1]}>{item.name}</Col>
            <Col className={b('row-item').toString()} md={schema[2]}>
              {roundAndSeparateThousands(
                item[getThirdColumnTitle(measureType).value],
                ROUND_CONTEST_VALUES,
              )}
            </Col>
            {isPlan && (
              <>
                <Col
                  md={schema[3]}
                  className={b('row-item').toString()}
                >
                  {roundAndSeparateThousands(item.planValue, ROUND_CONTEST_VALUES)}
                </Col>
                <Col
                  md={schema[4]}
                  className={b('row-item').toString()}
                >
                  {Boolean(Number(item.planProgress)) && `${roundAndSeparateThousands(item.planProgress, ROUND_CONTEST_VALUES)} %`}
                </Col>
              </>
            )}
          </Row>
        ))}
      </Scrollbars>
    </div>
  );
};

ContestResult.propTypes = propTypes;
export default ContestResult;
