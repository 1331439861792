import React, { memo } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { Collapse } from 'reactstrap';

const defaultProps = {
  label: null,
  button: null,
  buttonAction: null,
  checkBoxButton: null,
  isShow: true,
};

const propTypes = {
  inputs: PropTypes.object.isRequired,
  button: PropTypes.func,
  checkBoxButton: PropTypes.func,
  buttonAction: PropTypes.func,
  label: PropTypes.string,
  isShow: PropTypes.bool,
};

const b = block('formContainer');

const FormSection = ({
  inputs: Inputs,
  label,
  button: RenderButton,
  checkBoxButton: CheckBoxButton,
  isShow,
  buttonAction,
}) => (
  <div className={b('formSection')}>
    { label && <div className={b('formSection', 'label')}>{ label }</div> }
    { CheckBoxButton && <CheckBoxButton /> }
    { RenderButton && isShow && <RenderButton buttonAction={buttonAction} /> }
    <Collapse isOpen={Inputs && isShow}>
      <div className={b('formSection', 'row')}>
        {Inputs}
      </div>
    </Collapse>
  </div>
);

FormSection.defaultProps = defaultProps;
FormSection.propTypes = propTypes;

export default memo(FormSection);
