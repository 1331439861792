class AsyncActionFactory {
  CANCEL = '_CANCEL';

  REQUEST = '_REQUEST';

  SUCCESS = '_SUCCESS';

  FAIL = '_FAIL';

  START = '_START';

  END = '_END';

  PREFIX = '';

  constructor(type) {
    this.PREFIX = type;
  }

  createAsync(action) {
    return {
      success: this.getAction(action, this.SUCCESS),
      request: this.getAction(action, this.REQUEST),
      fail: this.getAction(action, this.FAIL),
      start: this.getAction(action, this.START),
      cancel: this.getAction(action, this.CANCEL),
      end: this.getAction(action, this.END),
    };
  }

  create(action) {
    return this.getAction(action);
  }

  createManyStep(action) {
    return {
      start: this.getAction(action, this.START),
      success: this.getAction(action, this.SUCCESS),
    };
  }

  getAction(action, type = '') {
    return `${this.PREFIX}/${action}${type}`;
  }
}

export default AsyncActionFactory;
