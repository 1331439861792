import { yupEmptyDouble } from 'helpers';
import { LANG_DICTIONARY, REGEXP, ERRORS } from 'consts';

import {
  string,
  date,
  number,
  object,
} from 'yup';

const {
  REQUIRED,
  IS_DATE,
  IS_NUMBER,
  ONLY_CYRILLIC_DASH,
  ONLY_CYRILLIC_SYMBOLS,
  NO_MORE_THEN_FIFTY,
  NO_MORE_THEN_THREE_HUNDRED,
  ONLY_CYRILLIC_DASH_AND_BRACKETS,
} = LANG_DICTIONARY;

const {
  BIRTH_PLACE,
  USER_NAME,
  USER_NAME_WITH_BRACKETS,
} = REGEXP;

const { ERROR_VALID_DATE } = ERRORS;

export default () => object().shape({
  lastName: string()
    .matches(USER_NAME_WITH_BRACKETS, ONLY_CYRILLIC_DASH_AND_BRACKETS)
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
  firstName: string()
    .matches(USER_NAME, ONLY_CYRILLIC_DASH)
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
  middleName: yupEmptyDouble([
    {
      message: ONLY_CYRILLIC_DASH,
      condition: string().matches(USER_NAME, ONLY_CYRILLIC_DASH),
    },
    {
      message: NO_MORE_THEN_FIFTY,
      condition: string().max(50, NO_MORE_THEN_FIFTY),
    },
  ]),
  gender: string().required(REQUIRED).nullable(REQUIRED),
  relationships: string().required(REQUIRED).nullable(REQUIRED),
  birthPlace: yupEmptyDouble([
    {
      message: ONLY_CYRILLIC_SYMBOLS,
      condition: string().matches(BIRTH_PLACE, ONLY_CYRILLIC_SYMBOLS),
    },
    {
      message: NO_MORE_THEN_THREE_HUNDRED,
      condition: string().max(100, NO_MORE_THEN_THREE_HUNDRED),
    },
  ]),

  insuredSum: number().typeError(IS_NUMBER).required(REQUIRED),
  birthDate: date()
    .max(new Date(), ERROR_VALID_DATE)
    .typeError(IS_DATE)
    .required(REQUIRED),
});
