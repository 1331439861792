import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Switch } from 'react-router-dom';
import { withCustomRouter } from 'HOC';
import {
  getCurrentUser,
  appCanRender,
  unlockDocumentsUnderwriting,
  saveCheckedContract,
  getVersionDp,
  getAccessPartners,
  getEventsCount,
  pushHistoryRoute,
  checkExistManual,
  getIp,
  getBuildVersion,
  checkAuthSession,
  saveAndClearQueryFromUrlParams,
  generateWindowUuid,
} from 'redux/rootActions';
import { getBuild } from 'helpers';
import productRoutes from './product';
import authorizationRoutes from './authorization';
import administrationRoutes from './administration';
import mainRoutes from './main';
import underwritingRoutes from './underwriting';
import notFoundRoute from './notFound';
import additionalRoutes from './additional';
import { ROUTES } from '../consts';

const defaultProps = {
  history: null,
  token: '',
  roles: {},
  getUser: () => null,
  appRender: () => null,
  location: {},
  getVersion: () => null,
  pushHistoryRouteAction: () => null,
  getIpAddress: () => null,
  getVersionHash: () => null,
  needUpdate: false,
  createWindowUuid: () => null,
  setAndClearQueryFromUrlParams: () => null,
  getPartners: () => null,
};

const propTypes = {
  needUpdate: PropTypes.bool,
  history: PropTypes.object,
  isRender: PropTypes.bool.isRequired,
  token: PropTypes.string,
  roles: PropTypes.object,
  getUser: PropTypes.func,
  appRender: PropTypes.func,
  location: PropTypes.object,
  getVersion: PropTypes.func,
  pushHistoryRouteAction: PropTypes.func,
  getIpAddress: PropTypes.func,
  getVersionHash: PropTypes.func,
  createWindowUuid: PropTypes.func,
  setAndClearQueryFromUrlParams: PropTypes.func,
  getPartners: PropTypes.func,
};

class Router extends React.Component {
  componentDidMount() {
    const {
      token,
      getUser,
      appRender,
      history,
      getVersion,
      location: { pathname, state },
      pushHistoryRouteAction,
      getIpAddress,
      getVersionHash,
      needUpdate,
      createWindowUuid,
      setAndClearQueryFromUrlParams,
      getPartners,
    } = this.props;

    setAndClearQueryFromUrlParams();
    createWindowUuid();

    const isNotSave = state && state.notSave;
    getIpAddress();
    getBuild(getVersionHash, needUpdate);

    document.addEventListener('visibilitychange', () => {
      const tokenFromStorage = localStorage.getItem('token');
      if (!document.hidden && tokenFromStorage) {
        getUser({ history });
      }
    });

    if (token) {
      getVersion();
      getUser({ history, initApp: true });
      getPartners({ history, initApp: true });

      if (!isNotSave) pushHistoryRouteAction(pathname);
    } else {
      history.push(ROUTES.signIn);
      appRender();
    }
  }

  render() {
    const {
      history,
      isRender,
      token,
      roles,
    } = this.props;

    const rest = {
      history,
      token,
      roles,
      isRender,
    };
    if (!isRender) return null;

    return (
      <Switch>
        {mainRoutes(rest)}
        {productRoutes(rest)}
        {authorizationRoutes(rest)}
        {administrationRoutes(rest)}
        {underwritingRoutes(rest)}
        {additionalRoutes(rest)}
        {notFoundRoute(rest)}
      </Switch>
    );
  }
}

Router.defaultProps = defaultProps;
Router.propTypes = propTypes;

const mapStateToProps = ({
  shared: {
    appRender,
  },
  authReducer: {
    roles,
    isLoading,
    token,
  },
  helperReducer: {
    needUpdate,
  },
}) => ({
  isRender: appRender,
  roles,
  isLoading,
  token,
  needUpdate,
});

export default withCustomRouter(
  connect(mapStateToProps, {
    appRender: appCanRender,
    getUser: getCurrentUser,
    unlockDocuments: unlockDocumentsUnderwriting,
    saveCheckedContracts: saveCheckedContract,
    getVersion: getVersionDp,
    getPartners: getAccessPartners,
    getEvents: getEventsCount,
    pushHistoryRouteAction: pushHistoryRoute,
    checkManual: checkExistManual,
    getIpAddress: getIp,
    getVersionHash: getBuildVersion,
    checkSession: checkAuthSession,
    setAndClearQueryFromUrlParams: saveAndClearQueryFromUrlParams,
    createWindowUuid: generateWindowUuid,
  })(Router),
);
