import React from 'react';
import {
  IPeditor,
  CustomCheckBox,
  RequiredLabel,
  PublicApiKeysPopup,
  MainBtn,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { Form } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { Collapse } from 'reactstrap';
import { Formik } from 'formik';
import { getFormattedDate } from 'helpers';
import { REGEXP, LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import Schema from './schema';
import './styles.scss';

const b = block('creating-or-updating-public-api-form');

const { IP_ADDRESS } = REGEXP;
const {
  REGENERATE_SECRET_KEY,
  API_KEY,
  DATE_UPDATE_SECRET_KEY,
  ACCESS_WITHOUT_RESTRICTION_IP_ADDRESS,
  AVAILABLE_API,
  USER,
  PARTNER,
  SUBDIVISION,
  SELECT_USER,
  SELECT_PARTNER,
  SELECT_BRANCH,
  COMMENT,
  SELECT_AVAILABLE_API,
  ENTER_COMMENT,
  STATUS,
  SAVE_WORD,
  DESCRIPTION_WORD,
  ENTER_DESCRIPTION,
} = LANG_DICTIONARY;

const propTypes = {
  ipAddress: PropTypes.arrayOf(PropTypes.object).isRequired,
  removeIpAddress: PropTypes.func.isRequired,
  addIpAddress: PropTypes.func.isRequired,
  changeIpAddress: PropTypes.func.isRequired,
  changeValueByName: PropTypes.func.isRequired,
  isAccessNotRestriction: PropTypes.bool.isRequired,
  id: PropTypes.number.isRequired,
  apiKey: PropTypes.string.isRequired,
  secretKey: PropTypes.string.isRequired,
  isActive: PropTypes.bool.isRequired,
  comment: PropTypes.string.isRequired,
  userId: PropTypes.number.isRequired,
  branchId: PropTypes.number.isRequired,
  partnerId: PropTypes.number.isRequired,
  publicApi: PropTypes.arrayOf(PropTypes.number).isRequired,
  secretKeyUpdateDate: PropTypes.string.isRequired,
  apiSelectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.arrayOf(PropTypes.object).isRequired,
  getBranches: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  loaders: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  isOpenPopup: PropTypes.bool.isRequired,
  setStatusPopup: PropTypes.func.isRequired,
  updateId: PropTypes.number.isRequired,
  regenerateSecretKey: PropTypes.func.isRequired,
  action: PropTypes.string.isRequired,
};

const CreatingOrUpdatingPublicApiForm = ({
  ipAddress,
  removeIpAddress,
  addIpAddress,
  changeIpAddress,
  changeValueByName,
  isAccessNotRestriction,
  id,
  apiKey,
  secretKey,
  isActive,
  comment,
  userId,
  branchId,
  partnerId,
  publicApi,
  secretKeyUpdateDate,
  apiSelectData,
  options,
  getBranches,
  getUsers,
  loaders,
  create,
  isOpenPopup,
  setStatusPopup,
  updateId,
  regenerateSecretKey,
  action,
}) => {
  const handleChangePartner = (onChange, e) => {
    onChange(e);
    onChange({ target: { value: '', name: 'branchId' } });
    onChange({ target: { value: '', name: 'userId' } });
    const { target: { value } } = e;
    getBranches(value);
  };

  const handleChangeBranch = (onChange, e, partner) => {
    onChange(e);
    onChange({ target: { value: '', name: 'userId' } });
    const { target: { value } } = e;
    getUsers({ partnerId: partner, branchId: value });
  };

  const getBody = (values) => {
    const addresses = isAccessNotRestriction
      ? undefined
      : ipAddress.reduce((acc, item) => {
        const ip = item.ip ? `${item.ip}` : '';
        const correctIp = !acc ? ip : `,${ip}`;

        return `${acc}${correctIp}`;
      }, '');

    return {
      ipAddress: addresses,
      isActive: values.isActive,
      comment: values.comment || undefined,
      userId: values.userId,
      partnerId: values.partnerId,
      branchId: values.branchId,
      publicApi: values.publicApi,
      action: values.action,
    };
  };

  const handleSubmitApi = (values) => {
    const isErrorIpAddress = isAccessNotRestriction
      ? false
      : ipAddress.some((item) => !IP_ADDRESS.test(item.ip));

    if (isErrorIpAddress) return null;

    const body = getBody(values);
    create(body, updateId);

    return null;
  };

  return (
    <div className={b()}>
      <PublicApiKeysPopup
        isOpen={isOpenPopup}
        secretKey={secretKey}
        apiKey={apiKey}
        handleClose={() => setStatusPopup(false)}
      />
      <RequiredLabel />
      <section className={b('section')}>
        <div className={b('section-content')}>
          <p className={b('title')}>{API_KEY}</p>
          <p>{apiKey || '-'}</p>
        </div>
      </section>
      <section className={b('section')}>
        <div className={b('section-content')}>
          <p className={b('title')}>{DATE_UPDATE_SECRET_KEY}</p>
          <p>{secretKeyUpdateDate ? getFormattedDate(secretKeyUpdateDate) : '-'}</p>
        </div>
      </section>
      <Formik
        onSubmit={handleSubmitApi}
        initialValues={{
          id,
          apiKey,
          isActive,
          comment,
          userId,
          branchId,
          partnerId,
          publicApi,
          action,
        }}
        enableReinitialize
        validationSchema={Schema}
        validateOnChange
      >
        {({
          values,
          errors,
          touched,
          handleChange,
          handleBlur,
          handleSubmit,
        }) => (
          <Form>
            <div className={b('form-content')}>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{ACCESS_WITHOUT_RESTRICTION_IP_ADDRESS}</p>
                  <CustomCheckBox
                    checked={isAccessNotRestriction}
                    name="isAccessNotRestriction"
                    onChange={(name, checked) => changeValueByName(name, !checked)}
                  />
                </div>
                <Collapse isOpen={!isAccessNotRestriction}>
                  <IPeditor
                    ipAddresses={ipAddress}
                    onRemoveIp={removeIpAddress}
                    onAddIp={addIpAddress}
                    onChangeIp={changeIpAddress}
                  />
                </Collapse>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{AVAILABLE_API}</p>
                  <SelectInput
                    values={values}
                    options={apiSelectData}
                    name="publicApi"
                    onChange={handleChange}
                    prefixWrapperClass={b('select').toString()}
                    errors={errors}
                    multiple
                    onBlur={handleBlur}
                    placeholder={SELECT_AVAILABLE_API}
                    touched={touched}
                    required
                    isShowLoading
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{PARTNER}</p>
                  <SelectInput
                    values={values}
                    options={options.partners}
                    name="partnerId"
                    onChange={(e) => handleChangePartner(handleChange, e)}
                    prefixWrapperClass={b('select').toString()}
                    loading={loaders.loadingPartners}
                    placeholder={SELECT_PARTNER}
                    errors={errors}
                    touched={touched}
                    onBlur={handleBlur}
                    isShowLoading
                    required
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{SUBDIVISION}</p>
                  <SelectInput
                    values={values}
                    options={options.branches}
                    name="branchId"
                    disabled={!values.partnerId}
                    onChange={(e) => handleChangeBranch(handleChange, e, values.partnerId)}
                    prefixWrapperClass={b('select').toString()}
                    errors={errors}
                    touched={touched}
                    loading={loaders.loadingBranches}
                    placeholder={SELECT_BRANCH}
                    onBlur={handleBlur}
                    isShowLoading
                    required
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{USER}</p>
                  <SelectInput
                    values={values}
                    options={options.users}
                    name="userId"
                    onChange={handleChange}
                    disabled={!values.branchId}
                    prefixWrapperClass={b('select').toString()}
                    loading={loaders.loadingUsers}
                    placeholder={SELECT_USER}
                    onBlur={handleBlur}
                    errors={errors}
                    touched={touched}
                    isShowLoading
                    required
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{DESCRIPTION_WORD}</p>
                  <TextInput
                    name="action"
                    values={values}
                    errors={errors}
                    onChange={handleChange}
                    prefixWrapperClass={b('input-text').toString()}
                    placeholder={ENTER_DESCRIPTION}
                    touched={touched}
                    onBlur={handleBlur}
                    required
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{COMMENT}</p>
                  <TextInput
                    name="comment"
                    values={values}
                    errors={errors}
                    onChange={handleChange}
                    prefixWrapperClass={b('input-text').toString()}
                    placeholder={ENTER_COMMENT}
                    touched={touched}
                    onBlur={handleBlur}
                  />
                </div>
              </section>
              <section className={b('section')}>
                <div className={b('section-content')}>
                  <p className={b('title')}>{STATUS}</p>
                  <CustomCheckBox
                    name="isActive"
                    checked={values.isActive}
                    onChange={(name, checked) => {
                      handleChange({ target: { name, value: !checked } });
                    }}
                  />
                </div>
              </section>
            </div>
            <div className={b('button-interface')}>
              {Boolean(updateId) && (
              <MainBtn
                type="button"
                text={REGENERATE_SECRET_KEY}
                className={b('save').toString()}
                onClick={() => regenerateSecretKey(updateId)}
              />
              )}
              <MainBtn
                type="submit"
                text={SAVE_WORD}
                onClick={handleSubmit}
                className={b('save').toString()}
              />
            </div>
          </Form>
        )}
      </Formik>
    </div>
  );
};

CreatingOrUpdatingPublicApiForm.propTypes = propTypes;
export default CreatingOrUpdatingPublicApiForm;
