import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';

const propTypes = {
  contractFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  expectedFiles: PropTypes.arrayOf(PropTypes.object).isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const { FILES } = LANG_DICTIONARY;
const FileCount = ({ contractFiles, expectedFiles, className }) => (
  Boolean(expectedFiles.length)
    && (
    <div className={className}>
      {`${FILES} ${contractFiles.length} / ${expectedFiles.length - 1}`}
    </div>
    ));

FileCount.propTypes = propTypes;
FileCount.defaultProps = defaultProps;
export default FileCount;
