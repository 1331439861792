import React, { useEffect, useMemo } from 'react';
import { block } from 'bem-cn';
import {
  LANG_DICTIONARY,
  ROUTES,
} from 'consts';
import {
  MainBtn,
  InfoTableConstructorIsz,
  ConstructorTableControl,
  WarningGuaranteeLevel,
  DescriptionAssetsPopup,
  WarningMessagePopup,
  DpPolicyConstructorExecutionPopup,
  DpPolicyConstructorExecutionSuccessPopup,
} from 'components';
import {
  selectBasketFiltering,
  selectBasketSorting,
  selectIsMinInsuranceSumClickedTables,
  selectSettingsProduct,
} from 'redux/basketConstructor/selectors';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { useActions } from 'hooks/useActions';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import { useEffectRedirect } from 'hooks/useEffectRedirect';
import { Loader, Segment, Dimmer } from 'semantic-ui-react';
import ExcelIcon from 'assets/images/excel.png';
import { CONSTRUCTOR_URLS } from '../_constants';
import { KnowMinInsuranceAmountButton } from '../_components/KnowMinInsuranceAmountButton';
import { ConstructorCreateButtonText } from '../_components/ConstructorCreateButtonText';
import './styles.scss';

const {
  READY_TO_ORDER_INDIVIDUAL_BASKETS,
  BACK_TO_CONSTRUCTOR,
  ORDER_READY_BASKET,
  DOWNLOADING,
  CLEAR_FILTER_SORT,
  CHANGE_BASKET,
  DATA_GETTING,
  CONTINUE_REGISTRATION,
  SEND_TO_DP_POLICY,
  GET_ANOTHER_CONSTRUCTOR_BASKET,
} = LANG_DICTIONARY;
const b = block('constructor-isz-prepared-form');

const propTypes = {
  baskets: PropTypes.arrayOf(PropTypes.object).isRequired,
  history: PropTypes.object.isRequired,
  productId: PropTypes.string.isRequired,
  settings: PropTypes.object.isRequired,
  generateReport: PropTypes.func.isRequired,
  isScrollLoading: PropTypes.bool.isRequired,
  tableControl: PropTypes.object.isRequired,
  selected: PropTypes.object,
  isTableLoading: PropTypes.bool.isRequired,
  minInsuranceSum: PropTypes.number,
  availableQuantityRequests: PropTypes.number,
  onToggleOpenChildBasket: PropTypes.func.isRequired,
  onSelectCalculatedPersonBasket: PropTypes.func.isRequired,
  onClickClearSortAndFiltering: PropTypes.func.isRequired,
  onClickGetMinInsuranceSum: PropTypes.func.isRequired,
  onClickCopyBasket: PropTypes.func.isRequired,
  onUpdateTable: PropTypes.func.isRequired,
  onEndScrollTable: PropTypes.func.isRequired,
  openChildIds: PropTypes.object.isRequired,
  childSelectedIds: PropTypes.object.isRequired,
  onSelectChildCalculatedPersonBasket: PropTypes.func.isRequired,
  warningPopupMessage: PropTypes.string.isRequired,
  clearWarningPopupMessage: PropTypes.func.isRequired,
  onClickExecute: PropTypes.func.isRequired,
  isGuaranteeLevelWarning: PropTypes.bool.isRequired,
  closeGuaranteeLevelWarningPopup: PropTypes.func.isRequired,
  isExecutionLoading: PropTypes.bool.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.object),
  isEditBasket: PropTypes.bool,
  guaranteeLevelWarningAction: PropTypes.func.isRequired,
  onClickPreExecuteDpPolicy: PropTypes.func.isRequired,
  isExecutionDpPolicyLoading: PropTypes.bool.isRequired,
  isPreExecutionDpPolicyLoading: PropTypes.bool.isRequired,
  isExecutionDpPolicyPopup: PropTypes.bool.isRequired,
  productForm: PropTypes.object.isRequired,
  isExecutionDpPolicySuccessPopup: PropTypes.bool.isRequired,
  closeExecutionDpPolicyPopup: PropTypes.func.isRequired,
  onClickExecutionDpPolicy: PropTypes.func.isRequired,
  dpPolicyExecutionUser: PropTypes.object,
};

const defaultProps = {
  selected: null,
  minInsuranceSum: null,
  availableQuantityRequests: null,
  productOptions: [],
  isEditBasket: false,
  dpPolicyExecutionUser: {},
};

const CalculatedPersonalBasketsForm = ({
  baskets,
  history,
  productId,
  settings,
  generateReport,
  isScrollLoading,
  openChildIds,
  tableControl,
  selected,
  isTableLoading,
  minInsuranceSum,
  availableQuantityRequests,
  onEndScrollTable,
  onToggleOpenChildBasket,
  onSelectCalculatedPersonBasket,
  onClickClearSortAndFiltering,
  onClickGetMinInsuranceSum,
  onClickCopyBasket,
  onUpdateTable,
  childSelectedIds,
  onSelectChildCalculatedPersonBasket,
  warningPopupMessage,
  clearWarningPopupMessage,
  onClickExecute,
  isGuaranteeLevelWarning,
  closeGuaranteeLevelWarningPopup,
  isExecutionLoading,
  isExecutionDpPolicyLoading,
  productOptions,
  isEditBasket,
  guaranteeLevelWarningAction,
  onClickPreExecuteDpPolicy,
  closeExecutionDpPolicyPopup,
  isPreExecutionDpPolicyLoading,
  isExecutionDpPolicyPopup,
  isExecutionDpPolicySuccessPopup,
  productForm,
  onClickExecutionDpPolicy,
  dpPolicyExecutionUser,
}) => {
  const {
    getHintsForBaskets,
    setFilterForBaskets,
    setBasketSort,
    setInitStateSelect,
    setProductId,
    closeMinInsuranceSumTable,
    recalculateCoupon,
  } = useActions(basketConstructorActions);

  useEffect(() => {
    setProductId(productId);
  }, []);

  const isMinInsuranceSumClicked = useSelector(selectIsMinInsuranceSumClickedTables);
  const productSettings = useSelector(selectSettingsProduct);
  const { hints, filter } = useSelector(selectBasketFiltering);
  const sorting = useSelector(selectBasketSorting);

  useEffectRedirect(() => {
    setInitStateSelect();
  }, CONSTRUCTOR_URLS);
  // eslint-disable-next-line arrow-body-style
  const checkIsGuaranteeLevelsDontSatisfyRestrictions = (guaranteeLevel) => {
    return !(settings.guaranteeLevelRestrictions[0] <= guaranteeLevel &&
      guaranteeLevel <= settings.guaranteeLevelRestrictions[1]);
  };

  const selectedMainId = selected && selected.id;
  const isDisabledExecutionButton = !selectedMainId ||
    checkIsGuaranteeLevelsDontSatisfyRestrictions(tableControl.guaranteeLevel) ||
      isTableLoading || !selected?.isActive;

  const isDisabledCopyBasket = useMemo(() => availableQuantityRequests === 0 || (!selectedMainId &&
      !Object.values(childSelectedIds).filter(Boolean).length),
  [selectedMainId, childSelectedIds]);

  const isChildAutocall = Object.values(childSelectedIds).some((child) => {
    if (child && child.isAutocall) {
      return true;
    }

    return false;
  });
  const isDisabledCopyBasketAutocall = isChildAutocall && (selected === null);

  const handleChangeFilter = ({ target: { name, value } }, { isDisableHints }) => {
    if (!isDisableHints) {
      getHintsForBaskets(name, value);
    }
  };

  const getPropsSortAndFilteringInterface = (name, label, postfix = '', transform = (value) => value) => ({
    name,
    label,
    hints: hints[name],
    filterValue: filter[name],
    sortValue: sorting[`${name}${postfix}`] && sorting[`${name}${postfix}`].type,
    onChangeFilter: handleChangeFilter,
    onConfirmFilter: setFilterForBaskets,
    onFocusFilter: handleChangeFilter,
    isResultSearchDefaultOpen: true,
    onChangeSort: setBasketSort,
    postfixSortFieldName: postfix,
    transform,
  });

  const isDisabledDpPolicyExecutionButton = isPreExecutionDpPolicyLoading ||
    isDisabledExecutionButton;

  const isShowDpPolicyExecutionButton = settings.isDpPolicySending &&
    settings.isIssueBasket;

  const isExecutionLoadingGuaranteeLevel = isExecutionLoading ||
    isExecutionDpPolicyLoading ||
    isPreExecutionDpPolicyLoading;

  const isShowBackToConstructorButton = !productSettings.isHideRegularBaskets;

  return (
    <div className={b()}>
      <div className={b('content')}>
        <DescriptionAssetsPopup chartOnly />
        <WarningMessagePopup
          message={warningPopupMessage}
          onClose={clearWarningPopupMessage}
        />
        <DpPolicyConstructorExecutionPopup
          isOpen={isExecutionDpPolicyPopup}
          isSubmitLoading={isExecutionDpPolicyLoading}
          productForm={productForm}
          onClose={closeExecutionDpPolicyPopup}
          onSubmit={onClickExecutionDpPolicy}
        />
        <DpPolicyConstructorExecutionSuccessPopup
          isOpen={isExecutionDpPolicySuccessPopup}
          onClose={closeExecutionDpPolicyPopup}
          user={dpPolicyExecutionUser}
        />
        <WarningGuaranteeLevel
          isOpen={isGuaranteeLevelWarning}
          handleClose={closeGuaranteeLevelWarningPopup}
          handleContinue={guaranteeLevelWarningAction}
          isExecutionLoading={isExecutionLoadingGuaranteeLevel}
        />
        <div className={b('guarantee')}>
          <button
            type="button"
            className={b('excel-btn')}
            onClick={generateReport}
          >
            <img src={ExcelIcon} alt="excel" />
          </button>
        </div>
        <ConstructorTableControl
          isCalculatedIndividualBaskets
          productId={productId}
          onUpdate={onUpdateTable}
          guaranteeLevelRestrictions={settings.guaranteeLevelRestrictions}
        />
        <Segment>
          <Dimmer active={isTableLoading} inverted>
            <Loader inverted content={DOWNLOADING} />
          </Dimmer>
          <div className={b('table')}>
            <div className={b('table-title')}>
              {READY_TO_ORDER_INDIVIDUAL_BASKETS}
              {isScrollLoading && (
              <div className={b('scroll-loading')}>
                <Loader inline active size="tiny" />
                <p>{`${DATA_GETTING}...`}</p>
              </div>
              )}
            </div>
            <div className={b('content-table')}>
              <InfoTableConstructorIsz
                isCalculatedIndividualBaskets
                data={baskets}
                isSortAndFilterInterface
                openChildIds={openChildIds}
                optionCode={tableControl.generalOptionCode}
                selected={selected}
                childSelectedIds={childSelectedIds}
                onToggleOpenChildBasket={onToggleOpenChildBasket}
                onSelectItem={onSelectCalculatedPersonBasket}
                onSelectChildItem={onSelectChildCalculatedPersonBasket}
                onEndScrollTable={onEndScrollTable}
                onUpdateTable={onUpdateTable}
                productOptions={productOptions}
                getPropsSortAndFilteringInterface={
                  getPropsSortAndFilteringInterface
                }
                productId={productId}
                currencyCode={tableControl.currencyCode}
                recalculateCoupon={recalculateCoupon}
                filters={filter}
                sorts={sorting}
              />
            </div>
          </div>
        </Segment>
        <div
          className={b('footer')}
        >
          <div className={b('footer-buttons')}>
            {isShowBackToConstructorButton && (
            <MainBtn
              text={BACK_TO_CONSTRUCTOR}
              onClick={() => history.push(ROUTES.constructorIsz({ id: productId }))}
            />
            )}
            <MainBtn
              text={CLEAR_FILTER_SORT}
              onClick={onClickClearSortAndFiltering}
            />
            <MainBtn
              text={(
                <ConstructorCreateButtonText
                  availableQuantityRequests={availableQuantityRequests}
                  text={GET_ANOTHER_CONSTRUCTOR_BASKET}
                />
              )}
              onClick={() => history.push(ROUTES.constructorIszCreating({ id: productId }))}
              disabled={availableQuantityRequests === 0}
            />
            <MainBtn
              text={(
                <ConstructorCreateButtonText
                  availableQuantityRequests={availableQuantityRequests}
                  text={CHANGE_BASKET}
                />
              )}
              onClick={onClickCopyBasket}
              disabled={isDisabledCopyBasket || isDisabledCopyBasketAutocall}
            />
            <KnowMinInsuranceAmountButton
              minInsuranceAmountData={minInsuranceSum}
              isMinInsuranceSumClicked={isMinInsuranceSumClicked}
              onClick={onClickGetMinInsuranceSum}
              currencyCode={tableControl.currencyCode}
              disabled={isDisabledExecutionButton}
              handleCloseMinInsuranceTable={closeMinInsuranceSumTable}
              className={b('min-pay-block')}
            />
            {isShowDpPolicyExecutionButton && (
              <MainBtn
                text={SEND_TO_DP_POLICY}
                onClick={() => onClickPreExecuteDpPolicy(false)}
                disabled={isDisabledDpPolicyExecutionButton}
                isLoading={isPreExecutionDpPolicyLoading}
              />
            )}
            {settings.isIssueBasket && (
              <MainBtn
                text={isEditBasket ? CONTINUE_REGISTRATION : ORDER_READY_BASKET}
                onClick={() => onClickExecute()}
                disabled={isDisabledExecutionButton || isExecutionLoading}
                isLoading={isExecutionLoading}
              />
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

CalculatedPersonalBasketsForm.propTypes = propTypes;
CalculatedPersonalBasketsForm.defaultProps = defaultProps;
export default CalculatedPersonalBasketsForm;
