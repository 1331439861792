import React from 'react';
import {
  Header,
  Modal,
} from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { SetPasswordForm } from 'components';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('temporary-password-form');

const {
  NEW_PASSWORD,
  CONFIRM_NEW_PASSWORD,
  CHANGE_PASSWORD,
  SET_NEW_PASSWORD,
  SAVE_WORD,
} = LANG_DICTIONARY;

const propTypes = {
  resetPassword: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const TemporaryPassword = ({ resetPassword, history }) => {
  const handleSubmit = ({ userPassword }, { setSubmitting }) => {
    setSubmitting(false);
    resetPassword({ userPassword, history });
  };

  return (
    <Modal open className={b()}>
      <Modal.Header>{CHANGE_PASSWORD}</Modal.Header>
      <Modal.Content image>
        <Modal.Description>
          <Header>{SET_NEW_PASSWORD}</Header>
          <div className={b('form')}>
            <SetPasswordForm
              handleSubmit={handleSubmit}
              titles={{
                password: NEW_PASSWORD,
                confirmPassword: CONFIRM_NEW_PASSWORD,
              }}
              textBtn={SAVE_WORD}
            />
          </div>
        </Modal.Description>
      </Modal.Content>
    </Modal>
  );
};

TemporaryPassword.propTypes = propTypes;
export default TemporaryPassword;
