import { getStartOfDayDate, initStateLivingAddress, checkLivingAddress } from 'helpers';

export default (initValues, data) => {
  const { documents, address, contacts } = initValues;

  return data.beneficiary.map((item) => ({
    ...initValues,
    formData: {
      firstName: item.firstName || '',
      lastName: item.lastName || '',
      middleName: item.patronymic || '',
      birthDate: item.birthDate
        ? getStartOfDayDate(item.birthDate) : null,
      gender: item.gender || null,
      relationships: item.relationship || null,
      insuredSum: item.insuredSum || '',
      birthPlace: item.birthPlace || '',
    },
    formDataDocuments: {
      documentType: item.beneficiaryDocument.documentType || 1,
      serial: item.beneficiaryDocument.serial || '',
      passportNumber: item.beneficiaryDocument.passportNumber || '',
      issuedBy: item.beneficiaryDocument.issuedBy || '',
      kp: item.beneficiaryDocument.kp || '',
      inn: item.beneficiaryDocument.inn || '',
      snils: item.beneficiaryDocument.snils || '',
      when: item.beneficiaryDocument.when
        ? getStartOfDayDate(item.beneficiaryDocument.when) : null,
      validUntil: item.beneficiaryDocument.validUntil
        ? getStartOfDayDate(item.beneficiaryDocument.validUntil) : null,
      issuedByAccepted: false,
    },
    documents: item.beneficiaryDocument.passportNumber
      ? true : documents,
    formDataAddress: {
      addressFias: item.beneficiaryAddress.addressFias || '',
      addressFiasLiving: item.beneficiaryAddress.addressFiasLiving || '',
      livingAddressIsSame: checkLivingAddress(item.beneficiaryAddress),
      apartment: item.beneficiaryAddress.apartment || '',
      apartmentLiving: item.beneficiaryAddress.apartmentLiving || '',
      area: item.beneficiaryAddress.area || '',
      areaLiving: item.beneficiaryAddress.areaLiving || '',
      city: item.beneficiaryAddress.city || '',
      cityLiving: item.beneficiaryAddress.cityLiving || '',
      country: item.beneficiaryAddress.country || '',
      countryLiving: item.beneficiaryAddress.countryLiving || '',
      house: item.beneficiaryAddress.house || '',
      houseLiving: item.beneficiaryAddress.houseLiving || '',
      houseNumber: item.beneficiaryAddress.houseNumber || '',
      houseNumberLiving: item.beneficiaryAddress.houseNumberLiving || '',
      index: item.beneficiaryAddress.index || '',
      indexLiving: item.beneficiaryAddress.indexLiving || '',
      isManualEnter: Boolean(item.beneficiaryAddress.country) && !item.beneficiaryAddress.fiasId,
      isManualEnterLiving: !checkLivingAddress(item.beneficiaryAddress)
        && !item.beneficiaryAddress.fiasIdLiving,
      republic: item.beneficiaryAddress.republic || item.beneficiaryAddress.city,
      republicLiving: item.beneficiaryAddress.republicLiving || item.beneficiaryAddress.cityLiving,
      street: item.beneficiaryAddress.street || '',
      streetLiving: item.beneficiaryAddress.streetLiving || '',
      fiasId: item.beneficiaryAddress.fiasId || '',
      fiasIdLiving: item.beneficiaryAddress.fiasIdLiving || '',
      kladId: item.beneficiaryAddress.kladId || '',
      kladIdLiving: item.beneficiaryAddress.kladIdLiving || '',
      ...(checkLivingAddress(item.beneficiaryAddress) && initStateLivingAddress),
    },
    address: item.beneficiaryAddress.country ? true : address,
    formDataContacts: {
      contactPhone: item.beneficiaryContact.contactPhone || '',
      email: item.beneficiaryContact.email || '',
      homePhone: item.beneficiaryContact.homePhone || '',
      mobilePhone: item.beneficiaryContact.mobilePhone || '',
      workPhone: item.beneficiaryContact.workPhone || '',
    },
    contacts: item.beneficiaryContact.mobilePhone ? true : contacts,
  }));
};
