import React from 'react';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { PaginationPanel, DocsTransferMenu, FlexTableSentMessage } from 'components';
import {
  Button,
  Input,
  Loader,
} from 'semantic-ui-react';
import {
  setInboxMessagesSearchValues,
  getSentMessages,
} from 'redux/rootActions';
import { LANG_DICTIONARY, PAGINATION_DEFAULT_INDEX } from 'consts';
import { usePagination } from 'hooks/usePagination';

const {
  SEARCH,
  RECIPIENT_FIO,
  RECIPIENT_PARTNER,
} = LANG_DICTIONARY;

const propTypes = {
  inboxUnreadMessagesCount: PropTypes.number,
  isLoadingMessages: PropTypes.bool,
  sentData: PropTypes.array,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  getSentMessagesData: PropTypes.func,
  setValues: PropTypes.func,
  recipientFio: PropTypes.string,
  recipientPartner: PropTypes.string,
  history: PropTypes.object,
};

const defaultProps = {
  inboxUnreadMessagesCount: 0,
  isLoadingMessages: false,
  sentData: [],
  totalPages: 1,
  currentPage: 1,
  getSentMessagesData: () => {},
  setValues: () => {},
  recipientFio: '',
  recipientPartner: '',
  history: {},
};

const b = block('docs-transfer-messages');

const DocsTransferSent = ({
  inboxUnreadMessagesCount,
  isLoadingMessages,
  sentData,
  totalPages,
  currentPage,
  getSentMessagesData,
  setValues,
  recipientFio,
  recipientPartner,
  history,
}) => {
  const getQuery = () => {
    const query = {};
    recipientFio && Object.assign(query, { userFullName: recipientFio });
    recipientPartner && Object.assign(query, { partnerName: recipientPartner });

    return query;
  };

  const { handleUpPage, handleDownPage } = usePagination({
    totalPages,
    currentPage,
    func: getSentMessagesData,
    query: getQuery(),
  });

  const handleSearch = () => {
    const query = getQuery();

    getSentMessagesData(PAGINATION_DEFAULT_INDEX, query);
  };

  return (
    <DocsTransferMenu inboxUnreadMessagesCount={inboxUnreadMessagesCount} history={history}>
      <div className={b()}>
        <div className={b('search')}>
          <Input
            className={b('field').toString()}
            value={recipientFio}
            name="recipientFio"
            placeholder={RECIPIENT_FIO}
            onChange={(e) => setValues('recipientFio', e.target.value)}
          />
          <Input
            className={b('field').toString()}
            value={recipientPartner}
            name="recipientPartner"
            placeholder={RECIPIENT_PARTNER}
            onChange={(e) => setValues('recipientPartner', e.target.value)}
          />
          <Button
            className={b('btn').toString()}
            onClick={handleSearch}
          >
            {SEARCH}
          </Button>
        </div>
        <Loader
          inline
          active={isLoadingMessages}
          size="big"
          className={b('loader').toString()}
        />
        <FlexTableSentMessage
          data={sentData}
          className={b().toString()}
          isLoading={isLoadingMessages}
        />
        <PaginationPanel
          active={currentPage}
          total={totalPages}
          handleUpPage={handleUpPage}
          handleDownPage={handleDownPage}
        />
      </div>
    </DocsTransferMenu>
  );
};

const mapStateToProps = ({
  docsTransfer,
}) => ({
  ...docsTransfer,
});

const mapDispatchToProps = {
  getSentMessagesData: getSentMessages,
  setValues: setInboxMessagesSearchValues,
};

DocsTransferSent.propTypes = propTypes;
DocsTransferSent.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocsTransferSent));
