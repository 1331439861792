import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import MessageIcon from 'assets/images/message-icon.png';
import './styles.scss';

const b = block('events-form-icon');

const propTypes = {
  total: PropTypes.number,
  onClick: PropTypes.func,
};

const defaultProps = {
  total: 0,
  onClick: () => null,
};
const EventsFormBtn = ({
  total,
  onClick,
}) => (
  <button
    type="button"
    className={b()}
    onClick={onClick}
  >
    <img src={MessageIcon} alt="message-icon" />
    {total !== 0 && <p className={b('count')}>{total}</p>}
  </button>
);

const mapStateToProps = ({
  events: {
    total,
  },
}) => ({
  total,
});

EventsFormBtn.propTypes = propTypes;
EventsFormBtn.defaultProps = defaultProps;
export default connect(mapStateToProps)(EventsFormBtn);
