import React from 'react';
import PropTypes from 'prop-types';
import { Form, Search, Icon } from 'semantic-ui-react';
import { GetFormError, Tooltip } from 'components';
import { getFormInputClass } from 'helpers';
import Scroll from 'react-scroll';
import SearchInputField from './inputField';

const defaultProps = {
  values: {},
  errors: null,
  touched: null,
  name: null,
  propertyName: null,
  label: null,
  fieldNumber: null,
  width: '',
  inputWidth: '',
  wrapClass: '',
  tooltipContent: null,
  loading: false,
  onFocus: () => {},
  placeholder: '',
  onResultSelect: () => {},
  results: [],
  disabled: false,
  onSearchChange: () => {},
  className: '',
  value: '',
  inputProps: {},
  options: [],
  onChange: () => {},
  onBlur: () => {},
  required: false,
  mask: null,
  prefixScroll: '',
};

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  propertyName: PropTypes.string,
  label: PropTypes.string,
  options: PropTypes.arrayOf(PropTypes.object),
  fieldNumber: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  width: PropTypes.string,
  inputWidth: PropTypes.string,
  wrapClass: PropTypes.string,
  tooltipContent: PropTypes.shape({
    text: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }),
  loading: PropTypes.bool,
  onFocus: PropTypes.func,
  placeholder: PropTypes.string,
  onResultSelect: PropTypes.func,
  results: PropTypes.arrayOf(PropTypes.object),
  disabled: PropTypes.bool,
  onSearchChange: PropTypes.func,
  className: PropTypes.string,
  value: PropTypes.string,
  inputProps: PropTypes.object,
  required: PropTypes.bool,
  prefixScroll: PropTypes.string,
  mask: PropTypes.string,
};

const SearchInput = ({
  name,
  propertyName,
  label,
  fieldNumber,
  onChange,
  onBlur,
  tooltipContent,
  width,
  inputWidth,
  wrapClass,
  loading,
  onFocus,
  placeholder,
  onResultSelect,
  disabled,
  onSearchChange,
  results,
  className,
  inputProps,
  value,
  required,
  prefixScroll,
  mask,
}) => {
  const rebuildInputProps = { ...inputProps };
  delete rebuildInputProps.setFieldTouched;

  const searchProps = {
    name,
    loading,
    onFocus,
    onBlur,
    placeholder,
    onResultSelect,
    disabled,
    onSearchChange,
    results,
    value,
    className,
    onChange,
    ...rebuildInputProps,
    input: {
      ...(rebuildInputProps.input || {}),
      ...(!!mask && {
        mask,
        as: SearchInputField,
      }),
    },
  };
  const { errors, touched } = inputProps;

  return (
    <Scroll.Element name={`scroll${name}${prefixScroll}`}>
      <Form.Field
        className={getFormInputClass({
          error: errors ? errors[name] : '',
          touched: touched ? touched[propertyName || name] : false,
          width,
          inputWidth,
          wrapClass,
        })}
        data-tip="helperTip"
        data-for={name}
      >
        { fieldNumber && <div className="fieldNumber">{ fieldNumber }</div> }
        <div className="fieldWrap">
          { label && <div className="label">{ label }</div> }
          <Search
            {...searchProps}
          />
          {required && <Icon name="write" className="icon-write-select" />}
        </div>
        <GetFormError
          errors={errors}
          touched={touched}
          itemName={name}
          propertyName={propertyName}
          value={value}
        />
        { tooltipContent && <Tooltip data={tooltipContent} tooltipName={name} /> }
      </Form.Field>
    </Scroll.Element>
  );
};

SearchInput.defaultProps = defaultProps;
SearchInput.propTypes = propTypes;

export default SearchInput;
