import React from 'react';
import PropTypes from 'prop-types';

const DefaultProps = {
  isActive: false,
};

const propTypes = {
  isActive: PropTypes.bool,
};

const FavoritesIcon = (props) => {
  const { isActive } = props;

  return (
    <svg width="38" height="29" viewBox="0 0 38 29" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path
        d="M19 0L23.2658 11.0557H37.0701L25.9022 17.8885L30.1679 28.9443L19 22.1115L7.83208 28.9443L12.0978 17.8885L0.929926 11.0557H14.7342L19 0Z"
        fill={isActive ? '#F9BB1D' : '#C4C4C4'}
      />
    </svg>
  );
};

FavoritesIcon.defaultProps = DefaultProps;
FavoritesIcon.propTypes = propTypes;

export default FavoritesIcon;
