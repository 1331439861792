import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { HelpMessage } from 'components';
import './styles.scss';

const b = block('basket-main-strategy-label');

const { MAIN_WORD } = LANG_DICTIONARY;

export default () => (
  <div className={b()}>
    <p className={b('label')}>{MAIN_WORD}</p>
    <HelpMessage message="Описание" />
  </div>
);
