import React from 'react';
import { block } from 'bem-cn';
import { HelpMessage } from 'components';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('strategy-filed-row');

const propTypes = {
  label: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired,
  helpMessage: PropTypes.string,
  isShow: PropTypes.bool,
  className: PropTypes.string,
};

const defaultProps = {
  helpMessage: '',
  isShow: true,
  className: '',
};

const FieldRow = ({
  label,
  children,
  helpMessage,
  isShow,
  className,
}) => (
  <Collapse isOpen={isShow}>
    <div className={b.mix(className)}>
      <p className={b('label')}>{label}</p>
      {children}
      <div className={b('help-message')}>
        <HelpMessage message={helpMessage} />
      </div>
    </div>
  </Collapse>
);

FieldRow.defaultProps = defaultProps;
FieldRow.propTypes = propTypes;

export default FieldRow;
