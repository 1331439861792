import {
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST,
  GET_SELECTS_FOR_PRODUCTS_REQUEST,
  SAVE_PRODUCTS_CHANGES_REQUEST,
  SET_OPTION_FOR_PRODUCT,
  SET_STATUS_FOR_ALL_PRODUCTS,
  CANCEL_ALL_CHANGES,
  CLEAR_FORM,
  SET_SELECT,
} from '../types';

const setSelect = (name, value) => ({
  type: SET_SELECT,
  payload: { name, value },
});

const getProductsForTable = (offset, query) => ({
  type: GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST,
  payload: { offset, query },
});

const setStatusForAllProducts = (type) => ({
  type: SET_STATUS_FOR_ALL_PRODUCTS,
  payload: type,
});

const getInfoForSelects = (id) => ({
  type: GET_SELECTS_FOR_PRODUCTS_REQUEST,
  payload: id,
});

const saveChangesProducts = (body, page, params) => ({
  type: SAVE_PRODUCTS_CHANGES_REQUEST,
  payload: { body, page, params },
});

const setOptionForProducts = (product, name, value) => ({
  type: SET_OPTION_FOR_PRODUCT,
  payload: { product, name, value },
});

const cancelChanges = () => ({
  type: CANCEL_ALL_CHANGES,
});

const clearForm = () => ({
  type: CLEAR_FORM,
});

export {
  getProductsForTable,
  getInfoForSelects,
  saveChangesProducts,
  setOptionForProducts,
  cancelChanges,
  clearForm,
  setStatusForAllProducts,
  setSelect,
};
