import { yupEmpty, onlyNumbers, validateEmail } from 'helpers';
import {
  LANG_DICTIONARY,
} from 'consts';

import {
  object,
  string,
} from 'yup';

const {
  REQUIRED,
  EMAIL_VALIDATION,
  NO_MORE_ONE_HUNDRED,
  INVALID_PHONE,
} = LANG_DICTIONARY;

export default (args = {}) => object().shape({
  mobilePhone: args.burnDocument ?
    string()
      .test('len', INVALID_PHONE, (val) => {
        const onlyNumbersValue = onlyNumbers(val.toString());

        return !onlyNumbersValue.length || onlyNumbersValue.length === 11;
      }) :
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11)
      .required(REQUIRED),
  contactPhone: string().max(100, NO_MORE_ONE_HUNDRED).nullable(),
  email: args.burnDocument ?
    string()
      .test('len', EMAIL_VALIDATION, (val) => !val.length || validateEmail(val)) :
    string()
      .test('len', EMAIL_VALIDATION, (val) => validateEmail(val))
      .required(REQUIRED),
  homePhone: yupEmpty(
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11),
    INVALID_PHONE,
  ).nullable(),
  workPhone: yupEmpty(
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11),
    INVALID_PHONE,
  ).nullable(),
});
