import React from 'react';
import { connect } from 'react-redux';
import { ExecutionData } from 'containers';
import PropTypes from 'prop-types';

const propTypes = {
  scenario: PropTypes.string.isRequired,
  stage: PropTypes.number.isRequired,
};

const IszExecutionData = ({
  scenario,
  stage,
}) => (
  <ExecutionData
    scenario={scenario}
    stage={stage}
  />
);

const mapStateToProps = ({
  checkoutReducer: {
    stage,
    scenario,
  },
}) => ({
  scenario,
  stage,
});

IszExecutionData.propTypes = propTypes;

export default connect(mapStateToProps)(IszExecutionData);
