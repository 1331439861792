import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import { AVAILABLE_FORMATS } from 'consts';
import { getFormat } from 'helpers';
import { FileViewModal } from 'components';

const propTypes = {
  className: PropTypes.string,
  fileId: PropTypes.string,
  file: PropTypes.object,
  onDelete: PropTypes.func,
};

const defaultProps = {
  className: '',
  fileId: '',
  file: {},
  onDelete: () => null,
};

const DocsTransferFileItem = ({
  className,
  fileId,
  file,
  onDelete,
}) => {
  const isViewableFile = AVAILABLE_FORMATS.includes(getFormat(file.type));

  const b = block(className);

  return (
    <div className={b('files-item')}>
      {isViewableFile && <FileViewModal file={file} />}
      <p className={(b('filename'))}>{file.name}</p>
      <div
        onClick={() => onDelete(fileId)}
        tabIndex={-1}
        role="button"
      >
        <Icon name="close" />
      </div>
    </div>
  );
};

DocsTransferFileItem.propTypes = propTypes;
DocsTransferFileItem.defaultProps = defaultProps;
export default DocsTransferFileItem;
