import { createSelector } from 'reselect';

const getUnreadMessagesCountSelector = ({
  docsTransfer,
}) => docsTransfer.inboxUnreadMessagesCount;

const getPartners = ({ docsTransfer }) => docsTransfer.recipientPartners;
const getRecipients = ({ docsTransfer }) => docsTransfer.recipients;
const getRecipientGroups = ({ docsTransfer }) => docsTransfer.recipientGroups;

const getRebuildPartners = createSelector(
  getPartners,
  (partners) => partners.map((p) => ({
    text: p.partnerName,
    value: p.recipientPartnerId,
  })),
);

const getRebuildRecipients = createSelector(
  [getRecipientGroups, getRecipients],
  (recipientGroups, recipients) => {
    const rebuildGroups = recipientGroups.map(({ recipientGroupName, recipientUsersGroupsId }) => ({
      text: recipientGroupName,
      value: `recipientUsersGroupsId-${recipientUsersGroupsId}`,
    }));
    const rebuildRecipients = recipients.map(({ recipientFullName, recipientId }) => ({
      text: recipientFullName,
      value: `recipientId-${recipientId}`,
    }));

    return [...rebuildGroups, ...rebuildRecipients];
  },
);

export {
  getUnreadMessagesCountSelector,
  getRebuildPartners,
  getRebuildRecipients,
};
