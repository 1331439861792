import {
  CHECK_PODFT_DATA,
  CLOSE_CHECK_AGENT_RESULT,
} from '../types';

const INITIAL_STATE = {
  isLoading: false,
  isOpenPopup: false,
  result: [],
};

export default (state = INITIAL_STATE, {
  type,
  payload,
}) => {
  switch (type) {
    case CLOSE_CHECK_AGENT_RESULT:
      return {
        ...state,
        isOpenPopup: false,
        result: [],
      };

    case CHECK_PODFT_DATA.start: {
      return {
        ...state,
        isLoading: true,
      };
    }

    case CHECK_PODFT_DATA.success: {
      return {
        ...state,
        result: payload.data.filter((item) => !item.result),
        isOpenPopup: !payload.isSuccess,
        isLoading: false,
      };
    }

    case CHECK_PODFT_DATA.fail: {
      return {
        ...state,
        isLoading: false,
        error: payload.error,
      };
    }

    default:
      return state;
  }
};
