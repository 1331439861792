import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('basketConstructor');

const DOWNLOAD_TEMPLATE_REQUEST = 'DOWNLOAD_TEMPLATE_REQUEST';
const DOWNLOAD_TEMPLATE_START = 'DOWNLOAD_TEMPLATE_START';
const DOWNLOAD_TEMPLATE_SUCCESS = 'DOWNLOAD_TEMPLATE_SUCCESS';
const DOWNLOAD_TEMPLATE_FAIL = 'DOWNLOAD_TEMPLATE_FAIL';

const UPLOAD_IMPORT_FILE_REQUEST = 'UPLOAD_IMPORT_FILE_REQUEST';
const UPLOAD_IMPORT_FILE_START = 'UPLOAD_IMPORT_FILE_START';
const UPLOAD_IMPORT_FILE_SUCCESS = 'UPLOAD_IMPORT_FILE_SUCCESS';
const UPLOAD_IMPORT_FILE_FAIL = 'UPLOAD_IMPORT_FILE_FAIL';

const RESET_CURRENT_ERROR_IMPORT = 'RESET_CURRENT_ERROR_IMPORT';
const RESET_ALL_ERROR_IMPORT = 'RESET_ALL_ERROR_IMPORT';

const GET_INFO_SCHEDULE_TASK_REQUEST = 'GET_INFO_SCHEDULE_TASK_REQUEST';
const GET_INFO_SCHEDULE_TASK_SUCCESS = 'GET_INFO_SCHEDULE_TASK_SUCCESS';
const GET_INFO_SCHEDULE_TASK_START = 'GET_INFO_SCHEDULE_TASK_START';
const GET_INFO_SCHEDULE_TASK_FAIL = 'GET_INFO_SCHEDULE_TASK_FAIL';

const CANCEL_SCHEDULE_TASK_REQUEST = 'CANCEL_SCHEDULE_TASK';
const CANCEL_SCHEDULE_TASK_SUCCESS = 'CANCEL_SCHEDULE_TASK_SUCCESS';
const CANCEL_SCHEDULE_TASK_START = 'CANCEL_SCHEDULE_TASK_START';
const CANCEL_SCHEDULE_TASK_FAIL = 'CANCEL_SCHEDULE_TASK_FAIL';

const CREATE_SCHEDULE_TASK_REQUEST = 'CREATE_SCHEDULE_TASK';
const CREATE_SCHEDULE_TASK_SUCCESS = 'CREATE_SCHEDULE_TASK_SUCCESS';
const CREATE_SCHEDULE_TASK_START = 'CREATE_SCHEDULE_TASK_START';
const CREATE_SCHEDULE_TASK_FAIL = 'CREATE_SCHEDULE_TASK_FAIL';

const GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST = 'GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST';
const GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS = 'GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS';
const GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_START = 'GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_START';
const GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_FAIL = 'GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_FAIL';

const SET_INIT_STATE_IMPORT = 'import/SET_INIT_STATE_IMPORT';

export const GET_IMPORT_VERSION = factory.createAsync('GET_IMPORT_VERSION');
export const UPLOAD_PODFT_IMPORT_FILE = factory.createAsync('UPLOAD_PODFT_IMPORT_FILE');

export {
  SET_INIT_STATE_IMPORT,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_REQUEST,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_START,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_FAIL,
  CREATE_SCHEDULE_TASK_FAIL,
  CREATE_SCHEDULE_TASK_REQUEST,
  CREATE_SCHEDULE_TASK_START,
  CREATE_SCHEDULE_TASK_SUCCESS,
  CANCEL_SCHEDULE_TASK_FAIL,
  CANCEL_SCHEDULE_TASK_REQUEST,
  CANCEL_SCHEDULE_TASK_START,
  CANCEL_SCHEDULE_TASK_SUCCESS,
  GET_INFO_SCHEDULE_TASK_FAIL,
  GET_INFO_SCHEDULE_TASK_SUCCESS,
  GET_INFO_SCHEDULE_TASK_REQUEST,
  GET_INFO_SCHEDULE_TASK_START,
  RESET_ALL_ERROR_IMPORT,
  RESET_CURRENT_ERROR_IMPORT,
  UPLOAD_IMPORT_FILE_FAIL,
  UPLOAD_IMPORT_FILE_REQUEST,
  UPLOAD_IMPORT_FILE_START,
  UPLOAD_IMPORT_FILE_SUCCESS,
  DOWNLOAD_TEMPLATE_FAIL,
  DOWNLOAD_TEMPLATE_REQUEST,
  DOWNLOAD_TEMPLATE_START,
  DOWNLOAD_TEMPLATE_SUCCESS,
};
