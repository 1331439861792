import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { range, isEmpty } from 'lodash';

import {
  Hint,
  DateCustomPicker,
  MainBtn,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { Popup } from 'semantic-ui-react';

import {
  LANG_DICTIONARY,
} from 'consts';

import './styles.scss';

const {
  TERM_OF_CREDIT,
  TERM_MONTH,
  CREDIT_AMOUNT,
  DATE_BEGIN_CREDIT,
  DATE_END_CREDIT,
  INSURANCE_SUM,
  INSURANCE_PREMIUM,
  CONTRACT_CURRENCY,
  NEXT,
  COUNT_TYPE,
  KSZ_TERM_ERROR,
} = LANG_DICTIONARY;

const defaultProps = {
  values: {},
  productTip: '',
  handleChangeCreditTerm: () => null,
  handleChangeCreditAmount: () => null,
  handleChangeInsuranceAmount: () => null,
  currencyOptions: [],
  handleChangeCreditCurrency: () => null,
  goNextForm: () => null,
  handleChangeCreditStartDate: () => null,
  productSelection: {},
  isDisabledStartDate: false,
  productCalcTypes: [],
  handleChangeCalcTypes: () => null,
  creditSumDisabled: true,
  insSumDisabled: true,
};

const propTypes = {
  values: PropTypes.object,
  productTip: PropTypes.string,
  durationMin: PropTypes.number.isRequired,
  durationMax: PropTypes.number.isRequired,
  handleChangeCreditTerm: PropTypes.func,
  handleChangeCreditAmount: PropTypes.func,
  handleChangeInsuranceAmount: PropTypes.func,
  currencyOptions: PropTypes.arrayOf(PropTypes.object),
  handleChangeCreditCurrency: PropTypes.func,
  goNextForm: PropTypes.func,
  handleChangeCreditStartDate: PropTypes.func,
  productSelection: PropTypes.object,
  isDisabledStartDate: PropTypes.bool,
  productCalcTypes: PropTypes.arrayOf(PropTypes.object),
  handleChangeCalcTypes: PropTypes.func,
  creditSumDisabled: PropTypes.bool,
  insSumDisabled: PropTypes.bool,
};

const b = block('ksz-form');

const KszForm = ({
  values,
  productTip,
  durationMin,
  durationMax,
  handleChangeCreditTerm,
  handleChangeCreditAmount,
  currencyOptions,
  handleChangeCreditCurrency,
  goNextForm,
  handleChangeCreditStartDate,
  isDisabledStartDate,
  productCalcTypes,
  handleChangeCalcTypes,
  creditSumDisabled,
  insSumDisabled,
  handleChangeInsuranceAmount,
  productSelection,
}) => {
  const termValues = range(durationMin, durationMax + 1, 1);
  const termOptions = termValues.map((termValue) => ({
    key: termValue,
    text: `${termValue} ${TERM_MONTH}`,
    value: termValue,
  }));
  const creditCurrencyDisabled = currencyOptions.length === 1;
  const isTouched = Object.keys(values.touched).every((field) => values.touched[field]);
  const isGoNextDisabled = !isTouched || !isEmpty(values.errors) || !productSelection.status;

  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('form', 'inputs')}>

          <SelectInput
            label={TERM_OF_CREDIT}
            placeholder={TERM_OF_CREDIT}
            options={termOptions}
            name="creditTerm"
            value={values.creditTerm}
            onChange={handleChangeCreditTerm}
            touched={values.touched}
            search
            noResultsMessage={KSZ_TERM_ERROR(durationMin, durationMax)}
          />

          <SelectInput
            label={CONTRACT_CURRENCY}
            placeholder={CONTRACT_CURRENCY}
            options={currencyOptions}
            name="creditCurrency"
            values={values}
            onChange={handleChangeCreditCurrency}
            disabled={creditCurrencyDisabled}
            touched={values.touched}
          />

          <DateCustomPicker
            label={DATE_BEGIN_CREDIT}
            placeholder={DATE_BEGIN_CREDIT}
            name="creditStartDate"
            values={values}
            onChange={handleChangeCreditStartDate}
            touched={values.touched}
            errors={values.errors}
            disabled={isDisabledStartDate}
          />

          <DateCustomPicker
            label={DATE_END_CREDIT}
            placeholder={DATE_END_CREDIT}
            name="creditEndDate"
            values={values}
            disabled
          />

          <SelectInput
            label={COUNT_TYPE}
            placeholder={COUNT_TYPE}
            options={productCalcTypes}
            name="productCalcType"
            values={values}
            onChange={handleChangeCalcTypes}
          />

          { !creditSumDisabled ?
            (
              <TextInput
                type="text"
                label={CREDIT_AMOUNT}
                placeholder={CREDIT_AMOUNT}
                name="creditAmount"
                values={values}
                onChange={handleChangeCreditAmount}
                error={values.errors.creditAmount || values.errors.correctionCoefSettingsError}
                touched={values.touched}
                currency
                disabled={creditSumDisabled}
              />
            )
            :
              <div />}

          <TextInput
            type="text"
            label={INSURANCE_SUM}
            placeholder={INSURANCE_SUM}
            name="creditInsuranceSum"
            values={values}
            onChange={handleChangeInsuranceAmount}
            error={values.errors.creditInsuranceSum}
            disabled={insSumDisabled}
            currency
            touched={values.touched}
          />

          <TextInput
            type="text"
            label={INSURANCE_PREMIUM}
            placeholder={INSURANCE_PREMIUM}
            name="creditInsurancePremium"
            values={values}
            error={values.errors.creditInsurancePremium}
            disabled
            currency
            touched={values.touched}
          />

        </div>
        <Hint tipText={productTip} />
      </div>
      <div className={b('footer-next-button')}>
        <Popup
          content={productSelection.message}
          basic
          disabled={productSelection.status}
          trigger={(
            <div>
              <MainBtn
                text={NEXT}
                disabled={isGoNextDisabled}
                onClick={goNextForm}
                className={b('next-button').toString()}
              />
            </div>
            )}
        />
      </div>
    </div>
  );
};

KszForm.defaultProps = defaultProps;
KszForm.propTypes = propTypes;
export default KszForm;
