import React from 'react';
import PropTypes from 'prop-types';
import {
  FlexTable, CheckingState, PayoutInfo,
} from 'components';
import { Popup } from 'semantic-ui-react';
import {
  getFormattedDate,
  setSpaceNumberDecimal,
} from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import { connect } from 'react-redux';
import { actions, checkAccessAction } from 'roles';
import template from './template';
import Controls from './controls';

import './styles.scss';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
  handleClickInfoIcon: PropTypes.func,
  handleClickPrintIcon: PropTypes.func,
  roles: PropTypes.object,
};

const defaultProps = {
  handleClickInfoIcon: () => null,
  handleClickPrintIcon: () => null,
  roles: {},
};

const {
  NUMBER,
  INSURER,
  BIRTH_DATE_SHORT,
  PRODUCT,
  STATUS,
  INSURANCE_SUM,
  DATE_CONTRACT,
  MANAGMENT,
  STATE_CHECKING,
  SALES_MANAGER,
  SUBDIVISION,
  MISSING,
  PREMIUM,
} = LANG_DICTIONARY;

const headers = [
  MANAGMENT,
  NUMBER,
  DATE_CONTRACT,
  INSURER,
  BIRTH_DATE_SHORT,
  PRODUCT,
  STATUS,
  PREMIUM,
  SALES_MANAGER,
  SUBDIVISION,
  STATE_CHECKING,
  INSURANCE_SUM,
];

const FlexTableMySales = ({
  data,
  handleClickInfoIcon,
  handleClickPrintIcon,
  roles,
}) => {
  const getControls = (ctsId) => (
    <Controls
      handleClickInfoIcon={handleClickInfoIcon}
      handleClickPrintIcon={handleClickPrintIcon}
      ctsId={ctsId}
    />
  );

  const rebuildData = data
    .map(({
      ctsNum,
      fio,
      birthday,
      dateCreate,
      product,
      status,
      ammount,
      premium,
      currencyName,
      fioSales,
      branchName,
      statusCheck,
      paymentFrequency,
      payments,
      commonStatusPaymentCode,
      ctsId,
      commonStatusPayment,
    }) => template(headers, [
      getControls(ctsId),
      ctsNum,
      getFormattedDate(dateCreate),
      fio,
      getFormattedDate(birthday),
      product,
      status,
      <div className="popUp">
        <p className="popUp-table">{`${setSpaceNumberDecimal(String(premium) || 0)} ${currencyName}`}</p>
        <Popup
          className="popUp-control-my-sales"
          trigger={(<div className="contract-status" name={commonStatusPaymentCode}>{commonStatusPayment || MISSING}</div>)}
          children={(
            <PayoutInfo
              payout={paymentFrequency}
              payoutsArray={payments}
              mySalesTable
              currencyShort={currencyName}
            />
          )}
          hoverable
          position="top center"
          basic
        />
      </div>,
      fioSales,
      branchName,
      <CheckingState status={statusCheck} />,
      `${setSpaceNumberDecimal(String(ammount) || 0)} ${currencyName}`,

    ], checkAccessAction(actions.SHOW_INSURER, roles)));

  return (
    <FlexTable data={rebuildData} minHeight={70} />
  );
};

const mapStateToProps = (
  {
    authReducer: {
      roles,
    },
  },
) => ({
  roles,
});

FlexTableMySales.propTypes = propTypes;
FlexTableMySales.defaultProps = defaultProps;
export default connect(mapStateToProps)(FlexTableMySales);
