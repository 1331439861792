import _ from 'lodash';
import {
  CHECK_ACTIVE_CONTEST,
  GET_BANNER,
  TOGGLE_IS_ACTIVE,
  GET_CONTESTS_DATA,
  GET_CLUSTER,
  SAVE_CONTEST_IMAGE,
  SET_INIT_STATE,
} from '../types';

const INITIAL_STATE = {
  isActiveContest: false,
  isActiveBanner: false,
  isLoadingBanner: false,
  bannerUrl: '',
  contestsInfo: [],
  clusters: {},
  images: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHECK_ACTIVE_CONTEST.success:
      return {
        ...state,
        isActiveContest: payload,
      };

    case CHECK_ACTIVE_CONTEST.fail:
      return {
        ...state,
        isActiveContest: false,
      };

    case GET_BANNER.start:
      return {
        ...state,
        isLoadingBanner: true,
        isActiveBanner: true,
      };

    case GET_BANNER.success:
      return {
        ...state,
        isLoadingBanner: false,
        isActiveBanner: true,
        bannerUrl: payload,
      };

    case GET_BANNER.fail:
      return {
        ...state,
        isLoadingBanner: false,
        isActiveBanner: false,
        bannerUrl: '',
      };

    case TOGGLE_IS_ACTIVE:
      return {
        ...state,
        isActiveBanner: payload,
      };

    case GET_CONTESTS_DATA.success:
      return {
        ...state,
        contestsInfo: payload.map(({ clasters, ...rest }) => ({
          ...rest,
          clusters: _.orderBy(clasters, (cluster) => cluster.number),
          test: 1,
        })),
      };

    case GET_CLUSTER.success:
      return {
        ...state,
        clusters: {
          ...state.clusters,
          [payload.id]: payload.data,
        },
      };

    case SAVE_CONTEST_IMAGE:
      return {
        ...state,
        images: {
          ...state.images,
          [payload.compId]: payload.url,
        },
      };

    case SET_INIT_STATE:
      return INITIAL_STATE;

    default:
      return state;
  }
};
