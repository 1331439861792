import moment from 'moment';
import { SYSTEM_DATE_TO, LANG_DICTIONARY } from '../consts';

const { DOCUMENT_TYPE_PASSPORT } = LANG_DICTIONARY;

const DOC_TYPES = {
  RUSSIAN_PASSPORT: 'russian_passport',
  OTHER: 'other',
};

const ADDRESS_TYPES = {
  REGISTRATION: 'registration',
  FACT: 'fact',
};

function getDocTypeId(values) {
  const exist = values.passportNumber || values.serial;

  if (!exist) {
    return undefined;
  }

  return values.documentType === DOCUMENT_TYPE_PASSPORT ?
    DOC_TYPES.RUSSIAN_PASSPORT :
    DOC_TYPES.OTHER;
}

export default function getAgentChecking(values, livingAddressIsSame) {
  const agent = {
    firstName: values.firstName,
    lastName: values.lastName,
    middleName: values.patronymic || values.middleName || undefined,
    birthDate: moment(values.birthDate).format(SYSTEM_DATE_TO),
    docNumber: values.passportNumber || undefined,
    docSeries: values.serial || undefined,
    inn: values.inn || undefined,
    birthPlace: values.birthPlace || undefined,
    docTypeId: getDocTypeId(values),
    addresses: [],
  };

  if (values.republic) {
    agent.addresses.push({
      apartmentName: values.apartment || undefined,
      cityName: values.city || undefined,
      housingName: values.house || undefined,
      provinceName: values.area || undefined,
      regionName: values.republic,
      streetName: values.street || undefined,
      type: ADDRESS_TYPES.REGISTRATION,
    });
  }

  if (values.republicLiving && !livingAddressIsSame) {
    agent.addresses.push({
      apartmentName: values.apartmentLiving || undefined,
      cityName: values.cityLiving || undefined,
      housingName: values.houseLiving || undefined,
      provinceName: values.areaLiving || undefined,
      regionName: values.republicLiving,
      streetName: values.streetLiving || undefined,
      type: ADDRESS_TYPES.FACT,
    });
  }

  return agent;
}
