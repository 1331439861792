import React from 'react';
import PropTypes from 'prop-types';
import {
  ProductsAdminForm,
  AdministrativeSectionInterface,
  PaginationPanel,
} from 'components';
import { withCustomRouter } from 'HOC';
import { connect } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import { toastr } from 'react-redux-toastr';
import {
  getProductsForTable,
  getInfoForSelects,
  saveChangesProducts,
  setOptionForProducts,
  cancelChanges,
  clearForm,
  setStatusForAllProducts,
  setSelect,
} from 'redux/rootActions';

const { CHOOSE_PRODUCT_FOR_SEARCH } = LANG_DICTIONARY;
const propTypes = {
  partners: PropTypes.arrayOf(PropTypes.object),
  products: PropTypes.arrayOf(PropTypes.object),
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  currentPartner: PropTypes.number,
  currentProduct: PropTypes.number,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  active: PropTypes.number,
  getProducts: PropTypes.func,
  getSelects: PropTypes.func,
  saveChanges: PropTypes.func,
  setOption: PropTypes.func,
  changesCancel: PropTypes.func,
  formClear: PropTypes.func,
  setAllStatus: PropTypes.func,
  setSelector: PropTypes.func,
};

const defaultProps = {
  active: 0,
  partners: [],
  products: [],
  tableInfo: [],
  currentPartner: null,
  currentProduct: null,
  currentPage: 1,
  totalPages: 1,
  getProducts: () => null,
  getSelects: () => null,
  saveChanges: () => null,
  setOption: () => null,
  changesCancel: () => null,
  formClear: () => null,
  setAllStatus: () => null,
  setSelector: () => null,
};

class DistributingProducts extends React.Component {
  componentDidMount = () => {
    const { getSelects } = this.props;
    getSelects();
  }

  componentWillUnmount = () => {
    const { formClear } = this.props;
    formClear();
  }

  getQueryParams = () => {
    const { currentPartner, currentProduct } = this.props;

    return {
      currentPartner,
      currentProduct,
    };
  }

  handleDownPage = () => {
    const { currentPage, getProducts } = this.props;
    if (currentPage > 1) {
      getProducts(currentPage - 1, this.getQueryParams());
    }
  }

  handleUpPage = () => {
    const { currentPage, totalPages, getProducts } = this.props;
    if (currentPage !== totalPages) {
      getProducts(currentPage + 1, this.getQueryParams());
    }
  }

  handleSearchOnSelect = (name) => (e, { value }) => {
    const {
      setSelector,
      getProducts,
      getSelects,
    } = this.props;
    setSelector(name, value);
    if (name === 'currentPartner') {
      toastr.warning(CHOOSE_PRODUCT_FOR_SEARCH);
      getSelects(value);
      setSelector('tableInfo', []);
      setSelector('initialData', []);
      setSelector('currentProduct', null);
    } else {
      getProducts(1, { ...this.getQueryParams(), [name]: value });
    }
  }

  saveButton = () => {
    const {
      tableInfo,
      saveChanges,
      currentPage,
    } = this.props;
    saveChanges(tableInfo, currentPage, { ...this.getQueryParams() });
  }

  cancelButton = () => {
    const { changesCancel } = this.props;
    changesCancel();
  }

  changeProduct = (name, value, product) => {
    const { setOption } = this.props;
    if (name === 'active') {
      setOption(product, name, !value);
    } else {
      setOption(product, name, value);
    }
  }

  setStatusForAllProducts = (type) => {
    const { setAllStatus } = this.props;
    setAllStatus(type);
  }

  render() {
    const {
      active,
      partners,
      products,
      tableInfo,
      currentPartner,
      currentProduct,
      currentPage,
      totalPages,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active}>
        <ProductsAdminForm
          tableInfo={tableInfo}
          partners={partners}
          products={products}
          currentPartner={currentPartner}
          currentProduct={currentProduct}
          setStatusForAllProducts={this.setStatusForAllProducts}
          changeProduct={this.changeProduct}
          cancelButton={this.cancelButton}
          saveButton={this.saveButton}
          handleSearchOnSelect={this.handleSearchOnSelect}
        />
        <PaginationPanel
          active={currentPage}
          total={totalPages}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  productsAdminInterface: {
    partners,
    products,
    tableInfo,
    currentPartner,
    currentProduct,
    currentPage,
    totalPages,
  },
}) => ({
  partners,
  products,
  tableInfo,
  currentPartner,
  currentProduct,
  currentPage,
  totalPages,
});

const mapDispatchToProps = {
  getProducts: getProductsForTable,
  getSelects: getInfoForSelects,
  saveChanges: saveChangesProducts,
  setOption: setOptionForProducts,
  changesCancel: cancelChanges,
  formClear: clearForm,
  setAllStatus: setStatusForAllProducts,
  setSelector: setSelect,
};

DistributingProducts.propTypes = propTypes;
DistributingProducts.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(
  withCustomRouter(DistributingProducts),
);
