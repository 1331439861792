import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY, CLIENT_REPORTS_TYPES, REGEXP } from 'consts';
import { Input } from 'semantic-ui-react';
import { MainBtn } from 'components';
import block from 'bem-cn';
import { getDateForPicker } from 'helpers';

const propTypes = {
  setFields: PropTypes.func,
  contractNumber: PropTypes.number,
  getReportsByNum: PropTypes.func,
  dateInvest: PropTypes.string,
  actualDate: PropTypes.string,
};

const defaultProps = {
  setFields: () => null,
  contractNumber: null,
  getReportsByNum: () => null,
  dateInvest: '',
  actualDate: '',
};

const b = block('clientReports');

const {
  ENTER_CONTRACT_NUMBER,
  SEARCH,
  INVEST_DATE,
  CONTRACT_NUMBER,
  LAST_AVAILABLE_REPORT_DATE,
} = LANG_DICTIONARY;

const ContractNumber = (props) => {
  const {
    setFields,
    contractNumber,
    getReportsByNum,
    dateInvest,
    actualDate,
  } = props;

  const onSearch = () => {
    const refactoredContractNumber = contractNumber.replace(REGEXP.SPACES_TABS_LINE_BREAKS, '');

    getReportsByNum({ type: CLIENT_REPORTS_TYPES.CONTRACT, numFull: refactoredContractNumber });
  };

  return (
    <div>
      <div className={b('wrapper')}>
        <div className={b('field-block')}>
          <div className={b('filed-block-label')}>
            {ENTER_CONTRACT_NUMBER}
          </div>
          <div className={b('field')}>
            <Input
              className={b('field')}
              name="contractNumber"
              placeholder={CONTRACT_NUMBER}
              onChange={(e) => setFields('contractNumber', e.target.value)}
              value={contractNumber}
              required
            />
          </div>
        </div>
        <div className={b('search')}>
          <MainBtn
            className={b('btn')}
            onClick={onSearch}
            text={SEARCH}
          />
        </div>
      </div>
      <div className={b('field-block-number')}>
        <div className={b('filed-block-label')}>
          {INVEST_DATE}
        </div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            type="text"
            name="dateInvest"
            value={dateInvest ? getDateForPicker(dateInvest) : ''}
            disabled
          />
        </div>
      </div>
      <div className={b('field-block-number')}>
        <div className={b('filed-block-label')}>
          {LAST_AVAILABLE_REPORT_DATE}
        </div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            type="text"
            name="actualReportDate"
            value={actualDate ? getDateForPicker(actualDate) : ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

ContractNumber.propTypes = propTypes;
ContractNumber.defaultProps = defaultProps;

export default ContractNumber;
