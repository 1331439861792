import React, { Component } from 'react';
import { block } from 'bem-cn';
import { connect } from 'react-redux';
import {
  getFilterOptions,
  searchProducts,
  getContractInfo,
  clearContractInfo,
  clearFilterProducts,
  setStartDate,
  setSelectDate,
  setExtraSettings,
  setInitialValuesMySales,
  handlePaginationPage,
  getExtendSettings,
  getDownloadCheck,
  getDownloadList,
  closeDownloadPopup,
  getDownloadReport,
  setInitialValuesReport,
  getReportOptions,
} from 'redux/rootActions';
import { DownloadReport } from 'components';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import {
  ROUTES,
  LANG_DICTIONARY,
  USER_ROLES,
  KEY_NAMES,
  REGEXP,
} from 'consts';
import {
  getDate,
  checkDataEmpty,
  replaceAllButNumbers,
  numberOrStringTypes,
} from 'helpers';
import { ContractInfo } from 'containers';
import { withCustomRouter } from 'HOC';
import SearchInputs from './searchInputs';
import Controls from './controls';
import Products from './products';
import Pagination from './paginationPanel';
import './styles.scss';

const { myClients, printForm } = ROUTES;
const { ALL_TIME } = LANG_DICTIONARY;
const {
  SUPERADMIN,
  SK_ADMIN,
  DOCUMENT_CONTROLLER,
  CONTACT_CENTER,
  BDM,
} = USER_ROLES;
const { KEY_ENTER } = KEY_NAMES;

const propTypes = {
  products: PropTypes.arrayOf(PropTypes.object).isRequired,
  filterPartners: PropTypes.arrayOf(PropTypes.object).isRequired,
  branches: PropTypes.arrayOf(PropTypes.object).isRequired,
  statusList: PropTypes.arrayOf(PropTypes.object).isRequired,
  searchResult: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.object,
    ),
  ).isRequired,
  search: PropTypes.func.isRequired,
  getOptions: PropTypes.func.isRequired,
  getContract: PropTypes.func.isRequired,
  clearContract: PropTypes.func.isRequired,
  history: PropTypes.object,
  clearSearch: PropTypes.func.isRequired,
  loadingSearch: PropTypes.bool,
  isNewSearch: PropTypes.bool,
  location: PropTypes.object,
  setInitialValues: PropTypes.func,
  setDateValue: PropTypes.func,
  setExtraValues: PropTypes.func,
  setSelectDateValue: PropTypes.func,
  openExtendSettings: PropTypes.func,
  startDate: PropTypes.object.isRequired,
  endDate: PropTypes.object.isRequired,
  contractNumber: PropTypes.string,
  ammount: PropTypes.string,
  productId: PropTypes.string,
  statusId: PropTypes.string,
  branchId: PropTypes.string,
  partnerId: PropTypes.string,
  insurerFio: PropTypes.string,
  userFio: PropTypes.string,
  paginationPage: PropTypes.number,
  setPaginationPage: PropTypes.func,
  dateSelectValue: PropTypes.string,
  isOpen: PropTypes.bool,
  contractId: PropTypes.number,
  openDownloadReportsPopUp: PropTypes.bool,
  closePopUp: PropTypes.func,
  downloadReports: PropTypes.arrayOf(PropTypes.object),
  downloadReport: PropTypes.func,
  getReportsForDownload: PropTypes.func,
  haveReports: PropTypes.bool,
  getCheck: PropTypes.func,
  totalPagesMySales: PropTypes.number,
  idPartner: PropTypes.number,
  gurole: PropTypes.arrayOf(PropTypes.string),
  ...numberOrStringTypes(
    'productIdReport',
    'statusIdReport',
  ),
};

const defaultProps = {
  idPartner: 1,
  totalPagesMySales: 1,
  dateSelectValue: '',
  isOpen: false,
  contractId: 0,
  setPaginationPage: () => null,
  paginationPage: 1,
  contractNumber: '',
  ammount: '',
  productId: '',
  statusId: '',
  branchId: '',
  partnerId: '',
  insurerFio: '',
  userFio: '',
  openExtendSettings: () => null,
  setExtraValues: () => null,
  setSelectDateValue: () => null,
  setDateValue: () => null,
  location: {},
  setInitialValues: () => null,
  openDownloadReportsPopUp: false,
  closePopUp: () => null,
  downloadReports: [],
  downloadReport: () => null,
  getReportsForDownload: () => null,
  history: {},
  loadingSearch: false,
  isNewSearch: true,
  haveReports: false,
  getCheck: () => null,
  gurole: [],
};
const b = block('form-products');

class MySales extends Component {
  constructor(props) {
    super(props);
    this.state = {
      extendSettings: false,
      isDownloadSettings: true,
      paginationPage: 1,
      popupIsOpen: false,
    };
  }

  async componentDidMount() {
    const {
      location: { state },
      setInitialValues,
      setSelectDateValue,
      setExtraValues,
      clearSearch,
      getCheck,
      gurole,
      isNewSearch,
    } = this.props;
    const isCache = state && state.cash;
    // TODO move login from role to action
    const isDefaultFilterValue = gurole.some((role) => (
      role === SUPERADMIN ||
      role === SK_ADMIN ||
      role === DOCUMENT_CONTROLLER ||
      role === CONTACT_CENTER ||
      role === BDM
    ));

    if (!isCache || isNewSearch) {
      await setInitialValues();
      // set default filters value
      if (isDefaultFilterValue) {
        const { start, end } = getDate(ALL_TIME);
        await setSelectDateValue(start, end, ALL_TIME);
        await setExtraValues('partnerId', null);
        this.showExtendSetting();
      }
      clearSearch();
    }
    this.searchAction(true);
    getCheck();
  }

  handleChangeDate = ({ target }) => {
    const { value, name } = target;
    const { setDateValue } = this.props;
    this.setState({ [name]: value });
    setDateValue(name, value);
  };

  handleChange = (event, { value, name }) => {
    const { setExtraValues } = this.props;
    this.setState({ [name]: value });
    setExtraValues(name, value);
  };

  handleChangeDateSelect = (event, { value }) => {
    const { start, end } = getDate(value);
    const { setSelectDateValue } = this.props;
    this.setState({
      dateSelectValue: value,
      startDate: start,
      endDate: end,
    });
    setSelectDateValue(start, end, value);
  };

  showExtendSetting = () => {
    const { extendSettings, isDownloadSettings } = this.state;
    const { openExtendSettings, getOptions } = this.props;
    if (isDownloadSettings) {
      getOptions();
    }
    this.setState({
      extendSettings: !extendSettings,
      isDownloadSettings: false,
    });
    openExtendSettings();
  };

  searchAction = (isInitial) => {
    const {
      search,
      startDate: dateBeg,
      endDate: dateEnd,
      contractNumber,
      ammount,
      productId,
      statusId,
      branchId,
      partnerId,
      insurerFio,
      userFio,
      paginationPage,
    } = this.props;
    const page = isInitial ? paginationPage : 1;
    let dateEndForRequest = dateEnd;
    let emptyDateEnd = {};
    if (!dateEnd) {
      dateEndForRequest = new Date();
      emptyDateEnd = { endDate: dateEndForRequest };
    }
    this.setState({
      paginationPage: page,
      ...emptyDateEnd,
    });

    const refactoredCtsNum = contractNumber.replace(REGEXP.SPACES_TABS_LINE_BREAKS, '');

    if (partnerId === null) {
      const body = checkDataEmpty({
        dateBeg,
        dateEnd: dateEnd || new Date(),
        ammount,
        partnerId,
        statusId,
        branchId,
        productId,
        ctsNum: refactoredCtsNum,
        insurerFio,
        userFio,
      });
      body.partnerId = null;

      return search(page, body);
    }

    return search(page, checkDataEmpty({
      dateBeg,
      dateEnd: dateEnd || new Date(),
      ammount,
      partnerId,
      statusId,
      branchId,
      productId,
      ctsNum: refactoredCtsNum,
      insurerFio,
      userFio,
    }));
  };

  handleDownPage = () => {
    const {
      paginationPage,
      search,
      startDate: dateBeg,
      endDate: dateEnd,
      contractNumber: ctsNum,
      ammount,
      productId,
      statusId,
      branchId,
      partnerId,
      insurerFio,
      userFio,
    } = this.props;
    if (paginationPage && paginationPage !== 1) {
      if (partnerId === null) {
        const body = checkDataEmpty({
          dateBeg,
          dateEnd: dateEnd || new Date(),
          ammount,
          partnerId,
          statusId,
          branchId,
          productId,
          ctsNum,
          insurerFio,
          userFio,
        });
        body.partnerId = null;

        return search(paginationPage - 1, body);
      }

      return search(paginationPage - 1, checkDataEmpty({
        dateBeg,
        dateEnd: dateEnd || new Date(),
        ammount,
        partnerId,
        statusId,
        branchId,
        productId,
        ctsNum,
        insurerFio,
        userFio,
      }));
    }

    return null;
  };

  handleUpPage = () => {
    const {
      paginationPage,
      search,
      startDate: dateBeg,
      endDate: dateEnd,
      contractNumber: ctsNum,
      ammount,
      productId,
      statusId,
      branchId,
      partnerId,
      insurerFio,
      userFio,
      totalPagesMySales,
    } = this.props;
    if (paginationPage !== totalPagesMySales) {
      if (partnerId === null) {
        const body = checkDataEmpty({
          dateBeg,
          dateEnd: dateEnd || new Date(),
          ammount,
          partnerId,
          statusId,
          branchId,
          productId,
          ctsNum,
          insurerFio,
          userFio,
        });
        body.partnerId = null;

        return search(paginationPage + 1, body);
      }

      return search(paginationPage + 1, checkDataEmpty({
        dateBeg,
        dateEnd: dateEnd || new Date(),
        ammount,
        partnerId,
        statusId,
        branchId,
        productId,
        ctsNum,
        insurerFio,
        userFio,
      }));
    }

    return null;
  };

  handleChangePaginationInput = (e) => {
    const { searchResult, setPaginationPage } = this.props;
    const { value } = e.target;
    let page = replaceAllButNumbers(value);
    if (page > searchResult.length) page = searchResult.length;
    if (page <= 1 && page !== '') page = 1;
    if (!searchResult.length) return null;
    setPaginationPage(page);

    return null;
  };

  handleBlurPaginationInput = (e) => {
    const { value } = e.target;
    if (!value) this.setState({ paginationPage: 1 });
  };

  handleClickIcon = (id) => () => {
    const { history } = this.props;
    history.push(`${myClients}/${id}`);
  };

  handleClickPrintIcon = (id) => () => {
    const { history } = this.props;
    history.push({ pathname: `${printForm}/${id}`, state: { ctsId: id } });
  }

  handleClickBackBtn = () => {
    const { clearContract } = this.props;
    clearContract();
    this.setState({ contractId: null });
  };

  handleEnter = (e) => {
    if (e.key === KEY_ENTER) {
      e.preventDefault();
      this.searchAction();
    }
  }

  getDownloadReports = () => {
    const { getReportsForDownload } = this.props;
    getReportsForDownload();
  }

  togglePopUp = () => {
    const { popupIsOpen } = this.state;
    this.setState({
      popupIsOpen: !popupIsOpen,
    });
  }

  render() {
    const {
      popupIsOpen,
    } = this.state;
    const {
      products,
      branches,
      statusList,
      searchResult,
      clearSearch,
      loadingSearch,
      paginationPage,
      haveReports,
      openDownloadReportsPopUp,
      downloadReports,
      downloadReport,
      totalPagesMySales,
      filterPartners,
      idPartner,
      startDate,
      endDate,
      dateSelectValue,
      isOpen,
      contractId,
      dateCheckBegReport,
      dateCheckEndReport,
      productIdsReport,
      statusIdsReport,
      setInitValuesReport,
      getOptionsReport,
    } = this.props;
    if (contractId) {
      return (
        <ContractInfo
          handleClickBackBtn={this.handleClickBackBtn}
        />
      );
    }

    return (
      <div className={b()}>
        <form
          onSubmit={(e) => e.preventDefault()}
          onKeyPress={this.handleEnter}
          role="presentation"
        >
          {popupIsOpen ? (
            <DownloadReport
              handleChangeDate={this.handleChangeDate}
              handleChange={this.handleChange}
              openDownloadReportsPopUp={openDownloadReportsPopUp}
              closePopUp={this.togglePopUp}
              downloadReports={downloadReports}
              download={downloadReport}
              popupIsOpen={popupIsOpen}
              dateCheckBegReport={dateCheckBegReport}
              dateCheckEndReport={dateCheckEndReport}
              products={products}
              statusList={statusList}
              productIds={productIdsReport}
              statusIds={statusIdsReport}
              setInitValuesReport={setInitValuesReport}
              showExtendSetting={getOptionsReport}
            />
          ) : null}
          <Controls
            startDate={startDate}
            endDate={endDate}
            handleChangeDate={this.handleChangeDate}
            handleChangeSelect={this.handleChangeDateSelect}
            showExtendSetting={this.showExtendSetting}
            searchAction={() => this.searchAction(false)}
            dateSelectValue={dateSelectValue}
            isOpenSetting={isOpen}
            clearSearch={clearSearch}
            haveReports={haveReports}
            togglePopUp={this.togglePopUp}
            getDownloadReports={this.getDownloadReports}
            loading={loadingSearch}
          />
          <section className={b('content')}>
            <Collapse isOpen={isOpen}>
              <SearchInputs
                products={products}
                filterPartners={filterPartners}
                branches={branches}
                statusList={statusList}
                handleChange={this.handleChange}
                searchAction={() => this.searchAction(false)}
                idPartner={idPartner}
                {...this.state}
                {...this.props}
              />
            </Collapse>
            <Products
              data={searchResult[0] || []}
              handleClickIcon={this.handleClickIcon}
              handleClickPrintIcon={this.handleClickPrintIcon}
              loading={loadingSearch}
            />
          </section>
          <Pagination
            handleDownPage={this.handleDownPage}
            handleUpPage={this.handleUpPage}
            active={paginationPage}
            total={totalPagesMySales}
            handleChangeInput={this.handleChangePaginationInput}
            handleBlurInput={this.handleBlurPaginationInput}
          />
        </form>
      </div>
    );
  }
}

const mapStateToProps = ({
  mySales,
  filtering,
  filtering: {
    partners: filterPartners,
  },
  reportsAdminInterface,
  authReducer: {
    gurole,
  },
}) => ({
  filterPartners,
  gurole,
  ...mySales,
  ...filtering,
  ...reportsAdminInterface,
});

const mapDispatchToProps = {
  getOptions: getFilterOptions,
  getOptionsReport: getReportOptions,
  search: searchProducts,
  getContract: getContractInfo,
  clearContract: clearContractInfo,
  clearSearch: clearFilterProducts,
  setDateValue: setStartDate,
  setSelectDateValue: setSelectDate,
  setExtraValues: setExtraSettings,
  setInitialValues: setInitialValuesMySales,
  setPaginationPage: handlePaginationPage,
  openExtendSettings: getExtendSettings,
  getCheck: getDownloadCheck,
  getReportsForDownload: getDownloadList,
  closePopUp: closeDownloadPopup,
  downloadReport: getDownloadReport,
  setInitValuesReport: setInitialValuesReport,
};

MySales.propTypes = propTypes;
MySales.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(MySales));
