import React, { Component } from 'react';
import { block } from 'bem-cn';
import { UNDERWRITING_STORE_LINK } from 'consts';
import PropTypes from 'prop-types';
import InsurerIcon from 'assets/images/insurer.png';
import InsuredIcon from 'assets/images/insured.png';
import BeneficiariesIcon from 'assets/images/beneficiaries.png';
import ContractIcon from 'assets/images/contract.png';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const {
  contractData,
  insurerData,
  insuredData,
  beneficiaryData,
} = UNDERWRITING_STORE_LINK;

const b = block('switch-check-form');

const propTypes = {
  switchList: PropTypes.arrayOf(PropTypes.object),
  toSwitchPerson: PropTypes.func,
};

const defaultProps = {
  switchList: [],
  toSwitchPerson: () => null,
};

class SwitchCheckForm extends Component {
  constructor(props) {
    super(props);
    const { switchList } = props;
    this.state = {
      currentTab: switchList[0].prop,
      visableTab: 0,
    };
  }

   switchTab = (e, index) => {
     e.stopPropagation();
     this.setState({ visableTab: index });
   };

   handleClickTab = (prop, index) => {
     const { toSwitchPerson } = this.props;
     const { currentTab } = this.state;
     const newCurrentTab = index ? `${prop}${index}` : prop;
     if (currentTab === newCurrentTab) return null;
     toSwitchPerson(prop, index);

     return this.setState({
       currentTab: index ? `${prop}${index}` : prop,
     });
   }

    getIcon = (prop) => {
      const getIconNode = (src, alt) => (<img src={src} alt={alt} />);

      switch (prop) {
        case insuredData:
          return getIconNode(InsuredIcon, 'insured icon');
        case insurerData:
          return getIconNode(InsurerIcon, 'insurer icon');
        case beneficiaryData:
          return getIconNode(BeneficiariesIcon, 'beneficiary icon');
        case contractData:
          return getIconNode(ContractIcon, 'contract icon');
        default:
          return null;
      }
    };

    getCaret = (type) => {
      const { switchList } = this.props;
      const { visableTab } = this.state;
      let currentType = '';
      if (switchList.length < 3) return null;
      if (type === 'left' && visableTab !== 0) currentType = type;
      if (type === 'right' && visableTab !== switchList.length - 2) currentType = type;

      return currentType ? <Icon name={`caret ${currentType}`} /> : null;
    };

    render() {
      const { switchList } = this.props;
      const { currentTab, visableTab } = this.state;

      return (
        <>
          <section className={b()}>
            {switchList.map(({
              prop,
              title,
              index,
            }, indexSwitch) => {
              const currentProp = index ? `${prop}${index}` : prop;
              const isVisablePage = visableTab === indexSwitch || visableTab === indexSwitch - 1;
              const isActiveTab = currentTab === currentProp;
              const isFirstTab = visableTab === indexSwitch;
              const isSecondTab = visableTab === indexSwitch - 1;

              return (
                <div
                  className={b('tab', { active: isActiveTab, first: isFirstTab })}
                  onClick={() => this.handleClickTab(prop, index)}
                  role="button"
                  tabIndex="-1"
                  style={{ display: isVisablePage ? 'flex' : 'none' }}
                  key={title}
                >
                  {isFirstTab && (
                  <div
                    onClick={(e) => this.switchTab(e, indexSwitch - 1)}
                    role="button"
                    tabIndex="-1"
                  >
                    {this.getCaret('left')}
                  </div>
                  )}
                  <div className={b('icon')}>
                    {this.getIcon(prop)}
                  </div>
                  <p className={b('text')}>{title}</p>
                  {isSecondTab && (
                  <div
                    onClick={(e) => this.switchTab(e, indexSwitch)}
                    role="button"
                    tabIndex="-1"
                  >
                    {this.getCaret('right')}
                  </div>
                  )}
                </div>
              );
            })}
          </section>
        </>
      );
    }
}

SwitchCheckForm.propTypes = propTypes;
SwitchCheckForm.defaultProps = defaultProps;
export default SwitchCheckForm;
