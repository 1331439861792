import testDataGenerator from 'helpers/getTestData';
import { TEST_DATA_ENUMS } from 'consts';
import rebuildBeneficiaries from './rebuildBeneficiaries';
import { getRealDocumentDateForInsuredChild, getRealPasportDateForInsured } from './getRealPasportDate';

const generateInsurer = (scenario, storeData) => {
  const {
    fillTestDataInsurerNsz,
    nszInsurerIsInsured,
    insuredBirthDate,
    insuredSexId,
    hideInnNsz,
    hideSnilsNsz,
  } = storeData;
  const { insurer } = testDataGenerator.nszTestDataGenerate(scenario);

  const realPasportDate = getRealPasportDateForInsured(insuredBirthDate);

  const rebuildedInsurer = {
    ...insurer,
    when: realPasportDate,
    birthDate: insuredBirthDate,
    ...(nszInsurerIsInsured && { gender: insuredSexId, birthDate: insuredBirthDate }),
    ...(hideInnNsz && { inn: '' }),
    ...(hideSnilsNsz && { snils: '' }),
  };

  fillTestDataInsurerNsz(rebuildedInsurer);
};

const generateInsured = (scenario, storeData) => {
  const {
    fillTestDataInsuredNsz,
    insuredBirthDate,
    insuredSexId,
    childInsuredBirthDate,
    childInsuredSexId,
    nszInsurerIsInsured,
    hideInnNsz,
    hideSnilsNsz,
  } = storeData;
  const { insured, insuredChild } = testDataGenerator.nszTestDataGenerate(scenario);

  const realPasportDate = getRealPasportDateForInsured(insuredBirthDate);

  const rebuildedInsured = {
    ...insured,
    when: realPasportDate,
    ...(!nszInsurerIsInsured && { gender: insuredSexId, birthDate: insuredBirthDate }),
    ...(hideInnNsz && { inn: '' }),
    ...(hideSnilsNsz && { snils: '' }),
  };

  const realDocumentDataForChild = getRealDocumentDateForInsuredChild(childInsuredBirthDate);

  const rebuildedChildInsured = insuredChild ? {
    values: {
      ...insuredChild.values,
      personBlock: {
        ...insuredChild.values.personBlock,
        birthDate: new Date(childInsuredBirthDate),
        gender: childInsuredSexId,
      },
      documentBlock: {
        ...insuredChild.values.documentBlock,
        ...realDocumentDataForChild,
      },
    },
  } : insuredChild;

  fillTestDataInsuredNsz(rebuildedInsured, nszInsurerIsInsured, rebuildedChildInsured);
};

const generateBeneficiary = (scenario, storeData) => {
  const {
    fillTestDataBeneficiaryNsz,
    beneficiaryByLawNsz,
    numberOfBeneficiaries,
    insuredBirthDate,
  } = storeData;
  const { beneficiaries } = testDataGenerator.nszTestDataGenerate(scenario);

  const finalBeneficiaries = rebuildBeneficiaries(
    beneficiaries,
    numberOfBeneficiaries,
    insuredBirthDate,
  );
  fillTestDataBeneficiaryNsz(finalBeneficiaries, beneficiaryByLawNsz);
};

export default {
  [TEST_DATA_ENUMS.insurer]: (scenario, storeData) => generateInsurer(scenario, storeData),
  [TEST_DATA_ENUMS.insured]: (scenario, storeData) => generateInsured(scenario, storeData),
  [TEST_DATA_ENUMS.beneficiary]: (scenario, storeData) => generateBeneficiary(scenario, storeData),
};
