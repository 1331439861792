import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import {
  SearchInput,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY, COUNTRIES } from 'consts';
import { getInputProps, getAdressString } from 'helpers';
import { searchAddress, resetSearchAdress } from 'redux/rootActions';
import { getBlackAddressInfo } from 'redux/rootSelectors';
import { connect } from 'react-redux';
import _ from 'lodash';

const {
  INDEX,
  REPUBLIC,
  AREA,
  CITY,
  STREET,
  HOUSE,
  HOUSE_NUMBER,
  APARTMENT,
} = LANG_DICTIONARY;

const defaultProps = {
  isLivingAddress: false,
  isInsured: false,
  insuredIsSame: false,
  isLoading: false,
  searchResult: null,
  searchHouses: null,
  onSearch: null,
  inputProps: {
    values: {},
    errors: {},
    touched: {},
    setValues: () => null,
  },
  insuredValues: null,
  childInsuredValues: {},
  resetSearch: null,
  selectSearch: null,
  index: 0,
  prefix: '',
  isChildInsured: false,
  isChildForm: false,
  listValidAddress: [],
  isListBlackAddress: false,
  isManualEnter: false,
  payloadRequestCheckCode: {},
  blackAddressInfo: {},
};

const propTypes = {
  isChildInsured: PropTypes.bool,
  prefix: PropTypes.string,
  isLivingAddress: PropTypes.bool,
  isLoading: PropTypes.bool,
  isInsured: PropTypes.bool,
  insuredIsSame: PropTypes.bool,
  onSearch: PropTypes.func,
  searchResult: PropTypes.arrayOf(PropTypes.object),
  searchHouses: PropTypes.arrayOf(PropTypes.object),
  resetSearch: PropTypes.func,
  selectSearch: PropTypes.func,
  inputProps: PropTypes.object,
  insuredValues: PropTypes.object,
  childInsuredValues: PropTypes.object,
  index: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]),
  isListBlackAddress: PropTypes.bool,
  isChildForm: PropTypes.bool,
  isManualEnter: PropTypes.bool,
  listValidAddress: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.object])),
  payloadRequestCheckCode: PropTypes.object,
  blackAddressInfo: PropTypes.object,
};

const getSearchValue = (values, field) => (values ? values[field] : '');

class AddressBlock extends Component {
  constructor(props) {
    super(props);

    this.state = {
      addressFiasFocus: false,
      addressFiasLivingFocus: false,
      houseFocus: false,
      houseLivingFocus: false,
    };
  }

  getStreet = (values) => {
    if (!values.data.street_with_type) {
      return values.data.settlement_type === 'мкр' ? values.data.settlement_with_type : '';
    }

    return values.data.street_with_type;
  };

  checkAddressIsNotEmpty = (address) => address &&
    Object.keys(address).length &&
    address.hasOwnProperty('data');

  getValueFromProps = () => {
    const {
      inputProps: { values },
      isLivingAddress,
    } = this.props;
    const addressFias = getSearchValue(
      values,
      'addressFias',
    );
    const addressFiasLiving = getSearchValue(
      values,
      'addressFiasLiving',
    );
    const houseFias = getSearchValue(
      values,
      'house',
    );
    const houseFiasLiving = getSearchValue(
      values,
      'houseLiving',
    );

    const streetFias = this.checkAddressIsNotEmpty(addressFias) ?
      this.getStreet(addressFias) :
      '';
    const streetFiasLiving = this.checkAddressIsNotEmpty(addressFiasLiving) ?
      this.getStreet(addressFiasLiving) :
      '';

    const searchFias = isLivingAddress ? addressFiasLiving : addressFias;
    const searchValue = searchFias ? searchFias.value : '';
    const searchHouse = isLivingAddress ? houseFiasLiving : houseFias;
    const searchStreet = isLivingAddress ? streetFiasLiving : streetFias;

    return {
      value: searchValue,
      houseValue: searchHouse,
      streetValue: searchStreet,
    };
  }

  setFormikField = (values) => {
    const {
      inputProps: {
        setValues,
      },
      isLivingAddress,
    } = this.props;
    if (setValues) {
      setValues(values, isLivingAddress);
    }
  }

  handleResultSelect = () => (e, { result }) => {
    const {
      resetSearch,
      selectSearch,
    } = this.props;

    this.setState({
      addressFiasFocus: false,
      addressFiasLivingFocus: false,
      houseFocus: false,
      houseLivingFocus: false,
    });

    selectSearch(result);
    resetSearch();
  };

  handleSearchChange = (e, { value }) => {
    const {
      onSearch,
      inputProps: {
        values,
      },
      isLivingAddress,
    } = this.props;
    const addressFias = getSearchValue(
      values,
      'addressFias',
    );
    const addressFiasLiving = getSearchValue(
      values,
      'addressFiasLiving',
    );

    const searchFias = isLivingAddress ? addressFiasLiving : addressFias;
    const searchFiasKey = isLivingAddress ? 'addressFiasLiving' : 'addressFias';
    const newValues = {
      ...values,
      [searchFiasKey]: {
        ...searchFias,
        value,
      },
    };
    this.setFormikField(newValues);
    if (value) {
      onSearch(value);
    }
  };

  searchHouse = ({ target: { value } }) => {
    const {
      onSearch,
      inputProps: {
        values,
      },
      isLivingAddress,
    } = this.props;
    const addressFias = getSearchValue(
      values,
      'addressFias',
    );
    const addressFiasLiving = getSearchValue(
      values,
      'addressFiasLiving',
    );
    const fiasData = isLivingAddress ? addressFiasLiving : addressFias;

    const { data = {} } = fiasData;
    const {
      country,
      area_with_type: areaWithType,
      region_with_type: regionWithType,
      city_with_type: cityWithType,
      settlement_with_type: settlementWithType,
      street_with_type: streetWithType,
    } = data;
    const newValue = getAdressString({
      country,
      areaWithType,
      regionWithType,
      cityWithType,
      settlementWithType,
      streetWithType,
    });

    const houseFiasKey = isLivingAddress ? 'houseLiving' : 'house';
    this.setFormikField({
      ...values,
      [houseFiasKey]: value,
    });
    onSearch(`${newValue} ${value}`);
  };

  focusHandler = (e) => {
    const { onSearch } = this.props;
    const { value } = this.getValueFromProps();

    this.setState({
      [`${e.target.name}Focus`]: true,
    });
    if (value) {
      onSearch(value);
    }
  };

  blurHandler = (e) => {
    this.setState({
      [`${e.target.name}Focus`]: false,
    });
  };

  render() {
    const {
      addressFiasFocus,
      addressFiasLivingFocus,
      houseFocus,
      houseLivingFocus,
    } = this.state;
    const {
      searchResult,
      searchHouses,
      isLoading,
      isLivingAddress,
      inputProps: { values },
      prefix,
    } = this.props;
    const living = isLivingAddress ? 'Living' : '';
    const onLoadingAdress = isLivingAddress
      ? isLoading && addressFiasLivingFocus
      : isLoading && addressFiasFocus;
    const onLoadingHouse = isLivingAddress
      ? isLoading && houseLivingFocus
      : isLoading && houseFocus;
    const hasCity = isLivingAddress ? values[`${prefix}cityLiving`] : values[`${prefix}city`];
    const hasHouse = isLivingAddress ? values[`${prefix}houseLiving`] : values[`${prefix}house`];

    const { value, houseValue, streetValue } = this.getValueFromProps();

    return (
      <Container>
        <Row>
          <Col>
            <SearchInput
              name={`${prefix}addressFias${living}`}
              propertyName={`${prefix}addressFias${living}`}
              loading={onLoadingAdress}
              onFocus={this.focusHandler}
              onBlur={this.blurHandler}
              onResultSelect={this.handleResultSelect(prefix)}
              onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
              results={searchResult}
              value={value}
              className="insurerData__searchBar"
              inputProps={getInputProps(this.props, `${prefix}addressFias${living}`)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="12">
            <SelectInput
              name={`${prefix}country${living}`}
              propertyName={`${prefix}country${living}`}
              options={COUNTRIES}
              disabled
              required
              {...getInputProps(this.props, `${prefix}country${living}`)}
            />
          </Col>
          <Col md="3" sm="12">
            <TextInput
              name={`${prefix}index${living}`}
              propertyName={`${prefix}index${living}`}
              placeholder={INDEX}
              disabled
              required
              {...getInputProps(this.props, `${prefix}index${living}`)}
            />
          </Col>
          <Col md="3" sm="12">
            <TextInput
              name={`${prefix}republic${living}`}
              propertyName={`${prefix}republic${living}`}
              placeholder={REPUBLIC}
              required
              disabled
              {...getInputProps(this.props, `${prefix}republic${living}`)}
            />
          </Col>
          <Col md="3" sm="12" className="margin-none">
            <TextInput
              name={`${prefix}area${living}`}
              propertyName={`${prefix}area${living}`}
              placeholder={AREA}
              disabled
              {...getInputProps(this.props, `${prefix}area${living}`)}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="12">
            <TextInput
              name={`${prefix}city${living}`}
              propertyName={`${prefix}city${living}`}
              placeholder={CITY}
              disabled
              required
              {...getInputProps(this.props, `${prefix}city${living}`)}
            />
          </Col>
          <Col md="3" sm="12">
            <TextInput
              name={`${prefix}street${living}`}
              propertyName={`${prefix}street${living}`}
              placeholder={STREET}
              disabled
              value={streetValue}
              {...getInputProps(this.props, `${prefix}street${living}`)}
            />
          </Col>
          <Col md="2" sm="12">
            <SearchInput
              name={`${prefix}house${living}`}
              propertyName={`${prefix}house${living}`}
              loading={onLoadingHouse}
              onFocus={this.focusHandler}
              onBlur={this.blurHandler}
              placeholder={HOUSE}
              onResultSelect={this.handleResultSelect(prefix)}
              disabled={!hasCity}
              onSearchChange={_.debounce(this.searchHouse, 500, { leading: true })}
              results={searchHouses || []}
              value={houseValue}
              required
              className="insurerData__searchBar"
              inputProps={getInputProps(this.props, `${prefix}house${living}`)}
            />
          </Col>
          <Col md="2" sm="12">
            <TextInput
              name={`${prefix}houseNumber${living}`}
              propertyName={`${prefix}houseNumber${living}`}
              placeholder={HOUSE_NUMBER}
              disabled={!hasHouse}
              {...getInputProps(this.props, `${prefix}houseNumber${living}`)}
            />
          </Col>
          <Col md="2" sm="12">
            <TextInput
              name={`${prefix}apartment${living}`}
              propertyName={`${prefix}apartment${living}`}
              placeholder={APARTMENT}
              type="text"
              maxLength="10"
              {...getInputProps(this.props, `${prefix}apartment${living}`)}
            />
          </Col>
        </Row>
      </Container>
    );
  }
}

function mapStateToProps(state) {
  const {
    helperReducer: {
      addressSearchResult,
      isLoading,
    },
  } = state;

  const searchHouses = addressSearchResult &&
    addressSearchResult.filter((value) => value.data.house)
      .map((value) => ({
        ...value,
        title: getAdressString({
          house_type_full: value.data.house_type_full,
          house: value.data.house,
          block_type_full: value.data.block_type_full,
          block: value.data.block,
        }),
      }));

  return {
    searchHouses,
    searchResult: addressSearchResult,
    isLoading,
    blackAddressInfo: getBlackAddressInfo(state),
  };
}

AddressBlock.defaultProps = defaultProps;
AddressBlock.propTypes = propTypes;

export default connect(
  mapStateToProps,
  {
    onSearch: searchAddress,
    resetSearch: resetSearchAdress,
  },
)(AddressBlock);
