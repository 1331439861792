import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer, MainBtn } from 'components';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import { useActions } from 'hooks/useActions';
import './styles.scss';
import { Yields } from './components';

const {
  MY_QUERYES_CONSTRUCTOR_BASKET,
  BASKET_POPUP_TEXT_IN_PROGGRES,
  BASKET_POPUP_TEXT_NOT_PROFITABLE,
  GET_ANOTHER_CONSTRUCTOR_BASKET_AGAIN,
} = LANG_DICTIONARY;

const propTypes = {
  status: PropTypes.string,
  history: PropTypes.object,
  isOpen: PropTypes.bool,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onClickCreateBasketAgain: PropTypes.func,
};

const defaultProps = {
  productId: 0,
  status: '',
  history: {},
  isOpen: false,
  onClickCreateBasketAgain: () => null,
};
const b = block('basket-popup');

const ConstructorBasketPopup = ({
  status,
  history,
  isOpen,
  productId,
  onClickCreateBasketAgain,
}) => {
  const { clearSortAndFiltering } = useActions(basketConstructorActions);

  const backToConstructorWithoutSorting = () => {
    clearSortAndFiltering();
    history.push(ROUTES.constructorIszPreparedBaskets({ id: productId }));
  };

  const basketPopUp = (
    <div className={b()}>
      <div className={b('header')}>
        <div>
          {status === 'calculated' ? <Yields /> : <></>}
          {status === 'in_progress' ? <p>{BASKET_POPUP_TEXT_IN_PROGGRES}</p> : <></>}
          {status === 'error_not_profitable' ? <p>{BASKET_POPUP_TEXT_NOT_PROFITABLE}</p> : <></>}
        </div>
      </div>
      <div className={b('content')}>
        <MainBtn
          onClick={() => backToConstructorWithoutSorting()}
          text={MY_QUERYES_CONSTRUCTOR_BASKET}
        />
        <MainBtn
          onClick={onClickCreateBasketAgain}
          text={GET_ANOTHER_CONSTRUCTOR_BASKET_AGAIN}
        />
      </div>
    </div>
  );

  return (
    <PopupContainer
      isOpen={isOpen}
      children={basketPopUp}
    />
  );
};

ConstructorBasketPopup.propTypes = propTypes;
ConstructorBasketPopup.defaultProps = defaultProps;
export default ConstructorBasketPopup;
