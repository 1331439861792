import {
  put,
  takeLatest,
  select,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import { getGlobalHistory } from 'components';
import _ from 'lodash';
import {
  apiCall,
  getError,
  apiDownload,
  getConfigDashboard,
  factoryProducts,
  getSaveDataContractUrl,
  rebuildValues,
  dateFormatter,
} from 'helpers';
import {
  setStatusAmountLimit,
  setFundDescription,
  setInitFormBeneficiary,
  resetValidationTerrorist,
  setInitStateCheckoutReducer,
  clearStoreAgentData,
  changeQueryParams,
  setUnitedFrontendParams,
} from 'redux/rootActions';
import {
  API,
  ROUTES,
  LANG_DICTIONARY,
  REF_INS_STATUSES_CODE_ENUM,
  REF_INS_STATUSES_NAME_ENUM,
} from 'consts';
import {
  getScenarioInfo,
  getContractNum,
  getActionRoles,
} from 'redux/rootSelectors';
import {
  GET_PRODUCTS_REQUEST,
  GET_PRODUCTS_START,
  GET_PRODUCTS_SUCCESS,
  GET_PRODUCTS_FAIL,
  CHECK_PRODUCT_REQUEST,
  CHECK_PRODUCT_START,
  CHECK_PRODUCT_SUCCESS,
  CHECK_PRODUCT_FAIL,
  GET_PRODUCT_FORM_REQUEST,
  GET_PRODUCT_FORM_SUCCESS,
  GET_PRODUCT_FORM_FAIL,
  CHECK_SUM_REQUEST,
  CHECK_SUM_SUCCESS,
  CHECK_SUM_FAIL,
  SAVE_FORMS_REQUEST,
  SAVE_FORMS_FORM_START,
  SAVE_FORMS_SUCCESS,
  SAVE_FORMS_FAIL,
  GET_DATA_CONTRACT_REQUEST,
  GET_DATA_CONTRACT_START,
  GET_DATA_CONTRACT_SUCCESS,
  GET_DATA_CONTRACT_FAIL,
  SET_ANNUL_STATUS,
  UPDATE_STATUS_SUCCESS,
  UPDATE_STATUS_FAIL,
  SAVE_SCAN_REQUEST,
  SAVE_SCAN_SUCCESS,
  SAVE_SCAN_FAIL,
  GET_CONTRACT_FILE_REQUEST,
  GET_CONTRACT_FILE_SUCCESS,
  GET_CONTRACT_FILE_FAIL,
  SET_PRODUCT_SCENARIO,
  GET_REGENERATE_CONTRACT_FILE_REQUEST,
  GET_REGENERATE_CONTRACT_FILE_FAIL,
  GET_CONTRACT_COPY_REQUEST,
  GET_CONTRACT_COPY_START,
  GET_CONTRACT_COPY_FAIL,
  GET_CONTRACT_COPY_SUCCESS,
  GET_ADDITIONAL_DOCS_REQUEST,
  DOWNLOAD_ADDITIONAL_FILE_REQUEST,
  GET_ADDITIONAL_DOCS_SUCCESS,
  GET_ADDITIONAL_DOCS_FAIL,
  GET_ADDITIONAL_DOCS_START,
  DOWNLOAD_ADDITIONAL_FILE_SUCCESS,
  DOWNLOAD_ADDITIONAL_FILE_FAIL,
  DOWNLOAD_ADDITIONAL_FILE_START,
  CHECK_ACCOUNT_NUMBERS_REQUEST,
  CHECK_ACCOUNT_NUMBERS_START,
  CHECK_ACCOUNT_NUMBERS_FAIL,
  CHECK_ACCOUNT_NUMBERS_SUCCESS,
  CHECK_SUM_START,
  GET_PRODUCT_FORM_START,
  GET_ISZ_RESTIRCTIONS,
  GET_CURRENT_COURSE,
  DOWNLOAD_PRESENTATION,
  GET_SIGNING_TYPE,
  CHECK_IS_ACTIVE_CONFIRM_BUTTON,
  SENT_SMS,
} from 'redux/reducers/types';

const { home } = ROUTES;
const {
  CONTRACT_ANNULLED,
  SIGN_CONTRACT_SUCCESS,
  QUALIFICATION_ERR,
} = LANG_DICTIONARY;

function* getIszRestrictions({ payload }) {
  const {
    premiumFreq,
    productId,
    errorCallback,
    mainRequestId,
  } = payload;

  try {
    yield put({ type: GET_ISZ_RESTIRCTIONS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_RESTRICTIONS,
      query: {
        premiumFreq,
        productId,
        calcRequestId: mainRequestId || undefined,
      },
    });

    yield put({ type: GET_ISZ_RESTIRCTIONS.success, payload: data });
    errorCallback(null);
  } catch (e) {
    errorCallback(getError(e));
    yield put({ type: GET_ISZ_RESTIRCTIONS.fail, payload: getError(e) });
  }
}

function* getCourseByRefExchangeRatesRegimesCoef({ payload }) {
  const { coefId } = payload;

  try {
    yield put({ type: GET_CURRENT_COURSE.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_COURSE(coefId),
    });

    yield put({ type: GET_CURRENT_COURSE.success, payload: data });
  } catch (e) {
    yield put({ type: GET_CURRENT_COURSE.fail, payload: getError(e) });
  }
}

function* checkAccountNumbers({ payload }) {
  try {
    yield put({ type: CHECK_ACCOUNT_NUMBERS_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CHECK_ACCOUNT_NUMBERS,
      query: {
        num: payload,
      },
    });
    yield put({ type: CHECK_ACCOUNT_NUMBERS_SUCCESS, payload: data });

    return data;
  } catch (e) {
    yield put({ type: CHECK_ACCOUNT_NUMBERS_FAIL, payload: getError(e) });

    return false;
  }
}

function* getContractCopy({ payload }) {
  const { GET_CONTRACT_COPY } = API;
  const { id, history } = payload;
  try {
    yield put({ type: GET_CONTRACT_COPY_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_CONTRACT_COPY,
      body: { ctsId: id },
    });
    yield put(clearStoreAgentData());
    yield put(setInitStateCheckoutReducer());
    yield put(setInitFormBeneficiary());
    yield put(resetValidationTerrorist());
    yield put({ type: GET_CONTRACT_COPY_SUCCESS, payload: data });
    history.push(`${ROUTES.preliminaryCalculation}/${data.productId}`);
  } catch (e) {
    yield put({ type: GET_CONTRACT_COPY_FAIL, payload: getError(e) });
  }
}

function* getProducts({ payload: { id } }) {
  const { GET_PRODUCTS } = API;
  const roleActions = yield select(getActionRoles);
  const { getProductList } = getConfigDashboard(roleActions);
  try {
    yield put({ type: GET_PRODUCTS_START });
    const { data } = getProductList ? yield apiCall({
      type: 'post',
      url: GET_PRODUCTS,
    }) : [];
    if (id) {
      const description = data.find((product) => product.id === Number(id));
      yield put(setFundDescription(description));
    }
    yield put({ type: GET_PRODUCTS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_PRODUCTS_FAIL, payload: getError(error) });
  }
}

function* checkProduct({
  payload: {
    id,
    history,
    historyLink,
    scenario,
    qualification,
    isWithoutMasterId,
  },
}) {
  const { CHECK_PRODUCT } = API;
  try {
    yield put({ type: CHECK_PRODUCT_START, payload: { history, historyLink, id } });
    const data = yield apiCall({
      type: 'post',
      body: {
        id: Number(id),
        qualification,
        isWithoutMasterId,
      },
      url: CHECK_PRODUCT,
    });
    const isGoNextForm = data.data;
    if (isGoNextForm) {
      yield (factoryProducts(scenario, history, historyLink, id));
    } else toastr.error('', QUALIFICATION_ERR);
    yield put({ type: CHECK_PRODUCT_SUCCESS, payload: { history, historyLink, id } });
  } catch (res) {
    yield put({ type: CHECK_PRODUCT_FAIL, payload: getError(res) });
  }
}

function* getProductForm({ payload }) {
  const { GET_PRODUCT_FORM } = API;
  try {
    const {
      id,
      constructorProduct,
      baskedId,
      guaranteeLevel,
    } = payload;
    const body = constructorProduct ? {
      id: Number(id),
      mainRequestId: Number(baskedId),
      guaranteeLevel,
    } : {
      id: Number(id),
    };
    yield put({ type: GET_PRODUCT_FORM_START });
    const { data } = yield apiCall({
      type: 'post',
      body,
      url: GET_PRODUCT_FORM,
    });
    yield put({ type: SET_PRODUCT_SCENARIO, payload: data.scenario });
    yield put({ type: GET_PRODUCT_FORM_SUCCESS, payload: { data, baskedId, constructorProduct } });
  } catch (error) {
    yield put({ type: GET_PRODUCT_FORM_FAIL, payload: getError(error) });
  }
}

function* checkSum({
  payload: {
    id,
    sumInsured,
    constructorProduct,
    mainRequestId,
    cbSuccess,
    premiumFreq,
  },
}) {
  yield put({ type: CHECK_SUM_START });
  const { CHECK_SUM } = API;
  const { currencyRateId } = yield select(getScenarioInfo);
  const body = constructorProduct ? {
    id: Number(id),
    sumInsured: Number(sumInsured),
    currencyRateId,
    mainRequestId,
    premiumFreq,
  }
    : {
      id: Number(id),
      sumInsured: Number(sumInsured),
      currencyRateId,
      premiumFreq,
    };
  try {
    yield apiCall({
      type: 'post',
      body,
      url: CHECK_SUM,
    });
    yield put({ type: CHECK_SUM_SUCCESS });
    cbSuccess();
  } catch (error) {
    const { response: { data } } = error;
    const isLimitError = data && data.message && data.message.type === 'limit';
    yield put(setStatusAmountLimit(isLimitError));
    yield put({ type: CHECK_SUM_FAIL, payload: getError(error, null, true) });
  }
}

function* saveForms({ payload: { values, redirect } }) {
  const { history, path } = redirect;
  try {
    yield put({ type: SAVE_FORMS_FORM_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: getSaveDataContractUrl(values),
      body: rebuildValues(values),
    });
    yield put({ type: SAVE_FORMS_SUCCESS, payload: data });
    history.push(path);
  } catch (error) {
    yield put({ type: SAVE_FORMS_FAIL, payload: getError(error) });
  }
}

function* getDataContract({ payload: { id }, history }) {
  const { GET_CONTRACT } = API;
  try {
    yield put({ type: GET_DATA_CONTRACT_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: `${GET_CONTRACT}/${id}`,
    });

    const { productName, fundDescription, productTabColor } = data;
    yield put(setFundDescription({
      color: productTabColor,
      nameShort: productName,
      description: fundDescription,
    }));
    yield put({ type: GET_DATA_CONTRACT_SUCCESS, payload: data });

    const { productId } = data;
    yield getAdditionalDocsList({ payload: productId });
  } catch (error) {
    if (history) history.push(home);
    yield put({ type: GET_DATA_CONTRACT_FAIL, payload: getError(error) });
  }
}

function* setAnnulStatus({ payload }) {
  try {
    const {
      data: {
        refInsStatusesName,
        refInsStatusesCode,
      },
    } = yield apiCall({
      type: 'POST',
      url: API.UPDATE_CONTRACT_STATUS_ANNUL(payload),
    });

    yield put({
      type: UPDATE_STATUS_SUCCESS,
      payload: {
        status: refInsStatusesName,
        statusCode: refInsStatusesCode,
      },
    });

    toastr.success('', CONTRACT_ANNULLED);
  } catch (e) {
    yield put({ type: UPDATE_STATUS_FAIL, payload: getError(e) });
  }
}

function* saveScan({
  payload: {
    ctsId,
    scanParams,
    partnerId,
    isPrintForm,
  },
}) {
  const { SAVE_SCAN } = API;
  const history = getGlobalHistory();

  try {
    yield apiCall({
      type: 'POST',
      body: { ctsId, scanParams, partnerId },
      url: SAVE_SCAN,
    });

    yield put(changeQueryParams({
      riskProfile: null,
      masterId: null,
      qualification: null,
    }));

    yield put(setUnitedFrontendParams({
      riskProfile: null,
      masterId: null,
      qualification: null,
    }));

    yield put({
      type: UPDATE_STATUS_SUCCESS,
      payload: {
        status: REF_INS_STATUSES_NAME_ENUM.DOCUMENT_SIGNED,
        statusCode: REF_INS_STATUSES_CODE_ENUM.DOCUMENT_SIGNED,
      },
    });

    if (!isPrintForm) {
      history.push(ROUTES.home);
    }

    toastr.success('', SIGN_CONTRACT_SUCCESS);
    yield put({ type: SAVE_SCAN_SUCCESS });
  } catch (error) {
    yield put({ type: SAVE_SCAN_FAIL, payload: getError(error) });
  }
}

function* getContractFile({ payload: { ctsId, statusType } }) {
  const { GET_CONTRACT_FILE } = API;
  const fileName = yield select(getContractNum);
  try {
    yield apiDownload({
      url: `${GET_CONTRACT_FILE}/${ctsId}`,
    }, `${fileName}.pdf`);

    if (statusType === REF_INS_STATUSES_CODE_ENUM.SAVED) {
      yield put({
        type: UPDATE_STATUS_SUCCESS,
        payload: {
          status: REF_INS_STATUSES_NAME_ENUM.DOCUMENT_FORMED,
          statusCode: REF_INS_STATUSES_CODE_ENUM.DOCUMENT_FORMED,
        },
      });
    }

    yield put({ type: GET_CONTRACT_FILE_SUCCESS });
  } catch (error) {
    yield put({ type: GET_CONTRACT_FILE_FAIL, payload: getError(error) });
  }
}

function* getRegenerateFile({ payload }) {
  const { GET_REGENERATE_FILE } = API;
  const fileName = yield select(getContractNum);
  try {
    yield apiDownload({
      url: `${GET_REGENERATE_FILE}${payload}`,
    }, `${fileName}.pdf`);
  } catch (e) {
    yield put({ type: GET_REGENERATE_CONTRACT_FILE_FAIL, payload: getError(e) });
  }
}

function* getAdditionalDocsList({ payload }) {
  const { GET_ADDITIONAL_DOCS_LIST } = API;
  try {
    yield put({ type: GET_ADDITIONAL_DOCS_START });
    const { data } = yield apiCall({
      type: 'GET',
      query: { productId: payload },
      url: GET_ADDITIONAL_DOCS_LIST,
    });
    yield put({ type: GET_ADDITIONAL_DOCS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_ADDITIONAL_DOCS_FAIL, payload: getError(e) });
  }
}

function* downloadAdditionalDocument({ payload }) {
  try {
    const { DOWNLOAD_ADDITIONAL_DOC } = API;
    const {
      id,
      fileSystemPath,
      contractNum,
      insLineIszObjectId,
      ctsId,
    } = payload;

    const fileExt = _.last(fileSystemPath.split('.'));
    const fileName = `${contractNum}_dop.${fileExt}`;

    yield put({ type: DOWNLOAD_ADDITIONAL_FILE_START });
    yield apiDownload({
      url: DOWNLOAD_ADDITIONAL_DOC(id),
      query: {
        ctsId,
        insLineIszObjectId: insLineIszObjectId || undefined,
      },
    }, fileName);

    yield put({ type: DOWNLOAD_ADDITIONAL_FILE_SUCCESS });
  } catch (e) {
    yield put({ type: DOWNLOAD_ADDITIONAL_FILE_FAIL, payload: getError(e) });
  }
}

function* downloadPresentation({ payload }) {
  const {
    id,
    isConstructor,
    productPremiumFreqId,
    productTermId,
    mainRequestId,
    childRequestIds,
    insLineIszObjectId,
    productName,
    productTerm,
    productTermUnit,
    productCur,
    policySum,
    guaranteeLevel,
    premium,
  } = payload;

  const formattedTermUnit = dateFormatter(productTerm, productTermUnit)
    .split(' ')[1];

  try {
    yield put({ type: DOWNLOAD_PRESENTATION.start });
    yield apiDownload({
      url: API.DOWNLOAD_PRESENTATION(id),
      query: {
        isConstructor,
        productPremiumFreqId,
        productTermId,
        mainRequestId,
        childRequestIds,
        insLineIszObjectId,
        policySum,
        guaranteeLevel,
        premium,
      },
    }, `${productName}_${productTerm}_${formattedTermUnit}_${productCur}.pdf`);

    yield put({ type: DOWNLOAD_PRESENTATION.success });
  } catch (e) {
    yield put({ type: DOWNLOAD_PRESENTATION.fail });
  }
}

function* getSignInType({ payload }) {
  const { GET_SIGNING_TYPES } = API;
  try {
    yield put({ type: GET_SIGNING_TYPE.start });
    const { data } = yield apiCall({
      type: 'GET',
      query: { ctsId: payload },
      url: GET_SIGNING_TYPES,
    });
    const rebuildData = data.map((item) => ({
      key: item.id,
      value: item.code,
      text: item.name,
      isDefault: item.isDefault,
    }));
    yield put({ type: GET_SIGNING_TYPE.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_SIGNING_TYPE.fail });
  }
}

function* checkIsActiveConfirmButton({ payload }) {
  const { CHECK_IS_ACTIVE_CONFIRM } = API;
  try {
    yield put({ type: CHECK_IS_ACTIVE_CONFIRM_BUTTON.start });
    const { data } = yield apiCall({
      type: 'GET',
      query: { ctsId: payload },
      url: CHECK_IS_ACTIVE_CONFIRM,
    });
    yield put({ type: CHECK_IS_ACTIVE_CONFIRM_BUTTON.success, payload: data });
  } catch (e) {
    yield put({ type: CHECK_IS_ACTIVE_CONFIRM_BUTTON.fail });
  }
}

function* sentSms({ payload }) {
  const { SMS_SENT } = API;
  const {
    ctsId,
    isNeedScans,
  } = payload;

  try {
    yield put({ type: SENT_SMS.start });
    const { data } = yield apiCall({
      type: 'POST',
      body: { ctsId, isNeedScans },
      url: SMS_SENT,
    });
    yield put({ type: SENT_SMS.success, payload: data });
  } catch (e) {
    yield put({ type: SENT_SMS.fail });
  }
}

function* CheckoutSaga() {
  yield takeLatest(DOWNLOAD_ADDITIONAL_FILE_REQUEST, downloadAdditionalDocument);
  yield takeLatest(GET_ADDITIONAL_DOCS_REQUEST, getAdditionalDocsList);
  yield takeLatest(GET_CONTRACT_COPY_REQUEST, getContractCopy);
  yield takeLatest(GET_PRODUCTS_REQUEST, getProducts);
  yield takeLatest(CHECK_PRODUCT_REQUEST, checkProduct);
  yield takeLatest(GET_PRODUCT_FORM_REQUEST, getProductForm);
  yield takeLatest(CHECK_SUM_REQUEST, checkSum);
  yield takeLatest(SAVE_FORMS_REQUEST, saveForms);
  yield takeLatest(GET_DATA_CONTRACT_REQUEST, getDataContract);
  yield takeLatest(SAVE_SCAN_REQUEST, saveScan);
  yield takeLatest(GET_CONTRACT_FILE_REQUEST, getContractFile);
  yield takeLatest(GET_REGENERATE_CONTRACT_FILE_REQUEST, getRegenerateFile);
  yield takeLatest(CHECK_ACCOUNT_NUMBERS_REQUEST, checkAccountNumbers);
  yield takeLatest(SET_ANNUL_STATUS.request, setAnnulStatus);
  yield takeLatest(GET_ISZ_RESTIRCTIONS.request, getIszRestrictions);
  yield takeLatest(GET_CURRENT_COURSE.request, getCourseByRefExchangeRatesRegimesCoef);
  yield takeLatest(DOWNLOAD_PRESENTATION.request, downloadPresentation);
  yield takeLatest(GET_SIGNING_TYPE.request, getSignInType);
  yield takeLatest(CHECK_IS_ACTIVE_CONFIRM_BUTTON.request, checkIsActiveConfirmButton);
  yield takeLatest(SENT_SMS.request, sentSms);
}

export default CheckoutSaga;
