import React from 'react';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import './styles.scss';

const propTypes = {
  branch: PropTypes.string,
};

const defaultProps = {
  branch: '',
};

const branchVersion = 'alpha';
const alphaVersion = 'Alpha';

const SiteVersion = ({
  branch,
}) => (
  <div className="site-version-alpha">{branch === branchVersion ? alphaVersion : ''}</div>
);

SiteVersion.propTypes = propTypes;
SiteVersion.defaultProps = defaultProps;

const mapStateToProps = ({
  versionDp: {
    branch,
  },
}) => ({
  branch,
});

export default connect(mapStateToProps)(SiteVersion);
