import { useEffect } from 'react';

export const useEffectRedirect = (cb, disabledEffectUrls) => {
  useEffect(() => () => {
    const isExistUrl = disabledEffectUrls.some((url) => {
      const urlRegexpString = url.replace(/:[\w]+\//, '[\\w]+/');
      const urlRegexp = new RegExp(urlRegexpString);

      return urlRegexp.test(window.location.pathname);
    });

    if (!isExistUrl) {
      cb();
    }
  }, []);
};
