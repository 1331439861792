import { LANG_DICTIONARY } from 'consts';
import {
  EXPORT_1C_SUCCESS,
  EXPORT_1C_START,
  EXPORT_1C_FAIL,
  GET_CONTRACT_BY_NUMBER_SUCCESS,
  GET_INITIAL_STATE,
  GET_CONTRACT_CURRENT_STATE_SUCCESS,
  GET_CONTRACT_STATUSES_SUCCESS,
  GET_CONTRACT_BY_NUMBER_FAIL,
  SET_SELECT_VALUE,
  SET_PAYOUT_SUM_CONTRACT,
  ADD_PAYMENT_TO_CONTRACT,
  DELETE_PAYMENT_FROM_CONTRACT,
  SET_DOWNLOAD_FILE_FROM_1C,
  IMPORT_MANUAL_WEBSOCKET_START,
  IMPORT_MANUAL_WEBSOCKET_SUCCESS,
  IMPORT_MANUAL_WEBSOCKET_FAIL,
  SET_INIT_STORE_IMPORT_MANUAL,
  RESET_TRANSFER_DATA,
  IMPORT_MANUAL_WEBSOCKET_ERROR,
} from '../types';

const { NOT_CHOOSED } = LANG_DICTIONARY;
const INITIAL_STATE = {
  isLoadingExport1C: false,
  isDownloadFileFrom1C: false,
  isExportAdditionalForms: false,
  isOverwrite: false,
  contractInfo: {},
  statuses: [],
  currentStates: [],
  contractNotFounded: true,
  isImportManualLoading: false,
  isSuccessImport: false,
  errors: [],
  isAddedTestCts: false,
  fileName: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case IMPORT_MANUAL_WEBSOCKET_START:
      return {
        ...state,
        isImportManualLoading: true,
      };

    case IMPORT_MANUAL_WEBSOCKET_ERROR:
      return {
        ...state,
        isImportManualLoading: false,
        error: payload,
      };

    case IMPORT_MANUAL_WEBSOCKET_FAIL:
      return {
        ...state,
        errors: [...payload],
        isImportManualLoading: false,
      };

    case IMPORT_MANUAL_WEBSOCKET_SUCCESS:
      return {
        ...state,
        fileName: payload.fileName,
        isSuccessImport: true,
        isImportManualLoading: false,
      };

    case SET_INIT_STORE_IMPORT_MANUAL:
      return {
        ...state,
        errors: [],
        isImportManualLoading: false,
        isSuccessImport: false,
      };

    case SET_DOWNLOAD_FILE_FROM_1C: {
      const { name, type: value } = payload;

      return {
        ...state,
        [name]: value,
      };
    }

    case ADD_PAYMENT_TO_CONTRACT: {
      const { contractInfo, contractInfo: { payouts } } = state;

      return {
        ...state,
        contractInfo: {
          ...contractInfo,
          payouts: [
            ...payouts,
            payload,
          ],
        },
      };
    }

    case DELETE_PAYMENT_FROM_CONTRACT: {
      const { contractInfo } = state;

      return {
        ...state,
        contractInfo: {
          ...contractInfo,
          payouts: payload,
        },
      };
    }

    case SET_PAYOUT_SUM_CONTRACT: {
      const { contractInfo } = state;

      return {
        ...state,
        contractInfo: {
          ...contractInfo,
          payouts: payload,
        },
      };
    }

    case GET_CONTRACT_BY_NUMBER_FAIL: {
      const { statuses, currentStates } = state;

      return {
        ...INITIAL_STATE,
        statuses,
        currentStates,
      };
    }

    case SET_SELECT_VALUE: {
      const { contractInfo } = state;
      const { name, value } = payload;

      return {
        ...state,
        contractInfo: {
          ...contractInfo,
          [name]: value,
        },
      };
    }

    case GET_CONTRACT_STATUSES_SUCCESS: {
      return {
        ...state,
        statuses: payload,
      };
    }

    case GET_CONTRACT_CURRENT_STATE_SUCCESS: {
      return {
        ...state,
        currentStates: [...payload,
          {
            id: null,
            text: NOT_CHOOSED,
            value: null,
          },
        ],
      };
    }

    case GET_INITIAL_STATE: {
      return {
        ...INITIAL_STATE,
      };
    }

    case GET_CONTRACT_BY_NUMBER_SUCCESS: {
      return {
        ...state,
        contractInfo: {
          status: payload.statusId,
          currentState: payload.commonPaymentConditionId,
          payouts: payload.payments,
          ctsId: payload.ctsId,
          paymentFreq: payload.paymentFreq || '',
          currencyShort: payload.currencyShort || '',
          dateCancel: payload.dateCancel ? new Date(payload.dateCancel) : '',
          dateHave: '',
        },
        contractNotFounded: false,
      };
    }

    case EXPORT_1C_START:
      return {
        ...state,
        isLoadingExport1C: true,
      };

    case EXPORT_1C_SUCCESS:
      return {
        ...state,
        isLoadingExport1C: false,
      };

    case EXPORT_1C_FAIL:
      return {
        ...state,
        isLoadingExport1C: false,
        error: payload,
      };

    case RESET_TRANSFER_DATA:
      return {
        ...INITIAL_STATE,
      };

    default:
      return {
        ...state,
      };
  }
};
