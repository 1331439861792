import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const defaultProps = {
  nameCP: '',
  treeName: '',
  userLastName: '',
  userFirstName: '',
  userMiddleName: '',
};

const propTypes = {
  nameCP: PropTypes.string,
  treeName: PropTypes.string,
  userLastName: PropTypes.string,
  userFirstName: PropTypes.string,
  userMiddleName: PropTypes.string,
  userLogin: PropTypes.string.isRequired,
};

const b = block('userInfo');

class UserInfo extends Component {
  renderUserItem = ({ label, value }) => (value ? <div className={b('userInfo')}>{`${label} ${value}`}</div> : null);

  getUserItem = (item) => item || '';

  render() {
    const {
      nameCP,
      treeName,
      userLastName,
      userFirstName,
      userMiddleName,
      userLogin,
    } = this.props;

    const { PARTNER, SUBDIVISION, USER } = LANG_DICTIONARY;
    const RenderUserItem = this.renderUserItem;
    const userName = `${this.getUserItem(userLastName)} ${this.getUserItem(userFirstName)} ${this.getUserItem(userMiddleName)} (${userLogin})`;

    return (
      <div className={b()}>
        <RenderUserItem label={`${PARTNER}:`} value={nameCP} />
        <RenderUserItem label={`${SUBDIVISION}:`} value={treeName} />
        <RenderUserItem label={`${USER}:`} value={userName} />
      </div>
    );
  }
}

UserInfo.propTypes = propTypes;
UserInfo.defaultProps = defaultProps;

function mapStateToProps({ authReducer }) {
  return { ...authReducer };
}

export default connect(mapStateToProps, {})(UserInfo);
