import React from 'react';
import PropTypes from 'prop-types';
import ReactTooltip from 'react-tooltip';
import block from 'bem-cn';
import './styles.scss';

const propTypes = {
  tooltipName: PropTypes.string.isRequired,
  data: PropTypes.shape({
    text: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }).isRequired,
};

const b = block('tooltip');

const RenderTooltip = ({ data: { title, text }, tooltipName }) => (
  <ReactTooltip
    id={tooltipName}
    offset={{ left: 5 }}
    delayHide={500}
    delayShow={500}
    delayUpdate={500}
    place="right"
    type="light"
    effect="solid"
  >
    <div className={b()}>
      <div className={b('title')}>{ title }</div>
      {/* eslint-disable-next-line */}
        { text.map((tooltip, index) => <p className={b('text')} key={index}>{ tooltip }</p>) }
    </div>
  </ReactTooltip>
);

RenderTooltip.propTypes = propTypes;

export default RenderTooltip;
