import { createSelector } from 'reselect';
import _, { uniqBy, sortBy } from 'lodash';
import { getFormattedDate } from 'helpers';
import MultiSort from 'multi-sort';
import { DATE_CRETE_FORMAT_CONSTRUCTOR } from 'consts';
import moment from 'moment';

const checkSpread = (value) => (value ? [{ title: value }] : []);
const iterateFuncSort = (item) => item.title;
const sortByValue = (data) => sortBy(uniqBy(data, iterateFuncSort), iterateFuncSort);

const toConstructorDateCreateFormat = (val) => moment(val).format(DATE_CRETE_FORMAT_CONSTRUCTOR);

export const getOptionTypeId = (opt) => {
  const sortOptions = MultiSort(opt, ['default', 'value'], ['DESC', 'ASC']);
  const firstOptionDefault = _.get(sortOptions, '0.default', false);

  if (!firstOptionDefault) {
    return null;
  }

  return sortOptions[0].value;
};

const getCalculatedBaskets =
  ({ constructorIszInterface: { calculatedBaskets } }) => calculatedBaskets;
export const getSortDataCalculatedBaskets =
  ({ constructorIszInterface: { sort: { calculatedBaskets } } }) => calculatedBaskets;

export const getValueOption = ({
  constructorIszInterface: {
    guaranteeLevel: {
      valueOption,
    },
  },
}) => valueOption;

export const getFilterDataCalculatedBasketsSelector = createSelector(
  ({ constructorIszInterface: { filter: { calculatedBaskets } } }) => calculatedBaskets,
  (data) => data,
);

const generatePreEnterData = (data) => {
  const rebuildData = data.reduce((acc, item) => ({
    basket: [
      ...acc.basket,
      ...checkSpread(item.basket),
    ],
    term: [
      ...acc.term,
      ...checkSpread(item.term),
    ],
    pay: [
      ...acc.pay,
      ...checkSpread(item.pay),
    ],
    barriers: [
      ...acc.barriers,
      ...checkSpread(item.barriers),
    ],
    coupon: [
      ...acc.coupon,
      ...checkSpread(item.coupon),
    ],
    dateEnd: [
      ...acc.dateEnd,
      ...checkSpread(getFormattedDate(item.dateEnd)),
    ],
    status: [
      ...acc.status,
      ...checkSpread(item.status),
    ],
    partitionCoeff: [
      ...acc.partitionCoeff,
      ...checkSpread(item.partitionCoeff),
    ],
    dateCreate: [
      ...acc.dateCreate,
      ...checkSpread(toConstructorDateCreateFormat(item.dateCreate)),
    ],
  }),
  {
    dateCreate: [],
    partitionCoeff: [],
    basket: [],
    term: [],
    barriers: [],
    coupon: [],
    dateEnd: [],
    status: [],
    pay: [],
  });

  return {
    partitionCoeff: sortByValue(rebuildData.partitionCoeff),
    basket: sortByValue(rebuildData.basket),
    term: sortByValue(rebuildData.term),
    barriers: sortByValue(rebuildData.barriers),
    coupon: sortByValue(rebuildData.coupon),
    dateEnd: sortByValue(rebuildData.dateEnd),
    status: sortByValue(rebuildData.status),
    pay: sortByValue(rebuildData.pay),
    dateCreate: sortByValue(rebuildData.dateCreate),
  };
};

export const getPreEnterCalculatedBasketsSelector = createSelector(
  getCalculatedBaskets,
  generatePreEnterData,
);

const checkIncludes = (value, searchValue) => (!searchValue ||
  String(value).toLowerCase().includes(String(searchValue).toLowerCase()));

const sortByAlphabet = (tableData, sortData, filter = {}) => {
  const sortDataArray = Object.entries(sortData)
    .sort((a, b) => (a[1].time > b[1].time ? 1 : -1))
    .reduce((acc, item) => ({
      fields: [...acc.fields, ...(item[1].payload !== null ? [item[0]] : [])],
      sorts: [...acc.sorts, ...(item[1].payload !== null ? [item[1].payload] : [])],
    }), {
      fields: [],
      sorts: [],
    });

  const copyTableData = tableData.filter((item) => checkIncludes(item.basket, filter.basket) &&
      checkIncludes(item.term, filter.term) &&
      checkIncludes(item.pay, filter.pay) &&
      checkIncludes(item.barriers, filter.barriers) &&
      checkIncludes(item.coupon, filter.coupon) &&
      checkIncludes(getFormattedDate(item.dateEnd), filter.dateEnd) &&
      checkIncludes(item.status, filter.status) &&
      checkIncludes(item.partitionCoeff, filter.partitionCoeff) &&
      checkIncludes(toConstructorDateCreateFormat(item.dateCreate), filter.dateCreate));

  return MultiSort(copyTableData, sortDataArray.fields, sortDataArray.sorts);
};

export const getSortCalculatedBaskets = createSelector(
  getCalculatedBaskets,
  getSortDataCalculatedBaskets,
  getFilterDataCalculatedBasketsSelector,
  sortByAlphabet,
);
