import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { CURRENCY_CODE_REF, LANG_DICTIONARY } from 'consts';
import { separateThousands } from 'helpers';
import template from './template';
import FlexTable from '../../../../_tables/FlexTable';
import './styles.scss';

const b = block('min-insurance-amount-table');

const {
  MIN_INSURANCE_AMOUNT_TABLE_VERSION,
  MIN_INSURANCE_AMOUNT_TABLE_VALUE,
} = LANG_DICTIONARY;

const CURRENCY_SHORT = {
  [CURRENCY_CODE_REF.DOLLAR]: 'долл. США',
  [CURRENCY_CODE_REF.ROUBLE]: 'руб.',
  [CURRENCY_CODE_REF.EURO]: 'евро',
};

const headers = [
  MIN_INSURANCE_AMOUNT_TABLE_VERSION,
  MIN_INSURANCE_AMOUNT_TABLE_VALUE,
];

const propTypes = {
  minInsuranceAmountData: PropTypes.array,
  currencyCode: PropTypes.string.isRequired,
};

const defaultProps = {
  minInsuranceAmountData: [],
};

const MinInsuranceAmountTable = (props) => {
  const {
    minInsuranceAmountData,
    currencyCode,
  } = props;

  const rebuildData = minInsuranceAmountData
    .map(({
      minValue,
      versionName,
    }) => template(headers, [
      versionName,
      <div className={b('amount')}>
        <p>
          {separateThousands(minValue)}
          {' '}
          {CURRENCY_SHORT[currencyCode]}
        </p>
      </div>,
    ]));

  return (
    <FlexTable data={rebuildData} minHeight={40} />
  );
};

MinInsuranceAmountTable.propTypes = propTypes;
MinInsuranceAmountTable.defaultProps = defaultProps;

export default MinInsuranceAmountTable;
