import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Button } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('bonus-button');

const propTypes = {
  onClick: PropTypes.func,
  className: PropTypes.string,
};

const defaultProps = {
  onClick: () => null,
  className: '',
};

const BonusBtn = ({
  onClick,
  className,
}) => (
  <Button
    type="button"
    onClick={onClick}
    className={b.mix(className).toString()}
  >
    {`${LANG_DICTIONARY.BONUS} >`}
  </Button>
);

BonusBtn.propTypes = propTypes;
BonusBtn.defaultProps = defaultProps;

export default React.memo(BonusBtn);
