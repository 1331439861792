import { UNDERWRITING_STORE_LINK } from 'consts';

const {
  contractData: contractProp,
  insurerData: insurerProp,
  insuredData: insuredProp,
  beneficiaryData: beneficiaryProp,
} = UNDERWRITING_STORE_LINK;

const getSomePerson = (type, data, prop) => data && data
  .map((item, index) => ({
    title: data.length > 1 ? `${type} ${index + 1}` : type,
    prop,
    index,
  }));

export default (data) => {
  const {
    insuredData,
    beneficiaryData,
  } = data;

  return [
    {
      title: 'Договор',
      prop: contractProp,
    },
    {
      title: 'Страхователь',
      prop: insurerProp,
    },
    ...getSomePerson('Застрахованный', insuredData, insuredProp),
    ...getSomePerson('Выгодоприобретатель', beneficiaryData, beneficiaryProp),
  ];
};
