import React from 'react';
import PropTypes from 'prop-types';
import {
  FlexTable,
  WarningPopUp,
  EditJobPopUp,
} from 'components';
import { Select, Icon, Popup } from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, MASK } from 'consts';
import moment from 'moment/moment';
import EditIcon from 'assets/images/edit-icon.png';
import Play from 'assets/images/uploadImg.png';
import {
  activeTemplate,
  proccessTemplate,
  completeTemplate,
  failTemplate,
} from './template';
import './styles.scss';

const { schedulerFormatDate } = MASK;
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  activeTab: PropTypes.string,
  dictionary: PropTypes.object,
  prioritiSelect: PropTypes.arrayOf(PropTypes.object),
  handleChangeSelect: PropTypes.func,
  popUpOpenEdit: PropTypes.bool,
  popUpOpenStop: PropTypes.bool,
  saveButtonPopUp: PropTypes.func,
  popUpControl: PropTypes.func,
  stopJobControl: PropTypes.func,
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  cronTypeId: PropTypes.number,
  interval: PropTypes.number,
  timeStart: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  taskType: PropTypes.string,
  priorityId: PropTypes.number,
  timeForPopup: PropTypes.string,
};

const defaultProps = {
  data: [],
  activeTab: '',
  dictionary: {},
  prioritiSelect: [],
  handleChangeSelect: () => null,
  popUpOpenEdit: false,
  popUpOpenStop: false,
  saveButtonPopUp: () => null,
  popUpControl: () => null,
  stopJobControl: () => null,
  cronTypeSelect: [],
  cronTypeId: 0,
  interval: 0,
  timeStart: '',
  id: 0,
  isActive: false,
  taskType: '',
  priorityId: 0,
  timeForPopup: 0,
};

const {
  ID,
  PRIORITY_WORD,
  DESCRIPTION_WORD,
  TIME_START_WORD,
  CONTROL_START_PAUSE_JOB,
  NUMBER_OF_TRYIES,
  TIME_END_WORD,
  ERROR_TEXT_WORD,
  TYPE,
} = LANG_DICTIONARY;

const FlexTableScheduler = ({
  data,
  activeTab,
  dictionary,
  prioritiSelect,
  handleChangeSelect,
  popUpOpenEdit,
  popUpOpenStop,
  saveButtonPopUp,
  popUpControl,
  stopJobControl,
  cronTypeSelect,
  cronTypeId: cron,
  interval: intervalPopUp,
  timeStart: timeStartPopUp,
  id: idPopUp,
  isActive: active,
  taskType: taskTypePopUp,
  priorityId: priorityIdPopUp,
  timeForPopup,
}) => {
  const activeHeaders = [
    TYPE,
    PRIORITY_WORD,
    DESCRIPTION_WORD,
    TIME_START_WORD,
    CONTROL_START_PAUSE_JOB,
  ];
  const proccessHeaders = [
    ID,
    TYPE,
    PRIORITY_WORD,
    DESCRIPTION_WORD,
    TIME_START_WORD,
    NUMBER_OF_TRYIES,
  ];
  const completeHeaders = [
    ID,
    TYPE,
    PRIORITY_WORD,
    DESCRIPTION_WORD,
    TIME_START_WORD,
    TIME_END_WORD,
    NUMBER_OF_TRYIES,
  ];
  const failHeaders = [
    ID,
    TYPE,
    PRIORITY_WORD,
    DESCRIPTION_WORD,
    TIME_START_WORD,
    TIME_END_WORD,
    NUMBER_OF_TRYIES,
    ERROR_TEXT_WORD,
  ];

  const b = block('scheduler-admin-table');

  const getData = (tab, info) => {
    switch (tab) {
      case dictionary.ACTIVE: {
        return info.map(({
          cronTypeId,
          description,
          id,
          interval,
          isActive,
          priorityId,
          taskType,
          timeStart,
          time,
        }) => activeTemplate(activeHeaders, [
          taskType,
          <Select
            options={prioritiSelect}
            value={priorityId}
            onChange={(e, { value }) => handleChangeSelect(
              id,
              'priorityId',
              value,
              dictionary.PRIORITY,
              isActive,
              {
                interval,
                cronTypeId,
                taskType,
                time,
                timeStart,
              },
            )}
          />,
          description,
          <div className={b('edit-button')}>
            { timeStart && moment(timeStart).format(schedulerFormatDate) }
            <div
              onClick={() => popUpControl(
                true,
                cronTypeId,
                interval,
                timeStart,
                id,
                isActive,
                taskType,
                priorityId,
                time,
              )}
              tabIndex="-1"
              role="button"
              className={b('edit-button-icon')}
            >
              <img
                src={EditIcon}
                className={b('edit-icon')}
                alt="edit-icon"
              />
            </div>
          </div>,
          <div>
            {
              isActive
                ? (
                  <div className={b('pause-button')}>
                    <Icon
                      name="pause"
                      onClick={() => stopJobControl(
                        isActive,
                        {
                          taskType,
                        },
                      )}
                    />
                  </div>
                )
                : (
                  <div
                    onClick={() => stopJobControl(
                      isActive,
                      {
                        interval,
                        cronTypeId,
                        taskType,
                        time,
                        priorityId,
                        timeStart,
                      },
                    )}
                    tabIndex="-1"
                    role="button"
                    className={b('edit-button-icon')}
                  >
                    <img
                      src={Play}
                      className={b('edit-icon')}
                      alt="edit-icon"
                    />
                  </div>
                )
            }
          </div>,

        ]));
      }
      case dictionary.PROCCESS: {
        return info.map(({
          description,
          id,
          priority,
          taskType,
          timeStart,
          attempts,
        }) => proccessTemplate(proccessHeaders, [
          id,
          taskType,
          priority,
          description,
          timeStart && moment(timeStart).format(schedulerFormatDate),
          attempts,
        ]));
      }
      case dictionary.COMPLETE: {
        return info.map(({
          description,
          id,
          priority,
          taskType,
          timeStart,
          timeEnd,
          attempts,
        }) => completeTemplate(completeHeaders, [
          id,
          taskType,
          priority,
          description,
          timeStart && moment(timeStart).format(schedulerFormatDate),
          timeEnd && moment(timeEnd).format(schedulerFormatDate),
          attempts,
        ]));
      }
      case dictionary.FAIL: {
        return info.map(({
          description,
          id,
          priority,
          taskType,
          timeStart,
          timeEnd,
          attempts,
          error,
        }) => failTemplate(failHeaders, [
          id,
          taskType,
          priority,
          description,
          timeStart && moment(timeStart).format(schedulerFormatDate),
          timeEnd && moment(timeEnd).format(schedulerFormatDate),
          attempts,
          <Popup
            trigger={<Icon name="warning sign" />}
            children={(
              <div className={b('popup-error').toString()}>
                {error}
              </div>
              )}
            className={b('popup-item').toString()}
            pinned
            on="click"
            hoverable
            position="top center"
          />,
        ]));
      }
      default: {
        return [];
      }
    }
  };
  const rebuildData = getData(activeTab, data);

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
      {popUpOpenEdit
            && (
            <EditJobPopUp
              cronTypeId={cron}
              interval={intervalPopUp}
              timeStart={timeStartPopUp}
              id={idPopUp}
              status={active}
              taskType={taskTypePopUp}
              priorityId={priorityIdPopUp}
              saveButtonPopUp={saveButtonPopUp}
              isOpen={popUpOpenEdit}
              cronTypeSelect={cronTypeSelect}
              popUpControl={popUpControl}
              time={timeForPopup}
              popUp
            />
            )}
      { popUpOpenStop && (
      <WarningPopUp
        isOpen={popUpOpenStop}
        jobConrol={stopJobControl}
        taskType={taskTypePopUp}
      />
      )}
    </div>
  );
};

FlexTableScheduler.propTypes = propTypes;
FlexTableScheduler.defaultProps = defaultProps;
export default FlexTableScheduler;
