import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import {
  LANG_DICTIONARY,
  PRODUCT_VERSION,
  NSZ_CALCULATOR_ENUMS,
} from 'consts';
import {
  Hint,
  DateCustomPicker,
  RisksList,
  MainBtn,
  CalculatedValues,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import './styles.scss';
import { Popup } from 'semantic-ui-react';

const defaultProps = {
  countNszSum: () => null,
  values: {},
  currencySelect: [],
  onChangeBirthDate: () => null,
  scenario: '',
  basicRisks: [],
  productTerms: [],
  genderList: [],
  premiumFreq: [],
  productCalcTypes: [],
  listControl: () => null,
  risksLists: {},
  handleChangeSelect: () => null,
  onChangeBirthDateChild: () => null,
  handleChangeSelectCurrency: () => null,
  handleChangeSelectTerm: () => null,
  onChangeRegularEnter: () => null,
  onChangeSelectCalcType: () => null,
  onChangeNszSum: () => null,
  handleChangeAdditionalRisk: () => null,
  handleChangeCheckBoxAdditionalRisk: () => null,
  handleControlPackageRisks: () => null,
  onBlurRisksInput: () => null,
  productTip: '',
  goNextForm: () => null,
  contentType: '',
  productSelection: {},
  onRisksInput: () => null,
};

const propTypes = {
  contentType: PropTypes.string,
  goNextForm: PropTypes.func,
  countNszSum: PropTypes.func,
  onChangeNszSum: PropTypes.func,
  values: PropTypes.object,
  currencySelect: PropTypes.arrayOf(PropTypes.object),
  onChangeBirthDate: PropTypes.func,
  scenario: PropTypes.string,
  basicRisks: PropTypes.arrayOf(PropTypes.object),
  productTerms: PropTypes.arrayOf(PropTypes.object),
  genderList: PropTypes.arrayOf(PropTypes.object),
  premiumFreq: PropTypes.arrayOf(PropTypes.object),
  productCalcTypes: PropTypes.arrayOf(PropTypes.object),
  listControl: PropTypes.func,
  risksLists: PropTypes.object,
  onChangeBirthDateChild: PropTypes.func,
  handleChangeSelectCurrency: PropTypes.func,
  handleChangeSelectTerm: PropTypes.func,
  onChangeRegularEnter: PropTypes.func,
  onChangeSelectCalcType: PropTypes.func,
  handleChangeSelect: PropTypes.func,
  handleChangeAdditionalRisk: PropTypes.func,
  handleChangeCheckBoxAdditionalRisk: PropTypes.func,
  handleControlPackageRisks: PropTypes.func,
  onBlurRisksInput: PropTypes.func,
  productTip: PropTypes.string,
  handleChangeGenderChild: PropTypes.func.isRequired,
  handleChangeGender: PropTypes.func.isRequired,
  downloadPrintPayment: PropTypes.func.isRequired,
  productSelection: PropTypes.object,
  onRisksInput: PropTypes.func,
};

const {
  CONTRACT_CURRENCY,
  BIRTH_DATE,
  CHILD_BIRTH_DATE,
  BASIC_RISKS_PACKAGE,
  CONTRACT_TEMP,
  ADDITIONAL_RISKS,
  GENDER,
  CHILD_GENDER,
  PAYOUT_PERIODS_CONTRACT,
  COUNT_TYPE,
  SIZE_REGULAR_ENTER,
  AMOUNT_SUM,
  COUNT_WORD,
  NEXT,
  PRINT_PAYMENT,
} = LANG_DICTIONARY;

const b = block('nsz-form');

const RISK_TYPES = {
  basic: 'basic',
  additional: 'additional',
  package: 'package',
  insuranceSums: 'insuranceSums',
  standart: 'standart',
};

const CALC_TYPES = {
  from_premium: 'from_premium',
  from_sum: 'from_sum',
};

const {
  refCurrencyId,
  insuredBirthDate,
  childInsuredBirthDate,
  termId,
  insuredSexId,
  childInsuredSexId,
  premiumFreqId,
  productCalcType,
  regularPaymentAmount,
  accumulationAmount,
} = NSZ_CALCULATOR_ENUMS;

const NszForm = ({
  values,
  currencySelect,
  onChangeBirthDate,
  scenario,
  basicRisks,
  productTerms,
  genderList,
  premiumFreq,
  productCalcTypes,
  listControl,
  risksLists,
  onChangeBirthDateChild,
  handleChangeSelectCurrency,
  handleChangeSelectTerm,
  onChangeRegularEnter,
  onChangeNszSum,
  onChangeSelectCalcType,
  handleChangeSelect,
  handleChangeAdditionalRisk,
  handleControlPackageRisks,
  handleChangeCheckBoxAdditionalRisk,
  countNszSum,
  onBlurRisksInput,
  productTip,
  contentType,
  goNextForm,
  handleChangeGenderChild,
  handleChangeGender,
  downloadPrintPayment,
  productSelection,
  onRisksInput,
}) => {
  const isNszChild = scenario === PRODUCT_VERSION.nsz_2;
  const disableBirthDate = !values.refCurrencyId;
  const disableChildBirthDate = disableBirthDate || !values.insuredBirthDate;
  const disableTerms = disableChildBirthDate || (isNszChild && !values.childInsuredBirthDate);
  const disableRegularEnter = !values.productCalcType
  || (values.productCalcType !== CALC_TYPES.from_premium);
  const disableNszSum = !values.productCalcType
  || (values.productCalcType !== CALC_TYPES.from_sum);
  const disableProductCalcType = !values.premiumFreqId;
  const disableNextButton = !values.insuranceSums.length || !productSelection.status;
  const showAdditionalRisks = Boolean(values.termId) && contentType === RISK_TYPES.standart;

  const sumsValue = values.productCalcType === CALC_TYPES.from_premium ?
    parseFloat(values.regularPaymentAmount) : Number(values.accumulationAmount);
  const isDisableCalculateButton = !sumsValue;

  const isDisableCountButton = values.additionalRisks.find(
    (item) => !item.value || item.value > item.maxWeight || item.value < item.minWeight,
  );

  const setValidAdditionalRisks = () => {
    if (values.additionalRisks) {
      const additionalRisksErrors = values.additionalRisks.map(
        (risk, index) => onRisksInput(values.additionalRisks[index]),
      );

      return additionalRisksErrors.reduce((acc, el, idx) => {
        if (el) {
          const key = `additionalRisk${idx}`;
          acc[key] = el[key];
        }

        return acc;
      }, {});
    }

    return null;
  };

  return (
    <div className={b()}>
      <div className={b('content')}>
        <div className={b('form', 'inputs')}>
          <SelectInput
            label={CONTRACT_CURRENCY}
            placeholder={CONTRACT_CURRENCY}
            options={currencySelect}
            name={refCurrencyId}
            values={values}
            onChange={handleChangeSelectCurrency}
          />

          <DateCustomPicker
            label={BIRTH_DATE}
            placeholder={BIRTH_DATE}
            name={insuredBirthDate}
            disabled={disableBirthDate}
            values={values}
            onChange={onChangeBirthDate}
            errors={values.errors}
          />
          {
      isNszChild && (
        <DateCustomPicker
          label={CHILD_BIRTH_DATE}
          placeholder={CHILD_BIRTH_DATE}
          name={childInsuredBirthDate}
          values={values}
          disabled={disableChildBirthDate}
          onChange={onChangeBirthDateChild}
          errors={values.errors}
        />
      )
}
          <RisksList
            list={basicRisks}
            type={RISK_TYPES.basic}
            title={BASIC_RISKS_PACKAGE}
            open={risksLists.basic}
            listControl={listControl}
            name={RISK_TYPES.basic}
          />
          <SelectInput
            label={CONTRACT_TEMP}
            placeholder={CONTRACT_TEMP}
            options={productTerms}
            name={termId}
            values={values}
            errors={values.errors}
            isAlwaysTouched
            disabled={disableTerms}
            onChange={handleChangeSelectTerm}
          />
          {showAdditionalRisks && (
            <RisksList
              list={values.additionalRisks}
              type={RISK_TYPES.additional}
              title={ADDITIONAL_RISKS}
              open
              listControl={listControl}
              onChange={handleChangeAdditionalRisk}
              name={RISK_TYPES.additional}
              errors={values.riskErrors}
              hints={setValidAdditionalRisks()}
              onChangeCheckbox={handleChangeCheckBoxAdditionalRisk}
              onBlur={onBlurRisksInput}
            />
          )}
          {Boolean(values.packageRisks.length) && (
            <RisksList
              list={values.packageRisks}
              type={RISK_TYPES.package}
              title={ADDITIONAL_RISKS}
              open={risksLists.package}
              listControl={listControl}
              onChange={handleControlPackageRisks}
              name={RISK_TYPES.package}
            />
          )}
          <SelectInput
            label={GENDER}
            placeholder={GENDER}
            options={genderList}
            name={insuredSexId}
            values={values}
            onChange={handleChangeGender}
          />
          {isNszChild && (
          <SelectInput
            label={CHILD_GENDER}
            placeholder={CHILD_GENDER}
            options={genderList}
            name={childInsuredSexId}
            values={values}
            onChange={handleChangeGenderChild}
          />
          )}
          <SelectInput
            label={PAYOUT_PERIODS_CONTRACT}
            placeholder={PAYOUT_PERIODS_CONTRACT}
            options={premiumFreq}
            name={premiumFreqId}
            values={values}
            onChange={handleChangeSelect}
          />
          <SelectInput
            label={COUNT_TYPE}
            placeholder={COUNT_TYPE}
            options={productCalcTypes}
            name={productCalcType}
            values={values}
            onChange={onChangeSelectCalcType}
            disabled={disableProductCalcType}
          />
          <TextInput
            name={regularPaymentAmount}
            type="text"
            label={SIZE_REGULAR_ENTER}
            placeholder={SIZE_REGULAR_ENTER}
            error={values.errors.regularPaymentAmount}
            values={values}
            onChange={onChangeRegularEnter}
            touched={values.touched}
            disabled={disableRegularEnter}
            currency
            customCurrency
          />
          <TextInput
            name={accumulationAmount}
            type="text"
            label={AMOUNT_SUM}
            placeholder={AMOUNT_SUM}
            error={values.errors.accumulationAmount}
            values={values}
            onChange={onChangeNszSum}
            touched={values.touched}
            disabled={disableNszSum}
            currency
          />
          <div className={b('footer')}>
            <MainBtn
              text={COUNT_WORD}
              onClick={countNszSum}
              disabled={
                values.disableCountButton
                || isDisableCalculateButton
                || isDisableCountButton
              }
            />
            <MainBtn
              text={PRINT_PAYMENT}
              onClick={downloadPrintPayment}
              className={b('print-payment')}
              disabled={!values.insuranceSums.length}
            />
          </div>
        </div>
        <Hint tipText={productTip} />
      </div>
      <div className={b('form', 'calculated-wrapper')}>
        {Boolean(values.insuranceSums.length) && (
          <RisksList
            insuranceSums={values.insuranceSums}
            type={RISK_TYPES.insuranceSums}
            open
          />
        )}
      </div>
      <div className={b('form', 'calculated-wrapper')}>
        {
          Boolean(values.insuranceSums.length) &&
          (
            <CalculatedValues
              calculatedValues={values.calculatedValues}
            />
          )
        }
      </div>
      <div className={b('footer-next-button')}>
        <Popup
          content={productSelection.message}
          basic
          disabled={productSelection.status}
          trigger={(
            <div>
              <MainBtn
                text={NEXT}
                disabled={disableNextButton}
                onClick={goNextForm}
                className={b('next-button').toString()}
              />
            </div>
            )}
        />
      </div>
    </div>
  );
};

NszForm.defaultProps = defaultProps;
NszForm.propTypes = propTypes;
export default NszForm;
