import React, { Component } from 'react';
import moment from 'moment';
import block from 'bem-cn';
import { LANG_DICTIONARY, TIME_FORMAT } from 'consts';
import './styles.scss';

const b = block('currentTime');

class CurrentTime extends Component {
  constructor(props) {
    super(props);
    this.state = { currentTime: moment().format(TIME_FORMAT) };
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState({
        currentTime: moment().format(TIME_FORMAT),
      });
    }, 1000);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  getDate = () => {
    const { currentTime } = this.state;
    const timeZoneOffset = new Date().getTimezoneOffset();

    return ` ${currentTime} GMT +${-timeZoneOffset / 60}`;
  };

  render() {
    const { CURRENT_TIME } = LANG_DICTIONARY;

    return (
      <div className={b()}>
        {CURRENT_TIME}
        {this.getDate()}
      </div>
    );
  }
}

export default CurrentTime;
