import Scroll from 'react-scroll';

export default (errors, child = '') => {
  const keys = Object.keys(errors);
  const firstKey = keys[0];

  if (firstKey) {
    Scroll.scroller.scrollTo(`scroll${firstKey}${child}`, {
      duration: 500,
      smooth: true,
      offset: -100,
    });
  }
};
