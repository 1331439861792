import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('basket-strategy-title-button');

const propTypes = {
  iconName: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  text: PropTypes.string.isRequired,
  classNameIcon: PropTypes.string,
};

const defaultProps = {
  classNameIcon: '',
};

const StrategyTitleButton = ({
  iconName,
  text,
  onClick,
  classNameIcon,
}) => (
  <Button
    onClick={onClick}
    className={b().toString()}
  >
    <div className={b('content')}>
      <Icon name={iconName} className={b('icon').mix(classNameIcon).toString()} />
      <p className={b('text')}>{text}</p>
    </div>
  </Button>
);

StrategyTitleButton.propTypes = propTypes;
StrategyTitleButton.defaultProps = defaultProps;

export default StrategyTitleButton;
