import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { closeModalHistoryScan, getScan } from 'redux/rootActions';
import { connect } from 'react-redux';
import SaveIcon from 'assets/images/save-icon.png';
import { getFormattedDate } from 'helpers';
import { StatusScanChecking } from 'components';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('history-scans-popup');

const {
  DATE_CHECK,
  STATUS_CHECK,
  IMPORT_DATE,
  DOWNLOAD_SHORT,
  HISTORY_DOWNOLADED_DOCUMENTS,
} = LANG_DICTIONARY;

const propTypes = {
  history: PropTypes.arrayOf(PropTypes.object),
  downloadScan: PropTypes.func,
  historyScanModal: PropTypes.bool,
  fileType: PropTypes.string,
  close: PropTypes.func,
  left: PropTypes.number,
  top: PropTypes.number,
};

const defaultProps = {
  history: {},
  downloadScan: () => null,
  historyScanModal: false,
  fileType: '',
  close: () => null,
  left: 0,
  top: 0,
};

const heightRow = 46;
const heightHeader = 55;
const maxHeight = 600;

const HistoryScans = ({
  history,
  downloadScan,
  historyScanModal,
  fileType,
  close,
  left,
  top,
}) => {
  const veil = React.createRef();

  const closePopup = (e) => {
    if (e.target === veil.current) {
      close();
    }
  };

  const heighWithMax = Math.min(maxHeight, heightRow * history.length + heightHeader + 70);
  const topWindow = top - heighWithMax;

  return (
    <>
      {historyScanModal && (
      <div
        className={b()}
        ref={veil}
        onClick={closePopup}
        role="button"
        tabIndex="-1"
      >
        <div className={b('window')} style={{ top: topWindow, left: left + 20 }}>
          <p className={b('title')}>{`${HISTORY_DOWNOLADED_DOCUMENTS}: ${fileType}`}</p>
          <table className={b('table')}>
            <tbody>
              <tr>
                <th>{IMPORT_DATE}</th>
                <th>{DATE_CHECK}</th>
                <th>{STATUS_CHECK}</th>
                <th>{DOWNLOAD_SHORT}</th>
              </tr>
              {history.map(({
                importDate,
                checkFinishDate,
                checkStatusName,
                fileType: fileTypeInclude,
                fileName,
                id,
                checkStatusCode,
              }) => (
                <tr key={`${fileTypeInclude}${id}`}>
                  <td>{importDate ? getFormattedDate(importDate) : '-'}</td>
                  <td>{checkFinishDate ? getFormattedDate(checkFinishDate) : '-'}</td>
                  <td>
                    <div className={b('check-status')}>
                      <StatusScanChecking status={checkStatusCode} />
                      {checkStatusName}
                    </div>
                  </td>
                  <td>
                    <button
                      className={b('save-button')}
                      type="button"
                      onClick={() => downloadScan(id, fileName)}
                    >
                      <img src={SaveIcon} alt="save-icon" />
                    </button>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
      )}
    </>
  );
};

const mapStateToProps = ({
  popups: {
    historyScanModal,
    historyScanData,
  },
}) => ({
  ...historyScanData,
  historyScanModal,
});

const mapDispatchToProps = {
  close: closeModalHistoryScan,
  downloadScan: getScan,
};

HistoryScans.propTypes = propTypes;
HistoryScans.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(HistoryScans);
