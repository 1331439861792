import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';
import './styles.scss';

const b = block('risk-profile-error-popup');

const {
  BACK_WORD,
} = LANG_DICTIONARY;

const propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const RiskProfileErrorPopup = ({
  message,
  onClose,
  className,
}) => (
  <PopupContainer
    isOpen={Boolean(message)}
    close={onClose}
    className={className}
  >
    <div className={b()}>
      <p className={b('message')}>{message}</p>
    </div>
    <div className={b('btn-wrapper')}>
      <button
        className={b('btn-confirm')}
        type="button"
        onClick={onClose}
      >
        {BACK_WORD}
      </button>
    </div>
  </PopupContainer>
);

RiskProfileErrorPopup.propTypes = propTypes;
RiskProfileErrorPopup.defaultProps = defaultProps;
export default RiskProfileErrorPopup;
