import React, { useCallback } from 'react';
import { block } from 'bem-cn';
import { Input } from 'components';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import './styles.scss';
import FieldRow from '../FieldRow';
import StrategyTitleButton from '../StrategyTitleButton';
import { useEffectExceptOnMount } from '../../../../../../hooks/useEffectExceptOnMount';

const monthsInYear = 12;

const inputType = {
  term: 'paymentTerm',
  payment: 'value',
};

const b = block('guaranteed-payout');

const defaultProps = {
  index: 0,
  changeFieldsForName: () => null,
  deleteGuaranteedPayout: () => null,
  guaranteedPayout: {},
  term: 0,
};

const propTypes = {
  index: PropTypes.number,
  changeFieldsForName: PropTypes.func,
  deleteGuaranteedPayout: PropTypes.func,
  guaranteedPayout: PropTypes.object,
  term: PropTypes.number,
};

const {
  GUARANTEED_PAYMENT_ANNOTATION,
  GUARANTEED_PAYMENTS_AMOUNT,
  TERM_ANNOTATION,
  PAYMENT_TERM,
  SPECIFY_THE_TERM,
  DELETE,
} = LANG_DICTIONARY;

const GuaranteedPayout = (props) => {
  const {
    term,
    index,
    changeFieldsForName,
    deleteGuaranteedPayout,
    guaranteedPayout,
  } = props;

  const maxTerm = monthsInYear * term;

  useEffectExceptOnMount(() => {
    const maxValue = guaranteedPayout.paymentTerm > maxTerm
      ? maxTerm
      : guaranteedPayout.paymentTerm;

    changeFieldsForName(inputType.term, maxValue, index);
  }, [term]);

  const handleChange = useCallback(({ target: { name, value } }) => {
    const valueNumber = Number(value);

    if (!Number.isNaN(valueNumber)) {
      if (name === inputType.term) {
        const maxValue = Math.min(valueNumber, maxTerm);

        changeFieldsForName(name, maxValue, index);
      }

      if (name === inputType.payment) {
        const valueSplitted = value.split('.');

        if (valueSplitted[1]) {
          const isRemainderMoreThanTwo = valueSplitted[1].length > 2;
          const decimalValue = isRemainderMoreThanTwo ? `${valueSplitted[0]}.${valueSplitted[1].substring(0, 2)}` : value;

          changeFieldsForName(name, decimalValue, index);

          return;
        }

        changeFieldsForName(name, value, index);
      }
    }
  }, [maxTerm]);

  const handleDeleteGuaranteedPayout = () => {
    deleteGuaranteedPayout(index);
  };

  const termErr = !term && <div className={b('error')}>{SPECIFY_THE_TERM}</div>;

  const termVal = guaranteedPayout.paymentTerm > maxTerm
    ? maxTerm
    : guaranteedPayout.paymentTerm;

  return (
    <div className={b()}>
      <div className={b('number')}>
        <div>№</div>
        <div>{index + 1}</div>
      </div>
      <div className={b('guaranteed-payments-amount')}>
        <FieldRow helpMessage={GUARANTEED_PAYMENT_ANNOTATION}>
          <div className={b('guaranteed-payments-amount-text')}>{GUARANTEED_PAYMENTS_AMOUNT}</div>
          <Input
            name="value"
            wrapperClassName={b('percent-input').toString()}
            onChange={({ target }) => { handleChange({ target }); }}
            required
            value={guaranteedPayout.value}
          />
        </FieldRow>
      </div>
      <div className={b('payment-term')}>
        <FieldRow helpMessage={TERM_ANNOTATION}>
          <div className={b('guaranteed-payments-amount-text')}>{PAYMENT_TERM}</div>
          <Input
            name="paymentTerm"
            wrapperClassName={b('percent-input').toString()}
            onChange={({ target }) => { handleChange({ target }); }}
            required
            value={termVal}
            error={termErr}
          />
        </FieldRow>
      </div>
      <div className={b('button')}>
        <StrategyTitleButton
          text={DELETE}
          iconName="close"
          onClick={handleDeleteGuaranteedPayout}
        />
      </div>
    </div>
  );
};

GuaranteedPayout.defaultProps = defaultProps;

GuaranteedPayout.propTypes = propTypes;

export default GuaranteedPayout;
