import React from 'react';
import { Row } from 'reactstrap';
import {
  RangeDatePicker,
  SearchBtn,
  ExtendedSettingsBtn,
} from 'components';
import {
  Input,
  Select,
} from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import Collapse from 'reactstrap/es/Collapse';
import PropTypes from 'prop-types';

const propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object),
  fullBranches: PropTypes.arrayOf(PropTypes.object),
  checkingStatusList: PropTypes.arrayOf(PropTypes.object),
  checkStatusId: PropTypes.number,
  handleChange: PropTypes.func,
  ctsStatusId: PropTypes.number,
  searchBranchId: PropTypes.number,
  ctsNumOrFio: PropTypes.string,
  checkUserFio: PropTypes.string,
  dateCtsBeg: PropTypes.string,
  dateCtsEnd: PropTypes.string,
  dateCheckBeg: PropTypes.string,
  dateCheckEnd: PropTypes.string,
  userFio: PropTypes.string,
  showAdditionalSearch: PropTypes.bool,
  toggleAdditionalSearch: PropTypes.func,
  handleSearch: PropTypes.func,
};

const defaultProps = {
  statusList: [],
  fullBranches: [],
  checkingStatusList: [],
  checkStatusId: 0,
  handleChange: () => null,
  ctsStatusId: 0,
  searchBranchId: 0,
  ctsNumOrFio: '',
  checkUserFio: '',
  dateCtsBeg: '',
  dateCtsEnd: '',
  dateCheckBeg: '',
  dateCheckEnd: '',
  userFio: '',
  showAdditionalSearch: false,
  toggleAdditionalSearch: () => null,
  handleSearch: () => null,
};

const bem = block('check-form-search-block');
const {
  STATUS_CHECK,
  STATUS_CONTRACT,
  SUBDIVISION,
  CONTRACT_NUMBER_OR_FIO,
  CHECKER,
  USER,
  START_DATE,
  END_DATE,
  CHECK_DATE,
} = LANG_DICTIONARY;

const SearchInputs = ({
  statusList,
  fullBranches,
  checkingStatusList,
  checkStatusId,
  handleChange,
  ctsStatusId,
  searchBranchId,
  ctsNumOrFio,
  checkUserFio,
  dateCtsBeg,
  dateCtsEnd,
  dateCheckBeg,
  dateCheckEnd,
  userFio,
  showAdditionalSearch,
  toggleAdditionalSearch,
  handleSearch,
}) => {
  const getSelectProps = (nameProp, valueProp) => ({
    onChange: (event, { value, name }) => handleChange({ target: { value, name } }),
    name: nameProp,
    value: valueProp,
  });

  const getInputProps = (name, value) => ({
    name,
    defaultValue: value,
    onBlur: handleChange,
  });

  return (
    <div className={bem()}>
      <Row>
        <div className={bem('first-row')}>
          <Input
            name="ctsNumOrFio"
            defaultValue={ctsNumOrFio}
            onBlur={handleChange}
            placeholder={CONTRACT_NUMBER_OR_FIO}
          />
        </div>
        <div className={bem('first-row')}>
          <RangeDatePicker
            values={{
              dateCheckBeg,
              dateCheckEnd,
            }}
            labelStartDate={CHECK_DATE}
            labelEndDate={END_DATE}
            onChange={handleChange}
            startDateName="dateCheckBeg"
            endDateName="dateCheckEnd"
          />
        </div>
        <div className={bem('second-row')}>
          <div>
            <Select
              {...getSelectProps('checkStatusId', checkStatusId)}
              placeholder={STATUS_CHECK}
              options={checkingStatusList}
            />
          </div>
        </div>
        <div className={bem('second-row')}>
          <Input
            {...getInputProps('checkUserFio', checkUserFio)}
            placeholder={CHECKER}
          />
        </div>
        <div className={bem('second-row')}>
          <div className={bem('control')}>
            <SearchBtn action={handleSearch} />
            <ExtendedSettingsBtn
              toggle={toggleAdditionalSearch}
              isOpen={Boolean(showAdditionalSearch)}
            />
          </div>
        </div>
      </Row>
      <Collapse isOpen={showAdditionalSearch}>
        <Row>
          <div className={bem('third-row')}>
            <RangeDatePicker
              values={{
                dateCtsBeg,
                dateCtsEnd,
              }}
              labelStartDate={START_DATE}
              labelEndDate={END_DATE}
              onChange={handleChange}
              startDateName="dateCtsBeg"
              endDateName="dateCtsEnd"
            />
          </div>
          <div className={bem('third-row')}>
            <Select
              {...getSelectProps('ctsStatusId', ctsStatusId)}
              placeholder={STATUS_CONTRACT}
              options={statusList}
            />
          </div>
          <div className={bem('four-row')}>
            <Input
              {...getInputProps('userFio', userFio)}
              placeholder={USER}
            />
          </div>
          <div className={bem('four-row')}>
            <Select
              {...getSelectProps('searchBranchId', searchBranchId)}
              placeholder={SUBDIVISION}
              options={fullBranches}
            />
          </div>
        </Row>
      </Collapse>
    </div>
  );
};

SearchInputs.defaultProps = defaultProps;
SearchInputs.propTypes = propTypes;
export default SearchInputs;
