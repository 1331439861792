import { createSelector } from 'reselect';

export const sharedSelectors = {
  getQueryParams: ({ shared: { query } }) => query,
  getWindowUuid: createSelector(
    ({ shared: { windowTimestamp } }) => windowTimestamp,
    ({ shared: { windowUuid } }) => windowUuid,
    (windowTimestamp, windowUuid) => ({ windowTimestamp, windowUuid }),
  ),
};
