import React from 'react';
import PropTypes from 'prop-types';

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  propertyName: PropTypes.string,
  customError: PropTypes.string,
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
};

const defaultProps = {
  values: {},
  errors: {},
  touched: {},
  name: '',
  propertyName: '',
  customError: '',
  onChange: () => null,
  onBlur: () => null,
};

const WithFormikProps = (Component) => {
  const WrapperInput = ({
    values,
    errors,
    touched,
    name,
    propertyName,
    customError,
    ...rest
  }) => {
    const nameInput = propertyName || name;
    const value = values[nameInput];
    const error = customError || errors[name];
    const touch = touched[nameInput];

    return (
      <>
        <Component
          value={value}
          error={error}
          touched={touch}
          propertyName={propertyName}
          name={name}
          {...rest}
        />
      </>
    );
  };

  WrapperInput.defaultProps = defaultProps;
  WrapperInput.propTypes = propTypes;

  return WrapperInput;
};

export default WithFormikProps;
