import React, { useState } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, IMPORT_FILE_TYPE } from 'consts';
import { FileUploadAdmin, CustomCheckBox } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';
import { uploadBlocksPodft } from 'consts/importPodftData';

const {
  importPartners,
  importBranches,
  importUsers,
  importNszTariffs,
} = IMPORT_FILE_TYPE;

const b = block('admin-panel');

const {
  IMPORT_ADMIN,
  TITLE_ADMIN,
  PARTNER_ADMIN,
  BRANCHES_ADMIN,
  USERS_ADMIN,
  SETTINGS_ADMIN,
  LIST_PODFT_ADMIN,
  PARTNERS_AND_PRODUCTS,
  PODFT_TITLE,
  UPLOAD_PARTNER,
  UPLOAD_BRANCHES,
  UPLOAD_USERS,
  UPLOAD_TARIFF_NSZ,
  ALL_RECORDS_IN_THE_TABLE_WIL_BE_OVERWRITTEN_WITH_NEW_DATA,
  OVERWRITTEN_DATA,
} = LANG_DICTIONARY;

const description = [
  TITLE_ADMIN,
  PARTNER_ADMIN,
  BRANCHES_ADMIN,
  USERS_ADMIN,
  SETTINGS_ADMIN,
  LIST_PODFT_ADMIN,
];

const uploadBlocks = [
  { title: UPLOAD_PARTNER, type: importPartners },
  { title: UPLOAD_BRANCHES, type: importBranches },
  { title: UPLOAD_USERS, type: importUsers, resData: true },
];

const propTypes = {
  successInfoTask: PropTypes.bool,
  activeTaskInfoImport: PropTypes.object,
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  versions: PropTypes.object,
  uploadPodftFile: PropTypes.func,
};

const defaultProps = {
  cronTypeSelect: [],
  activeTaskInfoImport: {},
  successInfoTask: false,
  versions: {},
  uploadPodftFile: () => null,
};

const ImportForm = (props) => {
  const { versions, uploadPodftFile } = props;
  const [isRewrite, onChangeRewrite] = useState(false);

  return (
    <div className={b()}>
      <section className={b('title')}>
        <div className={b('title-text')}>
          {IMPORT_ADMIN}
        </div>
      </section>
      <div className={b('content')}>
        <section className={b('info-block')}>
          {description.map((desc) => <p key={desc}>{desc}</p>)}
        </section>
        <section className={b('download')}>
          <div className={b('title-download')}>
            {PARTNERS_AND_PRODUCTS}
          </div>
          {uploadBlocks.map((item) => (
            <FileUploadAdmin
              {...item}
              {...props}
              key={item.title}
            />
          ))}
          <div>
            <div className={b('rewrite')}>
              <div
                role="presentation"
                onClick={() => {
                  onChangeRewrite(!isRewrite);
                }}
                className={b('label-checkbox')}
              >
                {OVERWRITTEN_DATA}
              </div>
              <CustomCheckBox
                name="rewriteTariff"
                onChange={(name, val) => {
                  onChangeRewrite(!val);
                }}
                checked={isRewrite}
              />
            </div>
            <FileUploadAdmin
              title={UPLOAD_TARIFF_NSZ}
              type={importNszTariffs}
              isConfirm={isRewrite}
              confirmText={ALL_RECORDS_IN_THE_TABLE_WIL_BE_OVERWRITTEN_WITH_NEW_DATA}
              metadataRequest={{
                isRewrite,
              }}
              {...props}
            />
          </div>
          <div className={b('title-download')}>
            {PODFT_TITLE}
          </div>
          {uploadBlocksPodft.map((item) => (
            <FileUploadAdmin
              {...item}
              {...props}
              uploadFile={uploadPodftFile}
              version={versions[item.podftType]}
              key={item.title}
            />
          ))}
        </section>
      </div>
    </div>
  );
};

ImportForm.defaultProps = defaultProps;
ImportForm.propTypes = propTypes;
export default ImportForm;
