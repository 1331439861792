const LOG_PAGE_REQUEST = 'logging/LOG_PAGE_REQUEST';
const LOG_PAGE_START = 'logging/LOG_PAGE_START';
const LOG_PAGE_FAIL = 'logging/LOG_PAGE_FAIL';
const LOG_PAGE_SUCCESS = 'logging/LOG_PAGE_SUCCESS';

export {
  LOG_PAGE_FAIL,
  LOG_PAGE_REQUEST,
  LOG_PAGE_START,
  LOG_PAGE_SUCCESS,
};
