import React from 'react';
import { PopupContainer } from 'components';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import './styles.scss';

const {
  ATTENTION,
  UNITED_FRONTEND_BLOCK_MESSAGE,
} = LANG_DICTIONARY;
const b = block('united-frontend-block-popup');

const propTypes = {
  isOpenBlockUnitedFront: PropTypes.bool.isRequired,
};

const UnitedFrontendBlock = ({
  isOpenBlockUnitedFront,
}) => (
  <PopupContainer
    isOpen={isOpenBlockUnitedFront}
    className={b()}
  >
    <div className={b('content')}>
      <div className={b('attention')}>
        <Icon name="warning sign" />
        {`${ATTENTION}!`}
      </div>
      <p className={b('message')}>
        {UNITED_FRONTEND_BLOCK_MESSAGE}
      </p>
    </div>
  </PopupContainer>
);

UnitedFrontendBlock.propTypes = propTypes;
export default connect(({
  authReducer: {
    isOpenBlockUnitedFront,
  },
}) => ({ isOpenBlockUnitedFront }))(UnitedFrontendBlock);
