import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { Icon } from 'semantic-ui-react';
import { ScanList } from 'components';
import PropTypes from 'prop-types';
import { redirectFactory } from 'helpers';
import { withCustomRouter } from 'HOC';
import ProductColumn from './productColumn';
import './styles.scss';

const { checkDocumentList } = ROUTES;

const propTypes = {
  type: PropTypes.string,
  name: PropTypes.string,
  scans: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  redirect: PropTypes.string,
  ctsId: PropTypes.number,
  history: PropTypes.object,
};

const defaultProps = {
  type: '',
  name: '',
  scans: [],
  index: 0,
  redirect: '',
  ctsId: 0,
  history: {},
};

const propTypesTable = {
  insurerData: PropTypes.object,
  insuredData: PropTypes.arrayOf(PropTypes.object),
  beneficiaryData: PropTypes.arrayOf(PropTypes.object),
  contractData: PropTypes.object,
  history: PropTypes.object,
};

const defaultPropsTable = {
  insurerData: {},
  insuredData: [],
  beneficiaryData: [],
  contractData: {},
  history: {},
};

const {
  TYPE,
  NAMING,
  KIT_CHECK,
  CONTRACT,
  INSURER,
  SINGULAR_INSURED,
  SINGULAR_BENEFICIARY,
} = LANG_DICTIONARY;

const b = block('underwriting-contract-table');

const RowItem = ({
  type,
  name,
  scans,
  redirect,
  ctsId,
  history,
  index,
}) => {
  const column = (id) => `${b(`col-${id}`)}`;

  return (
    <div className={b()}>
      <ProductColumn
        firstRow=""
        secondRow={(
          <div
            className={b('info-icon')}
            role="button"
            tabIndex="-1"
            onClick={() => redirectFactory({
              type: redirect,
              ctsId,
              history,
              backPatch: checkDocumentList,
              index,
            })}
          >
            <Icon name="info" />
          </div>
)}
      />
      <div className={b('content')}>
        <ProductColumn
          firstRow={TYPE}
          secondRow={type}
          className={column(1)}
        />
        <ProductColumn
          firstRow={NAMING}
          secondRow={name}
          className={column(2)}
        />
        <ProductColumn
          firstRow={KIT_CHECK}
          secondRow={<ScanList list={scans} />}
          className={column(3)}
        />
      </div>
    </div>
  );
};

const UnderwritingContractTable = (props) => {
  const {
    insurerData: {
      ctgtsFio: insurerName,
      scans: scansInsurer,
      ctgtsType: insurerType,
    },
    insuredData,
    beneficiaryData,
    contractData: {
      ctsId,
      scans: scansContract,
    },
    history,
  } = props;

  return (
    <div>
      <RowItem
        type={CONTRACT}
        name="-"
        scans={scansContract}
        history={history}
        redirect="contract"
        ctsId={ctsId}
      />
      <RowItem
        type={INSURER}
        name={insurerName}
        scans={scansInsurer}
        redirect={insurerType}
        ctsId={ctsId}
        history={history}
      />
      {insuredData && insuredData.map(({ ctgtsFio, scans, ctgtsType }, index) => {
        const personType = insuredData.length > 1 ? `${SINGULAR_INSURED} ${index + 1}` : SINGULAR_INSURED;

        return (
          <RowItem
            type={personType}
            name={ctgtsFio}
            scans={scans}
            index={index}
            history={history}
            ctsId={ctsId}
            redirect={ctgtsType}
            key={`${ctsId}${personType}`}
          />
        );
      })}
      {beneficiaryData && beneficiaryData.map(({ ctgtsFio, scans, ctgtsType }, index) => {
        const personType = index ? `${SINGULAR_BENEFICIARY} ${index}` : SINGULAR_BENEFICIARY;

        return (
          <RowItem
            type={personType}
            name={ctgtsFio}
            index={index}
            scans={scans}
            history={history}
            ctsId={ctsId}
            redirect={ctgtsType}
            key={`${ctsId}${personType}`}
          />
        );
      })}
    </div>
  );
};

RowItem.defaultProps = defaultProps;
RowItem.propTypes = propTypes;
UnderwritingContractTable.propTypes = propTypesTable;
UnderwritingContractTable.defaultProps = defaultPropsTable;
export default withCustomRouter(UnderwritingContractTable);
