import React from 'react';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const bem = block('success-btn');

const propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  active: false,
  onClick: () => null,
};

const SuccessBtn = ({ active, onClick }) => (
  <div
    onClick={onClick}
    role="button"
    tabIndex="-1"
  >
    <Icon name="check" bordered className={bem({ active }).toString()} />
  </div>
);

SuccessBtn.propTypes = propTypes;
SuccessBtn.defaultProps = defaultProps;
export default SuccessBtn;
