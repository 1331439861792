import {
  GET_FILTER_OPTIONS_START,
  GET_FILTER_OPTIONS_FAIL,
  GET_FILTER_OPTIONS_SUCCESS,
  SEARCH_PRODUCT_BY_FILTER_SUCCESS,
  SEARCH_PRODUCT_BY_FILTER_START,
  SEARCH_PRODUCT_BY_FILTER_FAIL,
  CLEAR_FILTERS_PRODUCTS,
  GET_STATUS_CHECKING_SUCCESS,
  GET_STATUS_CHECKING_START,
  GET_STATUS_CHECKING_FAIL,
  SEARCH_DOC_STATUS_BY_FILTER_START,
  SEARCH_DOC_STATUS_BY_FILTER_FAIL,
  SEARCH_DOC_STATUS_BY_FILTER_SUCCESS,
  SEARCH_STATUS_PRODUCT_BY_FILTER_START,
  SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL,
  SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS,
} from '../types';

const EMPTY_INITIAL_OPTIONS_STATUS = [{
  key: null,
  value: null,
  text: 'Не выбрано',
}];

const INITIAL_STATE = {
  loading: false,
  loadingSearch: false,
  loadingPeople: false,
  err: null,
  products: [],
  statusList: [],
  partners: [],
  branches: [],
  searchResult: [],
  totalPagesMySales: 1,
  idPartner: 0,
  checkingStatusList: EMPTY_INITIAL_OPTIONS_STATUS,
};

export default (state = INITIAL_STATE, { type, payload, field }) => {
  switch (type) {
    case GET_FILTER_OPTIONS_START:
      return {
        ...state,
        loading: true,
      };
    case GET_FILTER_OPTIONS_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_FILTER_OPTIONS_SUCCESS:
      return {
        ...state,
        loading: false,
        [field]: payload,
      };
    case SEARCH_STATUS_PRODUCT_BY_FILTER_START:
      return {
        ...state,
        loadingSearch: true,
      };
    case SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL:
      return {
        ...state,
        loadingSearch: false,
        error: payload,
      };
    case SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loadingSearch: false,
        searchResult: payload.paginationData,
        totalPagesMySales: payload.data.totalPages === 0 ? 1 : payload.data.totalPages,
        idPartner: payload.idPartner,
      };
    case SEARCH_PRODUCT_BY_FILTER_START:
      return {
        ...state,
        loadingSearch: true,
      };
    case SEARCH_PRODUCT_BY_FILTER_FAIL:
      return {
        ...state,
        loadingSearch: false,
        error: payload,
      };
    case SEARCH_PRODUCT_BY_FILTER_SUCCESS:
      return {
        ...state,
        loadingSearch: false,
        searchResult: payload.paginationData,
        totalPagesMySales: payload.data.totalPages === 0 ? 1 : payload.data.totalPages,
        idPartner: payload.idPartner,
      };
    case SEARCH_DOC_STATUS_BY_FILTER_START:
      return {
        ...state,
        loadingSearch: true,
      };
    case SEARCH_DOC_STATUS_BY_FILTER_FAIL:
      return {
        ...state,
        loadingSearch: false,
        error: payload,
      };
    case SEARCH_DOC_STATUS_BY_FILTER_SUCCESS:
      return {
        ...state,
        loadingSearch: false,
      };
    case CLEAR_FILTERS_PRODUCTS: {
      return INITIAL_STATE;
    }
    case GET_STATUS_CHECKING_SUCCESS:
      return {
        ...state,
        checkingStatusList: [
          ...EMPTY_INITIAL_OPTIONS_STATUS,
          ...payload,
        ],
      };
    case GET_STATUS_CHECKING_START:
      return {
        ...state,
      };
    case GET_STATUS_CHECKING_FAIL:
      return {
        ...state,
        error: payload,
      };

    default:
      return state;
  }
};
