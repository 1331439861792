import { getRealPasportDateForInsured } from './getRealPasportDate';

const rebuildBeneficiaries = (beneficiaries, numberOfBeneficiaries, birthDate) => {
  const {
    beneficiary1,
    beneficiary2,
    beneficiary3,
    beneficiary4,
    ...rest
  } = beneficiaries;

  const listOfBeneficiaries = [
    beneficiary1,
    beneficiary2,
    beneficiary3,
    beneficiary4,
  ];

  const realPasportDate = getRealPasportDateForInsured(birthDate);

  const rebuildListOfBeneficiaries = listOfBeneficiaries
    .filter((item, i) => (i + 1) <= numberOfBeneficiaries)
    .reduce((acc, item, i) => ({
      ...acc,
      [`beneficiary${i + 1}`]: {
        ...item,
        formDataDocuments: {
          ...item.formDataDocuments,
          when: realPasportDate,
        },
        formData: {
          ...item.formData,
          birthDate,
          insuredSum: (i + 1) === numberOfBeneficiaries
            ? 100 - ((numberOfBeneficiaries - 1) * 10)
            : 10,
        },
      },
    }), {});

  return {
    ...rebuildListOfBeneficiaries,
    ...rest,
    countBeneficiaries: numberOfBeneficiaries,
  };
};

export default rebuildBeneficiaries;
