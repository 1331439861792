import {
  GET_PARTNERS_UNDERWRITING_REQUEST,
  GET_BRANCHES_UNDERWRITING_REQUEST,
  GET_USERS_UNDERWRITING_REQUEST,
  GET_STATUS_UNDERWRITING_REQUEST,
  GET_COUNT_UNDERWRITING_REQUEST,
  GET_CHECKER_UNDERWRITING_REQUEST,
  GET_CONTRACTS_UNDERWRITING_REQUEST,
  GET_STATUS_SCAN_UNDERWRITING_REQUEST,
  GET_ALL_BRANCHES_UNDERWRITING_REQUEST,
  UNLOCK_DOCUMENT_UNDERWRITING_REQUEST,
  GET_STATUS_CHECKING_UNDERWRITING_REQUEST,
  SET_CURRENT_SCAN_INDEX,
  SET_INIT_STATE,
} from '../types';

const getPartners = () => ({
  type: GET_PARTNERS_UNDERWRITING_REQUEST,
});

const getBranches = (payload) => ({
  type: GET_BRANCHES_UNDERWRITING_REQUEST,
  payload,
});

const getUsers = ({ partnerId, branchId }) => ({
  type: GET_USERS_UNDERWRITING_REQUEST,
  payload: { partnerId, branchId },
});

const getStatus = () => ({
  type: GET_STATUS_UNDERWRITING_REQUEST,
});

const getCountContract = (query, action) => ({
  type: GET_COUNT_UNDERWRITING_REQUEST,
  payload: { query, action },
});

const getCheckerList = () => ({
  type: GET_CHECKER_UNDERWRITING_REQUEST,
});

const getContractArray = ({ payload, history }) => ({
  type: GET_CONTRACTS_UNDERWRITING_REQUEST,
  payload,
  history,
});

const getStatusForScan = () => ({
  type: GET_STATUS_SCAN_UNDERWRITING_REQUEST,
});

const setCurrentScanIndex = (index) => ({
  type: SET_CURRENT_SCAN_INDEX,
  payload: index,
});

const unlockDocuments = (payload, history) => ({
  type: UNLOCK_DOCUMENT_UNDERWRITING_REQUEST,
  payload,
  history,
});

const getAllBranches = () => ({
  type: GET_ALL_BRANCHES_UNDERWRITING_REQUEST,
});

const setInitStateUnderwriting = () => ({
  type: SET_INIT_STATE,
});

const getStatusChecking = () => ({
  type: GET_STATUS_CHECKING_UNDERWRITING_REQUEST,
});

export {
  getStatusChecking,
  getAllBranches,
  setInitStateUnderwriting,
  unlockDocuments,
  setCurrentScanIndex,
  getStatusForScan,
  getContractArray,
  getCheckerList,
  getCountContract,
  getStatus,
  getPartners,
  getBranches,
  getUsers,
};
