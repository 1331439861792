import React from 'react';
import { Col, Container, Row } from 'reactstrap';
import { LANG_DICTIONARY, COUNTRIES } from 'consts';
import { SelectInput, TextInput } from 'components/_deprecated';
import PropTypes from 'prop-types';
import { DEFAULT_COUNTRY } from 'config';
import * as helperActions from 'redux/actions/helperActions';
import * as validateActions from 'redux/validation/actions';
import { useSelector } from 'react-redux';
import { SearchInput } from 'components';
import {
  getBlackAddressInfo,
  getIsRegionSearchLoading,
  getProductId,
  getRegionSearchResults,
} from 'redux/reducers/selectors';
import { useActions } from 'hooks/useActions';

const propTypes = {
  inputProps: PropTypes.object,
  isLivingAddress: PropTypes.bool,
  inputs: PropTypes.arrayOf(PropTypes.string),
  index: PropTypes.number,
  payloadRequestCheckCode: PropTypes.object,
  isListBlackAddress: PropTypes.bool,
};

const defaultProps = {
  inputProps: {},
  isLivingAddress: false,
  inputs: [],
  index: 0,
  payloadRequestCheckCode: {},
  isListBlackAddress: false,
};

const {
  INDEX,
  REPUBLIC,
  AREA,
  CITY,
  STREET,
  HOUSE,
  HOUSE_NUMBER,
  APARTMENT,
} = LANG_DICTIONARY;

let timeout;

const AddressBlockManual = ({
  inputProps,
  isLivingAddress,
  inputs,
  index,
  payloadRequestCheckCode,
  isListBlackAddress,
}) => {
  const {
    searchRegion,
    resetSearchRegion,
    checkCodeKladr,
  } = useActions({ ...helperActions, ...validateActions });
  const regionSearchResult = useSelector(getRegionSearchResults);
  const {
    isRegionSearchLoading,
    isRegionSearchLoadingLiving,
  } = useSelector(getIsRegionSearchLoading);
  const blackAddressInfo = useSelector(getBlackAddressInfo);
  const productId = useSelector(getProductId);

  const living = isLivingAddress ? 'Living' : '';

  const getRepublicValue = () => {
    const { values } = inputProps;

    return values[`republic${living}`] || '';
  };

  const setFormikValue = (params) => {
    const { setValues, setFieldValue, values } = inputProps;
    if (setValues) {
      setValues({ ...values, ...params });
    } else {
      Object.entries(params).forEach(({ key, value }) => {
        setFieldValue(key, value);
      });
    }
  };

  const handleRegionSearch = (e, { value }) => {
    setFormikValue({ [`republic${living}`]: value });

    if (timeout) {
      clearTimeout(timeout);
    }
    timeout = setTimeout(() => {
      searchRegion(value, isLivingAddress);
    }, 500);
  };

  const handleRegionSelect = (e, { result }) => {
    const { title, data: { kladr_id: kladrId, fias_id: fiasId } } = result;
    setFormikValue({
      [`republic${living}`]: title,
      [`kladId${living}`]: kladrId,
      [`fiasId${living}`]: fiasId,
    });
    resetSearchRegion();

    if (isListBlackAddress) {
      checkCodeKladr({
        index,
        code: kladrId,
        isLiving: isLivingAddress,
        payloadRequest: payloadRequestCheckCode,
        blackAddressInfo,
        productId,
      });
    }
  };

  const getInputProps = (name) => ({
    ...inputProps,
    name: `${name}${living}`,
  });

  const getTotalValue = () => {
    const { values } = inputProps;
    const noShowFieldInTotal = [`index${living}`];
    const arrayValue = inputs.map((item) => !noShowFieldInTotal.includes(item) && values[item]);

    return arrayValue
      .filter(Boolean)
      .join(', ');
  };

  return (
    <Container>
      <Row>
        <Col>
          <TextInput
            disabled
            value={getTotalValue()}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="12">
          <SelectInput
            options={COUNTRIES}
            disabled
            required
            {...getInputProps('country')}
            values={{ [`country${living}`]: DEFAULT_COUNTRY }}
          />
        </Col>
        <Col md="3" sm="12">
          <TextInput
            placeholder={INDEX}
            required
            mask="999999"
            {...getInputProps('index')}
          />
        </Col>
        <Col md="3" sm="12">
          <SearchInput
            placeholder={REPUBLIC}
            required
            {...getInputProps(`republic${living}`)}
            name={`republic${living}`}
            onResultSelect={handleRegionSelect}
            onSearchChange={handleRegionSearch}
            results={regionSearchResult}
            loading={isLivingAddress ? isRegionSearchLoadingLiving : isRegionSearchLoading}
            value={getRepublicValue()}
            className="insurerData__searchBar"
          />
        </Col>
        <Col md="3" sm="12" className="margin-none">
          <TextInput
            placeholder={AREA}
            {...getInputProps('area')}
          />
        </Col>
      </Row>
      <Row>
        <Col md="3" sm="12">
          <TextInput
            required
            placeholder={CITY}
            {...getInputProps('city')}
          />
        </Col>
        <Col md="3" sm="12">
          <TextInput
            placeholder={STREET}
            {...getInputProps('street')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={HOUSE}
            required
            {...getInputProps('house')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={HOUSE_NUMBER}
            {...getInputProps('houseNumber')}
          />
        </Col>
        <Col md="2" sm="12">
          <TextInput
            placeholder={APARTMENT}
            maxLength="10"
            {...getInputProps('apartment')}
          />
        </Col>
      </Row>
    </Container>
  );
};

AddressBlockManual.defaultProps = defaultProps;
AddressBlockManual.propTypes = propTypes;

export default AddressBlockManual;
