import { ONE_AND_HALF_MILLION } from './numbers';

export const FAVORITES_BASKET = {
  amountFavoritesBasketDefault: 0,
  maxFavoritesBasketsDefault: 0,
};

export const BASKET_CONST = {
  AUTOCALL_BARRIER: 'Autocall barrier',
  BONUS_COUPON: 'Bonus-coupon',
  COUPON_BARRIER: 'Coupon barrier',
};

export const REGULAR_BASKET_TYPE = 'regular';
export const READY_BASKET_TYPE = 'ready';
export const X_CONDITION = ONE_AND_HALF_MILLION;
