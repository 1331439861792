import React from 'react';
import { block } from 'bem-cn';
import {
  Breadcrumbs, MainBtn, UnderwritingTableRow, PaginationPanel,
} from 'components';
import { BREADCRUMBS, ROUTES, LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import SearchInputs from './searchInputs';
import './styles.scss';

const { DOCUMENT_UNDERWRITING } = BREADCRUMBS;
const { home } = ROUTES;
const { BACK } = LANG_DICTIONARY;

const propTypes = {
  statusList: PropTypes.arrayOf(PropTypes.object),
  fullBranches: PropTypes.arrayOf(PropTypes.object),
  checkingStatusList: PropTypes.arrayOf(PropTypes.object),
  checkStatusId: PropTypes.number,
  handleChange: PropTypes.func,
  ctsStatusId: PropTypes.number,
  searchBranchId: PropTypes.number,
  ctsNumOrFio: PropTypes.string,
  checkUserFio: PropTypes.string,
  dateCtsBeg: PropTypes.string,
  dateCtsEnd: PropTypes.string,
  dateCheckBeg: PropTypes.string,
  dateCheckEnd: PropTypes.string,
  userFio: PropTypes.string,
  showAdditionalSearch: PropTypes.bool,
  toggleAdditionalSearch: PropTypes.func,
  handleSearch: PropTypes.func,
  data: PropTypes.arrayOf(PropTypes.object),
  helperData: PropTypes.object,
  toggleInfo: PropTypes.func,
  history: PropTypes.object,
  config: PropTypes.object,
  activePaginationPage: PropTypes.number,
  handleUpPage: PropTypes.func,
  handleDownPage: PropTypes.func,
  total: PropTypes.number,
};

const defaultProps = {
  total: 1,
  statusList: [],
  fullBranches: [],
  checkingStatusList: [],
  checkStatusId: 0,
  handleChange: () => null,
  ctsStatusId: 0,
  searchBranchId: 0,
  ctsNumOrFio: '',
  checkUserFio: '',
  dateCtsBeg: '',
  dateCtsEnd: '',
  dateCheckBeg: '',
  dateCheckEnd: '',
  userFio: '',
  showAdditionalSearch: false,
  toggleAdditionalSearch: () => null,
  handleSearch: () => null,
  data: [],
  helperData: {},
  toggleInfo: () => null,
  history: {},
  config: {},
  activePaginationPage: 1,
  handleUpPage: () => null,
  handleDownPage: () => null,
};

const bem = block('check-document-list');

const CheckDocumentListForm = ({
  data,
  helperData,
  toggleInfo,
  handleChange,
  checkStatusId,
  ctsStatusId,
  searchBranchId,
  ctsNumOrFio,
  checkUserFio,
  dateCtsBeg,
  dateCtsEnd,
  dateCheckBeg,
  dateCheckEnd,
  userFio,
  showAdditionalSearch,
  toggleAdditionalSearch,
  handleSearch,
  history,
  config,
  activePaginationPage,
  handleUpPage,
  handleDownPage,
  total,
}) => (
  <section className={bem()}>
    <div className={bem('control')}>
      <MainBtn
        text={BACK}
        onClick={() => history.push(home)}
      />
      <Breadcrumbs
        breadcrumbsList={DOCUMENT_UNDERWRITING}
        active="1"
      />
    </div>
    <div className={bem('content')}>
      <SearchInputs
        bem={bem}
        {...helperData}
        handleChange={handleChange}
        checkStatusId={checkStatusId}
        ctsStatusId={ctsStatusId}
        searchBranchId={searchBranchId}
        ctsNumOrFio={ctsNumOrFio}
        checkUserFio={checkUserFio}
        dateCtsBeg={dateCtsBeg}
        dateCtsEnd={dateCtsEnd}
        dateCheckBeg={dateCheckBeg}
        dateCheckEnd={dateCheckEnd}
        userFio={userFio}
        showAdditionalSearch={showAdditionalSearch}
        toggleAdditionalSearch={toggleAdditionalSearch}
        handleSearch={handleSearch}
      />
      <div>
        {data.map((product, index) => (
          <UnderwritingTableRow
            data={product}
            config={config}
            toggleInfo={toggleInfo}
            key={product.contractData.ctsId}
            index={index}
          />
        ))}
      </div>
    </div>
    <PaginationPanel
      active={activePaginationPage}
      total={total}
      handleUpPage={handleUpPage}
      handleDownPage={handleDownPage}
    />
  </section>
);

CheckDocumentListForm.propTypes = propTypes;
CheckDocumentListForm.defaultProps = defaultProps;
export default CheckDocumentListForm;
