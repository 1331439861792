const GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST = 'productsAdminInterface/GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST';
const GET_PRODUCTS_FOR_ADMIN_INTERFACE_START = 'productsAdminInterface/GET_PRODUCTS_FOR_ADMIN_INTERFACE_START';
const GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS = 'productsAdminInterface/GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS';
const GET_PRODUCTS_FOR_ADMIN_INTERFACE_FAIL = 'productsAdminInterface/GET_PRODUCTS_FOR_ADMIN_INTERFACE_FAIL';

const GET_SELECTS_FOR_PRODUCTS_REQUEST = 'productsAdminInterface/GET_SELECTS_FOR_PRODUCTS_REQUEST';
const GET_SELECTS_FOR_PRODUCTS_START = 'productsAdminInterface/GET_SELECTS_FOR_PRODUCTS_START';
const GET_SELECTS_FOR_PRODUCTS_SUCCESS = 'productsAdminInterface/GET_SELECTS_FOR_PRODUCTS_SUCCESS';
const GET_SELECTS_FOR_PRODUCTS_FAIL = 'productsAdminInterface/GET_SELECTS_FOR_PRODUCTS_FAIL';

const SAVE_PRODUCTS_CHANGES_REQUEST = 'productsAdminInterface/SAVE_PRODUCTS_CHANGES_REQUEST';
const SAVE_PRODUCTS_CHANGES_START = 'productsAdminInterface/SAVE_PRODUCTS_CHANGES_START';
const SAVE_PRODUCTS_CHANGES_SUCCESS = 'productsAdminInterface/SAVE_PRODUCTS_CHANGES_SUCCESS';
const SAVE_PRODUCTS_CHANGES_FAIL = 'productsAdminInterface/SAVE_PRODUCTS_CHANGES_FAIL';

const SET_OPTION_FOR_PRODUCT = 'productsAdminInterface/SET_OPTION_FOR_PRODUCT';

const SET_STATUS_FOR_ALL_PRODUCTS = 'productsAdminInterface/SET_STATUS_FOR_ALL_PRODUCTS';

const CANCEL_ALL_CHANGES = 'productsAdminInterface/CANCEL_ALL_CHANGES';

const CLEAR_FORM = 'productsAdminInterface/CLEAR_FORM';

const SET_SELECT = 'productsAdminInterface/SET_SELECT';

export {
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_START,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_FAIL,
  GET_SELECTS_FOR_PRODUCTS_REQUEST,
  GET_SELECTS_FOR_PRODUCTS_START,
  GET_SELECTS_FOR_PRODUCTS_SUCCESS,
  GET_SELECTS_FOR_PRODUCTS_FAIL,
  SAVE_PRODUCTS_CHANGES_REQUEST,
  SAVE_PRODUCTS_CHANGES_START,
  SAVE_PRODUCTS_CHANGES_SUCCESS,
  SAVE_PRODUCTS_CHANGES_FAIL,
  SET_OPTION_FOR_PRODUCT,
  SET_STATUS_FOR_ALL_PRODUCTS,
  CANCEL_ALL_CHANGES,
  CLEAR_FORM,
  SET_SELECT,
};
