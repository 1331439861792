import React from 'react';
import { DateCustomPicker } from 'components';
import { DATE_FORMAT } from 'consts';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const bem = block('range-date-picker');

const propTypes = {
  labelStartDate: PropTypes.string,
  labelEndDate: PropTypes.string,
  startDateName: PropTypes.string,
  endDateName: PropTypes.string,
  onChange: PropTypes.func,
  values: PropTypes.object,
};

const defaultProps = {
  labelStartDate: '',
  labelEndDate: '',
  startDateName: 'startDate',
  endDateName: 'endDate',
  onChange: () => null,
  values: {},
};

const RangeDatePicker = ({
  values,
  onChange,
  startDateName,
  endDateName,
  labelStartDate,
  labelEndDate,
}) => (
  <section className={bem()}>
    <div className={bem('wrapper')}>
      <div className={bem('first-picker-wrapper')}>
        <p className={bem('title-date-input')}>{labelStartDate}</p>
        <DateCustomPicker
          values={values}
          maxDate={values[endDateName]}
          onChange={onChange}
          name={startDateName}
          dateFormat={DATE_FORMAT}
        />
      </div>
    </div>
    <div className={bem('wrapper')}>
      <p className={bem('title-date-input')}>{labelEndDate}</p>
      <DateCustomPicker
        minDate={values[startDateName]}
        values={values}
        name={endDateName}
        onChange={onChange}
      />
    </div>
  </section>
);

RangeDatePicker.defaultProps = defaultProps;
RangeDatePicker.propTypes = propTypes;
export default RangeDatePicker;
