import { PRODUCT_VERSION } from 'consts';
import {
  NORMAL_SETTINGS,
  COUPON_SETTINGS,
  NORMAL_CURRENCY_SETTINGS,
  COUPON_CURRENCY_SETTINGS,
  CONSTRUCTOR_ISZ,
  CONSTRUCTOR_ISZ_DOLLAR,
  NSZ_NORMAL_SETTINGS,
  NSZ_OLD_SETTINGS,
  NSZ_CHILD_SETTINGS,
  KSZ_NORMAL_SETTINGS,
} from './settings';

const productSettings = (groupName) => ({
  [`${PRODUCT_VERSION.normal}`]: NORMAL_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.coupon}`]: COUPON_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.normalCurrency}`]: NORMAL_CURRENCY_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.couponCurrency}`]: COUPON_CURRENCY_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.constructorIsz}`]: CONSTRUCTOR_ISZ[`${groupName}`],
  [`${PRODUCT_VERSION.constructorIszDollar}`]: CONSTRUCTOR_ISZ_DOLLAR[`${groupName}`],
  [`${PRODUCT_VERSION.nsz_1}`]: NSZ_NORMAL_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.nsz_3}`]: NSZ_OLD_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.nsz_2}`]: NSZ_CHILD_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.nsz_1}`]: NSZ_NORMAL_SETTINGS[`${groupName}`],
  [`${PRODUCT_VERSION.ksz_1}`]: KSZ_NORMAL_SETTINGS[`${groupName}`],
});

const getProductConfig = (scenario, groupName) => productSettings(groupName)[scenario];

export default getProductConfig;
