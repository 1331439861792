import {
  put,
  throttle,
  select,
  all,
  takeEvery,
} from 'redux-saga/effects';
import {
  apiCall,
  apiDownload,
  getError,
} from 'helpers';
import momentTimeZone from 'moment-timezone';
import {
  API,
  DATE_CRETE_FORMAT_CONSTRUCTOR,
  LANG_DICTIONARY,
  ROUTES,
} from 'consts';
import { getGlobalHistory } from 'components';
import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import {
  CHECK_JUST_NOW_EXECUTION_BASKET,
  EXECUTION_CALCULATING_BASKET,
  GENERATE_REPORT,
  GET_ASSETS_DESCRIPTION,
  SET_FAVORITES_DESCRIPTION,
  DELETE_FAVORITES_DESCRIPTION,
  GET_CALCULATED_PERSON_BASKETS,
  GET_HINTS_FOR_BASKETS,
  GET_HINTS_FOR_READY_BASKETS,
  GET_HINTS_FOR_REGULAR_BASKETS,
  GET_MIN_INSURANCE_SUM_TABLES,
  GET_PRODUCT_TYPES_TABLE_CONTROL,
  GET_AMOUNT_FAVORITES_BASKET,
  GET_READY_AND_REGULAR_BASKETS,
  GET_READY_BASKETS,
  GET_REGULAR_BASKETS,
  GET_REQUEST_COUNT,
  INS_LINE_ISZ_CALC,
  ANNUL_OLD_CONSTRUCTOR,
  EXECUTION_CALCULATING_DP_POLICY_BASKET,
  PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET,
} from '../types';
import {
  setJustNowExecutionBasketPayload,
  setProductTypeTableControl,
  setWarningMessage,
} from '../actions';
import { selectProductTypeCode } from '../selectors';
import { DEFAULT_THROTTLE_VALUE } from './common';
import { getCountRow } from '../reducers';

const {
  WARNING_BASKET_GUARANTEE_LEVEL_DOES_NOT_FIT,
  WARNING_BASKET_NEGATE_COUPON,
  DP_POLICY_BASKET_SENT,
  PRODUCT_EXECUTION_DISABLE,
} = LANG_DICTIONARY;

const checkSortParams = (sort) => {
  if (sort.length) {
    return sort;
  }

  return [];
};

function* getCalculatedPersonBaskets({
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    offset,
    limit,
    filters,
    sort,
    isScrollUpdate,
    isOnlyFavorite,
    abortSignal,
  },
}) {
  try {
    const sortParams = checkSortParams(sort);

    yield put({ type: GET_CALCULATED_PERSON_BASKETS.start, payload: { isScrollUpdate } });

    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_CALCULATED_BASKETS,
      signal: abortSignal,
      body: {
        partnersInsProductId,
        refCurrencyCode,
        warrantyLevel,
        refIszOptionTypeCode,
        refProductTypeCode,
        offset,
        limit,
        filters,
        sort: sortParams,
        isOnlyFavorite,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });

    yield put({ type: GET_CALCULATED_PERSON_BASKETS.success, payload: { data, offset, limit } });
  } catch (e) {
    yield put({ type: GET_CALCULATED_PERSON_BASKETS.fail, payload: getError(e) });
  }
}

function* getAssetsDescription({ payload }) {
  try {
    yield put({ type: GET_ASSETS_DESCRIPTION.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_ASSETS_BY_REQUEST_ID(payload),
    });

    yield put({ type: GET_ASSETS_DESCRIPTION.success, payload: data });
  } catch (e) {
    yield put({ type: GET_ASSETS_DESCRIPTION.fail, payload: getError(e) });
  }
}

function* setFavorites({ payload }) {
  try {
    yield put({ type: SET_FAVORITES_DESCRIPTION.start, payload });

    yield apiCall({
      type: 'POST',
      url: API.SET_FAVORITES,
      body: payload,
    });
  } catch (e) {
    yield put({ type: SET_FAVORITES_DESCRIPTION.fail, payload, error: getError(e) });
  }
}

function* deleteFavorites({ payload }) {
  try {
    yield put({ type: DELETE_FAVORITES_DESCRIPTION.start, payload });

    yield apiCall({
      type: 'DELETE',
      url: API.SET_FAVORITES,
      body: payload,
    });
  } catch (e) {
    yield put({ type: DELETE_FAVORITES_DESCRIPTION.fail, payload, error: getError(e) });
  }
}

function* getHintsForBaskets({ payload: { name, value } }) {
  try {
    yield put({ type: GET_HINTS_FOR_BASKETS.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKETS_HINTS,
      query: {
        fieldName: name,
        fieldValue: value,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });

    yield put({ type: GET_HINTS_FOR_BASKETS.success, payload: { name, data } });
  } catch (e) {
    yield put({ type: GET_HINTS_FOR_BASKETS.fail, payload: getError(e) });
  }
}

function* getHintsForReadyBaskets({ payload: { name, value } }) {
  try {
    yield put({ type: GET_HINTS_FOR_READY_BASKETS.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKETS_HINTS,
      query: {
        fieldName: name,
        fieldValue: value,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });

    yield put({ type: GET_HINTS_FOR_READY_BASKETS.success, payload: { name, data } });
  } catch (e) {
    yield put({ type: GET_HINTS_FOR_READY_BASKETS.fail, payload: getError(e) });
  }
}

function* getHintsForRegularBaskets({ payload: { name, value } }) {
  try {
    yield put({ type: GET_HINTS_FOR_REGULAR_BASKETS.start });

    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKETS_HINTS,
      query: {
        fieldName: name,
        fieldValue: value,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });

    yield put({ type: GET_HINTS_FOR_REGULAR_BASKETS.success, payload: { name, data } });
  } catch (e) {
    yield put({ type: GET_HINTS_FOR_REGULAR_BASKETS.fail, payload: getError(e) });
  }
}

export function* getAmountFavoriteBasket() {
  try {
    yield put({ type: GET_AMOUNT_FAVORITES_BASKET.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_AMOUNT_FAVORITES_BASKET,
    });
    yield put({ type: GET_AMOUNT_FAVORITES_BASKET.success, payload: data });
  } catch (e) {
    yield put({ type: GET_AMOUNT_FAVORITES_BASKET.fail, payload: getError(e) });
  }
}

export function* getProductTypes({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_TYPES_TABLE_CONTROL.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_BASKET_PRODUCT_TYPES,
      query: { productId: payload },
    });
    yield put({ type: GET_PRODUCT_TYPES_TABLE_CONTROL.success, payload: data });

    const productType = yield select(selectProductTypeCode);

    if (data.length && !productType) {
      yield put(setProductTypeTableControl(data[0].code));
    }
  } catch (e) {
    yield put({ type: GET_PRODUCT_TYPES_TABLE_CONTROL.fail, payload: getError(e) });
  }
}

function* getMinInsuranceSum({
  payload: {
    insLineIszCalcRequestId,
    partnersInsProductId,
    refCurrencyCode,
    qualification,
  },
}) {
  try {
    yield put({ type: GET_MIN_INSURANCE_SUM_TABLES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_MIN_PAY_BY_REQUEST,
      query: {
        insLineIszCalcRequestId,
        partnersInsProductId,
        refCurrencyCode,
        qualification,
      },
    });

    yield put({
      type: GET_MIN_INSURANCE_SUM_TABLES.success,
      payload: {
        result: data,
        isMinInsuranceSumClicked: true,
      },
    });
  } catch (e) {
    yield put({ type: GET_MIN_INSURANCE_SUM_TABLES.fail, payload: getError(e) });
  }
}

function* getUserRequestCount() {
  try {
    yield put({ type: GET_REQUEST_COUNT.start });
    const { data } = yield apiCall({
      url: API.GET_REQUEST_BASKETS_COUNT,
      type: 'GET',
    });
    yield put({ type: GET_REQUEST_COUNT.success, payload: data });
  } catch (e) {
    yield put({ type: GET_REQUEST_COUNT.fail, error: getError(e) });
  }
}

function* getReadyBaskets({
  payload: {
    offset,
    limit,
    isScrollUpdate,
  },
}) {
  try {
    yield put({ type: GET_READY_BASKETS.start, payload: { isScrollUpdate } });
    yield put({ type: GET_READY_BASKETS.success, payload: { data: [], offset, limit } });
  } catch (e) {
    yield put({ type: GET_READY_BASKETS.fail, payload: getError(e) });
  }
}

function* getRegularBaskets({
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    offset,
    limit,
    isScrollUpdate,
    regularSort,
    regularFilters,
    abortSignal,
  },
}) {
  try {
    const sortParams = checkSortParams(regularSort);

    yield put({ type: GET_REGULAR_BASKETS.start, payload: { isScrollUpdate } });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_REGULAR_BASKETS,
      signal: abortSignal,
      body: {
        partnersInsProductId,
        refCurrencyCode,
        warrantyLevel,
        refIszOptionTypeCode,
        refProductTypeCode,
        offset,
        limit,
        filters: regularFilters,
        sort: sortParams,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });
    yield put({ type: GET_REGULAR_BASKETS.success, payload: { data, offset, limit } });
  } catch (e) {
    yield put({ type: GET_REGULAR_BASKETS.fail, payload: getError(e) });
  }
}

function* getReadyAndRegularBaskets({
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    readyAbortSignal,
    regularAbortSignal,
  },
}) {
  const filterParams = [];
  yield all([
    getReadyBaskets({
      payload: {
        partnersInsProductId,
        refCurrencyCode,
        warrantyLevel,
        refIszOptionTypeCode,
        refProductTypeCode,
        limit: getCountRow(2),
        isScrollUpdate: false,
        offset: 0,
        readySort: [],
        readyFilters: filterParams,
        abortSignal: readyAbortSignal,
      },
    }),
    getRegularBaskets({
      payload: {
        partnersInsProductId,
        refCurrencyCode,
        warrantyLevel,
        refIszOptionTypeCode,
        refProductTypeCode,
        limit: getCountRow(2),
        isScrollUpdate: false,
        offset: 0,
        regularSort: [],
        regularFilters: filterParams,
        abortSignal: regularAbortSignal,
      },
    }),
  ]);
}

function* executionCalculatedBasket({
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    qualification,
  },
}) {
  try {
    yield put({ type: EXECUTION_CALCULATING_BASKET.start });

    const { data } = yield apiCall({
      type: 'POST',
      url: API.EXECUTION_BASKET(mainInsLineIszCalcRequestId),
      body: {
        childInsLineIszCalcRequestIds,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode,
        refCurrencyCode,
        partnersInsProductId,
        refProductTypeCode,
        qualification,
      },
    });

    yield put({ type: EXECUTION_CALCULATING_BASKET.success, payload: { ...data, guaranteeLevel } });
    getGlobalHistory().push(`${ROUTES.preliminaryCalculation}/${data.partnersInsProductId}`);
  } catch (e) {
    yield put({ type: EXECUTION_CALCULATING_BASKET.fail, payload: getError(e) });
  }
}

function* preExecutionCalculatedDpPolicyBasket({
  payload: {
    productId,
    mainInsLineIszCalcRequestId,
    guaranteeLevel,
    action,
    ...rest
  },
}) {
  try {
    if (!mainInsLineIszCalcRequestId) {
      return;
    }

    yield put({ type: PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.start });

    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_PRODUCT_FORM,
      body: {
        id: Number(productId),
        mainRequestId: Number(mainInsLineIszCalcRequestId),
        guaranteeLevel,
      },
    });

    const isMultiObjects = data.insLineIszObjects.length > 1;

    yield put({
      type: PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.success,
      payload: {
        productForm: data,
        isOpen: isMultiObjects,
      },
    });

    if (!data.productSelection.status) {
      toastr.error('', PRODUCT_EXECUTION_DISABLE);

      return;
    }

    if (!isMultiObjects) {
      yield executionCalculatedDpPolicyBasket({
        payload: {
          ...rest,
          mainInsLineIszCalcRequestId,
          guaranteeLevel,
          action,
        },
      });
    }
  } catch (e) {
    yield put({ type: PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.fail, payload: getError(e) });
  }
}

function* executionCalculatedDpPolicyBasket({
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    qualification,
    insLineIszObjectId,
    action,
  },
}) {
  try {
    yield put({ type: EXECUTION_CALCULATING_DP_POLICY_BASKET.start });

    const { data } = yield apiCall({
      type: 'POST',
      url: API.EXECUTION_DP_POLICY_BASKET(mainInsLineIszCalcRequestId),
      body: {
        childInsLineIszCalcRequestIds,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode,
        refCurrencyCode,
        partnersInsProductId,
        refProductTypeCode,
        qualification,
        insLineIszObjectId: insLineIszObjectId || undefined,
      },
    });

    yield put({
      type: EXECUTION_CALCULATING_DP_POLICY_BASKET.success,
      payload: data,
    });

    toastr.success('', DP_POLICY_BASKET_SENT);
    action();
  } catch (e) {
    yield put({ type: EXECUTION_CALCULATING_DP_POLICY_BASKET.fail, payload: getError(e) });
  }
}

function* insLineIszCalc({
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    insuranceAmount,
    mainRequestId,
    childRequestIds,
  },
}) {
  try {
    yield put({ type: INS_LINE_ISZ_CALC.start });

    const { data } = yield apiCall({
      type: 'POST',
      url: API.SAVE_CALC_VALUE(mainInsLineIszCalcRequestId),
      body: {
        childInsLineIszCalcRequestIds,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode,
        refCurrencyCode,
        partnersInsProductId,
        refProductTypeCode,
        insuranceAmount,
        mainRequestId,
        childRequestIds,
      },
    });

    yield put({ type: INS_LINE_ISZ_CALC.success, payload: { ...data, guaranteeLevel } });
  } catch (e) {
    yield put({ type: INS_LINE_ISZ_CALC.fail, payload: getError(e) });
  }
}

function* generateReport({
  payload: {
    partnersInsProductId,
    guaranteeLevel,
    refCurrencyCode,
    refIszOptionTypeCode,
    refProductTypeCode,
    sort,
    filters,
    abortSignal,
  },
}) {
  try {
    yield put({ type: GENERATE_REPORT.start });
    yield apiDownload({
      type: 'POST',
      url: API.GENERATE_REPORT,
      signal: abortSignal,
      body: {
        partnersInsProductId,
        warrantyLevel: guaranteeLevel,
        refCurrencyCode,
        refIszOptionTypeCode,
        refProductTypeCode,
        sort,
        filters,
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    }, `report ${moment().format(DATE_CRETE_FORMAT_CONSTRUCTOR)}.xlsx`);
    yield put({ type: GENERATE_REPORT.success });
  } catch (e) {
    yield put({ type: GENERATE_REPORT.fail, payload: getError(e) });
  }
}

export function* checkJustNowExecutionBasket({
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    justNowExecutionBasketId,
  },
}) {
  try {
    yield put(setJustNowExecutionBasketPayload({
      id: null,
      refCurrencyCode: null,
      refIszOptionTypeCode: null,
    }));
    yield put({ type: CHECK_JUST_NOW_EXECUTION_BASKET.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_CALCULATED_BASKETS,
      body: {
        partnersInsProductId,
        refCurrencyCode,
        warrantyLevel,
        refIszOptionTypeCode,
        isOnlyFavorite: false,
        refProductTypeCode,
        offset: 0,
        limit: 1,
        filters: [{
          name: 'insLineIszCalcBasketId',
          value: String(justNowExecutionBasketId),
          isExact: true,
        }],
        sort: [],
        userTimezoneCode: momentTimeZone.tz.guess(),
      },
    });

    if (!data.length) {
      yield put(setWarningMessage(WARNING_BASKET_GUARANTEE_LEVEL_DOES_NOT_FIT));

      return;
    }

    const isNegateCalculateValue = data[0].coupon < 0 || data[0].partitionCoeff < 0;

    if (isNegateCalculateValue) {
      yield put(setWarningMessage(WARNING_BASKET_NEGATE_COUPON));
    }

    yield put({ type: CHECK_JUST_NOW_EXECUTION_BASKET.success });
  } catch (e) {
    yield put({ type: CHECK_JUST_NOW_EXECUTION_BASKET.fail, payload: getError(e) });
  }
}

export function* annulOldConstructor({ payload: { ctsIds } }) {
  try {
    yield put({ type: ANNUL_OLD_CONSTRUCTOR.start });
    yield apiCall({
      url: `${API.ANNUL_OLD_CONSTRUCTOR}`,
      type: 'PUT',
      body: { ctsIds },
    });
    yield put({ type: ANNUL_OLD_CONSTRUCTOR.success });
  } catch (e) {
    yield put({ type: ANNUL_OLD_CONSTRUCTOR.fail, payload: getError(e) });
  }
}

export function* tablesSaga() {
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_ASSETS_DESCRIPTION.request, getAssetsDescription);
  yield throttle(DEFAULT_THROTTLE_VALUE, SET_FAVORITES_DESCRIPTION.request, setFavorites);
  yield throttle(DEFAULT_THROTTLE_VALUE, DELETE_FAVORITES_DESCRIPTION.request, deleteFavorites);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_HINTS_FOR_BASKETS.request, getHintsForBaskets);
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_HINTS_FOR_READY_BASKETS.request,
    getHintsForReadyBaskets,
  );
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_HINTS_FOR_REGULAR_BASKETS.request,
    getHintsForRegularBaskets,
  );
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_READY_BASKETS.request, getReadyBaskets);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_REGULAR_BASKETS.request, getRegularBaskets);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_REQUEST_COUNT.request, getUserRequestCount);
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_HINTS_FOR_READY_BASKETS.request,
    getHintsForReadyBaskets,
  );
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_HINTS_FOR_REGULAR_BASKETS.request,
    getHintsForRegularBaskets,
  );
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_MIN_INSURANCE_SUM_TABLES.request, getMinInsuranceSum);
  yield throttle(DEFAULT_THROTTLE_VALUE, GET_PRODUCT_TYPES_TABLE_CONTROL.request, getProductTypes);
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_READY_AND_REGULAR_BASKETS,
    getReadyAndRegularBaskets,
  );
  yield throttle(DEFAULT_THROTTLE_VALUE, GENERATE_REPORT.request, generateReport);
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    EXECUTION_CALCULATING_DP_POLICY_BASKET.request,
    executionCalculatedDpPolicyBasket,
  );
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.request,
    preExecutionCalculatedDpPolicyBasket,
  );
  yield takeEvery(CHECK_JUST_NOW_EXECUTION_BASKET.request, checkJustNowExecutionBasket);
  yield takeEvery(ANNUL_OLD_CONSTRUCTOR.request, annulOldConstructor);

  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_AMOUNT_FAVORITES_BASKET.request,
    getAmountFavoriteBasket,
  );

  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    EXECUTION_CALCULATING_BASKET.request,
    executionCalculatedBasket,
  );
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    INS_LINE_ISZ_CALC.request,
    insLineIszCalc,
  );
  yield throttle(
    DEFAULT_THROTTLE_VALUE,
    GET_CALCULATED_PERSON_BASKETS.request,
    getCalculatedPersonBaskets,
  );
}
