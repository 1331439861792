import {
  START_SCHEDULER_JOB_REQUEST,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_CRON_TYPES_REQUEST,
  GET_PRIORITIES_REQUEST,
  SET_SETTINGS_FOR_JOB,
  STOP_SCHEDULER_JOB_REQUEST,
  SET_ACTIVE_TAB,
  OPEN_POPUP_FOR_EDIT,
  OPEN_POPUP_FOR_CONFIRM_STOP,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST,
  GET_JOBS_COUNT_REQUEST,
  CLEAR_FORM,
} from '../types';

const getInitial = () => ({
  type: CLEAR_FORM,
});

const getJobsCount = () => ({
  type: GET_JOBS_COUNT_REQUEST,
});

const getProccessJobs = (page) => ({
  type: GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST,
  payload: page,
});

const getCompleteJobs = (page) => ({
  type: GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST,
  payload: page,
});

const getFailJobs = (page) => ({
  type: GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST,
  payload: page,
});

const openPopUptForConfirmStop = (type) => ({
  type: OPEN_POPUP_FOR_CONFIRM_STOP,
  payload: type,
});

const openPopUpForEidt = (type) => ({
  type: OPEN_POPUP_FOR_EDIT,
  payload: type,
});

const createJobScheduler = (body, currentPage) => ({
  type: START_SCHEDULER_JOB_REQUEST,
  payload: { body, currentPage },
});

const stopJobScheduler = (taskType, currentPage) => ({
  type: STOP_SCHEDULER_JOB_REQUEST,
  payload: { taskType, currentPage },
});

const getJobsForScheduler = (page) => ({
  type: GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST,
  payload: page,
});

const getSelectsCrons = () => ({
  type: GET_CRON_TYPES_REQUEST,
});

const getSelectPriorities = () => ({
  type: GET_PRIORITIES_REQUEST,
});

const setSettingForJob = (id, name, value) => ({
  type: SET_SETTINGS_FOR_JOB,
  payload: { id, name, value },
});

const setActiveTab = (tabName, tab) => ({
  type: SET_ACTIVE_TAB,
  payload: { tabName, tab },
});

export {
  getJobsCount,
  getFailJobs,
  getCompleteJobs,
  getProccessJobs,
  setActiveTab,
  createJobScheduler,
  stopJobScheduler,
  getJobsForScheduler,
  getSelectPriorities,
  getSelectsCrons,
  setSettingForJob,
  openPopUpForEidt,
  openPopUptForConfirmStop,
  getInitial,
};
