import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import { block } from 'bem-cn';
import {
  Select,
  Button,
  Form,
} from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { FieldBlock, PayoutInfo, InputField } from 'components';
import {
  clearFormContracts,
  saveContractStatus,
  getStatusList,
  getStatesList,
  setSelectValue,
  getContract,
  setPayoutSumContract,
  addPaymentToContract,
  deletePaymentFromContract,
} from 'redux/rootActions';
import { onlyNumbers } from 'helpers';
import {
  LANG_DICTIONARY,
  DATE_PICKER_FORMAT,
  MASK,
  REGEXP,
} from 'consts';
import ru from 'date-fns/locale/ru';
import './styles.scss';

registerLocale('ru', ru);
const { birthDateMask } = MASK;

const { CONTRACT_ID } = REGEXP;

const {
  SEARCH_CONTRACT,
  CURRENT_STATUS_CONTRACT,
  CURRENT_STATE_CONTRACT,
  EXECUTE,
  EXECUTE_CHANGES_CONTRACT,
  STATUS_MANAGMENT,
  CONTRACTES,
  DESCRIPTION_CONTRACTS_CONTROL,
  CONTRACT_NUMBER,
  NOT_CHOOSED,
  DATE_REMOVE_CONTRACT,
} = LANG_DICTIONARY;

const b = block('administration');

const propTypes = {
  getStatuses: PropTypes.func,
  getStates: PropTypes.func,
  clearForm: PropTypes.func,
  setSelect: PropTypes.func,
  getContractInfo: PropTypes.func,
  saveContract: PropTypes.func,
  setPayoutSum: PropTypes.func,
  addPayment: PropTypes.func,
  deletePayment: PropTypes.func,
  contractInfo: PropTypes.arrayOf(PropTypes.object),
  statuses: PropTypes.arrayOf(PropTypes.object),
  currentStates: PropTypes.arrayOf(PropTypes.object),
  contractNotFounded: PropTypes.bool,
};

const defaultProps = {
  contractInfo: [],
  statuses: [],
  currentStates: [],
  contractNotFounded: false,
  getStatuses: () => null,
  getStates: () => null,
  clearForm: () => null,
  setSelect: () => null,
  getContractInfo: () => null,
  saveContract: () => null,
  setPayoutSum: () => null,
  addPayment: () => null,
  deletePayment: () => null,
};

class ContractStatusControl extends Component {
  constructor(props) {
    super(props);
    this.state = {
      id: null,
      payoutStatusError: false,
    };
  }

  componentDidMount() {
    const {
      getStatuses,
      getStates,
    } = this.props;
    getStates();
    getStatuses();
  }

  componentWillUnmount() {
    const { clearForm } = this.props;
    clearForm();
  }

  handleChangeInput = ({ target: { value } }) => {
    this.setState({
      id: value.replace(CONTRACT_ID, ''),
    });
  }

  handleChangeSelect = (name) => (e, { value }) => {
    const { setSelect } = this.props;
    setSelect(name, value);
  }

  handleChangeDate = (name) => (e) => {
    const { setSelect } = this.props;
    setSelect(name, e);
  }

  onBlurDate = (name, date) => {
    const { setSelect } = this.props;
    setSelect(name, onlyNumbers(date));
  }

  searchContract = () => {
    const { getContractInfo } = this.props;
    const { id } = this.state;
    if (id) {
      getContractInfo(id);
    }
  }

  saveDataContract = () => {
    const { saveContract, contractInfo } = this.props;
    const { payouts } = contractInfo;
    const payments = payouts.map((item) => ({
      ...item,
      id: undefined,
      premiumCur: undefined,
      totalPaymentCur: item.totalPaymentCur ? onlyNumbers(String(item.totalPaymentCur)) : null,
    }));
    saveContract({ ...contractInfo, payments });
  }

  render() {
    const {
      contractInfo,
      statuses,
      currentStates,
      contractNotFounded,
      setPayoutSum,
      addPayment,
      deletePayment,
    } = this.props;
    const { id, payoutStatusError } = this.state;

    return (
      <div className={b()}>
        <section className={b('header-menu')}>
          <div className={b('header-title')}>
            {STATUS_MANAGMENT}
          </div>
          <div className={b('switch-button-active')}>{CONTRACTES}</div>
        </section>
        <div className={b('control-contracts')}>
          <section className={b('info')}>
            <p className={b('info-header')}>{DESCRIPTION_CONTRACTS_CONTROL}</p>
          </section>
          <Form className={b('control-contracts-content').toString()}>
            <section className={b('content')}>
              <p className={b('content-header')}>{SEARCH_CONTRACT}</p>
              <FieldBlock title={CONTRACT_NUMBER}>
                <div className="ui icon input">
                  <input
                    type="text"
                    placeholder="Поиск..."
                    onChange={this.handleChangeInput}
                    value={id}
                  />
                  <i
                    aria-hidden="true"
                    className="search circular inverted link icon"
                    onClick={this.searchContract}
                  />
                </div>
              </FieldBlock>
              <FieldBlock title={CURRENT_STATUS_CONTRACT}>
                <Select
                  options={statuses}
                  value={contractInfo.status || ''}
                  placeholder={NOT_CHOOSED}
                  onChange={this.handleChangeSelect('status')}
                  disabled={contractNotFounded}
                />
              </FieldBlock>
              <FieldBlock title={CURRENT_STATE_CONTRACT}>
                <Select
                  options={currentStates}
                  value={contractInfo.currentState || ''}
                  placeholder={NOT_CHOOSED}
                  onChange={this.handleChangeSelect('currentState')}
                  disabled={contractNotFounded}
                />
              </FieldBlock>
              <FieldBlock title={DATE_REMOVE_CONTRACT}>
                <DatePicker
                  maxDate={new Date()}
                  locale="ru"
                  disabled={contractNotFounded}
                  selected={contractInfo.dateCancel}
                  onChangeRaw={(e) => this.onBlurDate('dateHave', e.target.value)}
                  onChange={this.handleChangeDate('dateCancel')}
                  onBlur={() => (contractInfo.dateHave.length ? null : this.onBlurDate('dateCancel', null))}
                  dateFormat={DATE_PICKER_FORMAT}
                  showYearDropdown
                  customInput={(
                    <InputField value={contractInfo.dateCancel} mask={birthDateMask} />
                )}
                />
              </FieldBlock>
              {!contractNotFounded && (
              <PayoutInfo
                payout={contractInfo.paymentFreq}
                payoutsArray={contractInfo.payouts}
                selectList={currentStates}
                setPayoutSum={setPayoutSum}
                currencyShort={contractInfo.currencyShort}
                addPaymentToContract={addPayment}
                deletePaymentFromContract={deletePayment}
                haveError={payoutStatusError}
                control
              />
              )}
              <FieldBlock title={EXECUTE_CHANGES_CONTRACT}>
                <Button
                  type="submit"
                  className={b('submit').toString()}
                  onClick={this.saveDataContract}
                  disabled={contractNotFounded}
                >
                  {EXECUTE}
                </Button>
              </FieldBlock>
            </section>
          </Form>
        </div>
      </div>
    );
  }
}
const mapStateToProps = ({
  transferData,
}) => ({
  ...transferData,
});
const mapDispatchToProps = {
  getContractInfo: getContract,
  clearForm: clearFormContracts,
  saveContract: saveContractStatus,
  getStatuses: getStatusList,
  getStates: getStatesList,
  setSelect: setSelectValue,
  setPayoutSum: setPayoutSumContract,
  addPayment: addPaymentToContract,
  deletePayment: deletePaymentFromContract,
};

ContractStatusControl.propTypes = propTypes;
ContractStatusControl.defaultProps = defaultProps;

export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(ContractStatusControl),
);
