import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import {
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { getScanDirectly } from 'redux/rootActions';
import { block } from 'bem-cn';
import InlineCss from 'react-inline-css';
import ScanInterface from './interface';
import './styles.scss';

const b = block('scan-interface');
const PDFViewer = React.lazy(() => import('pdf-viewer-reactjs'));

const propTypes = {
  fileType: PropTypes.string,
  fileLink: PropTypes.string,
  loadingFile: PropTypes.bool,
  fileName: PropTypes.string,
  getScanDirectlyAction: PropTypes.func,
  ctsId: PropTypes.number,
  isShowDownloadIcon: PropTypes.bool,
};

const defaultProps = {
  fileType: '',
  fileLink: '',
  loadingFile: '',
  fileName: '',
  getScanDirectlyAction: () => null,
  ctsId: 0,
  isShowDownloadIcon: true,
};

const ZOOM_STEP = 25;
const MAX_ZOOM = 150;
const ZOOM_MIN = 100;

export const heightCanvas = 850;
export const widthCanvas = 905;

const ScanWindow = ({
  fileLink,
  loadingFile,
  fileName,
  getScanDirectlyAction,
  ctsId,
  fileType,
  isShowDownloadIcon,
}) => {
  const [pdfReader, setRef] = useState(React.createRef());
  const [page, setPage] = useState(1);
  const [key, setKey] = useState('init-key');
  const [zoom, setZoom] = useState(ZOOM_MIN);
  const [rotateAngle, setRotateAngle] = useState(ZOOM_MIN);

  useEffect(() => {
    setRotateAngle(0);
  }, [fileName]);

  const setZoomValue = (value) => {
    setZoom(value);
  };

  const downloadScan = () => {
    getScanDirectlyAction(ctsId, fileName);
  };

  const handlRotateRight = () => {
    if (rotateAngle === 270) {
      setRotateAngle(0);
    } else {
      setRotateAngle(rotateAngle + 90);
    }
  };

  const handlRotateLeft = () => {
    if (rotateAngle === -270) {
      setRotateAngle(0);
    } else {
      setRotateAngle(rotateAngle - 90);
    }
  };

  const handleUpPage = () => {
    const { handleNextClick, state: { pages } } = pdfReader;
    handleNextClick();
    if (pages !== page) setPage(page + 1);
  };

  const handleDownPage = () => {
    const { handlePrevClick } = pdfReader;
    handlePrevClick();
    if (page > 1) setPage(page - 1);
  };

  const handleUpZoom = () => {
    const { handleZoomIn } = pdfReader;
    if (zoom < MAX_ZOOM) setZoomValue(zoom + ZOOM_STEP);
    handleZoomIn();
  };

  const handleDownZoom = () => {
    const { handleZoomOut } = pdfReader;
    if (zoom > 100) setZoomValue(zoom - ZOOM_STEP);
    handleZoomOut();
  };

  const resetInterface = () => {
    if (pdfReader && pdfReader.state) {
      pdfReader.state.scale = 1;
      pdfReader.state.page = 1;
    }
    setKey(Math.random());
    setZoomValue(ZOOM_MIN);
    setPage(1);
  };

  const width = fileType !== 'pdf' ? `${widthCanvas}px` : 'unset';

  return (
    <>
      <ScanInterface
        {...pdfReader}
        handleUpPage={handleUpPage}
        handleDownPage={handleDownPage}
        handleDownZoom={handleDownZoom}
        page={page}
        zoom={zoom}
        key={key}
        setKey={setKey}
        handleUpZoom={handleUpZoom}
        downloadScan={downloadScan}
        resetInterface={resetInterface}
        fileLink={fileLink}
        handlRotateLeft={handlRotateLeft}
        handlRotateRight={handlRotateRight}
        isShowDownloadIcon={isShowDownloadIcon}
      />
      <Dimmer.Dimmable as={Segment} dimmed={loadingFile}>
        <Dimmer active={loadingFile} inverted>
          <Loader>{`${LANG_DICTIONARY.DOWNLOADING}...`}</Loader>
        </Dimmer>
        <InlineCss stylesheet={`
          canvas {
            min-width: ${width};
            min-height: ${heightCanvas}px;
          }
        `}
        >
          <div className={b('pdf-wrapper')}>
            {fileLink && (
              <React.Suspense fallback={<Loader active />}>
                <PDFViewer
                  ref={(ref) => setRef(ref)}
                  loader={<div />}
                  document={{
                    url: fileLink,
                  }}
                  canvasCss={b('canvas-wrapper', { [`rotate${rotateAngle}`]: true, img: fileType !== 'pdf' }).toString()}
                  css={b('wrapper', { img: fileType !== 'pdf' }).toString()}
                  hideNavbar
                  rotationAngle={rotateAngle}
                />
              </React.Suspense>
            )}
          </div>
        </InlineCss>
      </Dimmer.Dimmable>
    </>
  );
};

ScanWindow.propTypes = propTypes;
ScanWindow.defaultProps = defaultProps;

const mapDispatchToProp = {
  getScanDirectlyAction: getScanDirectly,
};

export default connect(null, mapDispatchToProp)(ScanWindow);
