import React, { useMemo } from 'react';
import {
  Collapse,
} from 'reactstrap';
import { HelpMessage } from 'components';
import { Icon } from 'semantic-ui-react';
import { SortAndFilterInterface } from 'components/index';
import {
  DATE_FORMATS,
  LANG_DICTIONARY,
} from 'consts';
import { dateFormatter } from 'helpers';
import PropTypes from 'prop-types';
import { Scrollbars } from 'react-custom-scrollbars';
import ChildOptionTable from './ChildOptionTable';
import '../styles.scss';
import FavoritesIcon from '../../../../../_svgLib/favoritesIcon';
import RefreshButton from './RefreshButton';
import { declOfDay } from '../../../BasketCreatingForm/components/Strategy/helpers';

const {
  ASSETS_BASKET,
  PERIOD,
  PAYOUT_TIMES,
  BARRIERS,
  DOING_END,
  DATE_CREATE,
  STRATEGY_TYPE,
  STRATEGY,
  MAIN_WORD,
  COUPON_LESS,
  COUPON_MORE,
  PARTITION_COEFFICIENT_LESS,
  PARTITION_COEFFICIENT,
  FAVORITES,
  AUTOCALL_BARRIER,
  AUTOCALL_BONUS_COUPON,
  SCHEDULE_GARANT_PAYMENTS,
  SIMBOL_NUMBER,
  PRODUCT_NUMBER,
  NOTIONAL_BARRIER,
  GEARED_BARRIER,
  GROWTH_CAP,
  PROTECTION_COUPON,
  LOOKBACK,
  LOOKBACK_OPTION_DESCRIPTION,
} = LANG_DICTIONARY;

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})),
  isSortAndFilterInterface: PropTypes.bool,
  setSort: PropTypes.func,
  setFilter: PropTypes.func,
  table: PropTypes.string,
  preEnterData: PropTypes.shape({}),
  filterData: PropTypes.shape({}),
  sortData: PropTypes.shape({}),
  isCalculatedIndividualBaskets: PropTypes.bool,
  handleClickAssets: PropTypes.func,
  classNameTable: PropTypes.string,
  onScroll: PropTypes.func.isRequired,
  onToggleOpenChildBasket: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired,
  startArrowPositions: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onSelectItem: PropTypes.func.isRequired,
  onClickAssets: PropTypes.func.isRequired,
  getPropsSortAndFilteringInterface: PropTypes.func.isRequired,
  containerRef: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.shape({ current: PropTypes.instanceOf(Element) }),
  ]).isRequired,
  onScrollStop: PropTypes.func.isRequired,
  childSelectedIds: PropTypes.shape({}).isRequired,
  onSelectChildItem: PropTypes.func,
  selected: PropTypes.oneOfType([PropTypes.shape({}), PropTypes.instanceOf(null)]),
  openChildIds: PropTypes.shape({}).isRequired,
  postfixSortFieldName: PropTypes.string,
  onClickFavorites: PropTypes.func,
  productOptions: PropTypes.arrayOf(PropTypes.shape({})),
  optionCode: PropTypes.string,
  isValueLessThanOneHalfMillion: PropTypes.bool,
  rebuildSchema: PropTypes.func.isRequired,
  recalculateCoupon: PropTypes.func,
  productId: PropTypes.number,
  isLoading: PropTypes.number,
};

const defaultProps = {
  data: [],
  isSortAndFilterInterface: false,
  setSort: () => null,
  setFilter: () => null,
  table: '',
  preEnterData: {},
  filterData: {},
  sortData: {},
  isCalculatedIndividualBaskets: false,
  handleClickAssets: () => null,
  onClickFavorites: () => null,
  onSelectChildItem: () => null,
  selected: null,
  classNameTable: '',
  postfixSortFieldName: '',
  productOptions: [],
  optionCode: '',
  isValueLessThanOneHalfMillion: true,
  recalculateCoupon: () => null,
  productId: null,
  isLoading: 0,
};

const MainBarrierOptionTable = (props) => {
  const {
    data,
    block,
    isSortAndFilterInterface,
    isCalculatedIndividualBaskets,
    classNameTable,
    onScroll,
    onToggleOpenChildBasket,
    startArrowPositions,
    onSelectItem,
    onClickAssets,
    onClickFavorites,
    getPropsSortAndFilteringInterface,
    containerRef,
    onScrollStop,
    childSelectedIds,
    onSelectChildItem,
    selected,
    openChildIds,
    postfixSortFieldName,
    productOptions,
    optionCode,
    isValueLessThanOneHalfMillion,
    recalculateCoupon,
    productId,
    isLoading,
  } = props;

  const basketsData = data.filter((b) => {
    if (b.lookbackFlag) {
      return JSON.stringify(b.lookbackData) === JSON.stringify(b.refIszOptionTypeLookbackData);
    }

    return true;
  });

  const flagsProductOptions = productOptions.reduce((acc, option) => {
    acc[option.name] = {
      isCoupon: option.isCoupon,
      isAutocall: option.isCoupon,
      isFlexRedemption: option.isFlexRedemption,
      isFixRedemption: option.isFixRedemption,
      isLookback: option.lookbackData?.length,
    };

    return acc;
  }, {});

  const isCoupon = useMemo(() => flagsProductOptions[optionCode]?.isCoupon, [optionCode]);
  const isAutocall = useMemo(() => flagsProductOptions[optionCode]?.isAutocall, [optionCode]);
  // const isLookback = useMemo(() => flagsProductOptions[optionCode]?.isLookback, [optionCode]);
  const isFlexRedemption = useMemo(
    () => flagsProductOptions[optionCode]?.isFlexRedemption, [optionCode],
  );
  const isFixRedemption = useMemo(
    () => flagsProductOptions[optionCode]?.isFixRedemption, [optionCode],
  );

  const onRefresh = (basket, child) => {
    recalculateCoupon({
      productId,
      basket,
      child,
      optionCode,
    });
  };

  const getGridTemplateColumns = () => [
    'minmax(4rem, 4rem)', // ID
    ...(isCalculatedIndividualBaskets ? [
      'minmax(11rem, 11rem)', // Date created
    ] : []),

    'minmax(9rem, 9rem)', // Strategy
    'minmax(10rem, 10rem)', // Str. type
    'minmax(10rem, 3fr)', // Assets

    ...(isCalculatedIndividualBaskets ? [
      'minmax(6rem, 6rem)', // Favorite
    ] : []),

    'minmax(6rem, 6rem)', // Term
    'minmax(8rem, 8rem)', // Date end

    'minmax(10.5rem, 3fr)', // Freq
    'minmax(8rem, 12rem)', // Barriers
    ...(isCoupon ? [
      ...(isValueLessThanOneHalfMillion ? ['minmax(12rem, 3fr)'] : []), // Coupon less
      'minmax(8rem, 3fr)', // Coupon greater
    ] : [
      ...(isValueLessThanOneHalfMillion ? ['minmax(12rem, 3fr)'] : []), // Part coef less
      'minmax(12rem, 3fr)', // Part coef greater
      'minmax(6rem, 1fr)', // Growth cap
    ]),

    ...(isAutocall ? [
      'minmax(6rem, 6rem)', // Autocall barrier
      'minmax(7rem, 7rem)', // Bonus coupon
    ] : []),

    ...(isFlexRedemption || isFixRedemption ? [
      'minmax(6rem, 6rem)', // Notional Barrier
    ] : []),

    ...(isFlexRedemption ? [
      'minmax(6rem, 6rem)', // Geared Barrier
    ] : []),

    ...(isFixRedemption ? [
      'minmax(6rem, 6rem)', // Protection coupon
    ] : []),

    [
      'minmax(6rem, 6rem)', // lookback
    ],

    'minmax(10rem, auto)', // Garant-payments
  ].join(' ');

  const getChildTemplateColumns = () => [
    isCalculatedIndividualBaskets ? '24rem' : '13rem',
    '10rem',
    'auto',
  ].join(' ');

  return (
    <div className={block.mix(classNameTable).toString()} ref={containerRef}>
      <Scrollbars
        onScroll={onScroll}
        onScrollStop={onScrollStop}
        renderView={(prop) => <div {...prop} className={block('view')} />}
        renderThumbHorizontal={(prop) => <div {...prop} className={block('thumb-horizontal')} />}
        className={block('scroll')}
      >
        <div className={block('grid').toString()} style={{ gridTemplateColumns: getGridTemplateColumns() }}>
          <div className={`${block('row').toString()} ${block('table-header').toString()}`}>
            <div>
              <span>{SIMBOL_NUMBER}</span>
              {isSortAndFilterInterface && (
              <SortAndFilterInterface
                {...getPropsSortAndFilteringInterface('insLineIszCalcBasketId', PRODUCT_NUMBER)}
              />
              )}
            </div>

            {isCalculatedIndividualBaskets && (
              <div>
                <span>{DATE_CREATE}</span>
                {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('dateCreate', DATE_CREATE, postfixSortFieldName)}
                />
                )}
              </div>
            )}
            <div>
              <span>{STRATEGY}</span>
              {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('subbasketsAmount', STRATEGY)}
                />
              )}
            </div>
            <div>{STRATEGY_TYPE}</div>
            <div>
              <span>{ASSETS_BASKET}</span>
              {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('basketAssetsText', ASSETS_BASKET)}
                />
              )}
            </div>
            {isCalculatedIndividualBaskets && (
              <div>{FAVORITES}</div>
            )}
            <div>
              <span>{PERIOD}</span>
              {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('term', PERIOD)}
                />
              )}
            </div>
            <div>
              <span>{DOING_END}</span>
              {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('dateEnd', DOING_END, postfixSortFieldName)}
                />
              )}
            </div>
            {!isCoupon &&
              (
              <>
                {isValueLessThanOneHalfMillion && (
                <div>
                  <span>{PARTITION_COEFFICIENT_LESS}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('yield1', PARTITION_COEFFICIENT_LESS, '', (value) => value.replaceAll(',', '.'))}
                    isDisableHints
                  />
                  )}
                </div>
                )}
                <div>
                  <span>{PARTITION_COEFFICIENT}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('yield2', PARTITION_COEFFICIENT, '', (value) => value.replaceAll(',', '.'))}
                    isDisableHints
                  />
                  )}
                </div>

                <div>
                  {GROWTH_CAP}
                </div>
              </>
              )}
            <div>
              <span>{PAYOUT_TIMES}</span>
              {isSortAndFilterInterface && (
              <SortAndFilterInterface
                {...getPropsSortAndFilteringInterface('refInsDateUnitValue2', PAYOUT_TIMES)}
              />
              )}
            </div>
            <div>
              <span>{BARRIERS}</span>
              {isSortAndFilterInterface && (
              <SortAndFilterInterface
                {...getPropsSortAndFilteringInterface('basketBarriersText', BARRIERS)}
              />
              )}
            </div>
            {isCoupon &&
              (
              <>
                {isValueLessThanOneHalfMillion && (
                <div>
                  <span>{COUPON_LESS}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('yield1', COUPON_LESS, '', (value) => value.replaceAll(',', '.'))}
                    isDisableHints
                  />
                  )}
                </div>
                )}
                <div>
                  <span>{COUPON_MORE}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('yield2', COUPON_MORE, '', (value) => value.replaceAll(',', '.'))}
                    isDisableHints
                  />
                  )}
                </div>
              </>
              )}
            {isAutocall && (
              <>
                <div>
                  <span>{AUTOCALL_BARRIER}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('autocallBarrier', AUTOCALL_BARRIER)}
                  />
                  )}
                </div>
                <div>
                  <span>{AUTOCALL_BONUS_COUPON}</span>
                  {isSortAndFilterInterface && (
                  <SortAndFilterInterface
                    {...getPropsSortAndFilteringInterface('autocallBonusCoupon', AUTOCALL_BONUS_COUPON)}
                  />
                  )}
                </div>
              </>
            )}
            {(isFlexRedemption || isFixRedemption) && (
              <>
                <div>
                  <span>{NOTIONAL_BARRIER}</span>
                </div>
              </>
            )}
            {isFlexRedemption &&
              (
              <>
                <div>
                  <span>{GEARED_BARRIER}</span>
                </div>
              </>
              )}
            {isFixRedemption &&
              (
              <>
                <div>
                  <span>{PROTECTION_COUPON}</span>
                </div>
              </>
              )}
            <div>{SCHEDULE_GARANT_PAYMENTS}</div>
            <div>
              <span>{LOOKBACK}</span>
              {isSortAndFilterInterface && (
                <SortAndFilterInterface
                  {...getPropsSortAndFilteringInterface('lookbackFlag', LOOKBACK)}
                  isSortingDisabled
                />
              )}
              <HelpMessage message={LOOKBACK_OPTION_DESCRIPTION} />
            </div>
          </div>
          {basketsData.map((basket, index) => {
            const {
              id,
              basketAssetsText,
              term,
              refInsDateUnitValue2,
              basketBarriersText,
              dateEnd,
              dateCreate,
              refIszOptionTypeDescription,
              subbasketsAmount,
              child,
              yield1,
              yield2,
              autocallBarrier,
              autocallBonusCoupon,
              isFavorite,
              isActive,
              insLineIszCalcBasketGarantSchedule,
              insLineIszCalcBasketId,
              notionalBarrier,
              gearedBarrier,
              growthCap,
              protectionCoupon,
              lookbackFlag,
              lookbackData,
            } = basket;

            const isSelected = Boolean(selected && selected.id === id);

            return (
              <div
                role="row"
                tabIndex={0}
                onClick={() => onSelectItem(basket)}
                className={block('row', { choosed: isSelected, unactivated: !isActive }).toString()}
              >
                <div>
                  {insLineIszCalcBasketId}
                </div>
                {isCalculatedIndividualBaskets && (
                  <div>
                    {dateCreate}
                  </div>
                )}
                <div className={block('strategy').mix(block('main-strategy')).toString()}>
                  {MAIN_WORD}
                  {subbasketsAmount !== 0 && (
                    <div className={block('multi-strategy-count')}>
                      <Icon
                        name={openChildIds[id] ? 'minus' : 'plus'}
                        className={block('multi-strategy-dropdown').toString()}
                        onClick={(e) => onToggleOpenChildBasket(e, basket)}
                      />
                      {subbasketsAmount}
                    </div>
                  )}
                </div>
                <div>
                  {refIszOptionTypeDescription}
                </div>
                <div className={block('asset-button').toString()}>
                  {basketAssetsText}
                  <Icon
                    name="info circle"
                    className={block('asset-icon').toString()}
                    onClick={(e) => onClickAssets(e, id)}
                  />
                </div>
                {isCalculatedIndividualBaskets && (
                  <div role="button" tabIndex={0} onClick={(e) => onClickFavorites({ e, id, isFavorite })}>
                    <FavoritesIcon isActive={isFavorite} />
                  </div>
                )}
                <div>
                  {dateFormatter(term, DATE_FORMATS.year)}
                </div>
                <div>
                  {dateEnd}
                </div>
                {!isCoupon && (
                  <>
                    {isValueLessThanOneHalfMillion && (
                    <div>
                      {isActive ?
                        `${yield1?.toFixed(1)}%` :
                        (
                          <RefreshButton
                            onRefresh={onRefresh}
                            basket={basket}
                            child={child}
                            isLoading={isLoading}
                            id={id}
                            selectedId={selected?.id}
                          />
                        )}
                    </div>
                    )}
                    <div>
                      {isActive ?
                        `${yield2?.toFixed(1)}%` :
                        (
                          <RefreshButton
                            onRefresh={onRefresh}
                            basket={basket}
                            child={child}
                            isLoading={isLoading}
                            id={id}
                            selectedId={selected?.id}
                          />
                        )}
                    </div>
                    <div>
                      {growthCap}
                    </div>
                  </>
                )}
                <div>
                  {refInsDateUnitValue2}
                </div>
                <div>
                  {basketBarriersText}
                </div>
                {isCoupon && (
                  <>
                    {isValueLessThanOneHalfMillion && (
                    <div>
                      {isActive ?
                        `${yield1?.toFixed(1)}%` :
                        (
                          <RefreshButton
                            onRefresh={onRefresh}
                            basket={basket}
                            child={child}
                            isLoading={isLoading}
                            id={id}
                            selectedId={selected?.id}
                          />
                        )}
                    </div>
                    )}
                    <div>
                      {isActive ?
                        `${yield2?.toFixed(1)}%` :
                        (
                          <RefreshButton
                            onRefresh={onRefresh}
                            basket={basket}
                            child={child}
                            isLoading={isLoading}
                            id={id}
                            selectedId={selected?.id}
                          />
                        )}
                    </div>
                  </>
                )}
                {isAutocall && (
                  <>
                    <div>
                      {autocallBarrier}
                    </div>
                    <div>
                      {autocallBonusCoupon}
                    </div>
                  </>
                )}
                {
                      (isFlexRedemption || isFixRedemption) && (
                      <>
                        <div>
                          {notionalBarrier}
                        </div>
                      </>
                      )
                  }
                {
                      isFlexRedemption && (
                      <>
                        <div>
                          {gearedBarrier}
                        </div>
                      </>
                      )
                  }
                {
                      isFixRedemption && (
                      <>
                        <div>
                          {protectionCoupon}
                        </div>
                      </>
                      )
                  }
                <div>
                  {insLineIszCalcBasketGarantSchedule === null ?
                    <p>-</p>
                    : (
                      <div className={block('garant-schedule').toString()}>
                        {insLineIszCalcBasketGarantSchedule.map((item) => (
                          <div>{`${item.paymentTerm} мес: ${item.value}%`}</div>
                        ))}
                      </div>
                    )}
                </div>
                <div>
                  {!lookbackFlag ?
                    <p>-</p>
                    : (
                      <div className={block('asset-button').toString()}>
                        {lookbackData.map((el) => `+ ${el.periodLength} ${declOfDay(el.periodLength)}`).join('; ')}
                      </div>
                    )}
                </div>

                <Collapse
                  isOpen={Boolean(openChildIds[id])}
                >
                  <ChildOptionTable
                    block={block}
                    onClickAssets={onClickAssets}
                    data={child}
                    mainRequest={basket}
                    startArrowPosition={startArrowPositions[index]}
                    isOpen={Boolean(openChildIds[id])}
                    onSelectItem={onSelectChildItem}
                    childSelectedIds={childSelectedIds}
                    childTemplateColumns={getChildTemplateColumns()}
                  />
                </Collapse>
              </div>
            );
          })}
        </div>
      </Scrollbars>
    </div>
  );
};

MainBarrierOptionTable.defaultProps = defaultProps;
MainBarrierOptionTable.propTypes = propTypes;
export default MainBarrierOptionTable;
