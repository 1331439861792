import moment from 'moment';

export default {
  values: {
    personBlock: {
      lastName: 'Колейников',
      gender: 1,
      firstName: 'Игорь',
      patronymic: 'Алекссеевич',
      birthDate: '2000-05-05T20:00:00.000Z',
      birthPlace: 'Таганрог',
    },
    documentBlock: {
      documentType: 2,
      serial: 'III-МЮ',
      passportNumber: '123456',
      issuedBy: 'Московский городской ЗАГС Управления ЗАГС г.Москвы.',
      when: moment().subtract(1, 'months').toDate(),
      validUntil: '',
      kp: '022-073',
      issuedByAccepted: true,
    },
    addressBlock: {
      country: 'Россия',
      index: '347942',
      republic: 'обл Ростовская',
      area: '',
      city: 'г Таганрог',
      street: '1-й Новый пер',
      house: 'д 1',
      houseNumber: '1',
      apartment: 'кв 1',
      fiasId: '0146c050-9dee-4a2a-be3e-8f5c2c13f540',
      kladId: '6100001100003620001',
      addressFias: {
        data: {
          postal_code: '347942',
          country: 'Россия',
          country_iso_code: 'RU',
          federal_district: null,
          region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
          region_kladr_id: '6100000000000',
          region_iso_code: 'RU-ROS',
          region_with_type: 'Ростовская обл',
          region_type: 'обл',
          region_type_full: 'область',
          region: 'Ростовская',
          area_fias_id: null,
          area_kladr_id: null,
          area_with_type: null,
          area_type: null,
          area_type_full: null,
          area: null,
          city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
          city_kladr_id: '6100001100000',
          city_with_type: 'г Таганрог',
          city_type: 'г',
          city_type_full: 'город',
          city: 'Таганрог',
          city_area: null,
          city_district_fias_id: null,
          city_district_kladr_id: null,
          city_district_with_type: null,
          city_district_type: null,
          city_district_type_full: null,
          city_district: null,
          settlement_fias_id: null,
          settlement_kladr_id: null,
          settlement_with_type: null,
          settlement_type: null,
          settlement_type_full: null,
          settlement: null,
          street_fias_id: '99f44183-04a6-4243-9cb3-66026b3b062a',
          street_kladr_id: '61000011000036200',
          street_with_type: '1-й Новый пер',
          street_type: 'пер',
          street_type_full: 'переулок',
          street: '1-й Новый',
          house_fias_id: '0146c050-9dee-4a2a-be3e-8f5c2c13f540',
          house_kladr_id: '6100001100003620001',
          house_type: 'д',
          house_type_full: 'дом',
          house: '1',
          block_type: null,
          block_type_full: null,
          block: null,
          entrance: null,
          floor: null,
          flat_fias_id: null,
          flat_type: null,
          flat_type_full: null,
          flat: null,
          flat_area: null,
          square_meter_price: null,
          flat_price: null,
          postal_box: null,
          fias_id: '0146c050-9dee-4a2a-be3e-8f5c2c13f540',
          fias_code: '61000011000000003620001',
          fias_level: '8',
          fias_actuality_state: '0',
          kladr_id: '6100001100003620001',
          geoname_id: '484907',
          capital_marker: '0',
          okato: '60437000000',
          oktmo: '60737000001',
          tax_office: '6154',
          tax_office_legal: '6154',
          timezone: null,
          geo_lat: '47.272531',
          geo_lon: '38.929167',
          beltway_hit: null,
          beltway_distance: null,
          metro: null,
          qc_geo: '0',
          qc_complete: null,
          qc_house: null,
          history_values: [
            'пер Новый 1-й',
          ],
          unparsed_parts: null,
          source: null,
          qc: null,
        },
        unrestricted_value: '347942, Ростовская обл, г Таганрог, 1-й Новый пер, д 1',
        value: 'Ростовская обл, г Таганрог, 1-й Новый пер, д 1',
      },
      isManualAddress: true,
    },
    addressBlockLiving: {
      livingAddressIsSame: true,
      countryLiving: '',
      indexLiving: '',
      republicLiving: '',
      areaLiving: '',
      cityLiving: '',
      streetLiving: '',
      houseLiving: '',
      houseNumberLiving: '1',
      apartmentLiving: 'кв 1',
      fiasIdLiving: '',
      kladIdLiving: '',
      addressFiasLiving: {},
      isManualAddressLiving: false,
    },
    bankBlock: {
      bic: '',
      bankName: '',
      corAccount: '',
      bankInn: '',
      kpp: '',
      curAccount: '',
    },
    innSnilsBlock: {
      inn: '321312312321',
      snils: '062-811-748 55',
    },
    contactBlock: {
      mobilePhone: '+7(321)312-31-23',
      homePhone: '8(321)312-31-23',
      workPhone: '+7(321)312-32-13',
      contactPhone: '32132142141',
      email: 'childInsured@mail.ru',
    },
    relationBlock: {
      relationship: 1,
    },
  },
};
