import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { PopupContainer, MainBtn, InputField } from 'components';
import { onlyNumbers } from 'helpers';
import { Select, Icon } from 'semantic-ui-react';
import DatePicker, { registerLocale } from 'react-datepicker';
import { MASK, LANG_DICTIONARY } from 'consts';
import moment from 'moment/moment';
import ru from 'date-fns/locale/ru';
import './styles.scss';

registerLocale('ru', ru);

const {
  timeMask,
  timeDatePickerFormat,
  datePickerSchedulerFormat,
} = MASK;
const {
  CHOOSE_TIME_START_FOR_JOB,
  SAVE_WORD,
  TIME_START_WORD,
  TIME_WORD,
  INTERVAL_WITH_TIME,
} = LANG_DICTIONARY;
const b = block('scheduler-edit-job');
const bI = block('scheduler-import-block');
const propTypes = {
  cronTypeId: PropTypes.number,
  interval: PropTypes.number,
  timeStart: PropTypes.string,
  id: PropTypes.number,
  status: PropTypes.bool,
  taskType: PropTypes.string,
  priorityId: PropTypes.number,
  time: PropTypes.string,
  saveButtonPopUp: PropTypes.func,
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  isOpen: PropTypes.bool,
  popUpControl: PropTypes.func,
  popUp: PropTypes.bool,
  createTask: PropTypes.func,
  cancelTask: PropTypes.func,
  getTaskInfo: PropTypes.func,
  link: PropTypes.string,
  isNetwork: PropTypes.bool,
  path: PropTypes.string,
  activeTaskInfoImport: PropTypes.object,
};

const defaultProps = {
  activeTaskInfoImport: {},
  popUp: false,
  cronTypeSelect: [],
  isOpen: false,
  getTaskInfo: () => null,
  popUpControl: () => null,
  saveButtonPopUp: () => null,
  cronTypeId: 1,
  interval: 5,
  timeStart: new Date(),
  id: 0,
  status: false,
  taskType: '',
  priorityId: 1,
  time: '10:00',
  createTask: () => null,
  cancelTask: () => null,
  link: '',
  isNetwork: false,
  path: '',
};

class EditJobPopUp extends React.Component {
  constructor(props) {
    super(props);
    const {
      activeTaskInfoImport,
      cronTypeId,
      interval,
      timeStart,
      id,
      status,
      taskType,
      priorityId,
      time,
    } = this.props;

    const activeTaskTime = activeTaskInfoImport.timeStart && moment(activeTaskInfoImport.timeStart).format('HH:mm');

    this.state = {
      cronTypeId: activeTaskInfoImport.cronTypeId || cronTypeId,
      interval: activeTaskInfoImport.interval || interval,
      time: activeTaskInfoImport.time || activeTaskTime
          || time || moment(timeStart).format('HH:mm'), // ???
      timeStart: activeTaskInfoImport.timeStart || timeStart,
      id: activeTaskInfoImport.id || id,
      status: activeTaskInfoImport.isActive || status,
      taskType: activeTaskInfoImport.taskType || taskType,
      priorityId: activeTaskInfoImport.priorityId || priorityId,
      endStatus: activeTaskInfoImport.status,
      dateEnd: activeTaskInfoImport.dateEnd,
    };
  }

  handleChange = (name, value) => {
    const {
      popUp,
      path,
      isNetwork,
      createTask,
      link,
      getTaskInfo,
    } = this.props;
    const {
      time,
      interval,
      status,
      timeStart,
      priorityId,
      taskType,
    } = this.state;
    this.setState({
      [name]: value,
    });
    if (!popUp && status && value === 1) {
      createTask({
        time,
        path: isNetwork ? link : path,
        isNetwork,
        action: () => getTaskInfo(taskType),
        cronTypeId: value,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && value === 2) {
      createTask({
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId: value,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && value === 3) {
      createTask({
        interval,
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId: value,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && value === 4) {
      createTask({
        interval,
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId: value,
        priorityId,
        taskType,
      });
    }
  }

  saveChanges = () => {
    const {
      saveButtonPopUp,
      popUp,
      path,
      isNetwork,
      createTask,
      cancelTask,
      link,
      getTaskInfo,
    } = this.props;
    const {
      time,
      interval,
      status,
      timeStart,
      cronTypeId,
      priorityId,
      taskType,
    } = this.state;
    if (popUp) {
      saveButtonPopUp(this.state);
    } else if (!status && cronTypeId === 1) {
      createTask({
        time,
        path: isNetwork ? link : path,
        isNetwork,
        action: () => getTaskInfo(taskType),
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!status && cronTypeId === 2) {
      createTask({
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!status && cronTypeId === 3) {
      createTask({
        interval,
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!status && cronTypeId === 4) {
      createTask({
        interval,
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!status && cronTypeId === 5) {
      createTask({
        interval,
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (status) {
      cancelTask(taskType, () => getTaskInfo(taskType));
    }

    this.setState((state) => ({ status: !state.status }));
  }

  handleChangeOnBlur = () => {
    const {
      popUp,
      path,
      isNetwork,
      createTask,
      link,
      getTaskInfo,
    } = this.props;
    const {
      time,
      interval,
      status,
      timeStart,
      cronTypeId,
      priorityId,
      taskType,
    } = this.state;
    if (!popUp && status && cronTypeId === 1) {
      createTask({
        time,
        path: isNetwork ? link : path,
        isNetwork,
        action: () => getTaskInfo(taskType),
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && cronTypeId === 2) {
      createTask({
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && cronTypeId === 3) {
      createTask({
        interval,
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    } else if (!popUp && status && cronTypeId === 4) {
      createTask({
        interval,
        time: moment(timeStart).toISOString(),
        path: isNetwork ? link : path,
        action: () => getTaskInfo(taskType),
        isNetwork,
        cronTypeId,
        priorityId,
        taskType,
      });
    }
  }

  getFields = (bem) => {
    const {
      interval,
      cronTypeId,
      time,
      timeStart,
    } = this.state;
    switch (cronTypeId) {
      case 1: {
        return (
          <div>
            <InputField
              title={TIME_START_WORD}
              value={time}
              onChange={(e) => this.handleChange('time', e.target.value)}
              mask={timeMask}
            />
          </div>
        );
      }
      case 2: {
        return (
          <div className={bem('date-picker')}>
            <div className={bem('date-picker-title')}>{TIME_START_WORD}</div>
            <DatePicker
              locale="ru"
              selected={new Date(timeStart)}
              showTimeSelect
              timeFormat={timeDatePickerFormat}
              timeCaption={TIME_WORD}
              dateFormat={datePickerSchedulerFormat}
              onChange={(e) => this.handleChange('timeStart', e)}
              onBlur={this.handleChangeOnBlur}
            />
          </div>
        );
      }
      case 3: {
        return (
          <InputField
            value={interval}
            onBlur={this.handleChangeOnBlur}
            title={INTERVAL_WITH_TIME}
            onChange={(e) => this.handleChange('interval', onlyNumbers(e.target.value))}
          />
        );
      }
      case 4: {
        return (
          <div>
            <div className={bem('date-picker')}>
              <div className={bem('date-picker-title')}>{TIME_START_WORD}</div>
              <DatePicker
                locale="ru"
                selected={new Date(timeStart)}
                showTimeSelect
                timeFormat={timeDatePickerFormat}
                timeCaption={TIME_WORD}
                dateFormat={datePickerSchedulerFormat}
                onChange={(e) => this.handleChange('timeStart', e)}
                onBlur={this.handleChangeOnBlur}
              />
            </div>
            <InputField
              value={interval}
              onBlur={this.handleChangeOnBlur}
              title={INTERVAL_WITH_TIME}
              onChange={(e) => this.handleChange('interval', onlyNumbers(e.target.value))}
            />
          </div>
        );
      }
      default: {
        return null;
      }
    }
  }

  render() {
    const {
      cronTypeSelect,
      isOpen,
      popUpControl,
      popUp,
    } = this.props;
    const {
      cronTypeId,
      status,
      endStatus,
      dateEnd,
    } = this.state;

    if (popUp) {
      return (
        <PopupContainer
          isOpen={isOpen}
          close={() => popUpControl(false)}
          isCloseButton
          children={(
            <div className={b()}>
              <div className={b('header')}>{CHOOSE_TIME_START_FOR_JOB}</div>
              <Select
                options={cronTypeSelect}
                value={cronTypeId}
                onChange={(e, { value }) => this.handleChange('cronTypeId', value)}
              />
              {this.getFields(b)}
              <div className={b('footer')}>
                <MainBtn
                  onClick={this.saveChanges}
                  className={b('save-button')}
                  text={SAVE_WORD}
                />
              </div>
            </div>
              )}
        />
      );
    }

    return (
      <div className={bI()}>
        <div className={bI('status-task')}>
          <div className={bI(endStatus ? 'end-status-good' : 'end-status')}>{endStatus ? 'Выполнено успешно' : 'Завершено с ошибкой'}</div>
          <div className={bI('end-date')}>{dateEnd && moment(dateEnd).format('HH:mm:ss, DD.MM.YYYY')}</div>
        </div>
        <div className={bI('import-header')}>
          <div className={bI('header')}>{CHOOSE_TIME_START_FOR_JOB}</div>
          <Select
            options={cronTypeSelect}
            value={cronTypeId}
            onChange={(e, { value }) => this.handleChange('cronTypeId', value)}
          />
        </div>
        {this.getFields(bI)}
        <div className={bI('footer')}>
          <MainBtn
            onClick={this.saveChanges}
            className={bI('save-button')}
            text={status ? (
              <div className={bI('save-button-content')}>
                Остановить
                <Icon name="pause" />
              </div>
            ) : (
              <div className={bI('save-button-content')}>
                Запустить
                <Icon name="play" />
              </div>
            )}
          />
        </div>
      </div>
    );
  }
}

EditJobPopUp.propTypes = propTypes;
EditJobPopUp.defaultProps = defaultProps;
export default EditJobPopUp;
