import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import {
  Dimmer,
  Loader,
  Segment,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { FlexTableMySales } from 'components';
import './styles.scss';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  handleClickIcon: PropTypes.func.isRequired,
  handleClickPrintIcon: PropTypes.func.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  loading: false,
};

const { SEARCH } = LANG_DICTIONARY;

const Products = (props) => {
  const {
    data,
    handleClickIcon,
    handleClickPrintIcon,
    loading,
  } = props;

  return (
    <Dimmer.Dimmable as={Segment} dimmed={loading}>
      <Dimmer active={loading} inverted>
        <Loader>{`${SEARCH}...`}</Loader>
      </Dimmer>
      <FlexTableMySales
        data={data}
        handleClickInfoIcon={handleClickIcon}
        handleClickPrintIcon={handleClickPrintIcon}
      />
    </Dimmer.Dimmable>
  );
};

Products.propTypes = propTypes;
Products.defaultProps = defaultProps;
export default Products;
