import {
  GET_PRODUCT_TYPES_TABLE_CONTROL,
  SET_PRODUCT_TYPE_FORM_CREATING,
  ADD_NEW_STRATEGY,
  DELETE_STRATEGY,
  COPY_STRATEGY,
  CHANGE_CURRENCY,
  CALCULATE_COUPON,
  GET_MIN_INSURANCE_SUM_FORM_CREATING,
  SAVE_PARTNERS_INS_PRODUCT_ID,
  SET_INIT_STATE,
  SET_INIT_STATE_SELECT,
  GET_CURRENCIES,
  SET_GENERAL_OPTION_ID,
  CHANGE_GUARANTEE_LEVEL,
  CHANGE_CURRENCY_TABLE_CONTROL,
  CHANGE_GUARANTEE_LEVEL_ERROR,
  GET_PRODUCT_SETTINGS,
  SET_PRODUCT_TYPE_TABLE_CONTROL,
  SET_PRODUCT_ONLY_FAVORITE,
  GET_OPTION_TYPES,
  GET_OPTION_TYPES_RESTRICTIONS,
  GET_CALCULATED_PERSON_BASKETS,
  TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKET,
  SELECT_CALCULATED_PERSON_BASKET,
  GET_ASSETS_DESCRIPTION,
  SET_FAVORITES_DESCRIPTION,
  DELETE_FAVORITES_DESCRIPTION,
  GET_HINTS_FOR_BASKETS,
  GET_HINTS_FOR_READY_BASKETS,
  GET_HINTS_FOR_REGULAR_BASKETS,
  SET_BASKET_FILTER,
  SET_REGULAR_BASKET_FILTER,
  SET_READY_BASKET_FILTER,
  SET_BASKET_SORT,
  SET_REGULAR_BASKET_SORT,
  SET_READY_BASKET_SORT,
  CLEAR_SORTING_AND_FILTERING,
  SET_MAIN_STRATEGY_DATA,
  SET_CHILDREN_STRATEGY_DATA,
  GET_REQUEST_DATA_FOR_COPY,
  GET_PRODUCT_TYPES_FORM_CREATING,
  SET_INIT_STATE_CREATING_FORM,
  GET_MIN_INSURANCE_SUM_TABLES,
  GET_REQUEST_COUNT,
  GET_READY_BASKETS,
  GET_READY_AND_REGULAR_BASKETS,
  SELECT_READY_BASKET,
  TOGGLE_OPEN_READY_CHILD_BASKET,
  SELECT_REGULAR_BASKET,
  TOGGLE_OPEN_REGULAR_CHILD_BASKET,
  GET_REGULAR_BASKETS,
  REMOVE_LAST_STRATEGIES,
  SELECT_CHILD_CALCULATED_PERSON_BASKET,
  SELECT_CHILD_READY_BASKET,
  SELECT_CHILD_REGULAR_BASKET,
  CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS,
  EXECUTION_CALCULATING_BASKET,
  GET_TERMS,
  GET_PAYOUT_FREQUENCY,
  GET_ACTIVES,
  GENERATE_REPORT,
  SET_WARNING_MESSAGE,
  CHECK_JUST_NOW_EXECUTION_BASKET,
  SET_JUST_NOW_EXECUTION_BASKET,
  INS_LINE_ISZ_CALC,
  ANNUL_OLD_CONSTRUCTOR,
  SET_PRODUCT_ID,
  GET_AMOUNT_FAVORITES_BASKET,
  CLOSE_MIN_INSURANCE_SUM_TABLE,
  CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING,
  CHECK_MULTIPLE_ASSETS,
  GET_GENERAL_ASSET_CLASS,
  GET_MAX_GUARANTEE_LEVEL,
  ADD_NEW_GUARANTEED_PAYOUT,
  CHANGE_FIELDS_FOR_NAME,
  DELETE_GUARANTEED_PAYOUT,
  RECALCULATE_COUPON,
  COPY_GUARANTEED_PAYOUTS,
  EXECUTION_CALCULATING_DP_POLICY_BASKET,
  CLOSE_EXECUTION_DP_POLICY_POPUP,
  PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET,
  RESET_CALCULATED_STATUS_COUPON,
  CLEAR_REGULAR_BASKET,
  CLEAR_READY_BASKET,
  CLEAR_CALCULATED_BASKET,
  CHANGE_LOOKBACK_FLAG,
} from '../types';

export const setProductId = (productId) => ({
  type: SET_PRODUCT_ID,
  payload: { productId },
});

export const getProductTypeTableControl = (productId) => ({
  type: GET_PRODUCT_TYPES_TABLE_CONTROL.request,
  payload: productId,
});

export const getAmountFavoritesBasket = () => ({
  type: GET_AMOUNT_FAVORITES_BASKET.request,
});

export const getProductTypeFormCreating = (productId) => ({
  type: GET_PRODUCT_TYPES_FORM_CREATING.request,
  payload: productId,
});

export const setProductTypeFormCreating = (productId, refProductTypeCode) => ({
  type: SET_PRODUCT_TYPE_FORM_CREATING.start,
  payload: { productId, refProductTypeCode },
});

export const setProductTypeTableControl = (refProductTypeCode) => ({
  type: SET_PRODUCT_TYPE_TABLE_CONTROL,
  payload: refProductTypeCode,
});

export const setProductOnlyFavorite = (isOnlyFavorite) => ({
  type: SET_PRODUCT_ONLY_FAVORITE,
  payload: isOnlyFavorite,
});

export const getOptionType = (productId, refProductTypeCode) => ({
  type: GET_OPTION_TYPES.request,
  payload: { productId, refProductTypeCode },
});

export const getOptionTypesRestrictions =
    (refIszOptionTypeName, term, couponFrequency, strategyUuid) => ({
      type: GET_OPTION_TYPES_RESTRICTIONS.request,
      payload: {
        refIszOptionTypeName, term, couponFrequency, strategyUuid,
      },
    });

export const addNewStrategy = (uuid) => ({
  type: ADD_NEW_STRATEGY,
  payload: uuid,
});

export const addNewGuaranteedPayout = (payout) => ({
  type: ADD_NEW_GUARANTEED_PAYOUT,
  payload: payout,
});

export const copyGuaranteedPayouts = (payouts) => ({
  type: COPY_GUARANTEED_PAYOUTS,
  payload: payouts,
});

export const deleteStrategy = (uuid) => ({
  type: DELETE_STRATEGY,
  payload: uuid,
});

export const copyStrategy = (copyUuid, newUuid) => ({
  type: COPY_STRATEGY,
  payload: { copyUuid, newUuid },
});

export const changeCurrencyFormCreating = ({ currencyCode, productId }) => ({
  type: CHANGE_CURRENCY.start,
  payload: { currencyCode, productId },
});

export const getMaxGuaranteeLevel = ({ currencyCode, productId }) => ({
  type: GET_MAX_GUARANTEE_LEVEL.request,
  payload: { currencyCode, productId },
});

export const calculateCoupon = () => ({
  type: CALCULATE_COUPON.request,
});

export const recalculateCoupon = (payload) => ({
  type: RECALCULATE_COUPON.request,
  payload,
});

export const getMinInsuranceSumFormCreating = ({ productId, qualification }) => ({
  type: GET_MIN_INSURANCE_SUM_FORM_CREATING.request,
  payload: { productId, qualification },
});

export const savePartnersInsProduct = (payload) => ({
  type: SAVE_PARTNERS_INS_PRODUCT_ID,
  payload,
});

export const getCurrencies = (productId, refProductTypeCode) => ({
  type: GET_CURRENCIES.request,
  payload: {
    productId,
    refProductTypeCode,
  },
});

export const setGeneralOptionType = (optionId) => ({
  type: SET_GENERAL_OPTION_ID,
  payload: optionId,
});

export const changeGuaranteeLevel = (value) => ({
  type: CHANGE_GUARANTEE_LEVEL,
  payload: value,
});

export const changeLookbackFlag = ({ uuid, lookbackFlag, lookbackData }) => ({
  type: CHANGE_LOOKBACK_FLAG,
  payload: { uuid, lookbackFlag, lookbackData },
});

export const changeCurrencyTableControl = (value) => ({
  type: CHANGE_CURRENCY_TABLE_CONTROL,
  payload: value,
});

export const changeGuaranteeLevelError = (error) => ({
  type: CHANGE_GUARANTEE_LEVEL_ERROR,
  payload: error,
});

export const getProductSettings = (productId, currencyCode) => ({
  type: GET_PRODUCT_SETTINGS.request,
  payload: { productId, currencyCode },
});

export const getCalculatedPersonBaskets = ({
  partnersInsProductId,
  refCurrencyCode,
  warrantyLevel,
  refIszOptionTypeCode,
  refProductTypeCode,
  offset,
  limit,
  filters,
  sort,
  isScrollUpdate,
  isOnlyFavorite,
  optionsArray,
  abortSignal,
}) => ({
  type: GET_CALCULATED_PERSON_BASKETS.request,
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    offset,
    limit,
    filters,
    isScrollUpdate,
    sort,
    isOnlyFavorite,
    optionsArray,
    abortSignal,
  },
});

export const toggleOpenCalculatedPersonalChildBasket = (mainId, basket) => ({
  type: TOGGLE_OPEN_CALCULATED_PERSONAL_CHILD_BASKET,
  payload: { mainId, basket },
});

export const toggleOpenReadyChildBasket = (mainId, status) => ({
  type: TOGGLE_OPEN_READY_CHILD_BASKET,
  payload: { mainId, status },
});

export const toggleOpenRegularChildBasket = (mainId, status) => ({
  type: TOGGLE_OPEN_REGULAR_CHILD_BASKET,
  payload: { mainId, status },
});

export const setInitState = () => ({
  type: SET_INIT_STATE,
});

export const setInitStateSelect = () => ({
  type: SET_INIT_STATE_SELECT,
});

export const selectCalculatedPersonBasket = (basket) => ({
  type: SELECT_CALCULATED_PERSON_BASKET,
  payload: basket,
});

export const getAssetsDescription = (insLineIszCalcRequestId) => ({
  type: GET_ASSETS_DESCRIPTION.request,
  payload: insLineIszCalcRequestId,
});

export const setFavorites = ({ insLineIszCalcRequestId, partnersInsProductId, currencyCode }) => ({
  type: SET_FAVORITES_DESCRIPTION.request,
  payload: { id: insLineIszCalcRequestId, partnersInsProductId, currencyCode },
});

export const deleteFavorites = (
  { insLineIszCalcRequestId, partnersInsProductId, currencyCode },
) => ({
  type: DELETE_FAVORITES_DESCRIPTION.request,
  payload: { id: insLineIszCalcRequestId, partnersInsProductId, currencyCode },
});

export const clearAssetsDescription = () => ({
  type: GET_ASSETS_DESCRIPTION.success,
  payload: [],
});

export const setAssetsDescription = (description) => ({
  type: GET_ASSETS_DESCRIPTION.success,
  payload: description,
});

export const getHintsForBaskets = (name, value) => ({
  type: GET_HINTS_FOR_BASKETS.request,
  payload: { name, value },
});

export const getHintsForReadyBaskets = (name, value) => ({
  type: GET_HINTS_FOR_READY_BASKETS.request,
  payload: { name, value },
});

export const getHintsForRegularBaskets = (name, value) => ({
  type: GET_HINTS_FOR_REGULAR_BASKETS.request,
  payload: { name, value },
});

export const setFilterForBaskets = (name, value) => ({
  type: SET_BASKET_FILTER,
  payload: { name, value },
});

export const setFilterForRegularBaskets = (name, value) => ({
  type: SET_REGULAR_BASKET_FILTER,
  payload: { name, value },
});

export const setFilterForReadyBaskets = (name, value) => ({
  type: SET_READY_BASKET_FILTER,
  payload: { name, value },
});

export const setBasketSort = ({ name, type, timestamp }) => ({
  type: SET_BASKET_SORT,
  payload: { name, type, timestamp },
});

export const setRegularBasketSort = ({ name, type, timestamp }) => ({
  type: SET_REGULAR_BASKET_SORT,
  payload: { name, type, timestamp },
});

export const setReadyBasketSort = ({ name, type, timestamp }) => ({
  type: SET_READY_BASKET_SORT,
  payload: { name, type, timestamp },
});

export const clearSortAndFiltering = () => ({
  type: CLEAR_SORTING_AND_FILTERING,
});

export const setMainStrategyData = (data) => ({
  type: SET_MAIN_STRATEGY_DATA,
  payload: data,
});

export const setChildrenStrategyData = (data) => ({
  type: SET_CHILDREN_STRATEGY_DATA,
  payload: data,
});

export const setInitCreatingForm = () => ({
  type: SET_INIT_STATE_CREATING_FORM,
});

export const resetCalculatedStatusCoupon = () => ({
  type: RESET_CALCULATED_STATUS_COUPON,
});

export const getMinInsuranceSumTables = (insLineIszCalcRequestId) => ({
  type: GET_MIN_INSURANCE_SUM_TABLES.request,
  payload: insLineIszCalcRequestId,
});

export const setMinInsuranceSumTables = (minInsuranceSum) => ({
  type: GET_MIN_INSURANCE_SUM_TABLES.success,
  payload: {
    data: minInsuranceSum,
    isMinInsuranceSumClicked: false,
  },
});

export const copyRequest = ({
  insLineIszCalcRequestId,
  childInsLineIszCalcRequestIds,
  partnersInsProductId,
  refProductTypeCode,
  refCurrencyCode,
  garantSchedule,
}) => ({
  type: GET_REQUEST_DATA_FOR_COPY.request,
  payload: {
    insLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    garantSchedule,
  },
});

export const getReadyBaskets = ({
  partnersInsProductId,
  refCurrencyCode,
  warrantyLevel,
  refIszOptionTypeCode,
  refProductTypeCode,
  offset,
  limit,
  readySort,
  readyFilters,
  abortSignal,
}) => ({
  type: GET_READY_BASKETS.request,
  payload: {
    isScrollUpdate: true,
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    offset,
    limit,
    readySort,
    readyFilters,
    abortSignal,
  },
});

export const getReadyAndRegularBaskets = ({
  partnersInsProductId,
  refCurrencyCode,
  warrantyLevel,
  refIszOptionTypeCode,
  refProductTypeCode,
  readyFilters,
  regularFilters,
  readyAbortSignal,
  regularAbortSignal,
}) => ({
  type: GET_READY_AND_REGULAR_BASKETS,
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    readyFilters,
    regularFilters,
    readyAbortSignal,
    regularAbortSignal,
  },
});

export const getRequestCount = () => ({
  type: GET_REQUEST_COUNT.request,
});

export const selectReadyBasket = (payload) => ({
  type: SELECT_READY_BASKET,
  payload,
});

export const selectRegularBasket = (payload) => ({
  type: SELECT_REGULAR_BASKET,
  payload,
});

export const getRegularBaskets = ({
  partnersInsProductId,
  refCurrencyCode,
  warrantyLevel,
  refIszOptionTypeCode,
  refProductTypeCode,
  offset,
  limit,
  regularSort,
  regularFilters,
  abortSignal,
}) => ({
  type: GET_REGULAR_BASKETS.request,
  payload: {
    isScrollUpdate: true,
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    offset,
    limit,
    regularSort,
    regularFilters,
    abortSignal,
  },
});

export const removeLastStrategies = (count) => ({
  type: REMOVE_LAST_STRATEGIES,
  payload: count,
});

export const selectChildPersonCalculatedBasket = (id, basket) => ({
  type: SELECT_CHILD_CALCULATED_PERSON_BASKET,
  payload: { id, basket },
});

export const selectChildReadyBasket = (id, basket) => ({
  type: SELECT_CHILD_READY_BASKET,
  payload: { id, basket },
});

export const selectChildRegularBasket = (id, basket) => ({
  type: SELECT_CHILD_REGULAR_BASKET,
  payload: { id, basket },
});

export const clearSelectedItemsCalculatedPersonBasket = () => ({
  type: CLEAR_SELECTED_ITEMS_CALCULATED_PERSON_BASKETS,
});

export const executionCalculatingBasket = ({
  mainInsLineIszCalcRequestId,
  childInsLineIszCalcRequestIds,
  guaranteeLevel,
  refIszOptionTypeCode,
  refCurrencyCode,
  partnersInsProductId,
  refProductTypeCode,
  qualification,
}) => ({
  type: EXECUTION_CALCULATING_BASKET.request,
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    qualification,
  },
});

export const annulOldConstructor = (ctsIds) => ({
  type: ANNUL_OLD_CONSTRUCTOR.request,
  payload: {
    ctsIds,
  },
});

export const insLineIszCalc = ({
  mainInsLineIszCalcRequestId,
  childInsLineIszCalcRequestIds,
  guaranteeLevel,
  refIszOptionTypeCode,
  refCurrencyCode,
  partnersInsProductId,
  refProductTypeCode,
  insuranceAmount,
  mainRequestId,
  childRequestIds,
}) => ({
  type: INS_LINE_ISZ_CALC.request,
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    insuranceAmount,
    mainRequestId,
    childRequestIds,
  },
});

export const getTerms = ({ productId, currencyCode }) => ({
  type: GET_TERMS.request,
  payload: {
    productId,
    currencyCode,
  },
});

export const getPayoutFrequency = (productId, termId) => ({
  type: GET_PAYOUT_FREQUENCY.request,
  payload: { productId, termId },
});

export const getAssetOptions = (term, refCurrencyCode, strategyUuid) => ({
  type: GET_ACTIVES.request,
  payload: { term, refCurrencyCode, strategyUuid },
});

export const generateReport = ({
  partnersInsProductId,
  guaranteeLevel,
  refCurrencyCode,
  refIszOptionTypeCode,
  refProductTypeCode,
  sort,
  filters,
  abortSignal,
}) => ({
  type: GENERATE_REPORT.request,
  payload: {
    partnersInsProductId,
    guaranteeLevel,
    refCurrencyCode,
    refIszOptionTypeCode,
    refProductTypeCode,
    sort,
    filters,
    abortSignal,
  },
});

export const checkJustNowExecutionBasket = ({
  partnersInsProductId,
  refCurrencyCode,
  warrantyLevel,
  refIszOptionTypeCode,
  refProductTypeCode,
  justNowExecutionBasketId,
}) => ({
  type: CHECK_JUST_NOW_EXECUTION_BASKET.request,
  payload: {
    partnersInsProductId,
    refCurrencyCode,
    warrantyLevel,
    refIszOptionTypeCode,
    refProductTypeCode,
    justNowExecutionBasketId,
  },
});

export const setWarningMessage = (message) => ({
  type: SET_WARNING_MESSAGE,
  payload: message,
});

export const setJustNowExecutionBasketPayload = ({
  id,
  refIszOptionTypeCode,
  refCurrencyCode,
}) => ({
  type: SET_JUST_NOW_EXECUTION_BASKET,
  payload: {
    id,
    refIszOptionTypeCode,
    refCurrencyCode,
  },
});

export const closeMinInsuranceSumTableFormCreating = () => ({
  type: CLOSE_MIN_INSURANCE_SUM_TABLE_FORM_CREATING,
});

export const closeMinInsuranceSumTable = () => ({
  type: CLOSE_MIN_INSURANCE_SUM_TABLE,
});

export const checkMultipleAssets = (assets, uuid) => ({
  type: CHECK_MULTIPLE_ASSETS.request,
  payload: { assets, uuid },
});

export const getGeneralAssetClass = (assets, uuid) => ({
  type: GET_GENERAL_ASSET_CLASS.request,
  payload: { assets, uuid },
});

export const changeFieldsForName = (name, value, index) => ({
  type: CHANGE_FIELDS_FOR_NAME,
  payload: { name, value, index },
});

export const deleteGuaranteedPayout = (index) => ({
  type: DELETE_GUARANTEED_PAYOUT,
  payload: { index },
});

export const cancelBasketsRequest = () => ({
  type: GET_CALCULATED_PERSON_BASKETS.cancel,
});

export const executionCalculatingDpPolicyBasket = ({
  mainInsLineIszCalcRequestId,
  childInsLineIszCalcRequestIds,
  guaranteeLevel,
  refIszOptionTypeCode,
  refCurrencyCode,
  partnersInsProductId,
  refProductTypeCode,
  qualification,
  insLineIszObjectId,
  action,
}) => ({
  type: EXECUTION_CALCULATING_DP_POLICY_BASKET.request,
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    qualification,
    insLineIszObjectId,
    action,
  },
});

export const preExecutionCalculatingDpPolicyBasket = ({
  mainInsLineIszCalcRequestId,
  childInsLineIszCalcRequestIds,
  guaranteeLevel,
  refIszOptionTypeCode,
  refCurrencyCode,
  partnersInsProductId,
  refProductTypeCode,
  qualification,
  productId,
  action,
}) => ({
  type: PRE_EXECUTION_CALCULATING_DP_POLICY_BASKET.request,
  payload: {
    mainInsLineIszCalcRequestId,
    childInsLineIszCalcRequestIds,
    guaranteeLevel,
    refIszOptionTypeCode,
    refCurrencyCode,
    partnersInsProductId,
    refProductTypeCode,
    qualification,
    productId,
    action,
  },
});

export const closeExecutionDpPolicyPopup = () => ({
  type: CLOSE_EXECUTION_DP_POLICY_POPUP,
});

export const clearRegularBasket = () => ({
  type: CLEAR_REGULAR_BASKET,
});

export const clearReadyBasket = () => ({
  type: CLEAR_READY_BASKET,
});

export const clearCalculatedBasket = () => ({
  type: CLEAR_CALCULATED_BASKET,
});
