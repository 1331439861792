import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { block } from 'bem-cn';
import {
  LANG_DICTIONARY, ROUTES,
} from 'consts';
import {
  ConstructorTableControl,
  DescriptionAssetsPopup,
  InfoTableConstructorIsz,
  MainBtn,
  WarningGuaranteeLevel,
  WarningMessagePopup,
} from 'components';

import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import './styles.scss';
import { useActions } from 'hooks/useActions';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import {
  selectRegularBasketSorting,
  selectReadyBasketSorting,
  selectReadyBasketFiltering,
  selectRegularBasketFiltering,
  selectIsMinInsuranceSumClickedTables,
} from 'redux/basketConstructor/selectors';
import { useEffectRedirect } from 'hooks/useEffectRedirect';
import PropTypes from 'prop-types';
import { CONSTRUCTOR_URLS } from '../_constants';
import { KnowMinInsuranceAmountButton } from '../_components/KnowMinInsuranceAmountButton';
import { ConstructorCreateButtonText } from '../_components/ConstructorCreateButtonText';

const propTypes = {
  availableQuantityRequests: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.instanceOf(null),
  ]),
  minInsuranceSum: PropTypes.number,
  isLoadingReadyAndRegularBasket: PropTypes.bool.isRequired,
  productId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  onUpdateTables: PropTypes.func.isRequired,
  readyBaskets: PropTypes.arrayOf(PropTypes.object).isRequired,
  readyOpenChildIds: PropTypes.object.isRequired,
  readyScrollLoading: PropTypes.bool.isRequired,
  readySelected: PropTypes.object,
  regularBaskets: PropTypes.arrayOf(PropTypes.object).isRequired,
  regularOpenChildIds: PropTypes.object.isRequired,
  regularScrollLoading: PropTypes.bool.isRequired,
  regularSelected: PropTypes.object,
  tableControl: PropTypes.object.isRequired,
  onSelectReadyBasket: PropTypes.func.isRequired,
  onToggleOpenReadyChildBasket: PropTypes.func.isRequired,
  onScrollEndReadyBaskets: PropTypes.func.isRequired,
  onSelectRegularBasket: PropTypes.func.isRequired,
  onToggleOpenRegularChildBasket: PropTypes.func.isRequired,
  onScrollEndRegularBaskets: PropTypes.func.isRequired,
  onClickGetMinInsuranceSum: PropTypes.func.isRequired,
  readyChildSelectedIds: PropTypes.object.isRequired,
  regularChildSelectedIds: PropTypes.object.isRequired,
  onSelectChildRegularBasket: PropTypes.func.isRequired,
  onSelectChildReadyBasket: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  onClickExecute: PropTypes.func.isRequired,
  isGuaranteeLevelWarning: PropTypes.bool.isRequired,
  closeGuaranteeLevelWarningPopup: PropTypes.func.isRequired,
  warningPopupMessage: PropTypes.string.isRequired,
  clearWarningPopupMessage: PropTypes.func.isRequired,
  isExecutionLoading: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.object),
  isEditBasket: PropTypes.bool,
  onUpdateRegularTable: PropTypes.func.isRequired,
  onUpdateReadyTable: PropTypes.func.isRequired,
};

const defaultProps = {
  availableQuantityRequests: null,
  regularSelected: null,
  readySelected: null,
  minInsuranceSum: null,
  productOptions: [],
  isEditBasket: false,
};

const {
  READY_TO_ORDER_CONSTRUCTOR_BASKETS,
  POPULAR_CONSTRUCTOR_BASKET,
  GET_ANOTHER_CONSTRUCTOR_BASKET,
  DOWNLOADING,
  DATA_GETTING,
  POLICY_EXECUTION,
  ALL_INVESTMENT_PRODUCTS,
  CONTINUE_REGISTRATION,
} = LANG_DICTIONARY;

const b = block('constructor-isz-form');

const RegularAndReadyBasketsForm = (props) => {
  const {
    availableQuantityRequests,
    minInsuranceSum,
    settings,
    isLoadingReadyAndRegularBasket,
    productId,
    onUpdateTables,
    readyBaskets,
    readyOpenChildIds,
    readyScrollLoading,
    readySelected,
    regularBaskets,
    regularOpenChildIds,
    regularScrollLoading,
    regularSelected,
    tableControl,
    onSelectReadyBasket,
    onToggleOpenReadyChildBasket,
    onScrollEndReadyBaskets,
    onSelectRegularBasket,
    onToggleOpenRegularChildBasket,
    onScrollEndRegularBaskets,
    onClickGetMinInsuranceSum,
    readyChildSelectedIds,
    regularChildSelectedIds,
    onSelectChildRegularBasket,
    onSelectChildReadyBasket,
    history,
    onClickExecute,
    isGuaranteeLevelWarning,
    closeGuaranteeLevelWarningPopup,
    warningPopupMessage,
    clearWarningPopupMessage,
    isExecutionLoading,
    productOptions,
    isEditBasket,
    onUpdateRegularTable,
    onUpdateReadyTable,
  } = props;

  const isMinInsuranceSumClicked = useSelector(selectIsMinInsuranceSumClickedTables);
  const { regularBasketsFilter, regularBasketsHints } = useSelector(selectRegularBasketFiltering);
  const regularBasketsSorting = useSelector(selectRegularBasketSorting);
  const { readyBasketsFilter, readyBasketsHints } = useSelector(selectReadyBasketFiltering);
  const readyBasketsSorting = useSelector(selectReadyBasketSorting);

  const {
    setInitStateSelect,
    setProductId,
    closeMinInsuranceSumTable,
    getHintsForReadyBaskets,
    getHintsForRegularBaskets,
    setRegularBasketSort,
    setReadyBasketSort,
    setFilterForRegularBaskets,
    setFilterForReadyBaskets,
    recalculateCoupon,
  } = useActions(basketConstructorActions);

  useEffect(() => {
    setProductId(productId);
  }, []);

  const handleChangeFilterReady = ({ target: { name, value } }, { isDisableHints }) => {
    if (!isDisableHints) {
      getHintsForReadyBaskets(name, value);
    }
  };

  const handleChangeFilterRegular = ({ target: { name, value } }, { isDisableHints }) => {
    if (!isDisableHints) {
      getHintsForRegularBaskets(name, value);
    }
  };

  const getRegularBasketPropsSortAndFilteringInterface = (name, label, postfix = '') => ({
    name,
    label,
    hints: regularBasketsHints[name],
    filterValue: regularBasketsFilter[name],
    sortValue: regularBasketsSorting[`${name}${postfix}`] && regularBasketsSorting[`${name}${postfix}`].type,
    onChangeFilter: handleChangeFilterRegular,
    onConfirmFilter: setFilterForRegularBaskets,
    onFocusFilter: handleChangeFilterRegular,
    isResultSearchDefaultOpen: true,
    onChangeSort: setRegularBasketSort,
    postfixSortFieldName: postfix,
  });

  const getReadyBasketPropsSortAndFilteringInterface = (name, label, postfix = '') => ({
    name,
    label,
    hints: readyBasketsHints[name],
    filterValue: readyBasketsFilter[name],
    sortValue: readyBasketsSorting[`${name}${postfix}`] && readyBasketsSorting[`${name}${postfix}`].type,
    onChangeFilter: handleChangeFilterReady,
    onConfirmFilter: setFilterForReadyBaskets,
    onFocusFilter: handleChangeFilterReady,
    isResultSearchDefaultOpen: true,
    onChangeSort: setReadyBasketSort,
    postfixSortFieldName: postfix,
  });

  useEffectRedirect(() => {
    setInitStateSelect();
  }, CONSTRUCTOR_URLS);

  const updateReadyTable = () => {
    onUpdateReadyTable();
  };

  const updateRegularTable = () => {
    onUpdateRegularTable();
  };

  // eslint-disable-next-line arrow-body-style
  const checkIsGuaranteeLevelsDontSatisfyRestrictions = (guaranteeLevel) => {
    return !(settings.guaranteeLevelRestrictions[0] <= guaranteeLevel &&
      guaranteeLevel <= settings.guaranteeLevelRestrictions[1]);
  };

  const isDisabledButtons = (!regularSelected && !readySelected) ||
    checkIsGuaranteeLevelsDontSatisfyRestrictions(tableControl.guaranteeLevel) ||
      checkIsGuaranteeLevelsDontSatisfyRestrictions(tableControl.guaranteeLevel) ||
      isLoadingReadyAndRegularBasket || (!regularSelected?.isActive && !readySelected?.isActive);

  return (
    <div className={b()}>
      <DescriptionAssetsPopup chartOnly />
      <WarningGuaranteeLevel
        isOpen={isGuaranteeLevelWarning}
        handleClose={closeGuaranteeLevelWarningPopup}
        handleContinue={() => onClickExecute(true)}
        isExecutionLoading={isExecutionLoading}
      />
      <WarningMessagePopup
        message={warningPopupMessage}
        onClose={clearWarningPopupMessage}
      />
      <div className={b('content')}>
        <ConstructorTableControl
          productId={productId}
          onUpdate={onUpdateTables}
          guaranteeLevelRestrictions={settings.guaranteeLevelRestrictions}
        />
        <div className={b('tables')}>
          <Segment>
            <Dimmer
              active={isLoadingReadyAndRegularBasket}
              inverted
              className={b('dimmer').toString()}
            >
              <Loader inverted content={DOWNLOADING} />
            </Dimmer>
            <div className={b('table')}>
              <div className={b('table-title')}>
                {READY_TO_ORDER_CONSTRUCTOR_BASKETS}
                {readyScrollLoading && (
                  <div className={b('scroll-loading')}>
                    <Loader inline active size="tiny" />
                    <p>{`${DATA_GETTING}...`}</p>
                  </div>
                )}
              </div>
              <div className={b('table-wrapper')}>
                <InfoTableConstructorIsz
                  data={readyBaskets}
                  childSelectedIds={readyChildSelectedIds}
                  openChildIds={readyOpenChildIds}
                  optionCode={tableControl.generalOptionCode}
                  selected={readySelected}
                  onToggleOpenChildBasket={onToggleOpenReadyChildBasket}
                  onSelectItem={onSelectReadyBasket}
                  onSelectChildItem={onSelectChildReadyBasket}
                  onEndScrollTable={onScrollEndReadyBaskets}
                  onUpdateTable={updateReadyTable}
                  productOptions={productOptions}
                  getPropsSortAndFilteringInterface={
                    getReadyBasketPropsSortAndFilteringInterface
                  }
                  currencyCode={tableControl.currencyCode}
                  productId={productId}
                  recalculateCoupon={recalculateCoupon}
                  filters={readyBasketsFilter}
                  sorts={readyBasketsSorting}
                />
              </div>
            </div>
            <div className={b('table')}>
              <div className={b('table-title')}>
                {POPULAR_CONSTRUCTOR_BASKET}
                {regularScrollLoading && (
                  <div className={b('scroll-loading')}>
                    <Loader inline active size="tiny" />
                    <p>{`${DATA_GETTING}...`}</p>
                  </div>
                )}
              </div>
              <div className={b('table-wrapper')}>
                <InfoTableConstructorIsz
                  data={regularBaskets}
                  openChildIds={regularOpenChildIds}
                  optionCode={tableControl.generalOptionCode}
                  selected={regularSelected}
                  onToggleOpenChildBasket={onToggleOpenRegularChildBasket}
                  onSelectItem={onSelectRegularBasket}
                  onEndScrollTable={onScrollEndRegularBaskets}
                  onUpdateTable={updateRegularTable}
                  childSelectedIds={regularChildSelectedIds}
                  onSelectChildItem={onSelectChildRegularBasket}
                  productOptions={productOptions}
                  getPropsSortAndFilteringInterface={
                    getRegularBasketPropsSortAndFilteringInterface
                  }
                  currencyCode={tableControl.currencyCode}
                  recalculateCoupon={recalculateCoupon}
                  productId={productId}
                  filters={regularBasketsFilter}
                  sorts={regularBasketsSorting}
                />
              </div>
            </div>
          </Segment>
        </div>
        <div className={b('footer')}>
          {!settings.hideConstrFull && (
          <MainBtn
            onClick={() => history.push(ROUTES.constructorIszPreparedBaskets({ id: productId }))}
            text={(
              <div className={b('get-basket-button')}>
                <p>{ALL_INVESTMENT_PRODUCTS}</p>
              </div>
            )}
          />
          )}
          {!settings.hideConstrFull && (
            <MainBtn
              text={(
                <ConstructorCreateButtonText
                  availableQuantityRequests={availableQuantityRequests}
                  text={GET_ANOTHER_CONSTRUCTOR_BASKET}
                />
              )}
              onClick={() => history.push(ROUTES.constructorIszCreating({ id: productId }))}
              disabled={availableQuantityRequests === 0}
            />
          )}
          <KnowMinInsuranceAmountButton
            isMinInsuranceSumClicked={isMinInsuranceSumClicked}
            minInsuranceAmountData={minInsuranceSum}
            onClick={onClickGetMinInsuranceSum}
            currencyCode={tableControl.currencyCode}
            disabled={isDisabledButtons}
            handleCloseMinInsuranceTable={closeMinInsuranceSumTable}
            className={b('min-pay-block')}
          />
          {settings.isIssueBasket && (
            <MainBtn
              text={isEditBasket ? CONTINUE_REGISTRATION : POLICY_EXECUTION}
              onClick={() => onClickExecute()}
              disabled={isDisabledButtons}
              isLoading={isExecutionLoading}
            />
          )}
        </div>
      </div>
    </div>
  );
};

RegularAndReadyBasketsForm.propTypes = propTypes;
RegularAndReadyBasketsForm.defaultProps = defaultProps;
export default RegularAndReadyBasketsForm;
