const API = {
  SIGN_IN: '/auth/signin',
  GET_ACCESS_BRANCHES_FOR_AUTH: '/auth/branches',
  GET_ACCESS_PARTNERS_FOR_AUTH: '/auth/partners',
  SET_AFFILIATION: '/auth/affiliation',
  RESET_REQUEST_API: '/reset/send-message',
  CHECK_PASS_ACCESS_API: '/reset/check-token',
  RESET_PASSWORD: '/reset/reset-password',
  GET_USER: '/auth/check-user',
  SEARCH_ADDRESS: '/fias/address',
  SEARCH_BANK: '/fias/bank',
  SEARCH_FMS_UNIT: '/fias/fms-unit',
  SEARCH_REGION: '/fias/region',
  GET_PRODUCTS: '/form/product',
  CHECK_PRODUCT: '/form/product-select',
  GET_PRODUCT_FORM: '/form/product-form',
  CHECK_SUM: '/form/check-sum-insured',
  GET_GENDERS: '/form/gender-list',
  GET_BENEFICIARIES: '/form/beneficiary-list',
  GET_RELATIONSHIPS: '/form/relationship-list',
  SAVE_CONTRACT: '/form/save-data-contract',
  GET_CONTRACT: '/form/get-data-contract',
  SCANS: '/scans',
  SAVE_SCAN: '/form/save-document-scan',
  GET_CONTRACT_FILE: '/form/download-contract',
  GET_PRODUCTS_LIST: '/search/product',
  CHECK_PODFT_DATA_API: '/checks-orchestration/check',
  GET_STATUS_LIST: '/search/status',
  GET_DOC_CHECKING_STATUS_LIST: '/search/doc-checking-status',
  GET_PARTNER_LIST: '/search/partner',
  GET_BRANCH_LIST: '/search/branch',
  SEARCH_PRODUCTS_LIST: '/search/list',
  SEARCH_STATUS_PRODUCTS_LIST: '/search/documents-check-status-list',
  GET_CONTRACT_INFO: '/search/extended',
  GET_SCAN: '/search/download',
  SEARCH_PEOPLE_BY_NAME: '/search/insurer',
  GET_IP: '/auth/ip',
  GET_FILE_TEMPLATE: '/import/download',
  IMPORT_FILE_ADMIN: '/import/upload',
  IMPORT_PODFT_FILE: '/checks-orchestration/import',
  GET_VERSION_DATE: '/checks-orchestration/version',
  EXPORT_1C: '/1c/export/manual',
  IMPORT_1C: '/1c/import/manual',
  GET_IMPORT_JOBS: '/import/jobs',
  CANCEL_IMPORT_JOBS: '/import/canceled',
  CREATE_IMPORT_JOBS: '/import/schedule',
  DOWNLOAD_GENERATE_FILE: '/import/download-user',
  CAPTCHA: '/auth/captcha',
  RECAPTCHA: '/auth/recaptcha',
  LOG_OUT: '/auth/signout',
  REPASSWORD: '/auth/repassword',
  FORGOT_PASSWORD: '/auth/forgot-password',
  LOGIN_RECOVERY_REQUEST: '/auth/access-recovery-request',
  LOG_PAGE: '/log/page',
  GET_USERS_DEPENDING_BRANCH_AND_PARTNER: '/document-checking/get-user-list',
  GET_BRANCHES_DEPENDING_PARTNER: '/document-checking/get-branch-list',
  GET_COUNT_CONTRACT_BY_FILTER: '/document-checking/get-document-count',
  GET_CHECKER_LIST: '/document-checking/get-checking-user-list',
  GET_CONTRACT_LIST: '/document-checking/search-document-list-for-checking',
  GET_CONTRACT_FOR_UNDERWRITING: '/document-checking/get-document',
  GET_STATUS_UNDERWRITING: '/document-checking/get-error-list',
  SAVE_CHECKED_DOCUMENT: (ctsId) => `/document-checking/cts/${ctsId}/save-document`,
  UNLOCK_DOCUMENT_UNDERWRITING: '/document-checking/unlock-document',
  GET_ALL_BRANCHES_UNDERWRITING: '/document-checking/get-all-branch-list',
  GET_STATUS_LIST_CHECKING_UNDERWRITING: '/document-checking/get-check-status-list',
  SEARCH_CONTRACTS_FOR_UNDERWRITING: '/document-checking/search-document-list',
  GET_VERSION_DP: '/common/version',
  FAKE_CONST: 'FAKE',
  UPLOAD_NEW_SCAN: '/scans/update',
  GET_EVENTS_MESSAGE_COUNT: '/events/count',
  GET_EVENTS_SCAN_ERROS: '/events/scans-errors',
  UNLOCK_ALL_CHECKING_DOCUMENT: '/document-checking/unlock-all-document',
  UPDATE_SCAN_TYPE: (scanId) => `/scans/id${scanId}/type`,
  DELETE_SCAN: (scanId) => `/scans/id${scanId}`,
  PARTNERS: '/partners',
  BRANCHES: '/branches',
  USERS: '/users',
  REPORTS: '/reports',
  GET_PARTNERS_FOR_ADMIN_TABLE: '/partners/admin-table',
  GET_PARTNERS_FOR_SELECT: '/partners/select',
  GET_BRANCHES_FOR_ADMIN_TABLE: '/branches/admin-table',
  GET_BRANCHES_FOR_SELECT: '/branches/select',
  CHECK_EXIST_MANUAL: '/common/check-manual',
  DOWNLOAD_MANUAL: '/common/manual',
  GET_USERS_FOR_ADMIN_TABLE: '/users/admin-table',
  GET_USERS_ROLES_FOR_SELECT: '/users/roles/select',
  GET_REPORTS_FOR_ADMIN_TABLE: '/reports/admin-table',
  LOCK_USER: '/users/lock',
  UNLOCK_USER: '/users/unlock',
  GET_LOGIN_FOR_USER: '/users/login/generate',
  GET_PASSWORD_FOR_USER: '/users/password/generate',
  GET_REPORT_TYPES_FOR_SELECT: '/reports/types',
  GET_REPORT_TAGS: '/reports/tags',
  GET_CHECK_FOR_REPORT: '/reports/check',
  GET_DOWNLOAD_LIST: '/reports/download/list',
  GET_DOWNLOAD_REPORT: '/reports/download/id',
  GET_REGENERATE_FILE: '/form/regenerate-contract/',
  GET_CONTRACT_COPY: '/form/copy-contract-data/',
  SEARCH_CONTRACTS: '/client-reports/search',
  GET_REPORT: '/client-reports/report',
  GET_SEARCH_REPORTS_TYPES: '/client-reports/search-reports-types',
  SEND_USER_CREDENTIAL: '/users/credentials',
  GET_INBOX_UNREAD_MESSAGES_COUNT: '/docs-transfer/inbox-unread-messages',
  GET_INBOX_MESSAGES: '/docs-transfer/inbox-messages',
  GET_SENT_MESSAGES: '/docs-transfer/sent-messages',
  GET_RECIPIENT_PARTNERS: '/docs-transfer/partners',
  GET_RECIPIENTS: '/docs-transfer/recipients',
  DOWNLOAD_INBOX_MESSAGE_FILE: (id) => `/docs-transfer/download-files/${id}`,
  DOCS_TRANSFER_MESSAGES: (id) => `/docs-transfer/messages/${id}`,

  GET_CALC_REQUEST_EVENTS: '/events/calc-request-notifications',
  SET_READED_CALCT_REQUEST_EVENTS: '/events/calc-request-notifications',
  GET_ISZ_CONSTRUCTOR_PRODUCTS_GROUP: '/form/product-group/',
  IMPORT_FILE_FROM_1C_TO_PARSE: '/1c/import/parse-xml',
  IMPORT_FILE_FROM_1C_MANUAL: '/1c/import/manual',
  IMPORT_FILE_FROM_1C_AUTO: '/1c/import/auto',
  IMPORT_FILE_FROM_1C_JOBS: '/1c/import/jobs',
  IMPORT_FILE_FROM_1C_DELETE_JOB: '/1c/import/cancel',
  GET_CONTRACT_PAYOUTS_BY_NUMBER: '/contracts/statuses-data',
  SAVE_CONTRACT_STATUS: '/contracts/save-status-data',
  GET_CONTRACT_STATES: '/contracts/conditions',
  DOWNLOAD_ADDITIONAL_DOC: (id) => `/form/download/additional-document/${id}`,
  GET_ADDITIONAL_DOCS_LIST: '/form/additional-documents-list',
  GET_CATALOG_SELECTOR: '/references/list',
  GET_CATALOG_INFO: '/references/id',
  SAVE_CHANGES_CATALOGS: '/references/save',
  GET_PRODUCTS_FOR_ADMIN_TABLE: '/distribution/distribution-data',
  SAVE_CHANGES_FOR_PRODUCTS: '/distribution/distribution-data',
  GET_SELECTS_PRODUCTS_LIST: '/form/product-list/',
  START_SCHEDULER_JOB: '/jobs/create',
  STOP_SCHEDULER_JOB: '/jobs/stop',
  GET_ACTIVE_JOBS: '/jobs/active',
  GET_PROCESS_JOBS: '/jobs/process',
  GET_FAILS_JOS: '/jobs/fail',
  GET_COMPLETED_JOBS: '/jobs/complete',
  GET_CRON_TYPES_SELECT: '/jobs/cron-types',
  GET_JOBS_PRIORITIES: '/jobs/priorities',
  GET_JOBS_COUNT: '/jobs/stats',
  GET_ACTIVE_TASK_INFO_IMPORT_SCHEDULER: '/jobs/active',
  CHECK_ACCOUNT_NUMBERS: '/form/check-account-numbers',
  GET_NSZ_PRODUCT: '/nsz/calculator/form-data',
  GET_ADDITIONAL_RISKS: (id) => `/form/nsz/id${id}/additional-risks`,
  GET_PACKAGE_RISKS: (id) => `/form/nsz/id${id}/package-additional-risks`,
  COUNT_SUM_NSZ_CALCULATOR: (id) => `/form/nsz/id${id}/calculate-insurance-amounts`,
  SAVE_CONTRACT_NSZ: (id) => `/form/nsz/id${id}/save-data-contract`,
  SAVE_CONTRACT_KSZ: '/form/ksz/save-data-contract',
  DOWNLOAD_PRINT_PAYMENT: (insProductId, insLineNszCalcId) => `/form/nsz/id${insProductId}/calculate-insurance-amounts/id${insLineNszCalcId}/download`,
  PUBLIC_API_LIST: '/public-api',
  GET_PUBLIC_API_REFERENCE: '/public-api/references',
  GET_PUBLIC_API_BY_ID: (id) => `/public-api/id${id}`,
  REGENERATE_SECRET_KEY: (id) => `/public-api/id${id}/secret/regenerate`,
  CHECK_CODE_KLADR: '/check/code-kladr',
  CHECK_ACTIVE_CONTEST: '/contests/is-active',
  GET_INVITE_BANNER: '/contests/banners/invite',
  GET_CONTESTS_DATA: '/contests/main',
  GET_CONTEST_CLUSTER: (compId, clusterId) => `/contests/${compId}/clasters/${clusterId}`,
  GET_CONTEST_IMAGE: (compId) => `/contests/${compId}/banners/description`,
  DOWNLOAD_CONTEST: (compId) => `/contests/${compId}/download`,
  UPDATE_CONTRACT_STATUS_DOCUMENT_FORMED: (ctsId) => `/form/${ctsId}/statuses/document-formed`,
  UPDATE_CONTRACT_STATUS_ANNUL: (ctsId) => `/form/cts/${ctsId}/statuses/annul`,
  UPDATE_UNITED_FRONT_SESSION: '/auth/united-front/payload',
  CHECK_TEMPLATE_EXIST: '/templates/check-exist',
  UPLOAD_TEMPLATE: '/templates/upload',
  ACCEPT_PARTNER_CHANEL_AGREEMENT: '/auth/agent-channel',
  GET_RESTRICTIONS: '/form/restrictions',
  GET_COURSE: (id) => `/form/course/${id}`,
  GET_CTS_SOURCES: '/branches/cts-source-codes',

  /* Basket module */
  GET_BASKET_PRODUCT_TYPES: '/constructor-isz/references/product-types',
  GET_BASKET_OPTION_TYPES: '/constructor-isz/references/option-types',
  GET_BASKET_OPTION_TYPES_RESTRICTIONS: '/constructor-isz/references/option-types-restrictions',
  GET_BASKET_CURRENCY_TYPES: '/constructor-isz/references/product-currencies',
  GET_BASKET_TERMS: '/constructor-isz/references/product-terms',
  GET_BASKET_ACTIVES: '/constructor-isz/references/assets',
  GET_BASKET_FREQUENCY: '/constructor-isz/references/payout-frequency',
  GET_BASKET_ASSETS_BY_REQUEST_ID: (id) => `/constructor-isz/requests/${id}/assets`,
  SET_FAVORITES: '/constructor-isz/favorites',
  GET_AMOUNT_FAVORITES_BASKET: '/constructor-isz/amount-favorite-basket',
  GET_CONSTRUCTOR_SETTINGS: '/constructor-isz/settings/product',
  GENERATE_REPORT: '/constructor-isz/report',
  GET_MIN_PAY_BY_PARAMETERS: '/constructor-isz/insurance-sum/by-parameters',
  GET_MIN_PAY_BY_REQUEST: '/constructor-isz/insurance-sum/by-request',
  CALCULATE_BASKET: '/constructor-isz/requests',
  REQUEST_DATA: '/constructor-isz/requests',
  GET_CALCULATED_BASKETS: '/constructor-isz/requests/calculated',
  GET_REQUEST_BASKETS_COUNT: '/constructor-isz/settings/request-count',
  NOTIFY_CLIENTS_CALC_BASKET: '/constructor-isz/notify',
  GET_BASKETS_HINTS: '/constructor-isz/requests/hints',
  GET_READY_BASKETS: '/constructor-isz/requests/ready',
  GET_REGULAR_BASKETS: '/constructor-isz/requests/regular',
  EXECUTION_BASKET: (id) => `/constructor-isz/requests/${id}/execution`,
  EXECUTION_DP_POLICY_BASKET: (id) => `/constructor-isz/requests/${id}/dp-policy-execution`,
  SAVE_CALC_VALUE: (id) => `/constructor-isz/requests/${id}/value-calculation`,
  ANNUL_OLD_CONSTRUCTOR: '/constructor-isz/annul-old-constructor-contracts',
  DOWNLOAD_PRESENTATION: (id) => `/form/${id}/presentation`,
  CHECK_MULTIPLE_ASSETS: '/constructor-isz/references/check-multiple-assets',
  GET_GENERAL_ASSET_CLASS: '/constructor-isz/references/get-general-asset-class',
  GET_MAX_GUARANTEE_LEVEL: '/constructor-isz/references/max-guarantee-level',
  RECALCULATE_BASKET: (id) => `/constructor-isz/requests/${id}/recalculate`,
  GET_SIGNING_TYPES: '/sms-signing/types',
  CHECK_IS_ACTIVE_CONFIRM: '/sms-signing/sms-button-status',
  SMS_SENT: '/sms-signing/link',
};

export default API;
