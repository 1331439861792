import React, { useCallback } from 'react';
import { Range } from 'react-range';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const bem = block('input-range');

const propsTypes = {
  value: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  maxValue: PropTypes.number.isRequired,
  minValue: PropTypes.number.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const defaultProps = {
  disabled: false,
};

const InputRangeCustom = ({
  name,
  value,
  maxValue,
  minValue,
  onChange,
  disabled,
}) => {
  const isDisabled = maxValue === minValue || disabled;
  const recountMaxValue = isDisabled ? maxValue + 1 : maxValue;
  const isRange = value >= minValue && value <= maxValue;
  const recountValue = isRange ? value : recountMaxValue;

  const handleChange = useCallback((values) => {
    onChange(values[0]);
  }, [onChange]);

  return (
    <div className={bem()}>
      <Range
        min={minValue}
        max={recountMaxValue}
        step={1}
        values={[recountValue]}
        onChange={handleChange}
        name={name}
        disabled={disabled}
        renderTrack={({ props, children }) => (
          <div
            {...props}
            className={bem('track')}
          >
            {children}
          </div>
        )}
        renderThumb={({ props }) => (
          <div
            {...props}
            className={bem('slider')}
          />
        )}
      />
    </div>
  );
};

InputRangeCustom.propTypes = propsTypes;
InputRangeCustom.defaultProps = defaultProps;
export default InputRangeCustom;
