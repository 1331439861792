export const NSZ_CALCULATOR_ENUMS = {
  refCurrencyId: 'refCurrencyId',
  insuredBirthDate: 'insuredBirthDate',
  childInsuredBirthDate: 'childInsuredBirthDate',
  termId: 'termId',
  insuredSexId: 'insuredSexId',
  childInsuredSexId: 'childInsuredSexId',
  premiumFreqId: 'premiumFreqId',
  productCalcType: 'productCalcType',
  regularPaymentAmount: 'regularPaymentAmount',
  accumulationAmount: 'accumulationAmount',
  refExchangeBankId: 'refExchangeBankId',
};
