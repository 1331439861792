import React from 'react';
import { Form, Loader } from 'semantic-ui-react';
import {
  RangeDatePicker,
  InputRangeCustom,
  MainBtn,
  CustomCheckBox,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const bem = block('check-document-master');
const {
  PARTNER,
  SUBDIVISION,
  USER,
  CONTRACT_NUMBER,
  FULL_NAME_CLIENTS,
  TYPE_CLIENT,
  INSURER,
  INSURED,
  BENEFICIARY,
  COUNT_CONTRACT_FOR_CHECK,
  NEW_DOCUMENT,
  CHECKED_DOCUMENT,
  PARTIALLY_CHECKED_DOCUMENT,
  ERROR,
  STATUS_CHECK,
  CHECKER,
  NEXT,
  STATUS,
  UPDATE_DATA,
  START_DATE,
  END_DATE,
  CHECK_TEST_CTS,
} = LANG_DICTIONARY;

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  handleChange: PropTypes.func,
  handleBlur: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.object),
  loadingPartners: PropTypes.bool,
  onChangePartner: PropTypes.func,
  getBranches: PropTypes.func,
  getUsers: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  branches: PropTypes.arrayOf(PropTypes.object),
  loadingBranches: PropTypes.bool,
  loadingUsers: PropTypes.bool,
  loadingStatus: PropTypes.bool,
  statusList: PropTypes.arrayOf(PropTypes.object),
  handleChangeCheckbox: PropTypes.func,
  partnerId: PropTypes.number,
  branchId: PropTypes.number,
  userId: PropTypes.number,
  statusId: PropTypes.number,
  ctsNum: PropTypes.string,
  ctgtsFio: PropTypes.string,
  handleChangePartner: PropTypes.func,
  handleChangeBranches: PropTypes.func,
  isInsurer: PropTypes.bool,
  isInsured: PropTypes.bool,
  isBeneficiary: PropTypes.bool,
  isNew: PropTypes.bool,
  isChecked: PropTypes.bool,
  isHalfChecked: PropTypes.bool,
  isError: PropTypes.bool,
  isTest: PropTypes.bool,
  // eslint-disable-next-line
  dateBeg: PropTypes.any, dateEnd: PropTypes.any,
  handleChangeCheckboxDependRange: PropTypes.func,
  handleChangeRangeInput: PropTypes.func,
  loadingCount: PropTypes.bool,
  countContract: PropTypes.number,
  documentCount: PropTypes.number,
  loadingChecker: PropTypes.bool,
  checkerList: PropTypes.arrayOf(PropTypes.object),
  checkUserId: PropTypes.number,
  handleSubmit: PropTypes.func,
  rangeInputValue: PropTypes.number,
};
const defaultProps = {
  values: {},
  touched: {},
  errors: {},
  handleChange: () => null,
  handleBlur: () => null,
  partners: PropTypes.arrayOf(PropTypes.object),
  loadingPartners: PropTypes.bool,
  onChangePartner: () => null,
  getBranches: () => null,
  getUsers: () => null,
  users: PropTypes.arrayOf(PropTypes.object),
  branches: [],
  loadingBranches: false,
  loadingUsers: false,
  loadingStatus: false,
  statusList: [],
  handleChangeCheckbox: () => null,
  partnerId: 0,
  branchId: 0,
  userId: 0,
  statusId: 0,
  ctsNum: '',
  ctgtsFio: '',
  handleChangePartner: () => null,
  handleChangeBranches: () => null,
  isInsurer: false,
  isInsured: false,
  isBeneficiary: false,
  isNew: false,
  isChecked: false,
  isHalfChecked: false,
  isError: false,
  isTest: false,
  dateBeg: '',
  dateEnd: '',
  handleChangeCheckboxDependRange: () => null,
  handleChangeRangeInput: () => null,
  loadingCount: false,
  countContract: 0,
  documentCount: 0,
  loadingChecker: false,
  checkerList: [],
  checkUserId: 0,
  handleSubmit: () => null,
  rangeInputValue: 0,
};

const CheckDocumentMasterForm = ({
  errors,
  values,
  touched,
  handleChange,
  partners,
  loadingPartners,
  users,
  branches,
  loadingBranches,
  loadingUsers,
  loadingStatus,
  statusList,
  handleChangeCheckbox,
  partnerId,
  branchId,
  userId,
  statusId,
  ctsNum,
  ctgtsFio,
  handleChangePartner,
  handleChangeBranches,
  isInsurer,
  isInsured,
  isBeneficiary,
  isNew,
  isChecked,
  isHalfChecked,
  isError,
  isTest,
  dateBeg,
  dateEnd,
  handleChangeCheckboxDependRange,
  handleChangeRangeInput,
  loadingCount,
  countContract,
  documentCount,
  loadingChecker,
  checkerList,
  checkUserId,
  handleSubmit,
  rangeInputValue,
}) => {
  const inputProps = {
    errors,
    values,
    touched,
    onChange: handleChange,
  };

  const disableBranches = loadingBranches || !partnerId;
  const disableUsers = loadingUsers || !partnerId;

  const getMessageCountStatus = () => {
    if (loadingCount) {
      return (
        <div className={bem('loader-message')}>
          <Loader inline active size="tiny" />
          <p className={bem('loader-text')}>{`${UPDATE_DATA}...`}</p>
        </div>
      );
    }
    if (countContract === 0) return 'Нет документов к проверке';

    return documentCount;
  };

  return (
    <Form className={bem()}>
      <div className={bem('field-block')}>
        <p className={bem('field-text')}>{PARTNER}</p>
        <SelectInput
          {...inputProps}
          name="partnerId"
          values={{ partnerId }}
          options={partners}
          isShowLoading
          loading={loadingPartners}
          disabled={loadingPartners}
          onChange={handleChangePartner}
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block')}>
        <p className={bem('field-text')}>{SUBDIVISION}</p>
        <SelectInput
          {...inputProps}
          options={branches}
          name="branchId"
          values={{ branchId }}
          disabled={disableBranches}
          isShowLoading
          loading={loadingBranches}
          onChange={handleChangeBranches}
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block')}>
        <p className={bem('field-text')}>{USER}</p>
        <SelectInput
          {...inputProps}
          name="userId"
          options={users}
          values={{ userId }}
          isShowLoading
          disabled={disableUsers}
          loading={loadingUsers}
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block')}>
        <div className={bem('date-picker-wrapper')}>
          <RangeDatePicker
            {...inputProps}
            values={{
              dateBeg,
              dateEnd,
            }}
            labelStartDate={START_DATE}
            labelEndDate={END_DATE}
            startDateName="dateBeg"
            endDateName="dateEnd"
          />
        </div>
        <SelectInput
          {...inputProps}
          name="statusId"
          placeholder={STATUS}
          values={{ statusId }}
          isShowLoading
          loading={loadingStatus}
          options={statusList}
          disabled={loadingStatus}
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block', { indent: true })}>
        <p className={bem('field-text')}>{CONTRACT_NUMBER}</p>
        <TextInput
          {...inputProps}
          values={{ ctsNum }}
          name="ctsNum"
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block', { 'no-margin': true, indent: true })}>
        <p className={bem('field-text')}>{FULL_NAME_CLIENTS}</p>
        <TextInput
          {...inputProps}
          values={{ ctgtsFio }}
          name="ctgtsFio"
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('field-block', { indent: true })}>
        <p className={bem('field-text')}>{TYPE_CLIENT}</p>
        <div className={bem('checkboxes-container')}>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{INSURER}</p>
            <CustomCheckBox
              onChange={handleChangeCheckbox}
              name="isInsurer"
              checked={isInsurer}
            />
          </div>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{INSURED}</p>
            <CustomCheckBox
              onChange={handleChangeCheckbox}
              name="isInsured"
              checked={isInsured}
            />
          </div>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{BENEFICIARY}</p>
            <CustomCheckBox
              onChange={handleChangeCheckbox}
              checked={isBeneficiary}
              name="isBeneficiary"
            />
          </div>
        </div>
      </div>
      <div className={bem('field-block', { column: true })}>
        <div className={bem('message-status')}>
          <p className={bem('field-text')}>{`${COUNT_CONTRACT_FOR_CHECK}:`}</p>
          {getMessageCountStatus()}
        </div>
        <InputRangeCustom
          maxValue={countContract}
          minValue={0}
          disabled={loadingCount}
          value={rangeInputValue}
          name="range"
          onChange={handleChangeRangeInput}
        />
      </div>
      <div className={bem('field-block', { indent: true })}>
        <p className={bem('field-text')}>{STATUS_CHECK}</p>
        <div className={bem('checkboxes-container')}>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{NEW_DOCUMENT}</p>
            <CustomCheckBox
              onChange={handleChangeCheckboxDependRange}
              name="isNew"
              checked={isNew}
            />
          </div>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{CHECKED_DOCUMENT}</p>
            <CustomCheckBox
              onChange={handleChangeCheckboxDependRange}
              name="isChecked"
              checked={isChecked}
            />
          </div>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{PARTIALLY_CHECKED_DOCUMENT}</p>
            <CustomCheckBox
              onChange={handleChangeCheckboxDependRange}
              name="isHalfChecked"
              checked={isHalfChecked}
            />
          </div>
          <div className={bem('checkbox-wrapper')}>
            <p className={bem('checkbox-title')}>{ERROR}</p>
            <CustomCheckBox
              onChange={handleChangeCheckboxDependRange}
              name="isError"
              checked={isError}
            />
          </div>
        </div>
      </div>
      <div className={bem('field-block', { indent: true })}>
        <p className={bem('field-text')}>{CHECK_TEST_CTS}</p>
        <div className={bem('checkboxes-container')}>
          <CustomCheckBox
            onChange={handleChangeCheckboxDependRange}
            name="isTest"
            checked={isTest}
          />
        </div>
      </div>
      <div className={bem('field-block')}>
        <p className={bem('field-text')}>{CHECKER}</p>
        <SelectInput
          {...inputProps}
          name="checkUserId"
          values={{ checkUserId }}
          isShowLoading
          loading={loadingChecker}
          options={checkerList}
          disabled={loadingChecker}
          prefixWrapperClass={bem('scroll').toString()}
        />
      </div>
      <div className={bem('btn')}>
        <MainBtn text={NEXT} onClick={handleSubmit} />
      </div>
    </Form>
  );
};

CheckDocumentMasterForm.defaultProps = defaultProps;
CheckDocumentMasterForm.propTypes = propTypes;

export default CheckDocumentMasterForm;
