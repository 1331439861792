import {
  GET_PRODUCT_INFO,
  CLEAR_STORE_KSZ_PRODUCTS,
  CHANGE_FIELD,
  SET_STAGE_PRODUCT,
  COPY_KSZ_CONTRACT,
  SET_FIELDS_TOUCHED,
  SET_RESTRICTIONS,
  FILL_TEST_DATA,
  FILL_TEST_DATA_BENEFICIARY_KSZ,
} from '../types';
import { CHECK_ACCOUNT_NUMBERS, SAVE_DATA, FILL_TEST_DATA_INSURED_NSZ } from '../../nszProduct/types';

export const fillBeneficiaryTestDataKsz = (beneficiaries, beneficiaryInLaw) => ({
  type: FILL_TEST_DATA_BENEFICIARY_KSZ,
  payload: {
    beneficiaries,
    beneficiaryInLaw,
  },
});

export const fillInsuredDataKsz = (insured, insurerIsInsured) => ({
  type: FILL_TEST_DATA_INSURED_NSZ,
  payload: { data: insured, insurerIsInsured },
});

export const fillInsurerTestDataKsz = (insurer) => ({
  type: FILL_TEST_DATA,
  payload: insurer,
});

export const checkAccountNumbersKsz = (num) => ({
  type: CHECK_ACCOUNT_NUMBERS.request,
  payload: num,
});

export const saveDataKszForm = (data) => ({
  type: SAVE_DATA,
  payload: data,
});

export const clearStoreKszProducts = () => ({
  type: CLEAR_STORE_KSZ_PRODUCTS,
});

export const changeFieldKszProduct = (name, value) => ({
  type: CHANGE_FIELD,
  payload: { name, value },
});

export const getKszProductInfo = (id) => ({
  type: GET_PRODUCT_INFO.request,
  payload: id,
});

export const setStageKszProduct = (stage) => ({
  type: SET_STAGE_PRODUCT,
  payload: stage,
});

export const getCopyKszContract = (id, history) => ({
  type: COPY_KSZ_CONTRACT.request,
  payload: { id, history },
});

export const setKszFieldsTouched = () => ({
  type: SET_FIELDS_TOUCHED,
});

export const setKszProductRestrictions = (restrictions) => ({
  type: SET_RESTRICTIONS,
  payload: { restrictions },
});
