import React, { PureComponent } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { Input, Checkbox } from 'semantic-ui-react';
import InputMask from 'react-input-mask';
import PropTypes from 'prop-types';
import { getFormattedTime, getFormattedDate } from 'helpers';
import { UploadIcon } from 'components';
import './styles.scss';

const bem = block('scheduled-task');

const {
  TIME_TASK_START,
  STATUS_NEXT_TASK,
  REPEAT_DAY,
  STATUS_PREV_TASK,
  SCHEDULED,
  SUCCESS_TASK,
  NOT_SCHEDULED,
  NO_TASK,
  FAIL_TASK,
  REPEAT_EVEY_N_MINUTE,
} = LANG_DICTIONARY;

const propTypes = {
  nextJob: PropTypes.object,
  lastJob: PropTypes.object,
  createTask: PropTypes.func,
  cancelTask: PropTypes.func,
  link: PropTypes.string,
  isNetwork: PropTypes.bool,
  path: PropTypes.string,
  timeStart: PropTypes.string,
  isActive: PropTypes.bool,
  status: PropTypes.bool,
  repeatEveryTime: PropTypes.bool,
  timeRepeat: PropTypes.number,
  handleChangeInputStore: PropTypes.func,
  importFrom1CForm: PropTypes.bool,
  successFilesDir: PropTypes.string,
  errorFilesDir: PropTypes.string,
  newFilesDir: PropTypes.string,
};

const defaultProps = {
  importFrom1CForm: false,
  successFilesDir: '',
  errorFilesDir: '',
  newFilesDir: '',
  handleChangeInputStore: () => null,
  timeRepeat: '',
  repeatEveryTime: false,
  status: false,
  isActive: false,
  timeStart: '',
  nextJob: {},
  lastJob: {},
  createTask: () => null,
  cancelTask: () => null,
  link: '',
  isNetwork: false,
  path: '',
};

class ScheduledTaskForm extends PureComponent {
  constructor(props) {
    super(props);
    const { nextJob: { repeat, dateTime }, timeStart } = props;
    this.state = {
      time: timeStart || (dateTime && getFormattedTime(dateTime)),
      repeat,
      stateBtn: dateTime ? 'active' : 'stop',
    };
  }

  handleChangeTime = ({ target: { value } }) => {
    this.setState({ time: value });
  };

  getStatusString = (date) => `${getFormattedTime(date)} ${getFormattedDate(date)}`;

  getNextTask = () => {
    const { nextJob: { dateTime }, isActive } = this.props;

    return dateTime || isActive ? `${SCHEDULED} ${this.getStatusString(dateTime)}`
      : `${NOT_SCHEDULED}`;
  };

  getScheduledTask = () => {
    const { lastJob: { dateTime, status }, status: statusImport1C } = this.props;
    if (!dateTime) return NO_TASK;

    return status || statusImport1C ? `${SUCCESS_TASK} ${this.getStatusString(dateTime)}`
      : `${FAIL_TASK} ${this.getStatusString(dateTime)}`;
  };

  getStatusBtn = () => {
    const {
      nextJob: { dateTime },
    } = this.props;

    return dateTime ? 'active' : 'stop';
  };

  handleClickBtn = () => {
    const {
      createTask,
      link,
      cancelTask,
      isNetwork,
      path,
    } = this.props;
    const { time, repeat, stateBtn } = this.state;
    if (stateBtn === 'stop') {
      createTask({
        path: isNetwork ? link : path,
        isNetwork,
        time,
        repeat,
        action: () => this.setState({ stateBtn: 'active' }),
      });
      this.setState({ stateBtn: 'active' });
    }
    if (stateBtn === 'active') {
      cancelTask();
      this.setState({ stateBtn: 'stop', time: '' });
    }
  };

  handleChangeCheckbox = () => {
    const { repeat } = this.state;
    this.setState({ repeat: !repeat });
  };

  checkDisable = () => {
    const { time, stateBtn } = this.state;
    const {
      link,
      path,
      isNetwork,
      importFrom1CForm,
      successFilesDir,
      errorFilesDir,
      newFilesDir,
      timeRepeat,
    } = this.props;
    const currentImport = isNetwork ? link : path;
    const statusFill = !time || !currentImport;
    if (importFrom1CForm) {
      const statusFields = !time
      || !successFilesDir
      || !errorFilesDir
      || !newFilesDir
      || !timeRepeat;

      return statusFields && stateBtn !== 'active';
    }

    return statusFill && stateBtn !== 'active';
  };

  render() {
    const {
      time,
      repeat,
    } = this.state;
    const {
      repeatEveryTime,
      timeRepeat,
      handleChangeInputStore,
    } = this.props;

    return (
      <section className={bem()}>
        <div className={bem('content')}>
          <div className={bem('setting-row')}>
            <p className={bem('title')}>
              {TIME_TASK_START}
            </p>
            <div className={bem('time-setting')}>
              <div className={bem('input')}>
                <Input
                  children={(
                    <InputMask
                      maskChar=" "
                      mask="99:99"
                      value={time}
                      onChange={this.handleChangeTime}
                    />
              )}
                />
              </div>
              <div className={bem('status-next-wrapper')}>
                <p className={bem('status-next-title')}>{STATUS_NEXT_TASK}</p>
                <p className={bem('status-next-value')}>{this.getNextTask()}</p>
              </div>
            </div>
          </div>
          <div className={bem('setting-row')}>
            <p className={bem('title')}>
              { repeatEveryTime ? REPEAT_EVEY_N_MINUTE : REPEAT_DAY}
            </p>
            <div className={bem('time-setting')}>
              <div className={bem('checkbox')}>
                {repeatEveryTime
                  ? (
                    <Input
                      value={timeRepeat}
                      name="timeRepeat"
                      onChange={handleChangeInputStore}
                    />
                  )
                  : (
                    <Checkbox
                      toggle
                      checked={repeat}
                      onChange={this.handleChangeCheckbox}
                    />
                  )}
              </div>
              <div className={bem('status-next-wrapper')}>
                <p className={bem('status-next-title')}>{STATUS_PREV_TASK}</p>
                <p className={bem('status-next-value')}>{this.getScheduledTask()}</p>
              </div>
            </div>
          </div>
        </div>
        <div>
          <UploadIcon
            status={this.getStatusBtn()}
            onClick={this.handleClickBtn}
            disabled={this.checkDisable()}
          />
        </div>
      </section>
    );
  }
}

ScheduledTaskForm.defaultProps = defaultProps;
ScheduledTaskForm.propTypes = propTypes;

export default ScheduledTaskForm;
