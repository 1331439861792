import React from 'react';
import PropTypes from 'prop-types';
import FileItem from './fileItem';

const defaultProps = {
  files: [],
  listClass: null,
  itemClass: null,
};

const propTypes = {
  files: PropTypes.arrayOf(PropTypes.object),
  listClass: PropTypes.object,
  itemClass: PropTypes.object,
};

const FileList = ({ files, listClass, ...props }) => (
  <div className={listClass}>
    {
      Object.values(files).map(({
        fileInfo,
        id,
        typeId,
        fileName,
        disableControls,
      }, index) => (
        <FileItem
          /* eslint-disable-next-line */
          key={index}
          fileName={fileName}
          file={fileInfo}
          index={index}
          id={id}
          value={typeId}
          disableControls={disableControls}
          {...props}
        />
      ))
    }
  </div>
);

FileList.defaultProps = defaultProps;
FileList.propTypes = propTypes;

export default FileList;
