import React from 'react';
import { Button, Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import './styles.scss';

const { SEARCH } = LANG_DICTIONARY;

const defaultProps = {
  text: SEARCH,
  className: '',
  disabled: false,
};

const propTypes = {
  text: PropTypes.string,
  className: PropTypes.string,
  action: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
};

const SearchBtn = (props) => {
  const {
    text,
    className,
    action,
    disabled,
  } = props;

  return (
    <Button
      type="text"
      className={`searchButton ${className}`}
      onClick={action}
      disabled={disabled}
    >
      <div className="searchButton__content">
        {text}
        <Icon name="search" />
      </div>
    </Button>
  );
};

SearchBtn.propTypes = propTypes;
SearchBtn.defaultProps = defaultProps;

export default withCustomRouter(SearchBtn);
