import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { CustomCheckBox, GetFormError } from 'components';
import { separateThousands } from 'helpers';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('risk-list');

const { ADDITIONAL_RISKS_NOT_FOUND, PERCENT_OF_THE_SUMMARY_OF_SURVIVAL } = LANG_DICTIONARY;

const RISK_TYPES = {
  basic: 'basic',
  additional: 'additional',
  package: 'package',
  insuranceSums: 'insuranceSums',
};

const propTypes = {
  list: PropTypes.arrayOf(PropTypes.any),
  type: PropTypes.string,
  title: PropTypes.string,
  listControl: PropTypes.func,
  disabled: PropTypes.bool,
  open: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  onChangeCheckbox: PropTypes.func,
  errors: PropTypes.object,
  onBlur: PropTypes.func,
  hints: PropTypes.object,
  insuranceSums: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  onBlur: () => null,
  onChangeCheckbox: () => null,
  list: [],
  type: '',
  title: '',
  listControl: () => null,
  disabled: false,
  open: false,
  onChange: () => null,
  name: '',
  errors: {},
  hints: {},
  insuranceSums: [],
};

const RisksList = ({
  list,
  type,
  title,
  listControl,
  disabled,
  open,
  onChange,
  name,
  onChangeCheckbox,
  errors,
  onBlur,
  hints,
  insuranceSums,
}) => {
  const checkForRenderHintOrError = (nameItem) => (
    Boolean(errors[nameItem] || hints[nameItem])
  );

  const getRisks = (packetRisks) => {
    switch (type) {
      case RISK_TYPES.basic: {
        return (
          list.map((item) => <div key={`${item.insObjectRiskId}`} className={b('risk')}>{item.nameDp}</div>)
        );
      }

      case RISK_TYPES.additional: {
        if (!list.length) {
          return (
            <div className={b('risk-wrapper')}>
              <div className={b('risk-row')}>{ADDITIONAL_RISKS_NOT_FOUND}</div>
            </div>
          );
        }

        return (
          <div>
            {list.map((item, index) => (
              <div className={b('risk-wrapper')}>
                <div className={b('risk-row')}>
                  <div className={b('risk-title')}>
                    {item.nameDp}
                  </div>
                  <div className={b('risk-check')}>
                    <CustomCheckBox
                      checked={item.checked}
                      onChange={() => onChangeCheckbox(item, !item.checked)}
                      name={item.name}
                      index={index}
                    />
                  </div>
                  <input
                    className={b('risk-weight')}
                    value={item.checked ? item.value : ''}
                    disabled={!item.checked || item.minWeight === item.maxWeight}
                    name="value"
                    onBlur={({ target: { value } }) => onBlur(item, value)}
                    onChange={(
                      {
                        target: {
                          name: inputName,
                          value,
                        },
                      },
                    ) => onChange(item, inputName, value)}
                  />
                  <div className={b('risk-check')}>%</div>
                </div>
                {checkForRenderHintOrError(item.name) && (
                <div className={b('hint')}>
                  <GetFormError
                    errors={errors}
                    itemName={item.name}
                    value={item.value}
                    isTouched
                    className={b('hint-text')}
                  />
                  <GetFormError
                    errors={hints}
                    itemName={item.name}
                    value={item.value}
                    isTouched
                  />
                </div>
                )}
              </div>
            ))}
          </div>
        );
      }

      case RISK_TYPES.package: {
        return (
          packetRisks.map((item) => (
            <div className={b('risk-row')}>
              <div className={b('package-risk')}>{`${item.riskWeight}${PERCENT_OF_THE_SUMMARY_OF_SURVIVAL}`}</div>
              <div className={b('package-desc')}>{item.nameDp}</div>
            </div>
          ))
        );
      }

      default: {
        return null;
      }
    }
  };

  if (type === RISK_TYPES.insuranceSums) {
    return (
      insuranceSums.map((item) => (
        <div className={b()}>
          <div className={b('insurance-sum-row')}>
            <div className={b('insurance-sum-title')}>{separateThousands(item.namePayment)}</div>
            <div className={b('insurance-sum-weight')}>{separateThousands(item.insuranceSum)}</div>
          </div>
        </div>
      ))
    );
  }

  if (type === RISK_TYPES.package) {
    return (
      <div>
        {
          list.map((item) => (
            <div className={b()}>
              <div className={b('header')}>
                <p className={b('title')}>{item.nameDp}</p>
                <div className={b('control')}>
                  <CustomCheckBox
                    checked={item.checked}
                    disabled={item.isMandatory}
                    onChange={() => onChange(item.packetId, 'checked', !item.checked)}
                  />
                  <button
                    type="button"
                    name="isOpen"
                    onClick={() => onChange(item.packetId, 'isOpen', !item.isOpen)}
                    disabled={disabled}
                    className={b('control-btn')}
                  >
                    <Icon name={item.isOpen ? 'caret down' : 'caret right'} />
                  </button>
                </div>
              </div>
              {item.isOpen && <div className={b('table')}>{getRisks(item.packetRisks)}</div>}
            </div>
          ))
        }
      </div>
    );
  }

  return (
    <div className={b()}>
      <div className={b('header')}>
        {title && (
        <div className={b('title-wrapper')}>
          <p className={b('title')}>{title}</p>
          <button
            type="button"
            onClick={() => listControl(name, !open)}
            disabled={disabled}
            className={b('control-btn')}
          >
            <Icon name={open ? 'caret down' : 'caret right'} />
          </button>
        </div>
        )}
      </div>
      {open && <div className={b('table')}>{getRisks()}</div>}
    </div>
  );
};

RisksList.propTypes = propTypes;
RisksList.defaultProps = defaultProps;
export default RisksList;
