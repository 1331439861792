import {
  APP_CAN_RENDER,
  SET_TIMEOUT_WORK,
  SET_BLOCK_REDIRECT,
  SET_STATUS_REDIRECT_MODAL,
  PUSH_HISTORY_ROUTE,
  DOWNLOAD_MANUAL_REQUEST,
  CHECK_EXIST_MANUAL_REQUEST,
  SET_PROGRESS_BAR_DOWNLOAD,
  SET_PROGRESS_PERCENT,
  SAVE_AND_CLEAR_QUERY_PARAMS,
  GENERATE_WINDOW_UUID,
  CHANGE_QUERY_PARAMS,
  SET_WINDOW_TIMESTAMP,
} from '../types';

const appCanRender = () => ({
  type: APP_CAN_RENDER,
});

const setTimeoutWork = () => ({
  type: SET_TIMEOUT_WORK,
});

const setBlockRedirect = (path) => ({
  type: SET_BLOCK_REDIRECT,
  payload: path,
});

const setStatusRedirectModal = (status) => ({
  type: SET_STATUS_REDIRECT_MODAL,
  payload: status,
});

const pushHistoryRoute = (route) => ({
  type: PUSH_HISTORY_ROUTE,
  payload: route,
});

const downloadManual = (partnerId, manualName) => ({
  type: DOWNLOAD_MANUAL_REQUEST,
  payload: { partnerId, manualName },
});

const checkExistManual = (partnerId) => ({
  type: CHECK_EXIST_MANUAL_REQUEST,
  payload: partnerId,
});

const setProgressBarDownload = (status) => ({
  type: SET_PROGRESS_BAR_DOWNLOAD,
  payload: status,
});

const setProgressPercent = (percent) => ({
  type: SET_PROGRESS_PERCENT,
  payload: percent,
});

const saveAndClearQueryFromUrlParams = () => ({
  type: SAVE_AND_CLEAR_QUERY_PARAMS,
});

const generateWindowUuid = () => ({
  type: GENERATE_WINDOW_UUID,
});

const changeQueryParams = (payload) => ({
  type: CHANGE_QUERY_PARAMS,
  payload,
});

const setWindowTimestamp = (payload) => ({
  type: SET_WINDOW_TIMESTAMP,
  payload,
});

export {
  setWindowTimestamp,
  changeQueryParams,
  generateWindowUuid,
  saveAndClearQueryFromUrlParams,
  setProgressPercent,
  setProgressBarDownload,
  checkExistManual,
  downloadManual,
  pushHistoryRoute,
  setStatusRedirectModal,
  setBlockRedirect,
  appCanRender,
  setTimeoutWork,
};
