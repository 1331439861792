import { LANG_DICTIONARY } from 'consts';
import { string, object } from 'yup';
import { onlyNumbers } from 'helpers';

const {
  REQUIRED,
  NO_MORE_TEN,
  FILL_COMPLETELY,
} = LANG_DICTIONARY;

const livingAddressCondition = (condition) => string().when('livingAddressIsSame', {
  is: (livingAddressIsSame) => livingAddressIsSame === 'true',
  then: string().notRequired(),
  otherwise: condition || string().required(REQUIRED),
});

export default () => object().shape({
  livingAddressIsSame: string().notRequired(),
  city: string().required(REQUIRED),
  house: string().required(REQUIRED),
  republic: string().required(REQUIRED),
  index: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val).length === 6)
    .required(REQUIRED),
  indexLiving: livingAddressCondition(
    string()
      .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val).length === 6)
      .required(REQUIRED),
  ),
  cityLiving: livingAddressCondition(),
  houseLiving: livingAddressCondition(),
  republicLiving: livingAddressCondition(),
  apartment: livingAddressCondition(string().max(10, NO_MORE_TEN).notRequired()),
  apartmentLiving: livingAddressCondition(string().max(10, NO_MORE_TEN).notRequired()),
});
