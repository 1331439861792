import {
  GET_USERS_UNDERWRITING_SUCCESS,
  GET_USERS_UNDERWRITING_START,
  GET_BRANCHES_UNDERWRITING_SUCCESS,
  GET_BRANCHES_UNDERWRITING_START,
  GET_PARTNERS_UNDERWRITING_SUCCESS,
  GET_PARTNERS_UNDERWRITING_START,
  GET_BRANCHES_UNDERWRITING_FAIL,
  GET_USERS_UNDERWRITING_FAIL,
  GET_PARTNERS_UNDERWRITING_FAIL,
  GET_STATUS_UNDERWRITING_SUCCESS,
  GET_STATUS_UNDERWRITING_START,
  GET_STATUS_UNDERWRITING_FAIL,
  GET_COUNT_UNDERWRITING_START,
  GET_COUNT_UNDERWRITING_FAIL,
  GET_COUNT_UNDERWRITING_SUCCESS,
  GET_CHECKER_UNDERWRITING_START,
  GET_CHECKER_UNDERWRITING_SUCCESS,
  GET_CHECKER_UNDERWRITING_FAIL,
  GET_CONTRACTS_UNDERWRITING_FAIL,
  GET_CONTRACTS_UNDERWRITING_START,
  GET_CONTRACTS_UNDERWRITING_SUCCESS,
  GET_STATUS_SCAN_UNDERWRITING_SUCCESS,
  GET_ALL_BRANCHES_UNDERWRITING_FAIL,
  GET_ALL_BRANCHES_UNDERWRITING_START,
  GET_ALL_BRANCHES_UNDERWRITING_SUCCESS,
  GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
  GET_STATUS_CHECKING_UNDERWRITING_FAIL,
  GET_STATUS_CHECKING_UNDERWRITING_START,
  SET_CURRENT_SCAN_INDEX,
  SET_INIT_STATE,
} from '../types';

const INITIAL_OPTIONS = [{
  key: 0,
  value: 0,
  text: 'Все',
}];

const INITIAL_OPTIONS_CHECKER = [{
  key: 0,
  value: 0,
  text: 'Отсутствует',
}];

const EMPTY_INITIAL_OPTIONS_STATUS = [{
  key: 0,
  value: 0,
  text: 'Все статусы',
}];

const DONT_CHECKED = [{
  key: 0,
  value: 0,
  text: 'Не выбрано',
}];

const INITIAL_STATE = {
  loadingUsers: false,
  loadingBranches: false,
  loadingPartners: false,
  loadingStatus: false,
  loadingCount: false,
  loadingChecker: false,
  loadingAllBranches: false,
  loadingCheckingStatus: false,
  checkingStatusList: EMPTY_INITIAL_OPTIONS_STATUS,
  statusListForScan: [],
  branches: INITIAL_OPTIONS,
  fullBranches: DONT_CHECKED,
  partners: INITIAL_OPTIONS,
  users: INITIAL_OPTIONS,
  countContract: 0,
  statusList: [],
  listForChecking: [],
  currentContractIndex: 0,
  checkerList: INITIAL_OPTIONS_CHECKER,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_PARTNERS_UNDERWRITING_START:
      return {
        ...state,
        loadingPartners: true,
      };
    case GET_BRANCHES_UNDERWRITING_START:
      return {
        ...state,
        loadingBranches: true,
      };
    case GET_USERS_UNDERWRITING_START:
      return {
        ...state,
        loadingUsers: true,
      };
    case GET_PARTNERS_UNDERWRITING_SUCCESS:
      return {
        ...state,
        partners: [
          ...INITIAL_OPTIONS,
          ...payload,
        ],
        loadingPartners: false,
      };
    case GET_USERS_UNDERWRITING_SUCCESS:
      return {
        ...state,
        users: [
          ...INITIAL_OPTIONS,
          ...payload,
        ],
        loadingUsers: false,
      };
    case GET_BRANCHES_UNDERWRITING_SUCCESS:
      return {
        ...state,
        branches: [
          ...INITIAL_OPTIONS,
          ...payload,
        ],
        loadingBranches: false,
      };
    case GET_BRANCHES_UNDERWRITING_FAIL:
      return {
        ...state,
        error: payload,
        loadingBranches: false,
      };
    case GET_USERS_UNDERWRITING_FAIL:
      return {
        ...state,
        error: payload,
        loadingUsers: false,
      };
    case GET_PARTNERS_UNDERWRITING_FAIL:
      return {
        ...state,
        error: payload,
        loadingBranches: false,
      };
    case GET_STATUS_UNDERWRITING_START:
      return {
        ...state,
        loadingStatus: true,
      };
    case GET_STATUS_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingStatus: false,
        error: payload,
      };

    case GET_STATUS_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingStatus: false,
        statusList: [
          ...EMPTY_INITIAL_OPTIONS_STATUS,
          ...payload,
        ],
      };

    case GET_COUNT_UNDERWRITING_START:
      return {
        ...state,
        loadingCount: true,
      };
    case GET_COUNT_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingCount: false,
        error: payload,
      };
    case GET_COUNT_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingCount: false,
        countContract: payload.count,
      };

    case GET_CHECKER_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingChecker: false,
        checkerList: [
          ...INITIAL_OPTIONS_CHECKER,
          ...payload,
        ],
      };

    case GET_CHECKER_UNDERWRITING_START:
      return {
        ...state,
        loadingChecker: true,
      };
    case GET_CHECKER_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingChecker: false,
        error: payload,
      };

    case GET_CONTRACTS_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingContracts: false,
        listForChecking: payload,
      };

    case GET_CONTRACTS_UNDERWRITING_START:
      return {
        ...state,
        loadingContracts: true,
      };
    case GET_CONTRACTS_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingContracts: false,
        error: payload,
      };
    case GET_STATUS_SCAN_UNDERWRITING_SUCCESS:
      return {
        ...state,
        statusListForScan: payload,
      };

    case SET_CURRENT_SCAN_INDEX:
      return {
        ...state,
        currentContractIndex: payload,
      };

    case GET_ALL_BRANCHES_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingAllBranches: false,
        fullBranches: [
          ...DONT_CHECKED,
          ...payload,
        ],
      };

    case GET_ALL_BRANCHES_UNDERWRITING_START:
      return {
        ...state,
        loadingAllBranches: true,
      };
    case GET_ALL_BRANCHES_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingAllBranches: false,
        error: payload,
      };

    case GET_STATUS_CHECKING_UNDERWRITING_SUCCESS:
      return {
        ...state,
        loadingCheckingStatus: false,
        checkingStatusList: [
          ...EMPTY_INITIAL_OPTIONS_STATUS,
          ...payload,
        ],
      };

    case GET_STATUS_CHECKING_UNDERWRITING_START:
      return {
        ...state,
        loadingCheckingStatus: true,
      };
    case GET_STATUS_CHECKING_UNDERWRITING_FAIL:
      return {
        ...state,
        loadingCheckingStatus: false,
        error: payload,
      };

    case SET_INIT_STATE:
      return INITIAL_STATE;
    default:
      return state;
  }
};
