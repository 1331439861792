import React from 'react';
import {
  NszAgentData,
  KszAgentData,
  TestDataGenerator,
} from 'containers';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import {
  NAMES_GROUP_SETTINGS,
  BLOCK_NAMES,
  LANG_DICTIONARY,
  TYPE_PERSON,
  INS_LINE_TYPES,
  TEST_DATA_ENUMS,
} from 'consts';
import { block } from 'bem-cn';
import { AgentCheckingErrorPopup, MainBtn } from 'components';
import PropTypes from 'prop-types';
import {
  getAgentPersonBlockSelector,
  getAgentDocumentBlockSelector,
  getAgentAddressBlockSelector,
  getAgentBankBlockSelector,
  getAgentAddressLivingBlockSelector,
  getAgentInnSnilsBlockSelector,
  getAgentContactBlockSelector,
  getAgentRelationBlockSelector,
  getInsuredIsSame,
  getDisableInsuredIsSame,
  getMainRequestId,
} from 'redux/rootSelectors';
import {
  validateAgentFieldValues,
  setAgentAllTouchFields,
  clearAgentErrors,
  checkAgent,
} from 'redux/rootActions';
import {
  getErrorsFromValidationError,
  rebuildAgentErrorsBlock,
  getProductConfig,
  scrollToError,
  getStoreKeysByInsLineType,
  getAgentChecking,
} from 'helpers';
import { withCustomRouter } from 'HOC';
import { memoize, get } from 'lodash';
import '../styles.scss';
import Schema from './schema';

const { insuredPerson, insuredChildPerson } = NAMES_GROUP_SETTINGS;
const b = block('contragent-data');

const {
  insured,
  childInsured,
} = TYPE_PERSON;

const propTypes = {
  scenario: PropTypes.string.isRequired,
  isInsured: PropTypes.bool,
  insuredIsSame: PropTypes.bool.isRequired,
  checkAccountNumbersError: PropTypes.func.isRequired,
  checkNumbers: PropTypes.func.isRequired,
  personBlockProps: PropTypes.object.isRequired,
  documentBlockProps: PropTypes.object.isRequired,
  addressBlockProps: PropTypes.object.isRequired,
  bankBlockProps: PropTypes.object.isRequired,
  addressLivingBlockProps: PropTypes.object.isRequired,
  insSnilsBlockProps: PropTypes.object.isRequired,
  validateFieldValues: PropTypes.object.isRequired,
  contactBlockProps: PropTypes.object.isRequired,
  relationBlockProps: PropTypes.object.isRequired,
  clearErrors: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  history: PropTypes.object.isRequired,
  setAllTouchFields: PropTypes.func.isRequired,
  personBlockPropsChild: PropTypes.object.isRequired,
  documentBlockPropsChild: PropTypes.object.isRequired,
  addressBlockPropsChild: PropTypes.object.isRequired,
  bankBlockPropsChild: PropTypes.object.isRequired,
  addressLivingBlockPropsChild: PropTypes.object.isRequired,
  insSnilsBlockPropsChild: PropTypes.object.isRequired,
  contactBlockPropsChild: PropTypes.object.isRequired,
  relationBlockPropsChild: PropTypes.object.isRequired,
  isLoadingPodft: PropTypes.bool.isRequired,
  passportError: PropTypes.string.isRequired,
  snilsError: PropTypes.string.isRequired,
  setStage: PropTypes.func.isRequired,
  hideBankInfo: PropTypes.bool.isRequired,
  checkPassportCovid: PropTypes.bool,
  isValidAddress: PropTypes.bool.isRequired,
  stage: PropTypes.number,
  beneficiariesDataUrl: PropTypes.string.isRequired,
  insurerDataUrl: PropTypes.string.isRequired,
  additionalParams: PropTypes.object.isRequired,
  calculatorData: PropTypes.object.isRequired,
  checkAgentAction: PropTypes.func.isRequired,
  addressCheckingPayload: PropTypes.object.isRequired,
  mainRequestId: PropTypes.number.isRequired,
  productId: PropTypes.number.isRequired,
};

const defaultProps = {
  checkPassportCovid: false,
  stage: PropTypes.number,
  isInsured: true,
};

const formType = 'insured';
const formTypeChild = 'insuredChild';
const {
  NEXT,
  DOCUMENT_TYPE_BIRTH_DOCUMENT,
  SINGULAR_INSURED,
  SINGULAR_INSURED_CHILD,
} = LANG_DICTIONARY;

class Insured extends React.Component {
  constructor(props) {
    super(props);

    const {
      stage,
      history,
      match: { params: { id } },
      insurerDataUrl,
      additionalParams,
    } = props;

    if (stage < 3) {
      history.push(`${insurerDataUrl}/${id}`);
    }

    switch (additionalParams.type) {
      case INS_LINE_TYPES.NSZ:
        this.InsuredMainComponent = NszAgentData(formType);
        this.InsuredChildComponent = NszAgentData(formTypeChild);
        break;
      case INS_LINE_TYPES.KSZ:
        this.InsuredMainComponent = KszAgentData(formType);
        this.InsuredChildComponent = KszAgentData(formTypeChild);
        break;
      default:
    }
  }

  getConfig = memoize((person, scenario) => getProductConfig(scenario, person));

  componentDidMount() {
    const { scenario } = this.props;
    this.validateForm({ form: formType });
    const productConfigChild = this.getConfig(insuredChildPerson, scenario);

    if (productConfigChild) {
      this.validateForm({ form: formTypeChild });
    }
  }

  nextStep = () => {
    const {
      insuredIsSame,
      setAllTouchFields,
      history,
      match: { params: { id } },
      passportError,
      snilsError,
      setStage,
      scenario,
      beneficiariesDataUrl,
    } = this.props;
    const productConfigChild = this.getConfig(insuredChildPerson, scenario);
    const childValidateErrors = productConfigChild
      ? this.validateForm({ form: formTypeChild })
      : {};
    const mainValidateErrors = insuredIsSame
      ? {}
      : this.validateForm({ form: formType });

    if (Object.keys(mainValidateErrors).length) {
      scrollToError(mainValidateErrors);
    } else if (Object.keys(childValidateErrors).length) {
      scrollToError(childValidateErrors, 'child');
    }

    setAllTouchFields(formTypeChild);
    setAllTouchFields(formType);

    const isValid = !passportError &&
      !snilsError &&
      !Object.values({ ...childValidateErrors, ...mainValidateErrors }).some(Boolean);

    if (!isValid) {
      return null;
    }

    this.validateAgent(() => {
      setStage(4);
      history.push(`${beneficiariesDataUrl}/${id}`);
    });

    return null;
  }

  validateAgent = (next) => {
    const {
      personBlockProps: { values: personValues },
      documentBlockProps: { values: documentValues },
      addressBlockProps: { values: addressValues },
      addressLivingBlockProps: { values: addressLivingValues },
      personBlockPropsChild: { values: personValuesChild },
      documentBlockPropsChild: { values: documentValuesChild },
      addressBlockPropsChild: { values: addressValuesChild },
      addressLivingBlockPropsChild: { values: addressLivingValuesChild },
      scenario,
      insuredIsSame,
      checkAgentAction,
    } = this.props;

    const productConfigChild = this.getConfig(insuredChildPerson, scenario);
    const isChild = Boolean(productConfigChild);

    const data = [];

    if (!insuredIsSame) {
      data.push({
        body: this.getValidationDataAgent({
          personValues,
          documentValues,
          addressValues,
          addressLivingValues,
          agentType: TYPE_PERSON.insured,
        }),
        agentName: SINGULAR_INSURED,
      });
    }

    if (isChild) {
      data.push({
        body: this.getValidationDataAgent({
          personValues: personValuesChild,
          documentValues: documentValuesChild,
          addressValues: addressValuesChild,
          addressLivingValues: addressLivingValuesChild,
          agentType: `${TYPE_PERSON.insured}2`,
        }),
        agentName: SINGULAR_INSURED_CHILD,
      });
    }

    if (!data.length) {
      next();

      return null;
    }

    checkAgentAction({
      next,
      data,
    });

    return null;
  }

  getValidationDataAgent = ({
    personValues,
    documentValues,
    addressValues,
    addressLivingValues,
    agentType,
  }) => {
    const {
      productId,
      addressCheckingPayload,
      mainRequestId,
    } = this.props;

    return {
      agent: getAgentChecking({
        ...personValues,
        ...documentValues,
        ...addressValues,
        ...addressLivingValues,
      }, addressLivingValues.livingAddressIsSame),
      ...addressCheckingPayload,
      agentType,
      productId,
      mainRequestId: mainRequestId || undefined,
    };
  }

  validateForm = ({ newValue, form }) => {
    const {
      isInsured,
      insuredIsSame,
      checkAccountNumbersError,
      checkNumbers,
      personBlockProps,
      documentBlockProps,
      addressBlockProps,
      bankBlockProps,
      addressLivingBlockProps,
      insSnilsBlockProps,
      validateFieldValues,
      contactBlockProps,
      relationBlockProps,
      clearErrors,
      personBlockPropsChild,
      documentBlockPropsChild,
      addressBlockPropsChild,
      bankBlockPropsChild,
      addressLivingBlockPropsChild,
      insSnilsBlockPropsChild,
      contactBlockPropsChild,
      relationBlockPropsChild,
      scenario,
      hideBankInfo,
      checkPassportCovid,
    } = this.props;

    const productConfigMain = this.getConfig(insuredPerson, scenario);
    const productConfigChild = this.getConfig(insuredChildPerson, scenario);

    const isMainForm = form === formType;
    const currentForm = isMainForm ? productConfigMain : productConfigChild;
    const isBank = get(currentForm, 'isBank');
    const { values: { livingAddressIsSame } } = isMainForm
      ? addressLivingBlockProps : addressLivingBlockPropsChild;

    const valuesMain = {
      personBlockProps,
      documentBlockProps,
      addressBlockProps,
      bankBlockProps,
      addressLivingBlockProps,
      insSnilsBlockProps,
      contactBlockProps,
      relationBlockProps,
    };

    const valuesChild = {
      personBlockProps: personBlockPropsChild,
      documentBlockProps: documentBlockPropsChild,
      addressBlockProps: addressBlockPropsChild,
      bankBlockProps: bankBlockPropsChild,
      addressLivingBlockProps: addressLivingBlockPropsChild,
      insSnilsBlockProps: insSnilsBlockPropsChild,
      contactBlockProps: contactBlockPropsChild,
      relationBlockProps: relationBlockPropsChild,
    };

    const agent = form === formType ? valuesMain : valuesChild;
    const valuesForValidation = {
      ...agent.personBlockProps.values,
      ...agent.documentBlockProps.values,
      ...agent.addressBlockProps.values,
      ...agent.bankBlockProps.values,
      ...agent.addressLivingBlockProps.values,
      ...agent.insSnilsBlockProps.values,
      ...agent.contactBlockProps.values,
      ...agent.relationBlockProps.values,
      ...newValue ? { [newValue.name]: newValue.value } : {},
    };

    const blockValues = {
      [BLOCK_NAMES.personBlock]: agent.personBlockProps.values,
      [BLOCK_NAMES.documentBlock]: agent.documentBlockProps.values,
      [BLOCK_NAMES.addressBlock]: agent.addressBlockProps.values,
      [BLOCK_NAMES.bankBlock]: agent.bankBlockProps.values,
      [BLOCK_NAMES.addressBlockLiving]: agent.addressLivingBlockProps.values,
      [BLOCK_NAMES.innSnilsBlock]: agent.insSnilsBlockProps.values,
      [BLOCK_NAMES.contactBlock]: agent.contactBlockProps.values,
      [BLOCK_NAMES.relationBlock]: agent.relationBlockProps.values,
    };

    try {
      Schema({
        livingAddressIsSame,
        isBank: isBank && !hideBankInfo,
        isInsured,
        insuredIsSame,
        checkAccountNumbersError,
        checkNumbers,
        isChildForm: !isMainForm,
        birthDate: valuesForValidation.birthDate,
        isCheckPasswordCovid: checkPassportCovid,
        isBirthCertificate: valuesForValidation.documentType === DOCUMENT_TYPE_BIRTH_DOCUMENT,
      })
        .validateSync({ ...valuesForValidation }, { abortEarly: false });

      clearErrors(form);

      return {};
    } catch (error) {
      const validationErrors = getErrorsFromValidationError(error);

      const errorsAtBlocks = rebuildAgentErrorsBlock(blockValues, validationErrors);
      validateFieldValues(errorsAtBlocks, form);

      return validationErrors;
    }
  };

  render() {
    const {
      personBlockPropsChild,
      documentBlockPropsChild,
      addressBlockPropsChild,
      bankBlockPropsChild,
      addressLivingBlockPropsChild,
      insSnilsBlockPropsChild,
      contactBlockPropsChild,
      relationBlockPropsChild,
      isLoadingPodft,
      ...rest
    } = this.props;
    const { scenario } = rest;
    const {
      InsuredMainComponent,
      InsuredChildComponent,
    } = this;

    const productConfigMain = this.getConfig(insuredPerson, scenario);
    const productConfigChild = this.getConfig(insuredChildPerson, scenario);
    const isChild = Boolean(productConfigChild);

    return (
      <div className={b()}>
        <AgentCheckingErrorPopup />
        <InsuredMainComponent
          config={productConfigMain}
          isInsured
          isChild={isChild}
          validate={this.validateForm}
          form={insured}
          {...rest}
        />
        {isChild && (
          <InsuredChildComponent
            config={productConfigChild}
            isInsured
            isChildForm
            validate={this.validateForm}
            {...rest}
            personBlockProps={personBlockPropsChild}
            documentBlockProps={documentBlockPropsChild}
            addressBlockProps={addressBlockPropsChild}
            bankBlockProps={bankBlockPropsChild}
            addressLivingBlockProps={addressLivingBlockPropsChild}
            insSnilsBlockProps={insSnilsBlockPropsChild}
            contactBlockProps={contactBlockPropsChild}
            relationBlockProps={relationBlockPropsChild}
            form={childInsured}
          />
        )}
        <div className={b('control-interface')}>
          <TestDataGenerator scenario={scenario} type={TEST_DATA_ENUMS.insured} />
          <MainBtn
            onClick={this.nextStep}
            text={NEXT}
            disabled={isLoadingPodft}
            isLoading={isLoadingPodft}
            className={b('btn').toString()}
          />
        </div>
      </div>
    );
  }
}

const mapStateToProps = (state, ownProps) => {
  const {
    product,
    productValues,
  } = getStoreKeysByInsLineType(ownProps.additionalParams.type);

  const {
    [product]: {
      checkAccountNumbersError,
      childInsuredValues,
      stage,
      calculatorData,
      [productValues]: {
        personType,
        hideInn,
        hideSnils,
        hideBankInfo,
        listBlackAddress,
        insurerIsInsured: insurerIsInsuredSetting,
        restrictions,
        productTerms,
        scenario,
        checkPassportCovid,
      },
    },
    validation: {
      isLoading: isLoadingPodft,
    },
    authReducer,
    helperReducer: {
      passportError,
      snilsError,
      addressValues,
    },
  } = state;

  return {
    checkPassportCovid,
    hideBankInfo,
    userInfo: authReducer,
    passportError,
    snilsError,
    addressValues,
    scenario,
    isLoadingPodft,
    checkAccountNumbersError,
    childInsuredValues,
    stage,
    calculatorData,
    personType,
    hideInn,
    hideSnils,
    restrictions,
    productTerms,
    insurerIsInsuredSetting,
    isListBlackAddress: listBlackAddress,
    personBlockProps: getAgentPersonBlockSelector(state, formType),
    documentBlockProps: getAgentDocumentBlockSelector(state, formType),
    addressBlockProps: getAgentAddressBlockSelector(state, formType),
    bankBlockProps: getAgentBankBlockSelector(state, formType),
    addressLivingBlockProps: getAgentAddressLivingBlockSelector(state, formType),
    insSnilsBlockProps: getAgentInnSnilsBlockSelector(state, formType),
    contactBlockProps: getAgentContactBlockSelector(state, formType),
    relationBlockProps: getAgentRelationBlockSelector(state, formType),
    personBlockPropsChild: getAgentPersonBlockSelector(state, formTypeChild),
    documentBlockPropsChild: getAgentDocumentBlockSelector(state, formTypeChild),
    addressBlockPropsChild: getAgentAddressBlockSelector(state, formTypeChild),
    bankBlockPropsChild: getAgentBankBlockSelector(state, formTypeChild),
    addressLivingBlockPropsChild: getAgentAddressLivingBlockSelector(state, formTypeChild),
    insSnilsBlockPropsChild: getAgentInnSnilsBlockSelector(state, formTypeChild),
    contactBlockPropsChild: getAgentContactBlockSelector(state, formTypeChild),
    relationBlockPropsChild: getAgentRelationBlockSelector(state, formTypeChild),
    insuredIsSame: getInsuredIsSame(state),
    disableInsuredIsSame: getDisableInsuredIsSame(state),
    mainRequestId: getMainRequestId(state),
  };
};

const mapDispatchToProps = (dispatch, ownProps) => ({
  validateFieldValues: bindActionCreators(validateAgentFieldValues, dispatch),
  setAllTouchFields: bindActionCreators(setAgentAllTouchFields, dispatch),
  clearErrors: bindActionCreators(clearAgentErrors, dispatch),
  setStage: bindActionCreators(ownProps.setStage, dispatch),
  checkAgentAction: bindActionCreators(checkAgent, dispatch),
});

Insured.propTypes = propTypes;
Insured.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Insured));
