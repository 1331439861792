import { API } from 'consts';
import {
  apiCall,
  getError,
} from 'helpers';
import { ITEM_COUNT_EVENTS_SCAN, ITEM_COUNT_NOTIFICATION_EVENT } from 'config';
import { put, takeLatest } from 'redux-saga/effects';
import {
  GET_EVENTS_MESSAGES_COUNT_FAIL,
  GET_EVENTS_MESSAGES_COUNT_REQUEST,
  GET_EVENTS_MESSAGES_COUNT_START,
  GET_EVENTS_MESSAGES_COUNT_SUCCESS,
  GET_EVENTS_SCANS_FAIL,
  GET_EVENTS_SCANS_REQUEST,
  GET_EVENTS_SCANS_START,
  GET_EVENTS_SCANS_SUCCESS,
  GET_NOTIFICATION_START,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_REQUEST,
  POST_READED_NOTIFICATIONS_FAIL,
  POST_READED_NOTIFICATIONS_REQUEST,
  POST_READED_NOTIFICATIONS_START,
} from '../types';

const { GET_EVENTS_MESSAGE_COUNT, GET_EVENTS_SCAN_ERROS } = API;

function* setReadedNotifications({ payload }) {
  try {
    yield put({ type: POST_READED_NOTIFICATIONS_START });
    yield apiCall({
      url: API.GET_CALC_REQUEST_EVENTS,
      type: 'POST',
      body: {
        calcRequests: payload,
      },
    });
    yield getEventsCount();
  } catch (e) {
    yield put({ type: POST_READED_NOTIFICATIONS_FAIL, payload: getError(e) });
  }
}
function* getEventsCount() {
  try {
    yield put({ type: GET_EVENTS_MESSAGES_COUNT_START });
    const { data } = yield apiCall({
      url: GET_EVENTS_MESSAGE_COUNT,
      type: 'GET',
    });
    yield put({ type: GET_EVENTS_MESSAGES_COUNT_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_EVENTS_MESSAGES_COUNT_FAIL, payload: getError(error) });
  }
}

function* getEventsScanErrors({ payload: { page, params } }) {
  try {
    yield put({ type: GET_EVENTS_SCANS_START });
    const { data } = yield apiCall({
      url: GET_EVENTS_SCAN_ERROS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_EVENTS_SCAN * (page - 1),
        limit: ITEM_COUNT_EVENTS_SCAN,
        ...params,
      },
    });
    yield put({ type: GET_EVENTS_SCANS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_EVENTS_SCANS_FAIL, payload: getError(error) });
  }
}

function* getNotificationEvent({ payload }) {
  try {
    yield put({ type: GET_NOTIFICATION_START });
    const { data } = yield apiCall({
      url: API.GET_CALC_REQUEST_EVENTS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_NOTIFICATION_EVENT * (payload - 1),
        limit: ITEM_COUNT_NOTIFICATION_EVENT,
      },
    });
    yield put({ type: GET_NOTIFICATION_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_NOTIFICATION_FAIL, payload: getError(e) });
  }
}

function* eventsSaga() {
  yield takeLatest(POST_READED_NOTIFICATIONS_REQUEST, setReadedNotifications);
  yield takeLatest(GET_EVENTS_SCANS_REQUEST, getEventsScanErrors);
  yield takeLatest(GET_EVENTS_MESSAGES_COUNT_REQUEST, getEventsCount);
  yield takeLatest(GET_NOTIFICATION_REQUEST, getNotificationEvent);
}

export default eventsSaga;
