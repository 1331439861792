import React from 'react';
import PropTypes from 'prop-types';
import { TYPE_CHECKING_CONTRACT_RU } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import DoubleTick from '../../../assets/images/double-tick.png';
import './styles.scss';

const {
  checked,
  error,
  new: newStatus,
} = TYPE_CHECKING_CONTRACT_RU;

const propTypes = {
  status: PropTypes.string,
};

const defaultProps = {
  status: newStatus,
};

const b = block('checking-state');

const CheckingState = ({ status }) => {
  const checkedStatus = (
    <div className={b({ checked: true })}>
      <p>{status}</p>
      <img src={DoubleTick} alt="double-tick" className={b('succes-icon')} />
    </div>
  );

  const errorStatus = (
    <div className={b({ error: true })}>
      <p>{status}</p>
      <Icon name="warning sign" />
    </div>
  );

  const getStatus = () => {
    switch (status) {
      case checked:
        return checkedStatus;
      case error:
        return errorStatus;
      default:
        return status;
    }
  };

  return getStatus();
};

CheckingState.propTypes = propTypes;
CheckingState.defaultProps = defaultProps;
export default CheckingState;
