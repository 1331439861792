import {
  GET_PARTNERS_OPTIONS_SUCCESS,
  GET_BRANCHES_OPTIONS_SUCCESS,
  GET_REPORT_TYPE_OPTIONS_SUCCESS,
  SELECT_OPTIONS,
} from '../types';

const INITIAL_STATE = {
  partners: [],
  partnersSelect: null,
  branches: [],
  branchesSelect: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_PARTNERS_OPTIONS_SUCCESS:
      return {
        ...state,
        [`${payload.prefix}partners`]: [
          {
            key: null,
            value: null,
            text: 'Не выбрано',
          },
          ...payload.data,
        ],
      };

    case GET_BRANCHES_OPTIONS_SUCCESS:
      return {
        ...state,
        [`${payload.prefix}branches`]: [
          {
            key: null,
            value: null,
            text: 'Не выбрано',
          },
          ...payload.data,
        ],
      };

    case GET_REPORT_TYPE_OPTIONS_SUCCESS:
      return {
        ...state,
        [`${payload.prefix}reportTypes`]: [
          {
            key: null,
            value: null,
            text: 'Не выбрано',
          },
          ...payload.newData,
        ],
      };
    case SELECT_OPTIONS: {
      const { selectType, value, prefix = '' } = payload;

      return {
        ...state,
        [`${prefix}${selectType}Select`]: value,
      };
    }

    default:
      return state;
  }
};
