import React from 'react';
import { ROUTES, LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import notFoundImage from 'assets/images/404_code.jpeg';
import './styles.scss';

const b = block('not-found-page');
const propTypes = {
  history: PropTypes.object,
};
const defaultProps = {
  history: {},
};

const {
  PAGE_NOT_FOUND_CODE,
  PAGE_NOT_FOUND_TEXT,
  BACK_TO_MAIN_PAGE,
} = LANG_DICTIONARY;

const NotFoundPage = ({ history }) => (
  <div className={b()}>
    <div className={b('main')}>
      <div className={b('left-side')}>
        <p className={b('code')}>{PAGE_NOT_FOUND_CODE}</p>
        <div className={b('text')}>{PAGE_NOT_FOUND_TEXT}</div>
      </div>
      <div className={b('right-side')}>
        <img className={b('image')} src={notFoundImage} alt="not-found" />
      </div>
    </div>
    <div className={b('footer')}>
      <button className={b('back-button')} type="button" onClick={() => history.push(ROUTES.home)}>
        {BACK_TO_MAIN_PAGE}
      </button>
    </div>
  </div>
);

NotFoundPage.propTypes = propTypes;
NotFoundPage.defaultProps = defaultProps;
export default withCustomRouter(NotFoundPage);
