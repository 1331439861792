import React, { Component } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import {
  getStatus,
  getPartners,
  simpleDate,
  getErrorsFromValidationError,
} from 'helpers';
import {
  Select,
  Button,
  Form,
  Dropdown,
} from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { FieldBlock, DateCustomPicker, CustomCheckBox } from 'components';
import {
  getAdminOptions,
  export1C,
  setDownloadFile,
  resetTransferDataForm,
} from 'redux/rootActions';
import { checkAccessRoute } from 'roles';
import './styles.scss';
import schema from './schema';
import {
  exportTypes,
  exportTypeAll,
  valuesWithOutAll,
  selectedExportTypes,
} from './exportTypes';

const {
  DESCRIPTION_1C,
  SETTING_EXPORT,
  PATCH_EXPORT,
  SELECT_PARTNER_ADMIN,
  SELECT_PERIOD_ADMIN,
  SELECT_SIGN_PERIOD_ADMIN,
  SELECT_STATUS_ADMIN,
  REWRITE_FILES,
  BEGIN_UNLOADING,
  START_DATE,
  END_DATE,
  START_SIGN_DATE,
  END_SIGN_DATE,
  EXECUTE,
  NUMBER_CONTRACT_UPLOAD,
  NOT_CHOOSED,
  UPLOAD_TO_BROWSER,
  UPLOAD_ADDITIONAL_PRINT_FORM,
  SELECT_TYPE_CONTRACTS,
  EXPORT_TEST_CTS,
} = LANG_DICTIONARY;

const propTypes = {
  getOptions: PropTypes.func,
  export1c: PropTypes.func,
  parentClass: PropTypes.func,
  statusList: PropTypes.arrayOf(PropTypes.object),
  partners: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  isLoading: PropTypes.bool,
  location: PropTypes.object,
  roles: PropTypes.object,
  isDownloadFileFrom1C: PropTypes.bool,
  setFileDownload: PropTypes.func,
  resetForm: PropTypes.func.isRequired,
  isOverwrite: PropTypes.bool,
  isExportAdditionalForms: PropTypes.bool,
  isAddedTestCts: PropTypes.bool,
};

const defaultProps = {
  setFileDownload: () => null,
  isDownloadFileFrom1C: false,
  getOptions: null,
  export1c: null,
  parentClass: null,
  statusList: [],
  partners: [],
  history: {},
  isLoading: false,
  location: {},
  roles: {},
  isOverwrite: false,
  isExportAdditionalForms: false,
  isAddedTestCts: false,
};

class Unloading1C extends Component {
  constructor(props) {
    super(props);
    this.state = {
      dateBeg: '',
      dateEnd: '',
      signDateBeg: '',
      signDateEnd: '',
      isExecute: false,
      exportPath: '',
      ctsNum: '',
      partnerId: 0,
      statuses: [],
      errors: {},
      touched: {},
      types: [],
    };
  }

  componentDidMount() {
    const {
      getOptions,
      location: { pathname },
      roles,
    } = this.props;
    const access = checkAccessRoute(pathname, roles);
    if (access) getOptions();
  }

  componentWillUnmount() {
    const { resetForm } = this.props;
    resetForm();
  }

  handleChangeExportType = (e, { value }) => {
    const isAllTypes = value.some((type) => exportTypeAll === type);

    if (isAllTypes) {
      this.setState({ types: valuesWithOutAll });

      return null;
    }

    this.setState({
      types: value,
    });

    return null;
  }

  handleChange = ({ target }) => {
    const { value, name } = target;
    this.validate({
      ...this.state,
      [name]: value,
    });
    this.setState({
      [name]: value,
    });
  };

  handleChangeSelect = (name) => (e, { value }) => {
    this.validate({
      ...this.state,
      [name]: value,
    });
    this.setState({
      [name]: value,
    });
  };

  handleFocus = ({ target }) => {
    const { touched } = this.state;
    const { name, value } = target;
    this.validate({
      ...this.state,
      [name]: value,
    });
    this.setState({
      touched: {
        ...touched,
        [name]: true,
      },
    });
  };

  validate = (values) => {
    const { isDownloadFileFrom1C } = this.props;
    try {
      schema(isDownloadFileFrom1C).validateSync(values, { abortEarly: false });
      this.setState({ errors: {} });

      return true;
    } catch (error) {
      this.setState({ errors: getErrorsFromValidationError(error) });

      return false;
    }
  };

  getError = (name) => {
    const { touched, errors, isExecute } = this.state;

    return isExecute || touched[name] ? errors[name] : '';
  };

  execute = () => {
    const {
      exportPath,
      partnerId,
      dateBeg,
      dateEnd,
      signDateBeg,
      signDateEnd,
      statuses,
      ctsNum,
      types,
    } = this.state;
    const {
      export1c,
      history,
      isDownloadFileFrom1C,
      isOverwrite,
      isExportAdditionalForms,
      isAddedTestCts,
    } = this.props;

    this.setState({ isExecute: true });
    if (!this.validate(this.state)) return null;

    const body = isDownloadFileFrom1C ? {
      ctsNum,
      partnerId: partnerId || 0,
      dateBeg: dateBeg ? simpleDate(dateBeg) : '',
      dateEnd: dateEnd ? simpleDate(dateEnd) : '',
      signDateBeg: signDateBeg ? simpleDate(signDateBeg) : '',
      signDateEnd: signDateEnd ? simpleDate(signDateEnd) : '',
      statuses,
      isDownload: isDownloadFileFrom1C,
      isExportAdditionalForms,
      types,
      isAddedTestCts,
    } : {
      ctsNum,
      exportPath,
      partnerId: partnerId || 0,
      dateBeg: dateBeg ? simpleDate(dateBeg) : '',
      dateEnd: dateEnd ? simpleDate(dateEnd) : '',
      signDateBeg: signDateBeg ? simpleDate(signDateBeg) : '',
      signDateEnd: signDateEnd ? simpleDate(signDateEnd) : '',
      statuses,
      overwrite: isOverwrite,
      isDownload: isDownloadFileFrom1C,
      isExportAdditionalForms,
      types,
      isAddedTestCts,
    };
    export1c(body, history, isDownloadFileFrom1C);

    return null;
  };

  handleChangeCheckbox = (name, value) => {
    const { setFileDownload } = this.props;
    setFileDownload(name, !value);
  }

  render() {
    const {
      parentClass,
      statusList,
      partners,
      isDownloadFileFrom1C,
      isExportAdditionalForms,
      isLoading,
      isOverwrite,
      isAddedTestCts,
    } = this.props;
    const {
      dateEnd,
      dateBeg,
      signDateBeg,
      signDateEnd,
      types,
    } = this.state;

    const inputProps = {
      onChange: this.handleChange,
      onFocus: this.handleFocus,
    };

    const typeOptions = types.length === exportTypes.length - 1 ? selectedExportTypes : exportTypes;

    return (
      <div className={parentClass('unloading-1c')}>
        <section className={parentClass('info')}>
          <p>{DESCRIPTION_1C}</p>
        </section>
        <Form className={parentClass('uploading1C').toString()} loading={isLoading}>
          <section className={parentClass('content')}>
            <p className={parentClass('content-header')}>
              {SETTING_EXPORT}
            </p>
            <FieldBlock title={PATCH_EXPORT}>
              <div className={parentClass('field')}>
                <input
                  className={parentClass('input-text').toString()}
                  type="text"
                  name="exportPath"
                  disabled={isDownloadFileFrom1C}
                  {...inputProps}
                />
                <p className={parentClass('error')}>{this.getError('exportPath')}</p>
              </div>
            </FieldBlock>
            <FieldBlock title={UPLOAD_TO_BROWSER}>
              <CustomCheckBox
                onChange={this.handleChangeCheckbox}
                name="isDownloadFileFrom1C"
                checked={isDownloadFileFrom1C}
              />
            </FieldBlock>
            <FieldBlock title={UPLOAD_ADDITIONAL_PRINT_FORM}>
              <CustomCheckBox
                onChange={this.handleChangeCheckbox}
                name="isExportAdditionalForms"
                checked={isExportAdditionalForms}
              />
            </FieldBlock>
            <FieldBlock title={SELECT_PARTNER_ADMIN}>
              <Select
                className={parentClass('select').toString()}
                options={getPartners(partners)}
                placeholder={NOT_CHOOSED}
                {...inputProps}
                onChange={this.handleChangeSelect('partnerId')}
                name="partnerId"
              />
            </FieldBlock>
            <FieldBlock title={SELECT_TYPE_CONTRACTS}>
              <Dropdown
                onChange={this.handleChangeExportType}
                value={types}
                className={parentClass('select').toString()}
                placeholder={NOT_CHOOSED}
                options={typeOptions}
                clearable
                fluid
                multiple
                selection
              />
            </FieldBlock>
            <FieldBlock title={SELECT_PERIOD_ADMIN}>
              <div className={parentClass('date-block')}>
                <div className={parentClass('date')}>
                  {/* eslint-disable-next-line */}
                <label htmlFor="dateBeg" className={parentClass('label')}>
                  <p className={parentClass('label-date')}>{START_DATE}</p>
                  <DateCustomPicker
                    values={{ dateBeg }}
                    name="dateBeg"
                    id="dateBeg"
                    {...inputProps}
                  />
                </label>
                  <p className={parentClass('error')}>{this.getError('dateBeg')}</p>
                </div>
                <div className={parentClass('date')}>
                  {/* eslint-disable-next-line */}
                <label htmlFor="dateEnd" className={parentClass('label')}>
                  <p className={parentClass('label-date')}>{END_DATE}</p>
                  <DateCustomPicker
                    minDate={dateBeg}
                    values={{ dateEnd }}
                    name="dateEnd"
                    id="dateEnd"
                    {...inputProps}
                  />
                </label>
                  <p className={parentClass('error')}>{this.getError('dateEnd')}</p>
                </div>
              </div>
            </FieldBlock>
            <FieldBlock title={SELECT_SIGN_PERIOD_ADMIN}>
              <div className={parentClass('date-block')}>
                <div className={parentClass('date')}>
                  {/* eslint-disable-next-line */}
                <label htmlFor="signDateBeg" className={parentClass('label')}>
                  <p className={parentClass('label-date')}>{START_SIGN_DATE}</p>
                  <DateCustomPicker
                    values={{ signDateBeg }}
                    name="signDateBeg"
                    id="signDateBeg"
                    {...inputProps}
                  />
                </label>
                  <p className={parentClass('error')}>{this.getError('signDateBeg')}</p>
                </div>
                <div className={parentClass('date')}>
                  {/* eslint-disable-next-line */}
                <label htmlFor="signDateEnd" className={parentClass('label')}>
                  <p className={parentClass('label-date')}>{END_SIGN_DATE}</p>
                  <DateCustomPicker
                    minDate={signDateBeg}
                    values={{ signDateEnd }}
                    name="signDateEnd"
                    id="signDateEnd"
                    {...inputProps}
                  />
                </label>
                  <p className={parentClass('error')}>{this.getError('signDateEnd')}</p>
                </div>
              </div>
            </FieldBlock>
            <FieldBlock title={NUMBER_CONTRACT_UPLOAD}>
              <div className={parentClass('field')}>
                <input
                  className={parentClass('input-text').toString()}
                  type="text"
                  {...inputProps}
                  name="ctsNum"
                />
              </div>
            </FieldBlock>
            <FieldBlock title={SELECT_STATUS_ADMIN}>
              <Select
                className={parentClass('select').toString()}
                options={getStatus(statusList, true)}
                multiple
                placeholder={NOT_CHOOSED}
                {...inputProps}
                onChange={this.handleChangeSelect('statuses')}
                name="statuses"
              />
            </FieldBlock>
            <FieldBlock title={REWRITE_FILES}>
              <CustomCheckBox
                onChange={this.handleChangeCheckbox}
                name="isOverwrite"
                checked={isOverwrite}
                disabled={isDownloadFileFrom1C}
              />
            </FieldBlock>
            <FieldBlock title={EXPORT_TEST_CTS}>
              <CustomCheckBox
                onChange={this.handleChangeCheckbox}
                name="isAddedTestCts"
                checked={isAddedTestCts}
              />
            </FieldBlock>
            <FieldBlock title={BEGIN_UNLOADING}>
              <Button
                type="submit"
                className={parentClass('submit').toString()}
                onClick={this.execute}
                disabled={isLoading}
              >
                {EXECUTE}
              </Button>
            </FieldBlock>
          </section>
        </Form>
      </div>
    );
  }
}

const mapDispatchToProps = {
  getOptions: getAdminOptions,
  export1c: export1C,
  setFileDownload: setDownloadFile,
  resetForm: resetTransferDataForm,
};
const mapStateToProps = (
  {
    filtering: {
      statusList,
      partners,
    },
    transferData: {
      isLoadingExport1C,
      isDownloadFileFrom1C,
      isExportAdditionalForms,
      isOverwrite,
      isAddedTestCts,
    },
    authReducer: {
      roles,
    },
  },
) => (
  {
    isDownloadFileFrom1C,
    roles,
    statusList,
    partners,
    isLoading: isLoadingExport1C,
    isExportAdditionalForms,
    isOverwrite,
    isAddedTestCts,
  });

Unloading1C.defaultProps = defaultProps;
Unloading1C.propTypes = propTypes;

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Unloading1C));
