import React from 'react';
import { ROUTES, LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { MainBtn, BreadcrumbsAdmin } from 'components';
import { withCustomRouter } from 'HOC';
import './styles.scss';

const {
  addPartners,
  addSubdivisions,
  addUsers,
  addProducts,
  addReports,
  catalogsSection,
  home,
  publicApi,
} = ROUTES;

const {
  BACK,
  BACK_WORD,
} = LANG_DICTIONARY;

const breadcrumbsBem = block('breadcrumbs-administrative-section');
const bem = block('administrative-section');

const propTypes = {
  history: PropTypes.object,
  children: PropTypes.node,
  active: PropTypes.number,
  buttonInterface: PropTypes.string,
};

const defaultProps = {
  history: {},
  children: null,
  active: 0,
  buttonInterface: 'main',
};

const breadcrumbs = [
  {
    name: 'Партнеры',
    link: addPartners,
    stage: 1,
  },
  {
    name: 'Подразделения',
    link: addSubdivisions,
    stage: 2,
  },
  {
    name: 'Пользователи',
    link: addUsers,
    stage: 3,
  },
  {
    name: 'Отчеты',
    link: addReports,
    stage: 4,
  },
  {
    name: 'Дистрибуция',
    link: addProducts,
    stage: 5,
  },
  {
    name: 'Справочники',
    link: catalogsSection,
    stage: 6,
  },
  {
    name: 'Публичный API',
    link: publicApi,
    stage: 7,
  },
];

const AdministrativeSectionInterface = ({
  children,
  active,
  history,
  buttonInterface,
}) => {
  const handleClickButton = () => {
    if (buttonInterface === 'main') history.push(home);
    if (buttonInterface === 'back') history.goBack({ cache: true });
  };

  return (
    <section className={bem()}>
      <div className={bem('header')}>
        <MainBtn
          onClick={handleClickButton}
          text={buttonInterface === 'back' ? BACK_WORD : BACK}
          className={bem('button').toString()}
        />
        <BreadcrumbsAdmin
          bem={breadcrumbsBem}
          active={active}
          breadcrumbs={breadcrumbs}
          history={history}
        />
      </div>
      <div className={bem('content')}>
        {children}
      </div>
    </section>
  );
};

AdministrativeSectionInterface.propTypes = propTypes;
AdministrativeSectionInterface.defaultProps = defaultProps;
export default withCustomRouter(AdministrativeSectionInterface);
