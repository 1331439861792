import { createSelector } from 'reselect';
import { getCountVisableErrors } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import { PREFIX } from '../enums';

const getDataBeneficiaries = (state) => state.beneficiaries;

// address form
const getBeneficiaryAddressData = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataAddress;
const getBeneficiaryAddressErrors = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataAddressErrors;
const getBeneficiaryAddressTouched = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataAddressTouched;
const getAddressConfigSelector = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].address;

// documents form
const getBeneficiaryDocumentsData = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataDocuments;
const getBeneficiaryDocumentsErrors = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataDocumentsErrors;
const getBeneficiaryDocumentsTouched = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataDocumentsTouched;
const getBeneficiaryDocumentsSnilsErrorSelector = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataDocumentsErrors.snils;
const getDocumentsConfigSelector = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].documents;

// contacts form
const getBeneficiaryContactsData = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataContacts;
const getBeneficiaryContactsErrors = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataContactsErrors;
const getBeneficiaryContactsTouched = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataContactsTouched;
const getContactsConfigSelector = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].contacts;

// main form
const getBeneficiary = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formData;
const getBeneficiaryErrors = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataErrors;
const getBeneficiaryTouched = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].formDataTouched;

const getCountBeneficiaries = ({ beneficiaries: { countBeneficiaries } }) => countBeneficiaries;
const getIsBeneficiaries = ({ beneficiaries: { isBeneficiaries } }) => isBeneficiaries;

const getInsuranceAmountErrorSelector = createSelector(
  getCountBeneficiaries,
  getDataBeneficiaries,
  (count, beneficiaries) => {
    const rebuildBeneficaries = Object.values(beneficiaries).slice(0, count);
    const summ = rebuildBeneficaries.reduce((totalSumm, data) => {
      const currentSum = data.formData.insuredSum;

      return totalSumm + Number(currentSum);
    }, 0);

    return summ !== 100 ? LANG_DICTIONARY.MUST_BE_ONE_HUNDRED : null;
  },
);

const getBeneficiaryFormDataSelector = createSelector(
  getBeneficiary,
  (formData) => formData,
);

const getDataBeneficiariesSelector = createSelector(
  getDataBeneficiaries,
  (beneficiaries) => beneficiaries,
);

const getBeneficiaryDocumentsDataSelector = createSelector(
  getBeneficiaryDocumentsData,
  (formData) => formData,
);

const getBeneficiaryAddressDataSelector = createSelector(
  getBeneficiaryAddressData,
  (formData) => formData,
);

const getBeneficiaryAddressErrorsSelector = createSelector(
  getBeneficiaryAddressErrors,
  (errors) => errors,
);

const getBeneficiaryDocumentsErrorsSelector = createSelector(
  getBeneficiaryDocumentsErrors,
  (errors) => errors,
);

const getBeneficiaryErrorsSelector = createSelector(
  getBeneficiaryErrors,
  (errors) => errors,
);

const getBeneficiaryTouchedSelector = createSelector(
  getBeneficiaryTouched,
  (touched) => touched,
);

const getBeneficiaryDocumentsTouchedSelector = createSelector(
  getBeneficiaryDocumentsTouched,
  (touched) => touched,
);

const getBeneficiaryAddressTouchedSelector = createSelector(
  getBeneficiaryAddressTouched,
  (touched) => touched,
);

const getBeneficiaryDocumentsCountVisableErrors = createSelector(
  getBeneficiaryDocumentsErrors,
  getBeneficiaryDocumentsTouched,
  (errors, touched) => getCountVisableErrors(errors, touched),
);

const getBeneficiaryAddressCountVisableErrors = createSelector(
  getBeneficiaryAddressErrors,
  getBeneficiaryAddressTouched,
  (errors, touched) => getCountVisableErrors(errors, touched),
);

const getBeneficiaryContactsTouchedSelector = createSelector(
  getBeneficiaryContactsTouched,
  (touched) => touched,
);

const getBeneficiaryContactsErrorsSelector = createSelector(
  getBeneficiaryContactsErrors,
  (errors) => errors,
);

const getBeneficiaryContactsCountVisableErrors = createSelector(
  getBeneficiaryContactsErrors,
  getBeneficiaryContactsTouched,
  (errors, touched) => getCountVisableErrors(errors, touched),
);

const getBeneficiaryContactsDataSelector = createSelector(
  getBeneficiaryContactsData,
  (formData) => formData,
);

const getActiveConfigSelector = (beneficiaries, index) => beneficiaries[`${PREFIX}${index}`].active;

export {
  getCountBeneficiaries,
  getIsBeneficiaries,
  getInsuranceAmountErrorSelector,
  getBeneficiaryContactsDataSelector,
  getBeneficiaryContactsErrorsSelector,
  getBeneficiaryContactsTouchedSelector,
  getBeneficiaryContactsCountVisableErrors,
  getBeneficiaryAddressCountVisableErrors,
  getBeneficiaryDocumentsCountVisableErrors,
  getBeneficiaryDocumentsSnilsErrorSelector,
  getBeneficiaryAddressTouchedSelector,
  getBeneficiaryDocumentsTouchedSelector,
  getBeneficiaryTouchedSelector,
  getBeneficiaryAddressErrorsSelector,
  getBeneficiaryDocumentsErrorsSelector,
  getBeneficiaryErrorsSelector,
  getBeneficiaryAddressDataSelector,
  getBeneficiaryDocumentsDataSelector,
  getActiveConfigSelector,
  getDocumentsConfigSelector,
  getAddressConfigSelector,
  getContactsConfigSelector,
  getDataBeneficiariesSelector,
  getBeneficiaryFormDataSelector,
};
