import React from 'react';
import { PopupContainer, MainBtn } from 'components';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import { useSelector } from 'react-redux';
import { selectConstructorTableControlData } from '../../../../../redux/basketConstructor/selectors';

const b = block('popup-guarantee-warning');

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  handleClose: PropTypes.func.isRequired,
  handleContinue: PropTypes.func.isRequired,
  isExecutionLoading: PropTypes.bool.isRequired,
};

const {
  WARNING_GUARANTEE_LEVEL,
  BACK_WORD,
  CONTINUE_WORD,
  ATTENTION,
} = LANG_DICTIONARY;

const WarningGuaranteeLevel = ({
  isOpen,
  handleClose,
  handleContinue,
  isExecutionLoading,
}) => {
  const { guaranteeLevel } = useSelector(selectConstructorTableControlData);

  return (
    <PopupContainer
      isOpen={isOpen}
      close={handleClose}
      isCloseButton
    >
      <div className={b()}>
        <p className={b('title')}>{`${ATTENTION}!`}</p>
        <div className={b('content')}>
          <p>{WARNING_GUARANTEE_LEVEL(guaranteeLevel)}</p>
        </div>
        <div
          className={b('buttons')}
        >
          <MainBtn
            text={BACK_WORD}
            className={b('button')}
            onClick={handleClose}
          />
          <MainBtn
            text={CONTINUE_WORD}
            className={b('button')}
            onClick={handleContinue}
            isLoading={isExecutionLoading}
          />
        </div>
      </div>
    </PopupContainer>
  );
};

WarningGuaranteeLevel.propTypes = propTypes;
export default WarningGuaranteeLevel;
