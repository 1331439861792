import React from 'react';
import { CheckDocumentEditorForm } from 'components';
import { getSwitchList, getProp } from 'helpers';
import { connect } from 'react-redux';
import { UNDERWRITING_STORE_LINK } from 'consts';
import _ from 'lodash';
import PropTypes from 'prop-types';
import {
  changeStatusScan,
  getScanUnderwriting,
  getStatusForScanUnderwriting,
  changeStatusScanSelect,
  changeErrorDescription,
  setBlockRedirect,
} from 'redux/rootActions';

const { contractData: contractDataLink, insurerData: insurerDataLink } = UNDERWRITING_STORE_LINK;

const propTypes = {
  underwriting: PropTypes.object,
  getStatusForScan: PropTypes.func,
  files: PropTypes.object,
  getScan: PropTypes.func,
  changeStatus: PropTypes.func,
  changeStatusSelect: PropTypes.func,
  changeErrorDesc: PropTypes.func,
  listForChecking: PropTypes.arrayOf(PropTypes.number),
  handleSwitch: PropTypes.func,
  currentContractIndex: PropTypes.number,
  statusListForScan: PropTypes.arrayOf(PropTypes.object),
  actionClickYes: PropTypes.func,
  messageClose: PropTypes.string,
  actionClickNo: PropTypes.func,
  history: PropTypes.object,
  handleFinishCheck: PropTypes.func,
  notificationBlockRedirect: PropTypes.bool,
  cancelRedirect: PropTypes.func,
  confirmRedirect: PropTypes.func,
  redirectWarning: PropTypes.bool,
};

const defaultProps = {
  redirectWarning: false,
  underwriting: {},
  getStatusForScan: () => null,
  files: {},
  getScan: () => null,
  changeStatus: () => null,
  changeStatusSelect: () => null,
  changeErrorDesc: () => null,
  listForChecking: [],
  handleSwitch: () => null,
  currentContractIndex: 0,
  statusListForScan: [],
  actionClickYes: () => null,
  messageClose: '',
  actionClickNo: '',
  history: {},
  handleFinishCheck: () => null,
  confirmRedirect: () => null,
  cancelRedirect: () => null,
  notificationBlockRedirect: false,
};

class CheckDocumentEditor extends React.Component {
  constructor(props) {
    super(props);
    const { underwriting } = props;
    const scanId = getProp(underwriting, [contractDataLink, 'scans', 0, 'fileScanId'], null);
    const scanCheckId = getProp(underwriting, [contractDataLink, 'scans', 0, 'fileScanCheckId'], null);
    this.state = {
      prop: contractDataLink,
      index: null,
      scanId,
      scanCheckId,
      zoom: false,
    };
  }

  componentDidMount() {
    const { getStatusForScan, underwriting } = this.props;
    const { scanId } = this.state;
    const fileName = getProp(underwriting, [contractDataLink, 'scans', 0, 'fileName'], null);
    getStatusForScan();

    if (scanId) {
      this.downloadFile(scanId, fileName);
    }
  }

  handleZoomChange = () => {
    this.setState({
      zoom: true,
    });
  }

  handleUnZoomChange = () => {
    this.setState({
      zoom: false,
    });
  }

  getFile = (id) => {
    const { files } = this.props;

    const emptyFile = {
      type: null,
      link: null,
      name: null,
      id: null,
      scanId: null,
    };

    const currentFile = files[id] || emptyFile;

    const {
      type: fileType,
      link: fileLink,
      name: fileName,
      id: ctsId,
    } = currentFile;

    return {
      fileLink,
      fileType,
      fileName,
      ctsId,
      scanId: id,
    };
  };

  downloadFile = (id, fileName) => {
    const { getScan } = this.props;
    getScan(id, fileName);
  };

  setCurrentScan = (scanId, scanCheckId, fileName) => {
    this.downloadFile(scanId, fileName);
    this.setState({ scanId, scanCheckId });
  };

  toSwitchPerson = (prop, index = null) => {
    const { underwriting } = this.props;
    const scan = index === null
      ? underwriting[prop].scans[0]
      : underwriting[prop][index].scans[0];
    const contrAgent = index === null
      ? underwriting[prop]
      : underwriting[prop][index];
    const contrAgentAddress = _.get(contrAgent, 'ctgtsAddr.0.address');
    const scanId = scan && scan.fileScanId;
    const scanCheckId = scan && scan.fileScanCheckId;
    const fileName = scan && scan.fileName;
    this.setState({
      prop,
      index,
      scanId,
      scanCheckId,
      contrAgentAddress,
    });
    if (scanId) this.downloadFile(scanId, fileName);
  };

  changeStatusScan = (indexScan, status) => {
    const { prop, index: indexPerson } = this.state;
    const {
      changeStatus,
    } = this.props;
    changeStatus({
      indexScan,
      status,
      prop,
      indexPerson,
    });
  };

  changeStatusScanSelect = (indexScan, status, saveOnChanged = true) => {
    const { prop, index: indexPerson } = this.state;
    const { changeStatusSelect } = this.props;

    changeStatusSelect({
      indexScan,
      status,
      prop,
      indexPerson,
      saveOnChanged,
    });
  };

  handleBlurErrorDesc = (indexScan, desc) => {
    const { prop, index: indexPerson } = this.state;
    const { changeErrorDesc } = this.props;
    changeErrorDesc({
      indexScan,
      desc,
      prop,
      indexPerson,
    });
  };

  render() {
    const {
      underwriting,
      underwriting: {
        checkingData,
        contractData,
        insurerData,
      },
      files: {
        loading,
      },
      listForChecking,
      handleSwitch,
      currentContractIndex,
      statusListForScan,
      actionClickYes,
      messageClose,
      actionClickNo,
      history,
      handleFinishCheck,
      notificationBlockRedirect,
      cancelRedirect,
      confirmRedirect,
      redirectWarning,
    } = this.props;
    const {
      prop,
      index,
      scanId,
      scanCheckId,
      zoom,
      contrAgentAddress,
    } = this.state;
    const currentData = index || index === 0 ? underwriting[prop][index] : underwriting[prop];
    const personData = prop === contractDataLink ? underwriting[insurerDataLink] : currentData;
    const { scans } = currentData;
    const { ctgtsFio: insurerName } = insurerData;

    return (
      <CheckDocumentEditorForm
        cancelRedirect={cancelRedirect}
        changeStatusScan={this.changeStatusScan}
        changeStatusScanSelect={this.changeStatusScanSelect}
        checkingData={checkingData}
        confirmRedirect={confirmRedirect}
        contractData={contractData}
        currentContractIndex={currentContractIndex}
        currentScan={scanId}
        currentScanCheck={scanCheckId}
        handleBlurErrorDesc={this.handleBlurErrorDesc}
        handleClickNo={actionClickNo}
        handleClickYes={actionClickYes}
        handleFinishCheck={handleFinishCheck}
        handleSwitch={handleSwitch}
        history={history}
        indexPerson={index}
        insurerName={insurerName}
        lengthListCheck={listForChecking.length}
        loadingFile={loading}
        messageClose={messageClose}
        notificationBlockRedirect={notificationBlockRedirect}
        personData={personData}
        redirectWarning={redirectWarning}
        scans={scans}
        setCurrentScan={this.setCurrentScan}
        statusListForScan={statusListForScan}
        switchList={getSwitchList(underwriting)}
        toSwitchPerson={this.toSwitchPerson}
        handleZoomChange={this.handleZoomChange}
        zoom={zoom}
        handleUnZoomChange={this.handleUnZoomChange}
        contrAgentAddress={contrAgentAddress}
        {...this.getFile(scanId)}
      />
    );
  }
}

const mapStateToProps = ({
  underwriting,
  files,
  underwriting: {
    loading,
  },
  underwritingMaster: {
    listForChecking,
    statusListForScan,
  },
  shared: {
    redirectWarning,
  },
}) => ({
  redirectWarning,
  statusListForScan,
  underwriting,
  files,
  listForChecking,
  loading,
});
const mapDispatchToProps = {
  changeStatus: changeStatusScan,
  changeStatusSelect: changeStatusScanSelect,
  changeErrorDesc: changeErrorDescription,
  getScan: getScanUnderwriting,
  getStatusForScan: getStatusForScanUnderwriting,
  setBlockRedirectApp: setBlockRedirect,
};

CheckDocumentEditor.defaultProps = defaultProps;
CheckDocumentEditor.propTypes = propTypes;
export default connect(mapStateToProps, mapDispatchToProps)(CheckDocumentEditor);
