import React from 'react';
import { block } from 'bem-cn';
import { getFormattedDate } from 'helpers';
import { Row, Col } from 'reactstrap';
import { LANG_DICTIONARY, TYPE_CHECKING_CONTRACT_RU } from 'consts';
import PropTypes from 'prop-types';
import ProductColumn from './productColumn';
import './styles.scss';

const propTyeps = {
  contractData: PropTypes.object,
  insurerData: PropTypes.object,
  checkingData: PropTypes.object,
  handleOpenInfo: PropTypes.func,
  showInfo: PropTypes.bool,
  isShowHeader: PropTypes.bool,
};

const defaultProps = {
  contractData: {},
  insurerData: {},
  checkingData: {},
  handleOpenInfo: () => null,
  showInfo: false,
  isShowHeader: false,
};

const b = block('underwriting-product-row');
const {
  NUMBER,
  INSURER,
  DATE_CONTRACT,
  STATUS_CONTRACT,
  SUBDIVISION,
  STATUS_CHECK,
  DATE_CHECK,
  CHECKER,
} = LANG_DICTIONARY;

const ProductRow = (props) => {
  const {
    contractData: {
      ctsNum,
      ctsDateCreate,
      ctsBranchName,
      ctsId,
      status,
    },
    insurerData: {
      ctgtsFio,
    },
    checkingData: {
      lastCheckDateTime,
      lastCheckUserFio,
      checkStatusGeneral,
    },
    handleOpenInfo,
    showInfo,
    isShowHeader,
  } = props;

  const iconInfo = showInfo ? <p className={b('icon-arrow', { down: true })}> &gt; </p>
    : <p className={b('icon-arrow')}> &gt; </p>;

  return (
    <>
      <div className={b()}>
        <div
          className={b('arrow')}
          onClick={() => handleOpenInfo(ctsId)}
          role="button"
          tabIndex="-1"
        >
          <ProductColumn
            firstRow=""
            secondRow={iconInfo}
            isShowHeader={isShowHeader}
          />
        </div>
        <Row>
          <Col md="2">
            <ProductColumn
              firstRow={NUMBER}
              secondRow={ctsNum}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="2" sm="12">
            <ProductColumn
              firstRow={INSURER}
              secondRow={ctgtsFio}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="1">
            <ProductColumn
              firstRow={DATE_CONTRACT}
              secondRow={getFormattedDate(ctsDateCreate)}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="1">
            <ProductColumn
              firstRow={STATUS_CONTRACT}
              secondRow={status}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="2">
            <ProductColumn
              firstRow={SUBDIVISION}
              secondRow={ctsBranchName}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="1">
            <ProductColumn
              firstRow={STATUS_CHECK}
              secondRow={TYPE_CHECKING_CONTRACT_RU[checkStatusGeneral]}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="1">
            <ProductColumn
              firstRow={DATE_CHECK}
              secondRow={lastCheckDateTime ? getFormattedDate(lastCheckDateTime) : '-'}
              isShowHeader={isShowHeader}
            />
          </Col>
          <Col md="2">
            <ProductColumn
              firstRow={CHECKER}
              secondRow={lastCheckUserFio || '-'}
              isShowHeader={isShowHeader}
            />
          </Col>
        </Row>
      </div>
    </>
  );
};

ProductRow.defaultProps = defaultProps;
ProductRow.propTypes = propTyeps;
export default ProductRow;
