import React, { Component } from 'react';
import { withCustomRouter } from 'HOC';
import { AdministrativeSectionInterface, CreatingOrUpdatingUserForm } from 'components';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import {
  createUser,
  getUserDataForUpdate,
  updateUser,
  clearEditorAdminUsersForm,
  selectOptionUser,
  getOptionsForPartnerSelectUser,
  getOptionsForBranchSelectUser,
  lockUser,
  unlockUser,
  getUsersRoles,
  setUserOption,
  setBranchesByPartner,
  getLoginForUser,
  getPasswordForUser,
  setWebsiteListedStatusTrue,
  setWebsiteListedStatusFalse,
  setWebsiteLockedStatusTrue,
  setWebsiteLockedStatusFalse,
  setValueForUser,
  sendUserCredentials,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';

const propTypes = {
  match: PropTypes.object,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  history: PropTypes.object,
  location: PropTypes.object,
  userFormData: PropTypes.object,
  lockCurrentUser: PropTypes.func,
  unlockCurrentUser: PropTypes.func,
  userspartners: PropTypes.arrayOf(PropTypes.object),
  usersbranches: PropTypes.arrayOf(PropTypes.object),
  setUserOptions: PropTypes.func,
  getUserData: PropTypes.func,
  usersroles: PropTypes.arrayOf(PropTypes.object),
  setBranchesWithPartner: PropTypes.func,
  partnerbranches: PropTypes.arrayOf(PropTypes.object),
  clearForm: PropTypes.func,
  getPartners: PropTypes.func,
  getBranches: PropTypes.func,
  getRoles: PropTypes.func,
  getUserLogin: PropTypes.func,
  getUserPassword: PropTypes.func,
  createNewUser: PropTypes.func,
  updateUserById: PropTypes.func,
  setWebSiteStatusTrue: PropTypes.func,
  setWebSiteStatusFalse: PropTypes.func,
  setLockedTrue: PropTypes.func,
  setLockedFalse: PropTypes.func,
  setValue: PropTypes.func,
  sendCredentials: PropTypes.func,
  isSendCredentialsLoading: PropTypes.bool,
  gurole: PropTypes.array,
};

const defaultProps = {
  setValue: () => null,
  match: {},
  clearForm: () => null,
  active: '',
  history: {},
  location: {},
  userFormData: {},
  lockCurrentUser: () => null,
  unlockCurrentUser: () => null,
  userspartners: [],
  usersbranches: [],
  setUserOptions: () => null,
  getUserData: () => null,
  usersroles: [],
  setBranchesWithPartner: () => null,
  partnerbranches: [],
  getPartners: () => null,
  getBranches: () => null,
  getRoles: () => null,
  getUserLogin: () => null,
  getUserPassword: () => null,
  createNewUser: () => null,
  updateUserById: () => null,
  setWebSiteStatusTrue: () => null,
  setWebSiteStatusFalse: () => null,
  setLockedTrue: () => null,
  setLockedFalse: () => null,
  sendCredentials: () => null,
  isSendCredentialsLoading: false,
  gurole: [],
};

const { ENTER_FIRSTNAME, ENTER_LASTNAME } = LANG_DICTIONARY;
class CreatingOrUpdatingUser extends Component {
  constructor(props) {
    super(props);
    const { match: { params: { id } } } = props;
    this.selectPrefix = 'update';
    this.updatedUser = id || null;
    this.handleSubmitSuccess = id ? this.updateUser : this.createUser;

    this.state = {
      errorsAffiliations: {},
    };
  }

  componentDidMount() {
    const {
      getUserData, getPartners, getBranches, getRoles,
    } = this.props;
    const { location: { state } } = this.props;
    const id = state && state.id;
    if (this.updatedUser) {
      getUserData(this.updatedUser || id);
    }
    getRoles();
    getPartners(null, 'users');
    getBranches(null, 'users');
  }

  componentWillUnmount() {
    const { clearForm } = this.props;
    clearForm();
  }

  getBodyRequest = (values) => {
    const {
      login,
      password,
      lastName,
      firstName,
      middleName,
      birthDate,
      email,
      mobilePhone,
      snils,
      partnerUid,
      partnerEnum,
      locked,
      webSiteListed,
      affiliations,
      isEmployee,
    } = values;

    return {
      login,
      password,
      lastName,
      firstName,
      middleName,
      birthDate,
      email,
      mobilePhone,
      snils,
      partnerUid,
      partnerEnum,
      locked,
      webSiteListed,
      affiliations,
      isEmployee,
    };
  };

  getLogin = (values) => {
    const { middleName, firstName, lastName } = values;
    const { getUserLogin } = this.props;
    const loginData = {};
    if (firstName.length === 0) {
      toastr.warning(ENTER_FIRSTNAME);
    } else if (lastName.length === 0) {
      toastr.warning(ENTER_LASTNAME);
    } else if (middleName.length !== 0) {
      loginData.middleName = middleName;
      loginData.firstName = firstName;
      loginData.lastName = lastName;
      getUserLogin(loginData, values);
    } else {
      loginData.firstName = firstName;
      loginData.lastName = lastName;
      getUserLogin(loginData, values);
    }
  }

  getPassword = (values) => {
    const { getUserPassword } = this.props;
    getUserPassword(values);
  }

  // eslint-disable-next-line consistent-return
  createUser = (values) => {
    const { createNewUser, clearForm, history } = this.props;

    const composeErrors = {};
    Object.keys(values.affiliations).forEach((key) => {
      const isErrorPartner = !values.affiliations[key].partnerId;
      const isErrorBranch = !values.affiliations[key].branchId;
      const isErrorRole = !values.affiliations[key].roleId;
      if (isErrorPartner) {
        composeErrors[key] = (`Настройте партнера для ${+key + 1}-ой ролевой зависимости`);
      } else if (isErrorBranch) {
        composeErrors[key] = (`Настройте подразделение для ${+key + 1}-ой ролевой зависимости`);
      } else if (isErrorRole) {
        composeErrors[key] = (`Настройте роль у ${+key + 1}-ой ролевой зависимости`);
      }
    });
    this.setState({
      errorsAffiliations: { ...composeErrors },
    });
    const newAffiiations = _.uniqBy(values.affiliations, 'branchId');
    const countIsDefaut = newAffiiations.reduce((prev, item) => {
      if (item.isDefault) {
        return prev + 1;
      }

      return prev;
    }, 0);

    if (countIsDefaut > 1) {
      return toastr.warning('Выберите только одно подразделение по умолчанию');
    }

    if (countIsDefaut === 0) {
      return toastr.warning('Выберите одно подразделение по умолчанию');
    }
    if (Object.keys(composeErrors).length) {
      toastr.warning('Настройте ролевые зависимости');
    } else {
      const body = this.getBodyRequest(values);
      createNewUser(body, history);
      clearForm();
    }
  };

  // eslint-disable-next-line consistent-return
  updateUser = (values) => {
    const { updateUserById } = this.props;

    const composeErrors = {};
    Object.keys(values.affiliations).forEach((key) => {
      const isErrorPartner = !values.affiliations[key].partnerId;
      const isErrorBranch = !values.affiliations[key].branchId;
      const isErrorRole = !values.affiliations[key].roleId;
      if (isErrorPartner) {
        composeErrors[key] = (`Настройте партнера для ${+key + 1}-ой ролевой зависимости`);
      } else if (isErrorBranch) {
        composeErrors[key] = (`Настройте подразделение для ${+key + 1}-ой ролевой зависимости`);
      } else if (isErrorRole) {
        composeErrors[key] = (`Настройте роль у ${+key + 1}-ой ролевой зависимости`);
      }
    });
    this.setState({
      errorsAffiliations: { ...composeErrors },
    });
    const newAffiiations = _.uniqBy(values.affiliations, 'branchId');
    const countIsDefaut = newAffiiations.reduce((prev, item) => {
      if (item.isDefault) {
        return prev + 1;
      }

      return prev;
    }, 0);

    if (countIsDefaut > 1) {
      return toastr.warning('Выберите только одно подразделение по умолчанию');
    }

    if (countIsDefaut === 0) {
      return toastr.warning('Выберите одно подразделение по умолчанию');
    }
    if (Object.keys(composeErrors).length) {
      toastr.warning('Настройте ролевые зависимости');
    } else {
      const body = this.getBodyRequest(values);
      updateUserById(this.updatedUser, body);
    }
  }

  handleChangeValue = (name, value) => {
    const { setValue } = this.props;
    setValue(name, value);
  }

  render() {
    const {
      active,
      history,
      userFormData,
      lockCurrentUser,
      unlockCurrentUser,
      userspartners,
      usersbranches,
      setUserOptions,
      getUserData,
      usersroles,
      setBranchesWithPartner,
      partnerbranches,
      setWebSiteStatusTrue,
      setWebSiteStatusFalse,
      setLockedTrue,
      setLockedFalse,
      sendCredentials,
      isSendCredentialsLoading,
      gurole,
    } = this.props;
    const { errorsAffiliations } = this.state;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <CreatingOrUpdatingUserForm
          history={history}
          handleSubmitSuccess={this.handleSubmitSuccess}
          isUpdatedForm={Boolean(this.updatedUser)}
          initialValues={userFormData}
          lockUser={lockCurrentUser}
          unlockUser={unlockCurrentUser}
          partners={userspartners}
          branches={usersbranches}
          setOption={setUserOptions}
          getUserData={getUserData}
          usersroles={usersroles}
          setBranchesWithPartner={setBranchesWithPartner}
          partnerbranches={partnerbranches}
          getLogin={this.getLogin}
          getPassword={this.getPassword}
          setWebSiteStatusTrue={setWebSiteStatusTrue}
          setWebSiteStatusFalse={setWebSiteStatusFalse}
          setLockedTrue={setLockedTrue}
          setLockedFalse={setLockedFalse}
          errorsAffiliations={errorsAffiliations}
          handleChangeValue={this.handleChangeValue}
          sendUserCredentials={sendCredentials}
          isCredentialsLoading={isSendCredentialsLoading}
          gurole={gurole}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  usersAdminInterface: {
    userFormData,
    userspartners,
    usersbranches,
    usersroles,
    partnerbranches,
    isSendCredentialsLoading,
  },
  authReducer: {
    gurole,
  },
  selects,
}) => ({
  userFormData,
  userspartners,
  usersbranches,
  usersroles,
  partnerbranches,
  isSendCredentialsLoading,
  gurole,
  ...selects,
});

const mapDispatchToProps = {
  createNewUser: createUser,
  updateUserById: updateUser,
  getUserData: getUserDataForUpdate,
  clearForm: clearEditorAdminUsersForm,
  lockCurrentUser: lockUser,
  unlockCurrentUser: unlockUser,
  getPartners: getOptionsForPartnerSelectUser,
  setOption: selectOptionUser,
  getBranches: getOptionsForBranchSelectUser,
  getRoles: getUsersRoles,
  setUserOptions: setUserOption,
  setBranchesWithPartner: setBranchesByPartner,
  getUserLogin: getLoginForUser,
  getUserPassword: getPasswordForUser,
  setWebSiteStatusTrue: setWebsiteListedStatusTrue,
  setWebSiteStatusFalse: setWebsiteListedStatusFalse,
  setLockedTrue: setWebsiteLockedStatusTrue,
  setLockedFalse: setWebsiteLockedStatusFalse,
  setValue: setValueForUser,
  sendCredentials: sendUserCredentials,
};

CreatingOrUpdatingUser.propTypes = propTypes;
CreatingOrUpdatingUser.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatingOrUpdatingUser),
);
