import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer, MainBtn } from 'components';
import '../StopJobPopUp/styles.scss';

const {
  ATTENTION,
  ANNUL_CONTRACT,
  YES,
  BACK_WORD,
} = LANG_DICTIONARY;

const b = block('scheduler-stop-job');
const propTypes = {
  isOpen: PropTypes.bool,
  setAnnulStatusContract: PropTypes.func,
  openPopup: PropTypes.func,
  ctsId: PropTypes.string,
};
const defaultProps = {
  isOpen: false,
  setAnnulStatusContract: () => null,
  openPopup: () => null,
  ctsId: '',
};

const AnnulContractWarning = (props) => {
  const {
    isOpen,
    setAnnulStatusContract,
    openPopup,
    ctsId,
  } = props;

  const confrimPopUp = (
    <div className={b()}>
      <div className={b('header')}>{`${ATTENTION}!`}</div>
      <div className={b('content')}>{ANNUL_CONTRACT}</div>
      <div className={b('footer')}>
        <MainBtn
          text={YES}
          className={b('confirm-button')}
          onClick={() => {
            setAnnulStatusContract(ctsId);
            openPopup(!isOpen);
          }}
        />
        <MainBtn
          text={BACK_WORD}
          className={b('cancel-button')}
          onClick={() => openPopup(!isOpen)}
        />
      </div>
    </div>
  );

  return (
    <PopupContainer
      isOpen={isOpen}
      children={confrimPopUp}
    />
  );
};

AnnulContractWarning.propTypes = propTypes;
AnnulContractWarning.defaultProps = defaultProps;
export default AnnulContractWarning;
