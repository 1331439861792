const SET_FIELD_VALUE = 'beneficiaries/SET_FIELD_VALUE';
const SET_STATUS_TAB = 'beneficiaries/SET_STATUS_TAB';
const SET_ACTIVE_TAB = 'beneficiaries/SET_ACTIVE_TAB';
const SET_SHARED_COUNT_ERROR = 'beneficiaries/SET_SHARED_COUNT_ERROR';
const SET_ERRORS = 'beneficiaries/SET_ERRORS';
const SET_TOUCHED = 'beneficiaries/SET_TOUCHED';
const SET_ADDRESS = 'beneficiaries/SET_ADDRESS';
const SET_LIVING_ADDRESS_IS_SAME = 'beneficiaries/SET_LIVING_ADDRESS_IS_SAME';
const SET_ALL_TOUCHED = 'beneficiaries/SET_ALL_TOUCHED';
const SET_COUNT_BENEFICIARIES = 'beneficiaries/SET_COUNT_BENEFICIARIES';
const SET_IS_BENEFICIARIES = 'beneficiaries/SET_IS_BENEFICIARIES';
const SET_INIT_FORM = 'beneficiaries/SET_INIT_FORM';
const SET_FMS_UNIT = 'beneficiaries/SET_FMS_UNIT';

export {
  SET_COUNT_BENEFICIARIES,
  SET_IS_BENEFICIARIES,
  SET_INIT_FORM,
  SET_ALL_TOUCHED,
  SET_LIVING_ADDRESS_IS_SAME,
  SET_ADDRESS,
  SET_FMS_UNIT,
  SET_TOUCHED,
  SET_ERRORS,
  SET_SHARED_COUNT_ERROR,
  SET_ACTIVE_TAB,
  SET_FIELD_VALUE,
  SET_STATUS_TAB,
};
