import React, { memo } from 'react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { getStatusContract, getDateAndTimeUtc } from 'helpers';
import { MainBtn } from 'components';
import PropTypes from 'prop-types';

const propTypes = {
  parentClass: PropTypes.func,
  checkDateTime: PropTypes.string,
  checkStatusGeneral: PropTypes.string,
  checkUserFio: PropTypes.string,
  lengthListCheck: PropTypes.number,
  currentContractIndex: PropTypes.number,
  history: PropTypes.object,
};

const defaultProps = {
  parentClass: () => null,
  checkDateTime: '',
  checkStatusGeneral: '',
  checkUserFio: '',
  lengthListCheck: 0,
  currentContractIndex: 0,
  history: {},
};

const {
  CHECK_MASTER,
  STATUS_CHECK,
  DATE_AND_TIME_CHECK,
  CHECKER,
  SET_OF,
} = LANG_DICTIONARY;

const HeaderForm = ({
  parentClass,
  checkDateTime,
  checkStatusGeneral,
  checkUserFio,
  lengthListCheck,
  currentContractIndex,
  history,
}) => {
  const { title: status, color: colorStatus } = getStatusContract(checkStatusGeneral);
  const checker = checkUserFio || 'Отсутствует';

  return (
    <div className={parentClass('content-header')}>
      <div className={parentClass('header-row')}>
        <div className={parentClass('controls')}>
          <div className={parentClass('btn')}>
            <MainBtn
              text={LANG_DICTIONARY.BACK_PAGE}
              className={parentClass('back-btn').toString()}
              onClick={() => history.push(ROUTES.checkMaster)}
            />
          </div>
          <p className={parentClass('content-header-title', { active: true })}>
            {CHECK_MASTER}
          </p>
        </div>
        <div className={parentClass('status')}>
          <p className={parentClass('content-header-title')}>{`${STATUS_CHECK}:`}</p>
          <p className={parentClass('status-check', { [colorStatus]: true })}>{status}</p>
        </div>
      </div>
      <div className={parentClass('header-row')}>
        <div className={parentClass('title-wrapper')}>
          <p className={parentClass('content-header-title')}>
            {`${DATE_AND_TIME_CHECK}: ${getDateAndTimeUtc(checkDateTime)}`}
          </p>
          <p className={parentClass('content-header-title')}>
            {`${CHECKER}: ${checker}`}
          </p>
        </div>
        <div className={parentClass('count-contract-title')}>
          <p className={parentClass('bold-title')}>{currentContractIndex}</p>
          <p>{SET_OF}</p>
          <p className={parentClass('bold-title')}>{lengthListCheck}</p>
        </div>
      </div>
    </div>
  );
};

HeaderForm.propTypes = propTypes;
HeaderForm.defaultProps = defaultProps;
export default memo(HeaderForm);
