import { getActiveDistributingByDate } from 'helpers';
import {
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS,
  GET_SELECTS_FOR_PRODUCTS_SUCCESS,
  SET_OPTION_FOR_PRODUCT,
  SET_STATUS_FOR_ALL_PRODUCTS,
  CANCEL_ALL_CHANGES,
  CLEAR_FORM,
  SET_SELECT,
} from '../types';

const INITIAL_STATE = {
  partners: [],
  products: [],
  initialData: [],
  tableInfo: [],
  currentPartner: null,
  currentProduct: null,
  currentPage: 1,
  totalPages: 1,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_SELECT: {
      const { name, value } = payload;

      return {
        ...state,
        [name]: value,
      };
    }

    case GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS: {
      const { data } = payload;
      const newData = data ? data.map((item) => ({
        id: item.id,
        branchName: item.branchName,
        fullTreeName: item.branchTreeName,
        active: item.isActive,
        dataStart: item.dateBeg ? new Date(item.dateBeg) : null,
        dataEnd: item.dateEnd ? new Date(item.dateEnd) : null,
        dateStartHave: `${item.dateBeg}` || '',
        dateEndtHave: `${item.dateEnd}` || '',
      })) : [];

      return {
        ...state,
        initialData: newData,
        tableInfo: newData,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
      };
    }

    case GET_SELECTS_FOR_PRODUCTS_SUCCESS: {
      const { partners, products } = state;

      return {
        ...state,
        partners: payload.partners || partners,
        products: payload.products || products,
      };
    }

    case SET_OPTION_FOR_PRODUCT: {
      const { tableInfo } = state;
      const { product, name, value } = payload;
      const newTable = tableInfo.map((item, index) => {
        if (index === product) {
          if (name === 'dataStart') {
            return ({
              ...item,
              [name]: value,
              active: getActiveDistributingByDate(value, item.dataEnd),
            });
          }
          if (name === 'dataEnd') {
            return ({
              ...item,
              [name]: value,
              active: getActiveDistributingByDate(item.dataStart, value),
            });
          }
          if (name === 'active' && value) {
            return ({
              ...item,
              [name]: value,
              dataStart: new Date(),
            });
          }
          if (name === 'active' && !value) {
            return ({
              ...item,
              [name]: value,
              dataEnd: new Date(),
            });
          }

          return ({
            ...item,
            [name]: value,
          });
        }

        return ({
          ...item,
        });
      });

      return {
        ...state,
        tableInfo: newTable,
      };
    }

    case CANCEL_ALL_CHANGES: {
      const { initialData } = state;

      return {
        ...state,
        tableInfo: initialData,
      };
    }

    case CLEAR_FORM: {
      return {
        ...INITIAL_STATE,
      };
    }

    case SET_STATUS_FOR_ALL_PRODUCTS: {
      const { tableInfo } = state;
      const newStatuses = tableInfo.map((item) => {
        if (payload) {
          return ({
            ...item,
            active: payload,
            dataStart: new Date(),
            dataEnd: null,
          });
        }

        return ({
          ...item,
          active: payload,
          dataStart: null,
          dataEnd: new Date(),
        });
      });

      return {
        ...state,
        tableInfo: newStatuses,
      };
    }
    default:
      return state;
  }
};
