import _ from 'lodash';
import moment from 'moment';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import {
  SYSTEM_DATE_TO,
  DATE_FORMAT,
  INPUT_NAMES,
  REGEXP,
  LANG_DICTIONARY,
  TIME_FORMAT,
} from 'consts';

const {
  bankBic,
  bankInn,
  bankKpp,
  bankName,
  correctionAccount,
  issuedBy,
  kp,
} = INPUT_NAMES;

const {
  ONLY_NUMBERS_AND_COLON,
  SPACE_NUMBER,
} = REGEXP;

const {
  ALL_BUT_NUMBERS,
  FIRST_CHAR,
  SQUARE_BRACKETS,
  ROUTE_TEMPLATE,
  NUMBER_UP_TO_TWO_DECIMAL,
} = REGEXP;

const {
  REQUIRED_LABEL,
} = LANG_DICTIONARY;

const changeBeneficiaryForm = (
  beneficiaries,
  {
    count,
    setValues,
    values,
    valueList,
  },
) => {
  const valueSize = _.size(values);
  const updatedForm = { ...values };

  if (valueSize > count) {
    for (let i = count; i < valueSize; i += 1) {
      delete updatedForm[i];
    }
  } else {
    const diff = count - valueSize;

    for (let i = 0; i < diff; i += 1) {
      /* eslint-disable-next-line */
      updatedForm[valueSize + i] = valueList[0];
    }
  }

  setValues(updatedForm);
};

const setFieldValues = (valueList, setFieldValue, resetFields) => {
  /* eslint-disable-next-line */
  for (const index in valueList) {
    setFieldValue(valueList[index], '');
  }

  if (resetFields && resetFields.length > 0) {
    resetFields.forEach((item) => {
      const { field, value } = item;
      setFieldValue(field, value);
    });
  }
};

const setSomeFieldsValues = (valueList, setFieldValue) => {
  /* eslint-disable-next-line */
  for (const index in valueList) {
    setFieldValue(index, valueList[index] || '');
  }
};

const getFormInputClass = ({
  error,
  touched,
  width,
  wrapClass,
  isVisableError,
}) => classNames(
  width,
  wrapClass,
  { formInput: true, error: (error && touched) || isVisableError },
);

const getInputProps = ({ inputProps, modifiedProps }, name) => ({
  ...(inputProps
    || modifiedProps(name)),
});

const getFormattedDate = (date) => date && moment(date).format(DATE_FORMAT);
const getFormattedTime = (date) => moment(date).format(TIME_FORMAT);

const getUtcFormattedDate = (date) => date && moment(date).utc().format(DATE_FORMAT);

const stringToBool = (value) => {
  if (value === 'true' || value === 'false') {
    return value === 'true';
  }

  return value;
};

const stringToDate = (date) => moment(date, DATE_FORMAT).toISOString();

const checkForEmpty = (value) => value || '';
const checkEmptyObj = (value) => value || {};

const getAddress = (fiasValues, isLiving, isChild) => {
  const prefix = isChild ? 'child' : '';
  const living = isLiving ? 'Living' : '';
  const {
    unrestricted_value: unrestrictedValue,
    value,
    data: {
      country,
      postal_code: postalCode,
      region,
      street_with_type: streetWithType,
      settlement_with_type: settlementWithType,
      city_with_type: cityWithType,
      house,
      house_type: houseType,
      flat,
      flat_type: flatType,
      block,
      block_type: blockType,
      area_with_type: area,
      fias_id: fiasId,
      kladr_id: kladId,
      region_type: regionType,
    },
  } = fiasValues;

  const onlyFias = {
    data: fiasValues.data,
    unrestricted_value: unrestrictedValue,
    value,
  };

  const city = cityWithType || settlementWithType;

  return {
    [`${prefix}addressFias${living}`]: checkEmptyObj(onlyFias),
    [`${prefix}country${living}`]: checkForEmpty(country),
    [`${prefix}index${living}`]: checkForEmpty(postalCode),
    [`${prefix}republic${living}`]: region ? `${regionType} ${region}` : '',
    [`${prefix}area${living}`]: checkForEmpty(area),
    [`${prefix}city${living}`]: checkForEmpty(city),
    [`${prefix}street${living}`]: checkForEmpty(streetWithType),
    [`${prefix}house${living}`]: house ? `${houseType} ${house}` : '',
    [`${prefix}houseNumber${living}`]: block ? `${blockType} ${block}` : '',
    [`${prefix}apartment${living}`]: flat ? `${flatType} ${flat}` : '',
    [`${prefix}fiasId${living}`]: fiasId || '0',
    [`${prefix}kladId${living}`]: kladId || '0',
  };
};

const getFmsUnitTitle = (data) => {
  const { value, data: { code } } = data;

  return `${code} | ${value}`;
};

const getFmsUnitList = (fiasValues) => {
  const {
    name,
    code,
  } = fiasValues;

  return {
    [issuedBy]: checkForEmpty(name),
    [kp]: checkForEmpty(code),
  };
};

const getAdressString = (data) => {
  const keysValues = data && Object.keys(data);

  return keysValues.reduce((acc, value) => `${acc} ${data[`${value}`] || ''}`, '');
};

const getOptionsHouseNumber = (data) => {
  const options = data && data.map((value) => ({
    key: value.title,
    text: getAdressString({
      block_type_full: value.data.block_type_full,
      block: value.data.block,
    }),
    value,
  }));

  return options || [];
};

const getBankList = (fiasValues) => {
  const {
    bic,
    inn,
    kpp,
    name: { payment: namePayment },
    correspondent_account: corAccount,
  } = fiasValues;

  return {
    [bankBic]: checkForEmpty(bic),
    [bankInn]: checkForEmpty(inn),
    [bankKpp]: checkForEmpty(kpp),
    [bankName]: checkForEmpty(namePayment),
    [correctionAccount]: checkForEmpty(corAccount),
  };
};

const getFormat = (format) => /[^/]*$/.exec(format)[0];

const removeIfNeed = (item, typeId) => typeId && _.remove(item, (id) => id === typeId);

const setInitialValues = (valueList) => {
  let initialValues = {};

  /* eslint-disable-next-line */
  for (const index in valueList) {
    initialValues = { ...initialValues, ...{ [valueList[index]]: '' } };
  }

  return initialValues;
};

const getPropName = (name) => name.split('.')[1];

const checkDataEmpty = (data) => {
  const newData = {};
  /* eslint-disable-next-line */
  for (let key in data) {
    if (data[key]) newData[key] = data[key];
  }

  return newData;
};

const simpleDate = (date) => moment(date, DATE_FORMAT).format(SYSTEM_DATE_TO);
const getDateForPicker = (date) => moment(date).format(DATE_FORMAT);

const numberOrStringTypes = (...args) => {
  const typeObject = {};
  args.forEach((item) => {
    typeObject[item] = PropTypes.oneOfType([
      PropTypes.string,
      PropTypes.number,
    ]);
  });

  return typeObject;
};

const getPropsName = (values, index) => Object.keys(values).reduce((newValues, key) => {
  const indexValue = key.split('.')[0].replace(SQUARE_BRACKETS, '');
  if (+indexValue === index) {
    const keyValue = getPropName(key);

    return {
      ...newValues,
      [keyValue]: values[key],
    };
  }

  return {
    ...newValues,
    [key]: values[key],
  };
}, {});

const getCopyValues = (fieldList, values) => (
  fieldList.reduce((acc, field) => ({ ...acc, [field]: values[field] }), {})
);

const onlyNumberAndColon = (value) => value.replace(ONLY_NUMBERS_AND_COLON, '');

const checkObjectEmpty = (object) => Object.keys(object).some((item) => item);

const replaceAllButNumbers = (value) => String(value).replace(ALL_BUT_NUMBERS, '');
const enterDecimalValue = (newValue, oldValue) => {
  if (!newValue) {
    return newValue;
  }
  const isValidValue = NUMBER_UP_TO_TWO_DECIMAL.test(newValue);

  return isValidValue ? newValue : oldValue;
};

const setEmptyState = (values) => values.reduce((object, item) => ({
  ...object,
  [item.name]: '',
}), {});

const setSpaceNumber = (value) => {
  const valueStr = value.toString();
  if (valueStr.length <= 3) return valueStr;

  const str = valueStr.slice(0, valueStr.length - 3);
  const last = valueStr.slice(-3);

  return `${setSpaceNumber(str)} ${last}`;
};

const getCurrentPropsBeneficiaries = (props, index) => Object.keys(props)
  .reduce((currentProps, prop) => {
    if (prop.indexOf(`[${index}]`) !== -1) {
      return {
        ...currentProps,
        [prop]: props[prop],
      };
    }

    return currentProps;
  }, {});

const replaceTemplateRoute = (route) => route.toString().replace(ROUTE_TEMPLATE, '');

const getPlaceholder = (placeholder, isRequired) => (isRequired ? `${placeholder} ${REQUIRED_LABEL}` : placeholder);

const replaceFirstChar = (str) => (str ? str
  .toLowerCase().replace(FIRST_CHAR, (word) => word.toUpperCase()) : str);

const rebuildInputProps = (inputProps) => {
  const { values } = inputProps;

  return {
    ...inputProps,
    values: {
      ...values,
      lastName: replaceFirstChar(values.lastName),
      firstName: replaceFirstChar(values.firstName),
      patronymic: replaceFirstChar(values.patronymic),
    },
  };
};

const setSpaceNumberDecimal = (str) => str.replace(SPACE_NUMBER, '$1 ');

export {
  replaceTemplateRoute,
  getCurrentPropsBeneficiaries,
  getFormattedTime,
  onlyNumberAndColon,
  setSpaceNumber,
  setEmptyState,
  rebuildInputProps,
  replaceFirstChar,
  getPlaceholder,
  replaceAllButNumbers,
  getDateForPicker,
  checkObjectEmpty,
  getPropsName,
  getPropName,
  simpleDate,
  checkDataEmpty,
  setInitialValues,
  setFieldValues,
  setSomeFieldsValues,
  getFormInputClass,
  changeBeneficiaryForm,
  getInputProps,
  getFormattedDate,
  getUtcFormattedDate,
  stringToBool,
  stringToDate,
  removeIfNeed,
  getAddress,
  getBankList,
  getFmsUnitList,
  getFmsUnitTitle,
  getAdressString,
  getOptionsHouseNumber,
  getFormat,
  numberOrStringTypes,
  getCopyValues,
  enterDecimalValue,
  setSpaceNumberDecimal,
};
