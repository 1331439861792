import {
  API,
  ROUTES,
  SYSTEM_DATE_TO,
  NSZ_RISK_TYPE,
  LANG_DICTIONARY,
} from 'consts';
import {
  apiCall,
  getError,
  calculateAccessTermsNsz,
} from 'helpers';
import _ from 'lodash';
import {
  put,
  takeLatest,
  take,
  select,
} from 'redux-saga/effects';
import {
  clearStoreNszProducts,
  setInitFormBeneficiary,
  resetValidationTerrorist,
  changeFieldNszProduct,
  getPackageRisks,
  getAdditionalRisks,
} from 'redux/rootActions';
import { GET_PRODUCT_INFO, GET_PACKAGE_RISKS, GET_ADDITIONAL_RISKS } from 'redux/nszProduct/types';

import moment from 'moment';
import { toastr } from 'react-redux-toastr';
import { COPY_CONTRACT } from '../types';

function* getCopy({ payload }) {
  const { GET_CONTRACT_COPY } = API;
  const { id, history } = payload;
  try {
    yield put({ type: COPY_CONTRACT.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_CONTRACT_COPY,
      body: { ctsId: id },
    });
    yield put(clearStoreNszProducts());
    yield put(setInitFormBeneficiary());

    yield put(resetValidationTerrorist());
    history.push(`${ROUTES.nszCalculation}/${data.productId}`);

    // TODO create fabric if use current functional for not nsz contracts
    yield take(GET_PRODUCT_INFO.success);
    const { nszProductValues } = yield select(({ nszProduct }) => nszProduct);
    const insuredBirthDate = data.insured.birthDate;
    const childInsuredBirthDate = _.get(data, 'childInsured.birthDate');
    const terms = calculateAccessTermsNsz({
      insuredBirthDate,
      childInsuredBirthDate,
      nszProductValues,
    });
    yield put(changeFieldNszProduct('productTerms', terms));
    yield saveRisks(nszProductValues.contentType, data);
    yield put(changeFieldNszProduct('disableCountButton', false));
    yield put({ type: COPY_CONTRACT.success, payload: data });
  } catch (e) {
    yield put({ type: COPY_CONTRACT.fail, payload: getError(e) });
  }
}

function* saveRisks(riskType, data) {
  const insuredBirthDate = data.insured.birthDate;
  const childInsuredBirthDate = _.get(data, 'childInsured.birthDate');
  const bodyForRequestPackage = {
    id: data.productId,
    insuredBirthDate: moment(insuredBirthDate).format(SYSTEM_DATE_TO),
    termId: data.productTermId,
    scenario: data.scenario,
    childInsuredBirthDate: childInsuredBirthDate
      && moment(childInsuredBirthDate).format(SYSTEM_DATE_TO),
  };

  switch (riskType) {
    case NSZ_RISK_TYPE.package: {
      yield put(getPackageRisks(bodyForRequestPackage));
      yield take(GET_PACKAGE_RISKS.success);
      const {
        fieldValues: {
          packageRisks,
        },
      } = yield select(({ nszProduct }) => nszProduct);
      const checkedPackageRisks = packageRisks.map((item) => ({
        ...item,
        checked: data.risks.some((id) => id === item.packetId),
      }));
      yield put(changeFieldNszProduct('packageRisks', checkedPackageRisks));

      break;
    }

    case NSZ_RISK_TYPE.default: {
      yield put(getAdditionalRisks(bodyForRequestPackage));
      yield take(GET_ADDITIONAL_RISKS.success);

      break;
    }

    default:
      toastr.error('', LANG_DICTIONARY.UNKNOWN_TYPE_RISK);
      break;
  }
}

function* agentData() {
  yield takeLatest(COPY_CONTRACT.request, getCopy);
}

export default agentData;
