import {
  GET_TABLE_INFO_CONSTRUCTOR_REQUEST,
  GET_ASSETS_FOR_CONSTRUCTOR_REQUEST,
  GET_MIN_PAY_REQUEST,
  SET_CURRENT_CURRENCY,
  GET_CONTRACT_PERIODS_REQUEST,
  GET_PAYOUT_TIMES_REQUEST,
  SET_ASSETS,
  SET_PERIOD,
  SET_PAYOUT,
  SET_MASK,
  SET_BARRIERS,
  GET_CALCULATE_COUPON,
  GET_CALCULATED_BASKETS_REQUEST,
  GET_USER_BASKETS_REQUESTS_COUNT_REQUEST,
  GET_CHECKOUT_CALCULATED_BASKET_REQUEST,
  CLEAR_MIN_PAY,
  CLEAR_CONSTRUCTOR_CREATOR,
  GET_PRODUCTS_GROUPS_REQUEST,
  GET_CONSTRUCTOR_SETTINGS_REQUEST,
  CLEAR_ASSETS,
  CHANGE_GUARANTEE_STATE,
  SET_INIT_STATE,
  SET_SORT_DATA,
  SET_FILTER_DATA,
  CLEAR_FILTER_AND_SORT,
  COPY_REQUEST_DATA,
  GENERATE_XLSX_REPORT,
  GET_SETTINGS_AND_CALC_REQUESTS,
  GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE,
  SET_DESCRIPTION_ASSETS,
  SET_OPTION_TYPE_ID,
  SET_ERROR_BARRIER,
  SET_ERRORS_BARRIER,
} from '../types';

const getConstructorSettings = (id) => ({
  type: GET_CONSTRUCTOR_SETTINGS_REQUEST,
  payload: id,
});

const getProductGroups = (id) => ({
  type: GET_PRODUCTS_GROUPS_REQUEST,
  payload: id,
});

const clearConstrutctor = () => ({
  type: CLEAR_CONSTRUCTOR_CREATOR,
});

const getCheckoutBasket = ({
  baskedId,
  productId,
  history,
  constructorProduct,
  guarantee,
  currency,
  actives,
  term,
  refIszOptionTypeId,
}) => ({
  type: GET_CHECKOUT_CALCULATED_BASKET_REQUEST,
  payload: {
    baskedId,
    productId,
    history,
    constructorProduct,
    guarantee,
    currency,
    actives,
    term,
    refIszOptionTypeId,
  },
});

const getUserRequestsCount = () => ({
  type: GET_USER_BASKETS_REQUESTS_COUNT_REQUEST,
});

const getCalculatedCoupons = ({
  id,
  currencyId,
  guarantee,
  isRequestSetting,
}) => ({
  type: GET_CALCULATED_BASKETS_REQUEST,
  payload: {
    id,
    currencyId,
    guarantee,
    isRequestSetting,
  },
});

const getCalculateCoupon = (body) => ({
  type: GET_CALCULATE_COUPON,
  payload: body,
});

const setBarriersLevels = (value) => ({
  type: SET_BARRIERS,
  payload: value,
});

const setMaskConstructor = (newMask) => ({
  type: SET_MASK,
  payload: newMask,
});

const setPeriod = (values, value) => ({
  type: SET_PERIOD,
  payload: { values, value },
});

const setConstructorPayout = (values, value) => ({
  type: SET_PAYOUT,
  payload: { values, value },
});

const getTableInfo = ({ id, isRequestSetting, currencyId }) => ({
  type: GET_TABLE_INFO_CONSTRUCTOR_REQUEST,
  payload: { id, isRequestSetting, currencyId },
});

const getAssets = (term) => ({
  type: GET_ASSETS_FOR_CONSTRUCTOR_REQUEST,
  payload: term,
});

const getMinPay = ({
  currency,
  assets,
  minPayForTables,
  period,
  refInsDateUnitId,
  basketBarriersText,
}) => ({
  type: GET_MIN_PAY_REQUEST,
  payload: {
    currency,
    assets,
    minPayForTables,
    period,
    refInsDateUnitId,
    basketBarriersText,
  },
});

const setCurrency = (values) => ({
  type: SET_CURRENT_CURRENCY,
  payload: values,
});

const getPeriods = ({ currency, id }) => ({
  type: GET_CONTRACT_PERIODS_REQUEST,
  payload: { currency, id },
});

const getPayouts = (period) => ({
  type: GET_PAYOUT_TIMES_REQUEST,
  payload: period,
});

const setAssets = (values) => ({
  type: SET_ASSETS,
  payload: values,
});

const clearMinPay = () => ({
  type: CLEAR_MIN_PAY,
});

const clearAssets = () => ({
  type: CLEAR_ASSETS,
});

const changeStateGuaranteeLevel = (target) => ({
  type: CHANGE_GUARANTEE_STATE,
  payload: target,
});

const setInitStateConstructorIsz = () => ({
  type: SET_INIT_STATE,
});

const setSortConstructorIsz = ({
  type,
  payload,
  table,
  field,
}) => ({
  type: SET_SORT_DATA,
  payload: {
    type,
    payload,
    table,
    field,
  },
});

const setFilterConstructorIsz = ({
  payload,
  table,
  field,
}) => ({
  type: SET_FILTER_DATA,
  payload: {
    payload,
    table,
    field,
  },
});

const clearFilterAndSortConstructor = () => ({
  type: CLEAR_FILTER_AND_SORT,
});

const copyBasketConstructor = ({ requestId, insProductId }) => ({
  type: COPY_REQUEST_DATA.request,
  payload: { requestId, insProductId },
});

const generateXlsxReport = ({
  rows,
  warrantyLevel,
  currency,
  refIszOptionType,
  refIszOptionTypeId,
}) => ({
  type: GENERATE_XLSX_REPORT.request,
  payload: {
    rows,
    warrantyLevel,
    currency,
    refIszOptionType,
    refIszOptionTypeId,
  },
});

const getMyAndCalcRequests = ({
  currencyId,
  guarantee,
  isRequestSetting,
  id,
  refIszOptionTypeId,
}) => ({
  type: GET_SETTINGS_AND_CALC_REQUESTS.request,
  payload: {
    currencyId,
    guarantee,
    isRequestSetting,
    id,
    refIszOptionTypeId,
  },
});

const getSettingsConstructorForFormCreate = (id, isCopyRequestForm) => ({
  type: GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE,
  payload: { id, isCopyRequestForm },
});

const setDescriptionAssets = (description) => ({
  type: SET_DESCRIPTION_ASSETS,
  payload: description,
});

const changeConstructorOptionTypeId = (id) => ({
  type: SET_OPTION_TYPE_ID,
  payload: id,
});

const setBarrierError = ({ index, status }) => ({
  type: SET_ERROR_BARRIER,
  payload: { index, status },
});

const setBarrierErrors = (errors) => ({
  type: SET_ERRORS_BARRIER,
  payload: errors,
});

export {
  changeConstructorOptionTypeId,
  setBarrierErrors,
  setBarrierError,
  setDescriptionAssets,
  getSettingsConstructorForFormCreate,
  getMyAndCalcRequests,
  generateXlsxReport,
  copyBasketConstructor,
  clearFilterAndSortConstructor,
  setFilterConstructorIsz,
  setSortConstructorIsz,
  setInitStateConstructorIsz,
  changeStateGuaranteeLevel,
  clearAssets,
  getConstructorSettings,
  getProductGroups,
  clearConstrutctor,
  clearMinPay,
  getUserRequestsCount,
  getCheckoutBasket,
  getCalculatedCoupons,
  getCalculateCoupon,
  setBarriersLevels,
  setMaskConstructor,
  setConstructorPayout,
  setPeriod,
  setAssets,
  getTableInfo,
  getAssets,
  getMinPay,
  setCurrency,
  getPeriods,
  getPayouts,
};
