import {
  SET_DATE,
  SET_SELECT_DATE,
  SET_EXTRA_SETTINGS,
  SET_INITIAL_VALUES,
  SET_PAGINATION_PAGE,
  GET_EXTEND_SETTINGS,
  SET_INITIAL_VALUES_REPORT,
} from '../types';

const setStartDate = (name, date) => ({
  type: SET_DATE,
  payload: { name, date },
});

const getExtendSettings = () => ({
  type: GET_EXTEND_SETTINGS,
});
const setSelectDate = (start, end, value) => ({
  type: SET_SELECT_DATE,
  payload: { start, end, value },
});

const setExtraSettings = (name, value) => ({
  type: SET_EXTRA_SETTINGS,
  payload: { name, value },
});

const setInitialValuesMySales = () => ({
  type: SET_INITIAL_VALUES,
});

const handlePaginationPage = (page) => ({
  type: SET_PAGINATION_PAGE,
  payload: page,
});

const setInitialValuesReport = () => ({
  type: SET_INITIAL_VALUES_REPORT,
});

export {
  getExtendSettings,
  handlePaginationPage,
  setInitialValuesMySales,
  setStartDate,
  setSelectDate,
  setExtraSettings,
  setInitialValuesReport,
};
