const CONSTRUCTOR_ISZ_COUPON_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: false,
  isProductStart: true,
  isPremium: true,
};

const CONSTRUCTOR_ISZ_COUPON_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const CONSTRUCTOR_ISZ_COUPON_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const CONSTRUCTOR_ISZ_COUPON_BENEFICIARY = {

};

const CONSTRUCTOR_ISZ = {
  preliminary: CONSTRUCTOR_ISZ_COUPON_PRELIMINARY,
  insurrerAgent: CONSTRUCTOR_ISZ_COUPON_AGENT,
  insurredPerson: CONSTRUCTOR_ISZ_COUPON_PERSON,
  beneficiary: CONSTRUCTOR_ISZ_COUPON_BENEFICIARY,
};

export default CONSTRUCTOR_ISZ;
