import React from 'react';
import { getFormattedDate } from 'helpers';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';
import './styles.scss';

const propTypes = {
  open: PropTypes.bool,
  message: PropTypes.string,
  handleClickYes: PropTypes.func,
  handleClickNo: PropTypes.func,
  insurerName: PropTypes.string,
  ctsNum: PropTypes.string,
  ctsDate: PropTypes.string,
};

const defaultProps = {
  open: false,
  message: '',
  handleClickYes: () => null,
  handleClickNo: () => null,
  insurerName: '',
  ctsNum: '',
  ctsDate: '',
};

const {
  ATTENTION,
  INSURER,
  DATE_CONTRACT,
  KIT,
  CHECK_NOT_COMPLETELY,
  BACK_WORD,
  YES,
} = LANG_DICTIONARY;

const b = block('unfinished-check');
const UnfinishedCheck = ({
  open,
  message,
  handleClickYes,
  handleClickNo,
  insurerName,
  ctsNum,
  ctsDate,
}) => (
  <PopupContainer
    isOpen={open}
    close={handleClickNo}
  >
    <div className={b()}>
      <p className={b('warning')}>{`${ATTENTION}!`}</p>
      <div className={b('contract-info')}>
        <div className={b('contract-info-row')}>
          <p>{`${KIT}:`}</p>
          <p>{ctsNum}</p>
        </div>
        <div className={b('contract-info-row')}>
          <p>{`${DATE_CONTRACT}:`}</p>
          <p>{getFormattedDate(ctsDate)}</p>
        </div>
        <div className={b('contract-info-row')}>
          <p>{`${INSURER}:`}</p>
          <p>{insurerName}</p>
        </div>
      </div>
      <p className={b('status-check')}>{`${CHECK_NOT_COMPLETELY}!`}</p>
      <p className={b('question')}>{message}</p>
      <div className={b('controls')}>
        <div className={b('btn-wrapper')}>
          <button className={b('btn-cancel')} type="button" onClick={handleClickNo}>
            {BACK_WORD}
          </button>
        </div>
        <div className={b('btn-wrapper')}>
          <button className={b('btn-confirm')} type="button" onClick={handleClickYes}>
            {YES}
          </button>
        </div>
      </div>
    </div>
  </PopupContainer>
);

UnfinishedCheck.propTypes = propTypes;
UnfinishedCheck.defaultProps = defaultProps;
export default UnfinishedCheck;
