import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  trigger: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
  children: PropTypes.oneOfType([
    PropTypes.node,
    PropTypes.string,
    PropTypes.number,
  ]).isRequired,
};

const b = block('cloud-popup');

class CloudPopup extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      hover: false,
    };
  }

    hoverTrigger = () => {
      this.setState({
        hover: true,
      });
    }

    componentDidMount = () => {
      document.addEventListener('mouseover', this.handleClickOutside);
    }

    componentWillUnmount = () => {
      document.removeEventListener('mouseover', this.handleClickOutside);
    }

    setWrapperRef = (node) => {
      this.wrapperRef = node;
    }

    handleClickOutside = (event) => {
      const { hover } = this.state;
      if (hover && this.wrapperRef && !this.wrapperRef.contains(event.target)) {
        this.setState({ hover: false });
      }
    }

    render() {
      const { trigger, children } = this.props;
      const { hover } = this.state;

      return (
        <div
          className={b()}
          onMouseEnter={this.hoverTrigger}
          ref={this.setWrapperRef}
        >
          { hover && <div className="cloud-popup__popup">{children}</div> }
          <div className={b('connector')} />
          { trigger }
        </div>
      );
    }
}

CloudPopup.propTypes = propTypes;
export default CloudPopup;
