const START_SCHEDULER_JOB_REQUEST = 'schedulerJobs/START_SCHEDULER_JOB_REQUEST';
const START_SCHEDULER_JOB_START = 'schedulerJobs/START_SCHEDULER_JOB_START';
const START_SCHEDULER_JOB_SUCCESS = 'schedulerJobs/START_SCHEDULER_JOB_SUCCESS';
const START_SCHEDULER_JOB_FAIL = 'schedulerJobs/START_SCHEDULER_JOB_FAIL';

const STOP_SCHEDULER_JOB_REQUEST = 'schedulerJobs/STOP_SCHEDULER_JOB_REQUEST';
const STOP_SCHEDULER_JOB_START = 'schedulerJobs/STOP_SCHEDULER_JOB_START';
const STOP_SCHEDULER_JOB_SUCCESS = 'schedulerJobs/STOP_SCHEDULER_JOB_SUCCESS';
const STOP_SCHEDULER_JOB_FAIL = 'schedulerJobs/STOP_SCHEDULER_JOB_FAIL';

const GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST = 'schedulerJobs/GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST';
const GET_ACTIVE_JOBS_FOR_SCHEDULER_START = 'schedulerJobs/GET_ACTIVE_JOBS_FOR_SCHEDULER_START';
const GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS = 'schedulerJobs/GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS';
const GET_ACTIVE_JOBS_FOR_SCHEDULER_FAIL = 'schedulerJobs/GET_ACTIVE_JOBS_FOR_SCHEDULER_FAIL';

const GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST = 'schedulerJobs/GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST';
const GET_PROCCESS_JOBS_FOR_SCHEDULER_START = 'schedulerJobs/GET_PROCCESS_JOBS_FOR_SCHEDULER_START';
const GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS = 'schedulerJobs/GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS';
const GET_PROCCESS_JOBS_FOR_SCHEDULER_FAIL = 'schedulerJobs/GET_PROCCESS_JOBS_FOR_SCHEDULER_FAIL';

const GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST = 'schedulerJobs/GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST';
const GET_COMPLETE_JOBS_FOR_SCHEDULER_START = 'schedulerJobs/GET_COMPLETE_JOBS_FOR_SCHEDULER_START';
const GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS = 'schedulerJobs/GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS';
const GET_COMPLETE_JOBS_FOR_SCHEDULER_FAIL = 'schedulerJobs/GET_COMPLETE_JOBS_FOR_SCHEDULER_FAIL';

const GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST = 'schedulerJobs/GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST';
const GET_FAIL_JOBS_FOR_SCHEDULER_START = 'schedulerJobs/GET_FAIL_JOBS_FOR_SCHEDULER_START';
const GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS = 'schedulerJobs/GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS';
const GET_FAIL_JOBS_FOR_SCHEDULER_FAIL = 'schedulerJobs/GET_FAIL_JOBS_FOR_SCHEDULER_FAIL';

const GET_CRON_TYPES_REQUEST = 'schedulerJobs/GET_CRON_TYPES_REQUEST';
const GET_CRON_TYPES_START = 'schedulerJobs/GET_CRON_TYPES_START';
const GET_CRON_TYPES_SUCCESS = 'schedulerJobs/GET_CRON_TYPES_SUCCESS';
const GET_CRON_TYPES_FAIL = 'schedulerJobs/GET_CRON_TYPES_FAIL';

const GET_PRIORITIES_REQUEST = 'schedulerJobs/GET_PRIORITIES_REQUEST';
const GET_PRIORITIES_START = 'schedulerJobs/GET_PRIORITIES_START';
const GET_PRIORITIES_SUCCESS = 'schedulerJobs/GET_PRIORITIES_SUCCESS';
const GET_PRIORITIES_FAIL = 'schedulerJobs/GET_PRIORITIES_FAIL';

const SET_SETTINGS_FOR_JOB = 'schedulerJobs/SET_SETTINGS_FOR_JOB';

const SET_ACTIVE_TAB = 'schedulerJobs/SET_ACTIVE_TAB';

const OPEN_POPUP_FOR_EDIT = 'schedulerJobs/OPEN_POPUP_FOR_EDIT';

const OPEN_POPUP_FOR_CONFIRM_STOP = 'schedulerJobs/OPEN_POPUP_FOR_CONFIRM_STOP';

const GET_JOBS_COUNT_REQUEST = 'schedulerJobs/GET_JOBS_COUNT_REQUEST';
const GET_JOBS_COUNT_START = 'schedulerJobs/GET_JOBS_COUNT_START';
const GET_JOBS_COUNT_SUCCESS = 'schedulerJobs/GET_JOBS_COUNT_SUCCESS';
const GET_JOBS_COUNT_FAIL = 'schedulerJobs/GET_JOBS_COUNT_FAIL';

const CLEAR_FORM = 'schedulerJobs/CLEAR_FORM';

export {
  CLEAR_FORM,
  GET_JOBS_COUNT_REQUEST,
  GET_JOBS_COUNT_START,
  GET_JOBS_COUNT_SUCCESS,
  GET_JOBS_COUNT_FAIL,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_START,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_FAIL,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_START,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_FAIL,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST,
  GET_FAIL_JOBS_FOR_SCHEDULER_START,
  GET_FAIL_JOBS_FOR_SCHEDULER_FAIL,
  GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS,
  OPEN_POPUP_FOR_CONFIRM_STOP,
  OPEN_POPUP_FOR_EDIT,
  SET_ACTIVE_TAB,
  SET_SETTINGS_FOR_JOB,
  STOP_SCHEDULER_JOB_FAIL,
  STOP_SCHEDULER_JOB_SUCCESS,
  STOP_SCHEDULER_JOB_START,
  STOP_SCHEDULER_JOB_REQUEST,
  START_SCHEDULER_JOB_REQUEST,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_CRON_TYPES_REQUEST,
  GET_PRIORITIES_REQUEST,
  START_SCHEDULER_JOB_SUCCESS,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_CRON_TYPES_SUCCESS,
  GET_PRIORITIES_SUCCESS,
  START_SCHEDULER_JOB_START,
  START_SCHEDULER_JOB_FAIL,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_START,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_FAIL,
  GET_CRON_TYPES_START,
  GET_CRON_TYPES_FAIL,
  GET_PRIORITIES_FAIL,
  GET_PRIORITIES_START,
};
