import { toastr } from 'react-redux-toastr';
import { getMyAndCalcRequests } from 'redux/rootActions';
import { storeLink } from 'index';
import { LANG_DICTIONARY } from 'consts';
import { getSocket } from 'websocket';
import { BASKET_CALCULATED } from '../types';

const { ERROR_UPDATE_BASKETS } = LANG_DICTIONARY;

export default {
  subscribe: (id) => {
    getSocket().on(BASKET_CALCULATED, (data) => {
      if (data.error) {
        toastr.error('', ERROR_UPDATE_BASKETS);

        return null;
      }

      const {
        constructorIszInterface: {
          guaranteeLevel: {
            valueGuaranteeLevel,
            valueRadioButton,
            valueOption,
          },
        },
      } = this.props;

      storeLink.dispatch(getMyAndCalcRequests({
        currencyId: valueRadioButton,
        guarantee: valueGuaranteeLevel,
        refInsTypeOptionId: valueOption,
        id,
      }));

      return null;
    });
  },

  unsubscribe: () => getSocket().removeListener(BASKET_CALCULATED),
};
