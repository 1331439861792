import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const b = block('server-message-error');

const propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  message: '',
  className: '',
};
const ErrorMessage = ({
  message,
  className,
}) => message && (
<div className={`${b()} ${className}`}>
  <Icon name="warning sign" />
  <p className={b('message')}>{message}</p>
</div>
);

ErrorMessage.propTypes = propTypes;
ErrorMessage.defaultProps = defaultProps;
export default ErrorMessage;
