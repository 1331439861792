const activeTemplate = (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 2,
      headers: [
        { text: headers[0] },
      ],
      data: [
        {
          text: data[0],
          style: { wordBreak: 'break-all' },
        },
      ],
    },
    {
      md: 3,
      headers: [
        {
          text: headers[1],
          style: { justifyContent: 'center', display: 'flex' },
        },
      ],
      data: [
        {
          text: data[1],
        },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
  ],
});

const proccessTemplate = (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0] },
      ],
      data: [
        {
          text: data[0],
          style: { wordBreak: 'break-all' },
        },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[5] },
      ],
      data: [
        { text: data[5] },
      ],
    },
  ],
});

const completeTemplate = (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0] },
      ],
      data: [
        {
          text: data[0],
          style: { wordBreak: 'break-all' },
        },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[5] },
      ],
      data: [
        { text: data[5] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[6] },
      ],
      data: [
        { text: data[6] },
      ],
    },
  ],
});

const failTemplate = (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 1,
      headers: [
        { text: headers[0] },
      ],
      data: [
        {
          text: data[0],
          style: { wordBreak: 'break-all' },
        },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[5] },
      ],
      data: [
        { text: data[5] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[6] },
      ],
      data: [
        { text: data[6] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[7] },
      ],
      data: [
        { text: data[7] },
      ],
    },
  ],
});

export {
  activeTemplate,
  proccessTemplate,
  completeTemplate,
  failTemplate,
};
