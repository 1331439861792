import _ from 'lodash';
import { rebuildRoute } from 'helpers';

import { v4 as uuidV4 } from 'uuid';
import {
  APP_CAN_RENDER,
  SET_TIMEOUT_WORK,
  SET_BLOCK_REDIRECT,
  SET_STATUS_REDIRECT_MODAL,
  PUSH_HISTORY_ROUTE,
  CHECK_EXIST_MANUAL_SUCCESS,
  SET_PROGRESS_BAR_DOWNLOAD,
  SET_PROGRESS_PERCENT,
  SET_DOWNLOAD_FILENAME,
  SAVE_AND_CLEAR_QUERY_PARAMS_STORE,
  GENERATE_WINDOW_UUID,
  CHANGE_QUERY_PARAMS, SET_WINDOW_TIMESTAMP,
} from '../types';

const INITIAL_STATE = {
  appRender: false,
  requests: 0,
  lastRedirect: null,
  redirectBlock: false,
  redirectWarning: false,
  historyRoutes: [],
  manualName: '',
  isProgressBar: false,
  progressDownload: 0,
  downloadFileName: '',
  query: {},
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case APP_CAN_RENDER:
      return {
        ...state,
        appRender: true,
      };
    case SET_PROGRESS_BAR_DOWNLOAD:
      return {
        ...state,
        isProgressBar: payload,
      };
    case SET_TIMEOUT_WORK:
      return {
        ...state,
        requests: state.requests + 1,
      };

    case SET_BLOCK_REDIRECT:
      return {
        ...state,
        redirectBlock: payload,
      };

    case SET_STATUS_REDIRECT_MODAL:
      return {
        ...state,
        redirectWarning: payload,
      };

    case PUSH_HISTORY_ROUTE: {
      const { historyRoutes } = state;
      const last = _.last(historyRoutes);
      const currentRoute = rebuildRoute(payload);
      const lastRoute = rebuildRoute(last);
      if (lastRoute === currentRoute && last) return state;
      const rebuildHistoryRoutes = [
        ...historyRoutes,
        payload,
      ];
      if (rebuildHistoryRoutes.length > 5) rebuildHistoryRoutes.splice(0, 1);

      return {
        ...state,
        historyRoutes: rebuildHistoryRoutes,
      };
    }

    case CHECK_EXIST_MANUAL_SUCCESS:
      return {
        ...state,
        manualName: payload,
      };

    case SET_PROGRESS_PERCENT:
      return {
        ...state,
        progressDownload: payload,
      };

    case SET_DOWNLOAD_FILENAME:
      return {
        ...state,
        downloadFileName: payload,
      };

    case SAVE_AND_CLEAR_QUERY_PARAMS_STORE:
      return {
        ...state,
        query: payload,
      };

    case GENERATE_WINDOW_UUID:
      return {
        ...state,
        windowUuid: uuidV4(),
        windowTimestamp: new Date().getTime(),
      };

    case CHANGE_QUERY_PARAMS:
      return {
        ...state,
        query: {
          ...state.query,
          ...payload,
        },
      };

    case SET_WINDOW_TIMESTAMP:
      return {
        ...state,
        windowTimestamp: payload,
      };

    default:
      return state;
  }
};
