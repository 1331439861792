import React from 'react';
import { block } from 'bem-cn';
import { Row, Col } from 'reactstrap';
import {
  Input,
  Dimmer,
  Segment,
  Loader,
} from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  CreateEntityBtn,
  SelectsFactory,
  ExtendedSettingsBtn,
  FlexTableAdminUsers,
} from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  loadingTableInfo: PropTypes.bool,
  handleSearchOnInput: PropTypes.func,
  searchValue: PropTypes.string,
  handleSearchOnSelect: PropTypes.func,
  history: PropTypes.object,
  handleSearchOnInputBranch: PropTypes.func,
  checkedUsers: PropTypes.object,
  userCheckCheckbox: PropTypes.func,
  selectAllCheckBox: PropTypes.func,
  selectAllUsers: PropTypes.bool,
  openExtraSettings: PropTypes.func,
  ExtraSettingsOpen: PropTypes.bool,
};

const defaultProps = {
  ExtraSettingsOpen: false,
  openExtraSettings: () => null,
  tableInfo: [],
  loadingTableInfo: false,
  handleSearchOnInput: () => null,
  searchValue: '',
  handleSearchOnSelect: () => null,
  history: {},
  handleSearchOnInputBranch: () => null,
  checkedUsers: {},
  userCheckCheckbox: () => null,
  selectAllCheckBox: () => null,
  selectAllUsers: false,
};

const b = block('add-users-form');
const {
  CREATE_USER,
  DOWNLOADING,
  SUBDIVISION,
  STATUS,
  PARTNER,
  FILTER,
  LOGINFIO,
} = LANG_DICTIONARY;

class UsersAdminForm extends React.Component {
    showExtendSettingButton = () => {
      const { openExtraSettings } = this.props;

      openExtraSettings();
    }

    render() {
      const {
        tableInfo,
        loadingTableInfo,
        handleSearchOnInput,
        searchValue,
        handleSearchOnSelect,
        history,
        handleSearchOnInputBranch,
        checkedUsers,
        userCheckCheckbox,
        selectAllCheckBox,
        selectAllUsers,
        ExtraSettingsOpen,
      } = this.props;

      return (
        <div className={b()}>
          <div className={b('controls')}>
            <Row>
              <Col md="1" sm="1">
                <div className={b('filter')}>
                  <p>
                    {FILTER}
                    :
                  </p>
                </div>
              </Col>
              <Col md="7" sm="11" className="nopaddings">
                <Input
                  placeholder={LOGINFIO}
                  value={searchValue}
                  onChange={handleSearchOnInput}
                />
              </Col>
              <Col md="4" sm="12">
                <div className={b('create-user-and-settings')}>
                  <CreateEntityBtn
                    text={CREATE_USER}
                    onClick={() => history.push(ROUTES.createUser)}
                  />
                  <ExtendedSettingsBtn
                    isOpen={ExtraSettingsOpen}
                    toggle={this.showExtendSettingButton}
                  />
                </div>
              </Col>
            </Row>
            { ExtraSettingsOpen
              ? (
                <Row>
                  <Col md="3">
                    <SelectsFactory
                      onChange={handleSearchOnSelect}
                      placeholderText={PARTNER}
                      type="partners"
                      name="partnerId"
                      prefix="users"
                    />
                  </Col>
                  <Col md="3">
                    <Input
                      onChange={handleSearchOnInputBranch}
                      placeholder={SUBDIVISION}
                    />
                  </Col>
                  <Col md="2">
                    <SelectsFactory
                      onChange={handleSearchOnSelect}
                      type="statusActive"
                      name="statusUser"
                      prefix="users"
                      placeholderText={STATUS}
                    />
                  </Col>
                  <Col md="2">
                    <SelectsFactory
                      onChange={handleSearchOnSelect}
                      type="statusSite"
                      name="statusSite"
                      prefix="users"
                    />
                  </Col>
                  <Col md="2">
                    <SelectsFactory
                      onChange={handleSearchOnSelect}
                      type="isEmployee"
                      name="isEmployee"
                      prefix="users"
                    />
                  </Col>
                </Row>
              )
              : '' }
          </div>
          <Dimmer.Dimmable as={Segment} dimmed={loadingTableInfo}>
            <Dimmer active={loadingTableInfo} inverted>
              <Loader>{`${DOWNLOADING}...`}</Loader>
            </Dimmer>
            <FlexTableAdminUsers
              data={tableInfo}
              selectAllCheckBox={selectAllCheckBox}
              checkedUsers={checkedUsers}
              userCheckCheckbox={userCheckCheckbox}
              selectAllUsers={selectAllUsers}
            />
          </Dimmer.Dimmable>
        </div>
      );
    }
}

UsersAdminForm.propTypes = propTypes;
UsersAdminForm.defaultProps = defaultProps;
export default UsersAdminForm;
