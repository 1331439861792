import React from 'react';
import { block } from 'bem-cn';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import ExtendSettings from 'assets/images/extend-settings.png';
import './styles.scss';

const checkbox = block('checkbox-setting');
const label = block('label');
const bem = block('extend-setting-btn');

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  toggle: PropTypes.func.isRequired,
};

const ExtendSettingBtn = (props) => {
  const { isOpen, toggle } = props;

  return (
    <div className={bem()}>
      <label
        htmlFor="checkbox"
        className={label({ checkbox: true })}
      >
        <img src={ExtendSettings} alt="settings" className={bem('img')} />
        <input
          id="checkbox"
          className={checkbox()}
          type="checkbox"
          checked={isOpen}
          onChange={toggle}
        />
        <span className={`${checkbox({ custom: true })} ${'custom-checkbox'}`} />
      </label>
    </div>
  );
};

ExtendSettingBtn.propTypes = propTypes;
export default withCustomRouter(ExtendSettingBtn);
