import { INS_LINE_TYPES } from 'consts';

export default (type) => {
  switch (type) {
    case INS_LINE_TYPES.NSZ:
      return {
        product: 'nszProduct',
        productValues: 'nszProductValues',
        productFields: 'calculatorData',
      };
    case INS_LINE_TYPES.KSZ:
      return {
        product: 'kszProduct',
        productValues: 'kszProductValues',
        productFields: 'fieldValues',
      };
    default:
      return {};
  }
};
