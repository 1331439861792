import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setStageKszProduct,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { BeneficiariesCommon } from 'containers';
import { addressCheckingPayloadKszSelector, productIdKszSelector } from 'redux/rootSelectors';

const defaultProps = {};

const propTypes = {
  setStage: PropTypes.func.isRequired,
};

class KszBeneficiaries extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  render() {
    const {
      setStage,
      ...rest
    } = this.props;

    return (
      <BeneficiariesCommon
        setStage={setStage}
        executionUrl={ROUTES.kszExecution}
        preliminaryCalculationUrl={ROUTES.kszParameters}
        {...rest}
      />
    );
  }
}

KszBeneficiaries.propTypes = propTypes;
KszBeneficiaries.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  addressCheckingPayload: addressCheckingPayloadKszSelector(state),
  productId: productIdKszSelector(state),
});

const mapDispatchToProps = {
  setStage: setStageKszProduct,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KszBeneficiaries));
