import 'core-js/stable';
import 'regenerator-runtime/runtime';
import 'react-app-polyfill/ie9';
import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { setToken } from 'redux/actions';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'react-redux-toastr/lib/css/react-redux-toastr.min.css';
import 'animate.css/animate.css';
import configureStore from './store';
import './index.scss';
import App from './App';
import * as serviceWorker from './serviceWorker';

// eslint-disable-next-line import/no-mutable-exports
export let storeLink;

configureStore({}).then((store) => {
  storeLink = store;
  const token = localStorage.getItem('token');
  store.dispatch(setToken(token));
  ReactDOM.render(
    <Provider store={store}>
      <App />
    </Provider>,
    document.getElementById('root'),
  );
});

serviceWorker.unregister();
