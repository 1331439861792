import React from 'react';
import { Popup, Button } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('help-message');

const propTypes = {
  message: PropTypes.string,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
  message: '',
};

const HelpMessage = ({ message, className }) => (
  <Popup
    content={message}
    trigger={<Button className={b('icon').mix(className).toString()} icon="info circle" />}
    basic
  />
);

HelpMessage.propTypes = propTypes;
HelpMessage.defaultProps = defaultProps;
export default HelpMessage;
