import React, { Component, Fragment } from 'react';
import './styles.scss';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import { ROUTES } from 'consts';
import { toastr } from 'react-redux-toastr';

const { myClients } = ROUTES;
const b = block('switcher');
const subSwitch = b('sub-switch');
const subSwitchActive = b('sub-switch', { active: true });

const propTypes = {
  panels: PropTypes.arrayOf(PropTypes.object).isRequired,
  active: PropTypes.string,
  subActive: PropTypes.string,
  history: PropTypes.object,
  match: PropTypes.object,
  stateRoute: PropTypes.string,
  accessDenied: PropTypes.bool,
};

const defaultProps = {
  active: '0',
  subActive: '0',
  history: {},
  match: {},
  stateRoute: '',
  accessDenied: false,
};

class SwitchForm extends Component {
  constructor(props) {
    super(props);
    const { active, subActive } = this.props;

    this.state = {
      active: Number(active),
      subindex: Number(subActive),
    };
  }

  showActivePanel = (index) => {
    const { active } = this.state;

    return index === active ? 'active' : '';
  };

  showActiveSubPanel = (index, sub) => {
    const {
      active,
      subindex,
    } = this.state;

    return index === active && subindex === sub ? subSwitchActive : subSwitch;
  };

  switchPanel = (index, subindex) => () => {
    const {
      panels,
      history,
      match: { params: { id } },
      stateRoute,
    } = this.props;
    const activeSubindex = subindex ? `/${subindex}` : '';
    const path = `${myClients}/${id}/${index}${activeSubindex}`;
    this.setState({
      active: index,
      subindex: subindex || 0,
    });

    if (stateRoute) {
      history.push(path, stateRoute);
    }

    const { action } = panels[index];
    if (action) action();
  };

  showComponent = () => {
    const { panels } = this.props;
    const { active, subindex } = this.state;
    const activeIndex = panels[active] ? active : '0';
    // eslint-disable-next-line
    const {Component, data, message} = panels[activeIndex];
    const checkArray = Array.isArray(data[0]);
    const dataComponent = checkArray ? data[0] : data;
    if (!data.length) return <p className={b('message-form')}>{message}</p>;

    return <Component data={checkArray && data[subindex] ? data[subindex] : dataComponent} />;
  };

  renderSwitchTab = (title, index, message) => {
    const handleAccessError = () => {
      toastr.error('Доступ для данной роли запрещен');
    };

    const { accessDenied } = this.props;

    const handleAccessSwitchPanel = accessDenied ? this.switchPanel(index, 0) : handleAccessError;

    return (
      <div
        role="button"
        tabIndex="0"
        className={`${b('section')} ${this.showActivePanel(index)}`}
        onClick={handleAccessSwitchPanel}
        key={title}
      >
        <p className={b('message')}>{message}</p>
        {title}
      </div>
    );
  };

  renderSubSwitchTab = (index, subIndex) => (
    <div
      role="button"
      tabIndex="0"
      className={this.showActiveSubPanel(index, subIndex)}
      onClick={this.switchPanel(index, subIndex)}
      key={subIndex}
    >
      {subIndex + 1}
    </div>
  );

  render() {
    const { panels } = this.props;

    return (
      <>
        <div className={b()}>
          {panels.map(({ title, data, message }, index) => {
            if (Array.isArray(data[1])) {
              return (
                <div className={b('switch-block')} key={title}>
                  {this.renderSwitchTab(title, index, message)}
                  <div className={b('sub-switch-block')}>
                    {data.map((item, i) => this.renderSubSwitchTab(index, i))}
                  </div>
                </div>
              );
            }

            return this.renderSwitchTab(title, index, message);
          })}
        </div>
        {this.showComponent()}
      </>
    );
  }
}

SwitchForm.defaultProps = defaultProps;
SwitchForm.propTypes = propTypes;
export default withCustomRouter(SwitchForm);
