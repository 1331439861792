import { simpleDate, onlyNumbers } from 'helpers';
import { DEFAULT_COUNTRY } from 'config';
import { LANG_DICTIONARY } from 'consts';

const { DOCUMENT_TYPE_TEMP_CERT } = LANG_DICTIONARY;

const getAddressWithLiving = (address) => {
  const {
    addressFias,
    index,
    republic,
    area,
    houseNumber,
    apartment,
    city,
    street,
    house,
    fiasId,
    kladId,
    addressFiasLiving,
    indexLiving,
    republicLiving,
    areaLiving,
    houseNumberLiving,
    apartmentLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    fiasIdLiving,
    kladIdLiving,
    livingAddressIsSame,
    isManualEnter,
    isManualEnterLiving,
  } = address;

  const livingAddress = livingAddressIsSame ? {
    countryLiving: DEFAULT_COUNTRY,
    indexLiving: index,
    republicLiving: republic,
    areaLiving: area,
    houseNumberLiving: houseNumber,
    apartmentLiving: apartment,
    cityLiving: city,
    streetLiving: street,
    houseLiving: house,
    isManualAddressLiving: Boolean(isManualEnter),
    fiasIdLiving: fiasId,
    kladIdLiving: kladId,
    ...(isManualEnter ? {} : {
      addressFiasLiving: { ...addressFias },
    }),
  } : {
    countryLiving: DEFAULT_COUNTRY,
    indexLiving,
    republicLiving,
    areaLiving,
    houseNumberLiving,
    apartmentLiving,
    cityLiving,
    streetLiving,
    houseLiving,
    isManualAddressLiving: Boolean(isManualEnterLiving),
    fiasIdLiving,
    kladIdLiving,
    ...(isManualEnterLiving ? {} : {
      addressFiasLiving,
    }),
  };

  return {
    country: DEFAULT_COUNTRY,
    index,
    republic,
    area,
    houseNumber,
    apartment,
    city,
    street,
    house,
    isManualAddress: Boolean(isManualEnter),
    kladId,
    fiasId,
    ...(isManualEnter ? { } : {
      addressFias,
    }),
    ...livingAddress,
  };
};

export default ({
  main,
  contacts,
  isContacts,
  documents,
  isDocuments,
  address,
  isAddress,
}) => {
  const {
    firstName,
    middleName: patronymic,
    lastName,
    gender,
    relationships: relationship,
    birthPlace,
    insuredSum,
    birthDate,
  } = main;

  const {
    documentType,
    serial,
    passportNumber,
    issuedBy,
    when,
    validUntil,
    kp,
    inn,
    snils,
  } = documents;

  const {
    mobilePhone,
    homePhone,
    workPhone,
    contactPhone,
    email,
  } = contacts;

  return {
    firstName: firstName.trim(),
    patronymic: patronymic.trim(),
    lastName: lastName.trim(),
    gender,
    birthDate: simpleDate(birthDate),
    birthPlace,
    relationship,
    insuredSum: Number(insuredSum),
    beneficiaryContact: isContacts ? {
      mobilePhone: onlyNumbers(mobilePhone),
      homePhone: onlyNumbers(homePhone),
      workPhone: onlyNumbers(workPhone),
      contactPhone: onlyNumbers(contactPhone),
      email,
    } : undefined,
    beneficiaryAddress: isAddress ? getAddressWithLiving(address) : undefined,
    beneficiaryDocument: isDocuments ? {
      documentType,
      serial,
      passportNumber,
      issuedBy,
      when: simpleDate(when),
      ...(Number(documentType) === DOCUMENT_TYPE_TEMP_CERT && {
        validUntil: simpleDate(validUntil),
      }),
      kp: onlyNumbers(kp),
      inn,
      snils,
    } : undefined,
  };
};
