import React from 'react';
import { block } from 'bem-cn';
import { formatFromISO } from 'helpers/date/ISO';
import { DATE_MASKS } from 'consts';
import { ransomType } from 'consts/propTypes';
import './styles.scss';
import PropTypes from 'prop-types';

const b = block('ransom-table');
const bHeaderRow = block('ransom-table-header-row');
const bBodyRow = block('ransom-table-body-row');

const defaultProps = {
  ransoms: [],
};
const propTypes = {
  ransoms: PropTypes.arrayOf(ransomType),
};

const RansomsTable = ({ ransoms }) => (
  <div className={b('wrapper')}>
    <table className={b()}>
      <thead>
        <tr className={bHeaderRow()}>
          <th className={bHeaderRow('action-year')}>Год действия</th>
          <th className={bHeaderRow('date-begin')}>Дата начала</th>
          <th className={bHeaderRow('date-end')}>Дата окончания</th>
          <th className={bHeaderRow('ransom-amount')}>ВС в % от СС по Дожитию</th>
          <th className={bHeaderRow('ransom-amount-sum')}>Размер ВС в валюте договора</th>
        </tr>
      </thead>
      <tbody>
        {ransoms.map((ransom) => (
          <tr className={bBodyRow()} key={ransom.ransomNum}>
            <td className={bBodyRow('action-year')}>{ransom.yearNum}</td>
            <td className={bBodyRow('action-year')}>{formatFromISO(ransom.dateBegin, DATE_MASKS.fullDate())}</td>
            <td className={bBodyRow('action-year')}>{formatFromISO(ransom.dateEnd, DATE_MASKS.fullDate())}</td>
            <td className={bBodyRow('action-year')}>{ransom.ransomAmount}</td>
            <td className={bBodyRow('action-year')}>{ransom.ransomAmountSum}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

RansomsTable.defaultProps = defaultProps;
RansomsTable.propTypes = propTypes;

export default RansomsTable;
