import React from 'react';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { connect } from 'react-redux';
import { FlexTableInboxMessage, PaginationPanel, DocsTransferMenu } from 'components';
import {
  Button,
  Input,
  Loader,
  Checkbox,
} from 'semantic-ui-react';
import {
  getInboxMessages,
  downloadInboxMessageFiles,
  markAsReadInboxMessage,
  setInboxMessagesSearchValues,
} from 'redux/rootActions';
import { LANG_DICTIONARY, PAGINATION_DEFAULT_INDEX } from 'consts';
import { usePagination } from 'hooks/usePagination';
import './styles.scss';

const {
  SEARCH,
  SENDER_FIO,
  SENDER_PARTNER,
  NEW_DOCUMENT,
} = LANG_DICTIONARY;

const propTypes = {
  inboxUnreadMessagesCount: PropTypes.number,
  isLoadingMessages: PropTypes.bool,
  inboxData: PropTypes.array,
  totalPages: PropTypes.number,
  currentPage: PropTypes.number,
  getInboxMessagesData: PropTypes.func,
  saveFiles: PropTypes.func,
  markAsRead: PropTypes.func,
  setValues: PropTypes.func,
  senderFio: PropTypes.string,
  senderPartner: PropTypes.string,
  isNewMessages: PropTypes.bool,
  history: PropTypes.object,
};

const defaultProps = {
  inboxUnreadMessagesCount: 0,
  isLoadingMessages: false,
  inboxData: [],
  totalPages: 1,
  currentPage: 1,
  getInboxMessagesData: () => {},
  saveFiles: () => {},
  markAsRead: () => {},
  setValues: () => {},
  senderFio: '',
  senderPartner: '',
  isNewMessages: false,
  history: {},
};

const b = block('docs-transfer-messages');

const DocsTransferInbox = ({
  inboxUnreadMessagesCount,
  isLoadingMessages,
  inboxData,
  totalPages,
  currentPage,
  getInboxMessagesData,
  saveFiles,
  markAsRead,
  setValues,
  senderFio,
  senderPartner,
  isNewMessages,
  history,
}) => {
  const getQuery = () => {
    const query = {};
    senderFio && Object.assign(query, { userFullName: senderFio });
    senderPartner && Object.assign(query, { partnerName: senderPartner });
    isNewMessages && Object.assign(query, { isNewMessages });

    return query;
  };

  const { handleUpPage, handleDownPage } = usePagination({
    totalPages,
    currentPage,
    query: getQuery(),
    func: getInboxMessagesData,
  });

  const handleSearch = () => {
    const query = getQuery();

    getInboxMessagesData(PAGINATION_DEFAULT_INDEX, query);
  };

  return (
    <DocsTransferMenu inboxUnreadMessagesCount={inboxUnreadMessagesCount} history={history}>
      <div className={b()}>
        <div className={b('search')}>
          <Input
            className={b('field').toString()}
            value={senderFio}
            name="senderFio"
            placeholder={SENDER_FIO}
            onChange={(e) => setValues('senderFio', e.target.value)}
          />
          <Input
            className={b('field').toString()}
            value={senderPartner}
            name="senderPartner"
            placeholder={SENDER_PARTNER}
            onChange={(e) => setValues('senderPartner', e.target.value)}
          />
          <Checkbox
            className={b('checkbox').toString()}
            checked={isNewMessages}
            name="isNewMessages"
            onChange={() => setValues('isNewMessages', !isNewMessages)}
            label={NEW_DOCUMENT}
          />
          <Button
            className={b('btn').toString()}
            onClick={handleSearch}
          >
            {SEARCH}
          </Button>
        </div>
        <Loader
          inline
          active={isLoadingMessages}
          size="big"
          className={b('loader').toString()}
        />
        <FlexTableInboxMessage
          data={inboxData}
          saveFiles={saveFiles}
          markAsRead={markAsRead}
          isLoading={isLoadingMessages}
          className={b().toString()}
        />
        <PaginationPanel
          active={currentPage}
          total={totalPages}
          handleUpPage={handleUpPage}
          handleDownPage={handleDownPage}
        />
      </div>
    </DocsTransferMenu>
  );
};

const mapStateToProps = ({
  docsTransfer,
}) => ({
  ...docsTransfer,
});

const mapDispatchToProps = {
  getInboxMessagesData: getInboxMessages,
  saveFiles: downloadInboxMessageFiles,
  markAsRead: markAsReadInboxMessage,
  setValues: setInboxMessagesSearchValues,
};

DocsTransferInbox.propTypes = propTypes;
DocsTransferInbox.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(DocsTransferInbox));
