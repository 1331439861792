import React from 'react';

import { PopupContainer } from 'components';
import { TextInput } from 'components/_deprecated';
import { useSelector } from 'react-redux';
import {
  Button, Form, Dimmer, Loader,
} from 'semantic-ui-react';
import { Formik } from 'formik';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import * as yup from 'yup';

import './styles.scss';
import { useActions } from 'hooks/useActions';

import * as rootActions from 'redux/rootActions';

const initialValues = {
  fio: '',
  email: '',
};

const b = block('recovery-login-modal');

const {
  SEND_LOGIN_RECOVERY_TITLE,
  SEND_LOGIN_REVOVERY,
  REQUIRED,
  NO_MORE_THEN_FIFTY,
} = LANG_DICTIONARY;

const propTypes = {
  isUnitedFront: PropTypes.bool,
};

const defaultProps = {
  isUnitedFront: false,
};

const MAX_FIO_LENGTH = 50;
const MAX_EMAIL_LENGTH = 50;

const validationSchema = yup.object().shape({
  fio: yup.string().max(MAX_FIO_LENGTH, NO_MORE_THEN_FIFTY).required(REQUIRED),
  email: yup.string().max(MAX_EMAIL_LENGTH, NO_MORE_THEN_FIFTY).required(REQUIRED),
});

const RecoveryLogin = ({ isUnitedFront }) => {
  const {
    isShowLoginRecoveryModal,
    isLoadingSendingMessageRecoveryAccess,
  } = useSelector((state) => state.authReducer);
  const { changeRecoveryLoginModal, loginRecoveryRequest } = useActions(rootActions);

  const getInputProps = (
    { name, placeholder },
    {
      values,
      errors,
      touched,
      handleChange,
      handleBlur,
    },
  ) => ({
    name,
    placeholder,
    values,
    errors,
    touched,
    onChange: handleChange,
    onBlur: handleBlur,
  });

  const handleSubmit = async (values, { setSubmitting }) => {
    await loginRecoveryRequest(values);
    setSubmitting(false);
  };

  const handleClose = () => {
    if (!isLoadingSendingMessageRecoveryAccess) {
      changeRecoveryLoginModal(false);
    }
  };

  const isOpen = Boolean(isShowLoginRecoveryModal && isUnitedFront);

  return (
    <PopupContainer isOpen={isOpen} className={b()} close={handleClose} isCloseButton>
      <Dimmer
        className={b('dimmer').toString()}
        active={isLoadingSendingMessageRecoveryAccess}
        inverted
      >
        <Loader inverted content="Отправка сообщения" />
      </Dimmer>
      <Formik
        initialValues={initialValues}
        onSubmit={handleSubmit}
        validationSchema={validationSchema}
      >
        {(formData) => (
          <Form className={b('form')}>
            <div className={b('title')}>
              <p>{SEND_LOGIN_RECOVERY_TITLE}</p>
            </div>
            <div className={b('form-container')}>
              <TextInput {...getInputProps({ name: 'fio', placeholder: 'ФИО полностью' }, formData)} />
              <TextInput {...getInputProps({ name: 'email', placeholder: 'example@bcs.ru' }, formData)} />
            </div>
            <div className={b('controls')}>
              <Button
                type="submit"
                className={b('sendButton').toString()}
                disabled={formData.isSubmitting}
                onClick={formData.handleSubmit}
              >
                { SEND_LOGIN_REVOVERY }
              </Button>
            </div>
          </Form>
        )}
      </Formik>
    </PopupContainer>
  );
};

RecoveryLogin.propTypes = propTypes;
RecoveryLogin.defaultProps = defaultProps;

export default RecoveryLogin;
