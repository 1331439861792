const AVAILABLE_DOCS_TRANSFER_FORMATS = '.pdf, .jpeg, .jpg, .png, .docx, .xlsx, .xlsm, .pptx';

const DOCS_TRANSFER_MAX_FILES = 35;
const DOCS_TRANSFER_MAX_MESSAGE_LENGTH = 500;

export {
  AVAILABLE_DOCS_TRANSFER_FORMATS,
  DOCS_TRANSFER_MAX_FILES,
  DOCS_TRANSFER_MAX_MESSAGE_LENGTH,
};
