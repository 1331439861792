import {
  GET_UNREAD_INBOX_MESSAGES_COUNT,
  GET_INBOX_MESSAGES,
  MARK_AS_READ_INBOX_MESSAGE,
  SET_SEARCH_VALUES,
  GET_SENT_MESSAGES,
  GET_RECIPIENT_PARTNERS,
  GET_RECIPIENTS,
  CREATE_NEW_MESSAGE,
  SET_UNREAD_INBOX_COUNT,
} from '../types';

const INITIAL_STATE = {
  inboxUnreadMessagesCount: 0,
  isLoadingMessages: false,
  inboxData: [],
  sentData: [],
  totalPages: 1,
  currentPage: 1,
  senderFio: '',
  senderPartner: '',
  isNewMessages: false,
  recipientFio: '',
  recipientPartner: '',
  recipientPartners: [],
  recipients: [],
  recipientGroups: [],
  selectedPartnerId: null,
  selectedRecipient: null,
  message: '',
  files: [],
  isLoadingCreateMessage: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_UNREAD_INBOX_COUNT:
      return {
        ...state,
        inboxUnreadMessagesCount: payload,
      };

    case GET_UNREAD_INBOX_MESSAGES_COUNT.success:
      return {
        ...state,
        inboxUnreadMessagesCount: payload,
      };

    case GET_INBOX_MESSAGES.start:
      return {
        ...state,
        isLoadingMessages: true,
      };

    case GET_INBOX_MESSAGES.success: {
      const { data, totalPages, currentPage } = payload;

      return {
        ...state,
        isLoadingMessages: false,
        inboxData: data,
        totalPages,
        currentPage,
      };
    }

    case GET_INBOX_MESSAGES.fail:
      return {
        ...state,
        isLoadingMessages: false,
      };

    case GET_SENT_MESSAGES.start:
      return {
        ...state,
        isLoadingMessages: true,
      };

    case GET_SENT_MESSAGES.success: {
      const { data, totalPages, currentPage } = payload;

      return {
        ...state,
        isLoadingMessages: false,
        sentData: data,
        totalPages,
        currentPage,
      };
    }

    case GET_SENT_MESSAGES.fail:
      return {
        ...state,
        isLoadingMessages: false,
      };

    case MARK_AS_READ_INBOX_MESSAGE.success:
      return {
        ...state,
        inboxData: state.inboxData.map((message) => (message.id === payload.messageId ? ({
          ...message,
          isRead: true,
        }) : message)),
      };

    case SET_SEARCH_VALUES: {
      const { name, value } = payload;

      return {
        ...state,
        [name]: value,
      };
    }

    case GET_RECIPIENT_PARTNERS.success:
      return {
        ...state,
        recipientPartners: payload,
      };

    case GET_RECIPIENTS.success:
      return {
        ...state,
        recipients: payload.recipients,
        recipientGroups: payload.recipientGroups || [],
      };

    case CREATE_NEW_MESSAGE.start:
      return {
        ...state,
        isLoadingCreateMessage: true,
      };

    case CREATE_NEW_MESSAGE.success:
      return {
        ...state,
        isLoadingCreateMessage: false,
        message: '',
        files: [],
        selectedPartnerId: null,
        selectedRecipient: null,
        recipients: [],
        recipientGroups: [],
      };

    case CREATE_NEW_MESSAGE.fail:
      return {
        ...state,
        isLoadingCreateMessage: false,
      };

    default:
      return state;
  }
};
