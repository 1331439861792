import React from 'react';
import PropTypes from 'prop-types';
import { SelectInput } from 'components/_deprecated';

const propTypes = {
  onChange: PropTypes.func,
  text: PropTypes.string,
  placeholder: PropTypes.string,
  // eslint-disable-next-line react/forbid-prop-types
  value: PropTypes.any,
  options: PropTypes.arrayOf(PropTypes.object),
  type: PropTypes.string.isRequired,
  selectOptionByType: PropTypes.func,
  action: PropTypes.func,
  staticOptions: PropTypes.arrayOf(PropTypes.object),
  name: PropTypes.string,
  prefix: PropTypes.string,
  params: PropTypes.object,
  placeholderText: PropTypes.string,
};

const defaultProps = {
  onChange: () => null,
  selectOptionByType: () => null,
  text: '',
  placeholder: '',
  value: '',
  options: [],
  action: () => null,
  staticOptions: null,
  name: '',
  params: {},
  prefix: '',
  placeholderText: '',
};

class SelectWrapper extends React.Component {
  componentDidMount() {
    const { action, params, prefix } = this.props;
    action(params, prefix);
  }

    handleChange = (e) => {
      const {
        onChange,
        selectOptionByType,
        type,
        prefix,
      } = this.props;
      selectOptionByType(type, e.target.value, prefix);
      onChange(e, e.target);
    }

    render() {
      const {
        placeholderText,
        placeholder,
        value,
        options,
        staticOptions,
        name,
        selectOptionByType,
        action,
        ...rest
      } = this.props;

      return (
        <SelectInput
          {...rest}
          options={staticOptions || options}
          onChange={this.handleChange}
          placeholder={placeholderText || placeholder}
          value={value}
          name={name}
        />
      );
    }
}

SelectWrapper.defaultProps = defaultProps;
SelectWrapper.propTypes = propTypes;

export default SelectWrapper;
