import { put, debounce } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';

import {
  SEARCH_BANK_REQUEST,
  SEARCH_BANK_START,
  SEARCH_BANK_SUCCESS,
  SEARCH_BANK_FAIL,
} from '../types';
import { DEBOUNCE_DADATA_REQUESTS } from '../../../consts/numbers';

function* searchBank({ payload: { bank } }) {
  const { SEARCH_BANK } = API;
  try {
    yield put({ type: SEARCH_BANK_START });
    const { data } = yield apiCall({
      type: 'POST',
      body: { bank },
      url: SEARCH_BANK,
    });
    const searchValues = data.map((values) => ({ ...values, title: values.value }));
    yield put({ type: SEARCH_BANK_SUCCESS, payload: searchValues });
  } catch (error) {
    yield put({ type: SEARCH_BANK_FAIL, payload: getError(error) });
  }
}

function* BankSaga() {
  yield debounce(DEBOUNCE_DADATA_REQUESTS, SEARCH_BANK_REQUEST, searchBank);
}

export default BankSaga;
