import React from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { contestSelectors } from 'redux/rootSelectors';
import { contestActions } from 'redux/rootActions';
import { ContestInviteBannerPopup } from 'components';
import { withCustomRouter } from 'HOC';

const propTypes = {
  banner: PropTypes.shape({
    isActiveBanner: PropTypes.bool.isRequired,
    isLoadingBanner: PropTypes.bool.isRequired,
    bannerUrl: PropTypes.string.isRequired,
  }).isRequired,
  toggleIsActiveBanner: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const ContestInviteBanner = ({ banner, toggleIsActiveBanner, history }) => (
  <ContestInviteBannerPopup
    {...banner}
    history={history}
    toggleIsActiveBanner={toggleIsActiveBanner}
  />
);

const mapStateToProps = (state) => ({
  banner: contestSelectors.getBannerPayload(state),
});

ContestInviteBanner.propTypes = propTypes;
export default withCustomRouter(connect(mapStateToProps, contestActions)(ContestInviteBanner));
