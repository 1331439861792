import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('row-product');

const defaultProps = {
  value: '',
  row: null,
  html: '',
  children: null,
  stylePrefix: '',
};

const propTypes = {
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  title: PropTypes.string.isRequired,
  row: PropTypes.number,
  html: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node,
  ]),
  stylePrefix: PropTypes.string,
};

const heightRow = 52;
const padding = 4;

const RowProduct = (props) => {
  const {
    row,
    title,
    value,
    children,
    html,
    stylePrefix,
  } = props;

  const countHeight = () => row * (heightRow + padding - 1) || heightRow;

  if (children) {
    return (
      <div
        className={`${b()} ${stylePrefix}`}
        style={{ minHeight: row === 1 ? heightRow : countHeight() }}
      >
        <div className={b('row-title').mix(stylePrefix)}>
          <p>{title}</p>
        </div>
        <div className={b('row-with-children-content').mix(stylePrefix)}>
          {children}
        </div>
      </div>
    );
  }

  if (html) {
    return (
      <div className={b('description')} style={{ height: countHeight() }}>
        <p>{title}</p>
        <div dangerouslySetInnerHTML={{ __html: html }} />
      </div>
    );
  }

  return (
    <div className={b()} style={{ minHeight: row === 1 ? heightRow : countHeight() }}>
      <p>{title}</p>
      <div className={b('row-value')}>
        <p className={b('value')}>{value}</p>
      </div>
    </div>
  );
};

RowProduct.defaultProps = defaultProps;
RowProduct.propTypes = propTypes;

export default RowProduct;
