import React, { useEffect, useState } from 'react';
import moment from 'moment';
import PropTypes from 'prop-types';
import {
  Button, Form, Icon, Popup, Select,
} from 'semantic-ui-react';
import {
  LANG_DICTIONARY,
  ROUTES,
  CLIENT_REPORTS_TYPES, REGEXP,
} from 'consts';
import block from 'bem-cn';
import { connect, useSelector } from 'react-redux';
import { withCustomRouter } from 'HOC';
import './styles.scss';
import {
  DateCustomPicker,
  MainBtn,
} from 'components';
import {
  setSearchValues,
  setContract,
  setDate,
  resetForm,
  getReport,
  getSearchReportsTypes,
  resetChosenIdOnSearch,
  searchContracts,
} from 'redux/rootActions';
import {
  selectSearchReportsTypes,
} from 'redux/rootSelectors';
import ContractNumber from './сontractNumber';
import InsurerFIO from './insurerFIO';
import InsurerAndContractNumber from './insurerAndNumberContract';

const propTypes = {
  history: PropTypes.object,
  contractNumber: PropTypes.number,
  setValues: PropTypes.func,
  insurerFio: PropTypes.string,
  reportDate: PropTypes.string,
  isSelectedContractId: PropTypes.number,
  setChoseContract: PropTypes.func,
  setReportDate: PropTypes.func,
  reportsData: PropTypes.array,
  resetFields: PropTypes.func,
  downloadReport: PropTypes.func,
  resetId: PropTypes.func,
  seriesNumber: PropTypes.string,
  numbers: PropTypes.string,
  getSearchTypes: PropTypes.func,
  getContracts: PropTypes.func,
};

const defaultProps = {
  history: {},
  contractNumber: null,
  setValues: () => null,
  insurerFio: '',
  reportDate: '',
  isSelectedContractId: 0,
  setChoseContract: () => null,
  setReportDate: () => null,
  reportsData: [],
  resetFields: () => null,
  downloadReport: () => null,
  resetId: () => null,
  seriesNumber: '',
  numbers: '',
  getSearchTypes: () => null,
  getContracts: () => null,
};

const b = block('clientReports');

const {
  BACK,
  USE_FOR_SEARCH,
  SELECT_FEATURE,
  REPORT_DATE,
  DOWNLOAD_REPORT,
  REPORT_INFO_TEXT,
} = LANG_DICTIONARY;

const { home } = ROUTES;

const CustomerReportingService = (props) => {
  const {
    history,
    setValues,
    reportDate,
    isSelectedContractId,
    setChoseContract,
    setReportDate,
    contractNumber,
    insurerFio,
    reportsData,
    resetFields,
    downloadReport,
    resetId,
    seriesNumber,
    numbers,
    getSearchTypes,
    getContracts,
  } = props;

  const backToDashboard = async () => {
    history.push(home);
  };

  useEffect(() => () => {
    resetFields();
  }, []);

  useEffect(() => {
    getSearchTypes();
  }, []);

  const searchReportsTypes = useSelector(selectSearchReportsTypes);
  const searchReportsTypesOptions = () => searchReportsTypes.map((t) => ({
    value: t.code,
    text: t.name,
  }));

  const [type, setType] = useState('');

  const changeType = (value) => {
    setType(value);
    resetFields();
  };

  useEffect(() => {
    setType(searchReportsTypes?.[0]?.code || '');
  }, [searchReportsTypes]);

  const getActualDate = reportsData.find((item) => item.id === isSelectedContractId)?.actualDate;

  const maxChoseDate = moment(getActualDate).toDate();

  const getClientReport = () => {
    const data = reportsData.find((report) => report.id === isSelectedContractId);
    downloadReport({
      actualDate: data.actualDate,
      dateInvest: data.dateInvest,
      numFull: data.numFull,
      reportDate,
      searchFilters: {
        type,
        name: insurerFio.trim().replace(REGEXP.SPACES_TABS_LINE_BREAKS_BETWEEN_WORDS, ' ') || undefined,
        numFull: data.numFull || undefined,
        series: seriesNumber || undefined,
        numbers: numbers || undefined,
      },
    });
  };

  const isDownloadReportActive = isSelectedContractId && reportDate;

  return (
    <div className={b()}>
      <div>
        <Button
          type="text"
          className="formContainer__content__wrap__backButton"
          onClick={() => backToDashboard(history)}
        >
          {BACK}
        </Button>
        <div className={b('fund')}>
          <div className={b('fund-text')}>{SELECT_FEATURE}</div>
        </div>
      </div>
      <div className={b('form')}>
        <Form>
          <div>
            <div className={b('field-block')}>
              <div className={b('filed-block-label')}>
                {USE_FOR_SEARCH}
              </div>
              <div className={b('field')}>
                <Select
                  options={searchReportsTypesOptions()}
                  value={type}
                  onChange={(e, { value }) => changeType(value)}
                />
              </div>
            </div>
          </div>
          {type === CLIENT_REPORTS_TYPES.CONTRACT
            ? (
              <ContractNumber
                setFields={setValues}
                contractNumber={contractNumber}
                getReportsByNum={getContracts}
                dateInvest={reportsData[0]?.dateInvest}
                actualDate={reportsData[0]?.actualDate}
              />
            ) : (<></>)}
          {type === CLIENT_REPORTS_TYPES.INSR_PASSPORT ? (
            <InsurerFIO
              setFields={setValues}
              insurerFio={insurerFio}
              isSelectedContractId={isSelectedContractId}
              setChoseContract={setChoseContract}
              getReportsByFIO={getContracts}
              reportsData={reportsData}
              resetId={resetId}
              seriesNumber={seriesNumber}
              numbers={numbers}
            />
          ) : (<></>)}
          {type === CLIENT_REPORTS_TYPES.INSR_CONTRACT ? (
            <InsurerAndContractNumber
              setFields={setValues}
              contractNumber={contractNumber}
              getReportsByInsurerAndNum={getContracts}
              dateInvest={reportsData[0]?.dateInvest}
              actualDate={reportsData[0]?.actualDate}
              insurerFio={insurerFio}
            />
          ) : (<></>)}
          <div className={b('wrapper')}>
            <div className={b('field-block')}>
              <div className={b('filed-block-label')}>
                {REPORT_DATE}
              </div>
              <div className={b('field')}>
                <DateCustomPicker
                  name="reportDate"
                  onChange={(e) => { setReportDate('reportDate', e.target.value); }}
                  values={{ reportDate: new Date(reportDate) }}
                  required
                  maxDate={maxChoseDate}
                />
              </div>
            </div>
            <div className={b('info')}>
              <Popup
                content={REPORT_INFO_TEXT}
                trigger={<Icon className={b('icon')} size="large" name="info circle" />}
                basic
              />
            </div>
          </div>
          {
            isDownloadReportActive ? (
              <div className={b('download')}>
                <MainBtn
                  className={b('download-button')}
                  text={DOWNLOAD_REPORT}
                  onClick={() => getClientReport()}
                  disabled={!isDownloadReportActive}
                />
              </div>
            ) : (
              <></>
            )
          }
        </Form>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  const {
    clientReports: {
      contractNumber,
      insurerFio,
      reportDate,
      isSelectedContractId,
      reportsData,
      seriesNumber,
      numbers,
    },
  } = state;

  return {
    contractNumber,
    insurerFio,
    reportDate,
    isSelectedContractId,
    reportsData,
    seriesNumber,
    numbers,
  };
};

const mapDispatchToProps = {
  setValues: setSearchValues,
  setChoseContract: setContract,
  setReportDate: setDate,
  resetFields: resetForm,
  downloadReport: getReport,
  resetId: resetChosenIdOnSearch,
  getSearchTypes: getSearchReportsTypes,
  getContracts: searchContracts,
};

CustomerReportingService.propTypes = propTypes;
CustomerReportingService.defaultProps = defaultProps;

export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CustomerReportingService),
);
