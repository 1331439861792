import React from 'react';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  active: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.string,
  ]).isRequired,
  handleUpPage: PropTypes.func.isRequired,
  handleDownPage: PropTypes.func.isRequired,
  total: PropTypes.number,
};

const defaultProps = {
  total: 1,
};

const b = block('form-products');

const Pagination = (props) => {
  const {
    active,
    handleUpPage,
    handleDownPage,
    total,
  } = props;

  return (
    <section className={b('pagination')}>
      <div className={b('pagination-control')}>
        <div
          className={b('icon-caret')}
          tabIndex="0"
          role="button"
          onClick={handleDownPage}
        >
          <Icon name="caret left" />
        </div>
        <div className={b('pages')}><p>{`${active} из ${total}`}</p></div>
        <div
          className={b('icon-caret')}
          tabIndex="0"
          role="button"
          onClick={handleUpPage}
        >
          <Icon name="caret right" />
        </div>
      </div>
    </section>
  );
};

Pagination.propTypes = propTypes;
Pagination.defaultProps = defaultProps;
export default Pagination;
