import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';
import MinInsuranceAmountTable from '../MinInsuranceAmountTable';

const b = block('know-min-insurance-amount-button');

const propTypes = {
  minInsuranceAmountData: PropTypes.array,
  onClick: PropTypes.func.isRequired,
  handleCloseMinInsuranceTable: PropTypes.func.isRequired,
  currencyCode: PropTypes.string,
  disabled: PropTypes.bool,
  isMinInsuranceSumClicked: PropTypes.bool,
  className: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
};

const defaultProps = {
  disabled: false,
  minInsuranceAmountData: [],
  className: '',
  currencyCode: null,
  isMinInsuranceSumClicked: null,
};

export const KnowMinInsuranceAmountButton = ({
  minInsuranceAmountData,
  onClick,
  handleCloseMinInsuranceTable,
  currencyCode,
  disabled,
  isMinInsuranceSumClicked,
  className,
}) => (
  <div className={b.mix(className)}>
    {isMinInsuranceSumClicked && minInsuranceAmountData && minInsuranceAmountData.length ?
      (
        <div className={b('min-insurance-block')}>
          <Icon
            name="cancel"
            className={b('cancel-button').toString()}
            size="big"
            onClick={handleCloseMinInsuranceTable}
          />
          <div className={b('table')}>
            <MinInsuranceAmountTable
              minInsuranceAmountData={minInsuranceAmountData}
              currencyCode={currencyCode}
            />
          </div>
        </div>
      ) :
      (
        <Button
          className={b('button').toString()}
          onClick={onClick}
          disabled={disabled}
        >
          <Icon name="calculator" className={b('calculator-icon').toString()} />
          <p className={b('button-text')}>{LANG_DICTIONARY.KNOW_MINIMUM_PAY_REQUEST}</p>
        </Button>
      )}
  </div>
);

KnowMinInsuranceAmountButton.propTypes = propTypes;
KnowMinInsuranceAmountButton.defaultProps = defaultProps;
