import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY, CLIENT_REPORTS_TYPES, REGEXP } from 'consts';
import { Icon, Input } from 'semantic-ui-react';
import { MainBtn } from 'components';
import block from 'bem-cn';
import { getDateForPicker } from 'helpers';

const propTypes = {
  setFields: PropTypes.func,
  contractNumber: PropTypes.number,
  getReportsByInsurerAndNum: PropTypes.func,
  dateInvest: PropTypes.string,
  actualDate: PropTypes.string,
  insurerFio: PropTypes.string,
};

const defaultProps = {
  setFields: () => null,
  contractNumber: null,
  getReportsByInsurerAndNum: () => null,
  dateInvest: '',
  actualDate: '',
  insurerFio: '',
};

const b = block('clientReports');

const {
  ENTER_CONTRACT_NUMBER,
  SEARCH,
  INVEST_DATE,
  LAST_AVAILABLE_REPORT_DATE,
  CONTRACT_NUMBER,
  INSURER_FIO,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
} = LANG_DICTIONARY;

const InsurerAndContractNumber = (props) => {
  const {
    setFields,
    contractNumber,
    getReportsByInsurerAndNum,
    dateInvest,
    actualDate,
    insurerFio,
  } = props;

  const onSearch = () => {
    const refactoredContractNumber = contractNumber.replace(REGEXP.SPACES_TABS_LINE_BREAKS, '');
    const refactoredInsurerFio =
        insurerFio.replace(REGEXP.SPACES_TABS_LINE_BREAKS_START_AND_END, '').replace(REGEXP.SPACES_TABS_LINE_BREAKS_BETWEEN_WORDS, ' ');

    getReportsByInsurerAndNum({
      type: CLIENT_REPORTS_TYPES.INSR_CONTRACT,
      numFull: refactoredContractNumber,
      name: refactoredInsurerFio,
    });
  };

  return (
    <div>
      <div className={b('field-block')}>
        <div className={b('filed-block-label')}>{INSURER_FIO}</div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            placeholder={`${LAST_NAME} ${FIRST_NAME} ${PATRONYMIC}`}
            onChange={(e) => setFields('insurerFio', e.target.value)}
            value={insurerFio}
            icon={<Icon name="write" />}
          />
        </div>
      </div>
      <div className={b('wrapper')}>
        <div className={b('field-block')}>
          <div className={b('filed-block-label')}>
            {ENTER_CONTRACT_NUMBER}
          </div>
          <div className={b('field')}>
            <Input
              className={b('field')}
              name="contractNumber"
              placeholder={CONTRACT_NUMBER}
              onChange={(e) => setFields('contractNumber', e.target.value)}
              value={contractNumber}
              required
            />
          </div>
        </div>
        <div className={b('search')}>
          <MainBtn
            className={b('btn')}
            onClick={onSearch}
            text={SEARCH}
          />
        </div>
      </div>
      <div className={b('field-block-number')}>
        <div className={b('filed-block-label')}>
          {INVEST_DATE}
        </div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            type="text"
            name="dateInvest"
            value={dateInvest ? getDateForPicker(dateInvest) : ''}
            disabled
          />
        </div>
      </div>
      <div className={b('field-block-number')}>
        <div className={b('filed-block-label')}>
          {LAST_AVAILABLE_REPORT_DATE}
        </div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            type="text"
            name="actualReportDate"
            value={actualDate ? getDateForPicker(actualDate) : ''}
            disabled
          />
        </div>
      </div>
    </div>
  );
};

InsurerAndContractNumber.propTypes = propTypes;
InsurerAndContractNumber.defaultProps = defaultProps;

export default InsurerAndContractNumber;
