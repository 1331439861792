import React, { Component } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  Header,
  Modal,
  Button,
} from 'semantic-ui-react';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('timeout-window');
const { signIn } = ROUTES;

const {
  YES,
  NO,
  WORK_CONTINUE,
  TIMEOUT_ACTIVITY,
  NOT_ACTIVE_TIMEOUT,
  FINISH_SESSION,
} = LANG_DICTIONARY;

const propTypes = {
  getUser: PropTypes.func,
  clearIntervalFunc: PropTypes.func,
  setIntervalFunc: PropTypes.func,
  isShow: PropTypes.bool,
  history: PropTypes.object,
  close: PropTypes.func,
  startTimeout: PropTypes.func,
};

const defaultProps = {
  getUser: () => null,
  close: () => null,
  clearIntervalFunc: () => null,
  setIntervalFunc: () => null,
  isShow: false,
  history: {},
  startTimeout: () => null,
};

class TimeoutWindow extends Component {
  constructor(props) {
    super(props);
    this.state = {
      timer: 59,
    };
    this.timer = null;
  }

  // eslint-disable-next-line react/no-deprecated
  componentWillReceiveProps(nextProps) {
    const { isShow, clearIntervalFunc, setIntervalFunc } = nextProps;
    const { isShow: isShowPrevProps } = this.props;
    if (isShow && isShowPrevProps !== isShow) {
      clearIntervalFunc(this.timer);
      this.timer = setIntervalFunc(this.startInterval, 1000);
    }
  }

  componentDidUpdate(prevProps, prevState) {
    const { clearIntervalFunc, history } = prevProps;
    const { timer } = prevState;
    if (timer === 1) {
      clearIntervalFunc(this.timer);
      history.push(signIn);
    }
  }

  componentWillUnmount() {
    const { clearIntervalFunc } = this.props;
    clearIntervalFunc(this.timer);
  }

  startInterval = () => {
    this.setState((prevState) => ({ timer: prevState.timer - 1 }));
  };

  logOut = () => {
    const { history } = this.props;
    history.forcePush(ROUTES.signIn);
  };

  getCurrentUser = () => {
    const {
      history,
      getUser,
      close,
      clearIntervalFunc,
      startTimeout,
    } = this.props;
    clearIntervalFunc(this.timer);
    getUser({
      history,
      action: () => {
        startTimeout();
        this.setState({ timer: 59 });
        close();
      },
    });
  };

  render() {
    const { timer } = this.state;
    const time = timer < 10 ? `0${timer}` : timer;
    const { isShow } = this.props;

    return (
      <Modal open={isShow} className={b().toString()}>
        <Modal.Header>{TIMEOUT_ACTIVITY}</Modal.Header>
        <Modal.Content>
          <Modal.Description>
            {timer !== 0
              ? <Header>{`${WORK_CONTINUE}?`}</Header>
              : <Header>{NOT_ACTIVE_TIMEOUT}</Header>}
            <p className={b('timer')}>{`0:${time}`}</p>
            <div className={b('controls')}>
              {timer !== 0
              && (
              <>
                <Button onClick={this.getCurrentUser}>
                  {YES}
                </Button>
                <Button onClick={this.logOut}>
                  {NO}
                </Button>
              </>
              )}
              {
                timer === 0 && (
                <Button
                  onClick={this.logOut}
                  className={b('finish-session').toString()}
                >
                  {FINISH_SESSION}
                </Button>
                )
}
            </div>
          </Modal.Description>
        </Modal.Content>
      </Modal>
    );
  }
}

TimeoutWindow.propTypes = propTypes;
TimeoutWindow.defaultProps = defaultProps;
export default TimeoutWindow;
