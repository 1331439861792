import { TYPE_PERSON, ROUTES } from 'consts';

const {
  insurer,
  beneficiary,
  insured,
} = TYPE_PERSON;

const getLink = (ctsId, personId) => `${ROUTES.myClients}/${ctsId}/${personId}`;

export default (ctsId, type) => {
  switch (type) {
    case insurer:
      return getLink(ctsId, 1);
    case insured:
      return getLink(ctsId, 2);
    case beneficiary:
      return getLink(ctsId, 3);
    default:
      return getLink(ctsId, 0);
  }
};
