import React from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import { TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY, MASK } from 'consts';
import { getInputProps } from 'helpers';

const {
  MOBILE_PHONE,
  HOME_PHONE,
  WORK_PHONE,
  CONTACT_PHONE,
  EMAIL,
} = LANG_DICTIONARY;

const defaultProps = {
  inputProps: {},
  modifiedProps: () => null,
  options: {
    isRequiredEmail: true,
    isRequiredMobile: true,
  },
  prefix: '',
  isChildForm: false,
};

const propTypes = {
  prefix: PropTypes.string,
  inputProps: PropTypes.object,
  modifiedProps: PropTypes.func,
  options: PropTypes.shape({
    isRequiredEmail: PropTypes.bool,
    isRequiredMobile: PropTypes.bool,
  }),
  isChildForm: PropTypes.bool,
};

const { phoneMask, homePhoneMask } = MASK;

const ContactBlock = ({ prefix, ...props }) => {
  const checkedPrefix = prefix || '';

  const { options } = props;

  return (
    <Container>
      <Row>
        <Col md="6" sm="12">
          <TextInput
            name={`${checkedPrefix}mobilePhone`}
            placeholder={MOBILE_PHONE}
            mask={phoneMask}
            required={options.isRequiredEmail}
            {...getInputProps(props, `${checkedPrefix}mobilePhone`)}
          />
        </Col>
        <Col md="6" sm="12">
          <TextInput
            name={`${checkedPrefix}homePhone`}
            placeholder={HOME_PHONE}
            mask={homePhoneMask}
            {...getInputProps(props, `${checkedPrefix}homePhone`)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" sm="12">
          <TextInput
            name={`${checkedPrefix}workPhone`}
            placeholder={WORK_PHONE}
            mask={phoneMask}
            {...getInputProps(props, `${checkedPrefix}workPhone`)}
          />
        </Col>
        <Col md="6" sm="12">
          <TextInput
            name={`${checkedPrefix}contactPhone`}
            placeholder={CONTACT_PHONE}
            {...getInputProps(props, `${checkedPrefix}contactPhone`)}
          />
        </Col>
      </Row>
      <Row>
        <Col md="6" sm="12">
          <TextInput
            name={`${checkedPrefix}email`}
            placeholder={EMAIL}
            required={options.isRequiredEmail}
            {...getInputProps(props, `${checkedPrefix}email`)}
          />
        </Col>
      </Row>
    </Container>
  );
};

ContactBlock.defaultProps = defaultProps;
ContactBlock.propTypes = propTypes;

export default ContactBlock;
