import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import { FlexTable, CheckCircleOrCloseIcon } from 'components';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import template from './template';
import './styles.scss';

const {
  PARTNER, REPORT_TYPE, ROLES, STATUS,
} = LANG_DICTIONARY;

const b = block('reports-admin-table');
const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object.isRequired,
};

const defaultProps = {
  data: [],
};
const FlexTableAdminReports = ({ data, history }) => {
  const headers = [
    '',
    PARTNER,
    REPORT_TYPE,
    ROLES,
    STATUS,
  ];

  const rebuildData = data
    .map(({
      id,
      isActive,
      partnerName,
      reportType,
      roles,
    }) => template(headers, [
      <div
        onClick={() => history.push(`${ROUTES.updateReport}/${id}`)}
        role="button"
        tabIndex={0}
        className={b('edit')}
      >
        <img
          src={EditIcon}
          alt="edit-icon"
        />
      </div>,
      partnerName,
      reportType,
      <div className={b('roles')}>
        {roles.map((role) => (
          <div className={b('role')} key={`${id}${role}`}>
            {role}
          </div>
        ))}
      </div>,
      <CheckCircleOrCloseIcon status={isActive} />,
      `${id}report`,
    ]));

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableAdminReports.propTypes = propTypes;
FlexTableAdminReports.defaultProps = defaultProps;
export default withCustomRouter(FlexTableAdminReports);
