import React from 'react';
import { block } from 'bem-cn';
import {
  SwitchCheckForm,
  CheckBlock,
  UnfinishedCheck,
  ScanWindow,
  MainBtn,
} from 'components';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import PassportData from './passportData';
import ContractData from './contractData';
import HeaderForm from './headerForm';
import './styles.scss';

const b = block('check-document-editor');
const contractDataBlock = block('check-data-block');
const editorBlock = block('check-editor-block');

const propTypes = {
  personData: PropTypes.object,
  checkingData: PropTypes.object,
  contractData: PropTypes.object,
  switchList: PropTypes.arrayOf(PropTypes.object),
  toSwitchPerson: PropTypes.func,
  scans: PropTypes.arrayOf(PropTypes.object),
  changeStatusScan: PropTypes.func,
  setCurrentScan: PropTypes.func,
  currentScan: PropTypes.number,
  currentScanCheck: PropTypes.number,
  fileLink: PropTypes.string,
  fileType: PropTypes.string,
  loadingFile: PropTypes.bool,
  fileName: PropTypes.string,
  lengthListCheck: PropTypes.number,
  handleSwitch: PropTypes.func,
  currentContractIndex: PropTypes.number,
  statusListForScan: PropTypes.arrayOf(PropTypes.object),
  messageClose: PropTypes.string,
  handleClickYes: PropTypes.func,
  handleClickNo: PropTypes.func,
  changeStatusScanSelect: PropTypes.func,
  insurerName: PropTypes.string,
  handleBlurErrorDesc: PropTypes.func,
  history: PropTypes.object,
  indexPerson: PropTypes.number,
  handleFinishCheck: PropTypes.func,
  notificationBlockRedirect: PropTypes.bool,
  cancelRedirect: PropTypes.func,
  confirmRedirect: PropTypes.func,
  ctsId: PropTypes.number,
  redirectWarning: PropTypes.bool,
  handleZoomChange: PropTypes.func,
  zoom: PropTypes.bool,
  handleUnZoomChange: PropTypes.func,
  scanId: PropTypes.number,
  contrAgentAddress: PropTypes.string,
};

const defaultProps = {
  handleZoomChange: () => null,
  zoom: false,
  handleUnZoomChange: () => null,
  personData: {},
  checkingData: {},
  contractData: {},
  switchList: [],
  toSwitchPerson: () => null,
  scans: [],
  changeStatusScan: () => null,
  setCurrentScan: () => null,
  currentScan: 0,
  currentScanCheck: 0,
  fileLink: '',
  fileType: '',
  loadingFile: false,
  fileName: '',
  lengthListCheck: 0,
  handleSwitch: () => null,
  currentContractIndex: 0,
  statusListForScan: [],
  messageClose: '',
  handleClickYes: () => null,
  handleClickNo: () => null,
  changeStatusScanSelect: () => null,
  insurerName: '',
  handleBlurErrorDesc: () => null,
  history: {},
  indexPerson: 0,
  handleFinishCheck: () => null,
  notificationBlockRedirect: false,
  cancelRedirect: () => null,
  confirmRedirect: () => null,
  ctsId: 0,
  redirectWarning: false,
  scanId: 0,
  contrAgentAddress: '',
};

const {
  FOLLOWING,
  PREVIOUS,
  QUESTION_FINISH_CHECK,
} = LANG_DICTIONARY;

const CheckDocumentEditorForm = ({
  personData,
  checkingData,
  contractData,
  switchList,
  toSwitchPerson,
  scans,
  changeStatusScan,
  setCurrentScan,
  currentScan,
  currentScanCheck,
  fileLink,
  fileType,
  loadingFile,
  fileName,
  lengthListCheck,
  handleSwitch,
  currentContractIndex,
  statusListForScan,
  messageClose,
  handleClickYes,
  handleClickNo,
  changeStatusScanSelect,
  insurerName,
  handleBlurErrorDesc,
  history,
  handleFinishCheck,
  indexPerson,
  redirectWarning,
  cancelRedirect,
  confirmRedirect,
  ctsId,
  handleZoomChange,
  zoom,
  handleUnZoomChange,
  scanId,
  contrAgentAddress,
}) => (
  <section className={b()}>
    <div className={b('content')}>
      <HeaderForm
        parentClass={b}
        {...checkingData}
        history={history}
        lengthListCheck={lengthListCheck}
        currentContractIndex={currentContractIndex}
      />
      <section className={contractDataBlock()}>
        <ContractData
          history={history}
          parentClass={contractDataBlock}
          indexPerson={indexPerson}
          {...personData}
          {...contractData}
        />
        <PassportData
          parentClass={contractDataBlock}
          contrAgentAddress={contrAgentAddress}
          {...personData.ctgtsDocumentData}
        />
      </section>
      <section className={editorBlock()}>
        <div className={editorBlock('scan-window')}>
          <ScanWindow
            fileType={fileType}
            fileLink={fileLink}
            fileName={fileName}
            loadingFile={loadingFile}
            ctsId={ctsId}
            handleChangeZoom={handleZoomChange}
            zoom={zoom}
            handleUnZoomChange={handleUnZoomChange}
            scanId={scanId}
          />
        </div>
        <div className={editorBlock('control-wrapper')}>
          <SwitchCheckForm
            bem={editorBlock}
            switchList={switchList}
            toSwitchPerson={toSwitchPerson}
          />
          <CheckBlock
            data={scans}
            handleBlurErrorDesc={handleBlurErrorDesc}
            changeStatusScan={changeStatusScan}
            setCurrentScan={setCurrentScan}
            currentScan={currentScan}
            currentScanCheck={currentScanCheck}
            statusListForScan={statusListForScan}
            changeStatusScanSelect={changeStatusScanSelect}
          />
        </div>
      </section>
      <div className={b('controls-btn')}>
        {currentContractIndex !== 1 && (
        <MainBtn
          onClick={handleSwitch('prev')}
          className={b('prev-btn').toString()}
          text={(
            <div className={b('content-btn')}>
              <Icon name="arrow left" />
              <p>{PREVIOUS}</p>
            </div>
         )}
        />
        )}
        {currentContractIndex !== lengthListCheck && (
        <MainBtn
          onClick={handleSwitch('next')}
          className={b('next-btn').toString()}
          text={(
            <div className={b('content-btn')}>
              <p>{FOLLOWING}</p>
              <Icon name="arrow right" />
            </div>
        )}
        />
        )}
        {currentContractIndex === lengthListCheck && (
        <MainBtn
          onClick={handleFinishCheck}
          className={b('next-btn').toString()}
          text="Закончить проверку"
        />
        )}
      </div>
    </div>
    <UnfinishedCheck
      open={Boolean(messageClose)}
      message={messageClose}
      handleClickYes={handleClickYes}
      handleClickNo={handleClickNo}
      ctsNum={contractData.ctsNum}
      insurerName={insurerName}
      ctsDate={contractData.ctsDateCreate}
    />
    <UnfinishedCheck
      open={redirectWarning}
      message={QUESTION_FINISH_CHECK}
      handleClickYes={confirmRedirect}
      handleClickNo={cancelRedirect}
      ctsNum={contractData.ctsNum}
      insurerName={insurerName}
      ctsDate={contractData.ctsDateCreate}
    />
  </section>
);

CheckDocumentEditorForm.propTypes = propTypes;
CheckDocumentEditorForm.defaultProps = defaultProps;
export default CheckDocumentEditorForm;
