import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('clientReports');

const SET_SEARCH_VALUES = 'SET_SEARCH_VALUES';
const SET_CONTRACT = 'SET_CONTRACT';
const SET_DATE = 'SET_DATE';
const SET_INIT_STATE = 'SET_INIT_STATE';
const RESET_ID = 'RESET_ID';

const SEARCH_CONTRACTS = factory.createAsync('SEARCH_CONTRACTS');
const GET_REPORT = factory.createAsync('GET_REPORT');
const GET_SEARCH_REPORTS_TYPES = factory.createAsync('GET_SEARCH_REPORTS_TYPES');

export {
  SET_SEARCH_VALUES,
  SET_CONTRACT,
  SET_DATE,
  SEARCH_CONTRACTS,
  GET_REPORT,
  GET_SEARCH_REPORTS_TYPES,
  SET_INIT_STATE,
  RESET_ID,
};
