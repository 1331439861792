import { LANG_DICTIONARY } from 'consts';

import {
  object,
  string,
} from 'yup';

const { REQUIRED } = LANG_DICTIONARY;

export default (isDownloadFileFrom1C) => object().shape({
  ctsNum: string().notRequired(),
  exportPath: isDownloadFileFrom1C ? string() : string().required(REQUIRED),
  dateBeg: string()
    .nullable()
    .when(['ctsNum', 'signDateBeg', 'signDateEnd'], {
      is: (ctsNum, signDateBeg, signDateEnd) => !ctsNum && !signDateBeg && !signDateEnd,
      then: string().nullable().required(REQUIRED),
      otherwise: string().nullable().notRequired(),
    }),
  dateEnd: string()
    .nullable()
    .when(['ctsNum', 'signDateBeg', 'signDateEnd'], {
      is: (ctsNum, signDateBeg, signDateEnd) => !ctsNum && !signDateBeg && !signDateEnd,
      then: string().nullable().required(REQUIRED),
      otherwise: string().nullable().notRequired(),
    }),
  signDateBeg: string()
    .nullable()
    .when(['ctsNum', 'dateBeg', 'dateEnd'], {
      is: (ctsNum, dateBeg, dateEnd) => !ctsNum && !dateBeg && !dateEnd,
      then: string().nullable().required(REQUIRED),
      otherwise: string().nullable().notRequired(),
    }),
  signDateEnd: string()
    .nullable()
    .when(['ctsNum', 'dateBeg', 'dateEnd'], {
      is: (ctsNum, dateBeg, dateEnd) => !ctsNum && !dateBeg && !dateEnd,
      then: string().nullable().required(REQUIRED),
      otherwise: string().nullable().notRequired(),
    }),
}, [
  ['dateBeg', 'dateEnd'],
  ['dateBeg', 'signDateBeg'],
  ['dateBeg', 'signDateEnd'],
  ['dateEnd', 'signDateBeg'],
  ['dateEnd', 'signDateEnd'],
  ['signDateBeg', 'signDateEnd'],
]);
