import React from 'react';
import { LANG_DICTIONARY, X_CONDITION, MILLION } from 'consts';
import { useSelector } from 'react-redux';

const {
  BASKET_POPUP_TEXT_CALCULATED,
  BASKET_POPUP_TEXT_CALCULATED_KU,
  BASKET_POPUP_TEXT_CALCULATED_COUPON,
  FOR_CONTRIBUTION_OF,
  FOR_CONTRIBUTION_OF_UP_TO,
} = LANG_DICTIONARY;

const getHeaderText = (isKu, isCoupon, isOnlyOne, yield2) => {
  if (isOnlyOne) {
    if (isKu) {
      return (
        <>
          {BASKET_POPUP_TEXT_CALCULATED_KU}
          <b>{` ${yield2}%`}</b>
        </>
      );
    }

    if (isCoupon) {
      return (
        <>
          {BASKET_POPUP_TEXT_CALCULATED_COUPON}
          <b>{` ${yield2}%`}</b>
        </>
      );
    }
  }

  if (isKu) {
    return BASKET_POPUP_TEXT_CALCULATED_KU;
  }
  if (isCoupon) {
    return BASKET_POPUP_TEXT_CALCULATED_COUPON;
  }

  return BASKET_POPUP_TEXT_CALCULATED;
};

const Yields = () => {
  const {
    yield1, yield2, isKu, isCoupon, minObjectRestriction,
  } = useSelector(({ basketConstructor: { formCreating } }) => formCreating);

  const currentRestrictionLessX = minObjectRestriction < X_CONDITION;

  const isNoKuOrCoupon = Boolean(!isKu && !isCoupon);
  const isShowYields = Boolean(!isNoKuOrCoupon && yield1 && currentRestrictionLessX);

  return (
    <div>
      <p>{getHeaderText(isKu, isCoupon, !isShowYields, yield2)}</p>
      {isShowYields && (
        <>
          <p>
            {FOR_CONTRIBUTION_OF_UP_TO(X_CONDITION / MILLION)}
            <b>{`${yield1}%`}</b>
          </p>
          <p>
            {FOR_CONTRIBUTION_OF(X_CONDITION / MILLION)}
            <b>{`${yield2}%`}</b>
          </p>
        </>
      )}
    </div>
  );
};

export default Yields;
