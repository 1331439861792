import { AsyncActionFactory } from '../../helpers';

const factory = new AsyncActionFactory('userAdminInterface');

const GET_USERS_FOR_TABLE_REQUEST = 'usersAdminInterface/GET_USERS_FOR_TABLE_REQUEST';
const GET_USERS_FOR_TABLE_START = 'usersAdminInterface/GET_USERS_FOR_TABLE_START';
const GET_USERS_FOR_TABLE_SUCCESS = 'usersAdminInterface/GET_USERS_FOR_TABLE_SUCCESS';
const GET_USERS_FOR_TABLE_FAIL = 'usersAdminInterface/GET_USERS_FOR_TABLE_FAIL';

const CREATE_USER_REQUEST = 'usersAdminInterface/CREATE_USER_REQUEST';
const CREATE_USER_SUCCESS = 'usersAdminInterface/CREATE_USER_SUCCESS';
const CREATE_USER_FAIL = 'usersAdminInterface/CREATE_USER_FAIL';
const CREATE_USER_START = 'usersAdminInterface/CREATE_USER_START';

const SET_PAGE_PAGINATION = 'usersAdminInterface/SET_PAGE_PAGINATION';
const SEARCH_BY_USERS_NAME = 'usersAdminInterface/SEARCH_BY_USERS_NAME';

const GET_USER_DATA_REQUEST = 'usersAdminInterface/GET_USER_DATA_REQUEST';
const GET_USER_DATA_START = 'usersAdminInterface/GET_USER_DATA_START';
const GET_USER_DATA_SUCCESS = 'usersAdminInterface/GET_USER_DATA_SUCCESS';
const GET_USER_DATA_FAIL = 'usersAdminInterface/GET_USER_DATA_FAIL';

const UPDATE_USER_REQUEST = 'usersAdminInterface/UPDATE_USER_REQUEST';
const UPDATE_USER_SUCCESS = 'usersAdminInterfacee/UPDATE_USER_SUCCESS';
const UPDATE_USER_FAIL = 'usersAdminInterface/UPDATE_USER_FAIL';
const UPDATE_USER_START = 'usersAdminInterface/UPDATE_USER_START';

const LOCK_USER_REQUEST = 'userAdminInterface/LOCK_USER_REQUEST';
const LOCK_USER_SUCCESS = 'userAdminInterface/LOCK_USER_SUCCESS';
const LOCK_USER_FAIL = 'userAdminInterface/LOCK_USER_FAIL';
const LOCK_USER_START = 'userAdminInterface/LOCK_USER_START';

const UNLOCK_USER_REQUEST = 'userAdminInterface/UNLOCK_USER_REQUEST';
const UNLOCK_USER_SUCCESS = 'userAdminInterface/UNLOCK_USER_SUCCESS';
const UNLOCK_USER_FAIL = 'userAdminInterface/UNLOCK_USER_FAIL';
const UNLOCK_USER_START = 'userAdminInterface/UNLOCK_USER_START';

const CLEAR_FORM = 'usersAdminInterface/CLEAR_FORM';

const CLEAR_CHECKBOXED_LIST = 'userAdminInterface/CLEAR_CHECKBOXED_LIST';

const USER_TOGGLE_CHECKBOX = 'userAdminInterface/USER_TOGGLE_CHECKBOX';

const SET_INITIAL_VALUES = 'usersAdminInterface/SET_INITIAL_VALUES';

const GET_PARTNERS_FOR_USER_REQUEST = 'usersAdminInterface/GET_PARTNERS_FOR_USER_REQUEST';
const GET_PARTNERS_FOR_USER_START = 'usersAdminInterface/GET_PARTNERS_FOR_USER_START';
const GET_PARTNERS_FOR_USER_SUCCESS = 'usersAdminInterface/GET_PARTNERS_FOR_USER_SUCCESS';
const GET_PARTNERS_FOR_USER_FAIL = 'usersAdminInterface/GET_PARTNERS_FOR_USER_FAIL';

const GET_BRANCHES_FOR_USER_REQUEST = 'usersAdminInterface/GET_BRANCHES_FOR_USER_REQUEST';
const GET_BRANCHES_FOR_USER_START = 'usersAdminInterface/GET_BRANCHES_FOR_USER_START';
const GET_BRANCHES_FOR_USER_SUCCESS = 'usersAdminInterface/GET_BRANCHES_FOR_USER_SUCCESS';
const GET_BRANCHES_FOR_USER_FAIL = 'usersAdminInterface/GET_BRANCHES_FOR_USER_FAIL';

const SET_AFFILIATIONS_FOR_USER = 'userAdminInterface/SET_AFFILIATIONS_FOR_USER';

const SELECT_USER_OPTIONS = 'usersAdminInterface/SELECT_USER_OPTIONS';

const GET_USERS_ROLES_FOR_SELECT_REQUEST = 'userAdminInterface/GET_USERS_ROLES_FOR_SELECT_REQUEST';
const GET_USERS_ROLES_FOR_SELECT_START = 'userAdminInterface/GET_USERS_ROLES_FOR_SELECT_START';
const GET_USERS_ROLES_FOR_SELECT_SUCCESS = 'userAdminInterface/GET_USERS_ROLES_FOR_SELECT_SUCCESS';
const GET_USERS_ROLES_FOR_SELECT_FAIL = 'userAdminInterface/GET_USERS_ROLES_FOR_SELECT_FAIL';

const SET_BRANCHES_BY_PARTNERS = 'userAdminInterface/SET_BRANCHES_BY_PARTNERS';

const GET_LOGIN_FOR_USER_REQUEST = 'userAdminInterface/GET_LOGIN_FOR_USER_REQUEST';
const GET_LOGIN_FOR_USER_START = 'userAdminInterface/GET_LOGIN_FOR_USER_START';
const GET_LOGIN_FOR_USER_SUCCESS = 'userAdminInterface/GET_LOGIN_FOR_USER_SUCCESS';
const GET_LOGIN_FOR_USER_FAIL = 'userAdminInterface/GET_LOGIN_FOR_USER_FAIL';

const GET_PASSWORD_FOR_USER_REQUEST = 'userAdminInterface/GET_PASSWORD_FOR_USER_REQUEST';
const GET_PASSWORD_FOR_USER_START = 'userAdminInterface/GET_PASSWORD_FOR_USER_START';
const GET_PASSWORD_FOR_USER_SUCCESS = 'userAdminInterface/GET_PASSWORD_FOR_USER_SUCCESS';
const GET_PASSWORD_FOR_USER_FAIL = 'userAdminInterface/GET_PASSWORD_FOR_USER_FAIL';

const SET_WEBSITE_LISTED_USER_TRUE = 'userAdminInterface/SET_WEBSITE_LISTED_USER_TRUE';
const SET_WEBSITE_LISTED_USER_FALSE = 'userAdminInterface/SET_WEBSITE_LISTED_USER_FALSE';

const SET_WEBSITE_LOCKED_USER_TRUE = 'userAdminInterface/SET_WEBSITE_LOCKED_USER_TRUE';
const SET_WEBSITE_LOCKED_USER_FALSE = 'userAdminInterface/SET_WEBSITE_LOCKED_USER_FALSE';

const SELECT_ALL_USERS = 'userAdminInterface/SELECT_ALL_USERS';

const GET_EXTRA_SETTING = 'userAdminInterface/GET_EXTRA_SETTING';

const SET_VALUES_FOR_USER = 'userAdminInterface/SET_VALUES_FOR_USER';

const SEND_USER_CREDENTIALS = factory.createAsync('SEND_USER_CREDENTIALS');

export {
  SET_VALUES_FOR_USER,
  GET_EXTRA_SETTING,
  SET_WEBSITE_LOCKED_USER_TRUE,
  SET_WEBSITE_LOCKED_USER_FALSE,
  SET_WEBSITE_LISTED_USER_TRUE,
  SET_WEBSITE_LISTED_USER_FALSE,
  SELECT_ALL_USERS,
  GET_PASSWORD_FOR_USER_REQUEST,
  GET_PASSWORD_FOR_USER_START,
  GET_PASSWORD_FOR_USER_SUCCESS,
  GET_PASSWORD_FOR_USER_FAIL,
  GET_LOGIN_FOR_USER_REQUEST,
  GET_LOGIN_FOR_USER_START,
  GET_LOGIN_FOR_USER_SUCCESS,
  GET_LOGIN_FOR_USER_FAIL,
  SET_BRANCHES_BY_PARTNERS,
  GET_USERS_ROLES_FOR_SELECT_REQUEST,
  GET_USERS_ROLES_FOR_SELECT_START,
  GET_USERS_ROLES_FOR_SELECT_SUCCESS,
  GET_USERS_ROLES_FOR_SELECT_FAIL,
  SET_AFFILIATIONS_FOR_USER,
  GET_BRANCHES_FOR_USER_REQUEST,
  GET_BRANCHES_FOR_USER_SUCCESS,
  GET_BRANCHES_FOR_USER_START,
  GET_BRANCHES_FOR_USER_FAIL,
  SELECT_USER_OPTIONS,
  GET_PARTNERS_FOR_USER_FAIL,
  GET_PARTNERS_FOR_USER_START,
  GET_PARTNERS_FOR_USER_REQUEST,
  GET_PARTNERS_FOR_USER_SUCCESS,
  CLEAR_CHECKBOXED_LIST,
  USER_TOGGLE_CHECKBOX,
  SEARCH_BY_USERS_NAME,
  SET_INITIAL_VALUES,
  CLEAR_FORM,
  UPDATE_USER_FAIL,
  UPDATE_USER_START,
  UPDATE_USER_REQUEST,
  UPDATE_USER_SUCCESS,
  GET_USER_DATA_REQUEST,
  GET_USER_DATA_FAIL,
  GET_USER_DATA_START,
  GET_USER_DATA_SUCCESS,
  CREATE_USER_FAIL,
  CREATE_USER_REQUEST,
  CREATE_USER_START,
  CREATE_USER_SUCCESS,
  SET_PAGE_PAGINATION,
  GET_USERS_FOR_TABLE_START,
  GET_USERS_FOR_TABLE_SUCCESS,
  GET_USERS_FOR_TABLE_FAIL,
  GET_USERS_FOR_TABLE_REQUEST,
  LOCK_USER_REQUEST,
  LOCK_USER_SUCCESS,
  LOCK_USER_START,
  LOCK_USER_FAIL,
  UNLOCK_USER_REQUEST,
  UNLOCK_USER_SUCCESS,
  UNLOCK_USER_START,
  UNLOCK_USER_FAIL,
  SEND_USER_CREDENTIALS,
};
