import React from 'react';
import PropTypes from 'prop-types';
import { CheckingState, FlexTable } from 'components';
import { getFormattedDate } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import template from './template';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape()).isRequired,
};

const {
  NUMBER,
  INSURER,
  DATE_CONTRACT,
  SUBDIVISION,
  STATUS_DOCUMENT,
  PARTNER,
  FULL_NAME_EMPLOYEE,
  INSURANCE_CONTRACT,
  SET_OFFER,
  INSURANCE_PASSPORT_MAIN_SPREAD,
  INSURANCE_PASSPORT_REGISTRATION,
  INSURED_PASSPORT_MAIN_SPREAD,
  INSURED_PASSPORT_REGISTRATION,
  STATUS_CHECK,
  LAST_DATE_UPDATE_DOCS,
  DATE_CHECK,
  CHECKER,
} = LANG_DICTIONARY;

const headers = [
  NUMBER,
  DATE_CONTRACT,
  INSURER,
  FULL_NAME_EMPLOYEE,
  STATUS_DOCUMENT,
  PARTNER,
  SUBDIVISION,
  INSURANCE_CONTRACT,
  SET_OFFER,
  INSURANCE_PASSPORT_MAIN_SPREAD,
  INSURANCE_PASSPORT_REGISTRATION,
  INSURED_PASSPORT_MAIN_SPREAD,
  INSURED_PASSPORT_REGISTRATION,
  CHECKER,
  STATUS_CHECK,
  DATE_CHECK,
  LAST_DATE_UPDATE_DOCS,
];

const FlexTableDocumentsStatus = (props) => {
  const {
    data,
  } = props;

  const rebuildData = data
    .map(({
      ctsNum,
      dateCreate,
      status,
      insurerFio,
      partnerName,
      fioSales,
      statusCheck,
      lastStatusChangeDate,
      branchName,
      insCtsCheck,
      insOfferSetCheck,
      insPassportMainCheck,
      insPassportRegCheck,
      insdPassportMainCheck,
      insdPassportRegCheck,
      checkStatusDate,
      checkUserNameFull,
    }) => template(headers, [
      ctsNum,
      getFormattedDate(dateCreate),
      insurerFio,
      fioSales,
      status,
      partnerName,
      branchName,
      insCtsCheck,
      insOfferSetCheck,
      insPassportMainCheck,
      insPassportRegCheck,
      insdPassportMainCheck,
      insdPassportRegCheck,
      checkUserNameFull,
      <CheckingState status={statusCheck} />,
      getFormattedDate(checkStatusDate),
      getFormattedDate(lastStatusChangeDate),
    ]));

  return (
    <FlexTable data={rebuildData} minHeight={82} />
  );
};

FlexTableDocumentsStatus.propTypes = propTypes;
export default FlexTableDocumentsStatus;
