import {
  SET_SEARCH_VALUES,
  SET_CONTRACT,
  SET_DATE,
  SET_INIT_STATE,
  RESET_ID,
  GET_SEARCH_REPORTS_TYPES,
  SEARCH_CONTRACTS,
} from '../types';

const INITIAL_STATE = {
  contractNumber: '',
  insurerFio: '',
  reportDate: '',
  isSelectedContractId: 0,
  reportsData: [],
  seriesNumber: '',
  numbers: '',
  searchReportsTypes: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_SEARCH_VALUES: {
      const { name, value } = payload;

      return {
        ...state,
        [name]: value,
      };
    }

    case SET_CONTRACT: {
      const { id, actualDate } = payload;

      return {
        ...state,
        isSelectedContractId: id,
        reportDate: actualDate,
      };
    }

    case SET_DATE: {
      const { name, date } = payload;

      return {
        ...state,
        [name]: date,
      };
    }

    case SEARCH_CONTRACTS.success: {
      const { id, actualDate } = payload[0];

      return {
        ...state,
        reportsData: payload,
        isSelectedContractId: id,
        reportDate: actualDate,
      };
    }

    case GET_SEARCH_REPORTS_TYPES.success: {
      return {
        ...state,
        searchReportsTypes: payload,
      };
    }

    case SET_INIT_STATE:
      return {
        ...INITIAL_STATE,
        searchReportsTypes: state.searchReportsTypes,
      };

    case RESET_ID: {
      return {
        ...state,
        isSelectedContractId: 0,
      };
    }

    default:
      return state;
  }
};
