export default {
  lastName: 'Сын',
  firstName: 'Застрахованй',
  patronymic: 'Страхователя',
  documentType: 1,
  serial: '0622',
  passportNumber: '123452',
  birthDate: new Date('1998-05-01T20:00:00.000Z'),
  birthPlace: 'Таганрог, Итальянская 12',
  issuedBy: 'ТП № 118 ОУФМС РОССИИ ПО САНКТ-ПЕТЕРБУРГУ И ЛЕНИНГРАДСКОЙ ОБЛ. В ЛОДЕЙНОПОЛЬСКОМ РАЙОНЕ',
  issuedByAccepted: true,
  when: new Date('2019-05-04T21:00:00.000Z'),
  validUntil: '',
  kp: '470-062',
  gender: 1,
  addressFias: {
    data: {
      postal_code: '347942',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: null,
      region_fias_id: 'f10763dc-63e3-48db-83e1-9c566fe3092b',
      region_kladr_id: '6100000000000',
      region_iso_code: 'RU-ROS',
      region_with_type: 'Ростовская обл',
      region_type: 'обл',
      region_type_full: 'область',
      region: 'Ростовская',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: 'd72c95ed-9fdd-4f27-b94f-898fc3f1177d',
      city_kladr_id: '6100001100000',
      city_with_type: 'г Таганрог',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Таганрог',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: '99f44183-04a6-4243-9cb3-66026b3b062a',
      street_kladr_id: '61000011000036200',
      street_with_type: '1-й Новый пер',
      street_type: 'пер',
      street_type_full: 'переулок',
      street: '1-й Новый',
      house_fias_id: null,
      house_kladr_id: null,
      house_type: 'д',
      house_type_full: 'дом',
      house: '16Д',
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      postal_box: null,
      fias_id: '99f44183-04a6-4243-9cb3-66026b3b062a',
      fias_code: '6100001100000000362',
      fias_level: '7',
      fias_actuality_state: '0',
      kladr_id: '61000011000036200',
      geoname_id: '484907',
      capital_marker: '0',
      okato: '60437000000',
      oktmo: '60737000001',
      tax_office: '6154',
      tax_office_legal: '6154',
      timezone: null,
      geo_lat: '47.278761',
      geo_lon: '38.927846',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      qc_geo: '0',
      qc_complete: null,
      qc_house: null,
      history_values: [
        'пер Новый 1-й',
      ],
      unparsed_parts: null,
      source: null,
      qc: null,
    },
    unrestricted_value: '347942, Ростовская обл, г Таганрог, 1-й Новый пер, д 16Д',
    value: 'Ростовская обл, г Таганрог, 1-й Новый пер, д 16Д',
  },
  country: 'Россия',
  index: '347942',
  republic: 'обл Ростовская',
  area: '',
  city: 'г Таганрог',
  street: '1-й Новый пер',
  house: 'д 16Д',
  houseNumber: '1',
  apartment: 'кв 11',
  inn: '132312312332',
  snils: '694-797-945 06',
  mobilePhone: '+7(908)312-31-24',
  homePhone: '8(312)321-32-13',
  workPhone: '+7(321)321-32-13',
  contactPhone: '321312',
  email: 'testInsured@mail.ru',
  livingAddressIsSame: false,
  addressFiasLiving: {
    data: {
      postal_code: '107140',
      country: 'Россия',
      country_iso_code: 'RU',
      federal_district: null,
      region_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      region_kladr_id: '7700000000000',
      region_iso_code: 'RU-MOW',
      region_with_type: 'г Москва',
      region_type: 'г',
      region_type_full: 'город',
      region: 'Москва',
      area_fias_id: null,
      area_kladr_id: null,
      area_with_type: null,
      area_type: null,
      area_type_full: null,
      area: null,
      city_fias_id: '0c5b2444-70a0-4932-980c-b4dc0d3f02b5',
      city_kladr_id: '7700000000000',
      city_with_type: 'г Москва',
      city_type: 'г',
      city_type_full: 'город',
      city: 'Москва',
      city_area: null,
      city_district_fias_id: null,
      city_district_kladr_id: null,
      city_district_with_type: null,
      city_district_type: null,
      city_district_type_full: null,
      city_district: null,
      settlement_fias_id: null,
      settlement_kladr_id: null,
      settlement_with_type: null,
      settlement_type: null,
      settlement_type_full: null,
      settlement: null,
      street_fias_id: '2d4b988b-f7ea-4f42-bf16-c5bd9219943c',
      street_kladr_id: '77000000000066600',
      street_with_type: '1-й Новый пер',
      street_type: 'пер',
      street_type_full: 'переулок',
      street: '1-й Новый',
      house_fias_id: 'c0effe8c-8ea6-4637-894f-2a09a556d1d1',
      house_kladr_id: '7700000000006660001',
      house_type: 'д',
      house_type_full: 'дом',
      house: '2',
      block_type: null,
      block_type_full: null,
      block: null,
      entrance: null,
      floor: null,
      flat_fias_id: null,
      flat_type: null,
      flat_type_full: null,
      flat: null,
      flat_area: null,
      square_meter_price: null,
      flat_price: null,
      postal_box: null,
      fias_id: 'c0effe8c-8ea6-4637-894f-2a09a556d1d1',
      fias_code: '77000000000000006660001',
      fias_level: '8',
      fias_actuality_state: '0',
      kladr_id: '7700000000006660001',
      geoname_id: '524894',
      capital_marker: '0',
      okato: '45286565000',
      oktmo: '45378000',
      tax_office: '7708',
      tax_office_legal: '7708',
      timezone: null,
      geo_lat: '55.778812',
      geo_lon: '37.669052',
      beltway_hit: null,
      beltway_distance: null,
      metro: null,
      qc_geo: '0',
      qc_complete: null,
      qc_house: null,
      history_values: [
        'пер Новый 1-й',
      ],
      unparsed_parts: null,
      source: null,
      qc: null,
    },
    unrestricted_value: '107140, г Москва, 1-й Новый пер, д 2',
    value: 'г Москва, 1-й Новый пер, д 2',
  },
  countryLiving: 'Россия',
  indexLiving: '107140',
  republicLiving: 'г Москва',
  areaLiving: '',
  cityLiving: 'г Москва',
  streetLiving: '1-й Новый пер',
  houseLiving: 'д 2',
  houseNumberLiving: '1',
  apartmentLiving: 'кв 1',
  relationship: 1,
  fiasId: '99f44183-04a6-4243-9cb3-66026b3b062a',
  kladId: '61000011000036200',
  isManualEnter: false,
  fiasIdLiving: 'c0effe8c-8ea6-4637-894f-2a09a556d1d1',
  kladIdLiving: '7700000000006660001',
};
