import {
  GET_REPORTS_FOR_TABLE_REQUEST,
  SET_PAGE_PAGINATION,
  CREATE_REPORT_REQUEST,
  UPDATE_REPORT_REQUEST,
  CLEAR_FORM,
  GET_REPORT_DATA_REQUEST,
  SET_INITIAL_VALUES,
  SET_ROLES_FOR_REPORT,
  GET_ROLES_FOR_SELECT_REQUEST,
  GET_REPORT_TAGS_REQUEST,
  GET_REPORT_TYPES_FOR_SELECT_REQUEST,
  GET_PARTNERS_FOR_SELECT_REQUEST,
  SET_REPORT_SELECT,
  SET_TAGS_FOR_REPORT,
  SET_NEW_TAGS_SUCCESS,
  SET_REPORT_STATUS,
  GET_DOWNLOAD_CHECK_REQUEST,
  GET_DOWNLOAD_LIST_REQUEST,
  GET_DOWNLOAD_REPORT_REQUEST,
  CLOSE_DOWNLOAD_POPUP,
} from '../types';

const closeDownloadPopup = () => ({
  type: CLOSE_DOWNLOAD_POPUP,
});
const getDownloadCheck = () => ({
  type: GET_DOWNLOAD_CHECK_REQUEST,
});

const getDownloadList = () => ({
  type: GET_DOWNLOAD_LIST_REQUEST,
});

const getDownloadReport = ({
  id,
  reportType,
  dateSignBeg,
  dateSignEnd,
  productIds,
  statusIds,
}) => ({
  type: GET_DOWNLOAD_REPORT_REQUEST,
  payload: {
    id, reportType, dateSignBeg, dateSignEnd, productIds, statusIds,
  },
});

const setReportStatus = (values) => ({
  type: SET_REPORT_STATUS,
  payload: values,
});
const setTagsForReport = (allTags, selectedTags, values) => ({
  type: SET_TAGS_FOR_REPORT,
  payload: { allTags, selectedTags, values },
});

const setReportSelectOption = (values, option, value) => ({
  type: SET_REPORT_SELECT,
  payload: { values, option, value },
});
const getTagsForReport = (type) => ({
  type: GET_REPORT_TAGS_REQUEST,
  payload: type,
});

const getPartnersForReportForm = () => ({
  type: GET_REPORT_TYPES_FOR_SELECT_REQUEST,
});

const getReportTypesForReportForm = () => ({
  type: GET_PARTNERS_FOR_SELECT_REQUEST,
});

const getRolesForReportsForm = () => ({
  type: GET_ROLES_FOR_SELECT_REQUEST,
});

const setRolesForReport = (selected, rolesReport, values) => ({
  type: SET_ROLES_FOR_REPORT,
  payload: { selected, rolesReport, values },
});

const getReportsForAdminTable = (offset, query) => ({
  type: GET_REPORTS_FOR_TABLE_REQUEST,
  payload: offset,
  query,
});

const setPageReportsAdminTable = (page) => ({
  type: SET_PAGE_PAGINATION,
  payload: page,
});

const createReport = (body, history) => ({
  type: CREATE_REPORT_REQUEST,
  payload: { body, history },
});

const updateReport = (id, body) => ({
  type: UPDATE_REPORT_REQUEST,
  payload: { body, id },
});

const clearEditorAdminReportsForm = () => ({
  type: CLEAR_FORM,
});

const getReportDataForUpdate = (id) => ({
  type: GET_REPORT_DATA_REQUEST,
  payload: id,
});

const setInitialValuesReportsAdministration = () => ({
  type: SET_INITIAL_VALUES,
});

const setFilteredTags = (values) => ({
  type: SET_NEW_TAGS_SUCCESS,
  payload: values,
});

export {
  closeDownloadPopup,
  getDownloadCheck,
  getDownloadList,
  getDownloadReport,
  setReportStatus,
  setTagsForReport,
  setReportSelectOption,
  getReportTypesForReportForm,
  getPartnersForReportForm,
  getTagsForReport,
  getRolesForReportsForm,
  setRolesForReport,
  setInitialValuesReportsAdministration,
  createReport,
  updateReport,
  clearEditorAdminReportsForm,
  getReportDataForUpdate,
  getReportsForAdminTable,
  setPageReportsAdminTable,
  setFilteredTags,
};
