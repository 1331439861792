import React, { Component } from 'react';
import { CheckDocumentListForm } from 'components';
import {
  toggleAdditionalInfoUnderwriting,
  getStatusUnderwriting,
  getAllBranchesUnderwriting,
  getStatusCheckingUnderwriting,
  changeSearchValueUnderwriting,
  toggleAdditionalSearchUnderwriting,
  searchContractsForUnderwriting,
  switchPaginationPageUnderwriting,
  setInitStateUnderwritingList,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import { checkAccessRoute } from 'roles';

const propTypes = {
  getStatus: PropTypes.func,
  getAllBranches: PropTypes.func,
  getStatusChecking: PropTypes.func,
  searchContracts: PropTypes.func,
  underwritingList: PropTypes.object,
  underwritingMaster: PropTypes.object,
  changeSearchValue: PropTypes.func,
  toggleInfo: PropTypes.func,
  toggleAdditionalSearch: PropTypes.func,
  location: PropTypes.object,
  setInitState: PropTypes.func,
  switchPaginationPage: PropTypes.func,
  history: PropTypes.object,
  branchId: PropTypes.number,
  roles: PropTypes.object,
};

const defaultProps = {
  setInitState: PropTypes.func,
  getStatus: () => null,
  getAllBranches: () => null,
  getStatusChecking: () => null,
  searchContracts: () => null,
  underwritingList: {},
  underwritingMaster: {},
  changeSearchValue: () => null,
  toggleInfo: () => null,
  toggleAdditionalSearch: () => null,
  switchPaginationPage: PropTypes.func,
  history: {},
  location: {
    state: {
      cash: false,
    },
  },
  branchId: 0,
  roles: {},
};

class CheckDocumentList extends Component {
  componentDidMount() {
    const {
      getStatus,
      getAllBranches,
      getStatusChecking,
      location: { state, pathname },
      setInitState,
      underwritingList: {
        config: {
          isDownloaded,
        },
      },
      roles,
    } = this.props;

    const access = checkAccessRoute(pathname, roles);

    if (access) {
      getAllBranches();
      getStatusChecking();
      getStatus();
    }

    const cashing = state && state.cash;
    const isSearch = !cashing || !isDownloaded;
    if (isSearch && access) {
      setInitState();
      this.handleSearch();
    }
  }

  handleSearch = () => {
    const {
      searchContracts,
      underwritingList: { searchValues },
    } = this.props;
    const {
      ctsNumOrFio,
      checkStatusId,
      ctsStatusId,
      searchBranchId,
      checkUserFio,
      dateCtsBeg,
      dateCtsEnd,
      dateCheckBeg,
      dateCheckEnd,
      userFio,
    } = searchValues;
    const body = {
      ctsNumOrFio,
      dateCheckBeg: dateCheckBeg || undefined,
      dateCheckEnd: dateCheckEnd || undefined,
      checkStatusId: checkStatusId || 0,
      ctsStatusId: ctsStatusId || 0,
      checkUserFio,
      userFio: userFio || '',
      searchBranchId: searchBranchId || 0,
      dateCtsBeg: dateCtsBeg || undefined,
      dateCtsEnd: dateCtsEnd || undefined,
    };

    searchContracts(1, body);
  };

  handleChange = ({ target: { value, name } }) => {
    const { changeSearchValue } = this.props;
    changeSearchValue(name, value);
  };

  handleUpPage = () => {
    const {
      searchContracts,
      underwritingList: {
        searchValues,
        page,
        totalPages,
      },
    } = this.props;
    const {
      ctsNumOrFio,
      checkStatusId,
      ctsStatusId,
      searchBranchId,
      checkUserFio,
      dateCtsBeg,
      dateCtsEnd,
      dateCheckBeg,
      dateCheckEnd,
      userFio,
    } = searchValues;

    const body = {
      ctsNumOrFio,
      dateCheckBeg: dateCheckBeg || undefined,
      dateCheckEnd: dateCheckEnd || undefined,
      checkStatusId: checkStatusId || 0,
      ctsStatusId: ctsStatusId || 0,
      checkUserFio,
      userFio: userFio || '',
      searchBranchId: searchBranchId || 0,
      dateCtsBeg: dateCtsBeg || undefined,
      dateCtsEnd: dateCtsEnd || undefined,
    };
    if (page !== totalPages) searchContracts(page + 1, body);
  };

  handleDownPage = () => {
    const {
      searchContracts,
      underwritingList: {
        searchValues,
        page,
      },
    } = this.props;
    const {
      ctsNumOrFio,
      checkStatusId,
      ctsStatusId,
      searchBranchId,
      checkUserFio,
      dateCtsBeg,
      dateCtsEnd,
      dateCheckBeg,
      dateCheckEnd,
      userFio,
    } = searchValues;
    const body = {
      ctsNumOrFio,
      dateCheckBeg: dateCheckBeg || undefined,
      dateCheckEnd: dateCheckEnd || undefined,
      checkStatusId: checkStatusId || 0,
      ctsStatusId: ctsStatusId || 0,
      checkUserFio,
      userFio: userFio || '',
      searchBranchId: searchBranchId || 0,
      dateCtsBeg: dateCtsBeg || undefined,
      dateCtsEnd: dateCtsEnd || undefined,
    };
    if (page > 1) { searchContracts(page - 1, body); }
  };

  getConfigSettings = () => {
    const { underwritingList: { config } } = this.props;
    const { showAdditionalSearch } = config;

    return showAdditionalSearch;
  };

  toggleAdditionalSearch = () => {
    const { toggleAdditionalSearch } = this.props;
    toggleAdditionalSearch(!this.getConfigSettings());
  };

  render() {
    const {
      underwritingList,
      toggleInfo,
      underwritingMaster,
      history,
    } = this.props;
    const {
      list,
      searchValues,
      config,
      page,
      totalPages,
    } = underwritingList;

    return (
      <CheckDocumentListForm
        data={list[0]}
        activePaginationPage={page}
        config={config}
        handleUpPage={this.handleUpPage}
        handleDownPage={this.handleDownPage}
        total={totalPages}
        history={history}
        helperData={underwritingMaster}
        toggleInfo={toggleInfo}
        handleChange={this.handleChange}
        handleSearch={this.handleSearch}
        toggleAdditionalSearch={this.toggleAdditionalSearch}
        showAdditionalSearch={this.getConfigSettings()}
        {...searchValues}
      />
    );
  }
}

const mapStateToProps = ({
  underwritingList,
  underwritingMaster,
  authReducer: {
    branchId,
    roles,
  },
}) => ({
  underwritingList,
  underwritingMaster,
  branchId,
  roles,
});
const mapDispatchToProps = {
  toggleInfo: toggleAdditionalInfoUnderwriting,
  getStatus: getStatusUnderwriting,
  getAllBranches: getAllBranchesUnderwriting,
  getStatusChecking: getStatusCheckingUnderwriting,
  changeSearchValue: changeSearchValueUnderwriting,
  toggleAdditionalSearch: toggleAdditionalSearchUnderwriting,
  searchContracts: searchContractsForUnderwriting,
  switchPaginationPage: switchPaginationPageUnderwriting,
  setInitState: setInitStateUnderwritingList,
};

CheckDocumentList.propTypes = propTypes;
CheckDocumentList.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(CheckDocumentList));
