const EXPORT_1C_REQUEST = 'EXPORT_1C_REQUEST';
const EXPORT_1C_START = 'EXPORT_1C_START';
const EXPORT_1C_SUCCESS = 'EXPORT_1C_SUCCESS';
const EXPORT_1C_FAIL = 'EXPORT_1C_FAIL';
const GET_CONTRACT_BY_NUMBER_REQUEST = 'GET_CONTRACT_BY_NUMBER_REQUEST';
const GET_CONTRACT_BY_NUMBER_START = 'GET_CONTRACT_BY_NUMBER_START';
const GET_CONTRACT_BY_NUMBER_FAIL = 'GET_CONTRACT_BY_NUMBER_FAIL';
const GET_CONTRACT_BY_NUMBER_SUCCESS = 'GET_CONTRACT_BY_NUMBER_SUCCESS';
const GET_INITIAL_STATE = 'GET_INITIAL_STATE';
const SET_CONTRACT_STATUS_REQUEST = 'SET_CONTRACT_STATUS_REQUEST';
const SET_CONTRACT_STATUS_START = 'SET_CONTRACT_STATUS_START';
const SET_CONTRACT_STATUS_FAIL = 'SET_CONTRACT_STATUS_FAIL';
const SET_CONTRACT_STATUS_SUCCESS = 'SET_CONTRACT_STATUS_SUCCESS';
const GET_CONTRACT_STATUSES_REQUEST = 'GET_CONTRACT_STATUSES_REQUEST';
const GET_CONTRACT_STATUSES_START = 'GET_CONTRACT_STATUSES_START';
const GET_CONTRACT_STATUSES_SUCCESS = 'GET_CONTRACT_STATUSES_SUCCESS';
const GET_CONTRACT_STATUSES_FAIL = 'GET_CONTRACT_STATUSES_FAIL';
const GET_CONTRACT_CURRENT_STATE_REQUEST = 'GET_CONTRACT_CURRENT_STATE_REQUEST';
const GET_CONTRACT_CURRENT_STATE_START = 'GET_CONTRACT_CURRENT_STATE_START';
const GET_CONTRACT_CURRENT_STATE_SUCCESS = 'GET_CONTRACT_CURRENT_STATE_SUCCESS';
const GET_CONTRACT_CURRENT_STATE_FAIL = 'GET_CONTRACT_CURRENT_STATE_FAIL';
const SET_SELECT_VALUE = 'SET_SELECT_VALUE';
const SET_PAYOUT_SUM_CONTRACT = 'SET_PAYOUT_SUM_CONTRACT';
const ADD_PAYMENT_TO_CONTRACT = 'ADD_PAYMENT_TO_CONTRACT';
const DELETE_PAYMENT_FROM_CONTRACT = 'DELETE_PAYMENT_FROM_CONTRACT';
const SET_DOWNLOAD_FILE_FROM_1C = 'SET_DOWNLOAD_FILE_FROM_1C';
const CLEAR_ACTIVE_IMPORT_TASK = 'CLEAR_ACTIVE_IMPORT_TASK';

const IMPORT_MANUAL_WEBSOCKET_EMIT = 'IMPORT_MANUAL_WEBSOCKET_EMIT';
const IMPORT_MANUAL_WEBSOCKET_START = 'IMPORT_MANUAL_WEBSOCKET_START';
const IMPORT_MANUAL_WEBSOCKET_ERROR = 'IMPORT_MANUAL_WEBSOCKET_ERROR';
const IMPORT_MANUAL_WEBSOCKET_SUCCESS = 'IMPORT_MANUAL_WEBSOCKET_ON_SUCCESS';
const IMPORT_MANUAL_WEBSOCKET_FAIL = 'IMPORT_MANUAL_WEBSOCKET_ON_FAIL';

const SET_INIT_STORE_IMPORT_MANUAL = 'SET_INIT_STORE_IMPORT_MANUAL';
const RESET_TRANSFER_DATA = 'transferData/RESET_TRANSFER_DATA';

export {
  RESET_TRANSFER_DATA,
  CLEAR_ACTIVE_IMPORT_TASK,
  SET_INIT_STORE_IMPORT_MANUAL,
  IMPORT_MANUAL_WEBSOCKET_EMIT,
  IMPORT_MANUAL_WEBSOCKET_START,
  IMPORT_MANUAL_WEBSOCKET_SUCCESS,
  IMPORT_MANUAL_WEBSOCKET_FAIL,
  SET_DOWNLOAD_FILE_FROM_1C,
  ADD_PAYMENT_TO_CONTRACT,
  DELETE_PAYMENT_FROM_CONTRACT,
  SET_PAYOUT_SUM_CONTRACT,
  SET_SELECT_VALUE,
  GET_CONTRACT_CURRENT_STATE_REQUEST,
  GET_CONTRACT_CURRENT_STATE_START,
  GET_CONTRACT_CURRENT_STATE_SUCCESS,
  GET_CONTRACT_CURRENT_STATE_FAIL,
  GET_CONTRACT_STATUSES_REQUEST,
  GET_CONTRACT_STATUSES_START,
  GET_CONTRACT_STATUSES_SUCCESS,
  GET_CONTRACT_STATUSES_FAIL,
  SET_CONTRACT_STATUS_SUCCESS,
  SET_CONTRACT_STATUS_FAIL,
  SET_CONTRACT_STATUS_START,
  SET_CONTRACT_STATUS_REQUEST,
  GET_INITIAL_STATE,
  GET_CONTRACT_BY_NUMBER_SUCCESS,
  GET_CONTRACT_BY_NUMBER_FAIL,
  GET_CONTRACT_BY_NUMBER_REQUEST,
  GET_CONTRACT_BY_NUMBER_START,
  EXPORT_1C_FAIL,
  EXPORT_1C_REQUEST,
  EXPORT_1C_START,
  EXPORT_1C_SUCCESS,
  IMPORT_MANUAL_WEBSOCKET_ERROR,
};
