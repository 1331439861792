import {
  put,
  take,
  takeLatest,
} from 'redux-saga/effects';

import {
  apiCall,
  getError,
} from 'helpers';
import { API, ROUTES } from 'consts';
import {
  setKszFieldsTouched,
  clearStoreKszProducts,
  setInitFormBeneficiary,
  resetValidationTerrorist,
  clearStoreAgentData,
} from 'redux/rootActions';

import {
  GET_PRODUCT_INFO,
  CHECK_ACCOUNT_NUMBERS,
  COPY_KSZ_CONTRACT,
} from '../types';

function* checkAccountNumbers({ payload }) {
  try {
    yield put({ type: CHECK_ACCOUNT_NUMBERS.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CHECK_ACCOUNT_NUMBERS,
      query: {
        num: payload,
      },
    });
    yield put({ type: CHECK_ACCOUNT_NUMBERS.success, payload: data });

    return data;
  } catch (e) {
    yield put({ type: CHECK_ACCOUNT_NUMBERS.fail, payload: getError(e) });

    return false;
  }
}

function* getProductInfo({ payload }) {
  try {
    yield put({ type: GET_PRODUCT_INFO.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.GET_PRODUCT_FORM,
      body: {
        id: payload,
      },
    });

    const rebuildData = {
      ...data,
    };

    yield put({ type: GET_PRODUCT_INFO.success, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_PRODUCT_INFO.fail, payload: getError(e) });
  }
}

function* copyKszContact({ payload }) {
  const { GET_CONTRACT_COPY } = API;
  const { id, history } = payload;
  try {
    yield put({ type: COPY_KSZ_CONTRACT.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_CONTRACT_COPY,
      body: { ctsId: id },
    });

    yield put(clearStoreKszProducts());
    yield put(clearStoreAgentData());
    yield put(setInitFormBeneficiary());
    yield put(resetValidationTerrorist());

    history.push(`${ROUTES.kszParameters}/${data.productId}`);

    yield take(GET_PRODUCT_INFO.success);
    yield put(setKszFieldsTouched());
    yield put({ type: COPY_KSZ_CONTRACT.success, payload: data });
  } catch (e) {
    yield put({ type: COPY_KSZ_CONTRACT.fail, payload: getError(e) });
  }
}

function* kszProductSaga() {
  yield takeLatest(GET_PRODUCT_INFO.request, getProductInfo);
  yield takeLatest(CHECK_ACCOUNT_NUMBERS.request, checkAccountNumbers);
  yield takeLatest(COPY_KSZ_CONTRACT.request, copyKszContact);
}

export default kszProductSaga;
