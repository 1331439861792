import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import {
  FlexTable,
  CheckCircleOrCloseIcon,
  FlexTableAdminUsers,
  BranchesPopUp,
  FlexTableBranchesProducts,
} from 'components';
import { Icon } from 'semantic-ui-react';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import UserIcon from 'assets/images/branches-user.png';
import CaseIcon from 'assets/images/case-icon.png';
import template from './template';
import './styles.scss';

const {
  FULL_TREE_BRANCH,
  NAMING_BRANCHES,
  ADDRESS,
  PARTNER,
  ACTIVED,
  USERS,
  PRODUCTS_WORD,
  MANAGMENT,
  NOT_FOUND_DATA,
  IS_TEST,
} = LANG_DICTIONARY;

const b = block('branches-admin-table');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  popUpControl: PropTypes.object,
  branchesPopUpOpen: PropTypes.bool,
  getPopUpInfo: PropTypes.func,
  dictionaryLocal: PropTypes.object,
  popUpInfo: PropTypes.object,
};

const defaultProps = {
  data: [],
  history: {},
  popUpControl: () => null,
  branchesPopUpOpen: false,
  getPopUpInfo: () => null,
  dictionaryLocal: {},
  popUpInfo: {},
};

const headers = [
  USERS,
  PRODUCTS_WORD,
  MANAGMENT,
  FULL_TREE_BRANCH,
  NAMING_BRANCHES,
  ADDRESS,
  PARTNER,
  ACTIVED,
  IS_TEST,
];

const FlexTableAdminBranches = (
  {
    data,
    history,
    popUpControl,
    branchesPopUpOpen,
    getPopUpInfo,
    dictionaryLocal,
    popUpInfo,
  },
) => {
  const {
    USERS_WORD,
    PRODUCTS_WORD: PRODUCTS,
  } = dictionaryLocal;
  const getControls = (id) => (
    <div
      className={b('controls')}
    >
      <div
        role="button"
        tabIndex={-1}
        onClick={() => history.push(`${ROUTES.updateBranch}/${id}`)}
        className={b('control-button')}
      >
        <img
          src={EditIcon}
          className={b('edit-icon')}
          alt="edit-icon"
        />
      </div>
      <div
        role="button"
        tabIndex={-1}
        onClick={() => history.push(ROUTES.createBranch, { parent: true, id })}
        className={b('control-button')}
      >
        <Icon name="add circle" />
      </div>
    </div>
  );

  const rebuildData = data
    .map(({
      fullAddr,
      id,
      isActive,
      name,
      nameCp,
      treeName,
      isTest,
    }) => template(headers, [
      <div
        role="button"
        tabIndex={-1}
        className={b('control-wrapper')}
        onClick={() => getPopUpInfo(id, USERS_WORD)}
      >
        <img
          className={b('user-icon')}
          src={UserIcon}
          alt="user-icon"
        />
      </div>,
      <div
        role="button"
        tabIndex={-1}
        className={b('control-wrapper')}
        onClick={() => getPopUpInfo(id, PRODUCTS)}
      >
        <img
          className={b('user-icon')}
          src={CaseIcon}
          alt="case-icon"
        />
      </div>,
      getControls(id),
      treeName,
      name,
      fullAddr,
      nameCp,
      <CheckCircleOrCloseIcon status={isActive} />,
      <CheckCircleOrCloseIcon status={isTest} />,
      `${id}branch`,
    ]));

  const getPopUp = () => {
    if (popUpInfo.tableInfo && !popUpInfo.tableInfo.length) {
      return <div className={b('not-found')}>{NOT_FOUND_DATA}</div>;
    }
    if (popUpInfo.type === PRODUCTS) {
      return <FlexTableBranchesProducts tableInfo={popUpInfo.tableInfo} />;
    }
    if (popUpInfo.type === USERS_WORD) {
      return <FlexTableAdminUsers data={popUpInfo.tableInfo} popUpUsers />;
    }

    return null;
  };

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
      <BranchesPopUp
        isOpen={branchesPopUpOpen}
        closeAction={() => popUpControl(false)}
        children={getPopUp()}
      />
    </div>
  );
};

FlexTableAdminBranches.propTypes = propTypes;
FlexTableAdminBranches.defaultProps = defaultProps;
export default withCustomRouter(FlexTableAdminBranches);
