import { put, takeLatest } from 'redux-saga/effects';
import {
  apiCall,
  getError,
  filterEmptyProps,
  simpleDate,
} from 'helpers';
import { API, LANG_DICTIONARY } from 'consts';
import { ITEM_COUNT_ADMIN_TABLE_PARTNERS } from 'config';
import { toastr } from 'react-redux-toastr';
import { setPagePartnersAdminTable } from '../actions';
import {
  CREATE_PARTNER_FAIL,
  CREATE_PARTNER_REQUEST,
  CREATE_PARTNER_START,
  CREATE_PARTNER_SUCCESS,
  GET_PARTNERS_FOR_TABLE_FAIL,
  GET_PARTNERS_FOR_TABLE_REQUEST,
  GET_PARTNERS_FOR_TABLE_START,
  GET_PARTNERS_FOR_TABLE_SUCCESS,
  GET_PARTNER_DATA_FAIL,
  GET_PARTNER_DATA_REQUEST,
  GET_PARTNER_DATA_START,
  GET_PARTNER_DATA_SUCCESS,
  UPDATE_PARTNER_FAIL,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_START,
  UPDATE_PARTNER_SUCCESS,
} from '../types';

const { PARTNER_CREATED, PARTNER_UPDATED } = LANG_DICTIONARY;

function* createPartner({ payload }) {
  try {
    const rebuildBody = filterEmptyProps(payload);
    if (rebuildBody.dateContract) {
      rebuildBody.dateContract = simpleDate(rebuildBody.dateContract);
    }
    yield put({ type: CREATE_PARTNER_START });
    yield apiCall({
      url: API.PARTNERS,
      type: 'POST',
      body: rebuildBody,
    });
    yield put({ type: CREATE_PARTNER_SUCCESS });
    toastr.success('', PARTNER_CREATED);
  } catch (e) {
    yield put({ type: CREATE_PARTNER_FAIL, error: getError(e) });
  }
}

function* updatePartner({ payload }) {
  try {
    const { body, id } = payload;
    const rebuildBody = filterEmptyProps(body);
    delete rebuildBody.id;
    if (rebuildBody.dateContract) {
      rebuildBody.dateContract = simpleDate(rebuildBody.dateContract);
    }

    const pereRebuildBody = {
      actived: rebuildBody.actived,
      codeValue: rebuildBody.codeValue,
      contrAgent: rebuildBody.contrAgent,
      fullNaming: rebuildBody.fullNaming,
      includePartner: rebuildBody.includePartner,
      ipAddresses: rebuildBody.ipAddressesCheckbox ?
        null :
        rebuildBody.ipAddresses.map((item) => item.value),
      isPartnerRefersToBcsSk: rebuildBody.isPartnerRefersToBcsSk,
      isReportsAvailable: rebuildBody.isReportsAvailable,
      isRulesAccepting: rebuildBody.isRulesAccepting,
      legalType: rebuildBody.legalType,
      shortNaming: rebuildBody.shortNaming,
      showOnSite: rebuildBody.showOnSite,
      inn: rebuildBody.inn,
      ogrn: rebuildBody.ogrn,
    };
    yield put({ type: UPDATE_PARTNER_START });
    yield apiCall({
      url: `${API.PARTNERS}/id${id}`,
      type: 'PUT',
      body: pereRebuildBody,
    });
    yield put({ type: UPDATE_PARTNER_SUCCESS });
    toastr.success('', PARTNER_UPDATED);
  } catch (e) {
    yield put({ type: UPDATE_PARTNER_FAIL, error: getError(e) });
  }
}

function* getPartnersForTable({ payload, query }) {
  try {
    yield put({ type: GET_PARTNERS_FOR_TABLE_START });
    const { data } = yield apiCall({
      url: API.GET_PARTNERS_FOR_ADMIN_TABLE,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_PARTNERS * (payload - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_PARTNERS,
        ...query,
      },
    });
    yield put({ type: GET_PARTNERS_FOR_TABLE_SUCCESS, payload: data });
    if (!data.data.length && payload !== 1) {
      yield put(setPagePartnersAdminTable(payload - 1));
      yield getPartnersForTable({ payload: payload - 1, query });
    } else {
      yield put(setPagePartnersAdminTable(payload));
    }
  } catch (e) {
    yield put({ type: GET_PARTNERS_FOR_TABLE_FAIL, error: getError(e) });
  }
}

function* getPartnerDataForUpdate({ payload }) {
  try {
    yield put({ type: GET_PARTNER_DATA_START });
    const { data } = yield apiCall({
      url: `${API.PARTNERS}/id${payload}`,
      type: 'GET',
    });
    yield put({ type: GET_PARTNER_DATA_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PARTNER_DATA_FAIL, error: getError(e) });
  }
}

function* partnersSaga() {
  yield takeLatest(GET_PARTNER_DATA_REQUEST, getPartnerDataForUpdate);
  yield takeLatest(CREATE_PARTNER_REQUEST, createPartner);
  yield takeLatest(UPDATE_PARTNER_REQUEST, updatePartner);
  yield takeLatest(GET_PARTNERS_FOR_TABLE_REQUEST, getPartnersForTable);
}

export default partnersSaga;
