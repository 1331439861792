import { getTouchedProps, copyBeneficiaries } from 'helpers';
import { DEFAULT_COUNTRY } from 'config';
import { GET_CONTRACT_COPY_SUCCESS } from 'redux/reducers/types';
import { PREFIX } from '../enums';
import {
  SET_FIELD_VALUE,
  SET_STATUS_TAB,
  SET_ACTIVE_TAB,
  SET_SHARED_COUNT_ERROR,
  SET_ERRORS,
  SET_TOUCHED,
  SET_ADDRESS,
  SET_FMS_UNIT,
  SET_LIVING_ADDRESS_IS_SAME,
  SET_ALL_TOUCHED,
  SET_INIT_FORM,
  SET_COUNT_BENEFICIARIES,
  SET_IS_BENEFICIARIES,
} from '../types';
import { COPY_CONTRACT } from '../../agentData/types';
import { COPY_KSZ_CONTRACT, FILL_TEST_DATA_BENEFICIARY_KSZ } from '../../kszProduct/types';
import { FILL_TEST_DATA_BENEFICIARY_NSZ } from '../../nszProduct/types';
import { FILL_BENEFECIARES_DATA_ISZ } from '../../reducers/types';

const INIT_STATE_FORM_DATA_DOCUMENTS = {
  documentType: 1,
  serial: '',
  passportNumber: '',
  issuedBy: '',
  when: null,
  validUntil: null,
  kp: '',
  inn: '',
  snils: '',
  issuedByAccepted: false,
};

const INIT_STATE_FORM_DATA_CONTACTS = {
  contactPhone: '',
  email: '',
  homePhone: '',
  mobilePhone: '',
  workPhone: '',
};

const INIT_STATE_FORM_DATA_ADDRESS = {
  addressFias: {},
  addressFiasLiving: {},
  livingAddressIsSame: true,
  apartment: '',
  apartmentLiving: '',
  area: '',
  areaLiving: '',
  city: '',
  cityLiving: '',
  country: DEFAULT_COUNTRY,
  countryLiving: DEFAULT_COUNTRY,
  house: '',
  houseLiving: '',
  houseNumber: '',
  houseNumberLiving: '',
  index: '',
  indexLivin: '',
  republic: '',
  republicLiving: '',
  street: '',
  streetLiving: '',
};

const INIT_STATE_FORM_DATA = {
  firstName: '',
  lastName: '',
  middleName: '',
  birthDate: null,
  gender: null,
  relationships: null,
  insuredSum: '',
  birthPlace: '',
};

const INIT_STATE_BENEFICIARIES = {
  formData: INIT_STATE_FORM_DATA,
  formDataDocuments: INIT_STATE_FORM_DATA_DOCUMENTS,
  formDataAddress: INIT_STATE_FORM_DATA_ADDRESS,
  formDataContacts: INIT_STATE_FORM_DATA_CONTACTS,
  formDataErrors: {},
  formDataDocumentsErrors: {},
  formDataContactsErrors: {},
  formDataAddressErrors: {},
  formDataTouched: {},
  formDataDocumentsTouched: {},
  formDataContactsTouched: {},
  formDataAddressTouched: {},
  sharedCountErrors: 0,
  sharedCountErrorsDocuments: 0,
  sharedCountErrorsContacts: 0,
  sharedCountErrorsAddress: 0,
  livingAddressIsSame: true,
  contacts: false,
  address: false,
  documents: false,
  active: '',
};

const INITIAL_STATE = {
  beneficiary1: INIT_STATE_BENEFICIARIES,
  beneficiary2: INIT_STATE_BENEFICIARIES,
  beneficiary3: INIT_STATE_BENEFICIARIES,
  beneficiary4: INIT_STATE_BENEFICIARIES,
  isBeneficiaries: true,
  countBeneficiaries: 1,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case FILL_TEST_DATA_BENEFICIARY_KSZ:
    case FILL_TEST_DATA_BENEFICIARY_NSZ:
    case FILL_BENEFECIARES_DATA_ISZ: {
      const {
        beneficiaries,
        beneficiaryInLaw,
      } = payload;

      if (beneficiaryInLaw) {
        return {
          ...state,
        };
      }

      return {
        ...state,
        ...beneficiaries,
      };
    }

    case SET_FIELD_VALUE: {
      const {
        value,
        name,
        index,
        form = '',
      } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [`formData${form}`]: {
            ...state[`${PREFIX}${index}`][`formData${form}`],
            [name]: value,
          },
        },
      };
    }

    case SET_ALL_TOUCHED: {
      const { touched, index, form } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [`formData${form}Touched`]: touched,
        },
      };
    }

    case SET_ERRORS: {
      const { errors, form, index } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [`formData${form}Errors`]: errors,
        },
      };
    }

    case COPY_CONTRACT.success:
    case COPY_KSZ_CONTRACT.success:
    case GET_CONTRACT_COPY_SUCCESS: {
      const {
        beneficiary1,
        beneficiary2,
        beneficiary3,
        beneficiary4,
      } = state;
      const beneficiaries = copyBeneficiaries(INIT_STATE_BENEFICIARIES, payload);

      return {
        beneficiary1: beneficiaries[0] ? beneficiaries[0] : beneficiary1,
        beneficiary2: beneficiaries[1] ? beneficiaries[1] : beneficiary2,
        beneficiary3: beneficiaries[2] ? beneficiaries[2] : beneficiary3,
        beneficiary4: beneficiaries[3] ? beneficiaries[3] : beneficiary4,
        isBeneficiaries: payload.isBeneficiaryByLaw,
        countBeneficiaries: payload.beneficiary.length || 1,
      };
    }

    case SET_TOUCHED: {
      const {
        value,
        form,
        index,
        name,
      } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [`formData${form}Touched`]: {
            ...state[`${PREFIX}${index}`][`formData${form}Touched`],
            [name]: value,
          },
        },
      };
    }

    case SET_STATUS_TAB: {
      const { tabName, index, status } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [tabName.toLowerCase()]: status,
        },
      };
    }

    case SET_SHARED_COUNT_ERROR: {
      const {
        index,
        form = '',
        count,
      } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          [`sharedCountErrors${form}`]: count,
        },
      };
    }

    case SET_ACTIVE_TAB: {
      const { tabName, index } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          active: tabName,
        },
      };
    }

    case SET_ADDRESS: {
      const { index, values } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          formDataAddress: {
            ...state[`${PREFIX}${index}`].formDataAddress,
            ...values,
          },
          formDataAddressTouched: {
            ...state[`${PREFIX}${index}`].formDataAddressTouched,
            ...getTouchedProps(values),
          },
        },
      };
    }

    case SET_FMS_UNIT: {
      const { index, values } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          formDataDocuments: {
            ...state[`${PREFIX}${index}`].formDataDocuments,
            ...values,
          },
        },
      };
    }

    case SET_LIVING_ADDRESS_IS_SAME: {
      const { index, status } = payload;

      return {
        ...state,
        [`${PREFIX}${index}`]: {
          ...state[`${PREFIX}${index}`],
          formDataAddress: {
            ...state[`${PREFIX}${index}`].formDataAddress,
            livingAddressIsSame: status,
          },
        },
      };
    }

    case SET_COUNT_BENEFICIARIES:
      return {
        ...state,
        countBeneficiaries: payload,
      };

    case SET_IS_BENEFICIARIES:
      return {
        ...state,
        isBeneficiaries: payload,
      };

    case SET_INIT_FORM:
      return INITIAL_STATE;

    default:
      return state;
  }
};
