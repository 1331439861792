import React from 'react';
import PropTypes from 'prop-types';
import { Button, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { TextInput } from 'components/_deprecated';
import { object, ref, string } from 'yup';
import { LANG_DICTIONARY } from 'consts';
import { MIN_LENGTH_PASSWORD } from 'config';
import { getErrorsFromValidationError } from 'helpers';

const { REQUIRED, AT_LEAST_EIGHT, PASSWORDS_NOT_A_SAME } = LANG_DICTIONARY;

const schema = object().shape({
  userPassword: string()
    .min(MIN_LENGTH_PASSWORD, AT_LEAST_EIGHT)
    .required(REQUIRED),
  confirmUserPassword: string()
    .oneOf([ref('userPassword'), null], PASSWORDS_NOT_A_SAME)
    .required(REQUIRED),
});

const propTypes = {
  handleSubmit: PropTypes.func.isRequired,
  placeholders: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
  titles: PropTypes.shape({
    password: PropTypes.string,
    confirmPassword: PropTypes.string,
  }),
  bem: PropTypes.func,
  isLoading: PropTypes.bool,
  textBtn: PropTypes.string.isRequired,
};

const defaultProps = {
  placeholders: {
    password: '',
    confirmPassword: '',
  },
  titles: {
    password: '',
    confirmPassword: '',
  },
  bem: () => '',
  isLoading: false,
};

const SetPasswordForm = (props) => {
  const {
    handleSubmit: handleSubmitForm,
    placeholders: {
      password: passwordPlaceholder,
      confirmPassword: confirmPasswordPlaceholder,
    },
    titles: {
      password: passwordTitle,
      confirmPassword: confirmPasswordTitle,
    },
    bem,
    isLoading,
    textBtn,
  } = props;

  const validateForm = ({ userPassword, confirmUserPassword }) => {
    try {
      schema.validateSync({ userPassword, confirmUserPassword }, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };

  return (
    <Formik
      initialValues={{ userPassword: '', confirmUserPassword: '' }}
      validate={(values) => validateForm(values)}
      onSubmit={handleSubmitForm}
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        handleSubmit,
        isSubmitting,
      }) => {
        const inputProps = {
          values,
          errors,
          touched,
          onChange: handleChange,
          onBlur: handleBlur,
        };

        return (
          <Form onSubmit={handleSubmit} loading={isLoading}>
            <TextInput
              name="userPassword"
              type="password"
              placeholder={passwordPlaceholder}
              label={passwordTitle}
              {...inputProps}
            />
            <TextInput
              name="confirmUserPassword"
              type="password"
              placeholder={confirmPasswordPlaceholder}
              label={confirmPasswordTitle}
              {...inputProps}
            />
            <div className={bem('form', 'interface')}>
              <Button
                type="submit"
                className="auth__signInButton"
                disabled={isSubmitting}
              >
                { textBtn }
              </Button>
            </div>
          </Form>
        );
      }}
    </Formik>
  );
};

SetPasswordForm.defaultProps = defaultProps;
SetPasswordForm.propTypes = propTypes;

export default SetPasswordForm;
