import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import * as actionsBasketConstructor from 'redux/basketConstructor/actions';
import { CalculatedPersonalBasketsForm } from 'components';
import { withCustomRouter } from 'HOC';
import {
  selectCalculatedPersonBasket,
  selectConstructorTableControlData,
  selectSettingsProduct,
  selectBasketFiltering,
  selectBasketSorting,
  selectMinInsuranceSumTables,
  selectAvailableQuantityRequests,
  selectProductTypes,
  selectWarningMessage,
  selectJustNowExecutionBasketPayload,
  selectIsExecutionLoading,
  selectOptionType,
  selectExecutionDpPolicyLoading,
  selectPreExecutionDpPolicyLoading,
  selectIsExecutionDpPolicyPopup,
  selectIsExecutionDpPolicySuccessPopup,
  selectProductForm,
  selectDpPolicyExecutionUser,
} from 'redux/basketConstructor/selectors';
import { DEFAULT_VALUE_GUARANTEE } from 'config';
import { LANG_DICTIONARY } from 'consts';
import { getCountRow } from 'redux/basketConstructor/reducers';
import { getIsEditBasket } from 'redux/reducers/selectors';
import { getQualificationFormSelector } from 'redux/rootSelectors';

const {
  WARNING_CHILD_STRATEGY_TERM_MORE,
  WARNING_LIMIT_MAX_STRATEGIES,
  WARNING_EXECUTE_CALCULATING_BASKET,
  WARNING_BASKET_NEGATE_COUPON,
} = LANG_DICTIONARY;

const propTypes = {
  settings: PropTypes.object.isRequired,
  toggleOpenCalculatedPersonalChildBasket: PropTypes.func.isRequired,
  tableControl: PropTypes.object.isRequired,
  clearSortAndFiltering: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  minInsuranceSum: PropTypes.number,
  availableQuantityRequests: PropTypes.number,
  match: PropTypes.object.isRequired,
  getRequestCount: PropTypes.func.isRequired,
  getProductSettings: PropTypes.func.isRequired,
  getCalculatedPersonBaskets: PropTypes.func.isRequired,
  baskets: PropTypes.object.isRequired,
  filtering: PropTypes.object.isRequired,
  sorting: PropTypes.object.isRequired,
  copyRequest: PropTypes.func.isRequired,
  getMinInsuranceSumTables: PropTypes.func.isRequired,
  setMinInsuranceSumTables: PropTypes.func.isRequired,
  selectCalculatedPersonBasket: PropTypes.func.isRequired,
  selected: PropTypes.object,
  productTypes: PropTypes.arrayOf(PropTypes.object).isRequired,
  selectChildPersonCalculatedBasket: PropTypes.func.isRequired,
  clearSelectedItemsCalculatedPersonBasket: PropTypes.func.isRequired,
  executionCalculatingBasket: PropTypes.func.isRequired,
  executionCalculatingDpPolicyBasket: PropTypes.func.isRequired,
  generateReport: PropTypes.func.isRequired,
  setWarningMessage: PropTypes.func.isRequired,
  justNowExecutionBasketPayload: PropTypes.object.isRequired,
  warningMessage: PropTypes.string.isRequired,
  checkJustNowExecutionBasket: PropTypes.func.isRequired,
  isExecutionLoading: PropTypes.bool.isRequired,
  options: PropTypes.arrayOf(PropTypes.object),
  isEditBasket: PropTypes.bool,
  qualification: PropTypes.number,
  preExecutionCalculatingDpPolicyBasket: PropTypes.func.isRequired,
  clearCalculatedBasket: PropTypes.func.isRequired,
  isViewConstructorK1: PropTypes.bool,
};

const defaultProps = {
  minInsuranceSum: null,
  availableQuantityRequests: null,
  selected: null,
  options: [],
  isEditBasket: false,
  qualification: null,
  isViewConstructorK1: null,
};

class CalculatedPersonalBasketsComponent extends React.Component {
  state = {
    isGuaranteeLevelWarning: false,
    guaranteeLevelWarningAction: () => null,
  }

  componentDidMount() {
    const {
      match: { params: { id } },
      getRequestCount,
      getProductSettings,
      tableControl: {
        currencyCode,
      },
    } = this.props;

    getRequestCount();
    getProductSettings(id, currencyCode);
  }

  componentWillUnmount() {
    const { clearCalculatedBasket } = this.props;
    clearCalculatedBasket();

    if (this.tablesAbortController) {
      this.tablesAbortController.abort();
    }
  }

  handleUpdateTable = () => {
    this.getBaskets(false, { offset: 0, limit: getCountRow() });
    this.resetMinInsuranceSum();
  }

  handleEndScrollTable = () => {
    const {
      baskets: {
        data,
        offset,
      },
    } = this.props;
    this.getBaskets(true, { limit: data.length + getCountRow(), offset });
  }

  getSortingAndFiltering = () => {
    const {
      filtering: { filter },
      sorting,
    } = this.props;

    const filters = Object.entries(filter)
      .reduce((acc, [name, value]) => {
        if (value) {
          return [
            ...acc,
            {
              name,
              value,
              isExact: false,
            }];
        }

        return acc;
      }, []);

    const sort = Object.values(sorting)
      .sort((a, b) => a.timestamp - b.timestamp)
      .reduce((acc, payload) => {
        if (!payload.type) {
          return acc;
        }

        return [
          ...acc,
          {
            name: payload.name,
            type: payload.type,
          }];
      }, []);

    return {
      sort,
      filters,
    };
  }

  getBaskets = (isScrollUpdate = false, { offset, limit }) => {
    if (this.tablesAbortController) {
      this.tablesAbortController.abort();
    }

    const {
      match: { params: { id } },
      getCalculatedPersonBaskets,
      options,
      tableControl: {
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
        isOnlyFavorite,
      },
    } = this.props;

    // На следующей итерации event loop после отмены предыдущего запроса
    setTimeout(() => {
      this.tablesAbortController = new AbortController();

      getCalculatedPersonBaskets({
        partnersInsProductId: id,
        refCurrencyCode: currencyCode,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode: generalOptionCode,
        refProductTypeCode: productTypeCode,
        offset,
        limit,
        isScrollUpdate,
        isOnlyFavorite,
        optionsArray: options,
        abortSignal: this.tablesAbortController.signal,
        ...this.getSortingAndFiltering(),
      });
    });
  }

  generateReport = () => {
    const {
      generateReport,
      tableControl: {
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
      },
      match: { params: { id: partnersInsProductId } },
    } = this.props;

    if (this.reportAbortController) {
      this.reportAbortController.abort();
    }

    this.reportAbortController = new AbortController();

    generateReport({
      partnersInsProductId,
      guaranteeLevel,
      refCurrencyCode: currencyCode,
      refIszOptionTypeCode: generalOptionCode,
      refProductTypeCode: productTypeCode,
      abortSignal: this.reportAbortController.signal,
      ...this.getSortingAndFiltering(),
    });
  }

  handleClickCopyRequest = () => {
    const {
      copyRequest,
      baskets: {
        selected,
        childSelectedIds,
      },
      match: { params: { id: partnersInsProductId } },
      tableControl: {
        productTypeCode,
        currencyCode,
      },
      productTypes,
      setWarningMessage,
    } = this.props;

    const productType = productTypes.find(({ code }) => code === productTypeCode);

    const childRequestsPayload = Object.entries(childSelectedIds)
      .reduce((acc, [key, value]) => {
        const isTermValid = acc.isTermValid && !(selected && value && value.term > selected.term);

        if (value) {
          return {
            ids: [
              ...acc.ids,
              key,
            ],
            isTermValid,
          };
        }

        return {
          ...acc,
          isTermValid,
        };
      }, {
        ids: [],
        isTermValid: true,
      });

    if (childRequestsPayload.ids.length + 1 > productType.maxStrategiesAmount) {
      setWarningMessage(`${WARNING_LIMIT_MAX_STRATEGIES(productType.maxStrategiesAmount)}`);

      return;
    }

    if (!childRequestsPayload.isTermValid) {
      setWarningMessage(`${WARNING_CHILD_STRATEGY_TERM_MORE}!`);

      return;
    }

    copyRequest({
      insLineIszCalcRequestId: selected && selected.id,
      childInsLineIszCalcRequestIds: childRequestsPayload.ids,
      partnersInsProductId,
      refProductTypeCode: productTypeCode,
      refCurrencyCode: currencyCode,
      garantSchedule: selected?.insLineIszCalcBasketGarantSchedule,
    });
  }

  handleClickGetMinInsuranceSum = () => {
    const {
      baskets: {
        selected,
      },
      match: { params: { id: partnersInsProductId } },
      tableControl: {
        currencyCode,
      },
      getMinInsuranceSumTables,
      qualification,
    } = this.props;

    getMinInsuranceSumTables({
      insLineIszCalcRequestId: selected.id,
      partnersInsProductId,
      refCurrencyCode: currencyCode,
      qualification,
    });
  }

  resetMinInsuranceSum = () => {
    const {
      setMinInsuranceSumTables,
    } = this.props;

    setMinInsuranceSumTables([]);
  }

  handleSelectBasket = (basket) => {
    const {
      selectCalculatedPersonBasket: selectCalculatedPersonBasketAction,
      selectChildPersonCalculatedBasket,
      baskets: {
        selected,
        openChildIds,
      },
    } = this.props;

    const selectedId = selected && selected.id;
    const isAlreadySelected = selectedId === basket.id;

    const mainSelected = isAlreadySelected ?
      null :
      basket;

    selectCalculatedPersonBasketAction(mainSelected);

    if (!isAlreadySelected) {
      /* Выделение всех дочерних при выборе основной */
      basket.child.forEach((item) => {
        selectChildPersonCalculatedBasket(item.id, item);
      });

      if (selectedId && !openChildIds[selectedId]) {
        /* Снятие выделения дочерних с предыдущей выделенной основной корзины */
        selected.child.forEach((item) => {
          selectChildPersonCalculatedBasket(item.id, null);
        });
      }
    }

    this.resetMinInsuranceSum();
  }

  handleSelectChildBasket = (basket) => {
    const {
      selectChildPersonCalculatedBasket,
      baskets,
    } = this.props;

    const selectedBasket = baskets.childSelectedIds[basket.id] ?
      null :
      basket;

    selectChildPersonCalculatedBasket(basket.id, selectedBasket);
  }

  handleToggleOpenCalculatedPersonalChildBasket = (toggledMainBasket) => {
    const {
      toggleOpenCalculatedPersonalChildBasket,
      selectChildPersonCalculatedBasket,
      baskets: {
        selected,
        openChildIds,
      },
    } = this.props;

    const selectedId = selected && selected.id;
    const isSelected = selectedId === toggledMainBasket.id;

    const toggledMainBasketState = openChildIds[toggledMainBasket.id] ?
      null :
      toggledMainBasket;

    if (!isSelected) {
      /* Снятие выделения дочерних при их закрытии, если корзина не была выделена */
      toggledMainBasket.child.forEach((item) => {
        selectChildPersonCalculatedBasket(item.id, null);
      });
    }

    toggleOpenCalculatedPersonalChildBasket(toggledMainBasket.id, toggledMainBasketState);
  }

  handleClickClearSortAndFiltering = () => {
    const {
      clearSelectedItemsCalculatedPersonBasket,
      clearSortAndFiltering,
    } = this.props;
    clearSelectedItemsCalculatedPersonBasket();
    clearSortAndFiltering();
    this.resetMinInsuranceSum();
  }

  getExecutionBasketPayload = () => {
    const {
      baskets: {
        childSelectedIds,
        selected,
      },
      tableControl,
      match: { params: { id: partnersInsProductId } },
      setWarningMessage,
      qualification,
    } = this.props;

    if (!selected) {
      setWarningMessage(`${WARNING_EXECUTE_CALCULATING_BASKET}.`);

      return null;
    }

    const childSelectedIdsMap = new Map(selected.child.map((item) => [item.id, item]));
    const childChecking = Object.entries(childSelectedIds)
      .reduce((acc, [key, value]) => {
        if (!value) {
          return {
            count: acc.count,
            isAllSelectedChildForMainBasket: acc.isAllSelectedChildForMainBasket,
          };
        }

        const isAllSelectedChildForMainBasket = acc.isAllSelectedChildForMainBasket
          && Boolean(childSelectedIdsMap.get(Number(key)));

        return {
          count: acc.count + 1,
          isAllSelectedChildForMainBasket,
        };
      }, { count: 0, isAllSelectedChildForMainBasket: true });

    const isError = childChecking.count !== selected.child.length ||
      !childChecking.isAllSelectedChildForMainBasket;

    if (isError) {
      setWarningMessage(`${WARNING_EXECUTE_CALCULATING_BASKET}.`);

      return null;
    }

    const isNegateCalcValue = selected.yield1 < 0 && selected.yield2 < 0;

    if (isNegateCalcValue) {
      setWarningMessage(WARNING_BASKET_NEGATE_COUPON);

      return null;
    }

    return {
      mainInsLineIszCalcRequestId: selected.id,
      childInsLineIszCalcRequestIds: selected.child.map(({ id }) => id),
      guaranteeLevel: tableControl.guaranteeLevel,
      refIszOptionTypeCode: tableControl.generalOptionCode,
      refCurrencyCode: tableControl.currencyCode,
      refProductTypeCode: tableControl.productTypeCode,
      partnersInsProductId,
      qualification,
    };
  }

  handleClickExecuteBasket = (isFinished) => {
    const { executionCalculatingBasket, tableControl } = this.props;

    if (!isFinished && tableControl.guaranteeLevel !== DEFAULT_VALUE_GUARANTEE) {
      this.setState({
        isGuaranteeLevelWarning: true,
        guaranteeLevelWarningAction: () => this.handleClickExecuteBasket(true),
      });

      return null;
    }

    const payload = this.getExecutionBasketPayload();
    executionCalculatingBasket(payload);

    return null;
  }

  handleClickExecuteDpPolicyBasket = (insLineIszObjectId) => {
    const {
      executionCalculatingDpPolicyBasket,
      match: { params: { id: productId } },
    } = this.props;

    const payload = this.getExecutionBasketPayload();
    executionCalculatingDpPolicyBasket({
      ...payload,
      insLineIszObjectId,
      productId,
      action: () => this.setState({ isGuaranteeLevelWarning: false }),
    });
  }

  handleClickPreExecuteDpPolicyBasket = (isFinished) => {
    const {
      preExecutionCalculatingDpPolicyBasket,
      tableControl,
      match: { params: { id: productId } },
    } = this.props;

    if (!isFinished && tableControl.guaranteeLevel !== DEFAULT_VALUE_GUARANTEE) {
      this.setState({
        isGuaranteeLevelWarning: true,
        guaranteeLevelWarningAction: () => {
          this.handleClickPreExecuteDpPolicyBasket(true);
        },
      });

      return null;
    }

    const payload = this.getExecutionBasketPayload();
    preExecutionCalculatingDpPolicyBasket({
      ...payload,
      productId,
      action: () => this.setState({ isGuaranteeLevelWarning: false }),
    });

    return null;
  }

  render() {
    const {
      baskets,
      history,
      match: { params: { id } },
      settings,
      tableControl,
      minInsuranceSum,
      availableQuantityRequests,
      setWarningMessage,
      warningMessage,
      isExecutionLoading,
      options,
      isEditBasket,
    } = this.props;

    const {
      isGuaranteeLevelWarning,
      guaranteeLevelWarningAction,
    } = this.state;

    return (
      <CalculatedPersonalBasketsForm
        {...this.props}
        productId={id}
        generateReport={this.generateReport}
        baskets={baskets.data}
        history={history}
        settings={settings}
        guaranteeLevelWarningAction={guaranteeLevelWarningAction}
        onUpdateTable={this.handleUpdateTable}
        onEndScrollTable={this.handleEndScrollTable}
        onToggleOpenChildBasket={this.handleToggleOpenCalculatedPersonalChildBasket}
        tableControl={tableControl}
        onClickClearSortAndFiltering={this.handleClickClearSortAndFiltering}
        onClickCopyBasket={this.handleClickCopyRequest}
        onClickGetMinInsuranceSum={this.handleClickGetMinInsuranceSum}
        onSelectCalculatedPersonBasket={this.handleSelectBasket}
        onSelectChildCalculatedPersonBasket={this.handleSelectChildBasket}
        onClickExecute={this.handleClickExecuteBasket}
        onClickPreExecuteDpPolicy={this.handleClickPreExecuteDpPolicyBasket}
        onClickExecutionDpPolicy={this.handleClickExecuteDpPolicyBasket}
        isScrollLoading={baskets.isScrollLoading}
        openChildIds={baskets.openChildIds}
        childSelectedIds={baskets.childSelectedIds}
        selected={baskets.selected}
        isTableLoading={baskets.isTableLoading}
        minInsuranceSum={minInsuranceSum}
        resetMinInsuranceSum={this.resetMinInsuranceSum}
        availableQuantityRequests={availableQuantityRequests}
        isExecutionLoading={isExecutionLoading}
        warningPopupMessage={warningMessage}
        clearWarningPopupMessage={() => setWarningMessage('')}
        isGuaranteeLevelWarning={isGuaranteeLevelWarning}
        closeGuaranteeLevelWarningPopup={() => this.setState({ isGuaranteeLevelWarning: false })}
        productOptions={options}
        isEditBasket={isEditBasket}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  settings: selectSettingsProduct(state),
  baskets: selectCalculatedPersonBasket(state),
  filtering: selectBasketFiltering(state),
  sorting: selectBasketSorting(state),
  availableQuantityRequests: selectAvailableQuantityRequests(state),
  productTypes: selectProductTypes(state),
  tableControl: selectConstructorTableControlData(state),
  minInsuranceSum: selectMinInsuranceSumTables(state),
  warningMessage: selectWarningMessage(state),
  justNowExecutionBasketPayload: selectJustNowExecutionBasketPayload(state),
  isExecutionLoading: selectIsExecutionLoading(state),
  isExecutionDpPolicyLoading: selectExecutionDpPolicyLoading(state),
  isPreExecutionDpPolicyLoading: selectPreExecutionDpPolicyLoading(state),
  options: selectOptionType(state),
  isEditBasket: getIsEditBasket(state),
  qualification: getQualificationFormSelector(state),
  isExecutionDpPolicyPopup: selectIsExecutionDpPolicyPopup(state),
  isExecutionDpPolicySuccessPopup: selectIsExecutionDpPolicySuccessPopup(state),
  productForm: selectProductForm(state),
  dpPolicyExecutionUser: selectDpPolicyExecutionUser(state),
  isViewConstructorK1: state.checkoutReducer.isViewConstructorK1,
});

CalculatedPersonalBasketsComponent.propTypes = propTypes;
CalculatedPersonalBasketsComponent.defaultProps = defaultProps;

export const CalculatedPersonalBaskets = withCustomRouter(connect(
  mapStateToProps,
  actionsBasketConstructor,
)(CalculatedPersonalBasketsComponent));
