export default (props) => {
  const rebuildProps = { ...props };

  return Object.keys(rebuildProps)
    .reduce((prev, item) => {
      if (rebuildProps[item] || rebuildProps[item] === false || rebuildProps[item] === 0) {
        return {
          ...prev,
          [item]: rebuildProps[item],
        };
      }

      return prev;
    }, {});
};
