const GET_SCAN_REQUEST = 'files/GET_SCAN_REQUEST';
const GET_SCAN_START = 'files/GET_SCAN_START';
const GET_SCAN_FAIL = 'files/GET_SCAN_FAIL';
const GET_SCAN_SUCCESS = 'files/GET_SCAN_SUCCESS';

const GET_SCAN_DIRECTLY_REQUEST = 'files/GET_SCAN_DIRECTLY_REQUEST';
const GET_SCAN_DIRECTLY_START = 'files/GET_SCAN_DIRECTLY_START';
const GET_SCAN_DIRECTLY_FAIL = 'files/GET_SCAN_DIRECTLY_FAIL';
const GET_SCAN_DIRECTLY_SUCCESS = 'files/GET_SCAN_DIRECTLY_SUCCESS';

const UPLOAD_SCAN_REQUEST = 'files/UPLOAD_SCAN_REQUEST';
const UPLOAD_SCAN_START = 'files/UPLOAD_SCAN_START';
const UPLOAD_SCAN_SUCCESS = 'files/UPLOAD_SCAN_SUCCESS';
const UPLOAD_SCAN_FAIL = 'files/UPLOAD_SCAN_FAIL';

const DELETE_SCAN_REQUEST = 'files/DELETE_FILE_REQUEST';
const DELETE_SCAN_START = 'files/DELETE_FILE_START';
const DELETE_SCAN_FAIL = 'files/DELETE_FILE_FAIL';
const DELETE_SCAN_SUCCESS = 'files/DELETE_FILE_SUCCESS';

const SET_FILE_TYPE_REQUEST = 'files/SET_TYPE_FILE_REQUEST';
const SET_FILE_TYPE_START = 'files/SET_TYPE_START';
const SET_FILE_TYPE_FAIL = 'files/SET_TYPE_FAIL';
const SET_FILE_TYPE_SUCCESS = 'files/SET_TYPE_SUCCESS';

const DOWNLOAD_SCAN_FOR_VIEW_START = 'files/DOWNLOAD_SCAN_FOR_VIEW_START';
const DOWNLOAD_SCAN_FOR_VIEW_SUCCESS = 'files/DOWNLOAD_SCAN_FOR_VIEW_SUCCESS';
const DOWNLOAD_SCAN_FOR_VIEW_FAIL = 'files/DOWNLOAD_SCAN_FOR_VIEW_FAIL';
const DOWNLOAD_SCAN_FOR_VIEW_REQUEST = 'files/DOWNLOAD_SCAN_FOR_VIEW_REQ';

const SET_INIT_STATE = 'files/SET_INIT_STATE';

export {
  SET_INIT_STATE,
  DOWNLOAD_SCAN_FOR_VIEW_FAIL,
  DOWNLOAD_SCAN_FOR_VIEW_REQUEST,
  DOWNLOAD_SCAN_FOR_VIEW_START,
  DOWNLOAD_SCAN_FOR_VIEW_SUCCESS,
  DELETE_SCAN_SUCCESS,
  DELETE_SCAN_FAIL,
  DELETE_SCAN_START,
  DELETE_SCAN_REQUEST,
  SET_FILE_TYPE_SUCCESS,
  SET_FILE_TYPE_START,
  SET_FILE_TYPE_FAIL,
  SET_FILE_TYPE_REQUEST,
  UPLOAD_SCAN_REQUEST,
  UPLOAD_SCAN_START,
  UPLOAD_SCAN_SUCCESS,
  UPLOAD_SCAN_FAIL,
  GET_SCAN_DIRECTLY_REQUEST,
  GET_SCAN_DIRECTLY_FAIL,
  GET_SCAN_DIRECTLY_START,
  GET_SCAN_DIRECTLY_SUCCESS,
  GET_SCAN_FAIL,
  GET_SCAN_REQUEST,
  GET_SCAN_START,
  GET_SCAN_SUCCESS,
};
