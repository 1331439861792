import {
  OPEN_MODAL_HISTORY_SCAN,
  CLOSE_MODAL_HISTORY_SCAN,
  OPEN_MODAL_UPLOAD_SCAN,
  CLOSE_MODAL_UPLOAD_SCAN,
  SET_STATUS_AMOUNT_LIMINT,
} from '../types';

const INITIAL_STATE_HISTORY_SCAN_DATA = {
  history: [],
  fileType: '',
  top: 0,
  left: 0,
};

const INITIAL_STATE_UPLOAD_SCAN_DATA = {
  fileType: '',
  left: 0,
  top: 0,
};

const INITIAL_STATE = {
  historyScanModal: false,
  historyScanData: INITIAL_STATE_HISTORY_SCAN_DATA,
  uploadScanModal: false,
  uploadScanData: INITIAL_STATE_UPLOAD_SCAN_DATA,
  amountLimitPopup: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case OPEN_MODAL_HISTORY_SCAN: {
      const {
        history,
        fileType,
        left,
        top,
      } = payload;
      const currentScan = { ...payload };
      const rebuildHistory = [...history];
      delete currentScan.history;
      rebuildHistory.push(currentScan);

      return {
        ...state,
        historyScanModal: true,
        historyScanData: {
          history: rebuildHistory,
          fileType,
          left,
          top,
        },
      };
    }

    case CLOSE_MODAL_HISTORY_SCAN:
      return {
        ...state,
        historyScanModal: false,
        historyScanData: INITIAL_STATE_HISTORY_SCAN_DATA,
      };

    case OPEN_MODAL_UPLOAD_SCAN:
      return {
        ...state,
        uploadScanModal: true,
        uploadScanData: payload,
      };

    case CLOSE_MODAL_UPLOAD_SCAN:
      return {
        ...state,
        uploadScanModal: false,
        uploadScanData: INITIAL_STATE_UPLOAD_SCAN_DATA,
      };
    default:
      return state;

    case SET_STATUS_AMOUNT_LIMINT:
      return {
        ...state,
        amountLimitPopup: payload,
      };
  }
};
