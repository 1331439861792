import React, { useState } from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { Collapse } from 'reactstrap';
import './styles.scss';

const b = block('select-button');

const propTypes = {
  text: PropTypes.string,
  data: PropTypes.arrayOf(PropTypes.object),
  icon: PropTypes.string,
  onClickDropDown: PropTypes.func,
};

const defaultProps = {
  text: '',
  data: [],
  icon: '',
  onClickDropDown: () => null,
};

const SelectButton = ({
  text,
  data,
  icon,
  onClickDropDown,
}) => {
  const [isOpen, setStatusButton] = useState(false);

  return (
    <div className={b()}>
      <button
        type="button"
        className={b('button')}
        onClick={() => setStatusButton(!isOpen)}
      >
        <div className={b('text-wrapper')}>
          {icon && <Icon name={icon} />}
          <p className={b('text-button')}>{text}</p>
          {isOpen ? <Icon name="caret down" /> : <Icon name="caret right" />}
        </div>
      </button>
      <Collapse isOpen={isOpen}>
        <div className={b('content')}>
          {data.map((item) => (
            <button
              type="button"
              key={item.id}
              className={b('content-button')}
              onClick={() => onClickDropDown(item)}
            >
              {item.text}
            </button>
          ))}
        </div>
      </Collapse>
    </div>
  );
};

SelectButton.propTypes = propTypes;
SelectButton.defaultProps = defaultProps;
export default SelectButton;
