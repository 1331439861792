import AsyncActionFactory from 'helpers/actionsFactory';

export const factory = new AsyncActionFactory('validation');

export const CHECK_TERRORIST_VALID = 'CHECK_TERRORIST_VALID';
export const RESET_TERRORIST = 'RESET_TERRORIST';
export const REMOVE_CHECK_CODE_KLADR = factory.create('REMOVE_CHECK_CODE_KLADR');
export const SET_MANUAL_VALIDATION_ADDRESS = factory.create('SET_MANUAL_VALIDATION_ADDRESS');
export const CLOSE_CHECK_AGENT_RESULT = factory.create('CLOSE_CHECK_AGENT_RESULT');
export const CHECK_PODFT_DATA = factory.createAsync('CHECK_PODFT_DATA');
