import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY, CLIENT_REPORTS_TYPES, REGEXP } from 'consts';
import { MainBtn } from 'components';
import { Icon, Input } from 'semantic-ui-react';
import block from 'bem-cn';
import FlexTableClientReports from 'components/_tables/FlexTableClientReports';
import { onlyNumbers } from '../../helpers';

const b = block('clientReports');

const propTypes = {
  setFields: PropTypes.func,
  insurerFio: PropTypes.string,
  isSelectedContractId: PropTypes.number,
  setChoseContract: PropTypes.func,
  getReportsByFIO: PropTypes.func,
  reportsData: PropTypes.array,
  resetId: PropTypes.func,
  seriesNumber: PropTypes.string,
  numbers: PropTypes.string,
};

const defaultProps = {
  setFields: () => null,
  insurerFio: '',
  isSelectedContractId: 0,
  setChoseContract: () => null,
  getReportsByFIO: () => null,
  reportsData: [],
  resetId: () => null,
  seriesNumber: '',
  numbers: '',
};

const seriesLength = 4;
const numbersLength = 6;

const {
  SEARCH,
  LAST_NAME,
  FIRST_NAME,
  PATRONYMIC,
  INSURER_FIO,
  INSURER_PASSPORT_SERIES,
  PASSPORT_SERIES,
  INSURER_PASSPORT_NUMBER,
  PASSPORT_NUMBER,
} = LANG_DICTIONARY;

const InsurerFIO = (props) => {
  const {
    setFields,
    insurerFio,
    isSelectedContractId,
    setChoseContract,
    getReportsByFIO,
    reportsData,
    resetId,
    seriesNumber,
    numbers,
  } = props;

  const onSearch = () => {
    const refactoredInsurerFio = insurerFio.replace(REGEXP.SPACES_TABS_LINE_BREAKS_START_AND_END, '').replace(REGEXP.SPACES_TABS_LINE_BREAKS_BETWEEN_WORDS, ' ');

    getReportsByFIO({
      type: CLIENT_REPORTS_TYPES.INSR_PASSPORT,
      name: refactoredInsurerFio,
      series: seriesNumber,
      numbers,
    });
    resetId();
  };

  const setSeriesOrNumber = (name, value) => {
    const onlyNumberValue = onlyNumbers(value);

    if (name === 'seriesNumber') {
      const reworkSeriesNumber = onlyNumberValue > 9999 ? '9999' : onlyNumberValue;

      return setFields(name, reworkSeriesNumber);
    }
    const reworkNumber = onlyNumberValue > 999999 ? '999999' : onlyNumberValue;

    return setFields(name, reworkNumber);
  };

  const isSeries = seriesNumber.split('').length !== seriesLength;
  const isNumber = numbers.split('').length !== numbersLength;
  const isDisableSearchBtn = isSeries || isNumber || !insurerFio;

  return (
    <div>
      <div className={b('field-block')}>
        <div className={b('filed-block-label')}>{INSURER_FIO}</div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            placeholder={`${LAST_NAME} ${FIRST_NAME} ${PATRONYMIC}`}
            onChange={(e) => setFields('insurerFio', e.target.value)}
            value={insurerFio}
            icon={<Icon name="write" />}
          />
        </div>
      </div>
      <div className={b('field-block')}>
        <div className={b('filed-block-label')}>{INSURER_PASSPORT_SERIES}</div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            placeholder={PASSPORT_SERIES}
            onChange={(e) => setSeriesOrNumber('seriesNumber', e.target.value)}
            value={seriesNumber}
            icon={<Icon name="write" />}
          />
        </div>
      </div>
      <div className={b('field-block')}>
        <div className={b('filed-block-label')}>{INSURER_PASSPORT_NUMBER}</div>
        <div className={b('field')}>
          <Input
            className={b('field')}
            placeholder={PASSPORT_NUMBER}
            onChange={(e) => setSeriesOrNumber('numbers', e.target.value)}
            value={numbers}
            icon={<Icon name="write" />}
          />
        </div>
      </div>
      <div className={b('search-fio')}>
        <MainBtn
          className={b('btn')}
          onClick={onSearch}
          text={SEARCH}
          disabled={isDisableSearchBtn}
        />
      </div>
      <div className={reportsData.length ? b('table') : b('table-none')}>
        <FlexTableClientReports
          setChoseContract={setChoseContract}
          isSelectedContractId={isSelectedContractId}
          reportsData={reportsData}
        />
      </div>
    </div>
  );
};

InsurerFIO.propTypes = propTypes;
InsurerFIO.defaultProps = defaultProps;

export default InsurerFIO;
