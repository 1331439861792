import {
  put,
  takeLatest,
  takeEvery,
  debounce,
} from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API, LIST_DICTIONARY } from 'consts';
import {
  SEARCH_ADDRESS_REQUEST,
  SEARCH_ADDRESS_START,
  SEARCH_ADDRESS_SUCCESS,
  SEARCH_ADDRESS_FAIL,
  SEARCH_FMS_UNIT_REQUEST,
  SEARCH_FMS_UNIT_START,
  SEARCH_FMS_UNIT_SUCCESS,
  SEARCH_FMS_UNIT_FAIL,
  GET_OPTIONS_REQUEST,
  GET_OPTIONS_SUCCESS,
  GET_OPTIONS_FAIL,
  GET_MULTIPLE_OPTIONS,
  CANCEL_CURRENT_JOB_FAIL,
  CANCEL_CURRENT_JOB_SUCCESS,
  CANCEL_CURRENT_JOB_START,
  CANCEL_CURRENT_JOB_REQUEST,
  GET_AUTO_IMPORT_JOBS_FAIL,
  GET_AUTO_IMPORT_JOBS_SUCCESS,
  GET_AUTO_IMPORT_JOBS_START,
  GET_AUTO_IMPORT_JOBS_REQUEST,
  START_AUTO_IMPORT_FAIL,
  START_AUTO_IMPORT_SUCCESS,
  START_AUTO_IMPORT_START,
  START_AUTO_IMPORT_REQUEST,
  PARSE_XML_FILE_FOR_SELECTS_FAIL,
  PARSE_XML_FILE_FOR_SELECTS_SUCCESS,
  PARSE_XML_FILE_FOR_SELECTS_START,
  PARSE_XML_FILE_FOR_SELECTS_REQUEST,
  GET_BUILD_HASH_VERSION_SUCCESS,
  GET_BUILD_HASH_VERSION_FAIL,
  GET_BUILD_HASH_VERSION_START,
  GET_BUILD_HASH_VERSION_REQUEST,
  SEARCH_REGION,
} from 'redux/reducers/types';
import { DEBOUNCE_DADATA_REQUESTS } from '../../consts/numbers';

function* parseXmlFileForSelects({ payload }) {
  try {
    yield put({ type: PARSE_XML_FILE_FOR_SELECTS_START });
    const { data } = yield apiCall({
      type: 'POST',
      body: payload,
      url: API.IMPORT_FILE_FROM_1C_TO_PARSE,
    });
    yield put({ type: PARSE_XML_FILE_FOR_SELECTS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: PARSE_XML_FILE_FOR_SELECTS_FAIL, payload: getError(e) });
  }
}

function* startAutoImport({ payload }) {
  try {
    const {
      cronTypeId,
      errorFilesDir,
      newFilesDir,
      successFilesDir,
      time,
      interval,
      taskType,
      priorityId,
      action,
    } = payload;
    yield put({ type: START_AUTO_IMPORT_START });
    yield apiCall({
      type: 'POST',
      body: {
        specificTaskData: {
          errorFilesDir,
          newFilesDir,
          successFilesDir,
        },
        time,
        interval,
        taskType,
        priorityId,
        cronTypeId,
      },
      url: API.START_SCHEDULER_JOB,
    });
    yield put({ type: START_AUTO_IMPORT_SUCCESS });
    if (action) action();
  } catch (e) {
    yield put({ type: START_AUTO_IMPORT_FAIL, payload: getError(e) });
  }
}

function* getCurrentImportJob() {
  try {
    yield put({ type: GET_AUTO_IMPORT_JOBS_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.IMPORT_FILE_FROM_1C_JOBS,
    });
    yield put({ type: GET_AUTO_IMPORT_JOBS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_AUTO_IMPORT_JOBS_FAIL, payload: getError(e) });
  }
}

function* cancelCurrentJob({ payload }) {
  const { taskType, action } = payload;
  try {
    yield put({ type: CANCEL_CURRENT_JOB_START });
    yield apiCall({
      type: 'DELETE',
      url: API.STOP_SCHEDULER_JOB,
      body: {
        taskType,
      },
    });
    yield put({ type: CANCEL_CURRENT_JOB_SUCCESS });
    if (action) action();
  } catch (e) {
    yield put({ type: CANCEL_CURRENT_JOB_FAIL, payload: getError(e) });
  }
}

function* getBuildVersion() {
  try {
    yield put({ type: GET_BUILD_HASH_VERSION_START });
    const { data } = yield apiCall({
      type: 'GET',
      host: window.location.origin,
      url: '/build.json',
    });
    yield put({ type: GET_BUILD_HASH_VERSION_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_BUILD_HASH_VERSION_FAIL, payload: getError(e) });
  }
}

function* searchAddress({ payload: { address } }) {
  const { SEARCH_ADDRESS } = API;

  try {
    yield put({ type: SEARCH_ADDRESS_START });
    const { data } = yield apiCall({
      type: 'POST',
      body: { address },
      url: SEARCH_ADDRESS,
    });
    const payload = data.map((values) => ({ ...values, title: values.value }));
    yield put({ type: SEARCH_ADDRESS_SUCCESS, payload });
  } catch (error) {
    yield put({ type: SEARCH_ADDRESS_FAIL, payload: getError(error) });
  }
}

function* searchFmsUnit({ payload: { code } }) {
  const { SEARCH_FMS_UNIT } = API;

  try {
    yield put({ type: SEARCH_FMS_UNIT_START });
    const { data } = yield apiCall({
      type: 'POST',
      body: { code },
      url: SEARCH_FMS_UNIT,
    });
    const payload = data;
    yield put({ type: SEARCH_FMS_UNIT_SUCCESS, payload });
  } catch (error) {
    yield put({ type: SEARCH_FMS_UNIT_FAIL, payload: getError(error) });
  }
}

function* getMultipleOptions({ payload: { id } }) {
  yield (getOptions({ payload: { type: 'genders' } }));
  yield (getOptions({ payload: { type: 'beneficiaries', id } }));
  yield (getOptions({ payload: { type: 'relationships', id } }));
}

function* getOptions({ payload: { type, id } }) {
  const apiIndex = LIST_DICTIONARY[type];
  const url = API[apiIndex];
  try {
    const { data } = yield apiCall({
      type: 'POST',
      url,
      ...(id && { body: { id: Number(id) } }),
    });
    yield put({ type: GET_OPTIONS_SUCCESS, payload: { data, dataType: type } });
  } catch (error) {
    yield put({ type: GET_OPTIONS_FAIL, payload: getError(error) });
  }
}

function* searchRegion({ payload }) {
  const { region, isLivingAddress } = payload;
  const { SEARCH_REGION: searchRegionUrl } = API;
  try {
    yield put({ type: SEARCH_REGION.start, payload: { isLivingAddress } });
    const { data } = yield apiCall({
      type: 'POST',
      body: { region },
      url: searchRegionUrl,
    });
    const response = data.map((values) => ({ ...values, title: values.value }));
    yield put({ type: SEARCH_REGION.success, payload: response });
  } catch (e) {
    yield put({ type: SEARCH_REGION.fail, payload: getError(e) });
  }
}

function* HelperSaga() {
  yield debounce(DEBOUNCE_DADATA_REQUESTS, SEARCH_ADDRESS_REQUEST, searchAddress);
  yield takeEvery(GET_OPTIONS_REQUEST, getOptions);
  yield debounce(DEBOUNCE_DADATA_REQUESTS, SEARCH_FMS_UNIT_REQUEST, searchFmsUnit);
  yield takeLatest(GET_OPTIONS_REQUEST, getOptions);
  yield takeLatest(GET_MULTIPLE_OPTIONS, getMultipleOptions);
  yield takeLatest(CANCEL_CURRENT_JOB_REQUEST, cancelCurrentJob);
  yield takeLatest(GET_AUTO_IMPORT_JOBS_REQUEST, getCurrentImportJob);
  yield takeLatest(START_AUTO_IMPORT_REQUEST, startAutoImport);
  yield takeLatest(PARSE_XML_FILE_FOR_SELECTS_REQUEST, parseXmlFileForSelects);
  yield takeLatest(GET_BUILD_HASH_VERSION_REQUEST, getBuildVersion);
  yield takeLatest(SEARCH_REGION.request, searchRegion);
}

export default HelperSaga;
