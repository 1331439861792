import { createSelector } from 'reselect';

export const contestSelectors = {
  getBannerPayload: ({
    contests: {
      isActiveBanner,
      isLoadingBanner,
      bannerUrl,
    },
  }) => ({
    isActiveBanner,
    isLoadingBanner,
    bannerUrl,
  }),

  getImages: createSelector(
    ({ contests: { images } }) => images,
    (images) => images,
  ),

  getClusters: createSelector(
    ({ contests: { clusters } }) => clusters,
    (clusters) => clusters,
  ),

  getContestsInfo: createSelector(
    ({ contests: { contestsInfo } }) => contestsInfo,
    (contestsInfo) => contestsInfo,
  ),
};
