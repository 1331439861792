import {
  DOWNLOAD_TEMPLATE_SUCCESS,
  DOWNLOAD_TEMPLATE_START,
  DOWNLOAD_TEMPLATE_FAIL,
  UPLOAD_IMPORT_FILE_FAIL,
  UPLOAD_IMPORT_FILE_START,
  UPLOAD_IMPORT_FILE_SUCCESS,
  RESET_ALL_ERROR_IMPORT,
  RESET_CURRENT_ERROR_IMPORT,
  GET_INFO_SCHEDULE_TASK_START,
  GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS,
  SET_INIT_STATE_IMPORT,
  GET_IMPORT_VERSION,
  UPLOAD_PODFT_IMPORT_FILE,
} from '../types';

const INITIAL_STATE = {
  isDownload: false,
  isUpload: false,
  loading: false,
  errors: {},
  errorDownload: null,
  currentFileUpload: null,
  success: false,
  versions: {
    passport: {},
    extremism: {},
    fromu: {},
    mvk: {},
    bad_insurer: {},
    address: {},
    extremism_oon: {},
  },
};

export default (state = INITIAL_STATE, { type, payload, fileType }) => {
  switch (type) {
    case DOWNLOAD_TEMPLATE_START:
      return {
        ...state,
        isDownload: true,
      };

    case DOWNLOAD_TEMPLATE_FAIL:
      return {
        ...state,
        isDownload: false,
        errorDownload: payload,
      };

    case DOWNLOAD_TEMPLATE_SUCCESS:
      return {
        ...state,
        isDownload: false,
      };

    case UPLOAD_IMPORT_FILE_START:
      return {
        ...state,
        isUpload: true,
        currentFileUpload: fileType,
        errors: {
          ...state.errors,
          [fileType]: null,
        },
      };

    case UPLOAD_IMPORT_FILE_FAIL:
      return {
        ...state,
        isUpload: false,
        errors: {
          ...state.errors,
          [fileType]: payload,
        },
        currentFileUpload: fileType,
      };

    case UPLOAD_IMPORT_FILE_SUCCESS:
      return {
        ...state,
        isUpload: false,
        currentFileUpload: fileType,
      };

    case RESET_CURRENT_ERROR_IMPORT:
      return {
        ...state,
        errors: {
          ...state.errors,
          [payload]: null,
        },
      };

    case RESET_ALL_ERROR_IMPORT:
      return {
        ...state,
        errors: {},
      };

    case GET_INFO_SCHEDULE_TASK_START:
      return {
        ...state,
        loading: true,
      };

    case GET_ACTIVE_TASK_INFO_SCHEDULER_IMPORT_SUCCESS:
      return {
        ...state,
        success: true,
      };

    case SET_INIT_STATE_IMPORT:
      return INITIAL_STATE;

    case GET_IMPORT_VERSION.start:
      return { ...state };

    case GET_IMPORT_VERSION.success:
      return {
        ...state,
        versions: {
          ...state.versions,
          ...payload.versions,
        },
      };

    case GET_IMPORT_VERSION.fail:
      return { ...state };

    case UPLOAD_PODFT_IMPORT_FILE.start:
      return {
        ...state,
        isUpload: true,
        currentFileUpload: fileType,
        errors: {
          ...state.errors,
          [fileType]: null,
        },
      };

    case UPLOAD_PODFT_IMPORT_FILE.fail:
      return {
        ...state,
        isUpload: false,
        errors: {
          ...state.errors,
          [fileType]: payload,
        },
        currentFileUpload: fileType,
      };

    case UPLOAD_PODFT_IMPORT_FILE.success:
      return {
        ...state,
        isUpload: false,
        currentFileUpload: fileType,
      };

    default:
      return state;
  }
};
