import React from 'react';
import { block } from 'bem-cn';
import { withCustomRouter } from 'HOC';
import { connect } from 'react-redux';
import { setStatusModalMigrations } from 'redux/rootActions';
import { getFormattedDate } from 'helpers';
import PropTypes from 'prop-types';
import './styles.scss';
import { Icon } from 'semantic-ui-react';

const propTypes = {
  migrations: PropTypes.arrayOf(PropTypes.object),
  setStatusModal: PropTypes.func,
};

const defaultProps = {
  migrations: [],
  setStatusModal: () => null,
};

const b = block('migrations-popup');
const MigrationsPopup = ({ migrations, setStatusModal }) => (
  <div className={b()}>
    <div className={b('window')}>
      <button
        type="button"
        className={b('close-btn')}
        onClick={() => setStatusModal(false)}
      >
        <Icon name="close" />
      </button>
      <div className={b('content')}>
        <table>
          <tbody>
            <tr>
              <th>
                ID
              </th>
              <th>
                Дата
              </th>
              <th>
                Название
              </th>
            </tr>
            {migrations.map(({ id, name, timestamp }) => (
              <tr key={id}>
                <td>
                  {id}
                </td>
                <td>
                  {getFormattedDate(Number(timestamp))}
                </td>
                <td>
                  {name}
                </td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </div>
  </div>
);

const mapStateToProps = ({
  versionDp: {
    migrations,
  },
}) => ({
  migrations,
});

const mapDispatchToProps = {
  setStatusModal: setStatusModalMigrations,
};

MigrationsPopup.propTypes = propTypes;
MigrationsPopup.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(MigrationsPopup));
