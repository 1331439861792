import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const b = block('custom-checkbox');
const propTypes = {
  checked: PropTypes.bool,
  onChange: PropTypes.func,
  name: PropTypes.string,
  index: PropTypes.number,
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  isShowDisabled: PropTypes.bool,
};
const defaultProps = {
  checked: false,
  onChange: () => null,
  name: '',
  index: null,
  disabled: false,
  onClick: () => null,
  isShowDisabled: false,
};
const CustomCheckBox = ({
  checked,
  onChange,
  name,
  index,
  disabled,
  isShowDisabled,
  onClick,
}) => (
  <Icon
    name="check"
    bordered
    id={name}
    className={b('checkbox', { active: checked, disabled: isShowDisabled && disabled }).toString()}
    onClick={() => {
      onClick();
      if (!disabled) {
        onChange(name, checked, index);
      }
    }}
  />
);

CustomCheckBox.propTypes = propTypes;
CustomCheckBox.defaultProps = defaultProps;
export default CustomCheckBox;
