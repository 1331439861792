import React from 'react';
import { withRouter } from 'react-router-dom';
import { setStatusRedirectModal, setBlockRedirect, pushHistoryRoute } from 'redux/rootActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { ROUTES } from 'consts';
import _ from 'lodash';
import historyStorage from './historyStorage';

const propTypes = {
  setStatusModal: PropTypes.func,
  setBlockRedirectAction: PropTypes.func,
  redirectWarning: PropTypes.bool,
  redirectBlock: PropTypes.bool,
  history: PropTypes.object,
  location: PropTypes.object,
  pushHistoryRouteAction: PropTypes.func,
  historyRoutes: PropTypes.arrayOf(PropTypes.string),
  match: PropTypes.object,
};
const defaultProps = {
  setStatusModal: () => null,
  setBlockRedirectAction: () => null,
  redirectWarning: false,
  redirectBlock: false,
  history: {},
  pushHistoryRouteAction: () => null,
  historyRoutes: [],
  location: {},
  match: {},
};

export default (Component) => {
  class CustomRouter extends React.Component {
    componentWillUnmount() {
      const {
        setStatusModal,
        setBlockRedirectAction,
        redirectWarning,
        redirectBlock,
      } = this.props;
      if (redirectBlock) setBlockRedirectAction(false);
      if (redirectWarning) setStatusModal(false);
    }

    checkBlockRedirect = async (path, state) => {
      const {
        setStatusModal,
        redirectBlock,
      } = this.props;

      if (redirectBlock) {
        setStatusModal(true);
      } else {
        this.successRedirect(path, state);
      }
    };

    successRedirect = async (path, state) => {
      const param = this.checkRouterParam(path);
      const { search } = window.location;
      const { history } = this.props;
      if (historyStorage.listenRedirect) {
        await historyStorage.actionBeforeRedirect();
        historyStorage.setInitStorage();
      }
      history.push({
        search,
        ...param,
      }, state);
    };

    checkRouterParam = (param) => {
      if (typeof param === 'object') {
        return param;
      }

      return {
        pathname: param,
      };
    }

    customHistoryPush = async (nextPath, state) => {
      const param = this.checkRouterParam(nextPath);
      const { pushHistoryRouteAction } = this.props;
      pushHistoryRouteAction(param.pathname);
      await this.checkBlockRedirect(param, state);
    };

    setActionBeforeRedirect = (action) => {
      historyStorage.setActionBeforeRedirect(action);
    };

    confirmRedirect = async () => {
      const {
        historyRoutes,
        setBlockRedirectAction,
      } = this.props;
      const lastRoute = _.last(historyRoutes);
      setBlockRedirectAction(false);
      this.successRedirect(lastRoute);
    };

    goBack = async (state) => {
      const { historyRoutes } = this.props;
      if (this.listenRedirect) await this.action();
      if (historyRoutes.length > 1) {
        await this.customHistoryPush(historyRoutes[historyRoutes.length - 2], state);
      } else {
        await this.customHistoryPush(ROUTES.home, state);
      }
    };

    getPropsCustomwithCustomRouter = () => {
      const {
        location,
        history,
        match,
        ...rest
      } = this.props;

      return {
        location,
        match,
        history: {
          ...history,
          push: this.customHistoryPush,
          goBack: this.goBack,
          forcePush: this.successRedirect,
          pushWithoutSave: (link) => history.push(link, { notSave: true }),
        },
        confirmRedirect: this.confirmRedirect,
        setActionBeforeRedirect: this.setActionBeforeRedirect,
        ...rest,
      };
    };

    render() {
      return (
        <Component {...this.getPropsCustomwithCustomRouter()} />
      );
    }
  }

  const mapStateToProps = ({
    shared: {
      redirectBlock,
      historyRoutes,
      redirectWarning,
    },
  }) => ({ redirectBlock, historyRoutes, redirectWarning });

  const mapDispatchToProps = {
    setStatusModal: setStatusRedirectModal,
    setBlockRedirectAction: setBlockRedirect,
    pushHistoryRouteAction: pushHistoryRoute,
  };

  CustomRouter.defaultProps = defaultProps;
  CustomRouter.propTypes = propTypes;

  return withRouter(connect(mapStateToProps, mapDispatchToProps)(CustomRouter));
};
