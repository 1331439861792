import React from 'react';
import { Row, Col } from 'reactstrap';
import { withCustomRouter } from 'HOC';
import { LANG_DICTIONARY, DATE_FORMAT, ROUTES } from 'consts';
import { Select } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import {
  MainBtn,
  SearchBtn,
  ExtendedSettingsBtn,
  DateCustomPicker,
} from 'components';
import { block } from 'bem-cn';
import ExcelIcon from 'assets/images/excel.png';

const propTypes = {
  endDate: PropTypes.object.isRequired,
  startDate: PropTypes.object.isRequired,
  handleChangeDate: PropTypes.func.isRequired,
  dateSelectValue: PropTypes.string.isRequired,
  handleChangeSelect: PropTypes.func.isRequired,
  showExtendSetting: PropTypes.func.isRequired,
  isOpenSetting: PropTypes.bool.isRequired,
  searchAction: PropTypes.func.isRequired,
  clearSearch: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  haveReports: PropTypes.bool.isRequired,
  getDownloadReports: PropTypes.func.isRequired,
  togglePopUp: PropTypes.func.isRequired,
};

const b = block('form-products');
const { home } = ROUTES;
const {
  START_DATE,
  END_DATE,
  TODAY,
  YESTERDAY,
  CURRENT_WEEK,
  LAST_WEEK,
  CURRENT_MONTH,
  BACK,
  CURRENT_YEAR,
  ALL_TIME,
} = LANG_DICTIONARY;

const selectOptions = [
  TODAY,
  YESTERDAY,
  CURRENT_WEEK,
  LAST_WEEK,
  CURRENT_MONTH,
  CURRENT_YEAR,
  ALL_TIME,
].map((item) => ({
  key: item,
  value: item,
  text: item,
}));

const Controls = (props) => {
  const {
    endDate,
    startDate,
    handleChangeDate,
    dateSelectValue,
    handleChangeSelect,
    showExtendSetting,
    isOpenSetting,
    searchAction,
    clearSearch,
    history,
    haveReports,
    getDownloadReports,
    togglePopUp,
  } = props;

  const handleBackBtn = () => {
    clearSearch();
    history.push(home);
  };

  return (
    <section className={b('controls')}>
      <Row>
        <Col md={haveReports ? '2' : '3'} sm="12">
          <MainBtn
            className={b('backButton').toString()}
            onClick={handleBackBtn}
            text={BACK}
          />
        </Col>
        <Col md="2" sm="12">
          <div className={b('input-wrap')}>
            <p className={b('title-date-input')}>{START_DATE}</p>
            <DateCustomPicker
              values={{ startDate }}
              maxDate={endDate}
              onChange={handleChangeDate}
              name="startDate"
              dateFormat={DATE_FORMAT}
            />
          </div>
        </Col>
        <Col md="2" sm="12">
          <div className={b('input-wrap')}>
            <p className={b('title-date-input')}>{END_DATE}</p>
            <DateCustomPicker
              minDate={startDate}
              values={{ endDate }}
              name="endDate"
              onChange={handleChangeDate}
            />
          </div>
        </Col>
        <Col md="2" sm="12">
          <div className={b('input-wrap')}>
            <div className={b('select-wrapper')}>
              <Select
                options={selectOptions}
                value={dateSelectValue}
                onChange={handleChangeSelect}
              />
            </div>
          </div>
        </Col>
        <Col md="3" sm="12" className={b('control-buttons').toString()}>
          <div className={b('input-wrap')}>
            <div className={b('tools')}>
              <SearchBtn action={searchAction} />
              <ExtendedSettingsBtn isOpen={isOpenSetting} toggle={showExtendSetting} />
            </div>
          </div>
        </Col>
        {haveReports ? (
          <Col md="1">
            <div className={b('download-report')} onClick={() => { getDownloadReports(); togglePopUp(); }} role="button" tabIndex={0}>
              <img src={ExcelIcon} alt="excel" className={b('excel-icon')} />
            </div>
          </Col>
        ) : null}
      </Row>
    </section>
  );
};

Controls.propTypes = propTypes;
export default withCustomRouter(Controls);
