import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';

const propTypes = {
  breadcrumbs: PropTypes.arrayOf(PropTypes.object).isRequired,
  bem: PropTypes.func,
  history: PropTypes.object,
  active: PropTypes.number,
};

const defaultProps = {
  active: 1,
  bem: () => null,
  history: {},
};

const BreadcrumbsAdmin = (props) => {
  const {
    breadcrumbs,
    bem,
    active,
    history,
    history: { location: { pathname } },
  } = props;

  const handleClickButton = (action, link) => {
    const resultPathName = pathname.match(/\/[a-z]{1,}\/[a-z]{1,}/)[0];
    if (action) action();
    if (resultPathName !== link) {
      history.push(link);
    }
  };

  return (
    <div className={bem()}>
      {breadcrumbs.map(({
        name,
        action,
        stage,
        link,
      }) => (
        <div
          className={bem('item', { active: active === stage })}
          onClick={() => handleClickButton(action, link)}
          tabIndex="-1"
          role="button"
          key={`${name}${stage}`}
        >
          <p>{name}</p>
          { active === stage
            ? <Icon name="caret down" size="large" />
            : <Icon name="caret right" size="large" />}
        </div>
      ))}

    </div>
  );
};

BreadcrumbsAdmin.propTypes = propTypes;
BreadcrumbsAdmin.defaultProps = defaultProps;

export default BreadcrumbsAdmin;
