import {
  CURRENCY_REF,
  CURRENCY_CODE_REF,
  REGEXP,
} from 'consts';
import moment from 'moment';
import _ from 'lodash';
import multiSort from 'multi-sort';
import {
  CLEAR_ASSETS,
  CLEAR_CONSTRUCTOR_CREATOR,
  CLEAR_MIN_PAY,
  GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS,
  GET_CALCULATE_COUPON_FAIL,
  GET_CALCULATE_COUPON_START,
  GET_CALCULATE_COUPON_SUCCESS,
  GET_CONSTRUCTOR_SETTINGS_SUCCESS,
  GET_CONTRACT_PERIODS_SUCCESS,
  GET_MIN_PAY_FOR_USER_TABLES,
  GET_MIN_PAY_SUCCESS,
  GET_PAYOUT_TIMES_SUCCES,
  GET_PRODUCTS_GROUPS_SUCCESS,
  GET_TABLE_INFO_CONSTRUCTOR_START,
  GET_TABLE_INFO_CONSTRUCTOR_FAIL,
  GET_TABLE_INFO_CONSTRUCTOR_SUCCESS,
  GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS,
  GET_USER_BASKETS_REQUESTS_COUNT_FAIL,
  SET_ASSETS,
  SET_BARRIERS,
  SET_CURRENT_CURRENCY,
  SET_PAYOUT,
  SET_PERIOD,
  CHANGE_GUARANTEE_STATE,
  GET_USER_BASKETS_REQUESTS_COUNT_START,
  SET_INIT_STATE,
  SET_SORT_DATA,
  SET_FILTER_DATA,
  CLEAR_FILTER_AND_SORT,
  COPY_REQUEST_DATA,
  GET_SETTINGS_AND_CALC_REQUESTS,
  SET_DESCRIPTION_ASSETS,
  SET_OPTION_TYPE_ID,
  SET_ERROR_BARRIER,
  SET_ERRORS_BARRIER,
} from '../types';

const INITIAL_EDITOR_FORM = {
  assets: [],
  selectedAssets: [],
  minimumPay: '',
  currency: 'ruble',
  period: null,
  payout: null,
  optionType: null,
  barriers: [],
  barrierErrors: {},
};

const INITIAL_CONSTRUCTOR_SETTINGS = {
  fixedIncomeEditable: false,
  fixedIncomePerc: 100,
  hideConstrFull: false,
  isDollar: false,
  isIssueBasket: false,
  isRuble: false,
  optionOptions: [],
};

const INIT_STATE_GUARANTEE = {
  valueRadioButton: CURRENCY_REF.ROUBLE,
  valueGuaranteeLevel: 100,
};

const INIT_STATE_AND_SORT = {

};

const INITIAL_STATE = {
  constructorFormData: INITIAL_EDITOR_FORM,
  settings: INITIAL_CONSTRUCTOR_SETTINGS,
  guaranteeLevel: INIT_STATE_GUARANTEE,
  isOnlyFavorite: false,
  isLoadingPreparedBasket: false,
  isLoadingCalculateCoupon: false,
  tableInfoOne: [],
  tableInfoTwo: [],
  isLoadingReadyAndRegularBasket: false,
  numberOfQueryes: 0,
  assets: [],
  dopAssets: [],
  periods: [],
  payouts: [],
  status: null,
  minPayForUser: null,
  newMask: '',
  productGroups: {},
  isDisableSelectCurrency: true,
  descriptions: [],
  ...INIT_STATE_AND_SORT,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_PRODUCTS_GROUPS_SUCCESS: {
      return {
        ...state,
        productGroups: payload,
      };
    }
    case CLEAR_CONSTRUCTOR_CREATOR: {
      return {
        ...INITIAL_STATE,
      };
    }
    case CLEAR_MIN_PAY: {
      return {
        ...state,
        minPayForUser: null,
      };
    }
    case GET_MIN_PAY_FOR_USER_TABLES: {
      return {
        ...state,
        minPayForUser: payload,
      };
    }

    case GET_CALCULATE_COUPON_START: {
      return {
        ...state,
        isLoadingCalculateCoupon: true,
      };
    }

    case GET_USER_BASKETS_REQUESTS_COUNT_START:
      return {
        ...state,
      };

    case GET_USER_BASKETS_REQUESTS_COUNT_FAIL:
      return {
        ...state,
      };

    case GET_CALCULATE_COUPON_SUCCESS: {
      return {
        ...state,
        isLoadingCalculateCoupon: false,
        status: payload.status,
      };
    }

    case GET_CALCULATE_COUPON_FAIL: {
      return {
        ...state,
        isLoadingCalculateCoupon: false,
      };
    }

    case GET_SETTINGS_AND_CALC_REQUESTS.fail:
      return {
        ...state,
        isLoadingPreparedBasket: false,
      };

    case GET_SETTINGS_AND_CALC_REQUESTS.start:
      return {
        ...state,
        isLoadingPreparedBasket: true,
      };

    case GET_SETTINGS_AND_CALC_REQUESTS.success:
      return {
        ...state,
        isLoadingPreparedBasket: false,
        calculatedBaskets: payload.calcBasket.map((item) => ({
          ...item,
          basket: item.basket.replace(REGEXP.COMMA_GLOBAL, ', '),
        })),
      };

    case GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS: {
      return {
        ...state,
        numberOfQueryes: payload.count,
      };
    }

    case SET_BARRIERS: {
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          barriers: payload,
        },
      };
    }

    case SET_PERIOD: {
      const { values, value } = payload;

      return {
        ...state,
        constructorFormData: {
          ...values,
          barriers: [],
          period: value,
        },
      };
    }
    case SET_PAYOUT: {
      const { values, value } = payload;

      return {
        ...state,
        constructorFormData: {
          ...values,
          payout: value,
        },
      };
    }
    case SET_ASSETS: {
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          minimumPay: '',
          selectedAssets: payload,
        },
      };
    }
    case SET_CURRENT_CURRENCY: {
      const { currency } = state.constructorFormData;

      return {
        ...state,
        constructorFormData: {
          ...payload,
          barriers: [],
          minimumPay: '',
          period: null,
          currency: currency === CURRENCY_CODE_REF.ROUBLE ?
            CURRENCY_CODE_REF.DOLLAR : CURRENCY_CODE_REF.ROUBLE,
        },
      };
    }

    case GET_TABLE_INFO_CONSTRUCTOR_START:
      return {
        ...state,
        isLoadingReadyAndRegularBasket: true,
      };

    case GET_TABLE_INFO_CONSTRUCTOR_SUCCESS:
      return {
        ...state,
        isLoadingReadyAndRegularBasket: false,
        tableInfoOne: payload.readyBaskets,
        tableInfoTwo: payload.regularBaskets,
      };

    case GET_TABLE_INFO_CONSTRUCTOR_FAIL:
      return {
        ...state,
        isLoadingReadyAndRegularBasket: false,
      };

    case GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS: {
      const rebuildData = payload.map((item) => ({
        asset: item.assetApprove,
        value: item.code,
        text: `${item.name} (${item.code})`,
        key: item.id,
        desc: item.description,
      }));

      return {
        ...state,
        assets: rebuildData,
      };
    }

    case COPY_REQUEST_DATA.success: {
      const {
        actives,
        termId,
        minInsuranceAmount,
        currencyCode,
        payoutFreqId,
        barriers,
      } = payload;

      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          selectedAssets: actives.map((item) => item.code),
          period: termId,
          barriers: _.sortBy(barriers, ['num']).map((item) => item.barrier),
          minimumPay: minInsuranceAmount,
          currency: currencyCode,
          payout: payoutFreqId,
        },
      };
    }

    case GET_MIN_PAY_SUCCESS: {
      const { constructorFormData } = state;

      return {
        ...state,
        constructorFormData: {
          ...constructorFormData,
          minimumPay: payload,
        },
      };
    }
    case GET_CONTRACT_PERIODS_SUCCESS:
      return {
        ...state,
        periods: payload,
      };

    case GET_PAYOUT_TIMES_SUCCES:
      return {
        ...state,
        payouts: payload,
      };

    case GET_CONSTRUCTOR_SETTINGS_SUCCESS: {
      const sortOptions = multiSort(payload.optionTypes, ['isDefault', 'refIszOptionTypeId'], ['DESC', 'ASC']);
      const getOptionValue = (opt) => {
        const firstOptionDefault = _.get(opt, '0.isDefault', false);

        if (!firstOptionDefault) {
          return null;
        }

        return opt[0].refIszOptionTypeId;
      };

      return {
        ...state,
        settings: {
          ...state.settings,
          optionOptions: sortOptions
            .map((opt) => ({
              value: opt.refIszOptionTypeId,
              text: opt.description,
              key: `option${opt.refIszOptionTypeId}`,
              desc: opt.descriptionFull,
              default: opt.isDefault,
            })),
          ...payload,
        },
        guaranteeLevel: {
          ...state.guaranteeLevel,
          valueRadioButton: payload.isRuble ? CURRENCY_REF.ROUBLE : CURRENCY_REF.DOLLAR,
          valueOption: getOptionValue(sortOptions),
        },
        constructorFormData: {
          ...state.constructorFormData,
          currency: payload.isRuble ? CURRENCY_CODE_REF.ROUBLE : CURRENCY_CODE_REF.DOLLAR,
        },
        isDisableSelectCurrency: !payload.isRuble || !payload.isDollar,
      };
    }

    case CLEAR_ASSETS:
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          assets: [],
          selectedAssets: [],
        },
      };

    case CHANGE_GUARANTEE_STATE:
      return {
        ...state,
        guaranteeLevel: {
          ...state.guaranteeLevel,
          [payload.name]: payload.value,
        },
      };

    case SET_SORT_DATA: {
      return {
        ...state,
        sort: {
          ...state.sort,
          [payload.table]: {
            ...state.sort[payload.table],
            [payload.field]: {
              payload: payload.payload,
              time: moment().format(),
            },
          },
        },
      };
    }

    case SET_FILTER_DATA:
      return {
        ...state,
        filter: {
          ...state.filter,
          [payload.table]: {
            ...state.filter[payload.table],
            [payload.field]: payload.payload,
          },
        },
      };

    case SET_INIT_STATE:
      return { ...INITIAL_STATE };

    case CLEAR_FILTER_AND_SORT:
      return {
        ...state,
        ...INIT_STATE_AND_SORT,
      };

    case SET_DESCRIPTION_ASSETS:
      return {
        ...state,
        descriptions: payload,
      };

    case SET_ERROR_BARRIER:
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          barrierErrors: {
            ...state.constructorFormData.barrierErrors,
            [payload.index]: payload.status,
          },
        },
      };

    case SET_ERRORS_BARRIER:
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          barrierErrors: {
            ...payload,
          },
        },
      };

    case SET_OPTION_TYPE_ID:
      return {
        ...state,
        constructorFormData: {
          ...state.constructorFormData,
          optionType: payload,
        },
      };

    default:
      return state;
  }
};
