import React from 'react';
import PropTypes from 'prop-types';
import './styles.scss';

const defaultProps = {
  errors: null,
  touched: null,
  itemName: null,
  propertyName: null,
  customError: null,
  value: null,
  notTouched: false,
  isTouched: false,
  className: '',
};

const propTypes = {
  errors: PropTypes.object,
  touched: PropTypes.object,
  itemName: PropTypes.string,
  propertyName: PropTypes.string,
  customError: PropTypes.string,
  value: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
    PropTypes.bool,
    PropTypes.object,
  ]),
  notTouched: PropTypes.bool,
  isTouched: PropTypes.bool,
  className: PropTypes.string,
};

const GetFormError = ({
  errors,
  touched,
  itemName,
  customError,
  propertyName,
  value,
  notTouched,
  isTouched,
  className,
}) => {
  const haveError = errors && errors[itemName];
  const error = customError || haveError;
  const notTouch = notTouched && value;
  const touch = (touched && touched[propertyName || itemName]) || isTouched;
  const isShowError = notTouch || touch;

  return (error && isShowError) || (error && value && value.length)
    ? <div className={`error ${className}`}>{ customError || errors[itemName] }</div> : null;
};

GetFormError.propTypes = propTypes;
GetFormError.defaultProps = defaultProps;

export default GetFormError;
