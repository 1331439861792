import React from 'react';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('cross-btn');

const propTypes = {
  active: PropTypes.bool,
  onClick: PropTypes.func,
};

const defaultProps = {
  active: false,
  onClick: () => null,
};

const CancelCrossBtn = ({ active, onClick }) => (
  <div
    className={b()}
    onClick={onClick}
    role="button"
    tabIndex="-1"
  >
    <Icon
      name="close"
      size="big"
      disabled={!active}
      className={b({ active }).toString()}
    />
  </div>
);

CancelCrossBtn.propTypes = propTypes;
CancelCrossBtn.defaultProps = defaultProps;
export default CancelCrossBtn;
