import { getFormat } from 'helpers';
import JsPdf from 'jspdf';

export const getScanData = async ({ data }) => ({
  downloadLink: URL.createObjectURL(data),
  link: await rebuildToPdf(data),
  type: getFormat(data.type),
});

/*
  Неизвестно в каких единицах библиотека отрисовки пдф измеряет размеры.
  Значения подобраны вручную
*/
const correctCoefficient = 1.3;

export const rebuildToPdf = (data) => new Promise((resolve) => {
  const type = getFormat(data.type);
  if (type !== 'pdf') {
    const doc = new JsPdf({
      orientation: 'l',
      unit: 'px',
      format: 'a4',
    });

    const maxWidth = doc.internal.pageSize.getWidth();
    const maxHeight = doc.internal.pageSize.getHeight();
    const img = new Image();
    const blob = URL.createObjectURL(data);
    img.src = blob;
    img.onload = () => {
      const w = img.width;
      const h = img.height;

      const indexWidth = w > maxWidth ? w / maxWidth : 1;
      const indexHeight = maxHeight < h ? h / maxHeight : 1;
      const index = Math.max(indexHeight, indexWidth);
      let totalWidth = w / index;
      const totalHeight = h / index;

      if (totalWidth < totalHeight) {
        totalWidth *= correctCoefficient;
      }

      doc.addImage(
        img,
        type,
        (maxWidth - totalWidth) / 2,
        (maxHeight - totalHeight) / 2,
        totalWidth,
        totalHeight,
      );
      const file = doc.output('blob');
      resolve(URL.createObjectURL(file));
    };
  } else {
    resolve(URL.createObjectURL(data));
  }
});
