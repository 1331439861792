import {
  OPEN_MODAL_HISTORY_SCAN,
  CLOSE_MODAL_HISTORY_SCAN,
  OPEN_MODAL_UPLOAD_SCAN,
  CLOSE_MODAL_UPLOAD_SCAN,
  SET_STATUS_AMOUNT_LIMINT,
  CLOSE_ALL_POPUPS,
} from '../types';

const openModalHistoryScan = (data) => ({
  type: OPEN_MODAL_HISTORY_SCAN,
  payload: data,
});

const closeModalHistoryScan = () => ({
  type: CLOSE_MODAL_HISTORY_SCAN,
});

const openModalUploadScan = (data) => ({
  type: OPEN_MODAL_UPLOAD_SCAN,
  payload: data,
});

const closeModalUploadScan = () => ({
  type: CLOSE_MODAL_UPLOAD_SCAN,
});

const setStatusAmountLimit = (payload) => ({
  type: SET_STATUS_AMOUNT_LIMINT,
  payload,
});

const closeAllPopups = () => ({
  type: CLOSE_ALL_POPUPS,
});

export {
  closeAllPopups,
  setStatusAmountLimit,
  closeModalUploadScan,
  openModalUploadScan,
  openModalHistoryScan,
  closeModalHistoryScan,
};
