import React from 'react';
import PropTypes from 'prop-types';
import { Radio } from 'semantic-ui-react';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('constructor-basket-currencies');

const propTypes = {
  currencies: PropTypes.arrayOf(PropTypes.object).isRequired,
  name: PropTypes.string.isRequired,
  handleChange: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  disabled: PropTypes.bool.isRequired,
  value: PropTypes.string,
};

const defaultProps = {
  value: null,
};

const Currencies = ({
  currencies,
  name,
  value,
  handleChange,
  index,
  disabled,
}) => {
  const handleChangeRadio = (e, target) => {
    const [nameWithoutIndex] = target.name.split('.');
    handleChange(e, { name: nameWithoutIndex, value: target.value });
  };

  return (
    <div className={b()}>
      {currencies.map((currency) => (
        <Radio
          name={`${name}.${index}`}
          label={currency.currencyName}
          className={b('radio').toString()}
          value={currency.currencyCode}
          checked={value === currency.currencyCode}
          onChange={handleChangeRadio}
          disabled={disabled}
          key={currency.currencyCode}
        />
      ))}
    </div>
  );
};

Currencies.propTypes = propTypes;
Currencies.defaultProps = defaultProps;
export default Currencies;
