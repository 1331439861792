import { useState, useEffect } from 'react';

export const useScript = (url, name, val, parentNodeId) => {
  const [lib, setLib] = useState({});

  useEffect(() => {
    const root = parentNodeId
      ? document.getElementById(parentNodeId)
      : document.body;
    const script = document.createElement('script');

    script.src = url;
    script.async = true;

    if (val) {
      script.text = JSON.stringify(val);
    }

    script.onload = () => setLib({ [name]: window[name] });

    root.appendChild(script);

    return () => {
      delete window[name];
      script.remove();
    };
  }, [url]);

  return lib;
};
