export default {
  SUPERADMIN: 'superadmin',
  SK_ADMIN: 'sk_admin',
  DOCUMENT_CONTROLLER: 'document_controller',
  CONTACT_CENTER: 'contact_center',
  SUPERVISOR: 'supervisor',
  CURATOR: 'curator',
  PARTNER_ADMIN: 'partner_admin',
  BDM: 'bdm',
  USER: 'user',
  USER_CURRENT_BRANCH: 'user_current_branch',
  USER_ALL_BRANCH: 'user_all_branch',
};
