import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import { checkPassChangeAccess, resetPassword } from 'redux/actions';
import { withCustomRouter } from 'HOC';
import { SetPasswordForm } from 'components';
import './styles.scss';

const defaultProps = {
  isLoading: true,
  checkHash: null,
  resetPass: null,
  error: null,
  location: {},
  bem: null,
  userEmail: null,
  confirmationToken: null,
  history: null,
};

const propTypes = {
  isLoading: PropTypes.bool,
  checkHash: PropTypes.func,
  resetPass: PropTypes.func,
  error: PropTypes.string,
  location: PropTypes.object,
  bem: PropTypes.func,
  userEmail: PropTypes.string,
  confirmationToken: PropTypes.string,
  history: PropTypes.object,
};

class ResetForm extends Component {
  constructor(props) {
    super(props);
    const { checkHash, location: { search, hash } } = props;
    checkHash({ search, hash });
  }

  onReset = ({ userPassword }, { setSubmitting }) => {
    const {
      resetPass,
      userEmail,
      confirmationToken,
      history,
    } = this.props;

    resetPass({
      userEmail,
      confirmationToken,
      userPassword,
      history,
    });

    setSubmitting(false);
  };

  render() {
    const {
      isLoading,
      bem,
      error: serverError,
    } = this.props;

    const {
      ENTER_NEW_PASSWORD,
      REMOTE_BUTTON,
      NEW_PASSWORD,
      CONFIRM_NEW_PASSWORD,
    } = LANG_DICTIONARY;

    return (
      <>
        <div className={bem('title')}>{ ENTER_NEW_PASSWORD }</div>
        {
          serverError
            ? <div className={bem('error')}>{ serverError }</div>
            : (
              <SetPasswordForm
                handleSubmit={this.onReset}
                textBtn={REMOTE_BUTTON}
                bem={bem}
                placeholders={{
                  password: NEW_PASSWORD,
                  confirmPassword: CONFIRM_NEW_PASSWORD,
                }}
                isLoading={isLoading}
              />
            )
        }
      </>
    );
  }
}

ResetForm.propTypes = propTypes;
ResetForm.defaultProps = defaultProps;

function mapStateToProps({ authReducer, router }) {
  return { ...authReducer, ...router };
}

export default withCustomRouter(connect(
  mapStateToProps,
  {
    checkHash: checkPassChangeAccess,
    resetPass: resetPassword,
  },
)(ResetForm));
