import React from 'react';
import { PopupContainer } from 'components';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('public-api-keys-popup');
const { WARNING_SAVE_PUBLIC_API_KEY, API_KEY, SECRET_KEY } = LANG_DICTIONARY;

const propTypes = {
  isOpen: PropTypes.bool.isRequired,
  apiKey: PropTypes.string,
  secretKey: PropTypes.string,
  handleClose: PropTypes.func.isRequired,
};

const defaultProps = {
  apiKey: '',
  secretKey: '',
};

const PublicApiKeys = ({
  isOpen,
  apiKey,
  secretKey,
  handleClose,
}) => (
  <PopupContainer
    isOpen={isOpen}
    close={handleClose}
    isCloseButton
    isCloseClickOutside={false}
  >
    <div className={b()}>
      <div className={b('warning')}>
        <Icon name="warning sign" className={b('warning-icon').toString()} />
        <p>{`${WARNING_SAVE_PUBLIC_API_KEY}!`}</p>
      </div>
      <table className={b('table')}>
        <tbody>
          <tr>
            <td>
              {`${API_KEY}:`}
            </td>
            <td>
              {apiKey}
            </td>
          </tr>
          <tr>
            <td>
              {`${SECRET_KEY}:`}
            </td>
            <td>
              {secretKey}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  </PopupContainer>
);

PublicApiKeys.defaultProps = defaultProps;
PublicApiKeys.propTypes = propTypes;
export default PublicApiKeys;
