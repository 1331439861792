import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('contests');

export const CHECK_ACTIVE_CONTEST = factory.createAsync('CHECK_ACTIVE_CONTEST');
export const GET_BANNER = factory.createAsync('GET_BANNER');
export const GET_CONTESTS_DATA = factory.createAsync('GET_CONTESTS_DATA');
export const GET_CLUSTER = factory.createAsync('GET_CLUSTER');
export const GET_DOWNLOAD_CONTEST = factory.createAsync('GET_DOWNLOAD_CONTEST');

export const TOGGLE_IS_ACTIVE = factory.create('TOGGLE_IS_ACTIVE');
export const SAVE_CONTEST_IMAGE = factory.create('SAVE_CONTEST_IMAGE');
export const SET_INIT_STATE = factory.create('SET_INIT_STATE');
