import {
  put,
  select,
  takeLatest,
  throttle,
} from 'redux-saga/effects';
import {
  apiCall,
  getError,
  pagination,
  simpleDate,
  getConfigMySales,
  getOptionsUnderwriting,
} from 'helpers';
import { API } from 'consts';
import { getUserInfo, getActionRoles } from 'redux/reducers/selectors';
import { ITEM_COUNT_MY_SALES_PAGE } from 'config';
import { handlePaginationPage, searchProducts } from 'redux/rootActions';

import {
  GET_FILTER_OPTIONS_FAIL,
  GET_FILTER_OPTIONS_REQUEST,
  GET_FILTER_OPTIONS_START,
  GET_FILTER_OPTIONS_SUCCESS,
  SEARCH_PRODUCT_BY_FILTER_FAIL,
  SEARCH_PRODUCT_BY_FILTER_START,
  SEARCH_PRODUCT_BY_FILTER_SUCCESS,
  SEARCH_PRODUCT_BY_FILTER_REQUEST,
  SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST,
  GET_ADMIN_OPTIONS,
  GET_STATUS_CHECKING_SUCCESS,
  GET_STATUS_CHECKING_START,
  GET_STATUS_CHECKING_FAIL,
  SEARCH_STATUS_PRODUCT_BY_FILTER_START,
  SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL,
  SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS,
  SEARCH_REPORT_OPTIONS_REQUEST,
} from '../types';
import {
  GET_STATUS_CHECKING_UNDERWRITING_FAIL,
  GET_STATUS_CHECKING_UNDERWRITING_START,
  GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
} from '../../underwritingMaster/types';

const {
  GET_PRODUCTS_LIST,
  GET_STATUS_LIST,
  GET_PARTNER_LIST,
  GET_BRANCH_LIST,
  SEARCH_PRODUCTS_LIST,
  SEARCH_STATUS_PRODUCTS_LIST,
  GET_STATUS_LIST_CHECKING_UNDERWRITING,
} = API;

function* getCheckingStatus() {
  try {
    yield put({ type: GET_STATUS_CHECKING_UNDERWRITING_START });
    const { data } = yield apiCall({
      url: GET_STATUS_LIST_CHECKING_UNDERWRITING,
      type: 'GET',
    });
    yield put({
      type: GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'name', 'id'),
    });
  } catch (e) {
    yield put({ type: GET_STATUS_CHECKING_UNDERWRITING_FAIL, payload: getError(e) });
  }
}

function* getAllOptions() {
  const roleActions = yield select(getActionRoles);
  const { searchByPartner, searchByBranch } = getConfigMySales(roleActions);
  yield getOptions(GET_PRODUCTS_LIST, 'products');
  yield getOptions(GET_STATUS_LIST, 'statusList');
  yield getStatusScanList();
  yield getCheckingStatus();
  if (searchByPartner) yield getOptions(GET_PARTNER_LIST, 'partners');
  if (searchByBranch) yield getBranches('branches');
}

function* getReportOptions() {
  yield getOptions(GET_PRODUCTS_LIST, 'products');
  yield getOptions(GET_STATUS_LIST, 'statusList');
}

function* getAdminOptions() {
  yield getOptions(GET_STATUS_LIST, 'statusList');
  yield getOptions(GET_PARTNER_LIST, 'partners');
}

function* getStatusScanList() {
  try {
    yield put({ type: GET_STATUS_CHECKING_START });
    const { data } = yield apiCall({
      url: GET_STATUS_LIST_CHECKING_UNDERWRITING,
      type: 'GET',
    });

    yield put({
      type: GET_STATUS_CHECKING_SUCCESS,
      payload: getOptionsUnderwriting(data, 'name', 'code'),
    });
  } catch (e) {
    yield put({ type: GET_STATUS_CHECKING_FAIL, payload: getError(e) });
  }
}

function* getBranches(field) {
  try {
    yield put({ type: GET_FILTER_OPTIONS_START });
    const { partnerId } = yield select(getUserInfo);
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_BRANCH_LIST,
      body: {
        partnerId,
      },
    });
    yield put({ type: GET_FILTER_OPTIONS_SUCCESS, payload: data, field });
  } catch (error) {
    yield put({ type: GET_FILTER_OPTIONS_FAIL, payload: getError(error) });
  }
}

function* getOptions(url, field) {
  try {
    yield put({ type: GET_FILTER_OPTIONS_START });
    const { data } = yield apiCall({
      type: 'GET',
      url,
    });
    yield put({ type: GET_FILTER_OPTIONS_SUCCESS, payload: data, field });
  } catch (error) {
    yield put({ type: GET_FILTER_OPTIONS_FAIL, payload: getError(error) });
  }
}

function* searchProductsFilter({ payload }) {
  try {
    yield put({ type: SEARCH_PRODUCT_BY_FILTER_START });
    const { info } = payload;
    const { dateBeg, dateEnd } = info;
    const { page } = payload;
    let idPartner = '';

    if (info.partnerId) {
      idPartner = info.partnerId;
    } else if (info.partnerId === null) {
      idPartner = undefined;
    } else {
      const { partnerId } = yield select(getUserInfo);
      idPartner = partnerId;
    }

    const { data } = yield apiCall({
      type: 'POST',
      url: SEARCH_PRODUCTS_LIST,
      body: {
        ...payload.info,
        dateBeg: simpleDate(dateBeg),
        dateEnd: simpleDate(dateEnd),
        partnerId: idPartner,
        offset: `${ITEM_COUNT_MY_SALES_PAGE * (page - 1)}`,
        limit: ITEM_COUNT_MY_SALES_PAGE,
      },
    });
    const paginationData = pagination(data.data, ITEM_COUNT_MY_SALES_PAGE);
    yield put(
      { type: SEARCH_PRODUCT_BY_FILTER_SUCCESS, payload: { paginationData, data, idPartner } },
    );
    if (!paginationData.length && page !== 1) {
      yield put(handlePaginationPage(page - 1));
      yield searchProducts({ page: page - 1, info });
    } else {
      yield put(handlePaginationPage(page));
    }
  } catch (error) {
    yield put({ type: SEARCH_PRODUCT_BY_FILTER_FAIL, payload: getError(error) });
  }
}

function* searchStatusProductsFilter({ payload }) {
  try {
    yield put({ type: SEARCH_STATUS_PRODUCT_BY_FILTER_START });
    const { info } = payload;
    const { page } = payload;
    let idPartner = '';

    if (info.partnerId) {
      idPartner = info.partnerId;
    } else if (info.partnerId === null) {
      idPartner = undefined;
    } else {
      const { partnerId } = yield select(getUserInfo);
      idPartner = partnerId;
    }

    const { data } = yield apiCall({
      type: 'POST',
      url: SEARCH_STATUS_PRODUCTS_LIST,
      body: {
        ...payload.info,
        offset: `${ITEM_COUNT_MY_SALES_PAGE * (page - 1)}`,
        limit: ITEM_COUNT_MY_SALES_PAGE,
      },
    });
    const paginationData = pagination(data.data, ITEM_COUNT_MY_SALES_PAGE);
    yield put(
      {
        type: SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS,
        payload: { paginationData, data, idPartner },
      },
    );
    if (!paginationData.length && page !== 1) {
      yield put(handlePaginationPage(page - 1));
      yield searchProducts({ page: page - 1, info });
    } else {
      yield put(handlePaginationPage(page));
    }
  } catch (error) {
    yield put({ type: SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL, payload: getError(error) });
  }
}

function* FilterSaga() {
  yield takeLatest(GET_FILTER_OPTIONS_REQUEST, getAllOptions);
  yield throttle(1000, SEARCH_PRODUCT_BY_FILTER_REQUEST, searchProductsFilter);
  yield throttle(1000, SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST, searchStatusProductsFilter);
  yield takeLatest(SEARCH_REPORT_OPTIONS_REQUEST, getReportOptions);
  yield takeLatest(GET_ADMIN_OPTIONS, getAdminOptions);
}

export default FilterSaga;
