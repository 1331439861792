import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';
import React from 'react';
import { SharedPopups } from 'containers';
import { RenderFormContainer } from 'components';

const defaultProps = {
  activeBreadcrumb: null,
  activeSubBreadcrumb: null,
  additionalParams: null,
  publicRoute: false,
  component: () => null,
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any,
  activeBreadcrumb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  activeSubBreadcrumb: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  additionalParams: PropTypes.object,
  publicRoute: PropTypes.bool,
};

const CustomRoute = ({
  component: Component,
  activeBreadcrumb,
  activeSubBreadcrumb,
  additionalParams,
  ...rest
}) => (
  <Route
    {...rest}
    exact
    render={() => {
      const { publicRoute } = rest;

      return (
        <>
          {!publicRoute && <SharedPopups />}
          <RenderFormContainer
            component={Component}
            active={activeBreadcrumb}
            subActive={activeSubBreadcrumb}
            additionalParams={additionalParams}
            {...rest}
          />
        </>
      );
    }}
  />
);

CustomRoute.propTypes = propTypes;
CustomRoute.defaultProps = defaultProps;

export default CustomRoute;
