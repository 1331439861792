import React from 'react';
import { LockingForm } from 'components';
import { connect } from 'react-redux';
import { unlockAllCheckingDocuments, notifyClientsCalcBasket } from 'redux/rootActions';
import { withCustomRouter } from 'HOC';

const Locking = (props) => (
  <LockingForm {...props} />
);

const mapStateToProps = ({
  lockingAndTasks: {
    loadingUnlock,
    loadingNotifyCalcBasket,
  },
}) => ({
  loadingUnlock,
  loadingNotifyCalcBasket,
});

const mapDispatchToProps = {
  unlockCheckingDocuments: unlockAllCheckingDocuments,
  notifyCalcBasket: notifyClientsCalcBasket,
};

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Locking));
