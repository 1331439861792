import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import { FlexTable, CheckCircleOrCloseIcon, CustomCheckBox } from 'components';
import { Icon, Popup } from 'semantic-ui-react';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import { getFormattedDate } from 'helpers';
import template from './template';
import './styles.scss';
import popUpTemplate from './popUpTemplate';
import popUpUsersTemplate from './userPopUpTemplate';

const {
  MANAGMENT,
  FIO,
  BIRTH_DATE_SHORT,
  SHOW_ON_SITE_SHOR,
  STATUS,
  PARTNER,
  SUBDIVISION,
  ROLE,
  DEFAULT_SUBDIVISION,
  ACTIVE_ROLE,
  INHERIT_ROLE,
  LOGIN,
  DATE_LAST_LOGIN,
  LAST_IP_ADDRES,
  IS_EMPLOYER,
} = LANG_DICTIONARY;

const b = block('users-admin-table');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  checkedUsers: PropTypes.object,
  userCheckCheckbox: PropTypes.func,
  selectAllCheckBox: PropTypes.func,
  selectAllUsers: PropTypes.bool,
  popUpUsers: PropTypes.object,

};

const defaultProps = {
  data: [],
  history: {},
  checkedUsers: {},
  userCheckCheckbox: () => null,
  selectAllCheckBox: () => null,
  selectAllUsers: false,
  popUpUsers: null,
};

const popUpHeaders = [
  PARTNER,
  SUBDIVISION,
  ROLE,
  DEFAULT_SUBDIVISION,
  ACTIVE_ROLE,
  INHERIT_ROLE,
];

const popUpUsersHeaders = [
  LOGIN,
  FIO,
  BIRTH_DATE_SHORT,
  DATE_LAST_LOGIN,
  LAST_IP_ADDRES,
  STATUS,
  SHOW_ON_SITE_SHOR,
  IS_EMPLOYER,
];

const FlexTableAdminUsers = (
  {
    data,
    checkedUsers,
    userCheckCheckbox,
    selectAllCheckBox,
    history,
    selectAllUsers,
    popUpUsers,
  },
) => {
  const getControls = (id, affiliations) => (
    <div
      className={b('controls')}
    >
      <Popup
        className={b('popup').toString()}
        hoverable
        trigger={(
          <div
            role="button"
            tabIndex="-1"
            className={b('control-button')}
          >
            <Icon name="info circle" className={b('info-icon').toString()} />
          </div>
)}
        basic
        children={(
          <div className={b('info-popUp')}>
            <FlexTable
              data={affiliations && affiliations.map(({
                branch,
                partner,
                role,
                inheritRole,
                activeRole,
                isDefault,
              }) => popUpTemplate(popUpHeaders, [
                partner,
                branch,
                role,
                <CheckCircleOrCloseIcon status={isDefault} />,
                <CheckCircleOrCloseIcon status={activeRole} />,
                <CheckCircleOrCloseIcon status={inheritRole} />,
              ]))}
              minHeight={45}
            />
          </div>
      )}
      />
      <div
        role="button"
        tabIndex="-1"
        onClick={() => history.push(`${ROUTES.updateUser}/${id}`)}
        className={b('control-button')}
      >
        <img
          src={EditIcon}
          className={b('edit-icon')}
          alt="edit-icon"
        />
      </div>
    </div>
  );
  const getFio = (lastName, firstName, middleName) => {
    if (middleName !== null) {
      return `${lastName} ${firstName} ${middleName}`;
    }

    return `${lastName} ${firstName}`;
  };

  const getDate = (date) => {
    if (date !== null && date !== undefined && date !== '') {
      return getFormattedDate(date);
    }

    return true;
  };

  const headers = [
    MANAGMENT,
    <CustomCheckBox
      name="check"
      checked={selectAllUsers}
      onChange={selectAllCheckBox}
    />,
    LOGIN,
    FIO,
    BIRTH_DATE_SHORT,
    DATE_LAST_LOGIN,
    LAST_IP_ADDRES,
    STATUS,
    SHOW_ON_SITE_SHOR,
    IS_EMPLOYER,
  ];

  const checkActiveUser = (id) => checkedUsers[id];

  const rebuildData = data
    .map(({
      userId,
      login,
      lastName,
      firstName,
      middleName,
      dateLastlogin,
      lastIp,
      birthDate,
      webSiteListed,
      locked,
      affiliations,
      isEmployee,
    }) => template(headers, [
      getControls(userId, affiliations),
      <CustomCheckBox
        name="check"
        checked={checkActiveUser(userId)}
        onChange={() => userCheckCheckbox(userId)}
      />,
      login,
      getFio(lastName, firstName, middleName),
      getDate(birthDate),
      getDate(dateLastlogin),
      lastIp,
      <CheckCircleOrCloseIcon status={!locked} />,
      <CheckCircleOrCloseIcon status={webSiteListed} />,
      <CheckCircleOrCloseIcon status={isEmployee} />,
    ]));

  const popUpData = data.map(({
    login,
    lastName,
    firstName,
    middleName,
    dateLastlogin,
    lastIp,
    birthDate,
    webSiteListed,
    locked,
    isEmployee,
  }) => popUpUsersTemplate(popUpUsersHeaders, [
    login,
    getFio(lastName, firstName, middleName),
    getDate(birthDate),
    getDate(dateLastlogin),
    lastIp,
    <CheckCircleOrCloseIcon status={!locked} />,
    <CheckCircleOrCloseIcon status={webSiteListed} />,
    <CheckCircleOrCloseIcon status={isEmployee} />,
  ]));

  return (
    <div className={b({ popup: popUpUsers })}>
      <FlexTable data={popUpUsers ? popUpData : rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableAdminUsers.propTypes = propTypes;
FlexTableAdminUsers.defaultProps = defaultProps;
export default withCustomRouter(FlexTableAdminUsers);
