import { ROLES_ACTION } from 'consts';

const {
  searchByPartner,
  searchByBranch,
  searchByUser,
  getProductList,
  export1c,
  importUploadReferencies,
} = ROLES_ACTION;

const configMySales = {
  searchByPartner,
  searchByBranch,
  searchByUser,
};

const configDashboard = {
  getProductList,
};

const configSideMenu = {
  export1c,
  importUploadReferencies,
};

export {
  configSideMenu,
  configMySales,
  configDashboard,
};
