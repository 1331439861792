import {
  GET_BRANCHES_FOR_TABLE_REQUEST,
  SET_PAGE_PAGINATION,
  SEARCH_BY_BRANCHES_NAME,
  CREATE_BRANCH_REQUEST,
  GET_BRANCH_DATA_REQUEST,
  UPDATE_BRANCH_REQUEST,
  CLEAR_FORM,
  SET_INITIAL_VALUES,
  CONTROL_BRANCHES_POPUPS,
  GET_USERS,
  GET_LIST_OF_CTS_SOURCE,
} from '../types';

const getListOfCtsSources = () => ({
  type: GET_LIST_OF_CTS_SOURCE.request,
});

const controlBranchesPopUp = (value) => ({
  type: CONTROL_BRANCHES_POPUPS,
  payload: value,
});

const setInitialValuesBranchAdministration = () => ({
  type: SET_INITIAL_VALUES,
});

const getBranchesForAdminTable = (offset, query) => ({
  type: GET_BRANCHES_FOR_TABLE_REQUEST,
  payload: offset,
  query,
});

const setPageBranchesAdminTable = (page) => ({
  type: SET_PAGE_PAGINATION,
  payload: page,
});

const searchBranchesAdminTable = (params, page) => ({
  type: SEARCH_BY_BRANCHES_NAME,
  payload: params,
  page,
});

const createBranch = (body) => ({
  type: CREATE_BRANCH_REQUEST,
  payload: body,
});

const updateBranch = (id, body) => ({
  type: UPDATE_BRANCH_REQUEST,
  payload: { body, id },
});

const getBranchDataForUpdate = (id, isParent) => ({
  type: GET_BRANCH_DATA_REQUEST,
  payload: id,
  isParent,
});

const clearEdtiorAdminBranchesForm = () => ({
  type: CLEAR_FORM,
});

const getUsersAdminBranchesInterface = ({ offset, limit, searchValue }) => ({
  type: GET_USERS.request,
  payload: { offset, limit, searchValue },
});

export {
  getUsersAdminBranchesInterface,
  controlBranchesPopUp,
  setInitialValuesBranchAdministration,
  clearEdtiorAdminBranchesForm,
  updateBranch,
  getBranchDataForUpdate,
  createBranch,
  searchBranchesAdminTable,
  getBranchesForAdminTable,
  setPageBranchesAdminTable,
  getListOfCtsSources,
};
