import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import DocsTransferFileItem from './docsTransferFileItem';

const propTypes = {
  className: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  onDelete: PropTypes.func,
};

const defaultProps = {
  className: '',
  files: [],
  onDelete: () => null,
};

const DocsTransferFileList = ({
  className,
  files,
  onDelete,
}) => {
  const b = block(className);

  return (
    <div className={b('files-list')}>
      {
        files.map(({ id, file }) => (
          <DocsTransferFileItem
            key={id}
            className={b().toString()}
            file={file}
            fileId={id}
            onDelete={onDelete}
          />
        ))
      }
    </div>
  );
};

DocsTransferFileList.propTypes = propTypes;
DocsTransferFileList.defaultProps = defaultProps;
export default DocsTransferFileList;
