import React, { useState } from 'react';
import { LANG_DICTIONARY } from 'consts';
import { Dropdown } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const { NOT_FOUND_RESULTS } = LANG_DICTIONARY;

const b = block('infinity-search-select');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.number,
    key: PropTypes.string,
    value: PropTypes.string,
  })).isRequired,
  countItemWindow: PropTypes.number.isRequired,
  onChange: PropTypes.func,
  onLoad: PropTypes.func,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  name: PropTypes.string.isRequired,
  loading: PropTypes.bool,
};

const defaultProps = {
  value: 0,
  onChange: () => null,
  onLoad: () => null,
  loading: false,
};

const InfinitySearch = ({
  data,
  onChange,
  onLoad,
  value,
  countItemWindow,
  name,
  loading,
}) => {
  const [searchValue, setSearchValue] = useState('');

  const handleSearchChange = (e, { searchQuery }) => {
    setSearchValue(searchQuery);
    onLoad(searchQuery, countItemWindow);
  };

  const handleScroll = ({ target: { scrollTop, scrollHeight, clientHeight } }) => {
    const isEnd = scrollTop === scrollHeight - clientHeight;
    const limit = data.length + countItemWindow;

    if (isEnd) {
      onLoad(searchValue, limit);
    }
  };

  const handleChange = (e, { value: newVal }) => {
    setSearchValue('');
    onChange({ target: { value: newVal, name } });
  };

  const handleFocus = () => {
    onLoad('', countItemWindow);
    onChange({ target: { value: 0, name } });
  };

  return (
    <div className={b()}>
      <Dropdown
        loading={loading}
        search
        selection
        onSearchChange={handleSearchChange}
        searchQuery={searchValue}
        onChange={handleChange}
        options={data}
        onScroll={handleScroll}
        value={value}
        onFocus={handleFocus}
        noResultsMessage={NOT_FOUND_RESULTS}
      />
    </div>
  );
};

InfinitySearch.propTypes = propTypes;
InfinitySearch.defaultProps = defaultProps;
export default InfinitySearch;
