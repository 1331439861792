import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { Button, Form } from 'semantic-ui-react';
import { Formik } from 'formik';
import { LANG_DICTIONARY } from 'consts';
import { object, string } from 'yup';
import { TextInput } from 'components/_deprecated';
import { getErrorsFromValidationError } from 'helpers';
import { resetPassRequest } from 'redux/actions';
import './styles.scss';

const defaultProps = {
  isLoading: false,
  resetPass: null,
  success: null,
  bem: null,
};

const propTypes = {
  isLoading: PropTypes.bool,
  resetPass: PropTypes.func,
  success: PropTypes.string,
  bem: PropTypes.func,
};

const { EMAIL_VALIDATION, REQUIRED, NO_MORE_THEN_FIFTY } = LANG_DICTIONARY;

const schema = object().shape({
  userEmail: string()
    .email(EMAIL_VALIDATION)
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
});

class ForgotForm extends Component {
  onReset = ({ userEmail }, { setSubmitting }) => {
    const { resetPass } = this.props;
    resetPass(userEmail);
    setSubmitting(false);
  };

  validateForm = ({ userEmail }) => {
    try {
      schema.validateSync({ userEmail }, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };

  render() {
    const { isLoading, bem, success } = this.props;
    const {
      RESET_PASSWORD,
      REMOTE_BUTTON,
      EMAIL,
    } = LANG_DICTIONARY;

    return (
      <>
        <div className={bem('title')}>{ RESET_PASSWORD }</div>
        <Formik
          initialValues={{ userEmail: '' }}
          validate={(values) => this.validateForm(values)}
          onSubmit={this.onReset}
        >
          {({
            values,
            errors,
            touched,
            handleChange,
            handleBlur,
            handleSubmit,
            isSubmitting,
          }) => {
            const inputProps = {
              values,
              errors,
              touched,
              onChange: handleChange,
              onBlur: handleBlur,
            };

            return (
              <>
                {
                success ? <div className={bem('warningMessage')}>{ success }</div>
                  : (
                    <Form onSubmit={handleSubmit} loading={isLoading}>
                      <TextInput name="userEmail" type="text" placeholder={EMAIL} {...inputProps} />
                      <div className={bem('form', 'interface')}>
                        <Button
                          type="submit"
                          className="auth__signInButton"
                          disabled={isSubmitting}
                        >
                          { REMOTE_BUTTON }
                        </Button>
                      </div>
                    </Form>
                  )

              }
              </>
            );
          }}
        </Formik>
      </>
    );
  }
}

ForgotForm.propTypes = propTypes;
ForgotForm.defaultProps = defaultProps;

function mapStateToProps({ authReducer }) {
  return { ...authReducer };
}

export default connect(mapStateToProps, { resetPass: resetPassRequest })(ForgotForm);
