import React from 'react';
import { block } from 'bem-cn';
import './styles.scss';
import { Icon } from 'semantic-ui-react';
import { LastCheckInfo } from 'components';
import PropTypes from 'prop-types';

const propTypes = {
  changeStatusScan: PropTypes.func,
  checkDateTime: PropTypes.string,
  checkErrorCode: PropTypes.string,
  checkErrorDescription: PropTypes.string,
  checkErrorName: PropTypes.string,
  checkStatusName: PropTypes.string,
  checkUserFio: PropTypes.string,
  error: PropTypes.bool,
  indexScan: PropTypes.number,
  success: PropTypes.bool,
};

const defaultProps = {
  changeStatusScan: () => null,
  checkDateTime: '',
  checkErrorCode: '',
  checkErrorDescription: '',
  checkErrorName: '',
  checkStatusName: '',
  checkUserFio: '',
  error: false,
  indexScan: 0,
  success: false,
};

const bem = block('status-buttons');

class SwitcherStatusChecking extends React.Component {
  constructor(props) {
    super(props);
    const { checkStatusName, checkErrorDescription } = props;
    this.status = checkStatusName;
    this.description = checkErrorDescription;
  }

  changeStatusSuccess = () => {
    const {
      changeStatusScan,
      indexScan,
    } = this.props;
    changeStatusScan(indexScan, 'checked');
  };

  changeStatusError = () => {
    const {
      changeStatusScan,
      indexScan,
    } = this.props;
    changeStatusScan(indexScan, 'error');
  };

  wrapperInfo = (Component) => {
    const {
      checkUserFio,
      checkDateTime,
      checkErrorName,
      checkErrorCode,
    } = this.props;

    return (
      <LastCheckInfo
        content={
              {
                checker: checkUserFio,
                dateCheck: checkDateTime,
                status: this.status,
                checkErrorDescription: this.description,
                checkErrorCode,
                checkErrorName,
              }
}
      >
        {Component}
      </LastCheckInfo>
    );
  };

  render() {
    const {
      success,
      error,
    } = this.props;

    return (
      <div className={bem()}>
        {this.wrapperInfo(
          <div
            onClick={this.changeStatusSuccess}
            role="button"
            tabIndex="-1"
          >
            <Icon name="check" bordered className={bem('checkbox', { active: success }).toString()} />
          </div>,
        )}
        {this.wrapperInfo(
          <div
            onClick={this.changeStatusError}
            role="button"
            tabIndex="-1"
          >
            <Icon name="close" size="big" disabled={!error} />
          </div>,
        )}
      </div>
    );
  }
}

SwitcherStatusChecking.defaultProps = defaultProps;
SwitcherStatusChecking.propTypes = propTypes;
export default SwitcherStatusChecking;
