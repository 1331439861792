import React from 'react';
import { block } from 'bem-cn';
import ReactDOM from 'react-dom';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const {
  DOWNLOAD_FILE,
  FILE_NAME,
} = LANG_DICTIONARY;

const propTypes = {
  progressDownload: PropTypes.number.isRequired,
  isProgressBar: PropTypes.bool.isRequired,
  downloadFileName: PropTypes.string.isRequired,
};

const b = block('progress-bar-download');

const ProgressBarDownload = ({
  progressDownload,
  isProgressBar,
  downloadFileName,
}) => (
  <>
    {isProgressBar && ReactDOM.createPortal(
      (
        <div className={`${b()} animated bounceInLeft `}>
          <p className={b('title')}>{`${DOWNLOAD_FILE}...`}</p>
          <div className={b('filename')}>
            <p className={b('filename-title')}>{`${FILE_NAME}:`}</p>
            <p className={b('filename-file')}>{downloadFileName}</p>
          </div>
          <div className={b('progress-bar')}>
            <progress className={b('progress')} value={progressDownload} max="100" />
            <p className={b('percent')}>{`${progressDownload}%`}</p>
          </div>
        </div>
      ),
      document.getElementById('root'),
    )}
  </>
);

const mapStateToProps = ({
  shared: {
    progressDownload,
    isProgressBar,
    downloadFileName,
  },
}) => ({
  downloadFileName,
  progressDownload,
  isProgressBar,
});

ProgressBarDownload.propTypes = propTypes;
export default connect(mapStateToProps)(ProgressBarDownload);
