import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import {
  selectOption,
  getBranchesForAdminTable,
  searchBranchesAdminTable,
  setInitialValuesBranchAdministration,
  controlBranchesPopUp,
} from 'redux/rootActions';
import {
  BracnhesAdminForm,
  AdministrativeSectionInterface,
  PaginationPanel,
} from 'components';
import { withCustomRouter } from 'HOC';

const propTypes = {
  active: PropTypes.number,
  location: PropTypes.object,
  page: PropTypes.number,
  setOption: PropTypes.func,
  getBranchesForTable: PropTypes.func,
  searchValue: PropTypes.string,
  searchBranches: PropTypes.func,
  loadingTableInfo: PropTypes.bool,
  history: PropTypes.object,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  setInitialValues: PropTypes.func,
  totalPages: PropTypes.number,
  popUpControl: PropTypes.func,
  branchesPopUpOpen: PropTypes.bool,
};

const defaultProps = {
  popUpControl: () => null,
  branchesPopUpOpen: false,
  totalPages: 1,
  active: 0,
  location: {},
  page: 0,
  setOption: () => null,
  getBranchesForTable: () => null,
  searchValue: '',
  searchBranches: () => null,
  loadingTableInfo: false,
  history: {},
  tableInfo: [],
  setInitialValues: () => null,
};

const dictionaryLocal = {
  USERS_WORD: 'user',
  PRODUCTS_WORD: 'products',
};

class BranchesAdministration extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      popUpInfo: {},
    };
  }

  selectPrefix = 'branches';

  componentDidMount() {
    const {
      location: { state },
      page,
      setOption,
      getBranchesForTable,
    } = this.props;
    const isCache = state && state.cache;
    const currentPage = isCache ? page : 1;
    const params = this.getQueryParams();
    const { setInitialValues } = this.props;
    if (!isCache) {
      setOption('partners', null, this.selectPrefix);
      setOption('statusActive', null, this.selectPrefix);
      setInitialValues();
      getBranchesForTable(currentPage);
    } else {
      getBranchesForTable(currentPage, params);
    }
  }

  getQueryParams = () => {
    const {
      [`${this.selectPrefix}statusActiveSelect`]: status,
      [`${this.selectPrefix}partnersSelect`]: partnerId,
      searchValue,
    } = this.props;
    const checkEmptyProp = (prop) => (prop !== null && prop !== '' ? prop : undefined);

    return {
      status,
      partnerId,
      name: checkEmptyProp(searchValue),
    };
  }

  handleDownPage = () => {
    const {
      page,
      getBranchesForTable,
      loadingTableInfo,
    } = this.props;
    if (page > 1 && !loadingTableInfo) {
      getBranchesForTable(page - 1, this.getQueryParams());
    }
  }

  handleUpPage = () => {
    const {
      page,
      getBranchesForTable,
    } = this.props;
    getBranchesForTable(page + 1, this.getQueryParams());
  }

  handleSearchOnInput = ({ target: { value } }) => {
    const checkEmptyProp = (prop) => (prop !== null && prop !== '' ? prop : undefined);
    const { searchBranches, page } = this.props;
    searchBranches({ ...this.getQueryParams(), name: checkEmptyProp(value) }, page);
  }

  handleSearchOnSelect = (e, { value, name }) => {
    const { getBranchesForTable } = this.props;

    const params = this.getQueryParams();
    getBranchesForTable(1, {
      ...params,
      [name]: value,
    });
  }

  getPopUpInfo = (index, type) => {
    const {
      USERS_WORD,
      PRODUCTS_WORD,
    } = dictionaryLocal;
    const { tableInfo, popUpControl } = this.props;
    if (type === USERS_WORD) {
      const users = tableInfo.find(({ id }) => {
        if (id === index) {
          return id;
        }

        return null;
      });
      this.setState({
        popUpInfo: {
          type: USERS_WORD,
          tableInfo: users.users,
        },
      });
      popUpControl(true);
    }
    if (type === PRODUCTS_WORD) {
      const distribution = tableInfo.find(({ id }) => {
        if (id === index) {
          return id;
        }

        return null;
      });
      this.setState({
        popUpInfo: {
          type: PRODUCTS_WORD,
          tableInfo: distribution.distributions,
        },
      });
      popUpControl(true);
    }
  }

  render() {
    const {
      active,
      tableInfo,
      loadingTableInfo,
      page,
      history,
      searchValue,
      totalPages,
      popUpControl,
      branchesPopUpOpen,
    } = this.props;

    const {
      popUpInfo,
    } = this.state;

    return (
      <AdministrativeSectionInterface active={active}>
        <BracnhesAdminForm
          tableInfo={tableInfo}
          loadingTableInfo={loadingTableInfo}
          handleSearchOnInput={this.handleSearchOnInput}
          handleSearchOnSelect={this.handleSearchOnSelect}
          searchValue={searchValue}
          history={history}
          popUpControl={popUpControl}
          branchesPopUpOpen={branchesPopUpOpen}
          popUpInfo={popUpInfo}
          getPopUpInfo={this.getPopUpInfo}
          dictionaryLocal={dictionaryLocal}
        />
        <PaginationPanel
          active={page}
          total={totalPages}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  branchesAdminInterface,
  selects,
}) => ({
  ...branchesAdminInterface,
  ...selects,
});

const mapDispatchToProps = {
  popUpControl: controlBranchesPopUp,
  setOption: selectOption,
  getBranchesForTable: getBranchesForAdminTable,
  searchBranches: searchBranchesAdminTable,
  setInitialValues: setInitialValuesBranchAdministration,
};

BranchesAdministration.propTypes = propTypes;
BranchesAdministration.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(BranchesAdministration));
