import { object, string } from 'yup';
import { LANG_DICTIONARY } from 'consts';

const { REQUIRED } = LANG_DICTIONARY;

export default object().shape({
  userId: string().nullable().required(REQUIRED),
  branchId: string().nullable().required(REQUIRED),
  partnerId: string().nullable().required(REQUIRED),
  publicApi: string().nullable().required(REQUIRED),
  action: string().nullable().required(REQUIRED),
});
