import React from 'react';
import Scroll from 'react-scroll';
import { Icon, Input } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('custom-input');

const propTypes = {
  onChange: PropTypes.func,
  onBlur: PropTypes.func,
  onFocus: PropTypes.func,
  name: PropTypes.string,
  placeholder: PropTypes.string,
  required: PropTypes.bool,
  scrollId: PropTypes.string,
  error: PropTypes.string,
  disabled: PropTypes.bool,
  units: PropTypes.node,
  containerClassName: PropTypes.string,
  wrapperClassName: PropTypes.string,
  inputClassName: PropTypes.string,
  // eslint-disable-next-line
  value: PropTypes.any,
};

const defaultProps = {
  onChange: () => null,
  onBlur: () => null,
  onFocus: () => null,
  name: '',
  value: null,
  placeholder: '',
  required: false,
  scrollId: '',
  error: '',
  disabled: false,
  units: null,
  containerClassName: '',
  wrapperClassName: '',
  inputClassName: '',
};

const InputCustom = ({
  onChange,
  onBlur,
  onFocus,
  name,
  value,
  placeholder,
  scrollId,
  error,
  required,
  disabled,
  units,
  containerClassName,
  wrapperClassName,
  inputClassName,
}) => {
  const handleChange = (e, target) => {
    onChange({ target });
  };

  const isError = Boolean(error);

  return (
    <Scroll.Element name={`scroll${scrollId}`} className={b.mix(containerClassName)}>
      <div className={b('input-wrapper').mix(wrapperClassName)}>
        <div className={b('input').mix(inputClassName)}>
          <Input
            name={name}
            value={value}
            placeholder={placeholder}
            onChange={handleChange}
            onBlur={onBlur}
            onFocus={onFocus}
            error={isError}
            disabled={disabled}
          />
          {required && <Icon name="write" className={b('required-icon', { loading: isError }).toString()} />}
        </div>
        <div className={b('units')}>
          {units}
        </div>
      </div>
      {isError && <p className={b('error')}>{error}</p>}
    </Scroll.Element>
  );
};

InputCustom.defaultProps = defaultProps;
InputCustom.propTypes = propTypes;

export default InputCustom;
