import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { TYPE_CHECKING_CONTRACT } from 'consts';
import './styles.scss';

const b = block('status-scan-checking');

const {
  checked,
  error,
} = TYPE_CHECKING_CONTRACT;

const propTypes = {
  status: PropTypes.string,
};

const defaultProps = {
  status: '',
};

const StatusScanChecking = ({ status }) => {
  const getStatus = () => {
    switch (status) {
      case error:
        return (
          <Icon name="warning sign" />
        );
      case checked:
        return (
          <Icon name="checkmark" />
        );
      default:
        return null;
    }
  };

  return (
    <div className={b()}>
      {getStatus()}
    </div>
  );
};

StatusScanChecking.propTypes = propTypes;
StatusScanChecking.defaultProps = defaultProps;
export default StatusScanChecking;
