const API_BASE_URL = window.REACT_APP_API_BASE_URL || process.env.REACT_APP_API_BASE_URL || 'http://localhost:3000';
const WS_HOST = window.REACT_APP_WS_HOST || process.env.REACT_APP_WS_HOST || window.location.origin;
const WS_PATH = window.REACT_APP_WS_PATH || process.env.REACT_APP_WS_PATH || '/ws';

const BUILD_DATE = window.BUILD_DATE || process.env.REACT_APP_BUILD_DATE || 'now';
const GIT_HASH = window.GIT_HASH || process.env.REACT_APP_GIT_HASH || 'shahash';
const GIT_BRANCH = window.GIT_BRANCH || process.env.REACT_APP_GIT_BRANCH || 'develop';

const DEFAULT_REQUEST_DELAY = 1000;
const MIN_LENGTH_PASSWORD = 8;
const MIN_LENGTH_PASSWORD_AUTH = 6;
const SUPPORT_MAIL = 'support@bcslife.ru';
const MAX_SIZE_SCAN = 41943040;
const ITEM_COUNT_ADMIN_TABLE_PARTNERS = 15;
const ITEM_COUNT_MY_SALES_PAGE = 9;
const ITEM_COUNT_UNDERWRITING_LIST_PAGE = 12;
const ITEM_COUNT_EVENTS_SCAN = 8;
const ITEM_COUNT_NOTIFICATION_EVENT = 15;
const ITEM_COUNT_ADMIN_TABLE_USERS = 11;
const ITEM_COUNT_ADMIN_TABLE_PRODUCTS = 11;
const ITEM_COUNT_SCHEDULER_FORM = 17;
const ITEM_COUNT_INBOX_MESSAGES_FORM = 15;
const INSURER_AGE_MIN_RESTRICTION = 18;
const ITEM_COUNT_PUBLIC_API_LIST = 10;
const GET_BUILD_HASH_VERSION_TIMER = 600000;
const INSURED_AGE_MIN_RESTRICTION = 18;
const INSURED_AGE_MAX_RESTRICTION = 75;
const DEFAULT_COUNTRY = 'Россия';
const DOWNLOAD_ZIP_FORMAT_NAME = 'YYYY-MM-DD_hh:mm:ss';
const DOWNLOAD_PRINT_PAYMENT_NAME = 'YYYY-MM-DD_hh:mm:ss';
const IMPORT_MANUAL_TIMESTAMP = 'YYYY-MM-DD_HH:mm:ss';
const BASKET_REPORT_TIMESTAMP = 'YYYY-MM-DD_HH:mm:ss';
const DEFAULT_VALUE_GUARANTEE = 100;
const CONSTRUCTOR_BARRIERS_MIN = 60;
const CONSTRUCTOR_BARRIERS_MAX = 140;
const ITEM_COUNT_CONTEST_RESULT = 15;
const ROUND_CONTEST_VALUES = 2;
const ITEM_COUNT_DROPDOWN_USER_ADMIN = 10;

export {
  ITEM_COUNT_DROPDOWN_USER_ADMIN,
  ROUND_CONTEST_VALUES,
  ITEM_COUNT_CONTEST_RESULT,
  CONSTRUCTOR_BARRIERS_MIN,
  CONSTRUCTOR_BARRIERS_MAX,
  BASKET_REPORT_TIMESTAMP,
  DEFAULT_VALUE_GUARANTEE,
  ITEM_COUNT_PUBLIC_API_LIST,
  DOWNLOAD_PRINT_PAYMENT_NAME,
  WS_HOST,
  WS_PATH,
  API_BASE_URL,
  DEFAULT_REQUEST_DELAY,
  IMPORT_MANUAL_TIMESTAMP,
  MIN_LENGTH_PASSWORD_AUTH,
  MIN_LENGTH_PASSWORD,
  ITEM_COUNT_NOTIFICATION_EVENT,
  DOWNLOAD_ZIP_FORMAT_NAME,
  GET_BUILD_HASH_VERSION_TIMER,
  ITEM_COUNT_ADMIN_TABLE_PRODUCTS,
  ITEM_COUNT_SCHEDULER_FORM,
  MAX_SIZE_SCAN,
  INSURER_AGE_MIN_RESTRICTION,
  INSURED_AGE_MIN_RESTRICTION,
  INSURED_AGE_MAX_RESTRICTION,
  DEFAULT_COUNTRY,
  ITEM_COUNT_EVENTS_SCAN,
  ITEM_COUNT_UNDERWRITING_LIST_PAGE,
  ITEM_COUNT_MY_SALES_PAGE,
  ITEM_COUNT_ADMIN_TABLE_PARTNERS,
  ITEM_COUNT_ADMIN_TABLE_USERS,
  BUILD_DATE,
  GIT_HASH,
  GIT_BRANCH,
  SUPPORT_MAIL,
  ITEM_COUNT_INBOX_MESSAGES_FORM,
};
