import {
  RESET_TERRORIST,
  SET_MANUAL_VALIDATION_ADDRESS,
  CHECK_PODFT_DATA,
  CLOSE_CHECK_AGENT_RESULT,
} from '../types';

const checkAgent = (payload) => ({
  type: CHECK_PODFT_DATA.request,
  payload,
});

const closeCheckAgentResult = () => ({
  type: CLOSE_CHECK_AGENT_RESULT,
});

const resetValidationTerrorist = () => ({
  type: RESET_TERRORIST,
});

const setManualValidationAddress = ({
  status,
  index,
  isLiving,
}) => ({
  type: SET_MANUAL_VALIDATION_ADDRESS,
  payload: {
    status,
    isLiving,
  },
  index,
});

export {
  closeCheckAgentResult,
  setManualValidationAddress,
  checkAgent,
  resetValidationTerrorist,
};
