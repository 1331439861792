import {
  SET_SEARCH_VALUES,
  SET_CONTRACT,
  SET_DATE,
  GET_REPORT,
  SET_INIT_STATE,
  RESET_ID,
  GET_SEARCH_REPORTS_TYPES,
  SEARCH_CONTRACTS,
} from '../types';

const setSearchValues = (name, value) => ({
  type: SET_SEARCH_VALUES,
  payload: { name, value },
});

const setContract = (values) => ({
  type: SET_CONTRACT,
  payload: values,
});

const setDate = (name, date) => ({
  type: SET_DATE,
  payload: { name, date },
});

const searchContracts = (payload) => ({
  type: SEARCH_CONTRACTS.request,
  payload,
});

const getReport = (payload) => ({
  type: GET_REPORT.request,
  payload,
});

const getSearchReportsTypes = () => ({
  type: GET_SEARCH_REPORTS_TYPES.request,
});

const resetForm = () => ({
  type: SET_INIT_STATE,
});

const resetChosenIdOnSearch = () => ({
  type: RESET_ID,
});

export {
  setSearchValues,
  setContract,
  setDate,
  getReport,
  getSearchReportsTypes,
  resetForm,
  resetChosenIdOnSearch,
  searchContracts,
};
