import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import { LANG_DICTIONARY } from 'consts';
import { setSpaceNumber } from 'helpers';

const { REQUIRED_FIELD } = LANG_DICTIONARY;

const propTypes = {
  title: PropTypes.string.isRequired,
  parentClass: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  value: PropTypes.string.isRequired,
  sumValue: PropTypes.string.isRequired,
  currency: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
};

const defaultProps = {
  parentClass: () => '',
  required: false,
};

const PaymentItem = (props) => {
  const {
    title,
    parentClass,
    onChange,
    value,
    sumValue,
    currency,
    name,
    required,
  } = props;

  const sum = sumValue || 0;

  const getTitle = (desc) => (required ? `${desc} - ${REQUIRED_FIELD.toUpperCase()}` : desc);

  return (
    <div className={parentClass('row-sum')}>
      <Row>
        <Col md="3" sm="12">
          <p
            className={parentClass('title', { required })}
          >
            {getTitle(title)}
          </p>
        </Col>
        <Col md="1" sm="0" />
        <Col md="4" sm="6" xs="6">
          <div className={parentClass('input-block')}>
            <Form.Input
              onChange={onChange}
              name={name}
              value={value}
            />
          </div>
        </Col>
        <Col md="4" sm="6" xs="6">
          <p className={parentClass('sum')}>{`${setSpaceNumber(sum)} ${currency}`}</p>
        </Col>
      </Row>
    </div>
  );
};

PaymentItem.propTypes = propTypes;
PaymentItem.defaultProps = defaultProps;

export default PaymentItem;
