export const STRATEGY_FIELD_NAME = {
  PRODUCT_OPTION_TYPE_CODE: 'productOptionTypeCode',
  CURRENCY_RADIO_BUTTON: 'currencyCode',
  TERM_OPTIONS: 'termOptions',
  TERM: 'term',
  ASSET_OPTIONS: 'assetOptions',
  ASSET_CODES: 'assetCodes',
  PAYOUT_CODE: 'payoutCode',
  BARRIERS: 'barriers',
  PARTICIPATION_COEFFICIENT: 'participationCoefficient',
  COUPON: 'coupon',
  BARRIER_MANUAL_ERROR: 'barrierManualError',
  BARRIER_AUTOCALL: 'barrierAutocall',
  BONUS_COUPON: 'bonusCoupon',
  ASSET_CLASS: 'assetClass',
  IS_MULTIPLE_ASSET_CLASS: 'isMultipleAssetClass',
  OPTION_TYPES_RESTRICTIONS: 'optionTypesRestrictions',
  NOTIONAL_BARRIER: 'notionalBarrier',
  GEARED_BARRIER: 'gearedBarrier',
  GROWTH_CAP: 'growthCap',
  LOOKBACK_FLAG: 'lookbackFlag',
  LOOKBACK_DATA: 'lookbackData',
};
