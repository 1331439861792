import { put, takeLatest, debounce } from 'redux-saga/effects';
import { apiCall, getError, filterEmptyProps } from 'helpers';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY } from 'consts';
import { ITEM_COUNT_ADMIN_TABLE_PARTNERS } from 'config';
import { selectOption, setPageBranchesAdminTable } from 'redux/rootActions';
import {
  GET_BRANCHES_FOR_TABLE_FAIL,
  GET_BRANCHES_FOR_TABLE_REQUEST,
  GET_BRANCHES_FOR_TABLE_START,
  GET_BRANCHES_FOR_TABLE_SUCCESS,
  SEARCH_BY_BRANCHES_NAME,
  CREATE_BRANCH_FAIL,
  CREATE_BRANCH_REQUEST,
  CREATE_BRANCH_START,
  CREATE_BRANCH_SUCCESS,
  GET_BRANCH_DATA_FAIL,
  GET_BRANCH_DATA_REQUEST,
  GET_BRANCH_DATA_START,
  GET_BRANCH_DATA_SUCCESS,
  UPDATE_BRANCH_FAIL,
  UPDATE_BRANCH_START,
  UPDATE_BRANCH_REQUEST,
  UPDATE_BRANCH_SUCCESS,
  GET_USERS,
  GET_LIST_OF_CTS_SOURCE,
} from '../types';

const {
  BRANCH_CREATED,
  BRANCH_UPDATED,
} = LANG_DICTIONARY;

function* getListOfCtsSource() {
  try {
    yield put({ type: GET_LIST_OF_CTS_SOURCE.start });
    const { data } = yield apiCall({
      url: API.GET_CTS_SOURCES,
      type: 'GET',
    });

    yield put({ type: GET_LIST_OF_CTS_SOURCE.success, payload: data });
  } catch (e) {
    yield put({ type: GET_LIST_OF_CTS_SOURCE.fail });
  }
}

function* getUsers({ payload: { limit, offset, searchValue } }) {
  try {
    yield put({ type: GET_USERS.start });
    const { data } = yield apiCall({
      url: API.USERS,
      type: 'GET',
      query: {
        limit,
        offset,
        searchValue: searchValue || undefined,
      },
    });
    yield put({ type: GET_USERS.success, payload: data });
  } catch (e) {
    yield put({ type: GET_USERS.fail });
  }
}

function* createBranch({ payload }) {
  try {
    const body = filterEmptyProps(payload);
    yield put({ type: CREATE_BRANCH_START });
    yield apiCall({
      url: API.BRANCHES,
      type: 'POST',
      body,
    });
    yield put({ type: CREATE_BRANCH_SUCCESS });
    toastr.success('', BRANCH_CREATED);
  } catch (e) {
    yield put({ type: CREATE_BRANCH_FAIL, error: getError(e) });
  }
}

function* updateBranch({ payload }) {
  try {
    const { body, id } = payload;
    const rebuildBody = filterEmptyProps(body);
    delete rebuildBody.id;
    yield put({ type: UPDATE_BRANCH_START });
    yield apiCall({
      url: `${API.BRANCHES}/id${id}`,
      type: 'PUT',
      body: rebuildBody,
    });
    yield put({ type: UPDATE_BRANCH_SUCCESS });
    toastr.success('', BRANCH_UPDATED);
  } catch (e) {
    yield put({ type: UPDATE_BRANCH_FAIL, error: getError(e) });
  }
}

function* getBranchesForTable({ payload, query }) {
  try {
    yield put({ type: GET_BRANCHES_FOR_TABLE_START });
    const { data } = yield apiCall({
      url: API.GET_BRANCHES_FOR_ADMIN_TABLE,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_PARTNERS * (payload - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_PARTNERS,
        ...query,
      },
    });
    yield put({ type: GET_BRANCHES_FOR_TABLE_SUCCESS, payload: data });
    if (!data.data.length && payload !== 1) {
      yield put(setPageBranchesAdminTable(payload - 1));
      yield getBranchesForTable({ payload: payload - 1, query });
    } else {
      yield put(setPageBranchesAdminTable(payload));
    }
  } catch (e) {
    yield put({ type: GET_BRANCHES_FOR_TABLE_FAIL, error: getError(e) });
  }
}

function* searchBranchesByName({ payload, page }) {
  yield getBranchesForTable({ payload: page, query: payload });
}

function* getBranchDataForUpdate({ payload, isParent }) {
  try {
    yield put({ type: GET_BRANCH_DATA_START });
    const { data } = yield apiCall({
      url: `${API.BRANCHES}/id${payload}`,
      type: 'GET',
    });
    if (isParent) {
      yield put(selectOption('branches', data.id, 'update'));
    } else {
      yield put(selectOption('branches', data.parentBranchId, 'update'));
    }

    yield put(selectOption('partners', data.partnerId, 'update'));

    if (data.curatorId) {
      const { data: user } = yield apiCall({
        url: `${API.USERS}/id${data.curatorId}`,
        type: 'GET',
      });
      yield put({
        type: GET_USERS.success,
        payload: [{
          login: user.login,
          id: user.id,
        }],
      });
    }
    yield put({ type: GET_BRANCH_DATA_SUCCESS, payload: { data, isParent } });
  } catch (e) {
    yield put({ type: GET_BRANCH_DATA_FAIL, error: getError(e) });
  }
}

function* partnersSaga() {
  yield debounce(500, SEARCH_BY_BRANCHES_NAME, searchBranchesByName);
  yield takeLatest(UPDATE_BRANCH_REQUEST, updateBranch);
  yield takeLatest(GET_BRANCHES_FOR_TABLE_REQUEST, getBranchesForTable);
  yield takeLatest(CREATE_BRANCH_REQUEST, createBranch);
  yield takeLatest(GET_BRANCH_DATA_REQUEST, getBranchDataForUpdate);
  yield takeLatest(GET_USERS.request, getUsers);
  yield takeLatest(GET_LIST_OF_CTS_SOURCE.request, getListOfCtsSource);
}

export default partnersSaga;
