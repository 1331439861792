import moment from 'moment';
import { getProductConfig, replaceFirstChar } from 'helpers';
import {
  NAMES_GROUP_SETTINGS,
  REGEXP,
  PRODUCT_VERSION,
  SYSTEM_DATE_TO,
  LANG_DICTIONARY,
} from 'consts';
import { DEFAULT_COUNTRY } from 'config';

const {
  DOCUMENT_TYPE_TEMP_CERT,
} = LANG_DICTIONARY;

const { ONLY_NUMBERS } = REGEXP;

const { insuredChildPerson, insuredPerson, insurerAgent } = NAMES_GROUP_SETTINGS;

const rebuildApartment = (value) => (
  value && value.toLowerCase().indexOf('кв') === -1 ? `кв ${value}` : value
);

const setLivingAddress = ({
  livingAddressIsSame,
  addressFias,
  index,
  republic,
  area,
  houseNumber,
  apartment,
  city,
  street,
  house,
  fiasId,
  kladId,
  addressFiasLiving,
  apartmentLiving,
  indexLiving,
  republicLiving,
  areaLiving,
  houseNumberLiving,
  cityLiving,
  streetLiving,
  houseLiving,
  fiasIdLiving,
  kladIdLiving,
  isManualEnter,
  isManualEnterLiving,
}, {
  isAddress,
  isAddressLiving,
} = {
  isAddress: true,
  isAddressLiving: true,
}) => ({
  ...(isAddress ? {
    country: DEFAULT_COUNTRY,
    index,
    republic,
    area,
    houseNumber,
    apartment: rebuildApartment(apartment),
    city,
    street,
    house,
    isManualAddress: Boolean(isManualEnter),
    fiasId,
    kladId,
    ...(isManualEnter ? { addressFias: undefined } : { addressFias }),
  } : {}),
  ...(isAddressLiving ? {
    addressFiasLiving: livingAddressIsSame ? addressFias : addressFiasLiving,
    ...(livingAddressIsSame ? {
      countryLiving: DEFAULT_COUNTRY,
      indexLiving: index,
      republicLiving: republic,
      areaLiving: area,
      houseNumberLiving: houseNumber,
      apartmentLiving: rebuildApartment(apartment),
      cityLiving: city,
      streetLiving: street,
      houseLiving: house,
      isManualAddressLiving: Boolean(isManualEnter),
      fiasIdLiving: fiasId,
      kladIdLiving: kladId,
      ...(isManualEnter ? { addressFiasLiving: undefined } : {}),
    } : {
      countryLiving: DEFAULT_COUNTRY,
      indexLiving,
      republicLiving,
      areaLiving,
      houseNumberLiving,
      cityLiving,
      streetLiving,
      houseLiving,
      apartmentLiving: rebuildApartment(apartmentLiving),
      isManualAddressLiving: Boolean(isManualEnterLiving),
      fiasIdLiving,
      kladIdLiving,
      ...(isManualEnterLiving ? { addressFiasLiving: undefined } : {}),
    }),
  } : {}),
});

const rebuildPersonValues = ({
  values,
  isInsurer,
  insurerIsInsured,
  config,
  isChildForm,
  productForm,
}) => {
  const {
    documentType,
    kp,
    snils,
    mobilePhone,
    workPhone,
    contactPhone,
    homePhone,
    bic,
    bankName,
    corAccount,
    bankInn,
    kpp,
    curAccount,
    gender,
    birthDate,
    birthPlace,
    firstName,
    lastName,
    patronymic,
    serial,
    passportNumber,
    issuedBy,
    when,
    validUntil,
    email,
    relationship,
    inn,
  } = values;

  const {
    isBank,
    isPerson,
    isDocuments,
    isInnSnils,
    isContacts,
  } = config;

  const personValues = {
    ...(isBank && !productForm.hideBankInfo ? {
      bic,
      bankName,
      corAccount,
      bankInn,
      kpp,
      curAccount,
    } : {}),
    ...(isPerson ? {
      firstName: replaceFirstChar(firstName.trim()),
      lastName: replaceFirstChar(lastName.trim()),
      patronymic: replaceFirstChar(patronymic.trim()),
      gender,
      birthDate,
      birthPlace,
    } : {}),
    ...(isDocuments ? {
      documentType: Number(documentType),
      serial,
      passportNumber,
      issuedBy,
      when,
      ...(documentType === DOCUMENT_TYPE_TEMP_CERT && { validUntil }),
      kp: kp ? kp.replace(ONLY_NUMBERS, '') : kp,
    } : {}),
    ...(isInnSnils ? {
      inn: inn ? inn.replace(ONLY_NUMBERS, '') : inn,
      snils: snils ? snils.replace(ONLY_NUMBERS, '') : snils,
    } : {}),
    ...(isContacts ? {
      mobilePhone: mobilePhone ? mobilePhone.replace(ONLY_NUMBERS, '') : mobilePhone,
      homePhone: homePhone ? homePhone.replace(ONLY_NUMBERS, '') : homePhone,
      workPhone: workPhone ? workPhone.replace(ONLY_NUMBERS, '') : workPhone,
      contactPhone: contactPhone ? contactPhone.replace(ONLY_NUMBERS, '') : contactPhone,
      email,
    } : {}),
    ...setLivingAddress(values, config),
    relationship,
  };

  if (isInsurer) {
    personValues.relationship = undefined;
  }

  if (!isInsurer && insurerIsInsured && !isChildForm) {
    personValues.relationship = 0;
  }

  return personValues;
};

const rebuildContractValuesIsz = (values) => {
  const {
    scenario,
    productForm: {
      currencyRateId,
      insLineIszObjects,
      dateInvest,
    },
    productValues: {
      policySum,
      insuranceAmmount,
      productPeriod: productTermId,
      regDate: dateReg,
      isDeclarationAcceptance,
      productVersion,
      premiumFreqId,
    },
    isBeneficiaryByLaw,
    productId,
    insurerIsInsured,
    partnerId,
    branchId,
    mainRequestId,
    childRequestIds,
  } = values;

  const constructorIszScenarios = ['isz_5', 'isz_6'];

  return {
    premiumFreqId,
    isBeneficiaryByLaw,
    productId,
    productTermId,
    dateReg,
    dateInvest,
    insurerIsInsured,
    insuranceAmmount: parseFloat(insuranceAmmount),
    policyAmmount: parseFloat(policySum),
    partnerId,
    branchId,
    scenario,
    currencyRateId,
    isDeclarationAcceptance: Boolean(isDeclarationAcceptance),
    insLineIszObjectId: insLineIszObjects.length ? productVersion : undefined,
    ...(constructorIszScenarios.includes(scenario) ? { mainRequestId, childRequestIds } : {}),
  };
};

const rebuildContractValuesNsz = (values) => {
  const {
    productValues: {
      regularPaymentAmount,
      accumulationAmount,
      termId,
      premiumFreqId,
      risks,
      refCurrencyId,
    },
    isBeneficiaryByLaw,
    insurerIsInsured,
    partnerId,
    branchId,
    scenario,
    productForm,
    productId,
  } = values;

  const currency = productForm.refCurrenciesFull
    .find((item) => item.id === refCurrencyId);

  return {
    isBeneficiaryByLaw,
    productId,
    productTermId: termId,
    insurerIsInsured,
    regularPaymentAmount: parseFloat(regularPaymentAmount),
    accumulationAmount: parseFloat(accumulationAmount),
    partnerId,
    branchId,
    scenario,
    premiumFreqId,
    risks,
    refExchangeBankId: currency.refExchangeBankId,
    refCurrenciesId: refCurrencyId,
  };
};

const rebuildContractValuesKsz = (values) => {
  const {
    productValues: {
      creditAmount,
      creditCurrency,
      creditStartDate,
      creditTerm,
      creditEndDate,
      creditInsuranceSum,
      productCalcType,
    },
    isBeneficiaryByLaw,
    insurerIsInsured,
    partnerId,
    branchId,
    scenario,
    productId,
  } = values;

  return {
    isBeneficiaryByLaw,
    insurerIsInsured,
    partnerId,
    branchId,
    scenario,
    creditAmount,
    creditCurrency,
    creditStartDate: moment(creditStartDate).format(SYSTEM_DATE_TO),
    creditTerm,
    creditEndDate: moment(creditEndDate).format(SYSTEM_DATE_TO),
    creditInsuranceSum,
    productId,
    productCalcTypeCode: productCalcType,
  };
};

const correctionBankConfig = (config, hideBankInfo) => ({
  ...config,
  isBank: config.isBank && !hideBankInfo,
});

const rebuildValuesIsz = (values, config) => {
  const {
    insurer,
    insured,
    beneficiary,
    insurerIsInsured,
    productForm,
    productForm: {
      isCurrencyScenario,
      refExchangeRatesRegimesCoefId,
      currencyRate,
    },
  } = values;

  const {
    insurerConfig,
    insuredConfig,
  } = config;

  return {
    ...rebuildContractValuesIsz(values),
    insurer: rebuildPersonValues({
      values: insurer,
      isInsurer: true,
      insurerIsInsured,
      config: correctionBankConfig(insurerConfig),
      productForm,
    }),
    insured: rebuildPersonValues({
      values: insurerIsInsured ? insurer : insured,
      insurerIsInsured,
      config: correctionBankConfig(insuredConfig),
      productForm,
    }),
    beneficiary,
    isCurrencyScenario,
    refExchangeRatesRegimesCoefId,
    currencyRate,
  };
};

const rebuildValuesNsz = (values, config) => {
  const {
    insurer,
    insured,
    beneficiary,
    childInsured,
    insurerIsInsured,
    productForm,
    productValues: {
      coefId,
      isCurrencyScenario,
    },
  } = values;

  const {
    childInsuredConfig,
    insurerConfig,
    insuredConfig,
  } = config;

  return {
    ...rebuildContractValuesNsz(values),
    insurer: rebuildPersonValues({
      values: insurer,
      isInsurer: true,
      insurerIsInsured,
      config: correctionBankConfig(insurerConfig),
      productForm,
    }),
    insured: rebuildPersonValues({
      values: insurerIsInsured ? insurer : insured,
      insurerIsInsured,
      config: correctionBankConfig(insuredConfig),
      productForm,
    }),
    childInsured: childInsuredConfig ?
      rebuildPersonValues({
        values: childInsured,
        insurerIsInsured,
        config: correctionBankConfig(childInsuredConfig),
        isChildForm: true,
        productForm,
      })
      : undefined,
    beneficiary,
    coefId,
    isCurrencyScenario,
  };
};

const rebuildValuesKsz = (values, config) => {
  const {
    insurer,
    insured,
    beneficiary,
    insurerIsInsured,
    productForm,
    productValues: {
      isCurrencyScenario,
    },
  } = values;

  const {
    insurerConfig,
    insuredConfig,
  } = config;

  return {
    ...rebuildContractValuesKsz(values),
    insurer: rebuildPersonValues({
      values: insurer,
      isInsurer: true,
      insurerIsInsured,
      config: correctionBankConfig(insurerConfig),
      productForm,
    }),
    insured: rebuildPersonValues({
      values: insurerIsInsured ? insurer : insured,
      insurerIsInsured,
      config: correctionBankConfig(insuredConfig),
      productForm,
    }),
    beneficiary,
    isCurrencyScenario,
  };
};

const rebuildValues = (values) => {
  const { scenario } = values;
  const config = {
    childInsuredConfig: getProductConfig(scenario, insuredChildPerson),
    insurerConfig: getProductConfig(scenario, insurerAgent),
    insuredConfig: getProductConfig(scenario, insuredPerson),
  };

  switch (scenario) {
    case PRODUCT_VERSION.normal:
    case PRODUCT_VERSION.coupon:
    case PRODUCT_VERSION.normalCurrency:
    case PRODUCT_VERSION.couponCurrency:
    case PRODUCT_VERSION.constructorIsz:
    case PRODUCT_VERSION.constructorIszDollar:
      return rebuildValuesIsz(values, config);

    case PRODUCT_VERSION.nsz_1:
    case PRODUCT_VERSION.nsz_2:
    case PRODUCT_VERSION.nsz_3:
      return rebuildValuesNsz(values, config);

    case PRODUCT_VERSION.ksz_1:
      return rebuildValuesKsz(values, config);

    default:
      return {};
  }
};

const initStateLivingAddress = {
  countryLiving: '',
  indexLiving: '',
  republicLiving: '',
  areaLiving: '',
  houseNumberLiving: '',
  apartmentLiving: '',
  cityLiving: '',
  streetLiving: '',
  houseLiving: '',
  fiasIdLiving: '',
  kladIdLiving: '',
  addressFiasLiving: {},
  isManualEnterLiving: false,
};

export {
  initStateLivingAddress,
  rebuildValues,
  rebuildPersonValues,
};
