import { combineReducers } from 'redux';
import { reducer as toastrReducer } from 'react-redux-toastr';
import { setReduxGlobalHandler } from 'helpers/globalHandlerRedux';
import authReducer from './reducers/authReducer';
import helperReducer from './reducers/helperReducer';
import checkoutReducer from './reducers/checkoutReducer';
import validation from './validation/reducers';
import filtering from './filtering/reducers';
import contractInfo from './contractInfo/reducers';
import shared from './shared/reducers';
import bank from './bank/reducer';
import importForm from './import/reducers';
import transferData from './transferData/reducers';
import underwriting from './underwriting/reducers';
import files from './files/reducers';
import underwritingMaster from './underwritingMaster/reducers';
import underwritingList from './underwritingList/reducers';
import versionDp from './versioningDp/reducers';
import popups from './popups/reducers';
import events from './events/reducers';
import lockingAndTasks from './lockingAndTasks/reducers';
import partnersAdminInterface from './partnersAdminInterface/reducers';
import branchesAdminInterface from './branchesAdminInterface/reducers';
import usersAdminInterface from './usersAdmininterface/reducers';
import selects from './selects/reducers';
import beneficiaries from './beneficiaries/reducers';
import mySales from './mySales/reducers';
import reportsAdminInterface from './reportsAdminInterface/reducers';
import constructorIszInterface from './constructorIsz/reducers';
import productsAdminInterface from './productsAdmininterface/reducers';
import catalogsAdminInterface from './catalogsAdministrations/reducers';
import schedulerJobs from './schedulerJobs/reducers';
import nszProduct from './nszProduct/reducers';
import kszProduct from './kszProduct/reducers';
import agentData from './agentData/reducers';
import publicApi from './publicApi/reducers';
import contests from './contests/reducers';
import templates from './templates/reducers';
import basketConstructor from './basketConstructor/reducers';
import clientReports from './clientReports/reducers';
import docsTransfer from './docsTransfer/reducers';

const reducers = setReduxGlobalHandler({
  basketConstructor,
  agentData,
  authReducer,
  bank,
  beneficiaries,
  branchesAdminInterface,
  kszProduct,
  catalogsAdminInterface,
  checkoutReducer,
  constructorIszInterface,
  contests,
  contractInfo,
  events,
  files,
  filtering,
  helperReducer,
  importForm,
  lockingAndTasks,
  mySales,
  nszProduct,
  partnersAdminInterface,
  popups,
  productsAdminInterface,
  publicApi,
  reportsAdminInterface,
  schedulerJobs,
  selects,
  shared,
  toastr: toastrReducer,
  transferData,
  underwriting,
  underwritingList,
  underwritingMaster,
  usersAdminInterface,
  validation,
  versionDp,
  templates,
  clientReports,
  docsTransfer,
});

export default () => combineReducers(reducers);
