import React from 'react';
import { CustomRoute } from 'components';
import {
  AcceptAgreementPartnerChannel,
} from 'containers';
import { PAGE_TITLES, ROUTES } from 'consts';

const { main } = PAGE_TITLES;
const {
  partnerChannel,
} = ROUTES;

const pathes = [
  {
    path: partnerChannel,
    title: main,
    component: AcceptAgreementPartnerChannel,
    publicRoute: true,
  },
];

export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
