import React from 'react';
import { TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('captcha-block');
const { WRITE_CAPTCHA, DONT_SEE_CODE } = LANG_DICTIONARY;

const propTypes = {
  captcha: PropTypes.string,
  errors: PropTypes.object,
  touched: PropTypes.object,
  values: PropTypes.object,
  handleRecaptcha: PropTypes.func,
};

const defaultProps = {
  captcha: '',
  errors: {},
  touched: {},
  values: {},
  handleRecaptcha: () => null,
};

const CaptchaBlock = ({
  captcha,
  errors,
  touched,
  values,
  handleRecaptcha,
  ...rest
}) => (
  <div className={b()}>
    <p className={b('title')}>{WRITE_CAPTCHA}</p>
    <div className={b('content')}>
      <div className={b('image')}>
        <div dangerouslySetInnerHTML={{ __html: captcha }} />
        <div
          onClick={handleRecaptcha}
          role="button"
          tabIndex="-1"
        >
          <div className={b('button-content')}>
            <p className={b('repeat-text')}>{DONT_SEE_CODE}</p>
            <Icon name="repeat" className={b('icon').toString()} />
          </div>
        </div>
      </div>
      <TextInput
        values={values}
        errors={errors}
        touched={touched}
        name="key"
        {...rest}
      />
    </div>
  </div>
);

CaptchaBlock.defaultProps = defaultProps;
CaptchaBlock.propTypes = propTypes;

export default CaptchaBlock;
