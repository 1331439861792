import _ from 'lodash';
import {
  GET_EVENTS_MESSAGES_COUNT_SUCCESS,
  GET_EVENTS_SCANS_SUCCESS,
  GET_NOTIFICATION_SUCCESS,
  SET_READED_NOTIFICATIONS,
} from '../types';

const INITIAL_STATE = {
  errorScanCount: 0,
  notificationCount: 0,
  total: 0,
  scans: [],
  totalPages: 1,
  currentPage: 1,
  notificationsData: [],
  readedNotifications: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_READED_NOTIFICATIONS: {
      const { readedNotifications } = state;

      return {
        ...state,
        readedNotifications: [...readedNotifications, ...payload],
      };
    }
    case GET_EVENTS_MESSAGES_COUNT_SUCCESS:
      return {
        ...state,
        ...payload,
      };

    case GET_EVENTS_SCANS_SUCCESS:
      return {
        ...state,
        scans: payload.data,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
      };
    case GET_NOTIFICATION_SUCCESS: {
      const { readedNotifications } = state;
      if (payload.data) {
        const readed = payload.data.map(({ calcRequestId }) => calcRequestId);

        return {
          ...state,
          notificationsData: payload.data,
          currentPage: payload.currentPage,
          totalPages: payload.totalPages,
          readedNotifications: _.uniq([...readedNotifications, ...readed]),
        };
      }

      return {
        ...state,
        notificationsData: payload.data,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        readedNotifications: _.uniq([...readedNotifications]),
      };
    }
    default:
      return state;
  }
};
