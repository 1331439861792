import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import {
  DateCustomPicker,
  CustomCheckBox,
  InfinitySearch,
} from 'components';
import { TextInput } from 'components/_deprecated';
import './styles.scss';
import { SelectsFactory } from '../..';

const b = block('input-field');

const propTypes = {
  values: PropTypes.object,
  errors: PropTypes.object,
  name: PropTypes.string.isRequired,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  mask: PropTypes.string,
  title: PropTypes.string,
  required: PropTypes.bool,
  type: PropTypes.string,
  typeSelect: PropTypes.string,
  params: PropTypes.object,
  prefixSelect: PropTypes.string,
  prefixScroll: PropTypes.string,
};

const defaultProps = {
  values: {},
  errors: {},
  touched: {},
  disabled: false,
  mask: null,
  required: false,
  type: 'text',
  typeSelect: '',
  params: {},
  prefixSelect: '',
  title: '',
  prefixScroll: '',
};
const InputField = ({
  errors,
  name,
  touched,
  disabled,
  mask,
  title,
  type,
  values,
  typeSelect,
  params,
  prefixSelect,
  prefixScroll,
  ...rest
}) => (
  <div
    className={b({
      error: errors[name] && touched[name],
      checkbox: type === 'checkbox',
      date: type === 'date',
      select: type === 'select',
    })}
    key={name}
  >
    {title && <p className={b('title')}>{title}</p>}
    {type === 'text' && (
    <TextInput
      name={name}
      disabled={disabled}
      className={b('input', { disabled }).toString()}
      mask={mask}
      errors={errors}
      touched={touched}
      values={values}
      prefixWrapperClass={b('scroll').toString()}
      prefixScroll={prefixScroll}
      {...rest}
    />
    )}
    {type === 'checkbox' && (
      <CustomCheckBox
        name={name}
        checked={values[name]}
        prefixWrapperClass={b('scroll').toString()}
        prefixScroll={prefixScroll}
        {...rest}
      />
    )}
    {type === 'date' && (
    <div className={b('wrapper-datepicker')}>
      <DateCustomPicker
        name={name}
        values={values}
        errors={errors}
        touched={touched}
        prefixWrapperClass={b('scroll').toString()}
        prefixScroll={prefixScroll}
        {...rest}
      />
    </div>
    )}
    {type === 'searchSelect' && (
      <div className={b('wrapper-select')}>
        <InfinitySearch name={name} {...rest} />
      </div>
    )}
    {type === 'select' && (
    <div className={b('wrapper-select')}>
      <SelectsFactory
        name={name}
        type={typeSelect}
        params={params}
        errors={errors}
        touched={touched}
        values={values}
        disabled={disabled}
        prefix={prefixSelect}
        prefixWrapperClass={b('scroll').toString()}
        prefixScroll={prefixScroll}
        {...rest}
      />
    </div>
    )}
    {type === 'onlyText' && (
      <p className={b('text')}>
        {values[name]}
      </p>
    )}
  </div>
);

InputField.propTypes = propTypes;
InputField.defaultProps = defaultProps;
export default InputField;
