export default (headers, data) => ({
  key: data[5],
  data: [
    {
      md: 4,
      headers: [
        { text: headers[0] },
      ],
      data: [
        {
          text: data[0],
          style: { justifyContent: 'flex-start', paddingLeft: '10px', textAlign: 'left' },
        },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[3] },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[4] },
      ],
      data: [
        { text: data[4] },
      ],
    },
  ],
});
