const GET_FILTER_OPTIONS_START = 'GET_FILTER_OPTIONS_START';
const GET_FILTER_OPTIONS_FAIL = 'GET_FILTER_OPTIONS_FAIL';
const GET_FILTER_OPTIONS_SUCCESS = 'GET_FILTER_OPTIONS_SUCCESS';
const GET_FILTER_OPTIONS_REQUEST = 'GET_FILTER_OPTIONS_REQUEST';
const SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST = 'SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST';
const SEARCH_STATUS_PRODUCT_BY_FILTER_START = 'SEARCH_STATUS_PRODUCT_BY_FILTER_START';
const SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL = 'SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL';
const SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS = 'SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS';
const SEARCH_PRODUCT_BY_FILTER_REQUEST = 'SEARCH_PRODUCT_BY_FILTER_REQUEST';
const SEARCH_PRODUCT_BY_FILTER_START = 'SEARCH_PRODUCT_BY_FILTER_START';
const SEARCH_PRODUCT_BY_FILTER_FAIL = 'SEARCH_PRODUCT_BY_FILTER_FAIL';
const SEARCH_PRODUCT_BY_FILTER_SUCCESS = 'SEARCH_PRODUCT_BY_FILTER_SUCCESS';
const SEARCH_DOC_STATUS_BY_FILTER_START = 'SEARCH_DOC_STATUS_BY_FILTER_START';
const SEARCH_DOC_STATUS_BY_FILTER_FAIL = 'SEARCH_DOC_STATUS_BY_FILTER_FAIL';
const SEARCH_DOC_STATUS_BY_FILTER_SUCCESS = 'SEARCH_DOC_STATUS_BY_FILTER_SUCCESS';
const CLEAR_FILTERS_PRODUCTS = 'CLEAR_FILTERS_PRODUCTS';
const GET_ADMIN_OPTIONS = 'GET_ADMIN_OPTIONS';
const SEARCH_REPORT_OPTIONS_REQUEST = 'SEARCH_REPORT_OPTIONS_REQUEST';
const GET_STATUS_CHECKING_SUCCESS = 'GET_STATUS_CHECKING_SUCCESS';
const GET_STATUS_CHECKING_START = 'GET_STATUS_CHECKING_START';
const GET_STATUS_CHECKING_FAIL = 'GET_STATUS_CHECKING_FAIL';

export {
  GET_ADMIN_OPTIONS,
  CLEAR_FILTERS_PRODUCTS,
  SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST,
  SEARCH_STATUS_PRODUCT_BY_FILTER_START,
  SEARCH_STATUS_PRODUCT_BY_FILTER_FAIL,
  SEARCH_STATUS_PRODUCT_BY_FILTER_SUCCESS,
  SEARCH_PRODUCT_BY_FILTER_FAIL,
  SEARCH_PRODUCT_BY_FILTER_REQUEST,
  SEARCH_PRODUCT_BY_FILTER_START,
  SEARCH_PRODUCT_BY_FILTER_SUCCESS,
  SEARCH_DOC_STATUS_BY_FILTER_START,
  SEARCH_DOC_STATUS_BY_FILTER_FAIL,
  SEARCH_DOC_STATUS_BY_FILTER_SUCCESS,
  GET_FILTER_OPTIONS_FAIL,
  GET_FILTER_OPTIONS_REQUEST,
  GET_FILTER_OPTIONS_START,
  GET_FILTER_OPTIONS_SUCCESS,
  GET_STATUS_CHECKING_SUCCESS,
  GET_STATUS_CHECKING_START,
  GET_STATUS_CHECKING_FAIL,
  SEARCH_REPORT_OPTIONS_REQUEST,
};
