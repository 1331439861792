import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { getFormattedDate } from 'helpers';
import { CustomCheckBox, FlexTable } from 'components';
import { withCustomRouter } from 'HOC';
import template from './template';
import './styles.scss';

const b = block('branches-products-table');

const {
  PRODUCT_NAME_WORD,
  ACTIVE_DISTRIBUTING,
  START_PRODUCTS_SALE,
  ENDING_PRODUCTS_SALE,
} = LANG_DICTIONARY;

const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.object),
};
const defaultProps = {
  tableInfo: [],
};

const FlexTableBranchesProducts = ({
  tableInfo,
}) => {
  const headers = [
    PRODUCT_NAME_WORD,
    ACTIVE_DISTRIBUTING,
    START_PRODUCTS_SALE,
    ENDING_PRODUCTS_SALE,
  ];
  const rebuildData = tableInfo.map(({
    productName,
    isActive,
    dateBeg,
    dateEnd,
  }) => template(headers, [
    productName,
    <CustomCheckBox checked={isActive} />,
    dateBeg && getFormattedDate(dateBeg),
    dateEnd && getFormattedDate(dateEnd),
  ]));

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableBranchesProducts.propTypes = propTypes;
FlexTableBranchesProducts.defaultProps = defaultProps;
export default withCustomRouter(FlexTableBranchesProducts);
