import { createSelector } from 'reselect';
import { BLOCK_NAMES } from 'consts';

const getMainPath = (state, form) => state.agentData[form];

/* eslint-disable */
const getAgentPersonBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.personBlock];
const getAgentPersonBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.personBlock];
const getAgentPersonBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.personBlock];
const getAgentDocumentBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.documentBlock];
const getAgentDocumentBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.documentBlock];
const getAgentDocumentBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.documentBlock];
const getAgentAddressBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlock];
const getAgentAddressBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlock];
const getAgentAddressBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlock];
const getAgentBankBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.bankBlock];
const getAgentBankBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.bankBlock];
const getAgentBankBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.bankBlock];
const getAgentAddressLivingBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.addressBlockLiving];
const getAgentAddressLivingBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.addressBlockLiving];
const getAgentAddressLivingBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.addressBlockLiving];
const getAgentInnSnilsBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.innSnilsBlock];
const getAgentInnSnilsBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.innSnilsBlock];
const getAgentInnSnilsBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.innSnilsBlock];
const getAgentContactBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.contactBlock];
const getAgentContactBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.contactBlock];
const getAgentContactBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.contactBlock];
const getAgentRelationBlockValues = (state, form) => getMainPath(state, form).values[BLOCK_NAMES.relationBlock];
const getAgentRelationBlockErrors = (state, form) => getMainPath(state, form).errors[BLOCK_NAMES.relationBlock];
const getAgentRelationBlockTouched = (state, form) => getMainPath(state, form).touched[BLOCK_NAMES.relationBlock];
/* eslint-enable */

export const getAgentPersonBlockSelector = createSelector(
  getAgentPersonBlockValues,
  getAgentPersonBlockErrors,
  getAgentPersonBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentDocumentBlockSelector = createSelector(
  getAgentDocumentBlockValues,
  getAgentDocumentBlockErrors,
  getAgentDocumentBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressBlockSelector = createSelector(
  getAgentAddressBlockValues,
  getAgentAddressBlockErrors,
  getAgentAddressBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentBankBlockSelector = createSelector(
  getAgentBankBlockValues,
  getAgentBankBlockErrors,
  getAgentBankBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentAddressLivingBlockSelector = createSelector(
  getAgentAddressLivingBlockValues,
  getAgentAddressLivingBlockErrors,
  getAgentAddressLivingBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentInnSnilsBlockSelector = createSelector(
  getAgentInnSnilsBlockValues,
  getAgentInnSnilsBlockErrors,
  getAgentInnSnilsBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentContactBlockSelector = createSelector(
  getAgentContactBlockValues,
  getAgentContactBlockErrors,
  getAgentContactBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getAgentRelationBlockSelector = createSelector(
  getAgentRelationBlockValues,
  getAgentRelationBlockErrors,
  getAgentRelationBlockTouched,
  (values, errors, touched) => ({ values, errors, touched }),
);

export const getInsuredIsSame = ({ agentData: { insuredIsSame } }) => insuredIsSame;
export const getDisableInsuredIsSame = ({
  agentData: {
    disableInsuredIsSame,
  },
}) => disableInsuredIsSame;
