import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';
import './styles.scss';

const b = block('warning-message-popup');

const {
  ATTENTION,
} = LANG_DICTIONARY;

const propTypes = {
  message: PropTypes.string.isRequired,
  onClose: PropTypes.func.isRequired,
  className: PropTypes.string,
};

const defaultProps = {
  className: '',
};

const WarningMessagePopup = ({
  message,
  onClose,
  className,
}) => (
  <PopupContainer
    isOpen={Boolean(message)}
    close={onClose}
    className={className}
    isCloseButton
  >
    <div className={b()}>
      <p className={b('title')}>{`${ATTENTION}!`}</p>
      <p className={b('message')}>{message}</p>
    </div>
  </PopupContainer>
);

WarningMessagePopup.propTypes = propTypes;
WarningMessagePopup.defaultProps = defaultProps;
export default WarningMessagePopup;
