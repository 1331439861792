import moment from 'moment';
import { PRODUCT_VERSION } from 'consts';

export default ({
  insuredBirthDate,
  childInsuredBirthDate,
  nszProductValues,
}) => {
  const {
    insuredAvailableAgesByRisks,
    childInsuredAvailableAgesByRisks,
    scenario,
    productTerms,
  } = nszProductValues;

  const { TMax, B1Max } = insuredAvailableAgesByRisks;
  const { B2Max } = childInsuredAvailableAgesByRisks;
  const A1 = moment(moment().add(1, 'days')).diff(insuredBirthDate, 'years');
  const A2 = moment(moment().add(1, 'days')).diff(childInsuredBirthDate, 'years');

  const valuesForTMax = [TMax, (B1Max - A1), (B2Max - A2)];
  if (scenario === PRODUCT_VERSION.nsz_2) {
    const newTMax = Math.min(valuesForTMax[0], valuesForTMax[1], valuesForTMax[2]);

    return productTerms.filter((item) => item.key <= newTMax);
  }
  const newTMax = Math.min(valuesForTMax[0], valuesForTMax[1]);

  return productTerms.filter((item) => item.key <= newTMax);
};
