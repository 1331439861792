import { put, takeLatest } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';
import { ITEM_COUNT_SCHEDULER_FORM } from 'config';
import { getJobsForScheduler } from 'redux/rootActions';
import {
  STOP_SCHEDULER_JOB_FAIL,
  STOP_SCHEDULER_JOB_SUCCESS,
  STOP_SCHEDULER_JOB_START,
  STOP_SCHEDULER_JOB_REQUEST,
  START_SCHEDULER_JOB_REQUEST,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_CRON_TYPES_REQUEST,
  GET_PRIORITIES_REQUEST,
  START_SCHEDULER_JOB_SUCCESS,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_CRON_TYPES_SUCCESS,
  GET_PRIORITIES_SUCCESS,
  START_SCHEDULER_JOB_START,
  START_SCHEDULER_JOB_FAIL,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_START,
  GET_ACTIVE_JOBS_FOR_SCHEDULER_FAIL,
  GET_CRON_TYPES_START,
  GET_CRON_TYPES_FAIL,
  GET_PRIORITIES_FAIL,
  GET_PRIORITIES_START,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_START,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_FAIL,
  GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_START,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_FAIL,
  GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST,
  GET_FAIL_JOBS_FOR_SCHEDULER_START,
  GET_FAIL_JOBS_FOR_SCHEDULER_FAIL,
  GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS,
  GET_JOBS_COUNT_REQUEST,
  GET_JOBS_COUNT_START,
  GET_JOBS_COUNT_SUCCESS,
  GET_JOBS_COUNT_FAIL,
} from '../types';

function* getJobsCount() {
  try {
    yield put({ type: GET_JOBS_COUNT_START });
    const { data } = yield apiCall({
      url: API.GET_JOBS_COUNT,
      type: 'GET',
    });
    yield put({ type: GET_JOBS_COUNT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_JOBS_COUNT_FAIL, error: getError(e) });
  }
}
function* getJobs({ payload }) {
  try {
    yield put({ type: GET_ACTIVE_JOBS_FOR_SCHEDULER_START });
    const { data } = yield apiCall({
      url: API.GET_ACTIVE_JOBS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_SCHEDULER_FORM * (payload - 1),
        limit: ITEM_COUNT_SCHEDULER_FORM,
      },
    });
    yield put({
      type: GET_ACTIVE_JOBS_FOR_SCHEDULER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_ACTIVE_JOBS_FOR_SCHEDULER_FAIL, error: getError(e) });
  }
}

function* getCompleteJobs({ payload }) {
  try {
    yield put({ type: GET_COMPLETE_JOBS_FOR_SCHEDULER_START });
    const { data } = yield apiCall({
      url: API.GET_COMPLETED_JOBS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_SCHEDULER_FORM * (payload - 1),
        limit: ITEM_COUNT_SCHEDULER_FORM,
      },
    });
    yield put({
      type: GET_COMPLETE_JOBS_FOR_SCHEDULER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_COMPLETE_JOBS_FOR_SCHEDULER_FAIL, error: getError(e) });
  }
}

function* getProccessJobs({ payload }) {
  try {
    yield put({ type: GET_PROCCESS_JOBS_FOR_SCHEDULER_START });
    const { data } = yield apiCall({
      url: API.GET_PROCESS_JOBS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_SCHEDULER_FORM * (payload - 1),
        limit: ITEM_COUNT_SCHEDULER_FORM,
      },
    });
    yield put({
      type: GET_PROCCESS_JOBS_FOR_SCHEDULER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_PROCCESS_JOBS_FOR_SCHEDULER_FAIL, error: getError(e) });
  }
}

function* getFailJobs({ payload }) {
  try {
    yield put({ type: GET_FAIL_JOBS_FOR_SCHEDULER_START });
    const { data } = yield apiCall({
      url: API.GET_FAILS_JOS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_SCHEDULER_FORM * (payload - 1),
        limit: ITEM_COUNT_SCHEDULER_FORM,
      },
    });
    yield put({
      type: GET_FAIL_JOBS_FOR_SCHEDULER_SUCCESS,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_FAIL_JOBS_FOR_SCHEDULER_FAIL, error: getError(e) });
  }
}

function* startSchedulerJob({ payload }) {
  const { body, currentPage } = payload;
  try {
    yield put({ type: START_SCHEDULER_JOB_START });
    yield apiCall({
      url: API.START_SCHEDULER_JOB,
      type: 'POST',
      body,
    });
    yield put({ type: START_SCHEDULER_JOB_SUCCESS });
    yield put(getJobsForScheduler(currentPage));
  } catch (e) {
    yield put({ type: START_SCHEDULER_JOB_FAIL, error: getError(e) });
  }
}

function* stopSchedulerJob({ payload }) {
  const { taskType, currentPage } = payload;
  try {
    yield put({ type: STOP_SCHEDULER_JOB_START });
    yield apiCall({
      url: API.STOP_SCHEDULER_JOB,
      type: 'DELETE',
      body: {
        taskType,
      },
    });
    yield put({ type: STOP_SCHEDULER_JOB_SUCCESS });
    yield put(getJobsForScheduler(currentPage));
  } catch (e) {
    yield put({ type: STOP_SCHEDULER_JOB_FAIL, error: getError(e) });
  }
}

function* getCronsSelect() {
  try {
    yield put({ type: GET_CRON_TYPES_START });
    const { data } = yield apiCall({
      url: API.GET_CRON_TYPES_SELECT,
      type: 'GET',
    });
    yield put({ type: GET_CRON_TYPES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CRON_TYPES_FAIL, error: getError(e) });
  }
}

function* getPrioritiesSelect() {
  try {
    yield put({ type: GET_PRIORITIES_START });
    const { data } = yield apiCall({
      url: API.GET_JOBS_PRIORITIES,
      type: 'GET',
    });
    yield put({ type: GET_PRIORITIES_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PRIORITIES_FAIL, error: getError(e) });
  }
}

function* schedulerJobsSaga() {
  yield takeLatest(GET_ACTIVE_JOBS_FOR_SCHEDULER_REQUEST, getJobs);
  yield takeLatest(START_SCHEDULER_JOB_REQUEST, startSchedulerJob);
  yield takeLatest(STOP_SCHEDULER_JOB_REQUEST, stopSchedulerJob);
  yield takeLatest(GET_CRON_TYPES_REQUEST, getCronsSelect);
  yield takeLatest(GET_PRIORITIES_REQUEST, getPrioritiesSelect);
  yield takeLatest(GET_PROCCESS_JOBS_FOR_SCHEDULER_REQUEST, getProccessJobs);
  yield takeLatest(GET_COMPLETE_JOBS_FOR_SCHEDULER_REQUEST, getCompleteJobs);
  yield takeLatest(GET_FAIL_JOBS_FOR_SCHEDULER_REQUEST, getFailJobs);
  yield takeLatest(GET_JOBS_COUNT_REQUEST, getJobsCount);
}

export default schedulerJobsSaga;
