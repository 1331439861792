import React from 'react';
import { block } from 'bem-cn';
import { Row, Col } from 'reactstrap';
import {
  Input,
  Dimmer,
  Segment,
  Loader,
} from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { CreateEntityBtn, SelectsFactory, FlexTableAdminBranches } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('add-subdivisions-form');
const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  loadingTableInfo: PropTypes.bool,
  handleSearchOnSelect: PropTypes.func,
  handleSearchOnInput: PropTypes.func,
  history: PropTypes.object,
  searchValue: PropTypes.string,
  popUpControl: PropTypes.object,
  branchesPopUpOpen: PropTypes.bool,
  getPopUpInfo: PropTypes.func,
  dictionaryLocal: PropTypes.object,
  popUpInfo: PropTypes.object,
};
const defaultProps = {
  tableInfo: [],
  loadingTableInfo: false,
  handleSearchOnSelect: () => null,
  handleSearchOnInput: () => null,
  history: {},
  searchValue: '',
  popUpControl: () => null,
  branchesPopUpOpen: false,
  getPopUpInfo: () => null,
  dictionaryLocal: {},
  popUpInfo: {},
};

const {
  CREATE_BRANCH,
  DOWNLOADING,
  NAMING_BRANCH,
} = LANG_DICTIONARY;

const BracnhesAdminForm = ({
  tableInfo,
  loadingTableInfo,
  handleSearchOnSelect,
  handleSearchOnInput,
  history,
  searchValue,
  popUpControl,
  getPopUpInfo,
  dictionaryLocal,
  popUpInfo,
  branchesPopUpOpen,
}) => (
  <div className={b()}>
    <div className={b('controls')}>
      <Row>
        <Col md="3" sm="6">
          <SelectsFactory
            onChange={handleSearchOnSelect}
            type="partners"
            name="partnerId"
            prefix="branches"
          />
        </Col>
        <Col md="3" sm="6">
          <Input
            onChange={handleSearchOnInput}
            value={searchValue || ''}
            placeholder={NAMING_BRANCH}
          />
        </Col>
        <Col md="3" sm="6">
          <SelectsFactory
            onChange={handleSearchOnSelect}
            type="statusActive"
            prefix="branches"
            name="status"
          />
        </Col>
        <Col md="3" sm="6">
          <CreateEntityBtn
            text={CREATE_BRANCH}
            onClick={() => history.push(ROUTES.createBranch)}
          />
        </Col>
      </Row>
    </div>
    <Dimmer.Dimmable as={Segment} dimmed={loadingTableInfo}>
      <Dimmer active={loadingTableInfo} inverted>
        <Loader>{`${DOWNLOADING}...`}</Loader>
      </Dimmer>
      <FlexTableAdminBranches
        data={tableInfo}
        popUpControl={popUpControl}
        branchesPopUpOpen={branchesPopUpOpen}
        getPopUpInfo={getPopUpInfo}
        dictionaryLocal={dictionaryLocal}
        popUpInfo={popUpInfo}
      />
    </Dimmer.Dimmable>
  </div>
);

BracnhesAdminForm.propTypes = propTypes;
BracnhesAdminForm.defaultProps = defaultProps;
export default BracnhesAdminForm;
