import React from 'react';
import { block } from 'bem-cn';
import { Row, Col } from 'reactstrap';
import { Dimmer, Segment, Loader } from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  CreateEntityBtn, SelectsFactory, FlexTableAdminReports,
} from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  loadingTableInfo: PropTypes.bool,
  handleSearchOnSelect: PropTypes.func,
  history: PropTypes.object,
};

const defaultProps = {
  tableInfo: [],
  loadingTableInfo: false,
  handleSearchOnSelect: () => null,
  history: {},
};
const b = block('add-reports-form');
const {
  DOWNLOADING,
  PARTNER,
  FILTER,
  CREATE_REPORT,
} = LANG_DICTIONARY;

const ReportsAdminForm = ({
  history,
  tableInfo,
  loadingTableInfo,
  handleSearchOnSelect,
}) => (
  <div className={b()}>
    <div className={b('controls')}>
      <Row>
        <Col md="1" sm="1">
          <div className={b('filter')}>
            <p>
              {FILTER}
              :
            </p>
          </div>
        </Col>
        <Col md="4" sm="5">
          <SelectsFactory
            onChange={handleSearchOnSelect}
            placeholderText={PARTNER}
            type="partners"
            name="partnerId"
            prefix="reports"
          />
        </Col>
        <Col md="5" sm="6">
          <SelectsFactory
            onChange={handleSearchOnSelect}
            type="reportTypes"
            name="reportTypeId"
            prefix="reports"
          />
        </Col>
        <Col md="2" sm="12">
          <CreateEntityBtn
            text={CREATE_REPORT}
            onClick={() => history.push(ROUTES.createReport)}
          />
        </Col>
      </Row>
    </div>
    <Dimmer.Dimmable as={Segment} dimmed={loadingTableInfo}>
      <Dimmer active={loadingTableInfo} inverted>
        <Loader>{`${DOWNLOADING}...`}</Loader>
      </Dimmer>
      <FlexTableAdminReports
        data={tableInfo}
        history={history}
      />
    </Dimmer.Dimmable>
  </div>
);

ReportsAdminForm.propTypes = propTypes;
ReportsAdminForm.defaultProps = defaultProps;
export default ReportsAdminForm;
