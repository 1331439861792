const NORMAL_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: true,
  isProductStart: true,
  isPremium: true,
  isCurrencySelect: true,
  isInsuredBirthDay: true,
  isChildBirthDay: true,
  isBasicRisks: true,
  isAdditionalRisks: true,
  isAdditionalRisksPackage: true,
  isSexSelect: true,
  isChildrenSexSelect: true,
  isPayConditionsSelect: true,
  isCountType: true,
  isEnterSize: true,
  isAmount: true,
};

const NORMAL_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const NORMAL_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
};

const NORMAL_BENEFICIARY = {};

const NSZ_NORMAL_SETTINGS = {
  preliminary: NORMAL_PRELIMINARY,
  insurrerAgent: NORMAL_AGENT,
  insurredPerson: NORMAL_PERSON,
  beneficiary: NORMAL_BENEFICIARY,
};

export default NSZ_NORMAL_SETTINGS;
