import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { TemplatesUploadForm } from 'components';
import { useActions } from 'hooks/useActions';
import * as templatesActions from 'redux/templates/actions';

const TemplatesContainer = () => {
  const {
    errors,
    file,
    templateIsExist,
    isUploaded,
  } = useSelector((state) => state.templates);

  const {
    checkTemplate,
    uploadTemplate,
    setTemplate,
    clearUploadTemplateErrors,
    closeIsExistFileModal,
    clearStoreTemplates,
  } = useActions(templatesActions);

  useEffect(() => () => {
    clearStoreTemplates();
  }, []);

  return (
    <TemplatesUploadForm
      errors={errors}
      file={file}
      templateIsExist={templateIsExist}
      checkTemplate={checkTemplate}
      uploadTemplate={uploadTemplate}
      setTemplate={setTemplate}
      clearErrors={clearUploadTemplateErrors}
      closeIsExistFileModal={closeIsExistFileModal}
      isUploaded={isUploaded}
    />
  );
};

export default TemplatesContainer;
