import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import MainForm from '../MainForm';
import SwitcherBlcok from '../SwitcherBlock';
import AdditionalBlockBeneficiary from '../AdditionalBlock';
import '../../styles.scss';

const b = block('beneficiary');

const propTypes = {
  active: PropTypes.string,
  address: PropTypes.bool,
  addressFormData: PropTypes.object,
  addressFormDataTouched: PropTypes.object,
  addressFormDataErrors: PropTypes.object,
  contacts: PropTypes.bool,
  documents: PropTypes.bool,
  documentsFormData: PropTypes.object,
  documentsFormDataErrors: PropTypes.object,
  documentsFormDataTouched: PropTypes.object,
  formData: PropTypes.object,
  genders: PropTypes.arrayOf(PropTypes.object),
  index: PropTypes.number,
  relationships: PropTypes.arrayOf(PropTypes.object),
  setActiveTab: PropTypes.func,
  setStatusTab: PropTypes.func,
  validateForm: PropTypes.func,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  formDataErrors: PropTypes.object,
  formDataTouched: PropTypes.object,
  setAddress: PropTypes.func,
  setLivingAddressIsSame: PropTypes.func,
  setFmsUnit: PropTypes.func,
  snilsError: PropTypes.string,
  countVisableErrorsDocuments: PropTypes.number,
  countVisableErrorsAddress: PropTypes.number,
  countVisableErrorsContacts: PropTypes.number,
  contactsFormData: PropTypes.object,
  contactsFormDataErrors: PropTypes.object,
  contactsFormDataTouched: PropTypes.object,
  handleChangeNameByForm: PropTypes.func,
  handleBlurNameByForm: PropTypes.func,
  extremismErrorPerson: PropTypes.string,
  handleBlurPassportData: PropTypes.func,
  extremismErrorPassport: PropTypes.string,
  insuranceAmmountError: PropTypes.string,
  validateInsuredIsInBeneficiary: PropTypes.func,
  extremismBadInsurants: PropTypes.string,
  productForm: PropTypes.object,
  checkCode: PropTypes.func,
  userInfo: PropTypes.object,
  validateAddress: PropTypes.func,
  handleChangeAddress: PropTypes.func,
};

const defaultProps = {
  active: '',
  address: false,
  addressFormData: {},
  addressFormDataTouched: {},
  addressFormDataErrors: {},
  contacts: false,
  documents: false,
  documentsFormData: {},
  documentsFormDataErrors: {},
  documentsFormDataTouched: {},
  formData: {},
  genders: [],
  index: 0,
  relationships: [],
  setActiveTab: () => null,
  setStatusTab: () => null,
  validateForm: () => null,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  formDataErrors: {},
  formDataTouched: {},
  setAddress: () => null,
  setLivingAddressIsSame: () => null,
  setFmsUnit: () => null,
  snilsError: '',
  countVisableErrorsDocuments: 0,
  countVisableErrorsAddress: 0,
  countVisableErrorsContacts: 0,
  contactsFormData: {},
  contactsFormDataErrors: {},
  contactsFormDataTouched: {},
  handleChangeNameByForm: () => null,
  handleBlurNameByForm: () => null,
  extremismErrorPerson: '',
  handleBlurPassportData: () => null,
  extremismErrorPassport: '',
  insuranceAmmountError: '',
  validateInsuredIsInBeneficiary: () => null,
  extremismBadInsurants: '',
  productForm: {},
  checkCode: () => null,
  userInfo: {},
  validateAddress: () => null,
  handleChangeAddress: () => null,
};
const Beneficiary = ({
  active,
  address,
  addressFormData,
  addressFormDataTouched,
  addressFormDataErrors,
  contacts,
  documents,
  documentsFormData,
  documentsFormDataErrors,
  documentsFormDataTouched,
  formData,
  genders,
  index,
  relationships,
  setActiveTab,
  setStatusTab,
  validateForm,
  handleChangeByForm,
  handleTouchedByForm,
  formDataErrors,
  formDataTouched,
  setAddress,
  setLivingAddressIsSame,
  setFmsUnit,
  snilsError,
  countVisableErrorsDocuments,
  countVisableErrorsAddress,
  countVisableErrorsContacts,
  contactsFormData,
  contactsFormDataErrors,
  contactsFormDataTouched,
  handleChangeNameByForm,
  handleBlurNameByForm,
  extremismErrorPerson,
  handleBlurPassportData,
  extremismErrorPassport,
  insuranceAmmountError,
  validateInsuredIsInBeneficiary,
  extremismBadInsurants,
  productForm,
  checkCode,
  userInfo,
  validateAddress,
  handleChangeAddress,
}) => (
  <div>
    <MainForm
      index={index}
      formData={formData}
      addressFormData={addressFormData}
      formDataErrors={formDataErrors}
      formDataTouched={formDataTouched}
      handleChangeByForm={handleChangeByForm}
      handleTouchedByForm={handleTouchedByForm}
      validateForm={validateForm}
      handleBlurNameByForm={handleBlurNameByForm}
      handleChangeNameByForm={handleChangeNameByForm}
      relationships={relationships}
      genders={genders}
      insuranceAmmountError={insuranceAmmountError}
      validateInsuredIsInBeneficiary={validateInsuredIsInBeneficiary}
      isListBlackAddress={productForm.listBlackAddress}
      checkCode={checkCode}
      userInfo={userInfo}
      isAddress={address}
      validateAddress={validateAddress}
    />
    {extremismErrorPerson && <p className={b('validation-error')}>{extremismErrorPerson}</p>}
    {extremismBadInsurants && <p className={b('validation-error')}>{extremismBadInsurants}</p>}
    <div className={b('info-block-wrapper')}>
      <SwitcherBlcok
        active={active}
        setActiveTab={setActiveTab}
        setStatusTab={setStatusTab}
        index={index}
        address={address}
        contacts={contacts}
        documents={documents}
        countVisableErrorsDocuments={countVisableErrorsDocuments}
        countVisableErrorsAddress={countVisableErrorsAddress}
        countVisableErrorsContacts={countVisableErrorsContacts}
      />
      <AdditionalBlockBeneficiary
        index={index}
        type={active}
        documents={documents}
        address={address}
        contacts={contacts}
        handleChangeByForm={handleChangeByForm}
        handleTouchedByForm={handleTouchedByForm}
        birthDate={formData.birthDate}
        setAddress={setAddress}
        setFmsUnit={setFmsUnit}
        productForm={productForm}
        userInfo={userInfo}
        formData={formData}
        contactsFormData={contactsFormData}
        contactsFormDataErrors={contactsFormDataErrors}
        contactsFormDataTouched={contactsFormDataTouched}
        documentsFormData={documentsFormData}
        documentsFormDataErrors={documentsFormDataErrors}
        documentsFormDataTouched={documentsFormDataTouched}
        snilsError={snilsError}
        handleBlurPassportData={handleBlurPassportData}
        addressFormData={addressFormData}
        addressFormDataErrors={addressFormDataErrors}
        addressFormDataTouched={addressFormDataTouched}
        setLivingAddressIsSame={setLivingAddressIsSame}
        handleChangeAddress={handleChangeAddress}
      />
    </div>
    {extremismErrorPassport && <p className={b('validation-error')}>{extremismErrorPassport}</p>}
  </div>
);

Beneficiary.propTypes = propTypes;
Beneficiary.defaultProps = defaultProps;
export default memo(Beneficiary);
