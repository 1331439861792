import {
  CHANGE_SEARCH_VALUE_UNDERWRITING,
  TOGGLE_ADDITIONAL_SEARCH,
  SEARCH_CONTRACTS_REQUEST,
  TOGGLE_SHOW_ADDITIONAL_INFO,
  SWITCH_PAGINATION_PAGE,
  SET_INIT_STATE,
} from '../types';

const changeSearchValue = (name, value) => ({
  type: CHANGE_SEARCH_VALUE_UNDERWRITING,
  payload: { name, value },
});

const toggleAdditionalSearch = (value) => ({
  type: TOGGLE_ADDITIONAL_SEARCH,
  payload: value,
});

const toggleAdditionalInfo = (id, status) => ({
  type: TOGGLE_SHOW_ADDITIONAL_INFO,
  payload: { id, status },
});

const searchContractsForUnderwriting = (page, body) => ({
  type: SEARCH_CONTRACTS_REQUEST,
  payload: { page, body },
});

const switchPaginationPage = (page) => ({
  type: SWITCH_PAGINATION_PAGE,
  payload: page,
});

const setInitStateUnderwritingList = () => ({
  type: SET_INIT_STATE,
});

export {
  setInitStateUnderwritingList,
  switchPaginationPage,
  toggleAdditionalInfo,
  searchContractsForUnderwriting,
  changeSearchValue,
  toggleAdditionalSearch,
};
