import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { ScanUploadInput, MainBtn } from 'components';
import { closeModalUploadScan, updateContractScan } from 'redux/rootActions';
import { connect } from 'react-redux';
import { getFormattedDate } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import { Loader } from 'semantic-ui-react';
import './styles.scss';

const b = block('upload-scans-popup');

const heightWindow = 340;
const widthWindow = 650;

const {
  ATTENTION,
  DATE_CONTRACT,
  CONTRACT,
  INSURER,
  YOU_ULOAD_NEW_DOCUMENT,
  UPLOAD_SHORT,
} = LANG_DICTIONARY;

const propTypes = {
  ctsNum: PropTypes.string,
  dateCreate: PropTypes.string,
  fio: PropTypes.string,
  fileType: PropTypes.string,
  uploadScanModal: PropTypes.bool,
  closeUploadScan: PropTypes.func,
  updateScan: PropTypes.func,
  fileTypeCode: PropTypes.string,
  fileDocCode: PropTypes.string,
  ctsId: PropTypes.number,
  updateScanLoading: PropTypes.bool,
  left: PropTypes.number,
  top: PropTypes.number,
};

const defaultProps = {
  ctsNum: '',
  dateCreate: '',
  fio: '',
  fileType: '',
  uploadScanModal: false,
  closeUploadScan: () => null,
  updateScan: () => null,
  fileTypeCode: '',
  fileDocCode: '',
  ctsId: 0,
  updateScanLoading: false,
  left: 0,
  top: 0,
};

class ScanUpload extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      file: null,
    };

    this.veil = React.createRef();
  }

  closePopup = (e) => {
    const { closeUploadScan, updateScanLoading } = this.props;
    if (e.target === this.veil.current && !updateScanLoading) {
      closeUploadScan();
    }
  };

  uploadNewScan = () => {
    const {
      updateScan,
      fileTypeCode,
      fileDocCode,
      ctsId,
    } = this.props;
    const { file } = this.state;
    const formData = new FormData();
    formData.append('file', file);
    formData.append('fileDocCode', fileDocCode);
    formData.append('fileTypeCode', fileTypeCode);
    formData.append('ctsId', ctsId);

    if (file) updateScan(ctsId, formData);
  }

  setFile = (file) => {
    this.setState({ file });
  }

  render() {
    const {
      ctsNum,
      dateCreate,
      fio,
      fileType,
      uploadScanModal,
      updateScanLoading,
      left,
      top,
    } = this.props;

    const { file } = this.state;

    return (
      <>
        {uploadScanModal
    && (
      <div
        className={b()}
        onClick={this.closePopup}
        tabIndex="-1"
        role="button"
        ref={this.veil}
      >
        <div className={b('window')} style={{ left: left - widthWindow, top: top - heightWindow }}>
          <div className={b('content')}>
            <p className={b('title')}>{`${ATTENTION}!`}</p>
            <table className={b('table')}>
              <tbody>
                <tr>
                  <td>
                    {`${CONTRACT}:`}
                  </td>
                  <td>
                    {ctsNum}
                  </td>
                </tr>
                <tr>
                  <td>
                    {`${DATE_CONTRACT}:`}
                  </td>
                  <td>
                    {getFormattedDate(dateCreate)}
                  </td>
                </tr>
                <tr>
                  <td>
                    {`${INSURER}:`}
                  </td>
                  <td>
                    {fio}
                  </td>
                </tr>
              </tbody>
            </table>
            <p className={b('warning')}>{`${YOU_ULOAD_NEW_DOCUMENT}: ${fileType}`}</p>
            <div className={b('upload-scan')}>
              <ScanUploadInput file={file} setFile={this.setFile} />
            </div>
            <div className={b('controls')}>
              <MainBtn
                disabled={updateScanLoading}
                text={UPLOAD_SHORT}
                onClick={this.uploadNewScan}
              />
              {updateScanLoading && (
              <div className={b('loader')}>
                <Loader active inline />
              </div>
              )}
            </div>
          </div>
        </div>
      </div>
    ) }
      </>
    );
  }
}

const mapStateToProps = ({
  contractInfo: {
    contract: {
      ctsNum,
      dateCreate,
      ctsId,
    },
    updateScanLoading,
    insurer: {
      fio,
    },
  },
  popups: {
    uploadScanModal,
    uploadScanData: {
      fileType,
      fileTypeCode,
      fileDocCode,
      left,
      top,
    },
  },
}) => ({
  updateScanLoading,
  ctsId,
  fileTypeCode,
  fileDocCode,
  uploadScanModal,
  ctsNum,
  dateCreate,
  fio,
  fileType,
  left,
  top,
});

const mapDispatchToProps = ({
  closeUploadScan: closeModalUploadScan,
  updateScan: updateContractScan,
});

ScanUpload.propTypes = propTypes;
ScanUpload.defaultProps = defaultProps;

export default connect(mapStateToProps, mapDispatchToProps)(ScanUpload);
