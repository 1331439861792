export const chartConfig = {
  autosize: true,
  timezone: 'Etc/UTC',
  theme: 'light',
  style: '3',
  locale: 'ru',
  toolbar_bg: '#f1f3f6',
  enable_publishing: false,
  withdateranges: true,
  range: '12M',
  allow_symbol_change: true,
  container_id: 'tradingview_chart_root_id',
};
