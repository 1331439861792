/* eslint-disable no-new */
import React, { useEffect } from 'react';
import { useScript } from 'hooks/useScript';
import PropTypes from 'prop-types';
import { TRADING_VIEW_URLS } from '../../tradingViewConfig';
import { miniChartConfig } from './config';

const propTypes = {
  widgetSymbols: PropTypes.arrayOf(PropTypes.string).isRequired,
};

const { chartUrl, name } = TRADING_VIEW_URLS;

const MiniChartWidget = ({ widgetSymbols }) => {
  const { TradingView } = useScript(chartUrl, name);

  useEffect(() => {
    if (TradingView) {
      new TradingView.MediumWidget({
        ...miniChartConfig,
        symbols: widgetSymbols,
      });
    }
  }, [TradingView]);

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_minichart_root_id" />
    </div>
  );
};

MiniChartWidget.propTypes = propTypes;

export default MiniChartWidget;
