import {
  SEARCH_BANK_START,
  SEARCH_BANK_SUCCESS,
  SEARCH_BANK_RESET,
  SEARCH_BANK_FAIL,
  SEARCH_BANK_VALUE,
} from '../types';

const INITIAL_STATE = {
  bankSearchResult: [],
  isLoading: false,
  error: '',
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SEARCH_BANK_START: {
      return { ...state, isLoading: true };
    }

    case SEARCH_BANK_SUCCESS: {
      return { ...state, bankSearchResult: payload, isLoading: false };
    }

    case SEARCH_BANK_FAIL: {
      return { ...state, isLoading: false, error: payload };
    }

    case SEARCH_BANK_VALUE: {
      return { ...state, bankValues: payload.bank };
    }

    case SEARCH_BANK_RESET: {
      return { ...state, bankValues: [] };
    }
    default:
      return state;
  }
};
