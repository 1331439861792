import { PRODUCT_VERSION } from 'consts';
import insurer from '../commonData/insurer';
import bankData from '../commonData/bankData';
import insured from '../commonData/insured';
import beneficiaries from '../commonData/beneficiaries';

const dataToScenario = {
  [PRODUCT_VERSION.ksz_1]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured,
    beneficiaries,
  },
};

export default (scenario) => dataToScenario[scenario];
