const NORMAL_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: false,
  isProductStart: true,
  isPremium: true,
};

const NORMAL_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const NORMAL_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const NORMAL_BENEFICIARY = {

};

const NORMAL_SETTINGS = {
  preliminary: NORMAL_PRELIMINARY,
  insurrerAgent: NORMAL_AGENT,
  insurredPerson: NORMAL_PERSON,
  beneficiary: NORMAL_BENEFICIARY,
};

export default NORMAL_SETTINGS;
