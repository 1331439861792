class HistoryStorage {
  constructor() {
    this.setInitStorage();
  }

  setActionBeforeRedirect(action) {
    this.actionBeforeRedirect = action;
    this.listenRedirect = true;
  }

  setInitStorage = () => {
    this.actionBeforeRedirect = () => null;
    this.listenRedirect = false;
  }
}

const historyStorage = new HistoryStorage();

export default historyStorage;
