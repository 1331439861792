import {
  GET_PARTNERS_OPTIONS_REQUEST,
  SELECT_OPTIONS,
  GET_BRANCHES_OPTIONS_REQUEST,
  GET_REPORT_TYPE_OPTIONS_REQUEST,
} from '../types';

const getOptionsForPartnerSelect = (params, prefix) => ({
  type: GET_PARTNERS_OPTIONS_REQUEST,
  payload: { params, prefix },
});

const getOptionsForBranchSelect = (params, prefix) => ({
  type: GET_BRANCHES_OPTIONS_REQUEST,
  payload: { prefix, params },
});

const getOptionsForReportTypeSelect = (params, prefix) => ({
  type: GET_REPORT_TYPE_OPTIONS_REQUEST,
  payload: { prefix, params },
});

const selectOption = (selectType, value, prefix) => ({
  type: SELECT_OPTIONS,
  payload: {
    selectType,
    value,
    prefix,
  },
});

export {
  getOptionsForReportTypeSelect,
  getOptionsForPartnerSelect,
  selectOption,
  getOptionsForBranchSelect,
};
