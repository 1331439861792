const CHECK_PODFT = 'check_podft';
const AUTH_SIGNOUT = 'auth_signout';
const AUTH_CHANGE_TEMP_PASS = 'change_temp_pass';
const AUTH_CHECK_USER = 'get_current_user';
const FIAS_ADDRESS = 'get_fias_address';
const FIAS_BANK = 'get_fias_bank';
const FORM_PRODUCT = 'get_product_list';
const FORM_PRODUCT_SELECT = 'product_selection';
const FORM_PRODUCT_FORM = 'get_product_parameters';
const FORM_CHECK_SUM_INSURED = 'check_sum_insured';
const FORM_GENDER_LIST = 'get_references';
const FORM_BENEFICIARY_LIST = 'get_beneficiary_list';
const FORM_RELATIONSHIP_LIST = 'get_references';
const FORM_SAVE_DATA_CONTRACT = 'save_contract';
const FORM_GET_DATA_CONTRACT = 'get_data_contract';
const FORM_DOWNLOAD_CONTRACT = 'download_contract';
const FORM_UPDATE_STATUS_CONTRACT = 'update_status_contract';
const FORM_UPLOAD_DOCUMENT_SCAN = 'upload_document_scan';
const FORM_SAVE_DOCUMENT_SCAN = 'save_document_scan';
const SEARCH_PRODUCT = 'search_by_product';
const SEARCH_STATUS = 'search_by_status';
const SEARCH_BRANCH = 'search_by_branch';
const SEARCH_PARTNER = 'search_by_partner';
const SEARCH_LIST = 'get_search_list';
const SEARCH_EXTENDED = 'get_extended_contract';
const SEARCH_DOWNLOAD = 'download_search_document';
const IMPORT_UPLOAD_REFERENCIES = 'import_upload_referencies';
const IMPORT_DOWNLOAD_TEMPLATE = 'import_download_template';
const IMPORT_SCHEDULT = 'import_schedule';
const IMPORT_CANCELED = 'import_canceled';
const IMPORT_JOBS = 'import_jobs';
const EXPORT_1C = 'export_1c';
const LOG_PAGE_LOGGING = 'page_logging';
const DOCUMENT_CHECKING_GET_DOCUMENT = 'get_document_for_checking';
const DOCUMENT_CHECKING_GET_BRANCH_LIST = 'get_document_check_by_branch';
const DOCUMENT_CHECKING_GET_USER_LIST = 'get_document_check_by_user';
const DOCUMENT_CHECKING_GET_CHECKING_USER_LIST = 'get_document_check_by_checking_user';
const DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING = 'search_document_list_for_checking';
const DOCUMENT_CHECKING_GET_DOCUMENT_COUNT = 'get_document_count_for_checking';
const DOCUMENT_CHECKING_GET_ERROR_LIST = 'get_document_check_error_list';
const DOCUMENT_CHECKING_SAVE_DOCUMENT = 'save_document_after_checking';
const DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST = 'search_document_list';
const DOCUMENT_CHECKING_GET_ALL_BRANCH_LIST = 'get_all_branch_list';
const DOCUMENT_CHECKING_CHECK_STATUS_LIST = 'get_check_status_list';
const DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT = 'unlock_all_document';
const DOCUMENT_CHECKING_UNLOCK_DOCUMENT = 'unlock_document';
const UPDATE_DOCUMENT_SCAN = 'update_document_scan';
const GETTING_BRANCHES_FOR_AUTH = 'getting_branches_for_auth';
const GETTING_PARTNERS_FOR_AUTH = 'getting_partners_for_auth';
const SET_AFFILIATION = 'set_affiliation';
const CHECK_IS_ACTIVE_CONTEST = 'check_is_active_contest';
const DOWNLOAD_CONTEST_DATA = 'download_contest_data';

export {
  DOWNLOAD_CONTEST_DATA,
  CHECK_IS_ACTIVE_CONTEST,
  AUTH_CHANGE_TEMP_PASS,
  AUTH_CHECK_USER,
  AUTH_SIGNOUT,
  CHECK_PODFT,
  DOCUMENT_CHECKING_CHECK_STATUS_LIST,
  DOCUMENT_CHECKING_GET_ALL_BRANCH_LIST,
  DOCUMENT_CHECKING_GET_BRANCH_LIST,
  DOCUMENT_CHECKING_GET_CHECKING_USER_LIST,
  DOCUMENT_CHECKING_GET_DOCUMENT,
  DOCUMENT_CHECKING_GET_DOCUMENT_COUNT,
  DOCUMENT_CHECKING_GET_ERROR_LIST,
  DOCUMENT_CHECKING_GET_USER_LIST,
  DOCUMENT_CHECKING_SAVE_DOCUMENT,
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST,
  DOCUMENT_CHECKING_SEARCH_DOCUMENT_LIST_FOR_CHECKING,
  DOCUMENT_CHECKING_UNLOCK_ALL_DOCUMENT,
  DOCUMENT_CHECKING_UNLOCK_DOCUMENT,
  EXPORT_1C,
  FIAS_ADDRESS,
  FIAS_BANK,
  FORM_BENEFICIARY_LIST,
  FORM_CHECK_SUM_INSURED,
  FORM_DOWNLOAD_CONTRACT,
  FORM_GENDER_LIST,
  FORM_GET_DATA_CONTRACT,
  FORM_PRODUCT,
  FORM_PRODUCT_FORM,
  FORM_PRODUCT_SELECT,
  FORM_RELATIONSHIP_LIST,
  FORM_SAVE_DATA_CONTRACT,
  FORM_SAVE_DOCUMENT_SCAN,
  FORM_UPDATE_STATUS_CONTRACT,
  FORM_UPLOAD_DOCUMENT_SCAN,
  GETTING_BRANCHES_FOR_AUTH,
  GETTING_PARTNERS_FOR_AUTH,
  IMPORT_CANCELED,
  IMPORT_DOWNLOAD_TEMPLATE,
  IMPORT_JOBS,
  IMPORT_SCHEDULT,
  IMPORT_UPLOAD_REFERENCIES,
  LOG_PAGE_LOGGING,
  SEARCH_BRANCH,
  SEARCH_DOWNLOAD,
  SEARCH_EXTENDED,
  SEARCH_LIST,
  SEARCH_PARTNER,
  SEARCH_PRODUCT,
  SEARCH_STATUS,
  SET_AFFILIATION,
  UPDATE_DOCUMENT_SCAN,
};
