import React, { PureComponent } from 'react';
import PropTypes from 'prop-types';
import { DocumentBlock, InnSnilsBlock } from 'components';
import { ENUMS } from 'consts';
import '../../styles.scss';

const { BENEFICIARY_FORM_ENUM } = ENUMS;

const propTypes = {
  values: PropTypes.object,
  touched: PropTypes.object,
  errors: PropTypes.object,
  index: PropTypes.number,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  snilsError: PropTypes.string,
  birthDate: PropTypes.string,
  setFmsUnit: PropTypes.func,
};

const form = BENEFICIARY_FORM_ENUM.documents;

const defaultProps = {
  values: {},
  touched: {},
  errors: {},
  index: 0,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  snilsError: '',
  birthDate: '',
  setFmsUnit: () => null,
};
class DocumentBlockBeneficiary extends PureComponent {
  componentDidUpdate(prevProps) {
    const {
      values,
      values: { when },
      index,
      handleChangeByForm,
      birthDate,
    } = this.props;

    const currentValue = {
      target: {
        value: when,
        name: 'when',
      },
    };

    const validateArgs = { birthDate };

    if (prevProps.birthDate !== birthDate) {
      handleChangeByForm(
        currentValue,
        form,
        index,
        values,
        validateArgs,
      );
    }
  }

  saveFmsUnit = (data) => {
    const { setFmsUnit, index, values } = this.props;
    setFmsUnit(data, index, values);
  };

  setFieldValue = (name, value) => {
    const e = { target: { value, name } };
    const {
      handleChangeByForm,
      index,
      values,
    } = this.props;

    handleChangeByForm(e, form, index, values);
  };

  onChangeValue = (e) => {
    const {
      handleChangeByForm,
      index,
      values,
      birthDate,
    } = this.props;
    handleChangeByForm(e, form, index, values, { birthDate });
  }

  render() {
    const {
      values,
      touched,
      errors,
      index,
      handleTouchedByForm,
      snilsError,
    } = this.props;

    const inputProps = {
      onChange: this.onChangeValue,
      onFocus: (e) => handleTouchedByForm(e, form, index),
      values,
      touched,
      errors,
      prefixScroll: `${form}${index}`,
    };

    return (
      <div>
        <DocumentBlock
          inputProps={inputProps}
          documentType={values.documentType}
          isResetDataOnChangeDocumentData
          selectSearch={this.saveFmsUnit}
          setFieldValue={this.setFieldValue}
        />
        <InnSnilsBlock
          validateSnils={(val) => Boolean(val.length) && !snilsError}
          inputProps={inputProps}
        />
      </div>
    );
  }
}

DocumentBlockBeneficiary.propTypes = propTypes;
DocumentBlockBeneficiary.defaultProps = defaultProps;
export default DocumentBlockBeneficiary;
