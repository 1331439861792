import moment from 'moment/moment';
import {
  object,
  string,
  number,
  date,
} from 'yup';
import { LANG_DICTIONARY } from 'consts';
import { comparisonValues, onlyNumbers, separateThousands } from 'helpers';

const {
  REQUIRED,
  AMOUNT_SHOULD_BE,
  WARNING_FOR_BIG_POLICY_SUM,
  PERSON_MIN_AGE_ERROR,
  PERSON_MAX_AGE_ERROR,
  PERSON_MAX_AGE_ERROR_MONTHS,
  PERSON_MAX_AGE_ERROR_MONTH,
  IS_DATE,
  DATE_INVALID,
} = LANG_DICTIONARY;

const birthDateRestrictions = (product, productVersion, productPeriod, ageRestrictions) => {
  const {
    restrictions: {
      insured,
      insured: [{
        maxAge,
        maxAgeType,
        minAgeType,
      }],
    },
    insLineIszObjects,
    regDate,
  } = product;

  const getTerm = () => {
    if (productPeriod) {
      const term = product.productTerms.find((item) => item.termId === productPeriod).value;

      return term;
    }

    return '';
  };

  const getCalculatedMaxAge = () => {
    if (!productVersion || productVersion === insLineIszObjects[0].id) {
      return maxAge;
    }

    return insured[1].maxAge;
  };

  const countYears = (val) => moment(regDate).diff(val, 'years');
  const calculatedMaxAge = getCalculatedMaxAge() - getTerm();

  const schema = date()
    .typeError(IS_DATE)
    .max(new Date(), DATE_INVALID)
    .required(REQUIRED);

  return schema
    .test('len', PERSON_MIN_AGE_ERROR(ageRestrictions.minAge), (val) => {
      const fullYears = countYears(val);

      return comparisonValues(fullYears, ageRestrictions.minAge, minAgeType);
    })
    .test('len', PERSON_MAX_AGE_ERROR(calculatedMaxAge), (val) => {
      const fullYears = countYears(val);

      return comparisonValues(fullYears, calculatedMaxAge, maxAgeType);
    });
};

const birthDateRestrictionsIsz = (
  product,
  productVersion,
  productPeriod,
  ageRestrictions,
  birthDate,
) => {
  const {
    regDate,
  } = product;

  const countYears = (val) => moment(regDate).diff(val, 'years');

  const getTerm = () => {
    if (productPeriod) {
      const term = product.productTerms.find((item) => item.termId === productPeriod).value;

      return term;
    }

    return '';
  };

  const getTermInYears = () => {
    if (productPeriod) {
      const term = product.productTerms.find((item) => item.termId === productPeriod).termInYears;

      return term;
    }

    return 0;
  };

  const getTermInMonths = () => {
    if (productPeriod) {
      const term = product.productTerms.find((item) => item.termId === productPeriod).termInMonths;

      return term;
    }

    return 0;
  };

  const fullYears = countYears(birthDate);

  const maxErrorOnStart = () => {
    if (
      (fullYears > ageRestrictions.maxAgeStart
          && fullYears > ageRestrictions.maxAgeEnd - getTermInYears())
      ||
      (fullYears > ageRestrictions.maxAgeStart
            && fullYears <= ageRestrictions.maxAgeEnd - getTermInYears())
    ) {
      return true;
    }

    return false;
  };

  const minErrorOnStart = () => {
    if (
      (fullYears < ageRestrictions.minAgeStart
    && fullYears < ageRestrictions.minAgeEnd - getTerm())
      ||
    (fullYears < ageRestrictions.minAgeStart
    && fullYears >= ageRestrictions.minAgeEnd - getTerm())) {
      return true;
    }

    return false;
  };

  const getErrorPostfix = (month) => {
    switch (month) {
      case 0:
        return '';
      case 1:
        return ` ${month} ${PERSON_MAX_AGE_ERROR_MONTH}`;
      default:
        return ` ${month} ${PERSON_MAX_AGE_ERROR_MONTHS}`;
    }
  };

  const getErrorMessageWithMonths = () => {
    const ageDiffInMonths = (ageRestrictions.maxAgeEnd * 12) - getTermInMonths();
    const yearsDiff = Math.floor(ageDiffInMonths / 12);
    const monthsDiff = ageDiffInMonths - (yearsDiff * 12);

    return PERSON_MAX_AGE_ERROR(yearsDiff) + getErrorPostfix(monthsDiff);
  };

  const personMaxAgeError = maxErrorOnStart()
    ? PERSON_MAX_AGE_ERROR(ageRestrictions.maxAgeStart)
    : getErrorMessageWithMonths();

  const schema = date()
    .typeError(IS_DATE)
    .max(new Date(), DATE_INVALID)
    .required(REQUIRED);

  return schema
    .test('len', PERSON_MIN_AGE_ERROR(minErrorOnStart()
      ? ageRestrictions.minAgeStart
      : ageRestrictions.minAgeEnd - getTerm()), () => fullYears >= ageRestrictions.minAgeStart
          && fullYears + getTerm() >= ageRestrictions.minAgeEnd)
    .test('len', personMaxAgeError, () => fullYears <= ageRestrictions.maxAgeStart
          && fullYears + getTermInYears() <= ageRestrictions.maxAgeEnd);
};

export default (
  product,
  productVersion,
  productPeriod,
  ageRestrictions,
  isIszScenario,
  birthDate,
) => object().shape({
  productPeriod: string()
    .nullable(REQUIRED)
    .required(REQUIRED),
  productVersion: number()
    .transform((val) => val || 0)
    .test('len', REQUIRED, (value) => {
      const {
        insLineIszObjects,
      } = product;

      if (!insLineIszObjects.length) {
        return true;
      }

      return Boolean(value);
    }),
  policySum: string().test({
    name: 'policySum',
    test(value) {
      const {
        currencyName,
        iszRestrictions,
        insLineIszObjects,
      } = product;

      const restriction = insLineIszObjects.length ?
        iszRestrictions.find(({ insLineIszObjectId }) => insLineIszObjectId === productVersion) :
        iszRestrictions[0];

      if (!restriction) {
        return this.createError({
          message: 'Ограниченния с выбранной периодичностью не найдены',
          path: 'policySum',
        });
      }

      const {
        minType,
        maxType,
        noMax,
        noMin,
        minValue,
        maxValue,
      } = restriction;

      const valueString = value || '';
      const val = onlyNumbers(valueString);

      if (!noMin && !comparisonValues(val, minValue, minType)) {
        return this.createError({
          message: `${AMOUNT_SHOULD_BE} ${minType} ${separateThousands(minValue)} ${currencyName}`,
          path: 'policySum',
        });
      }

      if (!noMax && !comparisonValues(val, maxValue, maxType)) {
        return this.createError({
          message: WARNING_FOR_BIG_POLICY_SUM((`${separateThousands(maxValue)} ${currencyName}`)),
          path: 'policySum',
        });
      }

      return true;
    },
  }),
  birthDate: isIszScenario ?
    birthDateRestrictionsIsz(
      product,
      productVersion,
      productPeriod,
      ageRestrictions,
      birthDate,
    )
    : birthDateRestrictions(product, productVersion, productPeriod, ageRestrictions),
  premiumFreqId: string()
    .nullable(REQUIRED)
    .required(REQUIRED),
});
