import { useCallback, useEffect } from 'react';
import { PAGINATION_DEFAULT_INDEX } from 'consts';

export const usePagination = ({
  totalPages,
  currentPage,
  query,
  func,
}) => {
  useEffect(() => {
    func(PAGINATION_DEFAULT_INDEX);
  }, []);

  const nextPage = currentPage + PAGINATION_DEFAULT_INDEX;
  const prevPage = currentPage - PAGINATION_DEFAULT_INDEX;

  const handleUpPage = useCallback(() => {
    if (totalPages >= nextPage) {
      func(nextPage, query);
    }
  }, [nextPage, totalPages, query]);

  const handleDownPage = useCallback(() => {
    if (prevPage >= PAGINATION_DEFAULT_INDEX) {
      func(prevPage, query);
    }
  }, [prevPage, query]);

  return {
    handleUpPage,
    handleDownPage,
  };
};
