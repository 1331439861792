const GET_EVENTS_MESSAGES_COUNT_REQUEST = 'GET_EVENTS_MESSAGES_COUNT_REQUEST';
const GET_EVENTS_MESSAGES_COUNT_SUCCESS = 'GET_EVENTS_MESSAGES_COUNT_SUCCESS';
const GET_EVENTS_MESSAGES_COUNT_START = 'GET_EVENTS_MESSAGES_COUNT_START';
const GET_EVENTS_MESSAGES_COUNT_FAIL = 'GET_EVENTS_MESSAGES_COUNT_FAIL';

const GET_EVENTS_SCANS_REQUEST = 'GET_EVENTS_SCANS_REQUEST';
const GET_EVENTS_SCANS_SUCCESS = 'GET_EVENTS_SCANS_SUCCESS';
const GET_EVENTS_SCANS_START = 'GET_EVENTS_SCANS_START';
const GET_EVENTS_SCANS_FAIL = 'GET_EVENTS_SCANS_FAIL';

const GET_NOTIFICATION_START = 'GET_NOTIFICATION_START';
const GET_NOTIFICATION_SUCCESS = 'GET_NOTIFICATION_SUCCESS';
const GET_NOTIFICATION_FAIL = 'GET_NOTIFICATION_FAIL';
const GET_NOTIFICATION_REQUEST = 'GET_NOTIFICATION_REQUEST';

const SET_READED_NOTIFICATIONS = 'SET_READED_NOTIFICATIONS';

const POST_READED_NOTIFICATIONS_START = 'POST_READED_NOTIFICATIONS_START';
const POST_READED_NOTIFICATIONS_FAIL = 'POST_READED_NOTIFICATIONS_FAIL';
const POST_READED_NOTIFICATIONS_REQUEST = 'POST_READED_NOTIFICATIONS_REQUEST';

export {
  POST_READED_NOTIFICATIONS_FAIL,
  POST_READED_NOTIFICATIONS_REQUEST,
  POST_READED_NOTIFICATIONS_START,
  SET_READED_NOTIFICATIONS,
  GET_NOTIFICATION_START,
  GET_NOTIFICATION_SUCCESS,
  GET_NOTIFICATION_FAIL,
  GET_NOTIFICATION_REQUEST,
  GET_EVENTS_SCANS_REQUEST,
  GET_EVENTS_SCANS_SUCCESS,
  GET_EVENTS_SCANS_FAIL,
  GET_EVENTS_SCANS_START,
  GET_EVENTS_MESSAGES_COUNT_FAIL,
  GET_EVENTS_MESSAGES_COUNT_SUCCESS,
  GET_EVENTS_MESSAGES_COUNT_START,
  GET_EVENTS_MESSAGES_COUNT_REQUEST,
};
