import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { Button, Icon } from 'semantic-ui-react';
import './styles.scss';

const defaultProps = {
  errors: 0,
};

const propTypes = {
  onChangeTab: PropTypes.func.isRequired,
  onClick: PropTypes.func.isRequired,
  index: PropTypes.number.isRequired,
  activeTab: PropTypes.number.isRequired,
  label: PropTypes.string.isRequired,
  type: PropTypes.string.isRequired,
  tabsValues: PropTypes.string.isRequired,
  errors: PropTypes.number,
  validateTabsBeneficiaries: PropTypes.bool.isRequired,
};

const TabItem = ({
  onChangeTab,
  index,
  activeTab,
  onClick,
  label,
  type,
  tabsValues,
  errors,
  validateTabsBeneficiaries,
}) => {
  const isActive = index === activeTab;
  const itemStatus = tabsValues[type];

  const itemClass = classNames({
    tabItem: true,
    active: isActive,
  });
  const isRender = validateTabsBeneficiaries && errors !== 0;

  return (
    <div className={itemClass} key={index}>
      <Button
        type="button"
        className="footerPanel__userManual__button"
        onClick={() => onClick(type, index)}
      >
        { itemStatus ? (<Icon className="actionButton removeButton" name="times" />)
          : (<Icon className="actionButton plusButton" name="plus" />)}
      </Button>
      <Button
        type="button"
        className="footerPanel__userManual__button"
        onClick={() => onChangeTab(index)}
      >
        {label}
      </Button>
      {
        isRender && (
          <div className="errors">{errors}</div>
        )
      }
    </div>
  );
};

TabItem.defaultProps = defaultProps;
TabItem.propTypes = propTypes;

export default TabItem;
