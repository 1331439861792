import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import {
  setStageKszProduct,
} from 'redux/rootActions';
import { ROUTES } from 'consts';
import { InsurerDataV2 } from 'containers';
import { addressCheckingPayloadKszSelector, productIdKszSelector } from 'redux/rootSelectors';

const defaultProps = {};

const propTypes = {
  setStage: PropTypes.func.isRequired,
};

const KszInsurer = ({ setStage, ...rest }) => (
  <InsurerDataV2
    setStage={setStage}
    insuredDataUrl={ROUTES.kszInsuredData}
    preliminaryCalculationUrl={ROUTES.kszParameters}
    {...rest}
  />
);

KszInsurer.propTypes = propTypes;
KszInsurer.defaultProps = defaultProps;

const mapStateToProps = (state) => ({
  addressCheckingPayload: addressCheckingPayloadKszSelector(state),
  productId: productIdKszSelector(state),
});

const mapDispatchToProps = {
  setStage: setStageKszProduct,
};

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(KszInsurer));
