import { put, all } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';
import { CHECK_PODFT_DATA } from '../types';

function* checkAgent({ payload }) {
  const { CHECK_PODFT_DATA_API } = API;

  try {
    yield put({ type: CHECK_PODFT_DATA.start });

    const result = yield all(payload.data.map((item) => apiCall({
      type: 'POST',
      body: item.body,
      url: CHECK_PODFT_DATA_API,
    })));

    const data = result.map((item, index) => ({
      type: payload.data[index].agentName,
      ...item.data,
    }));

    const isSuccess = result.every((item) => item.data.result);

    if (isSuccess) {
      payload.next();
    }

    yield put({ type: CHECK_PODFT_DATA.success, payload: { data, isSuccess } });
  } catch (error) {
    yield put({ type: CHECK_PODFT_DATA.fail, payload: { message: getError(error) } });
  }
}

export default checkAgent;
