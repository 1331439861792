import React from 'react';
import PropTypes from 'prop-types';
import {
  PartersAdminForm,
  AdministrativeSectionInterface,
  PaginationPanel,
} from 'components';
import { withCustomRouter } from 'HOC';
import { ROUTES } from 'consts';
import { connect } from 'react-redux';
import {
  getPartnersForAdminTable,
  setPagePartnersAdminTable,
  selectOption,
} from 'redux/rootActions';

const { createPartner } = ROUTES;

const propTypes = {
  active: PropTypes.number,
  getPartnersForTable: PropTypes.func,
  location: PropTypes.object,
  page: PropTypes.number,
  setOption: PropTypes.func,
  statusSiteSelect: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  statusActiveSelect: PropTypes.oneOfType([
    PropTypes.number,
    PropTypes.bool,
  ]),
  partnersSelect: PropTypes.number,
  history: PropTypes.object,
  setPage: PropTypes.func,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  loadingTableInfo: PropTypes.bool,
  totalPages: PropTypes.number,
};

const defaultProps = {
  totalPages: 1,
  setPage: () => null,
  active: 0,
  getPartnersForTable: () => null,
  location: {},
  page: 1,
  setOption: () => null,
  statusSiteSelect: null,
  statusActiveSelect: null,
  partnersSelect: null,
  history: {},
  tableInfo: [],
  loadingTableInfo: false,
};

class PartersAdministration extends React.Component {
  constructor(props) {
    super(props);
    const {
      location: { state },
      setOption,
    } = props;
    const isCache = state && state.cache;
    if (!isCache) {
      setOption('statusSite', null);
      setOption('partners', null);
      setOption('statusActive', null);
    }
  }

  componentDidMount() {
    const {
      getPartnersForTable,
      location: { state },
      page,
    } = this.props;
    const isCache = state && state.cache;
    const currentPage = isCache ? page : 1;

    const params = isCache ? this.getQueryParams() : {};
    getPartnersForTable(currentPage, params);
  }

  createPartner = () => {
    const { history } = this.props;
    history.push(createPartner);
  }

  getQueryParams = () => {
    const {
      statusSiteSelect,
      statusActiveSelect,
      partnersSelect,
    } = this.props;

    return {
      status: statusActiveSelect,
      statusSite: statusSiteSelect,
      partnerId: partnersSelect,
    };
  }

  handleSearchOnSelect = (e, { value, name }) => {
    const {
      getPartnersForTable,
      setPage,
    } = this.props;

    const params = this.getQueryParams();
    getPartnersForTable(1, {
      ...params,
      [name]: value,
    });

    setPage(1);
  }

  handleDownPage = () => {
    const {
      page,
      getPartnersForTable,
      loadingTableInfo,
    } = this.props;
    if (page > 1 && !loadingTableInfo) {
      getPartnersForTable(page - 1, this.getQueryParams());
    }
  }

  handleUpPage = () => {
    const {
      page,
      getPartnersForTable,
      totalPages,
    } = this.props;
    if (page !== totalPages) {
      getPartnersForTable(page + 1, this.getQueryParams());
    }
  }

  render() {
    const {
      active,
      history,
      tableInfo,
      page,
      loadingTableInfo,
      totalPages,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active}>
        <PartersAdminForm
          createPartner={this.createPartner}
          history={history}
          tableInfo={tableInfo}
          handleSearch={this.handleSearchOnSelect}
          loadingTableInfo={loadingTableInfo}
        />
        <PaginationPanel
          active={page}
          total={totalPages}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  partnersAdminInterface: {
    loadingTableInfo,
    tableInfo,
    page,
    totalPages,
  },
  selects: {
    statusSiteSelect,
    statusActiveSelect,
    partnersSelect,
  },
}) => ({
  loadingTableInfo,
  tableInfo,
  page,
  statusSiteSelect,
  statusActiveSelect,
  partnersSelect,
  totalPages,
});

const mapDispatchToProps = {
  getPartnersForTable: getPartnersForAdminTable,
  setPage: setPagePartnersAdminTable,
  setOption: selectOption,
};

PartersAdministration.propTypes = propTypes;
PartersAdministration.defaultProps = defaultProps;

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(
  (PartersAdministration),
));
