import _ from 'lodash';

export const CHANGE_STATE_AUTO_GENERATED = 'CHANGE_STATE(AUTO_GENERATED)';
export const getReduxGlobalActionType = (reducer) => `${reducer}/${CHANGE_STATE_AUTO_GENERATED}`;
export const getReduxGlobalAction = (reducerPath, data) => {
  const [reducer, ...arrayPath] = reducerPath.split('.');
  const path = arrayPath.join('.');

  return {
    type: getReduxGlobalActionType(reducer),
    payload: {
      data,
      path,
    },
  };
};

export const setReduxGlobalHandler = (reducers) => Object
  .entries(reducers).reduce((acc, [key, reducer]) => ({
    ...acc,
    [key]: (state, { type, payload, ...rest }, ...args) => {
      if (type === getReduxGlobalActionType(key)) {
        const { path, data } = payload;
        const localState = _.get(state, path, {});
        const newState = _.set(state, path, { ...localState, ...data });

        return {
          ...newState,
        };
      }

      return reducer(state, { type, payload, ...rest }, ...args);
    },
  }), {});
