import { simpleDate } from 'helpers';
import { LANG_DICTIONARY } from 'consts';

const { INSURED_IS_BENEFICIARY_ERROR } = LANG_DICTIONARY;

export default (beneficaryData, insuredData, isChild, childInsuredData) => {
  const {
    firstName: firstNameBeneficiary,
    lastName: lastNameBeneficiary,
    middleName: middleNameBeneficiary,
    birthDate: birthDateBeneficiary,
  } = beneficaryData;

  const {
    firstName: firstNameInsured,
    lastName: lastNameInsured,
    middleName: middleNameInsured,
    birthDate: birthDateInsured,
  } = insuredData;

  const isExistBenefiaryData = firstNameBeneficiary
    && lastNameBeneficiary
    && middleNameBeneficiary
    && birthDateBeneficiary;

  if (!isExistBenefiaryData) return null;

  const birthDateBeneficiaryMoment = simpleDate(birthDateBeneficiary);
  const birthDateInsuredMoment = simpleDate(birthDateInsured);

  const insuredIsBeneficary = firstNameBeneficiary === firstNameInsured
    && lastNameBeneficiary === lastNameInsured
    && middleNameBeneficiary === middleNameInsured
    && birthDateBeneficiaryMoment === birthDateInsuredMoment;

  if (isChild) {
    const {
      childFirstName,
      childLastName,
      childMiddleName,
      childBirthdate,
    } = childInsuredData;

    const birthDateChildMoment = simpleDate(childBirthdate);

    const childIsBenefeciary = firstNameBeneficiary === childFirstName
    && lastNameBeneficiary === childLastName
    && middleNameBeneficiary === childMiddleName
    && birthDateBeneficiaryMoment === birthDateChildMoment;

    return insuredIsBeneficary || childIsBenefeciary ? INSURED_IS_BENEFICIARY_ERROR : null;
  }

  return insuredIsBeneficary ? INSURED_IS_BENEFICIARY_ERROR : null;
};
