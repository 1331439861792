import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { StatusScanChecking } from 'components';
import { connect } from 'react-redux';
import { getScan, openModalHistoryScan, openModalUploadScan } from 'redux/rootActions';
import { getElementDocumentPosition } from 'helpers';
import SaveIcon from 'assets/images/save-icon.png';
import EditIcon from 'assets/images/edit-icon.png';
import './styles.scss';

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  downloadScan: PropTypes.func,
  openHistoryScan: PropTypes.func,
  openUploadScan: PropTypes.func,
};

const defaultProps = {
  openHistoryScan: () => null,
  downloadScan: () => null,
  openUploadScan: () => null,
};

const b = block('scans-block');

const ScansBlock = ({
  openHistoryScan,
  downloadScan,
  openUploadScan,
  data,
}) => {
  const handleClickSaveButton = (e, scan) => {
    const { history, id, fileName } = scan;
    const { left, top } = getElementDocumentPosition(e);
    if (history && history.length) {
      openHistoryScan({ ...scan, left, top });
    } else {
      downloadScan(id, fileName);
    }
  };

  const handleClickEditIcon = (e, fileType, fileDocCode, fileTypeCode) => {
    const { left, top } = getElementDocumentPosition(e);
    openUploadScan({
      left,
      top,
      fileType,
      fileDocCode,
      fileTypeCode,
    });
  };

  const getSaveButton = (scan, fileName) => (
    <div
      className={b('save-icon')}
      role="button"
      tabIndex="-1"
      onClick={(e) => handleClickSaveButton(e, scan, fileName)}
    >
      <img src={SaveIcon} alt="save-icon" />
    </div>
  );

  const getEditButton = (isError, fileType, fileDocCode, fileTypeCode) => isError && (
    <div
      className={b('update-scan-icon')}
      role="button"
      onClick={(e) => handleClickEditIcon(e, fileType, fileDocCode, fileTypeCode)}
      tabIndex="-1"
    >
      <img src={EditIcon} alt="edit-icon" />
    </div>
  );

  const sortFunc = (first, second) => (first.fileType < second.fileType ? -1 : 1);

  return (
    <section className={b()}>
      {data && data.sort(sortFunc).map((scan) => {
        const {
          fileType,
          isError,
          checkStatusCode,
          checkErrorDescription,
          id,
          fileTypeCode,
          checkErrorName,
          fileDocCode,
          fileName,
          checkStatusName,
        } = scan;

        return (
          <div className={b('scan')} key={`${id}${fileType}`}>
            <p className={b('file-type')}>{`${fileType}:`}</p>
            {getSaveButton(scan, fileName)}
            <div className={b('status-icon')}>
              <StatusScanChecking status={checkStatusCode} />
            </div>
            <p className={b('status')}>{checkErrorDescription || checkErrorName || checkStatusName}</p>
            {getEditButton(isError, fileType, fileDocCode, fileTypeCode)}
          </div>
        );
      })}
    </section>
  );
};

const mapDispatchToProps = {
  downloadScan: getScan,
  openHistoryScan: openModalHistoryScan,
  openUploadScan: openModalUploadScan,
};

ScansBlock.propTypes = propTypes;
ScansBlock.defaultProps = defaultProps;
export default connect(null, mapDispatchToProps)(ScansBlock);
