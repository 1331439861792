import {
  AUTH_REQUEST,
  CHECK_PASS_REQUEST,
  RESET_REQUEST,
  CHANGE_PASSWORD,
  GET_CURRENT_USER,
  LOG_OUT,
  GET_IP_ADDRESS_REQUEST,
  RESET_TEMPORARY_PASSWORD_REQUEST,
  GET_CAPTCHA_REQUEST,
  RECAPTCHA,
  LOG_OUT_SUCCESS,
  SET_STORE_TOKEN,
  GET_BRANCHES_FOR_AUTH_REQUEST,
  GET_PARTNERS_FOR_AUTH_REQUEST,
  SET_AFFILIATION_REQUEST,
  TOGGLE_MODAL_AFFILIATION,
  SELECT_BRANCH_FOR_AUTH,
  SELECT_PARTNER_FOR_AUTH,
  CHECK_AUTH_SESSION,
  SET_UNITED_FRONTEND_PARAMS,
  TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION,
  UPDATE_UNITED_FRONTEND_SESSION,
  OPEN_WINDOW_BLOCK_UNITED_FRONT,
  SET_IS_TEST_BRANCH,
  ACCEPT_AGREEMENT_RULES,
  CLEAR_FORM_PARTNER_CHANNEL,
  CHANGE_FORM_PARTNER_CHANNEL,
  PASSWORD_RECOVERY,
  CHANGE_RECOVERY_LOGIN_MODAL,
  LOGIN_RECOVERY,
} from 'redux/reducers/types';

const clearFormPartnerChannel = () => ({
  type: CLEAR_FORM_PARTNER_CHANNEL,
});

const changeFormPartnerChannel = (name, value) => ({
  type: CHANGE_FORM_PARTNER_CHANNEL,
  payload: {
    name,
    value,
  },
});

const acceptAgreementRulesPartnerChannel = (isAuth, ruleIds, partnerId) => ({
  type: ACCEPT_AGREEMENT_RULES.request,
  payload: {
    isAuth,
    ruleIds,
    partnerId,
  },
});

const setIsTestBranch = (isTestBranch) => ({
  type: SET_IS_TEST_BRANCH,
  payload: isTestBranch,
});

const loginRecoveryRequest = (payload) => ({
  type: LOGIN_RECOVERY.request,
  payload,
});

const authorize = ({
  userLogin,
  userPassword,
  history,
  captchaId,
  key,
  action,
  masterId,
  tabUuid,
  tabTimestamp,
  riskProfile,
  qualification,
}) => ({
  type: AUTH_REQUEST,
  payload: {
    userLogin,
    userPassword,
    history,
    captchaId,
    key,
    action,
    masterId,
    tabUuid,
    tabTimestamp,
    riskProfile,
    qualification,
  },
});

const resetPassRequest = (userEmail) => ({
  type: RESET_REQUEST,
  payload: { userEmail },
});

const checkPassChangeAccess = ({ search, hash }) => ({
  type: CHECK_PASS_REQUEST,
  payload: { search, hash },
});

const resetPassword = ({
  userEmail,
  confirmationToken,
  userPassword,
  history,
}) => ({
  type: CHANGE_PASSWORD,
  payload: {
    userEmail,
    confirmationToken,
    userPassword,
    history,
  },
});

const getCurrentUser = ({
  token,
  history,
  action,
  initApp,
}) => ({
  type: GET_CURRENT_USER,
  payload: {
    token,
    history,
    action,
    initApp,
  },
});

const logOut = ({
  history,
  mute,
  redirect,
  isUnitedFront,
  masterId,
  riskProfile,
  qualification,
}) => ({
  type: LOG_OUT,
  payload: {
    history,
    mute,
    redirect,
    isUnitedFront,
    masterId,
    riskProfile,
    qualification,
  },
});

const getIp = () => ({
  type: GET_IP_ADDRESS_REQUEST,
});

const getCaptcha = ({ captchaId, recaptcha }) => ({
  type: GET_CAPTCHA_REQUEST,
  payload: { captchaId, recaptcha },
});

const getRecaptcha = (id) => ({
  type: RECAPTCHA,
  payload: { captchaId: id },
});

const resetTemporaryPassword = ({ userPassword, history }) => ({
  type: RESET_TEMPORARY_PASSWORD_REQUEST,
  payload: { userPassword, history },
});

const forgotPassword = (userLogin) => ({
  type: PASSWORD_RECOVERY.request,
  payload: { userLogin },
});

const logOutLocal = () => ({
  type: LOG_OUT_SUCCESS,
});

const setToken = (token) => ({
  type: SET_STORE_TOKEN,
  payload: token,
});

const getAccessBranches = (value, selectedByUser, history) => ({
  type: GET_BRANCHES_FOR_AUTH_REQUEST,
  payload: value,
  selectedByUser,
  history,
});

const getAccessPartners = ({ history, initApp }) => ({
  type: GET_PARTNERS_FOR_AUTH_REQUEST,
  history,
  initApp,
});

const setAffiliation = ({
  branchId,
  partnerId,
  history,
  isAuth,
}) => ({
  type: SET_AFFILIATION_REQUEST,
  payload: {
    branchId,
    partnerId,
  },
  history,
  isAuth,
});

const toggleModalAffiliation = (status) => ({
  type: TOGGLE_MODAL_AFFILIATION,
  payload: status,
});

const selectBranchAuth = (value) => ({
  type: SELECT_BRANCH_FOR_AUTH,
  payload: value,
});

const selectPartnerAuth = (value) => ({
  type: SELECT_PARTNER_FOR_AUTH,
  payload: value,
});

const checkAuthSession = () => ({
  type: CHECK_AUTH_SESSION.request,
});

const setUnitedFrontendParams = ({
  masterId,
  riskProfile,
  qualification,
}) => ({
  type: SET_UNITED_FRONTEND_PARAMS,
  payload: {
    masterId,
    riskProfile,
    qualification,
  },
});

const togglePopupUpdatingSessionUnitedFront = (status) => ({
  type: TOGGLE_POPUP_UNITED_FRONT_CHANGE_SESSION,
  payload: status,
});

const updateUnitedFrontSession = ({
  riskProfile,
  masterId,
  qualification,
  windowTimestamp,
  windowUuid,
}) => ({
  type: UPDATE_UNITED_FRONTEND_SESSION.request,
  payload: {
    riskProfile,
    masterId,
    qualification,
    tabTimestamp: windowTimestamp,
    tabUuid: windowUuid,
  },
});

const openWindowBlockUnitedFront = () => ({
  type: OPEN_WINDOW_BLOCK_UNITED_FRONT,
});

const changeRecoveryLoginModal = (payload) => ({
  type: CHANGE_RECOVERY_LOGIN_MODAL,
  payload,
});

export {
  openWindowBlockUnitedFront,
  updateUnitedFrontSession,
  togglePopupUpdatingSessionUnitedFront,
  setUnitedFrontendParams,
  checkAuthSession,
  selectBranchAuth,
  selectPartnerAuth,
  toggleModalAffiliation,
  setAffiliation,
  getAccessBranches,
  getAccessPartners,
  setToken,
  logOutLocal,
  getRecaptcha,
  getCaptcha,
  resetTemporaryPassword,
  getIp,
  authorize,
  resetPassRequest,
  checkPassChangeAccess,
  resetPassword,
  getCurrentUser,
  logOut,
  setIsTestBranch,
  acceptAgreementRulesPartnerChannel,
  clearFormPartnerChannel,
  changeFormPartnerChannel,
  forgotPassword,
  changeRecoveryLoginModal,
  loginRecoveryRequest,
};
