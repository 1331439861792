import React from 'react';
import {
  Dashboard,
  MySales,
  ContractInfo,
  Events,
  Contests,
  DocumentCheckStatus,
  CustomerReportingService,
  DocsTransferInbox,
  DocsTransferSent,
  DocsTransferCreate,
} from 'containers';
import { CustomRoute } from 'components';
import { PAGE_TITLES, ROUTES } from 'consts';

const {
  dashboard,
  sales,
  eventsTitle,
  contests: contestsTitle,
  documentCheckStatus: documentCheckStatusTitle,
  customerReportingService: customerReportingServiceTitle,
  docsTransfer: docsTransferTitle,
} = PAGE_TITLES;
const {
  home,
  myClients,
  events,
  contests,
  documentCheckStatus,
  customerReportingService,
  inboxDocsTransferMessages,
  sentDocsTransferMessages,
  createDocsTransferMessage,
} = ROUTES;

const pathes = [
  {
    path: home,
    title: dashboard,
    component: Dashboard,
  },
  {
    path: '/index.html',
    title: dashboard,
    component: Dashboard,
  },
  {
    path: documentCheckStatus,
    title: documentCheckStatusTitle,
    component: DocumentCheckStatus,
  },
  {
    path: customerReportingService,
    title: customerReportingServiceTitle,
    component: CustomerReportingService,
  },
  {
    path: inboxDocsTransferMessages,
    title: docsTransferTitle,
    component: DocsTransferInbox,
  },
  {
    path: sentDocsTransferMessages,
    title: docsTransferTitle,
    component: DocsTransferSent,
  },
  {
    path: createDocsTransferMessage,
    title: docsTransferTitle,
    component: DocsTransferCreate,
  },
  {
    path: myClients,
    title: sales,
    component: MySales,
  },
  {
    path: `${myClients}/:id/:active/:subActive`,
    title: sales,
    component: ContractInfo,
  },
  {
    path: `${myClients}/:id/:active`,
    title: sales,
    component: ContractInfo,
  },
  {
    path: `${myClients}/:id`,
    title: sales,
    component: ContractInfo,
  },
  {
    path: events,
    title: eventsTitle,
    component: Events,
  },
  {
    path: contests,
    title: contestsTitle,
    component: Contests,
  },
];
export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
