import React, { useEffect, useState } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, TEMPLATES_MODAL_CODES } from 'consts';
import PropTypes from 'prop-types';
import { TemplatesPopup, FileUploadAdmin } from 'components';
import _ from 'lodash';
import './styles.scss';

const b = block('templates-panel');

const {
  UPLOAD_TEMPLATES_TITLE,
  UPLOAD_TEMPLATE_INTERFACE,
  UPLOAD_TEMPLATE_BUTTON,
} = LANG_DICTIONARY;

const propTypes = {
  errors: PropTypes.arrayOf(PropTypes.object),
  file: PropTypes.object,
  templateIsExist: PropTypes.bool,
  checkTemplate: PropTypes.func,
  uploadTemplate: PropTypes.func,
  setTemplate: PropTypes.func,
  clearErrors: PropTypes.func,
  closeIsExistFileModal: PropTypes.func,
  isUploaded: PropTypes.bool,
};

const defaultProps = {
  errors: [],
  file: null,
  templateIsExist: false,
  checkTemplate: () => null,
  uploadTemplate: () => null,
  setTemplate: () => null,
  clearErrors: () => null,
  closeIsExistFileModal: () => null,
  isUploaded: false,
};

const TemplatesUploadForm = (props) => {
  const {
    errors,
    file,
    templateIsExist,
    checkTemplate,
    uploadTemplate,
    setTemplate,
    clearErrors,
    closeIsExistFileModal,
    isUploaded,
  } = props;

  const [controls, setControls] = useState({
    removeFile: () => null,
  });

  const clearInputFile = () => {
    controls.removeFile();
  };

  useEffect(() => {
    if (isUploaded) {
      clearInputFile();
    }
  }, [isUploaded]);

  const uploadExistedTemplate = () => {
    closeIsExistFileModal();
    const formData = new FormData();
    formData.append('file', file);
    uploadTemplate(formData);
  };

  const fileName = _.get(file, 'name', '');

  const closeExistPopup = () => {
    setTemplate(null);
    closeIsExistFileModal();
    clearInputFile();
  };

  const isOpenErrors = Boolean(errors.length);

  const uploadFile = ({ clearAction }, fileForUpload) => {
    setControls({
      removeFile: clearAction,
    });
    setTemplate(fileForUpload);
    const formData = new FormData();
    formData.append('file', fileForUpload);

    checkTemplate(formData);
  };

  return (
    <div className={b()}>
      <TemplatesPopup
        closePopup={closeExistPopup}
        popupIsOpen={templateIsExist}
        fileName={fileName}
        type={TEMPLATES_MODAL_CODES.exist}
        uploadTemplate={uploadExistedTemplate}
      />
      <TemplatesPopup
        closePopup={clearErrors}
        popupIsOpen={isOpenErrors}
        errors={errors}
        type={TEMPLATES_MODAL_CODES.error}
      />
      <section className={b('title')}>
        <div className={b('title-text')}>
          {UPLOAD_TEMPLATES_TITLE}
        </div>
      </section>
      <div className={b('content')}>
        <section className={b('info-block')}>
          {UPLOAD_TEMPLATE_INTERFACE}
        </section>
        <section className={b('download')}>
          <FileUploadAdmin
            title={UPLOAD_TEMPLATE_BUTTON}
            nameInputFile="template"
            uploadFile={uploadFile}
            acceptFiles=".doc,.docx"
          />
        </section>
      </div>
    </div>
  );
};

TemplatesUploadForm.defaultProps = defaultProps;
TemplatesUploadForm.propTypes = propTypes;
export default TemplatesUploadForm;
