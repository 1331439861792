import React, { Component } from 'react';
import block from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import ReactDom from 'react-dom';
import { KEY_NAMES } from 'consts';
import './styles.scss';

const popup = block('popup-container');

const propTypes = {
  close: PropTypes.func,
  isOpen: PropTypes.bool,
  children: PropTypes.node,
  isCloseButton: PropTypes.bool,
  isCloseClickOutside: PropTypes.bool,
  className: PropTypes.string,
  zIndex: PropTypes.number,
};

const defaultProps = {
  close: () => null,
  isOpen: false,
  children: null,
  isCloseButton: false,
  isCloseClickOutside: true,
  className: '',
  zIndex: null,
};

class PopupContainer extends Component {
  constructor() {
    super();
    this.veil = React.createRef();
  }

  escFunction = (event) => {
    const { close } = this.props;

    if (event.key === KEY_NAMES.KEY_ESCAPE) {
      close();
    }
  }

  componentDidMount() {
    document.addEventListener('keydown', this.escFunction, false);
  }

  componentWillUnmount() {
    document.removeEventListener('keydown', this.escFunction, false);
  }

  closePopup = (e) => {
    const { close } = this.props;
    if (e.target === this.veil.current) {
      close();
    }
  };

  render() {
    const {
      isOpen,
      children,
      isCloseButton,
      className,
      isCloseClickOutside,
      close,
      zIndex,
    } = this.props;

    return isOpen && ReactDom.createPortal(
      <>
        <div
          className="veil"
          ref={this.veil}
          onClick={(e) => isCloseClickOutside && this.closePopup(e)}
          role="button"
          tabIndex="0"
          id="veil"
          style={{ ...zIndex && { zIndex } }}
        >
          <div className={popup.mix(className)}>
            {isCloseButton && (
            <div
              className={popup('close-btn')}
              onClick={close}
              role="button"
              tabIndex="-1"
            >
              <Icon name="close" />
            </div>
            )}
            {children}
          </div>
        </div>
      </>, document.getElementsByClassName('App')[0],
    );
  }
}

PopupContainer.propTypes = propTypes;
PopupContainer.defaultProps = defaultProps;
export default PopupContainer;
