import { LANG_DICTIONARY, NSZ_CALCULATOR_ENUMS } from 'consts';
import {
  validateNszBithDate,
  validateNszBirthDateChild,
  validatePaymentFreq,
  validateInsuranceSum,
  validateAdditionalRisk,
  checkForRiskHelpMessage,
  getRegularEnterError,
} from 'helpers';
import { object, string } from 'yup';
import _ from 'lodash';
import { dateFormatter } from '../../../helpers';

const {
  VALIDATE_INSURED_AGE,
  VALIDATE_INSURED_CHILD_AGE,
  VALIDATE_INSURANCE_SUM,
  VALIDATE_ADDITIONAL_RISK,
  VALIDATE_RISK_WEIGHT,
  NOT_FOUND_REGULAR_SUM,
  NOT_FOUND_INSURANCE_SUM,
  WARNING_FOR_CORRECTION_COEF,
} = LANG_DICTIONARY;

const {
  insuredBirthDate,
  childInsuredBirthDate,
  regularPaymentAmount,
  accumulationAmount,
} = NSZ_CALCULATOR_ENUMS;

const getSchema = (type, variables, riskName) => {
  const {
    B1Min,
    B1Max,
    B2Min,
    B2Max,
    Z,
    C2Max,
    C2Min,
    D2Min,
    D2Max,
    minWeight,
    maxWeight,
    TMin,
    minValue,
    minType,
    maxValue,
    maxType,
    C1Min,
    C1Max,
    D1Min,
    D1Max,
    coefId,
    isCurrencyScenario,
  } = variables;

  switch (type) {
    case insuredBirthDate: {
      const min = Math.max(B1Min, C1Min, D1Min - TMin);
      const max = Math.min(B1Max, C1Max, D1Max - TMin);

      return {
        insuredBirthDate: string().test('', VALIDATE_INSURED_AGE(min, max), (value) => validateNszBithDate(value, variables)),
      };
    }

    case childInsuredBirthDate: {
      const min = Math.max(B2Min, C2Min, D2Min - TMin);
      const max = Math.min(B2Max, C2Max, D2Max - TMin);

      return {
        childInsuredBirthDate: string()
          .test('', VALIDATE_INSURED_CHILD_AGE(min, max), (value) => validateNszBirthDateChild(value, variables)),
      };
    }

    case regularPaymentAmount: {
      return {
        regularPaymentAmount: string()
          .test('len', NOT_FOUND_REGULAR_SUM, () => !(
            _.isNil(minValue) ||
            _.isNil(minType) ||
            _.isNil(maxValue) ||
            _.isNil(maxType)
          ))
          .test('len', getRegularEnterError(variables), (value) => validatePaymentFreq(variables, value))
          .test('', WARNING_FOR_CORRECTION_COEF, () => (coefId !== null) === isCurrencyScenario),
      };
    }

    case accumulationAmount: {
      const validation = string().test({
        test(value) {
          if (!variables.length) {
            return this.createError({
              message: NOT_FOUND_INSURANCE_SUM,
              path: 'accumulationAmount',
            });
          }

          const error = variables
            .find((item) => !validateInsuranceSum(item, value));

          return error
            ? this.createError({
              message: VALIDATE_INSURANCE_SUM(error.Smin, error.Smax),
              path: 'accumulationAmount',
            })
            : true;
        },
      });

      return {
        accumulationAmount: validation,
      };
    }

    case 'additional': {
      return {
        [riskName]: string().test('', VALIDATE_RISK_WEIGHT(minWeight, maxWeight), (value) => validateAdditionalRisk(variables, value)),
      };
    }

    case 'additionalHints': {
      return {
        [riskName]: string().test('', VALIDATE_ADDITIONAL_RISK(dateFormatter(Z, 'год')), () => checkForRiskHelpMessage(variables)),
      };
    }

    default: {
      return {};
    }
  }
};

const schema = (type, variables, riskName) => object().shape({
  ...getSchema(type, variables, riskName),
});

export default schema;
