import React from 'react';
import { Select } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import FieldRow from '../FieldRow';
import './styles.scss';

const b = block('basket-creating-form-product-type');

const {
  NOT_CHOOSED,
  PRODUCT_TYPE,
} = LANG_DICTIONARY;

const propTypes = {
  productTypeOptions: PropTypes.arrayOf(PropTypes.object).isRequired,
  productTypeCode: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  helpMessage: PropTypes.string,
};

const defaultProps = {
  helpMessage: '',
  productTypeCode: null,
};

const ProductType = ({
  onChange,
  helpMessage,
  productTypeOptions,
  productTypeCode,
}) => (
  <FieldRow
    helpMessage={helpMessage}
    label={PRODUCT_TYPE}
    className={b()}
  >
    <Select
      options={productTypeOptions}
      value={productTypeCode}
      onChange={onChange}
      placeholder={NOT_CHOOSED}
    />
  </FieldRow>
);

ProductType.propTypes = propTypes;
ProductType.defaultProps = defaultProps;

export default ProductType;
