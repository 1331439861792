import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { ImportForm } from 'components';
import { withCustomRouter } from 'HOC';
import { IMPORT_PODFT_TYPE } from 'consts';
import {
  resetAllError,
  downloadTemplate,
  uploadFileImport,
  resetCurrentError,
  getInfoScheduleTask,
  createScheduleTask,
  cancelScheduleTask,
  getActiveTaskInfo,
  getSelectsCrons,
  setInitStateImport,
} from 'redux/rootActions';
import { getPodftVersion, uploadPodftFileImport } from 'redux/import/actions';

const propTypes = {
  resetErrors: PropTypes.func,
  importUploadReferencies: PropTypes.bool,
  history: PropTypes.object,
  getScheduleTask: PropTypes.func,
  location: PropTypes.object,
  roles: PropTypes.object,
  uploadFile: PropTypes.func,
  getTaskInfo: PropTypes.func,
  getCrons: PropTypes.func,
  activeTaskInfoImport: PropTypes.object,
  clearImportTask: PropTypes.func,
  setInitState: PropTypes.func,
  getDate: PropTypes.func,
  versions: PropTypes.object,
  uploadPodftFile: PropTypes.func,
};

const defaultProps = {
  clearImportTask: () => null,
  activeTaskInfoImport: {},
  getCrons: () => null,
  getTaskInfo: () => null,
  resetErrors: () => {},
  importUploadReferencies: false,
  history: {},
  getScheduleTask: () => null,
  uploadFile: () => null,
  location: {},
  roles: {},
  setInitState: () => null,
  getDate: () => null,
  versions: {},
  uploadPodftFile: () => null,
};

class Import extends Component {
  constructor(props) {
    super(props);
    const { resetErrors } = props;
    resetErrors();

    this.state = {
      link: '',
      path: '',
      isNetwork: false,
    };
  }

  componentDidMount = () => {
    const {
      getTaskInfo,
      getCrons,
      setInitState,
      getDate,
    } = this.props;
    setInitState();
    getTaskInfo('import_passports');
    getCrons();
    getDate(Object.values(IMPORT_PODFT_TYPE));
  }

  handleChangeInput = ({ target: { value, name } }) => {
    this.setState({ [name]: value });
  };

  toggleCheckbox = (e, { checked }) => {
    this.setState({ isNetwork: checked });
  }

  render() {
    const { link, path, isNetwork } = this.state;
    const { activeTaskInfoImport, versions } = this.props;

    return (
      <ImportForm
        versions={versions}
        link={link}
        path={path}
        isNetwork={isNetwork}
        handleChangeInput={this.handleChangeInput}
        toggleCheckbox={this.toggleCheckbox}
        activeTaskInfoImport={activeTaskInfoImport}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = (
  {
    importForm: {
      errors,
      isUpload,
      currentFileUpload,
      lastJob,
      nextJob,
      loading,
      success: successInfoTask,
      versions,
    },
    authReducer: {
      roles,
    },
    helperReducer: {
      activeTaskInfoImport,
    },
    schedulerJobs: {
      cronTypeSelect,
    },
  },
) => ({
  activeTaskInfoImport,
  roles,
  lastJob,
  nextJob,
  errors,
  isUpload,
  currentFileUpload,
  loading,
  successInfoTask,
  cronTypeSelect,
  versions,
});

Import.propTypes = propTypes;
Import.defaultProps = defaultProps;

export default withCustomRouter(connect(mapStateToProps, {
  resetErrors: resetAllError,
  getExcelFile: downloadTemplate,
  uploadFile: uploadFileImport,
  resetCurrError: resetCurrentError,
  getScheduleTask: getInfoScheduleTask,
  createTask: createScheduleTask,
  cancelTask: cancelScheduleTask,
  getTaskInfo: getActiveTaskInfo,
  getCrons: getSelectsCrons,
  setInitState: setInitStateImport,
  getDate: getPodftVersion,
  uploadPodftFile: uploadPodftFileImport,
})(Import));
