import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withCustomRouter } from 'HOC';
import Bluebird from 'bluebird';
import {
  PRODUCT_VERSION,
  NSZ_CALCULATOR_ENUMS,
  ROUTES,
  LANG_DICTIONARY,
} from 'consts';
import {
  getProducts,
  getNszProductInfo,
  changeFieldNszProduct,
  getGenderList,
  clearStoreNszProducts,
  risksListControl,
  getAdditionalRisks,
  getPackageRisks,
  countSumCalculator,
  saveDataNszForm,
  changeAgentFieldValue,
  setStageProduct,
  printPayment,
} from 'redux/rootActions';
import {
  getValidationError,
  replaceAllButNumbers,
  simpleDate,
  rebuildErrors,
  calculateAccessTermsNsz,
  enterDecimalValue,
  checkIsCurrencyScenario,
} from 'helpers';
import { NszForm } from 'components';
import schema from './schema';

export const CALC_TYPES = {
  from_premium: 'from_premium',
  from_sum: 'from_sum',
};

const RISK_TYPES = {
  standart: 'standart',
  package: 'package',
};

const { REQUIRED } = LANG_DICTIONARY;

const defaultProps = {
  getAdditionalsRisks: () => null,
  history: null,
  getAllProducts: () => null,
  saveStore: () => null,
  getProductInfo: () => null,
  fieldValues: {},
  nszProductValues: {},
  genderList: [],
  getGenders: () => null,
  risksControl: () => null,
  risksLists: {},
  getPackage: () => null,
  countSum: () => null,
  saveData: () => null,
  productSelection: {
    status: false,
    message: null,
  },
  refExchangeRatesRegimesCoefs: null,
};

const propTypes = {
  saveData: PropTypes.func,
  countSum: PropTypes.func,
  getAdditionalsRisks: PropTypes.func,
  risksLists: PropTypes.object,
  genderList: PropTypes.arrayOf(PropTypes.object),
  nszProductValues: PropTypes.object,
  saveStore: PropTypes.func,
  history: PropTypes.object,
  match: PropTypes.object.isRequired,
  getAllProducts: PropTypes.func,
  getProductInfo: PropTypes.func,
  fieldValues: PropTypes.object,
  getGenders: PropTypes.func,
  risksControl: PropTypes.func,
  getPackage: PropTypes.func,
  changeAgentValue: PropTypes.func.isRequired,
  downloadPrintPayment: PropTypes.func.isRequired,
  productSelection: PropTypes.object,
  refExchangeRatesRegimesCoefs: PropTypes.arrayOf(PropTypes.object),
};

class NszCalculationComponent extends Component {
  componentDidMount() {
    const {
      match: { params: { id } },
      getAllProducts,
      getProductInfo,
      getGenders,
    } = this.props;

    getProductInfo(id);
    getAllProducts(id);
    getGenders();
  }

  getOptionsForValidation = () => {
    const {
      nszProductValues: {
        childInsuredAvailableAgesByRestrictionsOnBeginningContract = {},
        childInsuredAvailableAgesByRestrictionsOnEndContract = {},
        childInsuredAvailableAgesByRisks = {},
        childInsuredMaxAgeByRestrictions = {},
        insuredAvailableAgesByRestrictionsOnBeginningContract = {},
        insuredAvailableAgesByRestrictionsOnEndContract = {},
        insuredAvailableAgesByRisks = {},
      },
    } = this.props;

    return {
      ...childInsuredAvailableAgesByRestrictionsOnBeginningContract,
      ...childInsuredAvailableAgesByRestrictionsOnEndContract,
      ...childInsuredAvailableAgesByRisks,
      ...childInsuredMaxAgeByRestrictions,
      ...insuredAvailableAgesByRestrictionsOnBeginningContract,
      ...insuredAvailableAgesByRestrictionsOnEndContract,
      ...insuredAvailableAgesByRisks,
    };
  }

  clearSums = () => {
    const {
      fieldValues: {
        productCalcType,
      },
      saveStore,
    } = this.props;

    const sumInputName = productCalcType === CALC_TYPES.from_sum
      ? NSZ_CALCULATOR_ENUMS.regularPaymentAmount
      : NSZ_CALCULATOR_ENUMS.accumulationAmount;

    saveStore(sumInputName, 0);
  };

  handleChangeSelectCurrency = async (select) => {
    const {
      saveStore,
      fieldValues,
      nszProductValues: {
        refCurrenciesFull,
      },
      getProductInfo,
      history,
    } = this.props;

    const { target } = select;
    const { errors } = fieldValues;
    const { name, value } = target;
    const currency = refCurrenciesFull
      .find((item) => item.id === value);

    getProductInfo(currency.productId);
    history.push(`${ROUTES.nszCalculation}/${currency.productId}`);

    saveStore(name, value);
    saveStore(NSZ_CALCULATOR_ENUMS.insuredBirthDate, '');
    saveStore(NSZ_CALCULATOR_ENUMS.childInsuredBirthDate, '');
    saveStore(NSZ_CALCULATOR_ENUMS.termId, '');
    saveStore(NSZ_CALCULATOR_ENUMS.regularPaymentAmount, '');
    saveStore(NSZ_CALCULATOR_ENUMS.accumulationAmount, '');
    saveStore('additionalRisks', []);
    saveStore('packageRisks', []);
    saveStore('insuranceSums', []);
    this.clearSums();
    this.listControl('additional', false);
    this.clearRisksErrorsAndHints();
    this.checkForCountButton(name, value);
    const newErrorsRegular = rebuildErrors(errors, NSZ_CALCULATOR_ENUMS.regularPaymentAmount);
    const newErrorsAccumulationAmount = rebuildErrors(
      newErrorsRegular,
      NSZ_CALCULATOR_ENUMS.accumulationAmount,
    );
    saveStore('errors', newErrorsAccumulationAmount);
  }

  onChangeBirthDate = (date) => {
    const {
      saveStore,
      nszProductValues,
      fieldValues,
      changeAgentValue,
      fieldValues: { termId },
    } = this.props;
    const { insurerIsInsured } = nszProductValues;
    const { target } = date;
    const { name, value } = target;
    const { errors, childInsuredBirthDate } = fieldValues;
    let errorsValidation = {};
    const dataForValidation = this.getOptionsForValidation();

    try {
      schema(name, dataForValidation).validateSync({
        insuredBirthDate: value,
      });
      errorsValidation = rebuildErrors(errors, name);
      saveStore('errors', errorsValidation);
      this.checkForCountButton(name, value, errorsValidation);
    } catch (e) {
      const error = getValidationError(e);
      errorsValidation = { ...errors, ...error };
      saveStore('errors', errorsValidation);
      this.checkForCountButton(name, value, error);
    }
    saveStore(name, value);

    if (termId) {
      this.validateTerm('', errorsValidation);
      saveStore(NSZ_CALCULATOR_ENUMS.termId, '');
    }

    saveStore('packageRisks', []);
    saveStore('additionalRisks', []);
    saveStore('insuranceSums', []);
    this.clearSums();

    changeAgentValue({
      name: 'birthDate',
      value: target.value,
      blockName: 'personBlock',
      form: 'insured',
    });

    if (insurerIsInsured) {
      changeAgentValue({
        name: 'birthDate',
        value: target.value,
        blockName: 'personBlock',
        form: 'insurer',
      });
    }

    this.listControl('additional', false);
    this.clearRisksErrorsAndHints();
    this.filterTerms(value, childInsuredBirthDate);
  }

  onChangeBirthDateChild = (date) => {
    const {
      saveStore,
      fieldValues,
      changeAgentValue,
      fieldValues: { termId },
    } = this.props;
    const { target } = date;
    const { name, value } = target;
    const { errors, insuredBirthDate } = fieldValues;
    let errorsValidation = {};
    const dataForValidation = this.getOptionsForValidation();

    try {
      schema(name, dataForValidation).validateSync({
        childInsuredBirthDate: value,
      });
      errorsValidation = rebuildErrors(errors, name);
      saveStore('errors', errorsValidation);
      this.checkForCountButton(name, value, errorsValidation);
    } catch (e) {
      const error = getValidationError(e);
      errorsValidation = { ...errors, ...error };
      saveStore('errors', errorsValidation);
      this.checkForCountButton(name, value, error);
    }
    saveStore(name, value);

    if (termId) {
      this.validateTerm('', errorsValidation);
      saveStore(NSZ_CALCULATOR_ENUMS.termId, '');
    }

    saveStore('additionalRisks', []);
    saveStore('packageRisks', []);
    saveStore('insuranceSums', []);
    this.clearSums();
    changeAgentValue({
      name: 'birthDate',
      value: target.value,
      blockName: 'personBlock',
      form: 'insuredChild',
    });
    this.listControl('additional', false);
    this.clearRisksErrorsAndHints();
    this.filterTerms(insuredBirthDate, value);
  }

  validateTerm = (value, currErrors) => {
    const { saveStore, fieldValues: { errors } } = this.props;
    const newErrors = value ? { termId: undefined } : { termId: REQUIRED };
    const errorsValidation = currErrors
      ? { ...currErrors, ...newErrors }
      : { ...errors, ...newErrors };
    saveStore('errors', errorsValidation);
    this.checkForCountButton('termId', value, errorsValidation);
  }

  handleChangeSelectTerm = (select) => {
    const { saveStore } = this.props;
    const { target } = select;
    const { name, value } = target;
    this.listControl('additional', false);
    this.clearRisksErrorsAndHints();
    this.checkForAdditionalRisks(name, value);
    saveStore(name, value);
    saveStore('additionalRisks', []);
    saveStore('insuranceSums', []);
    this.clearSums();
    this.validateTerm(value);
  }

  handleChangeAdditionalRisk = (
    risk,
    name,
    value,
  ) => {
    const { saveStore, fieldValues, nszProductValues } = this.props;
    const { additionalRisks, riskErrors } = fieldValues;
    const choosedTerm = fieldValues.termId;
    const termWithT = nszProductValues.productTerms.find((item) => item.value === choosedTerm);
    const {
      riskId,
      minWeight,
      maxWeight,
      Z,
      name: riskName,
    } = risk;
    const variables = {
      Z,
      minWeight,
      maxWeight,
      T: termWithT.key,
    };
    const newValue = replaceAllButNumbers(value);
    const newRisks = additionalRisks.map((item) => {
      if (item.riskId === riskId) {
        return {
          ...item,
          [name]: newValue,
        };
      }

      return item;
    });

    try {
      schema('additional', variables, riskName).validateSync({
        [riskName]: newValue,
      });
      const newErrors = rebuildErrors(riskErrors, riskName);
      saveStore('riskErrors', newErrors);
      this.checkForCountButton(riskName, newValue, newErrors);
    } catch (e) {
      const error = getValidationError(e);
      saveStore('riskErrors', { ...riskErrors, ...error });
      this.checkForCountButton(riskName, newValue, error);
    }
    saveStore('additionalRisks', newRisks);
    saveStore('insuranceSums', []);
    this.clearSums();
  }

  onBlurRisksInput = (risk, value) => {
    const { saveStore, fieldValues, nszProductValues } = this.props;
    const { hints } = fieldValues;
    const choosedTerm = fieldValues.termId;
    const termWithT = nszProductValues.productTerms.find((item) => item.value === choosedTerm);
    const {
      Z,
      name: riskName,
    } = risk;
    const variables = {
      Z,
      T: termWithT.key,
    };
    const newValue = replaceAllButNumbers(value);
    try {
      schema('additionalHints', variables, riskName).validateSync({
        [riskName]: newValue,
      });
      const newErrors = rebuildErrors(hints, riskName);
      saveStore('hints', newErrors);
    } catch (e) {
      const hint = getValidationError(e);
      saveStore('hints', { ...hints, ...hint });
    }
  }

  onRisksInput = (risk, value) => {
    const { fieldValues, nszProductValues } = this.props;
    const choosedTerm = fieldValues.termId;
    const termWithT = nszProductValues.productTerms.find((item) => item.value === choosedTerm);
    const {
      Z,
      name: riskName,
    } = risk;
    const variables = {
      Z,
      T: termWithT.key,
    };
    const newValue = replaceAllButNumbers(value);
    try {
      schema('additionalHints', variables, riskName).validateSync({
        [riskName]: newValue,
      });
    } catch (e) {
      const hint = getValidationError(e);

      return hint;
    }

    return null;
  }

  handleChangeCheckBoxAdditionalRisk = (
    risk,
    value,
  ) => {
    const { saveStore, fieldValues } = this.props;
    const { additionalRisks, riskErrors } = fieldValues;
    const {
      riskId,
      value: riskValue,
      minWeight,
      maxWeight,
      name: riskName,
    } = risk;

    const variables = {
      minWeight,
      maxWeight,
    };
    const isDisabled = value || minWeight === maxWeight;
    const newRisks = additionalRisks.map((item) => {
      if (item.riskId === riskId) {
        return {
          ...item,
          checked: value,
          value: isDisabled ? item.value : '',
        };
      }

      return item;
    });

    if (value) {
      try {
        this.onBlurRisksInput(risk, risk.value);
        schema('additional', variables, riskName).validateSync({
          [riskName]: riskValue,
        });
        const newErrors = rebuildErrors(riskErrors, riskName);
        saveStore('riskErrors', newErrors);
        this.checkForCountButton(riskName, riskValue, newErrors);
      } catch (e) {
        const error = getValidationError(e);
        saveStore('riskErrors', { ...riskErrors, ...error });
        this.checkForCountButton(riskName, riskValue, error);
      }
    } else {
      const newErrors = rebuildErrors(riskErrors, riskName);
      saveStore('hints', {
        ...fieldValues.hints,
        [riskName]: null,
      });
      saveStore('riskErrors', newErrors);
      this.checkForCountButton('fake', 'fake', newErrors);
    }
    saveStore('additionalRisks', newRisks);
    saveStore('insuranceSums', []);
    this.clearSums();
  }

  validateAllAdditionalRisk = () => {
    const { fieldValues: { additionalRisks } } = this.props;

    Bluebird.each(
      additionalRisks,
      (risk) => {
        const isShowError = risk.checked && risk.maxWeight === risk.minWeight;

        if (isShowError) {
          this.onBlurRisksInput(risk, risk.value);
        }
      },
    );
  }

  handleControlPackageRisks = (
    packetId,
    name,
    value,
  ) => {
    const { saveStore, fieldValues } = this.props;
    const { packageRisks } = fieldValues;
    const newRisks = packageRisks.map((item) => {
      if (item.packetId === packetId) {
        return {
          ...item,
          [name]: value,
        };
      }

      return item;
    });
    const { errors } = fieldValues;
    const newErrorsRegular = rebuildErrors(errors, NSZ_CALCULATOR_ENUMS.regularPaymentAmount);
    const newErrorsAccumlationAmount = rebuildErrors(
      newErrorsRegular,
      NSZ_CALCULATOR_ENUMS.accumulationAmount,
    );
    saveStore('errors', newErrorsAccumlationAmount);
    saveStore('packageRisks', newRisks);
    saveStore('insuranceSums', []);
    this.clearSums();
    this.checkForCountButton('packageRisks', newRisks);
  }

  handleChangeGender = (data) => {
    const {
      changeAgentValue,
      nszProductValues: {
        insurerIsInsured,
      },
    } = this.props;
    if (insurerIsInsured) {
      changeAgentValue({
        name: 'gender',
        value: data.target.value,
        blockName: 'personBlock',
        form: 'insurer',
      });
    }

    changeAgentValue({
      name: 'gender',
      value: data.target.value,
      blockName: 'personBlock',
      form: 'insured',
    });
    this.handleChangeSelect(data);
  };

  handleChangeGenderChild = (data) => {
    const { changeAgentValue } = this.props;
    changeAgentValue({
      name: 'gender',
      value: data.target.value,
      blockName: 'personBlock',
      form: 'insuredChild',
    });
    this.handleChangeSelect(data);
  };

  handleChangeSelect = (select) => {
    const { saveStore, fieldValues } = this.props;
    const { target } = select;
    const { name, value } = target;
    const { errors } = fieldValues;
    const newErrorsRegular = rebuildErrors(errors, NSZ_CALCULATOR_ENUMS.regularPaymentAmount);
    const newErrorsAccumlationAmount = rebuildErrors(
      newErrorsRegular,
      NSZ_CALCULATOR_ENUMS.accumulationAmount,
    );
    saveStore('errors', newErrorsAccumlationAmount);
    saveStore(name, value);
    saveStore('insuranceSums', []);
    this.clearSums();
    this.checkForCountButton(name, value);
  }

  onChangeSelectCalcType = (select) => {
    const { saveStore, fieldValues } = this.props;
    const { target } = select;
    const { name, value } = target;
    const { errors } = fieldValues;
    const newErrorsRegular = rebuildErrors(errors, NSZ_CALCULATOR_ENUMS.regularPaymentAmount);
    const newErrorsAccumlationAmount = rebuildErrors(
      newErrorsRegular,
      NSZ_CALCULATOR_ENUMS.accumulationAmount,
    );
    saveStore('errors', newErrorsAccumlationAmount);
    saveStore(name, value);
    saveStore('insuranceSums', []);
    saveStore(NSZ_CALCULATOR_ENUMS.regularPaymentAmount, 0);
    saveStore(NSZ_CALCULATOR_ENUMS.accumulationAmount, 0);
    this.checkForCountButton(name, value);
  }

  onChangeRegularEnter = ({
    target: {
      value,
    },
  }) => {
    const {
      saveStore,
    } = this.props;

    this.handleRegularEnter(value);
    saveStore('insuranceSums', []);
    saveStore(NSZ_CALCULATOR_ENUMS.accumulationAmount, 0);
  }

  handleRegularEnter = (value) => {
    const {
      saveStore,
      fieldValues,
      nszProductValues,
      refExchangeRatesRegimesCoefs,
    } = this.props;
    const { regularPaymentAmountRestriction } = nszProductValues;
    const { errors, premiumFreqId, regularPaymentAmount } = fieldValues;

    this.removeRegularPaymentAmountEnterError();

    const newValue = enterDecimalValue(value, regularPaymentAmount);
    const valueForValidate = parseFloat(newValue.replace(',', '.'));
    const paymentVariables = regularPaymentAmountRestriction
      .find((item) => item.premiumFreqId === premiumFreqId);

    let coefId = null;
    saveStore('coefId', coefId);

    const isCurrencyScenario = checkIsCurrencyScenario(fieldValues.refCurrencyId);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    if (isCurrencyScenario) {
      const amount = valueForValidate || 0;

      coefId =
        this.findCorrectCoefIdInListOfCoefsByContractAmount(refExchangeRatesRegimesCoefs, amount);

      saveStore('coefId', coefId);
    }

    try {
      const variables = paymentVariables || {};
      schema('regularPaymentAmount', { ...variables, coefId, isCurrencyScenario }).validateSync({
        regularPaymentAmount: valueForValidate,
      });
      const newErrors = rebuildErrors(errors, 'regularPaymentAmount');
      saveStore('errors', newErrors);
      this.checkForCountButton('regularPaymentAmount', valueForValidate, newErrors);
    } catch (e) {
      const error = getValidationError(e);
      this.checkForCountButton('regularPaymentAmount', valueForValidate, error);
      saveStore('errors', { ...errors, ...error });
    }
    saveStore(NSZ_CALCULATOR_ENUMS.regularPaymentAmount, newValue);
  }

  findCorrectCoefIdInListOfCoefsByContractAmount = (coefs, amount) => {
    const correctCoefs = coefs
      .filter((coef) => this.checkIsAmountSatisfiesTheConstraints(coef, amount));

    if (correctCoefs.length) {
      if (correctCoefs.length > 1) {
        const nearestCoefId = this.findNearestPremiumAmountCoefIdInInterval(coefs);

        return nearestCoefId;
      }

      return correctCoefs[0].id;
    }

    return null;
  }

  checkIsAmountSatisfiesTheConstraints =
    (coef, amount) => (coef.premiumAmountMin <= amount && coef.premiumAmountMax >= amount);

  findNearestPremiumAmountCoefIdInInterval = (coefs) => {
    const correctCoef = coefs
      .map((coef) => ({
        value: coef.premiumAmountMax - coef.premiumAmountMin,
        id: coef.id,
      }))
      .sort((el1, el2) => el1.value - el2.value)[0];

    return correctCoef.id;
  }

  onChangeNszSum = (value) => {
    const {
      saveStore,
      fieldValues,
      nszProductValues,
    } = this.props;
    const { accumulationAmountRestriction } = nszProductValues;
    const { errors } = fieldValues;
    const newValue = replaceAllButNumbers(value);

    this.removeRegularPaymentAmountEnterError();

    const isCurrencyScenario = checkIsCurrencyScenario(fieldValues.refCurrencyId);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    try {
      schema('accumulationAmount', accumulationAmountRestriction || {}).validateSync({
        accumulationAmount: newValue,
      });
      const newErrors = rebuildErrors(errors, 'accumulationAmount');
      saveStore('errors', newErrors);
      this.checkForCountButton('accumulationAmount', newValue, newErrors);
    } catch (e) {
      const error = getValidationError(e);
      saveStore('errors', { ...errors, ...error });
      this.checkForCountButton('accumulationAmount', newValue, error);
    }
    saveStore('insuranceSums', []);
    saveStore(NSZ_CALCULATOR_ENUMS.regularPaymentAmount, 0);
    saveStore(NSZ_CALCULATOR_ENUMS.accumulationAmount, newValue);
  }

  removeRegularPaymentAmountEnterError = () => {
    const {
      saveStore,
      fieldValues: {
        errors,
      },
    } = this.props;

    delete errors.regularPaymentAmount;
    saveStore('errors', errors);
  }

  listControl = (name, value) => {
    const { risksControl } = this.props;

    if (name === 'additional' && value) {
      this.validateAllAdditionalRisk();
    }

    risksControl(name, value);
  }

  clearRisksErrorsAndHints = () => {
    const { saveStore } = this.props;
    saveStore('riskErrors', {});
    saveStore('hints', {});
  }

  clearCalcTypeErrors = () => {
    const { saveStore, fieldValues } = this.props;
    const { errors } = fieldValues;
    saveStore('errors', { ...errors, regularPaymentAmount: '', accumulationAmount: '' });
  }

  checkForAdditionalRisks = (name, value) => {
    const {
      nszProductValues,
      fieldValues,
      getAdditionalsRisks,
      getPackage,
      match: { params: { id } },
    } = this.props;
    const {
      insuredBirthDate,
      childInsuredBirthDate,
      termId,
    } = fieldValues;
    const {
      scenario,
      contentType,
    } = nszProductValues;
    const body = {
      id,
      insuredBirthDate: simpleDate(insuredBirthDate),
      termId,
      scenario,
      childInsuredBirthDate: scenario === 'nsz_2' ? simpleDate(childInsuredBirthDate) : undefined,
      [name]: value,
    };
    if (contentType === RISK_TYPES.standart) {
      getAdditionalsRisks(body);
    }
    if (contentType === RISK_TYPES.package) {
      getPackage(body);
    }
  }

  getRisksForCount = () => {
    const { nszProductValues, fieldValues } = this.props;
    const { contentType } = nszProductValues;
    const { additionalRisks, packageRisks } = fieldValues;
    if (contentType === RISK_TYPES.standart) {
      const risks = additionalRisks.map((item) => {
        if (item.checked) {
          return {
            id: item.riskId,
            weight: Number(item.value),
          };
        }

        return null;
      });
      const filteredRisks = risks.filter((item) => item);

      return filteredRisks;
    }
    if (contentType === RISK_TYPES.package) {
      const rebuildRisks = (risks) => risks.map((risk) => ({
        id: risk.riskId,
        weight: risk.riskWeight,
      }));

      const risks = packageRisks.reduce((acc, risksPackage) => {
        if (risksPackage.checked) {
          return [
            ...acc,
            ...rebuildRisks(risksPackage.packetRisks),
          ];
        }

        return [...acc];
      }, []);

      return risks;
    }

    return undefined;
  }

  checkForCountButton = (
    name,
    value,
    newErrors,
  ) => {
    const { saveStore, fieldValues, nszProductValues } = this.props;
    const { scenario } = nszProductValues;
    const isNszChild = scenario === PRODUCT_VERSION.nsz_2;
    const {
      refCurrencyId,
      insuredBirthDate,
      childInsuredBirthDate,
      termId,
      insuredSexId,
      childInsuredSexId,
      premiumFreqId,
      productCalcType,
      regularPaymentAmount,
      accumulationAmount,
      errors,
      riskErrors,
    } = fieldValues;
    const fieldForCheck = {
      refCurrencyId,
      insuredBirthDate,
      childInsuredBirthDate: isNszChild ? childInsuredBirthDate : null,
      termId,
      insuredSexId,
      childInsuredSexId: isNszChild ? childInsuredSexId : null,
      premiumFreqId,
      productCalcType,
      regularPaymentAmount: value === CALC_TYPES.from_premium
      || productCalcType === CALC_TYPES.from_premium
        ? regularPaymentAmount && parseFloat(regularPaymentAmount.replace(',', '.')) : null,
      accumulationAmount: value === CALC_TYPES.from_sum
      || productCalcType === CALC_TYPES.from_sum
        ? accumulationAmount : null,
      [name]: value,
    };
    const errorsField = newErrors || errors;
    const risksErrorsField = newErrors || riskErrors;
    const values = Object.values(fieldForCheck);
    const haveErrors = Object.values(errorsField).some(Boolean);
    const haveRisksErrors = Object.values(risksErrorsField).some(Boolean);
    const isEmptyValue = !value;
    const notFilledFields = values.filter((item) => item === undefined
      || item === 0
      || item === '').length;
    const disableButton = Boolean(haveErrors)
    || Boolean(notFilledFields)
    || isEmptyValue
    || Boolean(haveRisksErrors);

    saveStore('disableCountButton', Boolean(disableButton));
  }

  countNszSum = () => {
    const {
      countSum,
      fieldValues: {
        productCalcType,
        refCurrencyId,
      },
      saveStore,
    } = this.props;

    const isCurrencyScenario = checkIsCurrencyScenario(refCurrencyId);
    saveStore('isCurrencyScenario', isCurrencyScenario);

    const body = this.getBodyForCount();
    const {
      regularPaymentAmount,
      accumulationAmount,
      refExchangeBankId,
      ...rest
    } = body;

    const bodyForRequest = {
      ...rest,
      ...(productCalcType === CALC_TYPES.from_premium
        ? { regularPaymentAmount }
        : { accumulationAmount }),
      isCurrencyScenario,
    };

    countSum(bodyForRequest);
  }

  componentDidUpdate(prevProps) {
    const { fieldValues } = this.props;

    if (typeof fieldValues.regularPaymentAmount === 'number') {
      fieldValues.regularPaymentAmount = String(fieldValues.regularPaymentAmount);
    }

    const isCheckRegularEnter =
      prevProps.fieldValues.regularPaymentAmount
      && prevProps.fieldValues.regularPaymentAmount !== fieldValues.regularPaymentAmount;

    if (isCheckRegularEnter) {
      this.handleRegularEnter(fieldValues.regularPaymentAmount);
    }
  }

  getBodyForCount = () => {
    const {
      fieldValues,
      nszProductValues,
      match: { params: { id } },
    } = this.props;
    const {
      refCurrencyId,
      refExchangeBankId,
      insuredBirthDate,
      childInsuredBirthDate,
      termId,
      insuredSexId,
      childInsuredSexId,
      premiumFreqId,
      productCalcType: calcType,
      regularPaymentAmount,
      accumulationAmount,
      isCurrencyScenario,
    } = fieldValues;
    const {
      scenario,
      productCalcTypes,
    } = nszProductValues;
    const foundedProductCalcType = productCalcTypes.find((item) => item.value === calcType);
    const productCalcType = {
      id: foundedProductCalcType.key,
      code: foundedProductCalcType.value,
    };
    const risks = this.getRisksForCount();

    return {
      id,
      refCurrencyId,
      insuredBirthDate,
      refExchangeBankId,
      childInsuredBirthDate,
      termId,
      insuredSexId,
      childInsuredSexId,
      premiumFreqId,
      productCalcType,
      regularPaymentAmount: regularPaymentAmount ? parseFloat(regularPaymentAmount.replace(',', '.')) : undefined,
      accumulationAmount: accumulationAmount === 0 ? undefined : Number(accumulationAmount),
      scenario,
      risks,
      isCurrencyScenario,
    };
  }

  goNextForm = () => {
    const {
      saveData,
      history,
      match: { params: { id } },
    } = this.props;
    const body = this.getBodyForCount();
    const saveBody = {
      calculatorData: body,
    };
    saveData(saveBody);
    history.push(`${ROUTES.nszInsurerData}/${id}`);
  }

  filterTerms = (insuredBirthDate, childInsuredBirthDate) => {
    const { nszProductValues, saveStore } = this.props;
    const terms = calculateAccessTermsNsz({
      insuredBirthDate,
      childInsuredBirthDate,
      nszProductValues,
    });

    saveStore('productTerms', terms);
  }

  downloadPrintPayment = () => {
    const {
      nszProductValues: {
        insProductId,
      },
      fieldValues: {
        insLineNszCalcId,
      },
      downloadPrintPayment,
    } = this.props;

    downloadPrintPayment({
      insLineNszCalcId,
      insProductId,
    });
  }

  render() {
    const {
      saveStore,
      nszProductValues,
      fieldValues,
      genderList,
      risksLists,
    } = this.props;

    return (
      <NszForm
        onBlurRisksInput={this.onBlurRisksInput}
        onChange={saveStore}
        goNextForm={this.goNextForm}
        countNszSum={this.countNszSum}
        values={fieldValues}
        productTip={nszProductValues.productTip}
        scenario={nszProductValues.scenario}
        currencySelect={nszProductValues.refCurrencies}
        productTerms={fieldValues.productTerms}
        genderList={genderList}
        premiumFreq={nszProductValues.premiumFreq}
        productCalcTypes={nszProductValues.productCalcTypes}
        productSelection={nszProductValues.productSelection}
        onChangeBirthDate={this.onChangeBirthDate}
        basicRisks={nszProductValues.basicRiskPackages}
        downloadPrintPayment={this.downloadPrintPayment}
        listControl={this.listControl}
        risksLists={risksLists}
        contentType={nszProductValues.contentType}
        handleChangeSelectCurrency={this.handleChangeSelectCurrency}
        onChangeBirthDateChild={this.onChangeBirthDateChild}
        handleChangeSelectTerm={this.handleChangeSelectTerm}
        onChangeRegularEnter={this.onChangeRegularEnter}
        onChangeSelect={this.onChangeSelect}
        onChangeSelectCalcType={this.onChangeSelectCalcType}
        onChangeNszSum={this.onChangeNszSum}
        handleChangeSelect={this.handleChangeSelect}
        handleChangeAdditionalRisk={this.handleChangeAdditionalRisk}
        handleControlPackageRisks={this.handleControlPackageRisks}
        handleChangeCheckBoxAdditionalRisk={this.handleChangeCheckBoxAdditionalRisk}
        handleChangeGenderChild={this.handleChangeGenderChild}
        handleChangeGender={this.handleChangeGender}
        onRisksInput={this.onRisksInput}
      />
    );
  }
}

const mapStateToProps = ({
  nszProduct: {
    nszProductValues,
    fieldValues,
    genderList,
    risksLists,
    productSelection,
    refExchangeRatesRegimesCoefs,
  },
}) => ({
  productSelection,
  risksLists,
  genderList,
  nszProductValues,
  fieldValues,
  refExchangeRatesRegimesCoefs,
});

const mapDispatchToProps = {
  countSum: countSumCalculator,
  getAllProducts: getProducts,
  getProductInfo: getNszProductInfo,
  saveStore: changeFieldNszProduct,
  getGenders: getGenderList,
  clearStore: clearStoreNszProducts,
  risksControl: risksListControl,
  getAdditionalsRisks: getAdditionalRisks,
  getPackage: getPackageRisks,
  saveData: saveDataNszForm,
  changeAgentValue: changeAgentFieldValue,
  setStage: setStageProduct,
  downloadPrintPayment: printPayment,
};

NszCalculationComponent.propTypes = propTypes;
NszCalculationComponent.defaultProps = defaultProps;

export const NszCalculation = withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(NszCalculationComponent));
