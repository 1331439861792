import AsyncActionFactory from 'helpers/actionsFactory';

const actionFactory = new AsyncActionFactory('templates');

const CHECK_TEMPLATE = actionFactory.createAsync('CHECK_TEMPLATE');
const UPLOAD_TEMPLATE = actionFactory.createAsync('UPLOAD_TEMPLATE');
const SET_TEMPLATE = actionFactory.create('SET_TEMPLATE');
const CLEAR_ERRORS = actionFactory.create('CLEAR_ERRORS');
const CLOSE_TEMPLATE_IS_EXIST_MODAL = actionFactory.create('CLOSE_TEMPLATE_IS_EXIST_MODAL');
const CLEAR_STORE = actionFactory.create('CLEAR_STORE');

export {
  CHECK_TEMPLATE,
  UPLOAD_TEMPLATE,
  SET_TEMPLATE,
  CLEAR_ERRORS,
  CLOSE_TEMPLATE_IS_EXIST_MODAL,
  CLEAR_STORE,
};
