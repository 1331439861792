const assignIdCustomTable = ({ data, startId, prefix }) => {
  let id = startId;

  return data && data
    .map((item) => {
      const {
        data: dataRow,
        headers,
      } = item;

      const newItem = { ...item };

      if (headers) {
        newItem.headers = assignIdCustomTable({
          data: headers,
          startId: id,
          prefix: `${prefix}headers`,
        });
        id += headers.length + 1;
      }
      if (dataRow) {
        newItem.data = assignIdCustomTable({
          data: dataRow,
          startId: id,
          prefix: `${prefix}data`,
        });
        id += dataRow.length + 1;
      }

      id += 1;

      return {
        ...newItem,
        id: `${prefix}--${id}`,
      };
    });
};

export default assignIdCustomTable;
