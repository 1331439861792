import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { block } from 'bem-cn';
import { SchedulerForm, PaginationPanel } from 'components';
import {
  createJobScheduler,
  stopJobScheduler,
  getJobsForScheduler,
  getSelectPriorities,
  getSelectsCrons,
  setSettingForJob,
  setActiveTab,
  openPopUpForEidt,
  openPopUptForConfirmStop,
  getFailJobs,
  getCompleteJobs,
  getProccessJobs,
  getJobsCount,
  getInitial,
} from 'redux/rootActions';
import { withCustomRouter } from 'HOC';
import { ROUTES, LANG_DICTIONARY } from 'consts';
import moment from 'moment/moment';
import dictionary from './dictionary';
import './styles.scss';

const b = block('scheduler-container');

const {
  ACTIVE,
  PROCCESS,
  COMPLETE,
  FAIL,
  PRIORITY,
  CRON_TYPE_WORD,
  TIME_WORD,
  INTERVAL_WORD,
  TIME_START,
} = dictionary;

const {
  STATUS_SAVED,
} = LANG_DICTIONARY;

const propTypes = {
  numberOfActive: PropTypes.number,
  numberOfProccess: PropTypes.number,
  numberOfComplete: PropTypes.number,
  numberOfFail: PropTypes.number,
  activeTab: PropTypes.string,
  prioritiSelect: PropTypes.arrayOf(PropTypes.object),
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  popUpOpenEdit: PropTypes.bool,
  popUpOpenStop: PropTypes.bool,
  tableInfo: PropTypes.object,
  history: PropTypes.object,
  getJobs: PropTypes.func,
  getPrioritySelects: PropTypes.func,
  getCronsSelects: PropTypes.func,
  setTab: PropTypes.func,
  activeJobs: PropTypes.object,
  proccessJobs: PropTypes.object,
  completeJobs: PropTypes.object,
  failJobs: PropTypes.object,
  setSetting: PropTypes.func,
  startJob: PropTypes.func,
  controlPopUpConfirmStop: PropTypes.func,
  controlPopUpEdit: PropTypes.func,
  stopJob: PropTypes.func,
  getFailedJobs: PropTypes.func,
  getCompletedJobs: PropTypes.func,
  getProccessedJobs: PropTypes.func,
  clearTable: PropTypes.func,
  getCount: PropTypes.func,
};

const defaultProps = {
  getCount: () => null,
  getFailedJobs: () => null,
  getCompletedJobs: () => null,
  getProccessedJobs: () => null,
  history: {},
  getJobs: () => null,
  getPrioritySelects: () => null,
  getCronsSelects: () => null,
  setTab: () => null,
  activeJobs: {},
  proccessJobs: {},
  completeJobs: {},
  failJobs: {},
  setSetting: () => null,
  startJob: () => null,
  controlPopUpConfirmStop: () => null,
  controlPopUpEdit: () => null,
  clearTable: () => null,
  stopJob: () => null,
  numberOfActive: 0,
  numberOfProccess: 0,
  numberOfComplete: 0,
  numberOfFail: 0,
  activeTab: '',
  prioritiSelect: [],
  cronTypeSelect: [],
  popUpOpenEdit: false,
  popUpOpenStop: false,
  tableInfo: {},
};

class SchedulerForJobs extends React.Component {
  state = {
    cronTypeId: null,
    interval: null,
    timeStart: null,
    id: null,
    isActive: null,
    taskType: null,
    priorityId: null,
    time: null,
  }

  componentDidMount() {
    const {
      getJobs,
      getPrioritySelects,
      getCronsSelects,
      getCount,
    } = this.props;
    getPrioritySelects();
    getCronsSelects();
    getJobs(1);
    getCount();
  }

  componentWillUnmount() {
    const { clearTable } = this.props;
    clearTable();
  }

  handleUpPage = () => {
    const {
      getJobs,
      activeTab,
      tableInfo: { currentPage, totalPages },
      getFailedJobs,
      getCompletedJobs,
      getProccessedJobs,
    } = this.props;
    if (currentPage !== totalPages) {
      if (activeTab === ACTIVE) {
        getJobs(currentPage + 1);
      }
      if (activeTab === PROCCESS) {
        getProccessedJobs(currentPage + 1);
      }
      if (activeTab === FAIL) {
        getFailedJobs(currentPage + 1);
      }
      if (activeTab === COMPLETE) {
        getCompletedJobs(currentPage + 1);
      }
    }
  }

  handleDownPage = () => {
    const {
      getJobs,
      activeTab,
      tableInfo: { currentPage },
      getFailedJobs,
      getCompletedJobs,
      getProccessedJobs,
    } = this.props;
    if (currentPage !== 1) {
      if (activeTab === ACTIVE) {
        getJobs(currentPage - 1);
      }
      if (activeTab === PROCCESS) {
        getProccessedJobs(currentPage - 1);
      }
      if (activeTab === FAIL) {
        getFailedJobs(currentPage - 1);
      }
      if (activeTab === COMPLETE) {
        getCompletedJobs(currentPage - 1);
      }
    }
  }

  handleChangeTab = (tabName) => {
    const {
      setTab,
      activeJobs,
      proccessJobs,
      completeJobs,
      failJobs,
      getFailedJobs,
      getCompletedJobs,
      getProccessedJobs,
      getJobs,
      getCount,
    } = this.props;
    if (tabName === ACTIVE) {
      setTab(tabName, activeJobs);
      getJobs(1);
    }
    if (tabName === PROCCESS) {
      setTab(tabName, proccessJobs);
      getProccessedJobs(1);
    }
    if (tabName === COMPLETE) {
      setTab(tabName, completeJobs);
      getCompletedJobs(1);
    }
    if (tabName === FAIL) {
      setTab(tabName, failJobs);
      getFailedJobs(1);
    }
    getCount();
  }

  backToMain = () => {
    const { history } = this.props;
    history.push(ROUTES.home);
  }

  handleChangeSelect = (id, name, value, type, status, job) => {
    const { setSetting, startJob, tableInfo: { currentPage } } = this.props;
    if (type === PRIORITY && status) {
      const {
        interval,
        cronTypeId,
        taskType,
        timeStart,
        time,
      } = job;
      if (status && cronTypeId === 1) {
        startJob({
          cronTypeId,
          time: time || moment(timeStart).format('HH:mm'),
          taskType,
          priorityId: value,
        }, currentPage);
      }
      if (status && cronTypeId === 2) {
        startJob({
          cronTypeId,
          time: moment(timeStart).toISOString(),
          taskType,
          priorityId: value,
        }, currentPage);
      }
      if (status && cronTypeId === 3) {
        startJob({
          interval,
          cronTypeId,
          taskType,
          priorityId: value,
        }, currentPage);
      }
      if (status && cronTypeId === 4) {
        startJob({
          interval,
          cronTypeId,
          time: moment(timeStart).toISOString(),
          taskType,
          priorityId: value,
        }, currentPage);
      }
      if (status && cronTypeId === 5) {
        startJob({
          cronTypeId,
          taskType,
          priorityId: value,
        }, currentPage);
      }
    }
    setSetting(id, name, value);
  }

  saveButtonPopUp = (
    {
      id,
      cronTypeId,
      time,
      interval,
      status,
      taskType,
      priorityId,
      timeStart,
    },
  ) => {
    const { setSetting, startJob, tableInfo: { currentPage } } = this.props;
    setSetting(id, CRON_TYPE_WORD, cronTypeId);
    setSetting(id, TIME_WORD, time);
    setSetting(id, INTERVAL_WORD, interval);
    setSetting(id, TIME_START, moment(timeStart).toISOString());
    toastr.success(STATUS_SAVED);
    if (status && cronTypeId === 1) {
      startJob({
        cronTypeId,
        time,
        taskType,
        priorityId,
      }, currentPage);
    }
    if (status && cronTypeId === 2) {
      startJob({
        cronTypeId,
        time: moment(timeStart).toISOString(),
        taskType,
        priorityId,
      }, currentPage);
    }
    if (status && cronTypeId === 3) {
      startJob({
        interval,
        cronTypeId,
        taskType,
        priorityId,
      }, currentPage);
    }
    if (status && cronTypeId === 4) {
      startJob({
        interval,
        cronTypeId,
        time: moment(timeStart).toISOString(),
        taskType,
        priorityId,
      }, currentPage);
    } if (status && cronTypeId === 5) {
      startJob({
        cronTypeId,
        taskType,
        priorityId,
      }, currentPage);
    }
  }

  popUpControl = (
    changeType,
    cronTypeId,
    interval,
    timeStart,
    id,
    isActive,
    taskType,
    priorityId,
    time,
  ) => {
    const { controlPopUpEdit } = this.props;
    controlPopUpEdit(changeType);
    this.setState({
      cronTypeId,
      interval,
      timeStart,
      id,
      isActive,
      taskType,
      priorityId,
      time,
    });
  }

  jobControl = (
    status,
    job,
    endJob,
    taskType,
    popUpOpen,
  ) => {
    const {
      controlPopUpConfirmStop,
      stopJob,
      startJob,
      tableInfo: {
        currentPage,
      },
    } = this.props;
    const {
      cronTypeId,
      timeStart,
      time,
      priorityId,
      interval,
    } = job;
    if (status) {
      controlPopUpConfirmStop(true);
      this.setState({
        taskType: job.taskType,
      });
    }
    if (popUpOpen && endJob) {
      stopJob(taskType, currentPage);
      controlPopUpConfirmStop(false);
    }
    if (popUpOpen && !endJob) {
      controlPopUpConfirmStop(false);
    }
    if (!status) {
      if (cronTypeId === 1) {
        startJob({
          cronTypeId,
          time: time || moment(timeStart).format('HH:mm'),
          taskType: job.taskType,
          priorityId,
        }, currentPage);
      }
      if (cronTypeId === 2) {
        startJob({
          cronTypeId,
          time: moment(timeStart).toISOString(),
          taskType: job.taskType,
          priorityId,
        }, currentPage);
      }
      if (cronTypeId === 3) {
        startJob({
          interval,
          cronTypeId,
          taskType: job.taskType,
          priorityId,
        }, currentPage);
      }
      if (cronTypeId === 4) {
        startJob({
          interval,
          cronTypeId,
          time: moment(timeStart).toISOString(),
          taskType: job.taskType,
          priorityId,
        }, currentPage);
      }
      if (cronTypeId === 5) {
        startJob({
          cronTypeId,
          taskType: job.taskType,
          priorityId,
        }, currentPage);
      }
    }
  }

  render() {
    const {
      numberOfActive,
      numberOfProccess,
      numberOfComplete,
      numberOfFail,
      activeTab,
      prioritiSelect,
      cronTypeSelect,
      popUpOpenEdit,
      popUpOpenStop,
      tableInfo: {
        currentPage,
        data,
        totalPages,
      },
    } = this.props;
    const {
      cronTypeId,
      interval,
      timeStart,
      id,
      isActive,
      taskType,
      priorityId,
      time,
    } = this.state;

    return (
      <div className={b()}>
        <SchedulerForm
          dictionary={dictionary}
          tableInfo={data}
          currentPage={currentPage}
          totalPages={totalPages}
          popUpOpenEdit={popUpOpenEdit}
          popUpOpenStop={popUpOpenStop}
          numberOfActive={numberOfActive}
          numberOfProccess={numberOfProccess}
          numberOfComplete={numberOfComplete}
          numberOfFail={numberOfFail}
          activeTab={activeTab}
          prioritiSelect={prioritiSelect}
          cronTypeSelect={cronTypeSelect}
          changeTab={this.handleChangeTab}
          backToMain={this.backToMain}
          handleChangeSelect={this.handleChangeSelect}
          saveButtonPopUp={this.saveButtonPopUp}
          popUpControl={this.popUpControl}
          stopJobControl={this.jobControl}
          cronTypeId={cronTypeId}
          interval={interval}
          timeStart={timeStart}
          id={id}
          isActive={isActive}
          taskType={taskType}
          priorityId={priorityId}
          timeForPopup={time}
        />
        <PaginationPanel
          active={currentPage || 1}
          total={totalPages || 1}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </div>
    );
  }
}

const mapStateToProps = ({
  schedulerJobs,
}) => ({
  ...schedulerJobs,
});

const mapDispathToProps = {
  getCount: getJobsCount,
  startJob: createJobScheduler,
  stopJob: stopJobScheduler,
  getJobs: getJobsForScheduler,
  getPrioritySelects: getSelectPriorities,
  getCronsSelects: getSelectsCrons,
  setSetting: setSettingForJob,
  setTab: setActiveTab,
  controlPopUpEdit: openPopUpForEidt,
  controlPopUpConfirmStop: openPopUptForConfirmStop,
  getFailedJobs: getFailJobs,
  getCompletedJobs: getCompleteJobs,
  getProccessedJobs: getProccessJobs,
  clearTable: getInitial,
};

SchedulerForJobs.propTypes = propTypes;
SchedulerForJobs.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispathToProps,
)(SchedulerForJobs));
