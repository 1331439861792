import { put, takeLatest, select } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { getGlobalHistory } from 'components';
import {
  API,
  ROUTES,
  LANG_DICTIONARY,
  UNITED_FRONTEND_ERRORS,
} from 'consts';
import _ from 'lodash';
import { toastr } from 'react-redux-toastr';
import {
  appCanRender,
  toggleModalAffiliation,
  selectPartnerAuth,
  selectBranchAuth,
  getProducts,
  getVersionDp,
  getEventsCount,
  checkExistManual,
  contestActions,
  closeAllPopups,
  togglePopupUpdatingSessionUnitedFront,
  setUnitedFrontendParams,
  openWindowBlockUnitedFront,
  setWindowTimestamp,
  changeQueryParams,
  getInboxUnreadMessagesCount,
  setUnreadInboxCount,
} from 'redux/rootActions';
import { getUnitedFrontendParamsSelector, sharedSelectors } from 'redux/rootSelectors';
import { getCurrentCaptchaId } from 'redux/reducers/selectors';
import { CHECK_IS_ACTIVE_CONTEST } from 'helpers/rolesConfig/roles';
import {
  AUTH_REQUEST,
  SIGN_IN_START,
  SIGN_IN_SUCCESS,
  SIGN_IN_FAIL,
  RESET_REQUEST,
  RESET_REQUEST_START,
  RESET_REQUEST_SUCCESS,
  RESET_REQUEST_FAIL,
  CHECK_PASS_REQUEST,
  CHECK_PASS_REQUEST_SUCCESS,
  CHECK_PASS_REQUEST_FAIL,
  CHANGE_PASSWORD,
  CHANGE_PASSWORD_START,
  CHANGE_PASSWORD_SUCCESS,
  CHANGE_PASSWORD_FAIL,
  GET_CURRENT_USER,
  GET_CURRENT_USER_START,
  GET_CURRENT_USER_SUCCESS,
  GET_CURRENT_USER_FAIL,
  GET_IP_ADDRESS_SUCCESS,
  GET_IP_ADDRESS_FAIL,
  GET_IP_ADDRESS_REQUEST,
  GET_CAPTCHA_REQUEST,
  GET_CAPTCHA_START,
  GET_CAPTCHA_FAIL,
  GET_CAPTCHA_SUCCESS,
  LOG_OUT_SUCCESS,
  LOG_OUT_FAIL,
  LOG_OUT,
  RECAPTCHA,
  RESET_TEMPORARY_PASSWORD_REQUEST,
  RESET_TEMPORARY_PASSWORD_SUCCESS,
  RESET_TEMPORARY_PASSWORD_START,
  RESET_TEMPORARY_PASSWORD_FAIL,
  GET_BRANCHES_FOR_AUTH_REQUEST,
  GET_BRANCHES_FOR_AUTH_FAIL,
  GET_BRANCHES_FOR_AUTH_START,
  GET_BRANCHES_FOR_AUTH_SUCCESS,
  GET_PARTNERS_FOR_AUTH_FAIL,
  GET_PARTNERS_FOR_AUTH_REQUEST,
  GET_PARTNERS_FOR_AUTH_START,
  GET_PARTNERS_FOR_AUTH_SUCCESS,
  SET_AFFILIATION_FAIL,
  SET_AFFILIATION_REQUEST,
  SET_AFFILIATION_START,
  SET_AFFILIATION_SUCCESS,
  CHECK_AUTH_SESSION,
  OPEN_WINDOW_BLOCK_UNITED_FRONT,
  UPDATE_UNITED_FRONTEND_SESSION,
  ACCEPT_AGREEMENT_RULES,
  PASSWORD_RECOVERY,
  LOGIN_RECOVERY,
  CHANGE_RECOVERY_LOGIN_MODAL,
} from 'redux/reducers/types';
import { DOCS_TRANSFER_UNREAD_MESSAGES_COUNT } from 'websocket/types';
import { storeLink } from 'index';
import { socketInit, getSocket } from '../../websocket';

const {
  MESSAGE_CHANGE_PASSWORD_SUCCESS,
  RESTOR_PASSWORD_SUCCESS,
  RECOVERY_ACCESS_EMAIL_SUCCESS,
} = LANG_DICTIONARY;

const { home, signIn, partnerChannel } = ROUTES;

function redirectToHomePage() {
  const history = getGlobalHistory();
  history.push(home);
}

function* redirectToHomePageAndGetProducts() {
  const history = getGlobalHistory();
  const { location: { pathname } } = history;
  const { riskProfile } = yield select(getUnitedFrontendParamsSelector);
  if (pathname === ROUTES.home) {
    yield put(getProducts(null, riskProfile));
  }
  redirectToHomePage();
}

function getAcceptAgreementRedirectActions(isAuth) {
  if (isAuth) {
    return redirectToHomePage;
  }

  return redirectToHomePageAndGetProducts;
}

function* acceptPartnerChannelAgreement({
  payload: {
    isAuth,
    ruleIds,
    partnerId,
  },
}) {
  const body = {
    partnerId,
    ruleIds,
  };
  const { ACCEPT_PARTNER_CHANEL_AGREEMENT } = API;
  try {
    const history = getGlobalHistory();
    yield put({ type: ACCEPT_AGREEMENT_RULES.start });
    yield apiCall({
      type: 'POST',
      url: ACCEPT_PARTNER_CHANEL_AGREEMENT,
      body,
    });
    yield put({ type: ACCEPT_AGREEMENT_RULES.success });
    const userHistory = {
      payload: {
        history,
        initApp: true,
      },
    };
    yield getCurrentUser(userHistory);
    yield put(getVersionDp());
    yield put(toggleModalAffiliation(false));

    const redirectAction = getAcceptAgreementRedirectActions(isAuth);
    yield redirectAction();
  } catch (e) {
    yield put({ type: ACCEPT_AGREEMENT_RULES.fail, payload: getError(e) });
  }
}

function* authorize({
  payload: {
    userLogin,
    userPassword,
    key,
    action,
    masterId,
    tabUuid,
    tabTimestamp,
    riskProfile,
    qualification,
  },
}) {
  const { SIGN_IN } = API;
  const captchaId = yield select(getCurrentCaptchaId);
  try {
    yield put({ type: SIGN_IN_START });
    const { data: { token } } = yield apiCall({
      type: 'POST',
      body: {
        userLogin,
        userPassword,
        ...(masterId !== undefined && {
          masterId,
          tabUuid,
          tabTimestamp,
        }),
        ...(key && {
          captchaId,
          key,
        }),
        ...(riskProfile && {
          riskProfile,
        }),
        ...(!_.isNaN(qualification) && {
          qualification,
        }),
      },
      url: SIGN_IN,
      withoutToken: true,
    });
    yield put({ type: SIGN_IN_SUCCESS, payload: token });
    yield put(toggleModalAffiliation(true));
  } catch (error) {
    const { response: { data: { message: { captchaId: id } } } } = error;
    if (id) yield getCaptcha({ payload: { captchaId: id } });
    action?.();
    yield put({ type: SIGN_IN_FAIL, payload: getError(error) });
  }
}

function* loginRecoveryRequest({ payload: { fio, email } }) {
  try {
    const { LOGIN_RECOVERY_REQUEST } = API;

    yield put({ type: LOGIN_RECOVERY.start });
    yield apiCall({
      type: 'POST',
      body: {
        userFio: fio,
        userEmail: email,
      },
      url: LOGIN_RECOVERY_REQUEST,
      withoutToken: true,
    });
    toastr.success('', RECOVERY_ACCESS_EMAIL_SUCCESS);
    yield put({ type: CHANGE_RECOVERY_LOGIN_MODAL, payload: false });
  } catch (error) {
    const message = error?.response?.data?.message;

    if (message) {
      toastr.error('', message.RU);
    }
  } finally {
    yield put({ type: LOGIN_RECOVERY.end });
  }
}

function* getCaptcha({ payload: { captchaId } }) {
  const { CAPTCHA } = API;
  try {
    yield put({ type: GET_CAPTCHA_START });
    const { data: { captcha } } = yield apiCall({
      type: 'POST',
      url: CAPTCHA,
      body: {
        captchaId,
      },
    });
    yield put({ type: GET_CAPTCHA_SUCCESS, payload: { captcha, captchaId } });
  } catch (e) {
    yield put({ type: GET_CAPTCHA_FAIL, payload: getError(e) });
  }
}

function* recaptcha({ payload: { captchaId: id } }) {
  try {
    yield put({ type: GET_CAPTCHA_START });
    const { data: captchaId } = yield apiCall({
      type: 'POST',
      url: API.RECAPTCHA,
      body: {
        captchaId: id,
      },
    });
    yield getCaptcha({ payload: { captchaId } });
  } catch (e) {
    yield put({ type: GET_CAPTCHA_FAIL, payload: getError(e) });
  }
}

export function* resetPassRequest({ payload: { userEmail } }) {
  const { RESET_REQUEST_API } = API;

  try {
    yield put({ type: RESET_REQUEST_START });

    yield apiCall({
      type: 'POST',
      body: { userEmail },
      url: RESET_REQUEST_API,
      withoutToken: true,
    });

    yield put({ type: RESET_REQUEST_SUCCESS });
  } catch (error) {
    yield put({ type: RESET_REQUEST_FAIL, payload: getError(error) });
  }
}

export function* checkPassChangeAccess({ payload: { search, hash } }) {
  const { CHECK_PASS_ACCESS_API } = API;

  try {
    const userEmail = atob(search.substring(1));
    const confirmationToken = atob(hash.substring(1));

    yield apiCall({
      type: 'POST',
      body: { userEmail, confirmationToken },
      url: CHECK_PASS_ACCESS_API,
      withoutToken: true,
    });

    yield put({ type: CHECK_PASS_REQUEST_SUCCESS, payload: { userEmail, confirmationToken } });
  } catch (error) {
    yield put({ type: CHECK_PASS_REQUEST_FAIL, error });
  }
}

function* resetPassword({
  payload: {
    userEmail,
    confirmationToken,
    userPassword,
    history,
  },
}) {
  const { RESET_PASSWORD } = API;

  try {
    yield put({ type: CHANGE_PASSWORD_START });

    yield apiCall({
      type: 'POST',
      body: { userEmail, confirmationToken, userPassword },
      url: RESET_PASSWORD,
      withoutToken: true,
    });

    yield put({ type: CHANGE_PASSWORD_SUCCESS });

    if (history) history.push(signIn);
  } catch (error) {
    yield put({ type: CHANGE_PASSWORD_FAIL, payload: getError(error) });
  }
}

function* getCurrentUser({ payload: { history, action, initApp } }) {
  const { GET_USER } = API;
  try {
    yield put({ type: GET_CURRENT_USER_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_USER,
    });
    yield put({ type: GET_CURRENT_USER_SUCCESS, payload: data });
    yield put(appCanRender());
    yield put(getEventsCount());
    const currentPartnerId = yield select((({ authReducer: { partnerId } }) => partnerId));
    yield put(checkExistManual(currentPartnerId));

    if (initApp) {
      const { roles } = yield select((({ authReducer }) => authReducer));

      socketInit();
      getSocket().emit('verify', '');

      yield put(getInboxUnreadMessagesCount());
      getSocket().on(DOCS_TRANSFER_UNREAD_MESSAGES_COUNT, ({ inboxUnreadMessagesCount }) => {
        storeLink.dispatch(setUnreadInboxCount(inboxUnreadMessagesCount));
      });

      if (roles[CHECK_IS_ACTIVE_CONTEST]) {
        yield put(contestActions.checkActive());
      }
    }

    if (data.isUnitedFront) {
      yield checkUnitedFrontSession(data);
    } else {
      const query = yield select(sharedSelectors.getQueryParams);
      if (query.masterId || query.riskProfile) {
        yield put(changeQueryParams({
          masterId: query.masterId,
          riskProfile: query.riskProfile,
          qualification: Number(query.qualification),
        }));
        history.push(ROUTES.signIn);
      }
    }

    if (action) {
      action();
    }
  } catch (error) {
    if (initApp) {
      yield history.push(ROUTES.signIn);
    }

    yield put({ type: GET_CURRENT_USER_FAIL, payload: getError(error) });
    yield put(appCanRender());
  }
}

function* updateUnitedFrontSession({ payload }) {
  try {
    yield put({ type: UPDATE_UNITED_FRONTEND_SESSION.start });
    yield apiCall({
      type: 'PUT',
      url: API.UPDATE_UNITED_FRONT_SESSION,
      body: payload,
    });
    yield put(setUnitedFrontendParams({
      riskProfile: payload.riskProfile,
      masterId: payload.masterId,
      qualification: payload.qualification,
    }));
    yield put(setWindowTimestamp(payload.tabTimestamp));
    yield put({ type: UPDATE_UNITED_FRONTEND_SESSION.success });
    const history = getGlobalHistory();
    history.push({
      location: history.location,
      search: '',
    });
  } catch (e) {
    yield put({ type: UPDATE_UNITED_FRONTEND_SESSION, payload: getError(e) });
  }
}

function* checkUnitedFrontSession(session) {
  const query = yield select(sharedSelectors.getQueryParams);
  const {
    windowUuid,
    windowTimestamp,
    isOpenBlockUnitedFront,
  } = yield select(getUnitedFrontendParamsSelector);

  if (isOpenBlockUnitedFront) {
    return null;
  }

  if (!session.masterId && !session.riskProfile) {
    yield updateUnitedFrontSession({
      payload: {
        riskProfile: query.riskProfile || null,
        masterId: query.masterId || null,
        qualification: Number(query.qualification),
        tabUuid: windowUuid,
        tabTimestamp: windowTimestamp,
      },
    });

    return null;
  }

  if (session.tabTimestamp > windowTimestamp) {
    yield put(togglePopupUpdatingSessionUnitedFront(false));
    yield put(openWindowBlockUnitedFront());

    return null;
  }

  if (session.tabUuid !== windowUuid) {
    yield put(togglePopupUpdatingSessionUnitedFront(true));
  }

  return null;
}

function* getIp() {
  const { GET_IP } = API;
  try {
    const { data } = yield apiCall({
      type: 'GET',
      body: {},
      url: GET_IP,
    });
    yield put({ type: GET_IP_ADDRESS_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_IP_ADDRESS_FAIL, payload: getError(error) });
  }
}

function* logOut({
  payload: {
    history,
    mute,
    redirect = true,
    isUnitedFront,
    masterId,
    riskProfile,
    qualification,
  },
}) {
  const { LOG_OUT: LOG_OUT_URL } = API;
  try {
    yield apiCall({
      type: 'GET',
      url: LOG_OUT_URL,
    });
    yield put({ type: LOG_OUT_SUCCESS });
    if (history && redirect) {
      history.push(signIn);
    } else if (history && isUnitedFront) {
      yield put(setUnitedFrontendParams({
        riskProfile,
        masterId,
        qualification,
      }));
      history.push({
        pathname: signIn,
        search: `?masterId=${masterId}&riskProfile=${riskProfile}&qualification=${qualification}`,
      });
    }
  } catch (error) {
    yield put({ type: LOG_OUT_FAIL, payload: getError(error, null, mute) });
  } finally {
    yield put({ type: LOG_OUT_SUCCESS });
    if (history && redirect) {
      history.push(signIn);
    }
  }
}

function* changeTemporaryPassword({ payload: { userPassword, history } }) {
  const { REPASSWORD } = API;
  try {
    yield put({ type: RESET_TEMPORARY_PASSWORD_START });
    yield apiCall({
      type: 'POST',
      body: {
        userPassword,
      },
      url: REPASSWORD,
    });
    yield put({ type: RESET_TEMPORARY_PASSWORD_SUCCESS });
    history.push(signIn);
    toastr.success('', MESSAGE_CHANGE_PASSWORD_SUCCESS);
  } catch (error) {
    yield put({ type: RESET_TEMPORARY_PASSWORD_FAIL, payload: getError(error) });
  } finally {
    history.push(signIn);
  }
}

function* forgotPassword({
  payload: { userLogin },
}) {
  const { FORGOT_PASSWORD } = API;
  try {
    yield put({ type: PASSWORD_RECOVERY.start });
    yield apiCall({
      type: 'POST',
      body: {
        userLogin,
      },
      url: FORGOT_PASSWORD,
    });
    yield put({ type: PASSWORD_RECOVERY.success });
    toastr.success('', RESTOR_PASSWORD_SUCCESS);
  } catch (error) {
    const statusCode = error?.response?.data?.statusCode;

    if (statusCode === 404) {
      yield put({ type: CHANGE_RECOVERY_LOGIN_MODAL, payload: true });
    }

    yield put({ type: PASSWORD_RECOVERY.fail, payload: getError(error) });
  }
}

function* getAccessBranches({
  payload,
  selectedByUser,
  history,
  initApp,
}) {
  const { GET_ACCESS_BRANCHES_FOR_AUTH } = API;
  try {
    yield put({ type: GET_BRANCHES_FOR_AUTH_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_ACCESS_BRANCHES_FOR_AUTH,
      body: {
        partnerId: payload,
      },
    });
    yield put({ type: GET_BRANCHES_FOR_AUTH_SUCCESS, payload: data });
    if (data.length === 1) {
      yield put(selectBranchAuth(data[0].value));
      if (!selectedByUser && !initApp) {
        yield setAffiliation({
          history,
          payload: {
            partnerId: payload,
            branchId: data[0].value,
          },
          isAuth: true,
        });
      }
    }
  } catch (error) {
    yield put({ type: GET_BRANCHES_FOR_AUTH_FAIL, payload: getError(error) });
  }
}

function* getAccessPartners({ history, initApp }) {
  const { GET_ACCESS_PARTNERS_FOR_AUTH } = API;
  try {
    yield put({ type: GET_PARTNERS_FOR_AUTH_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: GET_ACCESS_PARTNERS_FOR_AUTH,
    });
    yield put({ type: GET_PARTNERS_FOR_AUTH_SUCCESS, payload: data });
    if (data.length === 1) {
      yield put(selectPartnerAuth(data[0].value));
      yield getAccessBranches({ payload: data[0].value, history, initApp });
    }
  } catch (error) {
    yield put({ type: GET_PARTNERS_FOR_AUTH_FAIL, payload: getError(error) });
  }
}

function* setAffiliation({ payload, history, isAuth }) {
  const { SET_AFFILIATION } = API;
  try {
    yield put({ type: SET_AFFILIATION_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: SET_AFFILIATION,
      body: payload,
    });
    yield put({ type: SET_AFFILIATION_SUCCESS, payload: data });
    const { isShowAcceptAgreement } = data;
    if (isShowAcceptAgreement) {
      history.push(partnerChannel, { isAuth });
    } else {
      const userHistory = {
        payload: {
          history,
          initApp: true,
        },
      };
      yield getCurrentUser(userHistory);
      yield put(getVersionDp());
      yield put(toggleModalAffiliation(false));

      const { location: { pathname } } = history;
      const { riskProfile } = yield select(getUnitedFrontendParamsSelector);

      if (isAuth) {
        getGlobalHistory().push(home);
      } else {
        if (pathname === ROUTES.home) {
          yield put(getProducts(null, riskProfile));
        }
        getGlobalHistory().push(home);
      }
    }
  } catch (error) {
    yield put({ type: SET_AFFILIATION_FAIL, payload: getError(error) });
  }
}

function* checkAuthSession() {
  try {
    yield put({ type: CHECK_AUTH_SESSION.start });
    yield apiCall({
      type: 'POST',
      url: API.GET_USER,
    });
    yield put({ type: CHECK_AUTH_SESSION.success });
  } catch (e) {
    const errorCode = _.get(e, 'response.data.message.ERROR');

    if (errorCode === UNITED_FRONTEND_ERRORS.TAB_TIMESTAMP_ERROR) {
      yield put(closeAllPopups());
      yield put({ type: OPEN_WINDOW_BLOCK_UNITED_FRONT });

      return;
    }

    yield put({ type: CHECK_AUTH_SESSION.fail, payload: getError(e) });
  }
}

function* AuthSaga() {
  yield takeLatest(SET_AFFILIATION_REQUEST, setAffiliation);
  yield takeLatest(GET_PARTNERS_FOR_AUTH_REQUEST, getAccessPartners);
  yield takeLatest(GET_BRANCHES_FOR_AUTH_REQUEST, getAccessBranches);
  yield takeLatest(RESET_TEMPORARY_PASSWORD_REQUEST, changeTemporaryPassword);
  yield takeLatest(RECAPTCHA, recaptcha);
  yield takeLatest(LOG_OUT, logOut);
  yield takeLatest(GET_CAPTCHA_REQUEST, getCaptcha);
  yield takeLatest(GET_IP_ADDRESS_REQUEST, getIp);
  yield takeLatest(AUTH_REQUEST, authorize);
  yield takeLatest(RESET_REQUEST, resetPassRequest);
  yield takeLatest(PASSWORD_RECOVERY.request, forgotPassword);
  yield takeLatest(CHECK_PASS_REQUEST, checkPassChangeAccess);
  yield takeLatest(CHANGE_PASSWORD, resetPassword);
  yield takeLatest(GET_CURRENT_USER, getCurrentUser);
  yield takeLatest(CHECK_AUTH_SESSION.request, checkAuthSession);
  yield takeLatest(UPDATE_UNITED_FRONTEND_SESSION.request, updateUnitedFrontSession);
  yield takeLatest(ACCEPT_AGREEMENT_RULES.request, acceptPartnerChannelAgreement);
  yield takeLatest(LOGIN_RECOVERY.request, loginRecoveryRequest);
}

export default AuthSaga;
