export const miniChartConfig = {
  chartOnly: false,
  locale: 'ru',
  colorTheme: 'light',
  gridLineColor: 'rgba(0, 0, 0, 1)',
  trendLineColor: 'rgba(7, 55, 99, 1)',
  fontColor: 'rgba(0, 0, 0, 1)',
  underLineColor: 'rgba(207, 226, 243, 1)',
  isTransparent: false,
  autosize: true,
  container_id: 'tradingview_minichart_root_id',
  showFloatingTooltip: true,
};
