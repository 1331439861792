import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const {
  DOCUMENT_STATUS,
  POLICY_NUMBER,
  INSURER,
} = LANG_DICTIONARY;

const propTypes = {
  statusCurrent: PropTypes.string,
  insurerFirstName: PropTypes.string,
  insurerLastName: PropTypes.string,
  insurerMiddleName: PropTypes.string,
  contractNum: PropTypes.string,
};

const defaultProps = {
  statusCurrent: null,
  insurerFirstName: null,
  insurerLastName: null,
  insurerMiddleName: null,
  contractNum: null,
};

const b = block('documentStatus');
const getName = (lastName, firstName, middleName) => lastName && `${lastName} ${firstName} ${middleName}`;

const DocumentStatusBar = ({
  statusCurrent,
  insurerFirstName,
  insurerLastName,
  insurerMiddleName,
  contractNum,
}) => (
  <div className={b()}>
    <div className={b('currentStatus')}>
      <div className={b('label')}>{DOCUMENT_STATUS}</div>
      <div className={b('currentStatus', 'value')}>{ statusCurrent }</div>
    </div>
    <div className={b('additionalInfo')}>
      <div className={b('label')}>{INSURER}</div>
      <div className={b('additionalInfo', 'value')}>
        {getName(insurerLastName, insurerFirstName, insurerMiddleName)}
      </div>
    </div>
    <div className={b('additionalInfo')}>
      <div className={b('label')}>{POLICY_NUMBER}</div>
      <div className={b('additionalInfo', 'value')}>{ contractNum }</div>
    </div>
  </div>
);

DocumentStatusBar.propTypes = propTypes;
DocumentStatusBar.defaultProps = defaultProps;

export default DocumentStatusBar;
