import React from 'react';
import ReduxToastr from 'react-redux-toastr';
import { BrowserRouter as Router } from 'react-router-dom';
import {
  SideMenu, GlobalHistory, HeadPanel, FooterPanel,
} from './components';
import Routes from './router';
import './App.scss';
import 'semantic-ui-css/semantic.min.css';

const App = () => (
  <Router>
    <div className="App">
      <SideMenu />
      <header className="App-header">
        <HeadPanel />
      </header>
      <Routes />
      <FooterPanel />
      <GlobalHistory />
      <ReduxToastr
        timeOut={10000}
        newestOnTop={false}
        preventDuplicates
        position="top-right"
        transitionIn="fadeIn"
        transitionOut="fadeOut"
        progressBar
        closeOnToastrClick
      />
    </div>
  </Router>
);

export default App;
