import {
  put,
  takeLatest,
  delay,
} from 'redux-saga/effects';
import {
  apiCall,
  getError,
} from 'helpers';
import { API } from 'consts';
import { DEFAULT_REQUEST_DELAY } from 'config';
import {
  UNLOCK_ALL_LOCKS_DOCUMENTS_REQUEST,
  UNLOCK_ALL_LOCKS_DOCUMENTS_SUCCESS,
  UNLOCK_ALL_LOCKS_DOCUMENTS_START,
  UNLOCK_ALL_LOCKS_DOCUMENTS_FAIL,
  NOTIFY_CLIENTS_CALC_BASKET_FAIL,
  NOTIFY_CLIENTS_CALC_BASKET_START,
  NOTIFY_CLIENTS_CALC_BASKET_SUCCESS,
  NOTIFY_CLIENTS_CALC_BASKET_REQUEST,
} from '../types';

const {
  UNLOCK_ALL_CHECKING_DOCUMENT,
  NOTIFY_CLIENTS_CALC_BASKET,
} = API;

function* unlockAllDocument() {
  try {
    yield put({ type: UNLOCK_ALL_LOCKS_DOCUMENTS_START });
    yield apiCall({
      url: UNLOCK_ALL_CHECKING_DOCUMENT,
      type: 'POST',
    });
    yield put({ type: UNLOCK_ALL_LOCKS_DOCUMENTS_SUCCESS });
  } catch (e) {
    yield put({ type: UNLOCK_ALL_LOCKS_DOCUMENTS_FAIL, error: getError(e) });
  }
}

function* notifyCalcBasket() {
  try {
    yield put({ type: NOTIFY_CLIENTS_CALC_BASKET_START });
    yield apiCall({
      url: NOTIFY_CLIENTS_CALC_BASKET,
      type: 'POST',
    });
    yield delay(DEFAULT_REQUEST_DELAY);
    yield put({ type: NOTIFY_CLIENTS_CALC_BASKET_SUCCESS });
  } catch (e) {
    yield put({ type: NOTIFY_CLIENTS_CALC_BASKET_FAIL, error: getError(e) });
  }
}

function* locksAndTasks() {
  yield takeLatest(NOTIFY_CLIENTS_CALC_BASKET_REQUEST, notifyCalcBasket);
  yield takeLatest(UNLOCK_ALL_LOCKS_DOCUMENTS_REQUEST, unlockAllDocument);
}

export default locksAndTasks;
