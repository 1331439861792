import React from 'react';
import { block } from 'bem-cn';
import { Input } from 'semantic-ui-react';
import { LANG_DICTIONARY, STATUS_SCANS, TYPE_ERROR_CHECKING_SCAN } from 'consts';
import { SwitcherStatusChecking } from 'components';
import { SelectInput } from 'components/_deprecated';
import PropTypes from 'prop-types';
import './styles.scss';

const bem = block('check-block-editor');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  changeStatusScan: PropTypes.func,
  setCurrentScan: PropTypes.func,
  currentScanCheck: PropTypes.number,
  statusListForScan: PropTypes.arrayOf(PropTypes.object),
  changeStatusScanSelect: PropTypes.func,
  handleBlurErrorDesc: PropTypes.func,
};

const defaultProps = {
  data: [],
  changeStatusScan: () => null,
  setCurrentScan: () => null,
  currentScanCheck: 0,
  statusListForScan: [],
  changeStatusScanSelect: () => null,
  handleBlurErrorDesc: () => null,
};

const {
  DESCRIPTION_ERROR,
  STATUS,
} = LANG_DICTIONARY;

const {
  badScan: badScanError,
  other: otherError,
} = TYPE_ERROR_CHECKING_SCAN;

const CheckBlock = ({
  data,
  changeStatusScan,
  setCurrentScan,
  currentScanCheck,
  statusListForScan,
  changeStatusScanSelect,
  handleBlurErrorDesc,
}) => (
  <div className={bem()}>
    {data && data.map(({
      checkDateTime,
      checkStatusCode,
      checkStatusName,
      checkUserFio,
      fileScanId,
      fileType,
      checkErrorCode,
      checkErrorName,
      checkErrorDescription,
      fileScanCheckId,
      fileName,
    }, index) => {
      const getStatus = () => {
        switch (checkStatusCode) {
          case STATUS_SCANS.error:
            return {
              error: true,
              success: false,
            };
          case STATUS_SCANS.checked:
            return {
              error: false,
              success: true,
            };
          default:
            return {
              error: false,
              success: false,
            };
        }
      };

      const {
        error,
        success,
      } = getStatus();

      const handleChangeSelect = ({ target: { value } }) => {
        changeStatusScanSelect(index, value);
      };
      const handleBlurInput = ({ target: { value } }) => handleBlurErrorDesc(index, value);
      if (!checkErrorCode) changeStatusScanSelect(index, badScanError, false);

      return (
        <div
          className={bem('item', { active: currentScanCheck === fileScanCheckId })}
          onClick={() => setCurrentScan(fileScanId, fileScanCheckId, fileName)}
          role="button"
          tabIndex="-1"
          key={fileScanCheckId}
        >
          <div className={bem('control')}>
            <p>{fileType}</p>
            <SwitcherStatusChecking
              error={error}
              success={success}
              changeStatusScan={changeStatusScan}
              indexScan={index}
              checkUserFio={checkUserFio}
              checkDateTime={checkDateTime}
              checkStatusName={checkStatusName}
              checkErrorDescription={checkErrorDescription}
              checkErrorName={checkErrorName}
            />
          </div>
          {error && (
          <div className={bem('status')}>
            <div className={bem('status-state')}>
              <p className={bem('select-title')}>{`${STATUS}:`}</p>
              <SelectInput
                values={{ status: checkErrorCode }}
                onChange={handleChangeSelect}
                options={statusListForScan}
                name="status"
              />
            </div>
            {checkErrorCode === otherError
            && (
            <Input
              defaultValue={checkErrorDescription}
              onBlur={handleBlurInput}
              placeholder={DESCRIPTION_ERROR}
              maxLength="200"
            />
            )}
          </div>
          )}
        </div>
      );
    })}
  </div>
);

CheckBlock.propTypes = propTypes;
CheckBlock.defaultProps = defaultProps;
export default CheckBlock;
