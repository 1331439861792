export default {
  checkPodtf: 'check_podft',
  getCurrentUser: 'get_current_user',
  getFiasAddress: 'get_fias_address',
  getFiasBank: 'get_fias_bank',
  sendResetMessage: 'send_reset_message',
  resetPassword: 'reset_password',
  checkToken: 'check_token',
  getProductList: 'get_product_list',
  productSelection: 'product_selection',
  getProductParameters: 'get_product_parameters',
  checkSumInsured: 'check_sum_insured',
  getBeneficiaryList: 'get_beneficiary_list',
  getReferences: 'get_references',
  saveContract: 'save_contract',
  getDataContract: 'get_data_contract',
  downloadContract: 'download_contract',
  updateStatusContract: 'update_status_contract',
  uploadDocumentScan: 'upload_document_scan',
  saveDocumentScan: 'save_document_scan',
  searchByProduct: 'search_by_product',
  searchByStatus: 'search_by_status',
  searchByBranch: 'search_by_branch',
  searchByPartner: 'search_by_partner',
  searchByUser: 'search_by_user',
  getSearchList: 'get_search_list',
  getExtendedContract: 'get_extended_contract',
  downloadSearchDocument: 'download_search_document',
  export1c: 'export_1c',
  importDownloadTemplate: 'import_download_template',
  importUploadReferencies: 'import_upload_referencies',
};
