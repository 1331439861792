import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';

import {
  Button,
  Modal,
  Search,
  Grid,
  Icon,
} from 'semantic-ui-react';

import { LANG_DICTIONARY } from 'consts';
import { searchAddress, setAddress } from 'redux/actions';
import { connect } from 'react-redux';

const { SEARCH_ADDRESS, SELECT_ADDRESS } = LANG_DICTIONARY;

const defaultProps = {
  buttonAction: null,
  onSearch: null,
  searchResult: null,
  isLoading: false,
};

const propTypes = {
  buttonAction: PropTypes.func,
  onSearch: PropTypes.func,
  searchResult: PropTypes.arrayOf(PropTypes.object),
  isLoading: PropTypes.bool,
};

class AddressModal extends Component {
  constructor(props) {
    super(props);

    this.state = {
      value: '',
      isOpen: false,
    };
  }

  handleResultSelect = async (e, { result }) => {
    const { buttonAction: setAddressValue } = this.props;
    await this.setState({ value: result.title });
    setAddressValue(result);
    this.toggleModal(false);
  };

  resetComponent = () => this.setState({ value: '' });

  toggleModal = (value) => {
    this.setState({ isOpen: value });
    this.resetComponent();
  };

  handleSearchChange = (e, { value }) => {
    const { onSearch } = this.props;

    this.setState({ value });

    onSearch(value);
  };

  render() {
    const { searchResult, isLoading } = this.props;

    const {
      value,
      isOpen,
    } = this.state;

    return (
      <Modal
        size="tiny"
        open={isOpen}
        onClose={() => this.toggleModal(false)}
        trigger={(
          <Button
            type="button"
            onClick={() => this.toggleModal(true)}
            className="insurerData__defaultButton modalView"
          >
            { SELECT_ADDRESS }
          </Button>
        )}
      >
        <Modal.Header>{ SEARCH_ADDRESS }</Modal.Header>
        <Modal.Content image>
          <Grid columns={1} className="insurerData__content">
            <Grid.Row>
              <Grid.Column>
                <Search
                  loading={isLoading}
                  onResultSelect={this.handleResultSelect}
                  onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                  results={searchResult}
                  value={value}
                  className="insurerData__searchBar"
                />
              </Grid.Column>
            </Grid.Row>
          </Grid>
        </Modal.Content>
        <Modal.Actions>
          <Button onClick={() => this.toggleModal(false)}>
            <Icon name="remove" />
            Cancel
          </Button>
        </Modal.Actions>
      </Modal>
    );
  }
}

function mapStateToProps({ helperReducer: { addressSearchResult, isLoading } }) {
  const searchResult = addressSearchResult.map((values) => ({ ...values, title: values.value }));

  return { searchResult, isLoading };
}

AddressModal.defaultProps = defaultProps;
AddressModal.propTypes = propTypes;

export default connect(
  mapStateToProps,
  { onSearch: searchAddress, setAddressValue: setAddress },
)(AddressModal);
