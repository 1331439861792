import { put, takeLatest } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import {
  API,
  ROUTES,
  ERROR_STATUSES,
  LANG_DICTIONARY,
} from 'consts';
import { ITEM_COUNT_PUBLIC_API_LIST } from 'config';
import { getGlobalHistory } from 'components';
import { toastr } from 'react-redux-toastr';
import {
  GET_PUBLIC_API_LIST,
  GET_DATA_FOR_SELECT_API,
  GET_BRANCHES,
  GET_PARTNERS,
  GET_USERS,
  CREATE_NEW_PUBLIC_API,
  GET_PUBLIC_API_BY_ID,
  REGENERATE_SECRET_KEY,
} from '../types';
import { setStatusPopupPublicApi } from '../actions';

const { SAVED } = LANG_DICTIONARY;

function* getPublicApiList({ payload }) {
  try {
    yield put({ type: GET_PUBLIC_API_LIST.start });
    const { data } = yield apiCall({
      url: API.PUBLIC_API_LIST,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_PUBLIC_API_LIST * (payload - 1),
        limit: ITEM_COUNT_PUBLIC_API_LIST,
      },
    });
    yield put({ type: GET_PUBLIC_API_LIST.success, payload: data });
  } catch (e) {
    yield put({ type: GET_PUBLIC_API_LIST.fail, error: getError(e) });
  }
}

function* getDataForSelectApi() {
  try {
    yield put({ type: GET_DATA_FOR_SELECT_API.start });
    const { data } = yield apiCall({
      url: API.GET_PUBLIC_API_REFERENCE,
      type: 'GET',
      query: {
        offset: 0,
        limit: 0,
      },
    });
    yield put({ type: GET_DATA_FOR_SELECT_API.success, payload: data });
  } catch (e) {
    yield put({ type: GET_DATA_FOR_SELECT_API.fail, error: getError(e) });
  }
}

function* getPartners() {
  const { GET_PARTNER_LIST } = API;
  try {
    yield put({ type: GET_PARTNERS.start });
    const { data } = yield apiCall({
      url: GET_PARTNER_LIST,
      type: 'GET',
    });
    yield put({
      type: GET_PARTNERS.success,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_PARTNERS.fail, payload: getError(e) });
  }
}

function* getUsers({ payload: { branchId, partnerId } }) {
  const { GET_USERS_DEPENDING_BRANCH_AND_PARTNER } = API;
  try {
    yield put({ type: GET_USERS.start });
    const { data } = yield apiCall({
      url: GET_USERS_DEPENDING_BRANCH_AND_PARTNER,
      type: 'POST',
      body: {
        branchId,
        partnerId,
      },
    });
    yield put({
      type: GET_USERS.success,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_USERS.fail, payload: getError(e) });
  }
}

function* getBranches({ payload }) {
  const { GET_BRANCHES_DEPENDING_PARTNER } = API;
  try {
    yield put({ type: GET_BRANCHES.start });
    const { data } = yield apiCall({
      url: GET_BRANCHES_DEPENDING_PARTNER,
      type: 'POST',
      body: {
        partnerId: payload,
      },
    });
    yield put({
      type: GET_BRANCHES.success,
      payload: data,
    });
  } catch (e) {
    yield put({ type: GET_BRANCHES.fail, payload: getError(e) });
  }
}

function* createNewApi({ payload: { body, updateId } }) {
  try {
    yield put({ type: CREATE_NEW_PUBLIC_API.start });
    const { data } = yield apiCall({
      url: updateId ? API.GET_PUBLIC_API_BY_ID(updateId) : API.PUBLIC_API_LIST,
      type: updateId ? 'PUT' : 'POST',
      body,
    });

    yield put({
      type: CREATE_NEW_PUBLIC_API.success,
      payload: data,
    });

    if (!updateId) {
      yield put(setStatusPopupPublicApi(true));
      getGlobalHistory().push(`${ROUTES.updatePublicApi}/${data.id}`);
    }

    toastr.success('', SAVED);
  } catch (e) {
    yield put({ type: CREATE_NEW_PUBLIC_API.fail, payload: getError(e) });
  }
}

function* getPublicApiById({ payload }, isUpdate) {
  try {
    yield put({ type: GET_PUBLIC_API_BY_ID.start, payload: isUpdate });
    const { data } = yield apiCall({
      url: API.GET_PUBLIC_API_BY_ID(payload),
      type: 'GET',
    });

    if (!isUpdate) {
      yield getBranches({ payload: data.partnerId });
      yield getUsers({
        payload: {
          partnerId: data.partnerId,
          userId: data.userId,
        },
      });
    }
    yield put({
      type: GET_PUBLIC_API_BY_ID.success,
      payload: data,
    });
  } catch (e) {
    if (e.response && e.response.status === ERROR_STATUSES.notFound) {
      getGlobalHistory().push(ROUTES.notFound);
    }
    yield put({ type: GET_PUBLIC_API_BY_ID.fail, payload: getError(e) });
  }
}

function* regenerateSecretKey({ payload }) {
  try {
    yield put({ type: REGENERATE_SECRET_KEY.start });
    const { data } = yield apiCall({
      url: API.REGENERATE_SECRET_KEY(payload),
      type: 'PUT',
    });
    yield put({
      type: REGENERATE_SECRET_KEY.success,
      payload: data,
    });
    yield getPublicApiById({ payload }, true);
    yield put(setStatusPopupPublicApi(true));
  } catch (e) {
    yield put({ type: REGENERATE_SECRET_KEY.fail, payload: getError(e) });
  }
}

function* partnersSaga() {
  yield takeLatest(REGENERATE_SECRET_KEY.request, regenerateSecretKey);
  yield takeLatest(GET_PUBLIC_API_BY_ID.request, getPublicApiById);
  yield takeLatest(CREATE_NEW_PUBLIC_API.request, createNewApi);
  yield takeLatest(GET_BRANCHES.request, getBranches);
  yield takeLatest(GET_PARTNERS.request, getPartners);
  yield takeLatest(GET_USERS.request, getUsers);
  yield takeLatest(GET_DATA_FOR_SELECT_API.request, getDataForSelectApi);
  yield takeLatest(GET_PUBLIC_API_LIST.request, getPublicApiList);
}

export default partnersSaga;
