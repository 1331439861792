import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, TEMPLATES_MODAL_CODES } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer } from 'components';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const {
  TEMPLATE_ALREADY_EXIST,
  YES,
  NO,
  TEMPLATE_CHECK_RESULT,
  UNKNOWN_ERROR,
} = LANG_DICTIONARY;

const propTypes = {
  popupIsOpen: PropTypes.bool,
  closePopup: PropTypes.func,
  errors: PropTypes.arrayOf(PropTypes.string),
  fileName: PropTypes.string,
  type: PropTypes.string,
  uploadTemplate: PropTypes.func,
};

const defaultProps = {
  popupIsOpen: false,
  closePopup: () => null,
  errors: [],
  fileName: '',
  type: '',
  uploadTemplate: () => null,
};

const b = block('templates-popup');

const TemplatesPopup = ({
  closePopup,
  errors,
  popupIsOpen,
  fileName,
  type,
  uploadTemplate,
}) => {
  const getModal = () => {
    switch (type) {
      case TEMPLATES_MODAL_CODES.error: {
        return (
          <div className={b()}>
            <div className={b('header-text')}>
              {TEMPLATE_CHECK_RESULT}
            </div>
            <div className={b('import-errors')}>
              {errors.map(({ message, tag }) => (
                <div className={b('error-row')}>
                  <Icon size="big" name="warning sign" className={b('warning-sign').toString()} />
                  {`${message} ${tag}`}
                </div>
              ))}
            </div>
          </div>
        );
      }

      case TEMPLATES_MODAL_CODES.exist: {
        return (
          <div className={b()}>
            <p className={b('header-text')}>
              {TEMPLATE_ALREADY_EXIST(fileName)}
            </p>
            <div className={b('controls')}>
              <div className={b('btn-wrapper')}>
                <button className={b('btn-confirm')} type="button" onClick={uploadTemplate}>
                  {YES}
                </button>
              </div>
              <div className={b('btn-wrapper')}>
                <button className={b('btn-cancel')} type="button" onClick={closePopup}>
                  {NO}
                </button>
              </div>
            </div>
          </div>
        );
      }

      default: {
        return (
          <div className={b()}>
            <div className={b('header-text')}>
              {UNKNOWN_ERROR}
            </div>
          </div>
        );
      }
    }
  };

  return (
    <PopupContainer
      close={closePopup}
      isOpen={popupIsOpen}
      isCloseButton
    >
      {getModal()}
    </PopupContainer>
  );
};

TemplatesPopup.propTypes = propTypes;
TemplatesPopup.defaultProps = defaultProps;
export default TemplatesPopup;
