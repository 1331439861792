import React from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn/lib/index';
import { withCustomRouter } from 'HOC';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const b = block('breadcrumbs');

const defaultProps = {
  subBreadcrumbs: false,
  onResetErrors: () => null,
  stage: 1,
  active: '',
};

const propTypes = {
  breadcrumbsList: PropTypes.arrayOf(PropTypes.object).isRequired,
  active: PropTypes.string,
  history: PropTypes.object.isRequired,
  subBreadcrumbs: PropTypes.bool,
  onResetErrors: PropTypes.func,
  stage: PropTypes.number,
};

const gotToSection = async (
  history,
  breadcrumbLink,
  onResetErrors,
) => {
  const { location: { pathname } } = history;
  if (pathname !== breadcrumbLink) {
    await onResetErrors();
  }

  history.push(breadcrumbLink);
};

const renderItem = ({
  active,
  history,
  subBreadcrumbs,
  onResetErrors,
  stage,
  match: { params: { id } },
}) => ({
  // eslint-disable-next-line
  name, link, stage: minStage, props,
}, index) => {
  const isActive = active === String(index);
  const breadcrumbLink = id ? `${link}/${id}` : link;
  const handleClick = () => link && stage >= minStage
    && gotToSection(
      history,
      breadcrumbLink,
      onResetErrors,
    );

  return (
    <div
      role="button"
      tabIndex="0"
      className={`${b('section')} ${isActive ? 'active' : ''} ${subBreadcrumbs ? 'subBreadcrumbs' : ''}`}
      onClick={handleClick}
      key={name}
    >
      { name }
      { !subBreadcrumbs && <Icon name="caret right" /> }
    </div>
  );
};

const Breadcrumbs = ({
  breadcrumbsList,
  ...props
}) => (
  <div className={b()}>
    {breadcrumbsList.map(renderItem(props))}
  </div>
);

Breadcrumbs.defaultProps = defaultProps;
Breadcrumbs.propTypes = propTypes;

export default withCustomRouter(Breadcrumbs);
