import React, { Component } from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { connect } from 'react-redux';
import { Form, Icon } from 'semantic-ui-react';
import { DATE_PICKER_FORMAT, MASK, KEY_NAMES } from 'consts';
import {
  GetFormError,
  Tooltip,
  CalendarIcon,
  InputField,
} from 'components';
import DatePicker, { registerLocale } from 'react-datepicker';
import { getError } from 'redux/actions';
import { getFormInputClass, getStartOfDayDate } from 'helpers';
import Scroll from 'react-scroll';
import ru from 'date-fns/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import './style.scss';

registerLocale('ru', ru);

const { birthDateMask } = MASK;
const { KEY_ENTER } = KEY_NAMES;
const defaultProps = {
  values: {},
  errors: {},
  touched: {},
  name: null,
  propertyName: null,
  label: null,
  fieldNumber: null,
  width: '',
  customValidation: null,
  GetError: null,
  wrapClass: '',
  validationValue: null,
  tooltipContent: null,
  placeholder: '',
  minDate: null,
  maxDate: null,
  onBlur: () => {},
  required: false,
  isVisableError: false,
  disabled: false,
  prefixScroll: '',
  prefixWrapperClass: '',
};

const propTypes = {
  disabled: PropTypes.bool,
  values: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  name: PropTypes.string,
  propertyName: PropTypes.string,
  label: PropTypes.string,
  fieldNumber: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  width: PropTypes.string,
  customValidation: PropTypes.func,
  GetError: PropTypes.func,
  wrapClass: PropTypes.string,
  validationValue: PropTypes.bool,
  tooltipContent: PropTypes.shape({
    text: PropTypes.arrayOf(PropTypes.string),
    title: PropTypes.string,
  }),
  placeholder: PropTypes.string,
  minDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  maxDate: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.string,
  ]),
  onBlur: PropTypes.func,
  required: PropTypes.bool,
  isVisableError: PropTypes.bool,
  prefixScroll: PropTypes.string,
  prefixWrapperClass: PropTypes.string,
};

class DateCustomPicker extends Component {
  getError = (customValidation, values) => {
    const { GetError, validationValue } = this.props;
    const onCheck = customValidation(values, validationValue);
    GetError(onCheck, 'passportError');

    return onCheck;
  };

  changeDateValueFocus = (name, value) => {
    const { onBlur } = this.props;
    this.changeDateValue(name)(value);
    onBlur(getStartOfDayDate(value));
  };

  changeDateValue = (name) => (date) => {
    const { onChange } = this.props;
    const data = {
      name,
      value: getStartOfDayDate(date),
    };
    onChange({ target: data });
  };

  render() {
    const {
      values,
      name,
      propertyName,
      errors,
      touched,
      label,
      fieldNumber,
      tooltipContent,
      width,
      customValidation,
      wrapClass,
      placeholder,
      minDate,
      maxDate,
      required,
      isVisableError,
      onBlur,
      disabled,
      prefixScroll,
      prefixWrapperClass,
    } = this.props;

    const date = values[propertyName || name];
    const selectedDate = date instanceof Date && !_.isNaN(date.getTime()) ? date : null;

    return (
      <Scroll.Element name={`scroll${name}${prefixScroll}`} className={prefixWrapperClass}>
        <Form.Field
          className={getFormInputClass({
            error: errors[name],
            touched: touched[propertyName || name],
            width,
            wrapClass,
            isVisableError,
          })}
          data-tip="helperTip"
          data-for={name}
        >
          {fieldNumber && <div className="fieldNumber">{ fieldNumber }</div>}
          <div
            className="fieldWrap date"
            role="button"
          >
            {label && <div className="label">{ label }</div>}
            <CalendarIcon />
            {required && <Icon name="write" />}
            <DatePicker
              disabled={disabled}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              autoComplete="off"
              locale="ru"
              isClearable
              onChange={this.changeDateValue(name)}
              name={name}
              selected={selectedDate}
              placeholderText={placeholder}
              maxDate={maxDate || null}
              minDate={minDate || null}
              dateFormat={DATE_PICKER_FORMAT}
              strictParsing
              onBlur={(e) => {
                if (e.target.value) {
                  onBlur(e.target.value);
                }
              }}
              onSelect={(e) => onBlur(e)}
              onMonthChange={(e) => this.changeDateValueFocus(name, e)}
              onYearChange={(e) => this.changeDateValueFocus(name, e)}
              onKeyDown={(e) => {
                if (e.key === KEY_ENTER) onBlur(e.target.value);
              }}
              customInput={(
                <InputField
                  value={selectedDate}
                  mask={birthDateMask}
                  name={name}
                  disabled={disabled}
                  prefixScroll={prefixScroll}
                />
              )}
            />
          </div>
          <div className="error date">
            <GetFormError
              errors={errors}
              touched={touched}
              itemName={name}
              propertyName={propertyName}
              customError={customValidation && this.getError(customValidation, values)}
              notTouched
              value={selectedDate}
              isDate
            />
          </div>
          { tooltipContent && <Tooltip data={tooltipContent} tooltipName={name} /> }
        </Form.Field>
      </Scroll.Element>
    );
  }
}

DateCustomPicker.defaultProps = defaultProps;
DateCustomPicker.propTypes = propTypes;

export default connect(null, { GetError: getError })(DateCustomPicker);
