import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import {
  RequiredLabel,
  InputField,
  MainBtn,
} from 'components';
import { getErrorsFromValidationError, onlyNumbers } from 'helpers';
import { Formik } from 'formik';
import { LANG_DICTIONARY } from 'consts';
import { ITEM_COUNT_DROPDOWN_USER_ADMIN } from 'config';
import schema from './schema';
import './styles.scss';

const {
  ID,
  PARTNER,
  NAMING_BRANCH,
  IS_ROOT_BRANCH,
  PARENT_BRANCH,
  FULL_TREE_BRANCH,
  BRANCH_ADDRESS,
  REVOKE,
  SAVE_WORD,
  CREATE,
  CODE_OKATO,
  NOT_CHOOSED,
  OCATO_LENGTH,
  INNER_BRANCH_CODE_BANK,
  BRANCH_TUTOR,
  IS_TEST_BRANCH,
  MASS_SEGMENT,
  CONTRACTS_SOURCE,
  SCANS_REQUIRED,
} = LANG_DICTIONARY;

const b = block('creating-or-updating-branch');

const propTypes = {
  initialValues: PropTypes.object,
  handleSubmitSuccess: PropTypes.func,
  history: PropTypes.object,
  isUpdatedForm: PropTypes.bool,
  getTreeBranchName: PropTypes.func,
  getUsers: PropTypes.func,
  users: PropTypes.arrayOf(PropTypes.object),
  isLoadingUsers: false,
  ctsSourcesCodes: PropTypes.arrayOf(PropTypes.object),
};
const defaultProps = {
  initialValues: {},
  handleSubmitSuccess: () => null,
  history: {},
  isUpdatedForm: false,
  getTreeBranchName: () => null,
  getUsers: () => null,
  users: [],
  isLoadingUsers: false,
  ctsSourcesCodes: [],
};

const CreatingOrUpdatingBranch = ({
  initialValues,
  handleSubmitSuccess,
  history,
  isUpdatedForm,
  getTreeBranchName,
  getUsers,
  users,
  isLoadingUsers,
  ctsSourcesCodes,
}) => {
  const validateForm = (values) => {
    const { isRootBranch } = values;
    try {
      schema(isRootBranch).validateSync(values, { abortEarly: false });

      return {};
    } catch (error) {
      return getErrorsFromValidationError(error);
    }
  };
  const handleChangeParterSelect = (e, onChange, setFieldValue) => {
    setFieldValue('parentBranchId', null);
    onChange(e);
  };

  return (
    <Formik
      initialValues={initialValues}
      validate={validateForm}
      onSubmit={handleSubmitSuccess}
      enableReinitialize
    >
      {({
        values,
        errors,
        touched,
        handleChange,
        handleBlur,
        setFieldValue,
        handleSubmit,
      }) => {
        const inputProps = {
          values,
          errors,
          touched,
          onChange: handleChange,
          onBlur: handleBlur,
        };

        const handleChangeOkato = (e) => {
          const { value, name } = e.target;
          const rebuildValue = onlyNumbers(value);
          if (rebuildValue.length <= OCATO_LENGTH) {
            handleChange({ target: { value: rebuildValue, name } });
          }
        };

        const handleChangeCheckbox = (name, checked) => (
          handleChange({ target: { name, value: !checked } }));

        return (
          <form className={b()}>
            <div>
              <RequiredLabel />
              <InputField
                name="id"
                title={ID}
                disabled
                {...inputProps}
              />
              <InputField
                name="partnerId"
                title={PARTNER}
                type="select"
                typeSelect="partners"
                prefixSelect="update"
                placeholderText={NOT_CHOOSED}
                required
                {...inputProps}
                onChange={(e) => handleChangeParterSelect(
                  e,
                  handleChange,
                  setFieldValue,
                )}
              />
              <InputField
                title={NAMING_BRANCH}
                name="branchName"
                required
                {...inputProps}
              />
              <InputField
                title={IS_ROOT_BRANCH}
                name="isRootBranch"
                type="checkbox"
                onChange={handleChangeCheckbox}
                checked={values.isRootBranch}
              />
              <InputField
                name="parentBranchId"
                disabled={!values.partnerId || values.isRootBranch}
                params={{ partnerId: values.partnerId }}
                placeholderText={NOT_CHOOSED}
                title={PARENT_BRANCH}
                type="select"
                typeSelect="branches"
                prefixSelect="update"
                required={!values.isRootBranch}
                {...inputProps}
              />
              <InputField
                title={FULL_TREE_BRANCH}
                name="treeBranch"
                type="onlyText"
                values={getTreeBranchName(
                  values.parentBranchId,
                  values.branchName,
                  values.isRootBranch,
                )}
              />
              <InputField
                type="searchSelect"
                title={BRANCH_TUTOR}
                name="curatorId"
                data={users}
                value={values.curatorId}
                isFirstRenderLoad
                onLoad={(search, limit) => getUsers({ offset: 0, limit, searchValue: search })}
                countItemWindow={ITEM_COUNT_DROPDOWN_USER_ADMIN}
                onChange={handleChange}
                loading={isLoadingUsers}
              />
              <InputField
                title={BRANCH_ADDRESS}
                name="branchAddress"
                {...inputProps}
              />
              <InputField
                title={CODE_OKATO}
                name="codeOKATO"
                {...inputProps}
                onChange={handleChangeOkato}
              />
              <InputField
                title={INNER_BRANCH_CODE_BANK}
                name="bankCode"
                {...inputProps}
              />
              <InputField
                title={IS_TEST_BRANCH}
                name="isTest"
                type="checkbox"
                onChange={handleChangeCheckbox}
                checked={values.isTest}
              />
              <InputField
                title={MASS_SEGMENT}
                name="massSegment"
                type="checkbox"
                onChange={handleChangeCheckbox}
                checked={values.massSegment}
              />
              <InputField
                type="searchSelect"
                title={CONTRACTS_SOURCE}
                name="ctsSourceCode"
                data={ctsSourcesCodes}
                value={values.ctsSourceCode}
                onChange={handleChange}
              />
              <InputField
                title={SCANS_REQUIRED}
                name="isNeedScans"
                type="checkbox"
                onChange={handleChangeCheckbox}
                checked={values.isNeedScans}
              />
            </div>
            <div className={b('controls')}>
              <MainBtn
                text={REVOKE}
                onClick={() => history.goBack({ cache: true })}
              />
              <MainBtn
                text={isUpdatedForm ? SAVE_WORD : CREATE}
                onClick={handleSubmit}
              />
            </div>
          </form>
        );
      }}
    </Formik>
  );
};

CreatingOrUpdatingBranch.propTypes = propTypes;
CreatingOrUpdatingBranch.defaultProps = defaultProps;
export default CreatingOrUpdatingBranch;
