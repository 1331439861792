const CREATE_PARTNER_REQUEST = 'partnersAdminInterface/CREATE_PARTNER_REQUEST';
const CREATE_PARTNER_SUCCESS = 'partnersAdminInterface/CREATE_PARTNER_SUCCESS';
const CREATE_PARTNER_FAIL = 'partnersAdminInterface/CREATE_PARTNER_FAIL';
const CREATE_PARTNER_START = 'partnersAdminInterface/CREATE_PARTNER_START';

const GET_PARTNERS_FOR_TABLE_REQUEST = 'partnersAdminInterface/GET_PARTNERS_FOR_TABLE_REQUEST';
const GET_PARTNERS_FOR_TABLE_START = 'partnersAdminInterface/GET_PARTNERS_FOR_TABLE_START';
const GET_PARTNERS_FOR_TABLE_SUCCESS = 'partnersAdminInterface/GET_PARTNERS_FOR_TABLE_SUCCESS';
const GET_PARTNERS_FOR_TABLE_FAIL = 'partnersAdminInterface/GET_PARTNERS_FOR_TABLE_FAIL';

const SET_PAGE_PAGINATION = 'partnersAdminInterface/SET_PAGE_PAGINATION';

const GET_PARTNER_DATA_REQUEST = 'partnersAdminInterface/GET_PARTNER_DATA_REQUEST';
const GET_PARTNER_DATA_START = 'partnersAdminInterface/GET_PARTNER_DATA_START';
const GET_PARTNER_DATA_SUCCESS = 'partnersAdminInterface/GET_PARTNER_DATA_SUCCESS';
const GET_PARTNER_DATA_FAIL = 'partnersAdminInterface/GET_PARTNER_DATA_FAIL';

const UPDATE_PARTNER_REQUEST = 'partnersAdminInterface/UPDATE_PARTNER_REQUEST';
const UPDATE_PARTNER_SUCCESS = 'partnersAdminInterface/UPDATE_PARTNER_SUCCESS';
const UPDATE_PARTNER_FAIL = 'partnersAdminInterface/UPDATE_PARTNER_FAIL';
const UPDATE_PARTNER_START = 'partnersAdminInterface/UPDATE_PARTNER_START';

const CLEAR_EDITOR_FORM = 'partnersAdminInterface/CLEAR_EDITOR_FORM';

const ADD_NEW_IP = 'partnersAdminInterface/ADD_NEW_IP';
const DEL_IP = 'partnersAdminInterface/DEL_IP';
const CHANGE_ACCESS = 'partnersAdminInterface/CHANGE_ACCESS';

export {
  CLEAR_EDITOR_FORM,
  UPDATE_PARTNER_REQUEST,
  UPDATE_PARTNER_SUCCESS,
  UPDATE_PARTNER_FAIL,
  UPDATE_PARTNER_START,
  GET_PARTNER_DATA_FAIL,
  GET_PARTNER_DATA_REQUEST,
  GET_PARTNER_DATA_START,
  GET_PARTNER_DATA_SUCCESS,
  SET_PAGE_PAGINATION,
  GET_PARTNERS_FOR_TABLE_REQUEST,
  GET_PARTNERS_FOR_TABLE_START,
  GET_PARTNERS_FOR_TABLE_SUCCESS,
  GET_PARTNERS_FOR_TABLE_FAIL,
  CREATE_PARTNER_FAIL,
  CREATE_PARTNER_SUCCESS,
  CREATE_PARTNER_REQUEST,
  CREATE_PARTNER_START,
  ADD_NEW_IP,
  DEL_IP,
  CHANGE_ACCESS,
};
