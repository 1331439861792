import React, { Component } from 'react';
import { BeneficiariesForm } from 'components';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import moment from 'moment/moment';
import _ from 'lodash';
import {
  setFieldValueBeneficiary,
  getMultipleOptions,
  setActiveTabBeneficiary,
  setStatusTabBeneficiary,
  setErrorsBeneficiary,
  setTouchedBeneficiary,
  setAddressBeneficiary,
  setFmsUnitBeneficiary,
  setLivingAddressIsSameBeneficiary,
  getError,
  setIsBeneficiaries,
  setCountBeneficiaries,
  setAllTouchedBeneficiary,
  saveForms,
  checkAgent,
} from 'redux/rootActions';
import {
  getDataBeneficiariesSelector,
  getRelationshipsSelector,
  getGendersSelector,
  getSnilsError,
  getBeneficiariesListSelector,
  getCountBeneficiaries,
  getIsBeneficiaries,
  getInsuranceAmountErrorSelector,
  getIsLoadingFormSelector,
  getUserInfoSelector,
  getErrorMessageFormSelector,
  getMainRequestId,
  getCheckPassportCovid,
  getBlackAddressInfo,
  getChildRequestIds,
  getCtsIdArr,
  getIsAnnul,
  getIsContractFormed,
} from 'redux/rootSelectors';
import {
  getErrorsFromValidationError,
  getAddress,
  getFmsUnitList,
  replaceFirstChar,
  getGenderByName,
  getTouchedProps,
  simpleDate,
  rebuildBeneficiary,
  checkInsuredIsBeneficiary,
  scrollToError,
  getAgentChecking,
} from 'helpers';
import { withCustomRouter } from 'HOC';
import {
  INPUT_NAMES,
  ENUMS,
  LANG_DICTIONARY, TYPE_PERSON,
} from 'consts';
import {
  mainForm,
  documentForm,
  addressForm,
  contactsForm,
} from './schemes';
import { annulOldConstructor } from '../../../redux/basketConstructor/actions';
import {
  resetCtsIds,
  setAnnulContract,
  setContractFormed,
} from '../../../redux/actions/checkoutActions';

const { SINGULAR_BENEFICIARY } = LANG_DICTIONARY;

const { BENEFICIARY_FORM_ENUM } = ENUMS;

const propTypes = {
  getOptions: PropTypes.func,
  match: PropTypes.object,
  beneficiaries: PropTypes.object,
  childInsuredValues: PropTypes.object,
  setTouched: PropTypes.func,
  setValue: PropTypes.func,
  checkTerror: PropTypes.func,
  setLivingAddressIsSame: PropTypes.func,
  snilsError: PropTypes.string,
  getPropError: PropTypes.func,
  setAddress: PropTypes.func,
  setFmsUnit: PropTypes.func,
  insuredIsSame: PropTypes.bool,
  productValues: PropTypes.object,
  insurerValues: PropTypes.object,
  insuredValues: PropTypes.object,
  saveProduct: PropTypes.func,
  history: PropTypes.object,
  userInfo: PropTypes.object,
  isBeneficiaries: PropTypes.bool,
  insuranceAmmountError: PropTypes.string,
  setCountBeneficiariesByLaw: PropTypes.func,
  setIsBeneficiariesByLaw: PropTypes.func,
  setErrors: PropTypes.func,
  countBeneficiaries: PropTypes.number,
  setAllTouched: PropTypes.func,
  setStageBeneficiary: PropTypes.func,
  stage: PropTypes.number,
  mainRequestId: PropTypes.number,
  scenario: PropTypes.string.isRequired,
  productForm: PropTypes.object.isRequired,
  setNextStage: PropTypes.func.isRequired,
  executionPath: PropTypes.string.isRequired,
  checkPassportCovid: PropTypes.bool,
  isValidTerrorist: PropTypes.bool,
  setStatusTab: PropTypes.func,
  isValidAddress: PropTypes.bool,
  blackAddressInfo: PropTypes.object,
  beneficiaryInLaw: PropTypes.bool,
  childRequestIds: PropTypes.arrayOf(PropTypes.number),
  annulContract: PropTypes.func,
  ctsIds: PropTypes.array,
  setIsContractFormed: PropTypes.func,
  isAnnul: PropTypes.bool,
  setAnnul: PropTypes.func,
  isContractFormed: PropTypes.bool,
  resetIds: PropTypes.func,
  checkAgentAction: PropTypes.func,
  addressCheckingPayload: PropTypes.object,
  productId: PropTypes.number.isRequired,
};

const defaultProps = {
  addressCheckingPayload: {},
  childInsuredValues: {},
  mainRequestId: 0,
  getOptions: () => null,
  match: () => null,
  beneficiaries: {},
  setTouched: () => null,
  setValue: () => null,
  checkTerror: () => null,
  setAllTouched: () => null,
  setLivingAddressIsSame: () => null,
  snilsError: '',
  getPropError: () => null,
  setAddress: () => null,
  setFmsUnit: () => null,
  insuredIsSame: false,
  productValues: {},
  insurerValues: {},
  insuredValues: {},
  saveProduct: () => null,
  history: {},
  userInfo: {},
  isBeneficiaries: false,
  insuranceAmmountError: '',
  setCountBeneficiariesByLaw: () => null,
  setIsBeneficiariesByLaw: () => null,
  setErrors: () => null,
  countBeneficiaries: 1,
  setStageBeneficiary: () => null,
  stage: 0,
  checkPassportCovid: false,
  isValidTerrorist: true,
  setStatusTab: () => null,
  isValidAddress: true,
  blackAddressInfo: {},
  beneficiaryInLaw: false,
  childRequestIds: [],
  annulContract: () => null,
  ctsIds: [],
  setIsContractFormed: () => null,
  isAnnul: false,
  setAnnul: () => null,
  isContractFormed: false,
  resetIds: () => null,
  checkAgentAction: () => null,
};

class Beneficiaries extends Component {
  componentDidMount() {
    const {
      getOptions,
      match: { params: { id } },
    } = this.props;
    getOptions(id);
  }

  validateOnChange = ({
    value,
    name,
    index,
    form,
    values,
    args,
  }) => {
    this.validateForm({
      values: {
        ...values,
        [name]: value,
      },
      index,
      form,
      args,
    });
  }

  setStatusTab = (data) => {
    const {
      setStatusTab,
    } = this.props;

    setStatusTab(data);
  }

  validateInsuredIsInBeneficiary = (values) => {
    const { insuredValues, childInsuredValues } = this.props;
    const insuredObject = insuredValues || {};
    const childInsuredObject = childInsuredValues || {};
    const {
      firstName,
      lastName,
      patronymic: middleName,
      birthDate,
    } = insuredObject;

    const isChildInsured = Object.keys(childInsuredValues).length;
    const {
      firstName: childFirstName,
      lastName: childLastName,
      patronymic: childMiddleName,
      birthDate: childBirthdate,
    } = childInsuredObject;

    const insuredData = {
      firstName,
      lastName,
      middleName,
      birthDate,
    };

    const childInsuredData = {
      childFirstName,
      childLastName,
      childMiddleName,
      childBirthdate,
    };

    return checkInsuredIsBeneficiary(
      values,
      insuredData,
      Boolean(isChildInsured),
      childInsuredData,
    );
  }

  handleTouchedByForm = ({ target: { name } }, form, index) => {
    const { setTouched } = this.props;
    setTouched({
      value: true,
      name,
      form,
      index,
    });
  }

  handleChangeByForm = (
    { target: { value, name } },
    form,
    index,
    values,
    args,
  ) => {
    const { setValue, beneficiaries } = this.props;
    if (name === 'birthDate') {
      const age = moment().diff(value, 'years');
      setValue({
        form: 'Documents',
        index,
        value: age < 18 ? 2 : 1,
        name: 'documentType',
      });
    }

    setValue({
      form,
      index,
      value,
      name,
    });
    this.validateOnChange({
      value,
      name,
      index,
      form,
      values,
      args,
    });

    const { contacts, formDataContacts } = beneficiaries[`beneficiary${index}`];

    if (name === 'documentType' && contacts) {
      this.validateForm({
        values: formDataContacts,
        form: 'Contacts',
        index,
        args: {
          burnDocument: value === 2,
        },
      });
    }

    if (form === BENEFICIARY_FORM_ENUM.documents && name.endsWith(INPUT_NAMES.issuedBy)) {
      setValue({
        form,
        index,
        value: false,
        name: 'issuedByAccepted',
      });

      this.validateForm({
        values: {
          ...values,
          issuedBy: value,
          issuedByAccepted: false,
        },
        index,
        form,
        args,
      });
    }
  }

  handleChangeNameByForm = ({ target: { value, name } }, form, index, values) => {
    const rebuildValue = replaceFirstChar(value);
    const currentValue = { target: { value: rebuildValue, name } };
    this.handleChangeByForm(
      currentValue,
      form,
      index,
      values,
    );
  }

  handleBlurNameByForm = async (form, index, values, e) => {
    const { setValue } = this.props;
    const actualValues = {
      ...values,
    };

    if (e.target) {
      actualValues[e.target.name] = e.target.value;
    }

    const { firstName, middleName, lastName } = actualValues;
    const gender = await getGenderByName({ firstName, midName: middleName, lastName });
    if (gender) {
      setValue({
        form,
        index,
        value: gender,
        name: 'gender',
      });
    }
  }

  setLivingAddressIsSame = (checked, index, values) => {
    const { setLivingAddressIsSame } = this.props;
    setLivingAddressIsSame({ index, status: !checked });
    const valuesForValidation = {
      ...values,
      livingAddressIsSame: !checked,
    };
    this.validateForm({ values: valuesForValidation, index, form: 'Address' });
  }

  handleChangeAddress = (data, index) => {
    const { setAddress } = this.props;
    setAddress({ values: data, index });
  }

  setAddress = (data, index, values, isLiving) => {
    const { setAddress } = this.props;
    const addressValues = getAddress(data, isLiving);
    const addressValuesForValidation = {
      ...values,
      ...addressValues,
    };

    setAddress({ values: addressValues, index });
    this.validateForm({ values: addressValuesForValidation, index, form: 'Address' });
  }

  setFmsUnit = (data, index) => {
    const { setFmsUnit } = this.props;
    const fmsUnitValues = getFmsUnitList(data);

    setFmsUnit({ values: fmsUnitValues, index });
  }

  validateAgent = (next) => {
    const {
      countBeneficiaries,
      beneficiaries,
      addressCheckingPayload,
      checkAgentAction,
      mainRequestId,
      productId,
    } = this.props;

    if (beneficiaries.isBeneficiaries) {
      next();

      return null;
    }

    const selectedBeneficiaries = Object.values(beneficiaries).slice(0, countBeneficiaries);
    const data = selectedBeneficiaries.map((item, index) => ({
      agentName: `${SINGULAR_BENEFICIARY} ${index + 1}`,
      body: {
        agent: getAgentChecking({
          ...item.formData,
          ...(item.documents && item.formDataDocuments),
          ...(item.address && item.formDataAddress),
        }, item.formDataAddress?.livingAddressIsSame),
        ...addressCheckingPayload,
        productId,
        agentType: `${TYPE_PERSON.beneficiary}${index ? index + 1 : ''}`,
        mainRequestId: mainRequestId || undefined,
      },
    }));

    checkAgentAction({
      data,
      next,
    });

    return null;
  }

  handleSubmit = _.debounce(() => {
    const {
      insuredIsSame,
      insurerValues,
      insuredValues,
      childInsuredValues,
      saveProduct,
      history,
      userInfo: {
        branchId,
        partnerId,
      },
      isBeneficiaries,
      insuranceAmmountError,
      executionPath,
      mainRequestId,
      childRequestIds,
      scenario,
      productForm,
      productValues,
      annulContract,
      ctsIds,
      setIsContractFormed,
      isAnnul,
      setAnnul,
      isContractFormed,
      resetIds,
      setNextStage,
      productId,
      match: { params: { id } },
    } = this.props;

    const { countBeneficiaries, beneficiaries, setAllTouched } = this.props;
    const selectedBeneficiaries = Object.values(beneficiaries).slice(0, countBeneficiaries);

    const countErrors = !isBeneficiaries
      && selectedBeneficiaries.reduce((prev, beneficiary, indexItem) => {
        const {
          formData,
          contacts,
          address,
          documents,
          formDataContacts,
          formDataAddress,
          formDataDocuments,
        } = beneficiary;

        const index = indexItem + 1;
        let count = prev;

        count += this.validateForm({
          values: formData,
          index,
          form: '',
          isExecution: true,
        });
        count += this.validateInsuredIsInBeneficiary(formData) ? 1 : 0;
        setAllTouched({
          touched: getTouchedProps(formData),
          index,
          form: '',
          isExecution: true,
        });

        if (contacts) {
          count += this.validateForm({
            values: formDataContacts,
            index,
            form: 'Contacts',
            isExecution: true,
          });
          setAllTouched({
            touched: getTouchedProps(formDataContacts),
            index,
            form: 'Contacts',
            isExecution: true,
          });
        }
        if (address) {
          count += this.validateForm({
            values: formDataAddress,
            index,
            form: 'Address',
            isExecution: true,
          });
          setAllTouched({
            touched: getTouchedProps(formDataAddress),
            index,
            form: 'Address',
            isExecution: true,
          });
        }
        if (documents) {
          count += this.validateForm({
            values: formDataDocuments,
            index,
            form: 'Documents',
            args: {
              birthDate: formData.birthDate,
            },
            isExecution: true,
          });
          setAllTouched({
            touched: getTouchedProps(formDataDocuments),
            index,
            form: 'Documents',
          });
        }

        return count;
      }, 0);

    const isErrorForm = countErrors || insuranceAmmountError;

    if (!isErrorForm || isBeneficiaries) {
      const beneficiariesList = selectedBeneficiaries.map((beneficiary) => {
        const {
          formData,
          contacts,
          address,
          documents,
          formDataContacts,
          formDataAddress,
          formDataDocuments,
        } = beneficiary;

        return rebuildBeneficiary({
          main: formData,
          contacts: formDataContacts,
          isContacts: contacts,
          documents: formDataDocuments,
          isDocuments: documents,
          address: formDataAddress,
          isAddress: address,
        });
      });

      const redirect = {
        history,
        path: `${executionPath}/${id}`,
      };

      const productValuesIsNotCurrScenario = {
        ...productForm,
        currencyRate: undefined,
        currencyRateId: undefined,
      };

      const productValuesForm = {
        insured: {
          ...insuredValues,
          birthDate: simpleDate(insuredValues.birthDate),
          when: simpleDate(insuredValues.when),
          validUntil: simpleDate(insuredValues.validUntil),
        },
        insurer: {
          ...insurerValues,
          birthDate: simpleDate(insurerValues.birthDate),
          when: simpleDate(insurerValues.when),
          validUntil: simpleDate(insurerValues.validUntil),
        },
        childInsured: {
          ...childInsuredValues,
          birthDate: simpleDate(childInsuredValues.birthDate),
          when: simpleDate(childInsuredValues.when),
          validUntil: simpleDate(childInsuredValues.validUntil),
        },
        beneficiary: isBeneficiaries ? [] : beneficiariesList,
        isBeneficiaryByLaw: isBeneficiaries,
        productId,
        insurerIsInsured: insuredIsSame,
        productValues,
        partnerId,
        branchId,
        scenario,
        productForm: productForm.isCurrencyScenario ? productForm : productValuesIsNotCurrScenario,
        mainRequestId,
        childRequestIds,
      };

      setIsContractFormed(true);
      if (isContractFormed && isAnnul) {
        setAnnul(false);
        annulContract(ctsIds);
        resetIds();
      }

      this.validateAgent(() => {
        setNextStage();
        saveProduct(productValuesForm, redirect);
      });
    }

    return null;
  }, 500)

  getSchema = (type, args) => {
    switch (type) {
      case '':
        return mainForm(args);

      case 'Contacts':
        return contactsForm(args);

      case 'Address':
        return addressForm(args);

      case 'Documents':
        return documentForm(args);

      default:
        return null;
    }
  }

  setCountBeneficiaries = (e, { value }) => {
    const { setCountBeneficiariesByLaw } = this.props;
    setCountBeneficiariesByLaw(value);
  }

  setIsBeneficiaries = (name, checked) => {
    const { setIsBeneficiariesByLaw } = this.props;
    setIsBeneficiariesByLaw(!checked);
  }

  validateForm = ({
    values,
    index,
    form = '',
    args,
    isExecution,
  }) => {
    const { setErrors, checkPassportCovid, beneficiaries } = this.props;
    const { documentType } = beneficiaries[`beneficiary${index}`].formDataDocuments;
    const burnDocument = Number(documentType) === 2;
    try {
      const schema = this.getSchema(form, {
        burnDocument,
        checkPassportCovid,
        ...args,
      });
      if (schema) {
        schema.validateSync(values, { abortEarly: false });
        setErrors({ errors: {}, form, index });

        return 0;
      }

      return 0;
    } catch (e) {
      const errors = getErrorsFromValidationError(e);
      setErrors({ errors, form, index });

      if (isExecution) {
        scrollToError(errors, `${form}${index}`);
      }

      return Object.keys(errors).length;
    }
  };

  render() {
    return (
      <BeneficiariesForm
        {...this.props}
        validateForm={this.validateForm}
        setAddress={this.setAddress}
        setFmsUnit={this.setFmsUnit}
        setLivingAddressIsSame={this.setLivingAddressIsSame}
        handleChangeByForm={this.handleChangeByForm}
        handleChangeNameByForm={this.handleChangeNameByForm}
        handleTouchedByForm={this.handleTouchedByForm}
        setCountBeneficiaries={this.setCountBeneficiaries}
        setIsBeneficiaries={this.setIsBeneficiaries}
        handleBlurNameByForm={this.handleBlurNameByForm}
        validateExtremism={this.validateExtremism}
        handleBlurPassportData={this.handleBlurPassportData}
        handleSubmit={this.handleSubmit}
        validateInsuredIsInBeneficiary={this.validateInsuredIsInBeneficiary}
        setStatusTab={this.setStatusTab}
        validateAddress={this.validateAddress}
        handleChangeAddress={this.handleChangeAddress}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  beneficiaries: getDataBeneficiariesSelector(state),
  genders: getGendersSelector(state),
  relationships: getRelationshipsSelector(state),
  snilsError: getSnilsError(state),
  beneficiariesList: getBeneficiariesListSelector(state),
  countBeneficiaries: getCountBeneficiaries(state),
  isBeneficiaries: getIsBeneficiaries(state),
  insuranceAmmountError: getInsuranceAmountErrorSelector(state),
  userInfo: getUserInfoSelector(state),
  isLoading: getIsLoadingFormSelector(state),
  error: getErrorMessageFormSelector(state),
  mainRequestId: getMainRequestId(state),
  childRequestIds: getChildRequestIds(state),
  checkPassportCovid: getCheckPassportCovid(state),
  blackAddressInfo: getBlackAddressInfo(state),
  ctsIds: getCtsIdArr(state),
  isAnnul: getIsAnnul(state),
  isContractFormed: getIsContractFormed(state),
});

const mapDispatchToProps = {
  checkAgentAction: checkAgent,
  setTouched: setTouchedBeneficiary,
  setErrors: setErrorsBeneficiary,
  setValue: setFieldValueBeneficiary,
  getOptions: getMultipleOptions,
  setActiveTab: setActiveTabBeneficiary,
  setStatusTab: setStatusTabBeneficiary,
  setAddress: setAddressBeneficiary,
  setFmsUnit: setFmsUnitBeneficiary,
  setLivingAddressIsSame: setLivingAddressIsSameBeneficiary,
  getPropError: getError,
  setIsBeneficiariesByLaw: setIsBeneficiaries,
  setCountBeneficiariesByLaw: setCountBeneficiaries,
  setAllTouched: setAllTouchedBeneficiary,
  saveProduct: saveForms,
  annulContract: annulOldConstructor,
  setAnnul: setAnnulContract,
  setIsContractFormed: setContractFormed,
  resetIds: resetCtsIds,
};

Beneficiaries.propTypes = propTypes;
Beneficiaries.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(Beneficiaries));
