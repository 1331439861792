import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { Row, Col } from 'reactstrap';
import { Dimmer, Segment, Loader } from 'semantic-ui-react';
import { CreateEntityBtn, FlexTableAdminPartners, SelectsFactory } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  createPartner: PropTypes.func,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  handleSearch: PropTypes.func,
  loadingTableInfo: PropTypes.bool,
};

const defaultProps = {
  createPartner: () => null,
  tableInfo: [],
  handleSearch: () => null,
  loadingTableInfo: false,
};

const b = block('add-partners-form');
const {
  CREATE_PARTNER,
  DOWNLOADING,
} = LANG_DICTIONARY;

const PartersAdminForm = ({
  createPartner,
  tableInfo,
  handleSearch,
  loadingTableInfo,
}) => (
  <div className={b()}>
    <div className={b('controls')}>
      <Row>
        <Col md="3" sm="6">
          <SelectsFactory
            onChange={handleSearch}
            type="partners"
            name="partnerId"
          />
        </Col>
        <Col md="3" sm="6">
          <SelectsFactory
            onChange={handleSearch}
            type="statusActive"
            name="status"
          />
        </Col>
        <Col md="3" sm="6">
          <SelectsFactory
            onChange={handleSearch}
            type="statusSite"
            name="statusSite"
          />
        </Col>
        <Col md="3" sm="6">
          <CreateEntityBtn text={CREATE_PARTNER} onClick={createPartner} />
        </Col>
      </Row>
    </div>
    <Dimmer.Dimmable as={Segment} dimmed={loadingTableInfo}>
      <Dimmer active={loadingTableInfo} inverted>
        <Loader>{`${DOWNLOADING}...`}</Loader>
      </Dimmer>
      <FlexTableAdminPartners data={tableInfo} />
    </Dimmer.Dimmable>
  </div>
);

PartersAdminForm.propTypes = propTypes;
PartersAdminForm.defaultProps = defaultProps;
export default PartersAdminForm;
