import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  userIP: PropTypes.string,
};

const defaultProps = {
  userIP: '',
};

const GetIpAddress = (props) => {
  const { IP_ADDRESS } = LANG_DICTIONARY;
  const { userIP } = props;

  return (
    <div className="iPAddress">
      {`${IP_ADDRESS} ${userIP}`}
    </div>
  );
};
const mapStateToProps = ({ authReducer: { userIP } }) => ({
  userIP,
});

GetIpAddress.propTypes = propTypes;
GetIpAddress.defaultProps = defaultProps;

export default connect(mapStateToProps)(GetIpAddress);
