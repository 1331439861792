import { put, takeLatest } from 'redux-saga/effects';
import { apiCall, getError } from 'helpers';
import { API } from 'consts';
import {
  GET_PARTNERS_OPTIONS_FAIL,
  GET_PARTNERS_OPTIONS_REQUEST,
  GET_PARTNERS_OPTIONS_START,
  GET_PARTNERS_OPTIONS_SUCCESS,
  GET_BRANCHES_OPTIONS_REQUEST,
  GET_BRANCHES_OPTIONS_SUCCESS,
  GET_BRANCHES_OPTIONS_START,
  GET_BRANCHES_OPTIONS_FAIL,
  GET_REPORT_TYPE_OPTIONS_REQUEST,
  GET_REPORT_TYPE_OPTIONS_SUCCESS,
  GET_REPORT_TYPE_OPTIONS_START,
  GET_REPORT_TYPE_OPTIONS_FAIL,
} from '../types';

function* getPartners({ payload: { prefix } }) {
  try {
    yield put({ type: GET_PARTNERS_OPTIONS_START });
    const { data } = yield apiCall({
      url: API.GET_PARTNERS_FOR_SELECT,
      type: 'GET',
    });
    yield put({ type: GET_PARTNERS_OPTIONS_SUCCESS, payload: { data, prefix } });
  } catch (e) {
    yield put({ type: GET_PARTNERS_OPTIONS_FAIL, error: getError(e) });
  }
}

function* getBranches({ payload: { prefix, params } }) {
  try {
    yield put({ type: GET_BRANCHES_OPTIONS_START });
    const { data } = yield apiCall({
      url: API.GET_BRANCHES_FOR_SELECT,
      type: 'GET',
      query: params,
    });
    const rebuildData = data.map(({ partnerId, ...rest }) => ({ ...rest }));
    yield put({ type: GET_BRANCHES_OPTIONS_SUCCESS, payload: { data: rebuildData, prefix } });
  } catch (e) {
    yield put({ type: GET_BRANCHES_OPTIONS_FAIL, error: getError(e) });
  }
}

function* getReportTypes({ payload: { prefix, params } }) {
  try {
    yield put({ type: GET_REPORT_TYPE_OPTIONS_START });
    const { data } = yield apiCall({
      url: API.GET_REPORT_TYPES_FOR_SELECT,
      type: 'GET',
      query: params,
    });
    const newData = data.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
    yield put({ type: GET_REPORT_TYPE_OPTIONS_SUCCESS, payload: { newData, prefix } });
  } catch (e) {
    yield put({ type: GET_REPORT_TYPE_OPTIONS_FAIL, error: getError(e) });
  }
}

function* partnersSaga() {
  yield takeLatest(GET_PARTNERS_OPTIONS_REQUEST, getPartners);
  yield takeLatest(GET_BRANCHES_OPTIONS_REQUEST, getBranches);
  yield takeLatest(GET_REPORT_TYPE_OPTIONS_REQUEST, getReportTypes);
}

export default partnersSaga;
