import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { UploadIcon } from 'components';
import { Loader } from 'semantic-ui-react';
import './styles.scss';

const {
  TASKS_AND_LOCKS,
  CLEAR_LOCKS,
  NOTIFY_CLIENTS_OF_CALCULATED_BASKETS,
} = LANG_DICTIONARY;

const b = block('locking-form');

const propTypes = {
  unlockCheckingDocuments: PropTypes.func,
  loadingUnlock: PropTypes.bool,
  notifyCalcBasket: PropTypes.func,
  loadingNotifyCalcBasket: PropTypes.bool,
};

const defaultProps = {
  unlockCheckingDocuments: () => null,
  notifyCalcBasket: () => null,
  loadingUnlock: false,
  loadingNotifyCalcBasket: false,
};

const LockingForm = ({
  unlockCheckingDocuments,
  loadingUnlock,
  notifyCalcBasket,
  loadingNotifyCalcBasket,
}) => {
  const tasks = [
    {
      loader: loadingUnlock,
      action: unlockCheckingDocuments,
      title: CLEAR_LOCKS,
    },
    {
      title: NOTIFY_CLIENTS_OF_CALCULATED_BASKETS,
      action: notifyCalcBasket,
      loader: loadingNotifyCalcBasket,
    },
  ];

  return (
    <section className={b()}>
      <div className={b('switcher')}><p className={b('tab')}>{TASKS_AND_LOCKS}</p></div>
      <section className={b('tasks')}>
        {tasks.map(({
          loader,
          title,
          action,
        }) => (
          <div className={b('task')}>
            <p className={b('title')}>{title}</p>
            <div className={b('loader')}>
              <Loader active={loader} inline />
            </div>
            <UploadIcon onClick={action} />
          </div>
        ))}
      </section>
    </section>
  );
};

LockingForm.propTypes = propTypes;
LockingForm.defaultProps = defaultProps;
export default LockingForm;
