import {
  GET_VERSION_DP_SUCCESS,
  GET_VERSION_DP_START,
  GET_VERSION_DP_FAIL,
  CLEAR_VERSION_DP,
  SET_STATUS_MODAL_MIGRATIONS,
} from '../types';

const INITIAL_STATE = {
  branch: '',
  hash: '',
  date: '',
  isShowMigrations: false,
  migrations: [],
  isLoading: false,
  isModalMigrations: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_VERSION_DP_START:
      return {
        ...state,
        isLoading: true,
      };
    case GET_VERSION_DP_FAIL:
      return {
        ...state,
        isLoading: false,
      };

    case GET_VERSION_DP_SUCCESS:
      return {
        ...state,
        ...payload.backend,
        ...payload.dbVersion,
        isShowMigrations: Boolean(payload.migrations),
        migrations: payload.migrations,
        isLoading: false,
      };

    case SET_STATUS_MODAL_MIGRATIONS:
      return {
        ...state,
        isModalMigrations: payload,
      };

    case CLEAR_VERSION_DP:
      return INITIAL_STATE;

    default:
      return state;
  }
};
