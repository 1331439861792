import { PRODUCT_VERSION } from 'consts';
import insurer from '../commonData/insurer';
import bankData from '../commonData/bankData';
import insured from '../commonData/insured';
import beneficiaries from '../commonData/beneficiaries';
import insuredChild from '../commonData/insuredChild';

const dataToScenario = {
  [PRODUCT_VERSION.nsz_1]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured,
    beneficiaries,
  },
  [PRODUCT_VERSION.nsz_2]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured,
    beneficiaries,
    insuredChild,
  },
  [PRODUCT_VERSION.nsz_3]: {
    insurer: {
      ...insurer,
      ...bankData,
    },
    insured,
    beneficiaries,
  },
};

export default (scenario) => dataToScenario[scenario];
