import React from 'react';
import { AdministrativeSectionInterface, CreatingOrUpdatingPublicApiForm } from 'components';
import {
  removeIpAddressPublicApi,
  addIpAddressPublicApi,
  changeIpAddressPublicApi,
  changeValueByNamePublicApi,
  getDataForSelectApi,
  getPartnersPublicApi,
  getBranchesPublicApi,
  getUsersPublicApi,
  createNewPublicApi,
  setStatusPopupPublicApi,
  getPublicApiById,
  setInitStatePublicApi,
  regenerateSecretKeyPublicApi,
} from 'redux/rootActions';
import {
  getApiDataSelector,
  getApiSelectDataSelector,
  getOptionsPublicApiSelector,
  getLoadersPublicApiSelector,
  getStatusPopupPublicApiSelector,
} from 'redux/rootSelectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';

const propTypes = {
  active: PropTypes.number.isRequired,
  getDataSelectApi: PropTypes.func.isRequired,
  getPartners: PropTypes.func.isRequired,
  match: PropTypes.object.isRequired,
  getApiById: PropTypes.func.isRequired,
  setInitState: PropTypes.func.isRequired,
  apiData: PropTypes.object.isRequired,
  removeIpAddress: PropTypes.func.isRequired,
  addIpAddress: PropTypes.func.isRequired,
  changeIpAddress: PropTypes.func.isRequired,
  changeValueByName: PropTypes.func.isRequired,
  apiSelectData: PropTypes.arrayOf(PropTypes.object).isRequired,
  options: PropTypes.object.isRequired,
  getBranches: PropTypes.func.isRequired,
  getUsers: PropTypes.func.isRequired,
  loaders: PropTypes.object.isRequired,
  create: PropTypes.func.isRequired,
  isOpenPopup: PropTypes.bool.isRequired,
  setStatusPopup: PropTypes.func.isRequired,
  regenerateSecretKey: PropTypes.func.isRequired,
};

class CreatingOrUpdatingPublicApi extends React.Component {
  componentDidMount() {
    const {
      getDataSelectApi,
      getPartners,
      match: { params: { id } },
      getApiById,
      setInitState,
    } = this.props;
    getDataSelectApi();
    getPartners();

    if (id) {
      getApiById(id);
    } else {
      setInitState();
    }
  }

  render() {
    const {
      active,
      apiData,
      removeIpAddress,
      addIpAddress,
      changeIpAddress,
      changeValueByName,
      apiSelectData,
      options,
      getBranches,
      getUsers,
      loaders,
      create,
      isOpenPopup,
      setStatusPopup,
      match: { params: { id } },
      regenerateSecretKey,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <CreatingOrUpdatingPublicApiForm
          removeIpAddress={removeIpAddress}
          addIpAddress={addIpAddress}
          changeIpAddress={changeIpAddress}
          changeValueByName={changeValueByName}
          apiSelectData={apiSelectData}
          options={options}
          getBranches={getBranches}
          getUsers={getUsers}
          loaders={loaders}
          create={create}
          isOpenPopup={isOpenPopup}
          setStatusPopup={setStatusPopup}
          updateId={id}
          regenerateSecretKey={regenerateSecretKey}
          {...apiData}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = (state) => ({
  apiData: getApiDataSelector(state),
  apiSelectData: getApiSelectDataSelector(state),
  options: getOptionsPublicApiSelector(state),
  loaders: getLoadersPublicApiSelector(state),
  isOpenPopup: getStatusPopupPublicApiSelector(state),
});

const mapDispatchToProps = {
  removeIpAddress: removeIpAddressPublicApi,
  addIpAddress: addIpAddressPublicApi,
  changeIpAddress: changeIpAddressPublicApi,
  changeValueByName: changeValueByNamePublicApi,
  getDataSelectApi: getDataForSelectApi,
  getPartners: getPartnersPublicApi,
  getBranches: getBranchesPublicApi,
  getUsers: getUsersPublicApi,
  create: createNewPublicApi,
  setStatusPopup: setStatusPopupPublicApi,
  getApiById: getPublicApiById,
  setInitState: setInitStatePublicApi,
  regenerateSecretKey: regenerateSecretKeyPublicApi,
};

CreatingOrUpdatingPublicApi.propTypes = propTypes;

export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatingOrUpdatingPublicApi),
);
