import { put, takeLatest } from 'redux-saga/effects';
import {
  apiCall,
  getError,
  simpleDate,
} from 'helpers';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY } from 'consts';
import { ITEM_COUNT_ADMIN_TABLE_PRODUCTS } from 'config';
import { getProductsForTable } from 'redux/rootActions';
import {
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_START,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS,
  GET_PRODUCTS_FOR_ADMIN_INTERFACE_FAIL,
  GET_SELECTS_FOR_PRODUCTS_REQUEST,
  GET_SELECTS_FOR_PRODUCTS_START,
  GET_SELECTS_FOR_PRODUCTS_SUCCESS,
  GET_SELECTS_FOR_PRODUCTS_FAIL,
  SAVE_PRODUCTS_CHANGES_REQUEST,
  SAVE_PRODUCTS_CHANGES_START,
  SAVE_PRODUCTS_CHANGES_SUCCESS,
  SAVE_PRODUCTS_CHANGES_FAIL,
} from '../types';

const {
  CHANGES_SAVE,
} = LANG_DICTIONARY;

function* getTableInfo({ payload }) {
  const { offset, query } = payload;
  const { currentPartner, currentProduct } = query;
  try {
    yield put({ type: GET_PRODUCTS_FOR_ADMIN_INTERFACE_START });
    const { data } = yield apiCall({
      url: API.GET_PRODUCTS_FOR_ADMIN_TABLE,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_PRODUCTS * (offset - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_PRODUCTS,
        productId: currentProduct,
        partnerId: currentPartner,
      },
    });
    yield put({ type: GET_PRODUCTS_FOR_ADMIN_INTERFACE_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PRODUCTS_FOR_ADMIN_INTERFACE_FAIL, error: getError(e) });
  }
}

function* getSelectsInformation({ payload }) {
  try {
    yield put({ type: GET_SELECTS_FOR_PRODUCTS_START });
    const { data: partners } = yield apiCall({
      url: API.GET_PARTNERS_FOR_SELECT,
      type: 'GET',
    });
    if (payload) {
      const { data } = yield apiCall({
        url: `${API.GET_SELECTS_PRODUCTS_LIST}${payload}`,
        type: 'GET',
      });
      const products = data.map((item) => ({
        key: item.productId,
        value: item.productId,
        text: item.productNameFull,
      }));
      yield put({ type: GET_SELECTS_FOR_PRODUCTS_SUCCESS, payload: { products } });
    } else {
      yield put({ type: GET_SELECTS_FOR_PRODUCTS_SUCCESS, payload: { partners } });
    }
  } catch (e) {
    yield put({ type: GET_SELECTS_FOR_PRODUCTS_FAIL, error: getError(e) });
  }
}

function* saveProductsChanges({ payload }) {
  const { body, page, params } = payload;
  const rebuildBody = body ? body.map((item) => ({
    branchName: item.branchName,
    branchTreeName: item.fullTreeName,
    isActive: item.active,
    dateBeg: item.dataStart ? simpleDate(item.dataStart) : undefined,
    dateEnd: item.dataEnd ? simpleDate(item.dataEnd) : undefined,
    id: item.id,
  })) : [];
  try {
    yield put({ type: SAVE_PRODUCTS_CHANGES_START });
    yield apiCall({
      url: API.SAVE_CHANGES_FOR_PRODUCTS,
      type: 'PUT',
      body: rebuildBody,
    });
    yield put({ type: SAVE_PRODUCTS_CHANGES_SUCCESS });
    yield put(getProductsForTable(page, params));
    toastr.success('', CHANGES_SAVE);
  } catch (e) {
    yield put({ type: SAVE_PRODUCTS_CHANGES_FAIL, error: getError(e) });
  }
}

function* productsAdmininistartionSaga() {
  yield takeLatest(GET_SELECTS_FOR_PRODUCTS_REQUEST, getSelectsInformation);
  yield takeLatest(GET_PRODUCTS_FOR_ADMIN_INTERFACE_REQUEST, getTableInfo);
  yield takeLatest(SAVE_PRODUCTS_CHANGES_REQUEST, saveProductsChanges);
}

export default productsAdmininistartionSaga;
