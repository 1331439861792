import {
  GET_EVENTS_MESSAGES_COUNT_REQUEST,
  GET_EVENTS_SCANS_REQUEST,
  GET_NOTIFICATION_REQUEST,
  SET_READED_NOTIFICATIONS,
  POST_READED_NOTIFICATIONS_REQUEST,
} from '../types';

const setNotificationsReaded = (notifications) => ({
  type: POST_READED_NOTIFICATIONS_REQUEST,
  payload: notifications,
});

const setReadedNotifications = (notifications) => ({
  type: SET_READED_NOTIFICATIONS,
  payload: notifications,
});

const getEventsCount = () => ({
  type: GET_EVENTS_MESSAGES_COUNT_REQUEST,
});

const getEventsScansErrors = (page, params) => ({
  type: GET_EVENTS_SCANS_REQUEST,
  payload: {
    page,
    params,
  },
});

const getNotification = (page) => ({
  type: GET_NOTIFICATION_REQUEST,
  payload: page,
});

export {
  setNotificationsReaded,
  setReadedNotifications,
  getNotification,
  getEventsScansErrors,
  getEventsCount,
};
