import React from 'react';
import { useScript } from 'hooks/useScript';
import PropTypes from 'prop-types';
import { TRADING_VIEW_URLS } from '../../tradingViewConfig';
import { reportingConfig } from './config';

const propTypes = {
  tvCode: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

const { reportingUrl, name } = TRADING_VIEW_URLS;

const ReportingWidget = ({ tvCode, code }) => {
  useScript(
    reportingUrl,
    name,
    {
      ...reportingConfig,
      symbol: `${tvCode}:${code}`,
    },
    'tradingview_root_id',
  );

  return (
    <div className="tradingview-widget-container">
      <div id="tradingview_root_id" />
    </div>
  );
};

ReportingWidget.propTypes = propTypes;

export default ReportingWidget;
