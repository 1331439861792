import React, { Component } from 'react';
import PropTypes from 'prop-types';
import block from 'bem-cn';
import _ from 'lodash';
import { Redirect } from 'react-router-dom';
import { getUnitedFrontendParamsSelector } from 'redux/rootSelectors';
import { withCustomRouter } from 'HOC';
import { Breadcrumbs, FundItem } from 'components';
import { Button } from 'semantic-ui-react';
import {
  LANG_DICTIONARY,
  ROUTES,
  INS_LINE_TYPES,
} from 'consts';
import { connect } from 'react-redux';
import {
  resetForms,
  resetErrors,
  loggingPage,
  pushHistoryRoute,
} from 'redux/rootActions';
import { checkObjectEmpty } from 'helpers';
import { checkAccessRoute } from 'roles';
import { toastr } from 'react-redux-toastr';
import './styles.scss';

const { home, signIn, myClients } = ROUTES;

const defaultProps = {
  component: null,
  Component: null,
  fetchProducts: null,
  onReset: null,
  onResetErrors: null,
  history: null,
  active: null,
  subActive: null,
  additionalParams: {},
  match: null,
  id: null,
  stage: 1,
  products: [],
  roles: {},
  breadcrumbs: [],
  subBreadcrumbs: [],
  publicRoute: false,
  token: '',
  logging: () => null,
  title: '',
  infoBlock: {},
  pushHistoryRouteAction: () => null,
  nszStage: -1,
  kszStage: 0,
  masterId: '',
};

const propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  component: PropTypes.any,
  // eslint-disable-next-line react/forbid-prop-types
  Component: PropTypes.any,
  fetchProducts: PropTypes.func,
  onReset: PropTypes.func,
  onResetErrors: PropTypes.func,
  history: PropTypes.object,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  subActive: PropTypes.string,
  additionalParams: PropTypes.object,
  match: PropTypes.object,
  id: PropTypes.string,
  stage: PropTypes.number,
  products: PropTypes.arrayOf(PropTypes.object),
  roles: PropTypes.object,
  breadcrumbs: PropTypes.arrayOf(PropTypes.object),
  subBreadcrumbs: PropTypes.arrayOf(PropTypes.object),
  publicRoute: PropTypes.bool,
  token: PropTypes.string,
  title: PropTypes.string,
  logging: PropTypes.func,
  infoBlock: PropTypes.object,
  pushHistoryRouteAction: PropTypes.func,
  nszStage: PropTypes.number,
  kszStage: PropTypes.number,
  masterId: PropTypes.string,
  isUnitedFront: PropTypes.bool.isRequired,
};

const b = block('formContainer');
const { BACK, NO_ACCESS_PAGE, BACK_WORD } = LANG_DICTIONARY;

const getItem = (products, id) => _.find(products, (item) => item.id === Number(id));

const backToDashboard = async (
  history,
  onReset,
  additionalParams,
) => {
  const { printForm } = additionalParams;

  if (printForm) {
    history.push(myClients, { cash: true, access: true });
  } else {
    history.push(home);
  }
};

const FormContainer = ({
  Component: FormComponent,
  history,
  active,
  subActive,
  additionalParams,
  additionalParams: {
    printForm,
  },
  products,
  onReset,
  onResetErrors,
  stage,
  nszStage,
  kszStage,
  id,
  breadcrumbs,
  subBreadcrumbs,
  infoBlock,
}) => {
  // TODO this _components needed refactoring
  const currentStage = additionalParams.type === INS_LINE_TYPES.ISZ ? stage : nszStage;

  const checkInsLineTypeIsKsz = () => (additionalParams.type === INS_LINE_TYPES.KSZ ?
    kszStage :
    currentStage);

  return (
    <div className={b('content')}>
      <div className={b('content', 'wrap')}>
        <div className={b('content', 'wrap', 'leftBar')}>
          <Button
            type="text"
            className="formContainer__content__wrap__backButton"
            onClick={() => backToDashboard(history, onReset, additionalParams)}
          >
            {printForm ? BACK_WORD : BACK}
          </Button>
          <FundItem data={infoBlock} {...getItem(products, id)} buttonContent />
        </div>
        <div className={b('content', 'wrap', 'formWrap')}>
          <Breadcrumbs
            breadcrumbsList={breadcrumbs}
            active={active}
            onResetErrors={onResetErrors}
            stage={checkInsLineTypeIsKsz()}
          />
          <div className={b('content', 'wrap', 'section')}>
            {subActive && (
              <Breadcrumbs
                breadcrumbsList={subBreadcrumbs}
                active={subActive}
                onResetErrors={onResetErrors}
                stage={checkInsLineTypeIsKsz()}
                subBreadcrumbs
              />
            )}
            <FormComponent additionalParams={additionalParams} />
          </div>
        </div>
      </div>
    </div>
  );
};

class RenderContainer extends Component {
  constructor(props) {
    super(props);
    document.title = props.title;
  }

  componentDidMount() {
    const {
      logging,
      history,
      title,
      history: {
        location: { pathname, state },
      },
      publicRoute,
      roles,
      pushHistoryRouteAction,
    } = this.props;
    if (!publicRoute) {
      logging({
        history,
        title,
        path: pathname,
      });
    }

    const isNotSave = state && state.notSave;
    if (!isNotSave) {
      pushHistoryRouteAction(pathname);
    }
    if (!checkAccessRoute(pathname, roles)) {
      history.push(home);
      toastr.error('', NO_ACCESS_PAGE);
    }
  }

  render() {
    const {
      component: FormComponent,
      history,
      active,
      subActive,
      additionalParams,
      products,
      onReset,
      onResetErrors,
      stage,
      match: { params: { id } },
      roles,
      breadcrumbs,
      subBreadcrumbs,
      publicRoute,
      token,
      infoBlock,
      nszStage,
      kszStage,
      isUnitedFront,
    } = this.props;

    const componentProps = {
      history,
      active,
      subActive,
      Component: FormComponent,
      additionalParams,
      products,
      onReset,
      onResetErrors,
      stage,
      id,
      breadcrumbs,
      subBreadcrumbs,
      infoBlock,
      nszStage,
      kszStage,
      isUnitedFront,
    };
    if (!checkObjectEmpty(roles) && !publicRoute) return null;
    if (!token && !publicRoute) return <Redirect to={signIn} />;

    return (
      <div className={b({ 'united-frontend': isUnitedFront })}>
        {
          additionalParams && additionalParams.isForm
            ? <FormContainer {...componentProps} />
            : <FormComponent active={active} />
        }
      </div>
    );
  }
}

RenderContainer.propTypes = propTypes;
RenderContainer.defaultProps = defaultProps;

FormContainer.propTypes = propTypes;
FormContainer.defaultProps = defaultProps;

function mapStateToProps(state) {
  const {
    checkoutReducer,
    authReducer,
    nszProduct,
    kszProduct,
  } = state;

  return {
    nszStage: nszProduct.stage,
    kszStage: kszProduct.stage,
    ...checkoutReducer,
    ...authReducer,
    ...getUnitedFrontendParamsSelector(state),
  };
}

export default withCustomRouter(connect(
  mapStateToProps,
  {
    onReset: resetForms,
    onResetErrors: resetErrors,
    logging: loggingPage,
    pushHistoryRouteAction: pushHistoryRoute,
  },
)(RenderContainer));
