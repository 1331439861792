import { LANG_DICTIONARY } from 'consts';
import {
  GET_USERS_FOR_TABLE_FAIL,
  GET_USERS_FOR_TABLE_START,
  GET_USERS_FOR_TABLE_SUCCESS,
  SET_PAGE_PAGINATION,
  SEARCH_BY_USERS_NAME,
  GET_USER_DATA_SUCCESS,
  CLEAR_FORM,
  SET_INITIAL_VALUES,
  USER_TOGGLE_CHECKBOX,
  CLEAR_CHECKBOXED_LIST,
  GET_PARTNERS_FOR_USER_SUCCESS,
  GET_BRANCHES_FOR_USER_SUCCESS,
  GET_USERS_ROLES_FOR_SELECT_SUCCESS,
  SELECT_USER_OPTIONS,
  SET_AFFILIATIONS_FOR_USER,
  SET_BRANCHES_BY_PARTNERS,
  GET_LOGIN_FOR_USER_SUCCESS,
  GET_PASSWORD_FOR_USER_SUCCESS,
  SELECT_ALL_USERS,
  SET_WEBSITE_LISTED_USER_TRUE,
  SET_WEBSITE_LISTED_USER_FALSE,
  SET_WEBSITE_LOCKED_USER_TRUE,
  SET_WEBSITE_LOCKED_USER_FALSE,
  GET_EXTRA_SETTING,
  SET_VALUES_FOR_USER,
  SEND_USER_CREDENTIALS,
} from '../types';

const INITIAL_EDITOR_FORM = {
  id: null,
  login: '',
  password: '',
  lastName: '',
  firstName: '',
  middleName: '',
  dateLastlogin: '',
  lastIp: '',
  birthDate: null,
  webSiteListed: false,
  locked: false,
  dateCreate: '',
  dateLock: '',
  dateUnlock: '',
  email: '',
  hash: '',
  mobilePhone: '',
  partnerEnum: '',
  partnerUid: '',
  snils: '',
  startBan: null,
  tempBan: null,
  uuid: '',
  isEmployee: false,
  affiliations: [
    {
      id: 0,
      branch: '',
      partner: '',
      role: '',
      partnerId: null,
      branchId: null,
      roleId: null,
      inheritRole: false,
      activeRole: false,
      isDefault: false,
    },
  ],
};

const INITIAL_STATE = {
  userFormData: INITIAL_EDITOR_FORM,
  loadingTableInfo: false,
  tableInfo: [],
  page: 1,
  loginOrFio: '',
  branchName: '',
  statusSite: '',
  statusUser: true,
  checkedUsers: {},
  partners: [],
  branches: [],
  userspartners: null,
  usersbranches: null,
  usersroles: null,
  partnerbranches: [],
  selectAllUsers: false,
  ExtraSettingsOpen: false,
  totalPages: 1,
  isSendCredentialsLoading: false,
};

const { NOT_CHOOSED } = LANG_DICTIONARY;

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case SET_VALUES_FOR_USER: {
      const { userFormData } = state;
      const { name, value } = payload;

      return {
        ...state,
        userFormData: {
          ...userFormData,
          [name]: value,
        },
      };
    }
    case GET_EXTRA_SETTING: {
      return {
        ...state,
        ExtraSettingsOpen: !state.ExtraSettingsOpen,
      };
    }
    case GET_USERS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loadingTableInfo: false,
        tableInfo: payload.data,
        page: payload.currentPage,
        totalPages: payload.totalPages,
      };

    case GET_USERS_FOR_TABLE_FAIL:
      return {
        ...state,
        loadingTableInfo: false,
      };

    case GET_USERS_FOR_TABLE_START:
      return {
        ...state,
        loadingTableInfo: true,
      };

    case SEARCH_BY_USERS_NAME:
      return {
        ...state,
        tableInfo: state.tableInfo,
        loginOrFio: payload.loginOrFio,
        branchName: payload.branchName,
      };

    case GET_USER_DATA_SUCCESS: {
      const {
        data,
      } = payload;

      return {
        ...state,
        userFormData: {
          id: data.id || '',
          login: data.login || '',
          password: data.password || '',
          lastName: data.lastName || '',
          firstName: data.firstName || '',
          middleName: data.middleName || '',
          dateLastlogin: data.dateLastlogin || '',
          lastIp: data.lastIp || '',
          birthDate: data.birthDate ? new Date(data.birthDate) : null,
          partnerUid: data.partnerUid || '',
          partnerEnum: data.partnerEnum || '',
          webSiteListed: Boolean(data.webSiteListed),
          locked: data.locked || false,
          affiliations: data.affiliations || [],
          dateCreate: data.dateCreate || '',
          dateLock: data.dateLock || '',
          dateUnlock: data.dateUnlock || '',
          email: data.email || '',
          hash: data.hash || '',
          mobilePhone: data.mobilePhone || '',
          snils: data.snils || '',
          startBan: data.startBan || null,
          tempBan: data.tempBan || null,
          uuid: data.uuid || '',
          isEmployee: data.isEmployee || false,
        },
      };
    }

    case CLEAR_FORM:
      return {
        ...state,
        userFormData: {
          ...INITIAL_EDITOR_FORM,
          affiliations: [
            {
              id: null,
              branch: '',
              partner: '',
              role: '',
              partnerId: null,
              branchId: null,
              roleId: null,
              inheritRole: false,
              activeRole: false,
              isDefault: false,
            },
          ],
        },
      };

    case SET_INITIAL_VALUES:
      return INITIAL_STATE;

    case SET_PAGE_PAGINATION:
      return {
        ...state,
        page: payload,
      };

    case USER_TOGGLE_CHECKBOX:
      return {
        ...state,
        checkedUsers: { ...payload },
      };

    case CLEAR_CHECKBOXED_LIST:
      return {
        ...state,
        checkedUsers: {},
      };

    case GET_PARTNERS_FOR_USER_SUCCESS:
      return {
        ...state,
        userspartners: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...payload.data,
        ],
      };

    case GET_BRANCHES_FOR_USER_SUCCESS:
      return {
        ...state,
        [`${payload.prefix}branches`]: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...payload.data,
        ],
      };

    case SELECT_USER_OPTIONS: {
      const { selectType, value, prefix = '' } = payload;

      return {
        ...state,
        [`${prefix}${selectType}Select`]: value,
      };
    }

    case SET_AFFILIATIONS_FOR_USER: {
      const { newAffiliation, values } = payload;

      return {
        ...state,
        userFormData: {
          ...values,
          affiliations: [...newAffiliation],
        },
      };
    }

    case GET_USERS_ROLES_FOR_SELECT_SUCCESS: {
      return {
        ...state,
        usersroles: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...payload,
        ],
      };
    }

    case SET_BRANCHES_BY_PARTNERS: {
      const { affil, values } = payload;

      return {
        ...state,
        userFormData: {
          ...values,
          affiliations: affil,
        },
      };
    }

    case GET_LOGIN_FOR_USER_SUCCESS: {
      const { data, values } = payload;

      return {
        ...state,
        userFormData: {
          ...values,
          login: data,
        },
      };
    }

    case GET_PASSWORD_FOR_USER_SUCCESS: {
      const { data, values } = payload;

      return {
        ...state,
        userFormData: {
          ...values,
          password: data,
        },
      };
    }

    case SELECT_ALL_USERS: {
      return {
        ...state,
        selectAllUsers: payload,
      };
    }

    case SET_WEBSITE_LISTED_USER_TRUE: {
      return {
        ...state,
        userFormData: {
          ...payload,
          webSiteListed: true,
        },
      };
    }

    case SET_WEBSITE_LISTED_USER_FALSE: {
      return {
        ...state,
        userFormData: {
          ...payload,
          webSiteListed: false,
        },
      };
    }

    case SET_WEBSITE_LOCKED_USER_TRUE: {
      return {
        ...state,
        userFormData: {
          ...payload,
          locked: false,
        },
      };
    }

    case SET_WEBSITE_LOCKED_USER_FALSE: {
      return {
        ...state,
        userFormData: {
          ...payload,
          locked: true,
        },
      };
    }

    case SEND_USER_CREDENTIALS.start: {
      return {
        ...state,
        isSendCredentialsLoading: true,
      };
    }

    case SEND_USER_CREDENTIALS.success: {
      const { userFormData } = state;

      return {
        ...state,
        isSendCredentialsLoading: false,
        userFormData: {
          ...userFormData,
          password: '',
        },
      };
    }

    case SEND_USER_CREDENTIALS.fail: {
      return {
        ...state,
        isSendCredentialsLoading: false,
      };
    }

    default:
      return state;
  }
};
