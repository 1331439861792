import React, { useEffect } from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import { PopupContainer, RangeDatePicker } from 'components';
import ExcelIcon from 'assets/images/excel.png';
import './styles.scss';
import { Select } from 'semantic-ui-react';
import {
  getProducts as parseProducts,
  getStatus,
  numberOrStringTypes,
  simpleDate,
} from 'helpers';

const {
  REPORT_NAMING,
  CHOOSE_REPORT,
  DOWNLOAD_SHORT,
  DATE_OF_CONCLUSION_FROM,
  END_DATE,
  STATUS,
  PRODUCT,
} = LANG_DICTIONARY;

const b = block('download-report');
const propTypes = {
  popupIsOpen: PropTypes.bool,
  closePopUp: PropTypes.func,
  downloadReports: PropTypes.arrayOf(PropTypes.object),
  download: PropTypes.func,
  handleChangeDate: PropTypes.func,
  products: PropTypes.arrayOf(PropTypes.object),
  statusList: PropTypes.arrayOf(PropTypes.object),
  ...numberOrStringTypes(
    'productId',
    'statusId',
  ),
};
const defaultProps = {
  popupIsOpen: false,
  closePopUp: () => null,
  downloadReports: [],
  download: () => null,
  handleChangeDate: () => null,
  products: [],
  statusList: [],
};

const DownloadReport = (props) => {
  const {
    closePopUp,
    downloadReports,
    download,
    popupIsOpen,
    handleChangeDate,
    dateCheckBegReport,
    dateCheckEndReport,
    products,
    statusList,
    productIds,
    handleChange,
    statusIds,
    setInitValuesReport,
    showExtendSetting,
  } = props;

  useEffect(() => () => {
    setInitValuesReport();
  }, []);

  useEffect(() => {
    if (!products.length && !statusList.length) showExtendSetting();
  }, []);

  const dateSignBeg = dateCheckBegReport ? simpleDate(dateCheckBegReport) : undefined;

  const dateSignEnd = dateCheckEndReport ? simpleDate(dateCheckEndReport) : undefined;

  const downloadPopUp = (
    <div className={b()}>
      <div className={b('window-reports')}>
        <div className={b('header')}>
          {CHOOSE_REPORT}
        </div>
        <div className={b('titles')}>
          <div className={b('title-name')}>{REPORT_NAMING}</div>
          <div className={b('title-download')}><p>{DOWNLOAD_SHORT}</p></div>
        </div>
        {downloadReports.map(({ reportType, id }) => (
          <div className={b('report-row')} key={id}>
            <div className={b('row-left')}>{reportType}</div>
            <div
              role="button"
              tabIndex={0}
              className={b('row-right')}
              onClick={() => download({
                id,
                reportType,
                dateSignBeg,
                dateSignEnd,
                productIds,
                statusIds,
              })}
            >
              <img src={ExcelIcon} alt="excel" />
            </div>
          </div>
        ))}
      </div>
      <div className={b('window-selects')}>
        <div className={b('header')}>
          Выберите фильтр
        </div>
        <div className={b('selects')}>
          <div className={b('select-date')}>
            <div className={b('input-wrap')}>
              <RangeDatePicker
                values={{
                  dateCheckBegReport,
                  dateCheckEndReport,
                }}
                labelStartDate={DATE_OF_CONCLUSION_FROM}
                labelEndDate={END_DATE}
                onChange={handleChangeDate}
                startDateName="dateCheckBegReport"
                endDateName="dateCheckEndReport"
              />
            </div>
          </div>
          <div>
            <Select
              className={b('select')}
              options={parseProducts(products, true)}
              placeholder={PRODUCT}
              onChange={handleChange}
              value={productIds}
              name="productIdsReport"
              multiple
              clearable
            />
          </div>
          <div>
            <Select
              className={b('select')}
              placeholder={STATUS}
              options={getStatus(statusList, true)}
              value={statusIds}
              onChange={handleChange}
              name="statusIdsReport"
              multiple
              clearable
            />
          </div>
        </div>
      </div>
    </div>
  );

  return (
    <PopupContainer
      style={{ zoom: '98%' }}
      close={closePopUp}
      isOpen={popupIsOpen}
      children={downloadPopUp}
      isCloseButton
    />
  );
};

DownloadReport.propTypes = propTypes;
DownloadReport.defaultProps = defaultProps;
export default DownloadReport;
