import iszGenerator from './iszGenerator';
import nszGenerator from './nszGenerator';
import kszGenerator from './kszGenerator';

const generatorsFactory = (scenario, formType, additionalData) => {
  const iszAction = () => iszGenerator[formType](scenario, additionalData);
  const nszAction = () => nszGenerator[formType](scenario, additionalData);
  const kszAction = () => kszGenerator[formType](scenario, additionalData);

  return {
    iszAction,
    nszAction,
    kszAction,
  };
};

export default generatorsFactory;
