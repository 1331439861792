import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer } from 'components';
import { useSelector, useDispatch } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { closeCheckAgentResult } from 'redux/rootActions';
import './styles.scss';

const {
  ATTENTION,
  AGENT_CHECKING_ERROR,
  AGENTS_CHECKING_ERROR,
} = LANG_DICTIONARY;

const b = block('agent-checking-error-popup');

const AgentCheckingError = () => {
  const result = useSelector(({ validation }) => validation.result);
  const isOpen = useSelector(({ validation }) => validation.isOpenPopup);
  const dispatch = useDispatch();

  return (
    <PopupContainer
      isOpen={isOpen}
      close={() => dispatch(closeCheckAgentResult())}
      isCloseButton
    >
      <div className={b()}>
        <div className={b('header')}>
          <div className={b('header-warning')}>{`${ATTENTION}!`}</div>
          <div className={b('header-text')}>
            {result.length > 1 ? AGENTS_CHECKING_ERROR : AGENT_CHECKING_ERROR}
          </div>
        </div>
        <div>
          {result.map((checking) => (
            <div key={checking.type} className={b('agent')}>
              <div className={b('agent-title')}>
                <p className={b('agent-title-text')}>{checking.type}</p>
              </div>
              {checking.errors.map((error) => (
                <div key={error} className={b('agent-error')}>
                  <div className={b('agent-error-wrap')}>
                    <Icon name="warning sign" className={b('agent-error-icon')} />
                    <p className={b('agent-error-text')}>{error}</p>
                  </div>
                </div>
              ))}
            </div>
          ))}
        </div>
      </div>
    </PopupContainer>
  );
};

export default AgentCheckingError;
