import React, {
  useMemo,
  useState,
  useEffect,
} from 'react';
import { PopupContainer } from 'components/index';
import PropTypes from 'prop-types';
import { FilterView } from './view';

const propTypes = {
  value: PropTypes.string,
  isOpen: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  hints: PropTypes.arrayOf(PropTypes.string),
  onConfirm: PropTypes.func.isRequired,
  onChange: PropTypes.func.isRequired,
  onFocus: PropTypes.func,
  label: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  isResultSearchDefaultOpen: PropTypes.bool,
  isDisableHints: PropTypes.bool.isRequired,
  transform: PropTypes.func.isRequired,
};

const defaultProps = {
  value: '',
  hints: [],
  isResultSearchDefaultOpen: false,
  onFocus: () => null,
};

export const Filter = ({
  value,
  isOpen,
  onClose,
  hints,
  label,
  name,
  onChange,
  onFocus,
  onConfirm,
  isResultSearchDefaultOpen,
  isDisableHints,
  transform,
}) => {
  const [insideValue, setInsideValue] = useState('');

  useEffect(() => {
    setInsideValue(value);
  }, [value]);

  const results = useMemo(() => hints.map((title) => ({
    title,
  })), [hints]);

  const handleSearchChange = ({ target }) => {
    setInsideValue(target.value);
    onChange({ target }, { isDisableHints });
  };

  const handleResultSelect = (e, { result }) => {
    setInsideValue(result.title);
    onChange({ target: { name, value: result.title } }, { isDisableHints });
  };

  const handleFocus = (e) => {
    onFocus(e, { isDisableHints });
  };

  const handleConfirm = (inputName, inputValue) => {
    onClose();
    onConfirm(name, transform(inputValue));
  };

  return (
    <PopupContainer
      isOpen={isOpen}
      close={onClose}
      isCloseButton
      isCloseClickOutside
    >
      <FilterView
        onClose={onClose}
        onConfirm={handleConfirm}
        results={results}
        label={label}
        name={name}
        onFocus={handleFocus}
        onSearchChange={handleSearchChange}
        value={insideValue}
        onResultSelect={handleResultSelect}
        defaultOpen={isResultSearchDefaultOpen}
        showNoResults={false}
      />
    </PopupContainer>
  );
};

Filter.defaultProps = defaultProps;
Filter.propTypes = propTypes;
