import {
  CHANGE_STATUS_SCAN,
  CHANGE_STATUS_SCAN_SELECT,
  GET_CONTRACT_REQUEST,
  SAVE_CHECKED_CONTRACT_REQUEST,
  CHANGE_DESC_SCAN,
  SET_INIT_STATE,
  SET_STATUS_FINISH_CHECK,
} from '../types';

const changeStatusScan = ({
  prop,
  indexPerson,
  indexScan,
  status,
}) => ({
  type: CHANGE_STATUS_SCAN,
  payload: {
    prop,
    indexPerson,
    indexScan,
    status,
  },
});

const changeStatusScanSelect = ({
  prop,
  indexPerson,
  indexScan,
  status,
  saveOnChanged,
}) => ({
  type: CHANGE_STATUS_SCAN_SELECT,
  payload: {
    prop,
    indexPerson,
    indexScan,
    status,
    saveOnChanged,
  },
});

const changeErrorDescription = ({
  prop,
  indexPerson,
  indexScan,
  desc,
}) => ({
  type: CHANGE_DESC_SCAN,
  payload: {
    prop,
    indexPerson,
    indexScan,
    desc,
  },
});

const getContractInfoUnderwriting = (id, history) => ({
  type: GET_CONTRACT_REQUEST,
  payload: id,
  history,
});

const saveCheckedContract = (ctsId, body, isUnlock) => ({
  type: SAVE_CHECKED_CONTRACT_REQUEST,
  payload: { ctsId, body, isUnlock },
});

const setInitState = () => ({
  type: SET_INIT_STATE,
});

const showPopupFinishedCheck = (status) => ({
  type: SET_STATUS_FINISH_CHECK,
  payload: status,
});

export {
  showPopupFinishedCheck,
  setInitState,
  changeErrorDescription,
  changeStatusScanSelect,
  saveCheckedContract,
  getContractInfoUnderwriting,
  changeStatusScan,
};
