import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('constructor-create-button-text');

const {
  LAST_QUERYES_FOR_USER,
} = LANG_DICTIONARY;

const propTypes = {
  text: PropTypes.string.isRequired,
  availableQuantityRequests: PropTypes.oneOfType([PropTypes.number, PropTypes.instanceOf(null)]),
};

const defaultProps = {
  availableQuantityRequests: null,
};

export const ConstructorCreateButtonText = ({
  text,
  availableQuantityRequests,
}) => (
  <div className={b()}>
    {text}
    {availableQuantityRequests !== null &&
      (<div className={b('last-query')}>{`${LAST_QUERYES_FOR_USER} ${availableQuantityRequests}`}</div>)}
  </div>
);

ConstructorCreateButtonText.propTypes = propTypes;
ConstructorCreateButtonText.defaultProps = defaultProps;
