import React, { memo } from 'react';
import { Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY, RU_TYPE_PERSON, ROUTES } from 'consts';
import { getDateForPicker, redirectContract, redirectFactory } from 'helpers';
import PropTypes from 'prop-types';

const propTypes = {
  parentClass: PropTypes.func,
  ctgtsBirthPlace: PropTypes.string,
  ctgtsBirthday: PropTypes.string,
  ctgtsFio: PropTypes.string,
  ctgtsGender: PropTypes.string,
  ctgtsInn: PropTypes.string,
  ctgtsSnils: PropTypes.string,
  ctgtsType: PropTypes.string,
  ctsBranchName: PropTypes.string,
  ctsNum: PropTypes.string,
  ctsPartnerName: PropTypes.string,
  ctsDateCreate: PropTypes.string,
  ctsUserFio: PropTypes.string,
  ctsId: PropTypes.number,
  history: PropTypes.object,
  indexPerson: PropTypes.number,
  productName: PropTypes.string,
};

const defaultProps = {
  parentClass: () => null,
  ctgtsBirthPlace: '',
  ctgtsBirthday: '',
  ctgtsFio: '',
  ctgtsGender: '',
  ctgtsInn: '',
  ctgtsSnils: '',
  ctgtsType: '',
  ctsBranchName: '',
  ctsNum: '',
  ctsPartnerName: '',
  ctsDateCreate: '',
  ctsUserFio: '',
  ctsId: 0,
  history: {},
  indexPerson: 0,
  productName: '',
};

const {
  CONTRACT_DATA,
  PARTNER, SUBDIVISION,
  USER,
  CLIENT_DATA,
  TYPE,
  INN,
  FIO,
  SNILS,
  BIRTH_DATE_SHORT,
  GENDER_SHORT,
  BIRTH_PLACE,
  SIMBOL_NUMBER,
  FROM,
  PRODUCT_NAME,
} = LANG_DICTIONARY;

const ContractData = ({
  parentClass,
  ctgtsBirthPlace,
  ctgtsBirthday,
  ctgtsFio,
  ctgtsGender,
  ctgtsInn,
  ctgtsSnils,
  ctgtsType,
  ctsBranchName,
  ctsNum,
  ctsPartnerName,
  ctsDateCreate,
  ctsUserFio,
  ctsId,
  history,
  indexPerson,
  productName,
}) => (
  <div className={parentClass('contract-data-wrapper')}>
    <section className={parentClass('contract')}>
      <p className={parentClass('block-title')}>{CONTRACT_DATA}</p>
      <div className={parentClass('content')}>
        <div className={parentClass('field')}>
          <p className={parentClass('field-title')}>{SIMBOL_NUMBER}</p>
          <p className={parentClass('field-value')}>{ctsNum}</p>
        </div>
        <div className={parentClass('field')}>
          <p className={parentClass('field-title')}>{FROM}</p>
          <p className={parentClass('field-value')}>{getDateForPicker(ctsDateCreate)}</p>
        </div>
        <div className={parentClass('block-info')}>
          <p>{`${PRODUCT_NAME}: ${productName}`}</p>
          <p>{`${PARTNER}: ${ctsPartnerName}`}</p>
          <p>{`${SUBDIVISION}: ${ctsBranchName}`}</p>
          <p>{`${USER}: ${ctsUserFio}`}</p>
        </div>
        <div
          role="button"
          tabIndex="-1"
          className={parentClass('icon-info')}
          onClick={() => redirectContract({ history, ctsId, backPatch: ROUTES.checkMasterEditor })}
        >
          <Icon name="info" />
        </div>
      </div>
    </section>
    <section className={parentClass('client')}>
      <p className={parentClass('block-title')}>{CLIENT_DATA}</p>
      <div className={parentClass('block-content')}>
        <div className={parentClass('content', { column: true })}>
          <div className={parentClass('field')}>
            <p className={parentClass('field-title', { 'fixed-width': true })}>{TYPE}</p>
            <p className={parentClass('field-value')}>{RU_TYPE_PERSON[ctgtsType]}</p>
          </div>
          <div className={parentClass('field')}>
            <p className={parentClass('field-title', { 'fixed-width': true })}>{INN}</p>
            <p className={parentClass('field-value')}>{ctgtsInn || '-'}</p>
          </div>
        </div>
        <div className={parentClass('content')}>
          <div className={parentClass('client-block')}>
            <div className={parentClass('client-fields-row')}>
              <div className={parentClass('field', { big: true })}>
                <p className={parentClass('field-title')}>{FIO}</p>
                <p className={parentClass('field-value')}>{ctgtsFio}</p>
              </div>
              <div className={parentClass('field')}>
                <p className={parentClass('field-title')}>{GENDER_SHORT}</p>
                <p className={parentClass('field-value')}>{ctgtsGender}</p>
              </div>
              <div className={parentClass('field')}>
                <p className={parentClass('field-title')}>{BIRTH_DATE_SHORT}</p>
                <p className={parentClass('field-value')}>{getDateForPicker(ctgtsBirthday)}</p>
              </div>
            </div>
            <div className={parentClass('client-fields-row')}>
              <div className={parentClass('field')}>
                <p className={parentClass('field-title')}>{SNILS}</p>
                <p className={parentClass('field-value', { width: true })}>{ctgtsSnils || '-'}</p>
              </div>
              <div className={parentClass('field', { big: true })}>
                <p className={parentClass('field-title')}>{BIRTH_PLACE}</p>
                <p className={parentClass('field-value')}>{ctgtsBirthPlace}</p>
              </div>
            </div>
          </div>
          <div
            role="button"
            tabIndex="-1"
            className={parentClass('icon-info')}
            onClick={() => redirectFactory({
              type: ctgtsType,
              history,
              ctsId,
              index: indexPerson,
              backPatch: ROUTES.checkMasterEditor,
            })}
          >
            <Icon name="info" />
          </div>
        </div>
      </div>
    </section>
  </div>
);

ContractData.propTypes = propTypes;
ContractData.defaultProps = defaultProps;
export default memo(ContractData);
