import React from 'react';
import PropTypes from 'prop-types';
import { TextInput } from 'components/_deprecated';
import { LANG_DICTIONARY } from 'consts';
import { checkInn, getInputProps } from 'helpers';

import { Container, Row, Col } from 'reactstrap';

const { INN, SNILS } = LANG_DICTIONARY;

const defaultProps = {
  snilsError: null,
  hideInn: true,
  hideSnils: true,
  validateSnils: () => null,
};

const propTypes = {
  snilsError: PropTypes.string,
  validateSnils: PropTypes.func,
  hideInn: PropTypes.bool,
  hideSnils: PropTypes.bool,
};

const InnSnilsBlock = (props) => {
  const {
    snilsError,
    validateSnils,
    hideInn,
    hideSnils,
  } = props;

  return (
    <Container>
      <Row>
        {!hideInn && (
          <Col md="4" sm="12">
            <TextInput
              name="inn"
              placeholder={INN}
              mask="999999999999"
              wrapClass="validationBox"
              customValidation={checkInn}
              validationSuccess
              {...getInputProps(props, 'inn')}
            />
          </Col>
        )}
        <Col md="4" sm="12">
          {!hideSnils && (
            <TextInput
              name="snils"
              placeholder={SNILS}
              mask="999-999-999 99"
              wrapClass="validationBox"
              customValidation={validateSnils}
              customError={snilsError}
              validationSuccess
              {...getInputProps(props, 'snils')}
            />
          )}
        </Col>
      </Row>
    </Container>
  );
};

InnSnilsBlock.defaultProps = defaultProps;
InnSnilsBlock.propTypes = propTypes;

export default InnSnilsBlock;
