import React from 'react';
import {
  acceptAgreementRulesPartnerChannel,
  clearFormPartnerChannel,
  changeFormPartnerChannel,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import { AgreementPartnerChannel } from 'components';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import _ from 'lodash';

const defaultProps = {
  acceptAgreement: () => null,
  history: {},
  changeForm: () => null,
  partnerRules: {},
  partnerChannelForm: {},
  selectedPartnerId: null,
};

const propTypes = {
  acceptAgreement: PropTypes.func,
  history: PropTypes.object,
  changeForm: PropTypes.func,
  partnerRules: PropTypes.object,
  partnerChannelForm: PropTypes.object,
  selectedPartnerId: PropTypes.number,
};

const AcceptAgreementPartnerChannel = ({
  acceptAgreement,
  history,
  changeForm,
  partnerRules,
  partnerChannelForm,
  selectedPartnerId,
}) => {
  const handleSubmit = () => {
    const isAuth = _.get(history, 'location.state.isAuth', false);
    const ruleIds = Object.values(partnerRules).map((rule) => rule.ruleId);
    acceptAgreement(isAuth, ruleIds, selectedPartnerId);
  };

  return (
    <AgreementPartnerChannel
      handleSubmit={handleSubmit}
      partnerChannelForm={partnerChannelForm}
      changeFormPartnerChannel={changeForm}
      partnerRules={partnerRules}
    />
  );
};

AcceptAgreementPartnerChannel.propTypes = propTypes;
AcceptAgreementPartnerChannel.defaultProps = defaultProps;

function mapStateToProps({
  authReducer: {
    partnerChannelForm,
    partnerRules,
    selectedPartnerId,
  },
}) {
  return {
    partnerChannelForm,
    partnerRules,
    selectedPartnerId,
  };
}

export default withCustomRouter(connect(mapStateToProps, {
  acceptAgreement: acceptAgreementRulesPartnerChannel,
  clearForm: clearFormPartnerChannel,
  changeForm: changeFormPartnerChannel,
})(AcceptAgreementPartnerChannel));
