/* eslint-disable react/no-array-index-key */
import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { CheckCircleOrCloseIcon } from 'components';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import './styles.scss';

const b = block('catalogs-admin-table');

const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.any),
  headers: PropTypes.arrayOf(PropTypes.string),
  editRow: PropTypes.func,
  deleteRow: PropTypes.func,
  accessRowEdit: PropTypes.func,
};

const defaultProps = {
  tableInfo: [],
  headers: [],
  editRow: () => null,
  deleteRow: () => null,
  accessRowEdit: () => null,
};

const checkStringId = (index, row) => index === 0 && typeof row === 'string';

const FlexTableCatalogs = ({
  headers,
  tableInfo,
  editRow,
  deleteRow,
  accessRowEdit,
}) => {
  const getTableHeader = headers.map((item, ind) => (
    <div className={b(ind === 0 ? 'header-row-id' : 'header')} key={ind}>
      <div>
        {item}
      </div>
    </div>
  ));
  const getTableRows = tableInfo.map(({
    editAccess,
    data,
    id,
    deleteStatus,
    cannotSave,
  }, i) => (
    <div className={b('table-row', { edit: editAccess, deleted: deleteStatus, saveError: cannotSave })} key={id}>
      <div
        onClick={() => (deleteStatus ? null : accessRowEdit(i))}
        role="button"
        tabIndex={0}
        className={b('edit')}
      >
        <img
          src={EditIcon}
          alt="edit-icon"
        />
      </div>
      {data.map((row, index) => (
        <div className={b(index === 0 ? 'row-id' : 'row')} key={index}>
          {index === 0 ? <div>{checkStringId(index, row) ? '' : row}</div> : (
            <input
              className={b('input-row')}
              value={checkStringId(index, row) ? '' : row}
              disabled={index === 0 || !editAccess}
              onChange={(e) => editRow(i, index, e.target.value)}
            />
          )}
        </div>
      ))}
      <div
        className={b('row-control')}
        onClick={() => deleteRow(i)}
        role="button"
        tabIndex={0}
      >
        <CheckCircleOrCloseIcon />
      </div>
    </div>
  ));

  return (
    <div className={b()}>
      <div className={b('headers')}>
        <div className={b('edit-header')} />
        {getTableHeader}
        <div className={b('edit-header')} />
      </div>
      {getTableRows}
    </div>
  );
};

FlexTableCatalogs.propTypes = propTypes;
FlexTableCatalogs.defaultProps = defaultProps;
export default withCustomRouter(FlexTableCatalogs);
