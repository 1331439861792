import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import {
  DragList,
  MultiSelect,
  RequiredLabel,
  CustomCheckBox,
} from 'components';
import { Icon, Select } from 'semantic-ui-react';
import { Formik } from 'formik';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('creating-or-updating-report');

const propTypes = {
  initialValues: PropTypes.object,
  history: PropTypes.object,
  handleSubmitSuccess: PropTypes.func,
  isUpdatedForm: PropTypes.bool,
  reportsRoles: PropTypes.arrayOf(PropTypes.object),
  setRoles: PropTypes.func,
  setTags: PropTypes.func,
  partners: PropTypes.arrayOf(PropTypes.object),
  reportTypes: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.arrayOf(PropTypes.object),
  setSelectorOption: PropTypes.func,
  id: PropTypes.number,
  switchCheckBox: PropTypes.func,
  allRoles: PropTypes.arrayOf(PropTypes.object),
  setErrorMultiSelect: PropTypes.func,
  errors: PropTypes.object,
};

const defaultProps = {
  allRoles: [],
  switchCheckBox: () => null,
  id: 0,
  setSelectorOption: () => null,
  setRoles: () => null,
  initialValues: {},
  history: {},
  handleSubmitSuccess: () => null,
  isUpdatedForm: false,
  reportsRoles: [],
  partners: [],
  reportTypes: [],
  tags: [],
  setTags: () => null,
  errors: {},
  setErrorMultiSelect: () => null,
};

const {
  ID,
  PARTNER,
  ACTIVED,
  REVOKE,
  SAVE_WORD,
  CREATE,
  REPORT_TYPE,
  NOT_CHOOSED,
  REQUIRED,
} = LANG_DICTIONARY;

const CreatingOrUpdatingReportForm = ({
  initialValues,
  handleSubmitSuccess,
  history,
  isUpdatedForm,
  reportsRoles,
  setRoles,
  partners,
  reportTypes,
  setSelectorOption,
  tags,
  setTags,
  id,
  allRoles,
  switchCheckBox,
  errors,
  setErrorMultiSelect,
}) => (
  <Formik
    initialValues={initialValues}
    onSubmit={handleSubmitSuccess}
    enableReinitialize
  >
    {({
      values,
      handleSubmit,
    }) => (
      <form className={b()}>
        <div>
          <div className={b('header')}><RequiredLabel /></div>
          <div className={b('content')}>
            <div className={b('id-field')}>
              <p className={b('id-field-header')}>{ID}</p>
              <input className={b('id-field-input')} disabled value={isUpdatedForm ? id : ''} />
            </div>
            <div className={b('partners-select')}>
              <div className={b('partners-select-header')}><p>{PARTNER}</p></div>
              <div className={b('partners-select-selector')}>
                <Select
                  value={values.partnerId}
                  options={partners}
                  placeholder={NOT_CHOOSED}
                  onChange={setSelectorOption(values, 'partnerId')}
                  className={errors.partnerError ? b('error-select').toString() : null}
                />
                <Icon name="write" className="icon-write-select" />
                {errors.partnerError ? <p className={b('error')}>{REQUIRED}</p> : null}
              </div>
            </div>
            <div className={b('partners-select')}>
              <div className={b('partners-select-header')}><p>{REPORT_TYPE}</p></div>
              <div className={b('partners-select-selector')}>
                <Select
                  value={values.reportType}
                  options={reportTypes}
                  placeholder={NOT_CHOOSED}
                  onChange={setSelectorOption(values, 'reportType')}
                  className={errors.reportTypeError ? b('error-select').toString() : null}
                />
                <Icon name="write" className="icon-write-select" />
                {errors.reportTypeError ? <p className={b('error')}>{REQUIRED}</p> : null}
              </div>
            </div>
            <div className={b('draggable-list')}>
              <DragList
                tags={tags}
                reportTags={values.tags}
                values={values}
                setTags={setTags}
                error={errors.reportTags}
              />
            </div>
            <div>
              <MultiSelect
                selectedRoles={values.roles}
                reportsRoles={reportsRoles}
                setRoles={setRoles}
                values={values}
                allRoles={allRoles}
                error={errors.rolesError}
                required={REQUIRED}
                setErrorMultiSelect={setErrorMultiSelect}
              />
            </div>
            <div className={b('active-status')}>
              <div className={b('status-title')}><p>{ACTIVED}</p></div>
              <div className={b('status-checkbox')}>
                <CustomCheckBox
                  name="check"
                  checked={values.isActive}
                  onChange={() => switchCheckBox(values)}
                />
              </div>
            </div>
          </div>
        </div>
        <div className={b('footer')}>
          <button
            className={b('footer-buttons')}
            onClick={() => history.goBack({ cache: true })}
            type="button"
          >
            {REVOKE}
          </button>
          <button
            className={b('footer-buttons')}
            onClick={handleSubmit}
            type="button"
          >
            { isUpdatedForm ? SAVE_WORD : CREATE}
          </button>
        </div>
      </form>
    )}
  </Formik>
);

CreatingOrUpdatingReportForm.propTypes = propTypes;
CreatingOrUpdatingReportForm.defaultProps = defaultProps;
export default CreatingOrUpdatingReportForm;
