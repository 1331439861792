import PropTypes from 'prop-types';

export const ransomType = PropTypes.shape({
  ransomNum: PropTypes.number,
  yearNum: PropTypes.number,
  dateBegin: PropTypes.string,
  dateEnd: PropTypes.string,
  ransomAmount: PropTypes.number,
  ransomAmountSum: PropTypes.number,
});

export const garantsType = PropTypes.shape({
  garantId: PropTypes.number,
  value: PropTypes.number,
  paymentTerm: PropTypes.number,
});
