import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { ENUMS, LANG_DICTIONARY } from 'consts';
import DocumentBlock from '../DocumentBlock';
import AddressBlock from '../AddressBlock';
import ContactsBlock from '../ContactsBlock';
import '../../styles.scss';

const propTypes = {
  type: PropTypes.string,
  documentsFormData: PropTypes.object,
  documentsFormDataTouched: PropTypes.object,
  documentsFormDataErrors: PropTypes.object,
  documents: PropTypes.bool,
  address: PropTypes.bool,
  contacts: PropTypes.bool,
  addressFormData: PropTypes.object,
  addressFormDataTouched: PropTypes.object,
  addressFormDataErrors: PropTypes.object,
  contactsFormData: PropTypes.object,
  index: PropTypes.number,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  setAddress: PropTypes.func,
  setLivingAddressIsSame: PropTypes.func,
  setFmsUnit: PropTypes.func,
  livingAddressIsSame: PropTypes.bool,
  validateSnils: PropTypes.func,
  snilsError: PropTypes.string,
  contactsFormDataErrors: PropTypes.object,
  contactsFormDataTouched: PropTypes.object,
  handleBlurPassportData: PropTypes.func,
  birthDate: PropTypes.object,
  productForm: PropTypes.object,
  userInfo: PropTypes.object,
  formData: PropTypes.object,
  handleChangeAddress: PropTypes.func,
};

const defaultProps = {
  type: '',
  documentsFormData: {},
  documentsFormDataTouched: {},
  documentsFormDataErrors: {},
  documents: false,
  address: false,
  contacts: false,
  addressFormData: {},
  addressFormDataTouched: {},
  addressFormDataErrors: {},
  contactsFormData: {},
  index: 0,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  setAddress: () => null,
  setLivingAddressIsSame: () => null,
  setFmsUnit: () => null,
  livingAddressIsSame: false,
  validateSnils: () => null,
  snilsError: '',
  contactsFormDataErrors: {},
  contactsFormDataTouched: {},
  handleBlurPassportData: {},
  birthDate: null,
  productForm: {},
  userInfo: {},
  formData: {},
  handleChangeAddress: () => null,
};

const { BENEFICIARY_FORM_ENUM } = ENUMS;
const {
  WITHOUT_ADDRESS_DATA,
  WITHOUT_CONTACT_DATA,
  WITHOUT_DOCUMENT_DATA,
} = LANG_DICTIONARY;

const b = block('additional-block');

const AdditionalBlockBeneficiary = ({
  type,
  documentsFormData,
  documentsFormDataTouched,
  documentsFormDataErrors,
  documents,
  address,
  contacts,
  addressFormData,
  addressFormDataTouched,
  addressFormDataErrors,
  contactsFormData,
  index,
  handleChangeByForm,
  handleTouchedByForm,
  setAddress,
  setLivingAddressIsSame,
  setFmsUnit,
  livingAddressIsSame,
  validateSnils,
  snilsError,
  contactsFormDataErrors,
  contactsFormDataTouched,
  handleBlurPassportData,
  birthDate,
  productForm,
  userInfo,
  formData,
  handleChangeAddress,
}) => {
  const additionalProps = {
    index,
    handleChangeByForm,
    handleTouchedByForm,
    type,
  };

  const getBlock = () => {
    switch (type) {
      case BENEFICIARY_FORM_ENUM.documents:
        return documents
          ? (
            <DocumentBlock
              values={documentsFormData}
              errors={documentsFormDataErrors}
              touched={documentsFormDataTouched}
              validateSnils={validateSnils}
              snilsError={snilsError}
              handleBlurPassportData={handleBlurPassportData}
              birthDate={birthDate}
              setFmsUnit={setFmsUnit}
              {...additionalProps}
            />
          ) : WITHOUT_DOCUMENT_DATA;
      case BENEFICIARY_FORM_ENUM.address:
        return address
          ? (
            <AddressBlock
              values={addressFormData}
              errors={addressFormDataErrors}
              touched={addressFormDataTouched}
              setAddress={setAddress}
              index={index}
              setLivingAddressIsSame={setLivingAddressIsSame}
              livingAddressIsSame={livingAddressIsSame}
              isListBlackAddress={productForm.listBlackAddress}
              userInfo={userInfo}
              formData={formData}
              setValues={(newValues) => handleChangeAddress(newValues, index)}
              {...additionalProps}
            />
          )
          : WITHOUT_ADDRESS_DATA;
      case BENEFICIARY_FORM_ENUM.contacts:
        return contacts
          ? (
            <ContactsBlock
              values={contactsFormData}
              touched={contactsFormDataTouched}
              errors={contactsFormDataErrors}
              isRequiredEmailAndMobile={documentsFormData.documentType !== 2}
              {...additionalProps}
            />
          ) : WITHOUT_CONTACT_DATA;
      default:
        return null;
    }
  };

  return (
    <>
      {type && (
        <div className={b()}>
            {getBlock()}
        </div>
      )}
    </>
  );
};

AdditionalBlockBeneficiary.propTypes = propTypes;
AdditionalBlockBeneficiary.defaultProps = defaultProps;
export default AdditionalBlockBeneficiary;
