import {
  GET_PUBLIC_API_LIST,
  SET_INIT_STATE,
  REMOVE_IP_ADDRESS,
  ADD_IP_ADDRESS,
  CHANGE_IP_ADDRESS,
  CHANGE_FIELD_BY_NAME,
  GET_DATA_FOR_SELECT_API,
  GET_PARTNERS,
  GET_BRANCHES,
  GET_USERS,
  CREATE_NEW_PUBLIC_API,
  SET_STATUS_POPUP,
  GET_PUBLIC_API_BY_ID,
  REGENERATE_SECRET_KEY,
} from '../types';

export const getPublicApiList = (currentPage) => ({
  type: GET_PUBLIC_API_LIST.request,
  payload: currentPage,
});

export const setInitStatePublicApi = () => ({
  type: SET_INIT_STATE,
});

export const removeIpAddressPublicApi = (id) => ({
  type: REMOVE_IP_ADDRESS,
  payload: id,
});

export const addIpAddressPublicApi = () => ({
  type: ADD_IP_ADDRESS,
});

export const changeIpAddressPublicApi = (id, value) => ({
  type: CHANGE_IP_ADDRESS,
  payload: { id, value },
});

export const changeValueByNamePublicApi = (name, value) => ({
  type: CHANGE_FIELD_BY_NAME,
  payload: { name, value },
});

export const getDataForSelectApi = () => ({
  type: GET_DATA_FOR_SELECT_API.request,
});

export const getPartnersPublicApi = () => ({
  type: GET_PARTNERS.request,
});

export const getBranchesPublicApi = (payload) => ({
  type: GET_BRANCHES.request,
  payload,
});

export const getUsersPublicApi = ({ partnerId, branchId }) => ({
  type: GET_USERS.request,
  payload: { partnerId, branchId },
});

export const createNewPublicApi = (body, updateId) => ({
  type: CREATE_NEW_PUBLIC_API.request,
  payload: { body, updateId },
});

export const setStatusPopupPublicApi = (status) => ({
  type: SET_STATUS_POPUP,
  payload: status,
});

export const getPublicApiById = (id) => ({
  type: GET_PUBLIC_API_BY_ID.request,
  payload: id,
});

export const regenerateSecretKeyPublicApi = (id) => ({
  type: REGENERATE_SECRET_KEY.request,
  payload: id,
});
