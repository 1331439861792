import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import { FlexTable, CheckCircleOrCloseIcon, CreateEntityBtn } from 'components';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import template from './template';
import './styles.scss';

const { createPublicApi } = ROUTES;

const {
  AVAILABLE_API,
  PARTNER,
  STATUS,
  USER,
  SUBDIVISION,
  ID_ADDRESS,
  API_KEY,
  DESCRIPTION_WORD,
  CREATE_API_KEY,
  NO_RESTRICTION,
} = LANG_DICTIONARY;

const b = block('public-api-admin-table');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
};

const defaultProps = {
  data: [],
  history: {},
};

const headers = [
  '',
  API_KEY,
  DESCRIPTION_WORD,
  PARTNER,
  SUBDIVISION,
  USER,
  AVAILABLE_API,
  ID_ADDRESS,
  STATUS,
];

const FlexTablePublicApi = ({ data, history }) => {
  const getEditIcon = (id) => (
    <div
      onClick={() => history.push(`${ROUTES.updatePublicApi}/${id}`)}
      tabIndex="-1"
      role="button"
      className={b('control-button')}
    >
      <img
        src={EditIcon}
        className={b('edit-icon')}
        alt="edit-icon"
      />
    </div>
  );

  const rebuildData = data
    .map(({
      id,
      apiKey,
      action,
      isActive,
      ipAddress,
      publicApi,
      userLastName,
      userFirstName,
      userMiddleName,
      branchName,
      partner,
    }) => template(headers, [
      getEditIcon(id),
      apiKey,
      action,
      <div className={b('multi-row')}>
        <p className={b('item-row')}>
          {partner}
        </p>
        <p className={b('item-row')}>
          {branchName}
        </p>
        <p className={b('item-row')}>
          {`${userLastName} ${userFirstName} ${userMiddleName || ''}`}
        </p>
      </div>,
      <div className={b('multi-row')}>
        {publicApi
          .map((item) => (
            <p
              key={`${apiKey}${item.id}api`}
              className={b('item-row')}
            >
              {item.description}
            </p>
          ))}
      </div>,
      ipAddress ? (
        <div className={b('multi-row')}>
          {ipAddress.split(',').map((item) => <p className={b('item-row')}>{item}</p>)}
        </div>
      )
        : NO_RESTRICTION,
      <CheckCircleOrCloseIcon status={isActive} />,
    ]));

  return (
    <div className={b()}>
      <div className={b('button-wrapper')}>
        <CreateEntityBtn
          text={CREATE_API_KEY}
          className={b('button')}
          onClick={() => history.push(createPublicApi)}
        />
      </div>
      <FlexTable data={rebuildData} minHeight={60} />
    </div>
  );
};

FlexTablePublicApi.propTypes = propTypes;
FlexTablePublicApi.defaultProps = defaultProps;
export default withCustomRouter(FlexTablePublicApi);
