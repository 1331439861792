import {
  CHECK_TEMPLATE,
  UPLOAD_TEMPLATE,
  SET_TEMPLATE,
  CLEAR_ERRORS,
  CLOSE_TEMPLATE_IS_EXIST_MODAL,
  CLEAR_STORE,
} from '../types';

const INITIAL_STATE = {
  errors: [],
  file: null,
  templateIsExist: false,
  error: '',
  isUploaded: false,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPLOAD_TEMPLATE.start: {
      return {
        ...state,
        isUploaded: false,
      };
    }

    case UPLOAD_TEMPLATE.fail: {
      return {
        ...state,
        error: payload,
        isUploaded: true,
      };
    }

    case UPLOAD_TEMPLATE.success: {
      return {
        ...state,
        errors: payload,
        isUploaded: true,
      };
    }

    case CHECK_TEMPLATE.fail: {
      return {
        ...state,
        error: payload,
      };
    }

    case CHECK_TEMPLATE.success: {
      return {
        ...state,
        templateIsExist: true,
      };
    }

    case CLEAR_ERRORS: {
      return {
        ...state,
        errors: [],
      };
    }

    case SET_TEMPLATE: {
      return {
        ...state,
        file: payload,
      };
    }

    case CLOSE_TEMPLATE_IS_EXIST_MODAL: {
      return {
        ...state,
        templateIsExist: false,
      };
    }

    case CLEAR_STORE: {
      return {
        ...INITIAL_STATE,
      };
    }

    default:
      return state;
  }
};
