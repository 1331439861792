import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';

const {
  REQUIRED,
} = LANG_DICTIONARY;

const b = block('formContainer');

const RequiredLabel = () => (
  <div
    className={b('required-label')}
  >
    <Icon name="write" />
    <p>{` - ${REQUIRED.toLowerCase()}`}</p>
  </div>
);

export default RequiredLabel;
