import React from 'react';
import { Icon } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import PrintIcon from 'assets/images/print-icon.png';
import './styles.scss';
import { connect } from 'react-redux';
import { toastr } from 'react-redux-toastr';
import { actions, checkAccessAction } from 'roles';

const propTypes = {
  handleClickInfoIcon: PropTypes.func,
  handleClickPrintIcon: PropTypes.func,
  ctsId: PropTypes.number,
  roles: PropTypes.object,
};

const defaultProps = {
  handleClickPrintIcon: () => null,
  handleClickInfoIcon: () => null,
  ctsId: 0,
  roles: {},
};

const b = block('controls-my-sales-table');

const ControlsMySalesTable = ({
  handleClickInfoIcon, handleClickPrintIcon, ctsId, roles,
}) => {
  const handleAccessError = () => {
    toastr.error('Доступ для данной роли запрещен');
  };
  const isAccessContractPrint = checkAccessAction(actions.SHOW_CONTRACT_PRINT_DOC, roles);

  const handleAccessContractPrint = isAccessContractPrint
    ? handleClickPrintIcon(ctsId)
    : handleAccessError;

  return (
    <div className={b()}>

      <button
        tabIndex="-1"
        type="button"
        onClick={handleClickInfoIcon(ctsId)}
      >

        <Icon name="info circle" className={b('info-icon').toString()} />
      </button>

      <button
        tabIndex="-1"
        type="button"
        onClick={handleAccessContractPrint}
      >
        <img src={PrintIcon} className={b('print-icon')} alt="print-icon" />
      </button>
    </div>
  );
};

const mapStateToProps = (
  {
    authReducer: {
      roles,
    },
  },
) => ({
  roles,
});

ControlsMySalesTable.propTypes = propTypes;
ControlsMySalesTable.defaultProps = defaultProps;
export default connect(mapStateToProps)(ControlsMySalesTable);
