import {
  put,
  takeLatest,
  debounce,
  select,
} from 'redux-saga/effects';
import _ from 'lodash';
import {
  apiCall,
  getError,
  apiDownload,
} from 'helpers';
import {
  API,
  ROUTES,
  LANG_DICTIONARY,
  CURRENCY_REF,
  CURRENCY_CODE_REF,
} from 'consts';
import { DEFAULT_VALUE_GUARANTEE, BASKET_REPORT_TIMESTAMP } from 'config';
import moment from 'moment';
import { getGlobalHistory } from 'components';
import {
  GET_TABLE_INFO_CONSTRUCTOR_FAIL,
  GET_TABLE_INFO_CONSTRUCTOR_REQUEST,
  GET_TABLE_INFO_CONSTRUCTOR_START,
  GET_TABLE_INFO_CONSTRUCTOR_SUCCESS,
  GET_ASSETS_FOR_CONSTRUCTOR_REQUEST,
  GET_MIN_PAY_REQUEST,
  GET_CONTRACT_PERIODS_REQUEST,
  GET_PAYOUT_TIMES_REQUEST,
  GET_ASSETS_FOR_CONSTRUCTOR_START,
  GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS,
  GET_ASSETS_FOR_CONSTRUCTOR_FAIL,
  GET_MIN_PAY_START,
  GET_MIN_PAY_SUCCESS,
  GET_MIN_PAY_FAIL,
  GET_CONTRACT_PERIODS_START,
  GET_CONTRACT_PERIODS_SUCCESS,
  GET_CONTRACT_PERIODS_FAIL,
  GET_PAYOUT_TIMES_START,
  GET_PAYOUT_TIMES_SUCCES,
  GET_PAYOUT_TIMES_FAIL,
  GET_CALCULATE_COUPON,
  GET_CALCULATE_COUPON_START,
  GET_CALCULATE_COUPON_FAIL,
  GET_CALCULATED_BASKETS_REQUEST,
  GET_CALCULATED_BASKETS_START,
  GET_CALCULATED_BASKETS_FAIL,
  GET_CALCULATED_BASKETS_SUCCESS,
  GET_CHECKOUT_CALCULATED_BASKET_REQUEST,
  GET_CHECKOUT_CALCULATED_BASKET_START,
  GET_CHECKOUT_CALCULATED_BASKET_FAIL,
  GET_USER_BASKETS_REQUESTS_COUNT_REQUEST,
  GET_USER_BASKETS_REQUESTS_COUNT_START,
  GET_USER_BASKETS_REQUESTS_COUNT_FAIL,
  GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS,
  GET_CALCULATE_COUPON_SUCCESS,
  GET_MIN_PAY_FOR_USER_TABLES,
  GET_CHECKOUT_CALCULATED_BASKET_SUCCESS,
  GET_PRODUCTS_GROUPS_START,
  GET_PRODUCTS_GROUPS_SUCCESS,
  GET_PRODUCTS_GROUPS_FAIL,
  GET_PRODUCTS_GROUPS_REQUEST,
  GET_CONSTRUCTOR_SETTINGS_FAIL,
  GET_CONSTRUCTOR_SETTINGS_REQUEST,
  GET_CONSTRUCTOR_SETTINGS_START,
  GET_CONSTRUCTOR_SETTINGS_SUCCESS,
  COPY_REQUEST_DATA,
  GENERATE_XLSX_REPORT,
  GET_SETTINGS_AND_CALC_REQUESTS,
  GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE,
} from '../types';
import { getValueOption, getOptionTypeId } from '../selectors';
import { changeConstructorOptionTypeId } from '../actions';

function* getConstructorSettings({ payload }) {
  try {
    yield put({ type: GET_CONSTRUCTOR_SETTINGS_START });
    const { data } = yield apiCall({
      url: API.GET_CONSTRUCTOR_SETTINGS(payload),
      type: 'GET',
    });
    yield put({ type: GET_CONSTRUCTOR_SETTINGS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CONSTRUCTOR_SETTINGS_FAIL, error: getError(e) });
  }
}

function* getProductGroups({ payload }) {
  try {
    yield put({ type: GET_PRODUCTS_GROUPS_START });
    const { data } = yield apiCall({
      url: `${API.GET_ISZ_CONSTRUCTOR_PRODUCTS_GROUP}${payload}`,
      type: 'GET',
    });
    yield put({ type: GET_PRODUCTS_GROUPS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PRODUCTS_GROUPS_FAIL, error: getError(e) });
  }
}

function* getCheckoutBasket({ payload }) {
  try {
    const {
      baskedId,
      productId,
      history,
      guarantee,
      refIszOptionTypeId,
      currency,
    } = payload;

    yield put({ type: GET_CHECKOUT_CALCULATED_BASKET_START });
    const { data } = yield apiCall({
      url: API.GET_CHECKOUT_CALCULATED_BASKET,
      type: 'POST',
      body: {
        requestId: Number(baskedId),
        productId,
        warrantyLevel: guarantee,
        refIszOptionTypeId,
        currencyCode: currency,
      },
    });
    yield put({ type: GET_CHECKOUT_CALCULATED_BASKET_SUCCESS, payload: data.requestId });
    history.push(`${ROUTES.preliminaryCalculation}/${productId}`);
  } catch (e) {
    yield put({ type: GET_CHECKOUT_CALCULATED_BASKET_FAIL, error: getError(e) });
  }
}
function* getUserRequetsCount() {
  try {
    yield put({ type: GET_USER_BASKETS_REQUESTS_COUNT_START });
    const { data } = yield apiCall({
      url: API.GET_REQUEST_BASKETS_COUNT,
      type: 'GET',
    });
    yield put({ type: GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_USER_BASKETS_REQUESTS_COUNT_FAIL, error: getError(e) });
  }
}

function* calculateCoupon({ payload }) {
  try {
    yield put({ type: GET_CALCULATE_COUPON_START });
    const { data } = yield apiCall({
      url: API.GET_CALCULATE_COUPON,
      type: 'POST',
      body: payload,
    });
    yield put({ type: GET_CALCULATE_COUPON_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CALCULATE_COUPON_FAIL, error: getError(e) });
  }
}

function* getPayouts({ payload }) {
  try {
    yield put({ type: GET_PAYOUT_TIMES_START });
    const { data } = yield apiCall({
      url: API.GET_CONSTRUCTOR_PAYOUTS(payload),
      type: 'GET',
    });
    const rebuildData = data.map(({ id, value }) => (
      {
        key: id,
        value: id,
        text: value,
      }
    ));
    yield put({ type: GET_PAYOUT_TIMES_SUCCES, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_PAYOUT_TIMES_FAIL, error: getError(e) });
  }
}

function* getPeriods({ payload: { currency, id } }) {
  try {
    yield put({ type: GET_CONTRACT_PERIODS_START });
    const { data } = yield apiCall({
      url: API.GET_CONSTRUCTOR_PERIODS(id),
      type: 'GET',
      query: { currency },
    });
    const rebuildData = data.map(({ id: itemId, term }) => (
      {
        key: itemId,
        value: itemId,
        text: term,
      }
    ));
    yield put({ type: GET_CONTRACT_PERIODS_SUCCESS, payload: rebuildData });
  } catch (e) {
    yield put({ type: GET_CONTRACT_PERIODS_FAIL, error: getError(e) });
  }
}

function* getMinPay({ payload }) {
  try {
    const {
      currency,
      assets,
      minPayForTables,
      period,
      refInsDateUnitId,
      basketBarriersText,
    } = payload;
    yield put({ type: GET_MIN_PAY_START });
    const { data } = yield apiCall({
      url: API.GET_MIN_PAY_BY_PARAMETERS,
      type: 'GET',
      query: {
        currency,
        actives: assets,
        term: period,
        basketBarriersText: basketBarriersText || undefined,
        refInsDateUnitId: refInsDateUnitId || undefined,
      },
    });
    if (minPayForTables) {
      yield put({ type: GET_MIN_PAY_FOR_USER_TABLES, payload: data });
    } else {
      yield put({ type: GET_MIN_PAY_SUCCESS, payload: data });
    }
  } catch (e) {
    yield put({ type: GET_MIN_PAY_FAIL, error: getError(e) });
  }
}

function* getAssetsForConstructor({ payload }) {
  try {
    yield put({ type: GET_ASSETS_FOR_CONSTRUCTOR_START });
    const { data } = yield apiCall({
      url: API.GET_ASSETS_CONSTRUCTOR,
      query: { termId: payload },
      type: 'GET',
    });

    yield put({ type: GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_ASSETS_FOR_CONSTRUCTOR_FAIL, error: getError(e) });
  }
}

function* getCurrencyId({ defaultCurrency }) {
  const { settings } = yield select(({ constructorIszInterface }) => constructorIszInterface);
  const currencyValue = settings.isRuble ? CURRENCY_REF.ROUBLE : CURRENCY_REF.DOLLAR;

  return defaultCurrency || currencyValue;
}

function* getInfoForTable({
  payload: {
    id,
    isRequestSetting,
    currencyId: currency,
    refIszOptionTypeId,
  },
}) {
  try {
    if (isRequestSetting) {
      yield getConstructorSettings({ payload: id });
    }
    const currencyId = yield getCurrencyId({ defaultCurrency: currency });
    const valueOption = refIszOptionTypeId || (yield select(getValueOption));

    if (!valueOption) {
      return null;
    }

    yield put({ type: GET_TABLE_INFO_CONSTRUCTOR_START });
    const { data: actual } = yield apiCall({
      url: API.GET_CONSTRUCTOR_INFO_ACTUAL,
      type: 'GET',
      query: {
        productId: id,
        warrantyLevel: DEFAULT_VALUE_GUARANTEE,
        currencyId,
        refIszOptionTypeId: valueOption,
      },
    });
    const { data: popular } = yield apiCall({
      url: API.GET_CONSTRUCTOR_INFO_POPULAR,
      type: 'GET',
      query: {
        productId: id,
        warrantyLevel: DEFAULT_VALUE_GUARANTEE,
        currencyId,
        refIszOptionTypeId: valueOption,
      },
    });

    yield put({
      type: GET_TABLE_INFO_CONSTRUCTOR_SUCCESS,
      payload: {
        readyBaskets: actual,
        regularBaskets: popular,
      },
    });
  } catch (e) {
    yield put({ type: GET_TABLE_INFO_CONSTRUCTOR_FAIL, error: getError(e) });
  }

  return null;
}

function* copyBasket({ payload }) {
  try {
    yield getSettingsForCreatingForm({
      payload: {
        id: payload.insProductId,
        isCopyRequestForm: true,
      },
    });

    yield put({ type: COPY_REQUEST_DATA.start });
    const { data } = yield apiCall({
      url: API.COPY_BASKETS,
      type: 'GET',
      query: {
        ...payload,
      },
    });
    yield getAssetsForConstructor({ payload: data.termId });
    yield getPeriods({ payload: { currency: data.currencyCode, id: payload.insProductId } });
    yield put(changeConstructorOptionTypeId(data.refIszOptionTypeId));

    yield put({ type: COPY_REQUEST_DATA.success, payload: data });
  } catch (e) {
    const status = _.get(e, 'response.status');
    if (status === 404) {
      getGlobalHistory().push(ROUTES.notFound);
    }
    yield put({ type: COPY_REQUEST_DATA.fail, error: getError(e) });
  }
}

function* getCalculatedBaskets({ payload: { productId, currencyId, guarantee } }) {
  try {
    yield put({ type: GET_CALCULATED_BASKETS_START });
    const { data } = yield apiCall({
      url: API.GET_CALCULATED_BASKETS,
      type: 'GET',
      query: {
        productId,
        currencyId,
        warrantyLevel: guarantee,
      },
    });
    yield put({ type: GET_CALCULATED_BASKETS_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_CALCULATED_BASKETS_FAIL, error: getError(e) });
  }
}

function* getSettingsAndCalcRequests({
  payload: {
    currencyId: currency,
    guarantee,
    isRequestSetting,
    id,
    refIszOptionTypeId,
  },
}) {
  try {
    if (isRequestSetting) {
      yield getConstructorSettings({ payload: id });
      const { settings } = yield select(({ constructorIszInterface }) => constructorIszInterface);

      if (settings.hideConstrFull) {
        getGlobalHistory().push(ROUTES.notFound);
      }
    }

    const valueOption = refIszOptionTypeId || (yield select(getValueOption));
    const currencyId = yield getCurrencyId({ defaultCurrency: currency });

    if (valueOption) {
      yield put({ type: GET_SETTINGS_AND_CALC_REQUESTS.start });

      const { data } = yield apiCall({
        url: API.GET_CALCULATED_BASKETS,
        type: 'GET',
        query: {
          productId: id,
          currencyId,
          warrantyLevel: guarantee,
          refIszOptionTypeId: valueOption,
        },
      });

      yield put({
        type: GET_SETTINGS_AND_CALC_REQUESTS.success,
        payload: {
          calcBasket: data,
        },
      });
    }
  } catch (e) {
    yield put({
      type: GET_SETTINGS_AND_CALC_REQUESTS.fail,
      payload: getError(e),
    });
  }
}

function* generateXlsxReport({ payload }) {
  try {
    yield put({ type: GENERATE_XLSX_REPORT.start });
    yield apiDownload({
      url: API.GENERATE_REPORT,
      type: 'POST',
      body: payload,
    }, `${LANG_DICTIONARY.REPORT_BASKETS_NAME}_${moment().format(BASKET_REPORT_TIMESTAMP)}.xlsx`);
    yield put({ type: GENERATE_XLSX_REPORT.success, payload });
  } catch (e) {
    yield put({ type: GENERATE_XLSX_REPORT.fail, error: getError(e) });
  }
}

function* getSettingsForCreatingForm({ payload }) {
  yield getConstructorSettings({ payload: payload.id });
  const { settings } = yield select(({ constructorIszInterface }) => constructorIszInterface);

  if (!payload.isCopyRequestForm) {
    const optionTypeId = getOptionTypeId(settings.optionOptions);
    yield put(changeConstructorOptionTypeId(optionTypeId));
  }

  if (settings.hideConstrFull) {
    getGlobalHistory().push(ROUTES.notFound);
  }

  const currency = settings.isRuble ? CURRENCY_CODE_REF.ROUBLE : CURRENCY_CODE_REF.DOLLAR;

  if (!payload.isCopyRequestForm) {
    yield getPeriods({ payload: { currency, id: payload.id } });
  }
}

function* constructorIszSaga() {
  yield debounce(800, GET_CALCULATE_COUPON, calculateCoupon);
  yield takeLatest(GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE, getSettingsForCreatingForm);
  yield takeLatest(GET_SETTINGS_AND_CALC_REQUESTS.request, getSettingsAndCalcRequests);
  yield takeLatest(GENERATE_XLSX_REPORT.request, generateXlsxReport);
  yield takeLatest(GET_PRODUCTS_GROUPS_REQUEST, getProductGroups);
  yield takeLatest(GET_CHECKOUT_CALCULATED_BASKET_REQUEST, getCheckoutBasket);
  yield takeLatest(GET_USER_BASKETS_REQUESTS_COUNT_REQUEST, getUserRequetsCount);
  yield takeLatest(GET_CALCULATED_BASKETS_REQUEST, getCalculatedBaskets);
  yield takeLatest(GET_TABLE_INFO_CONSTRUCTOR_REQUEST, getInfoForTable);
  yield takeLatest(GET_ASSETS_FOR_CONSTRUCTOR_REQUEST, getAssetsForConstructor);
  yield takeLatest(GET_MIN_PAY_REQUEST, getMinPay);
  yield takeLatest(GET_CONTRACT_PERIODS_REQUEST, getPeriods);
  yield takeLatest(GET_PAYOUT_TIMES_REQUEST, getPayouts);
  yield takeLatest(GET_CONSTRUCTOR_SETTINGS_REQUEST, getConstructorSettings);
  yield takeLatest(COPY_REQUEST_DATA.request, copyBasket);
}

export default constructorIszSaga;
