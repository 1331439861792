const GET_PARTNERS_UNDERWRITING_REQUEST = 'GET_PARTNERS_UNDERWRITING_REQUEST';
const GET_PARTNERS_UNDERWRITING_SUCCESS = 'GET_PARTNERS_UNDERWRITING_SUCCESS';
const GET_PARTNERS_UNDERWRITING_START = 'GET_PARTNERS_UNDERWRITING_START';
const GET_PARTNERS_UNDERWRITING_FAIL = 'GET_PARTNERS_UNDERWRITING_FAIL';

const GET_BRANCHES_UNDERWRITING_REQUEST = 'GET_BRANCHES_UNDERWRITING_REQUEST';
const GET_BRANCHES_UNDERWRITING_START = 'GET_BRANCHES_UNDERWRITING_START';
const GET_BRANCHES_UNDERWRITING_FAIL = 'GET_BRANCHES_UNDERWRITING_FAIL';
const GET_BRANCHES_UNDERWRITING_SUCCESS = 'GET_BRANCHES_UNDERWRITING_SUCCESS';

const GET_ALL_BRANCHES_UNDERWRITING_REQUEST = 'GET_ALL_BRANCHES_UNDERWRITING_REQUEST';
const GET_ALL_BRANCHES_UNDERWRITING_START = 'GET_ALL_BRANCHES_UNDERWRITING_START';
const GET_ALL_BRANCHES_UNDERWRITING_FAIL = 'GET_ALL_BRANCHES_UNDERWRITING_FAIL';
const GET_ALL_BRANCHES_UNDERWRITING_SUCCESS = 'GET_ALL_BRANCHES_UNDERWRITING_SUCCESS';

const GET_USERS_UNDERWRITING_REQUEST = 'GET_USERS_UNDERWRITING_REQUEST';
const GET_USERS_UNDERWRITING_SUCCESS = 'GET_USERS_UNDERWRITING_SUCCESS';
const GET_USERS_UNDERWRITING_START = 'GET_USERS_UNDERWRITING_START';
const GET_USERS_UNDERWRITING_FAIL = 'GET_USERS_UNDERWRITING_FAIL';

const GET_STATUS_UNDERWRITING_REQUEST = 'GET_STATUS_UNDERWRITING_REQUEST';
const GET_STATUS_UNDERWRITING_SUCCESS = 'GET_STATUS_UNDERWRITING_SUCCESS';
const GET_STATUS_UNDERWRITING_START = 'GET_STATUS_UNDERWRITING_START';
const GET_STATUS_UNDERWRITING_FAIL = 'GET_STATUS_UNDERWRITING_FAIL';

const GET_COUNT_UNDERWRITING_REQUEST = 'GET_COUNT_UNDERWRITING_REQUEST';
const GET_COUNT_UNDERWRITING_SUCCESS = 'GET_COUNT_UNDERWRITING_SUCCESS';
const GET_COUNT_UNDERWRITING_START = 'GET_COUNT_UNDERWRITING_START';
const GET_COUNT_UNDERWRITING_FAIL = 'GET_COUNT_UNDERWRITING_FAIL';

const GET_CHECKER_UNDERWRITING_REQUEST = 'GET_CHECKER_UNDERWRITING_REQUEST';
const GET_CHECKER_UNDERWRITING_SUCCESS = 'GET_CHECKER_UNDERWRITING_SUCCESS';
const GET_CHECKER_UNDERWRITING_START = 'GET_CHECKER_UNDERWRITING_START';
const GET_CHECKER_UNDERWRITING_FAIL = 'GET_CHECKER_UNDERWRITING_FAIL';

const GET_STATUS_CHECKING_UNDERWRITING_REQUEST = 'GET_STATUS_CHECKING_UNDERWRITING_REQUEST';
const GET_STATUS_CHECKING_UNDERWRITING_SUCCESS = 'GET_STATUS_CHECKING_UNDERWRITING_SUCCESS';
const GET_STATUS_CHECKING_UNDERWRITING_START = 'GET_STATUS_CHECKING_UNDERWRITING_START';
const GET_STATUS_CHECKING_UNDERWRITING_FAIL = 'GET_STATUS_CHECKING__UNDERWRITING_FAIL';

const GET_CONTRACTS_UNDERWRITING_REQUEST = 'GET_CONTRACTS_UNDERWRITING_REQUEST';
const GET_CONTRACTS_UNDERWRITING_SUCCESS = 'GET_CONTRACTS_UNDERWRITING_SUCCESS';
const GET_CONTRACTS_UNDERWRITING_START = 'GET_CONTRACTS_UNDERWRITING_START';
const GET_CONTRACTS_UNDERWRITING_FAIL = 'GET_CONTRACTS_UNDERWRITING_FAIL';

const GET_STATUS_SCAN_UNDERWRITING_REQUEST = 'GET_STATUS_SCAN_UNDERWRITING_REQUEST';
const GET_STATUS_SCAN_UNDERWRITING_SUCCESS = 'GET_STATUS_SCAN_UNDERWRITING_SUCCESS';
const GET_STATUS_SCAN_UNDERWRITING_START = 'GET_STATUS_SCAN_UNDERWRITING_START';
const GET_STATUS_SCAN_UNDERWRITING_FAIL = 'GET_STATUS_SCAN_UNDERWRITING_FAIL';

const UNLOCK_DOCUMENT_UNDERWRITING_REQUEST = 'UNLOCK_DOCUMENT_UNDERWRITING_REQUEST';
const UNLOCK_DOCUMENT_UNDERWRITING_SUCCESS = 'UNLOCK_DOCUMENT_UNDERWRITING_SUCCESS';
const UNLOCK_DOCUMENT_UNDERWRITING_START = 'UNLOCK_DOCUMENT_UNDERWRITING_START';
const UNLOCK_DOCUMENT_UNDERWRITING_FAIL = 'UNLOCK_DOCUMENT_UNDERWRITING_FAIL';

const SET_CURRENT_SCAN_INDEX = 'SET_CURRENT_SCAN_INDEX';
const SET_INIT_STATE = 'underwriting-master/SET_INIT_STATE';

export {
  SET_INIT_STATE,
  GET_STATUS_CHECKING_UNDERWRITING_FAIL,
  GET_STATUS_CHECKING_UNDERWRITING_REQUEST,
  GET_STATUS_CHECKING_UNDERWRITING_START,
  GET_STATUS_CHECKING_UNDERWRITING_SUCCESS,
  UNLOCK_DOCUMENT_UNDERWRITING_FAIL,
  UNLOCK_DOCUMENT_UNDERWRITING_REQUEST,
  UNLOCK_DOCUMENT_UNDERWRITING_START,
  UNLOCK_DOCUMENT_UNDERWRITING_SUCCESS,
  SET_CURRENT_SCAN_INDEX,
  GET_STATUS_SCAN_UNDERWRITING_REQUEST,
  GET_STATUS_SCAN_UNDERWRITING_SUCCESS,
  GET_STATUS_SCAN_UNDERWRITING_START,
  GET_STATUS_SCAN_UNDERWRITING_FAIL,
  GET_CONTRACTS_UNDERWRITING_FAIL,
  GET_CONTRACTS_UNDERWRITING_REQUEST,
  GET_CONTRACTS_UNDERWRITING_START,
  GET_CONTRACTS_UNDERWRITING_SUCCESS,
  GET_CHECKER_UNDERWRITING_REQUEST,
  GET_CHECKER_UNDERWRITING_SUCCESS,
  GET_CHECKER_UNDERWRITING_START,
  GET_CHECKER_UNDERWRITING_FAIL,
  GET_COUNT_UNDERWRITING_SUCCESS,
  GET_COUNT_UNDERWRITING_FAIL,
  GET_COUNT_UNDERWRITING_REQUEST,
  GET_COUNT_UNDERWRITING_START,
  GET_STATUS_UNDERWRITING_REQUEST,
  GET_STATUS_UNDERWRITING_SUCCESS,
  GET_STATUS_UNDERWRITING_START,
  GET_STATUS_UNDERWRITING_FAIL,
  GET_PARTNERS_UNDERWRITING_FAIL,
  GET_PARTNERS_UNDERWRITING_REQUEST,
  GET_PARTNERS_UNDERWRITING_START,
  GET_PARTNERS_UNDERWRITING_SUCCESS,
  GET_BRANCHES_UNDERWRITING_FAIL,
  GET_BRANCHES_UNDERWRITING_REQUEST,
  GET_BRANCHES_UNDERWRITING_START,
  GET_BRANCHES_UNDERWRITING_SUCCESS,
  GET_USERS_UNDERWRITING_FAIL,
  GET_USERS_UNDERWRITING_REQUEST,
  GET_USERS_UNDERWRITING_START,
  GET_USERS_UNDERWRITING_SUCCESS,
  GET_ALL_BRANCHES_UNDERWRITING_FAIL,
  GET_ALL_BRANCHES_UNDERWRITING_REQUEST,
  GET_ALL_BRANCHES_UNDERWRITING_START,
  GET_ALL_BRANCHES_UNDERWRITING_SUCCESS,
};
