import { toastr } from 'react-redux-toastr';
import { storeLink } from 'index';
import { getEventsCount } from 'redux/rootActions';
import { LANG_DICTIONARY } from 'consts';
import { getSocket } from 'websocket';
import { BASKET_CALCULATED_PERSON } from '../types';

const { BASKET_CALCULATED } = LANG_DICTIONARY;

export default {
  subscribe: () => getSocket().on(BASKET_CALCULATED_PERSON, () => {
    storeLink.dispatch(getEventsCount());
    toastr.success('', BASKET_CALCULATED);
  }),
  unsubscribe: () => getSocket().removeListener(BASKET_CALCULATED_PERSON),
};
