import React from 'react';
import block from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { Button } from 'semantic-ui-react';
import { CurrentTime, GetIpAddress, VersionDp } from 'components';
import { SUPPORT_MAIL } from 'config';
import { downloadManual } from 'redux/rootActions';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('footerPanel');

const defaultProps = {
  dataUserExist: false,
  manualName: '',
  partnerId: 0,
  downloadFileManual: () => null,
};

const propTypes = {
  dataUserExist: PropTypes.bool,
  manualName: PropTypes.string,
  partnerId: PropTypes.number,
  downloadFileManual: PropTypes.func,
};

const { USER_MANUAL, TECH_SUPPORT } = LANG_DICTIONARY;

const FooterPanel = ({
  dataUserExist,
  manualName,
  partnerId,
  downloadFileManual,
}) => (
  <div className={b()}>
    <div className={b('infoBlock')}>
      <GetIpAddress />
      <CurrentTime />
    </div>
    <div className={b('right')}>
      {dataUserExist && (
        <>
          <Button
            className="footerPanel__right__button"
            type="text"
          >
            <VersionDp />
          </Button>
        </>
      )}
      {dataUserExist && manualName && (
        <Button
          className="footerPanel__right__button"
          type="text"
          onClick={() => downloadFileManual(partnerId, manualName)}
        >
          { USER_MANUAL }
        </Button>
      )}
      {dataUserExist && (
        <a
          className={b('tech-support')}
          href={`mailto:${SUPPORT_MAIL}`}
        >
          {TECH_SUPPORT}
        </a>
      )}
    </div>
  </div>
);

const mapStateToProps = ({
  authReducer: {
    dataUserExist,
    partnerId,
  },
  shared: {
    manualName,
  },
}) => ({
  dataUserExist,
  manualName,
  partnerId,
});

const mapDispatchToProps = {
  downloadFileManual: downloadManual,
};

FooterPanel.propTypes = propTypes;
FooterPanel.defaultProps = defaultProps;
export default connect(mapStateToProps, mapDispatchToProps)(FooterPanel);
