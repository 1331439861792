const OPEN_MODAL_HISTORY_SCAN = 'popups/OPEN_MODAL_HISTORY_SCAN';
const CLOSE_MODAL_HISTORY_SCAN = 'popups/CLOSE_MODAL_HISTORY_SCAN';
const OPEN_MODAL_UPLOAD_SCAN = 'popups/OPEN_MODAL_UPLOAD_SCAN';
const CLOSE_MODAL_UPLOAD_SCAN = 'popups/CLOSE_MODAL_UPLOAD_SCAN';
const SET_STATUS_AMOUNT_LIMINT = 'popups/SET_STATUS_AMOUNT_LIMINT';
const CLOSE_ALL_POPUPS = 'popups/CLOSE_ALL_POPUPS';

export {
  CLOSE_ALL_POPUPS,
  SET_STATUS_AMOUNT_LIMINT,
  OPEN_MODAL_HISTORY_SCAN,
  CLOSE_MODAL_HISTORY_SCAN,
  OPEN_MODAL_UPLOAD_SCAN,
  CLOSE_MODAL_UPLOAD_SCAN,
};
