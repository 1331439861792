import AsyncActionFactory from 'helpers/actionsFactory';

const factory = new AsyncActionFactory('kszProduct');

export const GET_PRODUCT_INFO = factory.createAsync('GET_PRODUCT_INFO');
export const CHANGE_FIELD = factory.create('CHANGE_FIELD');
export const CLEAR_STORE_KSZ_PRODUCTS = factory.create('CLEAR_STORE_KSZ_PRODUCTS');
export const SET_STAGE_PRODUCT = factory.create('SET_STAGE_PRODUCT');
export const SAVE_DATA = factory.create('SAVE_DATA');
export const CHECK_ACCOUNT_NUMBERS = factory.createAsync('CHECK_ACCOUNT_NUMBER');
export const COPY_KSZ_CONTRACT = factory.createAsync('COPY_KSZ_CONTRACT');
export const SET_FIELDS_TOUCHED = factory.create('SET_FIELDS_TOUCHED');
export const SET_RESTRICTIONS = factory.create('SET_RESTRICTIONS');
export const FILL_TEST_DATA = factory.create('FILL_TEST_DATA');
export const FILL_TEST_DATA_INSURED_KSZ = factory.create('FILL_TEST_DATA_INSURED_KSZ');
export const FILL_TEST_DATA_BENEFICIARY_KSZ = factory.create('FILL_TEST_DATA_BENEFICIARY_KSZ');
