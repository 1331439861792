import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import {
  MainBtn,
  FlextTableEventsScan,
  PaginationPanel,
  InfoTableConstructorIsz,
} from 'components';
import { Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES } from 'consts';

import TableControls from './tableControls';

import './styles.scss';

const b = block('events-form');
const { home } = ROUTES;

const {
  BACK,
  ERRORS,
  NOTIFICATION_WORD,
} = LANG_DICTIONARY;

const propTypes = {
  scans: PropTypes.arrayOf(PropTypes.object),
  errorScanCount: PropTypes.number,
  history: PropTypes.object,
  active: PropTypes.number,
  handleDownPage: PropTypes.func,
  handleUpPage: PropTypes.func,
  roles: PropTypes.object,
  totalPages: PropTypes.number,
  notificationCount: PropTypes.number,
  notificationsData: PropTypes.arrayOf(PropTypes.object),
  handleChangeTab: PropTypes.func,
  choosedTabErrors: PropTypes.bool,
  choosedTabNotification: PropTypes.bool,
  setReadedNotifications: PropTypes.func,
  getScan: PropTypes.func,
  tableControls: PropTypes.object,
  onTableControlsChange: PropTypes.func,
};

const defaultProps = {
  setReadedNotifications: () => null,
  notificationsData: [],
  handleChangeTab: () => null,
  choosedTabErrors: false,
  choosedTabNotification: false,
  notificationCount: 0,
  totalPages: 1,
  scans: [],
  errorScanCount: 0,
  history: {},
  active: 0,
  roles: {},
  handleDownPage: () => null,
  handleUpPage: () => null,
  getScan: () => null,
  tableControls: {},
  onTableControlsChange: () => null,
};
const EventsForm = ({
  scans,
  errorScanCount,
  history,
  active,
  handleDownPage,
  handleUpPage,
  roles,
  totalPages,
  notificationCount,
  notificationsData,
  handleChangeTab,
  choosedTabErrors,
  choosedTabNotification,
  setReadedNotifications,
  getScan,
  tableControls,
  onTableControlsChange,
}) => {
  const rebuildDataNotifications = notificationsData.map(({
    basketAssets,
    basketBarriers,
    mainRequestId,
    coupon,
    currency,
    dateEnd,
    freqPaymentTermUnit,
    termUnit,
  }) => ({
    barriers: basketBarriers,
    basket: basketAssets,
    coupon,
    currency,
    dateEnd,
    id: mainRequestId,
    pay: freqPaymentTermUnit,
    term: termUnit,
    status: 'calculated',
  }));

  const onTableControlsSubmit = () => {
    if (choosedTabErrors) {
      getScan(1);
    }
  };

  return (
    <section className={b()}>
      <section className={b('content')}>
        <div className={b('controls')}>
          <MainBtn
            text={BACK}
            className={b('back-btn').toString()}
            onClick={() => history.push(home)}
          />
          <div className={b('pages')}>
            <div className={b('current-page')} onClick={() => handleChangeTab('errors')} role="button" tabIndex={-1}>
              <Icon name="warning sign" />
              <p className={b('title-page')}>{ERRORS}</p>
              <p className={b('error-count')}>{errorScanCount}</p>
            </div>
            <div className={b('current-page')} onClick={() => handleChangeTab('notifies')} role="button" tabIndex={-1}>
              <p className={b('title-page')}>{NOTIFICATION_WORD}</p>
              <p className={b('error-count')}>{notificationCount}</p>
            </div>
          </div>
        </div>
        <div className={b('table')}>
          <TableControls
            choosedTabErrors={choosedTabErrors}
            choosedTabNotification={choosedTabNotification}
            controls={tableControls}
            onControlsChange={onTableControlsChange}
            onControlsSubmit={onTableControlsSubmit}
          />
          {choosedTabErrors && <FlextTableEventsScan data={scans} roles={roles} />}
          {choosedTabNotification && (
          <InfoTableConstructorIsz
            data={rebuildDataNotifications}
            haveStatus
            notificationTable
            setReadedNotifications={setReadedNotifications}
          />
          )}
        </div>
      </section>
      <PaginationPanel
        active={active}
        handleUpPage={handleUpPage}
        handleDownPage={handleDownPage}
        total={totalPages}
      />
    </section>
  );
};
EventsForm.propTypes = propTypes;
EventsForm.defaultProps = defaultProps;
export default EventsForm;
