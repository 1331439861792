const getFormatScanData = (scans) => (scans ? scans.map(({
  checkErrorCode,
  checkErrorDescription,
  checkStatusCode,
  fileScanCheckId,
}) => ({
  checkErrorCode: checkErrorCode || undefined,
  checkErrorDescription: checkErrorDescription || undefined,
  checkStatusCode,
  fileId: fileScanCheckId,
})) : []);

const filterChanged = (scans) => (scans ? scans.filter(({ onChanged }) => onChanged) : []);

const getScansManyPerson = (persons) => persons && persons
  .reduce((arr, person) => ([
    ...arr,
    ...person.scans,
  ]), []);

export default (data, filter = true) => {
  const {
    contractData,
    beneficiaryData,
    insurerData,
    insuredData,
  } = data;

  const { scans: contractScans } = contractData;
  const { scans: insurerScans } = insurerData;

  const rebuildInsurerScans = filter ? filterChanged(insurerScans) : insurerScans;
  const rebuildContractScans = filter ? filterChanged(contractScans) : contractScans;
  const rebuildInsuredScans = filter
    ? filterChanged(getScansManyPerson(insuredData)) : getScansManyPerson(insuredData);
  const rebuildBeneficiaryScans = filter
    ? filterChanged(getScansManyPerson(beneficiaryData)) : getScansManyPerson(beneficiaryData);

  const arrayScans = [
    ...rebuildBeneficiaryScans,
    ...rebuildInsuredScans,
    ...rebuildContractScans,
    ...rebuildInsurerScans,
  ];

  return getFormatScanData(arrayScans);
};
