import React from 'react';
import { block } from 'bem-cn';
import {
  BUILD_DATE,
  GIT_HASH,
  GIT_BRANCH,
} from 'config';
import { connect } from 'react-redux';
import { PropTypes } from 'prop-types';
import { Popup } from 'semantic-ui-react';
import { setStatusModalMigrations } from 'redux/rootActions';
import { getFormattedDate } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import moment from 'moment';
import './styles.scss';

const {
  VERSION_SITE,
  VERSION_SERVER,
  VERSION_BASE,
  OPEN_MIGRATION_TABLE,
  VERSION_DP,
} = LANG_DICTIONARY;

const propTypes = {
  branch: PropTypes.string,
  hash: PropTypes.string,
  date: PropTypes.string,
  isShowMigrations: PropTypes.bool,
  setStatusModal: PropTypes.func,
  update_date: PropTypes.string,
  version: PropTypes.string,
};

const defaultProps = {
  branch: '',
  hash: '',
  date: '',
  isShowMigrations: false,
  setStatusModal: () => null,
  update_date: '',
  version: '',
};

const b = block('version-dp');

const getDate = (date) => moment(date, 'DD-MM-YYYY').format('DD.MM.YYYY');
const getVersion = (date, hash, branch) => moment(date, 'DD-MM-YYYY').isValid() && `${getDate(date)}/${hash}/${branch}`;
const frontVersion = getVersion(BUILD_DATE, GIT_HASH, GIT_BRANCH);

const VersionDp = ({
  branch,
  hash,
  date,
  isShowMigrations,
  setStatusModal,
  // FIX BACKEND PROP
  // eslint-disable-next-line
  update_date: updateDate,
  version,
}) => (
  <Popup
    hoverable
    position="top center"
    trigger={(
      <div className={b('right__button')} type="text">
        { VERSION_DP }
      </div>
    )}
    children={(
      <div className={b()}>
        <table>
          <tbody>
            <tr>
              <td>
                {`${VERSION_SITE}:`}
              </td>
              <td>
                {frontVersion}
              </td>
            </tr>
            <tr>
              <td>
                {`${VERSION_SERVER}:`}
              </td>
              <td>
                {getVersion(date, hash, branch)}
              </td>
            </tr>
            <tr>
              <td>
                {`${VERSION_BASE}:`}
              </td>
              <td>
                {`${getFormattedDate(updateDate)}/${version}`}
              </td>
            </tr>
          </tbody>
        </table>
        <div className={b('controls')}>
          {isShowMigrations && (
          <div
            tabIndex={-1}
            role="button"
            className={b('button')}
            onClick={() => setStatusModal(true)}
          >
            {OPEN_MIGRATION_TABLE}
          </div>
          )}
        </div>
      </div>
    )}
  />
);

VersionDp.propTypes = propTypes;
VersionDp.defaultProps = defaultProps;

const mapStateToProps = ({
  versionDp: {
    branch,
    date,
    hash,
    isShowMigrations,
    version,
    // eslint-disable-next-line
    update_date,
  },
}) => ({
  branch,
  hash,
  date,
  isShowMigrations,
  version,
  update_date,
});

export default connect(mapStateToProps, { setStatusModal: setStatusModalMigrations })(VersionDp);
