import React from 'react';
import { AdministrativeSectionInterface, PublicApiListForm } from 'components';
import { getPublicApiList } from 'redux/rootActions';
import {
  getPublicApiListSelector,
  getCurrentPageApiPublicSelector,
  getTotalPagesApiPublicSelector,
} from 'redux/rootSelectors';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';

const propTypes = {
  getApi: PropTypes.func.isRequired,
  active: PropTypes.number.isRequired,
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  currentPage: PropTypes.number.isRequired,
  totalPages: PropTypes.number.isRequired,
  location: PropTypes.object.isRequired,
};

class PublicApiList extends React.Component {
  componentDidMount() {
    const {
      getApi,
      location: { state },
      currentPage,
    } = this.props;
    const isCache = state && state.cache;
    if (!isCache) {
      getApi(1);
    } else {
      getApi(currentPage);
    }
  }

  handleDownPage = () => {
    const {
      currentPage,
      getApi,
    } = this.props;
    if (currentPage > 1) {
      getApi(currentPage - 1);
    }
  }

  handleUpPage = () => {
    const {
      currentPage,
      getApi,
      totalPages,
    } = this.props;
    if (currentPage !== totalPages) {
      getApi(currentPage + 1);
    }
  }

  render() {
    const {
      active,
      data,
      currentPage,
      totalPages,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <PublicApiListForm
          data={data}
          currentPage={currentPage}
          totalPages={totalPages}
          handleDownPage={this.handleDownPage}
          handleUpPage={this.handleUpPage}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = (state) => ({
  data: getPublicApiListSelector(state),
  currentPage: getCurrentPageApiPublicSelector(state),
  totalPages: getTotalPagesApiPublicSelector(state),
});

const mapDispatchToProps = {
  getApi: getPublicApiList,
};

PublicApiList.propTypes = propTypes;

export default withCustomRouter(connect(mapStateToProps, mapDispatchToProps)(PublicApiList));
