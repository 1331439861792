import { LANG_DICTIONARY } from 'consts';

const {
  DIGITAL_PLATFORM,
  INVESTMENT_WORLD,
  UNITED_FRONT,
  SELECT_ALL,
} = LANG_DICTIONARY;

export const exportTypeAll = 'all';

export const selectedExportTypes = [
  {
    key: 'united_frontend',
    value: 'united_frontend',
    text: UNITED_FRONT,
  },
  {
    key: 'investment_world',
    value: 'investment_world',
    text: INVESTMENT_WORLD,
  },
  {
    key: 'inside_contracts',
    value: 'inside_contracts',
    text: DIGITAL_PLATFORM,
  },
];

export const exportTypes = [
  {
    key: exportTypeAll,
    value: exportTypeAll,
    text: SELECT_ALL,
  },
  ...selectedExportTypes,
];

export const valuesWithOutAll = selectedExportTypes.map((type) => type.value);
