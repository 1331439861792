import React from 'react';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import './styles.scss';

const propTypes = {
  status: PropTypes.bool,
};

const defaultProps = {
  status: false,
};

const b = block('check-circle-or-close-icon');

const CheckCircleOrCloseIcon = ({ status }) => (
  <div className={b()}>
    { status ? <Icon name="check circle" /> : <Icon name="close" />}
  </div>
);

CheckCircleOrCloseIcon.propTypes = propTypes;
CheckCircleOrCloseIcon.defaultProps = defaultProps;
export default CheckCircleOrCloseIcon;
