import {
  CLEAR_FILTERS_PRODUCTS, GET_ADMIN_OPTIONS,
  GET_FILTER_OPTIONS_REQUEST,
  SEARCH_PRODUCT_BY_FILTER_REQUEST, SEARCH_REPORT_OPTIONS_REQUEST,
  SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST,
} from '../types';

const getFilterOptions = () => ({
  type: GET_FILTER_OPTIONS_REQUEST,
});

const searchProducts = (page, info) => ({
  type: SEARCH_PRODUCT_BY_FILTER_REQUEST,
  payload: { page, info },
});

const searchStatusProducts = (page, info) => ({
  type: SEARCH_STATUS_PRODUCT_BY_FILTER_REQUEST,
  payload: { page, info },
});

const clearFilterProducts = () => ({
  type: CLEAR_FILTERS_PRODUCTS,
});

const getAdminOptions = () => ({
  type: GET_ADMIN_OPTIONS,
});

const getReportOptions = () => ({
  type: SEARCH_REPORT_OPTIONS_REQUEST,
});

export {
  getAdminOptions,
  getFilterOptions,
  searchProducts,
  searchStatusProducts,
  clearFilterProducts,
  getReportOptions,
};
