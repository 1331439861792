import { all } from 'redux-saga/effects';
import { commonSaga } from './common';
import { formCreatingSaga } from './formCreating';
import { tablesSaga } from './tables';

function* basketConstructor() {
  yield all([
    commonSaga(),
    formCreatingSaga(),
    tablesSaga(),
  ]);
}

export default basketConstructor;
