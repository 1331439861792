import React, { useEffect, useMemo, useState } from 'react';
import { Dropdown, Icon } from 'semantic-ui-react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const b = block('custom-multi-select');
const { NOT_FOUND } = LANG_DICTIONARY;

const propTypes = {
  options: PropTypes.arrayOf(PropTypes.object),
  value: PropTypes.arrayOf(PropTypes.oneOfType([PropTypes.number, PropTypes.string])),
  maxItems: PropTypes.number,
  isSearch: PropTypes.bool,
  renderLabel: PropTypes.func,
  onChange: PropTypes.func,
  disabled: PropTypes.bool,
  error: PropTypes.string,
  required: PropTypes.bool,
  onLabelClick: PropTypes.func,
  className: PropTypes.string,
  name: PropTypes.string,
};

const defaultProps = {
  options: [],
  value: [],
  maxItems: Number.MAX_SAFE_INTEGER,
  renderLabel: undefined,
  isSearch: false,
  onChange: () => null,
  disabled: false,
  error: '',
  required: false,
  onLabelClick: () => null,
  className: '',
  name: '',
};

const CustomMultiSelect = ({
  options,
  isSearch,
  renderLabel,
  onChange,
  maxItems,
  value,
  disabled,
  error,
  required,
  onLabelClick,
  className,
  name,
}) => {
  const [flag, setFlag] = useState(true);
  const caseSensitiveSearch = (data, query) => data
    .filter((opt) => opt.title.toLowerCase().includes(query.toLowerCase()));

  const optionsObject = useMemo(() => options.reduce((acc, item) => ({
    ...acc,
    [item.value]: item,
  }), {}), [options]);

  useEffect(() => {
    if (value?.length && name && Object.keys(optionsObject).length && flag) {
      const values = value.filter((item) => optionsObject[item]);
      onChange({}, { name, value: values });
      setFlag(false);
    }
  }, [optionsObject, value, name]);

  return (
    <div className={b.mix(className)}>
      <Dropdown
        options={options}
        selection
        multiple
        renderLabel={renderLabel}
        search={isSearch ? caseSensitiveSearch : undefined}
        value={value}
        onLabelClick={(e, data) => {
          onLabelClick(e, {
            ...data,
            selectedOption: optionsObject[data.value],
          });
        }}
        name={name}
        onChange={(e, data) => {
          if (data.value.length <= maxItems || data.value.length < value.length) {
            onChange(e, data);
          }
        }}
        noResultsMessage={NOT_FOUND}
        disabled={disabled}
        error={Boolean(error)}
      />
      {required && <Icon name="write" className="icon-write-select" />}
      <p className={b('error')}>{error}</p>
    </div>
  );
};

CustomMultiSelect.propTypes = propTypes;
CustomMultiSelect.defaultProps = defaultProps;
export default CustomMultiSelect;
