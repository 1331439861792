import moment from 'moment';
import {
  DATE_CHECK_PASSPORT_COVID_END,
  DATE_CHECK_PASSPORT_COVID_START,
  DATE_FORMAT,
  ERRORS,
  LANG_DICTIONARY,
  MASK,
  REGEXP,
  SYSTEM_DATE_FROM,
  SYSTEM_DATE_TO,
  AGES,
} from 'consts';
import { string } from 'yup';
import { toastr } from 'react-redux-toastr';
import { email } from '@hapi/address';

const {
  POINT_IS_REPEATED,
} = REGEXP;
const {
  PASSPORT_EXPIRED,
} = LANG_DICTIONARY;
const { ERROR_VALID_DATE } = ERRORS;
const { distributingDateValidator } = MASK;

const DATE_FOR_CHECK_PASSPORT_EXPIRE = '2021-01-11';

const rebuildErrors = (errors, errorToDelete) => {
  const errorsArray = Object.entries(errors);
  const newErrorsArray = errorsArray.filter((item) => item[0] !== errorToDelete);
  const newErrors = newErrorsArray.reduce((acc, error) => ({
    ...acc,
    [error[0]]: error[1],
  }), {});

  return newErrors;
};

const validateAdditionalRisk = (variables, value) => {
  const { minWeight, maxWeight } = variables;

  return value >= minWeight && value <= maxWeight;
};

const checkForRiskHelpMessage = (variables) => {
  const { Z, T } = variables;

  return Z === T;
};

const validatePaymentFreq = (paymentFreqRestriction, value) => {
  const {
    minValue,
    minType,
    maxValue,
    maxType,
  } = paymentFreqRestriction;
  const firstCondition = comparisonValues(value, minValue, minType);
  const secondCondition = comparisonValues(value, maxValue, maxType);

  return firstCondition && secondCondition;
};

const getRegularEnterError = (variables) => {
  const {
    minValue,
    minType,
    maxValue,
    maxType,
  } = variables;

  return `Размер регулярного взноса должен быть ${minType} ${minValue} и ${maxType} ${maxValue}`;
};

const validateInsuranceSum = (insuranceVariables, value) => {
  const {
    Smin,
    Smax,
  } = insuranceVariables;

  return value >= Smin && value <= Smax;
};

const validateNszBithDate = (value, birthDateVariables) => {
  const {
    TMin,
    B1Max,
    B1Min,
    C1Min,
    C1Max,
    D1Min,
    D1Max,
  } = birthDateVariables;

  const a1 = moment(moment().add(1, 'days')).diff(value, 'years');

  return (a1 + TMin <= B1Max) &&
    a1 >= B1Min &&
    a1 >= C1Min &&
    a1 <= C1Max &&
    a1 + TMin >= D1Min &&
    a1 + TMin <= D1Max;
};

const validateNszBirthDateChild = (value, birthDateVariables) => {
  const {
    TMin,
    B2Max,
    B2Min,
    C2Max,
    D2Min,
    C2Min,
    D2Max,
  } = birthDateVariables;
  const a2 = moment(moment().add(1, 'days')).diff(value, 'years');

  return (a2 + TMin <= B2Max) &&
    a2 >= B2Min &&
    a2 >= C2Min &&
    a2 <= C2Max &&
    a2 + TMin >= D2Min &&
    a2 + TMin <= D2Max;
};

const getErrorsFromValidationError = (validationError) => {
  const FIRST_ERROR = 0;

  return validationError.inner.reduce((errors, error) => ({
    ...errors,
    [error.path]: error.errors[FIRST_ERROR],
  }), {});
};

const getValidationError = (validationError) => ({
  [validationError.path]: validationError.errors[0],
});

const checkInn = (value) => /\d{12}/.test(value);

const onlyNumbers = (value) => (value ? value.replace(/\D+/g, '') : value);

const removeLeadingZeros = (value) => (value ? value.replace(/^0+(?=\d)/, '') : value);

const checkOkatoCode = (value) => onlyNumbers(value).length >= 8 && onlyNumbers(value).length <= 11;

const validateRs = (rs, bik) => {
  const bikRs = bik.toString().slice(-3) + rs;
  const coefficients = [7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1, 3, 7, 1];
  const checksum = coefficients.reduce((sum, item, index) => sum + item * (bikRs[index] % 10), 0);

  return checksum % 10 === 0;
};

const checkCurrencyRs = (rs) => rs.toString().slice(5, 8) === '810';

/* eslint-disable */
const checkSnils = (value) => {
  let snils = onlyNumbers(value);
  let result = false;
  let error = null;

  if (typeof snils === 'number') {
    snils = snils.toString();
  } else if (typeof snils !== 'string') {
    snils = '';
  }
  if (snils.length && snils.length === 11) {
    let sum = 0;
    for (let i = 0; i < 9; i++) {
      sum += parseInt(snils[i]) * (9 - i);
    }
    let checkDigit = 0;

    if (sum < 100) {
      checkDigit = sum;
    } else if (sum > 101) {
      checkDigit = parseInt(sum % 101);
      if (checkDigit === 100) {
        checkDigit = 0;
      }
    }
    if (checkDigit === parseInt(snils.slice(-2))) {
      result = true;
    } else {
      error = 'Неправильное контрольное число';
    }
  }

  return { result, error };
};
/* eslint-enable */

const checkSnilsForYup = (value) => {
  const { result } = checkSnils(value);

  return Boolean(result);
};

const stringToMoment = (date) => {
  if (date) {
    return moment(moment(date, SYSTEM_DATE_FROM).format(SYSTEM_DATE_TO));
  }

  return moment(moment().format(SYSTEM_DATE_TO));
};

const yearDiff = (dateOne, dateTwo) => dateOne.diff(dateTwo, 'year');

const checkDateInCovidPeriod = (date) => {
  const covidFrom = moment(DATE_CHECK_PASSPORT_COVID_START);
  const covidTo = moment(DATE_CHECK_PASSPORT_COVID_END);

  return moment(date).isBetween(covidFrom, covidTo, null, '[]');
};

const checkPassportDate = (withChildren, isValidation, checkPassportCovid) => ({
  birthDate,
  when,
}) => {
  // TODO transfer functionality to yup
  if (!isValidation && (!birthDate || !when)) return null;

  const valid = checkPassportExpired(birthDate, when, checkPassportCovid);

  return valid ? null : PASSPORT_EXPIRED;
};

const checkGetPassportEqualBirthDate = (
  ageInYears,
  PassportDate,
  minDateShouldMore,
  maxDateShouldMore,
  lastDateToFirstChangeCrossed,
  lastDateToSecondChangeCrossed,
) => {
  const isEqualValidation = PassportDate.isAfter(moment(DATE_FOR_CHECK_PASSPORT_EXPIRE));
  if (isEqualValidation) {
    const minDateExpired = ageInYears >= 20
      && PassportDate <= minDateShouldMore
      && lastDateToFirstChangeCrossed;
    const maxDateExpired = ageInYears >= 45
      && PassportDate <= maxDateShouldMore
      && lastDateToSecondChangeCrossed;

    return {
      minDateExpired,
      maxDateExpired,
    };
  }

  const minDateExpired = ageInYears >= 20
    && PassportDate < minDateShouldMore
    && lastDateToFirstChangeCrossed;
  const maxDateExpired = ageInYears >= 45
    && PassportDate < maxDateShouldMore
    && lastDateToSecondChangeCrossed;

  return {
    minDateExpired,
    maxDateExpired,
  };
};

const checkPassportExpired = (birthDate, when, checkPassportCovid) => {
  const date = moment(birthDate).format(DATE_FORMAT);
  const BirthDate = stringToMoment(date);
  const PassportDate = stringToMoment(when);
  const currentDate = stringToMoment();
  const ageInYears = yearDiff(currentDate, BirthDate);

  const childBirthDate = moment(BirthDate).add(AGES.FIRST_PASSPORT_GET_YEARS, 'year');
  const minBirthDate = moment(BirthDate).add(AGES.SECOND_PASSPORT_GET_YEARS, 'year');
  const maxBirthDate = moment(BirthDate).add(AGES.LAST_PASSPORT_GET_YEARS, 'year');

  const lastDateToFirstChange = moment(BirthDate).add(AGES.SECOND_PASSPORT_GET_YEARS, 'years').add(90, 'days');
  const lastDateToSecondChange = moment(BirthDate).add(AGES.LAST_PASSPORT_GET_YEARS, 'years').add(90, 'days');
  const lastDateToFirstChangeCrossed = currentDate.isAfter(lastDateToFirstChange);
  const lastDateToSecondChangeCrossed = currentDate.isAfter(lastDateToSecondChange);

  const childMinDateExpired = moment(PassportDate).isSameOrBefore(childBirthDate);
  const {
    minDateExpired,
    maxDateExpired,
  } = checkGetPassportEqualBirthDate(
    ageInYears,
    PassportDate,
    minBirthDate,
    maxBirthDate,
    lastDateToFirstChangeCrossed,
    lastDateToSecondChangeCrossed,
  );
  const birthDateInCovidPeriod = checkDateInCovidPeriod(minBirthDate) ||
      checkDateInCovidPeriod(maxBirthDate) ||
      checkDateInCovidPeriod(childBirthDate);

  const isExpired = !(checkPassportCovid && birthDateInCovidPeriod) && (
    minDateExpired || maxDateExpired || childMinDateExpired);

  return !isExpired;
};

const checkPassportNotValid = (birthDate) => {
  const years = moment().diff(birthDate, 'years');

  return years >= 14;
};

function yupCheckIssuedByAccepted() {
  const { issuedByAccepted } = this.parent;

  return issuedByAccepted;
}

const yupEmpty = (validation, message) => (
  string().notRequired().test('ifEmpty', message, (value) => {
    if (value) {
      return validation.isValidSync(value);
    }

    return true;
  })
);

const yupEmptyDouble = (validation) => (
  string().notRequired().test('ifEmpty', validation[0].message, (value) => {
    if (value) {
      return validation[0].condition.isValidSync(value);
    }

    return true;
  }).test('ifEmpty1', validation[1].message, (value) => {
    if (value) {
      return string().max(100, validation[1].condition).isValidSync(value);
    }

    return true;
  })
);

const isNumber = (value) => {
  const { NUMBERS } = REGEXP;
  const isNum = NUMBERS.test(value);

  return isNum ? value : '';
};

const moreThen = (val) => {
  const { MAX_TWENTY } = REGEXP;

  return MAX_TWENTY.test(val) ? val.slice(0, val.length - 1) : val;
};

const checkValidDate = (...args) => {
  const check = args.every((date) => moment(date, DATE_FORMAT, true).isValid());
  if (!check) {
    toastr.error('', ERROR_VALID_DATE);
  }

  return check;
};

const checkValidationDate = (date) => moment(date, DATE_FORMAT, true).isValid();

const validateAge = (
  date,
  minRestrictions,
  maxRestrictions,
  regDate,
) => {
  const { min, type: minType } = minRestrictions;
  const { max, type: maxType } = maxRestrictions;

  const age = moment(regDate).diff(date, 'years');
  const checkMinRestrictions = comparisonValues(age, min, minType);
  const checkMaxRestrictions = comparisonValues(age, max, maxType);

  return checkMinRestrictions && checkMaxRestrictions;
};

const comparisonValues = (firstValue, secondValue, type) => {
  switch (type) {
    case '<=':
      return firstValue <= secondValue;
    case '>=':
      return firstValue >= secondValue;
    case '<':
      return firstValue < secondValue;
    case '>':
      return firstValue > secondValue;
    default:
      return firstValue === secondValue;
  }
};

const fromNullToStringObjects = (obj) => Object.keys(obj)
  .reduce(
    (newObj, k) => {
      if (obj[k] === null) {
        return { ...newObj, [k]: '' };
      }
      if (typeof obj[k] === 'object') {
        return { ...newObj, [k]: fromNullToStringObjects(obj[k]) };
      }

      return { ...newObj, [k]: obj[k] };
    },
    {},
  );

const addArrayToFormData = (formdata, array, name) => {
  formdata.append(`${name}[]`, JSON.stringify(array));
};

const getActiveDistributingByDate = (dateStart, dateEnd) => {
  const now = moment(new Date()).format(distributingDateValidator);
  const futureDate = moment(dateEnd).format(distributingDateValidator);
  const pastDate = moment(dateStart).format(distributingDateValidator);
  const diffEnd = moment(futureDate).diff(now, 'days');
  const diffStart = moment(pastDate).diff(now, 'days');
  if (dateStart === null && dateEnd === null) {
    return true;
  }
  if (dateStart === null) {
    return diffEnd > 0;
  }
  if (dateEnd === null) {
    return diffStart <= 0;
  }

  return diffEnd > 0 && diffStart <= 0;
};

const validateEmail = (value) => {
  const isValidEmail = email.isValid(value);

  return !POINT_IS_REPEATED.test(value) && isValidEmail;
};

export {
  checkPassportNotValid,
  validateEmail,
  addArrayToFormData,
  fromNullToStringObjects,
  getActiveDistributingByDate,
  comparisonValues,
  validateAge,
  checkPassportExpired,
  checkSnilsForYup,
  checkCurrencyRs,
  validateRs,
  checkValidationDate,
  checkValidDate,
  getErrorsFromValidationError,
  checkPassportDate,
  checkInn,
  checkSnils,
  onlyNumbers,
  removeLeadingZeros,
  yupEmpty,
  yupEmptyDouble,
  isNumber,
  moreThen,
  checkOkatoCode,
  validateNszBithDate,
  getValidationError,
  validateNszBirthDateChild,
  validatePaymentFreq,
  validateInsuranceSum,
  validateAdditionalRisk,
  checkForRiskHelpMessage,
  getRegularEnterError,
  rebuildErrors,
  yupCheckIssuedByAccepted,
};
