import React from 'react';
import { LANG_DICTIONARY } from 'consts';
import { getUtcFormattedDate, getFormattedDate, dateFormatter } from 'helpers';

import { RansomsTable, GarantsTable } from 'components';

const {
  GENDER_SHORT,
  FIO,
  BIRTH_PLACE,
  REGISTRATION_ADDRESS,
  REGISTRATION_ADDRESS_FACT,
  BIRTH_DATE_SHORT,
  DUL,
  SERIAL,
  NUMBER,
  DATE_OF_ISSUE,
  VALID_UNTIL,
  ISSUED_BY,
  KP_TOGETHER,
  INN,
  SNILS,
  MOBILE_PHONE,
  HOME_PHONE,
  WORK_PHONE,
  CONTACT_PHONE,
  EMAIL,
  PRODUCT,
  STRATEGY,
  DATE_BEGIN_CONTRACT,
  DATE_END_CONTRACT,
  TERM_OF_INSURANCE,
  PARTICIPATION_COEFFICIENT,
  PRODUCT_DESCRIPTION,
  PAYMENT_PERIODICITY,
  STATUS,
  DATE_CONTRACT,
  INSURANCE_SUM,
  INSURANCE_PREMIUM,
  COUPON_FREQUENCY,
  ANNUAL_YIELD_COUPON,
  BIC,
  SUBDIVISION,
  USER,
  PARTNER,
  INVEST_DATE,
  COR_ACCOUNT_SHORT,
  CHECK_ACCOUNT_SHORT,
  BANK_INSURER_DETAILS,
  KPP,
  BANK_NAME,
  DOCUMENTS_SHORT,
  DATE_REMOVE_CONTRACT,
  INSURANCE_FEE,
  GUARANTEED_PAYMENTS,
  REDEMPTION_AMOUNTS,
} = LANG_DICTIONARY;

const getDateCancel = (dateCancel) => (dateCancel ? [
  {
    title: DATE_REMOVE_CONTRACT, value: getFormattedDate(dateCancel), md: 6, sm: 6,
  },
] : []);
const getBankData = (bankData) => (bankData ? [
  {
    header: BANK_INSURER_DETAILS, md: 12, sm: 12,
  },
  {
    title: BANK_NAME, value: bankData.bankName, md: 9, sm: 7,
  },
  {
    title: BIC, value: bankData.bic, md: 3, sm: 5,
  },
  {
    title: CHECK_ACCOUNT_SHORT, value: bankData.curAcc, md: 4, sm: 6,
  },
  {
    title: COR_ACCOUNT_SHORT, value: bankData.corAcc, md: 4, sm: 6,
  },
  {
    title: INN, value: bankData.inn, md: 2, sm: 6,
  },
  {
    title: KPP, value: bankData.kpp, md: 2, sm: 6,
  },
] : []);

const getRansoms = (ransoms) => {
  if (!ransoms?.length) {
    return {};
  }

  return {
    title: `${REDEMPTION_AMOUNTS}:`,
    element: <RansomsTable ransoms={ransoms} />,
    md: 6,
    sm: 12,
    stylePrefix: 'ransoms',
  };
};

const getGarants = (garants) => {
  if (!garants?.length) {
    return {};
  }

  return {
    title: GUARANTEED_PAYMENTS,
    element: <GarantsTable garants={garants} />,
    md: 6,
    sm: 12,
    stylePrefix: 'garants',
  };
};

const getPerson = (data) => {
  const {
    fio,
    gender,
    birthDate,
    documentType,
    serial,
    passportNumber,
    when,
    validUntil,
    issuedBy,
    kp,
    birthPlace,
    inn,
    snils,
    addressReg,
    addressLive,
    homePhone,
    workPhone,
    contactPhone,
    mobilePhone,
    email,
    scans,
    bankData,
  } = data;

  return [
    {
      title: FIO, value: fio, md: 6, sm: 12,
    },
    {
      title: DUL, value: documentType, md: 6, sm: 12,
    },
    {
      title: GENDER_SHORT, value: gender, md: 6, sm: 12,
    },
    {
      title: SERIAL, value: serial, md: 2, sm: 4,
    },
    {
      title: NUMBER, value: passportNumber, md: 2, sm: 4,
    },
    {
      title: DATE_OF_ISSUE, value: when ? getFormattedDate(when) : '', md: 2, sm: 4,
    },
    {
      title: BIRTH_DATE_SHORT, value: birthDate ? getFormattedDate(birthDate) : '', md: 6, sm: 12,
    },
    {
      title: ISSUED_BY, value: issuedBy, md: 4, sm: 6,
    },
    kp ? {
      title: KP_TOGETHER, value: kp, md: 2, sm: 6,
    }
      : {
        title: VALID_UNTIL, value: validUntil ? getFormattedDate(validUntil) : '', md: 2, sm: 6,
      },
    {
      title: BIRTH_PLACE, value: birthPlace, md: 12, sm: 12,
    },
    {
      title: INN, value: inn, md: 6, sm: 6,
    },
    {
      title: SNILS, value: snils, md: 6, sm: 6,
    },
    {
      title: REGISTRATION_ADDRESS, value: addressReg, md: 12, sm: 12,
    },
    {
      title: REGISTRATION_ADDRESS_FACT, value: addressLive, md: 12, sm: 12,
    },
    {
      title: MOBILE_PHONE, value: mobilePhone, md: 4, sm: 12,
    },
    {
      title: HOME_PHONE, value: homePhone, md: 4, sm: 12,
    },
    {
      title: CONTACT_PHONE, value: contactPhone, md: 4, sm: 12,
    },
    {
      title: WORK_PHONE, value: workPhone, md: 4, sm: 12,
    },
    {
      title: EMAIL, value: email, md: 4, sm: 12,
    },
    ...getBankData(bankData),
    (scans && scans.length && {
      header: DOCUMENTS_SHORT, md: 12, sm: 12,
    }),
    { scans },
  ];
};

const getDataPerson = (data) => {
  if (Array.isArray(data)) {
    return data.map((person) => getPerson(person));
  }

  return getPerson(data);
};

const getCtsInsLineIsz = (ctsInsLineIsz) => {
  const initData = {
    participationRate: [],
    serialNumber: [],
    strategy: [],
    couponRate: [],
    couponPayment: [],
  };

  if (!ctsInsLineIsz) return initData;

  return ctsInsLineIsz
    .reduce((acc, item) => {
      const index = ctsInsLineIsz.length > 1 ? ` ${item.serialNumber}` : '';

      return {
        participationRate: [...acc.participationRate]
          .concat(item.participationRate ? [{
            title: `${PARTICIPATION_COEFFICIENT}${index}`,
            value: `${item.participationRate}%`,
            md: 6,
            sm: 12,
          }] : []),
        strategy: [...acc.strategy].concat(item.strategy ? [{
          title: `${STRATEGY}${index}`,
          value: item.strategy,
          md: 6,
          sm: 12,
        }] : []),
        couponRate: [...acc.couponRate].concat(item.couponRate ? [{
          title: `${ANNUAL_YIELD_COUPON}${index}`,
          value: item.couponRate,
          md: 6,
          sm: 12,
        }] : []),
        couponPayment: [...acc.couponPayment].concat(item.couponPayment ? [{
          title: `${COUPON_FREQUENCY}${index}`,
          value: item.couponPayment,
          md: 6,
          sm: 12,
        }] : []),
      };
    }, initData);
};

const rearrangeItems = (firstArray, secondArray) => {
  firstArray.sort((a, b) => (a.title > b.title ? 1 : -1));
  secondArray.sort((a, b) => (a.title > b.title ? 1 : -1));

  if (secondArray.length > 0) {
    return firstArray
      .reduce((acc, item, index) => ([...acc, item, secondArray[index]]), []);
  }

  return firstArray;
};

const getContractInfo = (data, additionallyData = {}) => {
  const {
    productName,
    dateBeg,
    dateCreate,
    dateEnd,
    dateInvest,
    insuranceTerm,
    status,
    insuranceAmmount,
    paymentFrequency,
    insurancePremium,
    scans,
    insuranceTermUnit,
    productDescription,
    partnerName,
    branchName,
    userFio,
    dateCancel,
    ctsInsLineIsz,
    premiumRegCur,
  } = data;

  const { ransoms, garants } = additionallyData;

  const {
    participationRate,
    strategy,
    couponRate,
    couponPayment,
  } = getCtsInsLineIsz(ctsInsLineIsz);

  return [
    {
      title: PRODUCT, value: productName, md: 6, sm: 12,
    },
    {
      title: STATUS, value: status, md: 6, sm: 12,
    },
    {
      title: DATE_CONTRACT, value: getFormattedDate(dateCreate), md: 6, sm: 12,
    },
    {
      title: PRODUCT_DESCRIPTION, html: productDescription, md: 6, sm: 12, row: 3,
    },
    {
      title: DATE_BEGIN_CONTRACT, value: getFormattedDate(dateBeg), md: 6, sm: 12,
    },
    {
      title: DATE_END_CONTRACT, value: getUtcFormattedDate(dateEnd), md: 6, sm: 12,
    },
    {
      title: INVEST_DATE, value: getFormattedDate(dateInvest), md: 6, sm: 12,
    },
    {
      title: INSURANCE_SUM, value: insuranceAmmount, md: 6, sm: 12,
    },
    ...getDateCancel(dateCancel),
    {
      title: TERM_OF_INSURANCE,
      value: dateFormatter(insuranceTerm, insuranceTermUnit),
      md: 6,
      sm: 12,
    },
    {
      title: INSURANCE_PREMIUM, value: insurancePremium, md: 6, sm: 12,
    },
    {
      title: PAYMENT_PERIODICITY, value: paymentFrequency, md: 6, sm: 12,
    },
    {
      title: PARTNER, value: partnerName, md: 6, sm: 12,
    },
    {
      title: SUBDIVISION, value: branchName, md: 6, sm: 12,
    },
    {
      title: USER, value: userFio, md: 6, sm: 12,
    },
    Boolean(premiumRegCur) && {
      title: INSURANCE_FEE, value: premiumRegCur, md: 6, sm: 12,
    },
    ...rearrangeItems(participationRate, strategy), // for ISZ constructor
    ...rearrangeItems(couponRate, couponPayment), // for ISZ constructor
    getRansoms(ransoms),
    getGarants(garants), // for ISZ constructor
    Boolean(scans?.length) && {
      header: DOCUMENTS_SHORT, md: 12, sm: 12,
    },
    { scans },
  ];
};

export {
  getDataPerson,
  getContractInfo,
};
