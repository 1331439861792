const ENVIRONMENT_ENUM = {
  production: 'production',
  development: 'development',
};

const PRODUCT_VERSION = {
  normal: 'isz_1',
  coupon: 'isz_2',
  normalCurrency: 'isz_3',
  couponCurrency: 'isz_4',
  constructorIsz: 'isz_5',
  constructorIszDollar: 'isz_6',
  nsz_1: 'nsz_1',
  nsz_2: 'nsz_2',
  nsz_3: 'nsz_3',
  ksz_1: 'ksz_1',
};

const CONTRACT_STATUS_CODES = {
  DOCUMENT_SIGNED: 'document_signed',
};

const PAYOUT_FREQ = {
  SIMULTANEOUSLY_AT_THE_END_OF_TIME: 6,
};

const PAYOUT_CODE_REF = {
  SIMULTANEOUSLY_AT_THE_END_OF_TIME: 'end_of_term',
};

const CURRENCY_REF = {
  ROUBLE: 1,
  DOLLAR: 2,
};

const CURRENCY_CODE_REF = {
  ROUBLE: 'ruble',
  DOLLAR: 'dollar',
  EURO: 'euro',
};

const NAMES_GROUP_SETTINGS = {
  preliminary: 'preliminary',
  insurerAgent: 'insurrerAgent',
  insuredPerson: 'insurredPerson',
  beneficiary: 'beneficiary',
  insuredChildPerson: 'insuredChildPerson',
};

const TYPE_PERSON = {
  insurer: 'insurer',
  insured: 'insured',
  beneficiary: 'beneficiary',
  childPerson: 'child_person',
};

const TYPE_CHECKING_CONTRACT = {
  halfChecked: 'half_checked',
  checked: 'checked',
  error: 'error',
  new: 'new',
};

const TYPE_ERROR_CHECKING_SCAN = {
  noSignature: 'no_signature',
  badScan: 'bad_scan',
  otherScan: 'other_scan',
  other: 'other',
};

const ASSET_COLOR = {
  red: 1,
  orange: 2,
  yellow: 3,
  green: 4,
};

const TYPE_CHECKING_CONTRACT_RU = {
  [TYPE_CHECKING_CONTRACT.halfChecked]: 'Частично проверен',
  [TYPE_CHECKING_CONTRACT.checked]: 'Проверен',
  [TYPE_CHECKING_CONTRACT.error]: 'Ошибка',
  [TYPE_CHECKING_CONTRACT.new]: 'Новый',
};

const RU_TYPE_PERSON = {
  [TYPE_PERSON.insurer]: 'Страхователь',
  [TYPE_PERSON.insured]: 'Застрахованный',
  [TYPE_PERSON.beneficiary]: 'Выгодоприобретатель',
};

const UNDERWRITING_STORE_LINK = {
  contractData: 'contractData',
  insuredData: 'insuredData',
  insurerData: 'insurerData',
  beneficiaryData: 'beneficiaryData',
};

const STATUS_SCANS = {
  error: 'error',
  checked: 'checked',
  new: 'new',
};

const DOCUMENT_TYPES = {
  none: 0,
  passport: 1,
  birth: 2,
};

const IMPORT_FILE_TYPE = {
  importPartners: 'import_partners_xlsx',
  importBranches: 'import_branches_xlsx',
  importUsers: 'import_users_xlsx',
  importProducts: 'import_products_xlsx',
  importExtremism: 'import_extremism_xlsx',
  importMvk: 'import_mvk_xlsx',
  importFromu: 'import_fromu_xlsx',
  importBadInsurantsXlsx: 'import_bad_insurants_xlsx',
  importExtremismOon: 'import_extremism_oon_xlsx',
  importAdress: 'import_adress_xlsx',
  importPassport: 'import_passport_from_path',
  importPassportFromUrl: 'import_passport_from_url',
  importNszTariffs: 'import_nsz_tariffs_xlsx',
};

const IMPORT_PODFT_TYPE = {
  pasportPodftType: 'passport',
  extremismPodftType: 'extremism',
  fromuPodftType: 'fromu',
  mvkPodftType: 'mvk',
  badInsurerPodftType: 'bad_insurer',
  addressPodftType: 'address',
  extremismOonPodftType: 'extremism_oon',
};

const VALID_PODFT_TYPE = {
  podftPassport: 'podft_passport',
  podftTerror: 'podft_terror',
  podftCombinePerson: 'podft_combine_person',
  podftBadInsurants: 'podft_bad_insurants',
};

const ERROR_STATUSES = {
  notFound: 404,
  forbidden: 403,
};

const KEY_NAMES = {
  KEY_ENTER: 'Enter',
  KEY_BACKSPACE: 'Backspace',
  KEY_ESCAPE: 'Escape',
};

const NSZ_RISK_TYPE = {
  package: 'package',
  default: 'standart',
};

const REQUEST_ERROR_CODE = {
  unauthorized: 401,
};

const CONSTRUCTOR_ISZ_OPTION_IDS = {
  NORMAL_EUROPE: 1,
  BARRIER: 3,
  BARRIER_M: 8,
};

const CONSTRUCTOR_ISZ_OPTION_CODES = {
  NORMAL_EUROPE: 'SEO',
  BARRIER: 'BO',
  BARRIER_M: 'BO_m',
  AC_GARANT: 'AC_garant',
};

const UNITED_FRONTEND_HEADER = {
  MASTER_ID: 'x-united-front-master-id',
  TAB_UUID: 'x-united-front-tab-uuid',
  TAB_TIMESTAMP: 'x-united-front-tab-timestamp',
};

const SERVICE_HEADERS = {
  CREATING_PRODUCT_ID: 'x-creating-product-id',
};

const UNITED_FRONTEND_ENUM = {
  EMPTY_MASTER_ID: 'None',
  EMPTY_PROFILE_RISK: 'None',
};

const UNITED_FRONTEND_ERRORS = {
  TAB_TIMESTAMP_ERROR: 'tab_timestamp_error',
};

const REF_INS_STATUSES_CODE_ENUM = {
  DRAFT: 'draft',
  CONTRACT_CALCULATION: 'contract_calculation',
  CONTRACT_CALCULATED: 'contract_calculated',
  DOCUMENT_GENERATION: 'document_generation',
  UNLOADED_TO_SK: 'unloaded_to_sk',
  AWAITING_PAYMENT: 'awaiting_payment',
  PAID: 'paid',
  UNDERWRITING: 'underwriting',
  UNDERWRITING_CANCELED: 'underwriting_canceled',
  CUSTOMER_CANCELED: 'customer_canceled',
  UNDERWRITING_COMPLETED: 'underwriting_completed',
  CONTRACT_FORMED: 'contract_formed',
  CONTRACT_SIGNED: 'contract_signed',
  CONTRACT_ISSUED: 'contract_issued',
  DOCUMENT_FORMED: 'document_formed',
  DOCUMENT_SIGNED: 'document_signed',
  CANCELED: 'canceled',
  IMPORTED_TO_SK: 'imported_to_sk',
  SAVED: 'saved',
  CONTRACT_PAID: 'contract_paid',
  CONTRACT_LESS_14: 'contract_less_14',
  CONTRACT_MORE_14: 'contract_more14',
  CONTRACT_REJECT: 'contract_reject',
  CANCELED_PARTNER: 'canceled_partner',
  CANCELED_TEST: 'canceled_test',
  NO_DOCS: 'no_docs',
};

const REF_INS_STATUSES_NAME_ENUM = {
  DOCUMENT_FORMED: 'Документы сформированы',
  DOCUMENT_SIGNED: 'Документы подписаны',
  ANNUL: 'Аннулирован',
};

const TEMPLATES_MODAL_CODES = {
  error: 'error',
  exist: 'exist',
};

const TEST_DATA_ENUMS = {
  insurer: 'insurer',
  insured: 'insured',
  beneficiary: 'beneficiary',
};

export {
  CONSTRUCTOR_ISZ_OPTION_CODES,
  REF_INS_STATUSES_NAME_ENUM,
  REF_INS_STATUSES_CODE_ENUM,
  CONTRACT_STATUS_CODES,
  UNITED_FRONTEND_ERRORS,
  UNITED_FRONTEND_ENUM,
  UNITED_FRONTEND_HEADER,
  CONSTRUCTOR_ISZ_OPTION_IDS,
  REQUEST_ERROR_CODE,
  NSZ_RISK_TYPE,
  CURRENCY_CODE_REF,
  PAYOUT_FREQ,
  CURRENCY_REF,
  ERROR_STATUSES,
  ASSET_COLOR,
  TYPE_ERROR_CHECKING_SCAN,
  TYPE_CHECKING_CONTRACT,
  TYPE_CHECKING_CONTRACT_RU,
  STATUS_SCANS,
  UNDERWRITING_STORE_LINK,
  RU_TYPE_PERSON,
  TYPE_PERSON,
  VALID_PODFT_TYPE,
  IMPORT_FILE_TYPE,
  DOCUMENT_TYPES,
  NAMES_GROUP_SETTINGS,
  PRODUCT_VERSION,
  ENVIRONMENT_ENUM,
  KEY_NAMES,
  TEMPLATES_MODAL_CODES,
  TEST_DATA_ENUMS,
  PAYOUT_CODE_REF,
  IMPORT_PODFT_TYPE,
  SERVICE_HEADERS,
};
