import React from 'react';
import block from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import userAvatar from 'assets/images/user_picture.png';
import { Input, Form } from 'semantic-ui-react';
import './styles.scss';

const b = block('userSearch');

const { USER_INFO } = LANG_DICTIONARY;

const UserSearch = () => (
  <div className={b()}>
    <div className={b('content')}>
      <img className={b('avatar')} src={userAvatar} alt="user pic" />
      <div className={b('searchBar')}>
        <Form>
          <Input
            name="search"
            action={{ icon: 'search' }}
            placeholder={USER_INFO}
            size="huge"
          />
        </Form>
      </div>
    </div>
  </div>
);

export default UserSearch;
