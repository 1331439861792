import React from 'react';
import PropTypes from 'prop-types';
import InputMask from 'react-input-mask';

const propTypes = {
  children: PropTypes.arrayOf(PropTypes.node),
  mask: PropTypes.string,
};

const defaultProps = {
  children: [],
  mask: null,
};

const SearchInputField = ({
  children,
  mask,
  ...wrapperProps
}) => (
  <div {...wrapperProps}>
    {children.map((child) => {
      if (child && child.type === 'input' && mask) {
        return (
          <InputMask
            key="input"
            autoComplete="off"
            maskChar="_"
            mask={mask}
            {...child.props}
          />
        );
      }

      return child;
    })}
  </div>
);

SearchInputField.defaultProps = defaultProps;
SearchInputField.propTypes = propTypes;

export default SearchInputField;
