import moment from 'moment/moment';
import {
  onlyNumbers,
  yupEmpty,
  yupEmptyDouble,
  validateRs,
  checkCurrencyRs,
  validateEmail,
  checkPassportExpired,
  yupCheckIssuedByAccepted,
  getStartOfDayDate,
} from 'helpers';
import {
  LANG_DICTIONARY,
  DATE_FORMAT,
  REGEXP,
} from 'consts';
import {
  object,
  string,
  number,
  date,
  mixed,
} from 'yup';

const {
  REQUIRED,
  IS_DATE,
  NO_MORE_TWO_HUNDRED_FIFTY_FIVE,
  NO_MORE_NINE,
  NO_MORE_TEN,
  EMAIL_VALIDATION,
  ONLY_CYRILLIC_DASH,
  ONLY_CYRILLIC_LATIN_SYMBOLS_QUOTES,
  ONLY_CYRILLIC_SYMBOLS_WITH_NUMBERS,
  NO_MORE_ONE_HUNDRED,
  INVALID_PHONE,
  ISSUED_BY_ACCEPT_REQUIRED,
  NO_MORE_THEN_FIFTY,
  FILL_COMPLETELY,
  DATE_INVALID,
  CHECK_SUM_CUR_ACCOUNT,
  INVALID_CODE_CURRENCY,
  NO_MORE_THEN_THREE_HUNDRED,
  WARNING_CYRILLIC_LATIN_DEF_NUMBERS,
  PASSPORT_EXPIRED,
  DOCUMENT_TYPE_PASSPORT,
  DOCUMENT_TYPE_BIRTH_DOCUMENT,
  DOCUMENT_TYPE_TEMP_CERT,
  TEMP_CERT_VALID_UNTIL_DATE,
  ONLY_CYRILLIC_DASH_AND_BRACKETS,
} = LANG_DICTIONARY;

const {
  CYRILLIC_LATIN_NUMBERS_SIMBOLS,
  BIRTH_PLACE,
  MIN_FOUR,
  USER_NAME,
  KP,
  MIN_SIX,
  MIN_TWELVE,
  NUMBERS_CYRILLIC_LATIN_DEF,
  USER_NAME_WITH_BRACKETS,
} = REGEXP;

const userSchema = () => ({
  lastName: string().matches(USER_NAME_WITH_BRACKETS, ONLY_CYRILLIC_DASH_AND_BRACKETS)
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
  firstName: string().matches(USER_NAME, ONLY_CYRILLIC_DASH)
    .max(50, NO_MORE_THEN_FIFTY)
    .required(REQUIRED),
  patronymic: yupEmptyDouble([
    {
      message: ONLY_CYRILLIC_DASH,
      condition: string().matches(USER_NAME, ONLY_CYRILLIC_DASH),
    },
    {
      message: NO_MORE_THEN_FIFTY,
      condition: string().max(50, NO_MORE_THEN_FIFTY),
    },
  ]).nullable(),
  gender: string().required(REQUIRED),
  birthPlace: string()
    .matches(BIRTH_PLACE, ONLY_CYRILLIC_SYMBOLS_WITH_NUMBERS)
    .max(100, NO_MORE_THEN_THREE_HUNDRED)
    .required(REQUIRED),
});

const documentSchema = (notOnlyPassport, birthDate, isCheckPasswordCovid) => ({
  documentType: number().required(REQUIRED),
  issuedBy: string()
    .max(255, NO_MORE_TWO_HUNDRED_FIFTY_FIVE)
    .matches(CYRILLIC_LATIN_NUMBERS_SIMBOLS, ONLY_CYRILLIC_LATIN_SYMBOLS_QUOTES)
    .required(REQUIRED)
    .when('documentType', {
      is: DOCUMENT_TYPE_PASSPORT,
      then: string().test(
        'is-accepted',
        ISSUED_BY_ACCEPT_REQUIRED,
        yupCheckIssuedByAccepted,
      ),
    }),
  when: date()
    .when('documentType', {
      is: DOCUMENT_TYPE_PASSPORT,
      then: date()
        .required(REQUIRED)
        .typeError(IS_DATE)
        .test('len', PASSPORT_EXPIRED, (val) => (
          checkPassportExpired(birthDate, val, isCheckPasswordCovid))),
    })
    .when('documentType', {
      is: DOCUMENT_TYPE_TEMP_CERT,
      then: date()
        .required(REQUIRED)
        .typeError(IS_DATE)
        .min(moment(birthDate || new Date()).add(14, 'years'), TEMP_CERT_VALID_UNTIL_DATE)
        .max(new Date(), DATE_INVALID)
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate())),
    })
    .when('documentType', {
      is: DOCUMENT_TYPE_BIRTH_DOCUMENT,
      then: date()
        .required(REQUIRED)
        .typeError(IS_DATE)
        .min(birthDate || new Date(), DATE_INVALID)
        .max(new Date(), DATE_INVALID)
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate())),
    }),
  validUntil: mixed()
    .when('documentType', {
      is: DOCUMENT_TYPE_TEMP_CERT,
      then: date()
        .typeError(IS_DATE)
        .min(getStartOfDayDate(new Date()), TEMP_CERT_VALID_UNTIL_DATE)
        .test('max', TEMP_CERT_VALID_UNTIL_DATE,
          // eslint-disable-next-line func-names
          function (val) {
            const { when } = this.parent;

            return moment(when || new Date()).add(30, 'days').isAfter(val);
          })
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate()))
        .required(REQUIRED),
      otherwise: mixed().notRequired(),
    }),
  kp: string()
    .when('documentType', {
      is: (documentType) => documentType === DOCUMENT_TYPE_PASSPORT,
      then: string().matches(KP, FILL_COMPLETELY).required(REQUIRED),
      otherwise: string().notRequired(),
    }),
  inn: yupEmpty(
    string()
      .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 12),
    FILL_COMPLETELY,
  ).nullable(),
  snils: yupEmpty(
    string()
      .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 11),
    FILL_COMPLETELY,
  ).nullable(),
  serial: string()
    .when('documentType', {
      is: DOCUMENT_TYPE_TEMP_CERT,
      then: string().notRequired(),
    })
    .when('documentType', {
      is: DOCUMENT_TYPE_BIRTH_DOCUMENT,
      then: string()
        .matches(NUMBERS_CYRILLIC_LATIN_DEF, WARNING_CYRILLIC_LATIN_DEF_NUMBERS)
        .required(REQUIRED)
        .test('len', REQUIRED, (val) => val.trim().length),
    })
    .when('documentType', {
      is: DOCUMENT_TYPE_PASSPORT,
      then: string()
        .matches(MIN_FOUR, FILL_COMPLETELY)
        .required(REQUIRED)
        .test('len', REQUIRED, (val) => val.trim().length),
    }),
  passportNumber: string()
    .when('documentType', {
      is: DOCUMENT_TYPE_TEMP_CERT,
      then: string().matches(MIN_TWELVE, FILL_COMPLETELY).required(REQUIRED),
      otherwise: string().matches(MIN_SIX, FILL_COMPLETELY).required(REQUIRED),
    }),
});

const addressSchema = (addressIsSame) => ({
  apartment: string().max(10, NO_MORE_TEN).notRequired().nullable(),
  livingAddressIsSame: string(),
  index: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val).length === 6)
    .required(REQUIRED),
  city: string().required(REQUIRED),
  house: string().required(REQUIRED),
  republic: string().required(REQUIRED),
  ...(!addressIsSame && {
    indexLiving: string()
      .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val).length === 6)
      .required(REQUIRED),
    cityLiving: string().required(REQUIRED),
    houseLiving: string().required(REQUIRED),
    apartmentLiving: string().max(10, NO_MORE_TEN).notRequired().nullable(),
    republicLiving: string().required(REQUIRED),
  }),
});

const contactsSchema = {
  mobilePhone: yupEmpty(
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11),
    INVALID_PHONE,
  ).required(REQUIRED),
  contactPhone: string().max(100, NO_MORE_ONE_HUNDRED).nullable(),
  email: string()
    .test('len', EMAIL_VALIDATION, (val) => validateEmail(val))
    .required(REQUIRED),
  homePhone: yupEmpty(
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11),
    INVALID_PHONE,
  ).nullable(),
  workPhone: yupEmpty(
    string()
      .test('len', INVALID_PHONE, (val) => onlyNumbers(val.toString()).length === 11),
    INVALID_PHONE,
  ).nullable(),
};

const bankSchema = (isBank, checkAccountNumbersError) => (!isBank ? {} : {
  bic: string()
    .min(9, FILL_COMPLETELY)
    .max(9, NO_MORE_NINE)
    .required(REQUIRED),
  bankName: string().required(REQUIRED),
  bankInn: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 10)
    .required(REQUIRED),
  kpp: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 9)
    .required(REQUIRED),
  corAccount: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 20),
  curAccount: string()
    .test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 20)
    .when('bic', (bic, schema) => (schema.test('len', CHECK_SUM_CUR_ACCOUNT, (val) => validateRs(val, bic))))
    .test('len', INVALID_CODE_CURRENCY, (val) => checkCurrencyRs(val))
    .test('len', checkAccountNumbersError, () => checkAccountNumbersError),
});

const relationshipsSchema = (isInsured, insuredIsSame) => {
  if (!isInsured || insuredIsSame) return {};

  return {
    relationship: string()
      .test('len', REQUIRED, (val) => Number(val) !== 0)
      .required(REQUIRED),
  };
};

const renderInitialFields = (
  disableValidation,
  addressIsSame,
  isBank,
  checkAccountNumbersError,
  isInsured,
  insuredIsSame,
  restrictions,
  notOnlyPassport,
  term,
  regDate,
  checkNumbers,
  birthDate,
  isCheckPasswordCovid,
) => (
  disableValidation ? {} : {
    ...relationshipsSchema(isInsured, insuredIsSame),
    ...userSchema(isInsured, restrictions, term, regDate),
    ...documentSchema(notOnlyPassport, birthDate, isCheckPasswordCovid),
    ...addressSchema(addressIsSame),
    ...contactsSchema,
    ...bankSchema(isBank, checkAccountNumbersError, checkNumbers),
  });

const Schema = ({
  disableValidation,
  addressIsSame,
  isBank,
  isInsured,
  insuredIsSame,
  restrictions,
  notOnlyPassport,
  term,
  regDate,
  checkAccountNumbersError,
  checkNumbers,
  isCheckPasswordCovid,
}) => object()
  .shape(renderInitialFields(
    disableValidation,
    addressIsSame,
    isBank,
    checkAccountNumbersError,
    isInsured,
    insuredIsSame,
    restrictions,
    notOnlyPassport,
    term,
    regDate,
    checkNumbers,
    isCheckPasswordCovid,
  ));

export default Schema;
