import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import './styles.scss';

const b = block('scan-interface');

const propTypes = {
  setKey: PropTypes.func,
  resetInterface: PropTypes.func,
  fileLink: PropTypes.string,
  state: PropTypes.object,
  handleUpPage: PropTypes.func,
  handleDownPage: PropTypes.func,
  handleUpZoom: PropTypes.func,
  handleDownZoom: PropTypes.func,
  zoom: PropTypes.number,
  page: PropTypes.number,
  downloadScan: PropTypes.func,
  handlRotateRight: PropTypes.func,
  handlRotateLeft: PropTypes.func,
  isShowDownloadIcon: PropTypes.bool,
};

const defaultProps = {
  setKey: () => null,
  resetInterface: () => null,
  state: {},
  fileLink: '',
  handleUpPage: () => null,
  handleDownPage: () => null,
  handleUpZoom: () => null,
  handleDownZoom: () => null,
  zoom: 100,
  page: 1,
  downloadScan: () => null,
  handlRotateRight: () => null,
  handlRotateLeft: () => null,
  isShowDownloadIcon: true,
};
class ScanInterface extends React.Component {
  componentDidUpdate(prevProps) {
    const {
      state: { pages },
      setKey,
      resetInterface,
      fileLink,
    } = this.props;
    const { fileLink: prevLink } = prevProps;
    if (pages === 0) {
      setTimeout(() => setKey(Math.random), 100); // check tootal pages in external _components
    }
    if (prevLink !== fileLink && prevLink) setTimeout(() => resetInterface(), 100);
  }

  render() {
    const {
      handleUpPage,
      handleDownPage,
      page,
      state: { pages },
      handleUpZoom,
      handleDownZoom,
      zoom,
      downloadScan,
      handlRotateRight,
      handlRotateLeft,
      isShowDownloadIcon,
    } = this.props;

    return (
      <div className={b()}>
        <div className={b('page-interface')}>
          <Icon
            name="caret left"
            onClick={handleDownPage}
          />
          <input
            type="text"
            className={b('page-count')}
            value={pages ? `${page}/${pages}` : ''}
          />
          <Icon
            name="caret right"
            onClick={handleUpPage}
          />
        </div>
        <div className={b('zoom-interface')}>
          <Icon name="zoom-out" onClick={handleDownZoom} />
          <input
            type="text"
            className={b('zoom-count')}
            value={`${zoom}%`}
          />
          <Icon name="zoom-in" onClick={handleUpZoom} />
        </div>
        <div className={b('zoom-interface')}>
          <Icon name="undo" onClick={handlRotateLeft} />
          <Icon name="redo" onClick={handlRotateRight} />
        </div>
        {isShowDownloadIcon && (<Icon name="download" onClick={downloadScan} />)}
      </div>
    );
  }
}

ScanInterface.propTypes = propTypes;
ScanInterface.defaultProps = defaultProps;
export default ScanInterface;
