export default (headers, data, checkShow) => {
  const withShowInsurerAction = [
    {
      md: 1,
      headers: [
        { text: headers[0] },
      ],
      data: [
        { text: data[0] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[1] },
        { text: headers[2] },
      ],
      data: [
        { text: data[1] },
        { text: data[2] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[3] },
        { text: headers[4] },
      ],
      data: [
        { text: data[3] },
        { text: data[4] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[5] },
        { text: headers[6] },
      ],
      data: [
        { text: data[5] },
        { text: data[6] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[7] },
      ],
      data: [
        { text: data[7] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[11] },
      ],
      data: [
        { text: data[11] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[8] },
        { text: headers[9] },
      ],
      data: [
        { text: data[8] },
        { text: data[9] },
      ],
    },
    {
      md: 1,
      headers: [
        { text: headers[10] },
      ],
      data: [
        { text: data[10] },
      ],
    },
  ];

  const withoutShowInsurerAction = [{
    md: 1,
    headers: [
      { text: headers[0] },
    ],
    data: [
      { text: data[0] },
    ],
  },
  {
    md: 3,
    headers: [
      { text: headers[1] },
      { text: headers[2] },
    ],
    data: [
      { text: data[1] },
      { text: data[2] },
    ],
  },
  {
    md: 3,
    headers: [
      { text: headers[5] },
      { text: headers[6] },
    ],
    data: [
      { text: data[5] },
      { text: data[6] },
    ],
  },
  {
    md: 1,
    headers: [
      { text: headers[7] },
    ],
    data: [
      { text: data[7] },
    ],
  },
  {
    md: 1,
    headers: [
      { text: headers[11] },
    ],
    data: [
      { text: data[11] },
    ],
  },
  {
    md: 2,
    headers: [
      { text: headers[8] },
      { text: headers[9] },
    ],
    data: [
      { text: data[8] },
      { text: data[9] },
    ],
  },
  {
    md: 1,
    headers: [
      { text: headers[10] },
    ],
    data: [
      { text: data[10] },
    ],
  }];

  return {
    key: data[1],
    data: checkShow ? withShowInsurerAction : withoutShowInsurerAction,
  };
};
