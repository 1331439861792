import React, { Component } from 'react';
import { withCustomRouter } from 'HOC';
import { AdministrativeSectionInterface, CreatingOrUpdatingPartnerForm } from 'components';
import PropTypes from 'prop-types';
import {
  createPartner,
  getPartnerDataForUpdate,
  updatePartner,
  clearEdtiorAdminPartnersForm,
  addNewIP,
  delIp,
  changeAccess,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import { ROUTES } from 'consts';

const propTypes = {
  match: PropTypes.object,
  getPartnerData: PropTypes.func,
  clearForm: PropTypes.func,
  createNewPartner: PropTypes.func,
  updatePartnerById: PropTypes.func,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  history: PropTypes.object,
  partnerFormData: PropTypes.object,
  addNewIPAddress: PropTypes.func,
  delIpAddress: PropTypes.func,
  changeUnlimitedAccess: PropTypes.func,
};

const defaultProps = {
  match: {},
  getPartnerData: () => null,
  clearForm: () => null,
  createNewPartner: () => null,
  updatePartnerById: () => null,
  active: '',
  history: {},
  partnerFormData: {},
  addNewIPAddress: () => null,
  delIpAddress: () => null,
  changeUnlimitedAccess: () => null,
};
class CreatingOrUpdatePartner extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id,
        },
      },
    } = props;
    this.updatedPartner = id || null;
    this.handleSubmitSuccess = id ? this.updatePartner : this.createPartner;
  }

  componentDidMount() {
    const { getPartnerData } = this.props;
    if (this.updatedPartner) getPartnerData(this.updatedPartner);
  }

  componentWillUnmount() {
    const { clearForm } = this.props;
    clearForm();
  }

  createPartner = (values) => {
    const { createNewPartner, history } = this.props;
    createNewPartner(values);
    history.push(ROUTES.addPartners, { cache: true });
  };

  updatePartner = (values) => {
    const { updatePartnerById } = this.props;
    updatePartnerById(this.updatedPartner, values);
  }

  render() {
    const {
      active,
      history,
      partnerFormData,
      addNewIPAddress,
      delIpAddress,
      changeUnlimitedAccess,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <CreatingOrUpdatingPartnerForm
          history={history}
          handleSubmitSuccess={this.handleSubmitSuccess}
          isUpdatedForm={Boolean(this.updatedPartner)}
          initialValues={partnerFormData}
          addNewIPAddress={addNewIPAddress}
          delIpAddress={delIpAddress}
          changeUnlimitedAccess={changeUnlimitedAccess}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  partnersAdminInterface: {
    partnerFormData,
  },
}) => ({ partnerFormData });

const mapDispatchToProps = {
  createNewPartner: createPartner,
  updatePartnerById: updatePartner,
  getPartnerData: getPartnerDataForUpdate,
  clearForm: clearEdtiorAdminPartnersForm,
  addNewIPAddress: addNewIP,
  delIpAddress: delIp,
  changeUnlimitedAccess: changeAccess,
};

CreatingOrUpdatePartner.propTypes = propTypes;
CreatingOrUpdatePartner.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatingOrUpdatePartner),
);
