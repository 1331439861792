import { API } from 'consts';
import {
  apiCall,
  getError,
  getScanData,
  apiDownload,
} from 'helpers';
import { put, takeLatest, takeEvery } from 'redux-saga/effects';
import {
  GET_SCAN_START,
  GET_SCAN_FAIL,
  GET_SCAN_SUCCESS,
  GET_SCAN_REQUEST,
  GET_SCAN_DIRECTLY_REQUEST,
  GET_SCAN_DIRECTLY_FAIL,
  UPLOAD_SCAN_FAIL,
  UPLOAD_SCAN_REQUEST,
  UPLOAD_SCAN_START,
  UPLOAD_SCAN_SUCCESS,
  SET_FILE_TYPE_FAIL,
  SET_FILE_TYPE_REQUEST,
  SET_FILE_TYPE_START,
  SET_FILE_TYPE_SUCCESS,
  DELETE_SCAN_FAIL,
  DELETE_SCAN_REQUEST,
  DELETE_SCAN_START,
  DELETE_SCAN_SUCCESS,
  DOWNLOAD_SCAN_FOR_VIEW_FAIL,
  DOWNLOAD_SCAN_FOR_VIEW_REQUEST,
  DOWNLOAD_SCAN_FOR_VIEW_START,
  DOWNLOAD_SCAN_FOR_VIEW_SUCCESS,
} from '../types';

const { GET_SCAN } = API;

function* getScan({ payload: { id, fileName } }) {
  try {
    yield put({ type: GET_SCAN_START, payload: id });
    const data = yield apiCall({
      type: 'GET',
      url: `${GET_SCAN}/${id}`,
      isBlob: true,

    });
    const scanData = yield getScanData(data);
    yield put({
      type: GET_SCAN_SUCCESS,
      payload: {
        ...scanData,
        name: fileName,
        id,
      },
    });
  } catch (error) {
    yield put({ type: GET_SCAN_FAIL, payload: getError(error) });
  }
}

function* uploadScan({ payload }) {
  const { SCANS } = API;
  const {
    formData,
  } = payload;
  try {
    yield put({ type: UPLOAD_SCAN_START });
    const { data } = yield apiCall({
      type: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: formData,
      url: SCANS,
    });

    yield put({ type: UPLOAD_SCAN_SUCCESS, payload: { data, ...payload } });
  } catch (error) {
    yield put({ type: UPLOAD_SCAN_FAIL, payload: getError(error) });
  }
}

function* setScanType({ payload: { typeId, fileId } }) {
  const { UPDATE_SCAN_TYPE } = API;
  try {
    yield put({ type: SET_FILE_TYPE_START });
    yield apiCall({
      type: 'PUT',
      url: UPDATE_SCAN_TYPE(fileId),
      body: {
        type: typeId,
      },
    });
    yield put({ type: SET_FILE_TYPE_SUCCESS, payload: { typeId, fileId } });
  } catch (error) {
    yield put({ type: SET_FILE_TYPE_FAIL, payload: getError(error) });
  }
}

function* deleteScan({ payload }) {
  const { DELETE_SCAN } = API;
  try {
    yield put({ type: DELETE_SCAN_START });
    yield apiCall({
      type: 'DELETE',
      url: DELETE_SCAN(payload),
    });
    yield put({ type: DELETE_SCAN_SUCCESS, payload });
  } catch (error) {
    yield put({ type: DELETE_SCAN_FAIL, payload: getError(error) });
  }
}

function* getScanDirectly({ payload: { ctsId, fileName } }) {
  try {
    yield apiDownload({
      url: `${GET_SCAN}/${ctsId}`,
    }, fileName);
  } catch (error) {
    yield put({ type: GET_SCAN_DIRECTLY_FAIL, payload: getError(error) });
  }
}

function* downloadScanForViewModal({ payload: scanId }) {
  try {
    yield put({ type: DOWNLOAD_SCAN_FOR_VIEW_START });
    const { data } = yield apiCall({
      type: 'GET',
      url: `${GET_SCAN}/${scanId}`,
      isBlob: true,
    });
    yield put({ type: DOWNLOAD_SCAN_FOR_VIEW_SUCCESS, payload: { data, scanId } });
  } catch (error) {
    yield put({ type: DOWNLOAD_SCAN_FOR_VIEW_FAIL, payload: getError(error) });
  }
}

function* fileSaga() {
  yield takeLatest(DOWNLOAD_SCAN_FOR_VIEW_REQUEST, downloadScanForViewModal);
  yield takeLatest(DELETE_SCAN_REQUEST, deleteScan);
  yield takeLatest(SET_FILE_TYPE_REQUEST, setScanType);
  yield takeEvery(UPLOAD_SCAN_REQUEST, uploadScan);
  yield takeLatest(GET_SCAN_DIRECTLY_REQUEST, getScanDirectly);
  yield takeLatest(GET_SCAN_REQUEST, getScan);
}

export default fileSaga;
