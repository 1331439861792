import React, { useEffect, useCallback, useMemo } from 'react';
import {
  Radio,
  Form,
  Input,
  Select,
  Checkbox,
} from 'semantic-ui-react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, REGEXP } from 'consts';
import { DEFAULT_VALUE_GUARANTEE } from 'config';
import PropTypes from 'prop-types';
import { ErrorMessage, HelpMessage } from 'components';
import { useActions } from 'hooks/useActions';
import * as actionsBasketConstructor from 'redux/basketConstructor/actions';
import { useSelector } from 'react-redux';
import {
  selectConstructorTableControlData,
  selectSettingsProduct,
} from 'redux/basketConstructor/selectors';
import './styles.scss';

const { SELECT_OPTION_CONSTRUCTOR } = LANG_DICTIONARY;

const propTypes = {
  className: PropTypes.string,
  productId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  guaranteeLevelRestrictions: PropTypes.arrayOf(PropTypes.number),
  isCalculatedIndividualBaskets: PropTypes.bool,
};

const defaultProps = {
  className: '',
  productId: 0,
  guaranteeLevelRestrictions: [DEFAULT_VALUE_GUARANTEE, DEFAULT_VALUE_GUARANTEE],
  isCalculatedIndividualBaskets: false,
};

const {
  CURRENCY,
  GUARANTEE_LEVEL,
  OF_THE_AMOUNT_OF_THE_PAID_CONTRIBUTION,
  VALUE_MUST_BE_NO_LEST,
  VALUE_MUST_BE_NO_MORE,
  PRODUCT_TYPE,
  MAIN_STRATEGY,
  ONLY_FAVORITE,
} = LANG_DICTIONARY;

const b = block('guarantee-level');

const ConstructorTableControl = ({
  className,
  productId,
  guaranteeLevelRestrictions,
  isCalculatedIndividualBaskets,
}) => {
  const {
    getOptionType,
    getCurrencies,
    getProductTypeTableControl,
    getAmountFavoritesBasket,
    setGeneralOptionType,
    changeGuaranteeLevel,
    changeGuaranteeLevelError,
    changeCurrencyTableControl,
    setProductTypeTableControl,
    setProductOnlyFavorite,
    getProductSettings,
  } = useActions(actionsBasketConstructor);
  const {
    productTypeOptions,
    productOptionOptions,
    productCurrenciesOptions,
    productTypeCode,
    guaranteeLevel,
    guaranteeLevelError,
    generalOptionCode,
    currencyCode,
    isOnlyFavorite,
    maxFavoritesBaskets,
    amountFavoritesBasket,
  } = useSelector(selectConstructorTableControlData);
  const { fixedIncomeEditable, fixedIncomePerc } = useSelector(selectSettingsProduct);
  const [minGuaranteeLevel, maxGuaranteeLevel] = guaranteeLevelRestrictions;

  useEffect(() => {
    getProductTypeTableControl(productId);
    getAmountFavoritesBasket();
  }, []);

  useEffect(() => {
    if (!currencyCode && productCurrenciesOptions.length) {
      handleChangeRadio(null, { value: productCurrenciesOptions[0].currencyCode });
    }
  }, [productCurrenciesOptions, currencyCode]);

  useEffect(() => {
    if (!generalOptionCode) {
      const defaultOption = productOptionOptions.find(({ def }) => def);

      if (defaultOption && defaultOption.value) {
        setGeneralOptionType(defaultOption.value);
      }
    }
  }, [productOptionOptions, generalOptionCode]);

  useEffect(() => {
    if (productTypeCode) {
      getOptionType(productId, productTypeCode);
      getCurrencies(productId, productTypeCode);
    }
  }, [productTypeCode]);

  useEffect(() => {
    let error = null;

    if (guaranteeLevel < minGuaranteeLevel) {
      error = `${VALUE_MUST_BE_NO_LEST} ${minGuaranteeLevel}%`;
    } else if (guaranteeLevel > maxGuaranteeLevel) {
      error = `${VALUE_MUST_BE_NO_MORE} ${maxGuaranteeLevel}%`;
    }

    changeGuaranteeLevelError(error);
  }, [guaranteeLevel, minGuaranteeLevel, maxGuaranteeLevel, changeGuaranteeLevelError]);

  const handleChangeProductType = useCallback((e, { value }) => {
    setProductTypeTableControl(value);
  }, []);

  const handleOnlyFavorite = useCallback(() => {
    setProductOnlyFavorite(!isOnlyFavorite);
  }, [isOnlyFavorite]);

  const handleChangeGuarantee = useCallback((event) => {
    const { target: { value } } = event;
    const onlyNumbersValue = value.replace(REGEXP.ALL_BUT_NUMBERS_AND_POINT, '');
    changeGuaranteeLevel(onlyNumbersValue.replace(REGEXP.MORE_THAN_ONE_DECIMAL, '$1'));
  }, [fixedIncomePerc, changeGuaranteeLevel]);

  const handleChangeRadio = useCallback((event, { value }) => {
    changeCurrencyTableControl(value);
    changeGuaranteeLevel(minGuaranteeLevel);
    getProductSettings(productId, value);
  }, [minGuaranteeLevel]);

  const handleChangeSelectOption = useCallback((e, { value }) => {
    setGeneralOptionType(value);
  }, []);

  const isDisabledSelectOption = productOptionOptions.length <= 1;

  const productType = useMemo(() => (
    productTypeOptions.find((item) => item.value === productTypeCode)
  ), [
    productTypeOptions,
    productTypeCode,
  ]);

  return (
    <div className={b.mix(className)}>
      {isCalculatedIndividualBaskets && (
        <div className={b('product-type')}>
          <p className={b('title')}>{`${ONLY_FAVORITE}:`}</p>
          <div className={b('favorite')}>
            <Checkbox
              checked={isOnlyFavorite}
              onChange={handleOnlyFavorite}
              name="onlyFavorite"
            />
            <p>{`${amountFavoritesBasket}/${maxFavoritesBaskets}`}</p>
          </div>
        </div>
      )}
      <div className={b('product-type')}>
        <p className={b('title')}>{`${PRODUCT_TYPE}:`}</p>
        <div className={b('input-wrapper')}>
          <Select
            placeholder={SELECT_OPTION_CONSTRUCTOR}
            options={productTypeOptions}
            value={productTypeCode}
            name="productType"
            onChange={handleChangeProductType}
            className={b('select-option').toString()}
          />
          {productType && (
          <HelpMessage
            message={productType.desc}
            className={b('info-icon').toString()}
          />
          )}
        </div>
      </div>
      <div className={b('option')}>
        <p className={b('title')}>{`${MAIN_STRATEGY}:`}</p>
        <Select
          placeholder={SELECT_OPTION_CONSTRUCTOR}
          options={productOptionOptions}
          value={generalOptionCode}
          disabled={isDisabledSelectOption}
          name="valueOption"
          onChange={handleChangeSelectOption}
          className={b('select-option').toString()}
        />
      </div>
      <div className={b('currency')}>
        <p className={b('title')}>{`${CURRENCY}:`}</p>
        <div className={b('radio-buttons')}>
          {!productCurrenciesOptions.length && (<p className={b('currency-space-symbol')}>-</p>)}
          {productCurrenciesOptions.map((item) => (
            <Form.Field key={item.currencyCode}>
              <Radio
                label={item.currencyName}
                value={item.currencyCode}
                checked={item.currencyCode === currencyCode}
                onChange={handleChangeRadio}
                className={b('radio').toString()}
              />
            </Form.Field>
          ))}
        </div>
      </div>
      <div className={b('guarantee')}>
        <div className={b('guarantee-value')}>
          <p className={b('currency-title')}>{`${GUARANTEE_LEVEL}:`}</p>
          <p className={b('guarantee-value-message')}>{`(${OF_THE_AMOUNT_OF_THE_PAID_CONTRIBUTION})`}</p>
          <div className={b('guarantee-input-wrapper')}>
            <Input
              className={b('guarantee-input', { error: Boolean(guaranteeLevelError) }).toString()}
              onChange={handleChangeGuarantee}
              value={guaranteeLevel}
              disabled={!fixedIncomeEditable}
              name="guaranteeLevel"
            />
            <p className={b('guarantee-percent')}>%</p>
          </div>
          {Boolean(guaranteeLevelError) && (<ErrorMessage message={guaranteeLevelError} />)}
        </div>
      </div>
    </div>
  );
};

ConstructorTableControl.propTypes = propTypes;
ConstructorTableControl.defaultProps = defaultProps;
export default ConstructorTableControl;
