import {
  EXPORT_1C_REQUEST,
  GET_CONTRACT_BY_NUMBER_REQUEST,
  GET_INITIAL_STATE,
  SET_CONTRACT_STATUS_REQUEST,
  GET_CONTRACT_CURRENT_STATE_REQUEST,
  GET_CONTRACT_STATUSES_REQUEST,
  SET_PAYOUT_SUM_CONTRACT,
  SET_SELECT_VALUE,
  ADD_PAYMENT_TO_CONTRACT,
  DELETE_PAYMENT_FROM_CONTRACT,
  SET_DOWNLOAD_FILE_FROM_1C,
  IMPORT_MANUAL_WEBSOCKET_EMIT,
  SET_INIT_STORE_IMPORT_MANUAL,
  RESET_TRANSFER_DATA,
} from '../types';

const setDownloadFile = (name, type) => ({
  type: SET_DOWNLOAD_FILE_FROM_1C,
  payload: {
    name,
    type,
  },
});

const setPayoutSumContract = (newPayouts) => ({
  type: SET_PAYOUT_SUM_CONTRACT,
  payload: newPayouts,
});

const setSelectValue = (name, value) => ({
  type: SET_SELECT_VALUE,
  payload: { name, value },
});

const getStatusList = () => ({
  type: GET_CONTRACT_STATUSES_REQUEST,
});

const getStatesList = () => ({
  type: GET_CONTRACT_CURRENT_STATE_REQUEST,
});
const saveContractStatus = (body) => ({
  type: SET_CONTRACT_STATUS_REQUEST,
  payload: body,
});

const clearFormContracts = () => ({
  type: GET_INITIAL_STATE,
});

const export1C = (body, history, isDownloadFileFrom1C) => ({
  type: EXPORT_1C_REQUEST,
  payload: body,
  history,
  isDownloadFileFrom1C,
});

const getContract = (id) => ({
  type: GET_CONTRACT_BY_NUMBER_REQUEST,
  payload: id,
});

const addPaymentToContract = (payment) => ({
  type: ADD_PAYMENT_TO_CONTRACT,
  payload: payment,
});

const deletePaymentFromContract = (index) => ({
  type: DELETE_PAYMENT_FROM_CONTRACT,
  payload: index,
});

const importManual = ({ file, data }) => ({
  type: IMPORT_MANUAL_WEBSOCKET_EMIT,
  payload: { file, data },
});

const setInitStoreImportManual = () => ({
  type: SET_INIT_STORE_IMPORT_MANUAL,
});

const resetTransferDataForm = () => ({
  type: RESET_TRANSFER_DATA,
});

export {
  resetTransferDataForm,
  setInitStoreImportManual,
  importManual,
  setSelectValue,
  getStatusList,
  getStatesList,
  getContract,
  export1C,
  clearFormContracts,
  saveContractStatus,
  setPayoutSumContract,
  addPaymentToContract,
  deletePaymentFromContract,
  setDownloadFile,
};
