import React from 'react';
import {
  TransferData,
  Locking,
  Import,
  PartnersAdministration,
  BranchesAdministration,
  CreatingOrUpdatingPartner,
  CreatingOrUpdatingBranch,
  UserAdministration,
  CreatingOrUpdatingUser,
  ReportsAdministration,
  CreatingOrUpdatingReport,
  DistributingProducts,
  CatalogsSection,
  SchedulerForJobs,
  PublicApiList,
  CreatingOrUpdatingPublicApi,
  TemplatesContainer,
} from 'containers';
import { CustomRoute, ContractStatusControl } from 'components';
import { PAGE_TITLES, ROUTES, LANG_DICTIONARY } from 'consts';

const { administration, importForm, lockingForm } = PAGE_TITLES;
const {
  importForm: importFormUrl,
  locking,
  addPartners,
  addSubdivisions,
  createPartner,
  updatePartner,
  createBranch,
  updateBranch,
  addUsers,
  createUser,
  updateUser,
  addReports,
  createReport,
  updateReport,
  statusControl,
  addProducts,
  catalogsSection,
  transferData,
  transferDataImportManual,
  transferDataImportAuto,
  schedulerJobs,
  publicApi,
  createPublicApi,
  updatePublicApi,
  templatesUpload,
} = ROUTES;

const infoBlock = {
  description: 'Выполните необходимое действие в интерфейсе справа',
};

const {
  ONE,
  TWO,
  THREE,
  FOUR,
  UPLOAD_TEMPLATES_TITLE,
} = LANG_DICTIONARY;

const pathes = [
  {
    path: transferData,
    title: administration,
    component: TransferData,
  },
  {
    path: transferDataImportManual,
    title: administration,
    component: TransferData,
  },
  {
    path: transferDataImportAuto,
    title: administration,
    component: TransferData,
  },
  {
    path: importFormUrl,
    title: importForm,
    component: Import,
  },
  {
    path: locking,
    title: lockingForm,
    component: Locking,
    infoBlock,
    additionalParams: {
      isForm: true,
    },
  },
  {
    path: addPartners,
    title: administration,
    component: PartnersAdministration,
    activeBreadcrumb: ONE,
  },
  {
    path: addSubdivisions,
    title: administration,
    component: BranchesAdministration,
    activeBreadcrumb: TWO,
  },
  {
    path: createPartner,
    title: administration,
    component: CreatingOrUpdatingPartner,
    activeBreadcrumb: ONE,
  },
  {
    path: `${updatePartner}/:id`,
    title: administration,
    component: CreatingOrUpdatingPartner,
    activeBreadcrumb: ONE,
  },
  {
    path: createBranch,
    title: administration,
    component: CreatingOrUpdatingBranch,
    activeBreadcrumb: TWO,
  },
  {
    path: `${updateBranch}/:id`,
    title: administration,
    component: CreatingOrUpdatingBranch,
    activeBreadcrumb: TWO,
  },
  {
    path: addUsers,
    title: administration,
    component: UserAdministration,
    activeBreadcrumb: THREE,
  },
  {
    path: createUser,
    title: administration,
    component: CreatingOrUpdatingUser,
    activeBreadcrumb: THREE,
  },
  {
    path: `${updateUser}/:id`,
    title: administration,
    component: CreatingOrUpdatingUser,
    activeBreadcrumb: THREE,
  },
  {
    path: addReports,
    title: administration,
    component: ReportsAdministration,
    activeBreadcrumb: FOUR,
  },
  {
    path: createReport,
    title: administration,
    component: CreatingOrUpdatingReport,
    activeBreadcrumb: FOUR,
  },
  {
    path: `${updateReport}/:id`,
    title: administration,
    component: CreatingOrUpdatingReport,
    activeBreadcrumb: FOUR,
  },
  {
    path: statusControl,
    title: administration,
    component: ContractStatusControl,
  },
  {
    path: addProducts,
    title: administration,
    component: DistributingProducts,
    activeBreadcrumb: 5,
  },
  {
    path: catalogsSection,
    title: administration,
    component: CatalogsSection,
    activeBreadcrumb: 6,
  },
  {
    path: schedulerJobs,
    title: administration,
    component: SchedulerForJobs,
  },
  {
    path: publicApi,
    title: administration,
    component: PublicApiList,
    activeBreadcrumb: 7,
  },
  {
    path: createPublicApi,
    title: administration,
    component: CreatingOrUpdatingPublicApi,
    activeBreadcrumb: 7,
  },
  {
    path: `${updatePublicApi}/:id`,
    title: administration,
    component: CreatingOrUpdatingPublicApi,
    activeBreadcrumb: 7,
  },
  {
    path: templatesUpload,
    title: UPLOAD_TEMPLATES_TITLE,
    component: TemplatesContainer,
  },
];

export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
