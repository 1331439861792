import { ConstructorIszCreatingForm } from 'components';
import {
  LANG_DICTIONARY,
  ROUTES,
  REGEXP,
  PAYOUT_FREQ,
  CONSTRUCTOR_ISZ_OPTION_IDS,
} from 'consts';
import {
  CONSTRUCTOR_BARRIERS_MAX,
  CONSTRUCTOR_BARRIERS_MIN,
} from 'config';
import { withCustomRouter } from 'HOC';
import PropTypes from 'prop-types';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import {
  clearConstrutctor,
  getAssets,
  getCalculateCoupon,
  getMinPay,
  getPayouts,
  getPeriods,
  setAssets,
  setBarriersLevels,
  setConstructorPayout,
  setCurrency,
  setMaskConstructor,
  setPeriod,
  clearAssets,
  copyBasketConstructor,
  getSettingsConstructorForFormCreate,
  setDescriptionAssets,
  changeConstructorOptionTypeId,
  setBarrierError,
  setBarrierErrors,
} from 'redux/rootActions';

const {
  ROUBLE,
  DOLLAR,
} = LANG_DICTIONARY;

const propTypes = {
  getAssetsConstructor: PropTypes.func,
  getTimePeriods: PropTypes.func,
  getConstructorPayouts: PropTypes.func,
  setCurrentCurrency: PropTypes.func,
  getMinimalPay: PropTypes.func,
  assets: PropTypes.arrayOf(PropTypes.object),
  periods: PropTypes.arrayOf(PropTypes.object),
  payouts: PropTypes.arrayOf(PropTypes.object),
  constructorFormData: PropTypes.object,
  dopAssets: PropTypes.arrayOf(PropTypes.object),
  setConstructorAssets: PropTypes.func,
  setTimePeriod: PropTypes.func,
  setPayout: PropTypes.func,
  mask: PropTypes.string,
  setMask: PropTypes.func,
  setBarriers: PropTypes.func,
  calculateCoupon: PropTypes.func,
  status: PropTypes.string,
  history: PropTypes.object,
  clearForm: PropTypes.func,
  removeAllAssets: PropTypes.func,
  match: PropTypes.object,
  isLoadingCalculateCoupon: PropTypes.bool,
  copyBasket: PropTypes.func,
  getSettingsConstructor: PropTypes.func,
  setDescription: PropTypes.func,
  saveBarrierErrors: PropTypes.func,
  changeOptionTypeId: PropTypes.func,
};

const defaultProps = {
  match: {},
  clearForm: () => null,
  status: '',
  history: {},
  setBarriers: () => null,
  setMask: () => null,
  mask: '',
  setTimePeriod: () => null,
  setPayout: () => null,
  setConstructorAssets: () => null,
  dopAssets: {},
  constructorFormData: {},
  getAssetsConstructor: () => null,
  getTimePeriods: () => null,
  getConstructorPayouts: () => null,
  setCurrentCurrency: () => null,
  getMinimalPay: () => null,
  assets: [],
  periods: [],
  payouts: [],
  isLoadingCalculateCoupon: false,
  calculateCoupon: () => null,
  removeAllAssets: () => null,
  copyBasket: () => null,
  getSettingsConstructor: () => null,
  setDescription: () => null,
  saveBarrierErrors: () => null,
  changeOptionTypeId: () => null,
};

class ConstructorIszCreating extends Component {
  constructor(props) {
    super(props);
    const { match: { url } } = props;

    this.isEdit = REGEXP.EDIT_BASKET_PAGE.test(url);
  }

  state = {
    assetsError: false,
    periodError: false,
    barriersError: false,
    barriersMinMaxError: false,
    payoutError: false,
  }

  componentDidMount() {
    const {
      clearForm,
      getConstructorPayouts,
      copyBasket,
      match: { params: { basketId, id } },
      getSettingsConstructor,
      setDescription,
    } = this.props;

    clearForm();
    getConstructorPayouts(id);
    setDescription([]);

    if (this.isEdit) {
      copyBasket({ insProductId: id, requestId: basketId });

      return null;
    }

    getSettingsConstructor(id);

    return null;
  }

  checkBarrierOption = (optionType) => (
    [CONSTRUCTOR_ISZ_OPTION_IDS.BARRIER_M, CONSTRUCTOR_ISZ_OPTION_IDS.BARRIER]
      .includes(optionType)
  )

  getMask(numberOfYears) {
    const { mask, setMask } = this.props;
    const newMask = mask.split('-').splice(0, numberOfYears).join('-');
    setMask(newMask);
  }

  handleChangeSelectTerm = (values) => (e, { value: val }) => {
    const {
      getAssetsConstructor,
      removeAllAssets,
      setTimePeriod,
      periods,
    } = this.props;
    const rebuildValues = {
      ...values,
      assets: [],
    };
    const check = periods.map(({ value, text }) => (val === value ? text : null));
    const checkId = check.filter((item) => (item));
    setTimePeriod(rebuildValues, val);
    this.getMask(checkId[0]);
    this.setState({ periodError: false });
    removeAllAssets();
    getAssetsConstructor(val);
  };

  handleChangeSelectPayout = (values) => (e, { value: val }) => {
    const { setPayout, setBarriers } = this.props;
    setPayout(values, val);
    this.setState({ payoutError: false });
    setBarriers([]);
  };

  getBodyRequest = (values, barriers) => {
    const { match: { params: { id } } } = this.props;
    const {
      selectedAssets,
      currency,
      period,
      payout,
      optionType,
    } = values;
    const isBarrierOption = this.checkBarrierOption(optionType);

    const body = {
      actives: selectedAssets,
      currency,
      termId: period,
      insProductId: id,
      refIszOptionTypeId: optionType,
    };

    if (isBarrierOption) {
      body.payoutFreqId = payout;
      body.barriers = barriers;
    }

    return body;
  }

  setCurrencyAndGetPeriod = (values) => {
    const {
      setCurrentCurrency,
      getTimePeriods,
      removeAllAssets,
      match: { params: { id } },
    } = this.props;
    const currency = values.currency === ROUBLE ? DOLLAR : ROUBLE;
    const rebuildValues = {
      ...values,
      assets: [],
    };
    setCurrentCurrency(rebuildValues);
    removeAllAssets();
    getTimePeriods({ currency, id });
  }

  checkBarriers = (values) => {
    const {
      selectedAssets,
      payout,
      period,
      barriers,
    } = values;
    const { saveBarrierErrors } = this.props;
    const count = this.getCountBarriers(values.period, values.payout);

    const barriersTemplate = [...Array(count).keys()];
    const checkBarriers = barriersTemplate
      .reduce((acc, item) => {
        const barrier = barriers[item] || 0;
        const isErrorBarrier = barrier < CONSTRUCTOR_BARRIERS_MIN
          || barrier > CONSTRUCTOR_BARRIERS_MAX;

        return {
          ...acc,
          errors: {
            ...acc.errors,
            [item]: isErrorBarrier,
          },
          isError: acc.isError || isErrorBarrier,
        };
      }, {
        errors: {},
        isError: false,
      });

    saveBarrierErrors(checkBarriers.errors);

    return !selectedAssets.length || !payout || !period || checkBarriers.isError;
  }

  getBasketRequest = (values) => {
    const {
      selectedAssets,
      payout,
      period,
      barriers,
      optionType,
    } = values;
    const { calculateCoupon } = this.props;
    const isBarrierOption = this.checkBarrierOption(optionType);

    const isErrorBarrier = isBarrierOption &&
      this.checkBarriers(values);
    const isPayoutError = isBarrierOption && !payout;
    const isError = !selectedAssets.length || isPayoutError || !period || isErrorBarrier;

    if (!isError) {
      const body = this.getBodyRequest(values, barriers);
      calculateCoupon(body);
    }

    this.setState({
      assetsError: !selectedAssets.length,
      payoutError: isPayoutError,
      periodError: !period,
    });
  }

  handleChangeInput = (value) => {
    const { setBarriers } = this.props;
    this.setState({
      barriersError: false,
      barriersMinMaxError: false,
    });
    setBarriers(value);
  }

  getMnInsuranceSum = (values) => {
    const { getMinimalPay, periods } = this.props;
    const {
      currency,
      selectedAssets,
      period,
      barriers,
      optionType,
      payout,
    } = values;
    const isBarrierOption = this.checkBarrierOption(optionType);

    this.setState({ assetsError: !selectedAssets.length });
    const isBarrierErrors = isBarrierOption &&
      this.checkBarriers(values);

    if (selectedAssets.length && !isBarrierErrors) {
      const { text } = periods.find(({ value }) => period === value);

      getMinimalPay({
        currency,
        assets: selectedAssets,
        period: text,
        refInsDateUnitId: payout,
        basketBarriersText: barriers.join('-'),
      });
    }
  };

  setErrorMultiSelect = (type) => {
    this.setState({
      assetsError: type,
    });
  };

  backToConstructor = () => {
    const { match: { params: { id } }, history } = this.props;
    history.push(`${ROUTES.constructorIsz}/${id}`);
  };

  setAssets = (values) => {
    const { setConstructorAssets } = this.props;
    this.setState({ assetsError: false });
    setConstructorAssets(values);
  }

  getCountBarriers = (termId, periodId) => {
    const { periods } = this.props;
    const period = periods.find((item) => item.value === termId);

    if (periodId === PAYOUT_FREQ.SIMULTANEOUSLY_AT_THE_END_OF_TIME) {
      return 1;
    }

    if (!period) {
      return 0;
    }

    return Number(period.text);
  }

  handleChangeOptionType = (e, { value }) => {
    const { changeOptionTypeId } = this.props;
    changeOptionTypeId(value);
  }

  render() {
    const {
      assets,
      periods,
      payouts,
      constructorFormData,
      dopAssets,
      status,
      history,
      isLoadingCalculateCoupon,
      match: { params: { id } },
      constructorFormData: {
        period,
      },
    } = this.props;

    const {
      assetsError,
      periodError,
      barriersError,
      barriersMinMaxError,
      payoutError,
    } = this.state;

    const ourErrors = {
      assetsError,
      periodError,
      barriersError,
      barriersMinMaxError,
      payoutError,
    };

    return (
      <ConstructorIszCreatingForm
        productId={id}
        status={status}
        history={history}
        initialValues={constructorFormData}
        dopAssets={dopAssets}
        setCurrency={this.setCurrencyAndGetPeriod}
        getMinPay={this.getMnInsuranceSum}
        assets={assets}
        period={period}
        periods={periods}
        payouts={payouts}
        setAssets={this.setAssets}
        handleChangeSelectPayout={this.handleChangeSelectPayout}
        getCountBarriers={this.getCountBarriers}
        handleSubmitSuccess={this.getBasketRequest}
        ourErrors={ourErrors}
        setErrorMultiSelect={this.setErrorMultiSelect}
        handleChangeInput={this.handleChangeInput}
        handleChangeSelectTerm={this.handleChangeSelectTerm}
        backToConstructor={this.backToConstructor}
        isLoadingCalculateCoupon={isLoadingCalculateCoupon}
        onChangeOptionType={this.handleChangeOptionType}
        checkBarrierOption={this.checkBarrierOption}
        {...this.props}
      />
    );
  }
}

const mapStateToProps = ({
  constructorIszInterface,
}) => ({
  ...constructorIszInterface,
});

const mapDispatchToProps = {
  getAssetsConstructor: getAssets,
  getMinimalPay: getMinPay,
  setCurrentCurrency: setCurrency,
  getTimePeriods: getPeriods,
  getConstructorPayouts: getPayouts,
  setConstructorAssets: setAssets,
  setPayout: setConstructorPayout,
  setTimePeriod: setPeriod,
  setMask: setMaskConstructor,
  setBarriers: setBarriersLevels,
  calculateCoupon: getCalculateCoupon,
  clearForm: clearConstrutctor,
  removeAllAssets: clearAssets,
  copyBasket: copyBasketConstructor,
  getSettingsConstructor: getSettingsConstructorForFormCreate,
  setDescription: setDescriptionAssets,
  changeOptionTypeId: changeConstructorOptionTypeId,
  saveBarrierError: setBarrierError,
  saveBarrierErrors: setBarrierErrors,
};

ConstructorIszCreating.propTypes = propTypes;
ConstructorIszCreating.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(ConstructorIszCreating),
);
