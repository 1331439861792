import {
  GET_ERROR,
  TOGGLE_MENU,
  SEARCH_ADDRESS_REQUEST,
  SEARCH_ADDRESS_VALUE,
  SEARCH_ADDRESS_RESET,
  SEARCH_FMS_UNIT_REQUEST,
  SEARCH_FMS_UNIT_VALUE,
  SEARCH_FMS_UNIT_RESET,
  GET_OPTIONS_REQUEST,
  GET_MULTIPLE_OPTIONS,
  SEARCH_REGION,
  SEARCH_REGION_RESET,
} from 'redux/reducers/types';

const toggleMenu = (systemClose) => ({ type: TOGGLE_MENU, payload: { systemClose } });

const getError = (error, itemType) => ({
  type: GET_ERROR,
  payload: { error, itemType },
});

const searchAddress = (address) => ({
  type: SEARCH_ADDRESS_REQUEST,
  payload: { address },
});

const searchFmsUnit = (code) => ({
  type: SEARCH_FMS_UNIT_REQUEST,
  payload: { code },
});

const setAddress = (address) => ({
  type: SEARCH_ADDRESS_VALUE,
  payload: { address },
});

const setFmsUnit = (address) => ({
  type: SEARCH_FMS_UNIT_VALUE,
  payload: { address },
});

const searchRegion = (region, isLivingAddress) => ({
  type: SEARCH_REGION.request,
  payload: { region, isLivingAddress },
});

const resetSearchRegion = () => ({
  type: SEARCH_REGION_RESET,
});

const getOptions = (type, id) => ({
  type: GET_OPTIONS_REQUEST,
  payload: { type, id },
});

const getMultipleOptions = (id) => ({
  type: GET_MULTIPLE_OPTIONS,
  payload: { id },
});

const resetSearchAdress = () => ({
  type: SEARCH_ADDRESS_RESET,
});

const resetSearchFmsUnit = () => ({
  type: SEARCH_FMS_UNIT_RESET,
});

export {
  toggleMenu,
  getError,
  searchAddress,
  resetSearchAdress,
  searchFmsUnit,
  resetSearchFmsUnit,
  setAddress,
  setFmsUnit,
  getOptions,
  getMultipleOptions,
  searchRegion,
  resetSearchRegion,
};
