import moment from 'moment';

import {
  GET_PRODUCT_INFO,
  CHANGE_FIELD,
  CLEAR_STORE_KSZ_PRODUCTS,
  SAVE_DATA,
  CHECK_ACCOUNT_NUMBERS,
  SET_STAGE_PRODUCT,
  COPY_KSZ_CONTRACT,
  SET_FIELDS_TOUCHED,
  SET_RESTRICTIONS,
} from '../types';

const INITIAL_FIELD_VALUES = {
  creditStartDate: new Date(),
  errors: {},
  hints: {},
  touched: {
    creditStartDate: true,
    creditTerm: false,
    creditAmount: false,
    creditCurrency: false,
    creditInsurancePremium: true,
  },
};

const INITIAL_STATE = {
  kszProductValues: {
    productsGrouping: [],
    productRiskTariffs: [],
  },
  fieldValues: INITIAL_FIELD_VALUES,
  calculatorData: {},
  insurerValues: null,
  insuredValues: null,
  childInsuredValues: {},
  insuredIsSame: true,
  contractData: {},
  beneficiaryValues: null,
  beneficiaryByLaw: true,
  stage: 1,
  genderList: [],
  risksLists: {
    basic: false,
    additional: false,
    additionalPackage: false,
  },
  isCurrencyScenario: null,
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHECK_ACCOUNT_NUMBERS.success: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }

    case CHECK_ACCOUNT_NUMBERS.fail: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }

    case SAVE_DATA: {
      return {
        ...state,
        ...payload,
        stage: 2,
      };
    }

    case CLEAR_STORE_KSZ_PRODUCTS: {
      return INITIAL_STATE;
    }

    case SET_FIELDS_TOUCHED: {
      const { fieldValues } = state;

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          touched: {
            ...fieldValues.touched,
            creditStartDate: true,
            creditTerm: true,
            creditAmount: true,
            creditCurrency: true,
            creditInsurancePremium: true,
          },
        },
      };
    }

    case CHANGE_FIELD: {
      const { fieldValues } = state;
      const { name, value } = payload;

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          [name]: value,
          touched: {
            ...fieldValues.touched,
            [name]: true,
          },
        },
      };
    }

    case GET_PRODUCT_INFO.success: {
      const { fieldValues } = state;
      const { refExchangeRatesRegimesCoefs } = payload;
      const firstGroupping = payload.productsGrouping[0];
      const isDefaultProduct = payload.productsGrouping.length &&
        firstGroupping.mainProduct.id === firstGroupping.groupedProduct.id;

      return {
        ...state,
        kszProductValues: payload,
        fieldValues: {
          ...fieldValues,
          creditCurrency: isDefaultProduct ?
            firstGroupping.mainProduct.refCurrencies.id :
            fieldValues.creditCurrency,
          productId: isDefaultProduct ?
            firstGroupping.mainProduct.insProduct.id :
            fieldValues.productId,
          touched: {
            ...fieldValues.touched,
            creditCurrency: isDefaultProduct || fieldValues.touched.creditCurrency,
          },
          creditStartDate: moment(payload.regDate).toDate(),
        },
        refExchangeRatesRegimesCoefs,
      };
    }

    case SET_STAGE_PRODUCT: {
      return {
        ...state,
        stage: payload,
      };
    }

    case COPY_KSZ_CONTRACT.success: {
      const insProduct = state.kszProductValues.productsGrouping.find((productGrouping) => (
        productGrouping.groupedProduct.refCurrencies.id === payload.currencyRateId
      ));

      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          creditStartDate: moment(payload.dateReg).toDate(),
          creditTerm: payload.term,
          creditAmount: payload.creditSum,
          creditCurrency: payload.currencyRateId,
          creditEndDate: moment(payload.dateEnd).toDate(),
          creditInsuranceSum: payload.insuranceAmmount,
          creditInsurancePremium: payload.policyAmmount,
          productId: insProduct ? insProduct.groupedProduct.id : payload.productId,
        },
      };
    }

    case SET_RESTRICTIONS:
      return {
        ...state,
        kszProductValues: {
          ...state.kszProductValues,
          restrictions: payload,
        },
      };

    default:
      return state;
  }
};
