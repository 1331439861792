import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import IPeditorRow from './row';
import './styles.scss';

const { ADD_IP_ADDRESS } = LANG_DICTIONARY;
const b = block('ip-editor');

const propTypes = {
  ipAddresses: PropTypes.arrayOf(PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
    ip: PropTypes.string,
  })),
  onRemoveIp: PropTypes.func,
  onAddIp: PropTypes.func,
  onChangeIp: PropTypes.func,
};

const defaultProps = {
  ipAddresses: [],
  onRemoveIp: () => null,
  onAddIp: () => null,
  onChangeIp: () => null,
};

const IPeditor = ({
  ipAddresses,
  onRemoveIp,
  onAddIp,
  onChangeIp,
}) => {
  const [focusId, setFocusId] = useState('');

  return (
    <div className={b()}>
      <div>
        {ipAddresses.map(({ id, ip }) => (
          <IPeditorRow
            onRemoveIp={onRemoveIp}
            onChangeIp={onChangeIp}
            key={id}
            id={id}
            ip={ip}
            focusId={focusId}
            setFocusId={setFocusId}
          />
        ))}
      </div>
      <button
        type="button"
        onClick={onAddIp}
        className={b('add-button').toString()}
      >
        {ADD_IP_ADDRESS}
      </button>
    </div>
  );
};

IPeditor.defaultProps = defaultProps;
IPeditor.propTypes = propTypes;

export default IPeditor;
