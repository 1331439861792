import {
  GET_CONTRACT_INFO_START,
  GET_CONTRACT_INFO_FAIL,
  GET_CONTRACT_INFO_SUCCESS,
  CLEAR_CONTRACT_INFO,
  UPDATE_SCAN_CONTRACT_SUCCESS,
  UPDATE_SCAN_CONTRACT_START,
  UPDATE_SCAN_CONTRACT_FAIL,
} from '../types';

const INITIAL_STATE = {
  beneficiary: [],
  contract: {},
  insured: [],
  insurer: [],
  garants: [],
  ransoms: [],
  updateScanLoading: false,
};

const setLoadingUpdateScan = (status, state) => {
  const {
    beneficiary,
    contract,
    insured,
    insurer,
    garants,
  } = state;

  return {
    beneficiary,
    contract,
    insured,
    insurer,
    garants,
    updateScanLoading: status,
  };
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case UPDATE_SCAN_CONTRACT_START:
      return setLoadingUpdateScan(true, state);

    case UPDATE_SCAN_CONTRACT_FAIL:
      return setLoadingUpdateScan(false, state);

    case UPDATE_SCAN_CONTRACT_SUCCESS:
      return setLoadingUpdateScan(false, state);

    case GET_CONTRACT_INFO_START:
      return {
        ...state,
        loading: true,
      };
    case GET_CONTRACT_INFO_FAIL:
      return {
        ...state,
        loading: false,
        error: payload,
      };
    case GET_CONTRACT_INFO_SUCCESS: {
      const {
        beneficiary,
        contract,
        insured,
        insurer,
        garants,
        ransoms,
      } = payload;

      return {
        ...state,
        beneficiary,
        contract,
        insurer,
        insured,
        garants,
        ransoms,
      };
    }
    case CLEAR_CONTRACT_INFO:
      return INITIAL_STATE;

    default:
      return state;
  }
};
