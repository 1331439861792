import React, { Component } from 'react';
import PropTypes from 'prop-types';
import CurrencyInput from 'react-currency-input';
import { block } from 'bem-cn';
import {
  Select,
} from 'semantic-ui-react';
import { updateItem, setSpaceNumber } from 'helpers';
import { LANG_DICTIONARY } from 'consts';
import { FieldBlock, ErrorMessage } from 'components';
import './styles.scss';

const propTypes = {
  setPayoutSum: PropTypes.func,
  payout: PropTypes.string,
  payoutsArray: PropTypes.arrayOf(PropTypes.object),
  selectList: PropTypes.arrayOf(PropTypes.object),
  mySalesTable: PropTypes.bool,
  currencyShort: PropTypes.string,
  haveError: PropTypes.bool,
};

const defaultProps = {
  setPayoutSum: () => null,
  payout: '',
  payoutsArray: [],
  selectList: [],
  mySalesTable: false,
  currencyShort: '',
  haveError: false,
};
const {
  PAYOUT_PERIODS_CONTRACT,
  PAYOUT_CONTRACT,
  CONTRACT_PREMIUM,
  PAYOUTED_CONTRACT,
  PAYOUT_STATUS,
  NOT_CHOOSED,
  INSERT_PAYOUTED_SUM,
} = LANG_DICTIONARY;

class PayoutInfo extends Component {
  get currencySuffix() {
    const { currencyShort } = this.props;

    return ` ${currencyShort}`;
  }

    handleChangeInput = (index) => (event, maskedValue) => {
      const { setPayoutSum, payoutsArray } = this.props;
      const zeroField = `0${this.currencySuffix}`;
      const newPayouts = updateItem(payoutsArray, index, {
        ...payoutsArray[index],
        totalPaymentCur: maskedValue === zeroField ? null : maskedValue,
      });
      setPayoutSum(newPayouts);
    }

    handleChangeSelect = (index) => (e, { value }) => {
      const { setPayoutSum, payoutsArray } = this.props;
      const newPayouts = updateItem(payoutsArray, index, {
        ...payoutsArray[index],
        paymentConditionId: value,
      });
      setPayoutSum(newPayouts);
    }

    render() {
      const {
        payout,
        payoutsArray,
        selectList,
        mySalesTable,
        currencyShort,
        haveError,
      } = this.props;
      const b = mySalesTable ? block('payout-info-table') : block('payout-info');

      return (
        <div className={b()}>
          <FieldBlock>
            <div className={b('header-wrapper')}>
              <div className={b('header-payout-periods')}>{`${PAYOUT_PERIODS_CONTRACT}: ${payout}`}</div>
              <div className={b('header')}>
                <div className={b('header-text')}>{PAYOUT_CONTRACT}</div>
                <div className={b('header-text')}>{CONTRACT_PREMIUM}</div>
                <div className={b('header-text')}>{PAYOUTED_CONTRACT}</div>
                <div className={b('header-text')}>{PAYOUT_STATUS}</div>
              </div>
            </div>
            <div className={b('table-wrapper')}>
              { payoutsArray.map((item, index) => (
                <div className={b('table-row')} key={item.id}>
                  <div className={b('table-text')}><p>{item.paymentOrder || '-'}</p></div>
                  <div className={b('table-text')}><p>{item.premiumCur ? `${setSpaceNumber(item.premiumCur)} ${currencyShort}` : null}</p></div>
                  <div className={b('table-text')}>
                    {mySalesTable ? <p>{item.totalPaymentCur ? `${setSpaceNumber(item.totalPaymentCur) || ''} ${currencyShort}` : null}</p>
                      : (
                        <CurrencyInput
                          value={item.totalPaymentCur}
                          onChangeEvent={this.handleChangeInput(index)}
                          suffix={` ${currencyShort}`}
                          inputType="tel"
                          precision={0}
                          placeholder="Не оплачено"
                        />
                      )}
                  </div>
                  <div className={b('table-text')}>
                    {mySalesTable ? <p>{item.paymentCondition}</p> : (
                      <Select
                        options={selectList}
                        value={item.paymentConditionId}
                        placeholder={NOT_CHOOSED}
                        disabled={mySalesTable}
                        onChange={this.handleChangeSelect(index)}
                      />
                    )}
                  </div>
                </div>
              ))}
            </div>
            {haveError && <ErrorMessage message={INSERT_PAYOUTED_SUM} />}
          </FieldBlock>
        </div>
      );
    }
}

PayoutInfo.propTypes = propTypes;
PayoutInfo.defaultProps = defaultProps;
export default PayoutInfo;
