import { API } from 'consts';
import { ITEM_COUNT_UNDERWRITING_LIST_PAGE } from 'config';
import { apiCall, getError, pagination } from 'helpers';
import { put, takeLatest } from 'redux-saga/effects';
import { switchPaginationPageUnderwriting, searchContractsForUnderwriting } from 'redux/rootActions';
import {
  SEARCH_CONTRACTS_SUCCESS,
  SEARCH_CONTRACTS_START,
  SEARCH_CONTRACTS_REQUEST,
  SEARCH_CONTRACTS_FAIL,
} from '../types';

const { SEARCH_CONTRACTS_FOR_UNDERWRITING } = API;

function* searchContracts({ payload }) {
  try {
    const { page, body } = payload;
    yield put({ type: SEARCH_CONTRACTS_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: SEARCH_CONTRACTS_FOR_UNDERWRITING,
      body: {
        ...body,
        offset: `${ITEM_COUNT_UNDERWRITING_LIST_PAGE * (page - 1)}`,
        limit: ITEM_COUNT_UNDERWRITING_LIST_PAGE,
      },
    });
    const paginationData = pagination(data.data, ITEM_COUNT_UNDERWRITING_LIST_PAGE);
    yield put({ type: SEARCH_CONTRACTS_SUCCESS, payload: { paginationData, data } });
    if (!paginationData.length && page !== 1) {
      yield put(switchPaginationPageUnderwriting(page - 1));
      yield searchContractsForUnderwriting({ page: page - 1, body });
    } else {
      yield put(switchPaginationPageUnderwriting(page));
    }
  } catch (error) {
    yield put({ type: SEARCH_CONTRACTS_FAIL, payload: getError(error) });
  }
}

function* underwritingListSaga() {
  yield takeLatest(SEARCH_CONTRACTS_REQUEST, searchContracts);
}

export default underwritingListSaga;
