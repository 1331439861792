import React, {
  useMemo,
  useEffect,
  useState,
  useRef,
} from 'react';
import PropTypes from 'prop-types';
import { useSelector } from 'react-redux';
import { LANG_DICTIONARY } from 'consts';
import { Icon } from 'semantic-ui-react';
import { dateFormatter } from 'helpers';
import { selectOptionType } from '../../../../../../redux/basketConstructor/selectors';

const {
  ASSETS_BASKET,
  PERIOD,
  PAYOUT_TIMES,
  BARRIERS,
  COUPON,
  DOING_END,
  STRATEGY_TYPE,
  ADDITIONAL_WORD,
  AUTOCALL_BARRIER,
  AUTOCALL_BONUS_COUPON,
  ID,
  PARTICIPATION_COEFFICIENT,
  GROWTH_CAP,
} = LANG_DICTIONARY;

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  onClickAssets: PropTypes.func.isRequired,
  block: PropTypes.func.isRequired,
  startArrowPosition: PropTypes.object,
  mainRequest: PropTypes.object.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onSelectItem: PropTypes.func.isRequired,
  childSelectedIds: PropTypes.shape({}).isRequired,
  childTemplateColumns: PropTypes.string.isRequired,
};

const defaultProps = {
  startArrowPosition: {},
};

const CORRECTION_WIDTH_LINE_ARROW_BY_ARROW_FIGURE = -7;

const ChildOptionTable = ({
  data,
  block,
  mainRequest,
  isOpen,
  startArrowPosition,
  onClickAssets,
  onSelectItem,
  childSelectedIds,
  childTemplateColumns,
}) => {
  const containerRef = useRef(null);
  const additionMarkClass = block(`additional-mark-${mainRequest.id}`).toString();

  const [firstArrowLineStyle, setFirstArrowLineStyle] = useState({});
  const [connectingArrowPositionState, setConnectingArrowPositionState] = useState({});

  const groupedChild = useMemo(() => Object.entries(data.reduce((acc, item) => ({
    ...acc,
    [item.refIszOptionTypeCode]: [
      ...(acc[item.refIszOptionTypeCode] || []),
      item,
    ],
  }), {})), [data]);

  const addConnectingArrowPositionState = (index, state) => {
    setConnectingArrowPositionState({
      ...connectingArrowPositionState,
      [index]: state,
    });
  };

  const calculateAdditionalMarkPosition = (element) => ({
    X: element.offsetLeft,
    Y: element.offsetTop + element.offsetHeight / 2,
  });

  const getStyleForFirstArrow = (element) => {
    const { Y } = calculateAdditionalMarkPosition(element);

    const coefficientHeight = groupedChild.length > 1 ? 0.75 : 1;

    return {
      left: startArrowPosition.X,
      height: Y * coefficientHeight,
      width: startArrowPosition.width / 2 + CORRECTION_WIDTH_LINE_ARROW_BY_ARROW_FIGURE,
    };
  };

  const getStyleForConnectingArrow = (outElement, inElement) => {
    const arrowPositionOut = calculateAdditionalMarkPosition(outElement);
    const arrowPositionIn = calculateAdditionalMarkPosition(inElement);
    const height = arrowPositionIn.Y - arrowPositionOut.Y;

    return {
      left: startArrowPosition.X,
      top: arrowPositionOut.Y + height / 4,
      height: arrowPositionIn.Y - arrowPositionOut.Y - height / 2,
      width: startArrowPosition.width / 2 + CORRECTION_WIDTH_LINE_ARROW_BY_ARROW_FIGURE,
      display: 'block',
    };
  };

  useEffect(() => {
    if (isOpen) {
      setImmediate(() => {
        const childElements = containerRef.current ?
          containerRef.current.getElementsByClassName(additionMarkClass) :
          [];
        const [firstElement] = childElements;

        if (firstElement) {
          const newFirstArrowStyle = getStyleForFirstArrow(firstElement);
          setFirstArrowLineStyle(newFirstArrowStyle);
        }

        Array.from(childElements).slice(0, -1).forEach((item, index) => {
          const connectingArrowStyle = getStyleForConnectingArrow(item, childElements[index + 1]);
          addConnectingArrowPositionState(index, connectingArrowStyle);
        });
      });
    }
  }, [
    data,
    isOpen,
    startArrowPosition,
  ]);

  const selectedOptionType = useSelector(selectOptionType);

  const getGridTemplateColumns = (isCoupon, child) => [
    'minmax(40px, 1fr)', // ID
    'minmax(40px, 1fr)', // Str. type
    'minmax(40px, 1fr)', // Assets
    'minmax(40px, 1fr)', // Term
    'minmax(40px, 1fr)', // Freq
    'minmax(40px, 1fr)', // Growth cap
    'minmax(40px, 1fr)', // Barriers
    ...(isCoupon ? [
      'minmax(40px, 1fr)', // Coupon
    ] : [
      'minmax(40px, 1fr)', // Part coef
    ]),

    'minmax(40px, 1fr)', // Date end
    ...(isCoupon && child[0].isAutocall ? [
      'minmax(40px, 1fr)', // AC Barrier
      'minmax(40px, 1fr)', // AC Bonus Coupon
    ] : []),
  ].join(' ');

  return (
    <>
      <div className={block('child-baskets-wrapper')} ref={containerRef}>
        <div style={firstArrowLineStyle} className={block('first-connecting-arrow')} />
        {groupedChild.map(([code, child], index) => {
          const isCoupon = selectedOptionType.find((option) => option.name === code)?.isCoupon;

          return (
            <div
              className={block('child-item')}
              key={`${mainRequest.id}-${code}`}
            >
              <div style={connectingArrowPositionState[index]} className={block('child-connecting-arrow')} />
              <div className={block('grid').toString()} style={{ gridTemplateColumns: childTemplateColumns }}>
                <div />
                <div className={block('additional-mark').mix(additionMarkClass).toString()}>{ADDITIONAL_WORD}</div>
                <div className={(block('grid')).toString()} style={{ gridTemplateColumns: getGridTemplateColumns(isCoupon, child) }}>
                  <div className={`${block('row').toString()} ${block('table-header').toString()}`}>
                    <div>{ID}</div>
                    <div>{STRATEGY_TYPE}</div>
                    <div>{ASSETS_BASKET}</div>
                    <div>{PERIOD}</div>
                    <div>{GROWTH_CAP}</div>
                    <div>{PAYOUT_TIMES}</div>
                    <div>{BARRIERS}</div>
                    {
                      isCoupon ? (
                        <>
                          <div>{COUPON}</div>
                        </>
                      ) : (
                        <div>{PARTICIPATION_COEFFICIENT}</div>
                      )
                    }

                    <div>{DOING_END}</div>
                    {
                      isCoupon && child[0].isAutocall && (
                        <>
                          <div>{AUTOCALL_BARRIER}</div>
                          <div>{AUTOCALL_BONUS_COUPON}</div>
                        </>
                      )
                    }
                  </div>
                  {child.map((basket) => {
                    const {
                      id,
                      refIszOptionTypeDescription,
                      basketAssetsText,
                      term,
                      refInsDateUnitValue2,
                      basketBarriersText,
                      coupon,
                      dateEnd,
                      isAutocall,
                      autocallBarrier,
                      autocallBonusCoupon,
                      insLineIszCalcBasketId,
                      partitionCoeff,
                      growthCap,
                    } = basket;

                    return (
                      <div
                        role="row"
                        tabIndex={0}
                        key={`row-main-${mainRequest.id}-child-${id}`}
                        className={block('row', { choosed: Boolean(childSelectedIds[id]) }).toString()}
                        onClick={() => onSelectItem(basket, mainRequest)}
                      >
                        <div>{insLineIszCalcBasketId}</div>
                        <div>{refIszOptionTypeDescription}</div>
                        <div className={block('asset-button').toString()}>
                          {basketAssetsText}
                          <Icon
                            name="info circle"
                            className={block('asset-icon').toString()}
                            onClick={(e) => onClickAssets(e, id)}
                          />
                        </div>
                        <div>{dateFormatter(term, 'год')}</div>
                        <div>{refInsDateUnitValue2}</div>
                        <div>{growthCap}</div>
                        <div>{basketBarriersText}</div>
                        {
                          isCoupon ? (
                            <>
                              <div>{coupon && `${coupon}%`}</div>
                            </>
                          ) : (
                            <div>{partitionCoeff && `${partitionCoeff}%`}</div>
                          )
                        }
                        <div>{dateEnd}</div>
                        {isCoupon && isAutocall && (
                        <>
                          <div>{autocallBarrier}</div>
                          <div>{autocallBonusCoupon}</div>
                        </>
                        )}
                      </div>
                    );
                  })}
                </div>
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

ChildOptionTable.propTypes = propTypes;
ChildOptionTable.defaultProps = defaultProps;
export default ChildOptionTable;
