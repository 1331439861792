import { put, takeLatest } from 'redux-saga/effects';
import { apiCall, getError, apiDownload } from 'helpers';
import { closeModalUploadScan, getEventsCount } from 'redux/rootActions';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY } from 'consts';
import {
  GET_CONTRACT_INFO_REQUEST,
  GET_CONTRACT_INFO_SUCCESS,
  GET_CONTRACT_INFO_START,
  GET_CONTRACT_INFO_FAIL,
  DOWNLOAD_SCAN,
  UPDATE_SCAN_CONTRACT_FAIL,
  UPDATE_SCAN_CONTRACT_REQUEST,
  UPDATE_SCAN_CONTRACT_START,
  UPDATE_SCAN_CONTRACT_SUCCESS,
} from '../types';

const {
  GET_CONTRACT_INFO,
  GET_SCAN,
  UPLOAD_NEW_SCAN,
} = API;

const { SUCCESS_SCAN_UPLOAD } = LANG_DICTIONARY;

function* getContractInfo({ payload }) {
  try {
    yield put({ type: GET_CONTRACT_INFO_START });
    const { data } = yield apiCall({
      type: 'POST',
      url: GET_CONTRACT_INFO,
      body: {
        ctsId: payload,
      },
    });
    yield put({ type: GET_CONTRACT_INFO_SUCCESS, payload: data });
  } catch (error) {
    yield put({ type: GET_CONTRACT_INFO_FAIL, payload: getError(error) });
  }
}

function* getScan({ payload, fileName }) {
  try {
    yield apiDownload({
      type: 'GET',
      url: `${GET_SCAN}/${payload}`,
    }, fileName);
  } catch (error) {
    yield put({ type: GET_CONTRACT_INFO_FAIL, payload: getError(error) });
  }
}

function* updateScan({ payload: { formData, ctsId } }) {
  try {
    yield put({ type: UPDATE_SCAN_CONTRACT_START });
    yield apiCall({
      type: 'POST',
      url: UPLOAD_NEW_SCAN,
      body: formData,
    });
    yield put(closeModalUploadScan());
    yield put({ type: UPDATE_SCAN_CONTRACT_SUCCESS });
    yield getContractInfo({ payload: ctsId });
    yield put(getEventsCount());
    toastr.success('', SUCCESS_SCAN_UPLOAD);
  } catch (error) {
    yield put({ type: UPDATE_SCAN_CONTRACT_FAIL, payload: getError(error) });
  }
}

function* contractInfoSaga() {
  yield takeLatest(UPDATE_SCAN_CONTRACT_REQUEST, updateScan);
  yield takeLatest(GET_CONTRACT_INFO_REQUEST, getContractInfo);
  yield takeLatest(DOWNLOAD_SCAN, getScan);
}

export default contractInfoSaga;
