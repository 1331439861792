import React, { Component } from 'react';
import { withCustomRouter } from 'HOC';
import { AdministrativeSectionInterface, CreatingOrUpdatingBranchForm } from 'components';
import PropTypes from 'prop-types';
import {
  createBranch,
  getBranchDataForUpdate,
  updateBranch,
  clearEdtiorAdminBranchesForm,
  selectOption,
  getUsersAdminBranchesInterface,
  setIsTestBranch,
  getListOfCtsSources,
} from 'redux/rootActions';
import { connect } from 'react-redux';
import { ROUTES } from 'consts';

const propTypes = {
  match: PropTypes.object,
  getBranchData: PropTypes.func,
  clearForm: PropTypes.func,
  createNewBranch: PropTypes.func,
  updateBranchById: PropTypes.func,
  active: PropTypes.oneOfType([
    PropTypes.string,
    PropTypes.number,
  ]),
  history: PropTypes.object,
  partnerFormData: PropTypes.object,
  location: PropTypes.object,
  setOption: PropTypes.func,
  branchFormData: PropTypes.object,
  getUsers: PropTypes.func.isRequired,
  users: PropTypes.arrayOf(PropTypes.object),
  isLoadingUsers: PropTypes.bool,
  branchId: PropTypes.number,
  isTestBranch: PropTypes.bool,
  setBranchIsTest: PropTypes.func,
  getCtsSources: PropTypes.func,
  ctsSourcesCodes: PropTypes.arrayOf(PropTypes.object),
};

const defaultProps = {
  match: {},
  getBranchData: () => null,
  clearForm: () => null,
  createNewBranch: () => null,
  updateBranchById: () => null,
  active: '',
  history: {},
  partnerFormData: {},
  location: {},
  setOption: () => null,
  branchFormData: {},
  users: [],
  isLoadingUsers: false,
  branchId: 0,
  isTestBranch: false,
  setBranchIsTest: () => null,
  getCtsSources: () => null,
  ctsSourcesCodes: [],
};

class CreatingOrUpdateBranch extends Component {
  constructor(props) {
    super(props);
    const {
      match: {
        params: {
          id,
        },
      },
    } = props;
    this.selectPrefix = 'update';
    this.updatedBranch = id || null;
    this.handleSubmitSuccess = id ? this.updateBranch : this.createBranch;
  }

  componentDidMount() {
    const { getBranchData, getCtsSources } = this.props;
    const { location: { state } } = this.props;
    const isParentBranch = state && state.parent;
    const id = state && state.id;

    getCtsSources();

    if (this.updatedBranch || isParentBranch) {
      getBranchData(this.updatedBranch || id, isParentBranch);
    }
  }

  componentWillUnmount() {
    const { clearForm, setOption } = this.props;
    setOption('partners', null, this.selectPrefix);
    setOption('branches', null, this.selectPrefix);
    clearForm();
  }

  getTreeBranchName = (parentBranchId, branchName, isRootBranch) => {
    const getValues = (treeBranch) => ({
      treeBranch,
    });

    const { [`${this.selectPrefix}branches`]: branches } = this.props;
    if (isRootBranch || !parentBranchId) return getValues(branchName);
    const parentBranch = branches && branches.find((branch) => branch.value === parentBranchId);
    if (!parentBranch) return getValues(branchName);

    return getValues(`${parentBranch.text}/${branchName}`);
  }

  getBodyRequest = (values) => {
    const {
      branchAddress,
      branchName,
      parentBranchId,
      partnerId,
      isRootBranch,
      codeOKATO,
      bankCode,
      curatorId,
      isTest,
      massSegment,
      ctsSourceCode,
      isNeedScans,
    } = values;

    return {
      branchAddress,
      branchName,
      parentBranchId,
      isRootBranch,
      partnerId,
      ...this.getTreeBranchName(parentBranchId, branchName, isRootBranch),
      codeOKATO,
      bankCode,
      curatorId: curatorId || undefined,
      isTest,
      massSegment,
      refCtsSourceCode: ctsSourceCode,
      isNeedScans,
    };
  }

  createBranch = (values) => {
    const { history, createNewBranch } = this.props;
    const body = this.getBodyRequest(values);
    createNewBranch(body);
    history.push(ROUTES.addSubdivisions, { cache: true });
  };

  updateBranch = (values) => {
    const {
      updateBranchById,
      branchId,
      isTestBranch,
      setBranchIsTest,
    } = this.props;
    const body = this.getBodyRequest(values);
    const { isTest } = body;
    const isUserBranch = Number(this.updatedBranch) === branchId;
    const testBranchValue = isUserBranch ? isTest : isTestBranch;
    updateBranchById(this.updatedBranch, body);
    setBranchIsTest(testBranchValue);
  }

  render() {
    const {
      active,
      history,
      branchFormData,
      getUsers,
      users,
      isLoadingUsers,
      ctsSourcesCodes,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active} buttonInterface="back">
        <CreatingOrUpdatingBranchForm
          history={history}
          handleSubmitSuccess={this.handleSubmitSuccess}
          isUpdatedForm={Boolean(this.updatedBranch)}
          getTreeBranchName={this.getTreeBranchName}
          initialValues={branchFormData}
          getUsers={getUsers}
          users={users}
          isLoadingUsers={isLoadingUsers}
          ctsSourcesCodes={ctsSourcesCodes}
        />
      </AdministrativeSectionInterface>
    );
  }
}

const mapStateToProps = ({
  branchesAdminInterface: {
    branchFormData,
    users,
    isLoadingUsers,
    ctsSourcesCodes,
  },
  authReducer: {
    branchId,
    isTestBranch,
  },
  selects,
}) => ({
  branchFormData,
  users,
  isLoadingUsers,
  branchId,
  isTestBranch,
  ctsSourcesCodes,
  ...selects,
});

const mapDispatchToProps = {
  createNewBranch: createBranch,
  updateBranchById: updateBranch,
  getBranchData: getBranchDataForUpdate,
  clearForm: clearEdtiorAdminBranchesForm,
  setOption: selectOption,
  getUsers: getUsersAdminBranchesInterface,
  setBranchIsTest: setIsTestBranch,
  getCtsSources: getListOfCtsSources,
};

CreatingOrUpdateBranch.propTypes = propTypes;
CreatingOrUpdateBranch.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(CreatingOrUpdateBranch),
);
