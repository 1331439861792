import {
  ASSET_COLOR,
  CONSTRUCTOR_ISZ_OPTION_CODES,
  PAYOUT_CODE_REF,
} from 'consts';

const minBonusCoupon = 0;
const maxBonusCoupon = 999;

const autocallBarrierRestrictionParameter = 'Autocall barrier';
const bonusCouponRestrictionParameter = 'Bonus-coupon';
const notionalBarrierRestrictionParameter = 'Notional barrier';
const gearedBarrierRestrictionParameter = 'Geared barrier';
const growthCapRestrictionParameter = 'Growth cap';
const couponBarrierRestrictionParameter = 'Coupon barrier';

export const checkIsBarrierOption = (productOptionTypeId) => (
  [CONSTRUCTOR_ISZ_OPTION_CODES.BARRIER_M, CONSTRUCTOR_ISZ_OPTION_CODES.BARRIER]
    .includes(productOptionTypeId)
);

export const calculateProductPayoutValue = (id) => {
  const periodicitySizeMap = [
    { id: 1, periodicity: 1 },
    { id: 2, periodicity: 30 },
    { id: 3, periodicity: 90 },
    { id: 4, periodicity: 180 },
    { id: 5, periodicity: 365 },
    { id: 6, periodicity: 365 },
    { id: 7, periodicity: 365 },
    { id: 8, periodicity: 60 },
    { id: 9, periodicity: 900 },
    { id: 10, periodicity: 720 },
    { id: 11, periodicity: 0 },
  ];

  return periodicitySizeMap.find((ps) => ps.id === id)?.periodicity;
};

export const checkIsEuropeOption = (productOptionTypeId) => (
  [CONSTRUCTOR_ISZ_OPTION_CODES.NORMAL_EUROPE]
    .includes(productOptionTypeId)
);

export const getCountBarrier = ({
  term,
  payoutCode,
  isMonoBarrier,
}) => {
  if (!term || !payoutCode) {
    return 0;
  }

  if (payoutCode === PAYOUT_CODE_REF.SIMULTANEOUSLY_AT_THE_END_OF_TIME) {
    return 1;
  }

  if (!term) {
    return 0;
  }

  if (isMonoBarrier) {
    return 1;
  }

  return Number(term);
};

const NUMBER_UP_TO_TWO_DECIMAL = /^\d*(\.?\d{1,2})$/;
export const validPercentValue = (val) => {
  const minDiapason = 0;
  const maxDiapason = 999;
  const value = String(val).replace(',', '.');
  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(value);
  const isValidDiapason = minDiapason < Number(value) && Number(value) < maxDiapason;

  return isTwoDecimal && isValidDiapason;
};

export const declOfDay = (d) => {
  const textArr = ['день', 'дня', 'дней'];
  const n = Math.abs(d) % 100;
  const n1 = n % 10;
  if (n > 10 && n < 20) {
    return textArr[2];
  }
  if (n1 > 1 && n1 < 5) {
    return textArr[1];
  }
  if (n1 === 1) {
    return textArr[0];
  }

  return textArr[2];
};

const getHasRestrictions = (restrictions) => {
  if (restrictions?.length) {
    return false;
  }

  return true;
};

export const validBonusCouponValue = (restrictions) => (val) => {
  if (getHasRestrictions(restrictions)) {
    return false;
  }

  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(val);
  const isValidDiapason = minBonusCoupon <= val && val <= maxBonusCoupon;

  const value = String(val).replace(',', '.');
  const validRestriction = restrictions.find(
    (r) => r.parameter === bonusCouponRestrictionParameter
      && r.minValue <= Number(value) && r.maxValue >= Number(value),
  );

  return isTwoDecimal && isValidDiapason && validRestriction;
};

export const validBarrierPercentValue = (restrictions) => (val) => {
  if (getHasRestrictions(restrictions)) {
    return false;
  }

  const value = String(val).replace(',', '.');
  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(value);

  const validRestriction = restrictions.find(
    (r) => r.parameter === autocallBarrierRestrictionParameter
      && r.minValue <= Number(value) && r.maxValue >= Number(value),
  );

  return isTwoDecimal && validRestriction;
};

export const validNotionalBarrierValue = (restrictions) => (val) => {
  if (getHasRestrictions(restrictions)) {
    return false;
  }

  const value = String(val).replace(',', '.');
  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(value);

  const validRestriction = restrictions.find(
    (r) => r.parameter === notionalBarrierRestrictionParameter
      && r.minValue <= Number(value) && r.maxValue >= Number(value),
  );

  return Boolean(isTwoDecimal && validRestriction);
};

export const validGearedBarrierValue = (restrictions) => (val) => {
  if (getHasRestrictions(restrictions)) {
    return false;
  }

  const value = String(val).replace(',', '.');
  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(value);

  const validRestriction = restrictions.find(
    (r) => r.parameter === gearedBarrierRestrictionParameter
      && r.minValue <= Number(value) && r.maxValue >= Number(value),
  );

  return Boolean(isTwoDecimal && validRestriction);
};

export const validGrowthCapValue = (restrictions) => (val) => {
  if (getHasRestrictions(restrictions)) {
    return false;
  }

  const value = String(val).replace(',', '.');
  const isTwoDecimal = NUMBER_UP_TO_TWO_DECIMAL.test(value);

  const validRestriction = restrictions.find(
    (r) => r.parameter === growthCapRestrictionParameter
      && r.minValue <= Number(value) && r.maxValue >= Number(value),
  );

  return Boolean(isTwoDecimal && validRestriction);
};

const getRestrictionsValidationMessage = (restrictions) => `Укажите значение в диапазоне ${
  restrictions
    .sort((a, b) => a.minValue - b.minValue)
    .map(
      (r) => {
        if (r.minValue !== r.maxValue) {
          return `от ${r.minValue} до ${r.maxValue}`;
        }

        return String(r.minValue);
      },
    )
    .join(' или ')
}`;

export const getBarrierValidationMessage = (restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === autocallBarrierRestrictionParameter,
  ),
);

export const getBarrierDescriptionMessage = (restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === couponBarrierRestrictionParameter,
  ),
);

export const getBonusCouponValidationMessage = (restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === bonusCouponRestrictionParameter,
  ),
);

export const getNotionalValidationBarrierMessage =
(restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === notionalBarrierRestrictionParameter,
  ),
);

export const getGearedValidationBarrierMessage = (restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === gearedBarrierRestrictionParameter,
  ),
);

export const getGrowthCaValidationMessage = (restrictions) => getRestrictionsValidationMessage(
  restrictions.filter(
    (r) => r.parameter === growthCapRestrictionParameter,
  ),
);

export const getColor = (asset) => {
  switch (asset) {
    case ASSET_COLOR.green:
      return 'green';

    case ASSET_COLOR.yellow:
      return 'yellow';

    case ASSET_COLOR.red:
      return 'red';

    case ASSET_COLOR.orange:
      return 'orange';

    default:
      return 'black';
  }
};
