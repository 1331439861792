import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Container, Row, Col } from 'reactstrap';
import {
  DateCustomPicker,
  SearchInput,
} from 'components';
import { SelectInput, TextInput } from 'components/_deprecated';
import { Button } from 'semantic-ui-react';
import {
  LANG_DICTIONARY,
  DOCUMENTS,
  PASSPORT,
  INPUT_NAMES,
  MASK,
} from 'consts';
import { getInputProps, getFmsUnitTitle } from 'helpers';
import { searchFmsUnit, resetSearchFmsUnit } from 'redux/rootActions';
import { connect } from 'react-redux';
import moment from 'moment';
import _ from 'lodash';
import { block } from 'bem-cn';

const {
  SERIAL,
  NUMBER,
  ISSUED_BY,
  DATE_OF_ISSUE,
  KP,
  VALID_UNTIL,
  DOCUMENT_TYPE_PASSPORT,
  DOCUMENT_TYPE_TEMP_CERT,
  ISSUED_BY_BUTTON_ACCEPT,
  ISSUED_BY_BUTTON_ACCEPTED,
  ISSUED_BY_CHANGE_NOTIFICATION,
} = LANG_DICTIONARY;

const propTypes = {
  inputProps: PropTypes.object,
  onlyPassport: PropTypes.bool,
  documentType: PropTypes.number,
  prefix: PropTypes.string,
  setFieldValue: PropTypes.func,
  resetSearch: PropTypes.func,
  selectSearch: PropTypes.func,
  onSearch: PropTypes.func,
  isLoading: PropTypes.bool,
  searchResult: PropTypes.arrayOf(PropTypes.object),
  isResetDataOnChangeDocumentData: PropTypes.bool,
};

const defaultProps = {
  prefix: '',
  inputProps: {},
  onlyPassport: false,
  documentType: 1,
  setFieldValue: () => null,
  isResetDataOnChangeDocumentData: false,
  resetSearch: null,
  selectSearch: null,
  onSearch: null,
  isLoading: false,
  searchResult: null,
};

const getToday = () => moment().toDate();

const b = block('insurerData');

class DocumentBlock extends Component {
  acceptIssuedBy = () => {
    const { setFieldValue } = this.props;

    setFieldValue('issuedByAccepted', true);
  }

  handleResultSelect = async (e, { result }) => {
    const { resetSearch, selectSearch, setFieldValue } = this.props;
    const { data, data: { code } } = result;

    setFieldValue(INPUT_NAMES.kp, code);
    selectSearch(data);
    resetSearch();
    setFieldValue('issuedByAccepted', false);
  };

  handleSearchChange = (e, { value }) => {
    const { onSearch, setFieldValue } = this.props;
    setFieldValue(INPUT_NAMES.kp, value);
    onSearch(value);
  };

  render() {
    const {
      documentType,
      onlyPassport,
      prefix,
      isLoading,
      searchResult,
      inputProps,
      isResetDataOnChangeDocumentData,
    } = this.props;

    const {
      values: {
        issuedBy,
        issuedByAccepted,
        kp,
      },
    } = inputProps;

    const searchProps = {
      ...this.props,
      inputProps: {
        ...inputProps,
        onBlur: (e) => {
          if (inputProps.onBlur) {
            inputProps.onBlur(e);
          }
        },
      },
    };

    return (
      <Container>
        <Row>
          <Col md="6" sm="12">
            <SelectInput
              name={`${prefix}documentType`}
              documentSelector
              options={onlyPassport ? PASSPORT : DOCUMENTS}
              {...getInputProps(this.props, 'documentType')}
              onChange={(...args) => {
                inputProps.onChange(...args);

                if (isResetDataOnChangeDocumentData) {
                  inputProps.onChange({ target: { name: 'serial', value: '' } });
                  inputProps.onChange({ target: { name: 'passportNumber', value: '' } });
                  inputProps.onChange({ target: { name: 'issuedBy', value: '' } });
                  inputProps.onChange({ target: { name: 'when', value: null } });
                  inputProps.onChange({ target: { name: 'kp', value: '' } });
                  inputProps.onChange({ target: { name: 'validUntil', value: null } });
                }
              }}
            />
          </Col>
          <Col md="3" sm="12">
            {
              documentType !== DOCUMENT_TYPE_TEMP_CERT
              && (
                <TextInput
                  name={`${prefix}serial`}
                  placeholder={SERIAL}
                  required
                  {...getInputProps(this.props, 'serial')}
                  mask={documentType === DOCUMENT_TYPE_PASSPORT ? '9999' : undefined}
                />
              )
            }
          </Col>
          <Col md="3" sm="12">
            <TextInput
              name={`${prefix}passportNumber`}
              placeholder={NUMBER}
              mask={documentType === DOCUMENT_TYPE_TEMP_CERT ? MASK.tempCertNumber : '999999'}
              required
              {...getInputProps(this.props, 'passportNumber')}
            />
          </Col>
        </Row>
        <Row>
          <Col md="3" sm="12">
            <DateCustomPicker
              name={`${prefix}when`}
              placeholder={DATE_OF_ISSUE}
              maxDate={getToday()}
              {...getInputProps(this.props, 'when')}
              onBlur={() => {}}
              required={documentType === DOCUMENT_TYPE_PASSPORT}
            />
          </Col>
          <Col md="3" sm="12">
            {
              documentType === DOCUMENT_TYPE_PASSPORT
              && (
                <SearchInput
                  required
                  name={`${prefix}kp`}
                  placeholder={KP}
                  loading={isLoading}
                  className={b('searchBar', { small: true }).toString()}
                  inputProps={getInputProps(searchProps, 'kp')}
                  mask="999-999"
                  results={searchResult}
                  value={kp}
                  onResultSelect={this.handleResultSelect}
                  onSearchChange={_.debounce(this.handleSearchChange, 500, { leading: true })}
                />
              )
            }
            {
              documentType === DOCUMENT_TYPE_TEMP_CERT
              && (
                <DateCustomPicker
                  name={`${prefix}validUntil`}
                  placeholder={VALID_UNTIL}
                  minDate={getToday()}
                  {...getInputProps(this.props, 'validUntil')}
                  onBlur={() => {}}
                  required={documentType === DOCUMENT_TYPE_TEMP_CERT}
                />
              )
            }
          </Col>
          <Col md="6" sm="12">
            <TextInput
              name={`${prefix}issuedBy`}
              placeholder={ISSUED_BY}
              required
              {...getInputProps(this.props, 'issuedBy')}
            />
          </Col>
        </Row>
        {
          documentType === DOCUMENT_TYPE_PASSPORT
          && (
            <div className={b('documentBlock', 'actions')}>
              <div
                className={b('documentBlock', 'notification', {
                  active: issuedBy !== '' && !issuedByAccepted,
                  hidden: issuedByAccepted,
                })}
              >
                {ISSUED_BY_CHANGE_NOTIFICATION}
              </div>

              <Button
                type="button"
                disabled={issuedBy === '' || issuedByAccepted}
                className={b('documentBlock', 'button', {
                  success: issuedByAccepted,
                }).toString()}
                onClick={this.acceptIssuedBy}
              >
                {issuedByAccepted ? ISSUED_BY_BUTTON_ACCEPTED : ISSUED_BY_BUTTON_ACCEPT}
              </Button>
            </div>
          )
        }
      </Container>
    );
  }
}

function mapStateToProps({ helperReducer: { fmsUnitSearchResult, isFmsUnitSearchLoading } }) {
  const searchResult = fmsUnitSearchResult &&
      fmsUnitSearchResult.map((data) => ({
        ...data,
        title: getFmsUnitTitle(data),
      }));

  return {
    searchResult,
    isLoading: isFmsUnitSearchLoading,
  };
}

DocumentBlock.propTypes = propTypes;
DocumentBlock.defaultProps = defaultProps;

export default connect(
  mapStateToProps,
  {
    onSearch: searchFmsUnit,
    resetSearch: resetSearchFmsUnit,
  },
)(DocumentBlock);
