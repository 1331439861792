export default (
  [
    dateHeader,
    organisationHeader,
    senderHeader,
    messageHeader,
    readHeader,
  ],
  [
    id,
    importDate,
    senderPartnerName,
    senderFullName,
    messageText,
    checkbox,
  ],
) => ({
  key: id,
  data: [
    {
      md: 1,
      headers: [
        { text: dateHeader },
      ],
      data: [
        { text: importDate },
      ],
    },
    {
      md: 3,
      headers: [
        { text: organisationHeader },
        { text: senderHeader },
      ],
      data: [
        { text: senderPartnerName },
        { text: senderFullName },
      ],
    },
    {
      md: 7,
      headers: [
        { text: messageHeader },
      ],
      data: [
        { text: messageText },
      ],
    },
    {
      md: 1,
      headers: [
        { text: readHeader },
      ],
      data: [
        { text: checkbox },
      ],
    },
  ],
});
