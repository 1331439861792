import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';
import { v4 as uuidV4 } from 'uuid';
import { connect } from 'react-redux';
import block from 'bem-cn';
import {
  getRecipientPartners,
  getRecipients,
  setInboxMessagesSearchValues,
  createNewMessage,
} from 'redux/rootActions';
import {
  DocsTransferMenu,
  DocsTransferFileList,
  MainBtn,
  Select,
} from 'components';
import './styles.scss';
import {
  Form,
  FormTextArea,
  Icon,
} from 'semantic-ui-react';
import { toastr } from 'react-redux-toastr';
import {
  LANG_DICTIONARY,
  AVAILABLE_DOCS_TRANSFER_FORMATS,
  DOCS_TRANSFER_MAX_FILES,
  DOCS_TRANSFER_MAX_MESSAGE_LENGTH,
} from 'consts';
import { getRebuildPartners, getRebuildRecipients } from 'redux/rootSelectors';
import { getRecipientFromValue } from 'helpers';

const {
  RECIPIENT,
  RECIPIENT_ORGANISATION,
  SELECT_PARTNER,
  SELECT_RECIPIENT,
  ENTER_MESSAGE,
  MESSAGE,
  FILES,
  SEND,
  DOCS_TRANSFER_MAX_FILES_ERROR,
  PLACEHOLDER_FILE_UPLOAD,
} = LANG_DICTIONARY;

const propTypes = {
  inboxUnreadMessagesCount: PropTypes.number,
  setValues: PropTypes.func,
  getPartners: PropTypes.func,
  getRecipientsForSend: PropTypes.func,
  createMessage: PropTypes.func,
  recipientPartners: PropTypes.array,
  history: PropTypes.object,
  selectedPartnerId: PropTypes.number,
  selectedRecipient: PropTypes.string,
  recipients: PropTypes.array,
  message: PropTypes.string,
  files: PropTypes.arrayOf(PropTypes.object),
  isLoadingCreateMessage: PropTypes.bool,
};

const defaultProps = {
  inboxUnreadMessagesCount: 0,
  setValues: () => {},
  getPartners: () => {},
  getRecipientsForSend: () => {},
  createMessage: () => {},
  recipientPartners: [],
  history: {},
  selectedPartnerId: null,
  selectedRecipient: '',
  recipients: [],
  message: '',
  files: [],
  isLoadingCreateMessage: false,
};

const b = block('docs-transfer-create');

const DocsTransferCreate = ({
  inboxUnreadMessagesCount,
  history,
  getPartners,
  getRecipientsForSend,
  createMessage,
  recipientPartners,
  setValues,
  selectedPartnerId,
  selectedRecipient,
  recipients,
  message,
  files,
  isLoadingCreateMessage,
}) => {
  useEffect(() => {
    getPartners();
  }, []);

  const isFilesExist = files.length > 0;

  const isSendButtonActive = selectedPartnerId &&
    (selectedRecipient) &&
    (message || isFilesExist);

  const handleSelectPartner = ({ target: { value } }) => {
    getRecipientsForSend(value);
    setValues('selectedPartnerId', value);
  };

  const handleSelectRecipient = ({ target: { value } }) => {
    setValues('selectedRecipient', value);
  };

  const handleOnChangeMessage = ({ target: { value } }) => {
    setValues('message', value);
  };

  const handleUploadFiles = ({ target }) => {
    if (target.files.length + files.length > DOCS_TRANSFER_MAX_FILES) {
      toastr.error('', DOCS_TRANSFER_MAX_FILES_ERROR);

      return;
    }

    const rebuildFiles = Array
      .from(target.files)
      .map((file) => ({
        id: uuidV4(),
        file,
      }));
    // eslint-disable-next-line no-param-reassign
    target.value = null;
    setValues('files', [...files, ...rebuildFiles]);
  };

  const handleDeleteFiles = (id) => {
    setValues(
      'files',
      files.filter((file) => file.id !== id),
    );
  };

  const handleOnClickButton = () => {
    const formData = new FormData();

    const recipient = getRecipientFromValue(selectedRecipient);

    formData.append('recipientPartnerId', selectedPartnerId);

    recipient.recipientId &&
      formData.append('recipientId', recipient.recipientId);
    recipient.recipientUsersGroupsId &&
      formData.append('recipientUsersGroupsId', recipient.recipientUsersGroupsId);

    message && formData.append('messageText', message);

    if (isFilesExist) {
      files.forEach(({ file }) => {
        formData.append('files', file);
      });
    }

    createMessage(formData);
  };

  return (
    <DocsTransferMenu inboxUnreadMessagesCount={inboxUnreadMessagesCount} history={history}>
      <div className={b()}>
        <div className={b('wrapper')}>
          <Form>
            <div className={b('partners')}>
              <span className={b('partners-text')}>
                {RECIPIENT_ORGANISATION}
              </span>
              <div className={b('partners-select')}>
                <Select
                  name="partners"
                  options={recipientPartners}
                  onChange={handleSelectPartner}
                  placeholder={SELECT_PARTNER}
                  value={selectedPartnerId}
                  search={false}
                />
              </div>
            </div>
            <div className={b('recipients')}>
              <span className={b('recipients-text')}>
                {RECIPIENT}
              </span>
              <div className={b('recipients-select')}>
                <Select
                  name="recipients"
                  options={recipients}
                  onChange={handleSelectRecipient}
                  placeholder={SELECT_RECIPIENT}
                  value={selectedRecipient}
                />
              </div>
            </div>
            <div className={b('message')}>
              <span className={b('message-text')}>
                {MESSAGE}
              </span>
              <div className={b('message-field')}>
                <FormTextArea
                  className={b('message-field').toString()}
                  onChange={handleOnChangeMessage}
                  placeholder={ENTER_MESSAGE}
                  value={message}
                  rows={8}
                  maxLength={DOCS_TRANSFER_MAX_MESSAGE_LENGTH}
                />
              </div>
            </div>
            <div className={b('files')}>
              <div className={b('files-wrapper')}>
                <span className={b('files-text')}>
                  {PLACEHOLDER_FILE_UPLOAD}
                </span>
                <label htmlFor="files" className={b('files-label')}>
                  <input
                    className={b('files-input')}
                    type="file"
                    id="files"
                    name="files"
                    onChange={handleUploadFiles}
                    accept={AVAILABLE_DOCS_TRANSFER_FORMATS}
                    multiple
                  />
                  <div className={b('controllers')}>
                    <Icon name="plus" className="plus-button" />
                  </div>
                </label>
                <div className={b('files-counter')}>
                  {`${FILES} ${files.length} / ${DOCS_TRANSFER_MAX_FILES}`}
                </div>
              </div>
              <DocsTransferFileList
                className={b().toString()}
                files={files}
                onDelete={handleDeleteFiles}
              />
            </div>
          </Form>
        </div>
        <MainBtn
          className={b('button')}
          text={SEND}
          onClick={handleOnClickButton}
          disabled={!isSendButtonActive}
          isLoading={isLoadingCreateMessage}
        />
      </div>
    </DocsTransferMenu>
  );
};

const mapStateToProps = (state) => {
  const {
    inboxUnreadMessagesCount,
    selectedPartnerId,
    selectedRecipient,
    message,
    files,
    isLoadingCreateMessage,
  } = state.docsTransfer;

  return {
    inboxUnreadMessagesCount,
    selectedPartnerId,
    selectedRecipient,
    recipientPartners: getRebuildPartners(state),
    recipients: getRebuildRecipients(state),
    message,
    files,
    isLoadingCreateMessage,
  };
};

const mapDispatchToProps = {
  getPartners: getRecipientPartners,
  getRecipientsForSend: getRecipients,
  setValues: setInboxMessagesSearchValues,
  createMessage: createNewMessage,
};

DocsTransferCreate.propTypes = propTypes;
DocsTransferCreate.defaultProps = defaultProps;
export default withCustomRouter(
  connect(mapStateToProps, mapDispatchToProps)(DocsTransferCreate),
);
