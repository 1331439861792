import {
  put,
  takeLatest,
} from 'redux-saga/effects';

import {
  apiCall,
  getError,
} from 'helpers';
import { API, LANG_DICTIONARY } from 'consts';
import {
  uploadTemplate,
} from 'redux/rootActions';

import { toastr } from 'react-redux-toastr';
import {
  CHECK_TEMPLATE,
  UPLOAD_TEMPLATE,
} from '../types';

const {
  TEMPLATE_UPLOADED,
} = LANG_DICTIONARY;

function* checkTemplateIsExist({ payload }) {
  try {
    yield put({ type: CHECK_TEMPLATE.start });
    const { data: isExist } = yield apiCall({
      type: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: payload,
      url: API.CHECK_TEMPLATE_EXIST,
    });

    if (isExist) {
      yield put({ type: CHECK_TEMPLATE.success });
    } else {
      yield put(uploadTemplate(payload));
    }
  } catch (e) {
    yield put({ type: CHECK_TEMPLATE.fail, payload: getError(e) });
  }
}

function* uploadTemplateSage({ payload }) {
  try {
    yield put({ type: UPLOAD_TEMPLATE.start });
    const { data } = yield apiCall({
      type: 'POST',
      headers: { 'Content-Type': 'multipart/form-data' },
      body: payload,
      url: API.UPLOAD_TEMPLATE,
    });

    yield put({ type: UPLOAD_TEMPLATE.success, payload: data });

    if (!data.length) {
      toastr.success(TEMPLATE_UPLOADED);
    }
  } catch (e) {
    yield put({ type: UPLOAD_TEMPLATE.fail, payload: getError(e) });
  }
}

function* templatesSaga() {
  yield takeLatest(CHECK_TEMPLATE.request, checkTemplateIsExist);
  yield takeLatest(UPLOAD_TEMPLATE.request, uploadTemplateSage);
}

export default templatesSaga;
