import {
  API,
  DEFAULT_DATE,
  SYSTEM_DATE_FROM,
} from 'consts';
import { put, takeLatest } from 'redux-saga/effects';
import {
  apiCall,
  apiDownload,
  getError,
} from 'helpers';
import moment from 'moment';
import {
  SEARCH_CONTRACTS,
  GET_REPORT,
  GET_SEARCH_REPORTS_TYPES,
} from '../types';

function* searchContracts({ payload }) {
  try {
    yield put({ type: SEARCH_CONTRACTS.start });
    const { data } = yield apiCall({
      type: 'POST',
      url: API.SEARCH_CONTRACTS,
      body: payload,
    });
    yield put({ type: SEARCH_CONTRACTS.success, payload: data.results });
  } catch (e) {
    yield put({ type: SEARCH_CONTRACTS.fail, payload: getError(e) });
  }
}

function* getReport({ payload }) {
  const {
    numFull,
    dateInvest,
    actualDate,
    reportDate,
    searchFilters,
  } = payload;

  try {
    yield put({ type: GET_REPORT.start });
    yield apiDownload({
      type: 'POST',
      url: API.GET_REPORT,
      body: {
        numFull,
        dateInvest: moment(dateInvest).startOf('day').format(DEFAULT_DATE),
        actualDate: moment(actualDate).startOf('day').format(DEFAULT_DATE),
        reportDate: moment(reportDate).startOf('day').format(DEFAULT_DATE),
        searchFilters,
      },
    }, `отчет-${moment().format(SYSTEM_DATE_FROM)}-${numFull}.pdf`);
    yield put({ type: GET_REPORT.success, payload });
  } catch (e) {
    yield put({ type: GET_REPORT.fail, payload: getError(e) });
  }
}

function* getSearchReportsTypes() {
  try {
    yield put({ type: GET_SEARCH_REPORTS_TYPES.start });
    const { data } = yield apiCall({
      type: 'GET',
      url: API.GET_SEARCH_REPORTS_TYPES,
    });

    yield put({ type: GET_SEARCH_REPORTS_TYPES.success, payload: data });
  } catch (e) {
    yield put({ type: GET_SEARCH_REPORTS_TYPES.fail, payload: getError(e) });
  }
}

function* clientReportsSaga() {
  yield takeLatest(SEARCH_CONTRACTS.request, searchContracts);
  yield takeLatest(GET_REPORT.request, getReport);
  yield takeLatest(GET_SEARCH_REPORTS_TYPES.request, getSearchReportsTypes);
}

export default clientReportsSaga;
