import React, { Component } from 'react';
import { LANG_DICTIONARY, INPUT_NAMES } from 'consts';
import { setEmptyState, replaceAllButNumbers, setSpaceNumber } from 'helpers';
import { block } from 'bem-cn';
import { Button, Form } from 'semantic-ui-react';
import { Row, Col } from 'reactstrap';
import PaymentItem from './paymentItem';
import './styles.scss';

const b = block('payment-sum');

const {
  DEATH_ANY_REASON,
  DEATH_ACCIDENT,
  INJURY_ACCIDENT,
  HOSPITALIZATION_ACCIDENT,
  DISABILITY_OUSV,
  DISABILITY_ANY_REASON,
  CRITICAL_DISEASES,
  TOTAL,
  INSURANCE_RISK,
  INSURANCE_AMOUNT_TITLE,
  INSURANCE_FEE,
  NEXT,
} = LANG_DICTIONARY;

const {
  deathAnyReason,
  deathAccident,
  injuryAccident,
  hospitalAccident,
  disabilityOusv,
  disabilityReason,
  criticalDiseases,
} = INPUT_NAMES;

const fieldsDesc = [
  INSURANCE_RISK,
  INSURANCE_AMOUNT_TITLE,
  INSURANCE_FEE,
];

const fields = [
  {
    title: DEATH_ANY_REASON,
    name: deathAnyReason,
    currency: 'руб.',
    coefficient: 0.3,
    required: true,
  },
  {
    title: DEATH_ACCIDENT,
    name: deathAccident,
    currency: 'руб.',
    coefficient: 0.3,
  },
  {
    title: INJURY_ACCIDENT,
    name: injuryAccident,
    currency: 'руб.',
    coefficient: 0.3,
  },
  {
    title: HOSPITALIZATION_ACCIDENT,
    name: hospitalAccident,
    currency: 'руб.',
    coefficient: 0.3,
  },
  {
    title: DISABILITY_OUSV,
    name: disabilityOusv,
    currency: 'руб.',
    coefficient: 0.3,
  },
  {
    title: DISABILITY_ANY_REASON,
    name: disabilityReason,
    currency: 'руб.',
    coefficient: 0.3,
  },
  {
    title: CRITICAL_DISEASES,
    name: criticalDiseases,
    currency: 'руб.',
    coefficient: 0.3,
  },
];

class PaymentSum extends Component {
  constructor(props) {
    super(props);

    this.state = setEmptyState(fields);
  }

  handleChange = (coefficient) => ({ target: { name, value } }) => {
    const curValue = replaceAllButNumbers(value);
    this.setState({
      [name]: setSpaceNumber(curValue),
      [`${name}Sum`]: Math.floor(curValue * coefficient),
    });
  };

  getValue = (name) => {
    const { [name]: value } = this.state;

    return value || '';
  };

  getSumValue = (name) => {
    const { [`${name}Sum`]: value } = this.state;

    return value || 0;
  };

  getTotalSum = () => fields.reduce((sum, item) => {
    const { [`${item.name}Sum`]: value } = this.state;

    return value ? setSpaceNumber(sum + value) : sum;
  }, 0);

  renderTitleDesc = (desc) => {
    const last = desc.length - 1;
    const getClass = (index) => (index !== last ? b('title-desc')
      : b('title-desc', { last: true }));

    return (
      <div className={b('title-desc-row').toString()}>
        <Row>
          {desc.map((item, index) => (
            <Col md="4">
              <div
                className={getClass(index)}
                key={item}
              >
                {item}
              </div>
            </Col>
          ))}
        </Row>
      </div>
    );
  };

  render() {
    return (
      <Form className={b()}>
        {this.renderTitleDesc(fieldsDesc)}
        {fields.map((item) => {
          const {
            name,
            coefficient,
          } = item;

          return (
            <PaymentItem
              key={name}
              onChange={this.handleChange(coefficient)}
              value={this.getValue(name)}
              sumValue={this.getSumValue(name)}
              parentClass={b}
              {...item}
            />
          );
        })}
        <div className={b('row-sum')}>
          <Row>
            <Col md="3" sm="6" xs="6">
              <p className={b('title', { total: true })}>{TOTAL}</p>
            </Col>
            <Col md="5" className={b('empty').toString()} />
            <Col md="4" sm="6" xs="6">
              <p className={b('sum')}>{`${this.getTotalSum()} руб.`}</p>
            </Col>
          </Row>
        </div>
        <div className={b('btn-block')}>
          <Button
            type="submit"
            className={b('apply-button').toString()}
          >
            { NEXT }
          </Button>
        </div>

      </Form>
    );
  }
}

export default PaymentSum;
