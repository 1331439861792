import { LANG_DICTIONARY } from 'consts';
import {
  GET_REPORTS_FOR_TABLE_FAIL,
  GET_REPORTS_FOR_TABLE_START,
  GET_REPORTS_FOR_TABLE_SUCCESS,
  SET_PAGE_PAGINATION,
  CLEAR_FORM,
  SET_INITIAL_VALUES,
  GET_REPORT_DATA_SUCCESS,
  GET_ROLES_FOR_SELECT_SUCCESS,
  SET_ROLES_FOR_REPORT,
  GET_REPORT_TAGS_SUCCESS,
  GET_REPORT_TYPES_FOR_SELECT_SUCCESS,
  GET_PARTNERS_FOR_SELECT_SUCCESS,
  SET_REPORT_SELECT,
  SET_TAGS_FOR_REPORT,
  SET_NEW_TAGS_SUCCESS,
  SET_REPORT_STATUS,
  GET_DOWNLOAD_CHECK_SUCCESS,
  GET_DOWNLOAD_LIST_SUCCESS,
  GET_DOWNLOAD_REPORT_SUCCESS,
  CLOSE_DOWNLOAD_POPUP,
} from '../types';

const INITIAL_EDITOR_FORM = {
  id: null,
  partnerId: '',
  reportType: '',
  roles: [],
  isActive: false,
  tags: [],
};

const INITIAL_STATE = {
  reportsFormData: INITIAL_EDITOR_FORM,
  loadingTableInfo: false,
  tableInfo: [],
  page: 1,
  haveReports: false,
  rolesForReport: [],
  selectedRoles: [],
  reportsRoles: [],
  tags: [],
  partners: [],
  reportTypes: [],
  downloadReports: [],
  reportDownload: null,
  openDownloadReportsPopUp: false,
  totalPages: 1,
};

const { NOT_CHOOSED } = LANG_DICTIONARY;

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CLOSE_DOWNLOAD_POPUP: {
      return {
        ...state,
        openDownloadReportsPopUp: false,
      };
    }
    case GET_DOWNLOAD_CHECK_SUCCESS: {
      return {
        ...state,
        haveReports: payload,
      };
    }

    case GET_DOWNLOAD_LIST_SUCCESS: {
      const { openDownloadReportsPopUp } = state;

      return {
        ...state,
        openDownloadReportsPopUp: !openDownloadReportsPopUp,
        downloadReports: payload,
      };
    }

    case GET_DOWNLOAD_REPORT_SUCCESS: {
      return {
        ...state,
        reportDownload: payload,
      };
    }
    case SET_REPORT_STATUS: {
      const { isActive: status } = payload;

      return {
        ...state,
        reportsFormData: {
          ...payload,
          isActive: !status,
        },
      };
    }

    case SET_TAGS_FOR_REPORT: {
      const { allTags, selectedTags, values } = payload;

      return {
        ...state,
        tags: allTags,
        reportsFormData: {
          ...values,
          tags: selectedTags,
        },
      };
    }
    case SET_REPORT_SELECT: {
      const { values, option, value } = payload;

      return {
        ...state,
        reportsFormData: {
          ...values,
          [option]: value,
        },
      };
    }
    case GET_PARTNERS_FOR_SELECT_SUCCESS: {
      return {
        ...state,
        partners: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...payload,
        ],
      };
    }
    case GET_REPORT_TYPES_FOR_SELECT_SUCCESS: {
      return {
        ...state,
        reportTypes: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...payload,
        ],
      };
    }
    case SET_ROLES_FOR_REPORT: {
      const { selected, rolesReport, values } = payload;

      return {
        ...state,
        reportsRoles: rolesReport,
        reportsFormData: {
          ...values,
          roles: selected,
        },
      };
    }
    case GET_REPORTS_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loadingTableInfo: false,
        tableInfo: payload.data,
        page: payload.currentPage,
        totalPages: payload.totalPages,
      };

    case GET_REPORTS_FOR_TABLE_FAIL:
      return {
        ...state,
        loadingTableInfo: false,
      };

    case GET_ROLES_FOR_SELECT_SUCCESS: {
      const { reportsFormData: { roles: reportRoles } } = state;
      const filteredRoles = payload.filter(
        ({ key: tagKey }) => !reportRoles.find((item) => item === tagKey),
      );

      return {
        ...state,
        reportsRoles: [
          {
            key: null,
            value: null,
            text: NOT_CHOOSED,
          },
          ...filteredRoles,
        ],
        allRoles: payload,
      };
    }

    case GET_REPORTS_FOR_TABLE_START:
      return {
        ...state,
        loadingTableInfo: true,
      };

    case SET_PAGE_PAGINATION:
      return {
        ...state,
        page: payload,
      };

    case CLEAR_FORM:
      return {
        ...state,
        tags: [],
        reportsFormData: INITIAL_EDITOR_FORM,
      };

    case GET_REPORT_DATA_SUCCESS: {
      const { data, newTags } = payload;
      const { reportsFormData: { tags: reportTags } } = state;
      const filteredTags = reportTags.filter(
        ({ id: tagId }) => !newTags.find((item) => item.id === tagId),
      );

      return {
        ...state,
        tags: filteredTags,
        reportsFormData: {
          id: data.id || '',
          partnerId: data.partnerId || '',
          reportType: data.reportTypeId || '',
          roles: data.roles || [],
          isActive: data.isActive || false,
          tags: newTags || [],
        },
      };
    }

    case GET_REPORT_TAGS_SUCCESS: {
      const { reportsFormData: { tags: reportTags } } = state;
      const filteredTags = payload.filter(
        ({ id: tagId }) => !reportTags.find((item) => item.id === tagId),
      );

      return {
        ...state,
        tags: filteredTags,
      };
    }

    case SET_NEW_TAGS_SUCCESS: {
      return {
        ...state,
        tags: payload,
      };
    }

    case SET_INITIAL_VALUES:
      return INITIAL_STATE;

    default:
      return state;
  }
};
