const CONSTRUCTOR_ISZ_DOLLAR_PRELIMINARY = {
  isProductPeriod: true,
  isPolicySum: true,
  isCurrencyName: true,
  isRate: true,
  isProductStart: true,
  isPremium: true,
};

const CONSTRUCTOR_ISZ_DOLLAR_AGENT = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: true,
};

const CONSTRUCTOR_ISZ_DOLLAR_PERSON = {
  isPerson: true,
  isDocuments: true,
  isAddress: true,
  isAddressLiving: true,
  isInnSnils: true,
  isContacts: true,
  isBank: false,
};

const CONSTRUCTOR_ISZ_DOLLAR_BENEFICIARY = {

};

const CONSTRUCTOR_ISZ_DOLLAR = {
  preliminary: CONSTRUCTOR_ISZ_DOLLAR_PRELIMINARY,
  insurrerAgent: CONSTRUCTOR_ISZ_DOLLAR_AGENT,
  insurredPerson: CONSTRUCTOR_ISZ_DOLLAR_PERSON,
  beneficiary: CONSTRUCTOR_ISZ_DOLLAR_BENEFICIARY,
};

export default CONSTRUCTOR_ISZ_DOLLAR;
