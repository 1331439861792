import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { Icon } from 'semantic-ui-react';
import { LANG_DICTIONARY, ENUMS } from 'consts';
import '../../styles.scss';

const {
  CONTACTS_SHORT,
  DOCUMENTS_SHORT,
  ADDRESS,
} = LANG_DICTIONARY;

const { BENEFICIARY_FORM_ENUM } = ENUMS;
const {
  contacts: contactsEnum,
  documents: documentsEnum,
  address: addressEnum,
} = BENEFICIARY_FORM_ENUM;

const propTypesTab = {
  title: PropTypes.string,
  type: PropTypes.string,
  index: PropTypes.number,
  setActiveTab: PropTypes.func,
  setStatusTab: PropTypes.func,
  isActive: PropTypes.bool,
  countVisableErrors: PropTypes.number,
  isShow: PropTypes.bool,
};

const defaultPropsTab = {
  title: '',
  type: '',
  index: 0,
  setActiveTab: () => null,
  setStatusTab: () => null,
  isActive: false,
  countVisableErrors: 0,
  isShow: false,
};

const propTypes = {
  address: PropTypes.bool,
  contacts: PropTypes.bool,
  documents: PropTypes.bool,
  index: PropTypes.number,
  setActiveTab: PropTypes.func,
  setStatusTab: PropTypes.func,
  countVisableErrorsDocuments: PropTypes.number,
  countVisableErrorsAddress: PropTypes.number,
  countVisableErrorsContacts: PropTypes.number,
  active: PropTypes.string,
};

const defaultProps = {
  address: false,
  contacts: false,
  documents: false,
  index: 0,
  setActiveTab: () => null,
  setStatusTab: () => null,
  countVisableErrorsDocuments: 0,
  countVisableErrorsAddress: 0,
  countVisableErrorsContacts: 0,
  active: '',
};

const b = block('switcher-beneficiary-block');

const BeneficiaryBlockTab = ({
  title,
  type,
  index,
  setActiveTab,
  setStatusTab,
  isActive,
  countVisableErrors,
  isShow,
}) => {
  const handleClickIcon = (status) => {
    setStatusTab({ tabName: type, index, status });
    setActiveTab({ tabName: type, index });
  };

  const getIcon = () => (
    <div className={isActive ? b('close') : b('circle')}>
      <Icon
        name={isActive ? 'close' : 'add circle'}
        onClick={() => handleClickIcon(!isActive)}
      />
    </div>
  );

  return (
    <div className={b('item', { active: isShow })}>
      {getIcon()}
      <div
        onClick={() => setActiveTab({ tabName: type, index })}
        tabIndex="-1"
        role="button"
      >
        {title}
      </div>
      {Boolean(countVisableErrors) && isActive && <p className={b('count-errors')}>{countVisableErrors}</p>}
    </div>
  );
};

const SwitcherBlockBeneficiary = ({
  address,
  contacts,
  documents,
  index,
  setActiveTab,
  setStatusTab,
  countVisableErrorsDocuments,
  countVisableErrorsAddress,
  countVisableErrorsContacts,
  active,
}) => {
  const tabProps = {
    index,
    setActiveTab,
    setStatusTab,
  };

  return (
    <div className={b({ 'not-active': !active })}>
      <BeneficiaryBlockTab
        title={DOCUMENTS_SHORT}
        {...tabProps}
        type={documentsEnum}
        isShow={active === documentsEnum}
        isActive={documents}
        countVisableErrors={countVisableErrorsDocuments}
      />
      <BeneficiaryBlockTab
        title={ADDRESS}
        {...tabProps}
        type={addressEnum}
        isShow={active === addressEnum}
        isActive={address}
        countVisableErrors={countVisableErrorsAddress}
      />
      <BeneficiaryBlockTab
        title={CONTACTS_SHORT}
        {...tabProps}
        type={contactsEnum}
        isShow={active === contactsEnum}
        isActive={contacts}
        countVisableErrors={countVisableErrorsContacts}
      />
    </div>
  );
};

SwitcherBlockBeneficiary.propTypes = propTypes;
SwitcherBlockBeneficiary.defaultProps = defaultProps;
BeneficiaryBlockTab.propTypes = propTypesTab;
BeneficiaryBlockTab.defaultProps = defaultPropsTab;
export default SwitcherBlockBeneficiary;
