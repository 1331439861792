export const CONSTRUCTOR_TABLE = {
  basketId: 0,
  dateCreate: 1,
  strategy: 2,
  strategyType: 3,
  assetsBasket: 4,
  favorites: 5,
  period: 6,
  doingEnd: 7,
  partitionCoefficientLess: 8,
  partitionCoefficient: 9,
  payoutTimes: 8,
  barriers: 9,
  couponLess: 10,
  couponMore: 11,
  autocallBarrier: 12,
  autocallBonusCoupon: 13,
  garantSchedule: 14,
};
