import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { TRADING_VIEW_WIDGETS } from '../tradingViewConfig';

import './styles.scss';

const propTypes = {
  b: PropTypes.func,
  setActiveWidget: PropTypes.func,
  chartOnly: PropTypes.bool,
};

const defaultProps = {
  b: () => null,
  setActiveWidget: () => null,
  chartOnly: false,
};

const {
  REPORTING,
  CHART,
} = LANG_DICTIONARY;

const {
  reporting,
  chart,
  miniChart,
} = TRADING_VIEW_WIDGETS;

const buttonsContent = [
  {
    text: REPORTING,
    widgetName: reporting,
  },
  {
    text: CHART,
    widgetName: chart,
  },
];

const TradingViewButtons = ({ b, setActiveWidget, chartOnly }) => (
  <div className={b('trading-view-buttons')}>
    {chartOnly ? (
      <Button
        className={b('button').toString()}
        onClick={() => setActiveWidget(miniChart)}
      >
        <p>{CHART}</p>
      </Button>
    ) : (
      buttonsContent.map(({ text, widgetName }) => (
        <Button
          key={text}
          className={b('button').toString()}
          onClick={() => setActiveWidget(widgetName)}
        >
          <p>{text}</p>
        </Button>
      ))
    )}
  </div>
);

TradingViewButtons.propTypes = propTypes;
TradingViewButtons.defaultProps = defaultProps;

export default TradingViewButtons;
