import { COPY_CONTRACT } from 'redux/agentData/types';
import moment from 'moment';
import _ from 'lodash';
import { CALC_TYPES } from 'containers/_contractExecution/NszCalculation';
import {
  LANG_DICTIONARY,
} from 'consts';
import {
  GET_PRODUCT_INFO,
  CHANGE_FIELD,
  GET_GENDER_LIST,
  CLEAR_STORE_NSZ_PRODUCTS,
  RISKS_LIST_CONTROL,
  GET_ADDITIONAL_RISKS,
  GET_PACKAGE_RISKS,
  COUNT_SUM_NSZ,
  SAVE_DATA,
  CHECK_ACCOUNT_NUMBERS,
  SET_STAGE_PRODUCT,
} from '../types';

const {
  PREM_REG_TOTAL,
  TAX_DEDUCTION,
  EXPECTED_INCOME,
} = LANG_DICTIONARY;

const INITIAL_FIELD_VALUES = {
  errors: {},
  riskErrors: {},
  hints: {},
  touched: {
    regularPaymentAmount: true,
    accumulationAmount: true,
  },
  refCurrencyId: null,
  disableCountButton: true,
  additionalRisks: [],
  packageRisks: [],
  insuranceSums: [],
  productTerms: [],
  coefId: null,
  isCurrencyScenario: null,
};

const INITIAL_STATE = {
  nszProductValues: {},
  fieldValues: INITIAL_FIELD_VALUES,
  calculatorData: {},
  insurerValues: null,
  insuredValues: null,
  childInsuredValues: {},
  insuredIsSame: true,
  contractData: {},
  beneficiaryValues: null,
  beneficiaryByLaw: true,
  stage: 1,
  genderList: [],
  risksLists: {
    basic: false,
    additional: false,
    additionalPackage: false,
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case CHECK_ACCOUNT_NUMBERS.success: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }

    case CHECK_ACCOUNT_NUMBERS.fail: {
      return {
        ...state,
        checkAccountNumbersError: payload,
      };
    }

    case SAVE_DATA: {
      return {
        ...state,
        ...payload,
        stage: 2,
      };
    }

    case COUNT_SUM_NSZ.success: {
      const { fieldValues } = state;
      const {
        expectedIncome,
        premRegTotal,
        regularPaymentAmount,
        taxDeduction,
      } = payload;

      const calculatedValues = [
        {
          title: PREM_REG_TOTAL,
          value: premRegTotal,
        },
        {
          title: TAX_DEDUCTION,
          value: taxDeduction,
        },
        {
          title: EXPECTED_INCOME,
          value: expectedIncome,
        },
      ];

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          ...payload,
          calculatedValues,
          regularPaymentAmount: regularPaymentAmount &&
            String(regularPaymentAmount).replace('.', ','),
        },
      };
    }
    case GET_ADDITIONAL_RISKS.success: {
      const { fieldValues } = state;

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          additionalRisks: payload,
        },
      };
    }

    case GET_PACKAGE_RISKS.success: {
      const { fieldValues } = state;

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          packageRisks: payload,
        },
      };
    }

    case RISKS_LIST_CONTROL: {
      const { risksLists } = state;
      const { name, value } = payload;

      return {
        ...state,
        risksLists: {
          ...risksLists,
          [name]: value,
        },
      };
    }

    case CLEAR_STORE_NSZ_PRODUCTS: {
      return INITIAL_STATE;
    }

    case GET_GENDER_LIST.success: {
      return {
        ...state,
        genderList: payload,
      };
    }

    case CHANGE_FIELD: {
      const { fieldValues } = state;
      const { name, value } = payload;

      return {
        ...state,
        fieldValues: {
          ...fieldValues,
          [name]: value,
          touched: {
            ...fieldValues.touched,
            [name]: true,
          },
        },
      };
    }

    case GET_PRODUCT_INFO.success: {
      const { refExchangeRatesRegimesCoefs } = payload;

      return {
        ...state,
        nszProductValues: payload,
        refExchangeRatesRegimesCoefs,
      };
    }

    case SET_STAGE_PRODUCT:
      return {
        ...state,
        stage: payload,
      };

    case COPY_CONTRACT.success: {
      const childInsuredBirthDateValue = _.get(payload, 'childInsured.birthDate');
      const childInsuredBirthDate = childInsuredBirthDateValue &&
        moment(childInsuredBirthDateValue).toDate();
      const childInsuredSexId = _.get(payload, 'childInsured.gender');

      return {
        ...state,
        fieldValues: {
          ...state.fieldValues,
          refCurrencyId: payload.refCurrenciesId,
          insuredBirthDate: moment(payload.insured.birthDate).toDate(),
          insuredSexId: payload.insured.gender,
          premiumFreqId: payload.premiumFreqId,
          productCalcType: CALC_TYPES.from_premium,
          regularPaymentAmount: payload.regularPaymentAmount && String(payload.regularPaymentAmount).replace('.', ','),
          accumulationAmount: payload.accumulationAmount,
          termId: payload.productTermId,
          childInsuredBirthDate,
          childInsuredSexId,
        },
      };
    }

    default:
      return state;
  }
};
