import randomString from 'randomstring';
import {
  GET_PUBLIC_API_LIST,
  SET_INIT_STATE,
  REMOVE_IP_ADDRESS,
  ADD_IP_ADDRESS,
  CHANGE_IP_ADDRESS,
  CHANGE_FIELD_BY_NAME,
  GET_DATA_FOR_SELECT_API,
  GET_PARTNERS,
  GET_BRANCHES,
  GET_USERS,
  CREATE_NEW_PUBLIC_API,
  SET_STATUS_POPUP,
  GET_PUBLIC_API_BY_ID,
  REGENERATE_SECRET_KEY,
} from '../types';

const INITIAL_STATE = {
  publicApiList: [],
  apiSelectData: [],
  partners: [],
  branches: [],
  users: [],
  loadingBranches: false,
  loadingPartners: false,
  loadingUsers: false,
  publicApiListLoading: false,
  isOpenPopup: false,
  currentPage: 1,
  totalPages: 1,
  error: '',
  apiData: {
    id: 0,
    apiKey: '',
    secretKey: '',
    ipAddress: [{
      ip: '',
      id: randomString.generate(5),
    }],
    isAccessNotRestriction: true,
    isActive: false,
    comment: '',
    userId: null,
    branchId: null,
    partnerId: null,
    publicApi: [],
    secretKeyUpdateDate: null,
    action: '',
  },
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case REGENERATE_SECRET_KEY.success:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ...payload,
        },
      };

    case GET_PUBLIC_API_BY_ID.start:
      if (payload) {
        return state;
      }

      return {
        ...state,
        apiData: {
          ...state.apiData,
          ...INITIAL_STATE.apiData,
          secretKey: state.apiData.secretKey,
        },
      };

    case GET_PUBLIC_API_BY_ID.success:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ...payload,
          isAccessNotRestriction: !payload.ipAddress,
          publicApi: payload.publicApi.map((item) => item.id),
          ipAddress: payload.ipAddress ? payload.ipAddress.split(',').map((item) => ({
            id: randomString.generate(5),
            ip: item,
          })) : [],
        },
      };

    case SET_STATUS_POPUP:
      return {
        ...state,
        isOpenPopup: payload,
      };

    case CREATE_NEW_PUBLIC_API.success:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ...payload,
        },
      };

    case GET_PUBLIC_API_LIST.success:
      return {
        ...state,
        publicApiList: payload.data,
        currentPage: payload.currentPage,
        totalPages: payload.totalPages,
        publicApiListLoading: false,
      };

    case GET_PUBLIC_API_LIST.fail:
      return {
        ...state,
        publicApiListLoading: false,
        error: payload,
      };

    case GET_PUBLIC_API_LIST.start:
      return {
        ...state,
        publicApiListLoading: true,
        error: payload,
      };

    case REMOVE_IP_ADDRESS: {
      const ipAddress = state.apiData.ipAddress.filter((ip) => ip.id !== payload);

      return {
        ...state,
        apiData: {
          ...state.apiData,
          isAccessNotRestriction: !ipAddress.length,
          ipAddress,
        },
      };
    }

    case ADD_IP_ADDRESS:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ipAddress: [
            ...state.apiData.ipAddress,
            { id: randomString.generate(5), ip: '' },
          ],
        },
      };

    case CHANGE_IP_ADDRESS:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          ipAddress: state.apiData.ipAddress.map((item) => (item.id === payload.id ?
            { id: item.id, ip: payload.value } : item)),
        },
      };

    case CHANGE_FIELD_BY_NAME:
      return {
        ...state,
        apiData: {
          ...state.apiData,
          [payload.name]: payload.value,
        },
      };

    case GET_DATA_FOR_SELECT_API.success:
      return {
        ...state,
        apiSelectData: payload.data.map(({
          refPublicApi_code: key,
          refPublicApi_description: text,
          refPublicApi_id: value,
        }) => ({
          key,
          value,
          text,
        })),
      };

    case SET_INIT_STATE:
      return INITIAL_STATE;

    case GET_PARTNERS.start:
      return {
        ...state,
        loadingPartners: true,
      };

    case GET_PARTNERS.fail:
      return {
        ...state,
        loadingPartners: false,
      };

    case GET_PARTNERS.success:
      return {
        ...state,
        partners: payload.map(({ id, partner }) => ({
          key: id,
          value: id,
          text: partner,
        })),
        loadingPartners: false,
      };

    case GET_BRANCHES.start:
      return {
        ...state,
        loadingBranches: true,
      };

    case GET_BRANCHES.fail:
      return {
        ...state,
        loadingBranches: false,
      };

    case GET_BRANCHES.success:
      return {
        ...state,
        loadingBranches: false,
        branches: payload.map(({ branchId, branchName }) => ({
          text: branchName,
          key: branchId,
          value: branchId,
        })),
      };

    case GET_USERS.start:
      return {
        ...state,
        loadingUsers: true,
      };

    case GET_USERS.fail:
      return {
        ...state,
        loadingUsers: false,
      };

    case GET_USERS.success:
      return {
        ...state,
        loadingUsers: false,
        users: payload.map(({ userFio, userId }) => ({
          text: userFio,
          value: userId,
          key: userId,
        })),
      };

    default:
      return state;
  }
};
