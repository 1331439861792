import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { PopupContainer } from 'components';
import { selectBasketAssets } from 'redux/basketConstructor/selectors';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import { useSelector } from 'react-redux';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { useActions } from 'hooks/useActions';
import TradingViewButtons from './components/TradingViewButtons';
import { TradingViewWidgetsWithPopup } from './components/TradingViewWidgets';

import './styles.scss';

const b = block('description-assets-popup');

const propTypes = {
  chartOnly: PropTypes.bool,
};

const defaultProps = {
  chartOnly: false,
};

const DescriptionAssets = ({ chartOnly }) => {
  const { clearAssetsDescription } = useActions(basketConstructorActions);
  const assets = useSelector(selectBasketAssets);

  const [activeWidget, setActiveWidget] = useState('');

  return (
    <PopupContainer
      close={clearAssetsDescription}
      isOpen={Boolean(assets.data.length) || assets.isLoading}
      isCloseButton={!assets.isLoading}
      className={b()}
    >
      <div className={b('content')}>
        <Segment
          className={b('segment', { loading: assets.isLoading }).toString()}
        >
          <Dimmer
            className={b('dimmer').toString()}
            active={assets.isLoading}
            inverted
          >
            <Loader inverted content={LANG_DICTIONARY.DOWNLOADING} />
          </Dimmer>
          {assets.data.map((item) => (
            <p className={b('desc')} key={item.name}>
              <b>{item.name}</b>
              {` - ${item.desc}`}
            </p>
          ))}
        </Segment>
        <TradingViewButtons
          b={b}
          setActiveWidget={setActiveWidget}
          chartOnly={chartOnly}
        />
      </div>
      {assets.data.length && (
        <TradingViewWidgetsWithPopup
          setActiveWidget={setActiveWidget}
          activeWidget={activeWidget}
          assetsData={assets.data}
        />
      )}
    </PopupContainer>
  );
};

DescriptionAssets.propTypes = propTypes;
DescriptionAssets.defaultProps = defaultProps;

export default DescriptionAssets;
