import moment from 'moment/moment';
import {
  onlyNumbers,
  yupEmpty,
  checkSnilsForYup,
  checkPassportExpired,
  yupCheckIssuedByAccepted,
  getStartOfDayDate,
} from 'helpers';
import { LANG_DICTIONARY, DATE_FORMAT, REGEXP } from 'consts';

import {
  object,
  string,
  date,
  mixed,
} from 'yup';

const {
  REQUIRED,
  IS_DATE,
  NO_MORE_TWO_HUNDRED_FIFTY_FIVE,
  ONLY_CYRILLIC_LATIN_SYMBOLS_QUOTES,
  FILL_COMPLETELY,
  CHECK_SUM_CUR_ACCOUNT,
  PASSPORT_EXPIRED,
  WARNING_CYRILLIC_LATIN_DEF_NUMBERS,
  DOCUMENT_TYPE_PASSPORT,
  DOCUMENT_TYPE_BIRTH_DOCUMENT,
  DOCUMENT_TYPE_TEMP_CERT,
  ISSUED_BY_ACCEPT_REQUIRED,
  DATE_INVALID,
  TEMP_CERT_VALID_UNTIL_DATE,
} = LANG_DICTIONARY;

const {
  CYRILLIC_LATIN_NUMBERS_SIMBOLS,
  MIN_SIX,
  MIN_FOUR,
  MIN_TWELVE,
  KP, NUMBERS_CYRILLIC_LATIN_DEF,
} = REGEXP;

export default (args = {}) => object().shape({
  documentType: string().required(REQUIRED),
  issuedBy: string()
    .max(255, NO_MORE_TWO_HUNDRED_FIFTY_FIVE)
    .matches(CYRILLIC_LATIN_NUMBERS_SIMBOLS, ONLY_CYRILLIC_LATIN_SYMBOLS_QUOTES)
    .required(REQUIRED)
    .when('documentType', {
      is: DOCUMENT_TYPE_PASSPORT.toString(),
      then: string().test(
        'is-accepted',
        ISSUED_BY_ACCEPT_REQUIRED,
        yupCheckIssuedByAccepted,
      ),
    }),
  when: date()
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_PASSPORT,
      then: date()
        .typeError(IS_DATE)
        .required(REQUIRED)
        .test('len',
          PASSPORT_EXPIRED, (val) => (args.birthDate && !args.burnDocument
            ? checkPassportExpired(args.birthDate, val, args.checkPassportCovid)
            : true)),
    })
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_TEMP_CERT,
      then: date()
        .typeError(IS_DATE)
        .required(REQUIRED)
        .min(moment(args.birthDate || new Date()).add(14, 'years'), TEMP_CERT_VALID_UNTIL_DATE)
        .max(new Date(), DATE_INVALID)
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate()))
        .required(REQUIRED),
    })
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_BIRTH_DOCUMENT,
      then: date()
        .typeError(IS_DATE)
        .required(REQUIRED)
        .min(args.birthDate || new Date(), DATE_INVALID)
        .max(new Date(), DATE_INVALID)
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate())),
    }),
  validUntil: mixed()
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_TEMP_CERT,
      then: date()
        .typeError(IS_DATE)
        .min(getStartOfDayDate(new Date()), TEMP_CERT_VALID_UNTIL_DATE)
        .test('max', TEMP_CERT_VALID_UNTIL_DATE,
          // eslint-disable-next-line func-names
          function (val) {
            const { when } = this.parent;

            return moment(when || new Date()).add(30, 'days').isAfter(val);
          })
        .transform((currentValue, originalValue) => (
          moment(originalValue, DATE_FORMAT, true).toDate()))
        .required(REQUIRED),
      otherwise: mixed().notRequired(),
    }),
  kp: string()
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_PASSPORT,
      then: string().matches(KP, FILL_COMPLETELY).required(REQUIRED),
      otherwise: string().notRequired(),
    }),
  inn: yupEmpty(
    string().test('len', FILL_COMPLETELY, (val) => onlyNumbers(val.toString()).length === 12),
    FILL_COMPLETELY,
  ),
  snils: yupEmpty(
    string().test('len', CHECK_SUM_CUR_ACCOUNT, (val) => {
      const value = onlyNumbers(val.toString());
      const isCompletely = value.length === 11;
      const isValid = checkSnilsForYup(value);

      return isCompletely && isValid;
    }),
    CHECK_SUM_CUR_ACCOUNT,
  ),
  serial: string()
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_TEMP_CERT,
      then: string().notRequired(),
    })
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_BIRTH_DOCUMENT,
      then: string()
        .matches(NUMBERS_CYRILLIC_LATIN_DEF, WARNING_CYRILLIC_LATIN_DEF_NUMBERS)
        .required(REQUIRED)
        .test('len', REQUIRED, (val) => val.trim().length),
    })
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_PASSPORT,
      then: string()
        .matches(MIN_FOUR, FILL_COMPLETELY)
        .required(REQUIRED)
        .test('len', REQUIRED, (val) => val.trim().length),
    }),
  passportNumber: string()
    .when('documentType', {
      is: (documentType) => Number(documentType) === DOCUMENT_TYPE_TEMP_CERT,
      then: string().matches(MIN_TWELVE, FILL_COMPLETELY).required(REQUIRED),
      otherwise: string().matches(MIN_SIX, FILL_COMPLETELY).required(REQUIRED),
    }),
});
