import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { Radio } from 'semantic-ui-react';
import { getDateForPicker } from 'helpers';
import template from './template';
import FlexTable from '../FlexTable';
import './styles.scss';

const b = block('clientReports');

const {
  CONTRACT_NUMBER,
  INVEST_DATE,
  LAST_AVAILABLE_REPORT_DATE,
} = LANG_DICTIONARY;

const headers = [
  CONTRACT_NUMBER,
  INVEST_DATE,
  LAST_AVAILABLE_REPORT_DATE,
];

const propTypes = {
  isSelectedContractId: PropTypes.number,
  setChoseContract: PropTypes.func,
  reportsData: PropTypes.array,
};

const defaultProps = {
  isSelectedContractId: 0,
  setChoseContract: () => null,
  reportsData: [],
};

const FlexTableClientReports = (props) => {
  const {
    isSelectedContractId,
    setChoseContract,
    reportsData,
  } = props;

  const rebuildData = reportsData
    .filter(Boolean)
    .map(({
      numFull,
      dateInvest,
      actualDate,
      id,
    }) => template(headers, [
      numFull,
      getDateForPicker(dateInvest),
      <div className={b('checkbox-wrapper')}>
        <div>{getDateForPicker(actualDate)}</div>
        <div className={b('checkbox')}>
          <Radio
            value={isSelectedContractId}
            checked={isSelectedContractId === id}
            name="isSelectedContractId"
            onChange={() => {
              setChoseContract({ id, actualDate });
            }}
          />
        </div>
      </div>,
    ]));

  return (
    <FlexTable data={rebuildData} minHeight={40} />
  );
};

FlexTableClientReports.propTypes = propTypes;
FlexTableClientReports.defaultProps = defaultProps;

export default FlexTableClientReports;
