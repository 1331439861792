export default (headers, data) => ({
  key: data[0],
  data: [
    {
      md: 2,
      headers: [
        { text: headers[0] },
        { text: headers[1] },
      ],
      data: [
        { text: data[0] },
        { text: data[1] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[3] },
        { text: headers[4] },
      ],
      data: [
        { text: data[3] },
        { text: data[4] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[5] },
      ],
      data: [
        { text: data[5] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[6] },
      ],
      data: [
        { text: data[6] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[7] },
      ],
      data: [
        { text: data[7] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[8] },
      ],
      data: [
        { text: data[8] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[9] },
      ],
      data: [
        { text: data[9] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[10] },
      ],
      data: [
        { text: data[10] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[11] },
      ],
      data: [
        { text: data[11] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[12] },
      ],
      data: [
        { text: data[12] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[13] },
        { text: headers[14] },
      ],
      data: [
        { text: data[13] },
        { text: data[14] },
      ],
    },
    {
      md: 2,
      headers: [
        { text: headers[15] },
        { text: headers[16] },
      ],
      data: [
        { text: data[15] },
        { text: data[16] },
      ],
    },
  ],
});
