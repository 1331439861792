import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { roundAndSeparateThousands } from 'helpers';
import { ROUND_CONTEST_VALUES } from 'config';

import './styles.scss';

const b = block('user-card-info-contest');
const {
  SUBDIVISION,
  CLUSTER,
  PLACE_IN_THE_RANKING,
  SALES,
  RUB_SHORT,
  NUMBER_OF_AGREEMENTS_SOLD,
  QUANTITY_ITEM_SHORT,
  AVERAGE_CHECK,
  COLLECTION_PLAN,
  IMPLEMENTATION_OF_A_PLAN,
} = LANG_DICTIONARY;

const propTypes = {
  user: PropTypes.object.isRequired,
  compId: PropTypes.number.isRequired,
};

const UserCardInfo = ({
  user: {
    name,
    branchName,
    clasterNumber: clusterNumber,
    order,
    salesAmount,
    salesCount,
    salesAverage,
    planValue,
    planProgress,
  },
  compId,
}) => {
  const rows = [
    { name: SUBDIVISION, text: branchName, mod: 'subdivision' },
    { name: CLUSTER, text: clusterNumber },
    { name: PLACE_IN_THE_RANKING, text: order },
    { name: `${SALES}, ${RUB_SHORT}`, text: roundAndSeparateThousands(salesAmount, ROUND_CONTEST_VALUES) },
    { name: `${NUMBER_OF_AGREEMENTS_SOLD}, ${QUANTITY_ITEM_SHORT}`, text: salesCount },
    { name: `${AVERAGE_CHECK}, ${RUB_SHORT}`, text: roundAndSeparateThousands(salesAverage, ROUND_CONTEST_VALUES) },
    { name: `${COLLECTION_PLAN}, ${RUB_SHORT}`, text: roundAndSeparateThousands(planValue, ROUND_CONTEST_VALUES) },
    { name: `${IMPLEMENTATION_OF_A_PLAN}, %`, text: roundAndSeparateThousands(planProgress, ROUND_CONTEST_VALUES) },
  ];

  return (
    <div className={b()}>
      <div className={b('title-wrapper')}>
        <p className={b('title')}>{name}</p>
      </div>
      {rows.map((row) => (
        <div className={b('row')} key={`card${row.name}${compId}`}>
          <p className={b('row-name', { mod: row.mod })}>{`${row.name}:`}</p>
          <p className={b('row-text', { mod: row.mod })}>{row.text}</p>
        </div>
      ))}
    </div>
  );
};

UserCardInfo.propTypes = propTypes;
export default UserCardInfo;
