import React from 'react';
import { block } from 'bem-cn';
import { garantsType } from 'consts/propTypes';
import './styles.scss';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';

const b = block('garants-table');
const bHeaderRow = block('garants-table-header-row');
const bBodyRow = block('garants-table-body-row');

const defaultProps = {
  garants: [],
};
const propTypes = {
  garants: PropTypes.arrayOf(garantsType),
};

const { THE_AMOUNT_OF_THE_GUARANTEE_PAYMENT_PERCENT, PAYMENT_TERM_MONTH } = LANG_DICTIONARY;

const GarantsTable = ({ garants }) => (
  <div className={b('wrapper')}>
    <table className={b()}>
      <thead>
        <tr className={bHeaderRow()}>
          <th className={bHeaderRow('payment-term')}>{PAYMENT_TERM_MONTH}</th>
          <th className={bHeaderRow('garant-value')}>{THE_AMOUNT_OF_THE_GUARANTEE_PAYMENT_PERCENT}</th>
        </tr>
      </thead>
      <tbody>
        {garants.map((garant) => (
          <tr className={bBodyRow()} key={garant.garantId}>
            <td className={bBodyRow('payment-term')}>{garant.paymentTerm}</td>
            <td className={bBodyRow('garant-value')}>{garant.value}</td>
          </tr>
        ))}
      </tbody>
    </table>
  </div>
);

GarantsTable.defaultProps = defaultProps;
GarantsTable.propTypes = propTypes;

export default GarantsTable;
