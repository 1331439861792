import { put, takeEvery, takeLatest } from 'redux-saga/effects';
import { apiCall, getError, apiDownload } from 'helpers';
import { API } from 'consts';
import parseUrl from 'parse-url';
import { getGlobalHistory } from 'components';
import {
  SET_TIMEOUT_WORK,
  CHECK_EXIST_MANUAL_REQUEST,
  CHECK_EXIST_MANUAL_SUCCESS,
  DOWNLOAD_MANUAL_REQUEST,
  DOWNLOAD_MANUAL_SUCCESS,
  SAVE_AND_CLEAR_QUERY_PARAMS_STORE,
  SAVE_AND_CLEAR_QUERY_PARAMS,
} from '../types';

function* timeoutWork() {
  yield put({ type: SET_TIMEOUT_WORK });
}

function* checkExistManual({ payload }) {
  try {
    const { data } = yield apiCall({
      type: 'GET',
      url: API.CHECK_EXIST_MANUAL,
      query: {
        partnerId: payload,
      },
    });
    yield put({ type: CHECK_EXIST_MANUAL_SUCCESS, payload: data });
  } catch (e) {
    getError(e);
  }
}

function* downloadManual({ payload: { partnerId, manualName } }) {
  try {
    yield apiDownload({
      type: 'GET',
      url: API.DOWNLOAD_MANUAL,
      query: {
        partnerId,
      },
    }, manualName);
    yield put({ type: DOWNLOAD_MANUAL_SUCCESS });
  } catch (e) {
    getError(e);
  }
}

function* saveAndClearQueryFromUrlParams() {
  const { query } = parseUrl(window.location.href);
  yield put({ type: SAVE_AND_CLEAR_QUERY_PARAMS_STORE, payload: query });
  const history = getGlobalHistory();
  history.push({
    location: history.location,
    search: '',
  });
}

function* sharedSaga() {
  yield takeLatest(SAVE_AND_CLEAR_QUERY_PARAMS, saveAndClearQueryFromUrlParams);
  yield takeLatest(CHECK_EXIST_MANUAL_REQUEST, checkExistManual);
  yield takeLatest(DOWNLOAD_MANUAL_REQUEST, downloadManual);
  yield takeEvery((action) => action.type.indexOf('_REQUEST') + 1, timeoutWork);
}

export default sharedSaga;
