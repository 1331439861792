import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { withWindowDimensions, withCustomRouter } from 'HOC';
import { useActions } from 'hooks/useActions';
import * as basketConstructorActions from 'redux/basketConstructor/actions';
import { useSelector } from 'react-redux';
import {
  selectIsValueLessThanOneHalfMillion,
  selectConstructorTableControlData,
} from 'redux/basketConstructor/selectors';
import { CONSTRUCTOR_TABLE } from 'consts';
import MainBarrierOptionTable from './components/MainBarrierOptionTable';
import './styles.scss';

const b = block('info-table-constructor-isz');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object).isRequired,
  isSortAndFilterInterface: PropTypes.bool,
  isCalculatedIndividualBaskets: PropTypes.bool,
  onEndScrollTable: PropTypes.func.isRequired,
  isScrollLoading: PropTypes.bool,
  onToggleOpenChildBasket: PropTypes.func.isRequired,
  optionCode: PropTypes.string,
  onSelectItem: PropTypes.func.isRequired,
  windowHeight: PropTypes.number.isRequired,
  windowWidth: PropTypes.number.isRequired,
  onUpdateTable: PropTypes.func.isRequired,
  childSelectedIds: PropTypes.object.isRequired,
  regularBasketType: PropTypes.string,
  readyBasketType: PropTypes.string,
  productId: PropTypes.number,
  currencyCode: PropTypes.string,
  history: PropTypes.object.isRequired,
  productOptions: PropTypes.arrayOf(PropTypes.object),
  filters: PropTypes.object,
  sorts: PropTypes.object,
};

const defaultProps = {
  isCalculatedIndividualBaskets: false,
  isScrollLoading: false,
  isSortAndFilterInterface: true,
  optionCode: null,
  regularBasketType: 'regular',
  readyBasketType: 'ready',
  productId: null,
  currencyCode: null,
  productOptions: [],
  filters: {},
  sorts: {},
};

const InfoTableConstructorIsz = (props) => {
  const postfixSortFieldName = 'Raw';
  const containerRef = useRef(null);
  const scrollRef = useRef(null);

  const [prevScrollTop, setPrevScrollTop] = useState(0);
  const [isHorizontalScroll, setIsHorizontalScroll] = useState(false);

  const prevScrollTopRef = useRef();

  const {
    onEndScrollTable,
    data,
    windowHeight,
    windowWidth,
    onUpdateTable,
    onToggleOpenChildBasket,
    childSelectedIds,
    productOptions,
    currencyCode,
    filters,
    sorts,
    history,
  } = props;

  const {
    getAssetsDescription,
    setFavorites,
    deleteFavorites,
  } = useActions(basketConstructorActions);

  const isValueLessThanOneHalfMillion = useSelector(selectIsValueLessThanOneHalfMillion);

  const {
    productTypeCode,
    guaranteeLevel,
    generalOptionCode,
    isOnlyFavorite,
  } = useSelector(selectConstructorTableControlData);
  const [startArrowPositions, setStartArrowPositions] = useState([]);

  const handleToggleOpenChildBasket = (e, id, status) => {
    e.stopPropagation();
    onToggleOpenChildBasket(id, status);
  };

  useEffect(() => {
    const isUpdate = guaranteeLevel
      && currencyCode
      && generalOptionCode
      && productTypeCode;

    if (isUpdate) {
      onUpdateTable({
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
        isOnlyFavorite,
      });
    }
  }, [
    filters,
    sorts,
    guaranteeLevel,
    currencyCode,
    generalOptionCode,
    productTypeCode,
    isOnlyFavorite,
  ]);

  useEffect(() => {
    if (containerRef.current) {
      const mainElements = containerRef.current.getElementsByClassName(b('main-strategy'));
      const mainPositionStartArrow = Array.from(mainElements).map((item) => ({
        Y: item.offsetTop + item.offsetHeight,
        X: item.offsetLeft + item.offsetWidth / 2,
        width: item.offsetWidth,
      }));
      setStartArrowPositions(mainPositionStartArrow);
    }
  }, [data, windowHeight, windowWidth]);

  useEffect(() => {
    if (prevScrollTop === prevScrollTopRef.current) {
      setIsHorizontalScroll(true);
    } else {
      setIsHorizontalScroll(false);
    }

    prevScrollTopRef.current = prevScrollTop;
  }, [prevScrollTop]);

  const handleScroll = (e) => {
    scrollRef.current = e;

    if (prevScrollTopRef.current === e.target.scrollTop) {
      setIsHorizontalScroll(true);
    }

    setPrevScrollTop(e.target.scrollTop);
  };

  const handleClickAssets = (e, id) => {
    e.stopPropagation();
    getAssetsDescription(id);
  };

  const handleClickFavorites = ({ e, id, isFavorite }) => {
    e.stopPropagation();
    if (isFavorite) {
      deleteFavorites({
        insLineIszCalcRequestId: id,
        partnersInsProductId: productId,
        currencyCode,
      });

      return;
    }

    setFavorites({ insLineIszCalcRequestId: id, partnersInsProductId: productId, currencyCode });
  };

  const handleScrollStop = () => {
    const { target } = scrollRef.current;

    if (!isHorizontalScroll && target.offsetHeight + target.scrollTop >= target.scrollHeight) {
      onEndScrollTable();
    }
  };

  const rebuildSchema = (checkParamsSchema) => {
    const schemaArray = checkParamsSchema();

    if (!isValueLessThanOneHalfMillion) {
      schemaArray[CONSTRUCTOR_TABLE.partitionCoefficientLess] += 1;
    }

    return schemaArray;
  };

  const tableProps = {
    ...props,
    startArrowPositions,
    containerRef,
    scrollRef,
    onClickFavorites: handleClickFavorites,
    onToggleOpenChildBasket: handleToggleOpenChildBasket,
    onClickAssets: handleClickAssets,
    onScroll: handleScroll,
    onScrollStop: handleScrollStop,
    childSelectedIds,
    postfixSortFieldName,
    productOptions,
    isValueLessThanOneHalfMillion,
    rebuildSchema,
  };

  const productId = parseInt(tableProps.productId, 10);

  if (!productId) {
    history.goBack();
  }

  return (
    <>
      <MainBarrierOptionTable
        {...tableProps}
        productId={productId}
        block={b}
      />
    </>
  );
};

InfoTableConstructorIsz.propTypes = propTypes;
InfoTableConstructorIsz.defaultProps = defaultProps;

export default withCustomRouter(withWindowDimensions(InfoTableConstructorIsz));
