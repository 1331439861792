import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY } from 'consts';
import { MainBtn, FlexTableScheduler } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const {
  SCHEDULER_JOBS,
  ACTIVE_JOBS,
  PROCCESS_JOBS,
  COMPLETE_JOBS,
  FAILED_JOBS,
  BACK,
} = LANG_DICTIONARY;

const propTypes = {
  numberOfActive: PropTypes.number,
  numberOfProccess: PropTypes.number,
  numberOfComplete: PropTypes.number,
  numberOfFail: PropTypes.number,
  activeTab: PropTypes.string,
  prioritiSelect: PropTypes.arrayOf(PropTypes.object),
  cronTypeSelect: PropTypes.arrayOf(PropTypes.object),
  popUpOpenEdit: PropTypes.bool,
  popUpOpenStop: PropTypes.bool,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  changeTab: PropTypes.func,
  backToMain: PropTypes.func,
  handleChangeSelect: PropTypes.func,
  saveButtonPopUp: PropTypes.func,
  popUpControl: PropTypes.func,
  stopJobControl: PropTypes.func,
  dictionary: PropTypes.object,
  cronTypeId: PropTypes.number,
  interval: PropTypes.number,
  timeStart: PropTypes.string,
  id: PropTypes.number,
  isActive: PropTypes.bool,
  taskType: PropTypes.string,
  priorityId: PropTypes.number,
  timeForPopup: PropTypes.string,
};

const defaultProps = {
  dictionary: {},
  changeTab: () => null,
  backToMain: () => null,
  handleChangeSelect: () => null,
  saveButtonPopUp: () => null,
  popUpControl: () => null,
  stopJobControl: () => null,
  numberOfActive: 0,
  numberOfProccess: 0,
  numberOfComplete: 0,
  numberOfFail: 0,
  activeTab: '',
  prioritiSelect: [],
  cronTypeSelect: [],
  popUpOpenEdit: false,
  popUpOpenStop: false,
  tableInfo: [],
  cronTypeId: 0,
  interval: 0,
  timeStart: '',
  id: 0,
  isActive: false,
  taskType: '',
  priorityId: 0,
  timeForPopup: '',
};

const b = block('scheduler-form');

const MENU_TAB = 'menu-item';

const ACTIVE_MENU_TAB = 'menu-item-active';

const SchedulerForm = ({
  dictionary,
  tableInfo,
  popUpOpenEdit,
  popUpOpenStop,
  numberOfActive,
  numberOfProccess,
  numberOfComplete,
  numberOfFail,
  activeTab,
  prioritiSelect,
  cronTypeSelect,
  changeTab,
  backToMain,
  handleChangeSelect,
  saveButtonPopUp,
  popUpControl,
  stopJobControl,
  cronTypeId,
  interval,
  timeStart,
  id,
  isActive,
  taskType,
  priorityId,
  timeForPopup,
}) => {
  const menuItem = [
    { text: ACTIVE_JOBS, number: numberOfActive, type: dictionary.ACTIVE },
    { text: PROCCESS_JOBS, number: numberOfProccess, type: dictionary.PROCCESS },
    { text: COMPLETE_JOBS, number: numberOfComplete, type: dictionary.COMPLETE },
    { text: FAILED_JOBS, number: numberOfFail, type: dictionary.FAIL },
  ];

  return (
    <div className={b()}>
      <div className={b('header')}>
        <MainBtn
          text={BACK}
          onClick={backToMain}
          className={b('back-button').toString()}
        />
        <div className={b('scheduler')}>{SCHEDULER_JOBS}</div>
      </div>
      <div className={b('content')}>
        <div className={b('menu')}>
          {menuItem.map(({ text, number, type }) => (
            <div
              className={b(activeTab === type ? ACTIVE_MENU_TAB : MENU_TAB)}
              onClick={() => changeTab(type)}
              role="button"
              tabIndex={-1}
              key={type}
            >
              {text}
              <div className={b('item-number')}>{number}</div>
            </div>
          ))}
        </div>
        <FlexTableScheduler
          data={tableInfo}
          activeTab={activeTab}
          dictionary={dictionary}
          prioritiSelect={prioritiSelect}
          handleChangeSelect={handleChangeSelect}
          popUpOpenEdit={popUpOpenEdit}
          popUpOpenStop={popUpOpenStop}
          saveButtonPopUp={saveButtonPopUp}
          popUpControl={popUpControl}
          stopJobControl={stopJobControl}
          cronTypeSelect={cronTypeSelect}
          cronTypeId={cronTypeId}
          interval={interval}
          timeStart={timeStart}
          id={id}
          isActive={isActive}
          taskType={taskType}
          priorityId={priorityId}
          timeForPopup={timeForPopup}
        />
      </div>
    </div>
  );
};

SchedulerForm.propTypes = propTypes;
SchedulerForm.defaultProps = defaultProps;
export default SchedulerForm;
