import AsyncActionFactory from 'helpers/actionsFactory';

const GET_TABLE_INFO_CONSTRUCTOR_REQUEST = 'constructorIszInterface/GET_TABLE_INFO_CONSTRUCTOR_REQUEST';
const GET_TABLE_INFO_CONSTRUCTOR_START = 'constructorIszInterface/GET_TABLE_INFO_CONSTRUCTOR_START';
const GET_TABLE_INFO_CONSTRUCTOR_SUCCESS = 'constructorIszInterface/GET_TABLE_INFO_CONSTRUCTOR_SUCCESS';
const GET_TABLE_INFO_CONSTRUCTOR_FAIL = 'constructorIszInterface/GET_TABLE_INFO_CONSTRUCTOR_FAIL';

const GET_ASSETS_FOR_CONSTRUCTOR_REQUEST = 'constructorIszInterface/GET_ASSETS_FOR_CONSTRUCTOR_REQUEST';
const GET_ASSETS_FOR_CONSTRUCTOR_START = 'constructorIszInterface/GET_ASSETS_FOR_CONSTRUCTOR_START';
const GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS = 'constructorIszInterface/GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS';
const GET_ASSETS_FOR_CONSTRUCTOR_FAIL = 'constructorIszInterface/GET_ASSETS_FOR_CONSTRUCTOR_FAIL';

const GET_MIN_PAY_REQUEST = 'constructorIszInterface/GET_MIN_PAY_REQUEST';
const GET_MIN_PAY_START = 'constructorIszInterface/GET_MIN_PAY_START';
const GET_MIN_PAY_SUCCESS = 'constructorIszInterface/GET_MIN_PAY_SUCCESS';
const GET_MIN_PAY_FAIL = 'constructorIszInterface/GET_MIN_PAY_FAIL';

const SET_CURRENT_CURRENCY = 'constructorIszInterface/SET_CURRENT_CURRENCY';

const GET_CONTRACT_PERIODS_REQUEST = 'constructorIszInterface/GET_CONTRACT_PERIODS_REQUEST';
const GET_CONTRACT_PERIODS_START = 'constructorIszInterface/GET_CONTRACT_PERIODS_START';
const GET_CONTRACT_PERIODS_SUCCESS = 'constructorIszInterface/GET_CONTRACT_PERIODS_SUCCESS';
const GET_CONTRACT_PERIODS_FAIL = 'constructorIszInterface/GET_CONTRACT_PERIODS_FAIL';

const GET_PAYOUT_TIMES_REQUEST = 'constructorIszInterface/GET_PAYOUT_TIMES_REQUEST';
const GET_PAYOUT_TIMES_START = 'constructorIszInterface/GET_PAYOUT_TIMES_START';
const GET_PAYOUT_TIMES_SUCCES = 'constructorIszInterface/GET_PAYOUT_TIMES_SUCCES';
const GET_PAYOUT_TIMES_FAIL = 'constructorIszInterface/GET_PAYOUT_TIMES_FAIL';

const SET_ASSETS = 'constructorIszInterface/SET_ASSETS';

const SET_PERIOD = 'constructorIszInterface/SET_PERIOD';

const SET_PAYOUT = 'constructorIszInterface/SET_PAYOUT';

const SET_MASK = 'constructorIszInterface/SET_MASK';

const SET_BARRIERS = 'constructorIszInterface/SET_BARRIERS';

const GET_CALCULATE_COUPON = 'constructorIszInterface/GET_CALCULATE_COUPON';
const GET_CALCULATE_COUPON_START = 'constructorIszInterface/GET_CALCULATE_COUPON_START';
const GET_CALCULATE_COUPON_FAIL = 'constructorIszInterface/GET_CALCULATE_COUPON_FAIL';
const GET_CALCULATE_COUPON_SUCCESS = 'constructorIszInterface/GET_CALCULATE_COUPON_SUCCESS';

const GET_CALCULATED_BASKETS_REQUEST = 'constructorIszInterface/GET_CALCULATED_BASKETS_REQUEST';
const GET_CALCULATED_BASKETS_START = 'constructorIszInterface/GET_CALCULATED_BASKETS_START';
const GET_CALCULATED_BASKETS_FAIL = 'constructorIszInterface/GET_CALCULATED_BASKETS_FAIL';
const GET_CALCULATED_BASKETS_SUCCESS = 'constructorIszInterface/GET_CALCULATED_BASKETS_SUCCESS';

const GET_USER_BASKETS_REQUESTS_COUNT_REQUEST = 'constructorIszInterface/GET_USER_BASKETS_REQUESTS_COUNT_REQUEST';
const GET_USER_BASKETS_REQUESTS_COUNT_START = 'constructorIszInterface/GET_USER_BASKETS_REQUESTS_COUNT_START';
const GET_USER_BASKETS_REQUESTS_COUNT_FAIL = 'constructorIszInterface/GET_USER_BASKETS_REQUESTS_COUNT_FAIL';
const GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS = 'constructorIszInterface/GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS';

const GET_CHECKOUT_CALCULATED_BASKET_REQUEST = 'constructorIszInterface/GET_CHECKOUT_CALCULATED_BASKET_REQUEST';
const GET_CHECKOUT_CALCULATED_BASKET_START = 'constructorIszInterface/GET_CHECKOUT_CALCULATED_BASKET_START';
const GET_CHECKOUT_CALCULATED_BASKET_FAIL = 'constructorIszInterface/GET_CHECKOUT_CALCULATED_BASKET_FAIL';
const GET_CHECKOUT_CALCULATED_BASKET_SUCCESS = 'constructorIszInterface/GET_CHECKOUT_CALCULATED_BASKET_SUCCESS';

const GET_MIN_PAY_FOR_USER_TABLES = 'constructorIszInterface/GET_MIN_PAY_FOR_USER_TABLES';

const CLEAR_MIN_PAY = 'constructorIszInterface/CLEAR_MIN_PAY';

const CLEAR_CONSTRUCTOR_CREATOR = 'constructorIszInterface/CLEAR_CONSTRUCTOR_CREATOR';

const GET_PRODUCTS_GROUPS_START = 'constructorIszInterface/GET_PRODUCTS_GROUPS_START';
const GET_PRODUCTS_GROUPS_REQUEST = 'constructorIszInterface/GET_PRODUCTS_GROUPS_REQUEST';
const GET_PRODUCTS_GROUPS_FAIL = 'constructorIszInterface/GET_PRODUCTS_GROUPS_FAIL';
const GET_PRODUCTS_GROUPS_SUCCESS = 'constructorIszInterface/GET_PRODUCTS_GROUPS_SUCCESS';

const GET_CONSTRUCTOR_SETTINGS_REQUEST = 'constructorIszInterface/GET_CONSTRUCTOR_SETTINGS_REQUEST';
const GET_CONSTRUCTOR_SETTINGS_START = 'constructorIszInterface/GET_CONSTRUCTOR_SETTINGS_START';
const GET_CONSTRUCTOR_SETTINGS_FAIL = 'constructorIszInterface/GET_CONSTRUCTOR_SETTINGS_FAIL';
const GET_CONSTRUCTOR_SETTINGS_SUCCESS = 'constructorIszInterface/GET_CONSTRUCTOR_SETTINGS_SUCCESS';

const CLEAR_ASSETS = 'constructorIszInterface/CLEAR_ASSETS';

const factory = new AsyncActionFactory('constructorIszInterface');
const CHANGE_GUARANTEE_STATE = factory.create('CHANGE_GUARANTEE_VALUE');
const SET_INIT_STATE = factory.create('SET_INIT_STATE');
const SET_SORT_DATA = factory.create('SET_SORT_DATA');
const SET_FILTER_DATA = factory.create('SET_FILTER_DATA');
const CLEAR_FILTER_AND_SORT = factory.create('CLEAR_FILTER_AND_SORT');
const COPY_REQUEST_DATA = factory.createAsync('CLEAR_FILTER_AND_SORT');
const GENERATE_XLSX_REPORT = factory.createAsync('GENERATE_XLSX_REPORT');
const GET_SETTINGS_AND_CALC_REQUESTS = factory.createAsync('GET_SETTINGS_AND_CALC_REQUESTS');
const GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE = factory.create('GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE');
const SET_DESCRIPTION_ASSETS = factory.create('SET_DESCRIPTION_ASSETS');
const SET_OPTION_TYPE_ID = factory.create('SET_OPTION_TYPE_ID');
const SET_ERROR_BARRIER = factory.create('SET_ERROR_BARRIER');
const SET_ERRORS_BARRIER = factory.create('SET_ERRORS_BARRIER');

export {
  SET_OPTION_TYPE_ID,
  SET_ERRORS_BARRIER,
  SET_ERROR_BARRIER,
  SET_DESCRIPTION_ASSETS,
  GET_SETTING_CONSTRUCTOR_FOR_FORM_CREATE,
  GET_SETTINGS_AND_CALC_REQUESTS,
  GENERATE_XLSX_REPORT,
  COPY_REQUEST_DATA,
  CLEAR_FILTER_AND_SORT,
  SET_FILTER_DATA,
  SET_SORT_DATA,
  CHANGE_GUARANTEE_STATE,
  SET_INIT_STATE,
  CLEAR_ASSETS,
  GET_CONSTRUCTOR_SETTINGS_SUCCESS,
  GET_CONSTRUCTOR_SETTINGS_FAIL,
  GET_CONSTRUCTOR_SETTINGS_START,
  GET_CONSTRUCTOR_SETTINGS_REQUEST,
  GET_CHECKOUT_CALCULATED_BASKET_SUCCESS,
  GET_PRODUCTS_GROUPS_START,
  GET_PRODUCTS_GROUPS_SUCCESS,
  GET_PRODUCTS_GROUPS_FAIL,
  GET_PRODUCTS_GROUPS_REQUEST,
  CLEAR_CONSTRUCTOR_CREATOR,
  CLEAR_MIN_PAY,
  GET_MIN_PAY_FOR_USER_TABLES,
  GET_CALCULATE_COUPON_SUCCESS,
  GET_CHECKOUT_CALCULATED_BASKET_REQUEST,
  GET_CHECKOUT_CALCULATED_BASKET_START,
  GET_CHECKOUT_CALCULATED_BASKET_FAIL,
  GET_USER_BASKETS_REQUESTS_COUNT_REQUEST,
  GET_USER_BASKETS_REQUESTS_COUNT_START,
  GET_USER_BASKETS_REQUESTS_COUNT_FAIL,
  GET_USER_BASKETS_REQUESTS_COUNT_SUCCESS,
  GET_CALCULATED_BASKETS_REQUEST,
  GET_CALCULATED_BASKETS_START,
  GET_CALCULATED_BASKETS_FAIL,
  GET_CALCULATED_BASKETS_SUCCESS,
  GET_CALCULATE_COUPON_START,
  GET_CALCULATE_COUPON_FAIL,
  GET_CALCULATE_COUPON,
  SET_BARRIERS,
  SET_MASK,
  SET_PERIOD,
  SET_PAYOUT,
  SET_ASSETS,
  GET_TABLE_INFO_CONSTRUCTOR_FAIL,
  GET_TABLE_INFO_CONSTRUCTOR_REQUEST,
  GET_TABLE_INFO_CONSTRUCTOR_START,
  GET_TABLE_INFO_CONSTRUCTOR_SUCCESS,
  GET_ASSETS_FOR_CONSTRUCTOR_REQUEST,
  GET_ASSETS_FOR_CONSTRUCTOR_START,
  GET_ASSETS_FOR_CONSTRUCTOR_SUCCESS,
  GET_ASSETS_FOR_CONSTRUCTOR_FAIL,
  GET_MIN_PAY_REQUEST,
  GET_MIN_PAY_START,
  GET_MIN_PAY_SUCCESS,
  GET_MIN_PAY_FAIL,
  SET_CURRENT_CURRENCY,
  GET_CONTRACT_PERIODS_REQUEST,
  GET_CONTRACT_PERIODS_START,
  GET_CONTRACT_PERIODS_SUCCESS,
  GET_CONTRACT_PERIODS_FAIL,
  GET_PAYOUT_TIMES_REQUEST,
  GET_PAYOUT_TIMES_START,
  GET_PAYOUT_TIMES_SUCCES,
  GET_PAYOUT_TIMES_FAIL,
};
