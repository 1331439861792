import React, { Component } from 'react';
import { block } from 'bem-cn';
import { UnderwritingAdditionalInfo } from 'components';
import { Collapse } from 'reactstrap';
import PropTypes from 'prop-types';
import ProductRow from './productRow';
import './styles.scss';

const propTypes = {
  data: PropTypes.object,
  config: PropTypes.object,
  toggleInfo: PropTypes.func,
  index: PropTypes.number,
};

const defaultProps = {
  data: {
    contractData: {},
    insurerData: {},
    beneficiaryData: [],
    insuredData: [],
    checkingData: [],
  },
  config: {},
  toggleInfo: () => null,
  index: 0,
};

const b = block('underwriting-product');

class UnderwritingTableRow extends Component {
  toggleAdditionalInfo = (id) => {
    const { config, toggleInfo } = this.props;
    const status = config[id];
    toggleInfo(id, !status);
  };

  render() {
    const {
      data,
      data: { contractData },
      config,
      index,
    } = this.props;
    const { ctsId } = contractData;
    const showInfo = config[ctsId];

    return (
      <div className={b()}>
        <ProductRow
          {...data}
          isShowHeader={index === 0}
          handleOpenInfo={this.toggleAdditionalInfo}
          showInfo={showInfo}
        />
        <Collapse isOpen={Boolean(showInfo)}>
          <UnderwritingAdditionalInfo
            {...data}
          />
        </Collapse>
      </div>
    );
  }
}

UnderwritingTableRow.propTypes = propTypes;
UnderwritingTableRow.defaultProps = defaultProps;
export default UnderwritingTableRow;
