import { LANG_DICTIONARY, IMPORT_FILE_TYPE, IMPORT_PODFT_TYPE } from 'consts';
import xmlIcon from 'assets/images/xml.png';
import bz2 from 'assets/images/bz2.png';

const xmlFileType = '.xml';
const bz2FileType = '.bz2';

const {
  UPLOAD_LIST_TERRORIST,
  UPLOAD_LIST_MBK,
  UPLOAD_LIST_FROMU,
  UPDATE_BAD_INSURERS_LIST,
  UPLOAD_LIST_OON,
  UPDATE_LIST_BAD_ADRESS,
  UPDATE_LIST_INVALID_PASSPORT,
} = LANG_DICTIONARY;

const {
  importExtremism,
  importMvk,
  importFromu,
  importBadInsurantsXlsx,
  importExtremismOon,
  importAdress,
  importPassport,
} = IMPORT_FILE_TYPE;

const {
  extremismPodftType,
  fromuPodftType,
  mvkPodftType,
  badInsurerPodftType,
  extremismOonPodftType,
  addressPodftType,
  pasportPodftType,
} = IMPORT_PODFT_TYPE;

export const uploadBlocksPodft = [
  {
    title: UPLOAD_LIST_TERRORIST,
    type: importExtremism,
    acceptFiles: xmlFileType,
    icon: xmlIcon,
    podftType: extremismPodftType,
  },
  {
    title: UPLOAD_LIST_OON,
    type: importExtremismOon,
    acceptFiles: xmlFileType,
    icon: xmlIcon,
    podftType: extremismOonPodftType,
  },
  {
    title: UPLOAD_LIST_MBK,
    type: importMvk,
    acceptFiles: xmlFileType,
    icon: xmlIcon,
    podftType: mvkPodftType,
  },
  {
    title: UPLOAD_LIST_FROMU,
    type: importFromu,
    acceptFiles: xmlFileType,
    icon: xmlIcon,
    podftType: fromuPodftType,
  },
  {
    title: UPDATE_BAD_INSURERS_LIST,
    type: importBadInsurantsXlsx,
    podftType: badInsurerPodftType,
  },
  {
    title: UPDATE_LIST_BAD_ADRESS,
    type: importAdress,
    podftType: addressPodftType,
  },
  {
    title: UPDATE_LIST_INVALID_PASSPORT,
    type: importPassport,
    acceptFiles: bz2FileType,
    icon: bz2,
    podftType: pasportPodftType,
  },
];
