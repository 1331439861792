import React, { memo } from 'react';
import PropTypes from 'prop-types';
import { Icon, Input } from 'semantic-ui-react';
import { REGEXP } from 'consts';
import { block } from 'bem-cn';
import './styles.scss';

const b = block('ip-editor');
const { IP_ADDRESS } = REGEXP;

const propTypes = {
  id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  ip: PropTypes.string.isRequired,
  onRemoveIp: PropTypes.func.isRequired,
  onChangeIp: PropTypes.func.isRequired,
  focusId: PropTypes.string.isRequired,
  setFocusId: PropTypes.func.isRequired,
};

const validateIp = (ip) => IP_ADDRESS.test(ip);

const IPeditorRow = ({
  id,
  ip,
  onRemoveIp,
  onChangeIp,
  focusId,
  setFocusId,
}) => {
  const isError = Boolean(ip) && !validateIp(ip) && focusId !== id;

  return (
    <div className={b('row')}>
      <div className={b('row-content')}>
        <Input
          name={id}
          value={ip}
          onChange={({ target: { value } }) => onChangeIp(id, value)}
          onFocus={() => setFocusId(id)}
          onBlur={() => setFocusId('')}
          className={b('input', { error: isError }).toString()}
        />
        <button
          type="button"
          onClick={() => onRemoveIp(id)}
          className={b('remove-btn').toString()}
        >
          <Icon name="close" />
        </button>
      </div>
      {isError && (
      <div className={b('error')}>
        <Icon name="warning sign" className={b('error-icon').toString()} />
        <p className={b('error-text')}>Проверьте IP-адрес</p>
      </div>
      )}
    </div>
  );
};

IPeditorRow.propTypes = propTypes;
export default memo(IPeditorRow);
