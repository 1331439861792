const formatDate = (n, text) => `${n} ${text}`;

const declOfNum = (n, titles) => {
  const {
    few,
    one,
    two,
  } = titles;
  if (n % 10 === 1 && n % 100 !== 11) return formatDate(n, one);
  if (n % 10 >= 2 && n % 10 <= 4 && (n % 100 < 10 || n % 100 >= 20)) return formatDate(n, two);

  return formatDate(n, few);
};

const dateFormatter = (n, unit) => {
  const unitType = {
    day: 'день',
    year: 'год',
    month: 'месяц',
  };

  switch (unit) {
    case unitType.year:
      return declOfNum(n, {
        few: 'лет',
        one: 'год',
        two: 'года',
      });
    case unitType.day:
      return declOfNum(n, {
        few: 'дней',
        one: 'день',
        two: 'дня',
      });
    case unitType.month:
      return declOfNum(n, {
        few: 'месяцев',
        one: 'месяц',
        two: 'месяца',
      });
    default:
      return undefined;
  }
};

export {
  declOfNum,
  dateFormatter,
};
