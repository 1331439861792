import React from 'react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import { connect } from 'react-redux';
import {
  selectOption,
  getUsersForAdminTable,
  searchUsersAdminTable,
  setInitialValuesUserAdministration,
  userToggleCheckbox,
  clearCheckedList,
  lockUser,
  unlockUser,
  checkAllUsers,
  clearEditorAdminUsersForm,
  getExtraSettings,
} from 'redux/rootActions';
import { Icon } from 'semantic-ui-react';
import { UsersAdminForm, AdministrativeSectionInterface, PaginationPanel } from 'components';
import { withCustomRouter } from 'HOC';
import { LANG_DICTIONARY } from 'consts';
import './styles.scss';

const propTypes = {
  active: PropTypes.number,
  location: PropTypes.object,
  page: PropTypes.number,
  setOption: PropTypes.func,
  loadingTableInfo: PropTypes.bool,
  history: PropTypes.object,
  tableInfo: PropTypes.arrayOf(PropTypes.object),
  setInitialValues: PropTypes.func,
  loginOrFio: PropTypes.string,
  branchName: PropTypes.string,
  getUsersForTable: PropTypes.func,
  clearCheckboxes: PropTypes.func,
  checkAllChecBoxes: PropTypes.func,
  ToggleCheckbox: PropTypes.func,
  checkedUsers: PropTypes.object,
  searchUsers: PropTypes.func,
  selectAllUsers: PropTypes.bool,
  lockCheckedUser: PropTypes.func,
  unlockCheckedUser: PropTypes.func,
  clearForm: PropTypes.func,
  openExtraSettings: PropTypes.func,
  ExtraSettingsOpen: PropTypes.bool,
  totalPages: PropTypes.number,
};

const defaultProps = {
  totalPages: 1,
  ExtraSettingsOpen: false,
  openExtraSettings: () => null,
  active: 0,
  location: {},
  page: 0,
  setOption: () => null,
  getUsersForTable: () => null,
  loginOrFio: '',
  loadingTableInfo: false,
  history: {},
  tableInfo: [],
  setInitialValues: () => null,
  branchName: '',
  clearCheckboxes: () => null,
  checkAllChecBoxes: () => null,
  ToggleCheckbox: () => null,
  checkedUsers: {},
  searchUsers: () => null,
  selectAllUsers: false,
  lockCheckedUser: () => null,
  unlockCheckedUser: () => null,
  clearForm: () => null,
};

const {
  LOCK,
  UNLOCK,
  STATUSUSER,
  STATUSSITE,
  PARTNERS_PREFIX,
  STATUS_ACTIVE_PREFIX,
  IS_EMPLOYEE,
} = LANG_DICTIONARY;

const b = block('buttons-and-paginations');

class UserAdministration extends React.Component {
  selectPrefix = 'users';

  componentDidMount() {
    const {
      location: { state },
      page,
      setOption,
      getUsersForTable,
      clearForm,
    } = this.props;
    const isCache = state && state.cache;
    const currentPage = isCache ? page : 1;
    const params = this.getQueryParams();
    const { setInitialValues } = this.props;
    if (!isCache) {
      setOption(PARTNERS_PREFIX, null, this.selectPrefix);
      setOption(STATUS_ACTIVE_PREFIX, null, this.selectPrefix);
      setOption(IS_EMPLOYEE, null, this.selectPrefix);
      setOption(STATUSSITE, null, this.selectPrefix);
      setInitialValues();
      clearForm();
      getUsersForTable(currentPage);
    } else {
      getUsersForTable(currentPage, params);
    }
  }

  getQueryParams = () => {
    const { loginOrFio, branchName } = this.props;
    const {
      [`${this.selectPrefix}statusActiveSelect`]: statusUser,
      [`${this.selectPrefix}partnersSelect`]: partnerId,
      [`${this.selectPrefix}statusSiteSelect`]: statusSite,
      [`${this.selectPrefix}isEmployeeSelect`]: isEmployee,
    } = this.props;
    const checkEmptyProp = (prop) => (prop !== null && prop !== '' ? prop : undefined);
    const checkStatus = (status) => (status === undefined || status === null ? status : !status);

    return {
      branchName: checkEmptyProp(branchName),
      statusUser: checkStatus(statusUser),
      loginOrFio: checkEmptyProp(loginOrFio),
      partnerId: checkEmptyProp(partnerId),
      statusSite: checkEmptyProp(statusSite),
      isEmployee: checkEmptyProp(isEmployee),
    };
  }

  handleDownPage = () => {
    const {
      page,
      getUsersForTable,
      loadingTableInfo,
      clearCheckboxes,
      checkAllChecBoxes,
    } = this.props;
    if (page > 1 && !loadingTableInfo) {
      getUsersForTable(page - 1, this.getQueryParams());
    }
    checkAllChecBoxes(false);
    clearCheckboxes();
  }

  handleUpPage = () => {
    const {
      page,
      getUsersForTable,
      clearCheckboxes,
      checkAllChecBoxes,
    } = this.props;
    checkAllChecBoxes(false);
    clearCheckboxes();
    getUsersForTable(page + 1, this.getQueryParams());
  }

  handleSearchOnInput = ({ target: { value } }) => {
    const {
      searchUsers, page, clearCheckboxes, checkAllChecBoxes,
    } = this.props;
    searchUsers({ ...this.getQueryParams(), loginOrFio: value || undefined }, page);
    checkAllChecBoxes(false);
    clearCheckboxes();
  }

  handleSearchOnInputBranch = ({ target: { value } }) => {
    const {
      searchUsers, page, clearCheckboxes, checkAllChecBoxes,
    } = this.props;
    if (value.length === 0) {
      searchUsers({ ...this.getQueryParams(), branchName: undefined }, page);
    } else if (value === null) {
      searchUsers({ ...this.getQueryParams() }, page);
    } else {
      searchUsers({ ...this.getQueryParams(), branchName: value }, page);
    }
    checkAllChecBoxes(false);
    clearCheckboxes();
  }

  handleSearchOnSelect = (e, { value, name }) => {
    const {
      getUsersForTable,
      page,
      clearCheckboxes,
      checkAllChecBoxes,
    } = this.props;
    const params = this.getQueryParams();
    if (value === null) {
      getUsersForTable(1, { ...params, [name]: undefined }, page);
    } else if (name === STATUSUSER) {
      getUsersForTable(1, {
        ...params,
        [name]: !value,
      });
    } else if (name === STATUSSITE) {
      getUsersForTable(1, {
        ...params,
        [name]: value,
      });
    } else if (name === IS_EMPLOYEE) {
      getUsersForTable(1, {
        ...params,
        [name]: value,
      });
    } else {
      getUsersForTable(1, {
        ...params,
        [name]: value,
      });
    }
    checkAllChecBoxes(false);
    clearCheckboxes();
  }

  userCheckCheckbox = (id) => {
    const {
      checkedUsers,
      ToggleCheckbox,
    } = this.props;
    const listOfUsers = checkedUsers;
    if (listOfUsers[id] === undefined || listOfUsers[id] === null) {
      listOfUsers[id] = true;
    } else {
      listOfUsers[id] = !listOfUsers[id];
    }
    ToggleCheckbox(listOfUsers);
  }

  selectAllCheckBox = () => {
    const {
      checkedUsers,
      ToggleCheckbox,
      selectAllUsers,
      tableInfo,
      checkAllChecBoxes,
    } = this.props;
    const listOfUsers = checkedUsers;
    if (!selectAllUsers) {
      Object.keys(tableInfo).forEach((user) => {
        listOfUsers[tableInfo[user].userId] = true;
      });
      checkAllChecBoxes(true);
    } else {
      Object.keys(tableInfo).forEach((user) => {
        listOfUsers[tableInfo[user].userId] = false;
      });
      checkAllChecBoxes(false);
    }
    ToggleCheckbox(listOfUsers);
  };

  handleLockUser = () => {
    const {
      checkedUsers, lockCheckedUser, page,
    } = this.props;
    const usersList = [];
    Object.keys(checkedUsers).forEach((key) => {
      if (checkedUsers[key]) {
        usersList.push(+key);
      }
    });
    if (usersList.length !== 0) {
      lockCheckedUser(usersList, page, this.getQueryParams());
    }
  }

  handleUnlockUser = () => {
    const {
      checkedUsers, unlockCheckedUser, page,
    } = this.props;
    const usersList = [];
    Object.keys(checkedUsers).forEach((key) => {
      if (checkedUsers[key]) {
        usersList.push(+key);
      }
    });
    if (usersList.length !== 0) {
      unlockCheckedUser(usersList, page, this.getQueryParams());
    }
  }

  render() {
    const {
      active,
      tableInfo,
      loadingTableInfo,
      page,
      history,
      loginOrFio,
      branchName,
      checkedUsers,
      selectAllUsers,
      openExtraSettings,
      ExtraSettingsOpen,
      totalPages,
    } = this.props;

    return (
      <AdministrativeSectionInterface active={active}>
        <UsersAdminForm
          tableInfo={tableInfo}
          loadingTableInfo={loadingTableInfo}
          handleSearchOnInput={this.handleSearchOnInput}
          handleSearchOnInputBranch={this.handleSearchOnInputBranch}
          handleSearchOnSelect={this.handleSearchOnSelect}
          searchValue={loginOrFio}
          branchName={branchName}
          history={history}
          checkedUsers={checkedUsers}
          userCheckCheckbox={this.userCheckCheckbox}
          selectAllCheckBox={this.selectAllCheckBox}
          selectAllUsers={selectAllUsers}
          openExtraSettings={openExtraSettings}
          ExtraSettingsOpen={ExtraSettingsOpen}
        />
        <div className={b()}>
          <div className={b('control-buttons')}>
            <button
              className={b('control-button')}
              onClick={this.handleLockUser}
              type="button"
            >
              <Icon name="close" />
              <p>{LOCK}</p>
            </button>
            <button
              className={b('control-button')}
              onClick={this.handleUnlockUser}
              type="button"
            >
              <Icon name="check circle" />
              <p>{UNLOCK}</p>
            </button>
          </div>
          <PaginationPanel
            active={page}
            total={totalPages}
            handleDownPage={this.handleDownPage}
            handleUpPage={this.handleUpPage}
          />
        </div>
      </AdministrativeSectionInterface>
    );
  }
}
const mapStateToProps = ({
  usersAdminInterface,
  selects,
}) => ({
  ...usersAdminInterface,
  ...selects,
});

const mapDispatchToProps = {
  setOption: selectOption,
  getUsersForTable: getUsersForAdminTable,
  searchUsers: searchUsersAdminTable,
  setInitialValues: setInitialValuesUserAdministration,
  ToggleCheckbox: userToggleCheckbox,
  clearCheckboxes: clearCheckedList,
  lockCheckedUser: lockUser,
  unlockCheckedUser: unlockUser,
  checkAllChecBoxes: checkAllUsers,
  clearForm: clearEditorAdminUsersForm,
  openExtraSettings: getExtraSettings,
};

UserAdministration.propTypes = propTypes;
UserAdministration.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(UserAdministration));
