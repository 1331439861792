import React from 'react';
import { useScript } from 'hooks/useScript';
import PropTypes from 'prop-types';
import { TRADING_VIEW_URLS } from '../../tradingViewConfig';
import { technicalAnalysisConfig } from './config';

const propTypes = {
  tvCode: PropTypes.string.isRequired,
  code: PropTypes.string.isRequired,
};

const { technicalAnalysisUrl, name } = TRADING_VIEW_URLS;

const widgetId = 'tradingview_technical_analysis_root_id';

const TechnicalAnalysisWidget = ({ tvCode, code }) => {
  useScript(
    technicalAnalysisUrl,
    name,
    {
      ...technicalAnalysisConfig,
      symbol: `${tvCode}:${code}`,
    },
    widgetId,
  );

  return (
    <div className="tradingview-widget-container">
      <div id={widgetId} />
    </div>
  );
};

TechnicalAnalysisWidget.propTypes = propTypes;

export default TechnicalAnalysisWidget;
