const male = 1;
const female = 2;
const noMatches = '';

const searchMatches = (template, base) => {
  const value = template.toLowerCase();
  const isMale = base.male.some((name) => name === value);

  if (isMale) {
    return male;
  }

  const isFemale = base.female.some((name) => name === value);

  return isFemale ? female : noMatches;
};

const searchMatchesEndings = (value, base) => {
  const isMale = base.male.some((endings) => (
    value.indexOf(endings) + endings.length === value.length));
  if (isMale) {
    return male;
  }
  const isFemale = base.female.some((endings) => (
    value.indexOf(endings) + endings.length === value.length));

  return isFemale ? female : noMatches;
};

export default ({ firstName, lastName, midName }) => import('consts/nameBase').then((nameBase) => {
  if (firstName) {
    const search = searchMatches(firstName, nameBase.names);
    if (search) return search;
  }

  if (lastName) {
    const search = searchMatchesEndings(lastName.toLowerCase(), nameBase.lastNames);
    if (search) return search;
  }

  if (midName) {
    return searchMatchesEndings(midName.toLowerCase(), nameBase.midNames);
  }

  return noMatches;
});
