import React from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import {
  CustomCheckBox,
  MainBtn,
} from 'components';
import { block } from 'bem-cn';
import './styles.scss';
import { getPartnerIdFromSelector } from 'redux/rootSelectors';
import { connect } from 'react-redux';

const bem = block('accept-agreement-partner-channel');

const defaultProps = {
  handleSubmit: () => null,
  changeFormPartnerChannel: () => null,
  partnerChannelForm: {},
  partnerRules: {},
};

const propTypes = {
  handleSubmit: PropTypes.func,
  changeFormPartnerChannel: PropTypes.func,
  partnerChannelForm: PropTypes.object,
  partnerRules: PropTypes.object,
};

const { OK_WORD } = LANG_DICTIONARY;

const AgreementPartnerChannel = ({
  handleSubmit,
  changeFormPartnerChannel,
  partnerChannelForm,
  partnerRules,
}) => {
  const handleChangeCheckBox = (name, checked) => {
    changeFormPartnerChannel(name, !checked);
  };

  const isDisabledButton = !(Object.values(partnerChannelForm).every(Boolean));

  return (
    <div className={bem()}>
      <div className={bem('container')}>
        <div className={bem('form')}>
          {
            partnerRules.map((rule, index) => {
              const clearRuleText = rule.text.replace(rule.textLink, '');

              return (
                <div className={bem('row')}>
                  <CustomCheckBox
                    name={`rule${index}`}
                    checked={partnerChannelForm[`rule${index}`]}
                    onChange={handleChangeCheckBox}
                  />
                  <div className={bem('text')}>
                    <div>
                      {clearRuleText}
                      <a
                        className={bem('link')}
                        href={rule.link}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {rule.textLink}
                      </a>
                    </div>
                  </div>
                </div>
              );
            })
          }
          <div className={bem('footer')}>
            <MainBtn
              text={OK_WORD}
              onClick={handleSubmit}
              disabled={isDisabledButton}
              className={bem('accept-btn')}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => ({
  selectedPartnerId: getPartnerIdFromSelector(state),
});

AgreementPartnerChannel.propTypes = propTypes;
AgreementPartnerChannel.defaultProps = defaultProps;
export default connect(mapStateToProps, {})(AgreementPartnerChannel);
