import React, { useEffect, useRef, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import SaveIcon from 'assets/images/save-icon.png';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('docs-transfer-message');

const propTypes = {
  messageId: PropTypes.number,
  messageText: PropTypes.string,
  isFilesInMessage: PropTypes.bool,
  saveFiles: PropTypes.func,
  openedMessageId: PropTypes.number,
  setOpenedMessageId: PropTypes.func,
};

const defaultProps = {
  messageId: null,
  messageText: '',
  isFilesInMessage: false,
  saveFiles: () => {},
  openedMessageId: null,
  setOpenedMessageId: () => {},
};

const DocsTransferMessage = ({
  messageId,
  messageText,
  isFilesInMessage,
  saveFiles,
  openedMessageId,
  setOpenedMessageId,
}) => {
  const messageTextRef = useRef();
  const [isShowFullText, setShowFullText] = useState(false);
  const [isTextOverflow, setTextOverflow] = useState(false);

  useEffect(() => {
    const element = messageTextRef.current;
    const isTextOnElementOverflow = element?.offsetHeight < element?.scrollHeight;

    setTextOverflow(isTextOnElementOverflow);
  }, [messageTextRef.current]);

  useEffect(() => {
    if (openedMessageId !== messageId) {
      setShowFullText(false);
    }
  }, [openedMessageId]);

  const handleShowText = () => {
    setShowFullText(true);
    setOpenedMessageId(messageId);
  };

  const handleHideText = () => {
    setShowFullText(false);
  };

  const handleOnFileIconClick = () => {
    saveFiles(messageId);
  };

  return (
    <div className={b('message-wrapper')}>
      {isShowFullText && (
        <p className={b('message-text-full')}>
          {messageText}
          <Icon className={b('message-close').toString()} name="close" onClick={handleHideText} />
        </p>
      )}
      <p className={b('message-text')} ref={messageTextRef}>
        {messageText}
      </p>
      {isFilesInMessage && (
        <div
          className={b('save-button')}
          onClick={handleOnFileIconClick}
          tabIndex={-1}
          role="button"
        >
          <img
            className={b('save-icon')}
            src={SaveIcon}
            alt="save-icon"
          />
        </div>
      )}
      {isTextOverflow && (
        <div
          className={b('message-dots-wrapper')}
          onClick={handleShowText}
          role="button"
          tabIndex={-1}
        >
          <div
            className={b('message-dots')}
          />
        </div>
      )}
    </div>
  );
};

DocsTransferMessage.propTypes = propTypes;
DocsTransferMessage.defaultProps = defaultProps;
export default DocsTransferMessage;
