import React from 'react';
import { block } from 'bem-cn';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import PropTypes from 'prop-types';
import { FlexTable, CheckCircleOrCloseIcon } from 'components';
import { getFormattedDate } from 'helpers';
import EditIcon from 'assets/images/edit-icon.png';
import { withCustomRouter } from 'HOC';
import template from './template';
import './styles.scss';

const {
  NAMING_IN_DP,
  SHORT_NAMING,
  CODE_NAMING,
  TYPE,
  DATE_CONTRACT,
  CONTRACT_NUMBER,
  SHOW_ON_SITE_SHOR,
  ACTIVED,
} = LANG_DICTIONARY;

const b = block('partners-admin-table');

const propTypes = {
  data: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
};

const defaultProps = {
  data: [],
  history: {},
};

const headers = [
  '',
  NAMING_IN_DP,
  SHORT_NAMING,
  CODE_NAMING,
  TYPE,
  DATE_CONTRACT,
  CONTRACT_NUMBER,
  SHOW_ON_SITE_SHOR,
  ACTIVED,
];

const FlexTableAdminPartners = ({ data, history }) => {
  const getEditIcon = (id) => (
    <div
      onClick={() => history.push(`${ROUTES.updatePartner}/${id}`)}
      tabIndex="-1"
      role="button"
      className={b('control-button')}
    >
      <img
        src={EditIcon}
        className={b('edit-icon')}
        alt="edit-icon"
      />
    </div>
  );

  const rebuildData = data
    .map(({
      nameCp,
      nameOrgShort,
      nameCode,
      type,
      dogDate,
      dogNum,
      websiteListed,
      isActive,
      id,
    }) => template(headers, [
      getEditIcon(id),
      nameCp,
      nameOrgShort,
      nameCode,
      type,
      dogDate && getFormattedDate(dogDate),
      dogNum,
      <CheckCircleOrCloseIcon status={websiteListed} />,
      <CheckCircleOrCloseIcon status={isActive} />,
      `${id}partner`,
    ]));

  return (
    <div className={b()}>
      <FlexTable data={rebuildData} minHeight={45} />
    </div>
  );
};

FlexTableAdminPartners.propTypes = propTypes;
FlexTableAdminPartners.defaultProps = defaultProps;
export default withCustomRouter(FlexTableAdminPartners);
