import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { ContestsForm } from 'components';
import { connect } from 'react-redux';
import { contestActions } from 'redux/rootActions';
import { contestSelectors } from 'redux/rootSelectors';
import { getActionRoles } from 'redux/reducers/selectors';

const propTypes = {
  getContestsData: PropTypes.func.isRequired,
  setInitState: PropTypes.func.isRequired,
};

const Contests = ({
  getContestsData,
  setInitState,
  ...rest
}) => {
  useEffect(() => {
    getContestsData();

    return setInitState;
  }, []);

  return (
    <ContestsForm {...rest} />
  );
};

const mapStateToProps = (state) => ({
  images: contestSelectors.getImages(state),
  clusters: contestSelectors.getClusters(state),
  contests: contestSelectors.getContestsInfo(state),
  roles: getActionRoles(state),
});

Contests.propTypes = propTypes;
export default connect(mapStateToProps, contestActions)(Contests);
