import React from 'react';
import { toastr } from 'react-redux-toastr';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { RegularAndReadyBasketsForm } from 'components';
import * as actionsBasketConstructor from 'redux/basketConstructor/actions';
import { getCountRow } from 'redux/basketConstructor/reducers';
import { withCustomRouter } from 'HOC';
import {
  DEFAULT_VALUE_GUARANTEE,
} from 'config';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import {
  selectAvailableQuantityRequests,
  selectConstructorTableControlData,
  selectIsExecutionLoading,
  selectLoadingReadyAndRegularBasket,
  selectMinInsuranceSumTables,
  selectOptionType,
  selectReadyBaskets,
  selectRegularBaskets,
  selectSettingsProduct,
  selectWarningMessage,
  selectRegularBasketSorting,
  selectReadyBasketSorting,
  selectReadyBasketFiltering,
  selectRegularBasketFiltering,
} from 'redux/basketConstructor/selectors';
import { getIsEditBasket } from 'redux/reducers/selectors';
import { getQualificationFormSelector } from 'redux/rootSelectors';

const { WARNING_BASKET_NEGATE_COUPON, INCORRECT_PRODUCT_SETTINGS } = LANG_DICTIONARY;

const propTypes = {
  tableInfoOne: PropTypes.arrayOf(PropTypes.object),
  tableInfoTwo: PropTypes.arrayOf(PropTypes.object),
  history: PropTypes.object,
  numberOfQueryes: PropTypes.number,
  getTableData: PropTypes.func,
  getRequestCount: PropTypes.func,
  match: PropTypes.object,
  getGroups: PropTypes.func,
  setInitStateConstructor: PropTypes.func,
  changeStateGuarantee: PropTypes.func,
  setDescription: PropTypes.func,
  tableControl: PropTypes.object.isRequired,
  readyBasketsSorting: PropTypes.object.isRequired,
  readyBasketsFiltering: PropTypes.object.isRequired,
  regularBasketsSorting: PropTypes.object.isRequired,
  regularBasketsFiltering: PropTypes.object.isRequired,
  getProductSettings: PropTypes.func.isRequired,
  getReadyAndRegularBaskets: PropTypes.func.isRequired,
  selectReadyBasket: PropTypes.func.isRequired,
  readyBaskets: PropTypes.object.isRequired,
  regularBaskets: PropTypes.object.isRequired,
  selectChildReadyBasket: PropTypes.func.isRequired,
  selectChildRegularBasket: PropTypes.func.isRequired,
  selectRegularBasket: PropTypes.func.isRequired,
  getRegularBaskets: PropTypes.func.isRequired,
  isLoadingReadyAndRegularBasket: PropTypes.bool.isRequired,
  settings: PropTypes.object.isRequired,
  availableQuantityRequests: PropTypes.number,
  minInsuranceSum: PropTypes.number,
  getReadyBaskets: PropTypes.func.isRequired,
  setMinInsuranceSumTables: PropTypes.func.isRequired,
  toggleOpenReadyChildBasket: PropTypes.func.isRequired,
  getMinInsuranceSumTables: PropTypes.func.isRequired,
  toggleOpenRegularChildBasket: PropTypes.func.isRequired,
  executionCalculatingBasket: PropTypes.func.isRequired,
  setWarningMessage: PropTypes.func.isRequired,
  warningMessage: PropTypes.string.isRequired,
  isExecutionLoading: PropTypes.bool,
  options: PropTypes.arrayOf(PropTypes.object),
  isEditBasket: PropTypes.bool,
  qualification: PropTypes.number,
  clearSortAndFiltering: PropTypes.func,
  clearRegularBasket: PropTypes.func.isRequired,
  clearReadyBasket: PropTypes.func.isRequired,
};

const defaultProps = {
  match: {},
  getRequestCount: () => null,
  history: {},
  getTableData: () => null,
  tableInfoOne: [],
  tableInfoTwo: [],
  numberOfQueryes: 0,
  getGroups: () => null,
  setInitStateConstructor: () => null,
  changeStateGuarantee: () => null,
  setDescription: () => null,
  isExecutionLoading: false,
  availableQuantityRequests: null,
  minInsuranceSum: null,
  options: [],
  isEditBasket: false,
  qualification: 0,
  clearSortAndFiltering: () => null,
};

class RegularAndReadyBaskets extends React.Component {
  state = {
    isGuaranteeLevelWarning: false,
  }

  checkHideRegularBaskets() {
    const { match: { params: { id } }, settings, history } = this.props;

    if (settings.isHideRegularBaskets && settings.hideConstrFull) {
      toastr.error('', INCORRECT_PRODUCT_SETTINGS);
      history.push(ROUTES.home);

      return true;
    }

    if (settings.isHideRegularBaskets) {
      history.push(ROUTES.constructorIszPreparedBaskets({ id }));

      return true;
    }

    return false;
  }

  componentDidMount() {
    const {
      match: { params: { id } },
      getRequestCount,
      getProductSettings,
      tableControl: {
        currencyCode,
      },
    } = this.props;

    const isRedirect = this.checkHideRegularBaskets();

    if (!isRedirect) {
      getRequestCount();
      getProductSettings(id, currencyCode);
    }
  }

  componentDidUpdate() {
    this.checkHideRegularBaskets();
  }

  componentWillUnmount() {
    const {
      clearReadyBasket,
      clearRegularBasket,
    } = this.props;

    clearReadyBasket();
    clearRegularBasket();

    if (this.readyAbortController) {
      this.readyAbortController.abort();
    }

    if (this.regularAbortController) {
      this.regularAbortController.abort();
    }
  }

  changeGuaranteeLevelState = ({ target }) => {
    const { changeStateGuarantee } = this.props;
    changeStateGuarantee(target);
  }

  getSortingAndFilteringForRegular = () => {
    const {
      regularBasketsFiltering: { regularBasketsFilter },
      regularBasketsSorting,
    } = this.props;
    const regularFilters = Object.entries(regularBasketsFilter)
      .reduce((acc, [name, value]) => {
        if (value) {
          return [
            ...acc,
            {
              name,
              value,
              isExact: false,
            }];
        }

        return acc;
      }, []);

    const regularSort = Object.values(regularBasketsSorting)
      .sort((a, b) => a.timestamp - b.timestamp)
      .reduce((acc, payload) => {
        if (!payload.type) {
          return acc;
        }

        return [
          ...acc,
          {
            name: payload.name,
            type: payload.type,
          }];
      }, []);

    return {
      regularSort,
      regularFilters,
    };
  }

  getSortingAndFilteringForReady = () => {
    const {
      readyBasketsFiltering: { readyBasketsFilter },
      readyBasketsSorting,
    } = this.props;

    const readyFilters = Object.entries(readyBasketsFilter)
      .reduce((acc, [name, value]) => {
        if (value) {
          return [
            ...acc,
            {
              name,
              value,
              isExact: false,
            }];
        }

        return acc;
      }, []);

    const readySort = Object.values(readyBasketsSorting)
      .sort((a, b) => a.timestamp - b.timestamp)
      .reduce((acc, payload) => {
        if (!payload.type) {
          return acc;
        }

        return [
          ...acc,
          {
            name: payload.name,
            type: payload.type,
          }];
      }, []);

    return {
      readySort,
      readyFilters,
    };
  }

  handleUpdateTables = () => {
    if (this.readyAbortController) {
      this.readyAbortController.abort();
    }

    if (this.readyAbortController) {
      this.regularAbortController.abort();
    }

    const {
      match: { params: { id } },
      getReadyAndRegularBaskets,
      tableControl: {
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
      },
      clearSortAndFiltering,
    } = this.props;
    clearSortAndFiltering();

    // На следующей итерации event loop после отмены предыдущего запроса
    setTimeout(() => {
      this.readyAbortController = new AbortController();
      this.regularAbortController = new AbortController();

      getReadyAndRegularBaskets({
        partnersInsProductId: id,
        refCurrencyCode: currencyCode,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode: generalOptionCode,
        refProductTypeCode: productTypeCode,
        ...this.getSortingAndFilteringForRegular(),
        ...this.getSortingAndFilteringForReady(),
        readyAbortSignal: this.readyAbortController.signal,
        regularAbortSignal: this.regularAbortController.signal,
      });
    });

    this.resetMinInsuranceSum();
  }

  getFiltersForBascet = (basketType) => {
    const filters = Object.entries(basketType)
      .reduce((acc, [name, value]) => {
        if (value) {
          return [
            ...acc,
            {
              name,
              value,
              isExact: false,
            }];
        }

        return acc;
      }, []);

    return filters;
  }

    getSortingForBasket = (basketType) => {
      const sorts = Object.values(basketType)
        .sort((a, b) => a.timestamp - b.timestamp)
        .reduce((acc, payload) => {
          if (!payload.type) {
            return acc;
          }

          return [
            ...acc,
            {
              name: payload.name,
              type: payload.type,
            }];
        }, []);

      return sorts;
    }

  getSortingAndFilteringForRegular = () => {
    const {
      regularBasketsFiltering: { regularBasketsFilter },
      regularBasketsSorting,
    } = this.props;

    const regularFilters = this.getFiltersForBascet(regularBasketsFilter);
    const regularSort = this.getSortingForBasket(regularBasketsSorting);

    return {
      regularSort,
      regularFilters,
    };
  }

  getSortingAndFilteringForReady = () => {
    const {
      readyBasketsFiltering: { readyBasketsFilter },
      readyBasketsSorting,
    } = this.props;

    const readyFilters = this.getFiltersForBascet(readyBasketsFilter);
    const readySort = this.getSortingForBasket(readyBasketsSorting);

    return {
      readySort,
      readyFilters,
    };
  }

  selectReadyBasket = (basket) => {
    const {
      selectReadyBasket,
      readyBaskets,
      selectChildReadyBasket,
    } = this.props;

    const oldSelectedBasketId = readyBaskets.selected && readyBaskets.selected.id;
    const newSelectedBasket = oldSelectedBasketId === basket.id ?
      null :
      basket;

    selectReadyBasket(newSelectedBasket);

    /* Выделение или его снятие с дочерних с корзины, на которую произошел клик мышкой  */
    basket.child.forEach((item) => {
      const newSelectedChildBasket = newSelectedBasket ?
        item :
        null;

      selectChildReadyBasket(item.id, newSelectedChildBasket);
    });

    /* Снятие выделения с дочерних с предыдущей выделенной готовой к оформлению корзины */
    if (oldSelectedBasketId) {
      readyBaskets.selected.child.forEach((item) => {
        selectChildReadyBasket(item.id, null);
      });
    }
  }

  handleSelectReadyBasket = (basket) => {
    const { regularBaskets } = this.props;

    if (regularBaskets.selected) {
      this.selectRegularBasket(regularBaskets.selected);
    }

    this.selectReadyBasket(basket);
    this.resetMinInsuranceSum();
  }

  handleSelectRegularBasket = (basket) => {
    const { readyBaskets } = this.props;

    if (readyBaskets.selected) {
      this.selectReadyBasket(readyBaskets.selected);
    }

    this.selectRegularBasket(basket);
    this.resetMinInsuranceSum();
  }

  selectRegularBasket = (basket) => {
    const {
      selectRegularBasket,
      regularBaskets,
      selectChildRegularBasket,
    } = this.props;

    const oldSelectedBasketId = regularBaskets.selected && regularBaskets.selected.id;
    const newSelectedBasket = oldSelectedBasketId === basket.id ?
      null :
      basket;

    selectRegularBasket(newSelectedBasket);

    /* Выделение или его снятие с дочерних с корзины, на которую произошел клик мышкой  */
    basket.child.forEach((item) => {
      const newSelectedChildBasket = newSelectedBasket ?
        item :
        null;

      selectChildRegularBasket(item.id, newSelectedChildBasket);
    });

    /* Снятие выделения с дочерних с предыдущей выделенной готовой к оформлению корзины */
    if (oldSelectedBasketId) {
      regularBaskets.selected.child.forEach((item) => {
        selectChildRegularBasket(item.id, null);
      });
    }
  }

  getRegularBaskets = () => {
    const {
      match: { params: { id } },
      tableControl: {
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
      },
      regularBaskets: {
        offset,
        data,
      },
      getRegularBaskets,
    } = this.props;

    if (this.regularAbortController) {
      this.regularAbortController.abort();
    }

    // На следующей итерации event loop после отмены предыдущего запроса
    setTimeout(() => {
      this.regularAbortController = new AbortController();

      getRegularBaskets({
        partnersInsProductId: id,
        refCurrencyCode: currencyCode,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode: generalOptionCode,
        refProductTypeCode: productTypeCode,
        offset,
        limit: data.length + getCountRow(2),
        abortSignal: this.regularAbortController.signal,
        ...this.getSortingAndFilteringForRegular(),
      });
    });
  }

  getReadyBaskets = () => {
    if (this.readyAbortController) {
      this.readyAbortController.abort();
    }

    const {
      match: { params: { id } },
      tableControl: {
        guaranteeLevel,
        currencyCode,
        generalOptionCode,
        productTypeCode,
      },
      readyBaskets: {
        offset,
        data,
      },
      getReadyBaskets,
    } = this.props;

    // На следующей итерации event loop после отмены предыдущего запроса
    setTimeout(() => {
      this.readyAbortController = new AbortController();

      getReadyBaskets({
        partnersInsProductId: id,
        refCurrencyCode: currencyCode,
        warrantyLevel: guaranteeLevel,
        refIszOptionTypeCode: generalOptionCode,
        refProductTypeCode: productTypeCode,
        offset,
        limit: data.length + getCountRow(2),
        abortSignal: this.readyAbortController.signal,
        ...this.getSortingAndFilteringForReady(),
      });
    });
  }

  handleClickGetMinInsuranceSum = () => {
    const {
      regularBaskets,
      readyBaskets,
      getMinInsuranceSumTables,
    } = this.props;
    const regularSelectedId = regularBaskets.selected && regularBaskets.selected.id;
    const readySelectedId = readyBaskets.selected && readyBaskets.selected.id;

    const {
      match: { params: { id } },
      tableControl: {
        currencyCode,
      },
      qualification,
    } = this.props;

    getMinInsuranceSumTables({
      insLineIszCalcRequestId: regularSelectedId || readySelectedId,
      partnersInsProductId: id,
      refCurrencyCode: currencyCode,
      qualification,
    });
  }

  resetMinInsuranceSum = () => {
    const {
      setMinInsuranceSumTables,
    } = this.props;

    setMinInsuranceSumTables([]);
  }

  handleToggleOpenRegularChildBasket = (toggledMainBasket) => {
    const {
      regularBaskets: {
        openChildIds,
      },
      toggleOpenRegularChildBasket,
    } = this.props;

    const toggledMainBasketState = openChildIds[toggledMainBasket.id] ?
      null :
      toggledMainBasket;

    toggleOpenRegularChildBasket(toggledMainBasket.id, toggledMainBasketState);
  }

  handleToggleOpenReadyChildBasket = (toggledMainBasket) => {
    const {
      readyBaskets: {
        openChildIds,
      },
      toggleOpenReadyChildBasket,
    } = this.props;

    const toggledMainBasketState = openChildIds[toggledMainBasket.id] ?
      null :
      toggledMainBasket;

    toggleOpenReadyChildBasket(toggledMainBasket.id, toggledMainBasketState);
  }

  handleSelectChildReadyBasket = (child, main) => {
    this.handleSelectReadyBasket(main);
  }

  handleSelectChildRegularBasket = (child, main) => {
    this.handleSelectRegularBasket(main);
  }

  handleClickExecuteBasket = (isFinished) => {
    const {
      readyBaskets,
      regularBaskets,
      executionCalculatingBasket,
      tableControl,
      match: { params: { id: partnersInsProductId } },
      setWarningMessage,
      qualification,
    } = this.props;

    const selected = readyBaskets.selected || regularBaskets.selected;
    const isNegateCalcValue = selected.yield1 < 0 && selected.yield2 < 0;

    if (isNegateCalcValue) {
      setWarningMessage(WARNING_BASKET_NEGATE_COUPON);

      return;
    }

    if (!isFinished && tableControl.guaranteeLevel !== DEFAULT_VALUE_GUARANTEE) {
      this.setState({ isGuaranteeLevelWarning: true });

      return;
    }

    executionCalculatingBasket({
      mainInsLineIszCalcRequestId: selected.id,
      childInsLineIszCalcRequestIds: selected.child.map(({ id }) => id),
      guaranteeLevel: tableControl.guaranteeLevel,
      refIszOptionTypeCode: tableControl.generalOptionCode,
      refCurrencyCode: tableControl.currencyCode,
      refProductTypeCode: tableControl.productTypeCode,
      partnersInsProductId,
      qualification,
    });
  }

  render() {
    const {
      history,
      match: { params: { id } },
      readyBaskets,
      isLoadingReadyAndRegularBasket,
      regularBaskets,
      settings,
      availableQuantityRequests,
      minInsuranceSum,
      warningMessage,
      setWarningMessage,
      isExecutionLoading,
      options,
      isEditBasket,
    } = this.props;

    const {
      isGuaranteeLevelWarning,
    } = this.state;

    return (
      <RegularAndReadyBasketsForm
        {...this.props}
        productId={id}
        history={history}
        settings={settings}
        changeGuaranteeLevelState={this.changeGuaranteeLevelState}
        onUpdateTables={this.handleUpdateTables}
        onUpdateRegularTable={this.getRegularBaskets}
        onUpdateReadyTable={this.getReadyBaskets}
        isLoadingReadyAndRegularBasket={isLoadingReadyAndRegularBasket}
        availableQuantityRequests={availableQuantityRequests}
        onClickGetMinInsuranceSum={this.handleClickGetMinInsuranceSum}
        minInsuranceSum={minInsuranceSum}
        onClickExecute={this.handleClickExecuteBasket}
        isGuaranteeLevelWarning={isGuaranteeLevelWarning}
        closeGuaranteeLevelWarningPopup={() => this.setState({ isGuaranteeLevelWarning: false })}
        warningPopupMessage={warningMessage}
        clearWarningPopupMessage={() => setWarningMessage('')}
        isExecutionLoading={isExecutionLoading}
        readyOpenChildIds={readyBaskets.openChildIds}
        readyBaskets={readyBaskets.data}
        readySelected={readyBaskets.selected}
        readyScrollLoading={readyBaskets.isScrollLoading}
        readyChildSelectedIds={readyBaskets.childSelectedIds}
        onScrollEndReadyBaskets={this.getReadyBaskets}
        onToggleOpenReadyChildBasket={this.handleToggleOpenReadyChildBasket}
        onSelectReadyBasket={this.handleSelectReadyBasket}
        onSelectChildReadyBasket={this.handleSelectChildReadyBasket}
        regularOpenChildIds={regularBaskets.openChildIds}
        regularBaskets={regularBaskets.data}
        regularSelected={regularBaskets.selected}
        regularScrollLoading={regularBaskets.isScrollLoading}
        regularChildSelectedIds={regularBaskets.childSelectedIds}
        onSelectRegularBasket={this.handleSelectRegularBasket}
        onToggleOpenRegularChildBasket={this.handleToggleOpenRegularChildBasket}
        onScrollEndRegularBaskets={this.getRegularBaskets}
        onSelectChildRegularBasket={this.handleSelectChildRegularBasket}
        productOptions={options}
        isEditBasket={isEditBasket}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  ...state.constructorIszInterface,
  tableControl: selectConstructorTableControlData(state),
  readyBaskets: selectReadyBaskets(state),
  regularBaskets: selectRegularBaskets(state),
  isLoadingReadyAndRegularBasket: selectLoadingReadyAndRegularBasket(state),
  settings: selectSettingsProduct(state),
  availableQuantityRequests: selectAvailableQuantityRequests(state),
  minInsuranceSum: selectMinInsuranceSumTables(state),
  warningMessage: selectWarningMessage(state),
  isExecutionLoading: selectIsExecutionLoading(state),
  options: selectOptionType(state),
  isEditBasket: getIsEditBasket(state),
  qualification: getQualificationFormSelector(state),
  regularBasketsSorting: selectRegularBasketSorting(state),
  regularBasketsFiltering: selectRegularBasketFiltering(state),
  readyBasketsSorting: selectReadyBasketSorting(state),
  readyBasketsFiltering: selectReadyBasketFiltering(state),
});

RegularAndReadyBaskets.propTypes = propTypes;
RegularAndReadyBaskets.defaultProps = defaultProps;

export default withCustomRouter(connect(
  mapStateToProps,
  actionsBasketConstructor,
)(RegularAndReadyBaskets));
