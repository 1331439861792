import { put, takeLatest } from 'redux-saga/effects';
import {
  apiCall,
  getError,
  filterEmptyProps,
  apiDownload,
} from 'helpers';
import { toastr } from 'react-redux-toastr';
import { API, LANG_DICTIONARY, ROUTES } from 'consts';
import { ITEM_COUNT_ADMIN_TABLE_PARTNERS } from 'config';
import { setPageReportsAdminTable, getTagsForReport } from 'redux/rootActions';
import {
  GET_REPORTS_FOR_TABLE_FAIL,
  GET_REPORTS_FOR_TABLE_REQUEST,
  GET_REPORTS_FOR_TABLE_START,
  GET_REPORTS_FOR_TABLE_SUCCESS,
  CREATE_REPORT_FAIL,
  CREATE_REPORT_REQUEST,
  CREATE_REPORT_START,
  CREATE_REPORT_SUCCESS,
  GET_REPORT_DATA_FAIL,
  GET_REPORT_DATA_REQUEST,
  GET_REPORT_DATA_START,
  GET_REPORT_DATA_SUCCESS,
  UPDATE_REPORT_FAIL,
  UPDATE_REPORT_START,
  UPDATE_REPORT_REQUEST,
  UPDATE_REPORT_SUCCESS,
  GET_ROLES_FOR_SELECT_REQUEST,
  GET_ROLES_FOR_SELECT_START,
  GET_ROLES_FOR_SELECT_SUCCESS,
  GET_ROLES_FOR_SELECT_FAIL,
  GET_REPORT_TAGS_FAIL,
  GET_REPORT_TAGS_REQUEST,
  GET_REPORT_TAGS_START,
  GET_REPORT_TAGS_SUCCESS,
  GET_REPORT_TYPES_FOR_SELECT_REQUEST,
  GET_REPORT_TYPES_FOR_SELECT_START,
  GET_REPORT_TYPES_FOR_SELECT_SUCCESS,
  GET_REPORT_TYPES_FOR_SELECT_FAIL,
  GET_PARTNERS_FOR_SELECT_REQUEST,
  GET_PARTNERS_FOR_SELECT_START,
  GET_PARTNERS_FOR_SELECT_SUCCESS,
  GET_PARTNERS_FOR_SELECT_FAIL,
  GET_DOWNLOAD_CHECK_FAIL,
  GET_DOWNLOAD_CHECK_REQUEST,
  GET_DOWNLOAD_CHECK_SUCCESS,
  GET_DOWNLOAD_CHECK_START,
  GET_DOWNLOAD_LIST_FAIL,
  GET_DOWNLOAD_LIST_REQUEST,
  GET_DOWNLOAD_LIST_SUCCESS,
  GET_DOWNLOAD_LIST_START,
  GET_DOWNLOAD_REPORT_FAIL,
  GET_DOWNLOAD_REPORT_REQUEST,
  GET_DOWNLOAD_REPORT_SUCCESS,
  GET_DOWNLOAD_REPORT_START,
} from '../types';

const { REPORT_CREATED, REPORT_UPDATED } = LANG_DICTIONARY;

function* getDownloadCheck() {
  try {
    yield put({ type: GET_DOWNLOAD_CHECK_START });
    const { data } = yield apiCall({
      url: API.GET_CHECK_FOR_REPORT,
      type: 'GET',
    });
    yield put({ type: GET_DOWNLOAD_CHECK_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_DOWNLOAD_CHECK_FAIL, error: getError(e) });
  }
}

function* getDownloadList() {
  try {
    yield put({ type: GET_DOWNLOAD_LIST_START });
    const { data } = yield apiCall({
      url: API.GET_DOWNLOAD_LIST,
      type: 'GET',
    });
    yield put({ type: GET_DOWNLOAD_LIST_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_DOWNLOAD_LIST_FAIL, error: getError(e) });
  }
}

function* getDownloadReport({ payload }) {
  const {
    id, reportType, dateSignBeg, dateSignEnd, productIds, statusIds,
  } = payload;

  const { GET_DOWNLOAD_REPORT } = API;
  try {
    yield put({ type: GET_DOWNLOAD_REPORT_START });
    yield apiDownload({
      type: 'POST',
      url: `${GET_DOWNLOAD_REPORT}${id}`,
      body: {
        dateSignBeg,
        dateSignEnd,
        productIds,
        statusIds,
      },
    }, `${reportType}.xlsx`);
    yield put({ type: GET_DOWNLOAD_REPORT_SUCCESS });
  } catch (e) {
    yield put({ type: GET_DOWNLOAD_REPORT_FAIL, error: getError(e) });
  }
}

function* getPartners() {
  try {
    yield put({ type: GET_PARTNERS_FOR_SELECT_START });
    const { data } = yield apiCall({
      url: API.GET_PARTNERS_FOR_SELECT,
      type: 'GET',
    });
    yield put({ type: GET_PARTNERS_FOR_SELECT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_PARTNERS_FOR_SELECT_FAIL, error: getError(e) });
  }
}

function* getReportTypes() {
  try {
    yield put({ type: GET_REPORT_TYPES_FOR_SELECT_START });
    const { data } = yield apiCall({
      url: API.GET_REPORT_TYPES_FOR_SELECT,
      type: 'GET',
    });
    const newData = data.map((item) => ({
      key: item.id,
      value: item.id,
      text: item.name,
    }));
    yield put({ type: GET_REPORT_TYPES_FOR_SELECT_SUCCESS, payload: newData });
  } catch (e) {
    yield put({ type: GET_REPORT_TYPES_FOR_SELECT_FAIL, error: getError(e) });
  }
}

function* getReportTags({ payload }) {
  try {
    yield put({ type: GET_REPORT_TAGS_START });
    const { data } = yield apiCall({
      url: API.GET_REPORT_TAGS,
      type: 'GET',
      query: {
        reportTypeId: payload,
      },
    });
    const newData = data.map((item) => ({
      id: item.tag,
      content: item.name,
    }));
    yield put({ type: GET_REPORT_TAGS_SUCCESS, payload: newData });
  } catch (e) {
    yield put({ type: GET_REPORT_TAGS_FAIL, error: getError(e) });
  }
}
function* getRolesForReport() {
  try {
    yield put({ type: GET_ROLES_FOR_SELECT_START });
    const { data } = yield apiCall({
      url: API.GET_USERS_ROLES_FOR_SELECT,
      type: 'GET',
    });
    yield put({ type: GET_ROLES_FOR_SELECT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: GET_ROLES_FOR_SELECT_FAIL, error: getError(e) });
  }
}
function* getReportsForTable({ payload, query }) {
  try {
    yield put({ type: GET_REPORTS_FOR_TABLE_START });
    const { data } = yield apiCall({
      url: API.REPORTS,
      type: 'GET',
      query: {
        offset: ITEM_COUNT_ADMIN_TABLE_PARTNERS * (payload - 1),
        limit: ITEM_COUNT_ADMIN_TABLE_PARTNERS,
        ...query,
      },
    });
    yield put({ type: GET_REPORTS_FOR_TABLE_SUCCESS, payload: data });
    if (!data.data.length && payload !== 1) {
      yield put(setPageReportsAdminTable(payload - 1));
      yield getReportsForTable({ payload: payload - 1, query });
    } else {
      yield put(setPageReportsAdminTable(payload));
    }
  } catch (e) {
    yield put({ type: GET_REPORTS_FOR_TABLE_FAIL, error: getError(e) });
  }
}

function* createReport({ payload }) {
  try {
    const { body, history } = payload;
    const rebuildBody = filterEmptyProps(body);
    const newTags = rebuildBody.tags.map((item) => ({
      name: item.content,
      tag: item.id,
    }));
    rebuildBody.tags = newTags;
    yield put({ type: CREATE_REPORT_START });
    const info = yield apiCall({
      url: API.REPORTS,
      type: 'POST',
      body: rebuildBody,
    });
    yield put({ type: CREATE_REPORT_SUCCESS });
    toastr.success('', REPORT_CREATED);
    const { data } = info;
    history.pushWithoutSave(`${ROUTES.updateReport}/${data}`);
  } catch (e) {
    yield put({ type: CREATE_REPORT_FAIL, error: getError(e) });
  }
}

function* updateReport({ payload }) {
  try {
    const { body, id } = payload;
    const rebuildBody = filterEmptyProps(body);
    const newTags = rebuildBody.tags.map((item) => ({
      name: item.content,
      tag: item.id,
    }));
    rebuildBody.tags = newTags;
    yield put({ type: UPDATE_REPORT_START });
    yield apiCall({
      url: `${API.REPORTS}/id${id}`,
      type: 'PUT',
      body: rebuildBody,
    });
    yield put({ type: UPDATE_REPORT_SUCCESS });
    toastr.success('', REPORT_UPDATED);
  } catch (e) {
    yield put({ type: UPDATE_REPORT_FAIL, error: getError(e) });
  }
}

function* getReportDataForUpdate({ payload }) {
  try {
    yield put({ type: GET_REPORT_DATA_START });
    const { data } = yield apiCall({
      url: `${API.REPORTS}/id${payload}`,
      type: 'GET',
    });
    const newTags = data.tags.map((item) => ({
      id: item.tag,
      content: item.name,
    }));
    yield put({ type: GET_REPORT_DATA_SUCCESS, payload: { data, newTags } });
    yield put(getTagsForReport(data.reportTypeId));
  } catch (e) {
    yield put({ type: GET_REPORT_DATA_FAIL, error: getError(e) });
  }
}

function* reportsSaga() {
  yield takeLatest(GET_REPORT_DATA_REQUEST, getReportDataForUpdate);
  yield takeLatest(CREATE_REPORT_REQUEST, createReport);
  yield takeLatest(UPDATE_REPORT_REQUEST, updateReport);
  yield takeLatest(GET_REPORTS_FOR_TABLE_REQUEST, getReportsForTable);
  yield takeLatest(GET_ROLES_FOR_SELECT_REQUEST, getRolesForReport);
  yield takeLatest(GET_REPORT_TAGS_REQUEST, getReportTags);
  yield takeLatest(GET_PARTNERS_FOR_SELECT_REQUEST, getPartners);
  yield takeLatest(GET_REPORT_TYPES_FOR_SELECT_REQUEST, getReportTypes);
  yield takeLatest(GET_DOWNLOAD_CHECK_REQUEST, getDownloadCheck);
  yield takeLatest(GET_DOWNLOAD_LIST_REQUEST, getDownloadList);
  yield takeLatest(GET_DOWNLOAD_REPORT_REQUEST, getDownloadReport);
}

export default reportsSaga;
