import {
  GET_BRANCHES_FOR_TABLE_FAIL,
  GET_BRANCHES_FOR_TABLE_START,
  GET_BRANCHES_FOR_TABLE_SUCCESS,
  SET_PAGE_PAGINATION,
  SEARCH_BY_BRANCHES_NAME,
  GET_BRANCH_DATA_SUCCESS,
  CLEAR_FORM,
  SET_INITIAL_VALUES,
  CONTROL_BRANCHES_POPUPS,
  GET_USERS,
  GET_LIST_OF_CTS_SOURCE,
} from '../types';

const INITIAL_EDITOR_FORM = {
  branchAddress: '',
  branchName: '',
  parentBranchId: null,
  partnerId: null,
  treeBranch: '',
  isRootBranch: false,
  curatorId: 0,
  codeOKATO: null,
  isTest: false,
  massSegment: false,
  ctsSourceCode: '',
  isNeedScans: false,
};

const INITIAL_STATE = {
  branchFormData: INITIAL_EDITOR_FORM,
  loadingTableInfo: false,
  tableInfo: [],
  page: 1,
  searchValue: null,
  totalPages: 1,
  branchesPopUpOpen: false,
  users: [],
  ctsSourcesCodes: [],
};

export default (state = INITIAL_STATE, { type, payload }) => {
  switch (type) {
    case GET_LIST_OF_CTS_SOURCE.success: {
      const result = payload.map((item) => ({
        value: item.code,
        key: item.code,
        text: item.name,
      }));

      return {
        ...state,
        ctsSourcesCodes: result,
      };
    }

    case CONTROL_BRANCHES_POPUPS: {
      return {
        ...state,
        branchesPopUpOpen: payload,
      };
    }
    case GET_BRANCHES_FOR_TABLE_SUCCESS:
      return {
        ...state,
        loadingTableInfo: false,
        tableInfo: payload.data,
        page: payload.currentPage,
        totalPages: payload.totalPages,
      };
    case GET_BRANCHES_FOR_TABLE_FAIL:
      return {
        ...state,
        loadingTableInfo: false,
      };
    case GET_BRANCHES_FOR_TABLE_START:
      return {
        ...state,
        loadingTableInfo: true,
      };

    case SEARCH_BY_BRANCHES_NAME:
      return {
        ...state,
        tableInfo: state.tableInfo,
        searchValue: payload.name,
      };

    case GET_BRANCH_DATA_SUCCESS: {
      const { isParent, data } = payload;
      if (isParent) {
        return {
          ...state,
          branchFormData: {
            ...state.branchFormData,
            parentBranchId: data.id,
            partnerId: data.partnerId,
          },
        };
      }

      return {
        ...state,
        branchFormData: {
          ...state.branchFormData,
          id: data.id,
          branchAddress: data.fullAddr || '',
          branchName: data.branchName || '',
          parentBranchId: data.isRootBranch ? null : data.parentBranchId,
          partnerId: data.partnerId,
          treeBranch: data.treeBranch || '',
          isRootBranch: data.isRootBranch,
          codeOKATO: data.codeOKATO,
          bankCode: data.bankCode || '',
          curatorId: data.curatorId || 0,
          isTest: data.isTest,
          massSegment: Boolean(data.massSegment),
          ctsSourceCode: data.refCtsSourceCode,
          isNeedScans: Boolean(data.isNeedScans),
        },
      };
    }

    case CLEAR_FORM: {
      return {
        ...state,
        branchFormData: INITIAL_EDITOR_FORM,
      };
    }

    case SET_INITIAL_VALUES:
      return INITIAL_STATE;

    case SET_PAGE_PAGINATION:
      return {
        ...state,
        page: payload,
      };

    case GET_USERS.start:
      return {
        ...state,
        isLoadingUsers: true,
      };

    case GET_USERS.success:
      return {
        ...state,
        isLoadingUsers: false,
        users: payload.map((user) => ({
          value: user.id,
          text: user.login,
          key: user.login,
        })),
      };

    case GET_USERS.fail:
      return {
        ...state,
        isLoadingUsers: false,
      };

    default:
      return state;
  }
};
