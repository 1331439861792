import { PRODUCT_VERSION, TEST_DATA_ENUMS } from 'consts';
import generatorsFactory from './helpers/generatorsFactory';
import getAdditionalData from './helpers/getAdditionalData';

const getHideOptions = (
  {
    iszInsurerIsInsured,
    nszInsurerIsInsured,
    kszInsurerIsInsured,
    beneficiaryByLawIsz,
    beneficiaryByLawNsz,
    beneficiaryByLawKsz,
  },
  scenario,
) => {
  switch (scenario) {
    case PRODUCT_VERSION.normal:
    case PRODUCT_VERSION.coupon:
    case PRODUCT_VERSION.normalCurrency:
    case PRODUCT_VERSION.couponCurrency:
    case PRODUCT_VERSION.constructorIsz:
    case PRODUCT_VERSION.constructorIszDollar: {
      return {
        insurerIsInsured: iszInsurerIsInsured,
        beneficiaryByLaw: beneficiaryByLawIsz,
      };
    }

    case PRODUCT_VERSION.nsz_1:
    case PRODUCT_VERSION.nsz_2:
    case PRODUCT_VERSION.nsz_3: {
      return {
        insurerIsInsured: nszInsurerIsInsured,
        beneficiaryByLaw: beneficiaryByLawNsz,
      };
    }

    case PRODUCT_VERSION.ksz_1: {
      return {
        insurerIsInsured: kszInsurerIsInsured,
        beneficiaryByLaw: beneficiaryByLawKsz,
      };
    }

    default: {
      return {};
    }
  }
};

const useTestDataGenerate = (scenario, formType) => {
  const additionalData = getAdditionalData();
  const { iszAction, nszAction, kszAction } = generatorsFactory(scenario, formType, additionalData);
  const { insurerIsInsured, beneficiaryByLaw } = getHideOptions(additionalData, scenario);
  const isHide = ((formType === TEST_DATA_ENUMS.insured) && insurerIsInsured)
  || ((formType === TEST_DATA_ENUMS.beneficiary) && beneficiaryByLaw);

  const mapper = {
    [PRODUCT_VERSION.normal]: iszAction,
    [PRODUCT_VERSION.coupon]: iszAction,
    [PRODUCT_VERSION.normalCurrency]: iszAction,
    [PRODUCT_VERSION.couponCurrency]: iszAction,
    [PRODUCT_VERSION.constructorIsz]: iszAction,
    [PRODUCT_VERSION.constructorIszDollar]: iszAction,
    [PRODUCT_VERSION.nsz_1]: nszAction,
    [PRODUCT_VERSION.nsz_2]: nszAction,
    [PRODUCT_VERSION.nsz_3]: nszAction,
    [PRODUCT_VERSION.ksz_1]: kszAction,
  };

  return {
    action: mapper[scenario],
    isHide,
  };
};

export default useTestDataGenerate;
