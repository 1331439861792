import React, { memo, useMemo } from 'react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY } from 'consts';
import { PopupContainer, WarningMessagePopup } from 'components';
import { checkIEBrowser } from 'helpers/detectBrowser';
import {
  ReportingWidget,
  ChartWidget,
  TechnicalAnalysisWidget,
  MiniChartWidget,
} from '..';
import { TRADING_VIEW_WIDGETS } from '../../tradingViewConfig';
import '../../../styles.scss';

const propTypes = {
  assetsData: PropTypes.arrayOf(PropTypes.object).isRequired,
  activeWidget: PropTypes.string,
  setActiveWidget: PropTypes.func,
};

const defaultProps = {
  activeWidget: '',
  setActiveWidget: () => null,
};

const {
  reporting,
  chart,
  technicalAnalysis,
  miniChart,
} = TRADING_VIEW_WIDGETS;

const TradingViewWidgetsWithPopup = ({
  assetsData,
  activeWidget,
  setActiveWidget,
}) => {
  const widgetSymbolsArr = useMemo(
    () => assetsData.map(({ tvCode, code }) => `${tvCode}:${code}|12M`),
    [assetsData],
  );

  const codesProps = useMemo(
    () => ({
      tvCode: assetsData[0].tvCode,
      code: assetsData[0].code,
    }),
    [assetsData],
  );

  const isIEBrowser = checkIEBrowser();

  if (Boolean(activeWidget) && isIEBrowser) {
    return (
      <WarningMessagePopup
        onClose={() => setActiveWidget('')}
        message={LANG_DICTIONARY.BROWSE_NOT_SUPPORTED_WIDGET}
      />
    );
  }

  const renderWidget = () => {
    switch (activeWidget) {
      case reporting:
        return {
          Component: <ReportingWidget {...codesProps} />,
          popupStyles: 'trading-view-reporting-popup',
        };
      case chart:
        return {
          Component: <ChartWidget {...codesProps} />,
          popupStyles: 'trading-view-chart-popup',
        };
      case miniChart:
        return {
          Component: <MiniChartWidget widgetSymbols={widgetSymbolsArr} />,
          popupStyles: 'trading-view-minichart-popup',
        };
      case technicalAnalysis:
        return {
          Component: <TechnicalAnalysisWidget {...codesProps} />,
          popupStyles: 'trading-view-technical-popup',
        };
      default:
        return {
          Component: <div />,
        };
    }
  };

  const { Component, popupStyles } = renderWidget();

  return (
    <PopupContainer
      close={() => setActiveWidget('')}
      isOpen={Boolean(activeWidget)}
      className={popupStyles}
      isCloseButton
    >
      {Component}
    </PopupContainer>
  );
};

TradingViewWidgetsWithPopup.propTypes = propTypes;
TradingViewWidgetsWithPopup.defaultProps = defaultProps;

export default memo(TradingViewWidgetsWithPopup);
