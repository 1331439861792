import React from 'react';
import { block } from 'bem-cn';
import PropTypes from 'prop-types';
import { Icon } from 'semantic-ui-react';
import iconSettings from 'assets/images/icon-settings.png';
import './styles.scss';

const b = block('upload-button');

const propTypes = {
  onClick: PropTypes.func,
  disabled: PropTypes.bool,
  status: PropTypes.string,
};

const defaultProps = {
  onClick: null,
  disabled: false,
  status: '',
};

const UploadIcon = ({ onClick, disabled, status }) => (
  <button
    type="button"
    className={b()}
    onClick={onClick}
    disabled={disabled}
    tabIndex="-1"
  >
    {status === 'stop' && <Icon name="stop" />}
    {status === 'active' && <img src={iconSettings} alt="washer" />}
    <div className={b('icon')} />
  </button>
);

UploadIcon.propTypes = propTypes;
UploadIcon.defaultProps = defaultProps;

export default UploadIcon;
