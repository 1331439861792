import { ROUTES, TYPE_PERSON } from 'consts';

const { myClients } = ROUTES;
const { insured, insurer, beneficiary } = TYPE_PERSON;

const redirectContract = ({
  history,
  ctsId,
  backPatch,
}) => history && history.push(`${myClients}/${ctsId}`, backPatch);

const redirectInsurer = ({
  history,
  ctsId,
  backPatch,
}) => history && history.push(`${myClients}/${ctsId}/1`, backPatch);

const redirectInsured = ({
  history,
  ctsId,
  index,
  backPatch,
}) => history && history.push(`${myClients}/${ctsId}/2/${index || ''}`, backPatch);

const redirectBeneficiary = ({
  history,
  ctsId,
  index,
  backPatch,
}) => history && history.push(`${myClients}/${ctsId}/3/${index || ''}`, backPatch);

const redirectFactory = ({
  type,
  history,
  ctsId,
  index,
  backPatch,
}) => {
  switch (type) {
    case 'contract':
      return redirectContract({
        history,
        ctsId,
        backPatch,
      });

    case insured:
      return redirectInsured({
        history,
        ctsId,
        index,
        backPatch,
      });
    case insurer:
      return redirectInsurer({
        history,
        ctsId,
        backPatch,
      });
    case beneficiary:
      return redirectBeneficiary({
        history,
        ctsId,
        index,
        backPatch,
      });
    default:
      return null;
  }
};

export {
  redirectFactory,
  redirectBeneficiary,
  redirectContract,
  redirectInsurer,
  redirectInsured,
};
