import {
  SEARCH_BANK_REQUEST,
  SEARCH_BANK_RESET,
  SEARCH_BANK_VALUE,
} from '../types';

const searchBank = (bank) => ({
  type: SEARCH_BANK_REQUEST,
  payload: { bank },
});

const setBank = (bank) => ({
  type: SEARCH_BANK_VALUE,
  payload: { bank },
});

const resetSearchBank = () => ({
  type: SEARCH_BANK_RESET,
});

export {
  searchBank,
  resetSearchBank,
  setBank,
};
