import React from 'react';
import { createBrowserHistory } from 'history';
import PropTypes from 'prop-types';
import { withCustomRouter } from 'HOC';

const propTypes = {
  history: PropTypes.object.isRequired,
};

let globalHistory = createBrowserHistory();

class HistoryComponent extends React.Component {
  constructor(props) {
    super(props);
    globalHistory = props.history;
  }

  componentDidUpdate() {
    const { history } = this.props;
    globalHistory = history;
  }

  render() {
    return null;
  }
}

HistoryComponent.propTypes = propTypes;

export const GlobalHistory = withCustomRouter(HistoryComponent);
export const getGlobalHistory = () => globalHistory;
