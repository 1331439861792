import axios from 'axios';
import {
  all,
  call,
  cancel,
  fork,
} from 'redux-saga/effects';
import { toastr } from 'react-redux-toastr';
import {
  LANG_DICTIONARY,
  ROUTES,
  REQUEST_ERROR_CODE,
  UNITED_FRONTEND_HEADER,
  PRODUCT_VERSION,
  API,
  ENVIRONMENT_ENUM,
  SERVICE_HEADERS,
} from 'consts';
import { API_BASE_URL } from 'config';
import { storeLink } from 'index';
import _ from 'lodash';
import getToastrOptions from '../components/_shared/TraceIdToastr';

const getToken = () => localStorage.getItem('token');
const { UNKNOWN_ERROR, UNSTABLE_INTERNET_CONNECT } = LANG_DICTIONARY;

const fetchJSON = (type) => (url, body, options) => axios({
  method: type,
  url,
  data: body,
  ...options,
});

const directRequest = ({
  url,
  type,
  headers,
  body,
  host = API_BASE_URL,
}) => fetchJSON(type)(
  `${host}${url}`,
  body,
  {
    headers: {
      ...(headers && { headers }),
      authorization: getToken(),
      'Content-Type': 'application/json',
    },
  },
).catch((err) => {
  const code = _.get(err, 'response.status');

  const isRedirectOnSignIn = code === REQUEST_ERROR_CODE.unauthorized &&
    window.location.pathname !== ROUTES.signIn &&
    process.env.NODE_ENV !== ENVIRONMENT_ENUM.development;

  if (isRedirectOnSignIn) {
    window.location.replace(ROUTES.signIn);
  }

  return Promise.reject(err);
});

const directDownloadFile = (blob, name) => {
  if (!window.navigator.msSaveOrOpenBlob) {
    const newUrl = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = newUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  } else {
    window.navigator.msSaveOrOpenBlob(blob, name);
  }
};

const apiCall = ({
  type,
  body,
  headers,
  url,
  withoutToken,
  isBlob,
  query,
  file,
  host = API_BASE_URL,
  ...rest
}) => {
  const allHeaders = {
    'Content-Type': 'application/json',
    ...(!withoutToken && { authorization: getToken() }),
    ...(headers && headers),
    ...getUnitedFrontendHeaders(),
    ...getCreatingProductIdHeaders(),
  };
  const options = {
    method: type,
    headers: allHeaders,
    params: query,
    ...(isBlob && { responseType: 'blob' }),
    ...rest,
  };

  let URL = `${host}${url}`;
  const version = Number(new Date());
  if (type === 'GET') URL = `${host}${url}?_ver=${version}`;

  return call(fetchJSON(type), URL, body, options);
};

const getFileNameByHeaders = (name) => {
  const searchValue = 'filename="';
  const start = name.indexOf(searchValue) + searchValue.length;
  const end = name.length - 1;

  return decodeURIComponent(name.slice(start, end));
};

const downloadFile = (response, fileName) => {
  const name = fileName || getFileNameByHeaders(response.headers['content-disposition']);
  if (!window.navigator.msSaveOrOpenBlob) {
    const newUrl = window.URL.createObjectURL(new Blob([response.data]));
    const link = document.createElement('a');
    link.href = newUrl;
    link.setAttribute('download', name);
    document.body.appendChild(link);
    link.click();
  } else {
    window.navigator.msSaveOrOpenBlob(new Blob([response.data]), name);
  }
};

const apiDownload = async ({
  type,
  headers,
  url,
  query,
  body,
  signal,
}, fileName) => {
  const version = Number(new Date());
  const versionUrl = `${API_BASE_URL}${url}?_ver=${version}`;

  // types cannot be imported due to circular dependency
  const SET_PROGRESS_BAR_DOWNLOAD = 'shared/SET_PROGRESS_BAR_DOWNLOAD';
  const SET_PROGRESS_PERCENT = 'shared/SET_PROGRESS_PERCENT';
  const SET_DOWNLOAD_FILENAME = 'shared/SET_DOWNLOAD_FILENAME';
  const delayTimeout = 1500;

  setTimeout(() => {
    storeLink.dispatch({ type: SET_DOWNLOAD_FILENAME, payload: fileName || '...' });
    storeLink.dispatch({ type: SET_PROGRESS_BAR_DOWNLOAD, payload: true });
  }, 0);

  const options = {
    signal,
    method: type || 'GET',
    headers: {
      authorization: getToken(),
      ...headers,
      ...getUnitedFrontendHeaders(),
    },
    params: query,
    data: body,
    responseType: 'blob',
    url: versionUrl,
    onDownloadProgress: (progressEvent) => {
      if (progressEvent) {
        const {
          loaded,
          total,
        } = progressEvent;

        const percent = total ? Math.ceil((loaded / total) * 100) : 0;
        storeLink.dispatch({ type: SET_PROGRESS_PERCENT, payload: percent });
      }
    },
  };

  return axios(options)
    .then((response) => {
      if (!fileName) {
        const fileNameHeaders = getFileNameByHeaders(response.headers['content-disposition']);
        storeLink.dispatch({ type: SET_DOWNLOAD_FILENAME, payload: fileNameHeaders });
      }
      downloadFile(response, fileName);
    })
    .finally(() => {
      if (signal && signal.aborted) {
        storeLink.dispatch({ type: SET_PROGRESS_BAR_DOWNLOAD, payload: false });

        return;
      }

      storeLink.dispatch({ type: SET_PROGRESS_PERCENT, payload: 100 });

      setTimeout(() => {
        storeLink.dispatch({ type: SET_PROGRESS_BAR_DOWNLOAD, payload: false });
        storeLink.dispatch({ type: SET_DOWNLOAD_FILENAME, payload: '' });
        storeLink.dispatch({ type: SET_PROGRESS_PERCENT, payload: 0 });
      }, delayTimeout);
    });
};

const getErrorText = (data, mute) => {
  try {
    const { message: { RU }, traceId } = data;
    if (!mute) {
      toastr.error('', RU, getToastrOptions(traceId));
    }

    return RU || UNKNOWN_ERROR;
  } catch (error) {
    if (!mute) toastr.error('', UNKNOWN_ERROR);

    return null;
  }
};

const getIdError = (data) => {
  try {
    const { message: { RU, rowId } } = data;
    toastr.error('', RU);

    return { rowId };
  } catch (error) {
    toastr.error('', UNKNOWN_ERROR);

    return null;
  }
};

const showWarningDisconnect = _.throttle(() => {
  toastr.error('', UNSTABLE_INTERNET_CONNECT);
});

const getError = (err, errorIndex, mute, rowId) => {
  if (err.message === 'canceled') {
    return null;
  }

  const error = err.response ? err : JSON.parse(err.message);

  const status = _.get(error, 'response.status');

  if (!status) {
    showWarningDisconnect();

    return null;
  }

  const data = _.get(error, `response.${errorIndex || 'data'}`);
  if (status === REQUEST_ERROR_CODE.unauthorized && window.location.pathname !== ROUTES.signIn) {
    const { search } = document.location;
    window.location.replace(`${ROUTES.signIn}${search}`);
  }

  const isBlob = data instanceof Blob;
  if (isBlob) {
    const reader = new FileReader();
    reader.onload = () => {
      const res = JSON.parse(reader.result);

      return getErrorText(res, mute);
    };
    reader.readAsText(data);
  } else if (rowId) {
    return getIdError(data);
  } else {
    return getErrorText(data, mute);
  }

  return null;
};

const getUnitedFrontendHeaders = () => {
  const {
    shared: {
      masterId,
      windowUuid,
      windowTimestamp,
    },
  } = storeLink.getState();

  return {
    ...(masterId && {
      [UNITED_FRONTEND_HEADER.TAB_UUID]: windowUuid,
      [UNITED_FRONTEND_HEADER.MASTER_ID]: masterId,
      [UNITED_FRONTEND_HEADER.TAB_TIMESTAMP]: windowTimestamp,
    }),
  };
};

const getCreatingProductIdHeaders = () => {
  const {
    checkoutReducer: {
      creatingProductId,
    },
  } = storeLink.getState();

  return {
    ...(creatingProductId && {
      [SERVICE_HEADERS.CREATING_PRODUCT_ID]: creatingProductId,
    }),
  };
};

const getSaveDataContractUrl = (values) => {
  const { SAVE_CONTRACT, SAVE_CONTRACT_NSZ, SAVE_CONTRACT_KSZ } = API;
  const { scenario, productId } = values;

  switch (scenario) {
    case PRODUCT_VERSION.normal:
    case PRODUCT_VERSION.coupon:
    case PRODUCT_VERSION.normalCurrency:
    case PRODUCT_VERSION.couponCurrency:
    case PRODUCT_VERSION.constructorIsz:
    case PRODUCT_VERSION.constructorIszDollar:
      return SAVE_CONTRACT;

    case PRODUCT_VERSION.nsz_1:
    case PRODUCT_VERSION.nsz_2:
    case PRODUCT_VERSION.nsz_3:
      return SAVE_CONTRACT_NSZ(productId);

    case PRODUCT_VERSION.ksz_1:
      return SAVE_CONTRACT_KSZ;

    default:
      return SAVE_CONTRACT;
  }
};

function canceling(cb) {
  let storage = [];

  return function* run(...args) {
    yield all(storage.map((item) => cancel(item)));
    storage = [];

    const forkCb = yield fork(cb, ...args);
    storage.push(forkCb);
  };
}

export {
  directRequest,
  getFileNameByHeaders,
  downloadFile,
  apiCall,
  apiDownload,
  getError,
  directDownloadFile,
  getSaveDataContractUrl,
  canceling,
};
