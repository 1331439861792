import {
  object,
  array,
  number,
  string,
} from 'yup';
import { LANG_DICTIONARY } from 'consts';
import { STRATEGY_FIELD_NAME } from './fieldNames';
import {
  checkIsEuropeOption,
  validBarrierPercentValue,
  validBonusCouponValue,
  validNotionalBarrierValue,
  validGearedBarrierValue,
  validPercentValue,
  getBarrierValidationMessage,
  getBonusCouponValidationMessage,
  getNotionalValidationBarrierMessage,
  getGearedValidationBarrierMessage,
  getGrowthCaValidationMessage,
  validGrowthCapValue,
} from './helpers';

const {
  REQUIRED,
  MAXIMUM_ASSETS,
  VALUE_MUST_BE_NUMBER_DIAPASON_0_999_WITH_TWO_DECIMAL,
} = LANG_DICTIONARY;

export const schema = (props) => {
  const {
    values = {},
    isMain,
    productOptionOptions = [],
  } = props;
  const { productOptionTypeCode, optionTypesRestrictions } = values;

  const currentProductOption = productOptionOptions.find(
    (productOption) => productOption.value === productOptionTypeCode,
  );

  const autocallFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isAutocall;

    return acc;
  }, {});

  const couponFlagsByProductOption = productOptionOptions.reduce((acc, option) => {
    acc[option.value] = option.isCoupon;

    return acc;
  }, {});

  const isChildCoupon = couponFlagsByProductOption[productOptionTypeCode];

  const isAutocall = autocallFlagsByProductOption[productOptionTypeCode];

  /* eslint-disable */
  const isFlexRedemption = currentProductOption?.isFlexRedemption;
  const isFixRedemption = currentProductOption?.isFixRedemption;
  const isKu = currentProductOption?.isKu;
  /* eslint-enable */

  return (object().shape({
    [STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE]: string()
      .nullable(REQUIRED)
      .required(REQUIRED),
    [STRATEGY_FIELD_NAME.TERM]: number()
      .nullable(REQUIRED)
      .required(REQUIRED),
    [STRATEGY_FIELD_NAME.ASSET_CODES]: array()
      .min(1, REQUIRED)
      .max(6, MAXIMUM_ASSETS),
    ...(isChildCoupon && {
      [STRATEGY_FIELD_NAME.PAYOUT_CODE]: string()
        .nullable(REQUIRED)
        .required(REQUIRED),
      ...(!isMain && {
        [STRATEGY_FIELD_NAME.COUPON]: string()
          .required(REQUIRED)
          .test('len', VALUE_MUST_BE_NUMBER_DIAPASON_0_999_WITH_TWO_DECIMAL, validPercentValue),
      }),
    }),
    ...((!isMain &&
                checkIsEuropeOption(values[STRATEGY_FIELD_NAME.PRODUCT_OPTION_TYPE_CODE])) && {
      [STRATEGY_FIELD_NAME.PARTICIPATION_COEFFICIENT]: string()
        .required(REQUIRED)
        .test('len', VALUE_MUST_BE_NUMBER_DIAPASON_0_999_WITH_TWO_DECIMAL, validPercentValue),
    }),
    ...(isMain && isAutocall && {
      [STRATEGY_FIELD_NAME.BARRIER_AUTOCALL]: string()
        .required(REQUIRED)
        .test('len', getBarrierValidationMessage(optionTypesRestrictions), validBarrierPercentValue(optionTypesRestrictions)),
      [STRATEGY_FIELD_NAME.BONUS_COUPON]: string()
        .required(REQUIRED)
        .test('len', getBonusCouponValidationMessage(optionTypesRestrictions), validBonusCouponValue(optionTypesRestrictions)),
    }),
    ...((isFlexRedemption || isFixRedemption) && {
      [STRATEGY_FIELD_NAME.NOTIONAL_BARRIER]: string().test(
        'len',
        getNotionalValidationBarrierMessage(optionTypesRestrictions),
        validNotionalBarrierValue(optionTypesRestrictions),
      ),
    }),
    ...(isFlexRedemption && {
      [STRATEGY_FIELD_NAME.GEARED_BARRIER]: string().test(
        'len',
        getGearedValidationBarrierMessage(optionTypesRestrictions),
        validGearedBarrierValue(optionTypesRestrictions),
      ),
    }),
    ...(isKu && {
      [STRATEGY_FIELD_NAME.GROWTH_CAP]: string().test(
        'len',
        getGrowthCaValidationMessage(optionTypesRestrictions),
        validGrowthCapValue(optionTypesRestrictions),
      ),
    }),
  }));
};
