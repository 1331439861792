import moment from 'moment';

export const getRealPasportDateForInsured = (birthDate) => {
  const age = moment().diff(birthDate, 'years');

  if (age >= 14 && age < 20) {
    return moment(birthDate).add(14, 'y').add(1, 'day').toDate();
  } if (age >= 20 && age < 45) {
    return moment(birthDate).add(20, 'y').add(1, 'day').toDate();
  } if (age >= 45) {
    return moment(birthDate).add(45, 'y').add(1, 'day').toDate();
  }

  return new Date();
};

export const getRealDocumentDateForInsuredChild = (childInsuredBirthDate) => {
  const age = moment().diff(childInsuredBirthDate, 'years');

  if (age >= 14) {
    return {
      documentType: 1,
      serial: '3213',
      passportNumber: '321312',
      issuedBy: '1 ОМ ОВД Г. МЕЖГОРЬЕ РЕСП. БАШКОРТОСТАН',
      when: new Date('2021-05-01T21:00:00.000Z'),
    };
  }

  return null;
};
