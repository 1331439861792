const CHANGE_STATUS_SCAN = 'underwriting/CHANGE_STATUS_SCAN';
const CHANGE_STATUS_SCAN_SAVE = 'underwriting/CHANGE_STATUS_SCAN_SAVE';
const CHANGE_STATUS_SCAN_SELECT = 'underwriting/CHANGE_STATUS_SCAN_SELECT';
const CHANGE_DESC_SCAN = 'underwriting/CHANGE_DESC_SCAN';
const GET_SCAN_REQUEST = 'underwriting/GET_SCAN_REQUEST';
const GET_SCAN_START = 'underwriting/GET_SCAN_START';
const GET_SCAN_FAIL = 'underwriting/GET_SCAN_FAIL';
const GET_SCAN_SUCCESS = 'underwriting/GET_SCAN_SUCCESS';

const GET_CONTRACT_REQUEST = 'underwriting/GET_CONTRACT_REQUEST';
const GET_CONTRACT_START = 'underwriting/GET_CONTRACT_START';
const GET_CONTRACT_FAIL = 'underwriting/GET_CONTRACT_FAIL';
const GET_CONTRACT_SUCCESS = 'underwriting/GET_CONTRACT_SUCCESS';

const SAVE_CHECKED_CONTRACT_REQUEST = 'underwriting/SAVE_CHECKED_CONTRACT_REQUEST';
const SAVE_CHECKED_CONTRACT_SUCCESS = 'underwriting/SAVE_CHECKED_CONTRACT_SUCCESS';
const SAVE_CHECKED_CONTRACT_FAIL = 'underwriting/SAVE_CHECKED_CONTRACT_FAIL';
const SAVE_CHECKED_CONTRACT_START = 'underwriting/SAVE_CHECKED_CONTRACT_START';

const SET_INIT_STATE = 'underwriting/SET_INIT_STATE';
const SET_STATUS_FINISH_CHECK = 'underwriting/SET_STATUS_FINISH_CHECK';

export {
  CHANGE_STATUS_SCAN_SAVE,
  SET_STATUS_FINISH_CHECK,
  SET_INIT_STATE,
  CHANGE_DESC_SCAN,
  CHANGE_STATUS_SCAN_SELECT,
  SAVE_CHECKED_CONTRACT_FAIL,
  SAVE_CHECKED_CONTRACT_REQUEST,
  SAVE_CHECKED_CONTRACT_START,
  SAVE_CHECKED_CONTRACT_SUCCESS,
  GET_CONTRACT_REQUEST,
  GET_CONTRACT_START,
  GET_CONTRACT_SUCCESS,
  GET_CONTRACT_FAIL,
  CHANGE_STATUS_SCAN,
  GET_SCAN_REQUEST,
  GET_SCAN_START,
  GET_SCAN_FAIL,
  GET_SCAN_SUCCESS,
};
