import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { EventsForm } from 'components';
import { withCustomRouter } from 'HOC';
import {
  getEventsScansErrors,
  getNotification,
  setReadedNotifications,
  setNotificationsReaded,
} from 'redux/rootActions';

const propTypes = {
  getScan: PropTypes.func,
  scans: PropTypes.arrayOf(PropTypes.object),
  errorScanCount: PropTypes.number,
  history: PropTypes.object,
  roles: PropTypes.object,
  currentPage: PropTypes.number,
  totalPages: PropTypes.number,
  notificationCount: PropTypes.number,
  getNotifies: PropTypes.func,
  notificationsData: PropTypes.arrayOf(PropTypes.shape()),
  clearNotifications: PropTypes.func,
  readedNotifications: PropTypes.arrayOf(PropTypes.shape()),
  setReaded: PropTypes.func,
};
const defaultProps = {
  setReaded: () => null,
  readedNotifications: [],
  clearNotifications: () => null,
  notificationsData: [],
  notificationCount: 0,
  currentPage: 1,
  totalPages: 1,
  getScan: () => null,
  scans: [],
  errorScanCount: 0,
  history: {},
  roles: {},
  getNotifies: () => null,
};

class Events extends Component {
  state = {
    choosedTabErrors: true,
    choosedTabNotification: false,
    controlParams: {
      fullName: '',
      contractName: '',
    },
  }

  componentDidMount() {
    const {
      getScan,
      currentPage,
    } = this.props;
    getScan(currentPage, this.getScanParams());
  }

  componentWillUnmount() {
    const { clearNotifications, readedNotifications } = this.props;
    if (readedNotifications.length) {
      clearNotifications(readedNotifications);
    }
  }

  getScanParams = () => {
    const { controlParams: { fullName, contractName } } = this.state;

    return { fullName, contractName };
  }

  handleChangeTab = (typeTab) => {
    const { getScan, getNotifies } = this.props;
    if (typeTab === 'errors') {
      this.setState({
        choosedTabErrors: true,
        choosedTabNotification: false,
      });
      getScan(1, this.getScanParams());
    }
    if (typeTab === 'notifies') {
      this.setState({
        choosedTabErrors: false,
        choosedTabNotification: true,
      });
      getNotifies(1);
    }
  }

  handleUpPage = () => {
    const {
      currentPage,
      getScan,
      totalPages,
      getNotifies,
    } = this.props;
    const { choosedTabErrors } = this.state;
    if (currentPage !== totalPages && totalPages !== 1) {
      if (choosedTabErrors) {
        getScan(currentPage + 1, this.getScanParams());
      } else {
        getNotifies(currentPage + 1);
      }
    }
  };

  handleDownPage = () => {
    const { currentPage, getScan, getNotifies } = this.props;
    const { choosedTabErrors } = this.state;
    if (currentPage > 1) {
      if (choosedTabErrors) {
        getScan(currentPage - 1, this.getScanParams());
      } else {
        getNotifies(currentPage - 1);
      }
    }
  };

  onTableControlsChange = (key, value) => {
    const { controlParams } = this.state;
    this.setState({
      controlParams: {
        ...controlParams,
        [key]: value,
      },
    });
  }

  render() {
    const {
      errorScanCount,
      history,
      scans,
      roles,
      totalPages,
      currentPage,
      notificationCount,
      getNotifies,
      notificationsData,
      getScan,
      setReaded,
    } = this.props;
    const {
      choosedTabErrors,
      choosedTabNotification,
      controlParams: tableControls,
    } = this.state;

    return (
      <EventsForm
        setReadedNotifications={setReaded}
        choosedTabErrors={choosedTabErrors}
        choosedTabNotification={choosedTabNotification}
        getScan={(page) => getScan(page, this.getScanParams())}
        errorScanCount={errorScanCount}
        notificationCount={notificationCount}
        history={history}
        scans={scans}
        handleChangeTab={this.handleChangeTab}
        handleUpPage={this.handleUpPage}
        handleDownPage={this.handleDownPage}
        active={currentPage}
        roles={roles}
        getNotifies={getNotifies}
        page={currentPage}
        totalPages={totalPages}
        notificationsData={notificationsData}
        tableControls={tableControls}
        onTableControlsChange={this.onTableControlsChange}
      />
    );
  }
}

const mapStateToPrpops = ({
  events: {
    scans,
    total,
    errorScanCount,
    currentPage,
    totalPages,
    notificationCount,
    notificationsData,
    readedNotifications,
  },
  authReducer: {
    roles,
  },
}) => ({
  scans,
  total,
  errorScanCount,
  roles,
  currentPage,
  totalPages,
  notificationCount,
  notificationsData,
  readedNotifications,
});

const mapDispatchToProps = {
  getScan: getEventsScansErrors,
  getNotifies: getNotification,
  setReaded: setReadedNotifications,
  clearNotifications: setNotificationsReaded,
};

Events.propTypes = propTypes;
Events.defaultProps = defaultProps;
export default withCustomRouter(connect(mapStateToPrpops, mapDispatchToProps)(Events));
