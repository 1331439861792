import React, { memo } from 'react';
import { Select, Form } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { block } from 'bem-cn';
import {
  MainBtn,
  RequiredLabel,
  ErrorMessage,
  CustomCheckBox,
  AgentCheckingErrorPopup,
} from 'components';
import { TestDataGenerator } from 'containers';
import {
  getBeneficiaryFormDataSelector,
  getActiveConfigSelector,
  getDocumentsConfigSelector,
  getAddressConfigSelector,
  getContactsConfigSelector,
  getBeneficiaryAddressDataSelector,
  getBeneficiaryDocumentsDataSelector,
  getBeneficiaryAddressErrorsSelector,
  getBeneficiaryDocumentsErrorsSelector,
  getBeneficiaryErrorsSelector,
  getBeneficiaryTouchedSelector,
  getBeneficiaryAddressTouchedSelector,
  getBeneficiaryDocumentsTouchedSelector,
  getBeneficiaryDocumentsSnilsErrorSelector,
  getBeneficiaryDocumentsCountVisableErrors,
  getBeneficiaryAddressCountVisableErrors,
  getBeneficiaryContactsDataSelector,
  getBeneficiaryContactsErrorsSelector,
  getBeneficiaryContactsTouchedSelector,
  getBeneficiaryContactsCountVisableErrors,
} from 'redux/rootSelectors';
import { LANG_DICTIONARY, TEST_DATA_ENUMS } from 'consts';
import { Collapse } from 'reactstrap';
import Beneficiary from './componets/Beneficiary';
import './styles.scss';

const { BENEFICIARY_BY_LAW, BENEFICIARY_COUNT, NEXT } = LANG_DICTIONARY;
const b = block('beneficiaries-form');

const propTypes = {
  validateForm: PropTypes.func,
  relationships: PropTypes.arrayOf(PropTypes.object),
  genders: PropTypes.arrayOf(PropTypes.object),
  beneficiaries: PropTypes.object,
  setActiveTab: PropTypes.func,
  setStatusTab: PropTypes.func,
  handleChangeByForm: PropTypes.func,
  handleTouchedByForm: PropTypes.func,
  setAddress: PropTypes.func,
  setFmsUnit: PropTypes.func,
  setLivingAddressIsSame: PropTypes.func,
  beneficiariesList: PropTypes.arrayOf(PropTypes.object),
  setCountBeneficiaries: PropTypes.func,
  countBeneficiaries: PropTypes.number,
  isBeneficiaries: PropTypes.bool,
  setIsBeneficiaries: PropTypes.func,
  handleChangeNameByForm: PropTypes.func,
  handleBlurNameByForm: PropTypes.func,
  validateExtremism: PropTypes.func,
  handleBlurPassportData: PropTypes.func,
  handleSubmit: PropTypes.func,
  insuranceAmmountError: PropTypes.string,
  isLoading: PropTypes.bool,
  validateInsuredIsInBeneficiary: PropTypes.func,
  error: PropTypes.string,
  productForm: PropTypes.object,
  checkCode: PropTypes.func,
  userInfo: PropTypes.object,
  validateAddress: PropTypes.func,
  scenario: PropTypes.string,
  handleChangeAddress: PropTypes.func,
};

const defaultProps = {
  validateForm: () => null,
  relationships: () => null,
  genders: [],
  beneficiaries: [],
  setActiveTab: () => null,
  setStatusTab: () => null,
  handleChangeByForm: () => null,
  handleTouchedByForm: () => null,
  setAddress: () => null,
  setFmsUnit: () => null,
  setLivingAddressIsSame: () => null,
  beneficiariesList: [],
  setCountBeneficiaries: () => null,
  countBeneficiaries: 0,
  isBeneficiaries: true,
  setIsBeneficiaries: () => null,
  handleChangeNameByForm: () => null,
  handleBlurNameByForm: () => null,
  validateExtremism: () => null,
  handleBlurPassportData: () => null,
  handleSubmit: () => null,
  insuranceAmmountError: '',
  isLoading: false,
  validateInsuredIsInBeneficiary: () => null,
  error: '',
  productForm: {},
  checkCode: () => null,
  userInfo: {},
  validateAddress: () => null,
  scenario: '',
  handleChangeAddress: () => null,
};

const BeneficiariesForm = ({
  validateForm,
  relationships,
  genders,
  beneficiaries,
  setActiveTab,
  setStatusTab,
  handleChangeByForm,
  handleTouchedByForm,
  setAddress,
  setFmsUnit,
  setLivingAddressIsSame,
  beneficiariesList,
  setCountBeneficiaries,
  countBeneficiaries,
  isBeneficiaries,
  setIsBeneficiaries,
  handleChangeNameByForm,
  handleBlurNameByForm,
  validateExtremism,
  handleBlurPassportData,
  handleSubmit,
  insuranceAmmountError,
  isLoading,
  validateInsuredIsInBeneficiary,
  error,
  productForm,
  productForm: {
    beneficiaryInLaw,
  },
  checkCode,
  userInfo,
  validateAddress,
  handleChangeAddress,
  scenario,
}) => {
  const getBeneficiaries = (count) => {
    const rebuildBeneficiaries = [...beneficiariesList];

    return rebuildBeneficiaries.slice(0, count);
  };

  return (
    <Form className={b()} loading={isLoading}>
      <AgentCheckingErrorPopup />
      <div className={b('config-block')}>
        <CustomCheckBox
          checked={isBeneficiaries}
          onChange={setIsBeneficiaries}
          disabled={beneficiaryInLaw}
          isShowDisabled
        />
        <p className={b('beneficiary-by-raw')}>{BENEFICIARY_BY_LAW}</p>
      </div>
      <Collapse isOpen={!isBeneficiaries}>
        <>
          <div className={b('config-block')}>
            <p className={b('beneficiary-count')}>{BENEFICIARY_COUNT}</p>
            <Select
              options={beneficiariesList}
              value={countBeneficiaries}
              onChange={setCountBeneficiaries}
            />
          </div>
          <RequiredLabel />
          {getBeneficiaries(countBeneficiaries).map(({ key }) => (
            <Beneficiary
              handleChangeAddress={handleChangeAddress}
              key={`beneficiary${key}`}
              index={key}
              handleChangeByForm={handleChangeByForm}
              handleTouchedByForm={handleTouchedByForm}
              validateForm={validateForm}
              relationships={relationships}
              genders={genders}
              setActiveTab={setActiveTab}
              setStatusTab={setStatusTab}
              setAddress={setAddress}
              setFmsUnit={setFmsUnit}
              productForm={productForm}
              handleChangeNameByForm={handleChangeNameByForm}
              handleBlurNameByForm={handleBlurNameByForm}
              validateExtremism={validateExtremism}
              validateInsuredIsInBeneficiary={validateInsuredIsInBeneficiary}
              checkCode={checkCode}
              userInfo={userInfo}
              validateAddress={validateAddress}
              active={getActiveConfigSelector(beneficiaries, key)}
              documents={getDocumentsConfigSelector(beneficiaries, key)}
              address={getAddressConfigSelector(beneficiaries, key)}
              insuranceAmmountError={insuranceAmmountError}
              contacts={getContactsConfigSelector(beneficiaries, key)}
              contactsFormData={getBeneficiaryContactsDataSelector(beneficiaries, key)}
              contactsFormDataErrors={getBeneficiaryContactsErrorsSelector(beneficiaries, key)}
              contactsFormDataTouched={getBeneficiaryContactsTouchedSelector(beneficiaries, key)}
              countVisableErrorsContacts={
                getBeneficiaryContactsCountVisableErrors(beneficiaries, key)
              }
              addressFormData={getBeneficiaryAddressDataSelector(beneficiaries, key)}
              addressFormDataErrors={getBeneficiaryAddressErrorsSelector(beneficiaries, key)}
              addressFormDataTouched={getBeneficiaryAddressTouchedSelector(beneficiaries, key)}
              countVisableErrorsAddress={
                getBeneficiaryAddressCountVisableErrors(beneficiaries, key)
              }
              setLivingAddressIsSame={setLivingAddressIsSame}
              handleBlurPassportData={handleBlurPassportData}
              documentsFormData={getBeneficiaryDocumentsDataSelector(beneficiaries, key)}
              documentsFormDataErrors={getBeneficiaryDocumentsErrorsSelector(beneficiaries, key)}
              documentsFormDataTouched={getBeneficiaryDocumentsTouchedSelector(beneficiaries, key)}
              snilsError={getBeneficiaryDocumentsSnilsErrorSelector(beneficiaries, key)}
              countVisableErrorsDocuments={
                getBeneficiaryDocumentsCountVisableErrors(beneficiaries, key)
              }
              formData={getBeneficiaryFormDataSelector(beneficiaries, key)}
              formDataErrors={getBeneficiaryErrorsSelector(beneficiaries, key)}
              formDataTouched={getBeneficiaryTouchedSelector(beneficiaries, key)}
            />
          ))}
        </>
      </Collapse>
      <ErrorMessage message={error} />
      <div className={b('submit-interface')}>
        <TestDataGenerator type={TEST_DATA_ENUMS.beneficiary} scenario={scenario} />
        <MainBtn
          text={NEXT}
          onClick={handleSubmit}
          disabled={isLoading}
        />
      </div>
    </Form>
  );
};

BeneficiariesForm.propTypes = propTypes;
BeneficiariesForm.defaultProps = defaultProps;
export default memo(BeneficiariesForm);
