import React, { Component } from 'react';
import block from 'bem-cn/lib/index';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { slide as Menu } from 'react-burger-menu';
import { Button } from 'semantic-ui-react';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { logOut, toggleMenu } from 'redux/actions';
import { checkAccessRoute } from 'roles';
import { withCustomRouter } from 'HOC';
import './styles.scss';

const b = block('sideBar');
const {
  SIGN_OUT,
  TITLE_IMPORT,
  CHECKING_DOCUMENTS,
  ADMINISTRATION,
  TASKS_AND_LOCKS,
  PARTNERS,
  BRANCHES,
  USERS,
  REPORTS,
  TRADE_DATAS,
  STATUS_MANAGMENT,
  DISTRIBUTING_PRODUCTS,
  SCHEDULER_JOBS,
  REFERENCES_ADMIN_INTERFACE,
  PUBLIC_API,
  UPLOAD_TEMPLATES_TITLE,
} = LANG_DICTIONARY;

const {
  importForm,
  signIn,
  checkMaster,
  checkDocumentList,
  checkMasterEditor,
  locking,
  addPartners,
  addSubdivisions,
  addUsers,
  addReports,
  statusControl,
  transferData,
  addProducts,
  schedulerJobs,
  catalogsSection,
  publicApi,
  templatesUpload,
} = ROUTES;

const defaultProps = {
  menuOpen: false,
  roles: {},
};

const propTypes = {
  ToggleMenu: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  menuOpen: PropTypes.bool,
  roles: PropTypes.object,
};

class BurgerMenu extends Component {
  constructor(props) {
    super(props);

    this.state = {
      showAdministration: false,
    };
  }

  logOut = () => {
    const { ToggleMenu, history } = this.props;
    ToggleMenu();
    history.push(signIn);
  };

  toggleShowAdministration = () => {
    const { showAdministration } = this.state;
    this.setState({ showAdministration: !showAdministration });
  };

  goToPath = (path) => () => {
    const { ToggleMenu, history } = this.props;
    ToggleMenu();
    history.push(path);
  };

  render() {
    const {
      menuOpen,
      ToggleMenu,
      roles,
    } = this.props;

    const { showAdministration } = this.state;

    const accessUnderwriting = checkAccessRoute(checkMaster, roles)
        && checkAccessRoute(checkMasterEditor, roles)
        && checkAccessRoute(checkDocumentList, roles);

    const accessExport1c = checkAccessRoute(transferData, roles);
    const accessImport = checkAccessRoute(importForm, roles);
    const accessLocking = checkAccessRoute(locking, roles);

    const accessPartnersAdmin = checkAccessRoute(addPartners, roles);
    const accessBranchesAdmin = checkAccessRoute(addSubdivisions, roles);
    const accessUsersAdmin = checkAccessRoute(addUsers, roles);
    const accessReportsAdmin = checkAccessRoute(addReports, roles);
    const accessStatusControl = checkAccessRoute(statusControl, roles);
    const accessProductsAdmin = checkAccessRoute(addPartners, roles);
    const accessSchedulerJobs = checkAccessRoute(schedulerJobs, roles);
    const accessReferences = checkAccessRoute(catalogsSection, roles);
    const accessPublicList = checkAccessRoute(publicApi, roles);
    const accessUploadTemplate = checkAccessRoute(templatesUpload, roles);

    const isShowAdministration = accessUnderwriting
    || accessImport
    || accessExport1c
    || accessLocking
    || accessPartnersAdmin
    || accessBranchesAdmin
    || accessUsersAdmin
    || accessReportsAdmin
    || accessStatusControl
    || accessProductsAdmin
    || accessSchedulerJobs
    || accessReferences
    || accessPublicList
    || accessUploadTemplate;

    return (
      <Menu
        className={b()}
        isOpen={menuOpen}
        onStateChange={(state) => !state.isOpen && ToggleMenu(true)}
        right
      >
        {isShowAdministration && (
        <Button
          onClick={this.toggleShowAdministration}
          className={b('menuItem').toString()}
          type="text"
        >
          { ADMINISTRATION }
        </Button>
        )}
        {showAdministration && (
        <div className={b('sub-menu')}>
          {accessExport1c
              && (
              <Button
                onClick={this.goToPath(transferData)}
                className={b('menuItem').toString()}
                type="text"
              >
                { TRADE_DATAS }
              </Button>
              )}
          {accessImport
              && (
              <Button
                onClick={this.goToPath(importForm)}
                className={b('menuItem').toString()}
                type="text"
              >
                { TITLE_IMPORT }
              </Button>
              )}
          {accessUnderwriting && (
          <Button
            onClick={this.goToPath(checkMaster)}
            className={b('menuItem').toString()}
            type="text"
          >
            { CHECKING_DOCUMENTS }
          </Button>
          )}
          {accessLocking && (
          <Button
            onClick={this.goToPath(locking)}
            className={b('menuItem').toString()}
            type="text"
          >
            { TASKS_AND_LOCKS }
          </Button>
          )}
          {accessSchedulerJobs && (
          <Button
            onClick={this.goToPath(schedulerJobs)}
            className={b('menuItem').toString()}
            type="text"
          >
            {SCHEDULER_JOBS }
          </Button>
          )}
          {accessPartnersAdmin && (
          <Button
            onClick={this.goToPath(addPartners)}
            className={b('menuItem').toString()}
            type="text"
          >
            { PARTNERS }
          </Button>
          )}
          {accessBranchesAdmin && (
          <Button
            onClick={this.goToPath(addSubdivisions)}
            className={b('menuItem').toString()}
            type="text"
          >
            { BRANCHES }
          </Button>
          )}
          {accessUsersAdmin && (
            <Button
              onClick={this.goToPath(addUsers)}
              className={b('menuItem').toString()}
              type="text"
            >
              { USERS }
            </Button>
          )}
          {accessReportsAdmin && (
            <Button
              onClick={this.goToPath(addReports)}
              className={b('menuItem').toString()}
              type="text"
            >
              { REPORTS }
            </Button>
          )}
          {accessStatusControl && (
            <Button
              onClick={this.goToPath(statusControl)}
              className={b('menuItem').toString()}
              type="text"
            >
              { STATUS_MANAGMENT }
            </Button>
          )}
          {accessProductsAdmin && (
            <Button
              onClick={this.goToPath(addProducts)}
              className={b('menuItem').toString()}
              type="text"
            >
              { DISTRIBUTING_PRODUCTS }
            </Button>
          )}
          {accessReferences && (
          <Button
            onClick={this.goToPath(catalogsSection)}
            className={b('menuItem').toString()}
            type="text"
          >
            { REFERENCES_ADMIN_INTERFACE }
          </Button>
          )}
          {accessPublicList && (
          <Button
            onClick={this.goToPath(publicApi)}
            className={b('menuItem').toString()}
            type="text"
          >
            { PUBLIC_API }
          </Button>
          )}
          {
            accessUploadTemplate && (
              <Button
                onClick={this.goToPath(templatesUpload)}
                className={b('menuItem').toString()}
                type="text"
              >
                { UPLOAD_TEMPLATES_TITLE }
              </Button>
            )
          }
        </div>
        )}
        <Button
          onClick={() => this.logOut()}
          className={b('menuItem').toString()}
          type="text"
        >
          { SIGN_OUT }
        </Button>
      </Menu>
    );
  }
}

BurgerMenu.propTypes = propTypes;
BurgerMenu.defaultProps = defaultProps;

const mapStateToProps = ({
  helperReducer,
  authReducer: {
    roles,
  },
}) => ({
  ...helperReducer,
  roles,
});

export default withCustomRouter(connect(
  mapStateToProps, { ToggleMenu: toggleMenu, LogOut: logOut },
)(BurgerMenu));
