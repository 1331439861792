import React from 'react';
import { block } from 'bem-cn';
import { Dimmer, Loader } from 'semantic-ui-react';
import PropTypes from 'prop-types';
import { LANG_DICTIONARY, ROUTES } from 'consts';
import { PopupContainer } from 'components';
import './styles.scss';

const propTypes = {
  isActiveBanner: PropTypes.bool.isRequired,
  isLoadingBanner: PropTypes.bool.isRequired,
  bannerUrl: PropTypes.string.isRequired,
  toggleIsActiveBanner: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
};

const b = block('contest-invite-banner-popup');
const { DOWNLOADING } = LANG_DICTIONARY;

const ContestInviteBanner = ({
  isLoadingBanner,
  isActiveBanner,
  bannerUrl,
  toggleIsActiveBanner,
  history,
}) => (
  <PopupContainer
    isOpen={isActiveBanner}
    isCloseButton
    close={() => toggleIsActiveBanner(false)}
    className={b()}
  >
    <div className={b('content')}>
      <Dimmer
        active={isLoadingBanner}
        inverted
        className={b('dimmer').toString()}
      >
        <div className={b('loader-wrapper')}>
          <Loader inverted content={DOWNLOADING} />
        </div>
      </Dimmer>
      <div
        onClick={() => {
          toggleIsActiveBanner(false);
          history.push(ROUTES.contests);
        }}
        role="button"
        tabIndex="-1"
      >
        {bannerUrl && (
        <img
          src={bannerUrl}
          alt="banner"
          className={b('banner')}
        />
        )}
      </div>
    </div>
  </PopupContainer>
);

ContestInviteBanner.propTypes = propTypes;
export default ContestInviteBanner;
