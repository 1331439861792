import { all } from 'redux-saga/effects';
import AuthSaga from './sages/authSaga';
import HelperSaga from './sages/helperSaga';
import CheckoutSaga from './sages/checkoutSaga';
import FilteringSaga from './filtering/sages';
import ContractInfo from './contractInfo/sages';
import BankSaga from './bank/saga';
import importSaga from './import/sages';
import AdministrationSaga from './transferData/sages';
import SharedSaga from './shared/sages';
import LoggingSaga from './logging/sages';
import UnderwritingSaga from './underwriting/sages';
import FileSaga from './files/sages';
import UnderwritingMasterSaga from './underwritingMaster/sages';
import UnderwritingListSaga from './underwritingList/sages';
import VersionDpSaga from './versioningDp/sages';
import EventsSaga from './events/sages';
import LocksAndTaskSaga from './lockingAndTasks/sages';
import PartnersSaga from './partnersAdminInterface/sages';
import BranchesSaga from './branchesAdminInterface/sages';
import UsersSaga from './usersAdmininterface/sages';
import SelectsSaga from './selects/sages';
import reportsSaga from './reportsAdminInterface/sages';
import constructorIszSaga from './constructorIsz/sages';
import productsAdmininistartionSaga from './productsAdmininterface/sages';
import catalogsAdministrationSaga from './catalogsAdministrations/sages';
import schedulerJobsSaga from './schedulerJobs/sages';
import nszProductSaga from './nszProduct/sages';
import kszProductSaga from './kszProduct/sages';
import agentData from './agentData/sages';
import publicApi from './publicApi/sages';
import ContestsSages from './contests/sages';
import PopupSages from './popups/sages';
import templatesSaga from './templates/sages';
import BasketConstructor from './basketConstructor/sages';
import clientReportsSaga from './clientReports/sages';
import docsTransferSaga from './docsTransfer/sages';
import ValidationSaga from './validation/sages';

export default function* rootSaga() {
  yield all([
    publicApi(),
    agentData(),
    nszProductSaga(),
    kszProductSaga(),
    catalogsAdministrationSaga(),
    productsAdmininistartionSaga(),
    schedulerJobsSaga(),
    constructorIszSaga(),
    reportsSaga(),
    SelectsSaga(),
    EventsSaga(),
    UnderwritingListSaga(),
    UnderwritingMasterSaga(),
    FileSaga(),
    SharedSaga(),
    BankSaga(),
    AuthSaga(),
    HelperSaga(),
    CheckoutSaga(),
    FilteringSaga(),
    ContractInfo(),
    importSaga(),
    AdministrationSaga(),
    LoggingSaga(),
    UnderwritingSaga(),
    VersionDpSaga(),
    LocksAndTaskSaga(),
    PartnersSaga(),
    BranchesSaga(),
    UsersSaga(),
    ContestsSages(),
    PopupSages(),
    templatesSaga(),
    BasketConstructor(),
    clientReportsSaga(),
    docsTransferSaga(),
    ValidationSaga(),
  ]);
}
