import React from 'react';
import { CustomRoute } from 'components';
import {
  Authorization,
  ForgotPassword,
  ResetPassword,
} from 'containers';
import { PAGE_TITLES, ROUTES } from 'consts';

const { main } = PAGE_TITLES;
const {
  signIn: signInUrl,
  forgotPassword: forgotPasswordUrl,
  resetPassword: resetPasswordUrl,
} = ROUTES;

const pathes = [
  {
    path: signInUrl,
    title: main,
    publicRoute: true,
    component: Authorization,
  },
  {
    path: forgotPasswordUrl,
    title: main,
    publicRoute: true,
    component: ForgotPassword,
  },
  {
    path: resetPasswordUrl,
    title: main,
    publicRoute: true,
    component: ResetPassword,
  },
];

export default (props) => pathes.map((route) => (
  <CustomRoute
    {...route}
    {...props}
    key={route.path}
    exact
  />
));
