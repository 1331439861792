import React from 'react';
import { block } from 'bem-cn';
import { Select } from 'semantic-ui-react';
import { LANG_DICTIONARY } from 'consts';
import { CreateEntityBtn, MainBtn, FlexTableCatalogs } from 'components';
import PropTypes from 'prop-types';
import './styles.scss';

const b = block('catalogs-admin-form');

const {
  ADD_ROW,
  REVOKE,
  SAVE_WORD,
  NOT_CHOOSED,
} = LANG_DICTIONARY;

const propTypes = {
  tableInfo: PropTypes.arrayOf(PropTypes.any),
  selectsInfo: PropTypes.arrayOf(PropTypes.object),
  headers: PropTypes.arrayOf(PropTypes.any),
  addRow: PropTypes.func,
  saveChanges: PropTypes.func,
  editRow: PropTypes.func,
  deleteRow: PropTypes.func,
  reloadChanges: PropTypes.func,
  handleChangeSelect: PropTypes.func,
  accessRowEdit: PropTypes.func,
  selectId: PropTypes.number,
  referenceName: PropTypes.string,
};

const defaultProps = {
  referenceName: null,
  selectId: 0,
  tableInfo: [],
  selectsInfo: [],
  headers: [],
  handleChangeSelect: () => null,
  accessRowEdit: () => null,
  addRow: () => null,
  saveChanges: () => null,
  editRow: () => null,
  deleteRow: () => null,
  reloadChanges: () => null,
};

const CatalogsAdminForm = ({
  headers,
  tableInfo,
  selectsInfo,
  handleChangeSelect,
  addRow,
  saveChanges,
  editRow,
  deleteRow,
  reloadChanges,
  accessRowEdit,
  selectId,
  referenceName,
}) => (
  <div className={b()}>
    <div>
      <div className={b('header')}>
        <Select
          options={selectsInfo}
          onChange={(e, { value }) => handleChangeSelect(value)}
          placeholder={NOT_CHOOSED}
        />
        <div className={b('reference-name')}>
          <div>{referenceName}</div>
        </div>
        <CreateEntityBtn
          text={ADD_ROW}
          onClick={() => addRow()}
          disabled={!selectId}
        />
      </div>
      <div className={b('content')}>
        {Boolean(headers.length) && (
        <FlexTableCatalogs
          headers={headers}
          tableInfo={tableInfo}
          editRow={editRow}
          deleteRow={deleteRow}
          accessRowEdit={accessRowEdit}
        />
        )}
      </div>
    </div>
    <div className={b('footer')}>
      <MainBtn
        text={REVOKE}
        className={b('control-button').toString()}
        onClick={() => reloadChanges()}
        disabled={!tableInfo.length}
      />
      <MainBtn
        text={SAVE_WORD}
        className={b('control-button').toString()}
        onClick={() => saveChanges()}
        disabled={!tableInfo.length}
      />
    </div>
  </div>
);

CatalogsAdminForm.propTypes = propTypes;
CatalogsAdminForm.defaultProps = defaultProps;
export default CatalogsAdminForm;
