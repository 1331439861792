import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { selectOption } from 'redux/rootActions';
import { checkRerender } from 'helpers';
import SelectWrapper from './selectWrapper';
import getSelect from './selects';

const propTypes = {
  type: PropTypes.string,
  text: PropTypes.string,
  name: PropTypes.string,
  prefix: PropTypes.string,
  params: PropTypes.object,
  errors: PropTypes.object,
  touched: PropTypes.object,
  disabled: PropTypes.bool,
  required: PropTypes.bool,
  placeholderText: PropTypes.string,
};

const defaultProps = {
  type: '',
  text: '',
  name: '',
  params: {},
  errors: {},
  touched: {},
  prefix: '',
  disabled: false,
  required: false,
  placeholderText: '',
};

const selectWrapperWinthStore = (action, type, prefix) => connect(({
  selects: {
    [`${prefix}${type}`]: options,
    [`${prefix}${type}Select`]: value,
  },
}) => ({
  options,
  value,
}), {
  action,
  selectOptionByType: selectOption,
})(SelectWrapper);

class SelectsFactory extends React.Component {
  shouldComponentUpdate({
    params,
    errors,
    name,
    disabled,
    required,
    touched,
  }) {
    const {
      params: oldParams,
      errors: oldErrors,
      disabled: oldDisabled,
      required: oldRequired,
      touched: oldTouched,
    } = this.props;

    const isRender = checkRerender(oldParams, params)
      || errors[name] !== oldErrors[name]
      || touched[name] !== oldTouched[name]
      || disabled !== oldDisabled
      || required !== oldRequired;

    return isRender;
  }

  render() {
    const {
      type,
      placeholderText,
      name,
      prefix,
      ...rest
    } = this.props;
    const selectData = getSelect(type);
    const SelectWrapperWithStore = selectWrapperWinthStore(selectData.action, type, prefix);

    return (
      <SelectWrapperWithStore
        placeholderText={placeholderText}
        type={type}
        name={name}
        prefix={prefix}
        {...selectData}
        {...rest}
      />
    );
  }
}

SelectsFactory.propTypes = propTypes;
SelectsFactory.defaultProps = defaultProps;
export default SelectsFactory;
