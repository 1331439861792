import React, { Component } from 'react';
import { block } from 'bem-cn';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { LANG_DICTIONARY } from 'consts';
import PropTypes from 'prop-types';
import './styles.scss';

const propTypes = {
  setTags: PropTypes.func,
  tags: PropTypes.arrayOf(PropTypes.object),
  allRoles: PropTypes.arrayOf(PropTypes.object),
  values: PropTypes.object,
  reportTags: PropTypes.arrayOf(PropTypes.object),
  error: PropTypes.bool,
};

const defaultProps = {
  error: false,
  tags: [],
  setTags: () => null,
  allRoles: [],
  values: {},
  reportTags: [],
};
const { PLACEHOLDER_DRAG_LIST_REPORTS } = LANG_DICTIONARY;

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list);
  const [removed] = result.splice(startIndex, 1);
  result.splice(endIndex, 0, removed);

  return result;
};

const move = ({
  tags,
  reportTags,
  source,
  destination,
}) => {
  const newTags = Array.from(tags);
  const newReportTags = Array.from(reportTags);
  const { index, droppableId } = source;
  const { droppableId: destinationId } = destination;
  const [removed] = newTags.splice(index, 1);
  newReportTags.push(removed);

  const final = {};
  final[droppableId] = newTags;
  final[destinationId] = newReportTags;

  return final;
};

const grid = 8;

const getItemStyle = (isDragging, draggableStyle) => ({
  userSelect: 'none',
  padding: grid * 2,
  margin: `0 0 ${grid}px 0`,
  background: 'white',
  ...draggableStyle,
});

const getListStyle = (isDraggingOver) => ({
  background: isDraggingOver ? 'lightblue' : 'lightgrey',
  padding: grid,
  width: 250,
});

const b = block('drag-list');
class DragList extends Component {
    onDragEnd = (result) => {
      const { source, destination } = result;
      const {
        tags,
        reportTags,
        values,
        setTags,
      } = this.props;
      if (!destination) {
        return;
      }
      if (source.droppableId === destination.droppableId) {
        const items = reorder(
          source.droppableId === 'droppable' ? tags : reportTags,
          source.index,
          destination.index,
        );
        if (source.droppableId === 'droppable') {
          setTags(items, reportTags, values);
        } else {
          setTags(tags, items, values);
        }
      } else if (source.droppableId === 'droppable') {
        const final = move({
          tags,
          reportTags,
          source,
          destination,
        });
        setTags(final.droppable, final.droppable2, values);
      } else if (source.droppableId === 'droppable2') {
        const final = move({
          tags: reportTags,
          reportTags: tags,
          source,
          destination,
        });
        setTags(final.droppable, final.droppable2, values);
      }
    };

    handleChangeNameTag = (item) => (e) => {
      const {
        tags, reportTags, values, setTags,
      } = this.props;
      const newTags = tags.slice();
      const newReportTags = reportTags.slice();
      Object.keys(newTags).forEach((key) => {
        if (newTags[key].id === item.id) {
          newTags[key].content = e.target.value;
        }
      });
      Object.keys(newReportTags).forEach((key) => {
        if (newReportTags[key].id === item.id) {
          newReportTags[key].content = e.target.value;
        }
      });
      setTags(newTags, newReportTags, values);
    };

    render() {
      const { tags, reportTags, error } = this.props;

      return (
        <DragDropContext onDragEnd={this.onDragEnd}>
          <Droppable droppableId="droppable">
            {(provided, snapshot) => (
              <div
                className={b('start')}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {tags.map((item, index) => (
                  <Draggable
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.id}${index}`}
                    draggableId={item.id}
                    index={index}
                  >
                    {(prov, snap) => (
                      <div
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                        style={getItemStyle(
                          snap.isDragging,
                          prov.draggableProps.style,
                        )}
                      >
                        <input className={b('item-input')} defaultValue={item.content} onChange={this.handleChangeNameTag(item)} />
                        <p className={b('item-input-tag')}>{item.id}</p>
                      </div>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
          <Droppable droppableId="droppable2">
            {(provided, snapshot) => (
              <div
                className={reportTags.length ? b('result') : b('empty')}
                ref={provided.innerRef}
                style={getListStyle(snapshot.isDraggingOver)}
              >
                {reportTags.length ? reportTags.map((item, index) => (
                  <Draggable
                    // eslint-disable-next-line react/no-array-index-key
                    key={`${item.id}${index}`}
                    draggableId={item.id}
                    index={index}
                  >
                    {(prov, snap) => (
                      <div
                        className={b('item')}
                        ref={prov.innerRef}
                        {...prov.draggableProps}
                        {...prov.dragHandleProps}
                        style={getItemStyle(
                          snap.isDragging,
                          prov.draggableProps.style,
                        )}
                      >
                        <input className={b('item-input')} defaultValue={item.content} onChange={this.handleChangeNameTag(item)} />
                        <p className={b('item-input-tag')}>{item.id}</p>
                      </div>
                    )}
                  </Draggable>
                )) : <div className={b('placeholder')}><p className={error ? b('error') : null}>{PLACEHOLDER_DRAG_LIST_REPORTS}</p></div>}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        </DragDropContext>
      );
    }
}

DragList.propTypes = propTypes;
DragList.defaultProps = defaultProps;
export default DragList;
