export default (headers, data) => ({
  key: `${data[0]}${data[1]}${data[2]}`,
  data: [
    {
      md: 2,
      headers: [
        { text: headers[0] },
      ],
      data: [
        { text: data[0] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[1] },
      ],
      data: [
        { text: data[1] },
      ],
    },
    {
      md: 3,
      headers: [
        { text: headers[2] },
      ],
      data: [
        { text: data[2] },
      ],
    },
    {
      md: 2,
      headers: [
        {
          text: headers[3],
          style: { justifyContent: 'center', textAlign: 'center' },
        },
      ],
      data: [
        { text: data[3] },
      ],
    },
    {
      md: 1,
      headers: [
        {
          text: headers[4],
          style: { justifyContent: 'flex-start', textAlign: 'center' },
        },
      ],
      data: [
        { text: data[4] },
      ],
    },
    {
      md: 1,
      headers: [
        {
          text: headers[5],
          style: { justifyContent: 'flex-start', textAlign: 'center' },
        },
      ],
      data: [
        { text: data[5] },
      ],
    },
  ],
});
