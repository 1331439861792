import moment from 'moment';
import {
  DATE_FORMAT,
  TIME_FORMAT,
} from 'consts';

export default (date) => {
  const currentDate = moment(date).format(DATE_FORMAT);
  const currentTime = moment(date).format(TIME_FORMAT);
  const zone = moment(date).utcOffset() / 60;

  return `${currentDate} ${currentTime} GMT+${zone}`;
};
