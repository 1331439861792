import { takeEvery, put } from 'redux-saga/effects';
import { contestActions } from 'redux/rootActions';
import {
  CLOSE_ALL_POPUPS,
} from '../types';

function* closeAllPopups() {
  // TODO add disabling function for all popups
  yield put(contestActions.toggleIsActiveBanner(false));
}

function* popupSages() {
  yield takeEvery(CLOSE_ALL_POPUPS, closeAllPopups);
}

export default popupSages;
