import testDataGenerator from 'helpers/getTestData';
import { TEST_DATA_ENUMS } from 'consts';
import rebuildBeneficiaries from './rebuildBeneficiaries';
import { getRealPasportDateForInsured } from './getRealPasportDate';

const generateInsurer = (scenario, additionalData) => {
  const {
    fillInsurerDataCheckout,
    hideInnIsz,
    hideSnilsIsz,
    iszInsurerIsInsured,
    birthDate,
  } = additionalData;
  const { insurer } = testDataGenerator.iszTestDataGenerate(scenario);

  const realPasportDate = getRealPasportDateForInsured(birthDate);

  const rebuildInsurer = {
    ...insurer,
    birthDate,
    when: realPasportDate,
    ...(hideInnIsz && { inn: '' }),
    ...(hideSnilsIsz && { snils: '' }),
    ...(iszInsurerIsInsured && { birthDate }),
  };
  fillInsurerDataCheckout(rebuildInsurer);
};

const generateInsured = (scenario, additionalData) => {
  const {
    fillInsuredDataIsz,
    hideInnIsz,
    hideSnilsIsz,
    iszInsurerIsInsured,
    birthDate,
  } = additionalData;
  const { insured } = testDataGenerator.iszTestDataGenerate(scenario);

  const realPasportDate = getRealPasportDateForInsured(birthDate);

  const rebuildInsured = {
    ...insured,
    when: realPasportDate,
    ...(hideInnIsz && { inn: '' }),
    ...(hideSnilsIsz && { snils: '' }),
    ...(!iszInsurerIsInsured && { birthDate }),
  };
  fillInsuredDataIsz(rebuildInsured);
};

const generateBeneficiary = (scenario, additionalData) => {
  const {
    fillBenefeciaryDataIsz,
    beneficiaryByLawIsz,
    numberOfBeneficiaries,
    birthDate,
  } = additionalData;
  const { beneficiaries } = testDataGenerator.iszTestDataGenerate(scenario);

  const finalBeneficiaries = rebuildBeneficiaries(beneficiaries, numberOfBeneficiaries, birthDate);
  fillBenefeciaryDataIsz(finalBeneficiaries, beneficiaryByLawIsz);
};

export default {
  [TEST_DATA_ENUMS.insurer]: (scenario, storeData) => generateInsurer(scenario, storeData),
  [TEST_DATA_ENUMS.insured]: (scenario, storeData) => generateInsured(scenario, storeData),
  [TEST_DATA_ENUMS.beneficiary]: (scenario, storeData) => generateBeneficiary(scenario, storeData),
};
